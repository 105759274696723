<template>
  <Modal
    :visible="visible"
    :size="'normal'"
    :title="'添加自定义指标'"
    :closable="true"
    @ok="handleOk"
    @cancel="visible=false"
  >
    <a-form-model
      v-if="visible"
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item ref="name" label="指标名称" prop="name">
        <a-input
          v-model="form.name"
          :max-length="10"
          @blur="
            () => {
              $refs.name.onFieldBlur();
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item label="指标类型" prop="dataType">
        <a-radio-group v-model="form.dataType" @change="dataTypeChange">
          <a-radio value="N">
            数值型
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="form.dataType == 'N'" label="指标小数" prop="precision">
        <a-select v-model="form.precision">
          <template v-for="(item, index) in precisionList">
            <a-select-option :key="index" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </template>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="form.dataType == 'C'" label="最大长度" prop="length">
        <CbNumber v-model="form.length" />
      </a-form-model-item>
      <a-form-model-item label="累加方式">
        <!-- <CbNumber v-model="form.dimension" /> -->
        <DictionariesInput v-model="form.dimension" parameter="dimension_type" />
      </a-form-model-item>
    </a-form-model>
    <div class="footer-text">自定义的指标项需要在算薪模块使用excel表将数据导入系统或在手工输入</div>
  </Modal>
</template>

<script>
import CbNumber from '@/components/CbNumber'
import { addCustom } from '@/services/wage/formulaSetting'
export default {
  name: 'CustomIndicator',
  components: {
    CbNumber,
    Modal: () => import('@/components/CbModal/index'),
    DictionariesInput: () => import('@/components/CbDropDownInput/dictionariesInput/index.vue')
  },
  data() {
    return {
      form: {
        name: '',
        dataType: 'N',
        precision: '',
        length: ''
      },
      visible: false,
      rules: {
        name: [
          { required: true, message: '请输入指标名称', trigger: 'blur' },
          { max: 10, message: '指标名称限制10个字符', trigger: 'change' }
        ],
        precision: [
          { required: true, message: '请选择指标小数精确度', trigger: 'change' }
        ],
        length: [
          { required: true, message: '请输入指标文本长度', trigger: 'blur' }
        ]
      },
      precisionList: [
        {
          label: '保留整数，四舍五入',
          value: 0
        },
        {
          label: '保留一位小数，四舍五入',
          value: 1
        },
        {
          label: '保留两位小数，四舍五入',
          value: 2
        },
        {
          label: '保留三位小数，四舍五入',
          value: 3
        }
      ]
    }
  },
  methods: {
    open() {
      Object.keys(this.form).forEach((item) => {
        this.form[item] = ''
      })
      this.form.dataType = 'N'
      this.visible = true
    },
    // 自定义指标弹窗确定
    handleOk() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const { form } = this
          if (form.dataType === 'C') {
            this.$delete(form, 'precision')
          }
          if (form.dataType === 'N') {
            this.$delete(form, 'length')
          }
          const { data } = await addCustom(form)
          console.log(data)
          this.visible = false
          this.$message.success('添加指标成功')
          this.$emit('addSucceed')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    dataTypeChange() {
      console.log('dataTypeChange')
    }
  }
}
</script>

<style lang="less" scoped>
.footer-text {
  color: @sc-grey-100;
  margin-top: 20px;
}
/deep/ .ant-radio-wrapper-checked > span:nth-child(2){
    color: @sc-grey-80;
}
</style>
