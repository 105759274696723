var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"proof-template"},[(!_vm.isAddTemplate)?_c('div',{staticClass:"proof-template-list"},[_c('div',{staticClass:"proof-template-search"},[_c('span',[_vm._v("证明模版设置")]),_c('a-button',{attrs:{"type":"primary","prefix":"add-one"},on:{"click":function($event){return _vm.addOrEditTemplate(_vm.templateType='add',{})}}},[_vm._v(" 添加模板 ")])],1),_c('custom-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"table-ref":".proof-template-list","row-key":"jobGradeId","pagination":{
        current: _vm.pager.pageNum,
        pageSize: _vm.pager.pageSize,
        total: _vm.total,
        showTotal: function (total) { return ("共 " + total + " 条数据"); },
        showQuickJumper: true,
        showSizeChanger: true
      }},on:{"change":_vm.loadDataList},scopedSlots:_vm._u([{key:"switchStatus",fn:function(ref){
      var scope = ref.scope;
return _c('span',{},[_c('div',[_c('a-switch',{attrs:{"size":"small"},on:{"change":function($event){return _vm.changeStatus(scope.record)}},model:{value:(scope.record.tempStatustype),callback:function ($$v) {_vm.$set(scope.record, "tempStatustype", $$v)},expression:"scope.record.tempStatustype"}})],1)])}},{key:"operation",fn:function(ref){
      var scope = ref.scope;
return [[_c('div',{staticClass:"operation-btn-box"},[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.addOrEditTemplate(_vm.templateType='edit', scope.record)}}},[_vm._v(" 编辑 ")]),_c('a-button',{staticClass:"delete",attrs:{"type":"link"},on:{"click":function($event){return _vm.deletTemplate(scope.record)}}},[_vm._v(" 删除 ")])],1)]]}}],null,false,3936683904)})],1):_c('AddTemplate',{on:{"back":_vm.backRefresh}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }