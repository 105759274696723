<template>
  <div class="addScheme">
    <HeaderBack :title="title" @back="toggleValue" />
    <div class="addScheme-content">
      <a-form-model ref="addForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
        <a-form-model-item label="参保城市" prop="provinceCity">
          <CbSelectArea
            ref="selectArea"
            :province-disabled="operateType === 'edit'"
            :city-disabled="operateType === 'edit'"
            :area-show="false"
            :government-auto="true"
            :province-width="200"
            :city-width="200"
            @change="onChangeArea"
          />
        </a-form-model-item>
        <a-form-model-item label="方案名称" prop="schemeName">
          <a-input v-model="form.schemeName" placeholder="请输入方案名称" />
        </a-form-model-item>
        <a-form-model-item v-if="showTemplate" label="方案参考模板">
          <div v-if="templates.length > 0">
            <a-popover v-for="(item, index) in templates" :key="index" title="参考方案模板" class="reference_template">
              <template slot="content">
                <custom-table
                  :columns="popoverColumns"
                  :data-source="item.builtinSchemeDataList"
                  :pagination="false"
                  :scroll="{ x: true }"
                  row-key="insureCode"
                >
                  <!-- 公司比例 -->
                  <template slot="companyScale" slot-scope="{ scope }"> {{ Number(scope.record.companyScale).toFixed(2) }}% </template>
                  <!-- 个人比例 -->
                  <template slot="personScale" slot-scope="{ scope }"> {{ Number(scope.record.personScale).toFixed(2) }}% </template>
                </custom-table>
              </template>
              <a-button :class="colorChange === item.value ? 'button-color' : ''" @click="selectScheme(item.value)">
                {{ item.label }}
              </a-button>
            </a-popover>
          </div>
          <a-button v-else disabled>暂无参考模板</a-button>
        </a-form-model-item>
        <a-form-model-item label="设定为默认方案">
          <a-checkbox :checked="form.defaultValue === '1'?true:false" @change="upDefaultVal">
            {{ checkedDefault?'该城市下已有默认方案': form.defaultValue === '0'?'该城市下暂无默认方案':'' }}
          </a-checkbox>
        </a-form-model-item>
      </a-form-model>

      <div class="insurance-list">
        <custom-table
          :columns="columns"
          :data-source="dataSource"
          :pagination="false"
          :scroll="{ x: true }"
          row-key="insureCode"
          @change="loadDataList"
        >
          <!-- 基数下限 -->
          <template slot="baseLow" slot-scope="{ scope }">
            <!-- <CbNumber v-model="scope.record.baseLow" :decimal-separator="true" precision="2" :is-thousands="true" numamount="true" /> -->
            <NumThousand
              :v-model="scope.record.baseLow"
              :decimal-separator="true"
              :int-val="scope.record.baseLow"
              :is-thousands="true"
              precision="2"
              numamount="true"
              @blur="inputChangeFun(arguments[0], scope, 'baseLow')"
            />
          </template>
          <!-- 基数上限 -->
          <template slot="baseTop" slot-scope="{ scope }">
            <!-- <CbNumber v-model="scope.record.baseTop" :decimal-separator="true" precision="2" numamount="true" /> -->
            <NumThousand
              :v-model="scope.record.baseTop"
              :int-val="scope.record.baseTop"
              :decimal-separator="true"
              :is-thousands="true"
              precision="2"
              numamount="true"
              @blur="inputChangeFun(arguments[0], scope, 'baseTop')"
            />
          </template>
          <!-- 公司比例 -->
          <template slot="companyScale" slot-scope="{ scope }">
            <CbNumber v-model="scope.record.companyScale" :max="100" :decimal-separator="true" precision="2" suffix="%" />
          </template>
          <!-- 公司额外固定金额 -->
          <template slot="companyExtra" slot-scope="{ scope }">
            <!-- <CbNumber v-model="scope.record.companyExtra" :decimal-separator="true" precision="2" numamount="true" /> -->
            <NumThousand
              :v-model="scope.record.companyExtra"
              :int-val="scope.record.companyExtra"
              :is-thousands="true"
              decimal-separator
              precision="2"
              numamount="true"
              @blur="inputChangeFun(arguments[0], scope, 'companyExtra')"
            />
          </template>
          <!-- 公司取整规则 -->
          <template slot="companyTrunc" slot-scope="{ scope }">
            <a-select v-model="scope.record.companyTrunc" :get-popup-container="getPopupContainer">
              <a-select-option v-for="(item, index) in roundingRules" :key="index" :value="item.value" :title="item.label">{{
                item.label
              }}</a-select-option>
            </a-select>
          </template>
          <!-- 个人比例 -->
          <template slot="personScale" slot-scope="{ scope }">
            <CbNumber v-model="scope.record.personScale" :decimal-separator="true" precision="2" :max="100" suffix="%" />
          </template>
          <!-- 个人额外固定金额 -->
          <template slot="personExtra" slot-scope="{ scope }">
            <!-- <CbNumber v-model="scope.record.personExtra" :decimal-separator="true" precision="2" numamount="true" /> -->
            <NumThousand
              :v-model="scope.record.personExtra"
              :int-val="scope.record.personExtra"
              :is-thousands="true"
              :decimal-separator="true"
              precision="2"
              numamount="true"
              @blur="inputChangeFun(arguments[0], scope, 'personExtra')"
            />
          </template>
          <!-- 个人取整规则 -->
          <template slot="personTrunc" slot-scope="{ scope }">
            <a-select v-model="scope.record.personTrunc" :get-popup-container="getPopupContainer">
              <a-select-option v-for="(item, index) in roundingRules" :key="index" :value="item.value" :title="item.label">{{
                item.label
              }}</a-select-option>
            </a-select>
          </template>
          <!-- 操作列 -->
          <template slot="operation" slot-scope="{ scope }">
            <a-button :disabled="dataSource.length <= 1" type="link" class="delete" @click="deleted(scope)">删除</a-button>
          </template>
          <template slot="footer">
            <a-button type="link" prefix="add-one" @click="addInsuranceType">
              <span>添加险种</span>
            </a-button>
          </template>
        </custom-table>
        <a-button type="primary" class="addScheme-buttom" @click="storage">保存</a-button>
      </div>
      <Drawer title="添加险种" :closable="false" size="small" :visible="visible" @close="onClose" @onSubmit="submit">
        <div class="drawer-content">
          <a-checkbox-group v-model="checkedList" :options="plainOptions" @change="onCheck" />
        </div>
      </Drawer>
      <Modal :visible="changeVisible" title="选择参考模板" @cancel="changeVisible=false" @ok="getSchemeDataListBySchemeId(tempId)">
        <p>选定模板时，模板数据会覆盖当前参保方案，方案原数据将消失，确认选择当前模板吗？</p>
      </Modal>

      <Modal :visible="tenantBillStatusShow" title="提示" @cancel="tenantBillStatusShow=false" @ok="validForm">
        <p>{{ tenantBillStatus === 1?'提示：本月账单已经生成，如要变更基数，需要使用【账单】功能重新生成本月账单，否则基数变更只能下个月生效，是否确定要保存？':'注意：本月账单已经生成并锁定，基数变更只能下个月生效，是否确定要保存？' }}</p>
      </Modal>
    </div>
  </div>
</template>
<script>
import mixins from '@/components/CustomTable/index.js'
import { getSchemeTemplateListApi, getSchemeDataListBySchemeIdApi, getSchemeApi, checkExistDefaultSchemeApi, checkChangSchemeApi } from '@/services/insurance/setting.js'
import { getTanantCodeBatch } from '@/services/dropDownInput/index.js'
export default {
  name: 'AddScheme',
  components: {
    // CbThreeLevel: () => import('@/components/KFromDesignCustom/CbThreeLevel/select.vue'),
    HeaderBack: () => import('@/components/headerBack'),
    CbNumber: () => import('@/components/CbNumber'),
    CbSelectArea: () => import('@/components/CbSelectArea'),
    NumThousand: () => import('@/components/CbNumber/thousand'),
    Drawer: () => import('@/components/CbDrawer/index'),
    Modal: () => import('@/components/CbModal/index')
  },
  mixins: [mixins],
  props: {
    // 标题
    title: {
      type: String,
      default: '',
      required: true
    },
    // 参保城市
    citys: {
      type: Array,
      default: () => []
    },
    cityCode: {
      type: String,
      default: ''
    },
    schemeId: {
      type: String,
      default: ''
    },
    // 操作类型
    operateType: {
      type: String,
      default: ''
    },
    // 投保类型（社保：social，医保： medical，公积金：housing ）
    insureClassify: {
      type: String,
      default: ''
    },
    // 修改参数
    schemeDetail: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      labelCol: { span: 2 },
      wrapperCol: { span: 6 },
      templates: [],
      visible: false,
      colorChange: '',
      form: {
        province: undefined,
        city: undefined,
        schemeName: undefined,
        schemeId: undefined,
        defaultValue: '0'
      },
      rules: {
        provinceCity: [],
        schemeName: [
          { required: true, message: '请填写方案名称', trigger: 'blur' },
          { max: 30, message: '方案名称最大支持30个文字', trigger: 'change' },
          { pattern: '^[\u4E00-\u9FA5A-Za-z0-9]+$', message: '方案名称不支持特殊字符', trigger: 'change' }
        ]
      },
      columns: [
        {
          title: '险种',
          dataIndex: 'insureCodeName',
          key: 'insureCodeName',
          width: 100,
          customCell: () => {
            return {
              style: {
                'padding-left': '32px'
              }
            }
          }
        },
        {
          title: '基数下限',
          dataIndex: 'baseLow',
          key: 'baseLow',
          scopedSlots: { customRender: 'baseLow' },
          width: 150
        },
        {
          title: '基数上限',
          dataIndex: 'baseTop',
          key: 'baseTop',
          scopedSlots: { customRender: 'baseTop' },
          width: 150
        },
        {
          title: '公司比例',
          dataIndex: 'companyScale',
          key: 'companyScale',
          scopedSlots: { customRender: 'companyScale' },
          width: 150
        },
        {
          title: '公司额外固定金额',
          dataIndex: 'companyExtra',
          key: 'companyExtra',
          scopedSlots: { customRender: 'companyExtra' },
          width: 160
        },
        {
          title: '公司取整规则',
          dataIndex: 'companyTrunc',
          key: 'companyTrunc',
          scopedSlots: { customRender: 'companyTrunc' },
          width: 170,
          customCell: () => {
            return {
              style: {
                'max-width': '160px'
              }
            }
          }
        },
        {
          title: '个人比例',
          dataIndex: 'personScale',
          key: 'personScale',
          scopedSlots: { customRender: 'personScale' },
          width: 150
        },
        {
          title: '个人额外固定金额',
          dataIndex: 'personExtra',
          key: 'personExtra',
          scopedSlots: { customRender: 'personExtra' },
          width: 160
        },
        {
          title: '个人取整规则',
          dataIndex: 'personTrunc',
          key: 'personTrunc',
          scopedSlots: { customRender: 'personTrunc' },
          width: 170,
          customCell: () => {
            return {
              style: {
                'max-width': '160px'
              }
            }
          }
        },
        {
          title: '',
          scopedSlots: { customRender: 'operation' },
          width: 80
        }
      ],
      popoverColumns: [
        // 参考方案模板提示表格的列
        {
          title: '险种',
          dataIndex: 'insureCodeName',
          key: 'insureCodeName'
        },
        {
          title: '基数下限',
          dataIndex: 'baseLow',
          key: 'baseLow'
        },
        {
          title: '基数上限',
          dataIndex: 'baseTop',
          key: 'baseTop'
        },
        {
          title: '公司比例',
          dataIndex: 'companyScale',
          key: 'companyScale',
          scopedSlots: { customRender: 'companyScale' }
        },
        {
          title: '公司额外固定金额',
          dataIndex: 'companyExtra',
          key: 'companyExtra'
        },
        {
          title: '公司取整规则',
          dataIndex: 'companyTruncName',
          key: 'companyTruncName'
        },
        {
          title: '个人比例',
          dataIndex: 'personScale',
          key: 'personScale',
          scopedSlots: { customRender: 'personScale' }
        },
        {
          title: '个人额外固定金额',
          dataIndex: 'personExtra',
          key: 'personExtra'
        },
        {
          title: '个人取整规则',
          dataIndex: 'personTruncName',
          key: 'personTruncName'
        }
      ],
      dataSource: [], // 险种列表
      roundingRules: [], // 取整规则
      plainOptions: [], // 添加险种的列表，根据当前类别动态获取
      checkedList: [],
      isValid: true,
      valid: true,
      showTemplate: false,
      changeVisible: false, // 点选参考模板的二次确认框
      tempId: '', // 点选参考模板弹出二次确认框时临时保存的值
      checkedDefault: false,
      tenantBillStatus: 0,
      tenantBillStatusShow: false
    }
  },
  watch: {
    'schemeId': {
      handler: function(val) {
        // 编辑时传schemeId，获取方案详情
        if (val) {
          this.getScheme()
        }
      },
      immediate: true
    },
    'form.city': function(val) {
      // 监听选择社保城市，查询社保方案
      if (val && Number(val) > -1) {
        this.getSchemeTemplateList()
        this.checkExistDefaultScheme()
      }
    }
    // 'form.schemeId': function(val) {
    //   // 监听选择方案模板id，查询模板明细
    //   if (val && this.operateType !== 'edit') {
    //     this.getSchemeDataListBySchemeId()
    //   }
    // }
  },
  created() {
    this.form = {}
    this.getTanantCodeBatch()
  },
  mounted() {
    this.rules.provinceCity = [{ required: true, validator: this.getProvinceCity, trigger: 'change' }]
  },
  methods: {
    getPopupContainer() {
      return this.$el.parentNode
    },
    upDefaultVal(e) {
      this.$set(this.form, 'defaultValue', e.target.checked ? '1' : '0')
    },
    // 校验同城市同投保类型是否存在默认方案
    async checkExistDefaultScheme() {
      const params = {
        cityCode: this.form.city,
        insureClassify: this.insureClassify,
        schemeId: this.schemeId || undefined
      }
      const res = await checkExistDefaultSchemeApi(params)
      if (res.code === 100) this.checkedDefault = res.data
    },
    // 所属城市校验
    getProvinceCity(rule, value, callback) {
      this.$nextTick(() => {
        if (!this.form.province) {
          callback(new Error('请选择所属省份'))
        } else if (!this.form.city) {
          callback(new Error('请选择所属城市'))
        } else {
          callback()
        }
      })
    },
    // 获取内置方案模板列表
    getSchemeTemplateList() {
      getSchemeTemplateListApi({
        cityCode: this.form.city,
        insureClassify: this.insureClassify
      }).then((res) => {
        if (res.code === 100) {
          this.templates = res.data.map((v) => {
            return {
              label: v.schemeName,
              value: v.schemeId,
              builtinSchemeDataList: v.builtinSchemeDataList
            }
          })
          if (this.operateType === 'add') {
            if (this.templates.length > 0) {
              this.getSchemeDataListBySchemeId(this.templates[0].value)
            }
          }
        } else {
          this.templates = []
        }
        // 方案模板显示
        this.showTemplate = true
      })
    },
    // 点选内置模板
    selectScheme(val) {
      this.tempId = val
      this.changeVisible = true
    },
    // 获取内置方案的数据
    getSchemeDataListBySchemeId(val) {
      // 使用参考模板内置方案的数据,清空之前表格数据
      this.dataSource = []
      this.changeVisible = false
      this.colorChange = val
      getSchemeDataListBySchemeIdApi({
        schemeId: val
      }).then((res) => {
        if (res.code === 100) {
          this.dataSource = res.data.map((v) => {
            return {
              ...v
            }
          })
        } else {
          this.dataSource = []
        }
      })
    },
    // 获取方案详情
    getScheme() {
      getSchemeApi({
        schemeId: this.schemeId
      }).then((res) => {
        if (res.code === 100) {
          this.$set(this.form, 'schemeName', res.data.schemeName)
          this.$set(this.form, 'schemeId', res.data.schemeId)
          this.$set(this.form, 'defaultValue', res.data.defaultValue)
          this.dataSource = res.data.schemeDataList
          this.$nextTick(() => {
            this.$refs.selectArea.dataEcho({ province: res.data.provinceCode, city: res.data.cityCode }) // 回显省市
          })
        }
      })
    },
    // 批量获取字典项
    getTanantCodeBatch() {
      const data = [this.insureClassify, 'trunc_rule']
      getTanantCodeBatch(data).then((res) => {
        if (res.code === 100) {
          res.data.forEach((item) => {
            if (item.codeName === this.insureClassify) {
              // 险种
              this.plainOptions = item.baseCodes.map((v) => {
                return {
                  label: v.content,
                  value: v.codeKey
                }
              })
            }
            if (item.codeName === 'trunc_rule') {
              // 取整规则
              this.roundingRules = item.baseCodes.map((v) => {
                return {
                  label: v.content,
                  value: v.codeKey
                }
              })
            }
          })
        }
      })
    },
    toggleValue() {
      this.$emit('back', true)
    },
    // 添加险种
    addInsuranceType() {
      const selectedList = this.dataSource.map((v) => v.insureCode) // 当前勾选的险种code列表
      this.plainOptions.forEach((item) => {
        if (selectedList.includes(item.value)) {
          item.disabled = true
        } else {
          item.disabled = false
        }
      })
      this.checkedList = this.dataSource.map((v) => v.insureCode) // 勾选列表
      this.visible = true
    },
    deleted(scope) {
      this.dataSource.splice(scope.index, 1)
    },
    onClose() {
      this.visible = false
    },
    // 保存“新增险种”
    submit() {
      const newAddCodeList = []
      const dataSourceCodeList = this.dataSource.map((v) => v.insureCode)
      this.checkedList.forEach((item) => {
        // 新增的险种不在模板列表内，则自动拼接属性，放入表格数据内
        if (!dataSourceCodeList.includes(item)) {
          newAddCodeList.push({
            insureCode: item,
            insureCodeName: this.plainOptions.filter((v) => v.value === item)[0].label,
            baseLow: undefined,
            baseTop: undefined,
            companyExtra: undefined,
            companyScale: undefined,
            companyTrunc: undefined,
            personExtra: undefined,
            personScale: undefined,
            personTrunc: undefined
          })
        }
      })
      this.dataSource = this.dataSource.concat(newAddCodeList)
      this.visible = false
      this.checkedList = []
      console.log(this.dataSource, 'this.dataSource')
    },
    onCheck() {},
    // 所属地区
    onChangeArea(val, e) {
      console.log(e)
      this.$set(this.form, 'province', e[0])
      this.$set(this.form, 'city', e[1])
    },
    // 险种input赋值
    inputChangeFun(returnVal, scope, fields) {
      this.dataSource[scope.index][fields] = returnVal
    },
    // 方案的保存
    storage() {
      this.isValid = true
      if (this.dataSource) {
        this.dataSource.forEach((item) => {
          for (var key in item) {
            if (item[key] === '') {
              this.isValid = false
            }
          }
        })
      }
      console.log(this.dataSource, '==dataSource')
      if (!this.isValid) {
        this.$message.error('请完善险种信息')
        return
      }
      if (this.dataSource.length < 1) {
        this.$message.error('至少存在一条险种信息')
        return
      }
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          if (this.operateType === 'edit') {
            checkChangSchemeApi({ schemeId: this.form.schemeId }).then(res => {
              if (res.code === 100) {
                if (res.data.tenantBillStatus === 1 || res.data.tenantBillStatus === 2) {
                  const tips = res.data.tenantBillStatus === 1
                    ? '本月账单已生成，调整内容下月生效，如需本月生效，请重新生成本月账单。确认是否要进行调整？'
                    : '本月账单已锁定，调整内容下月生效，如需本月生效，请重新生成本月账单。确认是否要进行调整？'
                  this.$Reconfirm({
                    title: '提示',
                    content: tips
                  }).then(() => {
                    this.validForm()
                  }).catch(() => {})
                } else {
                  this.validForm()
                }
              }
            })
          } else {
            this.validForm()
          }
        }
      })
    },
    validForm() {
      this.$emit('saveForm', {
        operateType: this.operateType,
        data: {
          insureClassify: this.insureClassify,
          schemeDataList: this.dataSource,
          provinceCode: this.form.province,
          cityCode: this.form.city,
          schemeName: this.form.schemeName,
          schemeId: this.form.schemeId,
          defaultValue: this.form.defaultValue
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.addScheme {
  width: 100%;
  padding: 20px;
  overflow: auto;
  .addScheme-buttom {
    margin-top: 26px;
    float: right;
  }
  .addScheme-content {
    width: 100%;
    /deep/.ant-form-item {
      margin-bottom: 24px;
    }
  }
}
.insurance-list {
  /deep/.number {
    width: 60%;
    .ant-input {
      color: @sc-grey-100;
    }
  }
  /deep/.ant-select {
    width: 85%;
    .ant-select-selection-selected-value {
      color: @sc-grey-100;
    }
  }
}
.drawer-content {
  /deep/.ant-checkbox-group-item {
    width: 46%;
    margin-bottom: 16px;
  }
}
.reference_template {
  margin-right: 10px;
}
/deep/.ant-checkbox-group {
  width: 100%;
}
/deep/.ant-table-thead tr th {
  background-color: @sc-greyBg-10;
}
/deep/.ant-table-footer {
  background-color: @base-bg-color;
  padding-left: 16px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
/deep/.ant-table-content {
  border-radius: 6px;
  border: 1px solid @sc-greyBg-20;
}
/deep/.ant-table-thead > tr:first-child > th:first-child {
  padding-left: 32px;
  border-top-left-radius: 6px;
}
/deep/.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 6px;
}
/deep/.threeLevelBox {
  margin-top: 4px;
  .ThreeLevels {
    color: @sc-grey-100;
  }
}
/deep/.ant-form-item-children {
  .ant-input,
  .ant-select-enabled {
    color: @sc-grey-100;
  }
}
/deep/.ant-form-item-label label {
  color: @sc-grey-100;
}
.button-color {
  color: @sc-primary-100;
  border-color: @sc-primary-100;
}
/deep/.ant-select-enabled {
  &:nth-child(3) {
    display: none;
  }
}
</style>
