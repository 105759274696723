<template>
  <div id="fieldModal" class="card-rule">
    <div class="card-main">
      <AlertTips @look="lookTips">
        <template slot="content">
          <p>1、外勤规则可控制员工申请外出、出差审批的时长单位 (如按小时提交)、时长计算方式 (如按班次计算)。</p>
          <p>2、可设置多个不同规则，设置后，前往【考勤组】把不同规则关联到各自考勤组。</p>
        </template>
      </AlertTips>
      <div class="addrulebtn">
        <a-button type="primary" prefix="add-one" @click="addField">
          新增外勤规则
        </a-button>
      </div>
      <custom-table
        id="fieldModalTable"
        :columns="fieldTable"
        :data-source="dataSource"
        :scroll="{
          y: tableHeight
        }"
        :tabs-height="36"
        row-key="outsideRuleId"
        :pagination="false"
      >
        <!-- 外出规则 -->
        <template slot="goOutRule" slot-scope="{scope}">
          {{ scope.record.outsideUnit | unit }}<!--，{{ scope.record.outsideHalfDayCalcType | CalcType }} -->
        </template>
        <!-- 外勤规则 -->
        <template slot="evectionRule" slot-scope="{scope}">
          {{ scope.record.travelUnit | unit }}<!-- ，{{ scope.record.travelHalfDayCalcType | CalcType }} -->
        </template>
        <!-- 操作列 -->
        <template slot="operation" slot-scope="{ scope}">
          <div class="flex-align-center">
            <div class="more-handle-btn-box">
              <a-button type="link" @click="edit(scope.record)">
                编辑
              </a-button>
              <a-button type="link" class="delete" @click="deleted(scope.record)">
                删除
              </a-button>
            </div>
            <!-- <a-popover
              placement="bottom"
              trigger="hover"
            >
              <template slot="content">
                <div class="more-handle-btn">
                  <a-button type="link" @click="edit(scope.record)">
                    编辑
                  </a-button>
                  <a-button type="link" class="delete" @click="deleted(scope.record)">
                    删除
                  </a-button>
                </div>
              </template>
              <div class="more-handle">
                ···
              </div>
            </a-popover> -->
          </div>
        </template>
      </custom-table>
    </div>
    <Modal
      :closable="true"
      :visible="fieldVisible"
      :mask-closable="false"
      :size="'normal'"
      :title="type==='add'?'新增外勤规则':'编辑外勤规则'"
      @cancel="onClose"
      @ok="handleDelShift"
    >
      <div class="field-content">
        <a-form-model
          ref="fieldForm"
          :model="fieldForm"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="规则名称" prop="ruleName">
            <a-input v-model="fieldForm.ruleName" type="text" placeholder="比如产品部的外勤规则" />
          </a-form-model-item>
          <BlockTitle title="外出规则" class="msg-title" />
          <a-form-model-item label="申请时间单位" prop="outsideUnit">
            <a-select v-model="fieldForm.outsideUnit" default-value="小时" :options="travelUnits" placeholder="请选择外出申请时间单位" />
          </a-form-model-item>
          <BlockTitle title="出差规则" class="msg-title" />
          <a-form-model-item label="申请时间单位" prop="travelUnit">
            <a-select v-model="fieldForm.travelUnit" default-value="小时" :options="travelUnits" placeholder="请选择出差申请时间单位" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </Modal>
  </div>
</template>
<script>
import { sepcialCode } from '@/utils/reg.js'
import mixins from '@/components/CustomTable/index.js'
import { getOutsideRuleListApi, addOutsideRuleApi, deleteOutsideRuleApi, updateOutsideRuleApi, detailOutsideRuleApi } from '@/services/attendance/rule'
import { halfday_calc_type, vacation_unit } from '@/pages/attendance/dict.js'
export default {
  name: 'FieldRule',
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index'),
    Modal: () => import('@/components/CbModal/index'),
    AlertTips: () => import('../../../components/alert.vue')
  },
  filters: {
    unit(val) {
      if (!val) return ' '
      const obj = vacation_unit.find(item => {
        return item.value === val
      })
      return obj.label + '申请'
    }
  },
  mixins: [mixins],
  data() {
    return {
      tableHeight: null,
      halfDayCalcType: halfday_calc_type,
      travelUnits: vacation_unit,
      fieldTable: [
        {
          title: '规则名称',
          dataIndex: 'ruleName',
          key: 'ruleName',
          ellipsis: true
        },
        {
          title: '外出规则',
          dataIndex: 'goOutRule',
          key: 'goOutRule',
          scopedSlots: { customRender: 'goOutRule' },
          customCell: () => {
            return {
              style: {
                'min-width': '120px'
              }
            }
          }
        },
        {
          title: '出差规则',
          dataIndex: 'evectionRule',
          key: 'evectionRule',
          scopedSlots: { customRender: 'evectionRule' },
          customCell: () => {
            return {
              style: {
                'min-width': '120px'
              }
            }
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '100px',
          fixed: 'right'
        }
      ],
      type: '',
      dataSource: [], // 数据
      fieldVisible: false,
      outsideRuleId: '',
      fieldForm: {
        fieldName: '',
        outsideUnit: undefined,
        travelUnit: undefined,
        halfDayStandard: '',
        morning: '',
        afternoon: ''
      },
      rules: {
        ruleName: [
          { required: true, message: '请输入规则名称', trigger: 'blur' },
          { min: 1, max: 30, message: '规则名称支持1~30个文字', trigger: 'change' },
          { pattern: sepcialCode, message: '规则名称不支持特殊字符', trigger: 'change' }
        ],
        outsideUnit: [
          { required: true, message: '请选择外出申请时间单位', trigger: ['blur', 'change'] }
        ],
        travelUnit: [
          { required: true, message: '请选择出差申请时间单位', trigger: ['blur', 'change'] }
        ]
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 18 }
    }
  },
  created() {
    this.getOutsideRuleListApi()
  },
  methods: {
    // 外勤规则列表
    getOutsideRuleListApi() {
      getOutsideRuleListApi().then(res => {
        if (res.code === 100) {
          this.dataSource = res.data
          this.$nextTick(() => {
            const contentH = document.body.scrollHeight - 48
            const tableH = document.querySelector('#fieldModal .ant-table-wrapper')
            const offset = document.querySelector('#fieldModal .ant-table-wrapper').offsetTop
            const headerH = document.querySelector('#fieldModal .ant-table-thead tr').clientHeight
            const tableMaxH = contentH - offset - 20
            tableH.style.maxHeight = `${tableMaxH}px`
            this.tableHeight = tableMaxH - headerH
          })
        }
      })
    },
    lookTips() {
      setTimeout(() => {
        const boxH = document.getElementById('rules').clientHeight
        const offset = document.getElementById('fieldModalTable').offsetTop
        this.tableHeight = boxH - offset - 80
        console.log(this.tableHeight, boxH, offset)
      }, 500)
    },
    // 新增外勤规则
    addField() {
      this.type = 'add'
      this.fieldVisible = true
    },
    async edit(data) {
      this.type = 'edit'
      this.outsideRuleId = data.outsideRuleId
      const res = await detailOutsideRuleApi({ id: this.outsideRuleId })
      this.fieldForm = res.data
      this.fieldVisible = true
    },
    deleted(data) {
      this.outsideRuleId = data.outsideRuleId
      this.$Reconfirm({
        title: '删除外勤规则',
        content: '确定要删除该外勤规则吗？'
      }).then(async() => {
        const res = await deleteOutsideRuleApi({ id: this.outsideRuleId })
        if (res.code === 100) {
          this.$message.success('删除成功')
          this.getOutsideRuleListApi()
        }
      }).catch(() => {
        console.log('no')
      })
    },
    handleDelShift() {
      this.$refs.fieldForm.validate(valid => {
        console.log(valid, 'valid')
        if (valid) {
          if (this.type === 'add') {
            addOutsideRuleApi(this.fieldForm).then(res => {
              if (res.code !== 100) return
              this.$message.success('新增成功')
              this.fieldVisible = false
              this.fieldForm = {}
              this.resetForm()
              this.getOutsideRuleListApi()
            })
          } else {
            updateOutsideRuleApi({ ...this.fieldForm, outsideRuleId: this.outsideRuleId }).then(res => {
              if (res.code !== 100) return
              this.$message.success('编辑成功')
              this.fieldVisible = false
              this.fieldForm = {}
              this.resetForm()
              this.getOutsideRuleListApi()
            })
          }
        }
      })
    },
    onClose() {
      this.fieldVisible = false
      this.fieldForm = {}
      this.resetForm()
    },
    resetForm() {
      this.$refs.fieldForm.resetFields()
    }
  }
}
</script>
<style lang="less" scoped>
.card-rule{
  width: 100%;
  height: 100%;
  .card-main{
    height: auto;
  }
}
.addrulebtn{
  margin: 0 0 16px 0;
}
.more-handle-btn {
  display: flex;
  flex-direction: column;
}
.more-handle{
  font-size: 20px;
  font-weight: 700;
  color: @sc-primary-100;
  cursor: pointer;
  margin-left: 5px;
}
.field-content{
  .ant-row{
    width: 100%;
    display: flex;
    /deep/.ant-form-item-label{
      width: 120px !important;
    }

  }
  .msg-title{
    margin-bottom: 16px;
  }
}
.more-handle-btn-box{
  display: flex;
  button{
    padding-left: 0;
    padding-right: 16px;
  }
}
</style>
