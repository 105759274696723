<!--
 * @Date: 2022-08-16 14:45:55
 * @descriotion:
-->
<template>
  <Modal
    :closable="true"
    :mask-closable="true"
    :visible="visible"
    title="补缴详情"
    size="large"
    @ok="cancel"
    @cancel="cancel"
  >
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      row-key="onJobId"
      :pagination="false"
      :scroll="{x:1500}"
    >
      <!-- 序号 -->
      <template slot="index" slot-scope="{ scope }">
        <span>{{ scope.index + 1 }}</span>
      </template>
      <!-- 姓名 -->
      <template slot="staffName" slot-scope="{ scope }">
        <CbTableAvater
          :staff-id="scope.record.staffId"
          :on-job-id="scope.record.onJobId"
          :table-name="scope.record.staffName"
          :title="scope.record.staffName"
          :is-archives="true"
        />
      </template>
    </custom-table>
  </Modal>
</template>
<script>
import { supplyPayDetailApi } from '@/services/insurance/bill'
import mixins from '@/components/CustomTable/index.js'
export default {
  name: 'SupplyModal',
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  mixins: [mixins],
  data() {
    return {
      dataSource: [],
      visible: false,
      columns: [
        {
          title: '证件号码',
          dataIndex: 'idNum',
          key: 'idNum',
          width: 200,
          ellipsis: true
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          key: 'staffName'
        },
        {
          title: '类型',
          dataIndex: 'insureClassify',
          key: 'insureClassify'
        },
        {
          title: '险种',
          dataIndex: 'insureCode',
          key: 'insureCode'
        },
        {
          title: '补缴起始年月',
          dataIndex: 'startMonth',
          key: 'startMonth'
        },
        {
          title: '补缴终止年月',
          dataIndex: 'endMonth',
          key: 'endMonth'
        },
        {
          title: '补缴基数',
          dataIndex: 'paymentBase',
          key: 'paymentBase'
        },
        {
          title: '单位缴费比例',
          dataIndex: 'companyScale',
          key: 'companyScale'
        },
        {
          title: '个人缴费比例',
          dataIndex: 'personScale',
          key: 'personScale'
        },
        {
          title: '单位缴费额',
          dataIndex: 'companyPay',
          key: 'companyPay'
        },
        {
          title: '个人缴费额',
          dataIndex: 'personPay',
          key: 'personPay'
        },
        {
          title: '缴费总计',
          dataIndex: 'total',
          key: 'total'
        },
        {
          title: '补缴原因',
          dataIndex: 'supplyReason',
          key: 'supplyReason'
        }
      ]
    }
  },
  mounted() {

  },
  methods: {
    async show(data) {
      this.visible = true
      const res = await supplyPayDetailApi(data)
      if (res.code === 100) {
        this.dataSource = res.data
      }
    },
    cancel() {
      this.visible = false
    }
  }
}
</script>
<style lang="less" scoped>

</style>
