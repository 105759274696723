<template>
  <div class="AutomaticRules">
    <a-form-model-item
      v-if="form.vacationTypeCode==='NXJ'"
      label="自动发放规则"
    >
      <a-radio-group v-model="form.vacationGrantRuleType">
        <a-radio v-for="convert in grantRuleList" :key="convert.codeKey" :style="inlineRadioStyle" :value="convert.codeKey">
          {{ convert.content }}
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <div v-if="form.vacationTypeCode==='NXJ'" class="AutomaticRules-content">
      <!-- 年假自动发放规则 -->
      <!-- 固定发放 -->
      <div v-if="form.vacationGrantRuleType==='1'" class="grantFixedDays">
        每年自动发放假期<CbNumber v-model="form.grantFixedDays" class="large-input" :decimal-separator="false" />天
      </div>
      <!-- 年假阶梯发放组 start -->
      <div v-else-if="['2','3'].includes(form.vacationGrantRuleType)">
        <div v-for="(it,index) in form.amountSteps" :key="index" class="set-rules">
          {{ form.vacationGrantRuleType === '2' ?'工':'司' }}龄满<CbNumber v-model="it.startValue" :disabled="!index" class="number-input" suffix="年" />
          <a-select v-model="it.containStartFlag" class="small-input">
            <a-select-option value="1">&lt;</a-select-option>
            <a-select-option value="2">≤</a-select-option>
          </a-select>{{ form.vacationGrantRuleType === '2' ?'工':'司' }}龄
          <a-select v-model="it.containEndFlag" class="small-input">
            <a-select-option value="1">&lt;</a-select-option>
            <a-select-option value="2">≤</a-select-option>
          </a-select>
          <CbNumber v-model="it.endValue" class="number-input margin-left-none" suffix="年" :decimal-separator="true" @change="change(it.endValue,index)" />，发放假期
          <CbNumber v-model="it.amount" class="number-input" suffix="天" :decimal-separator="true" />
          <a-button v-if="!index" type="iconBtn" icon="add-one" @click="form.amountSteps.push({containStartFlag: '1', containEndFlag: '1',startValue:form.amountSteps[form.amountSteps.length-1].endValue})" />
          <a-button v-else type="iconBtn" class="deleted" icon="close-small" @click="form.amountSteps.splice(index,1)" />
        </div>
      </div>
      <!-- 工龄阶梯发放组 end-->
      <!-- 工龄递增发放组 start-->
      <div v-else-if="['4','5'].includes(form.vacationGrantRuleType)" class="set-rules">
        {{ form.vacationGrantRuleType === '4' ?'工':'司' }}龄满<CbNumber v-model="form.grantMinWorkAge" class="increas-input" suffix="年" :decimal-separator="true" />，可以休假
        <CbNumber v-model="form.grantMinDays" class="increas-input" suffix="天" :decimal-separator="true" />，{{ form.vacationGrantRuleType === '4' ?'工':'司' }}龄每增加
        <CbNumber v-model="form.grantWorkAgeStep" class="increas-input" suffix="年" :decimal-separator="true" />，假期增加
        <CbNumber v-model="form.grantDaysStep" class="increas-input" suffix="天" :decimal-separator="true" />，假期最高上限
        <CbNumber v-model="form.grantMaxDays" class="number-input margin-left-none" suffix="天" :decimal-separator="true" />
      </div>
      <!-- 工龄递增发放组 end-->
    </div>
    <!-- 调休假自动发放规则 -->
    <div v-else-if="form.vacationTypeCode === 'TXJ'">
      按加班时长<CbNumber :disabled="true" defult-val="1" class="number-input" />:
      <CbNumber v-model="form.overtimeConvertScale" class="number-input" :decimal-separator="true" />补偿调休时长，自动发放
    </div>
  </div>
</template>

<script>
import { getTenantCode } from '@/services/dropDownInput/index.js'
export default {
  name: 'AutomaticRules',
  components: {
    CbNumber: () => import('@/components/CbNumber/index')
  },
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      radioStyle: {
        display: 'block',
        lineHeight: '35px'
      },
      inlineRadioStyle: {
        display: 'inline-block',
        lineHeight: '35px'
      },
      grantRuleList: []
    }
  },
  created() {
    this.getGrantRule()
  },
  methods: {
    // 获取自动发放规则
    async getGrantRule() {
      const grant = await getTenantCode({ codeName: 'vacation_grant_rule_type' })
      this.grantRuleList = grant.data
    },
    change(value, index) {
      if (this.form.amountSteps[index + 1]) {
        this.form.amountSteps[index + 1].startValue = value
      }
    }
  }
}
</script>

<style lang="less" scoped>
.AutomaticRules {
  &-content{
    margin-bottom: 10px;
  }
  .small-input {
    width: 50px;
    margin: 0 7px;
    display: inline-block;
  }
  .number-input {
    width: 68px;
    margin: 0 7px;
    display: inline-block;
  }
  .large-input {
    width: 161px;
    margin: 0 7px;
    display: inline-block;
  }
  .increas-input{
    width: 90px;
    margin: 0 7px;
    display: inline-block;
  }
  .margin-left-none {
    margin-left: 0px;
  }
  .set-rules {
    width: auto;
    background: @layout-body-background;
    padding: 4px 16px;
    line-height: 52px;
    .iconBtn{
      background: #fff;
      margin-bottom: 3px;
    }
    .deleted:hover{
      border-color: @sc-error-100;
      /deep/.iconpark-icon{
        color: @sc-error-100;
      }
    }
  }
  /deep/.ant-form-item {
    margin-bottom: 15px;
  }
  /deep/.ant-form-item-label {
    font-weight: 600;
    width: 100%;
  }
  .grantFixedDays{
    color: @sc-grey-80;
  }
}
</style>
