<template>
  <div class="container">
    <Modal :visible="show" closable type="info" :mask-closable="true" title="立即升级" size="small" @cancel="show=false">
      <p>请联系集团管理员进行员工容量升级。</p>
      <template slot="footer">
        <a-button type="primary" @click="hideModal">知道了</a-button>
      </template>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'UpgradeAdministrators',
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      show: false,
      modalImg: require('@/assets/img/global/info-circle-filled.png')
    }
  },
  methods: {
    open() {
      this.show = true
    },
    hideModal() {
      this.show = false
    }
  }
}
</script>
<style scoped lang="less">
p {
  color: @sc-grey-80;
  margin-bottom: 0;
}
</style>
