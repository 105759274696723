<!--
 * @Date: 2022-07-04 14:19:13
 * @descriotion:档案
-->
<template>
  <div class="box">
    <div v-if="!showImport">
      <div class="box-head">
        <div
          v-for="(item, index) in tabList"
          :key="index"
          class="box-head-list"
          :class="active === item.key ? 'active' : ''"
          @click="active = item.key"
        >
          <div class="data">{{ item.num }}</div>
          <div class="title">{{ item.title }}</div>
        </div>
      </div>
      <div>
        <archivesTable ref="table" :tab-key="active" @getImportCallback="getImportCallback" @callBackBatch="callBackBatch" />
      </div>
    </div>
    <ExcelImport v-else title="导入参保档案" import-template="socialSecurityArchives" @goBack="toggleValue" />
  </div>
</template>
<script>
import archivesTable from './table.vue'
import { getInsureStatusCountApi } from '@/services/insurance/archives'
export default {
  name: 'Archives',
  components: {
    archivesTable,
    ExcelImport: () => import('@/components/CbBatchImport/newImport.vue')
  },
  data() {
    return {
      showImport: false,
      active: '1',
      tabList: [
        {
          key: '1',
          num: '-',
          title: '正常在缴'
        },
        {
          key: '2',
          num: '-',
          title: '待投保'
        },
        {
          key: '3',
          num: '-',
          title: '待停保'
        },
        {
          key: '4',
          num: '-',
          title: '停保'
        }
      ]
    }
  },
  watch: {
    $route: {
      handler(to, from) {
        if (from.path === '/socialSecurity/securityArchivesDetail') {
          return
        }
        if (to.path === '/socialSecurity/securityArchives') {
          this.active = '1'
          this.getInsureStatusCount()
          this.$refs.table.getTenantBillStatus()
        }
      }
    }
  },
  created() {
    // 首页跳转参数判断
    if (this.$route.params.isHome === 'toBeInsured') {
      // 待投保
      this.active = '2'
    } else if (this.$route.params.isHome === 'toBeStopped') {
      // 待停保
      this.active = '3'
    }
    this.getInsureStatusCount()
  },
  methods: {
    // 在缴，待投保，待停保和停保的数量统计
    getInsureStatusCount() {
      getInsureStatusCountApi().then((res) => {
        if (res.code === 100) {
          this.tabList[0].num = res.data.insureNum
          this.tabList[1].num = res.data.waitInsureNum
          this.tabList[2].num = res.data.waitStopInsureNum
          this.tabList[3].num = res.data.stopInsureNum
        } else {
          this.tabList[0].num = '-'
          this.tabList[1].num = '-'
          this.tabList[2].num = '-'
          this.tabList[3].num = '-'
        }
      })
    },
    // 展示导入页回调
    getImportCallback() {
      this.showImport = true
    },
    // 导入
    toggleValue() {
      this.showImport = false
    },
    callBackBatch() {
      this.getInsureStatusCount()
    }
  }
}
</script>
<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff;
  padding: 20px;
  .box-head {
    width: 100%;
    height: 88px;
    border-radius: 7px;
    border: 1px solid @sc-greyBg-20;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 16px;
    .box-head-list {
      cursor: pointer;
      width: 25%;
      height: 55px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      border-right: 1px solid @sc-greyBg-20;
      &:nth-child(4) {
        border: 0;
      }
      &:hover {
        .data,
        .title {
          color: @sc-primary-100;
        }
      }
    }
  }
}
.data {
  text-align: center;
  width: 100%;
  font-size: 20px;
  color: @sc-grey-100;
}
.title {
  font-size: 16px;
  color: @sc-grey-60;
}
.active {
  .data,
  .title {
    color: @sc-primary-100;
  }
}
</style>
