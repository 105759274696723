<template>
  <div class="switch_group_box">
    <div v-for="item, index of switchGroup" :key="index" class="switch_child">
      <switchForm :switch-title="item.switchTitle" :type="item.type" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EntryInsurance',
  components: {
    switchForm: () => import('./switchForm.vue')
  },
  data() {
    return {
      switchGroup: [
        {
          switchTitle: '社保',
          type: 'social'
        },
        {
          switchTitle: '医保',
          type: 'medical'
        },
        {
          switchTitle: '公积金',
          type: 'housing'
        }
      ]
    }
  }

}
</script>

<style lang="less" scoped>
  .switch_group_box{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .switch_child{
      margin: 10px 0;
    }
  }
</style>
