<template>
  <div class="Details">
    <!--  导航  -->
    <HeaderBack :title="`${form.year||''}年年终奖`" @back="$emit('goBack')" />
    <!-- 数据统计 -->
    <CbStatistics :data-list="form.list" length="4" :is-function="true" class="statistics">
      <template slot="right">
        <a-button :prefix="form.locked ? 'unlock' : 'lock' " @click="changeBounsStatus">{{ form.locked?'数据解锁':'数据锁定' }}</a-button>
      </template>
    </CbStatistics>
    <!--  添加 搜索 筛选  -->
    <appForm
      :to-list="formList"
      class="v-line-button"
      :screen-roster-list="screenRosterList"
      @screenRosterParams="screenRosterParams"
      @getSearchCallback="getSearchCallback"
      @exportFileList="exportFileList"
    >
      <template slot="customButton">
        <a-space>
          <a-button v-if="!showBatchCom" :disabled="!!form.locked" prefix="piliangdaoru" @click="showBatch"> 批量导入 </a-button>
          <a-button v-else :disabled="!!form.locked" prefix="left" @click="showBatch"> 返回表格 </a-button>
          <a-button :disabled="!!form.locked" prefix="bendijisuan" @click="calculate"> 本地计算 </a-button>
        </a-space>
      </template>
    </appForm>
    <custom-table
      v-if="!showBatchCom"
      :columns="columns"
      :data-source="dataSource"
      :row-key="'id'"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="loadDataList"
    >
      <!-- 序号 -->
      <template slot="index" slot-scope="{ scope }">
        <span>{{ scope.index + 1 }}</span>
      </template>
      <template slot="staffName" slot-scope="{ scope }">
        <CbTableAvater
          :on-job-id="scope.record.onJobId"
          :staff-id="scope.record.staffId"
          :table-name="scope.record.staffName"
        />
      </template>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{ scope }">
        <div class="flex-align-center">
          <div class="content-btn-box">
            <a-button type="link" @click="edit(scope.record)">编辑</a-button>
            <a-button type="link" class="delete" @click="delet(scope.record)">删除</a-button>
          </div>
          <!-- <a-popover placement="bottom" trigger="hover">
            <template slot="content">
              <div class="more-handle-btn">
                <a-button type="link" @click="edit(scope.record)"> 编辑 </a-button>
                <a-button type="link" class="delete" @click="delet(scope.record)"> 删除 </a-button>
              </div>
            </template>
            <div class="more-handle">···</div>
          </a-popover> -->
        </div>
      </template>
    </custom-table>
    <ImportBatch v-else import-template="yearBouns" :title-show="false" :bouns-id="yearendBounsId" @getStaffStatistics="getStaffStatistics" />
    <!-- 添加年终奖 -->
    <UpdateYearendBouns ref="UpdateYearendBouns" :row="row" />
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import { getStaffYearendBouns, deleteStaffYearendBouns, getYearendBouns, lockYearendBouns, unlockYearendBouns, calculateYearendBounsTax, exportStaffYearendBouns } from '@/services/wage/yearendBouns.js'
import { debounce } from '@/utils/index.js'
export default {
  name: 'ArchivesTabel',
  components: {
    HeaderBack: () => import('@/components/headerBack/index.vue'),
    CbTableAvater: () => import ('@/components/CbTableAvater/index.vue'),
    UpdateYearendBouns: () => import('./components/updateYearendBouns.vue'),
    ImportBatch: () => import('@/components/CbBatchImport/newImport.vue'),
    CbStatistics: () => import('@/components/CbStatistics/index.vue')
  },
  mixins: [mixins],
  props: {
    yearendBounsId: {
      type: [String, Number],
      default: undefined
    }
  },
  data() {
    return {
      formList: ['search', 'export', 'screen'],
      staffYearendBounsId: '',
      form: {},
      row: {},
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'index' },
          width: '50px'
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          scopedSlots: { customRender: 'staffName' },
          width: '105px'
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          width: '100px',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          width: '100px',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          width: '100px',
          ellipsis: true
        },
        {
          title: '计税方式',
          dataIndex: 'taxCalcMethodName',
          key: 'taxCalcMethodName',
          ellipsis: true
        },
        {
          title: '年终奖',
          dataIndex: 'bounsAmount',
          key: 'bounsAmount',
          dataType: 'money'
        },
        {
          title: '应纳税额',
          dataIndex: 'taxAmount',
          key: 'taxAmount',
          dataType: 'money'
        },
        {
          title: '实发年终奖',
          dataIndex: 'realBounsAmount',
          // scopedSlots: { customRender: 'realBounsAmount' },
          key: 'realBounsAmount',
          dataType: 'money'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '100px'
        }
      ],
      // 数据
      dataSource: [],
      showBatchCom: false,
      screenRosterList: {
        list: [
          {
            type: 'selectDictionaries',
            label: '聘用形式',
            options: {
              width: '100%',
              parameter: 'engage_mode',
              clearable: true,
              placeholder: '请选择职级'
            },
            model: 'engageMode',
            key: 'selectDictionaries_1651022813841'
          },
          {
            type: 'selectDepartment',
            label: '部门',
            options: {
              width: '100%',
              clearable: true,
              placeholder: '请选择'
            },
            model: 'orgId',
            key: 'selectDepartment_1651022903831'
          },
          {
            type: 'selectPost',
            label: '岗位',
            options: {
              width: '100%',
              clearable: true,
              placeholder: '请选择'
            },
            model: 'postId',
            key: 'selectPost_1651022948111'
          },
          {
            'type': 'selectDictionaries',
            'label': '合同公司',
            'options': {
              'width': '100%',
              'parameter': 'contract_company',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'contractCompany',
            'key': 'selectDictionaries_1650962628706'
          },
          {
            'type': 'selectDictionaries',
            'label': '工作地点',
            'options': {
              'width': '100%',
              'parameter': 'work_address',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'workAddress',
            'key': 'selectDictionaries_1650962628701'
          },
          {
            'type': 'selectDictionaries',
            'label': '员工状态',
            'options': {
              'width': '100%',
              'parameter': 'staff_status',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'staffStatus',
            'key': 'selectDictionaries_1650962628702'
          }
        ],
        config: {
          layout: 'horizontal',
          labelCol: {
            xs: 4,
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
            xxl: 4
          },
          labelWidth: 100,
          labelLayout: 'flex',
          wrapperCol: {
            xs: 18,
            sm: 18,
            md: 18,
            lg: 18,
            xl: 18,
            xxl: 18
          },
          hideRequiredMark: false,
          customStyle: ''
        }
      }
    }
  },
  methods: {
    // 获取年终奖发放人员列表数据
    async fetchDataApiFn(param) {
      const res = await getStaffYearendBouns({ ...param, yearendBounsId: this.yearendBounsId })
      this.dataSource = res.data.records
      this.pager.currentPage = res.data.current || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
      this.total = res.data.total
      this.getDetail()
    },
    // 查询年终奖详情
    async getDetail() {
      const res = await getYearendBouns({ yearendBounsId: this.yearendBounsId })
      this.form = res.data
      if (res.data) {
        this.form.list = [{
          label: '总人数',
          num: res.data.staffNum
        }, {
          label: '应发年终奖',
          num: res.data.actualMount || 0,
          unit: '元',
          isMoney: true
        }, {
          label: '实发年终奖',
          num: res.data.factoryMount || 0,
          unit: '元',
          isMoney: true
        }, {
          label: '应纳税额',
          num: res.data.taxMount || 0,
          unit: '元',
          isMoney: true
        }]
      }
    },
    // 锁定年终奖
    async changeBounsStatus() {
      this.$Reconfirm({
        title: `年终奖${this.form.locked ? '数据解锁' : '数据锁定'}`,
        content: `是否确定${this.form.locked ? '解锁' : '锁定'}该年终奖？`
      }).then(() => {
        if (!this.form.locked) {
          this.submitLock()
        } else {
          this.submitUnLock()
        }
        this.handleChange()
      }).catch(() => {
        console.log('no')
      })
    },
    // 锁定年终奖
    async submitLock() {
      const res = await lockYearendBouns({ yearendBounsId: this.yearendBounsId })
      if (res.code === 100) {
        this.$message.success('已锁定！')
        this.getDetail()
      }
    },
    // 解锁年终奖
    async submitUnLock() {
      const res = await unlockYearendBouns({ yearendBounsId: this.yearendBounsId })
      if (res.code === 100) {
        this.$message.success('已解锁！')
        this.getDetail()
      }
    },
    // 计算年终奖税额
    async calculate() {
      const res = await calculateYearendBounsTax({ yearendBounsId: this.yearendBounsId })
      if (res.code === 100) {
        this.$message.success('计算成功！')
        this.serchGetData()
      }
    },
    // 搜索
    getSearchCallback(data) {
      this._queryParam.inputValue = data.searchValue
      this.serchGetData()
    },
    // 筛选
    screenRosterParams(data) {
      const { engageMode, orgId, postId, contractCompany, workAddress, staffStatus } = data.screenCondition
      this._queryParam = {
        staffName: data.searchValue,
        engageMode,
        orgId: orgId && orgId[0] && orgId[0].value,
        postId,
        contractCompany,
        workAddress,
        staffStatus
      }
      this.serchGetData()
    },
    async exportFileList() {
      const params = JSON.parse(JSON.stringify(this._queryParam))
      delete params.pageNum
      delete params.pageSize
      await exportStaffYearendBouns({ ...params, yearendBounsId: this.yearendBounsId })
    },
    // 编辑
    edit(row) {
      // 可编辑字段仅有“年终奖”一列，编辑后，需重新计算应纳税额与实发年终奖数据。
      this.row = { ...row }
      this.$refs.UpdateYearendBouns.visible = true
    },
    // 删除
    delet(record) {
      this.staffYearendBounsId = record.staffYearendBounsId
      this.$Reconfirm({
        title: '删除人员',
        content: '确定要删除该人员的年终奖数据吗?'
      }).then(() => {
        this.submitDel()
      }).catch(() => {
        console.log('no')
      })
    },
    // 确认删除
    submitDel: debounce(async function() {
      const res = await deleteStaffYearendBouns({ staffYearendBounsId: this.staffYearendBounsId })
      if (res.code === 100) {
        this.$message.success('删除成功！')
        this.serchGetData()
      }
    }, 500),
    showBatch() {
      this.showBatchCom = !this.showBatchCom
    },
    getStaffStatistics() {
      this.fetchDataApiFn(this.pager)
      this.showBatchCom = false
    }
  }
}
</script>

<style lang="less" scoped>
.Details {
  .header {
    margin-bottom: 20px;
  }
}
.more-handle-btn {
    display: flex;
    flex-direction: column;
  }
.more-handle {
  font-weight: 700;
  font-size: 20px;
  vertical-align: baseline;
  cursor: pointer;
  color: @sc-primary-100;
  margin-left: 6px;
}
.v-line-button{
  margin-bottom: 16px;
}
/deep/.anticon-lock{
      color: @sc-primary-100;
    }
  /deep/.upload-file{
  padding: 0 !important;
}
.content-btn-box{
  display: flex;
  button{
    padding-left: 0;
    padding-right: 16px;
  }
}
</style>
