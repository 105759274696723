<template>
  <div class="LogicFlow">
    <template v-if="formVisible">
      <div class="logicMain">
        <a-tabs :default-active-key="defaultActiveKey" @change="callback">
          <template v-for="(key, index) in tabList">
            <a-tab-pane :key="index" :tab="key.groupName" />
          </template>
          <template #tabBarExtraContent>
            <a-button class="editBtn" icon="edit" @click="categoryBlock = true">
              审批分类管理
            </a-button>
          </template>
        </a-tabs>
        <appForm v-if="tabList.length > 0" :form-list="formList">
          <template slot="customButton">
            <a-space>
              <a-button type="primary" prefix="file-addition-one" @click="addprocess(false)">
                {{ fucName }}
              </a-button>
              <a-button v-if="env === 'development'" type="primary" prefix="file-addition-one" @click="addprocess(true)">
                {{ fucName }}【内置流程】
              </a-button>
            </a-space>
          </template>
        </appForm>
        <custom-table :columns="columns" :data-source="dataSource" table-ref=".logicMain" row-key="flowId" :pagination="false" :loading="loading" @change="loadDataList">
          <span slot="processName" slot-scope="{ scope }">
            <div>
              <img :src="`${iconBasePath}icons/${scope.record.icon}.png`" class="flowIcon" alt="" srcset="">
              {{ scope.record.flowName }}
            </div>
          </span>
          <span slot="switchStatus" slot-scope="{ scope }">
            <div>
              <a-switch
                size="small"
                :default-checked="Boolean(scope.record.status)"
                @change="onChange(...arguments, scope)"
              />
            </div>
          </span>
          <!-- 操作列 -->
          <template slot="operation" slot-scope="{ scope }">
            <!-- <a-popover placement="bottom" trigger="hover">
              <template slot="content">
                <div class="more-handle-btn">
                  <a-button type="link" @click="edit(scope.record)"> 编辑 </a-button>
                  <a-button v-if="!scope.record.predefine" type="link" @click="copy(scope.record)"> 复制 </a-button>
                  <a-button v-if="!scope.record.predefine" type="link" class="delete" danger @click="delet(scope.record)"> 删除 </a-button>
                </div>
              </template>
              <div class="more-handle">···</div>
            </a-popover> -->
            <div class="operation-btn-box">
              <a-button type="link" @click="edit(scope.record)"> 编辑 </a-button>
              <a-popover v-if="!scope.record.predefine" placement="bottom" trigger="hover">
                <template slot="content">
                  <div class="more-handle-btn">
                    <a-button v-if="!scope.record.predefine" type="link" @click="copy(scope.record)"> 复制 </a-button>
                    <a-button v-if="!scope.record.predefine" type="link" class="delete" danger @click="delet(scope.record)"> 删除 </a-button>
                  </div>
                </template>
                <div class="more-handle">···</div>
              </a-popover>
            </div>
          </template>
        </custom-table>
      </div>
    </template>
    <div v-else class="processDesign">
      <Designer
        ref="designer"
        :group-list="tabList"
        :active-flow-group-id="activeFlowGroupId"
        :echo-data="echoData"
        :flow-id="flowId"
        @refresh="refresh"
        @backForm="backForm"
      />
    </div>
    <div v-if="categoryBlock" class="categoryBlock">
      <AppCategory
        :group-list="tabList"
        @back="
          categoryBlock = false
          init()
        "
        @upFlowGroup="upFlowGroup"
      />
    </div>
  </div>
</template>

<script>
import Designer from '../component/engine/index'
import CustomTable from '@/components/CustomTable/index.js'
import AppCategory from './appCategoryl/index'
// import CreateApproval from '../createApproval.vue'
import { mapMutations } from 'vuex'
import { getFlowGroupList, listFlow, deleteFlow, getFlowDesign, updateFlowStatus } from '@/services/logicFlow'
export default {
  name: 'LogicFlow',
  components: {
    // CreateApproval,
    Designer,
    AppCategory
  },
  mixins: [CustomTable],
  data() {
    return {
      env: process.env.VUE_APP_ENV,
      iconBasePath: process.env.VUE_APP_APP_STATIC,
      lazyInitData: true,
      defaultActiveKey: '1',
      tabList: [],
      dataSource: [],
      formList: ['screen'], // search搜索框 screen筛选 choice位置选择弹窗 export导出
      formVisible: true,
      tapsIndex: 0,
      categoryBlock: false,
      flowId: '',
      echoData: null
    }
  },
  computed: {
    fucName() {
      return this.tabList.length > 0 ? `新建${this.tabList[this.tapsIndex].groupName}` : ''
    },
    activeFlowGroupId() {
      return this.tabList.length > 0 ? this.tabList[this.tapsIndex].flowGroupId : ''
    },
    columns() {
      const columnsList = [
        {
          title: '审批名称',
          dataIndex: 'flowName',
          key: 'flowName',
          scopedSlots: { customRender: 'processName' },
          ellipsis: true
        },
        {
          title: '审批说明',
          dataIndex: 'flowRemark',
          key: 'flowRemark',
          ellipsis: true
        },
        {
          title: '发起人范围',
          dataIndex: 'initiatorScopeName',
          key: 'initiatorScopeName'
        },
        {
          title: '启用/禁用',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'switchStatus' },
          width: '300px'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '100px'
        }
      ]
      return columnsList
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapMutations('designer', ['setPredefine']),
    async onChange(value, e, info) {
      await updateFlowStatus({ flowId: info.record.flowId, status: value ? 1 : 0 })
      this.$message.success('状态更新成功')
      this.$$fetchListWithLoading(this._queryParam)
    },
    upFlowGroup() {
      this.init()
    },
    async init() {
      const res = await getFlowGroupList()
      this.tabList = res.data
      this.$$fetchListWithLoading(this._queryParam)
    },
    async fetchDataApiFn(_queryParam) {
      const res = await listFlow({ ..._queryParam, flowGroupId: this.activeFlowGroupId })
      const obj = {
        assignperson: '指定成员',
        all: '全员'
      }
      this.dataSource = res.data.map((item) => {
        return {
          ...item,
          initiatorScopeName: obj[item.initiatorScope]
        }
      })
    },
    onStartChange() {
      console.log(arguments)
    },
    addprocess(status) {
      this.flowId = undefined
      this.echoData = null
      this.formVisible = false
      this.setPredefine(status)
    },
    backForm(val) {
      this.formVisible = true
      this.callback(this.tapsIndex)
    },
    refresh() {
      this.setPredefine(false)
      this.formVisible = true
      this.$$fetchListWithLoading(this._queryParam)
    },
    callback(activeKey) {
      this.tapsIndex = activeKey
      this.defaultActiveKey = activeKey
      this.serchGetData()
    },
    async edit(info) {
      this.flowId = info.flowId
      const res = await getFlowDesign({ flowId: info.flowId })
      this.formateData(res)
      this.formVisible = false
      this.setPredefine(info.predefine)
    },
    async copy(info) {
      this.flowId = undefined
      const res = await getFlowDesign({ flowId: info.flowId })
      res.data.flowSetting.flowName = ''
      res.data.flowSetting.flowId = undefined
      this.formateData(res)
      this.formVisible = false
      this.setPredefine(info.predefine)
    },
    formateData(res) {
      const { flowSetting, flowDesign, formDesign } = res.data
      const { flowCode, flowGroupId, flowName, flowRemark, icon,
        initiatorScope, predefine, approveDuplicate, approveCopy,
        opinionRequired, initiatorList, cancelApproved, signRequired } = flowSetting
      // 基础信息回显的值
      this.echoData = {}
      this.echoData['basicSetting'] = {
        flowCode,
        flowGroupId,
        flowName,
        flowRemark,
        icon,
        initiatorScope,
        predefine,
        initiatorList: initiatorList.map((item) => {
          item.onJobId = item.initiatorId
          item.staffName = item.initiatorName
          return item
        })
      }
      this.echoData['adSetting'] = {
        approveDuplicate,
        approveCopy,
        opinionRequired,
        cancelApproved,
        signRequired
      }
      if (flowDesign.children.ccConfig.ccParticipants[0] && flowDesign.children.ccConfig.ccParticipants[0].initiatorParticipants) {
        const { participants } = flowDesign.children.ccConfig.ccParticipants[0].initiatorParticipants
        flowDesign.children.ccConfig.ccParticipants[0].initiatorParticipants.participants = participants.map((item) => {
          item.onJobId = item.id = item.participant
          item.staffName = item.participantName
          return item
        })
      }
      if (flowDesign.children.assigneeConfig.assigneeParticipants[0] && flowDesign.children.assigneeConfig.assigneeParticipants[0].initiatorParticipants) {
        const { participants } = flowDesign.children.assigneeConfig.assigneeParticipants[0].initiatorParticipants
        flowDesign.children.assigneeConfig.assigneeParticipants[0].initiatorParticipants.participants = participants.map((item) => {
          item.onJobId = item.id = item.participant
          item.staffName = item.participantName
          return item
        })
      }
      this.echoData['flowDesign'] = flowDesign
      this.echoData['formDesign'] = formDesign
    },
    delet(data) {
      this.flowId = data.flowId
      this.$Reconfirm({
        title: '删除审批',
        content: `确定删除【${data.flowName}】审批吗？`
      }).then(async() => {
        const { flowId } = this
        const { code } = await deleteFlow({ flowId })
        if (code !== 100) return
        this.$message.success('删除成功')
        this.fetchDataApiFn()
      }).catch(() => {
        console.log('no')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.flowIcon{
  width: 30px;
}
.LogicFlow {
  height: 100%;
  background-color: #fff;
  .logicMain{
    padding: 20px 20px 0;
    background-color: #fff;
  }
}
.processDesign{
  height: 100%;
}
.search {
  display: flex;
}
.categoryBlock {
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
}
.more-handle-btn {
  display: flex;
  flex-direction: column;
  .ant-btn {
    height: 25px;
  }
}
.more-handle {
  font-weight: 700;
  font-size: 20px;
  vertical-align: baseline;
  cursor: pointer;
  color: @sc-primary-100;
}
/deep/ .ant-tabs-extra-content {
  line-height: 28px;
}
/deep/ .ant-tabs-nav-scroll {
  margin-top: -3px;
}
/deep/ .ant-tabs-tab {
  color: @sc-grey-100;
  padding-top: 0;
  font-size: 16px;
  &:hover {
    color: @sc-primary-100;
  }
}
/deep/ .ant-tabs-tab-active {
  color: @sc-primary-100;
}
/deep/ .ant-switch {
  width: 30px;
  margin-left: 14px;
}
/deep/ .ant-space-item {
  margin-right: 12px!important;
}
.operation-btn-box{
  display:flex;
  button{
    padding-left:0;
    padding-right: 16px;
  }
}
</style>
