<!--
 * @Date: 2022-04-27 15:17:46
 * @descriotion:
-->
<template>
  <div class="setting-container">
    <BlockTitle title="自动打卡设置" :is-bold="true" class="blocktitle" />
    <div class="content">
      <!-- <a-checkbox :checked="form.entryAutoPunchFlag?true:false" @change="onChange('entryAutoPunchFlag')">
        员工入职当天，自动补一个上班卡
      </a-checkbox>
      <br> -->
      <a-checkbox :checked="form.leaveAutoPunchFlag?true:false" @change="onChange('leaveAutoPunchFlag')">
        员工离职当天，自动补一个下班卡
      </a-checkbox>
    </div>
    <BlockTitle title="上下班打卡" :is-bold="true" class="blocktitle" />
    <div class="applets">
      <span>打卡条件</span>
      <a-select v-model="form.punchRuleType" style="width:230px" @change="changeCondition">
        <a-select-option v-for="item in punch_rule_type" :key="item.label" :value="item.value">
          {{ item.label }}
        </a-select-option>
      </a-select>
    </div>
    <div v-show="form.punchRuleType !=='2'" class="applets">
      <span>关联打卡地址</span>
      <a-button class="commonIcon" prefix="add-one" @click="showAddress = true;outSideFlag = false">
        <span class="addressIcon">选择地址</span>
      </a-button>
      <div v-for="(item,i) in addressList" :key="item.clockLocationId" class="punch">
        <p class="name">{{ item.locationName }}</p>
        <p class="text">{{ item.gpsName }}</p>
        <div class="delete">
          <svg class="iconpark-icon" @click="delAddress(i)"><use href="#delete" /></svg>
        </div>
      </div>
    </div>
    <div v-show="form.punchRuleType !=='1'" class="applets">
      <span>关联打卡WIFI</span>
      <a-button class="commonIcon" prefix="add-one" @click="showWifi = true">
        <span class="addressIcon">选择WIFI</span>
      </a-button>
      <div v-for="(item,i) in wifiList" :key="item.clockWifiId" class="punch">
        <p class="name">{{ item.wifiName }}</p>
        <p class="text">{{ item.wifiBssId }}</p>
        <div class="delete">
          <svg class="iconpark-icon" @click="deleteWifi(i)"><use href="#delete" /></svg>
        </div>
      </div>
    </div>
    <BlockTitle title="外勤打卡" :is-bold="true" class="blocktitle" />
    <a-checkbox :checked="form.allowOutSidePunchFlag?true:false" @change="onChange('allowOutSidePunchFlag')">
      外勤打卡无需提交出差或者外出审批
    </a-checkbox>
    <br>
    <!-- <a-checkbox :checked="form.outSideUploadPhotoFlag?true:false" @change="onChange('outSideUploadPhotoFlag')">
      员工必须上传图片
    </a-checkbox>
    <br> -->
    <a-checkbox :checked="form.outSideTakePhotoFlag?true:false" @change="onChange('outSideTakePhotoFlag')">
      员工必须拍照上传
    </a-checkbox>
    <br>
    <a-checkbox :checked="form.outSideLimitLocationFlag?true:false" @change="onChange('outSideLimitLocationFlag');outSideList=[]">
      有指定的外勤打卡地址
    </a-checkbox>
    <br>
    <div v-if="form.outSideLimitLocationFlag">
      <a-button class="chooseAddress" prefix="add-one" @click="showOut=true">
        <span class="address">选择地址</span>
      </a-button>
      <div v-for="(item,i) in outSideList" :key="i" class="punch notFlag">
        <p class="name">{{ item.locationName }}</p>
        <p class="text">{{ item.gpsName }}</p>
        <div class="delete">
          <svg class="iconpark-icon" @click="deloutSideList(i)"><use href="#delete" /></svg>
        </div>
      </div>
    </div>

    <checkPunch type="address" :show="showAddress" :options="addressList" @cancel="cancel" @address="getAddressList" />
    <checkPunch type="wifi" :show="showWifi" :options="wifiList" @cancel="cancel" @address="getWifiList" />
    <checkPunch type="address" :show="showOut" :out-side-flag="true" :options="outSideList" @cancel="cancel" @address="getoutSideList" />
  </div>
</template>
<script>
import { punch_rule_type } from '@/pages/attendance/dict.js'
export default {
  name: 'ClockSetting',
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index'),
    checkPunch: () => import('../../components/checkAddress.vue')
  },
  props: {
    formdata: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checked: true,
      punch_rule_type: punch_rule_type,
      form: {},
      showAddress: false,
      showWifi: false,
      showOut: false,
      addressList: [],
      wifiList: [],
      outSideList: [],
      isVaild: true
    }
  },
  watch: {
    formdata: {
      immediate: true,
      handler(value) {
        console.log(value)
        this.isVaild = value.attendanceGroupType === '2'
        const { entryAutoPunchFlag, leaveAutoPunchFlag, punchRuleType, allowOutSidePunchFlag, outSideUploadPhotoFlag, outSideTakePhotoFlag, outSideLimitLocationFlag, clockLocation, clockWifi } = value
        if (clockLocation && clockLocation.length > 0) {
          if (this.addressList.length < 1) {
            this.addressList = clockLocation.filter(item => !item.outSideFlag)
          }
          if (this.outSideList.length < 1) {
            this.outSideList = clockLocation.filter(item => item.outSideFlag)
          }
        }
        if (clockWifi && clockWifi.length > 0) {
          if (this.wifiList.length < 1) {
            this.wifiList = clockWifi
          }
        }
        this.form = {
          entryAutoPunchFlag,
          leaveAutoPunchFlag,
          punchRuleType,
          allowOutSidePunchFlag,
          outSideUploadPhotoFlag,
          outSideTakePhotoFlag,
          outSideLimitLocationFlag,
          attendanceGroupClockLocation: [...this.addressList, ...this.outSideList],
          attendanceGroupClockWifi: this.wifiList
        }
        if (this.punchRuleType === '1') {
          this.wifiList = []
        } else if (this.punchRuleType === '2') {
          this.addressList = []
        }
      }
    }
  },
  mounted() {
  },
  methods: {
    onChange(str) {
      if (this.form[str] === 1) {
        this.form[str] = 0
      } else {
        this.form[str] = 1
      }
    },
    // 更改打卡条件
    changeCondition(value) {
      if (value === '1') {
        this.wifiList = []
      } else if (value === '2') {
        this.addressList = []
      }
    },
    methodName() {

    },
    cancel() {
      this.showAddress = false
      this.showWifi = false
      this.showOut = false
    },
    getAddressList(list) {
      this.showAddress = false
      this.addressList = list
    },
    getWifiList(list) {
      this.showWifi = false
      this.wifiList = list
    },
    getoutSideList(list) {
      this.showOut = false
      this.$nextTick(() => {
        this.outSideList = list
      })
    },
    next() {
      this.form.attendanceGroupClockWifi = []
      this.form.attendanceGroupClockLocation = []
      if (this.isVaild) {
        if (this.form.outSideLimitLocationFlag && this.outSideList.length < 1) {
          this.$message.info('请先选择外勤打卡地址')
          return false
        }
        if (this.form.punchRuleType === '1') {
          if (this.addressList.length < 1) {
            this.$message.info('请先选择关联打卡地址')
            return false
          }
        } else if (this.form.punchRuleType === '2') {
          if (this.wifiList.length < 1) {
            this.$message.info('请先选择关联打卡wifi')
            return false
          }
        } else if (this.form.punchRuleType === '3') {
          if (this.wifiList.length < 1 && this.addressList.length < 1) {
            this.$message.info('请先选择关联打卡地址或关联打卡wifi')
            return false
          }
        } else {
          if (this.wifiList.length < 1 || this.addressList.length < 1) {
            this.$message.info('请先选择关联打卡地址和关联打卡wifi')
            return false
          }
        }
      }
      this.wifiList.map(item => {
        this.form.attendanceGroupClockWifi.push({ clockWifiId: item.clockWifiId })
      })
      this.addressList.map(item => {
        this.form.attendanceGroupClockLocation.push({ clockLocationId: item.clockLocationId, outSideFlag: item.outSideFlag })
      })
      this.outSideList.map(item => {
        this.form.attendanceGroupClockLocation.push({ clockLocationId: item.clockLocationId, outSideFlag: item.outSideFlag })
      })
      this.$emit('getData', this.form)
      return true
    },
    deleteWifi(index) {
      this.wifiList.splice(index, 1)
    },
    delAddress(index) {
      this.addressList.splice(index, 1)
    },
    deloutSideList(index) {
      this.outSideList.splice(index, 1)
    }
  }

}
</script>
<style lang="less" scoped>
p{
  margin-bottom:0;
}
.setting-container{
    width:100%;
    padding:20px 43px 80px;
    height:100%;
    overflow: auto;
    .blocktitle{
      margin: 24px 0 16px;
    }
    .blocktitle:nth-child(1) {
      margin-top: 0;
    }
    .ant-checkbox-wrapper{
      margin: 5px 0;
      color: @sc-grey-80;
    }
    .applets{
      margin-bottom:24px;
      span{
        display: inline-block;
        width:120px;
        font-size: 14px;
        color: @sc-grey-80;
      }
      .addressIcon {
        width: 62px;
        color: @sc-grey-100;
      }
    }
}
.steps-action{
  width:100%;
  .btns{
    display: flex;
    justify-content: center;

  }
}
.punch{
  width:413px;
  margin: 14px 0 0 120px;
  border:1px solid @sc-grey-20;
  padding:13px 14px 11px;
  border-radius: 4px;
  background: @sc-greyBg-10;
  position:relative;
  .name{
    font-size:16px;
    font-weight: normal;
    color:@sc-grey-100;
    margin-bottom:8px;
  }
  .text{
    font-size:14px;
    color: @sc-grey-60
  }
  .delete{
    position:absolute;
    width: 15px;
    height: 15px;
    top:13px;
    right:14px;
    cursor: pointer;
    color: @sc-primary-100;
    .iconpark-icon {
      width: 100%;
      height: 100%;
    }
  }
}
.notFlag{
  margin: 16px 0 0 0;
}
.chooseAddress {
  margin-top: 16px;
  display: flex;
  align-items: center;
  .address {
    color: @sc-grey-80;
  }
  &:hover .address {
    color: @sc-primary-100;
  }
}
.commonIcon:hover .addressIcon {
  color: @sc-primary-100;
}
</style>
