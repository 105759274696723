<template>
  <div class="table-container">
    <BlockTitle title="近一年计薪周期展示" :is-bold="true" class="table-title" />
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      row-key="payPeriod"
      :pagination="false"
      @change="loadDataList"
    >
      <!-- 计薪月 -->
      <span slot="payPeriod" slot-scope="{scope}">
        {{ moment(scope.record.payPeriod).format('YYYY年MM月') }}
      </span>
      <!-- 开始日期 -->
      <span slot="startDate" slot-scope="{scope}">
        {{ moment(scope.record.startDate).format('YYYY-MM-DD') }}
      </span>
      <!-- 结束日期 -->
      <span slot="endDate" slot-scope="{scope}">
        {{ moment(scope.record.endDate).format('YYYY-MM-DD') }}
      </span>
    </custom-table>
  </div>
</template>

<script>
import CustomTable from '@/components/CustomTable/index.js'
import { getSalaryPeriodAll } from '@/services/wage/wagePayroll'
import moment from 'moment'
export default {
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index')
  },
  mixins: [CustomTable],
  data() {
    return {
      moment,
      columns: [
        {
          title: '计薪月',
          dataIndex: 'payPeriod',
          scopedSlots: { customRender: 'payPeriod' },
          key: 'payPeriod'
        },
        {
          title: '开始日期',
          dataIndex: 'startDate',
          scopedSlots: { customRender: 'startDate' },
          key: 'startDate'
        },
        {
          title: '结束日期',
          dataIndex: 'endDate',
          scopedSlots: { customRender: 'endDate' },
          key: 'endDate'
        }
      ],
      dataSource: []
    }
  },
  methods: {
    // 请求数据
    async fetchDataApiFn(_queryParam) {
      const res = await getSalaryPeriodAll({ ..._queryParam })
      if (res.code === 100) {
        const index = res.data.findIndex(v => moment(new Date()).format('YYYYMM') === v.payPeriod)
        const result = res.data?.length - index
        if (result >= 12) {
          this.dataSource = res.data.slice(index, index + 12)
          return
        }
        this.dataSource = res.data.slice(-12)
      }
    }

  }

}
</script>
<style lang="less" scoped>
.table-container {
  height: 100%;
  overflow:auto;
  box-sizing: border-box;
}
.table-title {
  margin: 20px 0;
}
</style>
