<!--信息记录-->
<template>
  <div v-if="recordData.length>0" class="infomation-record-p">
    <div class="infomation-anchor">
      <Anchor
        ref="anchor"
        :title-list="anchorList"
        container=".infomation-record"
        class="anchor"
      />
    </div>
    <div ref="personInfo" class="infomation-record beauty-scroll">
      <div v-for="(item, index) in recordData" :key="index" class="com-panel">
        <!--标题-->
        <div :id="'grid_' + item.fieldGroupId" class="com-panel-head">
          <!-- <div class="com-head-title">{{ item.fieldGroupName }}</div>
          <div class="com-head-divide" /> -->
        </div>
        <div class="com-panel-body">
          <recordList v-for="(col,key) in item.children" :key="key" :data-list="col" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Anchor: () => import ('@/components/CbAnchor/index.js'),
    recordList: () => import ('../components/recordList.vue') },
  props: {
    anchorHeight: {
      type: String,
      default: ''
    },
    statusType: {
      type: String,
      default: ''
    },
    pageType: {
      type: String,
      default: ''
    },
    archivesPerfectId: {
      type: String,
      default: ''
    },
    onJobId: {
      type: String,
      default: ''
    },
    userId: {
      type: String,
      default: ''
    },
    recordData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showAnchor: false, // 是否展示锚点导航
      noDataImg: require('@/assets/img/home/noData.png'),
      show: true
    }
  },
  inject: ['anchorHeight'],
  computed: {
    anchorList: function() {
      return this.recordData.map((v) => {
        return { id: `grid_${v.fieldGroupId}`, label: v.fieldGroupName }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.infomation-record-p {
  height:100%;
  padding-top: 20px;
  display: flex;
  .infomation-anchor {
    position: relative;
    width: 180px;
    padding-left: 20px;
  }
  .infomation-record {
    // max-height: 600px;
    overflow-y: auto;
    padding-left: 20px;
    flex: 1;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .com-panel {
    .com-panel-head {
      padding: 0 ;
      .flex-between-center();
      .com-head-title {
        position: relative;
        padding-left: 7px;
        color: @sc-grey-100;
        font-weight: 600;
        margin-right: 10px;
        .font-size(16px);
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 3px;
          height: 14px;
          background: @sc-primary-100;
        }
      }
      .com-head-divide {
        flex: 1;
        height: 1px;
        background-color: @sc-grey-20;
      }
      .com-head-edit {
        margin-left: 10px;
        .ant-btn-link {
          color: @sc-primary-60;
          padding: 0;
          height: auto;
        }
      }
    }
  }
}
.noDataImg_box{
  text-align: center;
.noDataImg{
  width: 100px;
  height: 100px;
}
}

</style>
