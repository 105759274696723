<template>
  <div class="VacationModal">
    <Drawer
      v-if="planVisible"
      :visible="planVisible"
      :mask-closable="false"
      :is-mask="false"
      size="normal"
      :z-index="8"
      :title="`${formType==='add'?'新增' : '编辑'}假期方案`"
      @close="planVisible = false"
      @onSubmit="onSave"
    >
      <div class="VacationModal-content">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <BaseMessage :form-type="formType" :form="form" @vacationChange="vacationChange" @valid="valid" />
          <DistributionRules :form="form" />
          <DeductionRules :form="form" />
        </a-form-model>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { sepcialCode } from '@/utils/reg.js'
import { createVacationRule, updateVacationRule, getRuleDetail } from '@/services/attendance/holidays.js'
import { debounce } from '@/utils/index.js'
export default {
  name: 'VacationModal',
  components: {
    Drawer: () => import('@/components/CbDrawer'),
    BaseMessage: () => import('./baseMessage/index.vue'),
    DistributionRules: () => import('./distributionRules/index.vue'),
    DeductionRules: () => import('./deductionRules/index.vue')
  },
  props: {
    formType: {
      type: String,
      default: ''
    },
    vacationRuleId: {
      type: String,
      default: ''
    }
  },
  data() {
    const validUser = (rule, val, callback) => {
      if (this.form.scopes.length < 1) {
        callback(new Error('请选择人员'))
      } else {
        callback()
      }
    }
    const checkLimit = (rule, val, callback) => {
      if (this.form.limitFlag) {
        if (!this.form.limitMin || !this.form.limitMax) {
          callback(new Error('请输入正确的请假限制范围'))
        } else {
          if (Number(this.form.limitMin) > Number(this.form.limitMax)) {
            callback(new Error('请输入正确的请假限制范围'))
          } else {
            callback()
          }
        }
      } else {
        callback()
      }
    }
    const checklimitFlag = (rule, val, callback) => {
      if (this.form.monthLimitFlag) {
        if (!this.form.monthLimitMin || !this.form.monthLimitMax) {
          callback(new Error('请输入正确的请假限制范围'))
        } else {
          if (Number(this.form.monthLimitMin) > Number(this.form.monthLimitMax)) {
            callback(new Error('请输入正确的请假限制范围'))
          } else if (Number(this.form.limitMax) > Number(this.form.monthLimitMax)) {
            callback(new Error('单次请假限制时间不能大于单月请假限制时间'))
          } else {
            callback()
          }
        }
      } else {
        callback()
      }
    }
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      planVisible: false,
      radioStyle: {
        display: 'block',
        lineHeight: '35px'
      },
      form: {
      },
      resetForm: {
        vacationTypeId: '',
        vacationTypeCode: '',
        vacationDelayType: '1',
        vacationUnit: 'D',
        vacationDeductType: '0',
        halfDayCalcType: '1',
        balanceConvertType: '0',
        vacationCalcType: '1',
        limitFlag: false,
        limitMin: '',
        limitMax: '',
        vacationGrantTimeType: '1',
        monthLimitFlag: false,
        monthLimitMin: '0',
        monthLimitMax: '',
        vacationGrantRuleType: '1',
        amountSteps: [{ startValue: 0, containStartFlag: '1', containEndFlag: '1' }],
        scopes: [],
        halfDayHourAm: '',
        halfDayHourPm: ''
      },
      rules: {
        vacationTypeId: [
          { required: true, message: '请选择假期类型', trigger: 'blur' }
        ],
        ruleName: [
          { required: true, message: '请输入假期方案名称', trigger: 'blur' },
          { min: 1, max: 30, message: '请输入1~30个文字', trigger: 'change' },
          { pattern: sepcialCode, message: '假期方案名称不支持特殊字符', trigger: 'change' }
        ],
        scopes: [{ required: true, validator: validUser, trigger: 'change' }],
        vacationUnit: [
          { required: true, message: '请选择请假时间单位', trigger: 'change' }
        ],
        vacationCalcType: [
          { required: true, message: '请选择请假时长计算方式', trigger: 'change' }
        ],
        halfDayCalcType: [
          { required: true, message: '请选择半天折算标准', trigger: 'change' }
        ],
        halfDayHourPm: [
          { required: true, message: '请输入下午折算时长', trigger: 'blur' }
        ],
        halfDayHourAm: [
          { required: true, message: '请输入上午折算时长', trigger: 'blur' }
        ],
        monthLimitFlag: [
          { validator: checklimitFlag, trigger: 'blur' }
        ],
        limitFlag: [
          { validator: checkLimit, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    valid() {
      this.$refs.ruleForm.validateField('scopes', () => {})
    },
    // 查询假期详情
    async getVacationDetails() {
      const res = await getRuleDetail({ vacationRuleId: this.vacationRuleId })
      // this.form.deductCurrentYearFlag = this.form.deductCurrentYearFlag === false ? '1' : '2'
      res.data.amountSteps && res.data.amountSteps.forEach(item => {
        item.containEndFlag = item.containEndFlag === false ? '1' : '2'
        item.containStartFlag = item.containStartFlag === false ? '1' : '2'
      })
      if (!res.data.amountSteps || res.data.amountSteps.length < 1) {
        res.data.amountSteps = [{ startValue: 0, containStartFlag: '1', containEndFlag: '1' }]
      }
      this.form = {
        ...res.data,
        deductCurrentYearFlag: res.data.deductCurrentYearFlag !== null ? res.data.deductCurrentYearFlag ? '2' : '1' : undefined,
        vacationLimitStaffType: res.data.vacationLimitStaffType?.split(',')
      }
    },
    // 保存
    onSave() {
      if (this.form.vacationTypeCode === 'NXJ') {
        if (this.form.vacationGrantTimeType) {
          if (this.form.vacationGrantTimeType === '1') {
            if (!this.form.grantWorkMonth) {
              this.$message.error(`假期方案发放时间未填写`)
              return
            }
          } else {
            if (!this.form.grantDate) {
              this.$message.error(`假期方案发放时间未填写`)
              return
            }
          }
        }
      }
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.formType === 'add') {
            return this.addPlan()
          }
          return this.editPlan()
        }
        this.$message.error(`${this.formType === 'add' ? '新增' : '编辑'}信息不完整！`)
      })
    },
    // 新增假期方案
    addPlan: debounce(async function() {
      this.form.limitMax = parseFloat(this.form.limitMax)
      this.form.limitMin = parseFloat(this.form.limitMin)
      this.form.monthLimitMax = parseFloat(this.form.monthLimitMax)
      this.form.monthLimitMin = parseFloat(this.form.monthLimitMin)
      this.form.amountSteps && this.form.amountSteps.forEach(item => {
        item.containEndFlag = item.containEndFlag !== '1'
        item.containStartFlag = item.containStartFlag !== '1'
      })
      const type = ['NXJ', 'TXJ', 'DBJ', 'SHJ', 'LTJ', 'TQJ']
      if (type.includes(this.form.vacationTypeCode)) {
        this.form.vacationDelayType = undefined
      }
      if (this.form.vacationDeductType === '0') {
        this.form.expiryMonths = ''
        this.form.deductCurrentYearFlag = undefined
        this.form.autoDeductDate = ''
      } else if (this.form.vacationDeductType === '1') {
        this.form.deductCurrentYearFlag = undefined
        this.form.autoDeductDate = ''
      } else if (this.form.vacationDeductType === '2') {
        this.form.expiryMonths = ''
      }
      if (this.form.vacationGrantTimeType === '1') {
        this.form.grantDate = ''
      } else if (this.form.vacationGrantTimeType === '2') {
        this.form.grantWorkMonth = ''
      }
      const res = await createVacationRule({ ...this.form, deductCurrentYearFlag: this.form.deductCurrentYearFlag !== undefined ? this.form.deductCurrentYearFlag !== '1' : '', vacationLimitStaffType: this.form.vacationLimitStaffType?.join(',') })
      if (res.code === 100) {
        this.$message.success('新增成功！')
        this.planVisible = false
        this.$parent.serchGetData()
      }
    }, 500),
    // 编辑假期方案
    editPlan: debounce(async function() {
      this.form.limitMax = parseFloat(this.form.limitMax)
      this.form.limitMin = parseFloat(this.form.limitMin)
      this.form.monthLimitMax = parseFloat(this.form.monthLimitMax)
      this.form.monthLimitMin = parseFloat(this.form.monthLimitMin)
      this.form.amountSteps && this.form.amountSteps.forEach(item => {
        item.containEndFlag = item.containEndFlag !== '1'
        item.containStartFlag = item.containStartFlag !== '1'
      })
      const type = ['NXJ', 'TXJ', 'DBJ', 'SHJ', 'LTJ', 'TQJ']
      if (type.includes(this.form.vacationTypeCode)) {
        this.form.vacationDelayType = undefined
      }
      if (this.form.vacationDeductType === '0') {
        this.form.expiryMonths = ''
        this.form.deductCurrentYearFlag = undefined
        this.form.autoDeductDate = ''
      } else if (this.form.vacationDeductType === '1') {
        this.form.deductCurrentYearFlag = undefined
        this.form.autoDeductDate = ''
      } else if (this.form.vacationDeductType === '2') {
        this.form.expiryMonths = ''
      }
      if (this.form.vacationGrantTimeType === '1') {
        this.form.grantDate = ''
      } else if (this.form.vacationGrantTimeType === '2') {
        this.form.grantWorkMonth = ''
      }
      const res = await updateVacationRule({ ...this.form, deductCurrentYearFlag: this.form.deductCurrentYearFlag !== undefined ? this.form.deductCurrentYearFlag !== '1' : '', vacationRuleId: this.vacationRuleId, vacationLimitStaffType: this.form.vacationLimitStaffType?.join(',') })
      if (res.code === 100) {
        this.$message.success('编辑成功！')
        this.planVisible = false
        this.$parent.serchGetData()
      }
    }, 500),
    open() {
      this.$nextTick(() => {
        if (this.formType === 'edit') {
          this.getVacationDetails()
        }
        this.vacationChange({})
        this.planVisible = true
      })
    },
    // 假期改变清空数据
    vacationChange({ vacationTypeId, vacationTypeCode, vacationTypeUnit }) {
      console.log(vacationTypeId, vacationTypeCode, vacationTypeUnit)
      this.form = { ...this.resetForm, vacationTypeCode: vacationTypeCode, vacationTypeId: vacationTypeId, vacationUnit: vacationTypeUnit }
    }
  }
}
</script>

<style lang="less" scoped>
.VacationModal {
  &-content {
    .number-input{
      width:70px;
      margin: 0 5px;
      display:inline-block;
    }
  }
  &-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid @sc-grey-10;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
}
}
.close-btn,
.ok-btn {
  width: 74px;
}
</style>
