<!--
 * @Date: 2022-04-26 14:48:24
 * @descriotion:
-->
<template>
  <div id="wifi" class="wifi">
    <appForm
      :to-list="formList"
      search-placeholder="请搜索"
      @getSearchCallback="getSearchCallback"
    >
      <template slot="customButton">
        <a-button type="primary" prefix="add-one" @click="addWifi">
          新增打卡WIFI
        </a-button>
      </template>
    </appForm>
    <custom-table
      id="wifiTable"
      :columns="columns"
      :data-source="dataSource"
      :tabs-height="36"
      :pagination="false"
      row-key="clockWifiId"
      @change="loadDataList"
    >
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{scope}">
        <template>
          <div class="more-handle-btn-box">
            <a-button type="link" @click="edit(scope.record)">
              编辑
            </a-button>
            <a-button type="link" class="delete" danger @click="delet(scope.record)">
              删除
            </a-button>
          </div>
        </template>
        <!-- <a-popover
          placement="bottom"
          trigger="hover"
        >
          <template slot="content">
            <div class="more-handle-btn">
              <a-button type="link" @click="edit(scope.record)">
                编辑88
              </a-button>
              <a-button type="link" class="delete" danger @click="delet(scope.record)">
                删除
              </a-button>
            </div>
          </template>
          <div class="more-handle">
            ···
          </div>
        </a-popover> -->
      </template>
    </custom-table>
    <Modal :title="title" :closable="true" size="normal" :visible.sync="dialogFormVisible" @cancel="cancel" @ok="ok">
      <a-form-model ref="ruleForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
        <a-form-model-item label="WIFI名称" prop="wifiName">
          <a-input v-model="form.wifiName" placeholder="请输入WIFI名称" />
        </a-form-model-item>
        <a-form-model-item ref="bssid" label="BSSID" prop="wifiBssId" class="BSSID">
          <a-input v-model="form.bssid1" class="BSSID_input" :max-length="2" @change="bssidChange" @paste="pasting" />:
          <a-input v-model="form.bssid2" class="BSSID_input" :max-length="2" @change="bssidChange" />:
          <a-input v-model="form.bssid3" class="BSSID_input" :max-length="2" @change="bssidChange" />:
          <a-input v-model="form.bssid4" class="BSSID_input" :max-length="2" @change="bssidChange" />:
          <a-input v-model="form.bssid5" class="BSSID_input" :max-length="2" @change="bssidChange" />:
          <a-input v-model="form.bssid6" class="BSSID_input" :max-length="2" @change="bssidChange" />
        </a-form-model-item>
        <a-form-model-item label=" ">
          <p class="wifiId">1.可以向公司IT部门询问公司WiFi的BSSID。</p>
          <p class="wifiId">2.采用WiFi连接打卡时需设置BSSID，查找方式：windows系统任务栏-点击搜索-输入命令提示符-打开命令提示符-输入cmd.exe回车，输入命令netsh wlan show interfaces回车即可查看已经连接的WIFI的BSSID。
          </p>
        </a-form-model-item>
      </a-form-model>
    </Modal>
  </div>
</template>
<script>
import { sepcialCode } from '@/utils/reg.js'
import mixins from '@/components/CustomTable/index.js'
import { clockWifiGetPageApi, addWifiApi, editWifiApi, delWifiApi } from '@/services/attendance/settingset.js'
import { textReg } from '@/utils/reg.js'
export default {
  name: 'Wifi',
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  mixins: [mixins],
  data() {
    const validWifi = (rule, val, callback) => {
      if (!this.form.bssid1 || !this.form.bssid2 || !this.form.bssid3 || !this.form.bssid4 || !this.form.bssid5 || !this.form.bssid6) {
        return callback(new Error('请输入Bssid'))
      } else {
        if (textReg.test(this.form.bssid1) || textReg.test(this.form.bssid2) || textReg.test(this.form.bssid3) || textReg.test(this.form.bssid4) || textReg.test(this.form.bssid5) || textReg.test(this.form.bssid6)) {
          return (new Error('BSSID不支持特殊字符'))
        } else if (this.form.bssid1.length < 2 || this.form.bssid2.length < 2 || this.form.bssid3.length < 2 || this.form.bssid4.length < 2 || this.form.bssid5.length < 2 || this.form.bssid6.length < 2) {
          return (new Error('请输入完整的BSSID'))
        } else {
          callback()
        }
      }
    }
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      wrapperColBSS: { span: 2 },
      form: {
        wifiName: '',
        wifiBssId: '',
        bssid1: '',
        bssid2: '',
        bssid3: '',
        bssid4: '',
        bssid5: '',
        bssid6: ''
      },
      title: '',
      dialogFormVisible: false,
      formLabelWidth: '120px',
      formVisible: true,
      formList: ['search'],
      columns: [{
        title: 'WIFI名称',
        dataIndex: 'wifiName',
        key: 'wifiName',
        scopedSlots: { customRender: 'processName' },
        ellipsis: true
      },
      {
        title: 'BSSID地址',
        dataIndex: 'wifiBssId',
        scopedSlots: { customRender: 'switchStatus' },
        key: 'wifiBssId',
        customCell: () => {
          return {
            style: {
              'min-width': '160px'
            }
          }
        }
      },
      {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
        width: '100px',
        fixed: 'right'
      }],
      dataSource: [],
      clockWifiId: '',
      rules: {
        wifiName: [
          { required: true, message: '请输入WIFI名称', trigger: 'blur' },
          { min: 1, max: 20, message: 'WIFI名称仅支持1~20个文字', trigger: 'change' },
          { pattern: sepcialCode, message: 'WIFI名称不支持特殊字符', trigger: 'change' }
        ],
        wifiBssId: [
          { required: true, validator: validWifi, trigger: 'change' }
        ]
      }
    }
  },
  created() {
    this.clockLocationGetPage()
  },
  methods: {
    // 粘贴
    async pasting(e) {
      const val = await navigator.clipboard.readText()
      const arr = val.split(':')
      if (arr.length === 6) {
        this.form.bssid1 = arr[0].length > 2 ? arr[0].slice(-2) : arr[0]
        this.form.bssid2 = arr[1]
        this.form.bssid3 = arr[2]
        this.form.bssid4 = arr[3]
        this.form.bssid5 = arr[4]
        this.form.bssid6 = arr[5]
        this.bssidChange()
      }
    },
    bssidChange() {
      this.$refs.ruleForm.validateField('wifiBssId', (err) => { console.log(err) })
    },
    // 打卡类型地区分页查询
    clockLocationGetPage(data = '') {
      clockWifiGetPageApi({ wifiName: data }).then(res => {
        this.dataSource = res.data
      })
    },
    getSearchCallback(val) {
      console.log(val)
      this.clockLocationGetPage(val.searchValue)
    },
    addWifi() {
      this.title = '新增打卡WIFI'
      this.dialogFormVisible = true
    },
    delet(scope) {
      this.clockWifiId = scope.clockWifiId
      this.$Reconfirm({
        title: '删除打卡WIFI',
        content: '确定要删除该打卡WIFI吗？'
      }).then(async() => {
        const res = await delWifiApi({ clockWifiId: this.clockWifiId })
        if (res.code === 100) {
          this.$message.success('删除成功')
          this.clockLocationGetPage()
        }
      }).catch(() => {
        console.log('no')
      })
    },
    edit(scope) {
      this.title = '编辑打卡WIFI'
      this.dialogFormVisible = true
      this.form.wifiName = scope.wifiName
      const bssId = scope.wifiBssId.split(':')
      this.form.bssid1 = bssId[0]
      this.form.bssid2 = bssId[1]
      this.form.bssid3 = bssId[2]
      this.form.bssid4 = bssId[3]
      this.form.bssid5 = bssId[4]
      this.form.bssid6 = bssId[5]
      this.$nextTick(() => {
        this.$set(this, 'form', this.form)
      })
      this.clockWifiId = scope.clockWifiId
    },
    cancel() {
      this.form = this.$options.data().form
      this.$refs.ruleForm.resetFields()
      this.dialogFormVisible = false
      this.clockLocationGetPage()
    },
    async ok() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.form.wifiBssId = `${this.form.bssid1}:${this.form.bssid2}:${this.form.bssid3}:${this.form.bssid4}:${this.form.bssid5}:${this.form.bssid6}`
          if (this.title === '新增打卡WIFI') {
            addWifiApi(this.form).then(res => {
              if (res.code !== 100) return
              this.$message.success('新增成功')
              this.cancel()
            })
          } else {
            this.form.wifiBssId = `${this.form.bssid1}:${this.form.bssid2}:${this.form.bssid3}:${this.form.bssid4}:${this.form.bssid5}:${this.form.bssid6}`
            editWifiApi({ ...this.form, clockWifiId: this.clockWifiId }).then(res => {
              if (res.code !== 100) return
              this.$message.success('编辑成功')
              this.cancel()
            })
          }
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.ant-alert.ant-alert-closable{
  margin-bottom:12px;
}
.wifi{
  height:100%;
  background-color: @base-bg-color;
  /deep/.arr_form{
    margin: 16px 0;
  }
}
.more-handle-btn {
    display: flex;
    flex-direction: column;
    .ant-btn {
      height:25px;
    }
  }
.more-handle {
  font-weight: 700;
  color: @sc-primary-100;
  font-size: 20px;
  vertical-align: baseline;
  cursor: pointer;
  margin-left: 5px;
}
.BSSID{
  display: flex;
}
.BSSID_input{
  width: 13%;
  margin: 0 5px;
  &:nth-child(1){
    margin: 0 5px 0 0;
  }
}
.wifiId {
  color: @sc-grey-80;
  margin-bottom: 0;
  font-size:14px;
  line-height: 22px;
}
.more-handle-btn-box{
  display: flex;
  button{
    padding-left: 0;
    padding-right: 16px;
  }
}
</style>
