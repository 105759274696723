<template>
  <Modal
    :closable="true"
    :visible="visible"
    :size="'normal'"
    :mask-closable="false"
    :title="'基本信息设置'"
    :ok-text="'确认'"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="setting-modal-panel">
      <div class="setting-panel">
        <div class="setting-panel-title">管理系统显示</div>
        <a-switch :checked="form.manageShow" size="small" @click="(checked) => (form.manageShow = checked)" />
      </div>
      <div class="setting-panel">
        <div class="setting-panel-title">员工端显示</div>
        <a-switch :checked="form.staffShow" size="small" @click="(checked) => (form.staffShow = checked)" />
      </div>
      <div class="setting-panel">
        <div class="setting-panel-title">入职登记表显示</div>
        <a-switch
          size="small"
          :checked="form.entryFormShow"
          @click="(checked) => (form.entryFormShow = checked)"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import { postConfigFieldGroup } from '@/services/user/fieldGroup.js'
export default {
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      visible: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      form: {
        fieldGroupId: null,
        manageShow: false,
        staffShow: true,
        entryFormShow: false
      }
    }
  },
  mounted() {},
  methods: {
    // 打开弹窗
    open(data) {
      if (data) {
        const newData = { ...data }
        this.form.fieldGroupId = newData.fieldGroupId + ''
        this.form.manageShow = newData.manageShow === 1
        this.form.staffShow = newData.staffShow === 1
        this.form.entryFormShow = newData.entryFormShow === 1
      }
      this.visible = true
    },
    // 保存事件
    async handleOk() {
      const data = {}
      data.fieldGroupId = this.form.fieldGroupId
      data.manageShow = this.form.manageShow ? 1 : 0
      data.staffShow = this.form.staffShow ? 1 : 0
      data.entryFormShow = this.form.entryFormShow ? 1 : 0
      const res = await postConfigFieldGroup(data)
      if (res.code === 100) {
        this.$message.success('设置成功')
        this.handleCancel()
        this.$emit('reload')
      }
    },
    // 取消事件
    handleCancel() {
      this.visible = false
      this.resetForm()
    },
    // 重置表单内容和验证
    resetForm() {
      this.form = {
        fieldGroupId: null,
        manageShow: false,
        staffShow: false,
        entryFormShow: false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.setting-modal-panel {
  .setting-panel {
    padding: 14px 0;
    .flex-between-center();
    .setting-panel-title {
      .font-size(14px);
      color: @sc-grey-100;
    }
  }
  .setting-panel + .setting-panel {
    border-top: 1px solid @sc-grey-20;
  }
}
</style>
