<template>
  <div class="collapse">
    <a-form-model
      ref="ruleForm"
      :model="staffAddInfo"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="模版名称" prop="staffName">
        <a-input v-model="staffAddInfo.staffName" placeholder="请输入名称" />
      </a-form-model-item>
    </a-form-model>
    <a-collapse v-model="activeKey" :bordered="false" expand-icon-position="right">
      <a-collapse-panel v-for="(item,index) in labelList" :key="String(index)">
        <template slot="header">
          <BlockTitle :title="item.fieldGroupName" />
        </template>
        <div class="collapse-child">
          <a-button v-for="(label,i) in item.fields" :key="i" @click="$emit('insert',label)">{{ label.fieldName }}</a-button>
        </div>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
export default {
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index')
  },
  props: {
    labelList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      labelCol: { lg: 8, xl: 6, xxl: 5 },
      wrapperCol: { lg: 15, xl: 17, xxl: 18 },
      staffAddInfo: { staffName: '' },
      activeKey: ['0', '1'],
      rules: {
        staffName: [{ required: true, message: '请输入模板名称', trigger: 'blur' }]
      }
    }
  },
  created() {
    const proveTemplate = JSON.parse(sessionStorage.getItem('proveTemplate'))
    this.staffAddInfo.staffName = proveTemplate.tempName
  },
  methods: {}

}
</script>

<style lang="less" scoped>
.collapse {
  height: calc( 100vh - 91px ) ;
  overflow: auto;
  padding: 20px 0 100px 20px;
  width: 432px;
  border-right: 1px solid  @sc-greyBg-20;
  &-child {
    display: flex;
    flex-wrap: wrap;
    .ant-btn {
      margin: 0 10px 10px 0;
    }
  }
  /deep/.ant-form {
    height: 40px;
  }
  /deep/.ant-form-explain {
      position: absolute;
  }
  /deep/.ant-collapse {
    background-color: #fff;
    width: 100%;
  }
  /deep/.ant-collapse-header {
    padding-left: 10px;
  }
  // /deep/.ant-collapse-header:before {
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   width: 4px;
  //   height: 14px;
  //   background-color: @sc-primary-100;
  // }
  /deep/.ant-collapse-item {
    border-bottom: none;
    .ant-collapse-arrow::before {
    content: "展开";
    display: inline-block;
  }
  }

  /deep/.ant-collapse-item-active {
    .ant-collapse-arrow::before {
      content: "收起";
      display: inline-block;
    }
  }
}
.collapse::-webkit-scrollbar {
    display:none
}
.collapse-child .ant-btn:hover{
  color: @sc-primary-100;
  border-color: @sc-primary-100;
}
</style>
