<template>
  <div>
    <template v-if="!exportVisible">
      <!--  导航  -->
      <div class="top_navigation">
        <HeaderBack @back="goBack">
          <template slot="left">
            <span class="top_nav_title" :title="salaryItemInfo.groupName">{{ salaryItemInfo.groupName }}</span>
            <span class="top_nav_time">{{ salaryPeriod.startDate }} - {{ salaryPeriod.endDate }}</span>
          </template>
          <template slot="center">
            <a-steps :current="current" @change="currentChange">
              <a-step v-for="item in steps" :key="item.key" :disabled="isNotDetail" :title="item.title" />
            </a-steps>
          </template>
        </HeaderBack>
      </div>
      <a-divider />
      <!--  步骤条内容 -->
      <div class="steps-content">
        <component
          :is="showContentName[current]"
          :salary-item-info="salaryItemInfo"
          @handleContinue="handleContinue"
        />
      </div>
      <!--  步骤条内容操作 -->
      <div class="steps-action">
        <a-space>
          <a-button
            v-if="!isNotDetail || ( current > 0 && current !== 3 )"
            class="previous"
            @click="prev"
          > 上一步 </a-button>
          <a-button
            v-if="(!isNotDetail && current !== 4) || ( current < steps.length - 1 && current !== 4 )"
            type="primary"
            @click="next"
          > {{ current === 2 && isNotDetail ? '锁定应发工资' : '下一步' }} </a-button>
          <a-button v-if="current == 4" type="primary" @click="goBack"> 返回首页 </a-button>
        </a-space>
      </div>
    </template>
    <!-- 批量定薪、批量调薪的展示 -->
    <template v-else>
      <!-- 人员批量定薪 -->
      <BatchImport
        v-if="batchType === 'batchFixedSalary'"
        :multiple="false"
        :file-size="20"
        title="人员批量定薪"
        import-template="batchFixedSalary"
        :extra-params="{
          groupCalcId: salaryItemInfo.groupCalcId
        }"
        @goBack="batchHide"
      />
      <!-- 人员批量调薪 -->
      <BatchImport
        v-if="batchType === 'batchChangeSalary'"
        :multiple="false"
        :file-size="20"
        title="人员批量调薪"
        import-template="batchChangeSalary"
        :extra-params="{
          groupCalcId: salaryItemInfo.groupCalcId
        }"
        @goBack="batchHide"
      />
      <!-- 核算薪资--导入合计 -->
      <BatchImport
        v-if="batchType === 'batchSetSalaryCalc'"
        :multiple="false"
        :file-size="20"
        title="批量导入合计"
        import-template="batchSetSalaryCalc"
        :extra-params="extraParams"
        @goBack="batchHide"
      />
      <!-- 核算薪资--导入合计 -->
      <BatchImport
        v-if="batchType === 'salaryIndexItemImportIndex'"
        :multiple="false"
        :file-size="20"
        title="批量导入明细"
        import-template="salaryIndexItemImportIndex"
        :extra-params="extraParams"
        @goBack="batchHide"
      />
    </template>
    <!-- 第三步【核算薪资】下一步展示的二次确认弹窗 -->
    <Modal
      :visible="lockSalaryVisible"
      title="锁定应发工资"
      closable
      size="small"
      @ok="handleOk"
      @cancel="lockSalaryVisible = false"
    >
      <div class="content">
        <CbAlert
          type="warning"
          show-icon
        >
          <template slot="content">
            <div>
              1.锁定当前薪资组应发工资后，参与计算的各薪资项数据将无法更改，建议您先确认核算薪资的结果，再进行锁定。
            </div>
            <!-- （未锁定应发工资的薪资组数量＞1时） -->
            <div v-if="notLockSalaryNum > 1">
              2.点击确认，当前薪资组应发工资锁定完成，并将返回计薪首页继续剩余薪资组计薪。
            </div>
            <!-- （未锁定应发工资的薪资组数量=1时） -->
            <div v-if="notLockSalaryNum === 1">
              2.当前计薪周期的应发工资已全员计算完毕，点击确认，将锁定该薪资组应发工资并前往个税业务版块，确认锁定并跳转吗？
            </div>
          </template>
        </CbAlert>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  forwardSetSalary,
  backwardStaff,
  forwardCalc,
  forwardTax,
  backwardFixed,
  backwardCal,
  forwardFinish,
  backwardTax } from '@/services/wage/wagePayroll'
export default {
  components: {
    Modal: () => import('@/components/CbModal/index'),
    CbAlert: () => import('@/components/CbAlert/index'),
    payrollPersonnel: () => import('./processItem/payrollPersonnel.vue'),
    taxDeclarationPersonnel: () => import('./processItem/taxDeclarationPersonnel'),
    changeSalary: () => import('./processItem/changeSalary'),
    accountingSalary: () => import('./processItem/accountingSalary'),
    accountingCompleted: () => import('./processItem/accountingCompleted'),
    HeaderBack: () => import('@/components/headerBack'),
    BatchImport: () => import('@/components/CbBatchImport/newImport.vue') // 导入表格组件
  },
  provide() {
    return {
      batchShow: this.batchShow,
      salaryItemInfo: this.salaryItemInfo,
      batchHide: this.batchHide,
      isNotDetail: this.isNotDetail,
      period: this.period
    }
  },
  props: {
    salaryItemInfo: {
      type: Object,
      default: () => {}
    },
    // 当前计薪周期下所有的薪资组
    salaryGroup: {
      type: Array,
      default: () => []
    },
    salaryPeriod: {
      type: Object,
      default: () => {}
    },
    oprationType: {
      type: String,
      default: ''
    },
    period: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    let current = 0
    if (this.oprationType === 'toContinue') {
      current = this.salaryItemInfo.calcStatus - 1
    }
    return {
      lockSalaryVisible: false,
      exportVisible: false,
      batchType: '',
      current: current,
      steps: [
        {
          key: 1,
          title: '核对计薪人员',
          content: 'First-content'
        },
        {
          key: 2,
          title: '定薪调薪',
          content: 'Second-content'
        },
        {
          key: 3,
          title: '核算薪资',
          content: 'Last-content'
        },
        {
          key: 4,
          title: '个税核对',
          content: 'Last-content'
        },
        {
          key: 5,
          title: '计薪完成',
          content: 'Last-content'
        }
      ],
      extraParams: {},
      showContentName: ['payrollPersonnel', 'taxDeclarationPersonnel', 'changeSalary', 'accountingSalary', 'accountingCompleted']
    }
  },
  computed: {
    isNotDetail() {
      return this.oprationType !== 'detail'
    },
    // 未锁定的薪资组的数量
    notLockSalaryNum() {
      let num = 0
      this.salaryGroup.forEach(item => {
        if (item.calcStatus <= 3) {
          num += 1
        }
      })
      return num
    }
  },
  methods: {
    // 查看明细随意点击跳转
    currentChange(val) {
      this.current = val
    },
    // 返回
    goBack() {
      this.$emit('goBack', this.period)
    },
    // 继续计薪
    handleContinue() {
      this.$emit('goBack')
    },
    // 批量隐藏的按钮
    batchHide() {
      this.exportVisible = false
    },
    // 批量显示的按钮功能
    batchShow(type, params = {}) {
      this.exportVisible = true
      this.extraParams['groupCalcId'] = this.salaryItemInfo.groupCalcId
      Object.assign(this.extraParams, params)
      this.batchType = type
    },
    async next() {
      if (this.oprationType !== 'detail') {
        if (this.current === 2) {
          this.lockSalaryVisible = true
          return
        }
        const ajaxOList = [forwardSetSalary, forwardCalc, forwardTax, forwardFinish]
        await ajaxOList[this.current]({ groupCalcId: this.salaryItemInfo.groupCalcId, taxpayPeriod: this.period })
      }
      this.current++
    },
    async prev() {
      if (this.oprationType !== 'detail') {
        if (this.current === 2) {
          await this.$Reconfirm({
            title: '提示',
            content: '点击上一步将清除已经核算完成的薪资项数据，确认要返回上一步么？'
          })
        }
        const ajaxOList = ['', backwardStaff, backwardFixed, backwardCal, backwardTax]
        await ajaxOList[this.current]({ groupCalcId: this.salaryItemInfo.groupCalcId })
      }
      // 阻止当前步骤条为第一项时点击上一步仍然生效
      if (this.current >= 1) {
        this.current--
      }
    },
    async handleOk() {
      await forwardTax({ groupCalcId: this.salaryItemInfo.groupCalcId, taxpayPeriod: this.period })
      // this.current++
      if (this.notLockSalaryNum > 1) {
        this.$emit('goBack', this.period)
      }
      if (this.notLockSalaryNum === 1) {
        this.$router.push({
          name: 'wageTaxes',
          params: {
            period: this.period
          }
        })
      }
      this.lockSalaryVisible = false
    }
  }
}
</script>
<style lang="less" scoped>
.top_navigation {
  padding: 0 0 20px 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: @gray-14;
  font-weight: bold;
  border-bottom: 2px solid @sc-greyBg-10;
  .top_nav_title{
    margin-right: 12px;
    font-size: 16px;
    max-width: 200px;
    color: @sc-grey-100;
    white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
  }
  .top_nav_time{
    .font-size(14px);
    font-weight: 500;
    color: @sc-grey-60;
  }
}
.steps-action {
  position: absolute;
  bottom:0;
  text-align: center;
  margin: 16px 0;
  left: 47%;
  .ant-btn{
    padding: 5px 20px;
    color: @sc-grey-100;
  }
}
.ant-divider-horizontal{
  display: none;
}
/deep/.ant-steps{
  // margin-right: 50px;
  width: 100%;
  margin-left: 50px;
  .ant-steps-item-icon{
    width: 24px;
    height: 24px;
    line-height: 21px;
    margin-right: 10px;
    border: 2px solid @sc-greyBg-50;
  }
  .ant-steps-item .ant-steps-item-title{
    .font-size(16px);
    color: @sc-grey-100;
    padding-right: 12px;
    &:after{
      top: 13px;
      width: 98px;
      background: @sc-greyBg-50;
    }
  }
  .ant-steps-item-active, .ant-steps-item-finish{
    .ant-steps-item-icon{
      border-color: @sc-primary-100;
    }
    .ant-steps-item-title{
      color: @sc-primary-100;
      &::after{
        background: @sc-primary-100;
      }
    }
  }
}
/deep/.abso-center{
  width: 55%;
}
/deep/.flex-align-center{
  margin: 0 !important;
}
.steps-content{
  padding: 20px 0 0 20px;
  padding-left: 0;
}
/deep/.ant-space-item{
  margin-right: 12px !important;
}
/deep/.primary{
  color: #fff !important;
}
</style>
