var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"top"},[_c('span',[_vm._v("岗位总数："+_vm._s(_vm.postTypeStatisticsValue.postCount)+"人")]),_c('span',{staticClass:"num"},[_vm._v("在职人数："+_vm._s(_vm.postTypeStatisticsValue.incumbencyCount)+"人")])]),_c('div',[_c('custom-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"table-ref":".box","row-key":'postName',"pagination":{
        current: _vm.pager.pageNum,
        pageSize: _vm.pager.pageSize,
        total: _vm.total,
        showTotal: function (total) { return ("共 " + total + " 条数据"); },
        showQuickJumper: true,
        showSizeChanger: true
      }},on:{"change":_vm.loadDataList}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }