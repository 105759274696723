<template>
  <div>
    <!--  添加 搜索 筛选  -->
    <appForm
      ref="appForm"
      :to-list="toList"
      :screen-roster-list="screenRosterList"
      width="400"
      @getSearchCallback="getSearchCallback"
      @screenRosterParams="screenRosterParams"
      @exportFileList="exportFileList"
    >
      <template v-if="keyval === '1'" slot="customButton">
        <a-button type="primary" prefix="add-one" @click="handleAdd()">
          添加待离职
        </a-button>
      </template>
    </appForm>
    <custom-table
      table-ref=".main-content"
      :tabs-height="36"
      :columns="columns"
      :data-source="dataSource"
      row-key="index"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="loadDataList"
    >
      <!-- 序号 -->
      <template slot="index" slot-scope="{ scope }">
        <span>{{ scope.index + 1 }}</span>
      </template>
      <!-- 姓名头像 -->
      <template slot="name" slot-scope="{ scope }">
        <CbTableAvater :status-type="keyval === '2' ? 'resigned' : ''" :table-avater="scope.record.avatar" :on-job-id="scope.record.onJobId" :table-name="scope.record.name" />
      </template>
      <!-- 状态 -->
      <template slot="applyStatus" slot-scope="{ scope }">
        <span>
          <StatusTag
            v-if="scope.record.flowId"
            :type="scope.record.applyStatus"
            :type-list="applyStatusList"
            @click="getCbApproveSteps(scope.record.flowId, scope.record.applyStatus)"
          />
        </span>
      </template>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{ scope }">
        <a-button v-if="keyval == '1' && (scope.record.applyStatus === '2' || dataType(scope.record.applyStatus) === 'null')" type="link" @click="handleAdd(scope)"> 调整离职日期 </a-button>
        <a-button v-if="keyval == '2'" type="link" @click="getQuitDetails(scope.record.onJobId)"> 详情 </a-button>
        <!-- <a-button v-if="keyval === '2'" type="link" @click="editItemConfirm(scope)"> 删除 </a-button> -->
      </template>
    </custom-table>
    <QuitForm ref="transferForm" :modal-title="modalTitle" :is-add="isAdd" @getFetchDataApiFn="getFetchDataApiFn" />
    <CbApproveSteps
      v-model="stepsVisible"
      :flow-id="flowId"
      :apply-status="applyStatus"
      :apply-status-list="applyStatusList"
    />
    <QuitDetails ref="quitDetails" />
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import CbTableAvater from '@/components/CbTableAvater/index'
import { waitQuitList, alreadyQuitList, deleteQuitEntry, waitLeaveListExport, alreadyLeaveListExport } from '@/services/user/quit.js'

export default {
  name: 'QuitTabel',
  components: {
    QuitForm: () => import('./form/quitForm.vue'),
    CbApproveSteps: () => import('@/components/CbApproveSteps/index'),
    QuitDetails: () => import('./form/quitDetails.vue'),
    CbTableAvater,
    StatusTag: () => import('@/components/CbStatusTag')
  },

  mixins: [mixins],
  props: {
    keyval: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      applyStatusList: {
        '1': { name: '审批中' },
        '2': { name: '已通过' },
        '3': { name: '驳回' }
      },
      quitOnJobId: '',
      total: 0,
      columns: [],
      toList: ['search', 'screen', 'export'], // search搜索框 screen筛选 choice位置选择弹窗 export导出
      waitQuitListTable: [
        {
          title: '序号',
          dataIndex: 'index',
          width: '50px',
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '姓名',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          width: 105
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          key: 'phoneNum',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          ellipsis: true
        },
        {
          title: '职务',
          dataIndex: 'dutyName',
          key: 'dutyName',
          ellipsis: true
        },
        {
          title: '聘用形式',
          dataIndex: 'engageModeName',
          key: 'engageModeName'
        },
        {
          title: '入职日期',
          dataIndex: 'entryDate',
          key: 'entryDate'
        },
        {
          title: '计划离职日期',
          dataIndex: 'planLeaveDate',
          key: 'planLeaveDate'
        },
        {
          title: '审批状态',
          dataIndex: 'applyStatus',
          scopedSlots: { customRender: 'applyStatus' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      alreadyQuitListTable: [
        {
          title: '序号',
          dataIndex: 'index',
          width: '50px',
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '姓名',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          width: 105
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          key: 'phoneNum',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          ellipsis: true
        },
        {
          title: '职务',
          dataIndex: 'dutyName',
          key: 'dutyName',
          ellipsis: true
        },
        {
          title: '聘用形式',
          dataIndex: 'engageModeName',
          key: 'engageModeName'
        },
        {
          title: '入职日期',
          dataIndex: 'entryDate',
          key: 'entryDate'
        },
        {
          title: '离职日期',
          dataIndex: 'leaveDate',
          key: 'leaveDate'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      dataSource: [], // 数据
      publicList: [
        {
          'type': 'selectDictionaries',
          'label': '聘用形式',
          'options': {
            'multiple': false,
            'disabled': false,
            'width': '100%',
            'parameter': 'engage_mode',
            'clearable': true,
            'placeholder': '请选择',
            'showSearch': false
          },
          'model': 'employment',
          'key': 'selectDictionaries_1651110883682'
        },
        {
          'type': 'selectDepartment',
          'label': '部门',
          'options': {
            'multiple': false,
            'disabled': false,
            'width': '100%',
            'clearable': true,
            'placeholder': '请选择',
            'showSearch': false
          },
          'model': 'selectDepartment',
          'key': 'selectDepartment_1651110886169'
        },
        {
          'type': 'selectPost',
          'label': '岗位',
          'options': {
            'multiple': false,
            'disabled': false,
            'width': '100%',
            'clearable': true,
            'placeholder': '请选择',
            'showSearch': false
          },
          'model': 'selectPost',
          'key': 'selectPost_1651110888169'
        },
        {
          'type': 'selectDataRange',
          'label': '入职日期',
          'options': {
            'width': '100%',
            'defaultValue': [undefined, undefined],
            'rangePlaceholder': ['开始时间', '结束时间'],
            'range': false,
            'showTime': false,
            'disabled': false,
            'hidden': false,
            'clearable': true,
            'placeholder': '请选择开始时间',
            'format': 'YYYY-MM-DD'
          },
          'model': 'startEntryDate',
          'key': 'date_1651214034712',
          'help': ''
        },
        {
          'type': 'selectDataRange',
          'label': '计划离职日期',
          'options': {
            'width': '100%',
            'defaultValue': [undefined, undefined],
            'rangePlaceholder': ['开始时间', '结束时间'],
            'rangeDefaultValue': [],
            'range': false,
            'showTime': false,
            'disabled': false,
            'hidden': false,
            'clearable': true,
            'placeholder': '请选择开始时间',
            'format': 'YYYY-MM-DD'
          },
          'model': 'startPlanLeaveDate',
          'key': 'date_1651214034712',
          'help': ''
        }
      ],
      approveStateList: {
        'type': 'selectDictionaries',
        'label': '审批状态',
        'options': {
          'multiple': false,
          'disabled': false,
          'width': '100%',
          'parameter': 'apply_status',
          'clearable': true,
          'placeholder': '请选择',
          'showSearch': false
        },
        'model': 'approveState',
        'key': 'selectDictionaries_1651110897449'
      },
      quitList: [
        {
          'type': 'selectDictionaries',
          'label': '聘用形式',
          'options': {
            'multiple': false,
            'disabled': false,
            'width': '100%',
            'parameter': 'engage_mode',
            'clearable': true,
            'placeholder': '请选择',
            'showSearch': false
          },
          'model': 'employment',
          'key': 'selectDictionaries_1651110883682'
        },
        {
          'type': 'selectDepartment',
          'label': '部门',
          'options': {
            'multiple': false,
            'disabled': false,
            'width': '100%',
            'clearable': true,
            'placeholder': '请选择',
            'showSearch': false
          },
          'model': 'selectDepartment',
          'key': 'selectDepartment_1651110886169'
        },
        {
          'type': 'selectPost',
          'label': '岗位',
          'options': {
            'multiple': false,
            'disabled': false,
            'width': '100%',
            'clearable': true,
            'placeholder': '请选择',
            'showSearch': false
          },
          'model': 'selectPost',
          'key': 'selectPost_1651110888169'
        },
        {
          'type': 'selectDataRange',
          'label': '入职日期',
          'options': {
            'width': '100%',
            'defaultValue': [undefined, undefined],
            'rangePlaceholder': ['开始时间', '结束时间'],
            'range': false,
            'showTime': false,
            'disabled': false,
            'hidden': false,
            'clearable': true,
            'placeholder': '请选择开始时间',
            'format': 'YYYY-MM-DD'
          },
          'model': 'startEntryDate',
          'key': 'date_1651214034712',
          'help': ''
        },
        {
          'type': 'selectDataRange',
          'label': '离职日期',
          'options': {
            'width': '100%',
            'defaultValue': [undefined, undefined],
            'rangePlaceholder': ['开始时间', '结束时间'],
            'rangeDefaultValue': [],
            'range': false,
            'showTime': false,
            'disabled': false,
            'hidden': false,
            'clearable': true,
            'placeholder': '请选择开始时间',
            'format': 'YYYY-MM-DD'
          },
          'model': 'startPlanLeaveDate',
          'key': 'date_1651214034712',
          'help': ''
        }
      ],
      screenRosterList: {
        'list': [],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      stepsVisible: false,
      flowId: '',
      applyStatus: '',
      modalTitle: '',
      isAdd: true
    }
  },
  watch: {
    keyval: {
      handler(val) {
        this.getFetchDataApiFn()
      }
    }
  },
  mounted() {},
  methods: {
    // 获取表格数据
    async fetchDataApiFn(param) {
      if (this.keyval === '1') {
        // 待离职
        const arr = [...this.publicList, this.approveStateList]
        this.screenRosterList.list = arr
        this.columns = this.waitQuitListTable
        const res = await waitQuitList(param)
        this.dataSource = res.data.records ? res.data.records : []
        this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
        this.total = res.data.total
      } else {
        // 已离职
        this.screenRosterList.list = this.quitList
        this.columns = this.alreadyQuitListTable
        const res = await alreadyQuitList(param)
        this.dataSource = res.data.records ? res.data.records : []
        this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
        this.total = res.data.total
      }
      this.addIndex()
    },
    addIndex() {
      if (this.dataSource.length > 0) {
        this.dataSource.forEach((item, index) => {
          item.index = index
        })
      }
    },
    // 操作回调
    getFetchDataApiFn() {
      this.serchGetData()
    },
    handleAdd(scope) {
      if (scope) {
        this.isAdd = false
        this.modalTitle = '调整离职日期'
      } else {
        this.modalTitle = '办理离职'
        this.isAdd = true
      }
      this.$refs.transferForm.open(scope || '')
    },
    // 删除确认
    editItemConfirm(val) {
      this.quitOnJobId = val.record.onJobId
      this.$Reconfirm({
        title: '删除',
        content: '是否确认删除？'
      }).then(async() => {
        const res = await deleteQuitEntry({
          onJobId: this.quitOnJobId
        })
        if (res.code === 100) {
          this.$message.success('删除成功')
          this.serchGetData()
        }
      }).catch(() => {
        console.log('no')
      })
    },
    // 搜索
    getSearchCallback(val) {
      this._queryParam.name = val.searchValue
      this.serchGetData()
    },
    // 筛选
    screenRosterParams(data) {
      const { employment, selectDepartment, selectPost, approveState, startEntryDate, startPlanLeaveDate } = data.screenCondition
      this._queryParam = {
        name: data.searchValue,
        engageMode: employment,
        orgId: selectDepartment ? selectDepartment[0].value : '',
        postId: selectPost,
        startEntryDate: startEntryDate ? startEntryDate[0] : '',
        endEntryDate: startEntryDate ? startEntryDate[1] : '',
        startPlanLeaveDate: startPlanLeaveDate ? startPlanLeaveDate[0] : '',
        endPlanLeaveDate: startPlanLeaveDate ? startPlanLeaveDate[1] : '',
        applyStatus: approveState
      }
      this.serchGetData()
    },
    // 审批步骤条
    getCbApproveSteps(data, val) {
      this.flowId = data
      this.applyStatus = val
      this.stepsVisible = true
    },
    getQuitDetails(id) {
      this.$refs.quitDetails.show(id, this.keyval)
    },
    // 导出
    exportFileList() {
      const { name, engageMode, orgId, postId, startEntryDate, endEntryDate, startPlanLeaveDate, endPlanLeaveDate, applyStatus } = this._queryParam
      const params = {
        name: name,
        engageMode: engageMode,
        orgId: orgId,
        postId: postId,
        startEntryDate: startEntryDate,
        endEntryDate: endEntryDate,
        startPlanLeaveDate: startPlanLeaveDate,
        endPlanLeaveDate: endPlanLeaveDate,
        applyStatus: applyStatus
      }
      if (this.keyval === '1') {
        waitLeaveListExport(params)
      } else {
        alreadyLeaveListExport(params)
      }
    },
    dataType(data) {
      return Object.prototype.toString.call(data).split(' ')[1].slice(0, -1).toLowerCase()
    }
  }
}
</script>

<style lang="less" scoped>
.more-handle-btn {
  display: flex;
  flex-direction: column;
}
</style>
