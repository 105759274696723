<template>
  <div>
    <Modal :visible="visible" :closable="true" size="normal" title="不予转正" ok-text="保存" @ok="handleOk" @cancel="handleCancel">
      <div class="v-title">未进行转正审批，确认不予转正？</div>
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="计划离职日期" prop="time">
          <a-date-picker v-model="form.time" value-format="YYYY-MM-DD" placeholder="请选择时间" />
        </a-form-model-item>
        <a-form-model-item label="离职方式" prop="type">
          <dictionariesInput v-model="form.type" parameter="handle_type" />
        </a-form-model-item>
        <a-form-model-item label="离职原因" prop="reason">
          <dictionariesInput v-model="form.reason" parameter="leave_reasons" />
        </a-form-model-item>
        <a-form-model-item label="离职备注" prop="mark">
          <a-textarea v-model="form.remark" :autosize="true" placeholder="最多300个字符" />
        </a-form-model-item>
      </a-form-model>
    </Modal>
  </div>
</template>

<script>
import dictionariesInput from '@/components/CbDropDownInput/dictionariesInput/index'
import { noOfficial } from '@/services/user/worker.js'
export default {
  components: {
    dictionariesInput,
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      onJobId: '',
      visible: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      form: {},
      optionsReason: [
        {
          value: '1',
          label: '个人原因'
        },
        {
          value: '2',
          label: '企业原因'
        },
        {
          value: '3',
          label: '其他'
        }
      ],
      rules: {
        time: [
          {
            required: true,
            message: '请选择计划离职日期',
            trigger: 'change'
          }
        ],
        type: [
          {
            required: true,
            message: '请选择离职方式',
            trigger: 'change'
          }
        ],
        reason: [
          {
            required: true,
            message: '请选择原因',
            trigger: 'change'
          }
        ],
        mark: [{}]
      }
    }
  },

  methods: {
    // 打开弹窗
    open(val) {
      this.onJobId = val.record.onJobId
      this.visible = true
    },
    // 保存事件
    handleOk() {
      // eslint-disable-next-line space-before-function-paren
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const res = await noOfficial({
            onJobId: this.onJobId,
            planLeaveDate: this.form.time,
            handleType: Number(this.form.type),
            leaveReasons: this.form.reason,
            remark: this.form.remark
          })
          if (res.code === 100) {
            this.$message.success('不予转正成功')
            this.$emit('getFetchDataApiFn')
            this.visible = false
          }
        }
      })
    },

    // 取消事件
    handleCancel() {
      this.visible = false
      this.resetForm()
    },
    // 重置表单内容和验证
    resetForm() {
      this.form = {}
      this.$refs.ruleForm.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
.ant-calendar-picker{
  width: 100%;
}
.v-title {
  margin-bottom: 16px;
  color: @error-color;
}
</style>
