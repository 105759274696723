<template>
  <div class="day beauty-scroll">
    <div class="toobar-left">
      <svg class="iconpark-icon"><use href="#rongqi" /></svg>
      <a-date-picker v-model="time" value-format="YYYY-MM-DD" :bordered="false" @change="onChange">
        <span>{{ time }}</span>
      </a-date-picker>
      <!-- <a-button class="month" @click="onday">当天</a-button> -->
    </div>
    <CbStatistics :data-list="statisticsList" length="6" class="statistics" text-align="center" />
    <div class="title">日排班统计</div>
    <div id="chart" />
  </div>
</template>
<script>
import * as echarts from 'echarts/core'
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import moment from 'moment'
import { staffShiftCountApi, dayDetailApi } from '@/services/attendance/scheduling.js'
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
])
export default {
  name: 'Month',
  components: {
    CbStatistics: () => import('@/components/CbStatistics/index')
  },
  data() {
    return {
      time: moment(new Date()).format('YYYY-MM-DD'),
      date: '',
      statisticsList: [{
        label: '本日排班总工时/小时',
        num: '0'
      },
      {
        label: '本日调班人数',
        num: '0'
      },
      {
        label: '本日应出勤人数',
        num: '0'
      },
      {
        label: '本日实际出勤人数',
        num: '0'
      }],
      // 考勤时间段
      attendanceManTime: [],
      // 计划在岗人数
      expect: [],
      // 实际到岗人数
      fact: [],
      myChart: null
    }
  },
  watch: {
    attendanceManTime() {
      this.echarts()
    }
  },
  mounted() {
    // const m = this.detectZoom()
    // document.getElementById('chart').style.zoom = Number(m) / 100
    // setTimeout(() => {
    //   this.echarts()
    //   this.$nextTick(() => {
    //     this.myChart.resize()
    //   })
    // })
  },
  created() {
    this.staffShiftCount()
    this.dayDetail()
  },
  methods: {
    detectZoom() {
      var ratio = 0
      var screen = window.screen
      var ua = navigator.userAgent.toLowerCase()
      if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio
      } else if (~ua.indexOf('msie')) {
        if (screen.deviceXDPI && screen.logicalXDPI) {
          ratio = screen.deviceXDPI / screen.logicalXDPI
        }
      } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
        ratio = window.outerWidth / window.innerWidth
      }

      if (ratio) {
        ratio = Math.round(ratio * 100)
      }
      return ratio
    },
    // 获取统计信息
    staffShiftCount() {
      staffShiftCountApi({
        type: 'day',
        daydate: `${this.time.split('-').join('')},${this.time.split('-').join('')}`
      }).then(res => {
        this.statisticsList[0].num = res.data.sumManHour || '0'
        this.statisticsList[1].num = res.data.sumChangeShiftManTime || '0'
        this.statisticsList[2].num = res.data.expectAttendanceManTime || '0'
        this.statisticsList[3].num = res.data.factAttendanceManTime || '0'
      })
    },
    // 日排班统计
    dayDetail() {
      dayDetailApi({
        type: 'day',
        daydate: `${this.time.split('-').join('')},${this.time.split('-').join('')}`
      }).then(res => {
        console.log(res)
        const attendanceManTime = []
        const expect = []
        const fact = []
        res.data.expect.forEach(item => {
          attendanceManTime.push(item.dayTime)
          expect.push(item.attendanceManTime)
        })
        res.data.fact.forEach(item => {
          fact.push(item.attendanceManTime)
        })
        this.attendanceManTime = attendanceManTime
        this.expect = expect
        this.fact = fact
      })
    },
    onChange(date, dateString) {
      // console.log(date, dateString)
      this.dayDetail()
      this.staffShiftCount()
    },
    echarts() {
      var chartDom = document.getElementById('chart')
      this.myChart = echarts.init(chartDom)
      var option
      option = {
        tooltip: {
          trigger: 'axis',
          extraCssText: 'font-size:14px'
        },
        legend: {
          padding: [20, 0, 0, 40],
          x: 'left',
          data: ['计划在岗人数', '实际出勤人数'],
          itemHeight: 10,
          itemWidth: 16,
          textStyle: { 'fontSize': 14 }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          // X轴数据 dayTime
          data: this.attendanceManTime
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '计划在岗人数',
            type: 'line',
            data: this.expect
          },
          {
            name: '实际出勤人数',
            type: 'line',
            data: this.fact
          }
        ]
      }

      option && this.myChart.setOption(option)
    },
    disabledDate(current) { // 不可选择未来日期
      return current > moment().endOf('day')
    }
    // onday() {
    //   this.time = moment(new Date()).format('YYYY-MM-DD')
    //   this.dayDetail()
    //   this.staffShiftCount()
    // }
  }
}
</script>
<style lang="less" scoped>
#chart{
  width:100%;
  height:350px;
}
.day{
    width: 100%;
    height: auto;
    background-color: @base-bg-color;
}
.toobar-left{
  width: auto;
  height: 34px;
  background-color: @sc-greyBg-10;
  display: inline-flex;
  padding: 0  0 0 16px;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 16px;
  .month{
    width: 63px;
    border: 0;
    height: 30px;
    border-radius: 0;
    padding: 0 10px;
    margin-right: 2px;
  }
  .iconpark-icon{
    width: 20px;
    height: 20px;
    color: @sc-primary-100;
  }
  .ant-calendar-picker {
    width: auto;
    padding: 0 16px;
    line-height: 30px;
    text-align: center;
    color: @sc-grey-100;
    &:hover {
      color: @sc-primary-100;
      cursor: pointer;
    }
    /deep/.ant-calendar-picker-input{
      border: 0;
    }
  }
}
.title{
  font-size:16px;
  font-weight: 600;
  margin: 16px 0;
  color: @sc-grey-100;
}
/deep/.statistics-center-item{
  position: relative;
  &:nth-child(-n+3)::after {
    content: '';
    position: absolute;
    right: 0;
    height: 30px;
    width: 1px;
    background-color: @sc-greyBg-20;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
