<!--
 * @Date: 2022-04-25 15:31:04
 * @descriotion: 管理
-->
<template>
  <div class="user-manage-panel">
    <a-tabs :default-active-key="defaultActiveKey" @change="callback">
      <a-tab-pane v-for="(key, index) in menuDataList" :key="index" :tab="key.name" />
    </a-tabs>
    <component :is="curComponentName" />
    <!-- <layout
      ref="layoutMenu"
      :menu-data-list="menuDataList"
      :selected-key.sync="selectedKey"
      class="left-menu-panel"
      @menu-select-change="menuSelectChange"
    >
      <template #leftTop>
        <div class="left-top-p" />
      </template>
      <template>
        <div class="atten-daily-tb-p beauty-scroll">
          <component :is="curComponentName" />
        </div>
      </template>
    </layout> -->
  </div>
</template>

<script>
import layout from '@/components/CbLayoutMenu/index'
import common from './common'
export default {
  name: 'Manage',
  components: {
    layout,
    ...common
  },
  data() {
    return {
      defaultActiveKey: 0,
      curComponentName: 'dailyAttendance',
      menuDataList: [
        {
          id: '1',
          name: '每日出勤',
          frontIcon: '#schedule',
          fileName: 'dailyAttendance',
          actions: []
        },
        {
          id: '2',
          name: '月度汇总',
          frontIcon: '#data-sheet',
          fileName: 'monthlySummary',
          actions: []
        },
        {
          id: '3',
          name: '月度关注',
          frontIcon: '#love-and-help',
          fileName: 'monthlyAttention',
          actions: []
        }
      ]
    }
  },
  created() {
    // 首页跳转参数判断
    if (this.$route.params.isHome === 'monthlyAttention') {
      // 月度关注
      this.defaultActiveKey = 2
    }
  },
  methods: {
    callback(activeKey) {
      this.curComponentName = this.menuDataList[activeKey].fileName
      this.defaultActiveKey = activeKey
    }
  }
}
</script>

<style lang="less" scoped>
.user-manage-panel {
  min-height: 100%;
  background: #fff;
  padding: 20px 20px 0;
  overflow: auto;
}
/deep/ .ant-tabs-extra-content {
  line-height: 30px;
}
/deep/ .ant-tabs-nav-scroll {
  margin-top: -3px;
}
/deep/ .ant-tabs-tab {
  color: @sc-grey-100;
  padding-top: 0 !important;
  font-size: 16px;
  &:hover {
    color: @sc-primary-100;
  }
}
/deep/ .ant-tabs-tab-active {
  color: @sc-primary-100;
}
</style>
