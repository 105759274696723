<template>
  <div>
    <div class="v-line">
      <div class="v-line-items">
        <div v-for="(item, index) in childList" :key="index" class="v-line-item" :class="{ 'v-select': item.salaryItemType == selectType }" @click="handleType(item.salaryItemType)">
          <span class="v-line-item-num warningNum">{{ item.perNum }}</span>
          <span class="v-line-item-tit">{{ item.salaryName }}</span>
        </div>
        <div v-if="selectDataList.length>1" class="buttoms">
          <div v-for="(item,key) in selectDataList.length" :key="key" class="buttom" :class="key===code?'color':''" @click="onClick(key)" />
        </div>
      </div>
      <div class="v-line-right" :class="{ 'v-select': selectType== 'all' }" @click="handleType(selectData.salaryItemType)">
        <span class="v-line-item-num">{{ selectData.perNum }}</span>
        <span class="v-line-item-title">{{ selectData.salaryName }}</span>
      </div>
    </div>

    <!--  添加 搜索 筛选  -->
    <appForm
      :to-list="toList"
      :screen-roster-list="screenRosterList"
      width="400"
      @getSearchCallback="getSearchCallback"
      @screenRosterParams="screenRosterParams"
      @exportFileList="exportFileList"
    >
      <template v-if="selectType !== 'all' && isNotDetail" slot="customButton">
        <a-space>
          <a-button prefix="piliangdaoru" @click="importTotal">
            导入合计
          </a-button>
          <!-- A02	绩效 A03	考勤 A04	补贴 A05	奖金  这四类有导入合计 -->
          <a-button v-if="['A02', 'A03', 'A04', 'A05'].includes(selectType)" prefix="piliangdaoru" @click="importdetail">
            导入明细
          </a-button>
        </a-space>
      </template>
    </appForm>
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      row-key="onJobId"
      :offset-bottom="60"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="loadDataList"
    >
      <!-- 头像 -->
      <template slot="staffName" slot-scope="{ scope }">
        <CbTableAvater
          :table-avater="scope.record.avatar"
          :table-name="scope.record.staffName"
          :on-job-id="scope.record.onJobId"
          :staff-id="scope.record.staffId"
        />
      </template>
      <!-- 序号 -->
      <template slot="index" slot-scope="{ scope }">
        <span>{{ scope.index + 1 }}</span>
      </template>

      <!-- 操作列 v-if="isNotDetail && selectType !== 'all'"  -->
      <template slot="operation" slot-scope="{ scope }">
        <div v-if="isNotDetail && selectType !== 'all'" class="flex-align-center">
          <div class="details-btn-box">
            <a-button type="link" @click="handleEdit(scope)">编辑</a-button>
            <a-button type="link" @click="handleDetail(scope)">明细</a-button>
          </div>
          <!-- <a-popover placement="bottom" trigger="hover">
            <template slot="content">
              <div class="more-handle-btn">
                <a-button type="link" @click="handleEdit(scope)">编辑</a-button>
                <a-button type="link" @click="handleDetail(scope)">明细</a-button>
              </div>
            </template>
            <div class="more-handle">···</div>
          </a-popover> -->
        </div>
        <div v-else class="details-btn">
          <a-button type="link" @click="handleDetail(scope)">明细</a-button>
        </div>
      </template>
    </custom-table>
    <!-- 抽屉 -->
    <Drawer
      size="small"
      :title="`编辑${title}`"
      placement="right"
      :is-mask="false"
      :closable="false"
      :visible="compile"
      @close="onClose"
      @onSubmit="confirm"
    >
      <div class="content">
        <div class="contentItem">
          <div class="label">姓名</div>
          <div class="value">{{ editInfo.staffName }}</div>
        </div>
        <div class="contentItem">
          <div class="label">工号</div>
          <div class="value">{{ editInfo.staffNum }}</div>
        </div>
        <template v-for="(item, index) in editInfo.items">
          <div :key="`contentItem${index}`" class="contentItem">
            <div class="label">{{ item.salaryItemName }}</div>
            <div class="value">
              <CbNumber v-model="item.amount" :min="0" :precision="2" decimal-separator />
            </div>
          </div>
        </template>
      </div>
    </Drawer>
    <Drawer
      size="small"
      :title="`${title}明细`"
      placement="right"
      is-mask
      display-btn
      closable
      :visible="detailVisible"
      @close="detailOnClose"
    >
      <div class="detailContent">
        <div class="header">
          <div class="avater">
            <CopyAvater size="28" :on-job-id="detailInfo.onJobId" :avater-name="detailInfo.staffName" />
          </div>
          <div class="sum">{{ detailInfo.sum | stateFormatNum }}元</div>
        </div>
        <div class="detailItems">
          <template v-for="(item, index) in detailInfo.types">
            <div :key="`detailContentItem${index}`" class="detailContentItem">
              <div class="groupTitle">
                <div class="name">{{ item.salaryTypeName }}</div>
                <div class="amount">{{ item.amount | stateFormatNum }}元</div>
              </div>
              <div class="groupContent">
                <template v-for="(key, groupItemIndex) in item.items">
                  <div :key="`groupItem${groupItemIndex}`" class="groupItem">
                    <div class="label">{{ key.salaryItemName }}</div>
                    <div class="value">{{ key.amount | stateFormatNum }}</div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import { stateFormatNum } from '@/utils/num-handle.js'
import {
  getSalaryCalcInfo,
  getCalcSalaryTypePage,
  getSalaryStaffByTypeDetail,
  updateStaffSalary,
  getSalaryStaffDetail,
  exportCalcSalaryTypePage } from '@/services/wage/wagePayroll'
export default {
  components: {
    CopyAvater: () => import('@/components/CbAvater/theAvater.vue'),
    Drawer: () => import('@/components/CbDrawer'),
    CbTableAvater: () => import('@/components/CbTableAvater/index'),
    CbNumber: () => import('@/components/CbNumber')
  },
  filters: {
    stateFormatNum
  },
  mixins: [mixins],
  inject: ['salaryItemInfo', 'isNotDetail', 'batchShow'],
  data() {
    return {
      childList: [],
      selectData: {},
      code: 0,
      compile: false,
      detailVisible: false,
      selectType: 'all',
      toList: ['search', 'screen', 'export'], // search搜索框 screen筛选 choice位置选择弹窗 export导出
      screenRosterList: {
        list: [
          {
            type: 'selectDictionaries',
            label: '聘用形式',
            options: {
              width: '100%',
              parameter: 'engage_mode',
              clearable: true,
              placeholder: '请选择职级'
            },
            model: 'engageMode',
            key: 'selectDictionaries_1651022813841'
          },
          {
            type: 'selectDepartment',
            label: '部门',
            options: {
              width: '100%',
              clearable: true,
              placeholder: '请选择'
            },
            model: 'orgId',
            key: 'selectDepartment_1651022903831'
          },
          {
            type: 'selectPost',
            label: '岗位',
            options: {
              width: '100%',
              clearable: true,
              placeholder: '请选择'
            },
            model: 'postId',
            key: 'selectPost_1651022948111'
          },
          {
            'type': 'selectDictionaries',
            'label': '合同公司',
            'options': {
              'width': '100%',
              'parameter': 'contract_company',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'contractCoId',
            'key': 'selectDictionaries_1650962628706'
          },
          {
            'type': 'selectDictionaries',
            'label': '工作地点',
            'options': {
              'width': '100%',
              'parameter': 'work_address',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'addressId',
            'key': 'selectDictionaries_1650962628701'
          },
          {
            'type': 'selectDictionaries',
            'label': '员工状态',
            'options': {
              'width': '100%',
              'parameter': 'staff_status',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'staffStatus',
            'key': 'selectDictionaries_1650962628702'
          }
        ],
        config: {
          layout: 'horizontal',
          labelCol: {
            xs: 4,
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
            xxl: 4
          },
          labelWidth: 100,
          labelLayout: 'flex',
          wrapperCol: {
            xs: 18,
            sm: 18,
            md: 18,
            lg: 18,
            xl: 18,
            xxl: 18
          },
          hideRequiredMark: false,
          customStyle: ''
        }
      },
      indexList: {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        width: 50,
        fixed: true,
        customRender: (text, record, index) => `${index + 1}`
      },
      editInfo: {},
      detailInfo: {},
      selectDataList: [],
      columns: [],
      dataSource: []
    }
  },
  computed: {
    title() {
      let res = ''
      for (let i = 0; i < this.selectDataList.length; i++) {
        const item = this.selectDataList[i]
        if (item.salaryItemType === this.selectType) {
          res = item.salaryName
          break
        }
      }
      return res
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    onClick(val) {
      this.code = val
      this.childList = this.selectDataList[val]
    },
    async initData() {
      const res = await getSalaryCalcInfo({ groupCalcId: this.salaryItemInfo.groupCalcId })
      this.selectDataList = []
      res.data.items.forEach(item => {
        if (item.salaryItemType === 'all') {
          this.selectData = item
        } else if (item.salaryItemType === 'A08') {
          this.selectDataList.unshift(item)
        } else {
          this.selectDataList.push(item)
        }
      })
      const newArr = []
      for (let i = 0; i < this.selectDataList.length;) {
        newArr.push(this.selectDataList.slice(i, i += 5))// 5按一页5条显示
      }
      this.selectDataList = newArr
      console.log(this.selectDataList)
      this.childList = this.selectDataList[0]
    },
    fetchDataApiFn(param) {
      param['salaryType'] = this.selectType
      param['groupCalcId'] = this.salaryItemInfo.groupCalcId
      param['inputValue'] = param['inputValue'] ? param['inputValue'] : ''
      getCalcSalaryTypePage(param).then(res => {
        const columnObj = {
          'staffName': '105px',
          'staffNum': '140px'
        }
        this.columns = res.data.columns.map((item, index) => {
          console.log(item.dataType, '=dataType')
          return {
            title: item.fieldName,
            dataIndex: item.fieldCode,
            width: columnObj[item.fieldCode]
              ? columnObj[item.fieldCode] : `${item.fieldName.length * 30 + 20}px`,
            fixed: index < 4 ? 'left' : false,
            scopedSlots: item.fieldCode === 'staffName' ? { customRender: item.fieldCode } : '',
            ellipsis: true,
            dataType: item.dataType
          }
        })
        this.columns.unshift(this.indexList)
        this.columns.push(
          {
            title: '',
            dataIndex: new Date().getTime(),
            key: new Date().getTime(),
            width: 50 // 下面的注释代码方这里了，添加表头列宽
          },
          {
            title: '操作',
            dataIndex: 'operation',
            fixed: 'right',
            width: 100,
            scopedSlots: { customRender: 'operation' }
          })
        // this.columns.push({
        //   title: '',
        //   dataIndex: new Date().getTime()
        // })
        this.dataSource = res.data.content ? res.data.content.records : []
        this.pager.pageNum = res.data.content.pageNum || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.content.pageSize || 10 // 更新当前的分页信息
        this.total = res.data.content.total || 0
      })
    },
    // 导入合计
    importTotal() {
      this.batchShow('batchSetSalaryCalc', { salaryType: this.selectType })
    },
    importdetail() {
      this.batchShow('salaryIndexItemImportIndex', { salaryType: this.selectType })
    },
    async handleEdit(scope) {
      const params = {
        onJobId: scope.record.onJobId,
        groupCalcId: this.salaryItemInfo.groupCalcId,
        salaryType: this.selectType
      }
      const res = await getSalaryStaffByTypeDetail(params)
      this.editInfo = res.data
      this.compile = true
    },
    async handleDetail(info) {
      const res = await getSalaryStaffDetail({ onJobId: info.record.onJobId, groupCalcId: this.salaryItemInfo.groupCalcId })
      this.detailInfo = res.data
      this.detailVisible = true
    },
    async confirm() {
      const params = JSON.parse(JSON.stringify(this.editInfo))
      params['groupCalcId'] = this.salaryItemInfo.groupCalcId
      // 没输入的值处理下不传后台
      params.items = params.items.filter(item => {
        return item.amount || item.amount === 0
      })
      await updateStaffSalary(params)
      this.serchGetData()
      this.initData()
      this.$message.success('修改成功')
      this.compile = false
    },
    onClose() {
      this.compile = false
    },
    // 详情关闭
    detailOnClose() {
      this.detailVisible = false
    },
    // 搜索
    getSearchCallback(val) {
      this._queryParam.inputValue = val.searchValue
      this.serchGetData()
    },
    // 筛选
    screenRosterParams(data) {
      const { engageMode, orgId, postId, contractCoId, addressId, staffStatus } = data.screenCondition
      this._queryParam = {
        inputValue: data.searchValue,
        engageMode,
        orgId: orgId && orgId[0] && orgId[0].value,
        postId,
        contractCoId,
        addressId,
        staffStatus
      }
      this.serchGetData()
    },
    handleType(val) {
      this.selectType = val
      this.initData()
      this.serchGetData()
    },
    async exportFileList() {
      const params = JSON.parse(JSON.stringify(this._queryParam))
      params.pageNum = 1
      params.pageSize = -1
      await exportCalcSalaryTypePage(params)
    }
  }
}
</script>
<style lang="less" scoped>
.v-line {
  display: flex;
  margin-bottom: 20px;
  border-radius: 6px;
  justify-content: space-between;
  align-items: center;
  &-right{
    border-radius: 6px;
    padding: 17px 0;
    width: 330px;
    display: flex;
    border: 1px solid @sc-greyBg-20;
    flex-direction: column;
    align-items: center;
    cursor:pointer;
    &-num {
      .font-size(20px);
      color: @sc-grey-100;
      font-weight: bold;
    }
  }
  &-items{
    width: calc(100% - 354px);
    display: flex;
    position: relative;
    padding: 17px 0;
    border-radius: 6px;
    border: 1px solid @sc-greyBg-20;
    align-items: center;
    justify-content: space-evenly;
  }
  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor:pointer;
    color: @sc-warning-100;
    &-num {
      .font-size(20px);
      // color: @sc-grey-100;
      font-weight: bold;
    }
    &-tit {
      .font-size(16px);
    }
    &:hover{
     color: rgba(237, 123, 47, 0.8)
    }
  }
  .v-line-item-title{
    color: @sc-grey-60;
    .font-size(16px);
  }
  .v-select span{
    color: @sc-primary-100;
  }
}
.arr_form .ant-space .ant-btn{
  padding: 5px 10px;
  border-radius: 4px;
  border-color: @sc-greyBg-50;
  span {
    color: @sc-grey-100;
  }
}
.content{
  .contentItem{
    display: flex;
    margin-bottom: 15px;
    .label{
      width: 120px;
      color: @sc-grey-80;
    }
    .value{
      flex: 1;
    }
  }
}
.detailContent{
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 12px;
    .font-size(16px);
     color: @sc-grey-100;
     margin-bottom: 18px;
    .avater{
      display: flex;
      align-items: center;
      /deep/.box .user-name{
        font-size: 16px;
      }
    }
  }
  .detailContentItem{
    margin-bottom: 20px;
    .groupTitle{
      background: @sc-greyBg-10;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 12px;
      border-radius: 6px 6px 0 0;
      .font-size(14px);
      color: @sc-grey-100;
    }
    .groupContent{
      .groupItem{
        .font-size(14px);
        padding: 10px 12px;
        border-bottom: 1px solid @sc-greyBg-20;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .label{
          color: @sc-grey-80;
        }
        .value{
          color: @sc-grey-100;
        }
      }
    }
  }
}
.buttoms{
  position: absolute;
  bottom: 5px;
  width: auto;
  height: 6px;
  display: flex;
}
.buttom{
  width: 24px;
  height: 6px;
  margin-right: 6px;
  border-radius:6px ;
  background-color: @sc-primary-40;
  cursor: pointer;
}
.color{
  background-color: @sc-primary-100;
}
.more-handle-btn {
  display: flex;
  flex-direction: column;
}
.details-btn .ant-btn-link{
  padding: 0;
}
.more-handle {
  font-weight: 700;
  font-size: 20px;
  vertical-align: baseline;
  cursor: pointer;
  color: @sc-primary-100;
  margin-left: 6px;
}
.details-btn-box{
  display: flex;
  button{
    padding-left: 0;
    padding-right: 16px;
  }
}
</style>
