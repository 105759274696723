/*
 * @Date: 2022-04-27 09:19:51
 * @descriotion:
 */
import BasicInformation from './basicInformation/index'
import Attendancerules from './attendanceRules/index'
import ClockSetting from './clockSetting/index'
import ScheduleCalendar from './scheduleCalendar/index.vue'
export default {
  BasicInformation,
  Attendancerules,
  ClockSetting,
  ScheduleCalendar
}
