var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"top_navigation"},[_c('a-button',{staticClass:"top_navigation_button",attrs:{"type":"iconBtn","icon":"left"},on:{"click":_vm.goBack}}),_c('span',{staticClass:"archiveRecord"},[_vm._v("档案记录")])],1),_c('div',{staticStyle:{"margin-top":"4px"}},[_c('a-tabs',{on:{"change":_vm.callback},model:{value:(_vm.tabType),callback:function ($$v) {_vm.tabType=$$v},expression:"tabType"}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"待完善"}}),_c('a-tab-pane',{key:"2",attrs:{"tab":"待审核"}}),_c('a-tab-pane',{key:"3",attrs:{"tab":"已通过"}}),_c('a-tab-pane',{key:"4",attrs:{"tab":"未通过"}})],1)],1),_c('div',[_c('appForm',[_c('template',{slot:"customButton"},[_c('a-space',[(_vm.tabType === '1')?_c('a-button',{attrs:{"prefix":"add-one"},on:{"click":_vm.handleAdd}},[_vm._v(" 批量邀请完善 ")]):_vm._e()],1)],1)],2),_c('custom-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"row-key":"archivesPerfectId","table-ref":".user-list","pagination":{
        current: _vm.pager.pageNum,
        pageSize: _vm.pager.pageSize,
        total: _vm.total,
        showTotal: function (total) { return ("共 " + total + " 条数据"); },
        showQuickJumper: true,
        showSizeChanger: true
      }},on:{"change":_vm.loadDataList},scopedSlots:_vm._u([{key:"index",fn:function(ref){
      var scope = ref.scope;
return [_c('span',[_vm._v(_vm._s(scope.index + 1))])]}},{key:"staffName",fn:function(ref){
      var scope = ref.scope;
return [_c('CbTableAvater',{attrs:{"table-avater":scope.record.avatar,"on-job-id":scope.record.onJobId,"table-name":scope.record.staffName,"staff-id":scope.record.staffId}})]}},{key:"operation",fn:function(ref){
      var scope = ref.scope;
return [(_vm.tabType === '1')?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.getInvitationPerfect(scope.record.staffId, '是否确认邀请完善')}}},[_vm._v("邀请完善")]):_vm._e(),(_vm.tabType === '2')?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.entryAudit(scope)}}},[_vm._v("审核")]):_vm._e(),(_vm.tabType === '4')?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.getInvitationPerfect(scope.record.staffId, '是否重新发送邀请')}}},[_vm._v("重新发送邀请")]):_vm._e()]}}])})],1),_c('PerDepModel',{attrs:{"placeholder":'请输入部门、人员、工号',"get-per-data-list":_vm.getPerDataList,"search-value-list":_vm.searchValueList},on:{"perModelConfirm":_vm.perModelConfirm},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }