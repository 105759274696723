<template>
  <div class="basic-info-p">
    <div class="basic-info-avator" @click="$refs.cbavater.hasAvator ? imgDetail = true : imgDetail = false">
      <CbAvater ref="cbavater" :on-job-id="userInfo.onJobId" :avater-name="userInfo.staffName" :show-user-name="false" size="52" />
      <!-- <img v-if="imgShow" :src="`${basePath}/hrsaas-base/staff/getAvatar?onJobId=${userInfo.onJobId}&width=${28}&height=${28}`" alt="" class="img" @click="imgDetail = true" @error="imgError"> -->
      <!-- <div v-else class="img">{{ userInfo.staffName ? userInfo.staffName.split('')[userInfo.staffName.length - 2] + userInfo.staffName.split('')[userInfo.staffName.length - 1] : '' }}</div> -->
    </div>
    <div class="basic-info-contain">
      <div class="info-p">
        <div class="name">
          {{ userInfo.staffName }}<span>{{ userInfo.staffNum }}</span>
        </div>
        <div class="tel">
          {{ userInfo.phoneNum }}
        </div>
      </div>
      <div class="dept-p">
        <div class="com-info">
          <div class="com-info-text">部门：</div>
          <div class="com-info-val">{{ userInfo.orgName }}</div>
        </div>
        <div class="com-info">
          <div class="com-info-title">岗位：</div>
          <div class="com-info-value">{{ userInfo.postName }}</div>
        </div>
      </div>
      <div class="post-p">
        <div class="com-info">
          <div class="com-info-text">司龄：</div>
          <div class="com-info-val">{{ userInfo.companyAge }}</div>
        </div>
        <div class="com-info">
          <div class="com-info-title">汇报上级：</div>
          <div class="com-info-value">{{ userInfo.reportText }}</div>
        </div>
      </div>
      <div class="addr-p">
        <div class="com-info">
          <div class="com-info-text">工作地点：</div>
          <div class="com-info-val">{{ userInfo.workAddr }}</div>
        </div>
        <div class="com-info" />
      </div>
    </div>
  </div>
</template>

<script>
import { getStaffInfoByOnJobId } from '@/services/user/roster.js'
export default {
  components: {
    CbAvater: () => import('@/components/CbAvater/theAvater')
  },
  props: {
    onJobId: {
      type: String,
      default: ''
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      basePath: process.env.VUE_APP_API_BASE_URL,
      userInfo: {
        avatar: '',
        name: '',
        phoneNum: '',
        staffNum: '',
        duty: '',
        orgName: '',
        postName: '',
        companyAge: '',
        workAddr: ''
      },
      imgDetail: false,
      imgShow: true
    }
  },
  watch: {
    'onJobId': function(val) {
      if (val) {
        this.initUserBasicInfo()
      }
    }
  },
  created() {
    this.initUserBasicInfo()
  },
  methods: {
    // 初始化用户基础信息
    initUserBasicInfo() {
      getStaffInfoByOnJobId({
        onJobId: this.onJobId
      }).then((res) => {
        if (res.code === 100) {
          this.userInfo = res.data
          if (this.userInfo.onJobId !== '') {
            this.imgShow = true
          }
        }
      })
    },
    imgError() {
      this.imgShow = false
    }
  }
}
</script>

<style lang="less" scoped>
.basic-info-p {
  width: 100%;
  min-height: 80px;
  background-color: @sc-greyBg-10;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 50px;
  .basic-info-avator {
    margin-right: 20px;
    /deep/.box .avater-name{
      font-size: 14px;
    }
    .img {
      /*display: inline-block;*/
      width: 52px;
      height: 52px;
      border-radius: 50%;
      background: @sc-primary-80;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
      line-height: 52px;
    }
  }
  .basic-info-contain {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-grow: 1;
    .info-p {
      flex-basis: 15.5%;
      .name {
        color: @sc-grey-100;
        .font-size(14px);
        span {
          margin-left: 22px;
        }
      }
      .tel {
        margin-top: 10px;
        color: @sc-grey-100;
        .font-size(14px);
        .anticon-phone {
          margin-right: 10px;
        }
      }
    }
    .dept-p {
      .font-size(14px);
      flex-basis: 15.5%;
    }
    .post-p {
      .font-size(14px);
      flex-basis: 15.5%;
    }
    .addr-p {
      .font-size(14px);
      flex-basis: 53.5%;
    }
    .com-info {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .com-info-text {
        margin-top: 0;
        color: @sc-grey-60;
      }
      .com-info-title {
        margin-top: 10px;
        flex-shrink: 0;
        color: @sc-grey-60;
      }
      .com-info-value {
        margin-top: 10px;
        color: @sc-grey-100;
      }
      .com-info-val {
        color: @sc-grey-100;
      }
    }
  }
}
.imgcontainer {
  text-align: center;
}
</style>
