<template>
  <div>
    <div v-if="!infoVisible">
      <!--  添加 搜索 筛选  -->
      <appForm
        ref="appForm"
        :to-list="toList"
        :screen-roster-list="screenRosterList"
        width="400"
        @getSearchCallback="getSearchCallback"
        @screenRosterParams="screenRosterParams"
        @exportFileList="exportFileList"
      >
        <template slot="customButton">
          <a-space>
            <a-button prefix="add-one" @click="handleAdd">
              批量邀请完善资料
            </a-button>
            <a-button prefix="genjin" @click="handleRecord">
              完善记录
            </a-button>
          </a-space>
        </template>
      </appForm>
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        row-key="onJobId"
        table-ref=".user-list"
        :pagination="{
          current: pager.pageNum,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条数据`,
          showQuickJumper: true,
          showSizeChanger: true,
          change: changPages(pager.pageNum, pager.pageSize)
        }"
        @change="loadDataList"
      >
        <!-- 序号 -->
        <template slot="index" slot-scope="{ scope }">
          <span>{{ scope.index + 1 }}</span>
        </template>
        <!-- 姓名头像 -->
        <template slot="staffName" slot-scope="{ scope }">
          <CbTableAvater :table-avater="scope.record.avatar" :on-job-id="scope.record.onJobId" :table-name="scope.record.staffName" :staff-id="scope.record.staffId" />
        </template>
        <!-- 操作列 -->
        <template slot="operation" slot-scope="{ scope }">
          <a-button v-if="scope.record.ifShow" type="link" @click="getInvitationPerfect(scope.record.staffId)">
            <span class="operation-items">邀请完善</span>
          </a-button>
          <a-button v-if="scope.record.entryFormExamShow === 1" type="link" @click="entryAudit(scope)">
            入职登记表审核
          </a-button>
        </template>
      </custom-table>
    </div>
    <!--  完善信息  -->
    <Information v-else @goBack="goBack" />
    <PerDepModel
      v-model="model"
      :placeholder="'请输入部门、人员、工号'"
      :get-per-data-list="getPerDataList"
      :search-value-list="searchValueList"
      @perModelConfirm="perModelConfirm"
    />
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import mixinss from '@/components/CbPerDepSelection/index.js'
import CbTableAvater from '@/components/CbTableAvater/index'
import Information from './components/information'
import { getArchivesStatisticsList, invitationPerfect, getStaffList, invitationBatchPerfect, exportArchivesStaffList } from '@/services/user/archive.js'
export default {
  name: 'ArchiveTabel',
  components: {
    CbTableAvater,
    Information
  },
  mixins: [mixins, mixinss],
  data() {
    return {
      archiveStaffId: '',
      infoVisible: false, // 完善记录显示隐藏
      total: 0,
      model: false,
      toList: ['search', 'screen', 'export'], // search搜索框 screen筛选 choice位置选择弹窗 export导出
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: '50px',
          align: 'center',
          fixed: 'left',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          fixed: 'left',
          scopedSlots: { customRender: 'staffName' },
          width: 105,
          sorter: true
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          key: 'phoneNum',
          fixed: 'left',
          width: 120,
          ellipsis: true
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          width: 125,
          fixed: 'left',
          sorter: true,
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'org',
          key: 'org',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'post',
          key: 'post',
          ellipsis: true
        },
        {
          title: '职务',
          dataIndex: 'dutyName',
          key: 'dutyName',
          ellipsis: true
        },
        {
          title: '整体信息(%)',
          dataIndex: 'allRate',
          key: 'allRate'
        },
        {
          title: '个人信息(%)',
          dataIndex: 'infoRate',
          key: 'infoRate'
        },
        {
          title: '附件材料(%)',
          dataIndex: 'fileRate',
          key: 'fileRate'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          fixed: 'right',
          width: 100,
          scopedSlots: { customRender: 'operation' }
        }
      ],
      dataSource: [],
      screenRosterList: {
        'list': [
          {
            'type': 'selectDictionaries',
            'label': '性别',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'sex',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'sex',
            'key': 'selectDictionaries_1650962530138',
            'hasErrorValid': false
          },
          {
            'type': 'selectDictionaries',
            'label': '合同公司',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'contract_company',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'contract_company',
            'key': 'selectDictionaries_1650962628706'
          },
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'selectDepartment',
            'key': 'selectDepartment_1651110886169'
          },
          {
            'type': 'selectPost',
            'label': '岗位',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择岗位',
              'showSearch': false
            },
            'model': 'selectPost',
            'key': 'selectPost_1650963054281'
          },
          {
            'type': 'selectRank',
            'label': '职级',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'selectRank',
            'key': 'selectRank_1650963084744'
          },
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'engage_mode',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'employment',
            'key': 'selectDictionaries_1650963124409'
          },
          {
            'type': 'selectDictionaries',
            'label': '转正状态',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'staff_status',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'reincarnation_state',
            'key': 'selectDictionaries_1650963200418'
          },
          {
            'type': 'selectDictionaries',
            'label': '学历',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'education',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'education',
            'key': 'selectDictionaries_1650963300633'
          },
          {
            'type': 'selectDictionaries',
            'label': '学位',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'degree',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'academic_degree',
            'key': 'selectDictionaries_1650963353137'
          },
          {
            'type': 'selectDictionaries',
            'label': '学习形式',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'study_form',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'learning_form',
            'key': 'selectDictionaries_1650963403152'
          },
          {
            'type': 'selectDataRange',
            'label': '入职日期',
            'options': {
              'width': '100%',
              'defaultValue': [undefined, undefined],
              'rangePlaceholder': ['开始时间', '结束时间'],
              'rangeDefaultValue': [],
              'range': false,
              'showTime': false,
              'disabled': false,
              'hidden': false,
              'clearable': true,
              'placeholder': '请选择开始时间',
              'format': 'YYYY-MM-DD'
            },
            'model': 'entryStartDate',
            'key': 'date_1651214034712',
            'help': ''
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      }
    }
  },
  methods: {
    async fetchDataApiFn(param) {
      const res = await getArchivesStatisticsList(param)
      this.dataSource = res.data.records ? res.data.records : []
      this.pager.pageNum = res.data.pageNum || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.pageSize || 10 // 更新当前的分页信息
      this.total = res.data.total
    },
    showUserInfo(record) {
      this.$emit('showUserInfo', record.id)
    },
    // 批量完善
    handleAdd() {
      this.model = true
    },
    async getPerDataList(id, name) {
      const res = await getStaffList({
        orgId: id,
        staffName: name
      })
      return res.data
    },
    // 人员部门选择搜索
    async searchValueList(value) {
      return this.getPerDataList(null, value)
    },
    // 完善信息
    handleRecord() {
      this.infoVisible = true
      this.$emit('getInfoVisible', this.infoVisible)
    },
    // 完善
    getInvitationPerfect(staffId) {
      this.archiveStaffId = staffId
      this.$Reconfirm({
        title: '邀请完善',
        content: '是否邀请完善？'
      }).then(async() => {
        const res = await invitationPerfect({ staffId: this.archiveStaffId })
        if (res.code === 100) {
          this.$message.success('发送成功')
          // 邀请完善后是否刷新页面
          // this.serchGetData()
        }
      }).catch(() => {
        console.log('no')
      })
    },
    // 人员选择确认
    async perModelConfirm(data) {
      const staffIdList = []
      const orgIdList = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].identification === 'peo') {
          staffIdList.push(data[i].id)
        } else if (data[i].identification === 'dep') {
          orgIdList.push(data[i].id)
        }
      }
      if (data.length === 0) {
        this.$message.warning('请选择员工！')
        return
      }
      const res = await invitationBatchPerfect({
        staffIdList: staffIdList,
        orgIdList: orgIdList
      })
      if (res.code === 100) {
        this.$message.success('发送成功')
        this.model = false
        this.serchGetData()
      }
    },
    // 搜索
    getSearchCallback(data) {
      this._queryParam.searchWord = data.searchValue
      this.serchGetData()
    },
    // 筛选
    screenRosterParams(data) {
      const { sex, contract_company, selectDepartment, selectPost, selectRank, employment, reincarnation_state, education, academic_degree, learning_form, entryStartDate } = data.screenCondition
      const searchWord = data.searchValue
      this._queryParam = {
        searchWord: searchWord,
        sex: sex,
        contractCo: contract_company,
        orgId: selectDepartment ? selectDepartment[0].value : '',
        postId: selectPost,
        jobGradeId: selectRank,
        engageMode: employment,
        staffStatus: reincarnation_state,
        education: education,
        degree: academic_degree,
        studyForm: learning_form,
        entryStartDate: entryStartDate ? entryStartDate[0] : '',
        entryEndDate: entryStartDate ? entryStartDate[1] : ''
      }
      this.serchGetData()
    },
    // 返回回调
    goBack() {
      this.dataSource = []
      this._queryParam.searchWord = ''
      this.serchGetData()
      this.infoVisible = false
      this.$emit('getInfoVisible', this.infoVisible)
    },
    // 入表审核
    entryAudit(data) {
      this.$router.push({
        path: '/staffIndex/userDetail',
        query: {
          staffId: data.record.staffId,
          statusType: 'toExamine', // 审核
          onJobId: data.record.onJobId,
          path: encodeURIComponent(this.$route.fullPath)
        }
      })
    },
    // 导出
    exportFileList() {
      const { staffName, sex, contractCo, orgId, postId, jobGradeId, engageMode, staffStatus, education, degree, studyForm, entryStartDate, entryEndDate } = this._queryParam
      exportArchivesStaffList({
        staffName: staffName,
        sex: sex,
        contractCo: contractCo,
        orgId: orgId,
        postId: postId,
        jobGradeId: jobGradeId,
        engageMode: engageMode,
        staffStatus: staffStatus,
        education: education,
        degree: degree,
        studyForm: studyForm,
        entryStartDate: entryStartDate,
        entryEndDate: entryEndDate
      })
    },
    changPages(a, b) {
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-space-item {
  margin-right: 12px;
}
</style>
