<!-- 社保方案 -->
<template>
  <div v-if="show" class="socialSecurityScheme">
    <div class="socialSecurityScheme-head">
      <a-button prefix="add" @click="addScheme">
        新增社保方案
      </a-button>
    </div>
    <custom-table :columns="columns" :data-source="dataSource" :pagination="false" row-key="schemeId" @change="loadDataList">
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{ scope }">
        <span class="operation-btn-box">
          <a-button type="link" @click="edit(scope.record)">
            调整
          </a-button>
          <a-button type="link" class="delete" danger @click="deleteConfirm(scope.record)">
            删除
          </a-button>
        </span>
        <!-- <a-popover placement="bottom" trigger="hover">
          <template slot="content">
            <div class="more-handle-btn">
              <a-button type="link" @click="edit(scope.record)">
                调整
              </a-button>
              <a-button type="link" class="delete" danger @click="deleteConfirm(scope.record)">
                删除
              </a-button>
            </div>
          </template>
          <div class="more-handle">
            ···
          </div>
        </a-popover> -->
      </template>
    </custom-table>
  </div>
  <addScheme
    v-else
    :title="title === 'add' ? '新增社保方案' : '编辑社保方案'"
    :scheme-id="schemeId"
    insure-classify="social"
    :operate-type="title"
    @saveForm="saveForm"
    @back="back"
  />
</template>
<script>
import addScheme from '../components/addScheme.vue'
import { getSchemeListApi, createSchemeApi, updateSchemeApi, delSchemeApi } from '@/services/insurance/setting.js'
import mixins from '@/components/CustomTable/index.js'
export default {
  name: 'SocialSecurityScheme',
  components: {
    addScheme
  },
  mixins: [mixins],
  data() {
    return {
      num: '',
      schemeId: '',
      title: '',
      show: true,
      dataSource: [], // 列表数据
      columns: [
        {
          title: '社保参保城市',
          dataIndex: 'cityName',
          key: 'cityName',
          ellipsis: true,
          customCell: () => {
            return {
              style: {
                'min-width': '160px'
              }
            }
          }
        },
        {
          title: '社保参保方案',
          dataIndex: 'schemeName',
          key: 'schemeName',
          ellipsis: true,
          customCell: () => {
            return {
              style: {
                'min-width': '160px'
              }
            }
          }
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operation' },
          width: 100
        }
      ]
    }
  },
  methods: {
    getNum() {
      console.log(this.num)
    },
    // 获取数据
    async fetchDataApiFn() {
      const res = await getSchemeListApi({ insureClassify: 'social' })
      this.dataSource = res.data ? res.data : []
    },
    addScheme() {
      this.title = 'add'
      this.schemeId = ''
      this.show = false
    },
    edit(data) {
      this.title = 'edit'
      this.schemeId = data.schemeId // 方案id
      this.show = false
    },
    // 删除确认
    deleteConfirm(data) {
      this.$Reconfirm({
        title: '删除',
        content: '删除社保方案'
      }).then(() => {
        this.deletedOk(data.schemeId)
      }).catch(() => {})
    },
    // 确认删除
    deletedOk(schemeId) {
      const _this = this
      delSchemeApi({
        schemeId
      }).then((res) => {
        if (res.code === 100) {
          this.$message.success('删除成功')
          this.$nextTick(() => {
            _this.serchGetData()
          })
        } else {
          this.$message.error('删除失败')
        }
      })
    },
    // 由表单返回
    back(val) {
      this.show = val
    },
    // 保存
    async saveForm(val) {
      const _this = this
      let res = {}
      if (val.operateType === 'add') {
        res = await createSchemeApi(val.data)
      } else {
        res = await updateSchemeApi(val.data)
      }
      if (res.code === 100) {
        this.$message.success('保存成功')
        this.show = true
        this.$nextTick(() => {
          _this.serchGetData()
        })
      } else {
        this.$message.success('保存失败')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.socialSecurityScheme {
  padding: 20px;
  .socialSecurityScheme-head {
    /deep/.ant-btn {
      margin-bottom: 16px;
    }
  }
}
.more-handle {
  font-weight: 700;
  color: @sc-primary-100;
  font-size: 20px;
  vertical-align: baseline;
  cursor: pointer;
}
.more-handle-btn {
  display: flex;
  flex-direction: column;
  .ant-btn {
    height: 25px;
  }
}
.operation-btn-box{
  display:flex;
  button{
    padding-left:0;
    padding-right:16px;
  }
}
</style>
