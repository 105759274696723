<template>
  <div>
    <Modal :visible="visible" :closable="true" size="normal" title="调整入职日期" ok-text="保存" @ok="handleOk" @cancel="handleCancel">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="入职日期" prop="time">
          <a-date-picker v-model="form.time" :value-format="'YYYY-MM-DD'" placeholder="请选择时间" />
        </a-form-model-item>
      </a-form-model>
    </Modal>
  </div>
</template>

<script>
import { entryDate } from '@/services/user/Induction.js'
export default {
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      entryApplyIds: '',
      visible: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      form: {},
      rules: {
        time: [
          {
            required: true,
            message: '请选择日期',
            trigger: 'change'
          }
        ]
      }
    }
  },

  methods: {
    // 打开弹窗
    open(data) {
      this.visible = true
      this.entryApplyIds = data.record.entryApplyId
    },
    // 保存事件
    handleOk() {
      // eslint-disable-next-line space-before-function-paren
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.visible = false
          const res = await entryDate({
            entryApplyId: this.entryApplyIds,
            planEntryDate: this.form.time
          })
          if (res.code === 100) {
            this.$message.success('调整入职日期成功')
            this.$emit('getFetchDataApiFn')
          }
        }
      })
    },

    // 取消事件
    handleCancel() {
      this.visible = false
      this.resetForm()
    },
    // 重置表单内容和验证
    resetForm() {
      this.form = {}
      this.$refs.ruleForm.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
.ant-calendar-picker{
  width: 100%;
}
</style>
