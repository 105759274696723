<!--
 * @Date: 2022-07-05 14:41:46
 * @descriotion:
-->
<template>
  <div>
    <Drawer wrap-class-name="detaildrawer" :closable="false" :visible="visible" :show-confirm="false" size="large" :z-index="2" @close="onClose">
      <div class="content">
        <div id="userInfo" class="userInfo">
          <a-row type="flex" justify="start" align="middle">
            <a-col :span="2">
              <CbAvater
                :on-job-id="billDetailParam.onJobId"
                :avater-name="insureBillDetail.staffName"
                size="52"
                :show-user-name="false"
              />
            </a-col>
            <a-col :span="9">
              <p class="name">{{ insureBillDetail.staffName }}</p>
              <p v-if="detailType == 'company'" class="companyName">
                {{ insureBillDetail.insureClassifyDetails.length > 0 ? insureBillDetail.insureClassifyDetails[0].companyName : '' }}
              </p>
            </a-col>
            <a-col :span="12">
              <div class="money">
                <p class="total">
                  总计：{{
                    (
                      Number(companyTotal(insureBillDetail.insureClassifyDetails)) + Number(personTotal(insureBillDetail.insureClassifyDetails))
                    ).toFixed(2) | stateFormatNum
                  }}元
                </p>
                <p>
                  本月公司合计：{{ Number(companyTotal(insureBillDetail.insureClassifyDetails)).toFixed(2) | stateFormatNum }}元；本月个人合计：{{
                    Number(personTotal(insureBillDetail.insureClassifyDetails)).toFixed(2) | stateFormatNum
                  }}元
                </p>
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="detail beauty-scroll">
          <!-- <div class="reasons">
            <p>备注</p>
            <span v-show="!editFlag">点击下方补缴调差按钮，输入备注内容</span>
            <a-textarea v-show="editFlag" placeholder="请输入备注" :rows="2" />
          </div> -->
          <!-- <div v-show="showFlag && !editFlag" class="tips-text">
            <div>
              <a-icon type="exclamation-circle" style="color:#ed7b2f" />
              提示：可取消补缴调差、恢复初始值
            </div>
            <div class="undo-btn" @click="deleteVisible = true"><a-icon type="undo" />删除补缴调差</div>
          </div> -->
          <template v-for="(item, index) in insureBillDetail.insureClassifyDetails">
            <p v-if="detailType == 'person'" :key="`index${index}`">{{ item.companyName }}</p>
            <DetailTable :key="index" :data-form.sync="insureBillDetail.insureClassifyDetails[index]" :edit-flag="editFlag" :show-flag="showFlag" />
          </template>
        </div>
        <!-- <div class="btn-box"> -->
        <!-- <a-button @click="onClose">取消</a-button> -->
        <!-- <a-button v-if="!showFlag && !editFlag && !isLock()" type="primary" @click="startSupply">补缴调差</a-button>
          <a-button v-if="showFlag && editFlag && !isLock()" type="primary" @click="saveDetail">保存</a-button>
          <a-button v-if="showFlag && !editFlag && !isLock()" type="primary" @click="editFlag = true">编辑</a-button> -->
        <!-- </div> -->
      </div>
    </Drawer>
    <Modal :visible="deleteVisible" title="删除补缴调差" @cancel="deleteVisible=false" @ok="deletedOk">
      <p>确定要删除补缴调差吗？</p>
    </Modal>
  </div>
</template>
<script>
import { getInsureBillDetailApi, updateSupplyApi, deleteSupplyApi } from '@/services/insurance/bill'
import { stateFormatNum } from '@/utils/num-handle.js'
export default {
  name: 'DetailDrawer',
  filters: {
    stateFormatNum
  },
  components: {
    DetailTable: () => import('../component/table.vue'),
    CbAvater: () => import('@/components/CbAvater/theAvater.vue'),
    Drawer: () => import('@/components/CbDrawer/index'),
    Modal: () => import('@/components/CbModal/index')
  },
  props: {
    detailType: {
      // 面向类型，person:个人（由主页面明细tab而来） company:主体（由主页面账单明细而来）
      type: String,
      default: ''
    }
  },
  inject: ['initBillData'],
  data() {
    return {
      visible: false,
      editFlag: false, // 补缴调差编辑
      showFlag: false, // 补缴调差显示
      deleteVisible: false, // 删除补缴调差确认框
      billDetailParam: {},
      insureBillDetail: {
        // 员工账单详情
        staffName: '',
        companyTotal: '',
        personTotal: '',
        supplyShow: 0, // 0:不显示 1:显示
        total: '',
        insureClassifyDetails: []
      }
    }
  },
  computed: {
    companyTotal() {
      return (data) => {
        let sum = 0
        for (let i = 0; i < data.length; i++) {
          sum += Number(data[i].companyTotal)
        }
        return sum.toFixed(2)
      }
    },
    personTotal() {
      return (data) => {
        let sum = 0
        for (let i = 0; i < data.length; i++) {
          sum += Number(data[i].personTotal)
        }
        return sum.toFixed(2)
      }
    }
  },
  mounted() {},
  methods: {
    onShow(data) {
      this.billDetailParam = data
      getInsureBillDetailApi(data).then((res) => {
        if (res.code === 100) {
          this.insureBillDetail = res.data
          this.showFlag = this.insureBillDetail.supplyShow === 1
        }
      })
      this.visible = true
    },
    onClose() {
      this.editFlag = false
      this.showFlag = false
      this.visible = false
    },
    // 开始补缴调差
    startSupply() {
      this.editFlag = true
      this.showFlag = true
    },
    // 保存补缴调差
    saveDetail() {
      let list = []
      for (let i = 0; i < this.insureBillDetail.insureClassifyDetails.length; i++) {
        list = list.concat(this.insureBillDetail.insureClassifyDetails[i].insureTypeList || [])
      }
      const newList = list.map((v) => {
        return { billId: v.billId, companySupply: v.companySupply, personSupply: v.personSupply }
      })
      updateSupplyApi(newList).then((res) => {
        if (res.code === 100) {
          this.$message.success('保存成功')
          this.editFlag = false
          this.showFlag = true
          this.visible = false
          try {
            this.$emit('reloadData')
            this.initBillData() // 同步账单主页数据
            this.initInnerBillData() // 同步主体-账单页数据
          } catch (e) {
            console.log(e)
          }
        }
      })
    },
    // 删除补缴调差
    deletedOk() {
      deleteSupplyApi({
        onJobId: this.billDetailParam.onJobId,
        insureCompanyId: this.billDetailParam.insureCompanyId,
        insureMonth: this.billDetailParam.insureMonth
      }).then((res) => {
        if (res.code === 100) {
          this.$message.success('删除成功')
          this.deleteVisible = false
          this.editFlag = false
          this.showFlag = false
          this.visible = false
          try {
            this.$emit('reloadData')
            this.initBillData() // 同步账单主页数据
            this.initInnerBillData() // 同步主体-账单页数据
          } catch (e) {
            console.log(e)
          }
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;
  position: relative;
  .font-size(14px);
  .userInfo {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    p {
      margin: 0;
    }
    .name {
      color: @sc-grey-100;
      font-weight: 600;
      font-size: 18px;
    }
    .companyName {
      color: @sc-grey-100;
    }
    .money {
      text-align: right;
      color: @sc-grey-100;
      .total {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .detail {
    width: 100%;
    height: calc(100% - 160px);
  }
}
.reasons {
  display: flex;
  margin: 16px 0;
  p {
    width: 50px;
    color: @sc-grey-100;
  }
  span {
    color: @sc-grey-40;
  }
}
.tips-text {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 10px;
  background: #fff8f0;
  font-size: 14px;
  .look {
    color: @sc-primary-100;
    cursor: pointer;
    margin-right: 30px;
  }
  .undo-btn {
    color: @sc-primary-100;
    cursor: pointer;
  }
}
/deep/.tableFooter {
  padding: 0 143px;
  p {
    text-align: center !important;
  }
}
/deep/.avater-name {
  font-size: 16px !important;
}
</style>
<style lang="less">
.detaildrawer{
  .ant-drawer-header{
    display: none;
    z-index: 0;
  }
  .ant-drawer-body{
    top: 0;
    height: 100%;
  }
  .button{
    text-align: center;
  }
}
</style>
