<template>
  <div class="LegalModal">
    <Modal
      v-if="legalVisible"
      :z-index="8"
      :closable="true"
      :visible="legalVisible"
      :title="`${formType === 'add' ? '新增' : '设置'}法定节假`"
      size="normal"
      overflow-auto
      @ok="addLegal"
      @cancel="legalVisible = false"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="法定节假名称" prop="holidayName">
          <a-input
            v-model="form.holidayName"
            placeholder="请输入法定节假名称"
            :disabled="formType==='edit'&& !form.userDefinedFlag"
          />
        </a-form-model-item>
        <a-form-model-item label="放假时间" prop="holidayTime">
          <a-range-picker
            v-model="form.holidayTime"
            :value-format="format"
            :allow-clear="false"
            :disabled-date="disabledDate"
            @change="holidayTimeChange"
          />
        </a-form-model-item>
        <a-form-model-item v-if="form.holidayDays" label="放假天数">
          <span>{{ form.holidayDays }}天</span>
        </a-form-model-item>
        <a-form-model-item label="法定日期">
          <a-range-picker
            v-if="showFlag"
            v-model="form.legalDate"
            :value-format="format"
            :default-picker-value="rangePicker"
            :disabled-date="disabledLegalDate"
            :disabled="!form.holidayTime.length"
            @change="legalTimeChange"
          />
        </a-form-model-item>
        <a-form-model-item label="补班日期">
          <div v-for="(date, index) in form.adjustDates" :key="index">
            <a-date-picker
              v-model="date.offDayDate"
              :default-picker-value="moment(form.holidayTime[0], 'YYYY-MM-DD')"
              class="large-input"
              :value-format="format"
              :disabled-date="disabledLegalDate"
              placeholder="节假日期"
            />
            <a-date-picker
              v-model="date.workDayDate"
              class="large-input"
              :value-format="format"
              placeholder="补班日期"
              :disabled-date="disabledPunchDate"
            />
            <a-button class="closeBtn" type="iconBtn" icon="close-small" @click="form.adjustDates.splice(index,1)" />
          </div>
          <a-button class="addBtn" type="iconBtn" icon="add-one" @click="addAdjustDates" />
        </a-form-model-item>
      </a-form-model>
    </Modal>
  </div>
</template>

<script>
import {
  createFestival,
  updateFestival,
  selectFestivalDetail
} from '@/services/attendance/holidays.js'
import moment from 'moment'
import { sepcialCode } from '@/utils/reg.js'
import { debounce, dateMinus } from '@/utils/index.js'
export default {
  name: 'LegalModal',
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  props: {
    formType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      format: 'YYYY-MM-DD',
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      legalVisible: false,
      form: {},
      initData: {
        holidayName: '',
        holidayTime: [],
        startDate: '',
        endDate: '',
        holidayDays: '',
        legalDate: [],
        legalStartDate: '',
        legalEndDate: '',
        adjustDates: []
      },
      rules: {
        holidayTime: [
          { required: true, message: '请选择放假时间', trigger: 'change' }
        ],
        holidayName: [
          { required: true, message: '请输入法定节假名称', trigger: 'blur' },
          { pattern: sepcialCode, message: '法定节假节日不支持特殊字符', trigger: 'change' }
        ]
      },
      rangePicker: [],
      showFlag: true
    }
  },
  methods: {
    moment,
    // 获取法定节假日详情
    async getLegalDetails(holidayId) {
      const res = await selectFestivalDetail({ holidayId: holidayId })
      this.form = {
        ...res.data,
        holidayTime: [res.data.startDate, res.data.endDate],
        legalDate: [res.data.legalStartDate, res.data.legalEndDate]
      }
    },
    addLegal() {
      if (this.form.adjustDates && this.form.adjustDates.length > 0) {
        try {
          this.form.adjustDates.forEach(item => {
            if (!item.offDayDate || !item.workDayDate) {
              throw new Error('finish')
            }
          })
        } catch (e) {
          if (e.message === 'finish') {
            this.$message.error('请完善补班日期')
            return
          }
        }
      }
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          this.onSubmit()
          return
        }
        return this.$message.error('添加信息不完整！')
      })
    },

    open(holidayId) {
      this.legalVisible = true
      this.holidayId = holidayId
      this.form = { ...this.initData, adjustDates: [] }
      if (holidayId) {
        this.getLegalDetails(holidayId)
      }
      console.log('open', this.form)
    },
    // 限制请假日期范围  大于法定节假日范围
    disabledDate(current) {
      if (this.form.legalDate.length) {
        return moment(this.form.legalDate[0]) < current && current < moment(this.form.legalDate[1])
      }
    },
    // 补班日期
    disabledPunchDate(current) {
      if (this.form.legalDate.length) {
        return moment(this.form.legalDate[0]) <= current && current <= moment(this.form.legalDate[1])
      }
    },
    // 限制法定节假日日期范围  小于请假日期范围
    disabledLegalDate(current) {
      if (this.form.holidayTime.length) {
        return moment(this.form.holidayTime[0]) > current || current >= moment(this.form.holidayTime[1]).subtract(-1, 'day')
      }
    },
    onSubmit: debounce(async function() {
      if (this.formType === 'edit') {
        const res = await updateFestival({
          ...this.form,
          holidayId: this.holidayId
        })
        if (res.code === 100) {
          this.$message.success('编辑成功！')
          this.legalVisible = false
          this.$parent.serchGetData()
        }
        return
      }
      const res = await createFestival(this.form)
      if (res.code === 100) {
        this.$message.success('新增成功！')
        this.legalVisible = false
        this.$parent.serchGetData()
      }
    }, 500),
    // 放假时间获取起始
    holidayTimeChange(date) {
      if (date.length) {
        this.form.startDate = date[0]
        this.form.endDate = date[1]
        this.form.holidayDays = dateMinus(date[0], date[1]) + 1
        this.showFlag = false
        this.$nextTick(() => {
          this.rangePicker = [moment(date[0], 'YYYY-MM-DD'), moment(date[1], 'YYYY-MM-DD')]
          this.showFlag = true
        })
        return
      }
      this.form.holidayDays = undefined
    },
    // 法定日期获取起始
    legalTimeChange(date) {
      if (date.length) {
        this.form.legalStartDate = date[0]
        this.form.legalEndDate = date[1]
      }
    },
    addAdjustDates() {
      if (!this.form.adjustDates) {
        this.form.adjustDates = [{}]
        return
      }
      this.form.adjustDates.push({})
    }
  }
}
</script>

<style lang="less" scoped>
.closeBtn{
  /deep/.iconpark-icon{
    color:@sc-error-100;
  }
  &:hover {
    border-color: @sc-error-100;
    /deep/.iconpark-icon{
      color: @sc-error-100;
    }
  }
}
.large-input {
    width: 43% !important;
    margin-right: 10px;
    display: inline-block;
  }
  /deep/.ant-form-item {
    margin-bottom: 15px;
  }
/deep/.ant-calendar-picker{
  width: 100%;
}
/deep/ .ant-form-item-children {
  color: @sc-grey-80;
}
.addBtn{
  /deep/.iconpark-icon{
    color: @sc-primary-100;
  }
}
</style>
