<template>
  <div>
    <div class="title"><div class="title-left" />{{ dataList.insureMonth }}</div>
    <div class="tabs">
      <p>个人缴纳：<span>{{ dataList.personTotal | stateFormatNum }}</span> 元</p>
      <p>公司缴纳：<span>{{ dataList.companyTotal | stateFormatNum }}</span> 元</p>
    </div>
    <div class="table">
      <a-row class="table-head">
        <a-col :span="2" />
        <a-col v-for="(item,index) in dataList.insureList" :key="index" :span="colWidth" :title="item.insureName">
          {{ item.insureName }}
        </a-col>
      </a-row>
      <a-row class="table-content">
        <a-col :span="2" title="本人缴纳">
          本人缴纳
        </a-col>
        <a-col v-for="(item,index) in dataList.insureList" :key="index" :span="colWidth" :title="item.personPay">
          {{ item.personPay | stateFormatNum }}
        </a-col>
      </a-row>
      <a-row class="table-content">
        <a-col :span="2" title="公司缴纳">
          公司缴纳
        </a-col>
        <a-col v-for="(item,index) in dataList.insureList" :key="index" :span="colWidth" :title="item.companyPay">
          {{ item.companyPay | stateFormatNum }}
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import mixins from '@/components/CustomTable/index.js'
import { stateFormatNum } from '@/utils/num-handle.js'
export default {
  filters: {
    stateFormatNum
  },
  mixins: [mixins],
  props: {
    dataList: {
      type: [Object, Array],
      default: () => {}
    }
  },
  data() {
    return {
      colWidth: 6
    }
  },
  created() {
    if (this.dataList.insureList) {
      this.colWidth = parseInt(22 / this.dataList.insureList.length)
    }
  }
}
</script>
<style lang="less" scoped>
.title{
    font-size: 16px;
    color: @sc-grey-100;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin: 16px 0;
    .title-left{
        width: 3px;
        height: 14px;
        background: @sc-primary-100;
        margin-right: 6px;
    }
}
.tabs{
    display: flex;
    p{
        width: 25%;
        font-size: 16px;
        color: @sc-grey-60;
        span{
            color: @sc-grey-100;
        }
    }
}
/deep/.ant-table-thead > tr:first-child > th:first-child{
    border-top-left-radius: 12px;
    padding-left: 24px;
}
/deep/.ant-table-thead > tr:first-child > th:last-child{
    border-top-right-radius: 12px;
    padding-right: 24px;
}
/deep/.ant-table-tbody > tr:last-child > td:first-child{
    border-bottom-left-radius: 12px;
}
/deep/.ant-table-tbody > tr:last-child > td:last-child{
    border-bottom-right-radius: 12px;
}
.custom-table{
    margin-bottom: 30px;
}
.table{
font-size: 14px;
border: 1px solid @sc-greyBg-20;
border-radius: 12px;
}
.table-head{
  width: 100%;
  height: 44px;
  font-weight:600 ;
  text-align: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  line-height: 42px;
  background-color: @sc-greyBg-10;
}
.table-content{
  width: 100%;
  height: 42px;
  line-height: 42px;
  text-align: center;
  border-bottom: 1px solid @sc-greyBg-20;
  /deep/.ant-col{
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  /deep/.ant-col:nth-child(1){
    text-align: left;
    padding-left: 16px;
  }
  &:last-child{
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}
</style>
