<template>
  <div class="container">
    <!--  顶部返回  -->
    <!-- <div class="historyRecord" @click="callback">
      <a-icon type="left" class="icon" />
      <span>部门导入</span>
    </div> -->
    <BatchImport model-id="1544235909814231042" title="部门导入" :show-three="false" import-template="importOrg" @goBack="goBack" />
  </div>
</template>

<script>
import BatchImport from '@/components/CbBatchImport/newImport'
export default {
  name: 'ImportOrg',
  components: {
    BatchImport
  },
  methods: {
    goBack() {
      this.$emit('importCallback', false)
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  padding: 20px;
}
</style>
