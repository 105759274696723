<template>
  <div class="ToDoCalendar">
    <!-- 日历头部 -->
    <div class="ToDoCalendar-header flex-just-between flex-align-center">
      <div class="flex-align-center left-date">
        <a-icon class="icon" type="left" @click="previousMonth" />
        <span class="date">{{ moment(selectDate).format('YYYY. M') }}</span>
        <a-icon class="icon" type="right" @click="nextMonth" />
      </div>
      <!-- <div class="right-add"> -->
      <a-tooltip placement="bottom">
        <template slot="title">
          添加日程
        </template>
        <a-button type="iconBtn" icon="add-5o07n3n8" class="icon-btn" @click="addMatter" />
      </a-tooltip>
      <!-- </div> -->
    </div>
    <img src="@/assets/img/home/trees.png" alt="">
    <Calendar ref="calendar" :select-date="selectDate" @dateChange="dateChange" />
    <DateMatter :memo-matter-list="memoMatterList" :system-matter-list="systemMatterList" :select-date="selectDate" @handleEdit="handleEdit" @refresh="refresh" />
    <HandleModal ref="handleModal" @refresh="refresh" />
  </div>
</template>

<script>
import { getSchedule, todayEvents } from '@/services/home/calendar.js'
import { getDayAttendanceStaffList } from '@/services/home/attendance.js'
import moment from 'moment'
export default {
  name: 'ToDoCalendar',
  components: {
    Calendar: () => import('./components/calendar.vue'),
    DateMatter: () => import('./components/dateMatter.vue'),
    HandleModal: () => import('./components/handleModal.vue')
  },
  data() {
    return {
      moment,
      selectDate: moment(new Date()),
      memoMatterList: {},
      systemMatterList: []
    }
  },
  watch: {
    selectDate(date) {
      this.getTodayEvents()
      this.initSystemData()
    }
  },
  mounted() {
    // 获取人事今日事件查询
    this.getTodayEvents()
    // 获取某日迟到、早退、缺卡、旷工、请假、出差、外出、加班员工
    this.initSystemData()
  },
  methods: {
    async getTodayEvents() {
      const res = await getSchedule({ selectDate: this.selectDate.format('YYYY-MM-DD') })
      this.memoMatterList = res.data
    },
    async initSystemData() {
      this.systemMatterList = []
      // 获取考勤系统日程事件
      const attendance = await getDayAttendanceStaffList({ date: this.selectDate.format('YYYY-MM-DD') })
      this.systemMatterList = attendance.data
      // 获取人员系统日程事件
      const user = await todayEvents({ date: this.selectDate.format('YYYY-MM-DD') })
      this.systemMatterList = this.systemMatterList.concat(user.data)
    },
    addMatter() {
      this.$refs.handleModal.open()
    },
    previousMonth() {
      this.selectDate = moment(this.selectDate).subtract(1, 'months')
      this.$refs.calendar.dateObj = {}
      this.$refs.calendar.getMatterList()
    },
    nextMonth() {
      this.selectDate = moment(this.selectDate).add(1, 'months')
      this.$refs.calendar.dateObj = {}
      this.$refs.calendar.getMatterList()
    },
    dateChange(val) {
      this.selectDate = moment(val)
    },
    // 确认编辑
    handleEdit(data) {
      this.$refs.handleModal.open('edit', data)
    },
    // 刷新数据 1.当前日期备忘 2.日历数据
    refresh() {
      this.getTodayEvents()
      this.$refs.calendar.getMatterList()
    }
  }
}
</script>

<style lang="less" scoped>
.ToDoCalendar {
  position: relative;
  margin-top: 20px;
  border-radius: 8px;
  width: 100%;
  height: 641px;
  background: #ffffff;
  img {
    position: absolute;
    user-select: none;
    top: 41px;
    left: 0;
    width: 100%;
    height: 98px;
  }
  &-header {
    padding: 16px 14px 0 14px;
    display: flex;
    .left-date {
      user-select: none;
      .date {
        margin: 0 18px;
        .font-size(20px);
        color: @sc-grey-100;
        font-weight: bold;
      }
      .icon {
        color: @sc-primary-100;
        font-size: 14px;
        cursor: pointer;
      }
      .icon:hover{
        color: @sc-primary-60;
      }
    }
  }
}
/deep/.icon-btn{
  border: 0 !important;
  padding: 2px !important;
}
/deep/.iconpark-icon{
  width: 22px !important;
  height: 22px !important;
  color: @sc-primary-100 !important;
}
</style>
