import { render, staticRenderFns } from "./formulaBudget.vue?vue&type=template&id=408ab960&scoped=true&"
import script from "./formulaBudget.vue?vue&type=script&lang=js&"
export * from "./formulaBudget.vue?vue&type=script&lang=js&"
import style0 from "./formulaBudget.vue?vue&type=style&index=0&id=408ab960&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "408ab960",
  null
  
)

export default component.exports