<template>
  <div class="MoneyStatistics flex-center">
    <div class="num-box flex-just-between">
      <div class="num-item">
        <div class="num">
          <span>{{ costObj.laborCostSumCount }}</span>
          <span>万元</span>
        </div>
        <span>上月人力成本</span>
      </div>
      <div class="num-item">
        <div class="num">
          <span>{{ costObj.planSumCount }}</span>
          <span>万元</span>
        </div>
        <span>上月应发总工资</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MoneyStatistics',
  inject: ['costObj']
}
</script>
<style lang="less" scoped>
.MoneyStatistics {
  margin-top: 20px;
  .num-box {
    width: 307px;
    .num-item {
      display: flex;
      flex-direction: column;
      .num {
        span:first-child {
          .font-size(22px);
          color: @sc-grey-100;
          font-weight: bold;
        }
        span:nth-child(2) {
          margin-left: 6px;
          .font-size(14px);
          color: @sc-grey-60;
        }
      }
    }
    .num-item > span {
      margin-top: 5px;
      .font-size(14px);
      color: @sc-grey-60;
    }
  }
}
</style>
