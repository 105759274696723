<template>
  <div class="UpdateYearendBouns">
    <Drawer
      closable
      :is-mask="false"
      :visible="visible"
      title="编辑"
      size="small"
      @close="visible = false"
      @onSubmit="handleOk"
    >
      <a-form-model ref="ruleForm" :model="row" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="年终奖">
          <CbNumber v-model="row.bounsAmount" :decimal-separator="true" precision="2" :is-fill-zero="true" :maxlength="10" placeholder="请输入数字" />
        </a-form-model-item>
      </a-form-model>

    </Drawer>
  </div>
</template>

<script>
import { updateStaffYearendBouns } from '@/services/wage/yearendBouns.js'
import { debounce } from '@/utils/index.js'
export default {
  name: 'UpdateYearendBouns',
  components: {
    Drawer: () => import('@/components/CbDrawer'),
    CbNumber: () => import('@/components/CbNumber')
  },
  props: {
    row: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      visible: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 }
    }
  },
  methods: {
    // 编辑员工年终奖
    handleOk: debounce(async function() {
      const res = await updateStaffYearendBouns(this.row)
      if (res.code === 100) {
        this.$message.success('编辑成功！')
        this.visible = false
        this.$parent.serchGetData()
      }
    }, 500)
  }

}
</script>

<style lang="less" scoped>
/deep/ .ant-form-item-label > label {
  color: @sc-grey-80;
}
</style>
