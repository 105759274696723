var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('appForm',{ref:"appForm",attrs:{"to-list":_vm.toList,"screen-roster-list":_vm.screenRosterList,"width":"400"},on:{"getSearchCallback":_vm.getSearchCallback,"screenRosterParams":_vm.screenRosterParams,"exportFileList":_vm.exportFileList}},[_c('template',{slot:"customButton"},[(_vm.keyval === '1')?_c('a-button',{attrs:{"prefix":"add-one","type":"primary"},on:{"click":_vm.handleAdd}},[_vm._v(" 办理调动 ")]):_vm._e()],1)],2),_c('custom-table',{attrs:{"columns":_vm.columns,"tabs-height":36,"data-source":_vm.dataSource,"table-ref":".main-content","row-key":"transferApplyId","pagination":{
      current: _vm.pager.pageNum,
      pageSize: _vm.pager.pageSize,
      total: _vm.total,
      showTotal: function (total) { return ("共 " + total + " 条数据"); },
      showQuickJumper: true,
      showSizeChanger: true
    }},on:{"change":_vm.loadDataList},scopedSlots:_vm._u([{key:"index",fn:function(ref){
    var scope = ref.scope;
return [_c('span',[_vm._v(_vm._s(scope.index + 1))])]}},{key:"engageMode",fn:function(ref){
    var scope = ref.scope;
return [_c('span',[_vm._v(_vm._s(scope.engageMode === 0 ? '正式' : '非正式'))])]}},{key:"transferType",fn:function(ref){
    var scope = ref.scope;
return [_c('span',[_vm._v(_vm._s(scope.transferType === '1' ? '调岗' : scope.transferType === '2' ? '晋升' : '降级'))])]}},{key:"applyStatus",fn:function(ref){
    var scope = ref.scope;
return [_c('span',[(scope.record.applyStatus)?_c('StatusTag',{attrs:{"type":scope.record.applyStatus,"type-list":_vm.applyStatusList},on:{"click":function($event){return _vm.getCbApproveSteps(scope.record.flowId, scope.record.applyStatus)}}}):_vm._e()],1)]}},{key:"name",fn:function(ref){
    var scope = ref.scope;
return [_c('CbTableAvater',{attrs:{"table-avater":scope.record.avatar,"on-job-id":scope.record.onJobId,"table-name":scope.record.name,"staff-id":scope.record.staffId}})]}},{key:"operation",fn:function(ref){
    var scope = ref.scope;
return [(_vm.keyval == '2')?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.getransferDetails(scope.record.transferApplyId)}}},[_vm._v(" 详情 ")]):_vm._e()]}}])}),_c('TransferForm',{ref:"transferForm",on:{"getFetchDataApiFn":_vm.getFetchDataApiFn}}),_c('CbApproveSteps',{attrs:{"flow-id":_vm.flowId,"apply-status":_vm.applyStatus,"apply-status-list":_vm.applyStatusList},model:{value:(_vm.stepsVisible),callback:function ($$v) {_vm.stepsVisible=$$v},expression:"stepsVisible"}}),_c('TransferDetails',{ref:"TransferDetails"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }