var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('appForm',{attrs:{"to-list":_vm.formList,"search-placeholder":"请搜索姓名/工号","screen-roster-list":_vm.screenRosterList},on:{"screenRosterParams":_vm.screenRosterParams,"getSearchCallback":_vm.getSearchCallback,"exportFileList":_vm.exportFileList}},[_c('template',{slot:"customButton"},[_c('div',{staticClass:"custom-button"},[_c('a-button',{attrs:{"prefix":"piliangdaoru"},on:{"click":function($event){return _vm.$emit('importTable')}}},[_vm._v(" 批量导入 ")])],1)])],2),_c('custom-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"tabs-height":15,"row-key":'onJobId',"pagination":{
        current: _vm.pager.pageNum,
        pageSize: _vm.pager.pageSize,
        total: _vm.total,
        showTotal: function (total) { return ("共 " + total + " 条数据"); },
        showQuickJumper: true,
        showSizeChanger: true
      }},on:{"change":_vm.loadDataList},scopedSlots:_vm._u([{key:"index",fn:function(ref){
      var scope = ref.scope;
return [_c('span',[_vm._v(_vm._s(scope.index + 1))])]}},{key:"staffName",fn:function(ref){
      var scope = ref.scope;
return [_c('CbTableAvater',{attrs:{"staff-id":scope.record.staffId,"on-job-id":scope.record.onJobId,"table-avater":scope.record.avatar,"table-name":scope.record.staffName}})]}},{key:"operation",fn:function(ref){
      var scope = ref.scope;
return [_c('div',{staticClass:"flex-align-center"},[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.handleDelete(scope.record)}}},[_vm._v(" 添加 ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }