<template>
  <div class="blank-page">
    <div class="blank-page-content">
      <img :style="`width:90px`" src="@/assets/img/home/noData.png" alt="">
      <div class="text">{{ title }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BlankPage',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="less" scoped>
.blank-page{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blank-page-content{
    width: 30%;
    height: 30%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .text{
        text-align: center;
        width: 100%;
        font-size: 14px;
        color: @sc-grey-20;
    }
}
</style>
