<template>
  <div class="statistics">
    <div class="unit">
      <a-radio-group v-model="title">
        <a-radio-button value="1">
          日
        </a-radio-button>
        <a-radio-button value="2">
          周
        </a-radio-button>
      </a-radio-group>
    </div>
    <Week v-if="title=='2'" />
    <Day v-if="title=='1'" />
  </div>
</template>
<script>
import Week from './week.vue'
import Day from './day.vue'
export default {
  components: {
    Week,
    Day
  },
  data() {
    return {
      title: '1'
    }
  },
  methods: {}
}
</script>
<style lang="less" scoped>
.statistics{
  width: 100%;
  height: 100%;
  padding: 20px 20px 0;
  background: @base-bg-color;
  overflow: auto;
  position: relative;
}
.unit{
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
}
.button{
  width: 50%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button:hover{
  cursor: pointer;
}
</style>
