<template>
  <div class="slip-all">
    <div v-if="!isShow" class="slip-all-content">
      <a-button prefix="piliangdaoru" @click="handleAdd">
        <span>添加年终奖</span>
      </a-button>
      <CbNoData v-if="isRequest && !dataList.length" width="200" />
      <template v-else>
        <div v-for="item in dataList" :key="item.yearEndBounsId" class="slip-all-item flex-align-center">
          <CbStatistics :data-list="item.list" length="3" :is-title="true" :title="`${item.year}年年终奖`" :title-width="300" :is-logo="true" :is-function="true" class="p-statistics">
            <template slot="right">
              <div class="handle-box">
                <a-button prefix="chakan-chakanmingxi" @click="handleDetaile(item)">查看</a-button>
                <a-button v-if="!item.locked" class="edit" prefix="bianji" @click="handleEdit(item)">编辑</a-button>
                <a-button v-if="!item.locked" class="danger" prefix="shanchu" @click="handleDelete(item)">删除</a-button>
              </div>
            </template>
            <template slot="logo">
              <div v-if="item.locked" class="logo">
                <svg class="iconpark-icon"><use href="#suoding" /></svg>
                <span class="locking">已锁定</span>
              </div>
            </template>
          </CbStatistics>
        </div>
      </template>
    </div>
    <SlipTable v-if="isShow" :yearend-bouns-id="yearendBounsId" @goBack="goBack" />
    <AnnualForm ref="annualForm" />
  </div>
</template>

<script>
import { debounce } from '@/utils/index.js'
import { getEndyearBounsList, deleteYearendBouns } from '@/services/wage/yearendBouns.js'
export default {
  name: 'WageAnnualBonus',
  components: {
    SlipTable: () => import('./yearendBounsStaff/index.vue'),
    AnnualForm: () => import('./components/annualForm.vue'),
    CbStatistics: () => import('@/components/CbStatistics/index.vue'),
    CbNoData: () => import('@/components/CbNoData/index.vue')
  },
  data() {
    return {
      isShow: false,
      dataList: [],
      yearendBounsId: '',
      isRequest: false
    }
  },
  created() {
    this.initData()
  },
  methods: {
    // 初始化页面数据
    async initData() {
      const res = await getEndyearBounsList()
      if (res.code === 100) {
        this.dataList = []
        if (res.data.length > 0) {
          res.data.forEach(item => {
            this.dataList.push({
              tenantId: item.tenantId,
              yearendBounsId: item.yearendBounsId,
              locked: item.locked,
              year: item.year,
              list: [
                {
                  label: '发放人数',
                  num: item.staffNum || 0
                }, {
                  label: '预计发放金额',
                  num: item.estimateMount || 0,
                  unit: '元',
                  isMoney: true
                }, {
                  label: '实际发放金额',
                  num: item.actualMount || 0,
                  unit: '元',
                  isMoney: true
                }] })
          })
        }
        this.isRequest = true
      }
      console.log(this.dataList)
    },
    // 新增年终奖
    handleAdd() {
      this.yearendBounsId = undefined
      this.$refs.annualForm.open()
    },
    // 编辑年终奖
    handleEdit(item) {
      this.$refs.annualForm.open(item.yearendBounsId)
    },
    // 查看年终奖详情
    handleDetaile(item) {
      this.yearendBounsId = item.yearendBounsId
      this.isShow = true
    },
    // 删除年终奖
    handleDelete(item) {
      this.yearendBounsId = item.yearendBounsId
      this.$Reconfirm({
        title: '删除年终奖',
        content: '确定要删除该年度的年终奖数据吗?'
      }).then(() => {
        this.submitDel()
      }).catch(() => {
        console.log('no')
      })
    },
    // 确认删除
    submitDel: debounce(async function() {
      const res = await deleteYearendBouns({ yearendBounsId: this.yearendBounsId })
      if (res.code === 100) {
        this.$message.success('删除成功！')
        this.initData()
      }
    }, 500),

    goBack() {
      this.isShow = false
      this.initData()
    }
  }
}
</script>

<style lang="less" scoped>
.slip-all {
  background-color: @gray-1;
  padding:  20px;
  min-height: 100%;
  height: auto;
  &-item {
    height: 88px;
    font-size: 14px;
    margin-top: 20px;
    border-radius: 6px;
    border: 1px solid @border-color;
    .handle-box {
      display: flex;
      justify-content:space-around;
      align-items: center;
      .edit {
        margin: 0 14px;
      }
      .danger{
        color: @sc-error-100;
      }
    }
  }
}
.logo{
  width: 100%;
  border-radius:6px 0 ;
  height: 28px;
  background-color: @sc-primary-100;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .iconpark-icon {
    width: 14px;
    height: 14px;
    margin: 0 5px 0 0;
  }
  .locking {
    margin-bottom: 1px;
  }
}
.p-statistics {
  margin-bottom: 0!important;
}
</style>
