/* eslint-disable no-unused-vars */
<template>
  <div class="add-role">
    <div class="category-header">
      <a-button type="iconBtn" icon="left" @click="$emit('back')" />
      <div class="category-title">{{ formType ? '添加' : '编辑' }}审批角色</div>
    </div>
    <!-- <div class="category-title">{{ formType ? '添加' : '编辑' }}审批角色</div> -->

    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item ref="name" label="角色名称" prop="name">
        <a-input
          v-model="form.name"
          placeholder="1-20个字符，不能与现有角色名称重复"
        />
        <a-tooltip class="item" placement="right" trigger="hover">
          <template slot="title">
            <span>角色名称请不要包含部门负责人、HRBP、汇报对象和虚拟组织负责人系统预留名称</span>
          </template>
          <a-icon type="exclamation-circle" theme="filled" class="circle_icon" />
        </a-tooltip>
      </a-form-model-item>
    </a-form-model>

    <appForm @getSearchCallback="getSearchCallback">
      <template slot="customButton">
        <a-button type="primary" prefix="add-one" @click="addCategory">
          添加员工
        </a-button>
      </template>
    </appForm>
    <custom-table :columns="columns" :data-source="perDataList" :offset-bottom="52" table-ref=".add-role" :is-footer="true" :row-key="'onJobId'?'onJobId':'phoneNum'" :pagination="false">
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{ scope }">
        <span class="operation-btn-box">
          <a-button type="link" @click="setPermissions(scope.record)"> 设置权限 </a-button>
          <a-button type="link" class="delete" @click="delet(scope)"> 删除 </a-button>
        </span>
        <!-- <div class="flex-align-center">
          <a-popover placement="bottom" trigger="hover">
            <template slot="content">
              <div class="more-handle-btn">
                <a-button type="link" @click="setPermissions(scope.record)"> 设置权限 </a-button>
                <a-button type="link" class="delete" @click="delet(scope)"> 删除 </a-button>
              </div>
            </template>
            <div class="more-handle">···</div>
          </a-popover>
        </div> -->
      </template>
    </custom-table>
    <!-- 添加角色 -->
    <div class="vadd_button">
      <a-button class="close_button" @click="onClose">取消</a-button>
      <a-button type="primary" @click="addRose">确认</a-button>
    </div>

    <Drawer :title="titleMsg" :visible="visible" :closable="false" size="small" @close="visible = false" @onSubmit="onSubmin">
      <a-form-model ref="perRuleForm" :model="formStaff" :rules="perRules" :label-col="labelColW" :wrapper-col="wrapperColW">
        <a-form-model-item label="姓名" prop="userInfo">
          <PerTreeSelect
            v-if="!isSet"
            v-model="formStaff.userInfo"
            placeholder="请选择员工"
            mode-type="default"
            @getUserInfo="handleChange"
          />
          <a-input v-if="isSet" v-model="formStaff.staffName" :disabled="true" />
        </a-form-model-item>
        <a-form-model-item label="所属部门" prop="orgName">
          <a-input v-model="formStaff.orgName" :disabled="true" />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="phoneNumber">
          <a-input v-model="formStaff.phoneNum" :disabled="true" />
        </a-form-model-item>
        <a-form-model-item label="管理范围" prop="manageRange" required>
          <DepTreeSelect
            v-model="formStaff.manageRange"
            :multiple="true"
            :placholder="'请选择管理范围'"
            @perModelConfirm="perModelConfirm"
            @getSelectBlur="getSelectBlur"
          />
        </a-form-model-item>
      </a-form-model>
    </Drawer>
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import mixinsPer from '@/components/CbPerDepSelection/index.js'
import { getStaffInfo } from '@/services/user/roster.js'
import { getFlowRoleDetail, addFlowRole, updateFlowRole } from '@/services/logicFlow'
import Drawer from '@/components/CbDrawer/index'
export default {
  name: 'AddRole',
  components: {
    Drawer
  },
  mixins: [mixins, mixinsPer],
  props: {
    formType: {
      type: Number,
      default: 1
    },
    flowRoleId: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      titleMsg: '添加员工',
      labelCol: { span: 2 },
      wrapperCol: { span: 5 },
      labelColW: { span: 6 },
      wrapperColW: { span: 18 },
      visible: false,
      selectPersonList: [],
      deleteInfo: {},
      isSet: false,
      columns: [
        {
          title: '姓名',
          dataIndex: 'staffName',
          key: 'staffName',
          scopedSlots: { customRender: 'staffName' },
          width: '200px'
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          scopedSlots: { customRender: 'orgName' },
          width: '200px'
        },
        {
          title: '手机号',
          dataIndex: 'phoneNumber',
          key: 'phoneNumber',
          scopedSlots: { customRender: 'phoneNumber' },
          width: '150px'
        },
        {
          title: '管理范围',
          dataIndex: 'manageRangeName',
          key: 'manageRangeName',
          scopedSlots: { customRender: 'manageRangeName' },
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '250px'
        }
      ],
      form: {
        name: ''
      },
      formStaff: {},
      rules: {
        name: [
          { required: true, message: '请输入角色名', trigger: 'blur' },
          { min: 1, max: 20, message: '输入的名称应在1-20字符之间', trigger: 'blur' }
        ]
      },
      perRules: {
        userInfo: [{ required: true, message: '请选择人员', trigger: 'blur' }],
        manageRange: [{ required: true, message: '请选择管理范围', trigger: 'blur' }]
      },
      // 表格数据
      perDataList: []
    }
  },

  mounted() {
    if (!this.formType) this.getPerList()
  },
  methods: {
    async getPerList() {
      const res = await getFlowRoleDetail({ flowRoleId: this.flowRoleId })
      this.form.name = res.data.flowRoleName
      this.perDataList = res.data.userList ? res.data.userList.map((item) => {
        const { userId, userName, orgId, orgName, phoneNumber, manageRange, manageRangeCode } = item
        return {
          onJobId: userId,
          staffName: userName,
          orgId: orgId,
          orgName: orgName,
          phoneNumber,
          manageRangeName: manageRange,
          manageRangeCode: manageRangeCode
        }
      }) : []
    },
    getSearchCallback() {},
    addCategory() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.visible = true
          this.isSet = false
          this.formStaff = {}
        }
      })
    },
    async selectPerson(val) {
      const res = await getStaffInfo({ onJobId: val })
      if (res.code === 100 && res.data) {
        this.selectPersonList = res.data
      }
    },
    // 选择人改变时候
    async handleChange(val) {
      if (!val) return
      const { label, value } = val[0]
      const res = await getStaffInfo({ onJobId: value })
      if (!res.data) return
      this.selectPersonList = res.data
      const { orgName, phoneNum, orgId } = this.selectPersonList
      this.$set(this.formStaff, 'onJobId', value)
      this.$set(this.formStaff, 'userId', value)
      this.$set(this.formStaff, 'staffName', label)
      this.$set(this.formStaff, 'orgId', orgId)
      this.$set(this.formStaff, 'orgName', orgName)
      this.$set(this.formStaff, 'phoneNum', phoneNum)
      this.$set(this.formStaff, 'phoneNumber', phoneNum)
    },
    // 删除弹框确认
    delet(val) {
      this.deleteInfo = val.record
      this.deleteInfo.index = val.index
      this.$Reconfirm({
        title: '删除人员',
        content: `确定删除${this.deleteInfo.staffName}?`
      }).then(() => {
        // 确定删除
        this.perDataList.splice(this.deleteInfo.index, 1)
      }).catch(() => {
        console.log('no')
      })
    },
    perModelConfirm(data) {
      this.perDataList = []
      data.map((item) => {
        if (item.identification === 'peo') {
          this.perDataList.push(item)
        }
      })
    },
    getSelectBlur(val) {
      if (val) {
        this.$refs.perRuleForm.clearValidate('manageRange')
      }
    },
    // 添加/编辑 关闭
    onClose() {
      this.visible = false
      this.$emit('back')
    },
    // 点击确定
    onSubmin() {
      this.$refs.perRuleForm.validate((valid) => {
        if (valid) {
          const ids = []
          const lables = []
          this.formStaff.manageRange.forEach((element) => {
            ids.push(element.value)
            lables.push(element.label)
          })
          this.formStaff.manageRangeCode = ids.join(',')
          this.formStaff.manageRangeName = lables.join(',')
          // 设置权限
          if (this.isSet) {
            let arrayIndex = -1
            this.perDataList.forEach((item, index) => {
              if (item.onJobId === this.formStaff.userInfo) {
                arrayIndex = index
              }
            })
            // this.perDataList[arrayIndex] = this.formStaff
            this.$set(this.perDataList[arrayIndex], 'manageRangeName', this.formStaff.manageRangeName)
            this.$set(this.perDataList[arrayIndex], 'manageRangeCode', this.formStaff.manageRangeCode)
            this.perDataList = JSON.parse(JSON.stringify(this.perDataList))
          // 添加员工
          } else {
            this.perDataList.push(this.formStaff)
          }
          this.visible = false
        }
      })
    },
    // 添加角色确定
    async addRose() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
        // 编辑
          const pre = {
            flowRoleName: this.form.name
          }
          pre.userList = this.perDataList.map((item) => {
            return {
              userId: item.onJobId,
              orgId: item.orgId,
              phoneNumber: item.phoneNum,
              manageRange: item.manageRangeCode
            }
          })
          if (this.flowRoleId) {
            pre.flowRoleId = this.flowRoleId
            const res = await updateFlowRole(pre)
            if (res.code === 100) {
              this.$message.success('编辑成功')
              this.$emit('getInit')
            }
          } else {
            const res = await addFlowRole(pre)
            if (res.code === 100) {
              this.$message.success('添加成功')
              this.$emit('getInit')
            }
          }
        }
      })
    },

    // 点击设置权限
    setPermissions(val) {
      this.isSet = true
      let manageRange = []
      if (val.manageRangeName) {
        manageRange = this.getnewarr(val.manageRangeName.split(','), val.manageRangeCode.split(','))
      }
      const { onJobId, staffName, orgName, phoneNumber } = val
      this.formStaff = {
        ...this.formStaff,
        userInfo: onJobId,
        staffName: staffName,
        orgId: onJobId,
        orgName: orgName,
        phoneNum: phoneNumber,
        manageRange
      }
      this.visible = true
    },
    getnewarr(keyArr, valueArr) {
      return keyArr.map((item, index) => {
        return {
          label: item,
          value: valueArr[index]
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-form-item-children {
  display: flex;
  align-items: center;
  .anticon-exclamation-circle{
    margin-left: 10px;
  }
}
.add-role {
  padding: 0;
  .category-header {
    background: #ffffff;
    font-size: 16px;
    color: @sc-grey-100;
    display: flex;
    align-items: center;
    margin-top: -3px;
    margin-bottom: 20px;
    .iconBtn{
      padding: 1px;
      width: 20px;
      height: 20px;
    }
  }
  .category-title {
    position: relative;
    padding-left: 10px;
    .font-size(16px);
    font-weight: bold;
    color: @sc-grey-100;
  }
}
.back {
  width: 100%;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;
}
.vadd_button {
  margin: 10px 0;
  text-align: center;
  .close_button {
    margin-right: 12px;
  }
}
.more-handle-btn {
  display: flex;
  flex-direction: column;
  .ant-btn {
    height: 25px;
  }
}
.more-handle {
  font-weight: 700;
  line-height: 44px;
  font-size: 20px;
  vertical-align: baseline;
  cursor: pointer;
  margin-left: 5px;
  color: @sc-primary-100;
}
/deep/ .ant-form-item {
  margin-bottom: 20px;
}
.operation-btn-box{
  button{
    padding-left:0;
    padding-right:16px;
  }
}
</style>
