var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ShiftManagement beauty-scroll",attrs:{"id":"shiftManagement"}},[_c('AlertTips',[_c('template',{slot:"content"},[_c('p',[_vm._v("1.班次管理，即设置每天的上下班时间，打卡数据根据班次显示有无异常。")]),_c('p',[_vm._v("2.支持为不同的部门/人员，设置不同的上下班时间。")])])],2),_c('appForm',{attrs:{"to-list":_vm.formList,"search-placeholder":"请搜索"},on:{"getSearchCallback":_vm.getSearchCallback}},[_c('template',{slot:"customButton"},[_c('div',{staticClass:"custom-button"},[_c('a-dropdown',[_c('a-button',{attrs:{"suffix":"left"}},[_vm._v(" "+_vm._s(_vm.typeName)+" ")]),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},_vm._l((_vm.typeList),function(type){return _c('a-menu-item',{key:type.typeId,on:{"click":function($event){return _vm.initData(type)}}},[_c('a',[_vm._v(_vm._s(type.typeName))])])}),1)],1),_c('a-button',{attrs:{"type":"primary","prefix":"add-one"},on:{"click":_vm.addShift}},[_vm._v(" 新增班次 ")]),_c('a-button',{staticClass:"del",attrs:{"prefix":"reduce-one","disabled":!_vm.selectedRowKeys.length},on:{"click":_vm.batchDelete}},[_vm._v(" 批量删除 ")])],1)])],2),_c('custom-table',{attrs:{"id":"table","columns":_vm.columns,"data-source":_vm.dataSource,"row-key":"shiftId","pagination":false,"row-selection":{
      selectedRowKeys: _vm.selectedRowKeys,
      columnWidth: 10,
      onChange: _vm.onSelectChange,
      getCheckboxProps: function (record) { return ({
        props: {
          disabled: record.shiftType === 'X',
        },
      }); },
    },"selection-mode":true},on:{"change":_vm.loadDataList},scopedSlots:_vm._u([{key:"colorThemeCode",fn:function(ref){
    var scope = ref.scope;
return [_c('div',{staticClass:"color-div",style:(_vm.getcolor(scope.record.colorThemeCode))})]}},{key:"shiftType",fn:function(ref){
    var scope = ref.scope;
return [_c('div',{staticClass:"ellipsis"},[_vm._v(_vm._s(_vm._f("shiftTypes")(scope.record.shiftType)))])]}},{key:"shiftPunchRulesDescription",fn:function(ref){
    var scope = ref.scope;
return [_c('div',{domProps:{"innerHTML":_vm._s(scope.record.shiftPunchRulesDescription || '-')}})]}},{key:"shiftFlexType",fn:function(ref){
    var scope = ref.scope;
return [_c('span',[_vm._v(_vm._s(_vm._f("flexType")(scope.record.shiftFlexType)))])]}},{key:"shiftHours",fn:function(ref){
    var scope = ref.scope;
return [_c('span',[_vm._v(_vm._s(scope.record.shiftHours?scope.record.shiftHours+'小时':'-'))])]}},{key:"operation",fn:function(ref){
    var scope = ref.scope;
return [_c('div',{staticClass:"flex-align-center"},[[_c('div',{staticClass:"more-handle-btn-box"},[_c('a-button',{attrs:{"disabled":scope.record&&scope.record.shiftType === 'X',"type":"link"},on:{"click":function($event){return _vm.editShfit(scope.record)}}},[_vm._v(" 编辑 ")]),_c('a-button',{staticClass:"delete",attrs:{"disabled":scope.record&&scope.record.shiftType === 'X',"type":"link"},on:{"click":function($event){return _vm.delet(scope.record)}}},[_vm._v(" 删除 ")])],1)]],2)]}}])}),_c('AddShiftModal',{ref:"shiftModal",attrs:{"form-type":_vm.formType}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }