<template>
  <div>
    <!-- <div class="v-line">
      <div v-for="item in selectDataList" :key="item.code" class="v-line-item" :class="{ 'v-select': item.type == selectType }" @click="handleType(item.type)">
        <span class="v-line-item-num">{{ item.num }}</span>
        <span class="v-line-item-title">{{ item.title }}</span>
      </div>
    </div> -->
    <CbNumStatistics :is-tabs="true" :statistics-info="statisticsInfo" class="Statistics" @changeTab="changeTab" />
    <!--  添加 搜索 筛选  -->
    <appForm
      :to-list="toList"
      :screen-roster-list="screenRosterList"
      search-placeholder="搜索员工/工号"
      width="400"
      @getSearchCallback="getSearchCallback"
      @screenRosterParams="screenRosterParams"
      @exportFileList="exportFileList"
    >
      <template v-if="isNotDetail" slot="customButton">
        <a-button v-if="selectType === 0" prefix="genjin" class="falsify-btn" @click="handleEdit">
          修改计薪人员
        </a-button>
        <a-button v-if="selectType === 1 && selectDataList[1].value" prefix="genjin" class="falsify-btn" @click="setDays">
          设置应出勤天数
        </a-button>
      </template>
    </appForm>
    <!--  table  -->
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      row-key="onJobId"
      :offset-bottom="60"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="loadDataList"
    >
      <!-- 序号 -->
      <template slot="index" slot-scope="{ scope }">
        <span>{{ scope.index + 1 }}</span>
      </template>
      <!-- 头像 -->
      <template slot="staffName" slot-scope="{ scope }">
        <CbTableAvater
          :table-avater="scope.record.avatar"
          :table-name="scope.record.staffName"
          :on-job-id="scope.record.onJobId"
          :staff-id="scope.record.staffId"
        />
      </template>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{ scope }">
        <div class="flex-align-center">
          <div class="more-handle">
            <a-button v-if="selectType === 4" type="link" @click="handleAdd(scope)"> 添加 </a-button>
            <a-button v-if="selectType !== 4" :disabled="selectDataList[0].value < 2&&dataSource.length < 2" type="link" @click="handleDelete($event, scope)"> 移除 </a-button>
          </div>
        </div>
      </template>
    </custom-table>
    <Drawer
      :visible="editVisible"
      title="修改计薪人员"
      :z-index="8"
      placement="right"
      size="small"
      :is-mask="false "
      @close="onClose"
      @onSubmit="onSubmin"
    >
      <div class="main">
        <div class="main-list">
          <div class="main-title">固定计薪人员</div>
          <p>选择的人员只要正常在职，在本月及之后每个月都会自动加入工资表</p>
          <div class="main-checked">
            <CbSelectPersons class="per" :query-leave-person="true" :data-echo="perDataList" @perModelConfirm="perModelConfirm" />
          </div>
        </div>
      </div>
    </Drawer>
    <CbModal
      :visible="setDaysVisible"
      size="small"
      title="设置应出勤天数"
      @ok="handleSetDays"
      @cancel="setDaysVisible = false"
    >
      <div class="tip">全月应出勤天数为，假设本月新入职人员为全月在职时的出勤天数，便于计算日薪</div>
      <div class="setDaysBox">
        <template v-for="(item, index) in setDaysPerList">
          <div :key="`setDaysItem${index}`" class="setDaysItem">
            <span class="name">{{ item.staffName }}</span>
            <CbNumber v-model="item.days" decimal-separator :max="31" :min="1" suffix="天" />
          </div>
        </template>
      </div>
    </CbModal>
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import {
  getSalaryCalcStaffInfo,
  getSalaryStaffPage,
  getSalaryStaffAll,
  updateStaffToGroup,
  deleteStaffToGroup,
  addStaffToGroup,
  exportSalaryStaffPage,
  editYqeycqts } from '@/services/wage/wagePayroll'
export default {
  components: {
    CbNumStatistics: () => import('@/components/CbNumStatistics/index.vue'),
    Drawer: () => import('@/components/CbDrawer'),
    CbTableAvater: () => import('@/components/CbTableAvater/index'),
    CbSelectPersons: () => import('@/components/CbSelectPersons/index'),
    CbModal: () => import('@/components/CbModal/index'),
    CbNumber: () => import('@/components/CbNumber/index')
  },
  inject: ['isNotDetail'],
  mixins: [mixins],
  props: {
    salaryItemInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      statisticsInfo: {},
      setDaysVisible: false,
      setDaysPerList: [],
      selectType: 0,
      toList: ['search', 'screen', 'export'], // search搜索框 screen筛选 choice位置选择弹窗 export导出
      screenRosterList: {
        list: [
          {
            type: 'selectDictionaries',
            label: '聘用形式',
            options: {
              width: '100%',
              parameter: 'engage_mode',
              clearable: true,
              placeholder: '请选择职级'
            },
            model: 'engageMode',
            key: 'selectDictionaries_1651022813841'
          },
          {
            type: 'selectDepartment',
            label: '部门',
            options: {
              width: '100%',
              clearable: true,
              placeholder: '请选择'
            },
            model: 'orgId',
            key: 'selectDepartment_1651022903831'
          },
          {
            type: 'selectPost',
            label: '岗位',
            options: {
              width: '100%',
              clearable: true,
              placeholder: '请选择'
            },
            model: 'postId',
            key: 'selectPost_1651022948111'
          },
          {
            'type': 'selectDictionaries',
            'label': '合同公司',
            'options': {
              'width': '100%',
              'parameter': 'contract_company',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'contractCoId',
            'key': 'selectDictionaries_1650962628706'
          },
          {
            'type': 'selectDictionaries',
            'label': '工作地点',
            'options': {
              'width': '100%',
              'parameter': 'work_address',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'addressId',
            'key': 'selectDictionaries_1650962628701'
          },
          {
            'type': 'selectDictionaries',
            'label': '员工状态',
            'options': {
              'width': '100%',
              'parameter': 'staff_status',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'staffStatus',
            'key': 'selectDictionaries_1650962628702'
          }
        ],
        config: {
          layout: 'horizontal',
          labelCol: {
            xs: 4,
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
            xxl: 4
          },
          labelWidth: 100,
          labelLayout: 'flex',
          wrapperCol: {
            xs: 18,
            sm: 18,
            md: 18,
            lg: 18,
            xl: 18,
            xxl: 18
          },
          hideRequiredMark: false,
          customStyle: ''
        }
      },
      selectDataList: [
        {
          type: 0,
          label: '计薪人员',
          code: 'total',
          value: '',
          selected: true
        },
        {
          type: 1,
          label: '本月入职',
          code: 'currMonthEntry',
          value: ''
        },
        {
          type: 2,
          label: '本月离职',
          code: 'currMonthLeave',
          value: ''
        },
        {
          type: 3,
          label: '本月新增',
          code: 'currMonthAdd',
          value: ''
        },
        {
          type: 4,
          label: '本月减少',
          code: 'currMonthDecrease',
          value: ''
        }
      ],
      columns: [],
      payrollPerson: [
        {
          title: '序号',
          dataIndex: 'index',
          width: 50,
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          scopedSlots: { customRender: 'staffName' },
          width: 105
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          ellipsis: true
        },
        {
          title: '聘用形式',
          dataIndex: 'engageModeName',
          key: 'engageModeName',
          ellipsis: true
        },
        {
          title: '员工状态',
          dataIndex: 'staffStatusName',
          key: 'staffStatusName',
          ellipsis: true
        },
        {
          title: '入职日期',
          dataIndex: 'entryDate',
          key: 'entryDate',
          ellipsis: true
        },
        {
          title: '离职日期',
          dataIndex: 'leaveDate',
          key: 'leaveDate',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      changePerson: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'index' },
          width: 50
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          scopedSlots: { customRender: 'staffName' },
          width: 105
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          ellipsis: true
        },
        {
          title: '入职日期',
          dataIndex: 'entryDate',
          key: 'entryDate',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      dataSource: [],
      editVisible: false,
      // 修改计薪人员的全部数据
      perDataList: []
    }
  },
  watch: {
    selectType() {
      if (this.selectType === 1) {
        const columnItem = {
          title: '全月应出勤天数',
          dataIndex: 'days',
          key: 'days',
          hide: true
        }
        this.columns.splice(7, 0, columnItem)
      } else {
        for (let i = 0; i < this.columns.length; i++) {
          const item = this.columns[i]
          if (item.key === 'days') {
            this.columns.splice(i, 1)
            break
          }
        }
      }
    },
    isNotDetail: {
      handler(val) {
        if (!val) {
          this.columns = this.payrollPerson.splice(0, this.payrollPerson.length - 1)
        } else {
          this.columns = this.payrollPerson
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    changeTab(val) {
      this.initData()
      this.handleType(val.type)
    },
    initData() {
      getSalaryCalcStaffInfo({ groupCalcId: this.salaryItemInfo.groupCalcId }).then(res => {
        this.selectDataList.forEach(item => {
          item.value = res.data[item.code]
        })
        this.statisticsInfo = {
          tabList: this.selectDataList
        }
      })
    },
    fetchDataApiFn(param) {
      param['groupCalcId'] = this.salaryItemInfo.groupCalcId
      param['type'] = this.selectType
      param['groupCalcId'] = this.salaryItemInfo.groupCalcId
      param['inputValue'] = param['inputValue'] ? param['inputValue'] : ''
      getSalaryStaffPage(param).then(res => {
        this.dataSource = res.data.records ? res.data.records : []
        this.pager.pageNum = res.data.current || 1
        this.pager.pageSize = res.data.size || 10
        this.total = res.data.total || 0
      })
    },
    // 移除
    handleDelete(e, info) {
      this.$Reconfirm({
        title: '移除',
        content: `是否确认将【${info.record.staffName}】从该计薪组中移除?`
      }).then(async() => {
        await deleteStaffToGroup({ groupCalcId: this.salaryItemInfo.groupCalcId, onJobId: info.record.onJobId })
        this.initData()
        this.serchGetData()
        this.$message.success('移除成功！')
      })
      // 处理Enter键触发多次弹窗生成的dom
      if (e.pointerId === '-1' || e.pointerId === -1) {
        document.querySelector('.CbReconfirm').remove()
      }
    },
    async handleAdd(info) {
      await addStaffToGroup({ groupCalcId: this.salaryItemInfo.groupCalcId, onJobId: info.record.onJobId, staffId: info.record.staffId })
      this.initData()
      this.serchGetData()
      this.$message.success('添加成功！')
    },
    // 搜索
    getSearchCallback(val) {
      this._queryParam.inputValue = val.searchValue
      this.serchGetData()
    },
    // 筛选
    screenRosterParams(data) {
      const { engageMode, orgId, postId, contractCoId, addressId, staffStatus } = data.screenCondition
      this._queryParam = {
        inputValue: data.searchValue,
        engageMode,
        orgId: orgId && orgId[0] && orgId[0].value,
        postId,
        contractCoId,
        addressId,
        staffStatus
      }
      this.serchGetData()
    },
    // 修改计薪人员
    async handleEdit() {
      const res = await getSalaryStaffAll({ groupCalcId: this.salaryItemInfo.groupCalcId })
      this.perDataList = res.data
      this.editVisible = true
    },
    // 修改计薪人员确定按钮
    perModelConfirm(data) {
      this.perDataList = data
    },
    async onSubmin() {
      const params = {
        groupCalcId: this.salaryItemInfo.groupCalcId,
        userList: this.perDataList
      }
      await updateStaffToGroup(params)
      this.editVisible = false
      this.initData()
      this.serchGetData()
    },
    // 设置应出勤天数
    async setDays() {
      const params = {
        type: this.selectType,
        groupCalcId: this.salaryItemInfo.groupCalcId,
        pageNum: 1,
        pageSize: -1
      }
      const res = await getSalaryStaffPage(params)
      this.setDaysPerList = res.data.records
      this.setDaysVisible = true
    },
    async handleSetDays() {
      const isNoEmpty = this.setDaysPerList.some((item) => !item.days)
      if (isNoEmpty) return this.$message.error('应出勤天数不能为空！')
      await editYqeycqts({
        groupCalcId: this.salaryItemInfo.groupCalcId,
        items: this.setDaysPerList
      })
      this.$message.success('设置成功！')
      this.setDaysVisible = false
      this.$$fetchListWithLoading(this._queryParam)
    },
    onClose() {
      this.editVisible = false
    },
    handleType(val) {
      this.selectType = val
      this.serchGetData()
    },
    async exportFileList() {
      const params = JSON.parse(JSON.stringify(this._queryParam))
      params.pageNum = 1
      params.pageSize = -1
      await exportSalaryStaffPage(params)
    }
  }
}
</script>
<style lang="less" scoped>
.main-list{
  margin-bottom: 40px;
  .main-title{
    color: @sc-grey-100;
    font-size: 14px;
    padding-left: 10px;
    margin-bottom: 14px;
    position: relative;
    &:before {
      content: '';
      width: 3px;
      height: 14px;
      background: @sc-primary-100;
      position: absolute;
      top: 4px;
      left: 0;
    }
  }
  p {
    color: @sc-grey-60;
    .font-size(14px);
    margin-bottom: 20px;
  }
  .main-checked{
    .per {
      /deep/ .ant-btn {
        margin-bottom: 10px;
        > .anticon {
            color: @sc-primary-100;
          }
        > span {
            color: @sc-grey-100;
          }
        &:hover {
          > span {
            color: @sc-primary-100;
          }
        }
      }
      /deep/ .scopeList .person {
        width: 23%;
      }
    }
  }
}
.falsify-btn{
  padding: 5px 10px;
  border-radius: 4px;
  border-color: @sc-greyBg-50;
  span {
    color: @sc-grey-100;
  }
}
.more-handle{
  .ant-btn-link{
    padding: 0 16px 0 0;
  }
}
.drawerBottom{
  position: 'absolute';
  right: 0;
  bottom: '10px';
  width: '100%';
  padding: '10px 16px';
  background: '#fff';
  text-align: right;
  z-index: 1;
}
.tip{
  margin-bottom: 10px;
  color: @sc-grey-100;
}
.setDaysItem{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  .name{
    color: @sc-grey-80;
    display: block;
    margin-right: 10px;
    width: 100px;
    .multi-ellipsis(1);
  }
}
.Statistics{
  margin-bottom: 20px;
}
/deep/.content-list li{
  position: relative;
  &:nth-child(-n+4)::after {
    content: '';
    position: absolute;
    right: 0;
    height: 30px;
    width: 1px;
    background-color: @sc-greyBg-20;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
