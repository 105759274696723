<template>
  <div class="CompanyAffair">
    <a-card
      hoverable
      :tab-list="tabListNoTitle"
      :active-tab-key="noTitleKey"
      size="small"
      class="affair-cart"
      @tabChange="key => onTabChange(key, 'noTitleKey')"
    >
      <div v-if="noTitleKey === '2'" slot="tabBarExtraContent" class="card-icon" href="#">
        <!-- 处理类型 -->
        <a-popover title="处理类型" placement="bottom" trigger="hover" overlay-class-name="screen-popover">
          <div slot="content" class="screen-content">
            <span v-for=" status in handleList" :key="status.status" class="flex-center">
              <span :class=" status.selected?'selected':''" class="user-name" @click="conditionQuery( status,type='recordType')">{{ status.label }}</span>
            </span>
          </div>
          <span class="icon-box">
            <svg class="iconpark-icon icon"><use href="#edit-name" /></svg>
            <!-- <a-icon class="icon" type="file-search" /> -->
          </span>
        </a-popover>
        <!-- 审批状态 -->
        <a-popover title="审批状态" trigger="hover" placement="bottom" overlay-class-name="screen-popover">
          <div slot="content" class="screen-content">
            <span v-for=" status in statusList" :key="status.status" class="flex-center">
              <span :class=" status.selected?'selected':''" class="user-name" @click="conditionQuery( status,type='recordApprovalStatus')">{{ status.label }}</span>
            </span>
          </div>
          <span class="icon-box">
            <svg class="iconpark-icon icon"><use href="#update-rotation" /></svg>
            <!-- <a-icon class="icon" type="read" /> -->
          </span>
        </a-popover>
        <!-- 事项类型 -->
        <a-popover title="事项类型" trigger="hover" placement="bottom" overlay-class-name="screen-popover" :auto-adjust-overflow="false">
          <div slot="content" class="screen-content">
            <span v-for="flow in flowSimpleList" :key="flow.flowId" class="flex-center">
              <span v-if="flow.flowName !== undefined &&flow.flowName.length <5" :class="flow.selected?'selected':''" class="user-name" @click="conditionQuery(flow,type='flowId')">{{ flow.flowName }}</span>
              <a-tooltip v-else>
                <template slot="title">
                  {{ flow.flowName }}
                </template>
                <span v-if="flow.flowName !== undefined" :class="flow.selected?'selected':''" class="user-name" @click="conditionQuery(flow,type='flowId')">{{ flow.flowName&&flow.flowName.slice(0,3) }}...</span>
              </a-tooltip>
            </span>
          </div>
          <span class="icon-box">
            <svg class="iconpark-icon icon"><use href="#list-top" /></svg>
            <!-- <a-icon class="icon" type="unordered-list" /> -->
          </span>
        </a-popover>
      </div>
      <div class="card-content" @scroll="scrolling">
        <AffairList v-if="affairList.length" :affair-list="affairList" :status-name-list="statusNameList" @refreshList="getDataList" />
        <CbNoData v-if="!affairList.length&&isRequest" />
        <div v-if="isInEnd" class="in-the-end flex-center">
          <span>我是有底线的～</span>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import { getRecordList, getFlowSimpleList } from '@/services/home/process.js'
import { debounce } from '@/utils/index.js'
export default {
  name: 'CompanyAffair',
  components: {
    AffairList: () => import('./components/affairList.vue'),
    CbNoData: () => import('@/components/CbNoData/index')
  },
  props: {},
  data() {
    return {
      isInEnd: false,
      isRequest: false,
      noTitleKey: '3',
      flowId: '',
      recordApprovalStatus: '',
      recordType: '',
      tabListNoTitle: [
        {
          key: '3',
          tab: '待审批'
        },
        {
          key: '1',
          tab: '审批中'
        },
        {
          key: '2',
          tab: '已完成'
        },
        {
          key: '4',
          tab: '抄送我'
        }
      ],
      // 审批状态
      statusList: [
        {
          status: '0',
          label: '全部',
          selected: true
        },
        {
          status: '1',
          label: '审批通过'
        },
        {
          status: '2',
          label: '审批未通过'
        },
        {
          status: '3',
          label: '已撤销'
        }

      ],
      // 处理类型
      handleList: [
        {
          status: '0',
          label: '全部',
          selected: true
        },
        {
          status: '1',
          label: '我发起的'
        },
        {
          status: '2',
          label: '我审批的'
        }
      ],
      // 事项筛选条件
      flowSimpleList: [],
      // 审批列表
      affairList: [],
      statusNameList: {
        inapprove: {
          name: '审批中',
          color: 'rgba(237, 123, 47, 1)',
          bgColor: 'rgba(237, 123, 47, .1)'
        },
        exception: {
          name: '等待HR干预', // 审批异常待处理
          color: 'rgba(237, 123, 47, 1)',
          bgColor: 'rgba(237, 123, 47, .1)'
        },
        exceptionend: {
          name: '审批终止',
          color: 'rgba(227, 77, 89, 1)',
          bgColor: 'rgba(227, 77, 89, .1)'
        },
        revokeapprove: {
          name: '撤销审批',
          color: 'rgba(143, 147, 161, 0.6)',
          bgColor: 'rgba(143, 147, 161, 0.1)'
        },
        rejectapprove: {
          name: '未通过',
          color: 'rgba(227, 77, 89, 1)',
          bgColor: 'rgba(227, 77, 89, .1)'
        },
        completeapprove: {
          name: '已通过',
          color: 'rgba(0, 168, 112, 1)',
          bgColor: 'rgba(0, 168, 112, .1)'
        }
      },
      // 页码
      pageNum: 1
    }
  },
  watch: {
    noTitleKey(newVal) {
      this.refreshData()
      if (newVal === '2') {
        this.getFlowSimpleList()
        return
      }
      this.flowId = ''
      this.recordApprovalStatus = ''
      this.recordType = ''
    },
    flowId(newVal) {
      this.refreshData()
    },
    recordApprovalStatus(newVal) {
      this.refreshData()
    },
    recordType(newVal) {
      this.refreshData()
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    onTabChange(key, type) {
      this[type] = key
    },
    // type:undefined 初始化 && 切换tabs栏数据更新
    // type:scroll 滚动到底部追加一页数据
    getDataList: debounce(async function(type) {
      this.isRequest = false
      const res = await getRecordList(
        {
          queryType: this.noTitleKey,
          recordApprovalStatus: this.recordApprovalStatus,
          recordType: this.recordType,
          flowId: this.flowId,
          pageSize: '10',
          pageNum: this.pageNum,
          timeType: '0'
        }
      )
      if (res.code === 100) {
        if (this.affairList.length && type === 'scroll' && !res.data.records.length) {
          this.isInEnd = true
          return
        }
        this.isRequest = true
        const list = res.data.records?.map((v) => {
          const addObj = this.statusNameList[v.processApproveStatus]
          return { ...v, ...addObj }
        })
        // 初次请求数据&&切换tabs事件
        if (type !== 'scroll') {
          this.affairList = list
          return
        }
        // 滚动下拉事件追加数据
        this.affairList = [...this.affairList, ...list]
      }
    }, 200),
    // 内容区域滚动事件
    scrolling(e) {
      const { scrollTop, scrollHeight, clientHeight } = e.srcElement
      // 判断是否滚动条是否滑动到底部
      // 屏幕像素小于 1920 小数问题 + 1
      if (scrollTop === 0) return
      if (window.screen.width < 1920) {
        if (scrollTop + 1 + clientHeight > scrollHeight && !this.isInEnd) {
          this.pageNum++
          this.getDataList('scroll')
          return
        }
      }
      if (scrollTop + clientHeight === scrollHeight && !this.isInEnd) {
        this.pageNum++
        this.getDataList('scroll')
      }
    },
    // 获取事项筛选条件
    async getFlowSimpleList() {
      Object.assign(this.$data.statusList, this.$options.data().statusList)
      Object.assign(this.$data.handleList, this.$options.data().handleList)
      const res = await getFlowSimpleList()
      this.flowSimpleList = res.data.filter(v => v.status === 1)
      this.flowSimpleList = [{ flowName: '全部', flowId: '', selected: true }].concat(this.flowSimpleList)
    },
    // 条件查询
    conditionQuery(data, type) {
      switch (type) {
        case 'flowId':
          this.flowId = data.flowId
          this.flowSimpleList.forEach(v => {
            v.selected = false
          })
          data.selected = true
          break
        case 'recordApprovalStatus':
          this.recordApprovalStatus = data.status
          this.statusList.forEach(v => {
            v.selected = false
          })
          data.selected = true
          break
        case 'recordType':
          this.recordType = data.status
          this.handleList.forEach(v => {
            v.selected = false
          })
          data.selected = true
          break
      }
      this.$forceUpdate()
    },
    // 更新数据
    refreshData() {
      this.affairList = []
      this.pageNum = 1
      this.isRequest = false
      this.isInEnd = false
      this.getDataList()
    }
  }
}
</script>

<style lang="less" scoped>
.CompanyAffair {
  margin-right: 20px;
  width: calc(50% - 10px);
  .affair-cart {
    height: 641px;
    border-radius: 8px;
  }
  .card-content{
    padding: 15px 13px 15px 14px;
    height:591px;
    overflow: auto;
    position: relative;
    .no-data{
      text-align:center;
      img{
        height:112px;
        width:126px;
      }
      div{
        margin-top: 20px;
        .font-size(14px);
        color: @sc-grey-80;
      }
    }
  }
  .in-the-end{
    color: @sc-grey-60;
    .font-size(14px);
  }
  .card-icon {
    display: flex;
    padding: 13px 0;
    .icon-box {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 14px;
      height: 24px;
      line-height: 24px;
      width: 24px;
      border-radius: 50%;
      background: @sc-greyBg-10;
      .icon {
        display: block;
        width: 14px;
        height: 14px;
      }
    }
    .icon-box:hover{
      .icon {
        color: @sc-primary-100;
      }
    }
  }
  .ant-card {
    border-radius: 8px;
    border-color:#fff ;
  }
  .ant-card:hover {
    box-shadow: none;
  }
  /deep/.ant-card-head {
    padding: 0 13px !important;
  }
  /deep/.ant-tabs-tab {
    margin-right: 40px;
    padding: 13px 6px;
    .font-size(16px);
    color: @sc-grey-100;
  }
  /deep/.ant-tabs-tab:hover,/deep/.ant-tabs-tab-active {
    color: @sc-primary-100;
    font-weight: 400;
  }
  /deep/.ant-tabs-extra-content {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /deep/.ant-card-body {
    padding: 0;
    height:591px;
  }
  // 滚动条
  ::-webkit-scrollbar {
      width: 4px;
    }
  ::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background: @sc-greyBg-10;
    }
  ::-webkit-scrollbar-track {
      display:none;
  }
}
</style>
<style lang="less">
.screen-popover{
  .ant-popover-title{
    color: @sc-grey-60;
    .font-size(14px);
    padding-left:12px !important;
  }
  .screen-content{
    width:300px;
    max-height: 200px;
    overflow:auto;
    padding-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    .user-name{
      display:inline-block;
      cursor: pointer;
      text-align: center;
      margin: 12px 0 0 12px;
      width: 84px;
      height: 26px;
      border-radius: 2px;
      border: 1px solid @sc-greyBg-20;
    }
    .user-name:hover{
      color: @sc-primary-100;
    }
    .selected{
      border:1px solid @sc-primary-100;
      color: @sc-primary-100;
    }
  }
  @media screen and(min-width: 1920px){
  .screen-content {
    max-height: 500px;
  }
}
  .ant-popover-inner-content {
    padding: 0px;
  }
  // 滚动条
  ::-webkit-scrollbar {
      width: 4px;
    }
  ::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background: @sc-greyBg-10;
    }
  ::-webkit-scrollbar-track {
      display:none;
  }
}
</style>
