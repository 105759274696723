<!--附件材料-->
<template>
  <div class="attachment-info-p">
    <div class="attachment-anchor">
      <Anchor
        v-if="showAnchor"
        ref="anchor"
        :title-list="anchorList"
        container=".attachment-info"
        :position="{ top: 20, left: 35, position: 'absolute' }"
      />
    </div>
    <div class="attachment-info beauty-scroll" :style="{ height: anchorHeight() }">
      <div v-for="(item, index) in infoList" :key="index" class="com-panel">
        <div :id="'grid_' + item.groupId" class="com-panel-head">
          <BlockTitle :title="item.groupName">
            <template slot="center">
              <div class="com-head-divide" />
            </template>
          </BlockTitle>
        </div>
        <div class="com-panel-body">
          <div v-for="(pic, picIndex) in item.children" :key="picIndex" class="com-panel-body-item">
            <UploadImage
              v-if="item.groupId == '1'"
              :prop-file-list="pic.fileId ? [pic] : []"
              :max-sum="1"
              :type-list="['jpeg', 'png', 'jpg', 'pdf']"
              :file-size="10"
              :disabled="statusTypeDisabled"
              :show-del="$route.query.pageType !== 'archive' && (statusType !== 'toExamine' && statusType !== 'resigned')"
              class="item-upload"
              @fileUpload="(res) => uploadImg(res, pic)"
              @fileDel="delImg"
            />
            <UploadFile
              v-if="item.groupId == '2'"
              :prop-file-list="pic.fileId ? [pic] : []"
              :max-sum="1"
              :type-list="['doc', 'docx', 'pdf','jpeg', 'png', 'jpg']"
              :file-size="50"
              :disabled="statusTypeDisabled"
              :show-del="$route.query.pageType !== 'archive' && (statusType !== 'toExamine' && statusType !== 'resigned')"
              class="item-upload"
              @fileUpload="(res) => uploadFile(res, pic)"
              @fileDel="delImg"
            />
            <div class="item-title">
              {{ pic.fileName }}
              <div v-if="pic.createType !== 0" class="flex-align-center">
                <a-popover placement="bottom" trigger="hover">
                  <template slot="content">
                    <div class="more-handle-btn">
                      <a-button type="link" @click="edit(pic, item)">
                        编辑
                      </a-button>
                      <!-- v-if="item.groupId == '2'" -->
                      <a-button type="link" class="delete" @click="delSingle(pic)">
                        删除
                      </a-button>
                    </div>
                  </template>
                  <div v-if="statusType !== 'toExamine' && statusType !== 'resigned'" class="more-handle">
                    ···
                  </div>
                </a-popover>
              </div>
            </div>
          </div>
          <div class="com-panel-body-item">
            <a-button v-if="statusType !== 'toExamine' && statusType !== 'resigned'" prefix="add-one" class="attachment-add-btn" @click="addAttachment(item)">
              添加{{ item.groupName }}
            </a-button>
          </div>
        </div>
      </div>
    </div>
    <ModalForm ref="modalForm" v-bind="$props" :name="currentFormName" @reload="initData()" />
  </div>
</template>

<script>
import Anchor from '@/components/CbAnchor/index.js'
import UploadImage from '@/components/UploadImage'
import UploadFile from '@/components/UploadFile'
import ModalForm from './attachment-modal-form.vue'
import {
  getStaffFileList,
  archivesGetStaffFileList,
  deleteStaffFileRelation,
  deleteStaffFile,
  updateStaffFile,
  getEntryFormExamFileInfo
} from '@/services/user/roster.js'
import { getSignatures } from '@/services/upload/index.js'
// import { IMG_PREVIEW } from '@/services/api.js'
export default {
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index'),
    Anchor,
    UploadImage,
    UploadFile,
    ModalForm
  },
  props: {
    userId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showAnchor: false, // 是否展示锚点导航
      infoList: [
        {
          groupId: '1',
          groupName: '基本材料',
          children: []
        },
        {
          groupId: '2',
          groupName: '档案材料',
          children: []
        }
      ],
      currentFormName: '', // 当前新增表单的名称标识
      attachmentTypes: [
        {
          groupId: '1',
          groupName: '基本材料'
        },
        {
          groupId: '2',
          groupName: '档案材料'
        }
      ],
      delAttachmentInfo: {},
      basePath: process.env.VUE_APP_API_BASE_URL
    }
  },
  inject: ['anchorHeight', 'statusType'],
  computed: {
    anchorList: function() {
      return this.infoList.map((v) => {
        return { id: `grid_${v.groupId}`, label: v.groupName }
      })
    },
    statusTypeDisabled: function() {
      let boolean = false
      if (this.statusType === 'toExamine' || this.statusType === 'resigned') {
        boolean = true
      } else {
        boolean = false
      }
      return boolean
    }
  },
  mounted() {
    this.initData()
  },
  updated() {
    this.$refs.anchor ? this.$refs.anchor.getAllTitleOffsetTop() : null
  },
  methods: {
    anchorShow() {
      this.$nextTick(() => {
        this.showAnchor = true
      })
    },
    // 添加材料
    addAttachment(item) {
      this.currentFormName = item.groupName
      this.$refs.modalForm.open()
    },
    async initData() {
      this.infoList[0].children = []
      this.infoList[1].children = []
      let res
      const { archivesPerfectId, pageType, statusType, onJobId, staffId } = this.$route.query
      // 修改接口 （从档案审核到员工信息页面）
      if (pageType === 'archive') {
        res = await archivesGetStaffFileList({ archivesId: archivesPerfectId })
      } else if (statusType === 'toExamine') {
        res = await getEntryFormExamFileInfo({ staffId, onJobId })
      } else {
        res = await getStaffFileList({ onJobId })
      }
      const ids = res.data.filter((v) => v.fileId !== null && v.fileId !== '').map((v) => v.fileId) || []
      let signatures = []
      if (ids.length > 0) {
        const signatureRes = await getSignatures({ ids: ids.join(',') })
        signatures = signatureRes.data
      }
      // 如果上传文件上线偏大，出现效率问题，可以吧forEach和filter改成原生for循环，通过删减signatures和break方式优化效率
      res.data.forEach((item, index) => {
        const signature = signatures.filter((v) => v.id === item.fileId)[0]
        const fileTemp = JSON.parse(JSON.stringify(item))
        if (signature) {
          fileTemp['fileUrl'] = `${this.basePath}/hrsaas-storage/image/thumbnail?id=${item.fileId}&signature=${signature.signature}&expireTime=${signature.time}`
          fileTemp['name'] = signature.name
          fileTemp['signature'] = signature.signature
          fileTemp['time'] = signature.time
        }
        this.infoList[item.fileType - 1].children.push(fileTemp)
      })
      this.anchorShow()
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    // 上传图片
    uploadImg(file, pic) {
      updateStaffFile({
        staffFileId: pic.staffFileId,
        staffId: this.userId,
        fileType: 1,
        fileName: pic.fileName,
        createType: pic.createType,
        fileId: file.fileId
      }).then((res) => {
        if (res.code === 100) {
          this.initData()
        }
      })
    },
    // 上传文件
    uploadFile(file, pic) {
      updateStaffFile({
        staffFileId: pic.staffFileId,
        staffId: this.userId,
        fileType: 2,
        fileName: pic.fileName,
        createType: pic.createType,
        fileId: file.fileId
      }).then((res) => {
        if (res.code === 100) {
          this.initData()
        }
      })
    },
    // 删除图片
    delImg(file, list) {
      deleteStaffFileRelation({
        staffFileId: file.file.staffFileId
      }).then((res) => {
        if (res.code === 100) {
          this.$message.success('删除成功')
          this.initData()
        }
      })
    },
    // 编辑附件类目
    edit(file, item) {
      this.currentFormName = item.groupName
      this.$refs.modalForm.open(file)
    },
    // 删除附件类目的提示
    delSingle(file) {
      this.delAttachmentInfo = file
      this.$Reconfirm({
        title: '删除',
        content: `确定要删除“${this.delAttachmentInfo.fileName}”该附件？`
      }).then(() => {
        // 删除附件类目
        deleteStaffFile({ staffFileId: this.delAttachmentInfo.staffFileId }).then((res) => {
          if (res.code === 100) {
            this.$message.success('删除成功')
            this.initData()
          } else {
            this.$message.error('删除失败')
          }
        })
      }).catch(() => {
        console.log('no')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.attachment-info-p {
  display: flex;
  .attachment-anchor {
    position: relative;
    width: 200px;
    padding-left: 20px;
  }
  .attachment-info {
    // max-height: 600px;
    overflow-y: auto;
    flex: 1;
    padding-right: 20px;
    padding-bottom: 20px;
    .com-panel {
      .com-panel-head {
        padding: 20px 0;
        .flex-between-center();
        .com-head-title {
          position: relative;
          padding-left: 7px;
          color: @sc-grey-100;
          margin-right: 10px;
          .font-size(16px);
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 3px;
            height: 14px;
            background: @sc-primary-100;
          }
        }
        .com-head-divide {
          flex: 1;
          height: 1px;
          background-color: @sc-grey-20;
        }
        .com-head-edit {
          margin-left: 10px;
          .ant-btn-link {
            color: @sc-primary-60;
            padding: 0;
            height: auto;
          }
        }
      }
      .com-panel-body {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        .com-panel-body-item {
          margin-left: 45px;
          .item-upload {
            width: 307px !important;
            height: 141px !important;
            /deep/.ant-upload-list-picture-card-container {
              width: 307px !important;
              height: 141px !important;
            }
            /deep/.ant-upload-list-item {
              width: 307px !important;
              height: 141px !important;
            }
            /deep/.ant-upload-select-picture-card {
              width: 307px !important;
              height: 141px !important;
            }
            /deep/.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
              object-fit: fill;
            }
            /deep/.upload-file {
              max-width: calc(307px - 16px);
              height: calc(141px - 16px);
              // max-width: 307px
            }
          }
          .item-title {
            .font-size(14px);
            position: relative;
            color: @sc-grey-100;
            margin-top: 10px;
            text-align: center;
            margin-bottom: 24px;
            .flex-align-center {
              position: absolute;
              right: 0;
              top: 0;
            }
          }
          .attachment-add-btn {
            margin-top: 60px;
            margin-bottom: 60px;
            margin-left: 102px;
          }
        }
      }
    }
  }
}
.more-handle-btn {
  display: flex;
  flex-direction: column;
  .ant-btn {
    height: 25px;
  }
}
.more-handle {
  font-weight: 700;
  font-size: 20px;
  vertical-align: baseline;
  cursor: pointer;
}
</style>
