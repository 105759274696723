<template>
  <div>
    <!--  添加 搜索 筛选  -->
    <appForm
      :to-list="toList"
      :screen-roster-list="screenRosterList"
      width="400"
      @getSearchCallback="getSearchCallback"
      @screenRosterParams="screenRosterParams"
      @exportFileList="exportFileList"
    >
      <template slot="customButton">
        <a-space>
          <div>
            <span> 本月报税人员：</span>
            <span class="number">{{ total }}</span>
          </div>
        </a-space>
      </template>
    </appForm>
    <!--  table  -->
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      row-key="onJobId"
      :offset-bottom="60"
      table-ref=".div-content"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="loadDataList"
    >
      <!-- 序号 -->
      <template slot="index" slot-scope="{ scope }">
        <span>{{ scope.index + 1 }}</span>
      </template>
      <!-- 头像 -->
      <template slot="staffName" slot-scope="{ scope }">
        <CbTableAvater
          :table-avater="scope.record.avatar"
          :table-name="scope.record.staffName"
          :on-job-id="scope.record.onJobId"
          :staff-id="scope.record.staffId"
        />
      </template>
      <!-- 操作列
      <template slot="operation" slot-scope="{ scope }">{{ scope }} </template> -->
    </custom-table>
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import CbTableAvater from '@/components/CbTableAvater/index'
import {
  getCurrentMonthCalStaffList,
  exportCurrentMonthCalStaffList } from '@/services/wage/wagePayroll'
export default {
  components: {
    CbTableAvater
  },
  inject: ['salaryItemInfo', 'period'],
  mixins: [mixins],
  data() {
    return {
      toList: ['search', 'screen', 'export'], // search搜索框 screen筛选 choice位置选择弹窗 export导出
      screenRosterList: {
        list: [
          {
            type: 'selectDictionaries',
            label: '聘用形式',
            options: {
              width: '100%',
              parameter: 'engage_mode',
              clearable: true,
              placeholder: '请选择职级'
            },
            model: 'engageMode',
            key: 'selectDictionaries_1651022813841'
          },
          {
            type: 'selectDepartment',
            label: '部门',
            options: {
              width: '100%',
              clearable: true,
              placeholder: '请选择'
            },
            model: 'orgId',
            key: 'selectDepartment_1651022903831'
          },
          {
            type: 'selectPost',
            label: '岗位',
            options: {
              width: '100%',
              clearable: true,
              placeholder: '请选择'
            },
            model: 'postId',
            key: 'selectPost_1651022948111'
          },
          {
            'type': 'selectDictionaries',
            'label': '合同公司',
            'options': {
              'width': '100%',
              'parameter': 'contract_company',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'contractCoId',
            'key': 'selectDictionaries_1650962628706'
          },
          {
            'type': 'selectDictionaries',
            'label': '工作地点',
            'options': {
              'width': '100%',
              'parameter': 'work_address',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'addressId',
            'key': 'selectDictionaries_1650962628701'
          },
          {
            'type': 'selectDictionaries',
            'label': '员工状态',
            'options': {
              'width': '100%',
              'parameter': 'staff_status',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'staffStatus',
            'key': 'selectDictionaries_1650962628702'
          }
        ],
        config: {
          layout: 'horizontal',
          labelCol: {
            xs: 4,
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
            xxl: 4
          },
          labelWidth: 100,
          labelLayout: 'flex',
          wrapperCol: {
            xs: 18,
            sm: 18,
            md: 18,
            lg: 18,
            xl: 18,
            xxl: 18
          },
          hideRequiredMark: false,
          customStyle: ''
        }
      },
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: '50px',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          scopedSlots: { customRender: 'staffName' },
          width: 105
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          ellipsis: true
        },
        {
          title: '员工状态',
          dataIndex: 'staffStatusName',
          key: 'staffStatusName',
          ellipsis: true
        },

        {
          title: '报税主体',
          dataIndex: 'contractCoName',
          key: 'contractCoName',
          ellipsis: true
        },

        {
          title: '应发工资',
          dataIndex: 'salaryAmount',
          key: 'salaryAmount',
          ellipsis: true,
          dataType: 'money'
        },
        {
          title: '个税金额',
          dataIndex: 'taxPayment',
          key: 'taxPayment',
          ellipsis: true,
          dataType: 'money'
        },

        {
          title: '免税收入',
          dataIndex: 'exemptionIncome',
          key: 'exemptionIncome',
          ellipsis: true,
          dataType: 'money'
        },
        {
          title: '专项扣除',
          dataIndex: 'specialDeduction',
          key: 'specialDeduction',
          ellipsis: true,
          dataType: 'money'
        },
        {
          title: '累计专项附加扣除',
          dataIndex: 'accumulatedSpecialDeduction',
          key: 'accumulatedSpecialDeduction',
          ellipsis: true,
          dataType: 'money'
        }
      ],
      dataSource: []
    }
  },
  methods: {
    fetchDataApiFn(param) {
      param['salaryType'] = this.selectType
      param['groupCalcId'] = this.salaryItemInfo.groupCalcId
      param['taxpayPeriod'] = this.period
      param['inputValue'] = param['inputValue'] ? param['inputValue'] : ''
      getCurrentMonthCalStaffList(param).then(res => {
        this.dataSource = res.data.records ? res.data.records : []
        this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
        this.total = res.data.total || 0
      })
    },
    // 搜索
    getSearchCallback(val) {
      this._queryParam.inputValue = val.searchValue
      this.serchGetData()
    },
    // 筛选
    screenRosterParams(data) {
      const { engageMode, orgId, postId, contractCoId, addressId, staffStatus } = data.screenCondition
      this._queryParam = {
        inputValue: data.searchValue,
        engageMode,
        orgId: orgId && orgId[0] && orgId[0].value,
        postId,
        contractCoId,
        addressId,
        staffStatus
      }
      this.serchGetData()
    },
    handleType(val) {

    },
    async exportFileList() {
      const params = JSON.parse(JSON.stringify(this._queryParam))
      params.pageNum = 1
      params.pageSize = -1
      await exportCurrentMonthCalStaffList(params)
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.arr_form{
  align-items: center;
  .ant-space{
    color: @sc-grey-100;
    .font-size(14px);
    .number{
      .font-size(16px);
      color: @sc-primary-100;
    }
  }
}
</style>
