<template>
  <div>
    <Modal :visible="visible" size="normal" title="入职事项" :footer="null" :closable="true" @cancel="visible=false">
      <div class="v-content">
        <div v-for="(item, index) in dataList" :key="index" class="v-items">
          <span class="v-title">{{ item.entryMatterName }}</span>
          <span class="v-label-status">
            <span :class="[item.entryMatterStatus === 0 ? 'warning' : 'success']" @click="openSetting(item, index)">{{ item.entryMatterStatus == 0 ? '待处理' : '已完成' }}</span>
          </span>
          <!-- <span class="v-label-set">
            <a-button v-if="item.entryMatterStatus == 0" type="link" @click="solvedMatters(item)"> {{ item.entryMatterStatus == 0 ? '设置' : '查看' }} </a-button>
          </span> -->
        </div>
      </div>
      <div class="btn_box">
        <!-- <a-button v-if="hangInTheAir > 0" class="set_span" @click="openSetting">设置</a-button> -->
      </div>
    </Modal>
  </div>
</template>

<script>
import { getEntryMatter } from '@/services/user/Induction.js'
export default {
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      visible: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      dataList: [
      ],
      userInfo: {}
    }
  },

  methods: {
    // 打开弹窗
    open(val) {
      this.userInfo = val
      const onJobId = val.onJobId
      this.getData(onJobId)
      this.visible = true
    },
    async getData(val) {
      const res = await getEntryMatter({ onJobId: val })
      if (res.code === 100) {
        this.dataList = res.data
      }
    },
    // 保存事件
    handleOk() {
      this.visible = false
    },

    // 取消事件
    handleCancel() {
      this.visible = false
      this.resetForm()
    },
    openSetting(item) {
      if (item.entryMatterStatus === 0) {
        this.visible = false
        const arr = this.dataList.filter((item) => {
          item.itemKey = item.entryMatterKey
          item.itemName = item.entryMatterName
          return item.entryMatterStatus === 0
        })
        this.$emit('open', this.userInfo, arr, item.entryMatterKey)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.v-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .v-items{
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
  }
  .v-items:first-child {
    .ant-space {
      padding-top: 0;
    }
  }
  .v-items:last-child {
    .ant-space {
      padding-bottom: 0;
    }
  }
  .ant-space-align-center{
  margin: 0;
  }
}
.v-title {
  display: block;
  text-align: left;
  color: @sc-grey-100;
}
.v-label-status {
  display: flex;
  span {
    cursor: pointer;
    text-align: center;
    height: 22px;
    padding: 0 10px;
    margin: 0 auto;
  }
  .warning{
    color: @sc-warning-100;
    background: @sc-warning-10;
  }
  .success{
    color: @sc-success-100;
    background: @sc-success-10;
  }
}
/deep/ .ant-space {
  width: 100%;
  padding: 10px 0;
}
/deep/ .ant-space-item {
  width: calc(100% / 3);
  &:nth-child(3) {
    text-align: right;
    color: @sc-primary-100;
  }
}
.btn_box{
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  .set_span{
  color: @sc-primary-100;
  // border: none;
  text-align: right;
  padding: 0 10px;
  cursor: pointer;
  width: 62px;
}
}
</style>
