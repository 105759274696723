<template>
  <div class="login-form">
    <div class="form-item">
      <Number v-model.trim="globForm.phoneNum" prefix="+86" />
    </div>
    <div class="form-item">
      <a-input-password v-model.trim="globForm.password">
        <a-icon slot="prefix" type="lock" />
      </a-input-password>
    </div>
    <a-button
      class="submit"
      type="primary"
      @click="onSubmit"
      @enter="onSubmit"
    >登录</a-button>
    <div class="options">
      <div class="check-code cursor" @click="changeLoginType(2)">验证码登录</div>
      <div class="right">
        <div class="forget cursor" @click="changeLoginType(3)">忘记密码</div>
      </div>
    </div>
    <!-- 滑块验证 -->
    <div v-if="visible" class="dialog">
      <div class="dialogContent">
        <div class="title">
          <p>拖动下方滑块完成拼图</p>
          <div class="icon">
            <a-icon type="redo" @click="onRefresh" />
            <a-icon type="close" @click="visible = false" />
          </div>
        </div>
        <slideverify
          ref="dialogopen"
          :l="puzzePass.smallHeight"
          :w="puzzePass.bigWidth"
          :h="puzzePass.bigHeight"
          :blocky="puzzePass.posY"
          :imgurl="puzzePass.bigImageBase64"
          :miniimgurl="puzzePass.smallImageBase64"
          :slider-text="puzzePass.text"
          @success="onSuccess"
          @fail="onFail"
          @refresh="onRefresh"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { login, getImagesApi, getPublicKeyApi, checkImagesApi } from '@/services/login'
import publicMixins from './publicMixins'
import Number from '@/components/CbNumber'
// import sercet from '@/utils/secret'
import { afterLogin } from './publicMixins'
import Cookie from 'js-cookie'
import { phoneReg } from '@/utils/reg.js'
import { RSAEncrypt } from '@/utils/secret.js'
import slideverify from '@/components/CbSlideverify/index.vue'
export default {
  name: 'AccountLogin',
  components: {
    Number,
    slideverify
  },
  mixins: [publicMixins, afterLogin],
  data() {
    return {
      visible: false,
      puzzePass: {},
      msg: '',
      password: 'Hrsaas123456',
      publicKey: ''
    }
  },
  computed: {
    canUse() {
      const { phoneNum, password } = this.globForm
      return !(phoneNum && password)
    }
  },
  mounted() {
  },
  methods: {
    async onSuccess(left) {
      const res = await checkImagesApi({ uuid: this.puzzePass.uuid, moveX: left })
      if (res.code === 100) {
        if (res.data) {
          this.visible = false
          this.login()
        } else {
          this.$message.error('验证失败，重新验证')
          this.onRefresh()
        }
      }
    },
    onFail() {
      this.msg = ''
    },
    onRefresh() {
      this.getImage()
    },
    getImage() {
      getImagesApi({}).then(res => {
        if (res.code === 100) {
          const data = res.data
          data.bigImageBase64 = 'data:image/png;base64,' + res.data.bigImageBase64
          data.smallImageBase64 = 'data:image/png;base64,' + res.data.smallImageBase64
          this.puzzePass = data
          this.visible = true
        }
      })
    },
    async onSubmit(e) {
      if (this.canUse) return
      e.preventDefault()
      this.logging = true
      const { phoneNum, password } = this.globForm
      if (!phoneNum) return this.$message.warning('请输入手机号')
      if (!phoneReg.test(phoneNum)) return this.$message.warning('手机号码格式错误，请检查')
      if (!password) return this.$message.warning('请输入密码')
      this.getImage()
    },
    async login() {
      // 获取公钥
      const publickKey = await getPublicKeyApi({ algorithm: 'RSA-2048' })
      this.publicKey = publickKey.data.publicKey
      // this.uuid = publickKey.data.uuid
      const { phoneNum, password } = this.globForm
      // 加密密码
      const encryptPassword = await RSAEncrypt(password, this.publicKey)
      const res = await login({ phoneNum, password: encryptPassword })
      // 临时token选择租户后换成正式token
      Cookie.set('hrsaas-token', res.data.token.accessToken)
      if (res.data.isUpdatePassword) {
        this.setLoginType(6)
      } else {
        this.changeLoginType(8)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./index.less');

.dialog{
  position: fixed;
  width:100%;
  height:100vh;
  top:0;
  left:0;
  z-index: 999;
  background: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: center;
  .dialogContent{
    display: inline-block;
    padding: 10px;
    background: #fff;
    border-radius: 6px;
    .title{
      display: flex;
      justify-content: space-between;
      margin-bottom:16px;
      align-items: center;
      p{
        margin:0;
        font-weight: bold;
        font-size:16px;
      }
    }
    .icon{
      /deep/.anticon{
        width:16px;
        height:16px;
        margin-left:16px;
        cursor: pointer;
      }
    }
  }
}
</style>
