var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.from)?_c('div',[_c('appForm',{attrs:{"to-list":_vm.toList,"screen-roster-list":_vm.screenRosterList,"width":"400"},on:{"getSearchCallback":_vm.getSearchCallback,"screenRosterParams":_vm.screenRosterParams,"exportFileList":_vm.exportFileList}}),_c('div',[_c('custom-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"row-key":'onJobId',"scroll":{ x: 1300 },"offset-bottom":64,"pagination":{
        current: _vm.pager.pageNum,
        pageSize: _vm.pager.pageSize,
        total: _vm.total,
        showTotal: function (total) { return ("共 " + total + " 条数据"); },
        showQuickJumper: true,
        showSizeChanger: true
      }},on:{"change":_vm.loadDataList},scopedSlots:_vm._u([{key:"index",fn:function(ref){
      var scope = ref.scope;
return [_c('span',[_vm._v(_vm._s(scope.index + 1))])]}},{key:"operation",fn:function(ref){
      var scope = ref.scope;
return [_c('div',{staticClass:"flex-align-center"},[_c('div',{staticClass:"more-handle"},[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.handleDetail(scope)}}},[_vm._v("明细")])],1)])]}},{key:"staffName",fn:function(ref){
      var scope = ref.scope;
return [_c('CbTableAvater',{attrs:{"table-avater":scope.record.avatar,"table-name":scope.record.staffName,"on-job-id":scope.record.onJobId,"staff-id":scope.record.staffId}})]}}],null,false,3302821782)})],1),_c('Drawer',{attrs:{"size":"small","title":"计薪明细","placement":"right","is-mask":"","display-btn":"","closable":"","visible":_vm.visible},on:{"close":_vm.detailOnClose}},[_c('div',{staticClass:"detailContent"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"avater"},[_c('CopyAvater',{attrs:{"size":"28","on-job-id":_vm.detailInfo.onJobId,"avater-name":_vm.detailInfo.staffName}})],1),_c('div',{staticClass:"sum"},[_vm._v(_vm._s(_vm.detailInfo.sum)+"元")])]),_c('div',{staticClass:"detailItems"},[_vm._l((_vm.detailInfo.types),function(item,index){return [_c('div',{key:("detailContentItem" + index),staticClass:"detailContentItem"},[_c('div',{staticClass:"groupTitle"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.salaryTypeName))]),_c('div',{staticClass:"amount"},[_vm._v(_vm._s(item.amount)+"元")])]),_c('div',{staticClass:"groupContent"},[_vm._l((item.items),function(key,groupItemIndex){return [_c('div',{key:("groupItem" + groupItemIndex),staticClass:"groupItem"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(key.salaryItemName))]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(key.amount)+"元")])])]})],2)])]})],2)])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }