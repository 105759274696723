<template>
  <Modal
    :closable="true"
    :title="modalTitle"
    :size="'normal'"
    :visible="visible"
    :mask-closable="false"
    ok-text="保存"
    @ok="handleOk"
    @cancel="handleCancel"
    @afterClose="afterClose"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="职务名称" prop="dutyName">
        <a-input v-model="form.dutyName" placeholder="请输入1-20个字符" />
      </a-form-model-item>
      <a-form-model-item label="职务描述" prop="dutyDescribe">
        <a-input
          v-model="form.dutyDescribe"
          type="textarea"
          :max-length="200"
          placeholder="请输入0-200个字符"
          :auto-size="{ minRows: 3, maxRows: 7 }"
        />
      </a-form-model-item>
      <a-form-model-item label="能力要求" prop="capacityDemand">
        <a-input
          v-model="form.capacityDemand"
          :max-length="200"
          type="textarea"
          placeholder="请输入0-200个字符"
          :auto-size="{ minRows: 3, maxRows: 7 }"
        />
      </a-form-model-item>
    </a-form-model>
  </Modal>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { createDuty, updateDuty } from '@/services/organization/duty.js'
export default {
  components: {
    Modal: () => import ('@/components/CbModal/index')
  },
  data() {
    return {
      modalTitle: '',
      visible: false,
      rules: {
        dutyName: [
          {
            required: true,
            message: '请输入1-20个字符',
            trigger: 'blur'
          }
        ]
      },
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      form: {}
    }
  },
  methods: {
    open(userInfo) {
      this.modalTitle = userInfo ? '编辑职务' : '新增职务'
      if (userInfo) {
        this.form.dutyName = userInfo.dutyName ? userInfo.dutyName : undefined
        this.form.dutyDescribe = userInfo.dutyDescribe ? userInfo.dutyDescribe : undefined
        this.form.capacityDemand = userInfo.capacityDemand ? userInfo.capacityDemand : undefined
        this.form.dutyCode = userInfo.dutyCode ? userInfo.dutyCode : undefined
        this.form.dutyId = userInfo.dutyId ? userInfo.dutyId : undefined
      }
      this.visible = true
    },
    async handleOk() {
      const { dutyName, dutyDescribe, capacityDemand, dutyCode, dutyId } = { ...this.form }
      let res
      const obj = {
        dutyName,
        dutyDescribe,
        capacityDemand,
        dutyCode,
        dutyId
      }
      if (this.modalTitle === '新增职务') {
        res = await createDuty(obj)
      } else if (this.modalTitle === '编辑职务') {
        res = await updateDuty(obj)
      }
      if (res.code === 100) {
        this.$message.success('新增成功')
        this.$emit('refresh')
      }
      this.handleCancel()
    },
    handleCancel() {
      this.visible = false
      this.form = {}
    },
    afterClose() {

    }
  }
}
</script>

<style>

</style>
