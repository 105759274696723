<template>
  <Drawer
    :is-mask="false"
    :z-index="101"
    title="条件计算公式"
    size="normal"
    :visible="visible"
    @close="visible = false"
    @onSubmit="handleOk"
  >
    <div class="branch-formula">
      <!-- 分支 可能多个 如果...则... -->
      <div>
        <div
          v-for="(branch, branchIndex) in formula.branchList"
          :key="branch.sortNum"
          class="branch-list"
        >
          <div class="if-header">
            <div>条件{{ formula.branchList.length > 1 ? branchIndex + 1 : '' }}</div>
            <div
              v-if="formula.branchList.length > 1"
              class="delete-block"
              @click="deleteBranch(branchIndex)"
            >
              <svg class="iconpark-icon"><use href="#delete-one" /></svg>
            </div>
          </div>
          <div class="branch-content">
            <div>
              <div class="statement if-statement">
                <span>
                  <span>如果</span>
                </span>
              </div>
              <div
                v-for="(condition, conditionIndex) in branch.conditionList"
                :key="`${branch.sortNum}-${condition.sortNum}`"
                class="condition-view"
              >
                <div v-if="conditionIndex > 0" class="and-statement">且</div>
                <div class="and-statement-view">
                  <div
                    class="formula-view"
                    @click="settingFormula({
                      mode: 'ifleft',
                      value: condition.leftExpression,
                      branchIndex,
                      conditionIndex
                    })"
                  >
                    {{ condition.leftExpression }}
                  </div>
                  <a-select
                    :default-value="getSymbol(condition.compareSymbol)"
                    class="select-view"
                    @change="(value) => setComparesymbol(value, branchIndex, conditionIndex)"
                  >
                    <a-select-option v-for="item in operatorList" :key="item.id" :value="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                  <div
                    class="formula-view"
                    @click="settingFormula({
                      mode: 'ifright',
                      value: condition.rightExpression,
                      branchIndex,
                      conditionIndex
                    })"
                  >
                    {{ condition.rightExpression }}
                  </div>
                  <div v-if="branch.conditionList.length > 1">
                    <a-button
                      class="and-statement-del"
                      type="iconBtn"
                      icon="close-small"
                      @click="deleteAnd(branchIndex, conditionIndex)"
                    />
                  </div>
                </div>
                <a-button v-if="branch.conditionList.length - 1 === conditionIndex" type="iconBtn" icon="add-one" class="and-statement-add" @click="addAnd(branchIndex)" />
              </div>
            </div>
            <div class="ifelse-statement">
              <div>
                <div class="statement">则</div>
                <div
                  class="formula-view"
                  @click="settingFormula({
                    mode: 'ifelse',
                    value: branch.description,
                    branchIndex
                  })"
                >
                  {{ branch.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="addBranch">
          <div class="add-btn">
            <a-button class="addBranchBtn" prefix="piliangdaoru" @click="addBranch">
              添加条件
            </a-button>
          </div>
        </div>
        <div class="else-view">
          <div class="else-statement">否则</div>
          <div
            class="formula-view"
            @click="settingFormula({
              mode: 'else',
              value: formula.description,
            })"
          >{{ formula.description }}</div>
        </div>
      </div>
      <div class="bottom-value">
        <div>
          <div class="form-label">封顶值</div>
          <CbNumber v-model="maxLimit" placeholder="请输入封顶值" />
        </div>
        <div>
          <div class="form-label minimum">保底值</div>
          <CbNumber v-model="minLimit" placeholder="请输入保底值" />
        </div>
      </div>
    </div>
    <formulaView
      ref="formulaView"
      add
      @formulaComplete="formulaComplete"
    />
  </Drawer>
</template>

<script>
import formulaView from './formulaView'
import { checkFormula } from '@/services/wage/formulaSetting'
import Drawer from '@/components/CbDrawer'
import CbNumber from '@/components/CbNumber'
export default {
  name: 'BranchFormula',
  components: {
    Drawer,
    formulaView,
    CbNumber
  },
  data() {
    return {
      operatorList: [ // 比较运算符
        {
          name: '大于',
          id: 0,
          compareSymbol: '>'
        },
        {
          name: '大于等于',
          id: 1,
          compareSymbol: '>='
        },
        {
          name: '小于',
          id: 2,
          compareSymbol: '<'
        },
        {
          name: '小于等于',
          id: 3,
          compareSymbol: '<='
        },
        {
          name: '等于',
          id: 4,
          compareSymbol: '=='
        },
        {
          name: '不等于',
          id: 5,
          compareSymbol: '<>'
        }
      ],
      // 用于后续添加
      origin: {
        conditionList: [ // 如果
          {
            leftExpression: '',
            compareSymbol: '>',
            rightExpression: '',
            sortNum: 0
          }
        ],
        description: '', // 则
        sortNum: 0
      },
      // 默认有一项
      formula: { // 计算公式
        branchList: [ // 条件
          {
            conditionList: [ // 如果
              {
                leftExpression: '',
                compareSymbol: '>',
                rightExpression: '',
                sortNum: 0
              }
            ],
            description: '', // 则
            sortNum: 0
          }
        ],
        description: '' // 否则
      },
      currentFormula: '',
      subData: null,
      visible: false,
      maxLimit: '',
      minLimit: ''
    }
  },
  computed: {
    getSymbol() {
      return (compareSymbol) => {
        if (!compareSymbol) return 0
        return this.operatorList.findIndex((item) => item.compareSymbol === compareSymbol)
      }
    }
  },
  methods: {
    // 点击去设置公式
    settingFormula(subData, showChar) {
      // 缓存当前节点信息
      this.subData = subData
      console.log('subData', subData)
      this.$refs.formulaView.show(showChar, { description: subData.value })
    },
    // 添加分支
    addBranch() {
      const { origin, formula: { branchList }} = this
      const branch = { ...origin }
      branch.sortNum = branchList.length
      this.formula.branchList.push(JSON.parse(JSON.stringify(branch)))
    },
    // 删除分支
    deleteBranch(branchIndex) {
      this.formula.branchList.splice(branchIndex, 1)
      // 整合序号 避免重复
      this.formula.branchList.forEach((item, index) => {
        item.sortNum = index
      })
    },
    // 添加条件
    addAnd(branchIndex) {
      const { origin: { conditionList }, formula } = this
      const branchList = formula.branchList[branchIndex]
      const and = { ...conditionList[0] }
      and.sortNum = branchList.conditionList.length
      this.formula.branchList[branchIndex].conditionList.push(and)
    },
    deleteAnd(branchIndex, conditionIndex) {
      this.formula.branchList[branchIndex].conditionList.splice(conditionIndex, 1)
      // 整合序号 避免重复
      this.formula.branchList[branchIndex].conditionList.forEach((item, index) => {
        item.sortNum = index
      })
    },
    back() {
      this.$emit('settingFormula', false)
    },
    formulaComplete({ description }) {
      const { mode, branchIndex, conditionIndex } = this.subData
      if (mode === 'ifleft') {
        this.formula.branchList[branchIndex].conditionList[conditionIndex].leftExpression = description
      }
      if (mode === 'ifright') {
        this.formula.branchList[branchIndex].conditionList[conditionIndex].rightExpression = description
      }
      if (mode === 'ifelse') {
        this.formula.branchList[branchIndex].description = description
      }
      if (mode === 'else') {
        this.formula.description = description
      }
      this.back()
    },
    setComparesymbol(value, branchIndex, conditionIndex) {
      const { operatorList } = this
      this.formula.branchList[branchIndex].conditionList[conditionIndex].compareSymbol =
      operatorList[value].compareSymbol
      console.log('setComparesymbol', value)
    },
    //
    async handleOk() {
      const { formula, maxLimit, minLimit } = this
      console.log('formulaformula', this.formula)
      // 判断分支是否都存在[条件]
      const { description, branchList } = formula
      // 错误提示
      const warningMessage = () => {
        this.$message.warning('分支条件公式不完善')
      }
      try {
        branchList.forEach(({ conditionList, description: subDescription }) => {
          if (!subDescription) {
            throw new Error('错误')
          }
          conditionList.forEach(({ leftExpression, rightExpression }) => {
            if (!leftExpression || !rightExpression) {
              throw new Error('错误')
            }
          })
        })
      } catch (error) {
        return warningMessage()
      }
      // 判断 [否则条件] 是否填写
      if (!description) {
        return warningMessage()
      }
      if (maxLimit && minLimit && (Number(minLimit) > Number(maxLimit))) {
        return this.$message.warning('保底值不能大于封顶值')
      }
      // 服务端校验公式的合法性
      const { data } = await checkFormula(formula)
      console.log(data)
      this.visible = false
      const params = {
        maxLimit,
        minLimit,
        ...formula
      }
      this.$emit('formulaComplete', params, data)
    },
    show({ description, branchList, minLimit, maxLimit }) {
      const origin = JSON.parse(JSON.stringify(this.origin))
      this.$set(this, 'formula', {
        description,
        branchList: branchList || [origin]
      })
      console.log('formula', this.formula)
      this.minLimit = minLimit
      this.maxLimit = maxLimit
      this.visible = true
    }
  }
}
</script>

<style lang="less" scoped>
.branch-formula {
  .branch-list {
    border: 1px solid @sc-greyBg-20;
    border-radius: 4px;
    margin-bottom: 14px;
    .if-header {
      background: @sc-greyBg-10;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      padding-left: 14px;
      border-radius: 4px;
      height: 42px;
      .delete-block {
        height: 42px;
        width: 42px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        .iconpark-icon{
          width: 16px;
          height: 16px;
        }
        &:hover {
          color: @sc-error-100;
        }
      }
    }
    .branch-content {
      padding: 14px;
    }
    .condition-view {
      padding-bottom: 14px;
      .and-statement{
        padding-bottom: 10px;
      }
      .and-statement-view {
        display: flex;
        align-items: center;
        .and-statement-del {
          margin-left: 5px;
          &:hover{
          /deep/.iconpark-icon{
            color: @error-color;
          }
            border-color: @error-color;
          }
        }
        /deep/.ant-select {
          width: 200px;
        }
      }
      .and-statement-add {
          margin-top: 12px;
        }
    }
    // /deep/ .ant-btn {
    //   flex-shrink: 0;
    //   margin: 0px;
    // }
  }
  .else-view {
    padding-bottom: 20px;
    align-items: center;
    .else-statement {
      padding-bottom: 10px;
      font-size: 14px;
      color: @sc-grey-100;
    }
  }
  .ifelse-statement {
    display: flex;
    justify-content: space-between;
  }
  .formula-view {
    padding: 5px 10px;
    border: 1px solid @sc-grey-20;
    border-radius: 5px;
    cursor: pointer;
    width: 400px;
    height: 32px;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
  }
  .select-view {
    width: 100px;
    margin: 0 5px;
  }
  .addBranch {
    margin-bottom: 20px;
  }
  .statement {
    font-weight: 700;
    padding-bottom: 10px;
  }
  .if-statement {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
  }
}
.bottom-value {
  display: flex;
  .form-label {
    font-weight: 700;
    padding-right: 10px;
  }
  > div {
    display: flex;
    align-items: center;
  }
  .minimum {
    padding-left: 20px;
  }
}
</style>
