<!--
 * @Date: 2022-04-26 14:48:24
 * @descriotion:
-->
<template>
  <div id="attendanceGroup" class="attendance">
    <div v-if="formVisible" class="attendanceList">
      <Alert class="alert" closable show-icon>
        <template #content>
          <p>1.请先在【设置】中设置“考勤项”、“打卡方式”、“规则”、“班次”、“排班日历”相关项。</p>
          <p>2.提示：有{{ dataPrompt.filter(item => !item.ignoreType).length }}人暂无考勤组 <span v-show="dataPrompt.filter(item => item.ignoreType).length>0">、有{{ dataPrompt.filter(item => item.ignoreType).length }}人不参与考勤组管理</span>，前往<span class="look" @click="lookPer">查看<svg class="iconpark-icon"><use href="#left" /></svg></span></p>
        </template>
      </Alert>
      <AlertTips>
        <template slot="content">
          <p>1.考勤组，即按部门和员工分组，并为每个组设置不同的出勤日等。</p>
          <p>2.支持设置多个考勤组，灵活组合员工的出勤方式，如双休、单休、大小周或排班等。</p>
          <p>3.不考勤方案，适用于企业中不需要考勤的人员，即不需要打卡或记录请假等，如企业高层。</p>
          <p>4.不打卡方案，适用于企业中不需要打卡，但需要记录请假的人员 ，如长期外勤人员。</p>
        </template>
      </AlertTips>
      <appForm
        :to-list="formList"
        search-placeholder="请搜索"
        @getSearchCallback="getSearchCallback"
      >
        <template slot="customButton">
          <a-button type="primary" prefix="add-one" @click="addGroup">
            新增考勤组
          </a-button>
        </template>
      </appForm>
      <custom-table
        id="table"
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        row-key="attendanceGroupId"
        @change="loadDataList"
      >
        <!-- 出勤日 -->
        <template slot="workCycleSequenceStr" slot-scope="{scope}">
          <span :title="scope.record.shiftScheduling.workCycleSequenceStr" v-html="scope.record.shiftScheduling.workCycleSequenceStr" />
        </template>
        <!-- 出勤时间 -->
        <template slot="shiftPunchRuleStr" slot-scope="{scope}">
          <span v-html="scope.record.shiftScheduling.shiftPunchRuleStr" />
        </template>
        <!-- 考勤组类型 -->
        <template slot="shiftSchedulingId" slot-scope="{scope}">
          <span>{{ scope.record.attendanceGroupType==='1'?'无需打卡':'需打卡' }}</span>
        </template>
        <template slot="morerule" slot-scope="{scope}">
          <span>加班规则-{{ scope.record.overTimeRuleName }}</span><br>
          <span>外勤规则-{{ scope.record.outSideRuleName }}</span><br>
          <span>补卡规则-{{ scope.record.appendPunchRuleName }}</span>
        </template>
        <!-- 操作列 -->
        <template slot="operation" slot-scope="{scope}">
          <template>
            <div class="operation-btn-box">
              <a-button type="link" @click="edit(scope.record)">
                编辑
              </a-button>
            </div>
          </template>
          <a-popover
            placement="bottom"
            trigger="hover"
          >
            <template slot="content">
              <div class="more-handle-btn">
                <!-- <a-button type="link" @click="edit(scope.record)">
                  编辑
                </a-button> -->
                <a-button type="link" @click="copying(scope.record)">
                  复制
                </a-button>
                <a-button type="link" class="delete" @click="delet(scope.record)">
                  删除
                </a-button>
              </div>
            </template>
            <div class="more-handle more-handle-box">
              ···
            </div>
          </a-popover>
        </template>
      </custom-table>
    </div>
    <AddAttendance v-else :type="type" :attendance-group-id="attendanceGroupId" @cancle="cancle" />
    <CbIgnoreModal
      :visible="visible"
      placeholder="请选择考勤组"
      title="无排班人员"
      :replace-fields="{label:'attendanceGroupName',value:'attendanceGroupId'}"
      replace-group-key="attendanceGroupId"
      replace-ignore-type-key="ignoreType"
      :data-prompt="dataPrompt"
      :select-list="dataSource"
      @cancel="cancel"
      @setPerson="handleOk"
    />
  </div>
</template>
<script>
import mixins from '@/components/CustomTable/index.js'
import { getListApi, delAttendanceGroupApi, staffNotInGroupApi, editStaffIgnoreInGroupApi } from '@/services/attendance/settingset.js'
export default {
  name: 'AttendanceGroup',
  components: {
    Alert: () => import('@/components/CbAlert'),
    AddAttendance: () => import('./addAttendance/index.vue'),
    AlertTips: () => import('../../components/alert.vue'),
    CbIgnoreModal: () => import('@/components/CbIgnoreModal/index')
  },
  filters: {
    str(val) {
      return val.replace('<br/>', '\n')
    }
  },
  mixins: [mixins],
  data() {
    return {
      // 校验复制考勤组名称
      attendanceGroupName: '',
      visible: false,
      formVisible: true,
      formList: ['search'],
      columns: [{
        title: '考勤组名称',
        dataIndex: 'attendanceGroupName',
        key: 'attendanceGroupName',
        scopedSlots: { customRender: 'processName' },
        width: '120px',
        ellipsis: true
      },
      {
        title: '考勤组类型',
        dataIndex: 'shiftSchedulingId',
        scopedSlots: { customRender: 'shiftSchedulingId' },
        key: 'shiftSchedulingId',
        width: '100px'
      },
      {
        title: '排班日历',
        dataIndex: 'shiftScheduling.shiftSchedulingName',
        scopedSlots: { customRender: 'shiftScheduling.shiftSchedulingName' },
        key: 'shiftScheduling.shiftSchedulingName',
        width: '120px',
        ellipsis: true
      },
      {
        title: '出勤日',
        dataIndex: 'shiftScheduling.workCycleSequenceStr',
        key: 'workCycleSequenceStr',
        scopedSlots: { customRender: 'workCycleSequenceStr' },
        width: '180px'
      },
      {
        title: '出勤时间',
        dataIndex: 'shiftScheduling.shiftPunchRuleStr',
        key: 'shiftPunchRuleStr',
        scopedSlots: { customRender: 'shiftPunchRuleStr' },
        width: '180px'
      },
      {
        title: '核算规则',
        dataIndex: 'morerule',
        key: 'morerule',
        scopedSlots: { customRender: 'morerule' },
        customCell: () => {
          return {
            style: {
              'min-width': '180px'
            }
          }
        }
      },
      {
        title: '适用范围',
        dataIndex: 'attendanceGroupStaffStr',
        key: 'attendanceGroupStaffStr',
        scopedSlots: { customRender: 'range' },
        customCell: () => {
          return {
            style: {
              'min-width': '200px'
            }
          }
        },
        ellipsis: true
      },
      {
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        scopedSlots: { customRender: 'operation' },
        width: '100px'
      }],
      dataSource: [],
      dataPrompt: [],
      attendanceGroup: '0',
      attendanceGroupId: '',
      time: null
    }
  },
  async mounted() {
    await this.getstaffNotInGroupList()
  },

  methods: {
    // 暂无考勤组人员列表弹窗
    cancel() {
      this.serchGetData()
      this.getstaffNotInGroupList()
      this.visible = false
    },
    // 暂无考勤组人员列表
    async getstaffNotInGroupList() {
      const res = await staffNotInGroupApi({})
      res.data.map(item => {
        item.attendanceGroupId = undefined
      })
      this.dataPrompt = res.data
      this.tipshow = this.dataPrompt.length > 0
    },
    async fetchDataApiFn(param) {
      const res = await getListApi(param)
      this.dataSource = res.data
    },
    getSearchCallback(data) {
      this._queryParam.attendanceGroupName = data.searchValue
      this.serchGetData()
    },
    addGroup() {
      this.type = ''
      this.formVisible = false
    },
    lookPer() {
      this.getstaffNotInGroupList()
      this.visible = true
    },
    async handleOk(data) {
      const staffInGroup = []
      const ignoreArr = []
      data.map(item => {
        if (item.attendanceGroupId) {
          const obj = {
            staffId: item.staffId,
            onJobId: item.onJobId,
            attendanceGroupId: item.attendanceGroupId
          }
          staffInGroup.push(obj)
        }
        ignoreArr.push({ onJobId: item.onJobId, staffId: item.staffId, ignoreType: item.ignoreType })
      })
      const res = await editStaffIgnoreInGroupApi({ staffInGroupDto: staffInGroup, editStaffIgnoreDto: ignoreArr })
      if (res.code !== 100) return
      this.serchGetData()
      this.getstaffNotInGroupList()
      this.visible = false
    },
    deleteScope(scope) {
      console.log(scope)
      this.dataPrompt.splice(scope)
    },
    edit(scope) {
      this.type = 'edit'
      this.attendanceGroupId = scope.attendanceGroupId
      this.formVisible = false
    },
    copying(scope) {
      this.type = 'copy'
      this.attendanceGroupId = scope.attendanceGroupId
      this.formVisible = false
    },
    delet(val) {
      this.attendanceGroupId = val.attendanceGroupId
      this.$Reconfirm({
        title: '删除考勤组',
        content: '确定要删除该考勤组吗？'
      }).then(async() => {
        const res = await delAttendanceGroupApi({ attendanceGroupId: this.attendanceGroupId })
        if (res.code !== 100) return
        this.$message.success('删除成功')
        this.serchGetData()
      }).catch(() => {
        console.log('no')
      })
    },
    cancle() {
      this.formVisible = true
      this.serchGetData()
      this.getstaffNotInGroupList()
    }
  }
}
</script>
<style lang="less" scoped>
.alert {
  margin-bottom: 20px;
  p {
    color: @sc-grey-100;
    margin-bottom: 0;
    .look{
      color:@sc-primary-100;
      cursor: pointer;
      margin-right:30px;
      margin-left: 8px;
      .iconpark-icon{
        width: 16px;
        height: 16px;
        vertical-align: sub;
        transform: rotate(180deg);
      }
    }
  }
}
.attendanceList{
 padding:20px 20px 0;
}
.attendance{
  width:100%;
  height: 100%;
  background-color: #fff;
  overflow: auto;
}
/deep/.ant-table-tbody tr td:nth-child(4) {
  white-space: pre-wrap;
}
.more-handle-btn {
    display: flex;
    flex-direction: column;
    .ant-btn {
      height:25px;
    }
  }
.more-handle {
  font-weight: 700;
  color: @sc-primary-100;
  font-size: 20px;
  vertical-align: baseline;
  cursor: pointer;
  margin-left: 4px;
}
.content {
  padding: 16px 20px;
  height:500px;
  overflow: auto;
  .list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height:50px;
    .user{
      width:150px;
      overflow: hidden;
      display: flex;
      align-items: center;
      .userId{
        margin:0 24px 0 10px;
      }
    }
  }
  .btn{
    width:100px;
    text-align: right;
    cursor: pointer;
    color: @sc-primary-100
  }
}
.model-p{
  margin-left: 20px;
  margin-top: 20px;
}
.operation-btn-box{
  float: left;
  button{
    padding-left: 0;
    padding-right: 16px;
  }
}
.more-handle-box{
    display: inline-block;
}
</style>
