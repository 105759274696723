<template>
  <div id="shiftManagement" class="ShiftManagement beauty-scroll">
    <AlertTips>
      <template slot="content">
        <p>1.班次管理，即设置每天的上下班时间，打卡数据根据班次显示有无异常。</p>
        <p>2.支持为不同的部门/人员，设置不同的上下班时间。</p>
      </template>
    </AlertTips>
    <appForm
      :to-list="formList"
      search-placeholder="请搜索"
      @getSearchCallback="getSearchCallback"
    >
      <template slot="customButton">
        <div class="custom-button">
          <a-dropdown>
            <!-- <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              {{ typeName }} <a-icon type="down" />
            </a> -->
            <a-button suffix="left">
              {{ typeName }}
            </a-button>
            <a-menu slot="overlay">
              <a-menu-item v-for="type in typeList" :key="type.typeId" @click="initData(type)">
                <a>{{ type.typeName }}</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <a-button type="primary" prefix="add-one" @click="addShift">
            新增班次
          </a-button>
          <a-button class="del" prefix="reduce-one" :disabled="!selectedRowKeys.length" @click="batchDelete">
            批量删除
          </a-button>
        </div>
      </template>
    </appForm>
    <custom-table
      id="table"
      :columns="columns"
      :data-source="dataSource"
      row-key="shiftId"
      :pagination="false"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        columnWidth: 10,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.shiftType === 'X',
          },
        }),
      }"
      :selection-mode="true"
      @change="loadDataList"
    >
      <!-- 班次颜色 -->
      <template slot="colorThemeCode" slot-scope="{scope}">
        <div class="color-div" :style="getcolor(scope.record.colorThemeCode)" />
      </template>
      <!-- 班次类型 -->
      <template slot="shiftType" slot-scope="{scope}">
        <div class="ellipsis">{{ scope.record.shiftType | shiftTypes }}</div>
      </template>
      <!-- 出勤时间 -->
      <template slot="shiftPunchRulesDescription" slot-scope="{scope}">
        <div v-html="scope.record.shiftPunchRulesDescription || '-'" />
        <!-- <span>{{ scope.record.shiftPunchRulesDescription | shiftPunchRulesDescription }}</span> -->
      </template>
      <!-- 弹性设置 -->
      <template slot="shiftFlexType" slot-scope="{scope}">
        <span>{{ scope.record.shiftFlexType | flexType }}</span>
      </template>
      <!-- 工作时间 -->
      <template slot="shiftHours" slot-scope="{scope}">
        <span>{{ scope.record.shiftHours?scope.record.shiftHours+'小时':'-' }}</span>
      </template>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{scope}">
        <div class="flex-align-center">
          <template>
            <div class="more-handle-btn-box">
              <a-button :disabled="scope.record&&scope.record.shiftType === 'X'" type="link" @click="editShfit(scope.record)">
                编辑
              </a-button>
              <a-button :disabled="scope.record&&scope.record.shiftType === 'X'" class="delete" type="link" @click="delet(scope.record)">
                删除
              </a-button>
            </div>
          </template>
        </div>
      </template>
    </custom-table>
    <AddShiftModal ref="shiftModal" :form-type="formType" />
  </div>
</template>

<script>
import { shiftFlexType, shiftType } from '../../dict'
import { getShiftListPage, deleteShiftList } from '@/services/attendance/shift.js'
import { getcolorTheme } from '@/services/attendance/color.js'
import { debounce } from '@/utils/index.js'
import mixins from '@/components/CustomTable/index.js'
export default {
  name: 'ShiftManagement',
  components: {
    AddShiftModal: () => import('./shiftModal/index'),
    AlertTips: () => import('../../components/alert.vue')
  },
  filters: {
    flexType(data) {
      let type
      shiftFlexType.forEach((v) => {
        if (v.value === data) {
          type = v.label
        }
      })
      return type || '-'
    },
    shiftTypes(data) {
      let type
      shiftType.forEach((v) => {
        if (v.value === data) {
          type = v.label
        }
      })
      return type || '-'
    },
    shiftPunchRulesDescription(val) {
      return val.replace(/\\n/g, '<br/>')
    }
  },
  mixins: [mixins],
  data() {
    return {
      formList: ['search'], // search搜索框 screen筛选 choice位置选择弹窗 export导出
      colorList: [],
      selectedRowKeys: [], // 当前选择列集合
      typeName: '全部班次',
      typeList: [
        { typeName: '全部班次', typeId: '0' },
        { typeName: '无用班次', typeId: '1' }
      ],
      columns: [
        {
          dataIndex: 'colorThemeCode',
          scopedSlots: { customRender: 'colorThemeCode' },
          key: 'colorThemeCode',
          width: '50px'
        },
        {
          title: '班次名称',
          dataIndex: 'shiftName',
          scopedSlots: { customRender: 'shiftName' },
          key: 'shiftName',
          width: '120px',
          ellipsis: true
        },
        {
          title: '班次简称',
          dataIndex: 'shiftShortName',
          key: 'shiftShortName',
          width: '100px',
          ellipsis: true
        },
        {
          title: '班次类型',
          dataIndex: 'shiftType',
          key: 'shiftType',
          scopedSlots: { customRender: 'shiftType' },
          width: '100px'
        },
        {
          title: '出勤时间',
          dataIndex: 'shiftPunchRulesDescription',
          key: 'shiftPunchRulesDescription',
          scopedSlots: { customRender: 'shiftPunchRulesDescription' },
          width: '240px'
        },
        {
          title: '弹性设置',
          dataIndex: 'shiftFlexType',
          scopedSlots: { customRender: 'shiftFlexType' },
          key: 'shiftFlexType',
          width: '200px'
        },
        {
          title: '出勤时长',
          dataIndex: 'shiftHours',
          scopedSlots: { customRender: 'shiftHours' },
          key: 'shiftHours',
          width: '100px'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '120px',
          fixed: 'right'
        }],
      dataSource: [],
      shiftFlexTypeList: shiftFlexType,
      loading: false,
      formVisible: true,
      formType: 'add'
    }
  },
  created() {
    this.getcolorThemeList()
  },
  methods: {
    // 查询系统颜色
    async getcolorThemeList() {
      const res = await getcolorTheme()
      this.colorList = res.data
    },
    getcolor(colorVal) {
      let color
      this.colorList.forEach((v) => {
        if (v.colorThemeCode === colorVal) {
          color = v.fontRgba
        }
      })
      return `background:rgba(${color})`
    },
    // 请求数据
    async fetchDataApiFn(_queryParam) {
      const res = await getShiftListPage({ shiftEnable: 0, ..._queryParam })
      console.log(res)
      if (res.code === 100) {
        this.dataSource = res.data
      }
    },
    // 条件查询
    initData({ typeName, typeId }) {
      this.typeName = typeName
      this._queryParam.shiftEnable = typeId
      this.serchGetData()
    },
    // 增加班次
    addShift() {
      this.formType = 'add'
      this.$refs.shiftModal.typeVisible = true
    },
    // 编辑班次
    editShfit(data) {
      this.formType = 'edit'
      this.$nextTick(() => {
        this.$refs.shiftModal.shiftVisible = true
        this.$refs.shiftModal.getShiftDetails(data.shiftId)
      })
    },
    // 删除班次
    delet(record) {
      this.selectedRowKeys = [record.shiftId]
      this.$Reconfirm({
        title: '删除班次',
        content: '确定要删除该班次吗？'
      }).then(() => {
        this.handleDelShift()
      }).catch(() => {
        console.log('no')
      })
    },
    // 批量删除
    batchDelete() {
      this.$Reconfirm({
        title: '删除班次',
        content: '确定要删除该班次吗？'
      }).then(() => {
        this.handleDelShift()
      }).catch(() => {
        console.log('no')
      })
    },
    // 确认删除班次
    handleDelShift: debounce(async function() {
      const res = await deleteShiftList({ shiftIds: this.selectedRowKeys })
      if (res.code === 100) {
        this.$message.success('删除成功！')
        this.serchGetData()
      }
    }, 500),
    // 表格选择项变更
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log('selectedRowKeys changed: ', selectedRowKeys, selectedRows)
      this.selectedRowKeys = selectedRowKeys
    },
    getSearchCallback({ searchValue }) {
      this._queryParam.searchWord = searchValue
      this.serchGetData()
    }
  }
}
</script>
<style lang="less" scoped>
.ShiftManagement{
  height: 100%;
  background-color: #fff;
  overflow: auto;
  padding:20px 20px;
  .color-div{
    width:24px;
    height:24px;
    border-radius: 2px;
    margin: 0 auto;
  }
  .custom-button{
    .ant-btn{
      margin-right: 12px;
    }
  }
}
.ellipsis{
  width:100%;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis
}
.more-handle-btn-box{
  display: flex;
  .ant-btn{
    margin-right: 16px;
    &:last-child{
      margin: 0;
    }
  }
}
</style>
