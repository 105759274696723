<template>
  <div class="DistributionRules">
    <!-- 事假 短期病假 探亲假 路途假 无发放规则 -->
    <div v-if="!['SHJ','DBJ','TQJ','LTJ'].includes(form.vacationTypeCode)">
      <BlockTitle title="发放规则" :is-bold="true" class="msg-title" />
      <AutomaticRules :form="form" />
      <!-- 有发放规则的 只有调休假没有发放时间 -->
      <a-form-model-item v-if="!form.vacationTypeCode!=='TXJ'" label="发放时间">
        <!-- 年假发放时间选择 -->
        <a-radio-group v-if="form.vacationTypeCode==='NXJ'" v-model="form.vacationGrantTimeType">
          <a-radio :style="radioStyle" value="1">
            以入职时间为开始时间，满<CbNumber v-model="form.grantWorkMonth" class="number-input" input-width="90" suffix="个月" :decimal-separator="false" />开始享有当年的年假
          </a-radio>
          <a-radio :style="radioStyle" value="2">
            每年<a-date-picker v-model="form.grantDate" class="large-input" format="MM-DD" value-format="MM-DD" mode="date" />
            <span>开始享受当年的年假</span>
          </a-radio>
        </a-radio-group>
        <!-- 其他都为固定申请即可享受 -->
        <span v-else>员工申请即可享受</span>
      </a-form-model-item>
      <a-form-model-item label="不享受假期">
        <a-checkbox-group v-model="form.vacationLimitStaffType" :options="vacationStaffList" />
      </a-form-model-item>
    </div>
  </div>
</template>

<script>
import { getTenantCode } from '@/services/dropDownInput/index.js'
export default {
  name: 'DistributionRules',
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index'),
    CbNumber: () => import('@/components/CbNumber/index'),
    AutomaticRules: () => import('./components/automaticRules.vue')
  },
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      vacationStaffList: [],
      radioStyle: {
        display: 'block',
        lineHeight: '45px'
      }
    }
  },
  created() {
    this.getVacationStaff()
  },
  methods: {
    // 获取假期限制员工类别
    async getVacationStaff() {
      const staff = await getTenantCode({ codeName: 'vacation_limit_staff_type' })
      this.vacationStaffList = staff.data.map((v) => {
        return {
          label: v.content,
          value: v.codeKey
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.DistributionRules {
  .msg-title {
    margin-bottom: 14px;
    margin-top: 2px;
  }
  .number-input {
    width: 91px;
    margin: 0 5px;
    display: inline-block;
  }
  .large-input {
    width: 161px;
    margin: 0 10px;
    display: inline-block;
  }
  /deep/.ant-form-item-label {
    font-weight: 600;
    width: 100%;
  }
  /deep/.ant-form-item-control-wrapper{
    width: 100%;
  }
  /deep/.ant-form-item-control {
    width:100%;
    /deep/.ant-checkbox-group{
       width:100%;
    }
  }
  /deep/.ant-checkbox-wrapper {
    margin-bottom: 20px;
  }
  /deep/.ant-checkbox-group-item{
    color: @sc-grey-80;
  }
  /deep/.ant-form-item-children{
    color: @sc-grey-80;
  }
}
</style>
