<template>
  <div class="framework">
    <div class="organization-tree-blcok">
      <div class="organization-btn">
        <div class="operation">
          <a-button type="primary" class="frame-btn" prefix="chart-graph" @click="frameworkVisible = true">
            <span class="chartGraph">框架图</span>
          </a-button>
          <a-button v-if="env==='development'" class="frame-btn" @click="frameVisible = true">
            <svg class="iconpark-icon"><use href="#chart-graph" /></svg>
            <span class="chartGraph">框架图</span>
          </a-button>
          <div class="virtual_organization">
            <a-switch v-model="virtualSwitch" size="small" @change="virtualSwitchChange" />
            <span>显示虚拟组织</span>
          </div>
        </div>
        <div class="operation-right">
          <a-dropdown class="more-operations" type="primary">
            <template #overlay>
              <a-menu>
                <a-menu-item @click="getFile">归档</a-menu-item>
                <a-menu-item @click="getArchiveHistory">归档记录</a-menu-item>
              </a-menu>
            </template>
            <div class="top-operation_operation">
              <a-button suffix="left">归档</a-button>
            </div>
          </a-dropdown>
          <a-dropdown class="more-operations" type="primary">
            <template #overlay>
              <a-menu>
                <a-menu-item @click="getMerge">合并</a-menu-item>
                <a-menu-item @click="getOrganizationManagement">组织类型管理</a-menu-item>
              </a-menu>
            </template>
            <div class="top-operation_operation">
              <a-button suffix="left">更多</a-button>
            </div>
          </a-dropdown>
          <div class="exportBtn">
            <a-tooltip placement="top">
              <template slot="title">
                <span>导出</span>
              </template>
              <a-button type="iconBtn" icon="daochu" @click="exportTreeList" />
            </a-tooltip>
          </div>
        </div>
      </div>
      <div class="top-operation">
        <!-- 操作行 -->
        <div class="total-organization-left">
          <svg class="iconpark-icon cityName"><use href="#building-2-fill" /></svg>
          <span class="company">
            {{ companyinfo.orgName }}
          </span>
        </div>
        <div class="total-organization-right">
          <a-button type="link" @click="addLowerLevel(companyinfo.orgId)">
            新增下级
          </a-button>
          <a-button class="add-first-item" type="link" @click="orgImport">
            组织部门导入
          </a-button>
        </div>
      </div>
      <div v-if="gData.length > 0" class="tree-block">
        <a-tree
          ref="Tree"
          :expanded-keys="expandedKeys"
          :auto-expand-parent="autoExpandParent"
          :tree-data="gData"
          :replace-fields="{ children: 'children', title: 'orgName', key: 'orgId' }"
          :show-line="false"
          :draggable="true"
          :selectable="false"
          :block-node="false"
          @expand="onExpand"
          @drop="onDrop"
        >
          <template slot="name" slot-scope="{ orgName,leaderName, leaderType, orgTypeName, orgId, invented, children, onJobId }">
            <div class="name">
              <span :title="orgName">
                {{ orgName }}
              </span>
            </div>
            <div class="last-line">
              <div class="leader-name">
                <!--                  <img src="@/assets/img/alipay.png" alt=""> -->
                <div v-if="leaderName" class="leader-header-img">
                  <CbAvater :on-job-id="onJobId" :avater-name="leaderName" size="30" :default-name-length="2" />
                </div>
              </div>
              <div class="orgin-name">
                {{ orgTypeName }}
              </div>
              <div v-if="invented" class="fictitious">
                虚拟
              </div>
              <div class="add-lower-level">
                <a-button class="addNext" type="link" @click="addLowerLevel(orgId, invented)">
                  <span>新增下级</span>
                </a-button>
                <a-button class="organization" type="link" @click="changeOrganization(orgId, onJobId, leaderType)">
                  <span>组织负责人</span>
                </a-button>
                <a-popover placement="bottomRight" trigger="hover" destroy-tooltip-on-hide>
                  <template slot="content">
                    <div class="more-handle-btn">
                      <a-button type="link" @click="editItem(orgId)">
                        <span>编辑</span>
                      </a-button>
                      <a-button type="link" @click="detailsItem(orgId)">
                        <span>详情</span>
                      </a-button>
                      <a-button type="link" @click="moveItem(orgId)">
                        <span>移动</span>
                      </a-button>
                      <a-button v-if="!invented || (invented && children.length <= 0)" class="delete" type="link" @click.once="deleteItem(orgId)">
                        <span>删除</span>
                      </a-button>
                    </div>
                  </template>
                  <div class="more-handle">
                    ···
                  </div>
                </a-popover>
                <div class="drag_class">
                  <svg class="iconpark-icon dragIcon"><use href="#drag" /></svg>
                </div>
              </div>
            </div>
          </template>
        </a-tree>
      </div>
      <div v-else class="empty_box">
        <img class="emptyImg" :src="emptyImg" alt="">
        <p>暂无数据</p>
      </div>
    </div>
    <div v-if="showImport" class="archive_history">
      <ImportOrg @importCallback="importCallback" />
    </div>
    <!-- 新增编辑弹窗 -->
    <AddFrameworkModal ref="AddFrameworkModal" @AddFrameworkModalOk="AddFrameworkModalOk" />
    <!-- 员工详情弹窗 -->
    <StaffDetailsDrawer ref="StaffDetailsDrawer" />
    <!-- 架构相关 -->
    <div v-if="frameworkVisible" class="StructureChartBlock">
      <CbStructureChart :data-tree="fulOrgTree" @back="frameworkVisible = false" />
    </div>
    <div v-if="frameVisible" class="StructureChartBlock">
      <orgChart :chart-data="chartData" @back="frameVisible = false" />
    </div>
    <!-- 移动 -->
    <MovementModal ref="MovementModal" @moveSuccess="moveSuccess" />

    <!-- 二次删除确认 -->
    <!-- <a-modal v-model="delOrgVisible" title="删除组织" ok-text="确认" @ok="handleDelOrg">
      <p>确定删除该组织及其子组织？</p>
    </a-modal> -->

    <!-- 归档 -->
    <!-- <a-modal v-model="fileOrgVisible" title="归档" ok-text="确认" @ok="handleFileOrg">
      <p>是否确认归档？</p>
    </a-modal> -->

    <!-- 合并 -->
    <Merge ref="Merge" @mergeCallback="getOrgTree" />

    <!-- 组织类型管理 -->
    <OrganizationManagement ref="OrganizationManagement" />

    <!-- 归档历史 -->
    <div v-if="archiveHistoryVisible" class="archive_history">
      <ArchiveHistory @archiveHistoryCallback="archiveHistoryVisible = false" />
    </div>
    <!-- 组织负责人 -->
    <Organizationleader ref="organizationLeader" @changeFlag="changeFlag" />
  </div>
</template>

<script>
import AddFrameworkModal from './components/AddFrameworkModal.vue'
import Organizationleader from './components/Organizationleader.vue'
import StaffDetailsDrawer from './components/StaffDetailsDrawer'
import Merge from './components/Merge.vue'
import OrganizationManagement from './components/OrganizationManagement.vue'
import ArchiveHistory from './components/ArchiveHistory/ArchiveHistory'
import MovementModal from './components/MovementModal'
import ImportOrg from './components/ImportOrg.vue'
import { getOrgTree, deleteOrg, getOnOffInventOrg, editOnOffInventOrg, exportOrgTree, archive, moveOrg } from '@/services/organization/framework.js'
function findItem(arr, addId, FN) {
  try {
    arr.forEach((item) => {
      if (item.orgId === addId) {
        FN(item)
        throw new Error('Whoops!')
      }
      if (item.children && item.children.length > 0) {
        findItem(item.children, addId, FN)
      }
    })
  } catch (err) {
    console.warn('未找到元素信息', err)
  }
}

import CbStructureChart from '@/components/CbStructureChart'
import orgChart from '@/components/CbStructureChart/orgChart'
import CbAvater from '@/components/CbAvater/theAvater'
export default {
  name: 'Framework',
  components: {
    orgChart,
    Organizationleader,
    CbStructureChart,
    AddFrameworkModal,
    StaffDetailsDrawer,
    MovementModal,
    Merge,
    OrganizationManagement,
    ArchiveHistory,
    ImportOrg,
    CbAvater
  },
  props: {},
  data() {
    return {
      env: process.env.VUE_APP_ENV,
      chartData: {},
      frameVisible: false,
      showImg: true,
      popoverFirstShow: false,
      popoverVisible: false,
      test: 11,
      expandedKeys: [],
      searchValue: '',
      autoExpandParent: true,
      gData: [],
      gDatas: [],
      datas: [],
      visible: false,
      addId: '', // 当前要增加的id
      id: null,
      editItemId: null,
      details: {},
      frameworkVisible: false,
      rootId: null,
      delOrgVisible: false,
      copyOrgId: '',
      deep: 0,
      companyinfo: {},
      fulOrgTree: [],
      virtualSwitch: false,
      fileOrgVisible: false,
      archiveHistoryVisible: false,
      basePath: process.env.VUE_APP_API_BASE_URL,
      emptyImg: require('@/assets/img/home/noData.png'),
      showImport: false
    }
  },
  async mounted() {
    await this.getOrgTree()
    await this.getOnOffInventOrg()
    this.$nextTick(() => {
      const { orgId } = this.$route.query
      if (orgId) {
        this.detailsItem(orgId)
      }
    })
  },
  methods: {
    changeOrganization(val, id, type) {
      this.$refs.organizationLeader.showModal(val, id, type)
    },
    changeFlag(val) {
      this.organizationFlag = val
      this.getOrgTree()
    },
    setTableHeight() {
      const contentH = document.body.scrollHeight - 48
      const treeH = document.querySelector('.ant-tree')
      const treeHeight = contentH - treeH.offsetTop
      treeH.classList.add('beauty-scroll')
      treeH.style = `max-height: ${treeHeight}px; overflow-y:scroll; overflow-x:hidden;`
    },
    async getOrgTree() {
      const { code, data } = await getOrgTree({ nested: true, staffNum: true, invented: '1', scope: false })
      if (code !== 100) return
      // 将数据添加自定义的属性
      this.setSlotItem(data, 0)
      // 保留原始数组 用于架构图
      this.fulOrgTree = JSON.parse(JSON.stringify(data))
      const depData = JSON.parse(JSON.stringify(data))
      const { orgName, orgTypeName, orgId } = depData[0]
      // 整合公司信息
      this.companyinfo = {
        orgName,
        orgTypeName,
        orgId
      }
      // 拆分数据 将根节点 子节点拆分
      this.gData = depData[0].children
      this.gDatas = depData
      this.chartData = this.gDatas[0]
      this.$nextTick(() => {
        this.gData.length === 0 ? false : this.setTableHeight()
      })
    },
    // 获取虚拟组织开关
    async getOnOffInventOrg(data) {
      const res = await getOnOffInventOrg()
      this.virtualSwitch = res.data === '0'
      if (data === 'edit') {
        this.getOrgTree()
      }
    },
    // 编辑开关虚拟组织
    async virtualSwitchChange() {
      const res = await editOnOffInventOrg({ onOff: this.virtualSwitch ? '0' : '1' })
      if (res.code === 100) {
        this.getOnOffInventOrg('edit')
      }
    },
    setSlotItem(arr, index) {
      arr.forEach((item, index) => {
        item.scopedSlots = { 'title': 'name' }
        item.id = item.orgId
        item.show = true
        item.index = index
        item.Id = item.id
        if (index > this.deep) {
          this.deep = index
        }
        item.deep = index
        if (item.children && item.children.length > 0) {
          this.setSlotItem(item.children, this.deep + 1)
        }
      })
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    addLowerLevel(addId, invented) {
      this.$refs.AddFrameworkModal.open('add', addId, {}, invented)
    },
    moveItem(addId) {
      this.$refs.MovementModal.open(this.gDatas, addId)
    },
    moveSuccess() {
      this.getOrgTree()
    },
    AddFrameworkModalOk(a, b, c) {
      this.expandedKeys.push(c)
      this.getOrgTree()
    },
    editItem(editItemId) {
      findItem(this.gData, editItemId, (item) => {
        this.$refs.AddFrameworkModal.open('edit', editItemId, item)
      })
    },
    detailsItem(key) {
      findItem(this.gData, key, (item) => {
        this.$refs.StaffDetailsDrawer.open({ ...item })
      })
    },
    deleteItem(orgId) {
      this.copyOrgId = orgId
      this.$Reconfirm({
        title: '删除组织',
        content: '确定删除该组织及其子组织？'
      }).then(async() => {
        const { copyOrgId } = this
        const { code } = await deleteOrg({ orgId: copyOrgId })
        if (code !== 100) return
        this.$message.success('删除成功')
        this.getOrgTree()
      }).catch(() => {
        console.log('no')
      })
    },
    // // tree拖拽
    onDrop(info) {
      const dropKey = info.node.eventKey
      const dragKey = info.dragNode.eventKey
      const dropPos = info.node.pos.split('-')
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])
      const loop = (data, key, callback) => {
        data.forEach((item, index, arr) => {
          if (item.orgId === key) {
            return callback(item, index, arr)
          }
          if (item.children) {
            return loop(item.children, key, callback)
          }
        })
      }
      const data = [...this.gData]

      // // Find dragObject
      let dragObj
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1)
        dragObj = item
      })
      if (!info.dropToGap) {
        // Drop on the content
        loop(data, dropKey, item => {
          item.children = item.children || []
          // where to insert 示例添加到尾部，可以是随意位置
          item.children.push(dragObj)
        })
      } else if (
        (info.node.children || []).length > 0 && // Has children
        info.node.expanded && // Is expanded
        dropPosition === 1 // On the bottom gap
      ) {
        loop(data, dropKey, item => {
          item.children = item.children || []
          // where to insert 示例添加到尾部，可以是随意位置
          item.children.unshift(dragObj)
        })
      } else {
        let ar
        let i
        loop(data, dropKey, (item, index, arr) => {
          ar = arr
          i = index
        })
        if (dropPosition === -1) {
          ar.splice(i, 0, dragObj)
        } else {
          ar.splice(i + 1, 0, dragObj)
        }
      }
      // this.gData = data
      // 拖拽后处理调用接口，重新渲染树
      const parentId = this.getParentList(dragObj.orgId, data)[this.getParentList(dragObj.orgId, data).length - 2]
      let indexData
      if (!parentId) {
        // 顶级拖拽
        const treeOrg = data.map((item, index) => {
          return {
            orgId: item.orgId,
            sortNum: index + 1,
            orgName: item.orgName
          }
        })
        this.getMoveOrg(dragObj.orgId, this.gDatas[0].orgId, treeOrg)
      } else {
        // 下级拖拽
        loop(data, parentId, (item, index, arr) => {
          indexData = item.children.map((item, index) => {
            return {
              orgId: item.orgId,
              sortNum: index + 1,
              orgName: item.orgName
            }
          })
        })
        this.getMoveOrg(dragObj.orgId, parentId, indexData)
      }
    },
    // 拖拽接口
    async getMoveOrg(orgId, parentId, indexData) {
      const res = await moveOrg({
        orgId: orgId,
        parentId: parentId,
        orgList: indexData
      })
      if (res.code === 100) {
        this.getOrgTree()
      }
    },
    // 获取父级id
    getParentList(id, list = [], result = []) {
      for (let i = 0; i < list.length; i += 1) {
        const item = list[i]
        // 找到目标
        if (item.id === id) {
          // 加入到结果中
          result.push(item.id)
          // 因为可能在第一层就找到了结果，直接返回当前结果
          if (result.length === 1) return result
          return true
        }
        // 如果存在下级节点，则继续遍历
        if (item.children) {
          // 预设本次是需要的节点并加入到最终结果result中
          result.push(item.id)
          const find = this.getParentList(id, item.children, result)
          // 如果不是false则表示找到了，直接return，结束递归
          if (find) {
            return result
          }
          // 到这里，意味着本次并不是需要的节点，则在result中移除
          result.pop()
        }
      }
    },
    // 归档
    getFile() {
      this.popoverVisible = false
      this.popoverFirstShow = false
      this.$Reconfirm({
        title: '归档',
        content: '是否确认归档？'
      }).then(async() => {
        const res = await archive({})
        if (res.code === 100) {
          this.$message.success('归档成功')
          this.getOrgTree()
          this.getOnOffInventOrg()
        }
      }).catch(() => {
        console.log('no')
      })
    },
    // 合并
    getMerge() {
      this.$refs.Merge.show()
      this.popoverVisible = false
    },
    // 组织类型管理
    getOrganizationManagement() {
      this.$refs.OrganizationManagement.show()
      this.popoverVisible = false
    },
    // 归档记录
    getArchiveHistory() {
      this.archiveHistoryVisible = true
      this.popoverVisible = false
      this.popoverFirstShow = false
    },
    // 导出
    exportTreeList() {
      exportOrgTree()
      this.popoverVisible = false
    },
    // imgError(e, Id) {
    //   this.depFor(this.gData, e, Id)
    // },
    // depFor(arr, e, Id) {
    //   arr.forEach((i) => {
    //     if (i.id === Id) {
    //       i.show = false
    //     } else {
    //       this.depFor(i.children, e, Id)
    //     }
    //   })
    // },
    orgImport() {
      this.showImport = true
    },
    importCallback(e) {
      this.showImport = e
      this.getOrgTree()
    }
  }
}
</script>

<style lang="less" scoped>
@treeItemHight: 44px;
@lineRight: 30px;
@normalSize: 16px;
@fontSize: 14px;
.framework {
  width: 100%;
  padding: 20px 20px 0;
  box-sizing: border-box;
  background: #fff;
  height: 100%;
  overflow: hidden;
  .organization-tree-blcok {
    flex: 1;
    .organization-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .operation {
        display: flex;
        .frame-btn {
          margin-right: 12px;
        }
        /deep/ .ant-btn > span {
          color: @sc-grey-100;
        }
        .virtual_organization {
          display: flex;
          align-items: center;
          font-size: @normalSize;
          line-height: 30px;
          margin-right: 30px;
          color: @sc-grey-100;
          span {
            margin-left: 12px;
          }
        }
        .top-operation_operation {
          margin-right: 10px;
        }
      }
      .operation-right {
        display: flex;
        align-items: center;
        .more-operations {
          margin-right:12px;
        }
      }
    }
    .top-operation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 44px;
      width: 100%;
      background:  @sc-greyBg-10;
      border-radius: 4px 4px 0 0;
      .total-organization-left {
        display: flex;
        align-items: center;
         .cityName {
          width: 19px;
          height: 17px;
          margin-left: 15px;
          margin-right: 5px;
        }
        .company {
          color: @sc-grey-100;
          font-weight: 500;
          font-size: @normalSize;
        }
        &:hover {
          .add-first-item {
            color: @sc-primary-80;
          }
        }
      }
      .total-organization-right {
        margin-right: 115px;
        .add-first-item {
          margin-right: 35px !important;
          margin-left: 30px !important;
        }
        .org-import {
          margin-right: 0;
        }
      }
    }
    .tree-block {
      overflow: hidden;
      // tree 组件修改
      /deep/ .ant-tree {
        > li > span.ant-tree-node-content-wrapper > .ant-tree-title > .name > span {
            font-size: @fontSize;
            color:  @sc-grey-100;
            font-weight: 500;
          }
        li {
          width: 100%;
          overflow: visible;
          padding: 0;
          span.ant-tree-switcher {
            height: @treeItemHight;
            line-height: @treeItemHight;
            .ant-tree-switcher-icon {
              font-size: 18px;
              vertical-align: middle;
            }
            &:hover {
              & + .ant-tree-node-content-wrapper {
                background: @sc-primary-10;
                .last-line {
                  display: flex;
                }
              }
            }
          }
        }
        .ant-tree-node-content-wrapper {
          position: relative;
          width: 100%;
          height: 50px;
          line-height: 50px;
          border-radius: 0;
          &:hover {
            background: @sc-primary-10;
            .last-line {
              display: flex;
            }
          }
          &:after {
            content: '';
            right: @lineRight;
            width: 98%;
            position: absolute;
            border-bottom: 1px solid @sc-greyBg-20;
            bottom: 0;
          }
        }
      }
      .last-line {
        position: absolute;
        display: none;
        display: flex;
        right: @lineRight;
        top: 0;
        height: 50px;
        line-height: 50px;
        align-items: center;
        .leader-name {
          text-align: left;
          color: @sc-grey-100;
          margin-right: 10px;
          width: 200px;
        }
        .orgin-name {
          color:  @sc-grey-60;
          text-align: left;
          width: 100px;
        }
        .add-lower-level {
          margin-left: 380px;
          margin-right: 30px;
          display: flex;
          align-items: center;
          .ant-btn.link span{
            color: @sc-primary-100;
          }
          .addNext {
            margin-right: 30px;
          }
          .organization{
            overflow: visible;
            margin-right: 30px;
          }
          .more-handle {
            font-weight: 700;
            line-height: @treeItemHight;
            font-size: 20px;
            vertical-align: baseline;
            color: @sc-primary-100;
            margin-right: 60px;
          }
          .drag_class {
            color: @sc-grey-60;
            .dragIcon{
              width: 16px;
              height: 16px;
              color: @sc-greyBg-100;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}

// 操作按钮相关
.more-handle-btn {
  display: flex;
  flex-direction: column;
}
.frameworkVisible {
  // width: 700px;
  /deep/.ant-drawer-content-wrapper {
    width: 1200px !important;
  }
}
.StructureChartBlock {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: @sc-grey-80;
}
  .archive_history {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
  }
  .fictitious {
    position: absolute;
    top: @fontSize;
    left: 550px;
    width: 49px;
    height: 24px;
    background: @sc-greyBg-10;
    border-radius: 2px;
    text-align: center;
    line-height: 24px;
    font-size: @fontSize;
    color:  @sc-grey-100;
  }
.name{
  width:200px;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis
}
/deep/ .ant-tree li span {
  color: @sc-grey-80;
  border: 0;
}
.imgnone{
  display: none;
}
.empty_box{
  width: 100%;
  text-align: center;
  margin-top: 386px;
.emptyImg{
  width: 186px;
  height: 165.33;
}
}
/deep/ .ant-popover-content {
  margin-top: 0;
}
/deep/ .ant-popover-arrow {
  display: block;
  top: 10px;
}
</style>
