<template>
  <div>
    <Modal
      overflow-auto
      :closable="true"
      :size="'normal'"
      :mask-closable="false"
      :visible="visible"
      :title="'公式测算'"
      @cancel="onClose"
    >
      <div class="modal-content">
        <!-- 单行指标项 -->
        <template v-if="singleLine&&singleLine.length>0">
          <div v-for="singleItem in singleLine" :key="singleItem.indexNo" class="modal-content-item flex-align-center">
            <span>{{ singleItem.indexName }}</span>
            <a-input v-if="singleItem.indexDataType === 'C'" v-model="singleItem.indexValue" />
            <CbNumber v-else-if="singleItem.indexDataType === 'N'" v-model="singleItem.indexValue" :decimal-separator="true" />
            <DictionariesInput v-else-if="singleItem.indexDataType === 'L'" v-model="singleItem.indexValue" :parameter="singleItem.indexCode" />
          </div>
        </template>
        <!-- 多行指标项 -->
        <template v-else>
          <div v-for="(line,i) in multiLine" :key="i" class="multiLine">
            <div>
              <div class="modal-content-item flex-align-center flex-just-between">
                <template v-if="line.indexNo1==='xh'">
                  第{{ i+1 }}次
                </template>
                <template v-else>
                  <span>{{ line.indexName1 }}</span>
                  <a-input v-if="line.indexDataType1 === 'C'" v-model="line.indexValue1" />
                  <CbNumber v-else-if="line.indexDataType1 === 'N'" v-model="line.indexValue1" :decimal-separator="true" />
                  <DictionariesInput v-else-if="line.indexDataType1 === 'L'" v-model="line.indexValue1" :parameter="line.indexCode1" />
                </template>
                <a-button v-if="multiLine.length > 1" type="iconBtn" icon="close-small" class="close-circle" @click="delLine(i)" />
              </div>
              <div v-if="line.indexName2" class="modal-content-item flex-align-center">
                <span>{{ line.indexName2 }}</span>
                <template v-if="line.indexNo2 === 'rxbsj'">
                  <CbTimePicker v-model="line.indexValue2" :options="timeSetUp" />
                </template>
                <template v-else>
                  <a-input v-if="line.indexDataType2 === 'C'" v-model="line.indexValue2" />
                  <CbNumber v-else-if="line.indexDataType2 === 'N'" v-model="line.indexValue2" :decimal-separator="true" />
                  <DictionariesInput v-else-if="line.indexDataType2 === 'L'" v-model="line.indexValue2" :parameter="line.indexCode2" />
                </template>
              </div>
            </div>
          </div>
          <a-button type="iconBtn" icon="add-one" class="plus-circle" @click="addLine" />
        </template>
        <div class="modal-content-item flex-align-center">
          <span>计算结果</span>
          <span>{{ result!==''? result:'--' }}</span>
        </div>
      </div>
      <template #footer>
        <a-button @click="onClose">退出</a-button>
        <a-button @click="clearData">重置</a-button>
        <a-button type="primary" @click="calculation">计算</a-button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { getIndexItem, formulaCalc } from '@/services/wage/salaryGroup.js'
export default {
  components: {
    Modal: () => import('@/components/CbModal/index.vue'),
    CbNumber: () => import('@/components/CbNumber/index.vue'),
    DictionariesInput: () => import('@/components/CbDropDownInput/dictionariesInput/index.vue'),
    CbTimePicker: () => import('@/components/CbTimePicker/index.vue')
  },
  data() {
    return {
      timeSetUp: {
        format: 'HH:mm',
        valueFormat: 'HHmm',
        placeholder: '时间',
        clearable: false,
        width: '115px'
      },
      visible: false,
      detailInfo: {},
      // 单行指标项数据
      singleLine: [],
      // 多行指标项数据
      multiLine: [],
      result: ''
    }
  },
  methods: {
    // 公式计算
    async calculation() {
      const res = await formulaCalc({ ...this.detailInfo, indexItem: { singleLine: this.singleLine, multiLine: this.multiLine }})
      if (res.code === 100) {
        this.$message.success('计算成功!')
        this.result = res.data
      }
    },
    // 获取指标项详情
    async getIndexItem() {
      const res = await getIndexItem({ formulaNo: this.detailInfo.formulaNo, formulaValue: this.detailInfo.formulaValue })
      res.data.multiLine ? this.multiLine.push(res.data.multiLine) : this.singleLine = res.data.singleLine
    },
    addLine() {
      const { indexName1, indexName2, indexNo1, indexNo2, indexDataType1, indexDataType2, indexCode1, indexCode2 } = this.multiLine[0]
      this.multiLine.push({ indexName1, indexName2, indexNo1, indexNo2, indexDataType1, indexDataType2, indexCode1, indexCode2 })
    },
    delLine(index) {
      this.multiLine.splice(index, 1)
    },
    onClose() {
      this.visible = false
    },
    open(detail) {
      console.log(detail)
      this.result = ''
      this.singleLine = []
      this.multiLine = []
      this.detailInfo = { ... detail }
      // 获取指标项详情
      this.getIndexItem()
      this.visible = true
    },
    // 重置数据
    clearData() {
      if (this.singleLine?.length) {
        this.singleLine.forEach((v) => {
          v.indexValue = ''
        })
      } else {
        this.multiLine.forEach((line) => {
          line.indexValue1 = ''
          if (line.indexName2) {
            line.indexValue2 = ''
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.modal-content {
  &-item{
    color:@sc-grey-100;
    .font-size(14px);
    margin-bottom: 24px;
    span:first-child{
        display:inline-block;
        width:90px;
        margin-right: 12px;
    }
    /deep/ .ant-input,/deep/.ant-select-selection {
      width:320px
    }
  }
  .multiLine{
    display:flex;
    .ant-btn{
      margin-left: 12px;
    }
  }
}
.close-circle{
  &:hover{
    /deep/.iconpark-icon{
    color: @error-color;
    }
    border-color: @error-color;
  }
}
.plus-circle{
  margin-bottom: 20px;
}
/deep/.primary{
  color: #fff !important;
  &:hover{
    color: #fff !important;
  }
}
</style>
