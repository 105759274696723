<template>
  <div class="MemoMatter">
    <!-- 备忘事项 -->
    <div class="date-matter">
      <div v-for="matter in memoMatterList.list" :key="matter.id">
        <a-popover v-model="matter.propoVisible" trigger="hover" placement="leftTop" overlay-class-name="matter-popover">
          <template slot="content">
            <div class="popover-title">
              <div class="title">{{ matter.title }}</div>
              <div class="text">{{ matter.content }}</div>
            </div>
            <div class="popover-content">
              <div class="content-item flex-align-center">
                <a-icon class="item-icon" type="clock-circle" />
                <span class="item-title">开始时间</span>
                <span class="item-text">{{ matter.memoDateValue }}</span>
              </div>
              <div v-if="matter.durationValue" class="content-item flex-align-center">
                <a-icon class="item-icon" type="clock-circle" />
                <span class="item-title">时长</span>
                <span class="item-text">{{ matter.durationValue }}</span>
              </div>
              <div class="content-item flex-align-center">
                <a-icon class="item-icon" type="clock-circle" />
                <span class="item-title">提醒</span>
                <span class="item-text">{{ matter.remindValue }}</span>
              </div>
              <div class="content-item flex-align-center">
                <a-icon class="item-icon" type="clock-circle" />
                <span class="item-title">重复</span>
                <span class="item-text">{{ matter.repeatTypeValue }}</span>
              </div>
            </div>
            <div class="popover-bottom">
              <a-button @click="editMatter(matter)">编辑</a-button>
              <a-button @click="delMatter(matter)">删除</a-button>
            </div>
          </template>
          <div class="matter flex-align-center">
            <!-- 时间 -->
            <span v-if="+matter.allDay" class="matter-time">全天</span>
            <div v-else class="matter-time">
              <span>{{ matter.startTime }}</span>
              <span>{{ matter.endTime }}</span>
            </div>
            <div class="matter-content">
              <div class="matter-title flex-align-center">
                <span class="type-color success" />
                <span>{{ matter.title }}</span>
              </div>
              <div class="matter-text">
                {{ matter.content }}
              </div>
            </div>
          </div>
        </a-popover>
      </div>
    </div>
    <!-- 编辑提示框 -->
    <Modal
      :visible="editVisible"
      :ok-text="'确认'"
      :size="'normal'"
      closable
      type="info"
      :title="'你正在更改重复日程'"
      @ok="handleEdit"
      @cancel="editVisible=false"
    >
      <template class="model-content">
        <a-radio-group v-model="editType" name="radioGroup">
          <a-radio :style="radioStyle" :value="1">仅此日程</a-radio>
          <a-radio :value="3">此日程及后续日程</a-radio>
        </a-radio-group>
      </template>
    </Modal>
    <!-- 删除提示框 -->
    <Modal
      :visible="delVisible"
      :ok-text="'确认'"
      :size="'normal'"
      closable
      type="info"
      :title="'您正在删除日程'"
      @ok="handleDel"
      @cancel="delVisible=false"
    >
      <div class="model-content">
        <a-radio-group v-model="delType" name="radioGroup">
          <a-radio v-for="item in scheduleList" :key="item.value" :style="item.value!==3?radioStyle:lastRadioStyle" :value="item.value">{{ item.label }}</a-radio>
        </a-radio-group>
      </div>
    </Modal>
  </div>
</template>

<script>
import { deleteCurrMemo, deleteFutureMemo, deleteAllMemo } from '@/services/home/calendar.js'
import { debounce } from '@/utils/index.js'
export default {
  name: 'MemoMatter',
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  props: {
    memoMatterList: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      scheduleList: [
        { label: '仅此日程', value: 1 },
        { label: '所有日程', value: 2 },
        { label: '此日程及后续日程', value: 3 }
      ],
      radioStyle: {
        display: 'inlineBlock',
        marginRight: '18px'
      },
      lastRadioStyle: {
        display: 'inlineBlock',
        marginRight: '0px'
      },
      delVisible: false,
      editVisible: false,
      // 删除备忘类型
      delType: 1,
      // 删除备忘类型api
      delTypeObj: {
        1: {
          api: deleteCurrMemo
        },
        2: {
          api: deleteAllMemo
        },
        3: {
          api: deleteFutureMemo
        }
      },
      // 编辑备忘类型
      editType: 1,
      memoId: '',
      repeatId: '',
      matterObj: {}
    }
  },
  methods: {
    editMatter(matter) {
      this.editType = 1
      matter.propoVisible = false
      this.matterObj = matter
      this.editVisible = true
    },
    delMatter(matter) {
      this.delType = 1
      matter.propoVisible = false
      this.memoId = matter.memoId
      this.repeatId = matter.repeatId
      this.delVisible = true
    },
    // 确认前往编辑
    handleEdit: debounce(async function() {
      console.log(+this.matterObj.allDay === 1)

      this.$emit('handleEdit', { ...this.matterObj, allDay: Boolean(+this.matterObj.allDay), editType: this.editType })
      this.editVisible = false
    }, 500),
    // 确认删除
    handleDel: debounce(async function() {
      const res = await this.delTypeObj[this.delType].api({ memoId: this.memoId, repeatId: this.repeatId })
      if (res.code === 100) {
        this.$message.success('删除成功！')
        this.delVisible = false
        this.$emit('refresh')
      }
    }, 500)
  }
}
</script>

<style lang="less">
.MemoMatter {
  .date-matter {
    padding: 0 8px;
  }
  .matter {
      height: 75px;
      padding: 0 6px;
      border-bottom: 1px solid @border-color;
      .matter-time{
        display: inline-block;
        display:flex;
        flex-direction: column;
        width: 35px;
      }
      .matter-content {
        margin-left: 14px;
        .font-size(14px);
        .matter-title {
          margin-bottom: 4px;
          span:nth-child(2) {
            margin-left: 8px;
            color: @sc-grey-100;
            font-weight: bold;
          }
          .type-color {
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
          }
          .success {
            background: rgba(97, 243, 192, 1);
          }
        }
        .matter-text{
          color: @sc-grey-60;;
          margin-left: 14px;
          .num{
            color: @sc-grey-100;
          }
        }
      }
    }
    .matter > span {
      color: @sc-grey-40;
      .font-size(14px);
    }
    .matter:hover {
      border-radius: 4px;
      background: @sc-greyBg-10;
    }
}
.matter-popover{
  .ant-popover-inner-content {
    box-shadow: 0px 4px 17px 1px rgba(73,86,128,0.1000);
    padding: 14px 12px !important;
    width: 404px;
  }
  .popover-title {
    .title{
      .font-size(16px);
      font-weight: bold;
      color: @sc-grey-100;
    }
    .text{
      margin: 4px 0 14px 0;
      .font-size(14px);
      color: @sc-grey-80;
    }
  }
  .popover-content{
    padding-bottom: 47px;
    border-top: 1px solid @border-color;
    .content-item{
       margin-top: 14px;
      .item-icon{
        margin-right:6px;
        line-height: 12px;
        color: @sc-grey-40;
      }
      .item-title{
        display: inline-block;
        width:80px;
        color: @sc-grey-40;
      }
      .item-text{
        color: @sc-grey-100;
      }
    }
  }
  .popover-bottom{
    border-top: 1px solid @border-color;
    position: absolute;
    bottom: 0;
    width: calc(100% - 12px);
    padding: 10px 16px;
    text-align: right;
    left: 0;
    background: #fff;
     border-radius: 0 0 4px 4px;
     .ant-btn{
      width: 66px;
      height: 28px;
      border-radius: 4px;
      color: @sc-grey-100;
     }
     .ant-btn:first-child:hover {
      color: @sc-primary-100;
      background:@sc-primary-10;
     }
     .ant-btn:last-child:hover {
      color: @error-color;
      border-color:@error-color;
      background: rgba(227, 77, 89, 0.1);
     }
    .ant-btn:first-child {
      margin-right:16px;
    }
  }
}
</style>

<style lang="less" scoped>
.model-title {
  .font-size(16px);
  font-weight: 600;
  color: @sc-grey-100;
  margin-bottom: 14px;
  .icon {
    width: 21px;
    height: 21px;
    margin-top: 4px;
    color: @sc-primary-100;
  }
  span {
    margin-left: 7px;
  }
}
.model-content {
  padding-left: 28px;
}
/deep/.ant-modal-body {
  padding: 24px;
}
/deep/.ant-radio-wrapper{
  color: @sc-grey-100;
}
/deep/.ant-modal-footer {
  padding: 6px 24px 24px 24px;
  .ant-btn {
    width: 74px;
    height: 30px;
    border-radius: 4px;
  }
  .ant-btn:first-child{
    background: @sc-greyBg-20;
  }
}
</style>
