<!--
 * @Author: luoyi
 * @Date: 2022-02-22 10:32:12
 * @Last Modified by:   luoyi
 * @Last Modified time: 2022-02-22 10:32:12
-->
<template>
  <Modal
    :closable="true"
    :visible="visible"
    :title="(mode == 'add' ? '新建' : '编辑') + '岗位'"
    :size="'normal'"
    :mask-closable="false"
    :ok-text="'保存'"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="岗位名称" prop="postName">
        <a-input v-model="form.postName" placeholder="请输入1-30个字符" />
      </a-form-model-item>
      <a-form-model-item label="适用组织" prop="orgList">
        <a-tree-select
          v-model="form.orgList"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="treeData"
          placeholder="请选择适用组织"
          allow-clear
          multiple
          :replace-fields="{
            children: 'children',
            title: 'orgName',
            key: 'orgId',
            value: 'orgId'
          }"
          tree-default-expand-all
          :search-value="searchvalue"
          tree-node-filter-prop="title"
          @search="handleSearch"
        />
      </a-form-model-item>
      <a-form-model-item label="对应职类">
        <a-select v-model="form.jobTypeId" placeholder="请选择" :options="postGroup" allow-clear @change="jobTypeIdChange" />
      </a-form-model-item>
      <a-form-model-item label="对应职级" prop="postUpper">
        <div class="choice_rank">
          <span class="choice_rank_top">下限</span>
          <a-select v-model="form.jobGradeFloor" :disabled="category" :options="rankList" placeholder="请选择" allow-clear style="width: 120px" />
          <span class="choice_rank_bottom">上限</span>
          <a-select v-model="form.jobGradeUpper" :disabled="category" :options="rankList" placeholder="请选择" allow-clear style="width: 120px" />
        </div>
      </a-form-model-item>
      <a-form-model-item label="岗位说明" prop="detail">
        <a-input
          v-model="form.detail"
          type="textarea"
          placeholder="请输入0-200个字符"
          :auto-size="{ minRows: 3, maxRows: 7 }"
        />
      </a-form-model-item>
    </a-form-model>
  </Modal>
</template>

<script>
import { getOrgTree } from '@/services/organization/framework.js'
import { createPostJobType, updatePostJobType } from '@/services/organization/post.js'
import { getRankDropDownList, getRankGroupList } from '@/services/organization/rank.js'
export default {
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      searchvalue: '',
      visible: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      postGroup: [],
      rankList: [],
      form: {
        postTypeId: null,
        postId: null,
        postName: '',
        orgList: [],
        detail: '',
        jobTypeId: undefined,
        jobGradeUpper: undefined,
        jobGradeFloor: undefined
      },
      rules: {
        postName: [
          {
            required: true,
            message: '请输入岗位名称',
            trigger: 'blur'
          },
          { min: 1, max: 30, message: '请输入1-30个字符', trigger: 'change' }
        ],
        postUpper: [{ required: false, validator: this.getPostUpper, trigger: 'change' }],
        detail: [
          { min: 0, max: 200, message: '请输入0-200个字符', trigger: 'change' }
        ],
        orgList: [
          {
            required: true,
            message: '请选择适用组织'
          }
        ]
      },
      treeData: [] // 适用组织数据
    }
  },
  computed: {
    mode() {
      // 根据id自动计算新增、编辑
      return this.form.postId ? 'update' : 'add'
    },
    first: {
      get() {
        return !!this.form.postId
      },
      set(v) {
      }
    },
    category() {
      let boolean = true
      if (this.form.jobTypeId && this.form.jobTypeId.length > 0) {
        boolean = false
      } else {
        boolean = true
      }
      return boolean
    }
  },
  watch: {
    rankList(val) {
    }
  },
  mounted() {
    this.initOrgData()
    this.$nextTick(() => {
      document.addEventListener('click', (event) => {
        event.stopPropagation()
        this.searchvalue = ''
      })
    })
    this.getPostGroupDropDownList()
  },
  methods: {
    // 上下限校验
    getPostUpper(rule, value, callback) {
      if (this.form.jobGradeUpper && !this.form.jobGradeFloor) {
        callback(new Error('请输入下限'))
      } else if (!this.form.jobGradeUpper && this.form.jobGradeFloor) {
        callback(new Error('请输入上限'))
      } else if (this.form.jobGradeUpper && this.form.jobGradeFloor) {
        if (+this.form.jobGradeUpper < +this.form.jobGradeFloor) {
          callback(new Error('上限必须大于下限'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    },
    getCalendarContainer() {
      return triggerNode => triggerNode.parentNode
    },
    // 初始化适用组织列表
    initOrgData() {
      getOrgTree({
        parentId: null,
        nested: true,
        scope: false
      }).then((res) => {
        if (res.code === 100) {
          this.treeData = res.data
        }
      })
    },
    // 打开弹窗
    open(data) {
      if (data) {
        if (data.jobTypeId) {
          this.jobTypeIdChange(data.jobTypeId).then((res) => {
            const orgList = data.orgList ? data.orgList.map((v) => v.orgId) : [] // 过滤已选组织
            this.form = Object.assign(this.form, { ...data })
            this.form.orgList = orgList
            this.form.jobTypeId = data.jobTypeId ? data.jobTypeId : undefined
            this.form.jobGradeUpper = data.jobGradeUpper ? data.jobGradeUpper : undefined
            this.form.jobGradeFloor = data.jobGradeFloor ? data.jobGradeFloor : undefined
          })
        } else {
          const orgList = data.orgList ? data.orgList.map((v) => v.orgId) : [] // 过滤已选组织
          this.form = Object.assign(this.form, { ...data })
          this.form.orgList = orgList
          this.form.postTypeId = data.postTypeId
          this.form.postName = data.postName
        }
      }
      this.visible = true
    },
    // 获取职级分类数据
    async getPostGroupDropDownList() {
      const res = await getRankGroupList()
      this.postGroup = res.data.map(item => {
        return {
          value: item.jobTypeId,
          label: item.jobTypeName
        }
      })
    },
    // 获取职级
    async jobTypeIdChange(e, data) {
      if ((e && data) || (e === undefined && data === undefined)) {
        this.form.jobGradeUpper = undefined
        this.form.jobGradeFloor = undefined
      }
      if (!e) return
      const res = await getRankDropDownList({
        jobTypeId: e
      })
      this.rankList = res.data.map(item => {
        return {
          value: item.jobGrade,
          label: item.jobTypeCode + item.jobGrade
        }
      })
      this.form.jobGradeFloor = this.rankList[0].value
      this.form.jobGradeUpper = this.rankList[this.rankList.length - 1].value
    },
    // 拼接组织数组变为字符串
    getStringData() {
      let params = ''
      this.form.orgList.forEach((item, index) => {
        if (index === 0) {
          params = item
        } else {
          params = params + ',' + item
        }
      })
      return params
    },
    // 保存事件
    handleOk() {
      // eslint-disable-next-line space-before-function-paren
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          const params = {
            postId: this.form.postId,
            postTypeId: this.form.postTypeId,
            postName: this.form.postName,
            orgIds: this.getStringData(this.form.orgList),
            jobTypeId: this.form.jobTypeId,
            jobGradeUpper: this.form.jobGradeUpper,
            jobGradeFloor: this.form.jobGradeFloor,
            detail: this.form.detail
          }
          if (this.mode === 'add') {
            const res = await createPostJobType(params)
            if (res.code === 100) {
              this.$message.success('新增成功')
              this.handleCancel()
              this.$emit('reload')
            }
          } else {
            const res = await updatePostJobType(params)
            if (res.code === 100) {
              this.$message.success('编辑成功')
              this.handleCancel()
              this.$emit('reload')
            }
          }
        }
      })
    },
    // 取消事件
    handleCancel() {
      this.visible = false
      this.resetForm()
    },
    // 重置表单内容和验证
    resetForm() {
      this.form = {
        postTypeId: null,
        postId: null,
        postName: '',
        orgList: [],
        detail: '',
        jobTypeId: undefined,
        jobGradeUpper: undefined,
        jobGradeFloor: undefined
      }
      this.$refs.ruleForm.resetFields()
    },
    handleSearch(e) {
      this.searchvalue = e
    }
  }
}
</script>

<style lang="less" scoped>
  .choice_rank {
    display: flex;
    justify-items: center;
    color: @sc-grey-80;
    .choice_rank_top {
      margin-right: 10px;
    }
    .choice_rank_bottom {
      margin: 0 10px 0 20px;
    }
  }
</style>
