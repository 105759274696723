<template>
  <div>
    <div class="sidebar">
      <span @click="openGuidedTour">导览</span>
    </div>
    <a-drawer
      title="创建险金体系导览"
      placement="right"
      :closable="false"
      width="30%"
      :visible="guidedTour"
      @close="onClose"
    >
      <div class="guidedTour">
        <p>1、此地图中未添加<span class="font-color">*</span>的应根据企业缴纳险金情况配置社保、医保、公积金方案</p>
        <p>2、此地图中添加<span class="font-color">*</span>的必须设置</p>
        <div class="guidedTour-content">
          <div class="principal">START</div>
          <hr>
          <div class="branch">
            <a-button @click="onClick('insuredSubjects')"> <a-icon type="check-circle" /> 设置参保主体<span class="font-color">*</span> </a-button>
            <a-button @click="onClick('socialSecurityScheme')"> <a-icon type="check-circle" /> 设置社保方案 </a-button>
            <a-button @click="onClick('healthCareScheme')"> <a-icon type="check-circle" /> 设置医保方案 </a-button>
            <a-button @click="onClick('providentFundScheme')"> <a-icon type="check-circle" /> 设置公积金方案 </a-button>
            <a-button @click="onClick('messageReminders')"> <a-icon type="check-circle" /> 设置消息提醒 </a-button>
          </div>
        </div>
      </div>
      <div class="deawer-buttom">
        <a-button type="primary" @click="onClose"> 确定 </a-button>
      </div>
    </a-drawer>
  </div>

</template>
<script>
export default {
  data() {
    return {
      guidedTour: false
    }
  },
  methods: {
    onClose() {
      this.guidedTour = false
    },
    openGuidedTour() {
      this.guidedTour = true
    },
    onClick(val) {
      this.$emit('fileName', val)
    }
  }
}
</script>
<style lang="less" scoped>
.sidebar{
  position: fixed;
  right: 0;
  top: 40%;
  background-color: @sc-grey-20;
  // width: 40px;
  padding: 10px;
  height: auto;
  color: @sc-primary-10;
}
.font-color{
  color: red;
}
.guidedTour{
  color: @sc-grey-100;
}
.guidedTour-content{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  padding: 60px;
  .principal{
    width: 90px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 20px;
    color: #fff;
    background-color: @sc-primary-100;
  }
  hr{
    width: 70px;
    border-top: 2px dotted @sc-primary-100;
  }
  .branch{
    width: 200px;
    height: 270px;
    display: flex;
    align-content: space-evenly;
    flex-wrap: wrap;
    /deep/.ant-btn{
      width: 100%;
      text-align: left;
      height: 40px;
      border: 0;
      color: @sc-grey-100;
      box-shadow:0px 1px 2px @sc-grey-60 ;
      &:hover{
        color: @sc-primary-100;
        border-color: @sc-primary-100;
      }
      &:focus{
        color: @base-bg-color;
        background-color: @sc-primary-100;
        border-color: @sc-primary-100;
      }
      .anticon-check-circle{
        color: #fff;
      }
    }
  }
}
/deep/.ThreeLevels{
  &:nth-child(2){
    margin: 0;
  }
}
.deawer-buttom{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #fff;
    text-align: right;
    z-index: 1;
  }
/deep/.ant-drawer-title{
  color: @sc-grey-100;
  font-weight: normal;
}
/deep/.ant-form-item-label label{
  color: @sc-grey-100;
}
</style>
