import healthCareScheme from './healthCareScheme'
import insuredSubjects from './insuredSubjects'
import messageReminders from './messageReminders'
import providentFundScheme from './providentFundScheme'
import socialSecurityScheme from './socialSecurityScheme'
export default {
  healthCareScheme,
  insuredSubjects,
  messageReminders,
  providentFundScheme,
  socialSecurityScheme
}
