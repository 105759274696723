<template>
  <Modal
    :visible="visible"
    :mask-closable="false"
    :closable="true"
    :title="(mode == 'add' ? '添加' : '编辑') + '提醒'"
    :ok-text="'保存'"
    :size="'large'"
    :z-index="2"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="v-ruleForm">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="提醒名称" prop="messageName">
          <a-input v-model="form.messageName" placeholder="请输入1-20个字符" />
        </a-form-model-item>
        <a-form-model-item v-if="customFlag == 1" label="日期设置" prop="setDay">
          <a-space>
            <a-select v-model="setYear" placeholder="请选择" allow-clear class="width-bai" :options="yeatOptions" />年
            <a-select v-model="setMonth" placeholder="请选择" allow-clear class="width-bai" :options="monthOptions" />月
            <a-select
              v-model="form.setDay"
              placeholder="请选择"
              allow-clear
              class="width-bai"
              :options="dayOptions"
              @change="
                () => {
                  this.$forceUpdate()
                  $refs.ruleForm.validateField('setDay')
                }
              "
            />日
          </a-space>
        </a-form-model-item>
        <div v-for="(item, index) in form.remindTimeList" :key="'remindTimeLis' + index" class="flex-display">
          <div class="v-lable-all">
            <div v-if="index == 0" class="v-lable"><span class="v-lable-span">*</span><span>提醒时间</span></div>
          </div>
          <a-space>
            <!-- 提前延后区分 -->
            <a-form-model-item
              :prop="'remindTimeList.' + index + '.aroundType'"
              :rules="{
                required: true,
                message: '请选择',
                trigger: 'change'
              }"
            >
              <dictionariesInput v-model="item.aroundType" class="width-bai" parameter="around_type" @getDictionariesValue="getDictionariesValue" />
            </a-form-model-item>
            <!-- 提前延后量（大于等于0） -->
            <a-form-model-item
              :prop="'remindTimeList.' + index + '.aroundNum'"
              :rules="{
                required: true,
                message: '请选择',
                trigger: 'change'
              }"
            >
              <a-input-number v-model="item.aroundNum" :min="1" />
            </a-form-model-item>
            <!-- 提前延后单位（1：天，2：月） -->
            <a-form-model-item
              :prop="'remindTimeList.' + index + '.aroundUnit'"
              :rules="{
                required: true,
                message: '请选择',
                trigger: 'change'
              }"
            >
              <dictionariesInput v-model="item.aroundUnit" class="width-bai" parameter="around_unit" />
            </a-form-model-item>
            <!-- 提醒时间 -->
            <a-form-model-item
              :prop="'remindTimeList.' + index + '.remindTime'"
              :rules="{
                required: true,
                message: '请选择',
                trigger: 'change'
              }"
            >
              <a-time-picker v-model="item.remindTime" format="HH:mm" value-format="HH:mm" />
            </a-form-model-item>
            <!-- 自然日工作日区分 -->
            <a-form-model-item
              :prop="'remindTimeList.' + index + '.dayType'"
              :rules="{
                required: true,
                message: '请选择',
                trigger: 'change'
              }"
            >
              <dictionariesInput v-model="item.dayType" class="width-bai" parameter="day_type" @change="chengev(index)" />
            </a-form-model-item>
            <a-form-model-item>
              <a-button v-if="index != 0" @click="handleRemoveTime(index)"> 移除 </a-button>
              <a-button v-if="index == 0" @click="handleAddTime"> 添加 </a-button>
            </a-form-model-item>
          </a-space>
        </div>

        <div v-for="(item, index) in form.applyScopeList" :key="'applyScope' + index" class="flex-display">
          <div class="v-lable-all">
            <div v-if="index == 0" class="v-lable"><span class="v-lable-span">*</span><span>适用范围</span></div>
          </div>
          <a-space class="flex-display">
            <!-- 范围类型 -->
            <a-form-model-item
              :prop="'applyScopeList.' + index + '.scopeType'"
              :rules="{
                required: true,
                message: '请选择',
                trigger: 'change'
              }"
            >
              <dictionariesInput v-model="item.scopeType" class="width-dbbai" parameter="scope_type" />
            </a-form-model-item>
            <!-- 二级联动聘部门 -->
            <div v-if="item.scopeType == 2">
              <a-form-model-item
                :prop="'applyScopeList.' + index + '.scopeContent'"
                :rules="{
                  required: true,
                  message: '请选择',
                  trigger: 'change'
                }"
              >
                <depTreeSelect v-model="item.scopeContent" class="width-dbbai" placeholder="请选择部门" />
              </a-form-model-item>
            </div>
            <!-- 二级联动聘用形式 -->
            <div v-if="item.scopeType == 3">
              <a-form-model-item
                :prop="'applyScopeList.' + index + '.scopeContent'"
                :rules="{
                  required: true,
                  message: '请选择',
                  trigger: 'change'
                }"
              >
                <dictionariesInput v-model="item.scopeContent" class="width-dbbai" parameter="recruit_type" />
              </a-form-model-item>
            </div>
            <!-- 二级职级 -->
            <div v-if="item.scopeType == 4">
              <a-form-model-item
                :prop="'applyScopeList.' + index + '.scopeContent'"
                :rules="{
                  required: true,
                  message: '请选择',
                  trigger: 'change'
                }"
              >
                <rankInput v-model="item.scopeContent" class="width-dbbai" />
              </a-form-model-item>
            </div>
            <!-- 二级岗位 -->
            <div v-if="item.scopeType == 5">
              <a-form-model-item
                :prop="'applyScopeList.' + index + '.scopeContent'"
                :rules="{
                  required: true,
                  message: '请选择',
                  trigger: 'change'
                }"
              >
                <postInput v-if="item.scopeType == 5" v-model="item.scopeContent" class="width-dbbai" />
              </a-form-model-item>
            </div>
            <a-form-model-item>
              <a-button v-if="index != 0" @click="handleRemoveApply(index)"> 移除 </a-button>
              <a-button v-if="index == 0" @click="handleAddApply"> 添加 </a-button>
            </a-form-model-item>
          </a-space>
        </div>

        <div v-for="(item, index) in form.notifierList" :key="'notifierList' + index" class="flex-display">
          <div class="v-lable-all">
            <div v-if="index == 0" class="v-lable"><span class="v-lable-span">*</span><span class="person">通知人</span></div>
          </div>
          <a-space class="flex-display">
            <!-- 通知人类型 -->
            <a-form-model-item
              :prop="'notifierList.' + index + '.notifierType'"
              :rules="{
                required: true,
                message: '请选择',
                trigger: 'change'
              }"
            >
              <dictionariesInput v-model="item.notifierType" class="width-dbbai" parameter="notifier_type" />
            </a-form-model-item>
            <!-- 二级联动 人员选择 -->
            <div v-if="item.notifierType == 3">
              <a-form-model-item
                :prop="'notifierList.' + index + '.onJobIds'"
                :rules="{
                  required: true,
                  message: '请选择指定通知人',
                  trigger: 'change'
                }"
              >
                <perTreeSelect v-model="item.onJobIds" style="width: 200px" />
              </a-form-model-item>
            </div>
            <!-- 二级联动 管理员设置 -->
            <div v-if="item.notifierType == 2">
              <a-form-model-item
                :prop="'notifierList.' + index + '.onJobIds'"
                :rules="{
                  required: true,
                  message: '请选择指定通知人',
                  trigger: 'change'
                }"
              >
                <a-select v-model="item.onJobIds" style="width: 200px">
                  <a-select-option v-for="itemm in userList" :key="itemm.id">
                    {{ itemm.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
            <a-form-model-item>
              <a-button v-if="index != 0" @click="handleRemoveNotifier(index)"> 移除 </a-button>
              <a-button v-if="index == 0" @click="handleAddNotifier"> 添加 </a-button>
            </a-form-model-item>
          </a-space>
        </div>

        <a-form-model-item label="提醒方式" prop="noteTyps">
          <a-checkbox-group v-model="form.noteTyps" :options="plainOptions" @change="changeNoteType" />
          <!-- <a-radio-group v-model="form.noteTyps" :options="plainOptions" @change="changeNoteType" /> -->
        </a-form-model-item>
        <a-form-model-item v-if="form.noteFlag == 1" label="短信模板" prop="noteTempId">
          <a-select v-model="form.noteTempId">
            <a-select-option v-for="item in modalType" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="form.emailFlag == 1" label="发送邮件" prop="emailId">
          <a-select v-model="form.emailId">
            <a-select-option v-for="item in emailList" :key="item.emailId" :value="item.emailId">
              {{ item.sender }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="form.emailFlag == 1" label="邮件主题" prop="emailTitle">
          <a-input v-model="form.emailTitle" placeholder="请输入1-20个字符" />
        </a-form-model-item>
        <a-form-model-item v-if="form.emailFlag == 1" label="上传附件">
          <a-upload
            name="file"
            :multiple="true"
            action=""
            :custom-request="custonRequest"
            :headers="headers"
            :show-upload-list="true"
            :file-list="fileList"
            :remove="removeFile"
          >
            <a-button> <a-icon type="upload" /> 上传文件 </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item v-if="form.emailFlag == 1" label="详情">
          <Tinymce-vue :value="content" :setting="setting" @input="(res) => (content = res)" />
        </a-form-model-item>
      </a-form-model>
    </div>
  </Modal>
</template>

<script>
// 字典下拉框
import dictionariesInput from '@/components/CbDropDownInput/dictionariesInput/index'
// 岗位下拉框
import postInput from '@/components/CbDropDownInput/post/index'
// 职级
import rankInput from '@/components/CbDropDownInput/rank/index'
// 人员选择
import perTreeSelect from '@/components/CbPerDepSelection/perTreeSelect/index'
import TinymceVue from '@/components/Tinymce'

import { getList, createMessageConfig, getMessageConfigInfo, updateMessageConfig, getTemplateList, getUserList } from '@/services/user/setting.js'
import { upload } from '@/services/upload/index.js'
import mixins from '@/components/CbPerDepSelection/index.js'
export default {
  components: {
    dictionariesInput,
    postInput,
    rankInput,
    perTreeSelect,
    TinymceVue,
    Modal: () => import('@/components/CbModal/index')
  },
  mixins: [mixins],
  data() {
    return {
      visible: false,
      customFlag: 0,
      content: '',
      plainOptions: [
        { label: '短信', value: 'noteFlag' },
        { label: '邮件', value: 'emailFlag' }
      ],
      modalType: [],
      userList: [],
      headers: {
        authorization: 'authorization-text'
      },
      yeatOptions: [],
      monthOptions: [],
      dayOptions: [],
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      form: {
        remindTimeList: [{}],
        applyScopeList: [{}],
        // 通知人
        notifierList: [{}],
        noteTyps: ['noteFlag'],
        emailFlag: 0,
        noteFlag: 1,
        emailTempContent: '',
        setDay: undefined
      },
      checkNick: false,
      setYear: undefined,
      setMonth: undefined,
      setDay: undefined,
      monthList: [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      fileList: [],
      setting: {
        cleanup: false,
        branding: false, // 隐藏商标
        elementpath: false, // 隐藏底栏的元素路径
        height: 200,
        min_height: 200,
        max_height: 650,
        menubar: true,
        preview: true,
        toolbar:
          'code undo redo | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |',
        toolbar_drawer: 'sliding',
        quickbars_selection_toolbar: 'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
        plugins: 'code link preview image media table lists fullscreen ',
        language: 'zh_CN', // 本地化设置
        forced_root_block: 'p', // 设置默认标签项
        images_upload_handler: this.uploadImages // 自定义上传图片
      },
      rules: {
        messageName: [
          {
            required: true,
            message: '请输入提醒消息',
            trigger: 'change'
          },
          { min: 1, max: 20, message: '请输入1-20个字符', trigger: 'change' }
        ],
        setDay: [
          {
            required: true,
            message: '请输入选择日',
            trigger: 'change'
          }
        ],
        noteTyps: [
          {
            required: true,
            message: '请选择提醒方式',
            trigger: 'change'
          }
        ],
        noteTempId: [{ required: true, message: '请选择模板类型', trigger: 'change' }],
        emailId: [{ required: true, message: '请选择发送邮件', trigger: 'change' }],
        emailTitle: [
          { required: true, message: '请填写邮件主题', trigger: ['blur', 'change'] },
          { min: 1, max: 20, message: '请输入1-20个字符', trigger: 'change' }
        ],
        detail: [{ min: 0, max: 200, message: '请输入0-200个字符', trigger: 'change' }]
      },
      treeData: [] // 适用组织数据
    }
  },
  computed: {
    mode() {
      // 根据id自动计算新增、编辑
      return this.form.messageConfigId ? 'update' : 'add'
    }
  },
  watch: {
    setYear(val) {
      this.setMonth = undefined
      this.form.setDay = undefined
    },
    setMonth(val) {
      this.form.setDay = ''
      if (val) {
        if (this.setYear) {
          const day = new Date(this.setYear, val, 0).getDate() // 获取某年某月的最后一天
          this.getDay(day)
        } else {
          this.getDay(this.monthList[val - 1])
        }
      } else {
        this.getDay(31)
      }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    // 打开弹窗
    open(data) {
      this.customFlag = data.customFlag
      this.form = {
        remindTimeList: [{}],
        applyScopeList: [{}],
        // 通知人
        notifierList: [{}],
        noteTyps: ['noteFlag'],
        emailFlag: 0,
        noteFlag: 1,
        setDay: undefined
      }
      if (data) {
        this.form.messageGroupId = data.messageGroupId
        this.form.messageConfigId = data.messageConfigId
      }

      this.initData()
      this.getTemplateList()
      this.getUserList()
      this.visible = true
      this.getYear()
      this.getMonth()
      this.getDay(31)
    },
    // 获取年
    getYear() {
      this.yeatOptions = []
      for (let i = 2022; i <= 2042; i++) {
        this.yeatOptions.push({ value: String(i), label: String(i) })
      }
    },
    // 获取月
    getMonth() {
      this.monthOptions = []
      for (let i = 1; i <= 12; i++) {
        this.monthOptions.push({ value: String(i), label: String(i) })
      }
    },
    // 获取日
    getDay(data) {
      this.dayOptions = []
      for (let i = 1; i <= data; i++) {
        this.dayOptions.push({ value: String(i), label: String(i) })
      }
    },
    async getTemplateList() {
      const res = await getTemplateList({})
      this.modalType = res.data.map((item) => {
        return {
          id: item.smsTemplateId,
          name: item.templateName
        }
      })
    },
    async getUserList() {
      const res = await getUserList()
      this.userList = res.data.map((item) => {
        return {
          id: item.onJobId,
          name: item.userName
        }
      })
    },
    async initData() {
      // 获取邮箱下拉
      const res = await getList()
      if (res.code === 100) {
        this.emailList = res.data
      }
      // 获取详情
      if (this.form.messageConfigId) {
        const ress = await getMessageConfigInfo({ messageConfigId: this.form.messageConfigId })
        if (ress.code === 100) {
          this.form = ress.data
          this.setFormInfo(ress.data)
        }
      }
    },
    // 编辑赋值
    setFormInfo(val) {
      // 提醒方式
      this.form.noteTyps = []
      if (val.emailFlag === 1) {
        this.form.noteTyps.push('emailFlag')
      }
      if (val.noteFlag === 1) {
        this.form.noteTyps.push('noteFlag')
      }
      // 日期设置
      this.form.remindTimeList = val.remindTimeList.map((item) => {
        return {
          // ...item
          aroundNum: String(item.aroundNum),
          aroundType: String(item.aroundType),
          aroundUnit: String(item.aroundUnit),
          dayType: String(item.dayType),
          remindTime: String(item.remindTime),
          remindTimeId: String(item.remindTimeId)
        }
      })
      // 通知人
      this.form.notifierList = val.notifierList.map((item) => {
        return {
          notifierId: String(item.notifierId),
          notifierType: String(item.notifierType),
          onJobIds: String(item.onJobIds)
        }
      })
      // 适用范围
      this.form.applyScopeList = val.applyScopeList.map((item) => {
        return {
          scopeContent: String(item.scopeType) === '2' ? this.depScopeType(item.scopeContent) : String(item.scopeContent),
          scopeId: String(item.scopeId),
          scopeType: String(item.scopeType)
        }
      })
      // 附件
      this.fileList = val.emailFileList.map((item) => {
        return {
          uid: item.fileId,
          name: item.fileName
        }
      })

      // 富文本

      this.content = val.emailTempContent

      // this.$forceUpdate()
    },
    // 编辑部门选择预备处理
    depScopeType(data) {
      const arr = [
        {
          label: '',
          value: data
        }
      ]
      return arr
    },
    changeNoteType(val) {
      // if (val.target.value === 'emailFlag') {
      //   this.form.modalType = 'emailFlag'
      // }
      // if (val.target.value === 'noteFlag') {
      //   this.form.modalType = 'noteFlag'
      // }
      this.form.emailFlag = val.includes('emailFlag') ? 1 : 0
      this.form.noteFlag = val.includes('noteFlag') ? 1 : 0
    },
    // 添加时间
    handleAddTime() {
      this.form.remindTimeList.push({})
    },
    // 移除时间
    handleRemoveTime(index) {
      this.form.remindTimeList.splice(index, 1)
    },
    // 添加试用范围
    handleAddApply() {
      this.form.applyScopeList.push({})
    },
    // 移除试用范围
    handleRemoveApply(index) {
      this.form.applyScopeList.splice(index, 1)
    },
    // 添加通知人
    handleAddNotifier() {
      this.form.notifierList.push({})
    },
    // 移除通知人
    handleRemoveNotifier(index) {
      this.form.notifierList.splice(index, 1)
    },
    // 上传
    async custonRequest(val) {
      const formData = new FormData()
      formData.append('file', val.file)
      formData.append('fileType', 'other')
      formData.append('fileName', val.file.name)
      formData.append('sensitiveFile', 0)
      const res = await upload(formData)
      if (res.code === 100) {
        this.fileList = [
          ...this.fileList,
          {
            uid: res.data.fileId,
            name: res.data.originFileName
          }
        ]
      }
    },
    // 删除文件
    removeFile(val) {
      this.fileList = this.fileList.filter((item) => {
        return item.uid !== val.uid
      })
    },
    chengev(val) {
      this.$refs.ruleForm.validateField('remindTimeList.' + val + '.dayType')
    },
    chengeT(val) {
      this.$refs.ruleForm.validateField('applyScopeList.' + val + '.scopeType')
    },
    chengeP(val) {
      this.$refs.ruleForm.validateField('notifierList.' + val + '.notifierType')
    },
    // 保存事件
    handleOk() {
      const applyScopeList = this.form.applyScopeList.map(item => {
        return {
          scopeType: item.scopeType,
          scopeContent: item.scopeType === '2' && item.scopeContent[0].value ? item.scopeContent[0].value : item.scopeContent
        }
      })
      // eslint-disable-next-line space-before-function-paren
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          if (this.form.notifierList) {
            this.form.notifierList = this.form.notifierList.map((item) => {
              return {
                notifierType: item.notifierType,
                onJobIds: item.onJobIds ? item.onJobIds[0] : ''
              }
            })
          }
          if (this.fileList.length > 0) {
            this.fileList = this.fileList.map((item) => {
              return {
                fileId: item.uid,
                fileName: item.name
              }
            })
          }
          const per = {
            messageGroupId: this.form.messageGroupId,
            messageName: this.form.messageName,
            setYear: this.form.setYear,
            setMonth: this.form.setMonth,
            setDay: this.form.setDay,
            remindTimeList: this.form.remindTimeList,
            applyScopeList: applyScopeList,
            notifierList: this.form.notifierList,
            emailFlag: this.form.emailFlag,
            noteFlag: this.form.noteFlag,
            noteTempId: this.form.noteTempId,
            emailId: this.form.emailId,
            emailTitle: this.form.emailTitle,
            // emailFileId: this.fileList.length > 0 ? this.fileList.join(',') : '',
            emailFileList: this.fileList,
            emailTempContent: this.content
          }
          if (this.mode === 'add') {
            const res = await createMessageConfig(per)
            if (res.code === 100) {
              this.$message.success('新增成功')
              this.handleCancel()
              this.$emit('getFetchDataApiFn')
            }
          } else {
            const res = await updateMessageConfig({ ...per, messageConfigId: this.form.messageConfigId })
            if (res.code === 100) {
              this.$message.success('编辑成功')
              this.handleCancel()
              this.$emit('getFetchDataApiFn')
            }
          }
        }
      })
    },
    // 取消事件
    handleCancel() {
      this.visible = false
      this.resetForm()
    },
    // 重置表单内容和验证
    resetForm() {
      this.form = {
        postTypeId: null,
        postId: null,
        postName: '',
        detail: ''
      }
      this.$refs.ruleForm.resetFields()
    },
    getDictionariesValue(val) {
      console.log('change', val)
    }
  }
}
</script>

<style lang="less" scoped>
.v-lable-all {
  width: 16.66%;
}
.v-lable {
  margin-top: 8px;
  color: @sc-grey-80;
  &-span {
    display: inline-block;
    margin-right: 0.02083rem;
    color: @error-color;
    font-size: 0.07292rem;
    font-family: SimSun, sans-serif;
    line-height: 1;
  }

}
.width-bai {
  width: 100px;
}
.width-dbbai {
  width: 200px;
}
.flex-display {
  display: flex;
}
.person {
  color: @sc-grey-80;
}
/deep/ .ant-btn > .anticon {
  color: @sc-primary-100;
}
/deep/ .ant-btn > span {
  color: @sc-grey-100;
}
/deep/ .ant-input-number-input {
  color: @sc-grey-100;
}
</style>
