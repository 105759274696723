<template>
  <div class="TypeModal">
    <Modal
      v-if="typeVisible"
      :closable="true"
      :visible="typeVisible"
      :mask-closable="false"
      :title="`${formType==='add'?'新增':'编辑'}假期类型`"
      :size="'normal'"
      @ok="addVacation"
      @cancel="typeVisible = false"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="假期类型" prop="vacationTypeName">
          <a-input v-model="form.vacationTypeName" placeholder="请输入假期类型名称" :disabled="formType==='add'?false:true" />
        </a-form-model-item>
        <a-form-model-item label="假期描述" prop="vacationDesc">
          <a-textarea
            v-model="form.vacationDesc"
            placeholder="请输入假期描述"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            :max-length="500"
          />
        </a-form-model-item>
        <a-form-model-item label="默认单位" prop="vacationTypeUnit">
          <a-select v-model="form.vacationTypeUnit" style="width: 150px">
            <a-select-option value="D">天</a-select-option>
            <a-select-option value="H">小时</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="假期颜色" prop="textColor">
          <CbColorPicker v-model="form.textColor" />
        </a-form-model-item>
      </a-form-model>
      <!-- <div class="TypeModal-bottom">
        <a-button class="close-btn" @click="typeVisible = false">取 消</a-button>
        <a-button type="primary" class="ok-btn" @click="addVacation">保 存</a-button>
      </div> -->
    </Modal>
  </div>
</template>

<script>
import { sepcialCode } from '@/utils/reg.js'
import { createVacationType, updateVacationType } from '@/services/attendance/holidays.js'
import { debounce } from '@/utils/index.js'
export default {
  name: 'TypeModal',
  components: {
    Modal: () => import('@/components/CbModal/index'),
    CbColorPicker: () => import('@/components/CbColorPicker/index')
  },
  props: {
    formType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      typeVisible: false,
      form: {},
      rules: {
        vacationTypeName: [
          { required: true, message: '请输入假期类型名称', trigger: 'blur' },
          { min: 1, max: 20, message: '假期类型名称仅支持1~20个文字', trigger: 'change' },
          { pattern: sepcialCode, message: '假期类型名称不支持特殊字符', trigger: 'change' }
        ],
        vacationTypeUnit: [
          { required: true, message: '请选择假期单位', trigger: 'change' }
        ],
        textColor: [
          { required: true, message: '请选择假期颜色', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    addVacation() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.onSubmit()
          return
        }
        this.$message.error('添加信息不完整！')
      })
    },
    onSubmit: debounce(async function() {
      if (this.formType === 'edit') {
        const res = await updateVacationType({ ...this.form })
        if (res.code === 100) {
          this.$message.success('编辑成功！')
          this.typeVisible = false
          this.$parent.serchGetData()
        }
        return
      }
      const res = await createVacationType(this.form)
      if (res.code === 100) {
        this.$message.success('新增成功！')
        this.typeVisible = false
        this.$parent.serchGetData()
      }
    }, 500),
    open(record) {
      this.$nextTick(() => {
        this.typeVisible = true
        if (this.formType === 'edit') {
          this.form = { ...record }
          return
        }
        this.form = {
          vacationTypeUnit: 'D'
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

</style>
