<template>
  <div class="main-content">
    <a-tabs default-active-key="1" @change="callback">
      <a-tab-pane key="1" tab="待离职">
        <QuitTabel v-if="key === '1'" :keyval="key" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="已离职">
        <QuitTabel v-if="key === '2'" :keyval="key" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import QuitTabel from './table.vue'

export default {
  name: 'Quit',
  components: {
    QuitTabel
  },

  data() {
    return {
      content: '',
      key: '1',
      model: false
    }
  },
  methods: {
    callback(key) {
      this.key = key
    },

    searchHeaderCallback() {}
  }
}
</script>

<style lang="less" scoped>
.main-content {
  min-height: 100%;
  background: #fff;
  padding: 20px 20px 0;
  overflow: auto;
}
/deep/ .ant-tabs{
  height: 100%;
  overflow: auto;
}
/deep/ .ant-tabs-nav-scroll {
  margin-top: -4px;
}
/deep/ .ant-tabs-tab {
  color:  @sc-grey-100;
  font-size: 16px;
  padding-top: 0;
}
/deep/ .ant-tabs-tab-active{
  color: @sc-primary-100;
  font-weight: 500;
}
/deep/ .ant-tabs-tab:hover {
  color: @sc-primary-100;
}
</style>
