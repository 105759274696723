<template>
  <div class="cultureNotice">
    <appForm
      ref="appForm"
      :to-list="['search']"
      width="400"
      search-placeholder="请输入公告标题"
      @getSearchCallback="getSearchCallback"
    >
      <template slot="customButton">
        <a-space>
          <a-button prefix="add-one" @click="handleAdd">
            添加公告
          </a-button>
        </a-space>
      </template>
    </appForm>
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      row-key="noticeId"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="loadDataList"
    >
      <!-- 序号 -->
      <template slot="index" slot-scope="{ scope }">
        <span>{{ scope.index + 1 }}</span>
      </template>
      <!-- 接收人员 -->
      <template slot="staffList" slot-scope="{ scope }">
        <TablePerson :data-echo="scope.record.staffList" />
      </template>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{ scope }">
        <span class="operation-btn-box">
          <a-button type="link" @click="preview(scope.record)">
            <span class="operation-items">预览</span>
          </a-button>
          <a-button type="link" class="delete" @click="del(scope.record)">
            删除
          </a-button>
        </span>
        <!-- <a-popover
          placement="bottom"
          trigger="hover"
        >
          <template slot="content">
            <div class="more-handle-btn">
              <a-button type="link" @click="preview(scope.record)">
                <span class="operation-items">预览</span>
              </a-button>
              <a-button type="link" class="delete" @click="del(scope.record)">
                删除
              </a-button>
            </div>
          </template>
          <div class="more-handle">
            ···
          </div>
        </a-popover> -->
      </template>
    </custom-table>
    <AddNotice ref="AddNotice" @getFetchData="getFetchData" />
    <Preview ref="preview" />
  </div>
</template>
<script>
import mixins from '@/components/CustomTable/index.js'
import { getNoticeList, delNotice, getNoticeDetail } from '@/services/culture/notice.js'
export default {
  name: 'CultureNotice',
  components: {
    TablePerson: () => import ('@/components/CbTablePerson'),
    AddNotice: () => import('./components/addNotice'),
    Preview: () => import('./components/Preview')
  },
  mixins: [mixins],
  data() {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: '50px',
          align: 'center',
          scopedSlots: { customRender: 'index' },
          fixed: 'left'
        },
        {
          title: '公告标题',
          dataIndex: 'noticeTitle',
          key: 'noticeTitle',
          ellipsis: true,
          width: 150,
          fixed: 'left'
        },
        {
          title: '接收人员 ',
          dataIndex: 'staffList',
          key: 'staffList',
          scopedSlots: { customRender: 'staffList' },
          width: 750
        },
        {
          title: '阅读情况',
          dataIndex: 'readRatio',
          key: 'readRatio',
          width: 100
        },
        {
          title: '发送时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width: 210
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: 100
        }
      ],
      dataSource: []
    }
  },
  methods: {
    async fetchDataApiFn(param) {
      const res = await getNoticeList(param)
      this.dataSource = res.data.records ? res.data.records : []
      this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
      this.total = res.data.total
    },
    getSearchCallback(val) {
      this._queryParam.keyWord = val.searchValue
      this.serchGetData()
    },
    // 添加公告
    handleAdd() {
      this.$refs.AddNotice.show()
    },
    // 预览
    preview(info) {
      getNoticeDetail({ noticeId: info.noticeId }).then(res => {
        this.$refs.preview.show(res.data)
      })
    },
    // 删除
    del(info) {
      this.$Reconfirm({
        title: '删除提示',
        content: `确定要删除《${info.noticeTitle}》这条公告吗？`
      }).then(async() => {
        const res = await delNotice({ noticeId: info.noticeId })
        if (res.code !== 100) return
        this.$message.success('删除成功')
        this.serchGetData()
      }).catch(() => {
        console.log('no')
      })
    },
    // 添加回调
    getFetchData() {
      this.serchGetData()
    }
  }
}
</script>
<style lang="less" scoped>
.cultureNotice{
  padding: 20px;
  background-color: #fff;
  height: 100%;
}
/deep/.arr_form {
  .ant-btn{
    color: @sc-grey-100;
    padding: 0 10px;
    &:hover{
      color: @sc-primary-100;
    }
  }
}
.more-handle-btn {
  display: flex;
  flex-direction: column;
  .ant-btn {
    height:25px;
    padding: 0 10px;
  }
  .delete {
    color: #E34D59;
  }
}
.more-handle {
  font-weight: 700;
  color: @sc-primary-100;
  font-size: 20px;
  vertical-align: baseline;
  cursor: pointer;
  margin-left: 4px;
}
.operation-btn-box{
  display:flex;
  button{
    padding-left:0;
    padding-right:16px;
  }
}
</style>
