<template>
  <div class="box">
    <div v-if="enterShow">
      <!-- 月份选择 -->
      <div class="flex-between-center">
        <div class="button_flex">
          <a-button prefix="add" @click="supplementaryEntry">
            补缴录入
          </a-button>
          <a-button class="button_margin" prefix="daoru" @click="batchImport">
            批量导入
          </a-button>
        </div>
        <div />
        <MonthPicker v-model="month" />
        <div />
      </div>
      <!--  表格  -->
      <div class="table_margin">
        <custom-table
          :columns="columns"
          :data-source="dataSource"
          row-key="onJobId"
          :pagination="{
            current: pager.pageNum,
            pageSize: pager.pageSize,
            total: total,
            showTotal: (total) => `共 ${total} 条数据`,
            showQuickJumper: true,
            showSizeChanger: true
          }"
          @change="loadDataList"
        >
          <!-- 操作列 -->
          <template slot="operation" slot-scope="{ scope }">
            <a-button type="link" class="delete" @click="delEnter(scope.record)">删除</a-button>
          </template>
        </custom-table>
      </div>
    </div>
    <div v-else>
      <Enter v-if="enterShowTitle === 'entry'" :month="month" @toggleValue="toggleValue" />
      <ExcelImport v-else title="补缴导入" import-template="supplementaryPayment" @goBack="toggleValue" />
    </div>
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import {
  getListApi,
  deleteSupplyPayApi,
  getTenantBillApi
} from '@/services/insurance/supplementary'
export default {
  name: 'SupplementaryPayment',
  components: {
    MonthPicker: () => import('./components/monthPicker.vue'),
    Enter: () => import('./enter.vue'),
    ExcelImport: () => import('@/components/CbBatchImport/newImport.vue')
  },
  mixins: [mixins],
  data() {
    return {
      month: '',
      currentYear: new Date().getFullYear(), // 当前年
      currentMonth: new Date().getMonth() + 1, // 当前月
      displayButton: true,
      enterShow: true,
      enterShowTitle: 'entry',
      total: 0,
      lazyInitData: true,
      columns: [
        {
          title: '证件号码',
          dataIndex: 'idNum',
          key: 'idNum',
          width: 200,
          ellipsis: true
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          key: 'staffName',
          width: 70,
          ellipsis: true
        },
        {
          title: '类型',
          dataIndex: 'insureClassify',
          key: 'insureClassify',
          width: 60
        },
        {
          title: '险种',
          dataIndex: 'insureCode',
          key: 'insureCode',
          width: 100,
          ellipsis: true
        },
        {
          title: '补缴起始年月',
          dataIndex: 'startMonth',
          key: 'startMonth',
          width: 120,
          ellipsis: true
        },
        {
          title: '补缴终止年月',
          dataIndex: 'endMonth',
          key: 'endMonth',
          width: 120,
          ellipsis: true
        },
        {
          title: '补缴基数',
          dataIndex: 'paymentBase',
          key: 'paymentBase',
          dataType: 'money',
          width: 100,
          ellipsis: true
        },
        {
          title: '单位缴费比例',
          dataIndex: 'companyScale',
          key: 'companyScale',
          width: 120,
          ellipsis: true
        },
        {
          title: '个人缴费比例',
          dataIndex: 'personScale',
          key: 'personScale',
          width: 120,
          ellipsis: true
        },
        {
          title: '单位缴费额',
          dataIndex: 'companyPay',
          key: 'companyPay',
          dataType: 'money',
          width: 100,
          ellipsis: true
        },
        {
          title: '个人缴费额',
          dataIndex: 'personPay',
          key: 'personPay',
          dataType: 'money',
          width: 100,
          ellipsis: true
        },
        {
          title: '缴费总计',
          dataIndex: 'total',
          key: 'total',
          dataType: 'money',
          width: 100,
          ellipsis: true
        },
        {
          title: '补缴原因',
          dataIndex: 'supplyReason',
          key: 'supplyReason',
          width: 150,
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: 50,
          fixed: 'right',
          ellipsis: true
        }
      ],
      dataSource: [],
      tenantBill: {}
    }
  },
  watch: {
    month(val) {
      this.serchGetData()
      this.getTenantBillApi()
      // 判断是否展示 录入 导入 删除
    //   const arr = val.split('-')
    //   if (Number(arr[0]) > Number(this.currentYear)) {
    //     this.displayButton = true
    //   } else if (Number(arr[0]) === Number(this.currentYear)) {
    //     if (Number(arr[1]) >= Number(this.currentMonth)) {
    //       this.displayButton = true
    //     } else {
    //       this.displayButton = false
    //     }
    //   } else {
    //     this.displayButton = false
    //   }
    }
  },
  methods: {
    // 判断账单是否存在
    async getTenantBillApi() {
      const res = await getTenantBillApi({ insureMonth: this.month })
      this.tenantBill = res.data
    },
    async fetchDataApiFn(param) {
      const res = await getListApi({ insureMonth: this.month, ...param })
      this.dataSource = res.data.records ? res.data.records : []
      this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
      this.total = res.data.total
    },
    // 删除
    delEnter(val) {
      if (this.tenantBill) {
        // 判断账单是否生成
        this.$Reconfirm({
          title: '删除',
          content: this.tenantBill.status === 0
            ? '本月账单已生成，删除补缴后须前往账单页重新生成账单，否则无法自动删除险金代扣信息。是否确认删除？'
            : '本月账单已锁定，删除补缴后须前往账单页重新生成账单，否则无法自动删除险金代扣信息。是否确认删除？'
        }).then(() => {
          this.deleteSupplyPayApi(val)
        }).catch(() => {})
      } else {
        this.deleteSupplyPayApi(val)
      }
    },
    // 删除
    async deleteSupplyPayApi(val) {
      const res = await deleteSupplyPayApi({
        supplyPayId: val.supplyPayId
      })
      if (res.code === 100) {
        this.$message.success('删除成功')
        this.serchGetData()
      }
    },
    // 补缴录入
    supplementaryEntry() {
      this.enterShowTitle = 'entry'
      this.enterShow = false
    },
    // 批量导入
    batchImport() {
      if (this.tenantBill) {
        // 判断账单是否生成
        this.$Reconfirm({
          title: '导入',
          content: this.tenantBill.status === '0'
            ? '本月账单已生成，新增补缴后须前往账单页重新生成账单，否则无法自动生成险金代扣信息。是否确认生成补缴？'
            : '本月账单已锁定，新增补缴后须前往账单页重新生成账单，否则无法自动生成险金代扣信息。是否确认生成补缴？'
        }).then(() => {
          this.enterShowTitle = 'import'
          this.enterShow = false
        }).catch(() => {})
      } else {
        this.enterShowTitle = 'import'
        this.enterShow = false
      }
    },
    // 补缴录入返回
    toggleValue() {
      this.enterShow = true
      this.serchGetData()
    }
  }
}
</script>

<style lang="less" scoped>
  .box {
    width: 100%;
    height: 100%;
    overflow: auto;
    background: #fff !important;
    padding: 20px;
    box-sizing: border-box;
    .flex-between-center {
      height: 40px;
    }
    .button_flex {
      position: absolute;
      top: 25px;
      left: 20px;
    }
    .button_margin {
      margin-left: 12px;
    }
    .table_margin {
      margin-top: 16px;
    }
  }
  /deep/.ant-calendar-picker-input {
    font-size: 16px !important;
    display: flex;
    margin: 0 !important;
  }
  /deep/.ant-calendar-picker {
    width: 120px !important;
  }
  /deep/.anticon {
    font-size: 16px !important;
  }
</style>
