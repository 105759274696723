<template>
  <div>
    <CbDrawer
      v-model="visible"
      title="添加公告"
      size="normal"
      confirm-text="发布"
      :z-index="4"
      :closable="false"
      @close="onClose"
      @onSubmit="onSubmit"
    >
      <div class="content">
        <a-form-model
          ref="form"
          :model="form"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :rules="rules"
        >
          <a-form-model-item label="公告标题" prop="noticeTitle">
            <a-input v-model="form.noticeTitle" placeholder="请输入名称2-30个字符" :max-length="30" />
          </a-form-model-item>
          <a-form-model-item label="接收范围" prop="staffList">
            <CbSelectPersons
              :data-echo="perDataList"
              @perModelConfirm="perModelConfirm"
            />
          </a-form-model-item>
          <a-form-model-item label="公告内容" prop="noticeText">
            <a-textarea v-model="form.noticeText" placeholder="请输入公告内容" :rows="15" :spellcheck="false" />
          </a-form-model-item>
          <a-form-model-item label="附件" prop="fileId">
            <UploadFile
              :type-list="['pdf', 'xlsx', 'doc', 'docx', 'ppt', 'pptx', 'txt', 'xls']"
              :file-size="20"
              :max-sum="5"
              upload-description="文件格式支持 PDF/XLSX/DOC/DOCX/PPT/PPTX/TXT/XLS，大小在20MB内"
              show-upload-list
              @fileUpload="fileUpload"
              @onDelete="onDelete"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
      <template #cenFooter>
        <a-button type="default" @click="toPreview">预览</a-button>
      </template>
      <Preview ref="preview" />
    </CbDrawer>
  </div>
</template>
<script>
import { addNotice } from '@/services/culture/notice.js'
export default {
  name: 'AddNotice',
  components: {
    CbDrawer: () => import('@/components/CbDrawer'),
    CbSelectPersons: () => import('@/components/CbSelectPersons'),
    UploadFile: () => import('@/components/UploadFile/normal'),
    Preview: () => import('./Preview.vue')
  },
  data() {
    return {
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      form: {},
      rules: {
        noticeTitle: [
          { required: true, message: '公告标题不可为空', trigger: ['blur', 'change'] },
          { min: 2, max: 30, message: '请输入2-30个字符', trigger: ['blur', 'change'] }
        ],
        staffList: [
          { required: true, validator: this.validRange, trigger: 'change' }
        ],
        noticeText: [
          { required: true, message: '公告内容不可为空', trigger: 'change' }
        ]
      },
      perDataList: []
    }
  },
  methods: {
    show() {
      this.visible = true
    },
    // 指定人员--选择人员
    perModelConfirm(data) {
      const arr = []
      data.map(item => {
        arr.push({ onJobId: item.onJobId, staffName: item.staffName })
      })
      this.perDataList = data
      this.form.staffList = arr
      this.$refs.form.validateField('staffList')
    },
    validRange(rule, value, callback) {
      if (!this.perDataList.length) {
        callback(new Error('接收范围不可为空'))
      } else {
        this.$refs.form.clearValidate('staffList')
        callback()
      }
    },
    fileUpload(fileInfo) {
      this.form.uploadFile = []
      fileInfo.fileList.forEach(item => {
        return this.form.uploadFile.push({
          fileId: item.fileId,
          fileName: item.name
        })
      })
    },
    onDelete(index) {
      this.form.uploadFile.splice(index, 1)
    },
    onSubmit() {
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          const { noticeTitle, staffList, noticeText, uploadFile } = this.form
          const params = {
            noticeTitle,
            staffList,
            noticeText,
            uploadFile
          }
          const res = await addNotice(params)
          if (res.code === 100) {
            this.$message.success('发布成功')
            this.$emit('getFetchData')
            this.onClose()
          }
        }
      })
    },
    toPreview() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$refs.preview.show(this.form, 'add')
        }
      })
    },
    onClose() {
      this.visible = false
      this.perDataList = []
      this.$refs.form.resetFields()
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.scopeList {
  .person{
  width: 120px!important;
  margin: 16px 16px 0 0 !important;
  }
  .num{
    .font-size(16px) !important;
    margin: 10px 0 0 10px !important;
  }
}
textarea.ant-input{
  color: @sc-grey-100;
  line-height: 26px;
  padding: 11px 20px 7px !important;
  word-break: break-all;
}
/deep/.upload-image{
  .ant-upload .ant-btn{
    color: @sc-grey-100;
    .anticon-upload{
      color: @sc-primary-100;
    }
    &:hover{
      color: @sc-primary-100;
    }
  }
  .uploadDescription{
    margin-top: 16px;
    color: @sc-grey-60 !important;
  }
  .fileList{
    margin: 16px 0;
    flex-wrap: wrap;
    .fileItem {
      margin-right: 10px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      padding: 7px 12px;
      background: @sc-greyBg-10;
      border-radius: 4px;
      .fileIcon{
        width: 20px;
        height: 20px;
        line-height: 20px;
        margin-right: 6px;
        img{
          width: 100%;
        }
      }
      .fileName{
        .font-size(12px);
        color: @sc-grey-100;
      }
    }
  }
}
</style>
