<template>
  <div class="NumStatistics" @click="jumpDetail">
    <ul class="num-box flex-just-between">
      <li>
        <span>{{ numObj.lateCount||0 }}</span>
        <span>迟到</span>
      </li>
      <li>
        <span>{{ numObj.earlyOutCount||0 }}</span>
        <span>早退</span>
      </li>
      <li>
        <span>{{ numObj.missCount||0 }}</span>
        <span>缺卡</span>
      </li>
      <li>
        <span>{{ numObj.absentCount||0 }}</span>
        <span>旷工</span>
      </li>
    </ul>
    <ul class="num-box flex-just-between">
      <li>
        <span>{{ numObj.outsideCount||0 }}</span>
        <span>外出</span>
      </li>
      <li>
        <span>{{ numObj.travelCount||0 }}</span>
        <span>出差</span>
      </li>
      <li>
        <span>{{ numObj.vacationCount||0 }}</span>
        <span>请假</span>
      </li>
      <li>
        <span>{{ numObj.overtimeCount||0 }}</span>
        <span>加班</span>
      </li>
    </ul>
  </div>
</template>
<script>
import { currentPeriodAttendanceStatistics } from '@/services/home/attendance.js'
export default {
  name: 'NumStatistics',
  components: {
  },
  data() {
    return {
      numObj: {}
    }
  },
  mounted() {
    this.getNumData()
  },
  methods: {
    async getNumData() {
      const res = await currentPeriodAttendanceStatistics()
      if (res.code === 100) {
        this.numObj = res.data
      }
    },
    jumpDetail() {
      const routeName = 'attendanceManage'
      const params = 'monthlyAttention'
      this.$router.push({
        name: routeName,
        params: {
          isHome: params
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.NumStatistics{
    .num-box{
        margin-top: 20px;
        padding: 0 60px;
        li{
            display: flex;
            flex-direction: column;
        }
         li > span {
            text-align: center;
         }
        li > span:first-child{
            .font-size(22px);
            color: @sc-grey-100;
            font-weight: bold;
        }
        li > span:nth-child(2){
            .font-size(14px);
            color: @sc-grey-60;
        }
    }
}
</style>
