<template>
  <div class="content-header">
    <div class="other">
      <a-icon v-if="canGoBack" :style="{ fontSize: '20px', 'margin-right': '10px' }" type="left" @click="goBack" />
      <div :class="{'modelName': getLoginType === 7}" v-html="title" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import publicMixins from './publicMixins'
export default {
  name: 'ContentHeader',
  mixins: [publicMixins],
  computed: {
    systemName() {
      return this.$store.state.setting.systemName
    },
    canGoBack() {
      const notAllowBackArr = [1, 2, 7, 8]
      return !notAllowBackArr.includes(this.getLoginType)
    },
    title() {
      const titleObj = {
        1: '欢迎使用',
        2: '验证码登录',
        3: '找回密码',
        4: '设置密码',
        5: '注册',
        6: '设置密码',
        7: '欢迎体验<br>一站式企业服务',
        8: '请选择企业'
      }
      return titleObj[this.getLoginType]
    },
    ...mapGetters('setting', ['getLoginType'])
  },
  methods: {
    goBack() {
      this.changeLoginType(1)
    }
  }
}
</script>

<style lang="less" scoped>
.modelName{
  text-align: left;
   background-image:-webkit-linear-gradient(308deg, #5947FF 0%, #4F96FF 100%);
  -webkit-background-clip:text;
  -webkit-text-fill-color:transparent;
}
.content-header{
  width: 100%;
}
.login{
  .logo>img{
    height: 40px;
  }
  .title{
    font-size: 20px;
    font-weight: bold;
    color: @primary-color;
    margin-bottom: 40px;
  }
}
.other{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 44px;
  font-size: 26px;
  line-height: 34px;
  font-weight: 700;
  text-align: center;
  color: rgba(32, 42, 64, 1);
  .title{
    font-size: 20px;
    color: @primary-color;
    margin-left: 10px;
  }
}
</style>
