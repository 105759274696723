<template>
  <div id="extraworkModal" class="extra-rule">
    <div class="extra-main">
      <AlertTips @look="lookTips">
        <template slot="content">
          <p>1、设置工作日、休息日和节假日的加班时长的计算规则和补偿规则。</p>
          <p>2、可设置多个不同规则，设置后，前往【考勤组】把不同规则关联到各自考勤组。</p>
        </template>
      </AlertTips>
      <div class="addrulebtn">
        <a-button type="primary" prefix="add-one" @click="addExtrawork">
          新增加班规则
        </a-button>
      </div>
      <custom-table
        id="extraworkModaltable"
        :scroll="{
          y: tableHeight
        }"
        :tabs-height="36"
        :columns="extraWorkTable"
        :data-source="dataSource"
        row-key="overtimeRuleId"
        :pagination="false"
      >
        <!-- 补偿方式 -->
        <template slot="compensationWay" slot-scope="{scope}">
          工作日：<span>{{ scope.record.workdayOvertimeConvertType | overtimeConvertType }}</span><br>
          休息日：<span>{{ scope.record.offdayOvertimeConvertType | overtimeConvertType }}</span><br>
          法定节假日：<span>{{ scope.record.holidayOvertimeConvertType | overtimeConvertType }}</span><br>
        </template>
        <!-- 补偿方式 -->
        <template slot="accountingWay" slot-scope="{scope}">
          工作日：<span>{{ scope.record.workdayOvertimeCalcType | overtimeCalcType }}</span><br>
          休息日：<span>{{ scope.record.offdayOvertimeCalcType | overtimeCalcType }}</span><br>
          法定节假日：<span>{{ scope.record.holidayOvertimeCalcType | overtimeCalcType }}</span><br>
        </template>
        <!-- 操作列 -->
        <template slot="operation" slot-scope="{ scope}">
          <div class="flex-align-center">
            <div class="more-handle-btn-box">
              <a-button type="link" @click="edit(scope.record)">
                编辑
              </a-button>
              <a-button type="link" class="delete" @click="deleted(scope.record)">
                删除
              </a-button>
            </div>
            <!-- <a-popover
              placement="bottom"
              trigger="hover"
            >
              <template slot="content">
                <div class="more-handle-btn">
                  <a-button type="link" @click="edit(scope.record)">
                    编辑
                  </a-button>
                  <a-button type="link" class="delete" @click="deleted(scope.record)">
                    删除
                  </a-button>
                </div>
              </template>
              <div class="more-handle">
                ···
              </div>
            </a-popover> -->
          </div>
        </template>
      </custom-table>
    </div>
    <Drawer
      :visible="extraVisible"
      :is-mask="false"
      size="large"
      :title="type==='add'?'新增加班规则':'编辑加班规则'"
      @close="onClose"
      @onSubmit="handleDelShift"
    >
      <div id="extra-content" ref="extraContent" class="extra-content">
        <a-form-model
          ref="extraRef"
          :model="form"
          :rules="rules"
        >
          <div class="rule-name" style="height:auto">
            <a-form-model-item ref="name" label="规则名称" prop="ruleName" class="ant-form-item-label">
              <a-input v-model="form.ruleName" placeholder="比如产品部的加班规则" />
            </a-form-model-item>
          </div>
          <div class="weekday">
            <div class="msg-title">
              工作日加班
            </div>
            <div class="name">
              <span>补偿方案</span>
              <a-select v-model="form.workdayOvertimeConvertType" :options="overtimeConvertType" />
            </div>
            <div class="name">
              <span>核算规则</span>
              <a-select v-model="form.workdayOvertimeCalcType" @change="ruleChange(form.workdayOvertimeCalcType,'workdayOvertimeLimitFlag')">
                <a-select-option v-for="item in overtimeCalcType" :key="item.value" :value="item.value" :title="item.label">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
            <hr class="partition">
            <div class="switch ">
              <span>加班时间限制</span>
              <a-switch size="small" checked-children="开" un-checked-children="关" :checked="form.workdayOvertimeLimitFlag===1?true:false" :disabled="form.workdayOvertimeCalcType==='3'" @click="onchange('workdayOvertimeLimitFlag')" />
            </div>
            <div ref="topLeft" />
            <div class="msg-cont weekday-limit">最后一次下班<CbNumber v-model="form.workdayBreakMinutes" :disabled="form.workdayOvertimeLimitFlag!== 1" max="720" class="number" input-width="54" />分钟后开始计算加班，最长加班时长<CbNumber v-model="form.workdayOvertimeMaxMinutes" :disabled="form.workdayOvertimeLimitFlag!== 1" max="720" class="number" input-width="54" />分钟
              <a-tooltip v-show="form.workdayOvertimeLimitFlag" placement="topRight" trigger="hover" :get-popup-container="()=>$refs.extraContent">
                <template slot="title">
                  <div>最长加班时长限制12小时，合计720分钟。</div>
                </template>
                <a-icon type="exclamation-circle" :class="{'icon-disabled': !form.workdayOvertimeLimitFlag}" theme="filled" />
              </a-tooltip>
            </div>
          </div>
          <div class="weekday closed">
            <div class="msg-title">
              休息日加班
            </div>
            <div class="name">
              <span>补偿方案</span>
              <a-select v-model="form.offdayOvertimeConvertType" default-value="不计补偿" :options="overtimeConvertType" />
            </div>
            <div class="name">
              <span>核算规则</span>
              <a-select v-model="form.offdayOvertimeCalcType" @change="ruleChange(form.offdayOvertimeCalcType,'offdayOvertimeLimitFlag')">
                <a-select-option v-for="item in overtimeCalcType" :key="item.value" :value="item.value" :title="item.label">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
            <div v-if="form.offdayOvertimeCalcType!=='2'" class="time">
              <a-form-model-item ref="time" label="打卡有效时段" prop="offdayPunchStartTime" class="ant-form-item-label">
                <a-time-picker v-model="form.offdayPunchStartTime" :default-open-value="moment('00:00', 'HH:mm')" :get-popup-container="()=>$refs.extraContent" format="HH:mm" value-format="HH:mm" placeholder="请选择" @change="valid('offdayPunchStartTime')" /> -
                <a-time-picker v-model="form.offdayPunchEndTime" :default-open-value="moment('00:00', 'HH:mm')" :get-popup-container="()=>$refs.extraContent" format="HH:mm" value-format="HH:mm" placeholder="请选择" @change="valid('offdayPunchStartTime')" />
              </a-form-model-item>
            </div>
            <hr class="partition">
            <div class="switch ">
              <span>加班时间限制</span>
              <a-switch size="small" checked-children="开" un-checked-children="关" :checked="form.offdayOvertimeLimitFlag===1?true:false" :disabled="form.offdayOvertimeCalcType==='3'" @click="onchange('offdayOvertimeLimitFlag')" />
            </div>

            <div class="breaks" />

            <div class="latest">
              <span>最晚加班时间</span>
              <div class="time-picker">
                <a-time-picker v-model="form.offdayLatestPunchTime" :default-open-value="moment('00:00', 'HH:mm')" :get-popup-container="()=>$refs.extraContent" :disabled="form.offdayOvertimeLimitFlag!== 1" format="HH:mm" value-format="HH:mm" placeholder="请选择" />
              </div>
            </div>
            <div class="breaks" />
            <div v-for="(item,index) in restTimes" :key="index" class="not-counted">
              <div class="time">
                <span>不计加班时段</span>
                <div class="time-picker">
                  <a-time-picker v-model="item.startTime" :default-open-value="moment('00:00', 'HH:mm')" :get-popup-container="()=>$refs.extraContent" :disabled="form.offdayOvertimeLimitFlag!== 1" format="HH:mm" value-format="HH:mm" placeholder="请选择" /> -
                  <a-time-picker v-model="item.endTime" :default-open-value="moment('00:00', 'HH:mm')" :get-popup-container="()=>$refs.extraContent" :disabled="form.offdayOvertimeLimitFlag!== 1" format="HH:mm" value-format="HH:mm" placeholder="请选择" />
                </div>
              </div>
              <div>
                <a-button v-if="index+1===1" type="iconBtn" icon="add-one" class="addBtn" :disabled="form.offdayOvertimeLimitFlag!== 1" @click="restTimes.push({overtimeType:'B'})" />
                <a-button v-else type="iconBtn" icon="close-small" class="delete" :disabled="form.offdayOvertimeLimitFlag!== 1" @click="del(index,'restTimes')" />
              </div>
            </div>
          </div>
          <div class="weekday rest">
            <div class="msg-title">
              节假日加班
            </div>
            <div class="name">
              <span>补偿方案</span>
              <a-select v-model="form.holidayOvertimeConvertType" default-value="不计补偿" :options="overtimeConvertType" />
            </div>
            <div class="name">
              <span>核算规则</span>
              <a-select v-model="form.holidayOvertimeCalcType" @change="ruleChange(form.holidayOvertimeCalcType,'holidayOvertimeLimitFlag')">
                <a-select-option v-for="item in overtimeCalcType" :key="item.value" :value="item.value" :title="item.label">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
            <div v-if="form.holidayOvertimeCalcType!=='2'" class="time">
              <a-form-model-item ref="holidayPunchStartTime" label="打卡有效时段" prop="holidayPunchStartTime" class="ant-form-item-label">
                <a-time-picker v-model="form.holidayPunchStartTime" :default-open-value="moment('00:00', 'HH:mm')" :get-popup-container="()=>$refs.extraContent" format="HH:mm" value-format="HH:mm" placeholder="请选择" @change="valid('holidayPunchStartTime')" /> -
                <a-time-picker v-model="form.holidayPunchEndTime" :default-open-value="moment('00:00', 'HH:mm')" :get-popup-container="()=>$refs.extraContent" format="HH:mm" value-format="HH:mm" placeholder="请选择" @change="valid('holidayPunchStartTime')" />
              </a-form-model-item>
            </div>
            <hr class="partition">
            <div class="switch ">
              <span>加班时间限制</span>
              <a-switch size="small" checked-children="开" un-checked-children="关" :checked="form.holidayOvertimeLimitFlag=== 1?true:false" :disabled="form.holidayOvertimeCalcType==='3'" @click="onchange('holidayOvertimeLimitFlag')" />
            </div>
            <div class="breaks" />

            <div class="latest">
              <span>最晚加班时间</span>
              <div class="time-picker">
                <a-time-picker v-model="form.holidayLatestPunchTime" :default-open-value="moment('00:00', 'HH:mm')" :get-popup-container="()=>$refs.extraContent" format="HH:mm" :disabled="form.holidayOvertimeLimitFlag!== 1" value-format="HH:mm" placeholder="请选择" />
              </div>
            </div>
            <div v-for="(item,index) in holidayTimes" :key="index" class="not-counted">
              <div class="time">
                <span>不计加班时段</span>
                <div class="time-picker">
                  <a-time-picker v-model="item.startTime" :default-open-value="moment('00:00', 'HH:mm')" :get-popup-container="()=>$refs.extraContent" :disabled="form.holidayOvertimeLimitFlag!== 1" format="HH:mm" value-format="HH:mm" placeholder="请选择" /> -
                  <a-time-picker v-model="item.endTime" :default-open-value="moment('00:00', 'HH:mm')" :get-popup-container="()=>$refs.extraContent" :disabled="form.holidayOvertimeLimitFlag!== 1" format="HH:mm" value-format="HH:mm" placeholder="请选择" />
                </div>
              </div>
              <div>
                <a-button v-if="index+1===1" type="iconBtn" icon="add-one" class="addBtn" :disabled="form.holidayOvertimeLimitFlag!== 1" @click="holidayTimes.push({overtimeType:'C'})" />
                <a-button v-else type="iconBtn" icon="close-small" class="delete" :disabled="form.holidayOvertimeLimitFlag!== 1" @click="del(index,'holidayTimes')" />
              </div>
            </div>
          </div>

          <div class="msg-title">
            加班最小时长
          </div>
          <div class="msg-cont">
            加班最小时长<CbNumber v-model="form.overtimeMinMinutes" max="720" class="cbnumber" input-width="54" />分钟，加班达到该时长，才能算加班，否则不计加班
          </div>
          <div class="msg-title">
            加班时长计算单位
          </div>
          <div class="msg-cont">
            加班时长计算单位
            <a-select v-model="form.overtimeUnit" :options="overtimeUnit" class="select" />
            <span v-show="form.overtimeUnit==='1'">员工加班时长，以分钟为单位计算，不足的不计算</span>
            <span v-show="form.overtimeUnit==='2'">员工加班时长，以半小时为单位计算，不足的不计算<span class="weekday-rest">（例如：加班1.62小时=加班1.5小时）</span></span>
            <span v-show="form.overtimeUnit==='3'">员工加班时长，以小时为单位计算，不足的不计算<span class="weekday-rest">（例如：加班1.62小时=加班1小时）</span></span>
            <span v-show="form.overtimeUnit==='4'">员工加班时长，以半天为单位计算，不足的不计算<span class="weekday-rest">（例如：加班0.85天=加班0.5天）</span></span>
            <span v-show="form.overtimeUnit==='5'">员工加班时长，以天为单位计算，不足的不计算<span class="weekday-rest">（例如：加班0.85天=加班0天）</span></span>
          </div>
          <div class="msg-title">
            加班时长统计等在员工端展示
          </div>
          <div class="msg-content">
            是否展示 <a-switch :checked="form.appletShowFlag?true:false" @change="(check)=>form.appletShowFlag = check ? 1 : 0" />
          </div>
          <!-- <div class="msg-title">
            更多设置
          </div>
          <div class="msg-tail">
            <a-checkbox style="margin-right: 10px;" :checked="form.splitOvernightFlag===1?true:false" @change="onchange('splitOvernightFlag')" />跨夜加班时长计入加班开始这天
            <span class="weekday-rest">(解决工作日跨夜加班到节假日)</span>
          </div> -->
        </a-form-model>
      </div>
    </Drawer>
  </div>
</template>
<script>
import moment from 'moment'
import mixins from '@/components/CustomTable/index.js'
import { sepcialCode } from '@/utils/reg.js'
import { getoOvertimeRuleListApi, addOvertimeRuleApi, deleteOvertimeRuleApi, updateOvertimeRuleApi, detailOvertimeRuleApi } from '@/services/attendance/rule'
import { overtime_convert_type, overtime_calc_type, overtime_unit } from '@/pages/attendance/dict.js'
export default {
  name: 'ExtraRule',
  components: {
    Drawer: () => import('@/components/CbDrawer'),
    CbNumber: () => import('@/components/CbNumber'),
    AlertTips: () => import('../../../components/alert.vue')
  },
  filters: {
    overtimeConvertType(val) {
      const obj = overtime_convert_type.find(item => {
        return item.value === val
      })
      return obj.label
    },
    overtimeCalcType(val) {
      const obj = overtime_calc_type.find(item => {
        return item.value === val
      })
      return obj.label
    }
  },
  mixins: [mixins],
  data() {
    const validateTime = (rule, value, callback) => {
      if (!this.form.offdayPunchStartTime || !this.form.offdayPunchEndTime) {
        callback(new Error('请选择打卡有效时段'))
      } else {
        callback()
      }
    }
    const validate = (rule, value, callback) => {
      if (!this.form.holidayPunchStartTime || !this.form.holidayPunchEndTime) {
        callback(new Error('请选择打卡有效时段'))
      } else {
        callback()
      }
    }
    return {
      type: '',
      overtimeConvertType: overtime_convert_type,
      overtimeCalcType: overtime_calc_type,
      overtimeUnit: overtime_unit,
      extraWorkTable: [
        {
          title: '规则名称',
          dataIndex: 'ruleName',
          key: 'ruleName',
          scopedSlots: { customRender: 'ruleName' },
          ellipsis: true
        },
        {
          title: '补偿方式',
          dataIndex: 'compensationWay',
          key: 'compensationWay',
          scopedSlots: { customRender: 'compensationWay' },
          customCell: () => {
            return {
              style: {
                'min-width': '300px'
              }
            }
          }
        },
        {
          title: '加班时长核算方式',
          dataIndex: 'accountingWay',
          key: 'accountingWay',
          scopedSlots: { customRender: 'accountingWay' },
          customCell: () => {
            return {
              style: {
                'min-width': '300px'
              }
            }
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '100px',
          fixed: 'right'
        }
      ],
      dataSource: [], // 数据
      extraVisible: false,
      overtimeRuleId: '',
      form: {
        overtimeRuleId: ' ',
        ruleName: '',
        workdayOvertimeConvertType: '0',
        workdayOvertimeCalcType: '1',
        workdayOvertimeLimitFlag: 0,
        workdayOvertimeMaxMinutes: null,
        workdayBreakMinutes: null,
        workdayPunchStartTime: '',
        workdayPunchEndTime: '',
        workdayLatestPunchTime: '',
        offdayOvertimeConvertType: '0',
        offdayOvertimeCalcType: '1',
        offdayOvertimeLimitFlag: 0,
        offdayOvertimeMaxMinutes: '',
        offdayBreakMinutes: '',
        offdayPunchStartTime: '',
        offdayPunchEndTime: '',
        offdayLatestPunchTime: '',
        holidayOvertimeConvertType: '0',
        holidayOvertimeCalcType: '1',
        holidayOvertimeLimitFlag: 0,
        holidayOvertimeMaxMinutes: '',
        holidayBreakMinutes: '',
        holidayPunchStartTime: '',
        holidayPunchEndTime: '',
        holidayLatestPunchTime: '',
        overtimeMinMinutes: null,
        overtimeUnit: '1',
        splitOvernightFlag: 0,
        appletShowFlag: 1
      },
      holidayTimes: [
        {
          overtimeType: 'C', startTime: null, endTime: null
        }
      ],
      restTimes: [
        {
          overtimeType: 'B', startTime: null, endTime: null
        }
      ],
      rules: {
        ruleName: [
          { required: true, message: '请输入规则名称', trigger: 'blur' },
          { min: 1, max: 30, message: '规则名称支持1~30个文字', trigger: 'change' },
          { pattern: sepcialCode, message: '规则名称不支持特殊字符', trigger: 'change' }
        ],
        offdayPunchStartTime: [
          { validator: validateTime, trigger: 'change' }
        ],
        holidayPunchStartTime: [
          { validator: validate, trigger: 'change' }
        ]
      },
      tableHeight: null
    }
  },
  created() {
    this.getoOvertimeRuleListApi()
  },
  methods: {
    moment,
    valid(str) {
      this.$refs.extraRef.validateField(str, (err) => { console.og(err) })
    },
    // // 加班规则列表
    getoOvertimeRuleListApi() {
      getoOvertimeRuleListApi().then(res => {
        if (res.code === 100) {
          this.dataSource = res.data
          this.$nextTick(() => {
            const contentH = document.body.scrollHeight - 48
            const tableH = document.querySelector('.extra-rule .ant-table-wrapper')
            const offset = document.querySelector('.extra-rule .ant-table-wrapper').offsetTop
            const headerH = document.querySelector('.extra-rule .ant-table-thead tr').clientHeight
            const tableMaxH = contentH - offset - 20
            tableH.style.maxHeight = `${tableMaxH}px`
            this.tableHeight = tableMaxH - headerH
          })
        }
      })
    },
    lookTips() {
      setTimeout(() => {
        const boxH = document.getElementById('rules').clientHeight
        const offset = document.getElementById('extraworkModaltable').offsetTop
        this.tableHeight = boxH - offset - 80
      }, 500)
    },
    // 新增加班规则
    addExtrawork() {
      this.type = 'add'
      this.extraVisible = true
    },
    async edit(data) {
      this.type = 'edit'
      this.extraVisible = true
      this.overtimeRuleId = data.overtimeRuleId
      const res = await detailOvertimeRuleApi({ id: this.overtimeRuleId })
      this.form = res.data
      const holidayTimes = res.data.overtimeRuleExcludedTimeVOList.filter(item => { return item.overtimeType === 'C' && item.endTime && item.startTime })
      const restTimes = res.data.overtimeRuleExcludedTimeVOList.filter(item => { return item.overtimeType === 'B' && item.endTime && item.startTime })
      if (holidayTimes.length > 0) { this.holidayTimes = holidayTimes } else { [{ overtimeType: 'C', startTime: null, endTime: null }] }
      if (restTimes.length > 0) { this.restTimes = restTimes } else { [{ overtimeType: 'B', startTime: null, endTime: null }] }
    },
    deleted(data) {
      this.overtimeRuleId = data.overtimeRuleId
      this.$Reconfirm({
        title: '删除补卡规则',
        content: '确定要删除该加班规则吗？'
      }).then(async() => {
        const res = await deleteOvertimeRuleApi({ id: this.overtimeRuleId })
        if (res.code === 100) {
          this.$message.success('删除成功')
          this.getoOvertimeRuleListApi()
        }
      }).catch(() => {
        console.log('no')
      })
    },
    handleDelShift() {
      this.$refs.extraRef.validate(valid => {
        if (valid) {
          delete this.form.overtimeRuleExcludedTimeVOList
          this.restTimes = this.restTimes.filter(item => { return item.startTime !== null && item.endTime !== null })
          this.holidayTimes = this.holidayTimes.filter(item => { return item.startTime !== null && item.endTime !== null })
          this.form.overtimeRuleExcludedTimeDTOList = [...this.restTimes, ...this.holidayTimes]
          if (this.type === 'add') {
            addOvertimeRuleApi(this.form).then(res => {
              if (res.code !== 100) return
              this.$message.success('新增成功')
              this.onClose()
            })
          } else {
            updateOvertimeRuleApi(this.form).then(res => {
              if (res.code !== 100) return
              this.$message.success('编辑成功')
              this.onClose()
            })
          }
        }
      })
    },
    onClose() {
      this.extraVisible = false
      this.form = this.$options.data().form
      this.holidayTimes = [
        {
          overtimeType: 'C', startTime: null, endTime: null
        }
      ]
      this.restTimes = [
        {
          overtimeType: 'B', startTime: null, endTime: null
        }
      ]
      this.getoOvertimeRuleListApi()
      this.resetForm()
    },
    resetForm() {
      this.$refs.extraRef.resetFields()
    },
    onchange(value) {
      if (this.form[value] === 1) {
        this.form[value] = 0
      } else {
        this.form[value] = 1
      }
    },
    ruleChange(val, value) {
      if (val === '3') {
        this.form[value] = 1
      }
    },
    del(e, val) {
      if (val === 'restTimes') {
        this.restTimes.splice(e, 1)
      } else if (val === 'holidayTimes') {
        this.holidayTimes.splice(e, 1)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.extra-rule{
  width: 100%;
  height:100%;
  .extra-main{
    height: auto;
  }
}
.addrulebtn{
  margin: 0 0 16px 0;
}
.more-handle-btn {
  display: flex;
  flex-direction: column;
}
.more-handle{
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  color: @sc-primary-100;
  margin-left: 5px;
}
.extra-content{
  position: relative;
  .ant-row{
    display: flex;
    /deep/ .ant-form-item-label{
      width: 90px;
    }
    .ant-form-item-control{
      width: inherit;
      .ant-form-item-children{
        display: flex;
        flex-wrap: wrap;
        .timeperiod{
          /deep/.ant-form-item-label{
            width: inherit;
          }
          /deep/.ant-form-item-control-wrapper{
            margin-right: 30px;
            /deep/.ant-form-item-children{
              display: flex;
              .ant-time-picker{
                margin: 0 10px;
              }
            }
          }
        }
      }
    }
    .ant-form-item-control-wrapper input, .ant-select{
			width: 300px;
		}
  }
}
	.msg-title{
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    border: 0;
    color: @sc-grey-100;
    margin-bottom: 20px;
	}
	.msg-cont{
    width: 100%;
    height: auto;
		color: @sc-grey-80;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: @sc-greyBg-10;
    padding: 20px 12px;
    border-radius: 4px;
    margin-bottom: 24px;
    .select{
      width: 135px;
      margin: 0 10px;
    }
    .cbnumber{
      width: 54px;
      margin: 0 10px;
    }
	}
  .msg-content{
    width: 100%;
    height: auto;
		color: @sc-grey-80;
    background-color: @sc-greyBg-10;
    padding: 20px 12px;
    border-radius: 4px;
    margin-bottom: 20px;
    .select{
      width: 135px;
      margin: 0 10px;
    }
    .cbnumber{
      width: 54px;
      margin: 0 10px;
    }
  }
  .msg-tail{
    width: 100%;
    height: auto;
		color: @sc-grey-80;
    display: flex;
    align-items: center;
    background-color: @sc-greyBg-10;
    padding:20px 12px;
    border-radius: 4px;
    margin-bottom: 60px;
  }
  .rule-name{
    width: 55%;
    margin-bottom: 20px;
  }
  .name{
    width: 50%;
    height: 32px;
    font-size: 14px;
    margin:0 0 16px 0 ;
    line-height: 32px;
    display: flex;
    span{
      color: @sc-grey-80;
    }
    .ant-select-enabled{
      width: 70%;
      margin: 0 0 0 16px;
    }
    .ant-form-item-label{
      width: 390px;
    }
    /deep/.ant-form-item-control-wrapper{
      margin: 0;
    }
  }
.weekday{
    width: 100%;
    height: auto;
    border-radius: 4px;
    padding: 10px 14px;
    border: 1px solid rgba(81, 121, 251, 0.3);
    border-left: 2px solid #5179FB;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 24px;
    .msg-title{
      width: 100%;
      font-size: 16px;
      font-weight: 600;
      border: 0;
      color: @sc-grey-100;
      margin-bottom: 20px;
    }
  }
  .closed{
    border:1px solid rgba(237, 123, 47, 0.3);
    border-left: 2px solid #ED7B2F;
    height: auto;
    .button{
      width:400px;
    }
  }
  .rest{
     border: 1px solid rgba(0, 168, 112, 0.3);
    border-left: 2px solid #00A870;
    height: auto;
    .button{
      width:400px;
    }
  }
  .switch{
    width: 170px;
    height: 22px;
    font-size: 14px;
    margin:16px 0 16px ;
    display: flex;
    color: @sc-grey-80;
    align-items: center;
    .ant-switch{
      margin:  0 0 0 20px;
    }
  }
  .partition{
    width: 100%;
    height: 2px;
    background-color: @sc-greyBg-10;
    border: 0;
    margin: 0;
    padding: 0;
  }
  .time{
    width: 50% ;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    color: @sc-grey-80;
    .time-picker{
      margin-left: 16px;
  }
  }
  .time-picket{
    width: 260px;
    margin-right: 140px;
  }
  .breaks{
    width: 50%;
  }
  .weekday-limit{
    padding: 0;
    margin: 0;
    height: auto;
    background-color: @base-bg-color;
  }
  .weekday-rest{
    color:@sc-grey-40;
    margin-left: 10px;
  }
  .ant-form-item-control-wrapper{
    .a-time-picker{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-time-picker{
      margin: 0 5px;
    }
  }
  }
  /deep/.ant-form-item{
    margin: 0;
  }
.not-counted{
  width: 150%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  .time{
    width: 50%;
    display: flex;
    margin-bottom: 0;
  }
  .time-picker{
    margin-left: 16px;
  }
  .addBtn /deep/.iconpark-icon{
    color: @sc-primary-100;
  }
  .delete{
    /deep/.iconpark-icon{
      color: @sc-error-100;
    }
    &:hover{
      border-color: @sc-error-100;
      /deep/.iconpark-icon{
        color: @sc-error-100;
      }
    }
  }
}
.latest{
  width: 50%;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  color: @sc-grey-80;
  .time-picker{
  margin-left: 16px;
}
}

.number{
  width: 50px;
  margin: 0 10px;
}
/deep/.ant-btn-icon-only{
  color: @sc-grey-80;
}
#topLeft {
  width: 70%;
}
/deep/.anticon-exclamation-circle{
  margin-left: 8px;
  color: @sc-primary-100;
}
.icon-disabled{
  color: @sc-grey-40
}
/deep/ .ant-time-picker-input {
  padding: 4px 12px;
}
.more-handle-btn-box{
  display: flex;
  button{
    padding-left: 0;
    padding-right: 16px;
  }
}
</style>
