<!--
 * @Date: 2022-07-04 17:25:20
 * @descriotion: 明细
-->
<template>
  <div class="bill-details">
    <div class="header-back">
      <a-button prefix="left" @click="$emit('back')">返回</a-button>
    </div>
    <appForm
      class="appform"
      :to-list="formList"
      search-placeholder="请搜索姓名/工号"
      @getSearchCallback="getSearchCallback"
      @exportFileList="exportFile"
    />
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      row-key="onJobId"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="loadDataList"
    >
      <!-- 姓名 -->
      <template slot="staffName" slot-scope="{ scope }">
        <CbTableAvater
          :staff-id="scope.record.staffId"
          :on-job-id="scope.record.onJobId"
          :table-name="scope.record.staffName"
          :title="scope.record.staffName"
          :is-archives="true"
        />
      </template>
      <!-- 操作 -->
      <template slot="operation" slot-scope="{ scope }">
        <a-button type="link" @click="edit(scope.record)">
          明细
        </a-button>
      </template>
    </custom-table>
    <DetailDrawer ref="detailDrawer" detail-type="person" @reloadData="serchGetData" />
  </div>
</template>
<script>
import mixins from '@/components/CustomTable/index.js'
import { getTenantBillDetailApi, exportInsureBillApi } from '@/services/insurance/bill'
export default {
  name: 'TableBill',
  components: {
    DetailDrawer: () => import('./viewBill/detailDrawer.vue'),
    CbTableAvater: () => import('@/components/CbTableAvater/index')
  },
  mixins: [mixins],
  props: {
    month: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          align: 'center',
          width: 50,
          fixed: 'left',
          customRender: (text, record, index) => `${index + 1}`
        },
        {
          title: '证件号码',
          dataIndex: 'idNum',
          key: 'idNum',
          width: 160,
          fixed: 'left'
        },
        {
          title: '姓名',
          dataIndex: '',
          key: '',
          scopedSlots: { customRender: 'staffName' },
          fixed: 'left',
          width: 105
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 50,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      dataSource: [],
      formList: ['search', 'export']
    }
  },
  watch: {
    'month': function(val) {
      this.serchGetData()
    }
  },
  created() {},
  mounted() {},
  methods: {
    getSearchCallback(data) {
      this._queryParam.keyWord = data.searchValue
      this.serchGetData()
    },
    edit(data) {
      this.$refs.detailDrawer.onShow({
        onJobId: data.onJobId,
        insureMonth: this.month
      })
    },
    // 获取账单明细
    async fetchDataApiFn(param) {
      const res = await getTenantBillDetailApi({ insureMonth: this.month, ...param })
      if (res.code === 100) {
        this.columns = this.$options.data().columns
        // 加载列
        const tempColumns = res.data.header
        for (let i = 0; i < tempColumns.length; i++) {
          const children = tempColumns[i].children.map((v) => {
            return {
              title: v.label,
              dataIndex: v.value,
              key: v.value,
              width: 190,
              dataType: v.dataType
            }
          })
          tempColumns[i].children = children
          tempColumns[i].title = tempColumns[i].insureClassify
          tempColumns[i].width = 100
        }
        this.columns.splice(
          3,
          0,
          {
            title: '单位合计金额',
            dataIndex: 'companyTotal',
            key: 'companyTotal',
            width: 120,
            dataType: 'money'
          },
          {
            title: '个人合计金额',
            dataIndex: 'personTotal',
            key: 'personTotal',
            width: 120,
            dataType: 'money'
          },
          ...tempColumns
        )
        this.dataSource = res.data.dataList.records ? res.data.dataList.records : []
        this.total = res.data.dataList.total
        this.pager.pageNum = res.data.dataList.current || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.dataList.size || 10 // 更新当前的分页信息
      }
    },
    exportFile() {
      exportInsureBillApi({ insureMonth: this.month, keyWord: this._queryParam.keyWord })
    }
  }
}
</script>
<style lang="less" scoped>
.header-back {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}
/deep/.default{
  .prefix{
    color: @sc-grey-100;
  }
}
.bill-details {
  height: 100%;
  /deep/.ant-table-thead tr th {
    padding: 6px 8px !important;
    border-right: 0.5px solid @sc-greyBg-20 !important;
    border-bottom: 1px solid @sc-greyBg-20 !important;
  }
}
</style>
