<template>
  <div>
    <Modal
      :closable="true"
      :title="'合并'"
      :visible="visible"
      :mask-closable="false"
      :ok-text="'保存'"
      :size="'normal'"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
        <a-form-model-item label="选择组织：">
          <DepTreeSelect v-model="selectOrganization" multiple />
        </a-form-model-item>
        <a-form-model-item label="合并至">
          <DepTreeSelect v-model="merge" :multiple="false" />
        </a-form-model-item>
      </a-form-model>
      <div class="explain">
        <div class="explain_margin explain-title">说明:</div>
        <div class="explain_margin">1、合并父级组织时，若子级组织被勾选（无子级组织视为被勾选），合并时，则一并合并到目标组织。原父级、子级组织不保留。</div>
        <div class="explain_margin">2、合并父级组织时，若子级组织未勾选，合并时，子级组织相互不合并， 仅移动至目标组织。父级组织更新为目标组织，原父级组织不保留。</div>
        <div class="explain_margin">3、合并后，组织下直属员工的“部门”数据相应自动更新为合并后的组织。 合并后组织负责人为目标组织负责人。</div>
      </div>
    </Modal>
  </div>
</template>

<script>
import mixins from '@/components/CbPerDepSelection/index.js'
import { mergeOrg } from '@/services/organization/framework.js'
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
}
export default {
  name: 'Merge',
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  mixins: [mixins],
  data() {
    return {
      selectOrganization: [],
      merge: [],
      formItemLayout,
      visible: false
    }
  },
  methods: {
    show() {
      this.visible = true
    },
    // 确认
    async handleOk() {
      if (this.selectOrganization.length <= 0 || !('value' in this.selectOrganization[0])) return this.$message.warning('选择组织不能为空')
      if (this.merge.length <= 0 || !('value' in this.merge[0])) return this.$message.warning('合并项不能为空')
      if (this.selectOrganization[0].value === this.merge[0].value) return this.$message.warning('同一组织不可合并')
      const organizationList = []
      this.selectOrganization.forEach(item => organizationList.push(item.value))
      const res = await mergeOrg({
        orgList: organizationList,
        mergeOrgId: this.merge[0].value
      })
      if (res.code === 100) {
        this.$message.success('合并成功')
        this.handleCancel()
        this.$emit('mergeCallback')
      }
    },
    // 关闭
    handleCancel() {
      this.visible = false
      this.selectOrganization = []
      this.merge = []
    }
  }
}
</script>

<style lang="less" scoped>
  .explain{
    color: @sc-grey-100;
    .font-size(14px);
    .explain_margin {
      margin-top: 20px;
    }
    .explain-title {
      color: @sc-grey-80;
    }
  }
</style>
