<template>
  <div>
    <Modal :visible="visible" :closable="true" size="normal" title="确认该员工入职" ok-text="保存" @ok="handleOk" @cancel="handleCancel">
      <a-checkbox v-model="probation">无试用期</a-checkbox>
      <a-checkbox v-model="entryRegistration">发送入职登记表</a-checkbox>
    </Modal>
  </div>
</template>

<script>
import { confirmEntry } from '@/services/user/Induction.js'
export default {
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      visible: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      // 发送入职登记表默认选中
      entryRegistration: true,
      probation: false,
      entryApplyId: ''
    }
  },

  methods: {
    // 打开弹窗
    open(data) {
      this.visible = true
      this.entryApplyId = data.record.entryApplyId
    },
    // 保存事件
    async handleOk() {
      this.visible = false
      const res = await confirmEntry({
        entryApplyId: this.entryApplyId,
        entryRegistration: this.entryRegistration ? 2 : 1,
        probation: this.probation ? 1 : 0
      })
      if (res.code === 100) {
        this.$message.success('确认入职成功')
        // this.entryRegistration = false
        this.probation = false
        this.$emit('getFetchDataApiFn')
      }
    },

    // 取消事件
    handleCancel() {
      this.visible = false
      // this.entryRegistration = false
      this.probation = false
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-checkbox + span {
  color: @sc-grey-80;
  .font-size(14px);
}
</style>
