<template>
  <div class="UserEcho">
    <div v-for="user in userList" :key="user.staffId" class="user-box flex-between-center">
      <div class="user-left flex-align-center">
        <CbAvater :avater-name="user.staffName" :on-job-id="user.avater" size="30" />
      </div>
      <a-icon type="close" class="close" @click="delUser(user.staffId)" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserEcho',
  components: {
    CbAvater: () => import('@/components/CbAvater/theAvater.vue')
  },
  props: {
    userList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      grantRuleList: []
    }
  },
  created() {
  },
  methods: {
    // 删除已选中的人员
    delUser(staffId) {
      this.$emit('delUser', staffId)
    }
  }
}
</script>

<style lang="less" scoped>
.UserEcho{
  display: flex;
  flex-wrap:wrap;
  .user-box{
    height:34px;
    display: flex;
    padding: 2px 14px 2px 2px;
    margin:0 10px 12px 0;
    background:@layout-body-background;
    border-radius: 153px;
    .user-left{
      margin-right: 14px;
    }
    .close{
        cursor: pointer;
    }
  }
}

</style>
