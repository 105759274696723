<template>
  <div class="EmployeeSituation">
    <div class="whisper">
      <img class="whisperIcon" src="@/assets/img/home/weiyu.png" alt="">
      {{ whisper }}
      <img class="whisperBack" src="@/assets/img/home/cloud.png" alt="">
    </div>
    <div class="statistics flex-align-center">
      <ul class="statistics-box  flex-just-between">
        <li class="statistics-item ">
          <span class="item-title">在职职工</span>
          <span class="item-num">{{ onJobNum || '--' }}<span class="item-unit">人</span></span>
        </li>
        <li class="statistics-item ">
          <span class="item-title">日平均工时</span>
          <span class="item-num">{{ averageManHours||'--' }}<span class="item-unit">时</span></span>
        </li>
        <li class="statistics-item ">
          <span class="item-title">上月人均成本</span>
          <span class="item-num">{{ averageCostOfLastMonth||'--' }}<span class="item-unit">元</span></span>
        </li>
        <li class="statistics-item ">
          <span class="item-title">审批平均耗时</span>
          <span class="item-num">{{ averageTime||'--' }}<span class="item-unit">分</span></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getWhisperContent } from '@/services/home/whisper.js'
import { averageProcessingTime } from '@/services/home/process.js'
import { attendanceAvgDayWokHours } from '@/services/home/attendance.js'
import { getStaffStatistics } from '@/services/home/user.js'
export default {
  name: 'EmployeeSituation',
  inject: ['costObj'],
  data() {
    return {
      whisper: '',
      // 在职员工数量
      onJobNum: '',
      // 日平均工时
      averageManHours: '',
      // 平均工时
      averageTime: '',
      // 上月人均人工成本
      averageCostOfLastMonth: ''
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      const whisper = await getWhisperContent()
      this.whisper = whisper.data
      const user = await getStaffStatistics()
      this.onJobNum = user.data
      const time = await averageProcessingTime()
      this.averageTime = time.data
      const hours = await attendanceAvgDayWokHours()
      this.averageManHours = hours.data
    }
  }
}
</script>

<style lang="less" scoped>
.EmployeeSituation {
  border-radius: 8px;
  width:100%;
  background: linear-gradient(355deg, #B7EDFF 1%, #CDF3FF 99%);
  .whisper{
    position: relative;
    padding: 12px 15px 11px 15px;
    height:45px;
    .font-size(16px);
    color: @sc-grey-100;
    .whisperIcon{
      width: 18px;
      height: 18px;
      position: relative;
      top: -3px;
      margin-right: 3px;
    }
    .whisperBack{
      position: absolute;
      right:0;
      width:417.5px;
      height:67.61px;
    }
  }
  .statistics{
    height:135px;
    background:rgba(255, 255, 255, 0.4);
    border-radius: 8px;
    backdrop-filter: blur(173px);
    &-box{
      padding: 0 9.5%;
      width:100%;
    }
    &-item{
      display:flex;
      flex-direction: column;
      .item-num {
        margin-top: 6px;
        .font-size(26px);
        color:  @sc-grey-100;
        font-weight: 600;
      }
      .item-title,.item-unit{
        .font-size(14px);
        color:  @sc-grey-60;
      }
      .item-unit{
        margin-left: 10px;
      }
    }
  }
}
</style>
