<template>
  <div class="p-container">
    <HeaderBack title="设置祝福" @back="$emit('back')" />
    <a-tabs :default-active-key="careMethod" @change="callback">
      <a-tab-pane key="h5" tab="H5海报" />
      <a-tab-pane key="workbench" tab="工作台海报" />
    </a-tabs>
    <div class="poster">
      <div class="posters">
        <div class="posters-top first">
          <div class="posters-top-head">
            <span>海报</span>
            <svg class="iconpark-icon" @click="addPoster"><use href="#piliangdaoru" /></svg>
          </div>
          <div v-if="imgList && imgList.length > 0" class="posters-top-content">
            <div
              v-for="(item,index) in imgList"
              :key="index"
              class="img"
              :style="{'backgroundImage':`${$options.filters.posterList(item,basePath)}`}"
              @click="imgChange(item,index)"
            >
              <div v-if="posterId === item.carePosterId && imgList.length > 0" class="isSelected">
                <span>已选中</span>
              </div>
              <div v-if="item.userDefined" class="deletemask">
                <svg class="iconpark-icon Circle-Button " @click.stop="delPoster(item.carePosterId,index)">
                  <use href="#shanchu-baise" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="showImg" class="posters-top-content">
            <CbNoData text="暂无海报" />
          </div>
        </div>
        <div class="posters-top second">
          <div class="posters-top-head">
            <span>文案</span>
            <svg class="iconpark-icon" @click="onClick">
              <use href="#piliangdaoru" />
            </svg>
          </div>
          <div v-if="infoList && infoList.length > 0" class="posters-top-content textPoster">
            <div
              v-for="(item,index) in infoList"
              :key="index"
              :class="Method ? 'text':'workStation'"
              @click="textChange(item,index)"
            >
              <div v-if="item.userDefined" class="deletemask">
                <svg class="iconpark-icon Circle-Button " @click.stop="delBless(item.careBlessId,index)">
                  <use href="#delete-one" />
                </svg>
              </div>
              <div :class="blessId === item.careBlessId ? 'textColor':'textItems'">
                <p>{{ Method ? `${item.careBlessTextOne} #姓名#` : item.careBlessTextOne }}</p>
                <p class="blessTwo">{{ item.careBlessTextTwo }}</p>
              </div>
            </div>
          </div>
          <div v-if="showText" class="posters-top-content">
            <CbNoData text="暂无文案" />
          </div>
        </div>
      </div>
      <div class="preview">
        <div class="preview-head">模板预览</div>
        <div class="preview-content">
          <div class="CbColorPicker">
            <span>字体颜色</span>
            <colorPicker v-model="color" @change="headleChangeColor" />
          </div>
          <div class="proview-min">
            <div class="receiver">
              <div class="long-view" />
              <div class="short-view" />
            </div>
            <div class="view">
              <div class="title" v-html="Method ? type ? '生日祝福' : '周年祝福' :'工作台'" />
              <div
                v-if="Method"
                id="content"
                class="content"
                :style="{'color':`${color}`}"
              >
                <img v-if="posterId" class="img-poster" :src="`${$options.filters.posters(imgSrc, basePath)}`">
                <p v-if="!posterId && !blessId" class="preview-p">请选择左侧海报及文案预览</p>
                <div class="h5-text">
                  <div v-if="message.messageOne && message.messageTwo" class="greetings-name">#姓名#：</div>
                  <p class="greetings-title">{{ message.messageOne }}</p>
                  <p class="greetings-title"> {{ message.messageTwo }}</p>
                </div>
              </div>
              <div v-else id="content" class="content">
                <div
                  class="banner"
                  :style="{'color':`${color}`,'backgroundImage':`url(${$options.filters.posters(imgSrc, basePath)})`}"
                >
                  <p v-if="message.messageOne || message.messageTwo" class="daytitle bold">今天~</p>
                  <p class="text firstText">{{ message.messageOne }}</p>
                  <p class="text">{{ message.messageTwo }}</p>
                </div>
                <div v-if="posterId" class="work-content">
                  <img :src="require('@/assets/img/culture/station.png')" alt="">
                </div>
                <p v-if="!posterId && !blessId" class="preview-p">请选择左侧海报及文案预览</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        closable
        size="normal"
        :z-index="2022"
        :visible="visible"
        title="文案自定义"
        @cancel="onClose('visible')"
        @ok="onSubmit('visible')"
      >
        <a-form-model
          v-if="!Method"
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="第一段" prop="careBlessTextOne">
            <a-input v-model="form.careBlessTextOne" :max-length="12" placeholder="请输入第一段祝福语" />
          </a-form-model-item>
          <a-form-model-item label="第二段" prop="careBlessTextTwo">
            <a-input v-model="form.careBlessTextTwo" :max-length="12" placeholder="请输入第二段祝福语" />
          </a-form-model-item>
          <div class="alerted">
            <p>说明: </p>
            <p>工作台海报目前支持两段文字，每段最多12个字符。编辑自定义的要注意字体格式</p>
          </div>
        </a-form-model>
        <a-form-model
          v-else
          ref="ruleForm"
          :model="form"
          :rules="rulesStation"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="标题" prop="careBlessTextOne">
            <a-input v-model="form.careBlessTextOne" :max-length="6" placeholder="请输入1-6个字符" />
          </a-form-model-item>
          <a-form-model-item label="内容" prop="careBlessTextTwo">
            <a-textarea
              v-model="form.careBlessTextTwo"
              placeholder="请输入1-100个字符"
              :auto-size="{ minRows: 3, maxRows: 5 }"
              :max-length="100"
            />
          </a-form-model-item>
          <div class="alerted">
            <p>说明: </p>
            <p>H5海报目前支持标题和内容的编辑，标题最多6个字符，内容最多100个字符</p>
          </div>
        </a-form-model>
      </Modal>
      <Modal
        closable
        overflow-auto
        :visible="addImgeVisible"
        title="添加海报"
        size="large"
        :z-index="2022"
        @cancel="onClose('addImgeVisible')"
        @ok="onSubmit('addImgeVisible')"
      >
        <!-- uplaod-tip="上传海报"  -->
        <div :style="{width: Method ? '750px':'694px', margin: `0 auto` }">
          <CBCropper
            ref="CBCropper"
            :img-size="Method ?
              { width: 750, height: 1624 } : { width: 694, height: 280 }"
            :file-size="20"
            :type-list="['jpg', 'jpeg', 'png']"
          />
        </div>
      </Modal>
    </div>
    <div class="complete">
      <a-button type="primary" class="setting" @click="Setting">完成设置</a-button>
    </div>
  </div>
</template>

<script>
import {
  getPosterList,
  getWishList,
  addWish,
  addPoster,
  completeSetting,
  searchSetting,
  delPoster,
  delBless } from '@/services/culture/care.js'
import { getSignatures } from '@/services/upload/index.js'
export default {
  name: 'SettingBless',
  components: {
    CbNoData: () => import('@/components/CbNoData/index.vue'),
    CBCropper: () => import('@/components/CBCropper'),
    Modal: () => import('@/components/CbModal/index.vue'),
    HeaderBack: () => import('@/components/headerBack/index.vue')
  },
  filters: {
    posters(imgSrc, basePath) {
      return `${basePath}/hrsaas-storage/image/thumbnail/name?id=${imgSrc.fileId}&expireTime=${imgSrc.time}&signature=${imgSrc.signature}`
    },
    posterList(imgSrc, basePath) {
      return `url(${basePath}/hrsaas-storage/image/thumbnail/name?id=${imgSrc.fileId}&width=${250}&height=${540}&expireTime=${imgSrc.time}&signature=${imgSrc.signature})`
    }
  },
  props: {
    careType: {
      type: String,
      default: 'birthday'
    }
  },
  data() {
    return {
      flag: false, // 判断是否被设置过
      blessId: '', // 被选中的祝福语id
      posterId: '', // 被选中的海报id
      careMethod: 'h5',
      color: '',
      showImg: false,
      showText: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      addImgeVisible: false, // 添加海报弹窗
      form: {}, // 文案modal表单
      visible: false,
      message: {
        messageOne: '',
        messageTwo: ''
      },
      infoList: [], // 文案模板列表
      imgList: [], // 海报模板列表
      imgSrc: {},
      rules: {
        careBlessTextOne: [{ required: true, message: '第一段祝福语不能为空', trigger: 'blur' },
          { max: 12, message: '祝福语超出12字符', trigger: 'change' }],
        careBlessTextTwo: [{ required: true, message: '第二段祝福语不能为空', trigger: 'blur' },
          { max: 100, message: '祝福语超出12字符', trigger: 'change' }]
      },
      rulesStation: {
        careBlessTextOne: [{ required: true, message: '标题不能为空', trigger: 'blur' },
          { max: 6, message: '标题超出6字符', trigger: 'change' }],
        careBlessTextTwo: [{ required: true, message: '内容不能为空', trigger: 'blur' },
          { max: 100, message: '内容超出100字符', trigger: 'change' }]
      },
      basePath: process.env.VUE_APP_API_BASE_URL
    }
  },
  computed: {
    Method() {
      return this.careMethod === 'h5'
    },
    type() {
      return this.careType === 'birthday'
    }
  },
  created() {
    this.getPosterList(this.careType, this.careMethod)
    this.getWishList(this.careType, this.careMethod)
  },
  methods: {
    // 切换tab
    callback(key) {
      this.imgSrc = {}
      this.careMethod = key
      this.message = {}
      this.getPosterList(this.careType, this.careMethod)
      this.getWishList(this.careType, this.careMethod)
      this.color = '#202A40'
    },
    // 获取海报列表
    async getPosterList(type, modular) {
      const res = await getPosterList({ careType: type, careMethod: modular })
      if (res.code !== 100) { this.showImg = true }
      this.imgList = res.data
      if (this.imgList.length <= 0) {
        this.showImg = true
      }
      const arr = []
      res.data.forEach(item => {
        arr.push(item.fileId)
      })
      if (arr.length > 0) {
        const signature = await getSignatures({ ids: arr.join(',') })
        this.imgList.forEach((item, index) => {
          item.time = signature.data[index].time
          item.signature = signature.data[index].signature
        })
      }
      this.isSetted('img', type, modular)
    },
    // 获取祝福语列表
    async getWishList(type, modular) {
      const res = await getWishList({ careType: type, careMethod: modular })
      if (res.code !== 100) { this.showText = true }
      this.infoList = res.data
      if (this.infoList.length <= 0) {
        this.showText = true
      }
      this.isSetted('copyWriting', type, modular)
    },
    // 判断是否走查询接口
    async isSetted(mode, type, modular) {
      const isSettId = await this.getSettingId(type, modular)
      const idList = []
      if (mode === 'img') {
        this.imgList.forEach(item => {
          idList.push(item.carePosterId)
        })
        const isFlag = isSettId && idList.indexOf(isSettId[0]) >= 0
        this.posterId = isFlag ? this.imgList[idList.indexOf(isSettId[0])].carePosterId : ''
        this.imgSrc = isFlag ? this.imgList[idList.indexOf(isSettId[0])] : {}
      } else if (mode === 'copyWriting') {
        this.infoList.forEach(item => {
          idList.push(item.careBlessId)
        })
        const isFlag = isSettId && idList.indexOf(isSettId[1]) >= 0
        this.blessId = isFlag ? this.infoList[idList.indexOf(isSettId[1])].careBlessId : ''
        this.message.messageOne = isFlag ? this.infoList[idList.indexOf(isSettId[1])].careBlessTextOne : ''
        this.message.messageTwo = isFlag ? this.infoList[idList.indexOf(isSettId[1])].careBlessTextTwo : ''
      }
      this.changeColor()
    },
    // 判断列表是否为空、有无被选中的海报和文案
    changeColor() {
      if (this.posterId === '' && this.blessId === '') {
        this.color = '#202a40'
      }
      if (this.imgList.length <= 0 && this.infoList.length <= 0) {
        this.color = '#202a40'
      }
    },
    // 判断选择图片信息 改变颜色
    judgmentImge(imgId) {
      if (imgId) {
        this.imgList.forEach(item => {
          if (item.carePosterId === imgId) {
            this.color = item.userDefined ? '#202a40' : item.defaultFontColor
          }
        })
      }
    },
    async getSettingId(type, modular) {
      const res = await searchSetting({ careType: type, careMethod: modular })
      // res.data = null
      if (res.data) {
        this.flag = true
        this.color = res.data.blessColor
        const imgId = res.data.carePosterId
        const textId = res.data.careBlessId
        return [imgId, textId]
      } else {
        this.color = '#202A40'
      }
    },
    // 点击改变颜色
    headleChangeColor(val) {
      this.color = val
    },
    // 点击改变图片
    imgChange(it) {
      this.imgSrc = it
      this.posterId = it.carePosterId
      if (!this.flag) {
        this.judgmentImge(it.carePosterId)
      }
    },
    // 点击选中文案
    textChange(it) {
      this.blessId = it.careBlessId
      this.message.messageOne = it.careBlessTextOne
      this.message.messageTwo = it.careBlessTextTwo
    },
    onChange(a, b, c) {},
    // 点击打开海报modal
    addPoster() {
      this.addImgeVisible = true
    },
    // modal取消
    onClose(val) {
      this[val] = false
      this.form = {}
    },
    // 点击打开modal
    onClick() {
      this.visible = true
    },
    // modal提交
    async onSubmit(val) {
      if (val === 'visible') {
        this.$refs.ruleForm.validate(async(valid) => {
          if (valid) {
            const { careBlessTextOne, careBlessTextTwo } = this.form
            const params = {
              careBlessTextOne: careBlessTextOne,
              careBlessTextTwo: careBlessTextTwo,
              careType: this.careType,
              careMethod: this.careMethod
            }
            const res = await addWish({ ...params })
            this.onClose(val)
            this.getWishList(this.careType, this.careMethod)
            console.log(res)
          }
        })
      } else {
        const arrPosterUrl = await this.$refs.CBCropper.uploadAndGetData()
        const param = {
          fileId: arrPosterUrl.fileId,
          careType: this.careType,
          careMethod: this.careMethod
        }
        const res = await addPoster({ ...param })
        this.onClose(val)
        this.getPosterList(this.careType, this.careMethod)
        console.log(res)
      }
    },
    // 完成设置
    async Setting() {
      const param = {
        careType: this.careType,
        careMethod: this.careMethod,
        blessColor: this.color,
        carePosterId: this.posterId,
        careBlessId: this.blessId
      }
      const res = await completeSetting({ ...param })
      if (res.code === 100) {
        this.$message.success('设置成功')
      }
      this.$emit('back')
      console.log(res)
    },
    // 删除海报
    delPoster(id) {
      this.$Reconfirm({
        title: '删除海报',
        content: `确定要删除这张海报吗?`
      }).then(async() => {
        const res = await delPoster({ carePosterId: id })
        if (res.code !== 100) return
        this.$message.success('删除成功')
        this.getPosterList(this.careType, this.careMethod)
        if (id === this.posterId) {
          this.imgSrc = {}
          this.posterId = ''
        }
      }).catch(() => {
        console.log('no')
      })
    },
    // 删除文案
    delBless(id, index) {
      this.$Reconfirm({
        title: '删除文案',
        content: `确定要删除这条文案吗?`
      }).then(async() => {
        const res = await delBless({ careBlessId: id })
        if (res.code !== 100) return
        this.$message.success('删除成功')
        this.getWishList(this.careType, this.careMethod)
        if (id === this.blessId) {
          this.message = {}
          this.blessId = ''
        }
      }).catch(() => {
        console.log('no')
      })
    }
  }
}
</script>

<style scoped lang="less">
@iponeColor: #E9EEF7;
.p-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 20px 20px 0;
  background: #fff;
}
.tabs{
    width: 100%;
    height: 54px;
    border-bottom: 1px solid @sc-greyBg-20;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .tabs-content{
      width: 310px;
      height: 38px;
      border-radius: 6px;
      padding: 4px;
      border: 1px solid @sc-greyBg-20;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .work-poster{
        width: 148px;
        height: 30px;
        border-radius:4px;
        color: @sc-grey-60;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .clickColor{
    background-color: @sc-greyBg-10;
    color: @sc-grey-100;
  }
  .poster {
    height: calc(100% - 147px);
    display: flex;
    justify-content: space-between;
    .posters{
      width: 60%;
      height: 100%;
      border-right: 1px solid @sc-greyBg-20;
      .posters-top{
        width: 100%;
        height: 50%;
        border-bottom: 1px solid @sc-greyBg-20;
        &:nth-child(2){
          border: 0;
        }
        .posters-top-head{
          width: 100%;
          height: 50px;
          display: flex;
          padding-right: 22px;
          align-items: center;
          justify-content: space-between;
          span{
            font-size: 16px;
            color: @sc-grey-100;
            font-weight: bold;
          }
        }
        .posters-top-content{
          width: 100%;
          height: 100%;
          position: relative;
          max-height: calc( 100% - 50px);
          overflow: auto !important;
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
          }
          .img{
            width: calc((100% - 60px) / 3);
            height: 120px;
            border-radius:10px ;
            margin: 0 20px 20px 0;
            background-size: 100%,100%;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            .isSelected {
              background-color: @sc-primary-100;
              overflow: hidden;
              white-space: nowrap;
              position: absolute;
              left: -58px;  // 根据实际调整
              top: 15px;   // 根据实际调整
              transform: rotate(-45deg);
              z-index: 20;
              span {
                color: #fff;
                padding: 3px 42px 3px 62px;
                display: block;
                font-size: 12px;
              }
            }
            &:hover{
              .deletemask{
              display: block;
              }
            }
            .deletemask{
              display: none;
              position: absolute;
              width: 100%;
              height: 26px;
              top: 0;
              left: 0;
              z-index: 10;
              text-align: right;
              margin: auto 0;
              background: rgba(0,0,0,0.2);
              .Circle-Button {
                width: 15px;
                height: 15px;
                margin-top:8px;
                margin-right: 8px;
                vertical-align: top;
              }
            }
            .mask{
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              border-radius:10px;
              background: rgba(0,0,0,0.2);
            }
            .icon{
              font-size: 26px;
              color: @sc-grey-60;
              position: absolute;
              bottom: 10px;
              right: 10px;
              z-index: 5;
            }
          }
          .text,
          .workStation {
            position: relative;
            width: calc((100% - 40px) / 2);
            height: 64px;
            border-radius:4px;
            margin: 0 20px 20px 0;
            border: 1px dashed @sc-greyBg-50;
            white-space: pre-line;
            color: @sc-grey-80;
            cursor: pointer;
            line-height: 20px;
            overflow: hidden;
            .deletemask{
              position: absolute;
              width: 100%;
              height: 26px;
              top: 0;
              left: 0;
              text-align: right;
              margin: auto 0;
              .Circle-Button {
                width: 15px;
                height: 15px;
                margin-top:5px;
                margin-right: 8px;
                vertical-align: top;
                color: #C7C9D0;
                &:hover{
                  color: red;
                }
              }
            }
            .textColor,
            .textItems {
              font-weight: bold;
              padding: 12px 16px;
              background-color: @sc-greyBg-10;
              color: @sc-primary-100;
              cursor: pointer;
              p {
                margin-bottom: 0;
              }
              .blessTwo {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              &:hover {
                color: @sc-primary-100;
              }
            }
            .textItems {
              background: #fff;
              color: @sc-grey-80;
            }
          }
          .workStation {
            width: calc((100% - 80px) / 4);
          }
        }
      }
    }
  }
.preview{
  width: 40%;
  height: 100%;
  padding-left: 20px;
  .preview-head{
    width: 100%;
    height: 32px;
    margin: 16px 0;
    border-radius:4px ;
    text-align: center;
    color: @sc-grey-100;
    font-size: 16px;
    font-weight: bold;
    line-height: 32px;
    background: @sc-greyBg-20;
  }
  .preview-content{
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
    .CbColorPicker{
      position: absolute;
      top: 0;
      bottom: 0;
      width: 30%;
      height: 34px;
      display: flex;
      align-items: center;
      span{
        padding-right: 12px;
      }
    }
  }
}
.proview-min {
  margin:  0 auto;
  height: calc(100% - 70px);
  width: 348px;
  border-radius: 50px;
  margin-bottom: 60px;
  border: 5px solid @iponeColor;
  padding:  24px 15px 20px 15px;
  position: relative;
  .receiver {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 7px;
    display: flex;
    align-items: center;
    .long-view {
      width: 51px;
      height: 6px;
      border-radius: 5px;
      background: @iponeColor;
      margin-right: 12px;
    }
    .short-view {
      border-radius: 50%;
      height: 12px;
      width: 12px;
      background: @iponeColor;
    }
  }
  .view {
    height: 100%;
    width: 100%;
    background: #fff;
    overflow: hidden;
    .title{
      width:100%;
      height:36px;
      text-align: center;
      line-height:36px;
      font-size: 16px;
      font-weight: bold;
    }
    .content{
      height: calc(100% - 36px);
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-content: flex-start;
      overflow: auto;
      position: relative;
      background: #F5F8FA;
      .img-poster {
        width: 100%;
      }
      .preview-p {
        position:absolute;
        top: 43%;
        color: @sc-grey-100;
      }
      .h5-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 188px 50px 0;
        word-break:break-all;
      }
      .size {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .work-content {
        width: 94%;
        margin: 0 auto;
        margin-top: 25px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .name{
      width: 100%;
      margin: 12px 0;
      text-align: center;
      font-size: 14px;
    }
    .greetings-title{
      width: 100%;
      text-align: left;
      margin-bottom: 5px;
      font-weight: 700;
      .font-size(14px);
    }
    .greetings-name {
      width: 100%;
      text-align: left;
      margin-bottom: 3px;
      font-weight: 700;
      .font-size(14px);
    }
    .banner{
      width: 94%;
      padding:26px 23px ;
      margin: 0 auto;
      height: 118px;
      background-size: 100% 100%;
      border-radius: 6px;
      font-weight: 700;
    }
    .daytitle{
      margin-bottom: 8px;
      font-size: 14px;
    }
    .text{
      white-space: pre-line;
      .font-size(12px);
      margin-bottom: 0;
    }
    .firstText {
      margin-bottom: 4px;
    }
  }
}
.iconpark-icon {
  width: 20px;
  height: 20px;
  color: @sc-primary-100;
  cursor: pointer;
}
/deep/.ant-upload-picture-card-wrapper{
  display: flex;
  justify-content: center;
}
.alerted{
  color: @sc-grey-40;
  p:nth-child(1){
    color: @sc-grey-100;
  }
}
.complete {
  position: absolute;
  bottom: 0;
  left:0;
  width: 100%;
  height:44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: @base-bg-color;
  border-top: 1px solid @sc-greyBg-20;
  .setting {
    background: @sc-primary-100;
    color: #fff;
    &:hover {
      background: @sc-primary-80;
      color: #fff;
    }
  }
}
</style>
