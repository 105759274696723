<template>
  <div class="attendancereminders">
    <div class="attendancereminders-title">
      <span>打卡提醒设置</span>
    </div>
    <div class="attendancereminders-content">
      <div class="attendancereminders-text">
        <div class="attendancereminders-text-title">
          <span>自动推送打卡提醒</span>
        </div>
        <div class="attendancereminders-text-content">
          <a-switch v-model="form.auto_push_clock" size="small" @change="onChange($event,'auto_push_clock')" />
        </div>
      </div>
      <div class="attendancereminders-text">
        <div class="attendancereminders-text-title" />
        <div class="attendancereminders-text-content">
          <a-checkbox v-model="form.clock_in" :disabled="!form.auto_push_clock" @change="onChange($event,'clock_in')"><span>上班打卡提醒 上班前10分钟内推送打卡提醒</span></a-checkbox>
        </div>
      </div>
      <div class="attendancereminders-text">
        <div class="attendancereminders-text-title" />
        <div class="attendancereminders-text-content">
          <a-checkbox v-model="form.clock_out" :disabled="!form.auto_push_clock" @change="onChange($event,'clock_out')"><span>下班打卡提醒 下班后系统自动推送打卡提醒</span></a-checkbox>
        </div>
      </div>
      <div class="attendancereminders-text">
        <div class="attendancereminders-text-title">
          <span>打卡成功声音提醒</span>
        </div>
        <div class="attendancereminders-text-content">
          <a-switch v-model="form.clock_success_sound" size="small" @change="onChange($event,'clock_success_sound')" />
        </div>
      </div>
      <!-- <div class="attendancereminders-text">
        <div class="attendancereminders-text-title">
          <span>打卡成功微信通知</span>
        </div>
        <div class="attendancereminders-text-content">
          <a-switch v-model="form.clock_success_weixin" size="small" @change="onChange($event,'clock_success_weixin')" />
        </div>
      </div> -->
    </div>
    <div class="attendancereminders-title">
      <span>补卡提醒设置</span>
    </div>
    <div class="attendancereminders-text">
      <div class="attendancereminders-text-title">
        <span>自动推送补卡提醒</span>
      </div>
      <div class="attendancereminders-text-content">
        <a-switch v-model="form.auto_push_mend" size="small" @change="onChange($event,'auto_push_mend')" />
      </div>
    </div>

    <div class="attendancereminders-text">
      <div class="attendancereminders-text-title" />
      <div class="attendancereminders-text-content">
        <a-radio-group v-model="form.remind_cycle" :disabled="!form.auto_push_mend" @change="onChange($event,'remind_cycle')">
          <a-radio :style="radioStyle" value="day">
            <span>每日</span>
            <a-time-picker v-model="form.day_mend_send_time" :disabled="form.remind_cycle!=='day' || !form.auto_push_mend" format="HH:mm" value-format="HH:mm" style="margin-left:10px" @change="onChange($event,'day_mend_send_time')" />
            <span>仅提醒前一天班次内缺卡的人员</span>
          </a-radio>
          <!-- <a-radio :style="radioStyle" value="week">
            每周
            <a-select v-model="form.week_mend_send_day" :disabled="form.remind_cycle!=='week' || !form.auto_push_mend" placeholder="请选择" style="width: 120px;margin-left:10px" @change="onChange($event,'week_mend_send_day')">
              <a-select-option v-for="item in week_day" :key="item.codeKey" :value="item.codeKey">
                {{ item.content }}
              </a-select-option>
            </a-select>
            <a-time-picker v-model="form.week_mend_send_time" format="HH:mm" value-format="HH:mm" :disabled="form.remind_cycle!=='week'|| !form.auto_push_mend" style="margin-left:10px" @change="onChange($event,'week_mend_send_time')" />
            <span>仅提醒从上周五至本周五内缺卡的人员</span>
          </a-radio> -->
          <a-radio :style="radioStyle" value="month">
            <span>每月</span>
            <a-select v-model="form.month_mend_send_day" :disabled="form.remind_cycle!=='month' || !form.auto_push_mend" placeholder="请选择" style="width: 170px;margin-left:10px" @change="onChange($event,'month_mend_send_day')">
              <a-select-option v-for="item in missingpunch_alert_day_type" :key="item.codeKey" :value="item.codeKey">
                {{ item.content }}
              </a-select-option>
            </a-select>
            <a-time-picker v-model="form.month_mend_send_time" format="HH:mm" value-format="HH:mm" :disabled="form.remind_cycle!=='month' || !form.auto_push_mend" style="margin-left:10px" @change="onChange($event,'month_mend_send_time')" />
            <span>仅提醒当前考勤周期内缺卡的人员</span>
          </a-radio>
        </a-radio-group>
      </div>
    </div>

  </div>
</template>

<script>
import { getTenantConfigList, updateTenantConfigApi } from '@/services/attendance/holidays'
import { getTenantCode } from '@/services/dropDownInput/index.js'
export default {
  name: 'AttendanceReminders',
  data() {
    return {
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
        marginTop: '10px'
      },
      // 周选择项
      weekvalue: null,
      week_day: [],
      monthvalue: null,
      missingpunch_alert_day_type: [],
      form: {
        value: 'week'
      },
      value: 1
    }
  },
  watch: {

  },
  created() {
    getTenantCode({ codeName: 'missingpunch_alert_day_type' }).then(res => {
      this.missingpunch_alert_day_type = res.data || []
    })
    // getTenantCode({ codeName: 'week_day' }).then(res => {
    //   this.week_day = res.data || []
    // })
  },
  mounted() {
    this.getConfigList()
  },
  methods: {
    async onChange(val, key) {
      let configContent = ''
      if (key === 'remind_cycle') {
        configContent = val.target.value
      } else {
        configContent = val.target ? val.target.checked : val
      }
      const res = await updateTenantConfigApi({ configItemName: key, configContent: configContent })
      if (res.code !== 100) return
      this.$message.success('更改成功')
      this.getConfigList()
    },
    async changeValue(val) {

    },
    async getConfigList() {
      const itemNameList = ['auto_push_clock', 'clock_in', 'clock_out', 'clock_success_sound', 'clock_success_weixin', 'auto_push_mend',
        'day_mend', 'day_mend_send_time', 'remind_cycle', 'week_mend_send_day', 'week_mend_send_time', 'month_mend', 'month_mend_send_day', 'month_mend_send_time']
      const res = await getTenantConfigList({ itemNameList })
      const obj = {}
      res.data.map(item => {
        obj[item.configItemName] = item.configContent === 'true' || item.configContent === 'false' ? JSON.parse(item.configContent) : item.configContent
      })
      this.value = obj.remind_cycle
      this.$set(this, 'form', obj)
    }
  }
}
</script>

<style lang="less" scoped>
.attendancereminders{
  width: 100%;
  padding: 20px 20px 0;
}
.attendancereminders-title{
    height: 20px;
    margin: 14px 0 30px;
    color: @sc-grey-100;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 16px;
      margin-top: 2px;
      margin-right: 10px;
      background: @sc-primary-100;
    }
    &:nth-child(1){
      margin: 0 0 30px;
    }
    span{
    font-size: 16px;
    margin-left: 12px;
    font-weight: bold;
  }
}
.attendancereminders-text{
    display: flex;
    justify-content: center;
    align-items: center;
}
.attendancereminders-text-title{
    width: 200px;
    height: 50px;
    font-size:14px;
    display: flex;
    color: @sc-grey-80;
}
 .attendancereminders-text-content{
    width: 100%;
    height: 50px;
    display: flex;
    span{
      margin-left:10px;
      color: @sc-grey-80;
    }
 }
</style>
