<template>
  <div class="SalaryPlan">
    <!-- 新增薪资方案子类 -->
    <a-button prefix="piliangdaoru" @click="showDrawer">新增分类</a-button>
    <!-- 薪资方案子类表格 -->
    <SalaryTable ref="salaryTable" />
    <!-- 新增编辑分类抽屉 -->
    <NewCategory ref="newCategory" @refresh="refreshSalary" />
  </div>
</template>

<script>
export default {
  name: 'SalaryPlan',
  components: {
    SalaryTable: () => import('./table.vue'),
    NewCategory: () => import('./newCategory/index.vue')
  },
  data() {
    return {}
  },
  methods: {
    showDrawer() {
      this.$refs.newCategory.open()
    },
    // 刷新薪资分类表格
    refreshSalary() {
      this.$refs.salaryTable.initData()
    }
  }
}
</script>
<style scoped lang="less">
.SalaryPlan {
  box-sizing: border-box;
}
</style>
