<!-- 减员人员 -->
<template>
  <div>
    <div>
      <appForm
        :to-list="formList"
        search-placeholder="请搜索姓名/工号"
        :screen-roster-list="screenRosterList"
        @screenRosterParams="screenRosterParams"
        @getSearchCallback="getSearchCallback"
        @exportFileList="exportFileList"
      >
        <template slot="customButton">
          <div class="custom-button">
            <a-button prefix="piliangdaoru" @click="$emit('importTable')">
              批量导入
            </a-button>
          </div>
        </template>
      </appForm>
      <!-- 表格数据 -->
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        :tabs-height="15"
        :row-key="'onJobId'"
        :pagination="{
          current: pager.pageNum,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条数据`,
          showQuickJumper: true,
          showSizeChanger: true
        }"
        @change="loadDataList"
      >
        <!-- 序号 -->
        <template slot="index" slot-scope="{ scope }">
          <span>{{ scope.index + 1 }}</span>
        </template>
        <!-- 姓名头像 -->
        <template slot="staffName" slot-scope="{ scope }">
          <CbTableAvater
            :staff-id="scope.record.staffId"
            :on-job-id="scope.record.onJobId"
            :table-avater="scope.record.avatar"
            :table-name="scope.record.staffName"
          />
        </template>
        <!-- 操作列 -->
        <template slot="operation" slot-scope="{ scope }">
          <div class="flex-align-center">
            <a-button type="link" @click="handleDelete(scope.record)"> 添加 </a-button>
          </div>
        </template>
      </custom-table>
    </div>
  </div>

</template>

<script>
import {
  getPersonnelReducingList,
  exportPersonnelReducingList,
  reducePersonAdd
} from '@/services/wage/tax.js'
import { debounce } from '@/utils/index.js'
import mixins from '@/components/CustomTable/index.js'
import tableMixin from './tableMixin'
export default {
  name: 'VerifyStaff',
  components: {
    CbTableAvater: () => import('@/components/CbTableAvater/index')
  },
  mixins: [mixins, tableMixin],
  props: {
    subjectIds: {
      type: Object,
      default: () => {}
    },
    screenRosterList: {
      type: [Array, Object],
      default: () => {}
    }
  },
  data() {
    return {
      delVisible: false,
      staffId: '',
      formList: ['search', 'export', 'screen'], // search搜索框   export导出
      // 表格项
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'index' },
          fixed: 'left',
          width: '50px'
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          scopedSlots: { customRender: 'staffName' },
          key: 'staffName',
          fixed: 'left',
          width: 105
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          key: 'phoneNum',
          width: '180px',
          ellipsis: true
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          width: 120,
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          width: 120,
          ellipsis: true
        },
        {
          title: '聘用形式',
          dataIndex: 'engageModeName',
          key: 'engageModeName',
          width: 120,
          ellipsis: true
        },
        {
          title: '报税主体',
          dataIndex: 'contractCoName',
          key: 'contractCoName',
          width: '200px',
          ellipsis: true
        },
        {
          title: '证件类型',
          dataIndex: 'idTypeName',
          key: 'idTypeName',
          width: 120,
          ellipsis: true
        },
        {
          title: '证件号码',
          dataIndex: 'idNum',
          key: 'idNum',
          width: '200px',
          ellipsis: true
        },
        {
          title: '性别',
          dataIndex: 'sexName',
          width: 60,
          key: 'sexName',
          ellipsis: true
        },
        {
          title: '出生日期',
          dataIndex: 'birthDate',
          width: 120,
          key: 'birthDate',
          ellipsis: true
        },

        {
          title: '任职受雇关系',
          dataIndex: 'employmentTypeName',
          key: 'employmentTypeName',
          width: '150px',
          ellipsis: true

        },
        {
          title: '员工状态',
          dataIndex: 'staffStatusName',
          key: 'staffStatusName',
          width: 120,
          ellipsis: true
        },
        {
          title: '人员状态',
          dataIndex: 'employeeStateName',
          key: 'employeeStateName',
          width: 120,
          ellipsis: true
        },
        {
          title: '报税入职日期',
          dataIndex: 'taxFilingEntryTime',
          key: 'taxFilingEntryTime',
          width: '150px',
          ellipsis: true
        },
        {
          title: '报税离职日期',
          dataIndex: 'taxFilingLeaveTime',
          key: 'taxFilingLeaveTime',
          width: '150px',
          ellipsis: true
        },
        {
          title: '入职年度就业情形',
          dataIndex: 'employmentSituationOfEntryYearName',
          key: 'employmentSituationOfEntryYearName',
          width: '160px',
          ellipsis: true
        },
        {
          title: '本年度6万扣除',
          dataIndex: 'deductionOf60kThisYearName',
          key: 'deductionOf60kThisYearName',
          width: '160px',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          fixed: 'right',
          width: '80px',
          align: 'center'
        }
      ],
      // 表格数据
      dataSource: [],
      pager: {
        pageNum: 1,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  methods: {
    // 获取年终奖发放人员列表数据
    async fetchDataApiFn(param) {
      this._queryParam = {
        ...param,
        ...this.subjectIds
      }
      const res = await getPersonnelReducingList(this._queryParam)
      this.dataSource = res.data?.records || [] || []
      this.pager = param
      this.total = res.data?.total || 0
    },
    // 导出报表
    exportFileList() {
      exportPersonnelReducingList({
        ...this._queryParam,
        pageSize: -1
      })
    },
    handleDelete(record) {
      reducePersonAdd({ taxpaySubjectId: this.subjectIds.taxpaySubjectId, onJobId: record.onJobId, addStatus: 0 }).then(res => {
        this.$Reconfirm({
          title: '提示',
          content: res.data.noticeMessage
        }).then(() => {
          if (res.data.noticeResult === '0') {
            this.submitDel(record, 1)
          }
        }).catch(() => {
        })
      })
      this.staffId = record.staffId
      this.delVisible = true
    },
    submitDel: debounce(async function(r, n) {
      const res = await reducePersonAdd({ taxpaySubjectId: this.subjectIds.taxpaySubjectId, onJobId: r.onJobId, addStatus: n })
      if (res.code === 100) {
        this.delVisible = false
        this.initData()
      }
    }, 500)
  }
}
</script>

<style lang="less" scoped>

</style>
