<template>
  <div class="organ-report-p">
    <div v-for="item in reportList" :key="item.title" class="report-p-multi">
      <panelTable type="payroll" :title="item.title" :table-list="item.column" />
    </div>
  </div>
</template>

<script>
import panelTable from '@/components/CbReportForm/panelTable.vue'
import { getReportColumn } from '@/services/organization/reportForm.js'
export default {
  components: {
    panelTable
  },
  data() {
    return {
      reportList: []
    }
  },
  created() {
    this.initReport()
  },
  methods: {
    // 初始化报表页面
    initReport() {
      getReportColumn({
        moduleCode: 'payroll'
      }).then((res) => {
        if (res.code === 100) {
          this.reportList = res.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.organ-report-p {
  background-color: @gray-1;
  height: 100%;
  padding: 20px;
  .report-p-multi + .report-p-multi {
    margin-top: 30px;
  }
}
/deep/ .report-table-panel {
  .report-panel-title {
    margin-top: -2px;
    &:before {
    height: 16px;
  }
  }
  .more-handle {
      font-weight: 700;
      color: @sc-primary-100;
      margin-left: 6px;
      font-size: 20px;
    }
  .report-panel-tb {
    padding-left: 0;
    .ant-table-header-column {
      margin-left: 0;
    }
  }
}
</style>
