var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cultureDynamic"},[_c('appForm',{ref:"appForm",attrs:{"to-list":['search'],"width":"400","search-placeholder":"请搜索动态标题"},on:{"getSearchCallback":_vm.getSearchCallback}},[_c('template',{slot:"customButton"},[_c('a-space',[_c('a-button',{staticClass:"a-button",attrs:{"prefix":"add-one"},on:{"click":_vm.handleAdd}},[_vm._v(" 添加动态 ")])],1)],1)],2),_c('custom-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"row-key":"newsId","table-ref":".user-list","pagination":{
      current: _vm.pager.pageNum,
      pageSize: _vm.pager.pageSize,
      total: _vm.total,
      showTotal: function (total) { return ("共 " + total + " 条数据"); },
      showQuickJumper: true,
      showSizeChanger: true
    }},on:{"change":_vm.loadDataList},scopedSlots:_vm._u([{key:"index",fn:function(ref){
    var scope = ref.scope;
return [_c('span',[_vm._v(_vm._s(scope.index + 1))])]}},{key:"staffList",fn:function(ref){
    var scope = ref.scope;
return [(scope.record.scope === 'choose')?_c('TablePerson',{attrs:{"data-echo":scope.record.staffList}}):_vm._e(),(scope.record.scope === 'all')?_c('span',[_vm._v("全体员工")]):_vm._e()]}},{key:"operation",fn:function(ref){
    var scope = ref.scope;
return [_c('span',{staticClass:"operation-btn-box"},[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.preview(scope.record)}}},[_vm._v(" 预览 ")]),_c('a-button',{staticClass:"delete",attrs:{"type":"link","danger":""},on:{"click":function($event){return _vm.del(scope.record)}}},[_vm._v(" 删除 ")])],1)]}}])}),_c('addCultureDynamic',{ref:"addCultureDynamic",on:{"onSubmit":_vm.onSubmit,"onPreview":_vm.onPreview}}),_c('Preview',{ref:"Preview",attrs:{"mode":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }