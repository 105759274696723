export default {
  data() {
    return {}
  },
  methods: {
    getSearchCallback(data) {
      this._queryParam.inputValue = data.searchValue
      this.serchGetData()
    },
    screenRosterParams(data) {
      console.log('screenCondition', data)
      const { orgId } = data.screenCondition
      this._queryParam = {
        ...data.screenCondition,
        inputValue: data.searchValue,
        orgId: orgId && orgId[0].value
      }
      this.serchGetData()
    }
  }
}
