<template>
  <div id="LegalHoliday" class="LegalHoliday beauty-scroll">
    <appForm>
      <template slot="customButton">
        <div class="custom-button">
          <a-dropdown>
            <!-- <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              {{ holidayYear }} <a-icon type="down" />
            </a> -->
            <a-button suffix="left">
              {{ holidayYear }}
            </a-button>
            <a-menu slot="overlay">
              <a-menu-item v-for="year in yearList" :key="year" @click="screenData(year)">
                <a>{{ year }}</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <a-button type="primary" prefix="add-one" @click="addLegal">
            添加法定节假
          </a-button>
        </div>
      </template>
    </appForm>
    <custom-table
      id="table"
      :columns="columns"
      :data-source="dataSource"
      row-key="holidayId"
      :pagination="false"
      @change="loadDataList"
    >
      <!-- 假期时间 -->
      <template slot="dateTime" slot-scope="{scope}">
        <span>{{ scope.record.startDate }}~{{ scope.record.endDate }}</span>
      </template>
      <!-- 放假天数 -->
      <template slot="holidayDays" slot-scope="{scope}">
        <span>{{ scope.record.holidayDays }}天</span>
      </template>
      <!-- 法定时间 -->
      <template slot="legal" slot-scope="{scope}">
        <span>{{ scope.record.legalStartDate }}~{{ scope.record.legalEndDate }}</span>
      </template>
      <!-- 调休上班日 -->
      <template slot="adjustDate" slot-scope="{scope}">
        <span v-html="$options.filters.adjustDate(scope.record.adjustDate)" />
      </template>
      <!-- 是否启用 -->
      <span slot="switchStatus" slot-scope="{scope}">
        <div>
          <a-switch :default-checked="Boolean(+scope.record.status)" size="small" @change="onChange(scope.record)" />
        </div>
      </span>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{ scope}">
        <div class="flex-align-center">
          <a-button type="link" @click="editLegal(scope.record)">
            设置
          </a-button>
        </div>
      </template>
    </custom-table>
    <LegalModal ref="legalModal" :form-type="formType" />
  </div>
</template>

<script>
import { selectFestival, updateFestivalStatus, selectYearList } from '@/services/attendance/holidays.js'
import moment from 'moment'
import mixins from '@/components/CustomTable/index.js'
export default {
  name: 'LegalHoliday',
  components: {
    LegalModal: () => import('./legalModal/index.vue')
  },
  filters: {
    adjustDate(str) {
      return str ? str.replace(/[\ \r\n]+/g, '<br/>') : '-'
    }
  },
  mixins: [mixins],
  data() {
    return {
      holidayYear: '',
      yearList: [],
      columns: [{
        title: '法定节假名称',
        dataIndex: 'holidayName',
        key: 'holidayName',
        width: '100px',
        ellipsis: true
      },
      {
        title: '放假时间',
        dataIndex: 'dateTime',
        scopedSlots: { customRender: 'dateTime' },
        key: 'dateTime',
        width: '170px'
      },
      {
        title: '放假天数',
        scopedSlots: { customRender: 'holidayDays' },
        dataIndex: 'holidayDays',
        key: 'holidayDays',
        width: '80px'
      },
      {
        title: '法定日期',
        dataIndex: 'legal',
        scopedSlots: { customRender: 'legal' },
        key: 'legal',
        width: '170px'
      },
      {
        title: '补班日期',
        dataIndex: 'adjustDate',
        scopedSlots: { customRender: 'adjustDate' },
        key: 'adjustDate',
        ellipsis: true,
        width: '250px'
      },
      {
        title: '是否启用',
        dataIndex: 'isEnable',
        scopedSlots: { customRender: 'switchStatus' },
        key: 'isEnable',
        width: '80px'
      },
      {
        title: '操作',
        dataIndex: 'setting',
        scopedSlots: { customRender: 'operation' },
        key: 'setting',
        width: '50px',
        fixed: 'right'
      }
      ],
      dataSource: [],
      loading: false,
      formVisible: true,
      holidayId: undefined,
      formType: undefined
    }
  },
  computed: {},
  created() {
    this.getYearList()
    this.holidayYear = String(moment().year())
  },
  mounted() {

  },
  methods: {
    async getYearList() {
      const year = await selectYearList()
      this.yearList = year.data
    },
    // 请求数据
    async fetchDataApiFn(_queryParam) {
      const res = await selectFestival({ holidayYear: this.holidayYear })
      this.dataSource = res.data
    },
    screenData(year) {
      this._queryParam.holidayYear = this.holidayYear = year
      this.serchGetData()
    },
    async onChange(record) {
      const { status, holidayId } = record
      const res = await updateFestivalStatus({ status: !status, holidayId: holidayId })
      if (res.code === 100) {
        this.$message.success('状态改变成功！')
        this.serchGetData()
      }
    },
    // 添加法定节假日
    addLegal() {
      this.formType = 'add'
      this.$refs.legalModal.open()
    },
    // 编辑法定节假日
    editLegal(record) {
      this.formType = 'edit'
      this.holidayId = record.holidayId
      this.$refs.legalModal.open(record.holidayId)
      // this.$refs.legalModal.legalVisible = true
      // this.$refs.legalModal.getLegalDetails(record.holidayId)
    }
  }
}
</script>

<style lang="less" scoped>
.LegalHoliday{
  height: 100%;
  overflow: auto;
  background-color: #fff;
  padding:20px;
  .custom-button{
    .ant-btn{
      margin-right: 12px;
    }
  }
}
/deep/ .ant-switch-small {
  margin-left: 15px;
}
</style>
