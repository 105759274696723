<template>
  <div class="user-info-p">
    <div class="user-info-header">
      <div class="user-info-header-left">
        <div class="user-info-header-left-t">员工档案字段设置</div>
        <div class="user-info-header-left-b">
          员工信息字段可应用于花名册、员工端个人详情页、入职登记表等员工信息页面
        </div>
      </div>
      <div class="user-info-header-right">
        <a-button type="link" prefix="preview-open" @click="preview">预览入职登记表</a-button>
        <a-button type="primary" prefix="add-one" @click="addGroup">
          添加字段组
        </a-button>
      </div>
    </div>
    <div>
      <a-collapse
        v-if="infoList.length > 0"
        :default-active-key="
          (function(list) {
            return list.map((v) => v.fieldGroupId)
          })(infoList)
        "
      >
        <template #expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : -90" />
        </template>
        <a-collapse-panel
          v-for="(item, infoIndex) in infoList"
          :key="item.fieldGroupId"
          :header="item.fieldGroupName"
        >
          <div class="tb-body">
            <customTable
              :columns="columns"
              :data-source="
                (function(item) {
                  return item.content.list[0].columns.map((v) => {
                    return {
                      ...v.list[0],
                      fieldName: v.list[0].fieldName,
                      fieldType: v.list[0].fieldType,
                      system: v.list[0].fieldId,
                      staff: v.list[0].fieldId,
                      improve: v.list[0].fieldId
                    }
                  })
                })(item)
              "
              :row-key="'model'"
              :pagination="false"
            >
              <template slot="fieldType" slot-scope="{ scope }">
                {{
                  scope.record.fieldType == 1
                    ? '文本'
                    : scope.record.fieldType == 2
                      ? '日期'
                      : scope.record.fieldType == 3
                        ? '选项'
                        : ''
                }}
              </template>
              <template slot="system" slot-scope="{ scope }">
                <a-badge v-if="scope.record.manageEdit" :color="theme.warning" text="编辑" />
                <a-badge v-if="scope.record.manageShow" :color="theme.success" text="可见" />
                <a-badge v-if="scope.record.manageRequire" :color="theme.error" text="必填" />
              </template>
              <template slot="staff" slot-scope="{ scope }">
                <a-badge v-if="scope.record.staffEdit" :color="theme.warning" text="编辑" />
                <a-badge v-if="scope.record.staffShow" :color="theme.success" text="可见" />
                <a-badge v-if="scope.record.staffRequire" :color="theme.error" text="必填" />
              </template>
              <template slot="improve" slot-scope="{ scope }">
                <a-badge v-if="scope.record.entryFormEdit" :color="theme.warning" text="编辑" />
                <a-badge v-if="scope.record.entryFormShow" :color="theme.success" text="可见" />
                <a-badge v-if="scope.record.entryFormRequire" :color="theme.error" text="必填" />
              </template>
              <template slot="operation" slot-scope="{ scope }">
                <div v-if="scope.record.createType === 1 || scope.record.editable === '1'" class="flex-align-center">
                  <span class="operation-btn-box">
                    <a-button
                      v-if="scope.record.createType === 1 || scope.record.editable === '1'"
                      type="link"
                      @click="lineEdit(scope.record, infoIndex)"
                    >
                      编辑
                    </a-button>
                    <a-button
                      type="link"
                      class="delete"
                      :disabled="scope.record.createType === 0"
                      @click="lineDelete(scope.record)"
                    >
                      删除
                    </a-button>
                  </span>
                  <!-- <a-popover
                    placement="bottom"
                    trigger="hover"
                  >
                    <template slot="content">
                      <div class="more-handle-btn">
                        <a-button
                          v-if="scope.record.createType === 1 || scope.record.editable === '1'"
                          type="link"
                          @click="lineEdit(scope.record, infoIndex)"
                        >
                          编辑
                        </a-button>
                        <a-button
                          type="link"
                          class="delete"
                          :disabled="scope.record.createType === 0"
                          @click="lineDelete(scope.record)"
                        >
                          删除
                        </a-button>
                      </div>
                    </template>
                    <div class="more-handle">
                      ···
                    </div>
                  </a-popover> -->
                </div>
              </template>
            </customTable>
          </div>
          <template slot="extra">
            <a-popover placement="bottom" trigger="hover">
              <template slot="content">
                <div class="more-handle-btn">
                  <a-button type="link" @click.stop="add(item.fieldGroupId)">添加字段</a-button>
                  <a-button v-if="item.editable === '1'" type="link" @click.stop="setting(item)">
                    设置
                  </a-button>
                  <a-button v-if="item.createType === 1" type="link" @click.stop="editHeader(item)">
                    编辑
                  </a-button>
                  <a-button v-if="item.createType === 1" class="delete" type="link" @click.stop="delHeader(item)">
                    删除
                  </a-button>
                </div>
              </template>
              <div class="more-handle" @click="handleClick">
                ···
              </div>
            </a-popover>
          </template>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <!--  预览入职登记表  -->
    <registrationCode ref="registrationCode" />
    <addGroupModel ref="addGroupModel" @reload="initData" />
    <addSettingModel ref="addSettingModel" @reload="initData" />
    <settingModel ref="settingModel" @reload="initData" />
    <editHeader ref="editHeader" @reload="initData" />
  </div>
</template>

<script>
import customTable from '@/components/CustomTable/index'
import { getFieldGroupList, postDeleteFieldConfig, deleteFieldGroup } from '@/services/user/fieldGroup.js'
import { theme } from '@/config/config.js'
import { contractCompany } from '@/services/dictionary/index.js'
export default {
  name: 'UserInfo',
  components: {
    addGroupModel: () => import('./components/add-group-model.vue'),
    addSettingModel: () => import('./components/add-model.vue'),
    settingModel: () => import('./components/setting-model.vue'),
    editHeader: () => import('./components/edit-header.vue'),
    registrationCode: () => import('./components/registration-code.vue')
  },
  mixins: [customTable],
  data() {
    return {
      theme,
      visible: false, // 详情查看
      delFieldObj: {}, // 要删除的字段
      columns: [
        {
          title: '字段名称',
          dataIndex: 'fieldName',
          width: '15%',
          key: 'fieldName'
        },
        {
          title: '字段类型',
          dataIndex: 'fieldType',
          width: '15%',
          scopedSlots: { customRender: 'fieldType' }
        },
        {
          title: '管理系统显示',
          dataIndex: 'system',
          width: '20%',
          scopedSlots: { customRender: 'system' }
        },
        {
          title: '员工端显示',
          dataIndex: 'staff',
          width: '20%',
          scopedSlots: { customRender: 'staff' }
        },
        {
          title: '入职登记表显示',
          dataIndex: 'improve',
          width: '20%',
          scopedSlots: { customRender: 'improve' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '10%',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      infoList: [],
      fieldGroupObj: {}
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    // 防止点击'...'折叠板收起张开
    handleClick(event) {
      event.stopPropagation()
    },
    async initData() {
      const res = await getFieldGroupList()
      if (res.code === 100) {
        const data = res.data
        this.infoList = []
        for (let i = 0; i < data.length; i++) {
          const group = data[i]
          group.content = this.resolveChild(group.fields, group.fieldGroupId)
          delete group.fields
          this.infoList.push(group)
        }
      }
    },
    // 前端生成出对应的form数据
    resolveChild(children, fieldGroupId, disabledType = 'manageEdit', showType = 'manageShow') {
      const content = {
        list: [
          {
            'type': 'grid',
            'label': '栅格布局',
            'columns': [],
            'options': {
              'gutter': 16
            },
            'key': 'grid_' + fieldGroupId
          }
        ],
        config: {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      }
      const newList = []
      if (children && children.length !== 0) {
        for (let i = 0; i < children.length; i++) {
          if (String(children[i].fieldType) === '1') {
            newList.push({
              span: 12,
              list: [
                {
                  ...children[i],
                  type: 'input',
                  label: children[i].fieldName,
                  model: children[i].fieldId,
                  key: children[i].fieldId,
                  options: {
                    'type': 'text',
                    'width': '100%',
                    'defaultValue': `${children[i].value || ''}`,
                    'defaultName': `${children[i].defaultName}`,
                    'placeholder': `请输入${children[i].fieldName}`,
                    'clearable': false,
                    'maxLength': 255,
                    'addonBefore': '',
                    'addonAfter': '',
                    'hidden': !children[i][showType],
                    'disabled': children[i][disabledType]
                  },
                  rules: [
                    {
                      'required': children[i].staffRequire === 1,
                      'message': `请输入${children[i].fieldName}`
                    }
                  ]
                }
              ]
            })
          } else if (String(children[i].fieldType) === '2') {
            newList.push({
              span: 12,
              list: [
                {
                  ...children[i],
                  type: 'select',
                  label: children[i].fieldName,
                  model: children[i].fieldId,
                  key: children[i].fieldId,
                  options: {
                    'width': '100%',
                    'defaultValue': `${children[i].value || ''}`,
                    'defaultName': `${children[i].defaultName}`,
                    'multiple': false,
                    'clearable': true,
                    'placeholder': `请选择${children[i].fieldName}`,
                    'dynamicKey': children[i].optionType,
                    'dynamic': true,
                    'showSearch': false,
                    'hidden': !children[i][showType],
                    'disabled': children[i][disabledType]
                  },
                  rules: [
                    {
                      'required': children[i].staffRequire === 1,
                      'message': `请选择${children[i].fieldName}`
                    }
                  ]
                }
              ]
            })
          } else if (String(children[i].fieldType) === '3') {
            newList.push({
              span: 12,
              list: [
                {
                  ...children[i],
                  type: 'date',
                  label: children[i].fieldName,
                  model: children[i].fieldId,
                  key: children[i].fieldId,
                  options: {
                    'width': '100%',
                    'defaultValue': `${children[i].value || ''}`,
                    'defaultName': `${children[i].defaultName}`,
                    'rangeDefaultValue': [],
                    'range': false,
                    'showTime': false,
                    'clearable': true,
                    'placeholder': `请选择${children[i].fieldName}`,
                    'format': 'YYYY-MM-DD',
                    'rangePlaceholder': ['开始时间', '结束时间'],
                    'hidden': !children[i][showType],
                    'disabled': children[i][disabledType]
                  },
                  rules: [
                    {
                      'required': children[i].staffRequire === 1,
                      'message': `请选择${children[i].fieldName}`
                    }
                  ]
                }
              ]
            })
          }
        }
      }
      content.list[0].columns = newList
      return content
    },
    // 添加字段组
    addGroup() {
      this.$refs.addGroupModel.open()
    },
    // 添加字段
    add(fieldGroupId) {
      this.$refs.addSettingModel.open({
        fieldGroupId
      })
    },
    // 设置字段组
    setting(item) {
      this.$refs.settingModel.open(item)
    },
    // 编辑字段
    async lineEdit(record, infoIndex) {
      if (record.fieldCode === 'companyid') {
        const arr = []
        const option = await contractCompany()
        option.data.forEach(item => {
          const obj = {
            label: item.companyName,
            value: item.companyId
          }
          arr.push(obj)
        })
        record.option = arr
      }
      this.$refs.addSettingModel.open({ ...record })
    },
    // 编辑折叠头
    editHeader(data) {
      this.$refs.editHeader.show(data)
    },
    // 删除字段组-弹窗
    delHeader(data) {
      this.fieldGroupObj = data
      this.$Reconfirm({
        title: '删除字段组',
        content: `确定要删除“${this.fieldGroupObj.fieldGroupName}”字段组所有数据？`
      }).then(async() => {
        // 删除字段组-确认
        const res = await deleteFieldGroup({
          fieldGroupId: this.fieldGroupObj.fieldGroupId
        })
        if (res.code === 100) {
          this.$message.success('字段组名称删除成功')
          this.initData()
        }
      }).catch(() => {
        console.log('no')
      })
    },
    // 删除确认提示框
    lineDelete(record) {
      this.delFieldObj = record
      this.$Reconfirm({
        title: '删除字段',
        content: `确定要删除“${this.delFieldObj.fieldName}”数据？`
      }).then(async() => {
        const res = await postDeleteFieldConfig({ fieldId: this.delFieldObj.fieldId })
        if (res.code === 100) {
          this.$message.success('删除成功')
          this.initData()
        }
      }).catch(() => {
        console.log('no')
      })
    },
    // 预览登记表
    preview() {
      this.$refs.registrationCode.show()
    }
  }
}
</script>

<style lang="less" scoped>
.user-info-p {
  padding: 20px 20px 0;
  .user-info-header {
    margin-bottom: 10px;
    .flex-between-center();
    .user-info-header-left {
      .user-info-header-left-t {
        font-weight: bold;
        .font-size(16px);
        color: @sc-grey-100;
      }
      .user-info-header-left-b {
        margin-top: 7px;
        .font-size(14px);
        color: @sc-grey-60;
      }
    }
    .user-info-header-right {
      /deep/.link{
        color: @sc-grey-80;
        margin-right: 12px;
        .iconpark-icon{
          color: @sc-grey-80;
        }
        &:hover{
          color: @sc-primary-100;
          .iconpark-icon{
            color: @sc-primary-100;
          }
        }
      }
    }
  }
  .ant-collapse {
    border: none;
    background-color: #fff;
    .ant-collapse-item {
      border-bottom: none;
      margin-bottom: 6px;
      /deep/.ant-collapse-header {
        background-color: #fff;
        padding: 10px 10px 10px 20px;
        font-size: 14px;
        .ant-collapse-arrow {
          left: 6px;
          color: @sc-primary-100;
        }
      }
      /deep/.ant-collapse-content {
        border-top: none;
        .ant-collapse-content-box {
          padding: 0;
        }
      }
    }
  }
  .ant-badge {
    margin-right: 30px;
    /deep/.ant-badge-status-dot {
      width: 8px;
      height: 8px;
    }
  }
}
.more-handle-btn {
  display: flex;
  flex-direction: column;
  .ant-btn {
    height: 25px;
  }
}
.more-handle {
  font-weight: 700;
  font-size: 20px;
  vertical-align: baseline;
  cursor: pointer;
  color: @sc-primary-100;
  margin-left: 5px;
}
.operation-btn-box{
  button{
    padding-left:0;
    padding-right:16px;
  }
}
</style>
