<template>
  <div class="archives-detail">
    <div class="archives-detail-toolbar">
      <div class="archives-detail-back">
        <a-button prefix="left" @click="goBack">返回</a-button>
      </div>
    </div>
    <UserInfo
      :user-id="userId"
      :status-type="statusType"
      :page-type="pageType"
      :on-job-id="onJobId"
      :archives-perfect-id="archivesPerfectId"
      :anchor-height="'calc(100vh - 305px)'"
      @changeTab="changeTab"
    />
  </div>
</template>

<script>
import UserInfo from './detail/index.vue'
export default {
  components: {
    UserInfo
  },
  data() {
    return {
      userId: '',
      statusType: '',
      tabType: 'personal',
      pageType: '',
      archivesPerfectId: '',
      onJobId: ''
    }
  },
  created() {
    this.userId = String(this.$route.query.staffId)
    this.statusType = String(this.$route.query.statusType)
    this.pageType = String(this.$route.query.pageType)
    this.archivesPerfectId = String(this.$route.query.archivesPerfectId)
    this.onJobId = String(this.$route.query.onJobId)
  },
  methods: {
    goBack() {
      this.$router.replace(decodeURIComponent(this.$route.query.path))
    },
    changeTab(val) {
      this.tabType = val
    }
  }
}
</script>

<style lang="less" scoped>
.archives-detail {
  height: 100%;
  background: #fff;
  .archives-detail-toolbar {
    padding: 20px 20px 0;
    .flex-between-center();
    .archives-detail-back {
      /deep/.ant-btn .prefix{
        color: @sc-grey-100;
      }
    }
  }
}
</style>
