<template>
  <div class="OtherRules">
    <!-- 发放月份 -->
    <a-form-model-item v-if="form.specialMonth || typeNo.slice(0,1) === 'B'" label="发放月份" style="display:flex">
      {{ form.name }}
      <a-radio-group v-model="form.isSpecialMonth" @change="monthChange">
        <a-radio :value="0">全部月份</a-radio>
        <a-radio :value="1">部分月份</a-radio>
      </a-radio-group>
      <div>
        <a-select
          v-if="form.isSpecialMonth"
          v-model="form.specialMonthList"
          mode="tags"
          placeholder="请选择月份，多选"
          class="line-input"
        >
          <a-select-option v-for="month in monthList" :key="month.value" :value="month.value">
            {{ month.label }}
          </a-select-option>
        </a-select>
      </div>
      <!-- 岗位内推选择发放月份 暂不维护 -->
      <!-- <a-select
        v-else
        placeholder="请选择"
        class="line-input"
      >
        <a-select-option v-for="type in monthTypeList" :key="type.value" :value="type.value">
          {{ type.label }}
        </a-select-option>
      </a-select> -->
    </a-form-model-item>
    <!-- 折算方式 -->
    <a-form-model-item v-if="form.isAttendScale||typeNo.slice(0,1) === 'B'" label="折算方式">
      <DictionariesInput v-model="form.attendScaleMode" class="line-input" parameter="attendscalemode" placeholder="请选择折算方式" />
    </a-form-model-item>
    <!-- 扣减规则 -->
    <a-form-model-item v-if="form.deductRule&&form.deductRuleList&&form.deductRuleList.length>0" label="扣发规则">
      <p>设置后，满足任意条件的员工将不享受对应的津贴补贴</p>
      <a-form-model-item v-if="form.deductRuleList.includes('staff_status')" label="员工状态">
        <a-checkbox-group v-model="form.deductRulePara.staff_status" :options="staffStatusList" />
      </a-form-model-item>
      <a-form-model-item v-if="form.deductRuleList.includes('inoutmonth')" label="入离职状态">
        <a-checkbox-group v-model="form.deductRulePara.inoutmonth" :options="inoutmonthList" />
      </a-form-model-item>
      <a-form-model-item v-if="form.deductRuleList.includes('engage_mode')" label="聘用形式">
        <a-checkbox-group v-model="form.deductRulePara.engage_mode" :options="engageModeList" />
      </a-form-model-item>
      <a-form-model-item v-if="form.deductRuleList.includes('absencetype')" label="考勤异常">
        <a-checkbox-group v-model="form.deductRulePara.absencetype" :options="absenceTypeList" />
      </a-form-model-item>
      <a-form-model-item v-if="form.deductRuleList.includes('vacation_type')" label="请假类型">
        <a-checkbox-group v-model="form.deductRulePara.vacation_type" :options="vacationTypeList" />
      </a-form-model-item>
    </a-form-model-item>
  </div>
</template>

<script>
import { getTanantCodeBatch } from '@/services/dropDownInput/index.js'
export default {
  name: 'OtherRules',
  components: {
    DictionariesInput: () => import('@/components/CbDropDownInput/dictionariesInput/index')
  },
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    typeNo: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      // 折算方式数据
      staffStatusList: [],
      inoutmonthList: [],
      engageModeList: [],
      absenceTypeList: [],
      vacationTypeList: [],
      monthList: [
        { label: '1月', value: '01' },
        { label: '2月', value: '02' },
        { label: '3月', value: '03' },
        { label: '4月', value: '04' },
        { label: '5月', value: '05' },
        { label: '6月', value: '06' },
        { label: '7月', value: '07' },
        { label: '8月', value: '08' },
        { label: '9月', value: '09' },
        { label: '10月', value: '10' },
        { label: '11月', value: '11' },
        { label: '12月', value: '12' }
      ],
      // 岗位内推月份选择类型 暂不维护
      monthTypeList: [
        { label: '被内推人员入职当月', value: '1' },
        { label: '被内推人员转正当月', value: '2' },
        { label: '固定月份', value: '3' }
      ]
    }
  },
  methods: {
    // 获取折算方式字典项
    async initWithHoldData() {
      this.$nextTick(async() => {
        this.form.deductRuleList = this.form.deductRule?.split(',')
        const res = await getTanantCodeBatch(this.form.deductRuleList)
        if (res.code === 100) {
          res.data.forEach(item => {
            item.baseCodes = item.baseCodes.map((v) => { return { label: v.content, value: v.codeKey } })
            switch (item.codeName) {
              case 'staff_status':
                console.log(this.form.deductRuleList)
                if (this.form.deductRuleList?.includes('staff_status')) {
                  this.staffStatusList = item.baseCodes
                }
                break
              case 'inoutmonth':
                if (this.form.deductRuleList?.includes('inoutmonth')) {
                  this.inoutmonthList = item.baseCodes
                }
                break
              case 'engage_mode':
                if (this.form.deductRuleList?.includes('engage_mode')) {
                  this.engageModeList = item.baseCodes
                }
                break
              case 'absencetype':
                if (this.form.deductRuleList?.includes('absencetype')) {
                  this.absenceTypeList = item.baseCodes
                }
                break
              case 'vacation_type':
                if (this.form.deductRuleList?.includes('vacation_type')) {
                  this.vacationTypeList = item.baseCodes
                }
                break
              default:
                break
            }
          })
        }
        this.$forceUpdate()
      })
    },
    monthChange({ target: { value }}) {
      if (!value) {
        this.form.specialMonthList = []
      }
    }
  }
}
</script>
<style lang="less" scoped>
.line-input{
  width: 320px;
  display: inline-block;
}
/deep/.ant-form-item{
  display:flex;
}
/deep/.ant-checkbox-group-item{
  margin-top: 10px;
}
</style>
