<template>
  <div class="ExcelImport">
    <BatchImport
      ref="batchimport"
      title="批量导入员工"
      import-template="importUser"
      :model-id="'1534340833881665537'"
      :show-update-list="true"
      :multiple="false"
      :file-list="fileList"
      :file-size="20"
      :data-list="dataList"
      :show-three="false"
      @goBack="goBack"
      @getStaffStatistics="getStaffStatistics"
    />

  </div>
</template>

<script>
import BatchImport from '@/components/CbBatchImport/newImport'
export default {
  name: 'ExcelImport',
  components: {
    BatchImport
  },
  data() {
    return {
      dataType: {
        sta: null,
        message: '请先导入符合规则的文件'
      },
      fileList: [],
      dataList: []
    }
  },
  methods: {
    // 去设置
    goSetting() {
      this.$router.push({
        path: '/staffIndex/userSetting',
        query: {
          type: '3'
        }
      })
    },
    getStaffStatistics() {
      this.$emit('getStaffStatistics')
    },
    goBack() {
      this.$emit('back')
    }
  }
}
</script>

<style lang="less" scoped>
.ExcelImport{
  padding: 20px;
  &-content{
    font-size: 14px;
  }
  &-bottom{
    position: fixed;
    left: 53%;
    bottom:20px;
  }
}
/deep/ .ant-upload.ant-upload-drag {
  background: #F9F9FA;
  border-radius: 8px;
}
/deep/ .header {
  margin-bottom: 20px;
}
/deep/ .ant-alert-info {
  background: @sc-primary-10;
  border: 1px solid @sc-primary-10;
}
/deep/ .ant-alert-message {
  font-weight: 700;
  color: @sc-grey-100;
}
</style>
