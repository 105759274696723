<template>
  <div class="AddEmployeeInfo">
    <HeaderBack title="添加员工信息" @back="$emit('back')" />
    <div class="AddEmployeeInfo-content">
      <div class="basics-msg">
        <BlockTitle title="基本信息" :is-bold="true" class="msg-title" />
        <div class="msg-content">
          <a-form-model
            ref="addInfo"
            :model="staffAddInfo"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="姓名" prop="staffName">
                  <a-input v-model="staffAddInfo.staffName" placeholder="请输入姓名" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="手机号" prop="phoneNum">
                  <a-input v-model="staffAddInfo.phoneNum" style="width: 30%" placeholder="请输入手机号" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="证件类型" prop="idType">
                  <a-select v-model="staffAddInfo.idType" placeholder="请选择" @select="idTypeSelect">
                    <a-select-option v-for="(item) in idTypeList" :key="item.codeKey" :value="item.codeKey">{{ item.content }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="证件号码" prop="idNum">
                  <a-input v-model="staffAddInfo.idNum" placeholder="请输入证件号" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="性别">
                  <a-select v-model="staffAddInfo.sex" placeholder="请选择">
                    <a-select-option v-for="(item) in sexList" :key="item.codeKey" :value="item.codeKey">{{ item.content }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="出生日期">
                  <a-date-picker v-model="staffAddInfo.birthdate" value-format="YYYY-MM-DD" placeholder="选择日期" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="籍贯" prop="nativePlace">
                  <CbSelectArea ref="selectArea" v-model="staffAddInfo.nativePlace" :area-show="true" container-width="362px" @getChangeValue="getChangeValue" @change="getStrandValue" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="居住地址" name="address" prop="address">
                  <a-input v-model="staffAddInfo.address" placeholder="1-50个字符" />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
      </div>
      <div class="onTheJob-msg">
        <BlockTitle title="在职信息" :is-bold="true" class="msg-title" />
        <div class="msg-content">
          <a-form-model
            ref="jobInfo"
            :model="onJobAddInfo"
            :rules="jobRules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="部门">
                  <DepTreeSelect v-model="onJobAddInfo.orgId" :select-width="'362px'" :multiple="false" placeholder="请选择部门" @getValueSelect="orgChange" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="岗位">
                  <a-select v-model="onJobAddInfo.postId" placeholder="请选择" :disabled="!onJobAddInfo.orgId" allow-clear @change="jobChange">
                    <a-select-opt-group v-for="item in postList" :key="item.postTypeId" :label="item.postTypeName">
                      <a-select-option v-for="it in item.postList" :key="it.postId" :value="it.postId">
                        {{ it.postName }}
                      </a-select-option>
                    </a-select-opt-group>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="职务">
                  <a-select v-model="onJobAddInfo.duty" placeholder="请选择" :disabled="!onJobAddInfo.orgId" allow-clear>
                    <a-select-option v-for="it in dutyList" :key="it.dutyId" :value="it.dutyId">
                      {{ it.dutyName }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="职级">
                  <a-select v-model="onJobAddInfo.jobGradeId" placeholder="请选择" :disabled="!onJobAddInfo.postId" allow-clear>
                    <a-select-opt-group v-for="item in rankList" :key="item.jobTypeId" :label="item.jobTypeName">
                      <a-select-option v-for="it in item.jobGradeList" :key="it.jobGradeId" :value="it.jobGradeId">
                        {{ it.jobTypeCode + it.jobGrade }}
                      </a-select-option>
                    </a-select-opt-group>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="合同公司">
                  <a-select v-model="onJobAddInfo.contractCo" placeholder="请选择">
                    <a-select-option v-for="(item) in companyList" :key="item.companyId" :disabled="item.status !== 1" :value="item.companyId">{{ item.companyName }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="汇报上级" name="checkPass">
                  <PerTreeSelect v-model="onJobAddInfo.reportId" placeholder="请选择汇报上级" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="员工状态" prop="staffStatus">
                  <a-select v-model="onJobAddInfo.staffStatus" placeholder="请选择" @change="Statuschange">
                    <a-select-option v-for="(item) in staffStatusList" :key="item.codeKey" :value="item.codeKey">{{ item.content }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="聘用形式" prop="engageMode">
                  <a-select v-model="onJobAddInfo.engageMode" placeholder="请选择">
                    <a-select-option v-for="(item) in engageModeList" :key="item.codeKey" :value="item.codeKey">{{ item.content }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="入职日期" prop="entryDate">
                  <a-date-picker v-model="onJobAddInfo.entryDate" value-format="YYYY-MM-DD" placeholder="选择日期" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="工号" prop="staffNum">
                  <div class="staffnuminput">
                    <a-input v-model="onJobAddInfo.staffNum" size="default" placeholder="选择聘用形式后生成工号" />
                    <a-button :disabled="configDisable ? true : disabledGenerate ? true : false" @click="generateJobid">
                      生成工号
                    </a-button>
                  </div>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="工作地点" prop="workplaceId">
                  <a-select v-model="onJobAddInfo.workplaceId" placeholder="请选择">
                    <a-select-option v-for="(item) in addressList" :key="item.addressId" :value="item.addressId">{{ item.addressName }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="转正日期" prop="formalDate">
                  <a-date-picker v-model="onJobAddInfo.formalDate" value-format="YYYY-MM-DD" placeholder="选择日期" />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
      </div>
    </div>
    <a-row class="AddEmployeeInfo-bottom" type="flex" justify="center">
      <a-button type="primary" @click="addEmployee">保存</a-button>
      <a-button @click="$emit('back')">取消</a-button>
    </a-row>
  </div>
</template>

<script>
import mixins from '@/components/CbPerDepSelection/index.js'
import { getPostDropDownList } from '@/services/organization/post.js'
import { getRankGroupDropDownList } from '@/services/organization/rank.js'
import { createStaffAndOnJob } from '@/services/user/roster.js'
import { getTanantCodeBatch } from '@/services/dropDownInput/index.js'
import { contractCompany, workAddress } from '@/services/dictionary/index.js'
import HeaderBack from '@/components/headerBack'
import { createStaffNum } from '@/services/user/roster.js'
import { getStaffNumConfig } from '@/services/user/setting.js'
import CbSelectArea from '@/components/CbSelectArea/index'
import { phoneReg, idCardReg } from '@/utils/reg.js'
import { getSelectDutyList } from '@/services/organization/duty.js'
export default {
  name: 'AddEmployeeInfo',
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index'),
    HeaderBack,
    CbSelectArea
  },
  mixins: [mixins],
  data() {
    return {
      configDisable: true, // 后台获取是否可以自动生成工号
      // isEdit: false, // 工号是否可以编辑
      engage: '',
      disabledGenerate: true,
      labelCol: { lg: 4, xl: 4, xxl: 4 },
      wrapperCol: { lg: 12, xl: 13, xxl: 14 },
      staffAddInfo: {
        idNum: ''
      },
      onJobAddInfo: {
        postId: undefined,
        jobGradeId: undefined,
        staffNum: undefined
      },
      politicalList: [],
      idTypeList: [],
      sexList: [],
      staffStatusList: [],
      postTypeList: [],
      engageModeList: [],
      dutyList: [], // 职务列表
      companyList: [],
      addressList: [],
      placeList: [],
      postList: [],
      jobList: [],
      rankList: [],
      rules: {
        staffName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, message: '姓名至少两个文字组成', trigger: 'blur' }
        ],
        phoneNum: [{ required: true, pattern: phoneReg, message: '请输入正确的手机号', trigger: 'blur' }],
        idNum: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback('请输入证件号')
                return
              }
              if (this.staffAddInfo.idType === '1' && !idCardReg.test(value)) {
                callback('请输入正确的身份证号码')
                return
              }
              callback()
              this.judgeIdNumber(value)
            },
            trigger: 'blur'
          }
        ],
        idType: [{ required: true, message: '请选择证件类型', trigger: 'blur' }],
        nativePlace: [{ required: false,
          validator: (rule, value, callback) => {
            if (!value) {
              callback('请将籍贯信息填写完整')
              return
            }
            callback()
          },
          trigger: 'blur' }],
        address: [{
          required: false,
          validator: (rule, value, callback) => {
            if (typeof value === 'undefined') {
              callback()
            }
            if (value.length > 50) {
              callback('居住地址最多50字')
              return
            }
            callback()
          },
          trigger: 'change'
        }]
      },
      jobRules: {
        staffStatus: [{ required: true, message: '请选择员工状态', trigger: 'change' }],
        entryDate: [{ required: true, message: '请选择入职日期', trigger: 'change' }],
        workplaceId: [{ required: true, message: '请选择工作地点', trigger: 'change' }],
        engageMode: [{ required: true, message: '请选择聘用形式', trigger: 'change' }],
        formalDate: [{ required: false, message: '请选择转正日期', trigger: 'change' }]
      },
      areaCode: {},
      areaRule: []
    }
  },
  watch: {
    'onJobAddInfo.engageMode'(newVal) {
      this.engage = newVal
      this.onJobAddInfo.staffNum = undefined
      if (newVal !== '') {
        this.disabledGenerate = false
      } else if (typeof newVal === 'undefined') {
        this.disabledGenerate = true
      }
    },
    'onJobAddInfo.staffStatus'(newVal) {
      this.jobRules.formalDate[0].required = newVal === '1'
    },
    idTypeList(val) {
      if (val.length > 0) {
        this.forFun(val, '1', 'staffAddInfo', 'idType')
      }
    },
    staffStatusList(val) {
      if (val.length > 0) {
        this.forFun(val, '1', 'onJobAddInfo', 'staffStatus')
      }
    },
    engageModeList(val) {
      if (val.length > 0) {
        this.forFun(val, '1', 'onJobAddInfo', 'engageMode')
      }
    }
  },
  created() {
    this.getSelectData()
    this.getEditConfig()
    this.getSelectDutyList()
  },
  methods: {
    judgeIdNumber(newVal) {
      const value = newVal
      if (this.staffAddInfo.idType === '1' && value.length === 18) {
        const topSix = value.slice(0, 6)
        const sex = value.slice(16, 17)
        this.$set(this.staffAddInfo, 'birthdate', value.slice(6, 10) + '-' + value.slice(10, 12) + '-' + value.slice(12, 14))
        this.areaCode = {
          province: topSix.slice(0, 2) + '0000',
          city: topSix.slice(0, 4) + '00',
          county: topSix
        }
        this.$refs.selectArea.dataEcho(this.areaCode)
        this.$set(this.staffAddInfo, 'sex', sex % 2 === 0 ? '1' : '0')
      }
    },
    Statuschange(val) {
      this.onJobAddInfo.staffStatus = val
    },
    async getSelectData() {
      const data = ['political', 'id_type', 'sex', 'staff_status', 'post_type', 'engage_mode']
      const res = await getTanantCodeBatch(data)
      if (res.code === 100) {
        res.data.forEach(item => {
          switch (item.codeName) {
            case 'political':
              this.politicalList = item.baseCodes
              break
            case 'id_type':
              this.idTypeList = item.baseCodes
              break
            case 'sex':
              this.sexList = item.baseCodes
              break
            case 'staff_status':
              this.staffStatusList = item.baseCodes.filter((i, index) => {
                return i.codeKey !== '3'
              })
              // 只有一个选项默认选中
              if (this.staffStatusList.length === 1) {
                this.$set(this.onJobAddInfo, 'staffStatus', this.staffStatusList[0].codeKey)
              }
              break
            case 'post_type':
              this.postTypeList = item.baseCodes
              break
            case 'engage_mode':
              this.engageModeList = item.baseCodes
              if (this.engageModeList.length === 1) {
                this.$set(this.onJobAddInfo, 'engageMode', this.engageModeList[0].codeKey)
              }
              break
            default:
              break
          }
        })
      }
      contractCompany().then((company) => {
        let num = 0
        let index = 0
        this.companyList = company.data
        this.companyList.forEach((item, i) => {
          if (item.status === 1) {
            num = ++num
            index = i
          }
        })
        // 如果列表数据只有一条且不为禁用则默认选中这条数据
        if (this.companyList.length === 1 && num === 1) {
          this.$set(this.onJobAddInfo, 'contractCo', this.companyList[0].companyId)
        }
        // 如果列表数据为多条且只有一条数据不为禁用则默认选中这条数据
        if (this.companyList.length > 1 && num === 1) {
          this.$set(this.onJobAddInfo, 'contractCo', this.companyList[index].companyId)
        }
      })
      workAddress().then((address) => {
        this.addressList = address.data
        if (this.addressList.length === 1) {
          this.$set(this.onJobAddInfo, 'workplaceId', this.addressList[0].addressId)
        }
      })
    },
    // 请求职务下拉列表
    async getSelectDutyList() {
      const res = await getSelectDutyList()
      this.dutyList = res.data
    },
    forFun(arr, condition, obj, value) {
      arr.forEach((item) => {
        if (item.codeKey === condition) {
          this.$set(this[obj], [value], item.codeKey)
        }
      })
    },
    addEmployee() {
      this.$refs.addInfo.validate(async addValid => {
        this.$refs.jobInfo.validate(async jobValid => {
          if (addValid && jobValid) {
            const data = {
              staffAddInfo: this.staffAddInfo,
              onJobAddInfo: { ...this.onJobAddInfo }
            }
            if (data.onJobAddInfo.orgId instanceof Object) {
              data.onJobAddInfo.orgId = data.onJobAddInfo.orgId[0].value
            }
            const res = await createStaffAndOnJob(data)
            if (res.code === 100) {
              this.$message.success('添加成功！')
              this.$emit('getStaffStatistics')
              this.$emit('back')
            }
            return
          }
          this.$message.error('添加信息不完整！')
        })
      })
    },
    async orgChange(orgList) {
      this.postList = []
      this.rankList = []
      this.onJobAddInfo.postId = undefined
      this.onJobAddInfo.jobGradeId = undefined
      if (orgList[0].value) {
        const res = await getPostDropDownList({ orgId: orgList[0].value })
        this.postList = res.data || []
      }
    },
    async jobChange(postId) {
      this.rankList = []
      this.onJobAddInfo.jobGradeId = undefined
      if (postId) {
        const res = await getRankGroupDropDownList({ postId: postId })
        this.rankList = res.data || []
      }
    },
    async generateJobid() {
      const res = await createStaffNum({ entryType: '1', idNum: '', engage: this.engage })
      this.onJobAddInfo.staffNum = res.data
    },
    async getEditConfig() {
      const res = await getStaffNumConfig()
      if (res.code === 100) {
        this.configDisable = res.data.staffNumAuto === 0
      }
    },
    idTypeSelect(value, e) {
      this.staffAddInfo.idNum = ''
    },
    getChangeValue() {

    },
    getStrandValue(a, b) {
      this.areaRule = []
      this.areaRule = b
      if (typeof this.areaRule[0] !== 'undefined') {
        this.rules.nativePlace[0].required = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .AddEmployeeInfo{
    padding:20px 20px 100px 20px;
    overflow: auto;
    height: 100%;
    min-width: 1300px;
    &-content{
      padding:0 146px;
      .msg-title {
       margin-bottom: 20px;
      }
      .msg-content {
        padding: 0 0 0 40px;
        .ant-form-item{
          margin-bottom: 17px;
        }
        .ant-input,.ant-select ,.ant-calendar-picker {
          width: 362px !important;
        }
        /deep/.ant-form-explain {
          position:absolute;
          margin-bottom: -5px;
        }
      }
      .native-place{
        /deep/.ant-select-selection {
          width: 176px;
        }
      }
    }
    &-bottom{
      margin-top: 26px;
      .ant-btn {
        opacity: 1;
        margin-right: 12px;
      }
    }
  }
.staffnuminput{
  display: inline-flex;
  width: 362px;
  /deep/ .ant-input{
    width: 260px !important;
  }
  /deep/ .ant-btn{
    border: none !important;
  }
}
// @media screen and (max-width: 1280px) {
//   .AddEmployeeInfo-content{
//     padding:0 46px;
//   }
// }
</style>
