var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('appForm',{ref:"appForm",attrs:{"to-list":_vm.toList,"screen-roster-list":_vm.screenRosterList,"width":"400"},on:{"getSearchCallback":_vm.getSearchCallback,"screenRosterParams":_vm.screenRosterParams,"exportFileList":_vm.exportFileList}}),(_vm.keyval === '2')?_c('Alert',{attrs:{"show-icon":true,"banner":true},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',[_vm._v("提示：最近转正列表仅显示近90天内已确认转正员工")])]},proxy:true}],null,false,3480637814)}):_vm._e(),_c('custom-table',{attrs:{"columns":_vm.columns,"table-ref":".main-content","tabs-height":36,"data-source":_vm.dataSource,"row-key":"onJobId","pagination":{
      current: _vm.pager.pageNum,
      pageSize: _vm.pager.pageSize,
      total: _vm.total,
      showTotal: function (total) { return ("共 " + total + " 条数据"); },
      showQuickJumper: true,
      showSizeChanger: true
    }},on:{"change":_vm.loadDataList},scopedSlots:_vm._u([{key:"index",fn:function(ref){
    var scope = ref.scope;
return [_c('span',[_vm._v(_vm._s(scope.index + 1))])]}},{key:"name",fn:function(ref){
    var scope = ref.scope;
return [_c('CbTableAvater',{attrs:{"table-avater":scope.record.avatar,"on-job-id":scope.record.onJobId,"staff-id":scope.record.staffId,"table-name":scope.record.name}})]}},{key:"engageMode",fn:function(ref){
    var scope = ref.scope;
return [_c('span',[_vm._v(_vm._s(scope.engageMode === 0 ? '正式' : '非正式'))])]}},{key:"applyStatus",fn:function(ref){
    var scope = ref.scope;
return [_c('span',[(scope.record.applyStatus)?_c('StatusTag',{attrs:{"type":scope.record.applyStatus,"type-list":_vm.applyStatusList},on:{"click":function($event){return _vm.getCbApproveSteps(scope.record.flowId, scope.record.applyStatus)}}}):_vm._e()],1)]}},{key:"operation",fn:function(ref){
    var scope = ref.scope;
return (_vm.keyval === '1')?[(scope.record.applyStatus === 2)?_c('div',[_vm._v("不予转正")]):_c('div',{staticClass:"different"},[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.handleConfirm(scope)}}},[_vm._v(" 办理转正 ")]),_c('a-popover',{attrs:{"placement":"bottom","trigger":"hover"}},[_c('template',{slot:"content"},[_c('div',{staticClass:"more-handle-btn"},[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.trimTime(scope)}}},[_vm._v(" 调整转正日期 ")]),_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.handleGiveUp(scope)}}},[_vm._v(" 不予转正 ")])],1)]),_c('div',{staticClass:"more-handle"},[_vm._v("···")])],2)],1)]:undefined}}],null,true)}),_c('confirmForm',{ref:"confirmForm",on:{"getFetchDataApiFn":_vm.getFetchDataApiFn}}),_c('trimTimeForm',{ref:"trimTimeForm",on:{"getFetchDataApiFn":_vm.getFetchDataApiFn}}),_c('giveUpForm',{ref:"giveUpForm",on:{"getFetchDataApiFn":_vm.getFetchDataApiFn}}),_c('CbApproveSteps',{attrs:{"flow-id":_vm.flowId,"apply-status":_vm.applyStatus,"apply-status-list":_vm.applyStatusList},model:{value:(_vm.stepsVisible),callback:function ($$v) {_vm.stepsVisible=$$v},expression:"stepsVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }