<template>
  <div>
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      table-ref=".table"
      row-key="jobGradeId"
      :pagination="false"
      :loading="loading"
      :selection-mode="selectionMode"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        columnWidth: 25,
        onChange: onSelectChange
      }"
      @change="loadDataList"
    >
      <!-- 占比头部自定义插槽 -->
      <span slot="proportionTitle">
        占比
        <a-tooltip placement="top">
          <template slot="title">
            <span>当前职级人数占当前职类总人数的比例</span>
          </template>
          <span>
            <a-icon type="question-circle" />
            (%)
          </span>
        </a-tooltip>
      </span>
      <!-- 开关自定义 -->
      <span slot="switchStatus" slot-scope="{text, scope}">
        <div>
          <a-switch :checked="scope.record.switchStatus === 1 ? true : false " size="small" @change="onChange(...arguments ,scope)" />
        </div>
      </span>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{text, scope}">
        <span class="operation-btn-box">
          <a-button type="link" @click="edit(scope.record)">
            编辑
          </a-button>
          <a-button type="link" class="delete" @click.once="deleteRank(scope.record)">
            删除
          </a-button>
        </span>
        <!-- <a-popover placement="bottom" trigger="hover" destroy-tooltip-on-hide>
          <template slot="content">
            <div class="more-handle-btn">
              <a-button type="link" @click="edit(scope.record)">
                编辑
              </a-button>
              <a-button type="link" class="delete" @click.once="deleteRank(scope.record)">
                删除
              </a-button>
            </div>
          </template>
          <div class="more-handle">
            ···
          </div>
        </a-popover> -->
        <!-- <a-button type="link" @click="edit(scope.record)">
          编辑
        </a-button>
        <a-button class="delBtn" type="link" @click="deleteRank(scope.record)">
          删除
        </a-button> -->
      </template>
    </custom-table>
    <Edit ref="edit" @complate="serchGetData" />
    <CreateRank ref="CreateRank" />
  </div>
</template>

<script>
import { getRankList, changeRankStatus, deleteRank } from '@/services/organization/rank.js'
import mixins from '@/components/CustomTable/index.js'
import Edit from './operation/edit'
import CreateRank from './operation/create-rank'
export default {
  name: 'RankTabel',
  components: {
    Edit,
    CreateRank
  },
  mixins: [mixins],
  props: {
    jobTypeInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      confirmLoading: false,
      columns: [
        {
          title: '级别',
          dataIndex: 'jobGrade',
          key: 'jobGrade',
          width: '80px'
        },
        {
          title: '人数',
          dataIndex: 'personCount',
          key: 'personCount',
          width: '80px'
        },
        {
          dataIndex: 'proportion',
          slots: { title: 'proportionTitle' },
          key: 'proportion',
          width: '120px'
        },
        {
          title: '通用能力',
          dataIndex: 'commonSkill',
          key: 'commonSkill',
          width: '300px'
        },
        {
          title: '专业能力',
          dataIndex: 'professinalSkill',
          key: 'professinalSkill',
          width: '300px'
        },
        {
          title: '核心价值',
          dataIndex: 'coreValue',
          key: 'coreValue',
          width: '300px'
        },
        {
          title: '状态',
          dataIndex: 'switchStatus',
          key: 'switchStatus',
          scopedSlots: { customRender: 'switchStatus' },
          width: '120px'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '150px'
        }
      ],
      selectedRowKeys: [], // 当前选择列集合
      dataSource: [], // 数据
      queryForm: this.$form.createForm(this, { name: 'horizontal_login' }), // 查询的form表单
      selectionMode: false
    }
  },
  methods: {
    async fetchDataApiFn() {
      const { jobTypeId } = this.jobTypeInfo
      if (!jobTypeId) return
      const res = await getRankList({ jobTypeId: jobTypeId || '' })
      this.dataSource = res.data
    },
    // Only show error after a field is touched.
    userNameError() {
      const { getFieldError, isFieldTouched } = this.queryForm
      return isFieldTouched('userName') && getFieldError('userName')
    },
    // Only show error after a field is touched.
    passwordError() {
      const { getFieldError, isFieldTouched } = this.queryForm
      return isFieldTouched('password') && getFieldError('password')
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.$emit('getSelectArr', selectedRowKeys)
    },
    clearArr() {
      this.selectedRowKeys = []
    },
    async onChange(switchStatus, e, info) {
      const res = await changeRankStatus({ jobGradeId: info.record.jobGradeId, switchStatus: switchStatus ? 1 : 0 })
      if (res.code === 100) {
        this.fetchDataApiFn()
        this.$message.success(`职级已${switchStatus ? '启用' : '停用'}!`)
      }
    },
    edit(info) {
      this.$refs.edit.show(info)
    },
    async deleteRank(record) {
      this.$Reconfirm({
        title: '删除',
        content: `确定要删除职级分类${record.jobTypeName}下的${record.jobTypeCode + record.jobGrade}吗?`
      }).then(async() => {
        const res = await deleteRank({ jobGradeId: record.jobGradeId })
        if (res.code === 100) {
          this.selectedRowKeys.forEach((item, index) => {
            if (item === record.jobGradeId) {
              this.selectedRowKeys.splice(index, 1)
            }
          })
          this.fetchDataApiFn()
          this.$message.success('删除成功')
        }
      }).catch(() => {
        console.log('取消')
      })
    }
  }
}
</script>

<style scoped lang="less">
.more-handle-btn {
  display: flex;
  flex-direction: column;
  .ant-btn {
    height: 25px;
  }
}
.more-handle {
  font-weight: 700;
  line-height: 44px;
  font-size: 20px;
  margin-left: 6px;
  color:  @sc-primary-100;
  vertical-align: baseline;
  cursor: pointer;
}
.operation-btn-box{
  display:flex;
  button{
    padding-left:0;
    padding-right:16px;
  }
}
</style>
