<template>
  <Modal
    :title="title"
    :visible="visible"
    size="large"
    ok-text="下一步"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="box">
      <div class="table_class">
        <!--    搜索结果   -->
        <div class="select_content">
          <div class="title">待选人员<span class="title_tip">根据人员姓名搜索显示、点击头像可选中</span></div>
          <!--    搜索    -->
          <div class="table_select">
            <a-input-search v-model.trim="value" placeholder="请输入人员姓名" enter-button @search="onSearch" />
          </div>
          <div class="select_content_avatar">
            <div v-if="contentArr && contentArr.length > 0" class="select_content_flex">
              <div v-for="(item, index) in contentArr" :key="index" class="avatar" @click="selectUse(item)">
                <CbAvatar
                  :on-job-id="item.onJobId"
                  :avater-name="item.staffName"
                  size="30"
                />
              </div>
            </div>
            <div v-else>
              <CbNoData width="75" />
            </div>
          </div>
        </div>
      </div>
      <!--   选择结果   -->
      <div v-if="resultVisible" class="select_class">
        <div class="title">已选人员<span class="title_tip">点击头像可取消选中</span></div>
        <!--    选择结果   -->
        <div class="select_content_avatar">
          <div v-if="selectArr && selectArr.length > 0" class="select_content_flex">
            <div v-for="(item, index) in selectArr" :key="index" class="avatar" @click="selectUseClose(item)">
              <CbAvatar
                :on-job-id="item.onJobId"
                :avater-name="item.staffName"
                size="30"
              />
            </div>
          </div>
          <div v-else>
            <CbNoData width="75" />
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
/*
* title  弹窗标题
* queryType 数据请求类型（档案列表类型（1：待投保列表 2：正常在缴列表 3：待停保列表 4：停保列表））
* @batchCallBack  确认回调方法
* */
import {
  getArchivesListApi
} from '@/services/insurance/archives'
export default {
  name: 'BatchModal',
  components: {
    CbAvatar: () => import('@/components/CbAvater/theAvater.vue'),
    CbNoData: () => import('@/components/CbNoData/index'),
    Modal: () => import('@/components/CbModal/index')
  },
  props: {
    title: {
      type: String,
      default: '批量人员选择'
    },
    queryType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      resultVisible: false,
      value: '',
      contentArr: [],
      selectArr: []
    }
  },
  watch: {
    selectArr(val) {
      if (val && val.length > 0) {
        this.resultVisible = true
      } else {
        this.resultVisible = false
      }
    }
  },
  methods: {
    // 开启弹窗
    show() {
      this.visible = true
      this.$nextTick(() => {
        this.getArchivesListApi()
      })
    },
    // 搜索
    onSearch(e) {
      // if (!e) return
      this.getArchivesListApi(e)
    },
    async getArchivesListApi(e) {
      const res = await getArchivesListApi(
        {
          keyWord: e,
          queryType: this.queryType,
          pageNum: 1,
          pageSize: 100
        }
      )
      this.contentArr = res.data.records ? res.data.records : []
    },
    // 选择人员
    selectUse(e) {
      const arr = this.selectArr.filter(item => item.onJobId === e.onJobId)
      if (arr && arr.length > 0) {
        this.$message.warning('同一人员不可重复选择！')
      } else {
        this.selectArr.push(e)
      }
    },
    // 取消选择
    selectUseClose(e) {
      this.selectArr = this.selectArr.filter(item => item.onJobId !== e.onJobId)
    },
    // 确认
    handleOk() {
      if (this.selectArr && this.selectArr.length > 0) {
        this.$emit('batchCallBack', this.selectArr)
        this.handleCancel()
      } else {
        this.$message.warning('请选择人员！')
      }
    },
    // 关闭弹窗
    handleCancel() {
      this.value = ''
      this.contentArr = []
      this.selectArr = []
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
  .box {
    .table_class {
      width: 100%;
      height: 300px;
      .select_content {
        padding: 10px 20px;
        box-sizing: border-box;
        .table_select {
          padding: 0 20px;
          box-sizing: border-box;
          margin: 10px 0;
        }
      }
    }
    .select_class {
      padding: 10px 20px;
      box-sizing: border-box;
      width: 100%;
      height: 300px;
      border-top: 1px @sc-greyBg-100 solid;
    }
  }
  .select_content_avatar {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 200px;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: scroll;
    .select_content_flex {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      .avatar {
        cursor: pointer;
        width: 90px;
        margin:0 21px 20px 0;
      }
    }
  }
  .title {
    font-size: 16px;
    margin-bottom: 15px;
    .title_tip {
      font-size: 12px;
      margin-left: 15px;
    }
  }
</style>
