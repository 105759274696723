<!--
 * @Date: 2022-04-27 14:36:05
 * @descriotion: 排班日历
-->
<template>
  <div class="setting-container">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="排班日历名称" prop="shiftSchedulingId">
        <a-select v-model="form.shiftSchedulingId" placeholder="请选择排班日历" @change="handleChange">
          <a-select-option v-for="item in shiftList" :key="item.shiftSchedulingId" :value="item.shiftSchedulingId">
            {{ item.shiftSchedulingName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <div v-show="form.shiftSchedulingId">
        <a-form-model-item label="排班日历类型">
          {{ detail.shiftCycleType === '1'?'按周排班':detail.shiftCycleType === '2'?'按月排班': `无固定休息日（${detail.cycleDays}天循环排班）` }}
        </a-form-model-item>
        <a-form-model-item label="出勤日">
          <p v-html="detail.workCycleSequenceStr" />
        </a-form-model-item>
        <a-form-model-item label="同步法定节假日">
          {{ detail.syncHolidayFlag?'是':'否' }}
        </a-form-model-item>
        <a-form-model-item label="特殊日期列表">
          <div v-if="detail.specialDays&&detail.specialDays.length>0">
            <p v-for="(item,index) in detail.specialDays" :key="index">
              {{ moment(item.specialDate).format('YYYY-MM-DD') }}
            </p>
          </div>
          <div v-else>
            无
          </div>
        </a-form-model-item>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import { shiftSchedulingListApi, shiftSchedulingDeatailApi } from '@/services/attendance/settingset.js'
import { shift_cycle_type, shift_scheduling_type } from '@/pages/attendance/dict.js'
import moment from 'moment'
export default {
  name: 'ScheduleCalendar',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      shift_cycle_type: shift_cycle_type,
      shift_scheduling_type: shift_scheduling_type,
      form: {},
      checkList: [], // 周 月排班日历check
      daysCheckList: [], // 无固定
      rules: {
        shiftSchedulingId: [{
          required: true, message: '请选择排班日历', trigger: 'change'
        }]
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      shiftList: [],
      detail: {}
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        const { shiftSchedulingId } = value
        this.form = { shiftSchedulingId }
        if (shiftSchedulingId) {
          this.getDetail(shiftSchedulingId)
        }
      }
    }
  },
  mounted() {
    this.getShiftListPage()
  },
  methods: {
    moment,
    async getDetail(shiftSchedulingId) {
      const res = await shiftSchedulingDeatailApi({ shiftSchedulingId })
      if (res.code !== 100) return
      this.detail = res.data
    },
    handleChange(val) {
      if (!val) return
      this.getDetail(val)
    },
    async getShiftListPage() {
      const res = await shiftSchedulingListApi()
      this.shiftList = res.data
    },
    next() {
      let flag = false
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          flag = true
          this.$emit('getData', this.form)
        } else {
          this.$message.info('请先完善排班日历')
        }
      })
      return flag
    },
    reset() {
      this.$refs.ruleForm.resetFields()
      this.$emit('resetData', this.form)
    }
  }
}
</script>

<style lang="less" scoped>
.setting-container{
    width:100%;
    padding:20px 43px 34px;
    height:100%;
    overflow: auto;
}
.calendarType{
  width:100%;
  padding:10px 20px;
  background-color: @sc-grey-20;
  border-radius: 4px;
}
.steps-action{
  width:100%;
  // position:absolute;
  // bottom:60px;
  .btns{
    display: flex;
    justify-content: center;
    .seat{
      width:24px;
    }
  }
}
</style>
