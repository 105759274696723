<template>
  <div class="organization-leader">
    <Modal
      :visible="visible"
      :closable="true"
      :size="'normal'"
      :mask-closable="false"
      :title="'编辑组织负责人'"
      :ok-text="'保存'"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        ref="form"
        :model="form"
        v-bind="{
          labelCol: { span: 5 },
          wrapperCol: { span: 19 },
        }"
      >
        <a-form-model-item label="组织负责人" prop="leaderName">
          <PerTreeSelect v-model="form.leaderId" />
        </a-form-model-item>
        <!-- <a-form-model-item label="负责形式" prop="leaderType">
          <a-radio-group v-model="form.leaderType" name="radioGroup">
            <a-radio :value="'1'">
              主管
            </a-radio>
            <a-radio :value="'2'">
              兼管
            </a-radio>
          </a-radio-group>
        </a-form-model-item> -->
      </a-form-model>
    </Modal>
  </div>
</template>

<script>
import mixins from '@/components/CbPerDepSelection/index.js'
import { updateLeader } from '@/services/organization/framework.js'
export default {
  name: 'Organizationleader',
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  mixins: [mixins],
  props: {
    show: {
      type: Boolean,
      default: false,
      require: true
    }
  },
  data() {
    return {
      form: {
        orgId: undefined,
        leaderId: undefined
        // leaderType: '1'
      },
      visible: false
    }
  },
  methods: {
    showModal(val, id, type) {
      this.visible = true
      this.form.orgId = val
      this.form.leaderId = id
      // this.form.leaderType = type || '1'
    },
    handleCancel() {
      const form = {
        orgId: undefined,
        leaderId: undefined
        // leaderType: '1'
      }
      this.$set(this, 'form', form)
      this.$refs.form.resetFields()
      this.visible = false
    },
    // 保存功能
    handleOk(e) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const params = {
            orgId: this.form.orgId,
            leaderId: this.form.leaderId
            // leaderType: this.form.leaderType
          }
          const res = await updateLeader(params)
          if (res.code === 100) {
            this.$message.success('修改负责人成功')
            this.$emit('changeFlag', this.visible)
            this.handleCancel()
          }
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-radio-wrapper {
  color: @sc-grey-80;
}
</style>
