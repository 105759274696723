<!--
 * @Date: 2022-04-25 15:49:36
 * @descriotion: 调班
-->
<template>
  <div class="main-content">
    <div v-if="implicit">
      <a-tabs :default-active-key="keys" @change="callback">
        <a-tab-pane :key="1" tab="本人调班">
          <MyChange v-if="keys === 1" @bulkShift="bulkShift" />
        </a-tab-pane>
        <a-tab-pane :key="2" tab="员工换班">
          <Employee v-if="keys === 2" @bulkShift="bulkShift" />
        </a-tab-pane>
      </a-tabs>
    </div>
    <ExcelImport v-else :title="text" :import-template="key === 1 ? 'change_shift' : 'exchange_shift'" @toggleValue="toggleValue" />
  </div>
</template>

<script>
import Employee from './employeesChange.vue'
import MyChange from './mychange.vue'

export default {
  name: 'Change',
  components: {
    Employee,
    MyChange,
    ExcelImport: () => import('../components/excelImport.vue')
  },
  data() {
    return {
      keys: 1,
      implicit: true,
      key: null
    }
  },
  methods: {
    callback(key) {
      this.keys = key
    },
    bulkShift(val, key, tab) {
      this.implicit = val
      this.text = key
      this.key = tab
    },
    toggleValue() {
      this.implicit = true
      this.keys = this.key
    }
  }
}
</script>

<style lang="less" scoped>
.main-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: @base-bg-color;
  padding: 20px;
}
/deep/.ant-tabs-tab{
  height:36px;
  font-size:16px;
  color: @sc-grey-100;
  padding-top: 0;
}
/deep/.ant-tabs-tab-active{
  color: @sc-primary-100 !important;
}
/deep/ .ant-tabs-nav-scroll {
  margin-top: -3px;
}
/deep/.ant-tabs{
  overflow: visible;
}
</style>
