<template>
  <div class="main-content">
    <a-tabs :active-key="keyval" @change="callback">
      <a-tab-pane key="1" tab="待入职">
        <InductionTabel v-if="keyval == 1" :screen-roster-list-data="toBeEmployedData" :keyval="keyval" :is-first="isFirst" @changeFirst="changeFirst" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="最近入职">
        <InductionTabel v-if="keyval == 2" :screen-roster-list-data="recentEmployedData" :keyval="keyval" :is-first="isFirst" @changeFirst="changeFirst" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="未入职">
        <InductionTabel v-if="keyval == 3" :screen-roster-list-data="noInduction" :keyval="keyval" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import moment from 'moment'
import InductionTabel from './table.vue'
export default {
  name: 'Induction',
  components: {
    InductionTabel
  },
  data() {
    return {
      // 记录只绑定跳转参数一次
      isFirst: true,
      content: '',
      keyval: '1',
      induction: {
        'list': [
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'engage_mode',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'employment',
            'key': 'selectDictionaries_1651022813841',
            'hasErrorValid': false
          },
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'depMent',
            'key': 'selectDepartment_1651022903831'
          },
          {
            'type': 'selectPost',
            'label': '岗位',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'selectPost',
            'key': 'selectPost_1651022948111'
          },
          {
            'type': 'selectDictionaries',
            'label': '招聘方式',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'recruit_type',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'recruitment',
            'key': 'selectDictionaries_1651022974086'
          },
          {
            'type': 'selectDataRange',
            'label': '入职日期',
            'options': {
              'width': '100%',
              'defaultValue': [undefined, undefined],
              'rangePlaceholder': ['开始时间', '结束时间'],
              'rangeDefaultValue': [],
              'range': false,
              'showTime': false,
              'disabled': false,
              'hidden': false,
              'clearable': true,
              'placeholder': '请选择开始时间',
              'format': 'YYYY-MM-DD'
            },
            'model': 'startPlanEntryDate',
            'key': 'date_1651214034712',
            'help': ''
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      noInduction: {
        'list': [
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'engage_mode',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'employment',
            'key': 'selectDictionaries_1651022813841',
            'hasErrorValid': false
          },
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'depMent',
            'key': 'selectDepartment_1651022903831'
          },
          {
            'type': 'selectPost',
            'label': '岗位',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'selectPost',
            'key': 'selectPost_1651022948111'
          },
          {
            'type': 'selectDictionaries',
            'label': '放弃原因',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'leave_reasons',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'renounce',
            'key': 'selectDictionaries_1651022974086'
          },
          {
            'type': 'selectDataRange',
            'label': '计划入职日期',
            'options': {
              'width': '100%',
              'defaultValue': [undefined, undefined],
              'rangePlaceholder': ['开始时间', '结束时间'],
              'range': false,
              'showTime': false,
              'disabled': false,
              'hidden': false,
              'clearable': true,
              'placeholder': '请选择',
              'format': 'YYYY-MM-DD'
            },
            'model': 'startPlanEntryDate',
            'key': 'date_1651227845564',
            'help': ''
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      }
    }
  },
  computed: {
    // 最近入职筛选数据
    recentEmployedData() {
      const query = this.$route.params.isHome
      const screenData = JSON.parse(JSON.stringify(this.induction))
      screenData.list.push({
        'type': 'selectDictionaries',
        'label': '入职登记表',
        'options': {
          'multiple': false,
          'disabled': false,
          'width': '100%',
          'parameter': 'entry_form_exam_status',
          'defaultValue': query === 'recentEmployment' && this.isFirst ? '1' : '',
          'clearable': true,
          'placeholder': '请选择',
          'showSearch': false
        },
        'model': 'entryFormExamStatus',
        'key': 'entryFormExamStatus'
      })
      return screenData
    },
    // 待入职筛选数据
    toBeEmployedData() {
      const query = this.$route.params.isHome
      const date = [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).add(10, 'days').format('YYYY-MM-DD')]
      return {
        'list': [
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'engage_mode',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'employment',
            'key': 'selectDictionaries_1651022813841',
            'hasErrorValid': false
          },
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'depMent',
            'key': 'selectDepartment_1651022903831'
          },
          {
            'type': 'selectPost',
            'label': '岗位',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'selectPost',
            'key': 'selectPost_1651022948111'
          },
          {
            'type': 'selectDictionaries',
            'label': '招聘方式',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'recruit_type',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'recruitment',
            'key': 'selectDictionaries_1651022974086'
          },
          {
            'type': 'selectDataRange',
            'label': '入职日期',
            'options': {
              'width': '100%',
              'defaultValue': query === 'toBeEmployed' && this.isFirst ? date : [undefined, undefined],
              'rangePlaceholder': ['开始时间', '结束时间'],
              'rangeDefaultValue': [],
              'range': false,
              'showTime': false,
              'disabled': false,
              'hidden': false,
              'clearable': true,
              'placeholder': '请选择开始时间',
              'format': 'YYYY-MM-DD'
            },
            'model': 'startPlanEntryDate',
            'key': 'date_1651214034712',
            'help': ''
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      }
    }
  },
  created() {
    // 首页判断跳转参数
    if (this.$route.params.isHome === 'recentEmployment') {
      this.keyval = '2'
    }
  },
  methods: {
    callback(key) {
      this.keyval = key
    },
    changeFirst() {
      this.isFirst = false
    }
  }
}
</script>

<style lang="less" scoped>
.main-content {
  min-height: 100%;
  background: #fff;
  padding: 20px 20px 0;
  overflow: auto;
}
/deep/ .ant-tabs{
  height: 100%;
  overflow: auto;
}
/deep/ .ant-tabs-nav-scroll {
  margin-top: -4px;
}
/deep/ .ant-tabs-tab {
   color:  @sc-grey-100;
   font-size: 16px;
   padding-top: 0;
}
/deep/ .ant-tabs-tab-active{
  color: @sc-primary-100;
  font-weight: 500;
}
/deep/ .ant-tabs-tab:hover {
  color: @sc-primary-100;
}
</style>
