var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('appForm',{ref:"appForm",attrs:{"to-list":_vm.toList,"not-shown-list":_vm.notShownList,"display-list":_vm.displayList,"screen-roster-list":_vm.screenRosterList},on:{"getSearchCallback":_vm.getSearchCallback,"searchHeaderCallback":_vm.searchHeaderCallback,"screenRosterParams":_vm.screenRosterParams,"exportFileList":_vm.exportFileList}},[_c('template',{slot:"customButton"},[_c('a-dropdown',{attrs:{"type":"primary"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('a-menu',{on:{"click":_vm.handleMenuClick}},_vm._l((_vm.functionList),function(item,index){return _c('a-menu-item',{key:index},[_vm._v(_vm._s(item.name))])}),1)]},proxy:true}])},[_c('a-button',{attrs:{"type":"primary","suffix":"left"}},[_vm._v(" 添加员工 ")])],1),_c('a-popover',{attrs:{"trigger":"click","visible":_vm.clicked,"placement":"rightTop","arrow-point-at-center":true},on:{"visibleChange":_vm.visibleChange}},[_c('template',{slot:"content"},[_c('div',{staticClass:"invitation-box"},[_c('img',{staticClass:"invitation-box_img",attrs:{"src":_vm.url.img,"alt":""}}),_c('div',{staticClass:"invitation-box_title"},[_vm._v("员工扫一扫，打开小程序")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.download}},[_vm._v("保存二维码")])],1)]),_c('a-button',{staticClass:"share",attrs:{"prefix":"two-dimensional-code"}},[_vm._v("分享")])],2)],1)],2),_c('custom-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"row-key":"staffId","scroll":_vm.tableScroll,"dragable":"","pagination":{
      current: _vm.pager.pageNum,
      pageSize: _vm.pager.pageSize,
      total: _vm.total,
      showTotal: function (total) { return ("共 " + total + " 条数据"); },
      showQuickJumper: true,
      showSizeChanger: true
    }},on:{"change":_vm.loadDataList,"watchWidth":_vm.watchWidth},scopedSlots:_vm._u([{key:"staffname",fn:function(ref){
    var scope = ref.scope;
return _c('span',{},[_c('CbTableAvater',{attrs:{"table-name":scope.record[_vm.staffNameCode],"on-job-id":scope.record.onJobId,"staff-id":scope.record.staffId}})],1)}},{key:"operation",fn:function(ref){
    var scope = ref.scope;
return [_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.resetPassword(scope)}}},[_vm._v("重置密码")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }