<template>
  <div class="daily-table">
    <div class="toobar-left">
      <!-- <a-icon type="calendar" theme="twoTone" two-tone-color="#507ff7" /> -->
      <svg class="iconpark-icon"><use href="#rongqi" /></svg>
      <a-date-picker v-model="time" value-format="YYYY-MM-DD" :disabled-date="disabledDate" :bordered="false" @change="dateChange">
        <span class="date">{{ time }}</span>
      </a-date-picker>
      <a-button class="month" @click="onday">昨天</a-button>
    </div>
    <div class="daily-toolbar">
      <div class="daily-statistic-left">
        <div v-show="statistcs.requiredNumber > 0" class="statistic-p">出勤：{{ statistcs.requiredNumber }}</div>
        <div v-show="statistcs.actualNumber > 0" class="statistic-p">打卡：{{ statistcs.actualNumber }}</div>
        <div v-show="statistcs.lackNumber > 0" class="statistic-p">旷工: <span>{{ statistcs.lackNumber }}</span></div>
        <div v-show="statistcs.lateNumber > 0" class="statistic-p">迟到:<span> {{ statistcs.lateNumber }}</span></div>
        <div v-show="statistcs.earlyNumber > 0" class="statistic-p">早退:<span> {{ statistcs.earlyNumber }}</span></div>
        <div v-show="statistcs.missNumber > 0" class="statistic-p">缺卡：<span>{{ statistcs.missNumber }}</span></div>
        <div v-show="statistcs.leaveNumber > 0" class="statistic-p">请假：<span>{{ statistcs.leaveNumber }}</span></div>
        <div v-show="statistcs.overtimeNumber > 0" class="statistic-p">加班：<span>{{ statistcs.overtimeNumber }}</span></div>
        <div v-show="statistcs.outNumber > 0" class="statistic-p">外出：<span>{{ statistcs.outNumber }}</span></div>
        <div v-show="statistcs.travelNumber > 0" class="statistic-p">出差：<span>{{ statistcs.travelNumber }}</span></div>
      </div>
      <appForm
        ref="appForm"
        :to-list="formList"
        search-placeholder="请搜索姓名/工号"
        :screen-roster-list="screenRosterList"
        @getSearchCallback="getSearchCallback"
        @screenRosterParams="screenRosterParams"
        @exportFileList="exportFileList"
        @refreshTableList="refreshTableList"
      />
    </div>

    <custom-table
      :columns="columns"
      :data-source="dataSource"
      row-key="onJobId"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="haedChang"
    >
      <!-- 序号 -->
      <template slot="index" slot-scope="{ scope }">
        <span>{{ scope.index + 1 }}</span>
      </template>
      <template slot="staffName" slot-scope="{ scope }">
        <CbTableAvater :table-name="scope.record.staffName" :on-job-id="scope.record.onJobId" :staff-id="scope.record.staffId" :title="scope.record.staffName" />
      </template>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{scope}">
        <div class="flex-align-center">
          <div class="more-handle">
            <a-button type="link" @click="detail(scope.record)">
              详情
            </a-button>
          </div>
        </div>
      </template>
    </custom-table>
    <Drawer
      :visible="drawer"
      :title="title"
      placement="right"
      size="small"
      wrap-class-name="detailDrawer"
      is-mask
      display-btn
      @close="onClose"
    >
      <div class="head">
        <div class="content">
          <span class="shiftName">班次:  <span :style="`color:rgba(${details.list?details.list[0].fontRgba:''})`">{{ details.shiftName }}</span></span>
          <span :style="`color:rgba(${details.list?details.list[0].fontRgba:''})`">{{ details.dayClockSection }} </span>
        </div>
      </div>
      <div class="dailytime">
        <div class="dailytime-head">
          <p class="head-p">合计出勤<span>{{ details.sumHour }}</span>小时</p>
          <a-timeline v-for="item in details.list" :key="item.staffShiftId">
            <a-timeline-item color="@sc-grey-60">
              <a-icon slot="dot" type="clock-circle-o" style="font-size: 16px;" />
              <p style="color:@sc-gray-100">上班 <span>{{ item.onClockTime || '-' }}</span></p>
              <p>打卡方式 <span>{{ item.onClockMode || '-' }}</span></p>
              <p>打卡地点 <span>{{ item.onClockPlace || '-' }}</span></p>
              <p>备注 <span>{{ item.onClockRemark || '-' }}</span></p>
            </a-timeline-item>
            <a-timeline-item color="@sc-grey-60">
              <a-icon slot="dot" type="clock-circle-o" style="font-size: 16px;" />
              <p style="color:@sc-gray-100;">下班 <span>{{ item.upClockTime || '-' }}</span></p>
              <p>打卡方式 <span>{{ item.upClockMode || '-' }}</span></p>
              <p>打卡地点 <span>{{ item.upClockPlace || '-' }}</span></p>
              <p>备注 <span>{{ item.upClockRemark || '-' }}</span></p>
            </a-timeline-item>
          </a-timeline>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import { getAttendancePage, getDailAttendance, getAttendanceResultList, refreshPunchApi } from '@/services/attendance/attendance.js'
import moment from 'moment'
export default {
  name: 'DailyAttendance',
  components: {
    Drawer: () => import('@/components/CbDrawer'),
    appForm: () => import('@/components/CbAppForm/index'),
    CbTableAvater: () => import('@/components/CbTableAvater/index')
  },
  mixins: [mixins],
  data() {
    return {
      formList: ['search', 'export', 'screen', 'refresh'], // search搜索框 screen筛选 export导出
      time: moment(moment().subtract(1, 'day')).format('YYYY-MM-DD'),
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'index' },
          width: '50px',
          fixed: 'left'
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          key: 'staffName',
          scopedSlots: { customRender: 'staffName' },
          width: '105px',
          fixed: 'left'
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          width: '120px',
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          width: '100px',
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          width: '100px',
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '出勤结果',
          dataIndex: 'result',
          width: '120px',
          key: 'result',
          ellipsis: true,
          customCell: () => {
            return {
              style: {
                'min-width': '120px'
              }
            }
          }
        },
        {
          title: '日出勤时长(小时)',
          dataIndex: 'actualHours',
          key: 'actualHours',
          sorter: true,
          width: '200px',
          ellipsis: true
        },
        {
          title: '最早上班时间',
          dataIndex: 'earliestClock',
          key: 'earliestClock',
          width: '200px',
          sorter: true,
          ellipsis: true
        },
        {
          title: '最晚下班时间',
          dataIndex: 'latestClock',
          key: 'latestClock',
          width: '200px',
          sorter: true,
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'operation',
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
          width: '80px'
        }
      ],
      // 筛选内容
      screenRosterList: {
        'list': [
          {
            'type': 'selectDictionaries',
            'label': '员工状态',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择员工状态',
              'showSearch': false,
              'parameter': 'staff_status',
              'defaultValue': ''
            },
            'model': 'staffStatuses',
            'key': 'staffStatuses',
            'rules': [
              {
                'required': false,
                'message': '请选择'
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '出勤结果',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'attendance_result_item',
              'clearable': true,
              'placeholder': '请选择出勤结果',
              'showSearch': false
            },
            'model': 'resultList',
            'key': 'resultList',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'engage_mode',
              'clearable': true,
              'placeholder': '请选择聘用形式',
              'showSearch': false
            },
            'model': 'engageModes',
            'key': 'engageModes'
          },
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择部门',
              'showSearch': false
            },
            'model': 'orgIds',
            'key': 'orgIds',
            'rules': [
              {
                'required': false,
                'message': '请选择部门'
              }
            ]
          },
          {
            'type': 'selectPost',
            'label': '岗位',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择岗位',
              'showSearch': false
            },
            'model': 'postIds',
            'key': 'postIds',
            'rules': [
              {
                'required': false,
                'message': '请选择岗位'
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 80,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      dataSource: [], // 数据
      statistcs: {
        requiredNumber: '0', // 出勤次数
        actualNumber: '0', // 打卡次数
        lackNumber: '0', // 旷工次数
        lateNumber: '0', // 迟到次数
        earlyNumber: '0', // 早退次数
        missNumber: '0', // 缺卡次数
        leaveNumber: '0', // 请假人数
        overtimeNumber: '0', // 加班人数
        outNumber: '0', // 外出人数
        travelNumber: '0'// 出差人数
      },
      drawer: false, // 详情
      details: {},
      title: ''
    }
  },
  created() {
  },
  methods: {
    async refreshTableList() {
      const res = await refreshPunchApi({ shiftDate: moment(this.time).format('YYYYMMDD') })
      if (res.code === 100) {
        this.serchGetData()
      }
    },
    dateChange() {
      this.serchGetData()
    },
    // 搜索
    getSearchCallback(value) {
      this._queryParam.searchWord = value.searchValue
      this.serchGetData()
    },
    // 筛选
    screenRosterParams(param) {
      this._queryParam.searchWord = param.searchValue
      this._queryParam.engageModes = param.screenCondition.engageModes ? param.screenCondition.engageModes.join(',') : ''
      if (param.screenCondition.orgIds) {
        const orgIds = param.screenCondition.orgIds.map(item => {
          return item.value
        })
        this._queryParam.orgIds = orgIds.join(',')
      }
      this._queryParam.resultList = param.screenCondition.resultList ? param.screenCondition.resultList.join(',') : ''
      this._queryParam.postIds = param.screenCondition.postIds ? param.screenCondition.postIds.join(',') : ''
      this._queryParam.staffStatuses = param.screenCondition.staffStatuses ? param.screenCondition.staffStatuses.join(',') : ''
      for (var key in this._queryParam) {
        if (!this._queryParam[key]) {
          delete this._queryParam[key]
        }
      }
      this.serchGetData()
    },
    // 获取每日出勤列表
    async fetchDataApiFn(param) {
      const res = await getAttendancePage({ ...param, date: this.time.split('-').join('') })
      this.dataSource = res.data.list.records ? res.data.list.records : []
      this.pager.pageNum = res.data.list.current || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.list.size || 10 // 更新当前的分页信息
      this.total = res.data.list.total
      this.statistcs = res.data.statistcs
    },
    disabledDate(current) { // 不可选择未来日期
      return current > moment().endOf('day')
    },
    onday() {
      this.time = moment(moment().subtract(1, 'day')).format('YYYY-MM-DD')
      this.serchGetData()
    },
    // 详情
    detail(info) {
      getDailAttendance({
        staffShiftId: info.staffShiftIda,
        onJobId: info.onJobId,
        date: this.time.split('-').join('')
      }).then(res => {
        this.title = info.staffName ? ` ${info.staffName}\u00A0\u00A0${this.time}` : `\u00A0\u00A0${this.time}`
        if (res.data) {
          this.details = res.data[0] // 详细记录
        }
      })
      this.drawer = true
    },
    onClose() {
      this.drawer = false
    },
    // 导出
    exportFileList() {
      const { staffStatuses, engageModes, orgIds, postIds, resultList, searchWord } = this._queryParam
      getAttendanceResultList({
        staffStatuses: staffStatuses,
        engageModes: engageModes,
        orgIds: orgIds,
        postIds: postIds,
        date: this.time.split('-').join(''),
        resultList: resultList,
        searchWord: searchWord
      })
    },
    haedChang(pagination, filters, sorter) {
      this._queryParam = {
        ...this._queryParam,
        ...this.pager,
        ...filters,
        sort: sorter.order && sorter.order === 'descend' ? sorter.field + ' ' + 'desc ' : sorter.field + ' ' + ' asc'
      }
      if (sorter.order) {
        this._queryParam['sort'] = sorter.order === 'descend' ? sorter.field + ' ' + 'desc ' : sorter.field + ' ' + 'asc'
      } else if (this._queryParam.sort) {
        delete this._queryParam.sort
      }
      this._queryParam.pageNum = pagination.current
      this._queryParam.pageSize = pagination.pageSize
      this.pager.pageNum = pagination.current
      this.pager.pageSize = pagination.pageSize
      this.total = pagination.total
      this.$$fetchListWithLoading(this._queryParam)
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-table td { white-space: nowrap; }
.daily-table {
  width: 100%;
  height: calc(100% - 50px);
  overflow: hidden;
  .daily-toolbar {
    .flex-just-between();
  }
.toobar-left{
  display: inline-block;
  padding:0 0 0 16px;
  margin-bottom: 16px;
  width: fit-content;
  height: 34px;
  background-color: @sc-greyBg-10;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 4px;
  color: @sc-primary-100;
  .ant-calendar-picker{
    padding:0 16px;
    color: @sc-grey-100;
    &:hover {
      cursor: pointer;
      color: @sc-primary-100 !important;
    }
  }
  .month{
      width: 64px;
      cursor:pointer;
      padding:  0 10px;
      border: 0;
      border-radius: 0;
      margin-right: 2px;
      height: 30px;
    }
  .iconpark-icon{
    width: 20px;
    height: 20px;
    color: @sc-primary-100;
  }
}
   .daily-statistic-left{
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
    }
    .statistic-p {
      margin-right: 40px;
      margin-bottom: 16px;
      font-size: 14px;
      color: @sc-grey-100;
      span{
        color: #FFAC1C;
      }
    }
  .more-handle {
    font-weight: 700;
    font-size: 20px;
    vertical-align: baseline;
    cursor: pointer;
  }
}
.ant-drawer{
  .head{
    font-size: 14px;
    border-bottom: 2px solid @sc-grey-10;
    height: 46px;
    line-height: 32px;
    .content{
      margin-top: 16px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .shiftName{
        color: @sc-grey-60;
        margin-right: 10px;
      }
        span{
          color: @sc-grey-100;
        }
      }
    }
  }
  .dailytime{
    width: 100%;
    .dailytime-head{
      width: 100%;
      .head-p{
        margin: 13px 0 16px 0;
        color: @sc-grey-60;
        span{
          padding: 0 4px ;
          color: @sc-primary-100;
        }
      }
      .ant-timeline{
        /deep/.ant-timeline-item-content{
          margin-left: 30px;
          background: @sc-greyBg-10;
          border-radius: 4px ;
          opacity: 1;
          border: 1px solid @sc-greyBg-20;
          padding: 10px 0 0;
          p{
              color: @sc-grey-80;
              margin: 0 14px 10px;
            span{
              color: @sc-grey-100;
              margin-left: 15px;
            }
          }
        }
        .ant-timeline-item{
          padding: 0 0 40px 0;
          top: 7px;
        }
      }
    }
  }
  /deep/.detailDrawer .ant-drawer-header{
    border: none !important;
    background: @sc-greyBg-10;
  }
</style>
