<template>
  <div class="cycle-container">
    <a-button class="edit" prefix="xiugaisuanxinrenyuan18" @click="openBtn($event)">修改</a-button>
    <p>计薪周期：{{ dateText }} </p>
    <p>生效月份：{{ effectiveYearMonth }} </p>
    <RevisePayTime ref="revisePayTime" :time-list="timeList" :next-time-list="nextTimeList" @refresh="refresh" />
    <Table ref="table" />
  </div>
</template>

<script>
import moment from 'moment'
import { getConfig, getBaseCode } from '@/services/wage/payPeroid.js'
export default {
  name: 'PayCycle',
  components: {
    RevisePayTime: () => import('./components/revisePayTime.vue'),
    Table: () => import('./components/table.vue')
  },
  data() {
    return {
      form: {
        startMonthType: '',
        startDay: '',
        effectiveYearMonth: ''
      },
      timeList: [],
      nextTimeList: ['当月', '下月', '下下月']
    }
  },
  computed: {
    dateText() {
      if (+this.form.startMonthType && this.form.startDay) {
        const current = `从${this.timeList[+this.form.startMonthType - 1]?.content || ''}${this.form.startDay}日开始`
        if (this.form.startDay === 1) {
          return `${current}至${this.timeList[+this.form.startMonthType - 1]?.content}最后一天`
        }
        return `${current}至${this.nextTimeList[+this.form.startMonthType - 1]}${this.form.startDay - 1}日`
      }
      return ''
    },
    effectiveYearMonth() {
      return this.form.effectiveYearMonth ? moment(this.form.effectiveYearMonth).format('YYYY年MM月') : '--'
    }
  },
  mounted() {
    this.getConfig()
    this.getBaseCode()
  },
  methods: {
    // 获取计薪周期配置
    async getConfig() {
      const res = await getConfig()
      this.form = res.data
    },
    // 获取计薪周期配置
    async getBaseCode() {
      const res = await getBaseCode()
      this.timeList = res.data
    },
    openBtn() {
      this.$refs.revisePayTime.open({ ...this.form, effectiveYearMonth: this.form.isCal ? this.form.allowedMinMonth : this.form.effectiveYearMonth })
    },
    changeTab(tab) {
      console.log(tab)
    },
    refresh() {
      this.getConfig()
      this.$refs.table.serchGetData()
    }
  }
}
</script>

<style scoped lang='less'>
.cycle-container {
  width: 100%;
  padding: 20px;
  p {
    margin: 20px 0 0;
    color: @sc-grey-100;
    .font-size(14px)
  }
}
</style>
