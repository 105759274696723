<template>
  <div>
    <div class="header-back">
      <a-button prefix="left" @click="$emit('back')">返回</a-button>
    </div>
    <appForm>
      <template slot="customButton">
        <a-button :disabled="startUp" @click="selectAll">全部选中</a-button>
        <a-button class="top_button" :disabled="startUp" @click="deselectAll">取消全选</a-button>
        <a-button class="top_button" type="primary" :disabled="startUp" @click="startUpDeclare">启动申报机器人</a-button>
        <a-button class="top_button" :disabled="!startUp" @click="terminationDeclare">终止执行</a-button>
        <!--        <a-button class="top_button" @click="executionHistory">查看执行历史</a-button>-->
        <a-button class="top_button" @click="refreshResults">刷新执行结果</a-button>
      </template>
    </appForm>
    <custom-table
      bordered
      :columns="columns"
      :data-source="dataSource"
      :row-key="'onJobId'"
      :scroll="{ y: 680 }"
      :pagination="false"
      :loading="loading"
      @change="loadDataList"
    >
      <template slot="checkStatus" slot-scope="{ scope }">
        <div>
          <a-checkbox v-model="scope.record.checkStatus" :disabled="startUp" />
        </div>
      </template>
    </custom-table>
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import { assignmentList } from './index.js'
export default {
  name: 'SmartDeclaration',
  mixins: [mixins],
  data() {
    return {
      pager: {
        pageNum: 1,
        pageSize: 40
      },
      dataSource: [],
      columns: [
        {
          title: '参保主体',
          dataIndex: 'company',
          width: '250px',
          scopedSlots: { customRender: 'company' },
          align: 'center',
          key: 'company',
          customRender(value, row, index) {
            return {
              children: value,
              attrs: {
                rowSpan: row['company_rowSpan'] ? row['company_rowSpan'] : 0
              }
            }
          }
        },
        {
          title: '官方系统',
          dataIndex: 'sys',
          width: '150px',
          align: 'center',
          scopedSlots: { customRender: 'sys' },
          key: 'sys',
          customRender(value, row, index) {
            return {
              children: value,
              attrs: {
                rowSpan: row['sys_rowSpan'] ? row['sys_rowSpan'] : 0
              }
            }
          }
        },
        {
          title: '业务流程',
          dataIndex: 'process',
          width: '150px',
          align: 'center'
        },
        {
          title: '选中',
          dataIndex: 'checkStatus',
          scopedSlots: { customRender: 'checkStatus' },
          width: '50px',
          align: 'center'
        },
        {
          title: '流程状态',
          dataIndex: 'processStatus',
          width: '100px',
          align: 'center'
        },
        {
          title: '最后一次执行情况',
          children: [
            {
              title: '开始执行时间',
              dataIndex: 'socialStartMonth',
              key: 'socialStartMonth',
              width: 100,
              align: 'center'
            },
            {
              title: '结束执行时间',
              dataIndex: 'socialFixedPaymentBase',
              key: 'socialFixedPaymentBase',
              width: 100,
              align: 'center'
            },
            {
              title: '执行结果',
              dataIndex: 'socialFixedPaymentBase1',
              key: 'socialFixedPaymentBase1',
              width: 100,
              align: 'center'
            },
            {
              title: '备注',
              dataIndex: 'socialFixedPaymentBase2',
              key: 'socialFixedPaymentBase2',
              width: 100,
              align: 'center'
            }
          ]
        }
      ],
      startUp: false
    }
  },
  computed: {
    columnKeyList() {
      const obj = {}
      this.columns.filter(item => item.customRender).forEach(element => {
        obj[element.dataIndex] = {
          msg: undefined,
          index: 0
        }
      })
      return obj
    }
  },
  mounted() {},
  methods: {
    // 表格合并列
    mergeData() {
      this.dataSource.forEach((item, index) => {
        Object.keys(this.columnKeyList).forEach(key => {
          if (index !== 0 &&
          item[key] === this.columnKeyList[key].msg) {
            this.dataSource[this.columnKeyList[key].index][`${[key]}_rowSpan`] += 1
          } else {
            item[`${[key]}_rowSpan`] = 1
            this.columnKeyList[key].msg = item[key]
            this.columnKeyList[key].index = index
          }
        })
      })
    },
    // 初始获取表格数据
    fetchDataApiFn() {
      this.dataSource = [
        {
          'key': 1,
          'company': '山东小数点信息技术有限公司',
          'sys': '社保网上申报系统',
          'process': '减员-增员',
          'checkStatus': true,
          'status': true,
          'socialStartMonth': '2022-08-23 08:00:00'
        },
        {
          'key': 2,
          'company': '山东小数点信息技术有限公司',
          'sys': '社保网上申报系统',
          'process': '缴费核定',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 3,
          'company': '山东小数点信息技术有限公司',
          'sys': '社保网上申报系统',
          'process': '税务提交',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 4,
          'company': '山东小数点信息技术有限公司',
          'sys': '医保网上申报系统',
          'process': '减员-增员',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 5,
          'company': '山东小数点信息技术有限公司',
          'sys': '医保网上申报系统',
          'process': '缴费核定',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 6,
          'company': '山东小数点信息技术有限公司',
          'sys': '医保网上申报系统',
          'process': '税务提交',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 7,
          'company': '山东小数点信息技术有限公司',
          'sys': '公积金网上申报系统',
          'process': '封存-开户-转移-启封',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 8,
          'company': '山东小数点信息技术有限公司',
          'sys': '公积金网上申报系统',
          'process': '缴费核定',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 9,
          'company': '山东数昶信息技术有限公司',
          'sys': '社保网上申报系统',
          'process': '减员-增员',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 10,
          'company': '山东数昶信息技术有限公司',
          'sys': '社保网上申报系统',
          'process': '缴费核定',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 11,
          'company': '山东数昶信息技术有限公司',
          'sys': '社保网上申报系统',
          'process': '税务提交',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 12,
          'company': '山东数昶信息技术有限公司',
          'sys': '医保网上申报系统',
          'process': '减员-增员',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 13,
          'company': '山东数昶信息技术有限公司',
          'sys': '医保网上申报系统',
          'process': '缴费核定',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 14,
          'company': '山东数昶信息技术有限公司',
          'sys': '医保网上申报系统',
          'process': '税务提交',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 15,
          'company': '山东数昶信息技术有限公司',
          'sys': '公积金网上申报系统',
          'process': '封存-开户-转移-启封',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 16,
          'company': '山东数昶信息技术有限公司',
          'sys': '公积金网上申报系统',
          'process': '缴费核定',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 17,
          'company': '墩儿信息技术有限责任公司',
          'sys': '社保网上申报系统',
          'process': '减员-增员',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 18,
          'company': '墩儿信息技术有限责任公司',
          'sys': '社保网上申报系统',
          'process': '缴费核定',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 19,
          'company': '墩儿信息技术有限责任公司',
          'sys': '社保网上申报系统',
          'process': '税务提交',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 20,
          'company': '墩儿信息技术有限责任公司',
          'sys': '医保网上申报系统',
          'process': '减员-增员',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 21,
          'company': '墩儿信息技术有限责任公司',
          'sys': '医保网上申报系统',
          'process': '缴费核定',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 22,
          'company': '墩儿信息技术有限责任公司',
          'sys': '医保网上申报系统',
          'process': '税务提交',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 23,
          'company': '墩儿信息技术有限责任公司',
          'sys': '公积金网上申报系统',
          'process': '封存-开户-转移-启封',
          'checkStatus': true,
          'status': true
        },
        {
          'key': 24,
          'company': '墩儿信息技术有限责任公司',
          'sys': '公积金网上申报系统',
          'process': '缴费核定',
          'checkStatus': true,
          'status': true
        }
      ]
      this.mergeData()
    },
    // 全选
    selectAll() {
      this.dataSource = assignmentList(this.dataSource, true)
    },
    // 取消全选
    deselectAll() {
      this.dataSource = assignmentList(this.dataSource, false)
    },
    // 启动申报机器人
    startUpDeclare() {
      this.startUp = true
    },
    // 终止执行
    terminationDeclare() {
      this.startUp = false
    },
    // 执行历史
    executionHistory() {
      alert('此功能未开放，散了吧哈哈哈哈')
    },
    // 刷新执行结果
    refreshResults() {
      this.serchGetData()
    }
  }
}
</script>

<style lang="less" scoped>
  .header-back {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    /deep/.ant-btn .prefix{
      color: @sc-grey-100;
    }
  }
  .top_button {
    margin-left: 20px;
  }
  /deep/.ant-table-thead tr th {
    padding: 6px 8px !important;
    border-right: 0.5px solid @sc-greyBg-20 !important;
    border-bottom: 1px solid @sc-greyBg-20 !important;
  }
</style>
