<template>
  <div class="box">
    <div class="tree-block">
      <a-tree
        :expanded-keys="expandedKeys"
        :auto-expand-parent="autoExpandParent"
        :tree-data="gData"
        :replace-fields="{ children: 'children', title: 'orgName', key: 'orgId' }"
        :show-line="false"
        :draggable="true"
        :selectable="false"
        :block-node="false"
        @expand="onExpand"
      >
        <!-- orgId, avatar: 由于未用到，总是爆红，暂时去掉 -->
        <template slot="name" slot-scope="{ onJobId, orgName, leaderName, orgTypeName, invented }">
          <span>
            {{ orgName }}
          </span>
          <div class="last-line">
            <div class="leader-name">
              <div style="display: flex; align-items: center">
                <CbAvater v-if="leaderName" :avater-name="leaderName" :on-job-id="onJobId" size="30" />
              </div>
            </div>
            <div class="orgin-name">
              {{ orgTypeName }}
            </div>
            <div v-if="invented" class="fictitious">
              虚拟
            </div>
          </div>
        </template>
      </a-tree>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TreeList',
  components: {
    CbAvater: () => import('@/components/CbAvater/theAvater.vue')
  },
  props: {
    treeList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      expandedKeys: [],
      autoExpandParent: true,
      companyinfo: {},
      gData: []
    }
  },
  watch: {
    treeList(val) {
      this.companyinfo = {
        orgName: val[0].orgName,
        orgTypeName: val[0].orgTypeName,
        orgId: val[0].orgId
      }
      this.gData = val[0].children
      this.$nextTick(() => {
        this.gData.length === 0 ? false : this.setTableHeight()
      })
    }
  },
  methods: {
    setTableHeight() {
      const contentH = document.body.scrollHeight - 48
      const treeH = document.querySelector('.archive_history .ant-tree')
      const treeHeight = contentH - treeH.offsetTop
      treeH.classList.add('beauty-scroll')
      treeH.style = `max-height: ${treeHeight}px; overflow: hidden scroll;`
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    }
  }
}
</script>

<style lang="less" scoped>
  @treeItemHight: 44px;
  @lineRight: 30px;
  .box {
    box-sizing: border-box;
  }
  .total-organization {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 35px;
    background: @sc-greyBg-10;
    .company {
      color: @sc-grey-100;
      font-weight: 500;
      .font-size(16px);
    }
    .add-first-item {
      margin-left: 30px;
      height: 100%;
    }
    &:hover {
      .add-first-item {
        display: flex;
      }
    }
  }
  .tree-block {
    overflow: hidden;
    // tree 组件修改
    /deep/ .ant-tree {
      > li > span.ant-tree-node-content-wrapper > .ant-tree-title > span {
        font-size: 14px;
        color: @sc-grey-100;
        font-weight: 500;
      }
      li {
        width: 100%;
        overflow: visible;
        padding: 0;
        span.ant-tree-switcher {
          height: @treeItemHight;
          line-height: @treeItemHight;
          .ant-tree-switcher-icon {
            font-size: 18px;
            vertical-align: middle;
          }
          &:hover {
            & + .ant-tree-node-content-wrapper {
              background: @sc-primary-10;
              .last-line {
                display: flex;
              }
            }
          }
        }
      }
      .ant-tree-node-content-wrapper {
        position: relative;
        width: 100%;
        height: 50px;
        line-height: 50px;
        border-radius: 0;
        padding-left: 0;
        &:hover {
          background: @sc-primary-10;
          .last-line {
            display: flex;
          }
        }
        &:after {
          content: '';
          right: @lineRight;
          width: 98%;
          position: absolute;
          border-bottom: 1px solid @sc-greyBg-20;
          bottom: 0;
        }
      }
    }
    .last-line {
      position: absolute;
      display: flex;
      right: 540px;
      top: 0;
      align-items: center;
      // padding-right: 124px;
      .leader-name {
        text-align: left;
        color: @sc-grey-100;
        margin-right: 10px;
        width: 200px;
        text-align: left;
      }
      .orgin-name {
        color: @sc-grey-60;
        text-align: left;
        width: 100px;
      }
    }
  }
  .fictitious {
    position: absolute;
    top: 14px;
    left: 550px;
    width: 49px;
    height: 24px;
    background: @sc-greyBg-10;
    border-radius: 2px;
    text-align: center;
    line-height: 24px;
    font-size: 14px;
    color: @sc-grey-100;
  }
/deep/ .ant-tree li span {
  color: @sc-grey-80;
  border: 0;
}
</style>
