<template>
  <div class="user-detail-contain">
    <div class="user-single-toolbar">
      <div class="user-single-back">
        <a-button prefix="left" @click="goBack">返回</a-button>
      </div>
      <!-- <div v-if="statusType !== 'toExamine' && tabType === 'personal'" class="user-single-btns">
        <a-button @click="downToPdf"><a-icon type="download" />下载档案</a-button>
        <a-button><a-icon type="printer" />打印档案</a-button>
      </div> -->
    </div>
    <UserInfo
      :user-id="userId"
      :status-type="statusType"
      :page-type="pageType"
      :on-job-id="onJobId"
      :archives-perfect-id="archivesPerfectId"
      :anchor-height="'calc(100vh - 305px)'"
      @changeTab="changeTab"
    />
  </div>
</template>

<script>
import UserInfo from '../components/userInfo/index.vue'
import { exportToPDF } from '@/utils/file-reader.js'
export default {
  components: {
    UserInfo
  },
  data() {
    return {
      userId: '',
      statusType: '',
      tabType: 'personal',
      pageType: '',
      archivesPerfectId: '',
      onJobId: ''
    }
  },
  created() {
    this.userId = String(this.$route.query.staffId)
    this.statusType = String(this.$route.query.statusType)
    this.pageType = String(this.$route.query.pageType)
    this.archivesPerfectId = String(this.$route.query.archivesPerfectId)
    this.onJobId = String(this.$route.query.onJobId)
  },
  methods: {
    // 下载PDF
    downToPdf() {
      exportToPDF('.person-info-download-p', `个人档案`)
    },
    changeTab(val) {
      this.tabType = val
    },
    goBack() {
      this.$router.replace(decodeURIComponent(this.$route.query.path))
    }
  }
}
</script>

<style lang="less" scoped>
.user-detail-contain {
  height: 100%;
  background: #fff;
  .user-single-toolbar {
    padding: 20px 20px 0;
    .flex-between-center();
    .user-single-back {
      /deep/.prefix {
        color: @sc-grey-100;
      }
    }
  }
}
</style>
