<template>
  <div class="set-password login-form">
    <div class="form-item">
      <a-input-password v-model.trim="globForm.password" type="text" prefix="新密码" />
    </div>
    <div class="form-item">
      <a-input-password v-model.trim="globForm.repassword" type="text" prefix="确认密码" />
    </div>
    <a-button
      class="submit"
      type="primary"
      :disabled="canUse"
      @click="onSubmit"
      @enterPress="onSubmit"
    >确定</a-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { resetPassword, configPassword, getPublicKeyApi } from '@/services/login'
import publicMixins from './publicMixins'
import { RSAEncrypt } from '@/utils/secret.js'
export default {
  name: 'SetPassword',
  mixins: [publicMixins],
  data() {
    return {
      form: {
        password: '',
        confirm: '',
        publicKey: ''
      }
    }
  },
  computed: {
    ...mapGetters('setting', ['getLoginType']),
    canUse() {
      const { password, repassword } = this.globForm
      return !(password && repassword)
    }
  },
  methods: {
    async onSubmit() {
      if (this.canUse) return
      const { password, repassword, phoneNum, uuid, code } = this.globForm
      if (!password) return this.$message.warning('请填写密码')
      if (!repassword) return this.$message.warning('请填再次确认密码')
      if (password !== repassword) return this.$message.warning('两次密码输入不一致，请检查')
      // 获取公钥
      const publickKey = await getPublicKeyApi({ algorithm: 'RSA-2048' })
      this.publicKey = publickKey.data.publicKey
      // 加密密码
      const encryptPassword = await RSAEncrypt(password, this.publicKey)
      const encryptrePassword = await RSAEncrypt(repassword, this.publicKey)
      const params = {
        password: encryptPassword,
        rePassword: encryptrePassword,
        phoneNum,
        code,
        uuid
      }
      // 4 忘记密码的设置密码  第一次登陆设置密码
      if (this.getLoginType === 4) {
        await resetPassword(params)
        this.$message.success('密码修改成功')
        this.changeLoginType(1)
      } else {
        await configPassword(params)
        this.setLoginType(8)
        this.$message.success('密码设置成功')
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./index.less');
/deep/.ant-input-affix-wrapper .ant-input:not(:first-child){
  padding-left: 88px;
}
</style>
