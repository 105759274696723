<template>
  <div class="main-content">
    <!-- <a-tabs v-if="!componentName" default-active-key="archives" @change="callback">
      <a-tab-pane key="archives" tab="薪资档案"> -->
    <wageArchivesTabel v-if="!componentName" :keyval="keyval" @change="callback" />
    <!-- </a-tab-pane>
      <a-tab-pane key="deduction" tab="专项附加扣除">
        <wageArchivesTabel v-if="keyval === 'deduction'" :keyval="keyval" />
      </a-tab-pane>
    </a-tabs> -->
    <!-- 人员批量定薪 -->
    <BatchImport
      v-if="componentName === 'batchSetFixedSalary'"
      :multiple="false"
      :file-size="20"
      title="人员批量定薪"
      import-template="batchSetFixedSalary"
      @goBack="importComplate"
    />
    <!-- 人员批量调薪 -->
    <BatchImport
      v-if="componentName === 'batchUpdateFixedSalary'"
      :multiple="false"
      :file-size="20"
      title="人员批量调薪"
      import-template="batchUpdateFixedSalary"
      @goBack="importComplate"
    />
  </div>
</template>

<script>
import WageArchivesTabel from './table.vue'
export default {
  name: 'WageArchives',
  components: {
    WageArchivesTabel,
    BatchImport: () => import('@/components/CbBatchImport/newImport')
  },
  data() {
    return {
      content: '',
      keyval: 'archives',
      componentName: ''
    }
  },
  methods: {
    callback(key) {
      this.componentName = key
    },
    importComplate() {
      this.componentName = ''
    }
  }
}
</script>

<style lang="less" scoped>
.main-content {
  min-height: 100%;
  height: auto!important;
  background: @gray-1;
  padding: 20px;
  padding-bottom: 0;
}
/deep/.ant-tabs-nav .ant-tabs-tab{
  padding-top: 0;
}
/deep/.ant-tabs{
  overflow: visible;
}
</style>
