<!--
 * @Date: 2022-05-23 10:48:56
 * @descriotion:
-->
<template>
  <div>
    <a-select
      v-for="(item,index) in list"
      :key="index"
      :class="item.checked?'':'check-text'"
      :value="item.value"
      :disabled="item.checked"
      :show-arrow="false"
      :placeholder="item.checked?'休':'+'"
      @change="onChange($event,index)"
    >
      <a-select-option v-for="shift in workList" :key="shift.shiftId" :value="shift.shiftId" :title="shift.shiftShortName">
        {{ shift.shiftShortName }}
      </a-select-option>
    </a-select>
  </div>
</template>
<script>
export default {
  name: 'SelectOrder',
  props: {
    checkList: {
      type: Array,
      default: () => []
    },
    workList: {
      type: Array,
      default: () => []
    },
    noworkList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      list: []

    }
  },
  watch: {
    checkList: {
      immediate: true,
      handler(val) {
        const arr = val
        arr.map(item => {
          if (item.checked) {
            item.id = this.noworkList.length > 0 ? this.noworkList[0].shiftId : ''
          }
        })
        this.list = arr
        this.$emit('change', this.list)
      }
    }
  },
  mounted() {
    this.list = this.checkList
  },
  methods: {
    onChange(e, i) {
      console.log(e)
      this.list[i].value = e
      this.$set(this.list, i, this.list[i])
      this.$emit('change', this.list)
    }
  }
}
</script>

<style lang="less" scoped>
form .ant-select{
    width:calc(100%/7);
}
/deep/.ant-select-dropdown{
  min-width: 85px;
}
/deep/.ant-select-selection__rendered{
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-select-selection__placeholder{
        text-align: center;
    }
}
/deep/.ant-select-selection{
    border-radius: 0;
}
.ant-select-no-arrow .ant-select-selection-selected-value{
    width:100%;
    text-align: center;
}
.check-text{
  color:@sc-primary-100;
  /deep/.ant-select-selection__placeholder{
    color:@sc-primary-100
  }
}
</style>
