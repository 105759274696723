const personsNum = {
  all: '缴纳人数',
  add: '本月增员',
  del: '本月减员'
}
const summaryMoney = {
  0: '合计金额',
  1: '本月公司缴纳',
  2: '本月个人缴纳',
  3: '公司补缴调差',
  4: '个人补缴调差'
}
const importList = {
  0: {
    title: '导出本月账单',
    menuList: [
      {
        label: '全部账单',
        type: ''
      },
      {
        label: '社保账单',
        type: 'social'
      },
      {
        label: '医保账单',
        type: 'medical'
      },
      {
        label: '公积金账单',
        type: 'housing'
      }
    ]
  },
  1: {
    title: '导出增员表',
    menuList: [
      {
        label: '社保增员表',
        type: 'social'
      },
      {
        label: '医保增员表',
        type: 'medical'
      },
      {
        label: '公积金增员表',
        type: 'housing'
      }
    ]
  },
  2: {
    title: '导出减员表',
    menuList: [
      {
        label: '社保减员表',
        type: 'social'
      },
      {
        label: '医保减员表',
        type: 'medical'
      },
      {
        label: '公积金减员表',
        type: 'housing'
      }
    ]
  },
  3: {
    title: '导出补缴表',
    menuList: [
      {
        label: '社保补缴表',
        type: 'social'
      },
      {
        label: '医保补缴表',
        type: 'medical'
      },
      {
        label: '公积金补缴表',
        type: 'housing'
      }
    ]
  }
}
const monthBillDetail = [
  {
    label: '参保分类'
  },
  {
    label: '本月增员'
  },
  {
    label: '减员人数'
  },
  {
    label: '缴费人数'
  },
  {
    label: '补缴人数'
  },
  {
    label: '单位合计(元)'
  },
  {
    label: '个人合计(元)'
  },
  {
    label: '总金额(元)'
  }
]
const insureTips = {
  2: `<p>缴纳起始月早于当前月份，如需补缴以往月份，请办理完投保后使用"补缴功能"录入补缴数据，是否确认办理投保？</p>`,
  3: '<p>本月账单已生成，如需本月为员工缴纳费用，请在投保业务办理完成后重新生成账单 </p>',
  4: '<p>本月账单已生成，如需本月为员工缴纳费用，请前办理完成后重新生成本月账单。如需补缴以往月份，请办理完投保后使用"补缴功能"录入补缴数据。是否确认办理投保业务？</p>',
  5: '<p>本月账单已锁定，如需本月为员工缴纳费用，请前办理完成后重新生成账单。是否确认办理投保业务？</p>',
  6: '<p>本月账单已锁定，如需本月为员工缴纳费用，请前办理完成后重新生成本月账单。如需补缴以往月份，请办理完投保后使用"补缴功能"录入补缴数据。是否确认办理投保业务？</p>'
}
const stopTips = {
  2: `<p>缴纳停止月早于当前月份，历史月份账单将不会更改。确认是否要办理停保？</p>`,
  3: '<p>本月账单已生成，如本月不为员工缴纳费用，请重新生成账单。确认是否要办理停保？</p>',
  4: '<p>缴纳停止月早于当前月份，历史月份账单将不会更改。确认是否要办理停保？</p>',
  5: '<p>本月账单已锁定，如本月不为员工缴纳费用，请重新生成账单。确认是否要办理停保？</p>',
  6: '<p>本月账单已锁定，如本月不为员工缴纳费用，请重新生成账单。缴纳停止月早于当前月份，历史月份账单将不会更改。确认是否要办理停保？</p>'
}
export { personsNum, summaryMoney, importList, monthBillDetail, insureTips, stopTips }
