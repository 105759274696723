<template>
  <div class="employeeschange">
    <div class="change-toobar">
      <div class="toobar-left">
        <a-select
          v-model="attendance"
          style="width: 114px;margin-right:12px"
          :title="attendance"
          @change="attendanceChange"
        >
          <a-select-option
            v-for="(item,index) in attendances"
            :key="`selectText${index}`"
            class="selectText"
            :title="item.label"
            :label="item.label"
            :value="item.value"
          >{{ item.label }}</a-select-option>
        </a-select>
        <div class="selectbutton">
          <a-dropdown type="primary">
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="(item, index) in functionList" :key="index" @click="add(item)">{{ item.name }}</a-menu-item>
              </a-menu>
            </template>
            <a-button prefix="add-one" suffix="left">
              换班
            </a-button>
          </a-dropdown>
        </div>
        <a-button prefix="reduce-one" :disabled="selectedRowKeys.length <= 0" @click="isDelete">
          批量删除
        </a-button>
      </div>
      <!--  添加 搜索 筛选  -->
      <appForm
        :to-list="toList"
        :screen-roster-list="screenRosterList"
        @getSearchCallback="getSearchCallback"
        @screenRosterParams="screenRosterParams"
        @exportFileList="exportFileList"
      />
    </div>
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      table-ref=".employeeschange"
      row-key="changeShiftApplyId"
      :tabs-height="36"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        columnWidth: 30,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.operateType !== 2,
          },
        }),
      }"
      :selection-mode="selectionMode"
      @change="loadDataList"
    >
      <!-- 序号 -->
      <template slot="index" slot-scope="{ scope }">
        <span>{{ scope.index + 1 }}</span>
      </template>
      <!-- 姓名头像 -->
      <template slot="shiftName" slot-scope="{ scope }">
        <CbTableAvater :table-name="scope.record.staffName" :on-job-id="scope.record.onJobId" :staff-id="scope.record.staffId" :title="scope.record.staffName" />
      </template>
      <template slot="changLogShiftNote" slot-scope="{scope}">
        <div :title="scope.record.changLogShiftNote" v-html="scope.record.changLogShiftNote" />
      </template>
      <!-- 数据来源 -->
      <template slot="operateType" slot-scope="{scope}">
        <div :title="getCodeContent(scope.record.operateType,operate_type)">{{ getCodeContent(scope.record.operateType,operate_type) }}</div>
      </template>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{scope}">
        <a-button :disabled="scope.record.operateType===2" type="link" @click="approval(scope.record)">
          审批详情
        </a-button>
      </template>
    </custom-table>
    <CbApproveSteps v-model="stepsVisible" :flow-id="flowId" :apply-status="applyStatus" :apply-status-list="applyStatusList" />
    <!-- 单个添加 -->
    <Modal
      overflow-auto
      closable
      :mask-closable="false"
      :visible="addRecord"
      :title="'添加换班记录'"
      :size="'normal'"
      @cancel="onClose"
      @ok="onSubmin"
    >
      <k-form-build
        ref="KFB"
        :value="singleClass"
        :text-display="true"
        @change="change"
      />
    </Modal>
  </div>
</template>

<script>
const operateTypes = { 1: '换班审批', 2: 'HR添加' }
import mixins from '@/components/CustomTable/index.js'
import moment from 'moment'
import { replaceTime } from '@/utils/reg.js'
import { getStaffApplyChangeShiftListPageApi, deleteStaffApplyChangeShiftApi, insertStaffApplyChangeShiftApi, attendanceGroupNameListApi, getExchangeShiftDescribeApi, exportApplyChangeShiftApi } from '@/services/attendance/scheduling.js'
export default {
  name: 'Employee',
  components: {
    Modal: () => import('@/components/CbModal/index'),
    CbTableAvater: () => import('@/components/CbTableAvater/index'),
    CbApproveSteps: () => import('@/components/CbApproveSteps/index')
  },
  filters: {
    operateTypes(val) {
      return operateTypes[val]
    }
  },
  mixins: [mixins],
  data() {
    return {
      functionList: [
        { name: '单个换班', label: 'addSingle' },
        { name: '批量换班', label: 'addAll' }
      ],
      applyStatusList: {
        1: {
          name: '审批中',
          color: 'rgba(237, 123, 47, 1)',
          bgColor: 'rgba(237, 123, 47, .1)'
        },
        2: {
          name: '已通过',
          color: 'rgba(0, 168, 112, 1)',
          bgColor: 'rgba(0, 168, 112, .1)'
        },
        3: {
          name: '驳回',
          color: 'rgba(227, 77, 89, 1)',
          bgColor: 'rgba(227, 77, 89, .1)'
        }
      },
      applyStatus: '',
      flowId: '',
      stepsVisible: false,
      attendances: [
        {
          value: '',
          label: '全部考勤组'
        }
      ],
      attendance: '',
      text: '批量导入换班记录',
      toList: ['search', 'screen', 'export'], // search搜索框 screen筛选 choice位置选择弹窗 export导出
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'index' },
          width: '50px',
          align: 'center',
          fixed: 'left'
        },
        {
          title: '换班人',
          dataIndex: 'shiftName',
          scopedSlots: { customRender: 'shiftName' },
          width: '105px',
          fixed: 'left'
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          width: '100px',
          fixed: 'left',
          ellipsis: true
        },

        {
          title: '部门',
          dataIndex: 'deptName',
          key: 'deptName',
          width: '100px',
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'positionName',
          key: 'positionName',
          width: '100px',
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '换班来源',
          dataIndex: 'operateType',
          key: 'operateType',
          scopedSlots: { customRender: 'operateType' },
          width: '80px'
        },
        {
          title: '换班日期',
          dataIndex: 'originalDate',
          key: 'originalDate',
          width: '100px',
          ellipsis: true
        },
        {
          title: '替班人',
          dataIndex: 'targetStaffName',
          key: 'targetStaffName',
          width: '100px',
          ellipsis: true
        },
        {
          title: '还班日期',
          dataIndex: 'repayDate',
          key: 'repayDate',
          width: '100px',
          ellipsis: true
        },
        {
          title: '换班班次',
          dataIndex: 'changLogShiftNote',
          key: 'changLogShiftNote',
          scopedSlots: { customRender: 'changLogShiftNote' },
          width: 300,
          ellipsis: true
        },
        {
          title: '换班原因',
          dataIndex: 'applyReason',
          key: 'applyReason',
          width: 200,
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '80px',
          fixed: 'right'
        }
      ],
      selectedRowKeys: [], // 当前选择列集合
      selectedRows: [],
      shiftChangeLogIds: [], // 多选删除时的id数组
      selectionMode: true,
      dataSource: [],
      screenRosterList: {
        'list': [
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择部门',
              'showSearch': false
            },
            'model': 'orgIds',
            'key': 'orgIds',
            'rules': [
              {
                'required': false,
                'message': '请选择部门'
              }
            ]
          },
          {
            'type': 'selectPost',
            'label': '岗位',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择岗位',
              'showSearch': false
            },
            'model': 'postIds',
            'key': 'postIds',
            'rules': [
              {
                'required': false,
                'message': '请选择岗位'
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 80,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      originalDate: '',
      addRecord: false,
      visible: true,
      singleClass: {
        'list': [
          {
            'type': 'html',
            'label': 'HTML',
            'options': {
              'hidden': false,
              'defaultValue': '<p style="color: darkorange">提示：仅支持同一考勤组员工进行调班，调班结果将实时更新到员工排班表</p>'
            },
            'key': 'html_1656041251846'
          },
          {
            'type': 'selectStaff',
            'label': '换班人',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择换班人',
              'showSearch': true
            },
            'model': 'onJobId',
            'key': 'onJobId',
            'rules': [
              {
                'required': true,
                'message': '请选择换班人'
              }
            ]
          },
          {
            'type': 'selectStaff',
            'label': '替班人',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择替班人',
              'showSearch': true
            },
            'model': 'targetOnJobId',
            'key': 'targetOnJobId',
            'rules': [
              {
                'required': true,
                'message': '请选择替班人'
              }
            ]
          },
          {
            'type': 'date',
            'label': '换班日期',
            'options': {
              'width': '100%',
              'defaultValue': '',
              'rangeDefaultValue': [],
              'range': false,
              'showTime': false,
              'disabled': false,
              'hidden': false,
              'clearable': false,
              'placeholder': '请选择换班日期',
              'rangePlaceholder': [
                '开始时间',
                '结束时间'
              ],
              'format': 'YYYY-MM-DD'
            },
            'model': 'originalDate',
            'key': 'originalDate',
            'help': '',
            'rules': [
              {
                'required': true,
                'message': '请选择换班日期'
              }
            ]
          },
          {
            'type': 'input',
            'label': '',
            'options': {
              'type': 'text',
              'width': '100%',
              'defaultValue': '',
              'placeholder': '--',
              'clearable': false,
              'maxLength': null,
              'addonBefore': '',
              'addonAfter': '',
              'hidden': false,
              'disabled': true
            },
            'model': 'shiftShortName',
            'key': 'shiftShortName',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'date',
            'label': '还班日期',
            'options': {
              'width': '100%',
              'defaultValue': '',
              'rangeDefaultValue': [],
              'range': false,
              'showTime': false,
              'disabled': false,
              'hidden': false,
              'clearable': false,
              'disabledDate': this.disabledDate,
              'placeholder': '请选择还班日期',
              'rangePlaceholder': [
                '开始时间',
                '结束时间'
              ],
              'format': 'YYYY-MM-DD'
            },
            'model': 'repayDate',
            'key': 'repayDate',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': '请选择还班日期'
              }
            ]
          },
          {
            'type': 'input',
            'label': '',
            'options': {
              'type': 'text',
              'width': '100%',
              'defaultValue': '',
              'placeholder': '--',
              'clearable': false,
              'maxLength': null,
              'addonBefore': '',
              'addonAfter': '',
              'hidden': false,
              'disabled': true
            },
            'model': 'repayShiftShortName',
            'key': 'repayShiftShortName',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'input',
            'label': '换班班次',
            'options': {
              'type': 'text',
              'width': '100%',
              'defaultValue': '',
              'placeholder': '--',
              'clearable': false,
              'maxLength': null,
              'addonBefore': '',
              'addonAfter': '',
              'hidden': false,
              'disabled': true
            },
            'model': 'exchangeShiftDescribe',
            'key': 'exchangeShiftDescribe',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'input',
            'label': '还班班次',
            'options': {
              'type': 'text',
              'width': '100%',
              'defaultValue': '',
              'placeholder': '--',
              'clearable': false,
              'maxLength': null,
              'addonBefore': '',
              'addonAfter': '',
              'hidden': false,
              'disabled': true
            },
            'model': 'repayShiftDescribe',
            'key': 'repayShiftDescribe',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'textarea',
            'label': '换班原因',
            'options': {
              'width': '100%',
              'minRows': 4,
              'maxRows': 6,
              'maxLength': 300,
              'defaultValue': '',
              'clearable': false,
              'hidden': false,
              'disabled': false,
              'placeholder': '请输入换班原因'
            },
            'model': 'applyReason',
            'key': 'applyReason',
            'help': '',
            'rules': [
              {
                'required': true,
                'message': '请输入换班原因'
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      // 控制批量导入的显隐
      implicit: false,
      addForm: {},
      // 字典项
      operate_type: []
    }
  },
  mounted() {
  },
  async created() {
    this.operate_type = await this.getTenantCode('operate_type')
    this.attendanceGroupNameList()
  },
  methods: {
    disabledDate(current) {
      return current <= moment(this.originalDate).endOf('day')
    },
    // 获取班次信息
    enquiries(data) {
      if (data.onJobId && data.targetOnJobId) {
        getExchangeShiftDescribeApi({ ...data }).then(res => {
          if (res.code === 100) {
            this.$refs.KFB.setData({ ...res.data })
          }
        })
      }
    },
    change(val, key) {
      if (key !== 'applyReason') {
        this.addForm[key] = val
        this.enquiries(this.addForm)
      }
    },
    // 考勤组列表
    attendanceGroupNameList() {
      attendanceGroupNameListApi().then(res => {
        res.data.forEach(item => {
          item.value = item.attendanceGroupId
          item.label = item.attendanceGroupName
        })
        this.attendances.push(...res.data)
      })
    },
    async fetchDataApiFn(param) {
      const res = await getStaffApplyChangeShiftListPageApi({ ...param, recordType: 'exchange', attendanceGroupId: this.attendance })
      console.log(res)
      this.dataSource = res.data.records ? res.data.records : []
      this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
      this.total = res.data.total || 0
      this.dataSource.forEach(item => {
        item.originalDate = item.originalDate.replace(replaceTime, '$1-$2-$3')
        if (item.repayDate) {
          item.repayDate = item.repayDate.replace(replaceTime, '$1-$2-$3')
        }
      })
    },
    // 搜索
    getSearchCallback(val) {
      this._queryParam.searchWord = val.searchValue
      this.serchGetData()
    },
    // 筛选
    screenRosterParams(val) {
      console.log(val)
      this._queryParam.searchWord = val.searchValue
      const orgIds = []
      if (val.screenCondition.orgIds !== undefined) {
        val.screenCondition.orgIds.forEach(item => {
          orgIds.push(item.value)
        })
        this._queryParam.orgIds = orgIds.join(',')
      }
      if (val.screenCondition.postIds !== undefined) {
        this._queryParam.postIds = val.screenCondition.postIds.join(',')
      }
      for (var key in this._queryParam) {
        if (!this._queryParam[key]) {
          delete this._queryParam[key]
        }
      }
      this.serchGetData()
    },
    // 导出
    exportFileList() {
      const { searchWord } = this._queryParam
      exportApplyChangeShiftApi({ searchWord: searchWord, recordType: 'exchange', attendanceGroupId: this.attendance })
    },
    isDelete() { // 删除
      this.$Reconfirm({
        title: '删除',
        content: '确定要删除该调班记录吗?'
      }).then(() => {
        deleteStaffApplyChangeShiftApi({ changeShiftApplyIds: this.selectedRowKeys }).then(res => {
          if (res.code === 100) {
            this.$message.success('删除成功')
            this.selectedRowKeys = []
            this.serchGetData()
          }
        })
      }).catch(() => {
        this.$message.info('已取消删除')
      })
    },
    // 表格选择项变更
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    // 审批详情
    approval(approvaldata) {
      console.log(approvaldata)
      this.flowId = approvaldata.flowId
      this.applyStatus = approvaldata.applyStatus
      this.stepsVisible = true
    },
    // 新增
    add(val) {
      if (val.label === 'addSingle') {
        this.addRecord = true
      } else {
        this.dragValue = true
        this.addRecord = false
        this.$emit('bulkShift', this.implicit, this.text, 2)
      }
    },
    // 添加换班记录
    async onSubmin() { // 确认
      const params = await this.$refs.KFB.getData() // 获取表单参数
      params.originalDate = params.originalDate.split('-').join('')
      params.repayDate = params.repayDate.split('-').join('')
      insertStaffApplyChangeShiftApi({ ...params, targetDate: params.originalDate, recordType: 'exchange' }).then(res => {
        if (res.code === 100) {
          this.$message.success('添加成功')
          this.serchGetData()
          this.onClose()
        }
      })
    },
    onClose() {
      this.addRecord = false
      this.$refs.KFB.reset()
      this.singleClass.list.forEach(item => {
        item.options.defaultValue = ''
      })
      this.addForm = {}
    },
    goBack() {
      this.implicit = false
      this.$emit('goBack', this.implicit)
    },
    // 考勤组发生改变
    attendanceChange() {
      this.serchGetData()
    }
  }
}
</script>

<style lang="less" scoped>
.employeeschange{
  height: 100%;
  background-color: @base-bg-color;
}
.change-toobar{
  display: flex;
  justify-content: space-between;
  .selectbutton{
    margin-right: 12px;
  }
  .toobar-left{
    display: flex;
    justify-content: flex-start;
     /deep/ .ant-select-dropdown {
        top: 85px !important;
      }
  }
}
</style>
