<template>
  <div class="proview-min">
    <div class="receiver">
      <div class="long-view" />
      <div class="short-view" />
    </div>
    <div class="screen-view">
      <div class="head-view">
        <div class="head-title">工资条</div>
        <div class="head-centent">{{ time }}</div>
        <div class="head-main">
          <div class="main-item">
            <div class="main-item-num">8888.88</div>
            <div class="main-item-label">实发工资(元)</div>
          </div>
          <div class="main-item">
            <div class="main-item-num">88.88</div>
            <div class="main-item-label">个人所得税(元)</div>
          </div>
        </div>
      </div>
      <div v-if="tipsSwitch" class="tips-view">
        <div class="tip-text">
          温馨提示：
          {{ tipsContent }}
        </div>
      </div>
      <div v-if="salaryList.length !== 0" class="salary-list">
        <div v-for="(item, index) in salaryList" :key="index" class="salary-item">
          <div class="salary-title">{{ item.className }}</div>
          <div class="salary-outer">
            <div v-for="(salary, sindex) in item.list" :key="sindex + '_' + index" class="salary-text">
              <div class="salary-label">{{ salary.salaryitemname }}</div>
              <div class="salary-value">888</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="nodata">
        暂无数据~
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'ProviewMin',
  components: {},
  props: {
    tipsContent: {
      type: String,
      default: ''
    },
    tipsSwitch: {
      type: Boolean,
      default: true
    },
    salaryList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      time: moment().format('YYYY年MM月')
    }
  },
  created() { },
  methods: { }
}
</script>

<style lang="less" scoped>
@iponeColor: #E9EEF7;
.proview-min {
  height: 700px;
  width: 380px;
  border-radius: 50px;
  border: 5px solid @iponeColor;
  padding: 24px 15px 20px 15px;
  position: relative;
  .receiver {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 7px;
    display: flex;
    align-items: center;
    .long-view {
      width: 51px;
      height: 6px;
      border-radius: 5px;
      background: @iponeColor;
      margin-right: 12px;
    }
    .short-view {
      border-radius: 50%;
      height: 12px;
      width: 12px;
      background: @iponeColor;
    }
  }
  @distanceTop: 40px;
  .screen-view {
    height: 100%;
    width: 100%;
    background: #F3F5F9;
    border-radius: 20px;
    overflow: auto;
    padding-bottom: 14px;
    &::-webkit-scrollbar {
      width: 0;
    }
    .head-view {
      width: 100%;
      height: 120px;
      background: url('~@/assets/img/payBg.png') no-repeat;
      background-size: cover;
      color: #ffff;
      position: relative;
      padding: 0 12px;
      margin-bottom: @distanceTop;
      .head-title {
        text-align: center;
        padding-bottom: 12px;
        padding-top: 16px;
        .font-size(18px);
      };
      .head-centent {
        text-align: center;
        .font-size(16px);
      }
      .head-main {
        position: absolute;
        bottom: -@distanceTop;
        left: 12px;
        right: 12px;
        display: flex;
        justify-content: space-between;
        padding: 10px 44px;
        background: #fff;
        border-radius: 6px;
        .main-item {
          > div {
            text-align: center;
          }
          .main-item-num {
            .font-size(22px);
            color: @sc-grey-100;
          }
          .main-item-label {
            .font-size(14px);
            color: @sc-grey-60;
          }
        }
      }
    }
    .tips-view {
      width: 100%;
      padding: 12px 12px 0;
      .tip-text {
        background: rgba(237,123,47,0.1);
        color: @sc-grey-100;
        padding: 6px 12px;
        font-size: 14px;
        border-radius: 6px;
        word-break: break-all;
      }
    }
    .salary-list {
      .salary-item {
        .salary-title {
          color: @sc-grey-80;
          font-size: 16px;
          padding: 14px 12px 7px;
          font-weight: 700;
        }
        .salary-outer {
          padding: 0 12px;
          background: #fff;
        }
        .salary-text {
          display: flex;
          justify-content: space-between;
          padding: 12px 0;
          .font-size(16px);
          border-bottom: 1px solid @sc-greyBg-20;
          .salary-label {
            color: @sc-grey-60;
          }
          .salary-value {
            color: @sc-grey-100;
          }
          &:last-child {
            border: none;
          }
        }
      }
    }
    .nodata {
      padding-top: 50px;
      text-align: center;
      color: @sc-grey-20;
      font-size: 16px;
    }
  }
}
</style>
