var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-info-p"},[_c('div',{staticClass:"user-info-header"},[_vm._m(0),_c('div',{staticClass:"user-info-header-right"},[_c('a-button',{attrs:{"type":"link","prefix":"preview-open"},on:{"click":_vm.preview}},[_vm._v("预览入职登记表")]),_c('a-button',{attrs:{"type":"primary","prefix":"add-one"},on:{"click":_vm.addGroup}},[_vm._v(" 添加字段组 ")])],1)]),_c('div',[(_vm.infoList.length > 0)?_c('a-collapse',{attrs:{"default-active-key":(function(list) {
          return list.map(function (v) { return v.fieldGroupId; })
        })(_vm.infoList)},scopedSlots:_vm._u([{key:"expandIcon",fn:function(props){return [_c('a-icon',{attrs:{"type":"caret-right","rotate":props.isActive ? 90 : -90}})]}}],null,false,1437070953)},_vm._l((_vm.infoList),function(item,infoIndex){return _c('a-collapse-panel',{key:item.fieldGroupId,attrs:{"header":item.fieldGroupName}},[_c('div',{staticClass:"tb-body"},[_c('customTable',{attrs:{"columns":_vm.columns,"data-source":(function(item) {
                return item.content.list[0].columns.map(function (v) {
                  return Object.assign({}, v.list[0],
                    {fieldName: v.list[0].fieldName,
                    fieldType: v.list[0].fieldType,
                    system: v.list[0].fieldId,
                    staff: v.list[0].fieldId,
                    improve: v.list[0].fieldId})
                })
              })(item),"row-key":'model',"pagination":false},scopedSlots:_vm._u([{key:"fieldType",fn:function(ref){
              var scope = ref.scope;
return [_vm._v(" "+_vm._s(scope.record.fieldType == 1 ? '文本' : scope.record.fieldType == 2 ? '日期' : scope.record.fieldType == 3 ? '选项' : '')+" ")]}},{key:"system",fn:function(ref){
              var scope = ref.scope;
return [(scope.record.manageEdit)?_c('a-badge',{attrs:{"color":_vm.theme.warning,"text":"编辑"}}):_vm._e(),(scope.record.manageShow)?_c('a-badge',{attrs:{"color":_vm.theme.success,"text":"可见"}}):_vm._e(),(scope.record.manageRequire)?_c('a-badge',{attrs:{"color":_vm.theme.error,"text":"必填"}}):_vm._e()]}},{key:"staff",fn:function(ref){
              var scope = ref.scope;
return [(scope.record.staffEdit)?_c('a-badge',{attrs:{"color":_vm.theme.warning,"text":"编辑"}}):_vm._e(),(scope.record.staffShow)?_c('a-badge',{attrs:{"color":_vm.theme.success,"text":"可见"}}):_vm._e(),(scope.record.staffRequire)?_c('a-badge',{attrs:{"color":_vm.theme.error,"text":"必填"}}):_vm._e()]}},{key:"improve",fn:function(ref){
              var scope = ref.scope;
return [(scope.record.entryFormEdit)?_c('a-badge',{attrs:{"color":_vm.theme.warning,"text":"编辑"}}):_vm._e(),(scope.record.entryFormShow)?_c('a-badge',{attrs:{"color":_vm.theme.success,"text":"可见"}}):_vm._e(),(scope.record.entryFormRequire)?_c('a-badge',{attrs:{"color":_vm.theme.error,"text":"必填"}}):_vm._e()]}},{key:"operation",fn:function(ref){
              var scope = ref.scope;
return [(scope.record.createType === 1 || scope.record.editable === '1')?_c('div',{staticClass:"flex-align-center"},[_c('span',{staticClass:"operation-btn-box"},[(scope.record.createType === 1 || scope.record.editable === '1')?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.lineEdit(scope.record, infoIndex)}}},[_vm._v(" 编辑 ")]):_vm._e(),_c('a-button',{staticClass:"delete",attrs:{"type":"link","disabled":scope.record.createType === 0},on:{"click":function($event){return _vm.lineDelete(scope.record)}}},[_vm._v(" 删除 ")])],1)]):_vm._e()]}}],null,true)})],1),_c('template',{slot:"extra"},[_c('a-popover',{attrs:{"placement":"bottom","trigger":"hover"}},[_c('template',{slot:"content"},[_c('div',{staticClass:"more-handle-btn"},[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){$event.stopPropagation();return _vm.add(item.fieldGroupId)}}},[_vm._v("添加字段")]),(item.editable === '1')?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){$event.stopPropagation();return _vm.setting(item)}}},[_vm._v(" 设置 ")]):_vm._e(),(item.createType === 1)?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){$event.stopPropagation();return _vm.editHeader(item)}}},[_vm._v(" 编辑 ")]):_vm._e(),(item.createType === 1)?_c('a-button',{staticClass:"delete",attrs:{"type":"link"},on:{"click":function($event){$event.stopPropagation();return _vm.delHeader(item)}}},[_vm._v(" 删除 ")]):_vm._e()],1)]),_c('div',{staticClass:"more-handle",on:{"click":_vm.handleClick}},[_vm._v(" ··· ")])],2)],1)],2)}),1):_vm._e()],1),_c('registrationCode',{ref:"registrationCode"}),_c('addGroupModel',{ref:"addGroupModel",on:{"reload":_vm.initData}}),_c('addSettingModel',{ref:"addSettingModel",on:{"reload":_vm.initData}}),_c('settingModel',{ref:"settingModel",on:{"reload":_vm.initData}}),_c('editHeader',{ref:"editHeader",on:{"reload":_vm.initData}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-info-header-left"},[_c('div',{staticClass:"user-info-header-left-t"},[_vm._v("员工档案字段设置")]),_c('div',{staticClass:"user-info-header-left-b"},[_vm._v(" 员工信息字段可应用于花名册、员工端个人详情页、入职登记表等员工信息页面 ")])])}]

export { render, staticRenderFns }