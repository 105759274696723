<template>
  <div class="user-setting-panel">
    <a-tabs :default-active-key="defaultActiveKey" @change="callback">
      <a-tab-pane v-for="(key, index) in menuDataList" :key="index" :tab="key.name" />
    </a-tabs>
    <component :is="curComponentName" />
    <!-- <layout ref="layoutMenu" :menu-data-list="menuDataList" :selected-key.sync="selectedKey" @menu-select-change="menuSelectChange">
      <template #leftTop>
        <div class="left-top-p" />
      </template>
      <component :is="curComponentName" />
    </layout> -->
  </div>
</template>

<script>
import layout from '@/components/CbLayoutMenu/index'
import common from './common'
export default {
  components: {
    layout,
    ...common
  },
  data() {
    return {
      defaultActiveKey: 0,
      curComponentName: 'personnelReport',
      menuDataList: [
        {
          id: '1',
          name: '人员报送',
          frontIcon: '#people-top',
          fileName: 'personnelReport',
          actions: []
        },
        {
          id: '2',
          name: '个税申报',
          frontIcon: '#credit',
          fileName: 'subscribe',
          actions: []
        }
        // {
        //   id: '3',
        //   name: '税款缴纳',
        //   frontIcon: '#insert-card',
        //   fileName: 'taxPayment',
        //   actions: []
        // }
      ]
    }
  },
  mounted() {
    // this.$refs.layoutMenu.showInit(1)
    const period = this.$route.params.period
    if (period) {
      this.defaultActiveKey = 1
    } else {
      this.defaultActiveKey = 0
    }
  },
  methods: {
    menuSelectChange(item) {
      this.curComponentName = item.fileName
    },
    callback(activeKey) {
      this.curComponentName = this.menuDataList[activeKey].fileName
      this.defaultActiveKey = activeKey
    }
  }
}
</script>

<style lang="less" scoped>
.user-setting-panel {
  min-height: 100%;
  background: @gray-1;
  padding: 20px 0;
}
/deep/.ant-tabs{
  padding:0 20px;
}
/deep/ .ant-tabs-extra-content {
  line-height: 30px;
}
// /deep/ .ant-tabs-nav-scroll {
//   margin-top: -3px;
// }
/deep/ .ant-tabs-tab {
  color: @sc-grey-100;
  padding-top: 0;
  font-size: 16px;
  &:hover {
    color: @sc-primary-100;
  }
}
/deep/ .ant-tabs-tab-active {
  color: @sc-primary-100;
}
</style>
