<template>
  <div>
    <div class="calendar-header">
      <a-icon
        type="left"
        :style="`color: ${canSelectPreMounth ? '#d9d9d9' : ''}`"
        @click="perviousMonth"
      />
      <div class="timePicker">
        <svg class="iconpark-icon" @click="isMonthPicker = true"><use href="#rongqi" /></svg>
        <a-month-picker
          v-model="month"
          :disabled-date="disabledMonth"
          value-format="YYYYMM"
          :format="`YYYY年MM月${titleText}`"
          :open="isMonthPicker"
          :get-calendar-container="trigger => {return trigger.parentNode}"
          @change="monthChange"
          @openChange="isMonthPicker = false"
        />
      </div>
      <a-icon
        key="next"
        :style="`color: ${canSelectNextMounth ? '#d9d9d9' : ''}`"
        type="right"
        @click="nextMonth"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {
  getSalaryPeriodAll
} from '@/services/wage/wagePayroll'
export default {
  name: 'MonthPicker',
  model: {
    prop: 'monthValue',
    event: 'change'
  },
  props: {
    monthValue: {
      type: String,
      default: ''
    },
    titleText: {
      type: String,
      default: '人员报送'
    }
  },
  data() {
    return {
      canUseMounthList: [],
      month: '',
      isMonthPicker: false
    }
  },
  computed: {
    showMounthList() {
      return this.canUseMounthList.map((item) => {
        return item.payPeriod
      })
    },
    canSelectPreMounth() {
      if (this.showMounthList.length === 0) return true
      return this.showMounthList[0] === this.time
    },
    canSelectNextMounth() {
      if (this.showMounthList.length === 0) return true
      return this.showMounthList[this.showMounthList.length - 1] === this.time
    }
  },
  watch: {
    monthValue: {
      handler(newval) {
        this.month = newval
      },
      immediate: true
    }
  },
  mounted() {
    this.getSalaryPeriodAll()
  },
  methods: {
    // 上一月
    perviousMonth() {
      const time = moment(this.monthValue).subtract(1, 'month').format('YYYYMM')
      this.$emit('change', time)
    },
    // // 下一月
    nextMonth() {
      if (this.isNext) return
      const time = moment(this.monthValue).add(1, 'month').format('YYYYMM')
      this.$emit('change', time)
    },
    disabledMonth(current) {
      return !this.showMounthList.includes(moment(current).format('YYYYMM'))
    },
    monthChange(date) {
      this.$emit('change', date)
      console.log('2222')
    },
    async getSalaryPeriodAll() {
      const { data } = await getSalaryPeriodAll()
      this.canUseMounthList = data
      this.$emit('change', data[0].currentPayPeriod)
    }
  }
}
</script>
<style lang="less" scoped>
  .calendar-header{
    width: auto;
    margin:0 0 20px 0 ;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 30px;
    /deep/i.anticon{
      font-size: 16px;
      margin: 0 30px;
      color: @sc-grey-100;
      &:last-child{
        margin:0
      }
    }
    .timePicker{
      font-size: 16px;
      display: flex;
      align-items: center;
      .ant-calendar-picker {
        /deep/ .ant-input {
          padding-right: 0;
        }
        /deep/.ant-calendar-picker-input {
          border: 0;
          font-size: 16px;
          padding-left: 5px;
          color: @sc-grey-100;
          font-weight: 600;
        }
        /deep/.ant-calendar-picker-clear,
        /deep/.ant-calendar-picker-icon {
          display: none;
        }
        /deep/.ant-input:focus{
          box-shadow: unset;
        }
      }
      .iconpark-icon {
        width: 15px;
        height: 15px;
        color: @sc-primary-100;
        cursor: pointer;
      }
    }
    .disableColor {
      color: @sc-grey-40 !important;
      cursor: no-drop;
    }
  }
/deep/ .ant-calendar-picker-container {
  top: 32px !important;
  left: -64px !important;
}
</style>
