<!--
 * @Author: luoyi
 * @Date: 2022-02-22 10:32:12
 * @Last Modified by:   luoyi
 * @Last Modified time: 2022-02-22 10:32:12
-->
<template>
  <Modal
    :closable="true"
    :visible="visible"
    :size="'normal'"
    :title="(mode == 'add' ? '新建' : '编辑') + '岗位分类'"
    :mask-closable="false"
    :ok-text="'保存'"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="分类名称" prop="postTypeName">
        <a-input v-model="form.postTypeName" placeholder="请输入1-10个字符" />
      </a-form-model-item>
      <a-form-model-item label="分类说明" prop="detail">
        <a-input
          v-model="form.detail"
          type="textarea"
          placeholder="请输入0-200个字符"
          :auto-size="{ minRows: 3, maxRows: 7 }"
        />
      </a-form-model-item>
    </a-form-model>
  </Modal>
</template>

<script>
import {
  postCreatePostType,
  postUpdatePostType
} from '@/services/organization/post.js'
export default {
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      visible: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      form: {
        postTypeId: null,
        postTypeName: '',
        detail: ''
      },
      rules: {
        postTypeName: [
          {
            required: true,
            message: '请输入分类名称',
            trigger: 'blur'
          },
          { min: 1, max: 10, message: '请输入1-10个字符', trigger: 'change' }
        ],
        detail: [
          { min: 0, max: 200, message: '请输入0-200个字符', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    mode() {
      // 根据id自动计算新增、编辑
      return this.form.postTypeId ? 'update' : 'add'
    }
  },
  methods: {
    open(data) {
      if (data) {
        this.form = Object.assign(this.form, data)
      }
      this.visible = true
    },
    // 保存事件
    handleOk() {
      // eslint-disable-next-line space-before-function-paren
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.mode === 'add') {
            const res = await postCreatePostType(this.form)
            if (res.code === 100) {
              this.$message.success('新增成功')
              this.handleCancel()
              this.$emit('reload')
            }
          } else {
            const res = await postUpdatePostType(this.form)
            if (res.code === 100) {
              this.$message.success('编辑成功')
              this.handleCancel()
              this.$emit('reload')
            }
          }
        }
      })
    },
    // 取消事件
    handleCancel() {
      this.visible = false
      this.resetForm()
    },
    // 重置表单内容和验证
    resetForm() {
      this.form = {
        postTypeId: null,
        postTypeName: '',
        detail: ''
      }
      this.$refs.ruleForm.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
</style>
