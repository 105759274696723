<!--信息记录-->
<template>
  <div class="infomation-record-p">
    <div class="infomation-anchor">
      <Anchor
        v-if="showAnchor"
        ref="anchor"
        :title-list="anchorList"
        container=".infomation-record"
        :position="{ top: 20, left: 35, position: 'absolute' }"
      />
    </div>
    <div ref="personInfo" class="infomation-record beauty-scroll" :style="{ height: anchorHeight() }">
      <div v-for="(item, index) in infoList" :key="index" class="com-panel">
        <!--标题-->
        <div :id="'grid_' + item.fieldGroupId" class="com-panel-head">
          <BlockTitle :title="item.fieldGroupName">
            <template slot="center">
              <div class="com-head-divide" />
            </template>
            <template slot="right">
              <div v-if="!item.isEdit && item.multiple == 0 && statusType !== 'resigned'" class="com-head-edit">
                <a-button type="link" @click="openEdit(item)">编辑</a-button>
              </div>
            </template>
          </BlockTitle>
          <!-- <div class="com-head-title">{{ item.fieldGroupName }}</div>
          <div class="com-head-divide" />
          <div v-if="!item.isEdit && item.multiple == 0 && statusType !== 'resigned'" class="com-head-edit">
            <a-button type="link" @click="openEdit(item)">编辑</a-button>
          </div> -->
        </div>
        <div>
          <div class="noDataImg_box">
            <NoData v-if="item.fieldGroupId == 1 && staffGrowthList.length === 0" />
            <NoData v-if="item.fieldGroupId == 2 && staffTrainList.length === 0" />
            <NoData v-if="item.fieldGroupId == 3 && staffContractList.length === 0" />
          </div>
          <CbTimeLine v-if="item.fieldGroupId == 1" :data="staffGrowthList">
            <div slot="timeNodeItem" slot-scope="scope">
              <p v-for="(itemm, indexx) in scope.row.reason.split('*****')" :key="'growth' + indexx">
                {{ itemm || '' }}
              </p>
            </div>
          </CbTimeLine>
          <CbTimeLine v-if="item.fieldGroupId == 2" :data="staffTrainList">
            <div slot="timeNodeItem" slot-scope="scope">
              <div v-if="staffTrainList.length > 0">
                <p>培训单位：{{ scope.row.trainAgency || '' }}</p>
                <p>证书名称：{{ scope.row.certificateName || '' }}</p>
                <p>证书编号：{{ scope.row.certificateCode || '' }}</p>
                <p>培训时长：{{ scope.row.trainTime === '暂无数据' ? '暂无数据' : scope.row.trainTime + '小时' || '' }}</p>
              </div>
            </div>
          </CbTimeLine>
          <CbTimeLine v-if="item.fieldGroupId == 3" :data="staffContractList">
            <div slot="timeNodeItem" slot-scope="scope">
              <div v-if="staffContractList.length > 0">
                <p>{{ scope.row.contractTerm === '暂无数据' ? `合同期限${scope.row.contractTerm}` : `合同期限：${scope.row.contractTerm}` }} | {{ scope.row.signDate === '暂无数据' ? `签订日期${scope.row.signDate}`: `${scope.row.signDate}签订` }}</p>
                <p>合同类型：{{ scope.row.contractTypeName || '' }}</p>
                <p>合同状态：{{ scope.row.contractStatusName || '' }}</p>
                <p>合同编号：{{ scope.row.contractCode || '' }}</p>
              </div>
            </div>
          </CbTimeLine>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import {
//   updateStaffInfo
// } from '@/services/user/roster.js'
import Anchor from '@/components/CbAnchor/index.js'
import CbTimeLine from '@/components/CbTimeLine/index'
import NoData from './noData.vue'
import { getStaffGrowthList, getStaffTrainBefore, getStaffContract } from '@/services/user/roster.js'
export default {
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index'),
    Anchor,
    CbTimeLine,
    NoData
  },
  props: {
    userId: {
      type: String,
      default: ''
    },
    statusType: {
      type: String,
      default: ''
    },
    onJobId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      showAnchor: false, // 是否展示锚点导航
      infoList: [],
      staffGrowthList: [], // 成长记录
      staffTrainList: [], // 培训记录
      staffContractList: [], // 合同记录
      timeLineData: [],
      noDataImg: require('@/assets/img/home/noData.png')
    }
  },
  inject: ['anchorHeight'],
  computed: {
    // eslint-disable-next-line space-before-function-paren
    anchorList: function () {
      return this.infoList.map((v) => {
        return { id: `grid_${v.fieldGroupId}`, label: v.fieldGroupName }
      })
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    anchorShow() {
      this.$nextTick(() => {
        this.showAnchor = true
      })
    },
    async initData() {
      this.infoList = [
        {
          fieldGroupId: '1',
          fieldGroupName: '成长记录',
          children: []
        },
        {
          fieldGroupId: '2',
          fieldGroupName: '培训记录',
          children: []
        },
        {
          fieldGroupId: '3',
          fieldGroupName: '合同记录',
          children: []
        }
      ]
      // 个人成长
      const res = await getStaffGrowthList({ onJobId: this.onJobId })
      if (res.code === 100) {
        this.staffGrowthList = this.timeLineFormat(res.data)
      }
      // 培训记录
      const rest = await getStaffTrainBefore({ onJobId: this.onJobId })
      if (rest.code === 100) {
        rest.data.forEach(item => {
          for (const i in item) {
            if (Object.prototype.toString.call(item[i]).slice(8, -1) === 'Null' || item[i] === '') {
              item[i] = '暂无数据'
            }
          }
        })
        this.staffTrainList = this.timeLineFormatTrain(rest.data)
      }
      // 合同记录
      const resc = await getStaffContract({ onJobId: this.onJobId })
      if (resc.code === 100) {
        resc.data.forEach(item => {
          for (const i in item) {
            if (Object.prototype.toString.call(item[i]).slice(8, -1) === 'Null' || item[i] === '') {
              item[i] = '暂无数据'
            }
          }
        })
        this.staffContractList = this.timeLineFormatContract(resc.data)
      }

      this.anchorShow()
    },
    // 格式化数据成长记录
    timeLineFormat(val) {
      return val.map((item) => {
        // 入职
        if (item.type === 1) {
          return {
            time: item.effectiveDate,
            title: '入职',
            reason: `部门：${item.orgName ? item.orgName : '暂无'}*****岗位：${item.postName ? item.postName : '暂无'}*****职级：${item.rankName ? item.rankName : '暂无'}`
          }
          // 转正
        } else if (item.type === 2) {
          return {
            time: item.effectiveDate,
            title: '转正',
            reason: `试用期：${item.probation ? item.probation : '暂无'}*****转正评价：${item.postName ? item.postName : '暂无'}`
          }
        } else if (item.type === 3) {
          return {
            time: item.effectiveDate,
            title: '调动',
            reason: `部门：${item.beforeOrgName ? item.beforeOrgName : '暂无'}调整至${item.afterOrgName ? item.afterOrgName : '暂无'}*****
                     职级：${item.beforeJobGradeName ? item.beforeJobGradeName : '暂无'}调整至${item.afterJobGradeName ? item.afterJobGradeName : '暂无'}*****
                    工作地点：${item.beforeWorkplaceName ? item.beforeWorkplaceName : '暂无'}调整至${item.afterWorkplaceName ? item.afterWorkplaceName : '暂无'}`
          }
        } else if (item.type === 4) {
          return {
            time: item.effectiveDate,
            title: '离职',
            reason: `离职原因：${item.leaveReasonsName ? item.leaveReasonsName : '暂无'}`
          }
        }
      })
    },
    // 格式化培训记录
    timeLineFormatTrain(val) {
      return val.map((item) => {
        return {
          ...item,
          time: `${item.startDate} - ${item.endDate}`,
          title: `培训名称：${item.trainName}  培训成绩：${item.trainScore}`
        }
      })
    },
    // 格式化合同记录
    timeLineFormatContract(val) {
      return val.map((item) => {
        return {
          ...item,
          time: `${item.startDate} - ${item.endDate}`,
          title: `${item.companyName} `
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.infomation-record-p {
  display: flex;
  .infomation-anchor {
    position: relative;
    width: 180px;
    padding-left: 20px;
  }
  .infomation-record {
    // max-height: 600px;
    overflow-y: auto;
    padding-left: 20px;
    flex: 1;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .com-panel {
    .com-panel-head {
      padding: 20px 0;
      .flex-between-center();
      .com-head-title {
        position: relative;
        padding-left: 7px;
        color: @sc-grey-100;
        margin-right: 10px;
        .font-size(16px);
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 3px;
          height: 14px;
          background: @sc-primary-100;
        }
      }
      .com-head-divide {
        flex: 1;
        height: 1px;
        background-color: @sc-grey-20;
      }
      .com-head-edit {
        margin-left: 10px;
        .ant-btn-link {
          color: @sc-primary-60;
          padding: 0;
          height: auto;
        }
      }
    }
  }
}
.noDataImg_box{
  width: 200px;
  div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
  }
}

</style>
