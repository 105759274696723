<!--
 * @Date: 2022-07-05 10:00:57
 * @descriotion:
-->
<template>
  <div>
    <div v-if="!exportShow" class="viewBill">
      <div class="header-back">
        <a-button prefix="left" @click="back">返回</a-button>
        <div class="header-back-title">{{ billParam.month.split('-')[0] }}年 {{ billParam.month.split('-')[1] }}月 {{ billParam.companyName }}</div>
      </div>
      <div class="list">
        <ul>
          <li v-for="(item, index) in personsNum" :key="index" class="personNum" :class="{ 'active': active === index }" @click="active = index">
            <p>{{ item.value }}</p>
            <p>{{ item.label }}</p>
          </li>
        </ul>
        <ul>
          <li v-for="(item, index) in summaryMoney" :key="index" class="personNum1">
            <p>{{ item.value | stateFormatNum }}</p>
            <p>{{ item.label }}({{ item.unit }})</p>
          </li>
        </ul>
      </div>
      <Table ref="table" :active="active" :bill-param="billParam" @exportBatchSupply="exportBatchSupply" />
    </div>
    <ExcelImport v-else title="导入补缴调差" :bill-param="billParam" import-template="exportBatchSupply" @goBack="goBack" />
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import { getCompanyBillDetailApi } from '@/services/insurance/bill'
import { stateFormatNum } from '@/utils/num-handle.js'
export default {
  name: 'ViewBill',
  filters: {
    stateFormatNum
  },
  components: {
    Table: () => import('./table.vue'),
    ExcelImport: () => import('@/components/CbBatchImport/newImport.vue')
  },
  mixins: [mixins],
  props: {
    billParam: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      personsNum: [
        {
          label: '缴纳人数',
          value: '-',
          type: 'all'
        },
        {
          label: '本月增员',
          value: '-',
          type: 'add'
        },
        {
          label: '本月减员',
          value: '-',
          type: 'del'
        },
        {
          label: '本月补缴',
          value: '-',
          type: 'sup'
        }
      ],
      active: 0,
      summaryMoney: [
        {
          label: '合计金额',
          value: '-',
          unit: '元'
        },
        {
          label: '本月公司缴纳',
          value: '-',
          unit: '元'
        },
        {
          label: '本月个人缴纳',
          value: '-',
          unit: '元'
        },
        {
          label: '公司补缴',
          value: '-',
          unit: '元'
        },
        {
          label: '个人补缴',
          value: '-',
          unit: '元'
        }
      ],
      exportShow: false
    }
  },
  watch: {
    billParam: {
      handler: function(val) {
        this.getDetail()
      },
      deep: true
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    exportBatchSupply() {
      this.exportShow = true
    },
    getDetail() {
      getCompanyBillDetailApi({
        insureCompanyId: this.billParam.insureCompanyId,
        insureMonth: this.billParam.month
      }).then((res) => {
        if (res.code === 100) {
          this.personsNum = [
            {
              label: '缴纳人数',
              value: res.data.personCount,
              type: 'all'
            },
            {
              label: '本月增员',
              value: res.data.increaseCount,
              type: 'add'
            },
            {
              label: '本月减员',
              value: res.data.decreasesCount,
              type: 'del'
            },
            {
              label: '本月补缴',
              value: res.data.supplyCount,
              type: 'sup'
            }
          ]
          this.summaryMoney = [
            {
              label: '合计金额',
              value: res.data.total,
              unit: '元'
            },
            {
              label: '本月公司缴纳',
              value: res.data.companyTotal,
              unit: '元'
            },
            {
              label: '本月个人缴纳',
              value: res.data.personTotal,
              unit: '元'
            },
            {
              label: '公司补缴',
              value: res.data.companySupplyTotal,
              unit: '元'
            },
            {
              label: '个人补缴',
              value: res.data.personSupplyTotal,
              unit: '元'
            }
          ]
        }
      })
    },
    goBack() {
      this.exportShow = false
    },
    back() {
      this.$refs.table.refresh()
      this.active = 0
      this.$emit('back')
    }
  }
}
</script>

<style lang="less" scoped>
.viewBill {
  height: 100%;
}
.header-back {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  /deep/.ant-btn .prefix{
    color: @sc-grey-100;
  }
  .header-back-title {
    margin-left: 24px;
    .font-size(16px);
    color: @sc-grey-100;
    font-weight: bold;
  }
}
.list {
  display: flex;
  justify-content: space-between;
  ul {
    width: 34.89%;
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 4px;
    .font-size(16px);
    p {
      margin: 0;
    }
    .active {
      p {
        color: @sc-primary-100 !important;
      }
    }
    .personNum {
      p {
        color: @sc-grey-60;
        font-size: 16px;
        &:nth-child(1) {
          color: @sc-grey-100;
          font-weight: 600;
          font-size: 20px;
        }
      }
      user-select: none;
      cursor: pointer;
    }
    .personNum1 {
      p {
        color: @sc-grey-60;
        font-size: 16px;
        &:nth-child(1) {
          color: @sc-grey-100;
          font-weight: 600;
          font-size: 20px;
        }
      }
    }
    &:nth-child(2) {
      width: 63.91%;
      background-color: @sc-greyBg-10;
    }
  }
  ul:nth-child(1) {
    border: 1px solid @sc-greyBg-20;
  }
}
</style>
