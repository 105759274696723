<template>
  <Modal :visible="visible" :closable="true" :size="'normal'" :title="'完善企业信息'" @ok="handleOk" @cancel="handleCancel">
    <a-form-model ref="ruleForm" :model="tenantForm" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="企业名称" required class="tenant-name">
        <a-input
          v-model="tenantForm.tenantName"
          disabled
          placeholder="请输入企业名称"
        />
      </a-form-model-item>
      <a-form-model-item label="企业规模" prop="scale">
        <a-select v-model="tenantForm.scale" :allow-clear="true" class="selectPlace">
          <template v-if="!tenantForm.scale" slot="placeholder">
            请选择
          </template>
          <a-select-option v-for="item in scaleList" :key="item.codeKey" :value="item.codeKey">
            {{ item.content }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="所属行业" prop="business">
        <a-select v-model="tenantForm.business" :allow-clear="true" class="selectPlace">
          <template v-if="!tenantForm.business" slot="placeholder">
            请选择
          </template>
          <a-select-opt-group v-for="item in treeData" :key="item.businessTypeId" :label="item.businessTypeName">
            <a-select-option v-for="it in item.businessList" :key="it.businessId" :value="it.businessId">
              {{ it.businessName }}
            </a-select-option>
          </a-select-opt-group>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="所在地区" prop="areaCode">
        <CbSelectArea
          ref="selectArea"
          v-model="tenantForm.areaCode"
          :province-width="100"
          :city-width="100"
          :area-width="100"
          :auto-fill-in-area-value="false"
          @getStrandValue="onChange"
        />
      </a-form-model-item>
    </a-form-model>
  </Modal>
</template>

<script>
import { getTenant, updateTenant } from '@/services/login'
import { getScale, getBusinessList } from '@/services/dictionary/tenant'
import { mapGetters } from 'vuex'
import CbSelectArea from '@/components/CbSelectArea/index'
export default {
  name: 'TenantModal',
  components: {
    CbSelectArea,
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      visible: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      tenantForm: {},
      treeData: [],
      scaleList: [],
      areaAdress: {},
      rules: {
        areaCode: [
          {
            required: false,
            validator: this.areaValid,
            trigger: ['change', 'blur']
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('account', ['tenant'])
  },
  mounted() {
    this.getTenantModel()
  },
  methods: {
    async getTenantModel() {
      const res = await getTenant()
      const { tenantName, scale, business, areaCode } = res.data.data
      if (!tenantName || !scale || !business || !areaCode) {
        this.show()
      }
      this.tenantForm = res.data.data ? res.data.data : {}
    },
    getArea() {
      if (this.tenantForm.areaCode && this.tenantForm.areaCode !== '-1') {
        const areaList = this.tenantForm.areaCode.split(',')
        this.areaAdress = { province: areaList[0], city: areaList[1], county: areaList[2] }
      } else {
        this.areaAdress = { province: undefined, city: undefined, county: undefined }
      }
      this.$nextTick(() => {
        this.$refs['selectArea'].dataEcho(this.areaAdress)
      })
    },
    // 获取行业
    async show() {
      this.visible = true
      const businessRes = await getBusinessList()
      this.treeData = businessRes.data
      const scaleRes = await getScale()
      this.scaleList = scaleRes.data.map(item => {
        return {
          ...item,
          content: `${item.content}${item.content === '1000' ? '人以上' : '人'}`
        }
      })
      this.getArea()
    },
    // 地区选择
    onChange(val, text) {
      this.tenantForm.areaCode = val[2]
      this.areaAdress = { province: val[0], city: val[1], county: val[2] }
      if (val[2]) {
        this.$refs.ruleForm.clearValidate('areaCode')
      }
    },
    areaValid(rule, value, callback) {
      if (!this.areaAdress.province && !value) {
        callback(new Error('请选择所在地区'))
      } else if (this.areaAdress.province && !value) {
        callback(new Error('请选择正确的地址'))
      } else {
        callback()
      }
    },
    handleCancel() {
      this.visible = false
    },
    handleOk() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          const { tenantId, scale, business, areaCode } = this.tenantForm
          const params = {
            loginTenant: tenantId || '',
            scale: scale || '',
            business: business || '',
            areaCode: areaCode || ''
          }
          const data = await updateTenant(params)
          if (data.code === 100) {
            this.$message.success('企业信息完善成功')
            this.visible = false
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-modal-content .ant-modal-body{
  .tenant-name .ant-form-item-control{
    cursor: not-allowed;
  }
  .ant-row{
    border-bottom: 1px solid @sc-grey-20;
    margin-bottom: 34px;
    .selectPlace {
      .ant-select-selection__placeholder{
        display: block !important;
      }
    }
    .ant-select-selection, .ant-select-open .ant-select-selection, .ant-select-focused .ant-select-selection{
      border: 0;
      box-shadow: unset;
    }
    .ant-input{
      border: 0;
      box-shadow: unset;
    }
    .ant-input[disabled]{
      background: #fff;
      color: @sc-grey-60;
    }
    .has-error{
      .ant-form-explain{
        position: absolute;
        top: 46px;
      }
    }
  }
  .ant-form-item-with-help{
    border-bottom-color: #e34d59 !important;
  }
}
</style>
