<template>
  <div class="BaseMessage">
    <a-form-model-item label="参算方式">
      <a-radio-group v-model="form.calcType">
        <a-radio value="1">加项</a-radio>
        <a-radio value="2">减项</a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item label="是否计税">
      <a-radio-group v-model="form.taxable">
        <a-radio :value="1">是</a-radio>
        <a-radio :value="0">否</a-radio>
      </a-radio-group>
    </a-form-model-item>
  </div>
</template>

<script>
export default {
  name: 'BaseMessage',
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
</style>
