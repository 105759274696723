var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CbNumStatistics',{staticClass:"Statistics",attrs:{"is-tabs":true,"statistics-info":_vm.statisticsInfo},on:{"changeTab":_vm.changeTab}}),_c('appForm',{attrs:{"to-list":_vm.toList,"screen-roster-list":_vm.screenRosterList,"search-placeholder":"搜索员工/工号","width":"400"},on:{"getSearchCallback":_vm.getSearchCallback,"screenRosterParams":_vm.screenRosterParams,"exportFileList":_vm.exportFileList}},[(_vm.isNotDetail)?_c('template',{slot:"customButton"},[(_vm.selectType === 0)?_c('a-button',{staticClass:"falsify-btn",attrs:{"prefix":"genjin"},on:{"click":_vm.handleEdit}},[_vm._v(" 修改计薪人员 ")]):_vm._e(),(_vm.selectType === 1 && _vm.selectDataList[1].value)?_c('a-button',{staticClass:"falsify-btn",attrs:{"prefix":"genjin"},on:{"click":_vm.setDays}},[_vm._v(" 设置应出勤天数 ")]):_vm._e()],1):_vm._e()],2),_c('custom-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"row-key":"onJobId","offset-bottom":60,"pagination":{
      current: _vm.pager.pageNum,
      pageSize: _vm.pager.pageSize,
      total: _vm.total,
      showTotal: function (total) { return ("共 " + total + " 条数据"); },
      showQuickJumper: true,
      showSizeChanger: true
    }},on:{"change":_vm.loadDataList},scopedSlots:_vm._u([{key:"index",fn:function(ref){
    var scope = ref.scope;
return [_c('span',[_vm._v(_vm._s(scope.index + 1))])]}},{key:"staffName",fn:function(ref){
    var scope = ref.scope;
return [_c('CbTableAvater',{attrs:{"table-avater":scope.record.avatar,"table-name":scope.record.staffName,"on-job-id":scope.record.onJobId,"staff-id":scope.record.staffId}})]}},{key:"operation",fn:function(ref){
    var scope = ref.scope;
return [_c('div',{staticClass:"flex-align-center"},[_c('div',{staticClass:"more-handle"},[(_vm.selectType === 4)?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.handleAdd(scope)}}},[_vm._v(" 添加 ")]):_vm._e(),(_vm.selectType !== 4)?_c('a-button',{attrs:{"disabled":_vm.selectDataList[0].value < 2&&_vm.dataSource.length < 2,"type":"link"},on:{"click":function($event){return _vm.handleDelete($event, scope)}}},[_vm._v(" 移除 ")]):_vm._e()],1)])]}}])}),_c('Drawer',{attrs:{"visible":_vm.editVisible,"title":"修改计薪人员","z-index":8,"placement":"right","size":"small","is-mask":false},on:{"close":_vm.onClose,"onSubmit":_vm.onSubmin}},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"main-list"},[_c('div',{staticClass:"main-title"},[_vm._v("固定计薪人员")]),_c('p',[_vm._v("选择的人员只要正常在职，在本月及之后每个月都会自动加入工资表")]),_c('div',{staticClass:"main-checked"},[_c('CbSelectPersons',{staticClass:"per",attrs:{"query-leave-person":true,"data-echo":_vm.perDataList},on:{"perModelConfirm":_vm.perModelConfirm}})],1)])])]),_c('CbModal',{attrs:{"visible":_vm.setDaysVisible,"size":"small","title":"设置应出勤天数"},on:{"ok":_vm.handleSetDays,"cancel":function($event){_vm.setDaysVisible = false}}},[_c('div',{staticClass:"tip"},[_vm._v("全月应出勤天数为，假设本月新入职人员为全月在职时的出勤天数，便于计算日薪")]),_c('div',{staticClass:"setDaysBox"},[_vm._l((_vm.setDaysPerList),function(item,index){return [_c('div',{key:("setDaysItem" + index),staticClass:"setDaysItem"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(item.staffName))]),_c('CbNumber',{attrs:{"decimal-separator":"","max":31,"min":1,"suffix":"天"},model:{value:(item.days),callback:function ($$v) {_vm.$set(item, "days", $$v)},expression:"item.days"}})],1)]})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }