<template>
  <div>
    <Drawer
      title="批量停保"
      :closable="false"
      size="small"
      :z-index="2"
      :visible="visible"
      @close="onClose"
      @onSubmit="onSubmin"
    >
      <div class="content">
        <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item label="适用员工" :wrapper-col="wrapperCols">
            <div class="per_list" :style="!flag ? avatarClassDefault : ''">
              <div v-for="(item, index) in form.staff" :key="index" class="per_list_avatar">
                <CbAvatar
                  :on-job-id="item.onJobId"
                  :avater-name="item.staffName"
                  size="24"
                  :default-name-length="1"
                />
              </div>
              <div v-if="form.staff.length > 8" class="downUp" @click="flag = !flag">
                <svg class="iconpark-icon" :style="!flag ? 'transform: rotate(270deg)' : 'transform: rotate(90deg)'"><use href="#left" /></svg>
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item label="停保" prop="stopMain">
            <a-checkbox-group v-model="form.stopMain">
              <a-checkbox value="1" name="type">社保</a-checkbox>
              <a-checkbox value="2" name="type">医保</a-checkbox>
              <a-checkbox value="3" name="type">公积金</a-checkbox>
            </a-checkbox-group>
          </a-form-model-item>
          <div v-if="form.stopMain.includes('1')">
            <BlockTitle id="title" title="社保" />
            <a-form-model-item ref="socialMonth" label="缴纳停止月" prop="socialMonth">
              <a-month-picker v-model="form.socialMonth" placeholder="请选择月份" value-format="YYYY-MM" style="width: 100%" @blur="() => {$refs.socialMonth.onFieldBlur()}" />
            </a-form-model-item>
            <a-form-model-item label="减员原因">
              <!-- <a-input v-model="form.socialReason" placeholder="请输入" /> -->
              <a-select v-model="form.socialReason">
                <a-select-option v-for="item in codeName.social_decreases_reason" :key="item.codeKey" :value="item.codeKey">
                  {{ item.content }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <div v-if="form.stopMain.includes('2')">
            <BlockTitle id="title" title="医保" />
            <a-form-model-item ref="medicalMonth" label="缴纳停止月" prop="medicalMonth">
              <a-month-picker v-model="form.medicalMonth" placeholder="请选择月份" value-format="YYYY-MM" style="width: 100%" @blur="() => {$refs.medicalMonth.onFieldBlur()}" />
            </a-form-model-item>
            <a-form-model-item label="减员原因">
              <!-- <a-input v-model="form.medicalReason" placeholder="请输入" /> -->
              <a-select v-model="form.medicalReason">
                <a-select-option v-for="item in codeName.medical_decreases_reason" :key="item.codeKey" :value="item.codeKey">
                  {{ item.content }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <div v-if="form.stopMain.includes('3')">
            <BlockTitle id="title" title="公积金" />
            <a-form-model-item ref="accumulationMonth" label="缴纳停止月" prop="accumulationMonth">
              <!--<a-input v-model="form.accumulationMonth" placeholder="请输入" @blur="() => {$refs.accumulationMonth.onFieldBlur()}" />-->
              <a-month-picker v-model="form.accumulationMonth" :get-popup-container="(triggerNode)=>triggerNode.parentNode" placeholder="请选择月份" value-format="YYYY-MM" style="width: 100%" @blur="() => {$refs.accumulationMonth.onFieldBlur()}" />
            </a-form-model-item>
            <a-form-model-item label="变更原因">
              <!-- <a-input v-model="form.accumulationReason" placeholder="请输入" /> -->
              <a-select v-model="form.accumulationReason" :get-popup-container="(triggerNode)=>triggerNode.parentNode">
                <a-select-option v-for="item in codeName.housing_decreases_reason" :key="item.codeKey" :value="item.codeKey">
                  {{ item.content }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
    </Drawer>
  </div>
</template>

<script>
import {
  getStopInsureApi,
  checkStopMonthExistSupplyApi,
  getInsureBillRemindStatusApi
} from '@/services/insurance/archives'
import { stopTips } from '../../bill/dict.js'
import { getTanantCodeBatch } from '@/services/dropDownInput/index.js'
import moment from 'moment'
export default {
  name: 'BatchShutdown',
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index'),
    CbAvatar: () => import('@/components/CbAvater/theAvater.vue'),
    Drawer: () => import('@/components/CbDrawer/index')
  },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      wrapperCols: { span: 20 },
      visible: false,
      flag: false,
      avatarClassDefault: {
        height: '110px',
        overflow: 'hidden'
      },
      form: {
        staff: [],
        stopMain: [],
        socialMonth: moment(new Date()).format('YYYY-MM'),
        socialReason: 'S003',
        medicalMonth: moment(new Date()).format('YYYY-MM'),
        medicalReason: 'Y001',
        accumulationMonth: moment(new Date()).format('YYYY-MM'),
        accumulationReason: 'G001'
      },
      rules: {
        stopMain: [{ type: 'array', required: true, message: '请选择停保类型', trigger: 'change' }],
        socialMonth: [
          { required: true, message: '请输入缴纳停止月', trigger: 'change' }
        ],
        medicalMonth: [
          { required: true, message: '请输入缴纳停止月', trigger: 'change' }
        ],
        accumulationMonth: [
          { required: true, message: '请输入缴纳停止月', trigger: 'change' }
        ]
      },
      codeName: {
        social_decreases_reason: [],
        medical_decreases_reason: [],
        housing_decreases_reason: []
      }
    }
  },
  created() {

  },
  methods: {
    // 获取字典项
    getTanantCodeBatch() {
      const data = ['social_decreases_reason', 'medical_decreases_reason', 'housing_decreases_reason']
      getTanantCodeBatch(data).then((res) => {
        if (res.code === 100) {
          res.data.forEach((item) => {
            this.codeName[item.codeName] = item.baseCodes
          })
        }
      })
    },
    // 打开抽屉
    show(data) {
      this.form.staff = data
      this.visible = true
      this.getTanantCodeBatch()
    },
    // 保存
    onSubmin() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          const isSure = await checkStopMonthExistSupplyApi({
            onJobIds: this.arrHandle(this.form.staff).join(','),
            socialStopMonth: this.form.stopMain.includes('1') ? this.form.socialMonth : null,
            medicalStopMonth: this.form.stopMain.includes('2') ? this.form.medicalMonth : null,
            housingStopMonth: this.form.stopMain.includes('3') ? this.form.accumulationMonth : null
          })
          const res = await getInsureBillRemindStatusApi({
            socialMonth: this.form.stopMain.includes('1') ? this.form.socialMonth : null,
            medicalMonth: this.form.stopMain.includes('2') ? this.form.medicalMonth : null,
            housingMonth: this.form.stopMain.includes('3') ? this.form.accumulationMonth : null
          })
          let tips = ''
          if (res.code === 100) {
            if (isSure.data) {
              tips += '<p>存在补缴，请到补缴中自行删除</p>'
            }
            if (res.data.socialBillRemindStatus === '1' && res.data.medicalBillRemindStatus === '1' && res.data.housingBillRemindStatus === '1') {
              if (isSure.data) {
                this.$Reconfirm({
                  title: '停保',
                  content: tips
                }).then(() => {
                  this.upData()
                }).catch(() => {})
              } else {
                this.upData()
              }
            } else {
              const arr = [res.data.socialBillRemindStatus, res.data.medicalBillRemindStatus, res.data.housingBillRemindStatus]
              const newArr = new Set(arr)
              newArr.forEach(item => {
                if (item !== '1') {
                  tips += stopTips[item]
                }
              })
              this.$Reconfirm({
                title: '批量停保',
                content: tips
              }).then(() => {
                this.upData()
              }).catch(() => {})
            }
          }
        } else {
          return false
        }
      })
    },
    async upData() {
      const res = await getStopInsureApi({
        onJobIds: this.arrHandle(this.form.staff), // 人员onJobId集合
        social: this.form.stopMain.includes('1') ? { endMonth: this.form.socialMonth, decreasesReason: this.form.socialReason } : null, // 社保
        medical: this.form.stopMain.includes('2') ? { endMonth: this.form.medicalMonth, decreasesReason: this.form.medicalReason } : null, // 医保
        housing: this.form.stopMain.includes('3') ? { endMonth: this.form.accumulationMonth, decreasesReason: this.form.accumulationReason } : null // 公积金
      })
      if (res.code === 100) {
        this.$message.success('批量停保成功！')
        this.$emit('callBackBatch')
        this.onClose()
      }
    },
    arrHandle(list) {
      const arr = []
      for (let i = 0; i < list.length; i++) {
        arr.push(list[i].onJobId)
      }
      return arr
    },
    // 关闭抽屉
    onClose() {
      this.visible = false
      this.form = this.$options.data().form
      this.$refs.ruleForm.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
  .per_list {
    position: relative;
    width: 465px;
    background: rgba(143,147,161,0.05);
    border-radius: 4px 4px 4px 4px;
    border: 1px solid rgba(143,147,161,0.10);
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    box-sizing: border-box;
    .per_list_avatar {
      width: 88px;
      margin: 0 20px 10px 0;
      cursor: pointer;
    }
    .downUp {
      cursor: pointer;
      position: absolute;
      top: 18px;
      right: 12px;
      color: @sc-greyBg-50;
      .iconpark-icon{
        width: 25px;
        height: 25px;
      }
    }
  }
  #title {
    margin-bottom: 20px;
    .title_division {
      width: 3px;
      height: 14px;
      background: @sc-primary-100;
      margin-right: 5px;
    }
  }
  /deep/.ant-form-item-label label ,/deep/.ant-checkbox-wrapper span ,/deep/.ant-drawer-title{
    color: @sc-grey-100;
  }
</style>
