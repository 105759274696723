<template>
  <div class="right-menu">
    <div class="selectAll">
      <a-checkbox :checked="isAllSelect" :disabled="allDisabled" @change="checkAll">
        全选
      </a-checkbox>
    </div>
    <!-- <a-checkbox-group v-model="idArray" :options="selectInfoList" /> -->
    <div
      v-for="item in selectInfoList"
      :key="item.staffId"
      :class="['personList', { 'enter-person': item.staffId === mouseenterId }, { 'person-click': item.staffId === clickId }]"
      style="cursor: pointer"
      @mouseenter="enter(item.staffId)"
    >
      <div class="name_box">
        <a-checkbox ref="checkboxGroup" :checked="item.checked" @change="onChange(item, $event)" />
        <p class="name_span" @click="clickInfo(item)">{{ item.staffName }}</p>
      </div>
      <!-- <span class="personList-span" @click="getPerson(item.staffId)"> </span> -->
      <div class="personList-icon">
        <a-icon v-if="item.staffId === mouseenterId" type="close-circle" theme="filled" @click="deletePerson(item)" />
      </div>
    </div>
    <p>
      <a-button v-if="confirmShow" class="confirmBatch" type="primary" @click="confirmBatch">确认开具</a-button>
    </p>
  </div>
</template>

<script>
import { addProveBatch } from '@/services/user/certificate.js'
export default {
  name: 'SelectPerson',
  props: {
    selectInfo: {
      type: [Array, Object],
      default: () => {}
    },
    tempId: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      mouseenterId: '1',
      clickId: '1',
      selectInfoList: [],
      idArray: [],
      isAllSelect: true,
      statusArr: [],
      allDisabled: false,
      confirmShow: true
    }
  },
  watch: {
    selectInfo: {
      handler: function(val) {
        this.selectInfoList = val
        if (this.selectInfoList.length === 0) {
          this.isAllSelect = false
          this.allDisabled = true
          this.confirmShow = false
          this.$emit('edit', false)
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    enter(val) {
      this.mouseenterId = val
    },
    initData() {
      this.selectInfoList.forEach(item => {
        const obj = {}
        obj.staffId = item.staffId
        obj.proveFileId = item.proveInfo.proveFileId
        this.idArray.push(obj)
        item.checked = true
      })
      this.$forceUpdate()
    },
    deletePerson(item) {
      if (item.checked) {
        this.forFun(this.idArray, item)
        this.forFun(this.selectInfoList, item)
      } else {
        this.forFun(this.selectInfoList, item)
      }
      if (this.idArray.length === this.selectInfoList.length) {
        this.isAllSelect = true
      }
    },
    forFun(arr, d) {
      arr.forEach((i, index) => {
        if (d.staffId === i.staffId) {
          arr.splice(index, 1)
          if (typeof this.selectInfoList[index] === 'undefined') {
            this.$emit('getInfo', this.selectInfoList[0])
          } else {
            this.$emit('getInfo', this.selectInfoList[index])
          }
        }
      })
    },
    async confirmBatch() {
      if (this.idArray.length === 0) {
        this.$message.warning('请选择开具证明人员')
        return
      }
      const res = await addProveBatch({
        'proveTempId': this.tempId,
        'staffProveInfoList': this.idArray
      })
      if (res.code === 100) {
        this.idArray = []
        this.isAllSelect = false
        this.$message.success('批量开具成功')
        this.$emit('unShow')
        this.selectInfoList.forEach((item) => {
          item.checked = false
        })
      } else {
        this.$message.warning(`${res.message}`)
      }
    },
    onChange(item, e) {
      const obj = {}
      // item.checked = e.target.checked
      item.checked = e.target.checked
      if (e.target.checked) {
        obj.staffId = item.staffId
        obj.proveFileId = item.proveInfo.proveFileId
        this.idArray.push(obj)
      } else {
        this.idArray.forEach((i, index) => {
          if (item.staffId === i.staffId) {
            this.idArray.splice(index, 1)
          }
        })
        this.isAllSelect = false
      }
      if (this.idArray.length === this.selectInfoList.length) {
        this.isAllSelect = true
      }
      this.$forceUpdate()
    },
    // 全选
    checkAll(e) {
      this.idArray = []
      this.isAllSelect = e.target.checked
      if (e.target.checked) {
        this.selectInfoList.forEach(item => {
          const obj = {}
          obj.staffId = item.staffId
          obj.proveFileId = item.proveInfo.proveFileId
          this.idArray.push(obj)
          item.checked = true
        })
      } else {
        this.selectInfoList.forEach(item => {
          item.checked = false
        })
        this.idArray = []
      }
      this.$forceUpdate()
    },
    clickInfo(item) {
      this.$emit('getInfo', item)
    }
  }
}
</script>

<style lang="less" scoped>
.selectAll{
   border-bottom: 1px solid @sc-greyBg-20;
   margin-left: 10px;
   padding-bottom: 10px;
   }
.right-menu{
  height: calc( 100vh - 190px);
  overflow: auto;
  p{
    text-align: center;
  }
}
.personList {
  line-height: 42px;
  height: 42px;
  font-size: 16px;
  padding: 0px 10px 0px 10px;
  vertical-align: middle;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.confirmBatch{
    margin-top: 10px;
}
.personList-span {
  line-height: 28px;
}
.personList-icon {
  display: flex;
  align-items: center;
  color: @sc-grey-20;
}
.enter-person {
  background: @sc-greyBg-10;
}
.person-click {
  color: @sc-primary-100;
}
.name_span{
  text-align: left;
  display: inline-flex;
  width: 100px;
  margin-left: 12px;
}
.name_box{
  text-align: left;
  width: 100px;
  display: inline-block;
}
/deep/ .ant-checkbox + span {
  color: @sc-grey-100;
}
</style>
