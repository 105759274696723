<!--
 * @Date: 2022-04-27 15:09:07
 * @descriotion:
-->
<template>
  <div class="setting-container">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item ref="overTimeRuleId" label="加班规则" prop="overTimeRuleId">
        <a-select v-model="form.overTimeRuleId" placeholder="请选择加班规则">
          <a-select-option v-for="item in overTimeRuleList" :key="item.overtimeRuleId" :value="item.overtimeRuleId">
            {{ item.ruleName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item ref="outSideRuleId" label="外勤规则" prop="outSideRuleId">
        <a-select v-model="form.outSideRuleId" placeholder="请选择外勤规则">
          <a-select-option v-for="item in outSideRuleList" :key="item.outsideRuleId" :value="item.outsideRuleId">
            {{ item.ruleName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item ref="appendPunchRuleId" label="补卡规则" :prop="form.attendanceGroupType === '2'?'appendPunchRuleId' :''">
        <a-select v-model="form.appendPunchRuleId" placeholder="请选择补卡规则" allow-clear>
          <a-select-option v-for="item in appendPunchList" :key="item.appendPunchRuleId" :value="item.appendPunchRuleId">
            {{ item.ruleName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label=" " :wrapper-col="{ span: 14 }">
        <span class="model-item-span">注：考勤扣款规则、考勤补贴规则可在【薪酬】- 【设置】-【考勤】中设定</span>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { getAppendPunchListApi, getoOvertimeRuleListApi, getOutsideRuleListApi } from '@/services/attendance/rule.js'
export default {
  name: 'AttendanceRules',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      labelCol: { span: 3 },
      wrapperCol: { span: 10 },
      options: [[
        {
          'value': '1',
          'label': '默认加班规则'
        },
        {
          'value': '2',
          'label': '下拉框2'
        }
      ]],
      form: {},
      overTimeRuleList: [],
      outSideRuleList: [],
      appendPunchList: [],
      rules: {
        overTimeRuleId: [{ required: true, message: '请选择加班规则', trigger: 'change' }],
        outSideRuleId: [{ required: true, message: '请选择外勤规则', trigger: 'change' }],
        appendPunchRuleId: [{ required: true, message: '请选择补卡规则', trigger: 'change' }]
      }
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        const { overTimeRuleId, outSideRuleId, appendPunchRuleId, attendanceGroupType } = value
        this.form = { overTimeRuleId, outSideRuleId, appendPunchRuleId, attendanceGroupType }
      }
    }
  },
  mounted() {
    this.getAppendPunchList()
    this.getoOvertimeRuleList()
    this.getOutsideRuleList()
  },
  methods: {
    async getAppendPunchList() {
      const res = await getAppendPunchListApi({})
      this.appendPunchList = res.data
    },
    async getoOvertimeRuleList() {
      const res = await getoOvertimeRuleListApi({})
      this.overTimeRuleList = res.data
    },
    async getOutsideRuleList() {
      const res = await getOutsideRuleListApi({})
      this.outSideRuleList = res.data
    },
    save() {
      console.log(this.form)
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$emit('save', this.form, 'rules')
        } else {
          this.$message.info('请先完善内容')
        }
      })
    },
    // 下一步
    next() {
      let flag = false
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          flag = true
          this.$emit('getData', this.form)
        } else {
          this.$message.info('请先完善考勤规则')
        }
      })
      return flag
    },
    reset() {
      this.$refs.ruleForm.resetFields()
      this.$emit('resetData', this.form)
    }
  }

}
</script>
<style lang="less" scoped>
.setting-container{
    width:100%;
    padding:20px 43px 34px;
    height:100%;
    overflow: auto;
}
.steps-action{
  width:100%;
  .btns{
    display: flex;
    justify-content: center;
    .seat{
      width:24px;
    }
  }
}
.model-item-span{
  width: 100%;
}
</style>
