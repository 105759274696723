<template>
  <div class="Calendar">
    <!-- 日历 -->
    <a-calendar ref="calender" v-model="calendarVal" :fullscreen="false" :header-render="headerRender" @change="change">
      <template slot="dateCellRender" slot-scope="value">
        <div class="day-content">
          <!-- 角标 holiday/adjust -->
          <span v-if="getTypeData(value)&&(getTypeData(value).includes('holiday')||getTypeData(value).includes('adjust'))" class="rest" :class="getTypeData(value).includes('holiday')?'':'repair'">
            {{ getTypeData(value).includes('holiday')?'休':'补' }}
          </span>
          <!-- 点 -->
          <span v-if="getTypeData(value)&&getTypeData(value).includes('schedule')" class="flex-just-center schedule-box">
            <div class="schedule" />
          </span>
          <!-- 下方文字 special/festival -->
          <div v-if="getTypeData(value)&&(getTypeData(value).includes('special')||getTypeData(value).includes('festival'))" class="text" :class="getTypeData(value).includes('special')?'special':'festival'">
            <span v-if="dateObj[value.format('YYYY-MM-DD')].title !== undefined &&dateObj[value.format('YYYY-MM-DD')].title.length <5">{{ dateObj[value.format('YYYY-MM-DD')].title }}</span>
            <a-tooltip v-else class="user-name">
              <template slot="title">
                {{ dateObj[value.format('YYYY-MM-DD')].title }}
              </template>
              <span v-if="dateObj[value.format('YYYY-MM-DD')].title !== undefined">{{ dateObj[value.format('YYYY-MM-DD')].title.slice(0,3) }}...</span>
            </a-tooltip>
          </div>
        </div>
      </template>
    </a-calendar>
  </div>
</template>

<script>
import { getMatter } from '@/services/home/calendar.js'
import moment from 'moment'
export default {
  name: 'Calendar',
  props: {
    selectDate: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      calendarVal: moment(new Date()),
      dateList: [],
      dateObj: {}
    }
  },
  computed: {
    startDate() {
      for (const key in this.dateObj) { return this.dateObj[key].date }
      return undefined
    },
    endDate() {
      return this.dateObj[Object.keys(this.dateObj)[Object.keys(this.dateObj).length - 1]]?.date || undefined
    }
  },
  watch: {
    selectDate(newVal) {
      this.calendarVal = newVal
    }
  },
  created() {
  },
  mounted() {
    this.getMatterList()
  },
  methods: {
    async getMatterList() {
      this.$nextTick(async() => {
        const res = await getMatter({ startDate: this.startDate, endDate: this.endDate })
        if (res.code === 100) {
          this.dateList = res.data
        }
      })
    },
    // 获取单元格内展示内容集合
    // return Array
    getTypeData(value) {
      // 获取当前展示日历所有日期 并 为其创建对象
      this.dateObj[value.format('YYYY-MM-DD')] = { date: value.format('YYYY-MM-DD') }
      const time = moment(value).format('YYYY-MM-DD')
      for (let i = 0; i < this.dateList.length; i++) {
        if (this.dateList[i].date === time) {
          // 快速查询赋值
          this.dateObj[time].title = this.dateList[i].title
          return this.dateList[i].matterType || []
        }
      }
    },
    // 判断是否为休息日
    isRest(value) {
      const time = moment(value).format('YYYYMMDD')
      let isRest
      this.dateList.forEach(v => {
        if (v.date === time) {
          isRest = v.isRest
        }
      })
      return isRest
    },
    change(val) {
      this.$emit('dateChange', val)
    },
    headerRender({ value, type, onChange, onTypeChange }) {
      return (
        <span></span>
      )
    }
  }
}
</script>

<style lang="less" scoped>
.Calendar{
/deep/.ant-fullcalendar{
  border: none;
  margin-top:45px ;
  .font-size(16px);
}
/deep/.ant-fullcalendar-column-header{
  padding-bottom:8px;
  color: @sc-grey-40;
}
/deep/.ant-fullcalendar-calendar-body{
  padding: 0;
}
/deep/.ant-fullcalendar-value{
  color: @sc-grey-100;
  border-radius: 6px;
  width:24px;
  height:24px;
  line-height:24px;
}
/deep/.ant-fullcalendar-cell{
  height: 48px;
}
/deep/.ant-fullcalendar-last-month-cell,/deep/.ant-fullcalendar-next-month-btn-day{
  .ant-fullcalendar-value{
    color:@sc-grey-40 !important;
  position: relative;

}
}
/deep/.ant-fullcalendar-today{
  .ant-fullcalendar-value{
    color: @sc-primary-100;
  }
}
/deep/.ant-fullcalendar-selected-day{
  .ant-fullcalendar-value{
    color: #fff;
  }
}
.day-content{
  height:22px;
  text-align:center;
  color:rgba(237, 123, 47, 1);
  .font-size(12px);
  .special{
    color:@sc-primary-100 ;
  }
  .schedule-box{
    height:6px;
  .schedule{
    margin-top: 3px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: @sc-primary-100;
  }
  }
  .text{
    height:20px !important;
  }
}
}
.rest {
  .font-size(12px);
  position: absolute;
  top: -30px;
  right:0px;
}
@media screen and(min-width: 1920px){
  .rest {
    right:12px;
  }
}
.repair{
  color:@sc-primary-100
}
</style>
