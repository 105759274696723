<template>
  <div class="organ-post-p">
    <layout
      ref="layoutMenu"
      :menu-data-list="menuDataList"
      :selected-key.sync="selectedKey"
      :del-msg-info="{
        title: '删除岗位分类',
        msg: '岗位分类及岗位?'
      }"
      @menu-select-change="menuSelectChange"
      @menu-action-edit="menuActionEdit"
      @menu-action-del="menuActionDel"
    >
      <template #leftTop>
        <div class="left-top-p" @click="handleAdd">
          <svg class="iconpark-icon post-add-icon"><use href="#add-one" /></svg>
          <span>新建岗位分类</span>
        </div>
      </template>
      <template #default>
        <div class="organ-post-tb-p">
          <PostTabel v-show="selectedKey != ''" ref="postTabel" />
        </div>
      </template>
    </layout>
    <postClassForm ref="postClassForm" @reload="initMenuData" />
  </div>
</template>

<script>
import layout from '@/components/CbLayoutMenu/index'
import PostTabel from './table.vue'
import {
  getPostTypeList,
  postDeletePostType
} from '@/services/organization/post.js'
export default {
  components: {
    layout,
    PostTabel,
    postClassForm: () => import('./form/postClass.vue')
  },
  data() {
    return {
      menuDataList: [],
      selectedKey: ''
    }
  },
  created() {
    this.initMenuData()
  },
  methods: {
    // 初始化菜单数据
    async initMenuData() {
      const res = await getPostTypeList()
      if (res.code === 100) {
        const newList = res.data.map((item) => {
          return {
            ...item,
            id: item.postTypeId + '',
            name: item.postTypeName,
            frontIcon: 'bookmark',
            actions: ['edit', 'del']
          }
        })
        this.menuDataList = newList
        this.$refs.layoutMenu.showInit()
        this.$nextTick(() => {
          if (this.selectedKey) {
            this.$refs.postTabel.reloadTable({ postTypeId: this.selectedKey })
          }
        })
      }
    },
    // 新建岗位分类
    handleAdd() {
      this.$refs.postClassForm.open()
    },
    // 菜单选择回调
    menuSelectChange(item) {
      if (item.id) {
        this.$refs.postTabel.reloadTable({ postTypeId: item.id })
      }
    },
    // 菜单编辑回调
    menuActionEdit(item) {
      this.$refs.postClassForm.open(item)
    },
    // 菜单删除回调
    menuActionDel(item, path) {
      postDeletePostType({
        postTypeId: item.id
      }).then((res) => {
        if (res.code === 100) {
          this.$message.success('删除岗位分类成功')
          this.initMenuData()
        }
      })
      this.$refs.layoutMenu.closeDelModal()
    }
  }
}
</script>

<style lang="less" scoped>
.organ-post-p {
  min-height: 100%;
  .left-top-p {
    display: flex;
    align-items: center;
    padding: 20px 20px 34px 16px;
    color:  @sc-grey-60;
    .font-size(14px);
    .post-add-icon {
      width: 16px;
      height: 16px;
      color: @sc-greyBg-100;
      vertical-align: top;
      margin-right: 8px;
      margin-top: 1px;
    }
    &:hover{
      color: @sc-primary-100;
      .post-add-icon{
        color: @sc-primary-100;
      }
    }
  }
  .organ-post-tb-p {
    padding: 20px 20px 0;
  }
}
/deep/.menu-layout .left-layout-menu-item .menu-item-icon{
  display: none !important;
}
</style>
