<!--
 * @Date: 2022-08-09 09:15:47
 * @descriotion:
-->
<template>
  <Drawer
    title="排班选择"
    size="normal"
    placement="right"
    :closable="true"
    :display-btn="true"
    :is-mask="true"
    :visible="visible"
    @close="onClose"
  >
    <div class="popconfirm">
      <div class="shift-button">
        <div v-for="(item,index) in shiftList" :key="index" class="button-box">
          <div class="sooner-btn" :style="{background:`rgba(${item.backGroundRgba})`,color:`rgba(${item.fontRgba})`}" @click="onClick(item)">
            <button class="sooner" :title="item.shiftShortName" :style="{background:`rgba(${item.fontRgba})`,color:'#FFFFFF'}">
              {{ item.shiftShortName }}
            </button>
            <div class="time">{{ item.shiftRuleName ? item.shiftRuleName :'00:00-23:59' }}</div>
          </div>
        </div>
      </div>
      <div class="left-button">
        <!-- <div class="not btn">无</div> -->
        <a-button @click="onClick">清除排班</a-button>
      </div>
    </div>
  </Drawer>
</template>
<script>
export default {
  name: 'SetDrawer',
  components: {
    Drawer: () => import('@/components/CbDrawer')
  },
  props: {
    shiftList: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      visible: false,
      shiftId: ''
    }
  },
  mounted() {

  },
  methods: {
    showDrawer() {
      this.visible = true
    },
    onClose() {
      this.visible = false
    },
    onClick(val) {
      this.shiftId = val ? val.shiftId : ''
      this.$emit('change', this.shiftId, val)
    }
  }
}
</script>
<style lang="less" scoped>
.popconfirm{
    width: 100%;
    height:100%;
}
.shift-button{
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
}
.popconfirm-title{
  color: @sc-grey-100;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 17px;
}
.sooner{
  width: 79px;
  height: 26px;
  border: 0;
  border-radius: 2px;
  line-height: 26px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 10px;
}
.button-box{
  margin-bottom: 16px;
  margin-right: 16px;
}
.button-box:nth-child(3n){
  margin: 0;
}
.sooner-btn{
  height: 26px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 0 ;
  overflow: hidden;
  cursor: pointer;
  .sooner{
    color: @base-bg-color;
  }
  .sublevel{
    color: @base-bg-color;
  }
  .rest{
    color: @base-bg-color;
  }
  .time{
    margin-left: 10px;
    width: 88px;
  }
}
.left-button{
  width: 138px;
  margin: 40px 0 16px  ;
  display: flex;
  justify-content: space-between;
  .btn{
  width: 48px;
  height: 30px;
  border-radius: 2px;
  text-align: center;
  line-height: 30px;
}
}
.not{
  color: @sc-grey-40;
  background: @sc-greyBg-10;
  height: 26px;
  line-height: 26px;
}
</style>
