import shiftManagement from './shiftManagement'
import attendanceGroup from './attendanceGroup'
import vacationPlan from './vacationPlan'
import holidayType from './holidayType'
import attendanceitems from './attendanceitems'
import rules from './rules'
import attendanceReminders from './attendanceReminders'
import methodPunch from './methodPunch'
import legalHoliday from './legalHoliday'
import holidayReminder from './holidayReminder'
import statisticalPeriod from './statisticalPeriod'
import schedulingCalendar from './schedulingCalendar'
export default {
  shiftManagement,
  attendanceGroup,
  vacationPlan,
  holidayType,
  attendanceitems,
  rules,
  attendanceReminders,
  methodPunch,
  legalHoliday,
  holidayReminder,
  statisticalPeriod,
  schedulingCalendar
}
