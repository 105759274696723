<!--社保的员工信息组件封装-->
<template>
  <div class="component-userinfo-contain">
    <div class="userinfo-contain">
      <div class="basic-userinfo-p">
        <basicInfo v-bind="$props" :update="update" />
      </div>
      <div class="tab-userinfo-p">
        <div class="com-tab" :class="{ 'active': activeTab == 'personal' }" @click="changeTab('personal')">
          参保信息
        </div>
        <div v-if="statusType !== 'toExamine'" class="com-tab" :class="{ 'active': activeTab == 'record' }" @click="changeTab('record')">
          参保记录
        </div>
      </div>
      <div v-if="activeTab==='personal'" class="tab-userinfo-content">
        <div v-if="insureClassifyList.length>0">
          <informationList v-for="(item,index) in insureClassifyList" :key="index" :data-source="item" />
        </div>
        <BlankPage v-else title="暂无参保信息" />
      </div>
      <div v-if="activeTab==='record'" class="tab-userinfo-content">
        <enrollmentRecords v-if="infoList.length>0" v-bind="$props" :record-data="infoList" />
        <BlankPage v-else title="暂无参保记录" />
      </div>
    </div>
  </div>
</template>

<script>
import basicInfo from './basic-info.vue'
import informationList from './components/informationList.vue'
import BlankPage from './components/blankPage.vue'
import enrollmentRecords from './components/enrollmentRecords.vue'
import { getStaffInsureDetailApi } from '@/services/insurance/archives'
import { getInsureRecordYearApi, getInsureRecordApi } from '@/services/insurance/archives' //
export default {
  components: { basicInfo, informationList, enrollmentRecords, BlankPage },
  props: {
    anchorHeight: {
      type: String,
      default: ''
    },
    userId: {
      type: String,
      default: ''
    },
    statusType: {
      type: String,
      default: ''
    },
    pageType: {
      type: String,
      default: ''
    },
    archivesPerfectId: {
      type: String,
      default: ''
    },
    onJobId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      update: false,
      activeTab: '',
      infoList: [],
      insureClassifyList: [],
      insureClassify: [
        { cityCode: '',
          cityName: '',
          increaseReason: '',
          insureClassify: '',
          insureClassifyName: '社保',
          insureCompanyId: '',
          insureCompanyName: '',
          insureTypeList: [],
          startMonth: '' },
        { cityCode: '',
          cityName: '',
          increaseReason: '',
          insureClassify: '',
          insureClassifyName: '医保',
          insureCompanyId: '',
          insureCompanyName: '',
          insureTypeList: [],
          startMonth: '' },
        { cityCode: '',
          cityName: '',
          increaseReason: '',
          insureClassify: '',
          insureClassifyName: '公积金',
          insureCompanyId: '',
          insureCompanyName: '',
          insureTypeList: [],
          startMonth: '' }
      ]
    }
  },
  provide() {
    return {
      anchorHeight: this.getAnchorHeight,
      statusType: this.statusType
    }
  },
  mounted() {
    this.activeTab = 'personal'
  },
  created() {
    this.getStaffInsureDetail()
    this.getYear()
  },
  methods: {
    // 获取员工投保详细信息
    getStaffInsureDetail() {
      getStaffInsureDetailApi({ onJobId: this.onJobId }).then(res => {
        if (res.data) {
          this.insureClassifyList = res.data
        }
      })
    },
    changeTab(tab) {
      this.activeTab = tab
      this.$emit('changeTab', tab)
    },
    getAnchorHeight() {
      return this.anchorHeight
    },
    updateInfo() {
      this.update = !this.update
    },
    onTimeline(val) {
      console.log(val)
    },
    getYear() {
      getInsureRecordYearApi({ onJobId: this.onJobId }).then(res => {
        if (res.code !== 100) return
        const arr = []
        res.data.forEach((item, index) => {
          getInsureRecordApi({ onJobId: this.onJobId, year: parseInt(item) }).then(res => {
            if (res.code === 100) {
              const obj = {
                fieldGroupId: parseInt(item),
                fieldGroupName: `${item}`,
                children: res.data
              }
              arr.push(obj)
            }
          })
          this.infoList = arr
          console.log(this.infoList)
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.component-userinfo-contain {
  height:calc(100% - 72px);
  padding: 15px 0 0;
  .userinfo-contain {
    height:100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .basic-userinfo-p {
    margin-bottom: 20px;
  }
  .tab-userinfo-p {
    height: 40px;
    border-radius: 8px;
    background-color: @sc-greyBg-10;
    .flex-center();
    .com-tab {
      width: 140px;
      height: 30px;
      color: @sc-grey-100;
      .font-size(14px);
      .flex-center();
      &.active,
      &:hover {
        color: @sc-primary-100;
        background-color: #fff;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .com-tab + .com-tab {
      margin-left: 125px;
    }
  }
  .tab-userinfo-content{
    height: calc(100% - 160px);
    overflow: auto;
  }
}
</style>
