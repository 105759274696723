var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Approvalcenter"},[_c('approvalcenterHead',{ref:"approvalcenterHead",on:{"getType":_vm.getType}}),_c('appForm',{ref:"appForm",attrs:{"width":"400","to-list":_vm.formList,"search-style":_vm.searchStyle,"search-placeholder":"请输入审批编号、姓名、部门","screen-roster-list":_vm.screenRosterList},on:{"getSearchCallback":_vm.getSearchCallback,"screenRosterParams":_vm.screenRosterParams,"exportFileList":_vm.exportFileList}}),_c('custom-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"table-ref":".Approvalcenter","row-key":'approveNum',"pagination":{
      current: _vm.pager.pageNum,
      pageSize: _vm.pager.pageSize,
      total: _vm.total,
      showTotal: function (total) { return ("共 " + total + " 条数据"); },
      showQuickJumper: true,
      showSizeChanger: true
    }},on:{"change":_vm.loadDataList},scopedSlots:_vm._u([{key:"initiatorName",fn:function(ref){
    var scope = ref.scope;
return [_c('CbTableAvater',{attrs:{"table-name":scope.record.initiatorName,"on-job-id":scope.record.initiatorId}})]}},{key:"processApproveStatus",fn:function(ref){
    var scope = ref.scope;
return [_c('StatusTag',{attrs:{"type":scope.record.processApproveStatus,"type-list":_vm.statusNameList},on:{"click":function($event){return _vm.getCbApproveSteps(scope.record.flowRecordId, scope.record.processApproveStatus)}}})]}},{key:"operation",fn:function(ref){
    var scope = ref.scope;
return [(['inapprove', 'exception'].includes(scope.record.processApproveStatus))?_c('span',{staticClass:"operation-btn-box"},[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.transferApproval(scope.record)}}},[_vm._v(" 转交 ")]),_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.downloadDoc(scope.record)}}},[_vm._v(" 下载审批单 ")])],1):_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.downloadDoc(scope.record)}}},[_vm._v(" 下载审批单 ")])]}}])}),_c('TransferModal',{ref:"transferModal",on:{"change":_vm.changTransfer}}),_c('CbApproveSteps',{attrs:{"flow-id":_vm.flowId,"apply-status":_vm.applyStatus,"apply-status-list":_vm.statusNameList},model:{value:(_vm.stepsVisible),callback:function ($$v) {_vm.stepsVisible=$$v},expression:"stepsVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }