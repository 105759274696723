<template>
  <div class="all-content">
    <div class="v-title">试用期设置</div>
    <a-form :form="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="试用期期限设置：" prop="probation">
        <a-space>
          <a-select v-model="form.probation" placeholder="请选择" :options="optionsType" />
          <span>个月</span> <a-button type="primary" html-type="submit" @click="handleOk"> 确定 </a-button>
        </a-space>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { setProbation, getProbationConfig } from '@/services/user/setting.js'
export default {
  data() {
    return {
      form: {
        probation: ''
      },
      labelCol: { span: 2 },
      wrapperCol: { span: 18 },
      optionsType: [
        {
          value: '1',
          label: '1'
        },
        {
          value: '2',
          label: '2'
        },
        {
          value: '3',
          label: '3'
        },
        {
          value: '4',
          label: '4'
        },
        {
          value: '5',
          label: '5'
        },
        {
          value: '6',
          label: '6'
        }
      ]
    }
  },
  created() {
    this.getProbationData()
  },
  methods: {
    async getProbationData() {
      const res = await getProbationConfig()
      this.form.probation = res.data
    },
    async handleOk() {
      const res = await setProbation({ probation: this.form.probation })
      if (res.code === 100) {
        this.$message.success('设置成功')
      }
    }
  }
}
</script>

<style scoped lang="less">
.all-content {
  height:100%;
  overflow: auto;
  padding: 20px;
  height: calc(100vh - 48px);
}
.v-title {
  font-weight: 600;
  font-size: 16px;
  color: @sc-grey-100;
}
.ant-select{
  width: 100px;
}
/deep/ .ant-col-2 {
  width: 9.333333%;
}
</style>
