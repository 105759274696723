<!--
 * @Date: 2022-07-06 11:22:41
 * @descriotion:
-->
<template>
  <div class="list">
    <div class="tips">
      选择需要导出的字段
    </div>
    <div>
      <div>
        <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
          基本信息
        </a-checkbox>
      </div>
      <br>
      <a-checkbox-group v-model="checkedList" :options="plainOptions" @change="onChange" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'ImportList',
  data() {
    return {
      indeterminate: false,
      checkAll: false,
      checkedList: [],
      plainOptions: ['姓名', '身份证号', '部门', '工号', '聘用形式', '参保公司', '公司合计金额', '公司缴纳比例', '个人缴纳比例']
    }
  },
  methods: {
    onChange(checkedList) {
      this.indeterminate = !!checkedList.length && checkedList.length < this.plainOptions.length
      this.checkAll = checkedList.length === this.plainOptions.length
      this.$emit('change', this.checkedList)
    },
    onCheckAllChange(e) {
      Object.assign(this, {
        checkedList: e.target.checked ? this.plainOptions : [],
        indeterminate: false,
        checkAll: e.target.checked
      })
      this.$emit('change', this.checkedList)
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.ant-checkbox-group-item{
  margin-bottom:10px;
}
.list{
    width:100%;
    padding:16px 20px;
    .tips{
        margin-bottom:16px;
        .font-size(16px);
        color: @sc-grey-100;
        font-weight: 600;
    }
    /deep/.ant-checkbox-wrapper{
      span{
        &:nth-child(2){
          color: @sc-grey-100;
        }
      }
    }
}
</style>
