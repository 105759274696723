<template>
  <div class="HandleModal">
    <Modal :visible="visible" :closable="true" :title="`${modalType?'编辑':'新建'}日程`" :size="'normal'" :mask-closable="false" @ok="handleOk" @cancel="visible=false">
      <div class="HandleModal-content">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="日程标题" prop="title">
            <a-input v-model="form.title" placeholder="请输入日程标题、会议、活动主题" />
          </a-form-model-item>
          <a-form-model-item label="内容" prop="content">
            <a-textarea
              v-model="form.content"
              placeholder="内容描述"
              :auto-size="{ minRows: 3, maxRows: 5 }"
              :max-length="100"
            />
          </a-form-model-item>
          <a-form-model-item label="全天">
            <a-switch v-model="form.allDay" default-checked />
          </a-form-model-item>
          <a-form-model-item :label="form.allDay?'日期':'开始时间'">
            <div class="flex-center" style="margin-top:4px">
              <a-date-picker v-model="form.startDate" value-format="YYYY-MM-DD" :style="`width:${form.allDay?'422px':'241px'}`" />
              <TimePicker v-if="!form.allDay" v-model="form.startTime" style="width:180px" :options="timeSetUp" />
            </div>
          </a-form-model-item>
          <a-form-model-item v-if="!form.allDay" :label="form.duration!=='5'?'时长':'结束时间'">
            <DictionariesInput v-if="form.duration!=='5'" v-model="form.duration" parameter="schedule_duration" />
            <div v-else class="flex-center">
              <a-date-picker v-model="form.startDate" disabled value-format="YYYY-MM-DD" :style="`width:${form.allDay?'422px':'241px'}`" />
              <TimePicker v-if="!form.allDay" v-model="form.endTime" :start-disabled="form.startTime" style="width:180px" :options="timeSetUp" />
            </div>
          </a-form-model-item>
          <a-form-model-item label="提醒">
            <DictionariesInput v-if="!form.allDay" v-model="form.remind" parameter="schedule_remind" />
            <DictionariesInput v-else v-model="form.remind" parameter="schedule_allday_remind" />
          </a-form-model-item>
          <a-form-model-item label="重复">
            <div class="flex-center">
              <DictionariesInput v-model="form.repeatType" parameter="schedule_repeat_type" :style="`width:${form.repeatType==='1'?'422px':'241px'}`" />
              <a-date-picker v-if="form.repeatType !== '1'" v-model="form.endDate" value-format="YYYY-MM-DD" placeholder="结束于" />
            </div>
          </a-form-model-item>
        </a-form-model>
      </div>
    </Modal>
  </div>
</template>

<script>
import { createMemo, updateCurrMemo, updateFutureMemo } from '@/services/home/calendar.js'
import { debounce } from '@/utils/index.js'
import moment from 'moment'
export default {
  name: 'HandleModal',
  components: {
    Modal: () => import('@/components/CbModal/index'),
    TimePicker: () => import('@/components/CbTimePicker/index'),
    DictionariesInput: () => import('@/components/CbDropDownInput/dictionariesInput/index')
  },
  props: {
    matterId: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      visible: false,
      modalType: '',
      timeSetUp: {
        format: 'HH:mm',
        valueFormat: 'HH:mm',
        placeholder: '时间',
        clearable: true
      },
      labelCol: { lg: 6, xl: 5, xxl: 4 },
      wrapperCol: { lg: 18, xl: 19, xxl: 20 },
      clockInTimesList: [],
      form: {
        title: '',
        content: '',
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        startTime: '',
        endDate: '',
        allDay: undefined,
        duration: '2',
        remind: '3',
        repeatType: '1'
      },
      rules: {
        title: [
          { required: true, message: '请输入日程标题', trigger: 'blur' },
          { min: 1, max: 15, message: '日程标题仅支持1~15个文字', trigger: 'change' }
        ],
        content: [
          { required: true, message: '请输入内容描述', trigger: 'blur' }
        ]
      },
      // 编辑备忘类型api
      editTypeObj: {
        1: {
          api: updateCurrMemo
        },
        3: {
          api: updateFutureMemo
        }
      }
    }
  },
  methods: {
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (!this.modalType) {
            this.submitCreate()
          } else {
            this.submitEdit()
          }
          return true
        }
        this.$message.error('输入信息不完整！')
      })
    },
    // 确定创建
    submitCreate: debounce(async function() {
      const res = await createMemo({ ...this.form, allDay: this.form.allDay ? '1' : '0' })
      if (res.code === 100) {
        this.$message.success('创建成功！')
        this.visible = false
        this.$emit('refresh')
      }
    }, 500),
    // 确定编辑
    submitEdit: debounce(async function() {
      console.log(this.form.editType)
      const res = await this.editTypeObj[this.form.editType].api({ ...this.form, allDay: this.form.allDay ? '1' : '0' })
      if (res.code === 100) {
        this.$message.success('保存成功！')
        this.visible = false
        this.$emit('refresh')
      }
    }, 500),
    open(type, data) {
      this.visible = true
      this.resetData()
      this.modalType = type
      if (type) {
        this.$nextTick(() => {
          this.form = { ...data, startDate: data.memoDate }
        })
      }
    },
    resetData() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
        Object.assign(this.$data.form, this.$options.data().form)
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.has-error .ant-form-explain {
  position: absolute;
  bottom: -15px;
}
</style>
<style>
.ant-time-picker-panel-select::-webkit-scrollbar {
    display:none ;
}
</style>
