<template>
  <a-form-model
    ref="basicForm"
    :model="form"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
    autocomplete="off"
  >
    <a-form-model-item label="薪资组名称" prop="groupName">
      <a-input v-model="form.groupName" placeholder="1-30个字符" @input="form.groupName = form.groupName.replace(/[^\w,\u4e00-\u9fa5,(,),（,）,_,-]/g,'')" />
    </a-form-model-item>
    <a-form-model-item label="启用年月" prop="startPeriod">
      <a-month-picker v-model="form.startPeriod" value-format="YYYY-MM" format="YYYY-MM" />
    </a-form-model-item>
    <a-form-model-item label="适用员工" prop="userList">
      <CbSelectPersons :query-leave-person="true" :data-echo="form.userList" @perModelConfirm="perModelConfirm" />
    </a-form-model-item>
    <a-form-item label="是否启用">
      <a-switch v-model="form.enabled" />
    </a-form-item>
  </a-form-model>
</template>

<script>
import mixins from '@/components/CbPerDepSelection/index.js'
export default {
  components: {
    CbSelectPersons: () => import('@/components/CbSelectPersons/index')
  },
  mixins: [mixins],
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    labelCol: {
      type: Object,
      default: () => {}
    },
    wrapperCol: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      model: false,
      rules: {
        groupName: [
          { required: true, message: '请输入薪资组名称', trigger: 'blur' },
          { min: 1, max: 30, message: '1-30个字符', trigger: 'change' }
        ],
        startPeriod: [
          { required: true, message: '请选择薪资年月', trigger: 'change' }
        ],
        userList: [
          { required: true, message: '请选择人员', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    perModelConfirm(data) {
      this.form.userList = data.map((v) => {
        return {
          onJobId: v.onJobId,
          staffName: v.staffName,
          staffId: v.staffId
        }
      })
      this.$refs.basicForm.validateField(['userList'])
      this.model = false
    },
    selectEmployees() {
      this.model = true
    }
  }
}
</script>
<style scoped lang="less">
/deep/.ant-form-item{
  margin-bottom: 14px;
}
</style>
