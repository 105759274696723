<template>
  <Modal
    :closable="true"
    :visible="visible"
    :z-index="100"
    :size="'normal'"
    :mask-closable="false"
    :title="modalTitle + '分类'"
    :ok-text="'确认'"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="分类名称" prop="groupName">
        <a-input v-model="form.groupName" placeholder="请输入1-20个字符" />
      </a-form-model-item>
    </a-form-model>
  </Modal>
</template>

<script>
import { createFlowGroup, updateFlowGroup } from '@/services/logicFlow'
export default {
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  props: {
    sortNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      form: {
        handleType: 1,
        groupName: '',
        flowGroupId: ''
      },
      rules: {
        groupName: [
          {
            required: true,
            message: '请输入分类名称',
            trigger: 'blur'
          },
          { min: 1, max: 10, message: '请输入1-10个字符', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    modalTitle() {
      return this.form.handleType ? '新建' : '编辑'
    }
  },
  mounted() {
  },
  methods: {
    // 确认操作
    handleSubmit() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          const { sortNum } = this
          const { groupName, flowGroupId } = this.form
          if (this.form.handleType) {
            const { code } = await createFlowGroup({ groupName, sortNum })
            if (code !== 100) return
            this.$message.success('创建成功')
            this.visible = false
          } else {
            const { code } = await updateFlowGroup({ groupName, flowGroupId })
            if (code !== 100) return
            this.$message.success('修改成功')
            this.visible = false
          }
          this.$emit('upFlowGroup')
        }
      })
    },
    // 取消事件
    handleCancel() {
      this.visible = false
      this.resetForm()
    },
    // 重置表单内容和验证
    resetForm() {
      this.form = Object.assign(this.form, this.$data.form)
      this.$refs.ruleForm.resetFields()
    },
    // 打开弹窗
    openModal(modal, groupDetail = {}) {
      this.form.handleType = modal.handleType
      this.form.flowGroupId = groupDetail.flowGroupId || ''
      this.form.groupName = groupDetail.groupName || ''
      this.visible = true
    }
  }
}
</script>

<style lang="less" scoped>
</style>
