<!--
 * @Date: 2022-07-07 13:55:58
 * @descriotion: 12312
-->
<template>
  <Drawer
    :title="title"
    :visible="visible"
    size="small"
    :z-index="2"
    @close="onClose"
    @onSubmit="onsubmit"
  >
    <div class="content">
      <UserInfo :user-info="userInfo" />
      <div class="insurance">
        <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item label="已投保">
            <span v-for="(item, index) in staffInsure.yetInsureClassify" :key="item.insureClassify">
              <span v-if="index !== 0">、</span>
              <span>{{ item.label }}</span>
            </span>
          </a-form-model-item>
          <a-form-model-item label="增投保" prop="type">
            <a-checkbox-group v-model="form.type">
              <a-checkbox v-for="item in staffInsure.notInsureClassify" :key="item.insureClassify" :value="item.insureClassify" :name="item.label">
                {{ item.label }}
              </a-checkbox>
            </a-checkbox-group>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="setForm">
        <AddFormVue v-if="form.type.includes('social')" ref="social" title="社保" insure-classify="social" :is-flag="false" />
        <AddFormVue v-if="form.type.includes('medical')" ref="medical" title="医保" insure-classify="medical" :is-flag="false" />
        <AddFormVue v-if="form.type.includes('housing')" ref="housing" title="公积金" insure-classify="housing" :is-flag="false" />
      </div>
    </div>
  </Drawer>
</template>

<script>
import {
  getStaffInsureClassifyApi,
  insureApi,
  getInsureBillRemindStatusApi
} from '@/services/insurance/archives'
import { insureTips } from '../../bill/dict.js'
export default {
  name: 'IncreaseInsurance',
  components: {
    UserInfo: () => import('./userInfo'),
    AddFormVue: () => import('./addForm'),
    Drawer: () => import('@/components/CbDrawer/index')
  },
  props: {
    title: {
      type: String,
      default: '增投保'
    }
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      visible: false,
      form: {
        type: []
      },
      userInfo: {},
      staffInsure: {
        yetInsureClassify: [],
        notInsureClassify: []
      },
      rules: {
        type: [{ type: 'array', required: true, message: '请选择增投保类型', trigger: 'change' }]
      }
    }
  },
  mounted() {

  },
  methods: {
    onClose() {
      this.visible = false
      this.form = {
        type: []
      }
      this.staffInsure = {
        yetInsureClassify: [],
        notInsureClassify: []
      }
      this.userInfo = {}
      this.$refs.ruleForm.resetFields()
    },
    show(data) {
      this.userInfo = data
      this.getStaffInsureClassifyApi(data.onJobId)
      this.visible = true
    },
    onsubmit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          let social = {}
          let medical = {}
          let housing = {}
          if (this.form.type.includes('social')) {
            social = await this.$refs.social.getData()
            if (!social) return
          }
          if (this.form.type.includes('medical')) {
            medical = await this.$refs.medical.getData()
            if (!medical) return
          }
          if (this.form.type.includes('housing')) {
            housing = await this.$refs.housing.getData()
            if (!housing) return
          }
          const params = {
            onJobIds: [this.userInfo.onJobId],
            socialStatus: this.form.type.includes('social') ? 1 : 0,
            social: this.form.type.includes('social') ? social.formData : null,
            medicalStatus: this.form.type.includes('medical') ? 1 : 0,
            medical: this.form.type.includes('medical') ? medical.formData : null,
            housingStatus: this.form.type.includes('housing') ? 1 : 0,
            housing: this.form.type.includes('housing') ? housing.formData : null
          }
          const paramMonth = {
            socialMonth: this.form.type.includes('social') ? social.formData.startMonth : null,
            medicalMonth: this.form.type.includes('medical') ? medical.formData.startMonth : null,
            housingMonth: this.form.type.includes('housing') ? housing.formData.startMonth : null
          }
          const res = await getInsureBillRemindStatusApi(paramMonth)
          if (res.code === 100) {
            let tips = ''
            if (res.data.socialBillRemindStatus === '1' && res.data.medicalBillRemindStatus === '1' && res.data.housingBillRemindStatus === '1') {
              this.addInsure(params)
            } else {
              const arr = [res.data.socialBillRemindStatus, res.data.medicalBillRemindStatus, res.data.housingBillRemindStatus]
              const newArr = new Set(arr)
              newArr.forEach(item => {
                if (item !== '1') {
                  tips += insureTips[item]
                }
              })
              this.$Reconfirm({
                title: '提示',
                content: tips
              }).then(() => {
                this.addInsure(params)
              }).catch(() => {})
            }
          }
        } else {
          return false
        }
      })
    },
    // 提交
    async addInsure(params) {
      const res = await insureApi(params)
      if (res.code === 100) {
        this.$message.success('投保成功！')
        this.$emit('callBackBatch')
        this.onClose()
      }
    },
    // 获取员工投保分类的投保情况信息（增投保时使用）
    async getStaffInsureClassifyApi(onJobId) {
      const res = await getStaffInsureClassifyApi({ onJobId })
      this.staffInsure.yetInsureClassify = res.data.yetInsureClassify
      this.staffInsure.notInsureClassify = res.data.notInsureClassify
    }
  }
}
</script>
<style lang="less" scoped>
  .insurance {
    box-sizing: border-box;
    /deep/.ant-form{
      padding-bottom: 0 !important;
    }
  }
  .content{
    // padding:20px;
    width:100%;
    height:100%;
    position:relative;
    .font-size(14px);
    .setForm{
      width: 100%;
      height: calc(100% - 400px);
      overflow: auto;
      /deep/.ant-form{
        padding-bottom: 0 !important;
      }
    }
  }
</style>
