<template>
  <div>
    <Modal
      :visible="visible"
      :closable="true"
      :mask-closable="false"
      :title="'编辑字段组名称'"
      :ok-text="'确认'"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="字段组名称">
          <a-input v-model="form.fieldGroupName" />
        </a-form-model-item>
      </a-form-model>
    </Modal>
  </div>
</template>

<script>
import { updateFieldGroup } from '@/services/user/fieldGroup.js'
export default {
  name: 'EditHeader',
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      visible: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      form: {
        fieldGroupName: '',
        fieldGroupId: ''
      }
    }
  },
  methods: {
    show(data) {
      this.visible = true
      this.form.fieldGroupName = data.fieldGroupName
      this.form.fieldGroupId = data.fieldGroupId
    },
    async handleOk() {
      const res = await updateFieldGroup({
        fieldGroupId: this.form.fieldGroupId,
        fieldGroupName: this.form.fieldGroupName
      })
      if (res.code === 100) {
        this.$message.success('字段组名称更新成功')
        this.handleCancel()
        this.$emit('reload')
      }
    },
    handleCancel() {
      this.resetForm()
      this.visible = false
    },
    resetForm() {
      this.form = {
        fieldGroupName: '',
        fieldGroupId: ''
      }
    }
  }
}
</script>

<style scoped>

</style>
