<template>
  <div class="SystemMatter">
    <!-- 系统日程 -->
    <div v-for="matter in systemMatterList" :key="matter.id">
      <div v-if="matter.list.length" class="matter flex-align-center">
        <span class="matter-time">全天</span>
        <div class="matter-content">
          <div class="matter-title flex-align-center">
            <span :class="matter.matterType?'success':'warning'" class="type-color" />
            <span>今日{{ matter.label }}</span>
          </div>
          <div class="matter-text">
            <span v-for="(user,i) in matter.list" :key="i">
              <span v-if="i<3">{{ user.staffName }}
                <span v-if="matter.type==='overtime'" class="num">{{ user.overtimeHours }}小时</span>
                <span v-else-if="matter.type==='absent'" class="num">{{ user.absentHours }}小时</span>
                <span v-else-if="matter.type==='late'" class="num">{{ user.lateinMinutes }}分钟</span>
                <span v-else-if="matter.type==='early'" class="num">{{ user.earlyoutMinutes }}分钟</span>
                <span v-else-if="matter.type==='miss'" class="num">{{ user.missTimes }}次</span>
                <span v-if="(matter.list.length>2&&i!==2)||(matter.list.length<3&&i!==matter.list.length-1)" class="num">、</span>
              </span>
            </span>
            <span v-if="matter.list.length>3">等{{ matter.list.length-3 }}人</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SystemMatter',
  props: {
    systemMatterList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="less">
.SystemMatter {
  padding: 0 8px;
  .matter {
      height: 75px;
      padding: 0 6px;
      border-bottom: 1px solid @border-color;
      .matter-time{
        display: inline-block;
        width: 35px;
      }
      .matter-content {
        margin-left: 14px;
        .font-size(14px);
        .matter-title {
          margin-bottom: 4px;
          span:nth-child(2) {
            margin-left: 8px;
            color: @sc-grey-100;
            font-weight: bold;
          }
          .type-color {
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
          }
          .success {
            background: rgba(97, 243, 192, 1);
          }
          .warning {
            background: rgba(255, 155, 62, 1);
          }
        }
        .matter-text{
          .multi-ellipsis(1);
          color: @sc-grey-60;;
          margin-left: 14px;
          .num{
            color: @sc-grey-100;
          }
        }
      }
    }
    .matter > span {
      color: @sc-grey-40;
      .font-size(14px);
      display: inline-block;
      width:40px;
    }
    .matter:hover {
      border-radius: 4px;
      background: @sc-greyBg-10;
    }
}
</style>
