<template>
  <div class="post-table">
    <div class="post-toolbar">
      <div class="btn_box">
        <div class="post-tb-left">
          <a-button prefix="add-one" @click="handleAdd()">
            <span>新建职务</span>
          </a-button>
          <a-button prefix="reduce-one" :disabled="!selectedRowKeys.length" @click="handleDel">
            <span>批量删除职务</span>
          </a-button>
        </div>
        <div class="post_ta_left">
          <a-tooltip placement="top">
            <template slot="title">
              <span>导出</span>
            </template>
            <a-button type="iconBtn" icon="daochu" @click="exportExcel" />
          </a-tooltip>
        </div>
      </div>
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        table-ref=".post-table"
        row-key="dutyId"
        :pagination="{
          current: pager.pageNum,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条数据`,
          showQuickJumper: true,
          showSizeChanger: true
        }"
        :loading="loading"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          columnWidth: 25,
          onChange: onSelectChange
        }"
        :selection-mode="selectionMode"
        @change="loadDataList"
      >
        <!-- 占比头部自定义插槽 -->
        <!-- 操作列 -->
        <template slot="operation" slot-scope="{ scope }">
          <span class="operation-btn-box">
            <a-button type="link" @click="handleAdd(scope.record)">
              编辑
            </a-button>
            <a-button type="link" class="delete" @click.once="delSingle(scope.record)">
              删除
            </a-button>
          </span>
        </template>
      </custom-table>
      <addForm ref="addForm" @refresh="tableRefresh" />
      <!-- <postForm ref="postForm" @reload="postFormReload" /> -->
    </div>
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import { getDutyList, deleteDuty, batchDeleteDuty, exportDutyList } from '@/services/organization/duty.js'
export default {
  name: 'DutiesTable',
  components: {
    addForm: () => import ('./components/addForm.vue')
  },
  mixins: [mixins],
  props: {
    jobTypeInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      columns: [
        {
          title: '职务名称',
          dataIndex: 'dutyName',
          key: 'dutyName',
          width: '200px'
        },
        {
          title: '在职人数',
          dataIndex: 'onJobCount',
          key: 'onJobCount',
          width: '100px'
        },
        {
          title: '职务描述',
          dataIndex: 'dutyDescribe',
          key: 'dutyDescribe',
          width: '300px',
          ellipsis: true
        },
        {
          title: '能力要求',
          dataIndex: 'capacityDemand',
          key: 'capacityDemand',
          width: '500px',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '100px',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      dataSource: [],
      selectedRowKeys: [],
      selectionMode: true,
      pager: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      loading: true
    }
  },
  methods: {
    // 获取列表
    async fetchDataApiFn(param) {
      const res = await getDutyList(param)
      this.dataSource = res.data.dutyList
      this.loading = false
      this.pager.pageNum = res.data.pageNum || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.pageSize || 10 // 更新当前的分页信息
      this.total = res.data.total
    },
    // 新建职务 & 编辑职务  弹窗
    handleAdd(userInfo) {
      this.$refs.addForm.open(userInfo || undefined)
    },
    // 批量删除前弹窗提示
    async handleDel() {
      this.$Reconfirm({
        title: '批量删除职务',
        content: `确定要删除选中的职务吗？`
      }).then(() => {
        this.batchdel()
      }).catch(() => {
        console.log('no')
      })
    },
    // 单个删除前弹窗提示
    async delSingle(userInfo) {
      this.$Reconfirm({
        title: '删除职务',
        content: `确定要删除${userInfo.dutyName}该职务吗？`
      }).then(() => {
        this.del(userInfo)
      }).catch(() => {
        console.log('no')
      })
    },
    // 表格选择项变更
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    async exportExcel() {
    // dutyName 接口参数 可选
      const res = await exportDutyList()
      if (res.code === 100) {
        this.$message.success('导出成功')
      }
    },
    // 编辑&新增成功刷新列表
    tableRefresh() {
      this.fetchDataApiFn(this.pager)
    },
    // 单个删除
    async del(userInfo) {
      const res = await deleteDuty({ dutyId: userInfo.dutyId })
      if (res.code === 100) {
        this.$message.success('职务删除成功')
        this.tableRefresh(this.pager)
        this.selectedRowKeys.forEach((i, index) => {
          if (i === userInfo.dutyId) {
            this.selectedRowKeys.splice(index, 1)
          }
        })
      }
    },
    async batchdel() {
      const res = await batchDeleteDuty({ dutyIdList: this.selectedRowKeys })
      if (res.code === 100) {
        this.selectedRowKeys = []
        this.$message.success('批量删除成功')
        this.tableRefresh(this.pager)
      }
    }
  }
}
</script>

<style lang="less" scoped>
    @fontSize: 14px;
   .post-table{
    background-color: #fff;
    padding: 20px 20px 0 20px;
    .btn_box{
        display: flex;
        justify-content: space-between;
    }
    .post-tb-left{
        width: 240px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-around;
    }
   }
    p {
        color:  @sc-grey-80;
    }
    .operation-btn-box{
      display:flex;
      button{
        padding-left:0;
        padding-right:16px;
      }
    }
    /deep/ .ant-table-thead > tr > th {
      color: @sc-grey-100;
      font-size: @fontSize;
    }
    /deep/ .ant-table-tbody > tr > td {
      color: @sc-grey-80;
      font-size: @fontSize;
      padding: 10px 8px;
    }
    /deep/ .ant-table-tbody > tr:hover {
      background: @sc-primary-10;
    }
    </style>

