<template>
  <div>
    <Modal
      overflow-auto
      :closable="true"
      :title="'组织类型管理'"
      :visible="visible"
      :z-index="2"
      :mask-closable="false"
      :ok-text="'保存'"
      :size="'normal'"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="organization_title">组织类型</div>
      <vueDragGable v-model="orgTypeAllList" class="wrapper">
        <transition-group>
          <div v-for="(it, index) in orgTypeAllList" :key="`management_list${index}`" class="management_list">
            <svg class="iconpark-icon management_list_icon"><use href="#application-two" /></svg>
            <!-- <a-icon type="fullscreen" class="management_list_icon" /> -->
            <a-input v-model="it.orgTypeName" placeholder="自定义选项，1-50字符" :disabled="it.createTypeDisabled" class="management_list_input" />
            <a-switch v-model="it.switchStatusType" size="small" />
            <!-- <a-popconfirm title="是否确认删除？" placement="left" ok-text="确认" cancel-text="取消" @confirm=""> -->
            <span v-if="it.createType === 1" class="management_list_del" @click="delOrganization(it, index)">删除</span>
            <!-- </a-popconfirm> -->
          </div>
        </transition-group>
      </vueDragGable>
      <a-button v-if="showAddButton" class="management_list_button" type="link" prefix="add-one" @click="addOrganizationType">添加</a-button>
    </Modal>
  </div>
</template>

<script>
import vueDragGable from 'vuedraggable'
import { getOrgTypeAllList, createOrUpdateOrgType, deleteOrgType } from '@/services/organization/framework.js'
export default {
  name: 'OrganizationManagement',
  components: {
    vueDragGable,
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      visible: false,
      orgTypeAllList: [
        {
          orgTypeId: null,
          orgTypeName: '',
          switchStatus: 0,
          switchStatusType: false,
          createType: 1,
          createTypeDisabled: false,
          sortNum: null
        }
      ]
    }
  },
  computed: {
    // 计算添加按钮是否展示
    showAddButton() {
      const arr = this.orgTypeAllList.filter(item => item.createType === 1)
      let type = true
      if (arr.length >= 10) {
        type = false
      } else {
        type = true
      }
      return type
    }
  },
  methods: {
    show() {
      this.visible = true
      this.getOrgTypeAllList()
    },
    // 查询组织类别列表
    async getOrgTypeAllList() {
      const res = await getOrgTypeAllList()
      this.orgTypeAllList = res.data.map(item => {
        return {
          ...item,
          switchStatusType: item.switchStatus !== 0,
          createTypeDisabled: item.createType === 0
        }
      })
    },
    // 保存
    async handleOk() {
      const notFilledList = this.orgTypeAllList.filter(item => !item.orgTypeName)
      if (notFilledList && notFilledList.length > 0) return this.$message.warning('您有新增未填项，请填写未填项在保存')
      const submitList = this.orgTypeAllList.map((item, index) => {
        return {
          orgTypeId: item.orgTypeId,
          orgTypeName: item.orgTypeName,
          switchStatus: item.switchStatusType ? 1 : 0,
          createType: item.createType,
          sortNum: index
        }
      })
      const res = await createOrUpdateOrgType(submitList)
      if (res.code === 100) {
        this.$message.success('保存成功')
        this.visible = false
      }
    },
    // 关闭弹窗
    handleCancel() {
      this.visible = false
      this.orgTypeAllList = []
    },
    // 删除组织
    async delOrganization(data, index) {
      this.$Reconfirm({
        title: '提示',
        content: '确认删除该组织类型吗？'
      }).then(async() => {
        if (data.orgTypeId) {
          const res = await deleteOrgType({ orgTypeId: data.orgTypeId })
          if (res.code === 100) {
            this.$message.success('删除成功')
            this.getOrgTypeAllList()
          }
        } else {
          this.orgTypeAllList.splice(index, 1)
          this.$message.success('删除成功')
        }
      }).catch(() => {
        console.log('cancel')
      })
    },
    // 添加组织类型
    addOrganizationType() {
      this.orgTypeAllList.push({
        orgTypeId: null,
        orgTypeName: '',
        switchStatus: 0,
        switchStatusType: false,
        createType: 1,
        createTypeDisabled: false,
        sortNum: null
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .organization_title {
    font-size: 14px;
    color: @sc-grey-80;
    margin-bottom: 16px;
  }
  .management_list {
    margin-bottom: 20px;
    .management_list_input {
      width: 368px;
      margin: 0 14px 0 15px;
    }
    .management_list_icon {
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }
    .management_list_del {
      cursor: pointer;
      font-size: 14px;
      color: @error-color;
      margin-left: 18px;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  .management_list_button {
    margin-top: 16px;
    margin-left: 30px;
  }
</style>
