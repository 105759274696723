<template>
  <div class="slip-all dont-copy">
    <div v-if="!detailsShow">
      <div class="calendar-top">
        <svg class="iconpark-icon" @click="switchYear('reduce')"><use href="#left" /></svg>
        <!-- <a-icon type="left" class="icon" @click="switchYear('reduce')" /> -->
        <div class="timePicker">
          <CbYearPicker v-model="yearTime" :allow-clear="false" class="CbYearPicker" />
        </div>
        <svg class="iconpark-icon right" @click="switchYear('add')"><use href="#left" /></svg>
        <!-- <a-icon type="right" class="icon" @click="switchYear('add')" /> -->
      </div>
      <CbNoData v-if="isRequest&&!payrollStatusList.length" width="200" />
      <template v-else>
        <div v-for="(item, index) in payrollStatusList" :key="index" class="slip-all-item">
          <CbStatistics :data-list="item.list" length="3" :is-title="true" :title="`${item.period}月工资条`" :title-width="336" :is-logo="true" :is-function="true" class="statistics">
            <template slot="right">
              <a-button prefix="chakan-chakanmingxi" @click="handleDetails(item.period)">查看</a-button>
            </template>
          </CbStatistics>
          <!-- <a-space :size="50">
            <div style="padding-left: 30px">{{ item.period }}月工资条</div>
            <a-divider type="vertical" style="height: 50px" />
            <div class="slip-all-item-div">
              <span class="slip-all-item-div-num">{{ item.sendCount }}/{{ item.allCount }}</span>
              <span>发送</span>
            </div>
            <div class="slip-all-item-div">
              <span class="slip-all-item-div-num">{{ item.viewCount }}</span>
              <span>查看</span>
            </div>
            <div class="slip-all-item-div">
              <span class="slip-all-item-div-num">{{ item.confirmCount }}</span>
              <span>确认</span>
            </div>
          </a-space>
          <a-button type="link" icon="file-search" @click="handleDetails(item.period)">查看</a-button> -->
        </div>
      </template>
    </div>
    <SlipTable v-else :current-time="currentTime" @goBack="goBack" />
  </div>
</template>

<script>
import SlipTable from './table.vue'
import { getStatusList } from '@/services/wage/staffPayroll.js'
import CbNoData from '@/components/CbNoData'
import CbYearPicker from '@/components/CbYearPicker'
export default {
  components: {
    SlipTable,
    CbNoData,
    CbYearPicker,
    CbStatistics: () => import('@/components/CbStatistics/index')
  },
  data() {
    return {
      payrollStatusList: [],
      detailsShow: false, // 详情展示
      yearTime: '', // 计薪年份
      yearShow: false,
      currentTime: null,
      // 是否请求
      isRequest: false
    }
  },
  watch: {
    yearTime() {
      this.getStatusList()
    }
  },
  created() {
    const { period } = this.$route.params
    if (period) {
      this.currentTime = period
      this.detailsShow = true
    }
  },
  mounted() {
    this.yearTime = new Date().getFullYear() + ''
    this.getStatusList()
  },
  methods: {
    panelChange(time) {
      this.yearTime = time.format('YYYY')
      this.yearShow = false
      this.getStatusList()
    },
    switchYear(mode) {
      let result = 0
      // 转数字 参与计算
      const yearTimeNum = Number(this.yearTime)
      if (mode === 'add') {
        // this.yearTime
        result = yearTimeNum + 1
      }
      if (mode === 'reduce') {
        result = yearTimeNum - 1
      }
      // 转回字符串 赋值
      result = String(result)
      this.yearTime = result
    },
    async getStatusList() {
      const { yearTime: year } = this
      const { data } = await getStatusList({
        year
      })
      this.payrollStatusList = data.map((
        { period, viewCount, sendCount, confirmCount, allCount }
      ) => {
        return {
          period: period,
          list: [
            {
              label: '发送',
              num: `${sendCount || 0}/${allCount || 0}`
            }, {
              label: '查看',
              num: viewCount || 0
            }, {
              label: '确认',
              num: confirmCount || 0
            }
            // , {
            //   label: '反馈',
            //   num: 0
            // }
          ]
        }
      })
      this.isRequest = true
    },
    handleDetails(date) {
      const { yearTime } = this
      this.currentTime = yearTime + date
      this.detailsShow = true
    },
    goBack() {
      this.detailsShow = false
    }
  }
}
</script>

<style lang="less" scoped>

.slip-all {
  padding: 20px;
  background-color: @gray-1;
  height: auto;
  min-height: 100%;
  // overflow: hidden;
  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    font-size: 14px;
    margin: 0 0 12px;
  }
}
  .calendar-top{
    width: auto;
    margin: 0 auto;
    display: flex;
    padding: 0 0 20px 0;
    align-items: center;
    justify-content: center;
    .iconpark-icon{
      width: 20px;
      height: 20px;
    }
    .right{
      transform: rotate(180deg);
    }
    .timePicker{
      /deep/.ant-calendar-picker {
        width: 100px;
        font-size: 16px;
        /deep/.ant-calendar-picker-input{
          border: 0;
          font-size: 16px;
          text-align: center;
        }
        /deep/.ant-calendar-picker-clear, /deep/.ant-calendar-picker-icon{
          display: none;
        }
      }
    }
  }
  .CbYearPicker {
    /deep/.ant-input {
      border: none !important;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: @sc-grey-100;
      line-height: 1;
    }
    /deep/ .ant-calendar-picker {
      display: flex;
      align-items: center;
    }
    /deep/ .anticon-calendar {
      display: none !important;
    }
  }
.statistics{
  /deep/.ant-btn{
    color: @sc-grey-100;
    &:hover{
      color: @sc-primary-100;
    }
  }
}
</style>
