<template>
  <div>
    <!--  添加 搜索 筛选  -->
    <appForm
      ref="appForm"
      :to-list="toList"
      :screen-roster-list="screenRosterList"
      width="400"
      @getSearchCallback="getSearchCallback"
      @screenRosterParams="screenRosterParams"
      @exportFileList="exportFileList"
    >
      <template slot="customButton">
        <a-button v-if="keyval === '1'" type="primary" prefix="add-one" @click="handleAdd()">
          添加待入职
        </a-button>
      </template>
    </appForm>
    <Alert v-if="keyval === '2'" type="warning" :show-icon="true" :banner="true">
      <template #content>
        <div class="alertTips">提示：最近入职列表仅显示近90天内的入职员工</div>
      </template>
    </Alert>
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      :tabs-height="36"
      :row-key="tableId ? 'onJobId' : 'entryApplyId'"
      table-ref=".main-content"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="loadDataList"
    >
      <!-- 序号 -->
      <template slot="index" slot-scope="{ scope }">
        <span>{{ scope.index + 1 }}</span>
      </template>
      <template slot="name" slot-scope="{ scope }">
        <span :title="scope.record.name" class="name">{{ scope.record.name }}</span>
      </template>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{ scope }">
        <span v-if="keyval === '1'" class="option-span">
          <a-button type="link" @click="handleAdd(scope)"> 确认入职 </a-button>
          <a-popover placement="bottom" trigger="hover">
            <template slot="content">
              <div class="more-handle-btn">
                <a-button type="link" @click="trimTime(scope)"> 调整入职日期 </a-button>
                <a-button type="link" @click="handleGiveUp(scope)"> 放弃入职 </a-button>
              </div>
            </template>
            <div class="more-handle">···</div>
          </a-popover>
        </span>
        <span v-if="keyval === '2'">
          <div class="more-handle-btn-box">
            <div class="option">
              <!-- v-if="scope.record.completeEntryMatter !== 0 || scope.record.allEntryMatter !== 0"  -->
              <a-button type="link" @click="handleMatter(scope)">
                入职事项 {{ scope.record.completeEntryMatter }}/{{ scope.record.allEntryMatter }}
              </a-button>
            </div>
            <div class="table">
              <a-button v-if="scope.record.entryFormExamShow === 1" type="link" @click="entryAudit(scope)">
                入职登记表审核
              </a-button>
            </div>
          </div>
          <!-- <div class="more-handle">···</div> -->
        </span>
        <a-button v-if="keyval === '3'" type="link" class="delete" @click="editItemConfirm(scope)"> 删除 </a-button>
      </template>
    </custom-table>
    <!-- 确认入职页面 -->
    <confirmForm ref="confirmForm" @getFetchDataApiFn="getFetchDataApiFn('join')" />
    <!-- 调整入职日期 -->
    <trimTimeForm ref="trimTimeForm" @getFetchDataApiFn="getFetchDataApiFn" />
    <!-- 放弃入职 -->
    <giveUpForm ref="giveUpForm" @getFetchDataApiFn="getFetchDataApiFn('leave')" />
    <!-- 入职事项 -->
    <matter ref="matter" @open="entryMattersEvent" />
    <!-- 添加页面 -->
    <inductionClassForm ref="inductionClassForm" :is-add="isAdd" :entry-apply-id="entryApplyId" :modal-title="modalTitle" @getFetchDataApiFn="getFetchDataApiFn" @entryMattersEvent="entryMattersEvent" />
    <!-- 入职事项一条龙 -->
    <entryMatters ref="entryMatters" @refreshTable="refreshTable" @closeSon="closeSon" />
  </div>
</template>

<script>
import moment from 'moment'
import mixins from '@/components/CustomTable/index.js'
import { getEntryApplyInfo, waitEntryList, recentlyHiredList, noEntryList, deleteNoEntry, waitEntryListExport, recentlyHiredListExport, noEntryListExport } from '@/services/user/Induction.js'
export default {
  name: 'InductionTabel',
  components: {
    Alert: () => import('@/components/CbAlert/index'),
    confirmForm: () => import('./form/confirmForm.vue'),
    trimTimeForm: () => import('./form/trimTimeForm.vue'),
    giveUpForm: () => import('./form/giveUpForm.vue'),
    matter: () => import('./form/matter.vue'),
    inductionClassForm: () => import('./form/InductionForm.vue'),
    entryMatters: () => import('./form/entryMatters.vue')
  },
  mixins: [mixins],
  props: {
    keyval: {
      type: String,
      default: ''
    },
    screenRosterListData: {
      type: Object,
      default: () => {}
    },
    isFirst: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      lazyInitData: true,
      tableId: false,
      entryApplyId: '',
      toList: ['search', 'screen', 'export'], // search搜索框 screen筛选 choice位置选择弹窗 export导出
      total: 0,
      columns: [],
      employedList: [
        {
          title: '序号',
          dataIndex: 'index',
          width: '50px',
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: { customRender: 'name' },
          width: 70,
          ellipsis: true
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          key: 'phoneNum',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          ellipsis: true
        },
        {
          title: '职务',
          dataIndex: 'dutyName',
          key: 'dutyName',
          ellipsis: true
        },
        {
          title: '聘用形式',
          dataIndex: 'engageModeName',
          key: 'engageModeName'
        },
        {
          title: '计划入职日期',
          dataIndex: 'planEntryDate',
          key: 'planEntryDate'
        },
        {
          title: '招聘方式',
          dataIndex: 'recruitTypeName',
          key: 'recruitTypeName'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ], // 待入职
      recentList: [
        {
          title: '序号',
          dataIndex: 'index',
          width: '50px',
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: { customRender: 'name' },
          width: 70,
          ellipsis: true
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          key: 'phoneNum',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          ellipsis: true
        },
        {
          title: '职务',
          dataIndex: 'dutyName',
          key: 'dutyName',
          ellipsis: true
        },
        {
          title: '聘用形式',
          dataIndex: 'engageModeName',
          key: 'engageModeName'
        },
        {
          title: '入职日期',
          dataIndex: 'actualEntryDate',
          key: 'actualEntryDate'
        },
        {
          title: '入职登记表',
          dataIndex: 'entryFormExamStatus',
          key: 'entryFormExamStatus'
        },
        {
          title: '招聘方式',
          dataIndex: 'recruitTypeName',
          key: 'recruitTypeName'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '240px'
        }
      ], // 最近入职
      notEmployedList: [
        {
          title: '序号',
          dataIndex: 'index',
          width: '50px',
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: { customRender: 'name' },
          width: 70,
          ellipsis: true
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          key: 'phoneNum',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          ellipsis: true
        },
        {
          title: '职务',
          dataIndex: 'dutyName',
          key: 'dutyName',
          ellipsis: true
        },
        {
          title: '聘用形式',
          dataIndex: 'engageModeName',
          scopedSlots: { customRender: 'engageModeName' }
        },
        {
          title: '入职日期',
          dataIndex: 'planEntryDate',
          key: 'planEntryDate'
        },
        {
          title: '放弃原因',
          dataIndex: 'reasonsName',
          key: 'reasonsName'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ], // 未入职
      dataSource: [], // 数据
      selectionMode: true,
      screenRosterList: this.screenRosterListData,
      entryFormExamStatus: {
        0: '未填写',
        1: '审核中',
        2: '审核通过',
        3: '审核驳回'
      },
      modalTitle: '', // 弹窗标题
      isAdd: true
    }
  },
  watch: {
    keyval: {
      handler(val) {
        this.getFetchDataApiFn()
      }
    }
  },
  mounted() {
    // 首页待入职判断
    if (this.$route.params.isHome === 'toBeEmployed' && this.isFirst) {
      this.$emit('changeFirst')
      this._queryParam.startPlanEntryDate = moment(new Date()).format('YYYY-MM-DD')
      this._queryParam.endPlanEntryDate = moment(new Date()).add(10, 'days').format('YYYY-MM-DD')
      this.fetchDataApiFn(this._queryParam)
      this._queryParam.startPlanEntryDate = undefined
      this._queryParam.endPlanEntryDate = undefined
    } else if (this.$route.params.isHome === 'recentEmployment' && this.isFirst) {
      this.$emit('changeFirst')
      // 首页判断跳转参数
      this._queryParam.entryFormExamStatus = '1'
      this.fetchDataApiFn(this._queryParam)
      this._queryParam.entryFormExamStatus = undefined
    } else {
      this.fetchDataApiFn(this._queryParam)
    }
  },
  methods: {
    // 获取表格数据
    async fetchDataApiFn(param) {
      if (this.keyval === '1') {
        // 待入职
        this.columns = this.employedList
        const res = await waitEntryList(param)
        this.dataSource = res.data.records ? res.data.records : []
        this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
        this.total = res.data.total
      } else if (this.keyval === '2') {
        // 最近入职
        this.columns = this.recentList
        const res = await recentlyHiredList(param)
        res.data.records.forEach(item => {
          item.entryFormExamStatus = this.entryFormExamStatus[item.entryFormExamStatus]
        })
        this.dataSource = res.data.records ? res.data.records : []
        this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
        this.total = res.data.total
      } else {
        // 未入职
        this.columns = this.notEmployedList
        const res = await noEntryList(param)
        this.dataSource = res.data.records ? res.data.records : []
        this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
        this.total = res.data.total
      }
      this.tableId = Object.prototype.hasOwnProperty.call(this.dataSource[0], 'onJobId')
    },
    // 确认入职
    handleConfirm(val) {
      this.$refs.confirmForm.open(val)
    },
    // 调整转正日期
    trimTime(val) {
      this.$refs.trimTimeForm.open(val)
    },
    // 放弃入职
    handleGiveUp(val) {
      this.$refs.giveUpForm.open(val)
    },
    // 事项
    handleMatter(val) {
      this.$refs.matter.open(val.record)
    },
    // 删除确认
    editItemConfirm(val) {
      this.entryApplyId = val.record.entryApplyId
      this.$Reconfirm({
        title: '删除',
        content: '是否确认删除？'
      }).then(async() => {
        const res = await deleteNoEntry({
          entryApplyId: this.entryApplyId
        })
        if (res.code === 100) {
          this.$message.success('删除成功')
          this.serchGetData()
        }
      }).catch(() => {
        console.log('no')
      })
    },
    // 操作回调
    getFetchDataApiFn(val) {
      this.serchGetData()
    },
    // 搜索
    getSearchCallback(data) {
      this._queryParam.name = data.searchValue
      this.serchGetData()
    },
    // 筛选
    screenRosterParams(data) {
      const { employment, depMent, selectPost, recruitment, renounce, startPlanEntryDate, entryFormExamStatus } = data.screenCondition
      this._queryParam = {
        name: data.searchValue,
        engageMode: employment,
        orgId: depMent ? depMent[0].value : '',
        postId: selectPost,
        recruitType: recruitment,
        reasons: renounce,
        entryFormExamStatus: entryFormExamStatus,
        startPlanEntryDate: startPlanEntryDate ? startPlanEntryDate[0] : '',
        endPlanEntryDate: startPlanEntryDate ? startPlanEntryDate[1] : ''
      }
      this.serchGetData()
    },
    async handleAdd(scope) {
      let res
      if (scope) {
        this.entryApplyId = scope.record.entryApplyId
        res = await getEntryApplyInfo({ entryApplyId: this.entryApplyId })
        this.modalTitle = '确认入职信息'
        this.isAdd = false
      } else {
        this.modalTitle = '添加待入职'
        this.isAdd = true
      }
      this.$refs.inductionClassForm.open(res?.data || '')
    },
    // 入表审核
    entryAudit(data) {
      this.$router.push({
        path: '/staffIndex/userDetail',
        query: {
          staffId: data.record.staffId,
          statusType: 'toExamine', // 审核
          onJobId: data.record.onJobId,
          path: encodeURIComponent(this.$route.fullPath)
        }
      })
    },
    // 导出
    exportFileList() {
      const { name, engageMode, orgId, postId, recruitType, reasons, planEntryDate, startPlanEntryDate, endPlanEntryDate } = this._queryParam
      const params = {
        name: name,
        engageMode: engageMode,
        orgId: orgId,
        postId: postId,
        recruitType: recruitType,
        reasons: reasons,
        planEntryDate: planEntryDate,
        startPlanEntryDate: startPlanEntryDate,
        endPlanEntryDate: endPlanEntryDate
      }
      if (this.keyval === '1') {
        waitEntryListExport(params)
      } else if (this.keyval === '2') {
        recentlyHiredListExport(params)
      } else {
        noEntryListExport(params)
      }
    },
    // 当入职成功以后弹出入职事项一条龙服务
    entryMattersEvent(userInfo, dataList, key) {
      this.$refs.entryMatters.open(userInfo, dataList, key)
    },
    refreshTable() {
      this.fetchDataApiFn(this._queryParam)
    },
    closeSon(onJobId) {
      this.dataSource.map((item) => {
        if (item.onJobId === onJobId) {
          this.$refs.matter.open(item)
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
/deep/.ant-alert{
  margin-bottom: 14px;
  border-radius: 4px;
}
.option-span{
  display: flex;
}
.more-handle-btn {
  display: flex;
  flex-direction: column;
}
.more-handle-btn-box{
  line-height: 22px;
  .option, .table {
    display: inline-block;
    padding: 0;
    margin: 0;
  }
  .option{
    width: 80px;
    margin-right: 16px;
  }
}
.more-handle {
  cursor: pointer;
  font-weight: 700;
  font-size: 20px;
  color: @sc-primary-100;
  margin-left: 16px;
}
</style>
