<template>
  <div id="cardModal" class="card-rule">
    <div class="card-main">
      <AlertTips @look="lookTips">
        <template slot="content">
          <p>1、补卡规则，设置员工每月可补卡的次数。</p>
          <p>2、设置后，当员工发起补卡审批，超过次数将不允许提交补卡申请。</p>
          <p>3、可设置多个不同规则，设置后，前往【考勤组】把不同规则关联到各自考勤组。</p>
        </template>
      </AlertTips>
      <div class="addrulebtn">
        <a-button type="primary" prefix="add-one" @click="addCard">
          新增补卡规则
        </a-button>
      </div>
      <custom-table
        id="table"
        :columns="columns"
        :tabs-height="36"
        :data-source="dataSource"
        :scroll="{
          y: tableHeight
        }"
        row-key="appendPunchRuleId"
        :pagination="false"
      >
        <!-- 	补卡次数限制 -->
        <template slot="maxTimes" slot-scope="{scope}">
          <span>{{ scope.record.maxTimes }} 次/月</span>
        </template>
        <!-- 补卡时间限制 -->
        <template slot="appendPunchLimitType" slot-scope="{scope}">
          <div>{{ getCodeContent(scope.record.appendPunchLimitType,appendPunchLimitType) }}<span v-show="scope.record.appendPunchLimitType === '2'">，第{{ scope.record.nextMonthMaxDay }}天内可补卡</span>
            <span v-show="scope.record.appendPunchLimitType === '4'">起的{{ scope.record.missingDayMaxDay }}天内可补卡</span></div>
        </template>
        <!-- 操作列 -->
        <template slot="operation" slot-scope="{ scope}">
          <div class="flex-align-center">
            <div class="more-handle-btn-box">
              <a-button type="link" @click="edit(scope.record)">
                编辑
              </a-button>
              <a-button type="link" class="delete" @click="deleted(scope.record)">
                删除
              </a-button>
            </div>
            <!-- <a-popover
              placement="bottom"
              trigger="hover"
            >
              <template slot="content">
                <div class="more-handle-btn">
                  <a-button type="link" @click="edit(scope.record)">
                    编辑9
                  </a-button>
                  <a-button type="link" class="delete" @click="deleted(scope.record)">
                    删除0
                  </a-button>
                </div>
              </template>
              <div class="more-handle">
                ···
              </div>
            </a-popover> -->
          </div>
        </template>
      </custom-table>
    </div>
    <Modal
      :closable="true"
      :visible="cardVisible"
      :mask-closable="false"
      :size="'normal'"
      :title="type==='add'?'新增补卡规则':'编辑补卡规则'"
      @cancel="onClose"
      @ok="handleDelShift"
    >
      <div class="card-content">
        <a-form-model
          ref="cardForm"
          :model="cardForm"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="规则名称" prop="ruleName">
            <a-input v-model="cardForm.ruleName" placeholder="比如产品部的补卡规则" style="width:100%" />
          </a-form-model-item>
          <a-form-model-item label="每位员工每个月最多补卡" prop="maxTimes">
            <CbNumber v-model="cardForm.maxTimes" placeholder="0" :max="99" width="100%" :max-length="2" suffix="次" />
          </a-form-model-item>
          <a-form-model-item label="补卡时间限制" prop="appendPunchLimitType">
            <a-select v-model="cardForm.appendPunchLimitType" placeholder="请选择补卡时间限制" style="width:72%;margin-right: 20px">
              <a-select-option v-for="item in appendPunchLimitType" :key="item.codeKey">
                {{ item.content }}
              </a-select-option>
            </a-select>
            <span v-if="cardForm.appendPunchLimitType!=='4'&&cardForm.appendPunchLimitType!=='2'">内可补卡</span>
          </a-form-model-item>
          <a-form-model-item v-if="cardForm.appendPunchLimitType==='2'" label=" " prop="nextMonthMaxDay">
            第
            <a-select v-model="cardForm.nextMonthMaxDay" style="width:80px ;margin:0 10px">
              <a-select-option v-for="index in 28" :key="index" :value="index">{{ index }}天</a-select-option>
            </a-select>
            内可补卡
          </a-form-model-item>
          <a-form-model-item v-if="cardForm.appendPunchLimitType==='4'" label=" " prop="missingDayMaxDay">
            起的
            <a-select v-model="cardForm.missingDayMaxDay" style="width:80px ;margin:0 10px">
              <a-select-option v-for="index in 31" :key="index" :value="index">{{ index }}天</a-select-option>
            </a-select>
            内可补卡
          </a-form-model-item>
        </a-form-model>
      </div>
      <!-- <div v-if="cardVisible=== true" class="screen_button">
        <a-button class="close_button" @click="onClose">取消</a-button>
        <a-button type="primary" class="ok-btn" @click="handleDelShift">确认</a-button>
      </div> -->
    </Modal>
  </div>
</template>
<script>
// const appendPunchLimitType = {
//   '1': '当前考勤周期',
//   '2': '下个考勤周期',
//   '3': '当前考勤周',
//   '4': '缺卡当日及第二日'
// }
import { sepcialCode, intReg } from '@/utils/reg.js'
import mixins from '@/components/CustomTable/index.js'
import { getAppendPunchListApi, addPunchRuleApi, deletePunchRuleApi, updatePunchRuleApi } from '@/services/attendance/rule'
export default {
  name: 'CardRule',
  components: {
    Modal: () => import('@/components/CbModal/index'),
    CbNumber: () => import('@/components/CbNumber'),
    AlertTips: () => import('../../../components/alert.vue')
  },
  filters: {
  },
  mixins: [mixins],
  data() {
    const nextMonthMaxDayValidate = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请选择补卡周期范围'))
      } else {
        callback()
      }
    }
    return {
      appendPunchLimitType: [],
      columns: [
        {
          title: '规则名称',
          dataIndex: 'ruleName',
          key: 'ruleName',
          scopedSlots: { customRender: 'ruleName' },
          customCell: () => {
            return {
              style: {
                'min-width': '100px'
              }
            }
          },
          ellipsis: true
        },
        {
          title: '补卡次数限制',
          dataIndex: 'maxTimes',
          key: 'maxTimes',
          scopedSlots: { customRender: 'maxTimes' },
          customCell: () => {
            return {
              style: {
                'min-width': '120px'
              }
            }
          }
        },
        {
          title: '补卡时间限制',
          dataIndex: 'appendPunchLimitType',
          scopedSlots: { customRender: 'appendPunchLimitType' },
          customCell: () => {
            return {
              style: {
                'min-width': '120px'
              }
            }
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '100px',
          fixed: 'right'
        }
      ],
      dataSource: [], // 数据
      cardVisible: false,
      cardForm: {
        ruleName: '',
        maxTimes: '',
        appendPunchLimitType: undefined,
        nextMonthMaxDay: null,
        missingDayMaxDay: null
      },
      rules: {
        ruleName: [
          { required: true, message: '请输入规则名称', trigger: 'blur' },
          { min: 1, max: 30, message: '用户名称仅支持1~30位的文字', trigger: 'change' },
          { pattern: sepcialCode, message: '规则名称不支持特殊字符', trigger: 'change' }
        ],
        maxTimes: [
          { required: true, message: '请输入补卡次数', trigger: 'blur' },
          { min: 0, max: 2, message: '补卡次数最大为99', trigger: 'change' },
          { pattern: intReg, message: '补卡次数只能是整数', trigger: 'change' }
        ],
        appendPunchLimitType: [
          { required: true, message: '请选择时间限制', trigger: 'blur' }
        ],
        nextMonthMaxDay: [
          { validator: nextMonthMaxDayValidate, trigger: 'change' }
        ],
        missingDayMaxDay: [
          { validator: nextMonthMaxDayValidate, trigger: 'change' }
        ]
      },
      labelCol: { lg: 5, xl: 4, xxl: 3 },
      wrapperCol: { lg: 19, xl: 20, xxl: 21 },
      appendPunchRuleId: '',
      type: 'add',
      tableHeight: null
    }
  },
  async created() {
    this.appendPunchLimitType = await this.getTenantCode('appendpunch_limit_type')
    this.getAppendPunchList()
  },
  methods: {
    // 补卡规则列表
    getAppendPunchList() {
      getAppendPunchListApi().then(res => {
        if (res.code === 100) {
          this.dataSource = res.data
          this.$nextTick(() => {
            const contentH = document.body.scrollHeight - 48
            const tableH = document.querySelector('.card-rule .ant-table-wrapper')
            const offset = document.querySelector('.card-rule .ant-table-wrapper').offsetTop
            const headerH = document.querySelector('.card-rule .ant-table-thead tr').clientHeight
            const tableMaxH = contentH - offset - 20
            tableH.style.maxHeight = `${tableMaxH}px`
            this.tableHeight = tableMaxH - headerH
          })
        }
      })
    },
    lookTips() {
      setTimeout(() => {
        const boxH = document.getElementById('rules').clientHeight
        const offset = document.getElementById('table').offsetTop
        this.tableHeight = boxH - offset - 80
      }, 500)
    },
    // 新增补卡规则
    addCard() {
      this.type = 'add'
      this.cardVisible = true
    },
    edit(data) {
      this.type = 'edit'
      this.appendPunchRuleId = data.appendPunchRuleId
      this.cardForm = { ...data }
      this.cardVisible = true
    },
    deleted(data) {
      this.appendPunchRuleId = data.appendPunchRuleId
      this.$Reconfirm({
        title: '删除补卡规则',
        content: '确定要删除该补卡规则吗？'
      }).then(async() => {
        const res = await deletePunchRuleApi({ id: this.appendPunchRuleId })
        if (res.code === 100) {
          this.$message.success('删除成功')
          this.getAppendPunchList()
        }
      }).catch(() => {
        console.log('no')
      })
    },
    handleDelShift() {
      this.$refs.cardForm.validate(async valid => {
        if (valid) {
          if (this.type === 'add') {
            const res = await addPunchRuleApi(this.cardForm)
            if (res.code !== 100) return
            this.$message.success('新增成功')
          } else {
            const res = await updatePunchRuleApi({ appendPunchRuleId: this.appendPunchRuleId, ...this.cardForm })
            if (res.code !== 100) return
            this.$message.success('编辑成功')
          }
          this.cardVisible = false
          this.cardForm = {}
          this.resetForm()
          this.getAppendPunchList()
        } else {
          return false
        }
      })
    },
    onClose() {
      this.cardVisible = false
      this.resetForm()
      this.cardForm = this.$options.data().cardForm
    },
    resetForm() {
      this.$refs.cardForm.resetFields()
    }
  }
}
</script>
<style lang="less" scoped>
.card-rule{
  width: 100%;
  height: 100%;
  .card-main{
    width: 100%;
    height: auto;
  }
}
.addrulebtn{
  margin: 0 0 16px 0;
}
.more-handle-btn {
  display: flex;
  flex-direction: column;
}
.more-handle{
  font-size: 20px;
  font-weight: 700;
  color: @sc-primary-100;
  cursor: pointer;
  margin-left: 5px;
}
.card-content{
  .ant-row{
    display: flex;
    /deep/ .ant-form-item-label{
      width: 180px;
    }
    .ant-form-item-control .ant-select{
      width: 300px;
    }
  }
}
/deep/.ant-col-xl-20{
  width: 64%;
}
  /deep/.ant-input:not(:last-child){
    padding-right: 45px;
  }
  /deep/.ant-form-item-children{
    color: @sc-grey-80;
  }
/deep/ .ant-input-affix-wrapper .ant-input-suffix {
    color: @sc-grey-100;
  }
.more-handle-btn-box{
  display: flex;
  button{
    padding-left: 0;
    padding-right: 16px;
  }
}
</style>
