<template>
  <div class="DateMatter">
    <!-- 备忘日程 -->
    <MemoMatter :memo-matter-list="memoMatterList" @refresh="$emit('refresh')" @handleEdit="handleEdit" />
    <!-- 系统日程 -->
    <SystemMatter :system-matter-list="systemMatterList" />
  </div>
</template>

<script>
export default {
  name: 'DateMatter',
  components: {
    MemoMatter: () => import('./memoMatter'),
    SystemMatter: () => import('./systemMatter')
  },
  props: {
    memoMatterList: {
      type: Object,
      default: () => {}
    },
    systemMatterList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    }
  },
  methods: {
    handleEdit(data) {
      this.$emit('handleEdit', data)
    }
  }
}
</script>

<style lang="less" scoped>
.DateMatter {
  height: 225px;
  margin-top: 12px;
  overflow: auto;
}
// 滚动条
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: rgba(143, 147, 161, 0.1);
  }
  ::-webkit-scrollbar-track {
    display: none;
  }
</style>
