<template>
  <div class="main-content">
    <a-tabs default-active-key="1" @change="callback">
      <a-tab-pane key="1" tab="试用期">
        <WorkerTabel v-if="keyval === '1'" :keyval="keyval" :is-first="isFirst" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="最近转正">
        <WorkerTabel v-if="keyval === '2'" :keyval="keyval" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import WorkerTabel from './table.vue'
export default {
  name: 'Induction',
  components: {
    WorkerTabel
  },
  data() {
    return {
      // 记录只绑定跳转参数一次
      isFirst: true,
      content: '',
      keyval: '1'
    }
  },
  methods: {
    callback(key) {
      this.keyval = key
      if (this.$route.params.isHome === 'regular') this.isFirst = false
    }
  }
}
</script>

<style lang="less" scoped>
.main-content {
  min-height: 100%;
  background: #fff;
  padding: 20px 20px 0;
  overflow: auto;
}
/deep/ .ant-tabs{
  height: 100%;
  overflow: auto;
}
/deep/ .ant-tabs-nav-scroll {
  margin-top: -4px;
}
/deep/ .ant-tabs-tab {
  color:  @sc-grey-100;
  font-size: 16px;
  padding-top: 0;
}
/deep/ .ant-tabs-tab-active{
  color: @sc-primary-100;
  font-weight: 500;
}
/deep/ .ant-tabs-tab:hover {
  color: @sc-primary-100;
}
</style>
