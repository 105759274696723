<template>
  <div class="NewCategory">
    <Drawer
      :title="`${drawerType?'编辑':'新增'}分类`"
      size="normal"
      :is-mask="false"
      :visible="visible"
      @close="visible = false"
      @onSubmit="onSubmit"
    >
      <a-form-model
        ref="typeForm"
        :rules="rules"
        :model="typeForm"
        :label-col="labelColType"
        :wrapper-col="wrapperColType"
      >
        <a-form-model-item label="分类名称" prop="name">
          <a-input v-model="typeForm.name" placeholder="请输入2-10个字符" />
        </a-form-model-item>
      </a-form-model>
    </Drawer>
  </div>
</template>

<script>
import { addType, updateType } from '@/services/wage/salaryGroup.js'
export default {
  name: 'NewCategory',
  components: {
    Drawer: () => import('@/components/CbDrawer')
  },
  props: {},
  data() {
    return {
      visible: false,
      drawerType: 'edit',
      labelColType: { span: 4 },
      wrapperColType: { span: 18 },
      // 分类
      typeForm: {
        name: '',
        code: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入类型名称', trigger: 'blur' },
          { min: 2, max: 10, message: '限制2-10个文字', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    onSubmit() {
      this.$refs.typeForm.validate(valid => {
        if (valid) {
          this.drawerType === 'edit' ? this.editType() : this.addType()
          return
        }
      })
    },
    // 添加分类
    async addType() {
      const res = await addType(this.typeForm)
      if (res.code === 100) {
        this.visible = false
        this.$message.success('添加成功！')
        this.$emit('refresh')
      }
    },
    // 编辑分类
    async editType() {
      const res = await updateType(this.typeForm)
      if (res.code === 100) {
        this.visible = false
        this.$message.success('编辑成功！')
        this.$emit('refresh')
      }
    },
    // 打开窗口
    open(data) {
      this.visible = true
      this.drawerType = data?.type
      this.typeForm.name = data?.typeName
      this.typeForm.code = data?.typeNo
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-form-item-label > label {
  color: @sc-grey-80;
}
</style>
