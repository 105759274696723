<template>
  <div class="LeaveTimeUnit">
    <a-form-model-item label="请假时间单位" prop="vacationUnit">
      <DictionariesInput v-model="form.vacationUnit" parameter="vacation_unit" placeholder="请选择请假时间单位" @change="unitChange" />
    </a-form-model-item>
    <!-- 时间单位为天或小时 -->
    <div v-show="['D','H'].includes(form.vacationUnit)">
      <a-form-model-item v-show="form.vacationUnit ==='H'" label="请假时长计算" prop="vacationCalcType">
        <DictionariesInput v-model="form.vacationCalcType" parameter="vacation_calc_type" placeholder="请选择请假时长计算方式" />
      </a-form-model-item>
      <a-form-model-item key="time" label="单次请假限制" prop="limitFlag">
        <a-radio-group v-model="form.limitFlag" @change="timesChange">
          <a-radio :style="inlineRadioStyle" :value="false">不限制</a-radio>
          <a-radio :style="inlineRadioStyle" :value="true">限制</a-radio>
          <div v-if="form.limitFlag" class="flex-align-center">
            <CbNumber v-model="form.limitMin" :is-zero="false" class="number-input margin-left-none" :decimal-separator="true" :suffix="form.vacationUnit ==='D'?'天':'小时' " />
            <span v-html="`≤`" /><span class="time-text">单次请假时长</span>≤
            <CbNumber v-model="form.limitMax" :is-zero="false" class="number-input margin-right-none" :decimal-separator="true" :suffix="form.vacationUnit ==='D'?'天':'小时' " />
          </div>
        </a-radio-group>
      </a-form-model-item>
      <!-- 产假 婚假 丧假无单月请假限制 -->
      <a-form-model-item v-if="!['CHJ','HUJ','SAJ'].includes(form.vacationTypeCode)&&form.vacationUnit ==='D'" key="month" label="单月请假限制" prop="monthLimitFlag">
        <a-radio-group v-model="form.monthLimitFlag" @change="monthsChange">
          <a-radio :style="inlineRadioStyle" :value="false">不限制</a-radio>
          <a-radio :style="inlineRadioStyle" :value="true">限制</a-radio>
          <div v-if="form.monthLimitFlag" class="flex-align-center">
            <CbNumber v-model="form.monthLimitMin" class="number-input margin-left-none" disabled :suffix="form.vacationUnit ==='D'?'天':'小时'" :decimal-separator="true" />
            <span v-html="`≤`" /><span class="time-text">单月请假时长</span>≤
            <CbNumber v-model="form.monthLimitMax" :min="form.limitMax" :is-zero="false" class="number-input margin-right-none" :suffix="form.vacationUnit ==='D'?'天':'小时'" :decimal-separator="true" />
          </div>
        </a-radio-group>
      </a-form-model-item>
    </div>
    <!-- 时间单位为半天 -->
    <div v-show="form.vacationUnit ==='B'">
      <a-form-model-item key="B" label="半天折算标准" prop="halfDayCalcType">
        <DictionariesInput v-model="form.halfDayCalcType" parameter="halfday_calc_type" @change="halfDayChange" />
        <!-- 按班次自定义折算 -->
        <div v-show="form.halfDayCalcType ==='1'" class="remind">
          <span>如班次 9:00-12:00，13:30-18:30</span><br>
          <span>上午 = 3小时</span><span class="time-left">下午 = 5小时</span>
        </div>
      </a-form-model-item>
      <!-- 自定义 -->
      <div>
        <a-form-model-item v-if="form.halfDayCalcType ==='2'" label="上午" prop="halfDayHourAm">
          <CbNumber v-model="form.halfDayHourAm" :decimal-separator="true" :precision="2" max="12" suffix="小时" />
        </a-form-model-item>
        <a-form-model-item v-if="form.halfDayCalcType ==='2'" label="下午" prop="halfDayHourPm">
          <CbNumber v-model="form.halfDayHourPm" :decimal-separator="true" :precision="2" max="12" suffix="小时" />
        </a-form-model-item>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeaveTimeUnit',
  components: {
    CbNumber: () => import('@/components/CbNumber/index'),
    DictionariesInput: () => import('@/components/CbDropDownInput/dictionariesInput/index')
  },
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      inlineRadioStyle: {
        display: 'inline-block',
        lineHeight: '35px'
      }
    }
  },
  methods: {
    // 单次限制次数改变
    timesChange({ target: { value }}) {
      if (!value) {
        this.clearData('times')
      }
    },
    // 月限制次数改变
    monthsChange({ target: { value }}) {
      if (!value) {
        this.clearData('months')
      }
    },
    // 时间单位改变
    unitChange(val) {
      this.clearData('all')
    },
    // 半天折算标准改变
    halfDayChange(value) {
      this.clearData('half')
    },
    clearData(clearType) {
      this.$nextTick(() => {
        const form = this.form
        if (clearType === 'times' || clearType === 'all') {
          form.limitMin = form.limitMax = undefined
        }
        if (clearType === 'months' || clearType === 'all') {
          form.monthLimitMin = form.monthLimitMax = undefined
          this.form.monthLimitMin = '0'
        }
        if (clearType === 'half' || clearType === 'all') {
          form.halfDayHourAm = form.halfDayHourPm = undefined
        }
        if (clearType === 'all') {
          form.limitFlag = form.monthLimitFlag = false
          form.halfDayHourAm = form.halfDayHourPm = undefined
          form.halfDayCalcType = form.vacationCalcType = '1'
        }
        this.$forceUpdate()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.LeaveTimeUnit {
  .time-text {
    margin: 0 10px;
  }
  .number-input {
    width: 90px;
    margin: 0 10px;
    display: inline-block;
  }
  .margin-left-none {
    margin-left: 0px;
  }
  .margin-right-none {
    margin-right: 0px;
  }
  .remind{
    background:@layout-body-background;
    color: @sc-grey-40;
    padding: 8px 10px;
    margin-top: 24px;
  }
  .time-left{
    margin-left: 47px;
  }
  /deep/.ant-form-item {
    margin-bottom: 15px;
  }
  /deep/.has-error .ant-form-explain {
    position: absolute;
    bottom: -15px;
  }
}
/deep/.ant-input-affix-wrapper{
  width: 90px;
}
.flex-align-center{
  color: @sc-grey-80;
}
</style>
