<template>
  <div class="roster">
    <template v-if="!componentName">
      <!--  顶部导航  -->
      <div class="tab_box">
        <div class="tab_box_left">
          <div class="completed">
            <span class="completedText onJobNum">{{ tabList.onJobCount }}</span>
            <span>在职</span>
          </div>
        </div>
        <div class="tab_box_center">
          <div class="tab_box_center_count" @click="$router.push({ path: '/staffIndex/induction' })">
            <span class="tab_box_color_num">{{ tabList.awaitEntryCount }}</span>
            <span>待入职</span>
          </div>
          <div class="tab_box_center_count" @click="$router.push({ path: '/staffIndex/quit' })">
            <span class="tab_box_color_num">{{ tabList.awaitLeaveCount }}</span>
            <span>待离职</span>
          </div>
          <div class="tab_box_center_count" @click="$router.push({ path: '/staffIndex/worker' })">
            <span class="tab_box_color_num">{{ tabList.tryOutCount }}</span>
            <span>试用期</span>
          </div>
          <div class="tab_box_center_count" @click="$router.push({ path: '/staffIndex/transfer' })">
            <span class="tab_box_color_num">{{ tabList.transferCount }}</span>
            <span>调动中</span>
          </div>
        </div>
        <div class="tab_box_right">
          <div class="tab_box_right_count" @click="$router.push({ path: '/staffIndex/archive' })">
            <div class="completed">
              <span class="completedText">{{ tabList.awaitPerfectCount }}</span>
              <span>待完善</span>
            </div>
          </div>
        </div>
      </div>
      <!--  表格  -->
      <div class="table">
        <RosterTable ref="RosterTable" :job-type-info="rosterTableInfo" @change="change" />
      </div>
    </template>
    <component :is="componentName" v-else @back="functionType=''" @getStaffStatistics="getStaffStatistics" />
  </div>
</template>

<script>
import RosterTable from './table'
import { getStaffStatistics } from '@/services/user/roster.js'
import common from './common.js'
export default {
  name: '',
  components: {
    RosterTable,
    ...common
  },
  data() {
    return {
      functionType: '',
      rosterTableInfo: {},
      tabList: {}
    }
  },
  computed: {
    componentName() {
      const arr = {
        0: 'AddEmployeeInfo',
        1: 'ExcelImport',
        2: 'ScannInvitation'
      }
      return arr[this.functionType]
    }
  },
  mounted() {
    // 判断跳转参数
    if (this.$route.params.thirdName === 'AddEmployeeInfo') {
      this.functionType = 0
    }
    this.getStaffStatistics()
  },
  methods: {
    // 获取员工信息
    async getStaffStatistics() {
      const res = await getStaffStatistics()
      this.tabList = res.data
    },
    change(column) {
      this.functionType = column.key
    }
  }
}
</script>

<style lang="less" scoped>
  .roster {
    background: #fff;
    min-height: 100%;
    overflow: auto;
  }
  .tab_box {
    height: 88px;
    background: #fff;
    border-radius: 6px;
    border: 1px solid @sc-greyBg-20;
    margin: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .tab_box_left {
      display: flex;
      flex: 1;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      border-right: 1px solid @sc-greyBg-20;
      font-size: 16px;
      text-align: center;
      cursor: pointer;
      color:  @sc-primary-100;
      .onJobNum {
        color: @sc-primary-100;
      }
      &:hover {
         .completedText {
          color: @sc-primary-100;
        }
      }
    }
    .tab_box_center {
      display: flex;
      flex: 4;
      justify-content: space-around;
      align-items: center;
      .tab_box_center_count {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        font-size: 16px;
        color:  @sc-grey-60;
      }
      .tab_box_center_count:hover {
        color: @sc-primary-100;
        .tab_box_color_num {
          color: @sc-primary-100;
        }
      }
    }
    .tab_box_right {
      flex: 1;
      border-left: 1px solid  @sc-greyBg-20;
      .tab_box_right_count {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 16px;
        color:  @sc-grey-60;
      }
      .tab_box_right_count:hover {
        color: @sc-primary-100;
        .completedText {
          color: @sc-primary-100;
        }
      }
    }
    .tab_box_color_num {
      width: 100%;
      text-align: left;
      font-size: 20px;
      font-weight: 700;
      color:  @sc-grey-100;
    }
    .tab_box_left:hover {
      color: @sc-primary-100;
    }
  }
  .table {
    padding: 0 20px;
    box-sizing: border-box;
  }
  /deep/ .ant-tabs-ink-bar {
    height: 0;
  }
  .completed {
    display: flex;
    flex-direction: column;
    align-items: center;
    .completedText {
      width: 100%;
      text-align: left;
      font-size: 20px;
      font-weight: 700;
      color:  @sc-grey-100;
    }
  }
</style>
