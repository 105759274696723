<template>
  <Modal
    :closable="true"
    :title="'编辑职级信息'"
    :size="'normal'"
    :visible="visible"
    :mask-closable="false"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    @afterClose="afterClose"
  >
    <k-form-build
      ref="KFB"
      :value="jsonData"
    />
  </Modal>
</template>

<script>
import { updateRank } from '@/services/organization/rank.js'
export default {
  name: 'Edit',
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      jsonData: {
        'list': [
          {
            'type': 'input',
            'label': '职类',
            'options': {
              'type': 'text',
              'width': '100%',
              'defaultValue': '',
              'placeholder': '请输入',
              'clearable': false,
              'maxLength': null,
              'addonBefore': '',
              'addonAfter': '',
              'hidden': false,
              'disabled': true
            },
            'model': 'jobTypeName',
            'key': 'input_1646106317778',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': '必填项'
              }
            ]
          },
          {
            'type': 'input',
            'label': '级别',
            'options': {
              'type': 'text',
              'width': '100%',
              'defaultValue': '',
              'placeholder': '请输入',
              'clearable': false,
              'maxLength': null,
              'addonBefore': '',
              'addonAfter': '',
              'hidden': false,
              'disabled': true
            },
            'model': 'jobGrade',
            'key': 'input_1646106346940',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': '必填项'
              }
            ]
          },
          {
            'type': 'textarea',
            'label': '通用能力',
            'options': {
              'width': '100%',
              'minRows': 4,
              'maxRows': 6,
              'maxLength': 300,
              'defaultValue': '',
              'clearable': false,
              'hidden': false,
              'disabled': false,
              'placeholder': '如：业务结果及贡献，商业价值的判断能力，学习和创新能力，协调和沟通能力等'
            },
            'model': 'commonSkill',
            'key': 'textarea_1646106363506',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': '必填项'
              }
            ]
          },
          {
            'type': 'textarea',
            'label': '专业能力',
            'options': {
              'width': '100%',
              'minRows': 4,
              'maxRows': 6,
              'maxLength': 300,
              'defaultValue': '',
              'clearable': false,
              'hidden': false,
              'disabled': false,
              'placeholder': '如：专业技术能力，对组织的影响，对客户价值的认知，产品实现的能力'
            },
            'model': 'professinalSkill',
            'key': 'textarea_1646106432039',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': '必填项'
              }
            ]
          },
          {
            'type': 'textarea',
            'label': '核心价值',
            'options': {
              'width': '100%',
              'minRows': 4,
              'maxRows': 6,
              'maxLength': 300,
              'defaultValue': '',
              'clearable': false,
              'hidden': false,
              'disabled': false,
              'placeholder': '如：具有企业特质的要素，发展与使命，内在动机等'
            },
            'model': 'coreValue',
            'key': 'textarea_1646112735253',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': '必填项'
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      jobGradeId: ''
    }
  },
  methods: {
    show(info) {
      this.jobGradeId = info.jobGradeId
      this.visible = true
      this.$nextTick(() => {
        this.$refs.KFB.setData(info)
      })
    },
    async handleOk() {
      const params = await this.$refs.KFB.getData().catch(() => {})
      this.confirmLoading = true
      params['jobGradeId'] = this.jobGradeId
      const res = await updateRank(params)
      this.confirmLoading = false
      if (!res) return false
      this.visible = false
      this.$emit('complate')
    },
    handleCancel() {
      this.visible = false
      this.$refs.KFB.reset()
    },
    afterClose() {
      this.$refs.KFB.reset()
    }
  }
}
</script>

<style lang="less" scoped>
</style>
