<template>
  <Modal
    :z-index="100"
    :closable="true"
    :visible="moveVisble"
    :size="'normal'"
    :mask-closable="false"
    :title="'移动到新分类'"
    :ok-text="'确认'"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
    >
      <a-form-model-item label="将当前分组内的所有表单移动到新分组" prop="targetFlowGroupId">
        <a-select v-model="form.targetFlowGroupId" style="width: 300px" :placeholder="`${CurrentGroup.currentGroupName}(当前分组)`">
          <a-select-option v-for="item in filterGroupList" :key="item.flowGroupId" :value="item.flowGroupId">{{ item.groupName }}</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </Modal>
</template>

<script>
import { transferFlow } from '@/services/logicFlow'
export default {
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  props: {
    groupList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      moveVisble: false,
      CurrentGroup: {
        currentFlowGroupId: '',
        currentGroupName: ''
      },
      text: '11111123213',
      form: {
        targetFlowGroupId: undefined
      },
      rules: {
        targetFlowGroupId: [
          {
            required: true,
            message: '请选择新分组',
            trigger: 'change'
          }
        ]
      }
    }
  },
  computed: {
    filterGroupList() {
      return this.groupList.filter(item => item.flowGroupId !== this.CurrentGroup.currentFlowGroupId)
    }
  },
  mounted() {
  },
  methods: {
    // 确认操作
    handleSubmit() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          const { currentFlowGroupId } = this.CurrentGroup
          const { code } = await transferFlow({ currentFlowGroupId, ...this.form })
          if (code !== 100) return
          this.$message.success('转移成功')
          this.handleCancel()
          this.$emit('submit')
        }
      })
    },
    // 取消事件
    handleCancel() {
      this.moveVisble = false
      this.resetForm()
    },
    // 重置表单内容和验证
    resetForm() {
      this.form = Object.assign(this.form, this.$data.form)
      this.$refs.ruleForm.resetFields()
    },
    // 打开弹窗
    openModal(groupDetail) {
      console.log(groupDetail)
      this.moveVisble = true
      this.CurrentGroup.currentFlowGroupId = groupDetail.flowGroupId
      this.CurrentGroup.currentGroupName = groupDetail.groupName
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-form-item-label {
  margin-top: -10px;
}
</style>
