<template>
  <Modal
    :visible="visible"
    :closable="true"
    :mask-closable="false"
    :size="'normal'"
    :title="(mode == 'add' ? '添加' : '编辑') + '字段'"
    :ok-text="'确认'"
    :z-index="2"
    overflow-auto
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <!-- :disabled="form.createType == 0" -->
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="字段名称" prop="fieldName">
        <a-input
          v-model="form.fieldName"
          :disabled="form.createType == 0"
          placeholder="请输入1-20个字符"
        />
      </a-form-model-item>
      <a-form-model-item label="类型" prop="fieldType">
        <a-select
          v-model="form.fieldType"
          style="width: 100%"
          :options="options"
          placeholder="请选择类型"
          :disabled="form.createType == 0 || mode != 'add'"
          @change="fieldTypeChange"
        />
      </a-form-model-item>
      <a-form-model-item label="表格列宽" prop="width">
        <numberInput
          v-model.trim="form.width"
          :is-zero="false"
          :max="999"
          :disabled="form.createType == 0"
          placeholder="请输入表格的表头列宽(5个汉字约为100宽度)"
        />
      </a-form-model-item>
      <a-form-model-item v-if="form.fieldType == '1'" label="文本长度" prop="fieldLength">
        <numberInput
          v-model.trim="form.fieldLength"
          :is-zero="false"
          :disabled="form.createType == 0"
          placeholder="请输入文本长度"
        />
      </a-form-model-item>
      <div v-if="form.fieldType == '3'">
        <template v-for="(key, index) in form.customOptions">
          <a-form-model-item
            :ref="`select${index}`"
            :key="`select${index}`"
            :label="index === 0 ? '选项' : ''"
            :prop="'customOptions.' + index + '.label'"
            :rules="{
              required: true,
              validator: selectBlur,
              trigger: 'blur',
              index: index
            }"
            class="optionsItem"
          >
            <a-input
              v-model="key.label"
              :max-length="50"
              class="int"
              :disabled="form.createType == 0"
              placeholder="选项名称"
            />
            <svg v-show="form.createType == 1 && form.customOptions.length > 1" class="iconpark-icon delBtn" @click="handleDelete(index)"><use href="#delete-one" /></svg>
          </a-form-model-item>
        </template>
        <div v-show="form.createType == 1 && form.customOptions.length < 100">
          <a @click="handleAdd">添加选项</a>
        </div>
      </div>
      <a-form-model-item :label="`管理系统属性`">
        <div class="switch-panel">
          <a-switch v-model="manageSwitch" size="default" checked-children="可见" un-checked-children="隐藏" :disabled="form.editable == '0'" />
          <a-checkbox-group
            v-model="manageChecks"
            :disabled="form.editable == '0' || !manageSwitch"
            :options="sysOptions"
          />
        </div>
      </a-form-model-item>
      <a-form-model-item :label="`员工端属性`">
        <div class="switch-panel">
          <a-switch v-model="staffSwitch" size="default" checked-children="可见" un-checked-children="隐藏" :disabled="form.editable == '0'" />
          <a-checkbox-group
            v-model="staffChecks"
            :disabled="form.editable == '0' || !staffSwitch"
            :options="staffOptions"
          />
        </div>
      </a-form-model-item>
      <a-form-model-item :label="`入职登记表属性`">
        <div class="switch-panel">
          <a-switch v-model="entryFormSwitch" size="default" checked-children="可见" un-checked-children="隐藏" :disabled="form.editable == '0'" />
          <a-checkbox-group
            v-model="entryFormChecks"
            :disabled="form.editable == '0' || !entryFormSwitch"
            :options="improveOptions"
          />
        </div>
      </a-form-model-item>
    </a-form-model>
  </Modal>
</template>

<script>
import { postCreateFieldConfig, postUpdateFieldConfig } from '@/services/user/fieldGroup.js'
export default {
  components: {
    numberInput: () => import('@/components/CbNumber'),
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      visible: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      options: [
        { value: '1', label: '文本' },
        { value: '2', label: '日期' },
        { value: '3', label: '选项' }
      ],
      form: {
        fieldId: null,
        fieldName: '',
        fieldType: undefined,
        fieldLength: '',
        customCodes: [],
        customOptions: [{ label: '', message: '请输入选项的值' }], // 临时的选项值数组，保存时将值过滤到customCodes字段上
        manageShow: true,
        manageEdit: true,
        manageRequire: false,
        staffShow: true,
        staffEdit: true,
        staffRequire: false,
        entryFormShow: true,
        entryFormEdit: true,
        entryFormRequire: false,
        fieldGroupId: '',
        promptMessage: '',
        editable: '1',
        createType: 1,
        width: ''
      },
      manageSwitch: true,
      manageChecks: [], // 管理端权限
      staffSwitch: true,
      staffChecks: [], // 员工端权限
      entryFormSwitch: true,
      entryFormChecks: [], // 入职登记表权限
      rules: {
        fieldName: [
          {
            required: true,
            message: '请输入字段名称',
            trigger: 'blur'
          },
          { min: 1, max: 20, message: '请输入1-20个字符', trigger: ['change', 'blur'] }
        ],
        fieldType: [
          {
            required: true,
            message: '请选择类型',
            trigger: 'blur'
          }
        ],
        fieldLength: [
          {
            required: true,
            message: '请输入文本长度',
            trigger: 'blur'
          },
          { min: 1, max: 4, message: '请输入1-4位数字', trigger: ['change', 'blur'] }
        ],
        width: [
          {
            required: true,
            message: '请输入表格表头的列宽',
            trigger: 'blur'
          }
        ]
      },
      sysOptions: [
        { label: '可编辑', value: 'manageEdit' },
        { label: '必填', value: 'manageRequire' }
      ],
      staffOptions: [
        { label: '可编辑', value: 'staffEdit' },
        { label: '必填', value: 'staffRequire' }
      ],
      improveOptions: [
        { label: '可编辑', value: 'entryFormEdit' },
        { label: '必填', value: 'entryFormRequire' }
      ]
    }
  },
  computed: {
    mode() {
      // 根据id自动计算新增、编辑
      return this.form.fieldId ? 'update' : 'add'
    }
  },
  watch: {
    entryFormSwitch: {
      handler(val) {
        if (!val) {
          this.entryFormChecks = []
        }
      },
      immediate: true
    },
    staffSwitch: {
      handler(val) {
        if (!val) {
          this.staffChecks = []
        }
      },
      immediate: true
    },
    manageSwitch: {
      handler(val) {
        if (!val) {
          this.manageChecks = []
        }
      },
      immediate: true
    }
  },
  methods: {
    selectBlur(rule, val, callback) {
      if (!val) {
        callback('请输入选项的值')
      }
      for (let i = 0; i < this.form.customOptions.length; i++) {
        if (rule.index === i) {
          continue
        }
        if (this.form.customOptions[i].label === val) {
          callback('选项重复，请确保选项唯一')
          return
        }
      }
      callback()
    },
    // 打开弹窗
    open(data) {
      if (data) {
        this.form = Object.assign(this.form, { ...data })
        this.form.fieldLength = this.form.fieldLength + ''
        this.form.fieldType = this.form.fieldType ? this.form.fieldType + '' : this.form.fieldType
        this.initAllOtherValue()
      }
      this.visible = true
      // resetFields报错：由于复选框的a-form-model-item未绑定prop值，必须所有绑定prop值才可以使用resetFields()
      // this.$refs.ruleForm.resetFields()
    },
    // 保存事件
    handleOk() {
      // eslint-disable-next-line space-before-function-paren
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          const form = { ...this.form }
          form.customCodes = form.customOptions.filter((v) => !!v.label).map((v) => v.label) // 解析选项值
          form.manageShow = this.manageSwitch ? 1 : 0
          form.manageEdit = this.manageChecks.includes('manageEdit') ? 1 : 0
          form.manageRequire = this.manageChecks.includes('manageRequire') ? 1 : 0
          form.staffShow = this.staffSwitch ? 1 : 0
          form.staffEdit = this.staffChecks.includes('staffEdit') ? 1 : 0
          form.staffRequire = this.staffChecks.includes('staffRequire') ? 1 : 0
          form.entryFormShow = this.entryFormSwitch ? 1 : 0
          form.entryFormEdit = this.entryFormChecks.includes('entryFormEdit') ? 1 : 0
          form.entryFormRequire = this.entryFormChecks.includes('entryFormRequire') ? 1 : 0
          form.promptMessage = `${form.fieldName}不能为空`
          form.width = Number(form.width)
          if (this.mode === 'add') {
            const res = await postCreateFieldConfig(form)
            if (res.code === 100) {
              this.$message.success('新增字段成功')
              this.handleCancel()
              this.$emit('reload')
            }
          } else {
            const res = await postUpdateFieldConfig(form)
            if (res.code === 100) {
              this.$message.success('编辑字段成功')
              this.handleCancel()
              this.$emit('reload')
            }
          }
        }
      })
    },
    // 取消事件
    handleCancel() {
      this.resetForm()
      this.visible = false
    },
    // 重置表单内容和验证
    resetForm() {
      this.form = {
        fieldId: null,
        fieldName: '',
        fieldType: undefined,
        fieldLength: '',
        customCodes: [],
        customOptions: [{ label: '', message: '请输入选项的值' }],
        manageShow: true,
        manageEdit: true,
        manageRequire: false,
        staffShow: true,
        staffEdit: true,
        staffRequire: false,
        entryFormShow: true,
        entryFormEdit: true,
        entryFormRequire: false,
        fieldGroupId: '',
        promptMessage: '',
        editable: '1',
        createType: 1
      }
      // this.$refs.ruleForm.resetFields()
    },
    // 增加选项
    handleAdd() {
      // 添加
      this.form.customOptions.push({
        label: '',
        message: '请输入选项的值'
      })
    },
    // 删除选项
    handleDelete(deleteIndex) {
      // 删除
      this.form.customOptions = this.form.customOptions.filter(
        (val, index) => index !== deleteIndex
      )
    },
    // 字段类型变更
    fieldTypeChange(value) {
      if (value === '3') {
        this.form.customOptions = [{ label: '', message: '请输入选项的值' }]
      } else if (value === '1') {
        this.form.fieldLength = ''
      }
    },
    // 解析后台传的内容
    initAllOtherValue() {
      if (this.form.option) {
        this.form.customOptions = [...this.form.option]
      }
      this.manageChecks = []
      this.staffChecks = []
      this.entryFormChecks = []
      this.manageSwitch = false
      this.staffSwitch = false
      this.entryFormSwitch = false
      if (this.form.manageShow) {
        this.manageSwitch = true
      }
      if (this.form.manageEdit) {
        this.manageChecks.push('manageEdit')
      }
      if (this.form.manageRequire) {
        this.manageChecks.push('manageRequire')
      }
      if (this.form.staffShow) {
        this.staffSwitch = true
      }
      if (this.form.staffEdit) {
        this.staffChecks.push('staffEdit')
      }
      if (this.form.staffRequire) {
        this.staffChecks.push('staffRequire')
      }
      if (this.form.entryFormShow) {
        this.entryFormSwitch = true
      }
      if (this.form.entryFormEdit) {
        this.entryFormChecks.push('entryFormEdit')
      }
      if (this.form.entryFormRequire) {
        this.entryFormChecks.push('entryFormRequire')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.optionsItem {
  width: 100%;
  /deep/.ant-form-item-label {
    width: 100%;
  }
  .int {
    width: 85%;
  }
  .delBtn {
    width: 16px;
    height: 16px;
    margin-left: 10px;
    cursor: pointer;
  }
}
.switch-panel {
  .ant-switch {
    margin-right: 155px;
  }
}
.column_width{
/deep/ .anticon {
  margin-left: 10px;
}
}
</style>
