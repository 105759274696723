<template>
  <div class="regional-class-container">
    <div v-for="item in areaLevelList" :key="item.level">
      <div class="level-header flex-align-center">
        <BlockTitle :title="item.level | formatLevel" :is-bold="true">
          <template slot="left">
            <a-button type="primary" @click="addLevelArea(item)">添加城市</a-button>
          </template>
        </BlockTitle>
      </div>
      <div class="level-content">
        <span v-for="area in item.areaList" :key="area.areaCode" class="area">{{ area.areaName }}</span>
      </div>
    </div>
    <EditAreaDrawer ref="editArea" @refresh="getAreaLevelList" />
  </div>
</template>

<script>
import { getAreaLevelList } from '@/services/wage/areaLevel.js'
export default {
  name: 'RegionalClass',
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index'),
    EditAreaDrawer: () => import('./editAreaDrawer/index.vue')
  },
  filters: {
    formatLevel(val) {
      const cnNumList = ['', '一', '二', '三', '四', '五', '六']
      if (val === 0) {
        return '公司所在城市'
      } else if (val > 0) {
        return `${cnNumList[val]}级城市`
      }
    }
  },
  data() {
    return {
      areaLevelList: []
    }
  },
  async created() {
    await this.getAreaLevelList()
  },
  methods: {
    // 获取地区分级
    async getAreaLevelList() {
      const { data } = await getAreaLevelList()
      this.areaLevelList = data
    },
    addLevelArea(area) {
      this.$refs.editArea.open(area)
    }
  }
}
</script>
<style scoped lang="less">
.regional-class-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.level-header{
  margin-bottom: 20px;
/deep/.primary {
  margin-left: 30px;
}
}
.level-content{
  .font-size(14px);
  margin-bottom: 10px;
  .area{
    display: inline-block;
    padding: 5px 15px;
    border: 1px solid @sc-primary-60;
    color: @sc-primary-100;
    border-radius: 5px;
    margin:0 15px 15px 0;
  }

}
</style>
