var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CbNumStatistics',{staticClass:"statistics",attrs:{"statistics-info":_vm.statisticsInfo,"is-tabs":true},on:{"changeTab":_vm.changeTab}}),_c('appForm',{attrs:{"to-list":_vm.toList,"screen-roster-list":_vm.screenRosterList,"width":"400","search-placeholder":"请输入员工姓名/工号"},on:{"getSearchCallback":_vm.getSearchCallback,"screenRosterParams":_vm.screenRosterParams,"exportFileList":_vm.exportFileList}},[_c('template',{slot:"customButton"},[_c('a-space',[(['0', '2'].includes(_vm.selectType))?_c('a-button',{staticClass:"a-button",attrs:{"prefix":"piliangdaoru"},on:{"click":_vm.batchSalaryBtn}},[_vm._v(" 批量定薪 ")]):_vm._e(),(['1', '2'].includes(_vm.selectType))?_c('a-button',{staticClass:"a-button",attrs:{"prefix":"piliangdaoru"},on:{"click":_vm.SalaryadjustmentBtn}},[_vm._v(" 批量调薪 ")]):_vm._e(),(['2'].includes(_vm.selectType))?_c('a-button',{staticClass:"a-button",attrs:{"prefix":"piliangdaoru"},on:{"click":_vm.addFixedItem}},[_vm._v(" 添加薪资固定项 ")]):_vm._e()],1)],1)],2),_c('custom-table',{staticClass:"table-height-box",attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"tabs-height":33,"row-key":'onJobId',"pagination":{
      current: _vm.pager.pageNum,
      pageSize: _vm.pager.pageSize,
      total: _vm.total,
      showTotal: function (total) { return ("共 " + total + " 条数据"); },
      showQuickJumper: true,
      showSizeChanger: true,
    }},on:{"change":_vm.loadDataList},scopedSlots:_vm._u([{key:"staffName",fn:function(ref){
    var scope = ref.scope;
return [_c('CbTableAvater',{attrs:{"on-job-id":scope.record.onJobId,"staff-id":scope.record.staffId,"table-name":scope.record.staffName}})]}},{key:"operation",fn:function(ref){
    var scope = ref.scope;
return [(scope.record.isAdjustment === 1)?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.changeWage(scope.record)}}},[_vm._v("调薪")]):_vm._e(),(scope.record.lastSetSalaryDate)?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.changeWageHistory(scope.record)}}},[_vm._v("调薪历史")]):_vm._e(),(scope.record.isAdjustment === 0)?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.fixedWage(scope.record)}}},[_vm._v("定薪")]):_vm._e()]}}])}),_c('AddWageFIxed',{ref:"AddWageFIxed",on:{"complate":_vm.serchGetData}}),_c('ChangeWage',{ref:"ChangeWage",attrs:{"info":_vm.selectTableItemInfo},on:{"complate":_vm.updateList}}),_c('SalaryHistory',{ref:"SalaryHistory",attrs:{"on-job-id":_vm.selectTableItemInfo.onJobId,"formal-date":_vm.selectTableItemInfo.formalDate}}),_c('FixedWage',{ref:"FixedWage",attrs:{"info":_vm.selectTableItemInfo},on:{"complate":_vm.updateList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }