<template>
  <CbDrawer
    title="添加动态"
    size="normal"
    :visible="visible"
    :closable="false"
    :z-index="10"
    confirm-text="发布"
    @close="onClose"
    @onSubmit="onSubmit"
  >
    <div class="content">
      <a-form-model
        ref="dynamicallyForm"
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        :rules="rules"
      >
        <a-form-model-item label="动态标题" prop="newsTitle">
          <a-input v-model="form.newsTitle" placeholder="请输入2-30个字符" :max-length="30" />
        </a-form-model-item>
        <a-form-model-item label="接收范围" prop="scope">
          <a-select v-model="form.scope" placeholder="请选择接收范围" @change="onChange">
            <a-select-option v-for="item in options" :key="`${item.value}`" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
          <CbSelectPersons
            v-if="form.scope==='choose'"
            :data-echo="perDataList"
            @perModelConfirm="perModelConfirm"
          />
        </a-form-model-item>
        <a-form-model-item label="动态内容" prop="newsText">
          <Tinymce-vue
            ref="tinymceVue"
            :value="form.newsText"
            :setting="setting"
            @setups="setups"
            @input="(res) => (form.newsText = res)"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
    <template slot="cenFooter">
      <a-button @click="onPreview">预览</a-button>
    </template>
    <Preview ref="Preview" mode="add" />
  </CbDrawer>
</template>
<script>
import { upload } from '@/services/upload/index.js'
import { addNewsApi } from '@/services/culture/dynamic.js'
export default {
  name: 'AddCultureDynamic',
  components: {
    TinymceVue: () => import ('@/components/Tinymce'),
    Preview: () => import('./Preview.vue'),
    CbDrawer: () => import('@/components/CbDrawer'),
    CbSelectPersons: () => import('@/components/CbSelectPersons')
  },
  data() {
    const validGroupStaff = (rule, val, callback) => {
      if (this.perDataList.length < 1) {
        callback(new Error('请选择人员'))
      } else {
        callback()
      }
    }
    const content = (rule, val, callback) => {
      if (!this.form.newsText) {
        callback(new Error('请输入动态内容'))
      } else {
        callback()
      }
    }
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      form: {},
      valid: true,
      options: [
        {
          value: 'all',
          label: '全员'
        }, {
          value: 'choose',
          label: '指定成员'
        }
      ],
      visible: false,
      perDataList: [],
      rules: {
        newsTitle: [
          { required: true, message: '请输入动态标题', trigger: ['blur', 'change'] },
          { min: 2, max: 30, message: '请输入2-30个字符', trigger: ['blur', 'change'] }
        ],
        scope: [{ required: true, message: '请选择接收范围', trigger: 'blur' }],
        staffList: [{ required: true, validator: validGroupStaff, trigger: 'change' }],
        newsText: [
          { required: true, validator: content, trigger: 'blur' }
        ]
      },
      setting: {
        selector: 'textarea',
        cleanup: false,
        menubar: false,
        branding: false,
        preview: true,
        elementpath: false,
        resize: false,
        toolbar:
          ' undo redo | bold alignleft aligncenter alignright alignjustify |image link | ',
        toolbar_drawer: 'sliding',
        quickbars_selection_toolbar: 'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
        plugins: ' link preview image media table lists fullscreen paste',
        language_url: '/tinymce/langs/zh_CN.js',
        language: 'zh_CN', // 本地化设置
        height: 500,
        convert_urls: false,
        content_style: 'body, p{color:#202a40;font-size:14px;} img{max-width:355px; display:block;height:auto; }',
        forced_root_block: 'p', // 设置默认标签项
        auto_focus: true, // 自动获取焦点
        // 粘贴图片时上传图片到服务器
        // urlconverter_callback: (url, node, onSave, name) => {
        //   if (node === 'img' && url.startsWith('blob:')) {
        //     // eslint-disable-next-line no-undef
        //     tinymce.activeEditor && tinymce.activeEditor.uploadImages()
        //   }
        //   // 输出服务器图片地址
        //   return url
        // },
        paste_preprocess: this.pastePreprocess,
        images_upload_handler: this.uploadImages // 自定义上传图片
      },
      basePath: process.env.VUE_APP_API_BASE_URL
    }
  },
  methods: {
    pastePreprocess(plugin, args) {
      args.content = args.content.replace(/<img ([\s\S]*?)>/g, '')
    },
    setups(editor) {
      editor.on('ExecCommand', async(e) => {
        if (e.value) {
          // 插入图片时判断尺寸
          if (e.command === 'mceUpdateImage') {
            if (e.value && e.value.width > 355) {
              e.value.height = parseInt(e.value.height / (e.value.width / 355)).toString()
              e.value.width = '355'
              e.ui = true
              // 超出宽度时按比例缩小添加img标签
            }
            editor.execCommand('mceReplaceContent', false, `<img 
              src="${e.value.src}"
              alt="${e.value.alt}"
              height="${e.value.height}"
              width="${e.value.width}"/>`)
          }
          // 插入链接时添加空格
          if (typeof (e.value) === 'string' && e.value.indexOf('<a') !== -1) {
            editor.execCommand('mceReplaceContent', false, '<span> </span>')
          }
        }
      })
    },
    async uploadImages(blobInfo, succFun, failFun) {
      const formData = new FormData()
      formData.append('file', blobInfo.blob())
      formData.append('fileType', 'other')
      formData.append('fileName', blobInfo.blob().name)
      formData.append('sensitiveFile', 0)
      if (blobInfo.blob().size / 1024 / 1024 > 5) {
        this.$message.error('上传图片不可大于5M')
        const dom = document.querySelector('.tox-dialog__busy-spinner')
        if (dom) {
          dom.style.display = 'none'
        }
      } else {
        const res = await upload(formData)
        if (res.code === 100) {
          const url = `${this.basePath}/hrsaas-storage/image/thumbnail/name?id=${res.data.fileId}`
          succFun(url)
        }
      }
    },
    perModelConfirm(data) {
      const arr = []
      this.perDataList = data
      this.$refs.dynamicallyForm.validateField('staffList', (err) => { console.log(err) })
      data.map(item => {
        console.log(item)
        arr.push({ onJobId: item.onJobId, staffName: item.staffName })
      })
      this.form.staffList = arr
    },
    onPreview() {
      console.log(this.form)
      this.$refs.dynamicallyForm.validate((valid) => {
        if (valid && this.valid) {
          // this.$emit('onPreview', this.form)
          this.$refs.Preview.onShow(this.form)
        }
      })
    },
    onChange(value) {
      if (value === 'all') {
        this.perDataList = []
      }
    },
    onShow() {
      this.visible = true
    },
    onClose() {
      this.visible = false
      this.form = {}
      this.perDataList = []
    },
    onSubmit() {
      this.valid = true
      if (this.form.scope === 'choose' && this.perDataList.length <= 0) {
        this.$message.error('请选择指定人员')
        this.valid = false
      }
      this.$refs.dynamicallyForm.validate((valid) => {
        if (valid && this.valid) {
          addNewsApi(this.form).then(res => {
            console.log(res)
            if (res.code === 100) {
              this.$message.success('发布成功')
              this.visible = false
              this.form = {}
              this.perDataList = []
              this.$emit('onSubmit', this.form)
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.tox-toolbar__primary{
  flex-wrap: nowrap !important;
  border-bottom: 1px solid #ccc ;
  // justify-content: space-evenly;
}
/deep/.tox-toolbar__group{
  width: auto !important;
    justify-content: center;
  }
  /deep/.tox-tbtn{
    margin:  0 7px !important;
  }
</style>
