<template>
  <div>
    <Modal v-if="addSendVisible" :size="'normal'" :closable="true" :visible="addSendVisible" :title="'添加抄送人'" :ok-text="'确认'" @ok="handleSubmit" @cancel="handleCancel">
      <div class="operation">
        <p class="operation-title first-title">选择抄送人</p>
        <a-button icon="plus-circle" @click="selectEmployees">
          选择部门和员工
        </a-button>
        <!-- 人员回显 -->
        <UserEcho v-if="form.copyToList.length" class="user-echo" :user-list="form.copyToList" @delUser="delUser" />
      </div>
      <div class="operation">
        <p class="operation-title">抄送原因</p>
        <a-textarea
          v-model="form.reason"
          placeholder="内容描述"
          :auto-size="{ minRows: 5, maxRows: 5 }"
          :max-length="100"
        />
      </div>
    </Modal>
    <PerModelSelect
      v-model="model"
      :data-echo="form.copyToList"
      :placeholder="'请输入部门、人员、工号'"
      @perModelConfirm="perModelConfirm"
    />
  </div>
</template>

<script>
import { addCarbonCopy } from '@/services/home/process.js'
import { debounce } from '@/utils/index.js'
import mixins from '@/components/CbPerDepSelection/index.js'
export default {
  components: {
    UserEcho: () => import('./userEcho.vue'),
    Modal: () => import('@/components/CbModal/index')
  },
  mixins: [mixins],
  data() {
    return {
      model: false,
      addSendVisible: false,
      form: {
        copyToList: []
      }
    }
  },
  methods: {
    // 确认操作
    handleSubmit: debounce(async function() {
      const { code } = await addCarbonCopy({ ...this.form, copyToList: this.form.copyToList.map(v => { return v.onJobId }) })
      if (code !== 100) return
      this.$message.success('添加成功！')
      this.addSendVisible = false
    }, 500),
    resetData() {
      Object.assign(this.$data.form, this.$options.data().form)
    },
    // 取消事件
    handleCancel() {
      this.addSendVisible = false
    },
    // 打开弹窗
    openModal(data) {
      this.resetData()
      this.form.flowRecordParticipantId = data.flowRecordParticipantId
      this.form.flowRecordId = data.flowRecordId
      this.addSendVisible = true
    },
    selectEmployees() {
      this.model = true
    },
    perModelConfirm(data) {
      this.form.copyToList = data.map((v) => {
        return {
          staffName: v.staffName,
          staffId: v.staffId,
          onJobId: v.onJobId
        }
      })
      this.model = false
    },
    // 删除已选中的人员
    delUser(staffId) {
      this.form.copyToList = this.form.copyToList.filter((v) => v.staffId !== staffId)
    }
  }
}
</script>

<style lang="less" scoped>
.operation {
  color: @sc-grey-80;
  .font-size(14px);
  &-title{
    margin: 20px 0 10px 0;
  }
  .first-title {
    margin-top: 0;
  }
  .user-echo{
    margin-top:15px;
  }
}
/deep/ .ant-btn > .anticon {
  color: @sc-primary-100;
}
/deep/ .anticon + span {
  color: @sc-grey-100;
}
/deep/ .ant-btn:hover {
  span {
    color: @sc-primary-100;
  }
}
</style>
