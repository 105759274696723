<!--
 * @Date: 2022-05-23 10:18:05
 * @descriotion:
-->
<template>
  <div>
    <Drawer
      :title="`${type? type === 'edit'?'编辑':'复制':'新增' }排班日历`"
      placement="right"
      size="normal"
      closable
      :z-index="8"
      :visible="visible"
      :is-mask="false"
      @onSubmit="onSave"
      @close="reset"
    >
      <div class="content">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          onkeydown="if(event.keyCode===13){return false}"
        >
          <a-form-model-item ref="shiftSchedulingName" label="排班日历名称" prop="shiftSchedulingName">
            <a-input v-model="form.shiftSchedulingName" placeholder="请输入排班日历名称" />
          </a-form-model-item>
          <a-form-model-item ref="shiftCycleType" label="排班日历类型" prop="shiftCycleType">
            <a-radio-group v-model="form.shiftCycleType" :options="shift_cycle_type" @change="changeShiftCycleType" />
            <Checkcycle v-show="form.shiftCycleType !== 3" :options="options" @checked="getcheckList" />
          </a-form-model-item>

          <a-form-model-item v-show="form.shiftCycleType === 3" ref="shiftSchedulingType" label="排班方式" prop="shiftSchedulingType">
            <a-radio-group v-model="form.shiftSchedulingType" :options="shift_scheduling_type" />
          </a-form-model-item>

          <a-form-model-item v-if="form.shiftCycleType === 3 && form.shiftSchedulingType !==2" ref="cycleDays" label="排班循环周期" prop="cycleDays">
            <a-select v-model="form.cycleDays" style="width: 120px" @change="init">
              <a-select-option v-for="item in 30" :key="item+1" :value="item+1">
                {{ item+1 }}
              </a-select-option>
            </a-select>
            <Checkcycle :options="options" @checked="getcheckList" />
          </a-form-model-item>

          <a-form-model-item v-show="form.shiftSchedulingType !==2" label="班次选择">
            <SelectOrder :check-list="options" :work-list="workList" :nowork-list="noworkList" @change="getShift" />
          </a-form-model-item>
          <a-form-model-item label="同步法定节假安排" prop="syncHolidayFlag">
            <a-switch v-model="form.syncHolidayFlag" size="small" />
          </a-form-model-item>
          <a-form-model-item label="特殊日期设置" prop="specialDays">
            <a-button class="addBtn" type="primary" prefix="add-one" @click="addDate">
              添加日期
            </a-button>
            <div v-for="(item,i) in form.specialDays" :key="i" class="addDate">
              <a-date-picker v-model="item.specialDate" value-format="YYYYMMDD" />
              <a-select v-model="item.shiftId" placeholder="请选择班次" class="select-box">
                <a-select-option v-for="shift in shiftList" :key="shift.shiftId" :value="shift.shiftId">
                  {{ shift.shiftName }}
                </a-select-option>
              </a-select>
              <svg class="iconpark-icon delete" @click="del(i)"><use href="#delete-5no76872" /></svg>
            </div>
          </a-form-model-item>
        </a-form-model>
      </div>

      <template slot="sufFooter">
        <a-tooltip v-show="type === 'edit'" placement="topRight" trigger="hover">
          <template slot="title">
            <div>修改点将于明日生效</div>
          </template>
          <a-icon type="exclamation-circle" class="exclamation-circle" theme="filled" />
        </a-tooltip>
      </template>
    </Drawer>
  </div>
</template>
<script>
import { shift_cycle_type, shift_scheduling_type } from '@/pages/attendance/dict.js'
import { shiftSchedulingAddApi, shiftSchedulingDeatailApi, shiftSchedulingUpdateApi } from '@/services/attendance/settingset.js'
import { getShiftListPage, getWorkShiftListApi } from '@/services/attendance/shift.js'
import { textReg } from '@/utils/reg.js'
export default {
  name: 'AddScheduling',
  components: {
    Drawer: () => import('@/components/CbDrawer'),
    Checkcycle: () => import('@/pages/attendance/components/checkcycle.vue'),
    SelectOrder: () => import('@/pages/attendance/components/selectOrder.vue')
  },
  data() {
    const validateText = (rule, val, callback) => {
      if (textReg.test(val)) {
        callback(new Error('不允许输入特殊字符'))
      } else {
        callback()
      }
    }
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      shift_cycle_type: shift_cycle_type,
      shift_scheduling_type: shift_scheduling_type,
      visible: false,
      form: {
        shiftSchedulingName: '',
        shiftCycleType: 1,
        shiftSchedulingType: 1,
        cycleDays: 2,
        cycles: [],
        workCycles: [],
        syncHolidayFlag: true,
        specialDays: [],
        remark: ''
      },
      rules: {
        shiftSchedulingName: [{ required: true, message: '排班日历名称不能为空', trigger: 'change' },
          { min: 1, max: 30, message: '排班日历名称支持1~30个字符', trigger: 'change' },
          { validator: validateText, trigger: 'change' }]
      },
      checkList: [],
      shiftList: [],
      options: [],
      workList: [],
      noworkList: [],
      type: '',
      shiftSchedulingId: ''
    }
  },
  mounted() {
    switch (this.form.shiftCycleType) {
      case 1:
        this.week()
        break
      case 2:
        this.month()
        break
      default:
        this.init()
    }
  },
  methods: {
    // 获取班次 工作日 班次
    async getworkPage() {
      const res = await getWorkShiftListApi()
      this.noworkList = res.data.filter(item => item.shiftType === 'X')
      this.workList = res.data.filter(item => item.shiftType !== 'X')
    },
    async getShiftListPage() {
      const res = await getShiftListPage({ shiftEnable: 0 })
      this.shiftList = res.data
    },
    async open(data, type) {
      this.getShiftListPage()
      this.getworkPage()
      this.type = type || ''
      if (type) {
        this.shiftSchedulingId = data.shiftSchedulingId || ''
        const res = await shiftSchedulingDeatailApi({ shiftSchedulingId: data.shiftSchedulingId })
        const arr = []
        res.data.workCycles.map((item, index) => {
          if (item) {
            arr.push({ checked: item, value: undefined, id: res.data.cycles[index] })
          } else {
            arr.push({ checked: item, value: res.data.cycles[index] })
          }
        })
        this.options = arr
        this.form.cycles = res.data.cycles
        this.form.workCycles = res.data.workCycles
        this.form.shiftSchedulingName = res.data.shiftSchedulingName
        this.form.shiftCycleType = Number(res.data.shiftCycleType)
        this.form.shiftSchedulingType = Number(res.data.shiftSchedulingType)
        this.form.cycleDays = res.data.cycleDays
        this.form.specialDays = res.data.specialDays
        this.form.syncHolidayFlag = res.data.syncHolidayFlag
        if (type === 'copy') {
          this.form.shiftSchedulingName = ''
        }
      }
      this.visible = true
    },
    pressEnter(e) {
      console.log(e)
    },
    onSave() {
      const arr = this.form.cycles.filter(item => !item)
      if (arr.length > 0 && this.form.shiftSchedulingType === 1) {
        this.$message.info('请选择班次')
        return
      }
      const days = this.form.specialDays
      for (let i = 0; i < days.length; i++) {
        if (!days[i].specialDate || !days[i].shiftId || JSON.stringify(days[i]) === '{}') {
          days.splice(i, 1)
          i = i - 1
        }
      }
      this.form.specialDays = days
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          if (this.type === 'edit') {
            const res = await shiftSchedulingUpdateApi({ shiftSchedulingId: this.shiftSchedulingId, ...this.form })
            if (res.code !== 100) return
          } else {
            const res = await shiftSchedulingAddApi(this.form)
            if (res.code !== 100) return
          }

          this.$message.success('操作成功')
          this.reset()
          this.$emit('ok')
        } else {
          return false
        }
      })
    },
    // 班-休
    getcheckList(val) {
      this.checkList = val
      const arr = []
      val.map(item => {
        arr.push(item.checked)
      })
      this.form.workCycles = arr
    },
    // 班次选择
    getShift(val) {
      const arr = []
      val.map((item) => {
        if (item.value) {
          arr.push(item.value)
        } else {
          arr.push(item.id)
        }
      })
      this.form.cycles = arr
    },
    addDate(e) {
      console.log(e)
      this.form.specialDays.push({ specialDate: undefined, shiftId: undefined })
    },
    del(i) {
      this.form.specialDays.splice(i, 1)
    },
    reset() {
      this.$refs.ruleForm.resetFields()
      this.form = this.$options.data().form
      this.week()
      this.visible = false
    },

    changeShiftCycleType(e) {
      this.form.shiftSchedulingType = 1
      switch (e.target.value) {
        case 1:
          this.week()
          break
        case 2:
          this.month()
          break
        default:
          this.init()
      }
    },
    init() {
      const arr = []
      for (let i = 0; i < this.form.cycleDays; i++) {
        arr.push({ checked: false, value: undefined })
      }
      this.options = arr
      this.getcheckList(arr)
    },
    week() {
      const arr = []
      for (let i = 0; i < 7; i++) {
        if (i === 5 || i === 6) {
          arr.push({ checked: true, value: undefined })
        } else {
          arr.push({ checked: false, value: undefined })
        }
      }
      this.options = arr
      this.getcheckList(arr)
    },
    month() {
      const arr = []
      for (let i = 0; i < 31; i++) {
        if (i === 6 || i === 13 || i === 20 || i === 27) {
          arr.push({ checked: true, value: undefined })
        } else {
          arr.push({ checked: false, value: undefined })
        }
      }
      this.getcheckList(arr)
      this.options = arr
    }
  }
}
</script>
<style lang="less" scoped>
.exclamation-circle{
  margin-left: 12px;
  color: @sc-primary-100;
}
.content{
    width:100%;
    padding-bottom:100px;
}
.btn{
  position: absolute;
  z-index: 99;
  bottom: 0;
  width: 100%;
  border-top: 1px solid @sc-greyBg-20;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
  .close-btn,
  .ok-btn {
    width: 74px;
  }
}
.addDate{
  display: flex;
  align-items: center;
  margin-bottom:10px;
  .select-box{
    width:200px;
    margin:0 10px;
  }
  .iconpark-icon.delete{
    width: 16px;
    height: 16px;
    color: @sc-error-100;
  }
}
/deep/.addBtn{
  margin: 0 0 10px;
}
</style>
