<template>
  <div class="info-table">
    <custom-table :pagination="false" :columns="columns" :data-source="dataInfo" :row-key="'id'">
      <!-- 状态开关 -->
      <template slot="messageStatus" slot-scope="{ scope }">
        <a-switch :checked="scope.record.messageStatus == 0 ? false : true" size="small" @change="onChangeVal(scope.record)" />
      </template>
      <template slot="remindTimeStrList" slot-scope="{ scope }">
        <div
          v-for="(item, index) in scope.record.remindTimeStrList"
          :key="scope.record.messageGroupId + index"
          :class="scope.record.remindTimeStrList.length != index + 1 ? 'v-time' : ''"
        >
          {{ item }}
        </div>
      </template>
      <template slot="action" slot-scope="{ scope }">
        <a-button type="link" class="link" @click="handleEdit(scope.record.messageConfigId)"> 编辑 </a-button>
        <a-button type="link" @click="deleteItemConfirm(scope)"> 删除 </a-button>
      </template>
    </custom-table>
  </div>
</template>

<script>
import { deleteMessageConfig, changeMessageStatus } from '@/services/user/setting.js'
import mixins from '@/components/CustomTable/index.js'

const data = [
  {
    total: 100,
    columns: [],
    key: '1',
    dataSource: [], // 数据
    name: '入职欢迎',
    time: ['提前1天', '自然日', '08:00提醒'],
    people: '员工本人'
  }
]
export default {
  mixins: [mixins],
  props: {
    dataInfo: {
      type: [Array, Object],
      default: () => {}
    }
  },
  data() {
    return {
      data,
      columns: [
        {
          title: '消息名称',
          dataIndex: 'messageName',
          key: 'messageName',
          width: 300
        },
        {
          title: '发送时间',
          dataIndex: 'remindTimeStrList',
          scopedSlots: { customRender: 'remindTimeStrList' },
          width: 300
        },
        {
          title: '通知人',
          dataIndex: 'notifierStr',
          scopedSlots: { customRender: 'notifierStr' },
          width: 300
        },
        {
          title: '状态',
          dataIndex: 'messageStatus',
          scopedSlots: { customRender: 'messageStatus' }
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: 200
        }
      ]
    }
  },
  methods: {
    // 删除确认
    async deleteItemConfirm(val) {
      this.messageSetConfigId = val.record.messageConfigId
      this.$Reconfirm({
        title: '删除',
        content: '是否确认删除？'
      }).then(async() => {
        const res = await deleteMessageConfig({
          messageConfigId: this.messageSetConfigId
        })
        if (res.code === 100) {
          this.$message.success('删除成功')
          this.serchGetData()
          this.$emit('getFetchDataApiFn')
        }
      }).catch(() => {
        console.log('no')
      })
    },
    handleEdit(val) {
      this.$emit('handleEdit', val)
    },
    // 状态改变
    async onChangeVal(val) {
      const per = {
        messageConfigId: val.messageConfigId,
        messageStatus: val.messageStatus === 0 ? 1 : 0
      }
      const res = await changeMessageStatus(per)
      if (res.code === 100) {
        this.$emit('getFetchDataApiFn')
      }
    }
  }
}
</script>

<style lang="less">
.info-table {
  .danger-btn {
    color: @error-color;
  }
}
.addButtom {
  float: right;
  margin: 0px 10px 10px 10px;
}
.link {
  padding-left: 0;
}
/*.v-time {*/
/*  border-bottom: 1px solid #eee;*/
/*}*/
</style>
