import personnelReport from './personnelReport'
import subscribe from './subscribe'
import taxPayment from './taxPayment'

export default {
  personnelReport,
  subscribe,
  taxPayment

}
