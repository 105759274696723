<template>
  <div class="BaseMessage">
    <a-form-model-item label="假期类型" prop="vacationTypeId">
      <a-select v-model="form.vacationTypeId" placeholder="请选择假期类型" @change="vacationChange">
        <a-select-option v-for="(type) in vacationTypeList" :key="type.vacationTypeId" :value="type.vacationTypeId" @click="form.vacationTypeCode = type.vacationTypeCode">{{ type.vacationTypeName }}</a-select-option>
      </a-select>
    </a-form-model-item>
    <BlockTitle title="基本信息" :is-bold="true" class="msg-title" />
    <a-form-model-item label="假期方案名称" prop="ruleName">
      <a-input v-model="form.ruleName" placeholder="请输入假期方案名称" />
    </a-form-model-item>
    <a-form-model-item label="适用范围" prop="scopes">
      <!-- <a-button icon="plus-circle" @click="selectEmployees">
        选择部门和员工
      </a-button> -->
      <!-- 人员回显 -->
      <!-- <UserEcho v-if="form.scopes.length" :user-list="form.scopes" @delUser="delUser" /> -->
      <CbSelectPersons :data-echo="form.scopes" @perModelConfirm="perModelConfirm" />
    </a-form-model-item>
    <LeaveTimeUnit :form="form" />
    <!-- 年假 调休假 短期病假 事假 路途假 探亲假 不进行请假时长统计 -->
    <a-form-model-item v-if="!['NXJ','TXJ','DBJ','SHJ','LTJ','TQJ'].includes(form.vacationTypeCode)" label="请假时长统计">
      <a-radio-group v-model="form.vacationDelayType">
        <a-radio v-for="leave in vacationDelayList" :key="leave.codeKey" :style="inlineRadioStyle" :value="leave.codeKey">
          {{ leave.content }}
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <!-- <PerModelSelect
      v-model="model"
      :data-echo="form.scopes"
      :placeholder="'请输入部门、人员、工号'"
      @perModelConfirm="perModelConfirm"
    /> -->
  </div>
</template>

<script>
import { getVacationType } from '@/services/attendance/holidays.js'
import { getTenantCode } from '@/services/dropDownInput/index.js'
import mixins from '@/components/CbPerDepSelection/index.js'
export default {
  name: 'BaseMessage',
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index'),
    LeaveTimeUnit: () => import('./components/leaveTimeUnit.vue'),
    // UserEcho: () => import('./components/userEcho.vue'),
    CbSelectPersons: () => import('@/components/CbSelectPersons/index')
  },
  mixins: [mixins],
  props: {
    formType: {
      type: String,
      default: undefined
    },
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      model: false,
      vacationTypeList: [],
      vacationDelayList: [],
      radioStyle: {
        display: 'block',
        lineHeight: '35px'
      },
      inlineRadioStyle: {
        display: 'inline-block',
        lineHeight: '35px'
      },
      valid: []
    }
  },
  watch: {
    valid() {
      this.$parent.$parent.$parent.$parent.$parent.valid()
    }
  },
  created() {
    this.getTypeList()
    this.getVacationDelay()
  },
  methods: {
    // 获取假期类型
    async getTypeList() {
      const type = await getVacationType()
      this.vacationTypeList = type.data
    },
    // 获取请假休息日顺延方式
    async getVacationDelay() {
      const delay = await getTenantCode({ codeName: 'vacation_delay_type' })
      this.vacationDelayList = delay.data
      console.log(this.vacationDelayList)
    },
    perModelConfirm(data) {
      this.form.scopes = data.map((v) => {
        return {
          staffName: v.staffName,
          staffId: v.staffId,
          onJobId: v.onJobId
        }
      })
      this.$emit('valid', data)
    },
    selectEmployees() {
      this.model = true
    },
    vacationChange(val) {
      const typeObj = this.vacationTypeList.find((v) => { return v.vacationTypeId === val })
      this.$emit('vacationChange', { ...typeObj })
    },
    // 删除已选中的人员
    delUser(staffId) {
      this.form.scopes = this.form.scopes.filter((v) => v.staffId !== staffId)
    }
  }
}
</script>

<style lang="less" scoped>
.BaseMessage {
  .msg-title {
    margin-bottom: 14px;
    margin-top: 2px;
  }
  .number-input {
    width: 91px;
    margin: 0 5px;
    display: inline-block;
  }
  /deep/.ant-form-item {
    margin-bottom: 15px;
  }
  /deep/.has-error .ant-form-explain {
    position: absolute;
    bottom: -15px;
  }
}
/deep/.ant-btn{
  color: @sc-grey-60;
  &:hover{
    color: @sc-primary-100;
    border-color: @sc-primary-100;
  }
  &:focus{
    color: @sc-primary-100;
    border-color: @sc-primary-100;
  }
}
/deep/.ant-radio-wrapper span{
  color: @sc-grey-80;
}

</style>
