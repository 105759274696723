<template>
  <div class="rank">
    <layout
      ref="layoutMenu"
      :menu-data-list="menuDataList"
      :selected-key="selectedKeys"
      :del-msg-info="{
        title: '删除职级分类',
        msg: '职级分类及内容?'
      }"
      @menu-select-change="menuSelectChange"
      @menu-action-edit="menuActionEdit"
      @menu-action-del="menuActionDel"
    >
      <template #leftTop>
        <div class="left-top-p" @click="handleAdd">
          <svg class="iconpark-icon post-add-icon"><use href="#add-one" /></svg>
          新建职级分类
        </div>
      </template>
      <template #default>
        <div class="content">
          <div class="top-button">
            <div>
              <a-button class="addBtn" prefix="add-one" @click="createRank">
                添加职级信息
              </a-button>
              <a-button prefix="reduce-one" :disabled="!selectedRowKeys.length" @click="handleDel">
                <span>批量删除职级</span>
              </a-button>
            </div>
            <a-tooltip placement="top">
              <template slot="title">
                <span>导出</span>
              </template>
              <a-button type="iconBtn" icon="daochu" @click="exportExcel" />
            </a-tooltip>
          </div>
          <div class="top">
            <!-- <div class="on-the-job">
              在职人数：{{ baseInfo.incumbencyCount || 0 }}
            </div> -->
            <div class="on-the-job">
              <!-- 已定级：{{ baseInfo.gradedCount || 0 }}
              ({{ baseInfo.gradedProportion || 0 }}) -->
              已定级：{{ baseInfo.gradedProportion }}%
            </div>
          </div>
          <div class="table">
            <RankTable ref="RankTable" :job-type-info="rankTypeInfo" @getSelectArr="getSelectArr" />
          </div>
        </div>
      </template>
    </layout>
    <CreateRankType ref="CreateRankType" @complate="menuInit" />
    <UpdateRankType ref="UpdateRankType" @complate="menuInit" />
    <CreateRank ref="CreateRank" @complate="search" />
  </div>
</template>

<script>
import layout from '@/components/CbLayoutMenu/index'
import { getRankTypeList, getRankStatistics, deleteRankType, rankExport, batchDeleteRank } from '@/services/organization/rank.js'
import RankTable from './table'
import CreateRankType from './operation/create-rank-type'
import UpdateRankType from './operation/update-rank-type'
import CreateRank from './operation/create-rank'

export default {
  name: 'Rank',
  components: {
    layout,
    RankTable,
    CreateRank,
    CreateRankType,
    UpdateRankType
  },
  data() {
    return {
      menuDataList: [],
      selectedKeys: '',
      baseInfo: {},
      rankTypeInfo: {},
      selectedRowKeys: []
    }
  },
  async mounted() {
    await this.menuInit()
    this.$refs.layoutMenu.showInit()
  },
  methods: {
    search() {
      this.$refs.RankTable.serchGetData()
    },
    async menuInit() {
      const res = await getRankTypeList()
      this.menuDataList = res.data.map(item => {
        return {
          ...item,
          id: item.jobTypeId + '',
          name: item.jobTypeName + item.jobTypeCode,
          actions: ['edit', 'del']
        }
      })
      // this.selectedKeys = this.menuDataList[0].id + ''
    },
    async getBaseInfo(item) {
      if (!item.jobTypeId) return
      const data = await getRankStatistics({ jobTypeId: item.jobTypeId })
      this.baseInfo = data.data
    },
    handleAdd() {
      this.$refs.CreateRankType.show()
    },
    handleOk() {

    },
    handleCancel() {

    },
    menuSelectChange(item) {
      this.$set(this, 'rankTypeInfo', item)
      this.selectedRowKeys = []
      this.$refs.RankTable.clearArr()
      this.$nextTick(() => {
        this.search()
        this.getBaseInfo(item)
      })
    },
    async menuActionEdit(item) {
      this.$refs.UpdateRankType.show(item)
    },
    // menuActionDel(item, path) {
    //   deleteRankType({ jobTypeId: item.jobTypeId }).then((res) => {
    //   })
    //   console.log(res, ';;;')
    //   this.$refs.layoutMenu.closeDelModal()
    //   this.menuInit()
    // },
    menuActionDel(item, path) {
      deleteRankType({
        jobTypeId: item.jobTypeId
      }).then((res) => {
        if (res.code === 100) {
          this.$message.success('删除职级分类成功')
          this.menuInit()
        }
      })
      this.$refs.layoutMenu.closeDelModal()
    },
    async createRank() {
      this.$refs.CreateRank.show(this.rankTypeInfo)
    },
    async exportExcel() {
      await rankExport({ jobTypeId: this.rankTypeInfo.jobTypeId })
    },
    // 获取已选择数据列表
    getSelectArr(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    // 删除
    async handleDel() {
      this.$Reconfirm({
        title: '删除',
        content: `确定要删除选中的职级吗?`
      }).then(async() => {
        const res = await batchDeleteRank({ jobGradeIdList: this.selectedRowKeys })
        if (res.code === 100) {
          this.$refs.RankTable.serchGetData()
          this.$message.success('删除成功')
        }
      }).catch(() => {
        console.log('取消')
      })
    }
  }
}
</script>

<style lang="less" scoped>
@fontSize: 14px;

.rank{
  height: 100%;
}
.left-top-p{
  padding: 20px 0 30px 16px;
  text-align: left;
  .font-size(14px);
  color: @sc-grey-60;
  &:hover{
    color: @sc-primary-100;
    .post-add-icon{
      color: @sc-primary-100;
    }
  }

}
.content{
  padding: 20px 20px 0;
  .top-button{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .addBtn{
    margin-right: 12px;
  }
  .top{
    display: flex;
    color: @sc-grey-100;
    margin-bottom: 16px;
    .on-the-job{
      margin-right: 60px;
      font-size: 14px;
    }
  }
}
.post-add-icon{
  width: 16px;
  height: 16px;
  color: @sc-greyBg-100;
  vertical-align: top;
  margin-right: 4px;
  margin-top: 3px;
}
/deep/ .ant-table-thead > tr > th {
  color: @sc-grey-100;
  font-size: 14px;
}
/deep/ .ant-table-tbody > tr > td {
  color:  @sc-grey-80;
  font-size: 14px;
}
</style>
