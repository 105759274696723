<!--
 * @Date: 2022-07-15 17:47:52
 * @descriotion:
-->
<template>
  <Modal
    :z-index="8"
    :closable="true"
    :visible="addRecord"
    :title="type==='add'?'添加补卡': flag ? '编辑补卡' : '补卡'"
    :size="'normal'"
    @cancel="onClose"
    @ok="onSubmin"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="员工姓名" prop="onJobId">
        <PerTreeSelect v-model="form.onJobId" placeholder="请选择员工" :disabled="type==='edit'" @getUserInfo="getUserInfo" />
      </a-form-model-item>
      <a-form-model-item label="补卡日期" prop="punchDate">
        <a-date-picker v-model="form.punchDate" :disabled="flag" value-format="YYYY-MM-DD" :disabled-date="disabledDate" format="YYYY-MM-DD" @change="form.punchTime='',getPunchList()" />
      </a-form-model-item>
      <a-form-model-item label="补卡时间" prop="punchTime">
        <a-time-picker v-model="form.punchTime" :disabled-hours="disabledHours" :disabled-minutes="disabledMinutes" :default-open-value="moment('00:00', 'HH:mm')" value-format="HH:mm" format="HH:mm" @openChange="getTime" />
      </a-form-model-item>
      <a-form-model-item v-show="punchList.length>1" label="归属时段">  <!--v-show="punchList.length>1" -->
        <a-radio-group v-model="form.staffShiftPunchId" @change="planchange(val)">
          <a-radio v-for="(item,index) in punchList" :key="index" :value="item.dayType === 1?item.staffShiftPunchId:''">
            {{ item.dayType === 1?`${item.planInTime}-${item.planOutTime}` : item.dayType === 2?'休息日':'节假日' }}
          </a-radio>
        </a-radio-group>

      </a-form-model-item>
    </a-form-model>
  </Modal>
</template>
<script>
import moment from 'moment'
import { getAppendPunchInfoApi, createV2Api, editStaffApplyAppendPunch } from '@/services/attendance/attendance.js'
export default {
  name: 'AddPunchTime',
  components: {
    Modal: () => import('@/components/CbModal/index'),
    PerTreeSelect: () => import('@/components/CbPerDepSelection/perTreeSelect/index')
  },
  data() {
    return {
      addRecord: false,
      form: {
        staffShiftPunchId: undefined,
        staffId: '',
        onJobId: undefined,
        punchDate: undefined,
        punchTime: undefined,

        appendPunchType: undefined,
        dayType: undefined,
        shiftId: undefined,
        staffShiftId: undefined,
        shiftPunchRuleId: undefined
      },
      rules: {
        onJobId: [{ required: true, message: '请选择补卡人员', trigger: 'change' }],
        punchDate: [{ required: true, message: '请选择补卡日期', trigger: 'change' }],
        punchTime: [{ required: true, message: '请选择补卡时间', trigger: 'change' }]
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      punchList: [], // 归属时段
      type: 'add',
      flag: null,
      appendPunchApplyId: null,
      hours: [],
      minutes: []
    }
  },
  methods: {
    moment,
    // 新增编辑获取员工信息
    async getUserInfo(userInfo) {
      this.form.onJobId = userInfo[0].value
      this.form.staffId = userInfo[0].staffId
      this.getPunchList()
    },
    disabledDate(current) { // 不可选择未来日期
      return current > moment().endOf('day')
    },
    disabledHours() {
      return this.hours
    },
    disabledMinutes(hours) {
      if (hours === new Date().getHours()) {
        return this.minutes
      } else {
        return []
      }
    },
    // 月度关注相当于新增补卡  编辑falg true
    show(data, flag = false) {
      if (flag) { this.flag = true; this.appendPunchApplyId = data.appendPunchApplyId }
      if (data) {
        this.type = 'edit'
        const { staffId, onJobId, punchDate, punchDateTime } = data
        this.form.staffId = staffId
        this.form.onJobId = onJobId
        this.form.punchDate = punchDate || undefined
        this.form.punchTime = punchDateTime ? punchDateTime.slice(-5) : undefined
        this.getPunchList()
      } else {
        this.type = 'add'
      }
      this.addRecord = true
    },
    onClose() {
      this.addRecord = false
      this.$refs.ruleForm.resetFields()
      this.form = this.$options.data().form
      this.flag = null
    },
    onSubmin() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.flag) {
            editStaffApplyAppendPunch({ appendPunchApplyId: this.appendPunchApplyId, punchTime: this.form.punchTime }).then(res => {
              if (res.code === 100) {
                this.$message.success('补卡成功')
                this.$emit('updata')
                this.onClose()
              }
            })
          } else {
            createV2Api(this.form).then(res => {
              if (res.code === 100) {
                this.$message.success('补卡成功')
                this.$emit('updata')
                this.onClose()
              }
            })
          }
        }
      })
    },
    getTime(open) {
      if (!open) {
        this.getPunchList()
      }
    },
    planchange(val) {
      const obj = this.punchList.find(item => item.staffShiftPunchId === val)
      this.form.staffShiftId = obj.staffShiftId || ''
      this.form.shiftId = obj.shiftId || ''
      this.form.appendPunchType = obj.appendPunchType || ''
      this.form.shiftPunchRuleId = obj.shiftPunchRuleId || ''
      this.form.dayType = obj.dayType || ''
    },
    range(start, end) {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    },
    async getPunchList(val) {
      const { punchTime, punchDate, onJobId } = this.form
      if (val === moment(new Date()).format('YYYY-MM-DD')) {
        this.hours = this.range(new Date().getHours() + 1, 24)
        this.minutes = this.range(new Date().getMinutes() + 1, 60)
      } else {
        this.hours = []
      }
      if (!punchTime || !punchDate || !onJobId) return
      const res = await getAppendPunchInfoApi({ punchTime, punchDate, onJobId })
      if (res.code === 100) {
        this.punchList = res.data
        if (res.data.length > 0) {
          this.form.staffShiftPunchId = res.data[0].staffShiftPunchId || ''
          this.form.staffShiftId = res.data[0].staffShiftId || ''
          this.form.shiftId = res.data[0].shiftId || ''
          this.form.appendPunchType = res.data[0].appendPunchType || ''
          this.form.shiftPunchRuleId = res.data[0].shiftPunchRuleId || ''
          this.form.dayType = res.data[0].dayType || ''
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.ant-time-picker,.ant-calendar-picker{
  width:100%;
}
</style>
