<template>
  <Modal
    :visible="visible"
    :closable="true"
    :mask-closable="false"
    :size="'normal'"
    :title="(mode == 'add' ? '添加' : '编辑') + '字段组'"
    :ok-text="'确认'"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="字段组名称" prop="fieldGroupName">
        <a-input v-model="form.fieldGroupName" placeholder="请输入1-20个字符" />
      </a-form-model-item>
    </a-form-model>
  </Modal>
</template>

<script>
import { postCreateFieldGroup } from '@/services/user/fieldGroup.js'
export default {
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      visible: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      form: {
        fieldGroupId: null,
        fieldGroupName: ''
      },
      rules: {
        fieldGroupName: [
          {
            required: true,
            message: '请输入字段组名称',
            trigger: 'blur'
          },
          { min: 1, max: 20, message: '请输入1-20个字符', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    mode() {
      // 根据id自动计算新增、编辑
      return this.form.fieldGroupId ? 'update' : 'add'
    }
  },
  mounted() {},
  methods: {
    // 打开弹窗
    open(data) {
      if (data) {
        this.form = Object.assign(this.form, { ...data })
      }
      this.visible = true
    },
    // 保存事件
    handleOk() {
      // eslint-disable-next-line space-before-function-paren
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.mode === 'add') {
            delete this.form.fieldGroupId
            const res = await postCreateFieldGroup(this.form)
            if (res.code === 100) {
              this.$message.success('新增字段组成功')
              this.handleCancel()
              this.$emit('reload')
            }
          } else {
            this.handleCancel()
            this.$emit('reload')
          }
        }
      })
    },
    // 取消事件
    handleCancel() {
      this.visible = false
      this.resetForm()
    },
    // 重置表单内容和验证
    resetForm() {
      this.form = {
        fieldGroupId: null,
        fieldGroupName: ''
      }
      this.$refs.ruleForm.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
</style>
