<template>
  <Modal
    :closable="true"
    :title="'修改职级分类'"
    :visible="visible"
    :size="'normal'"
    :mask-closable="false"
    @ok="handleOk"
    @cancel="handleCancel"
    @afterClose="afterClose"
  >
    <k-form-build
      ref="KFB"
      :value="jsonData"
    />
  </Modal>
</template>

<script>
import { UpperCaseReg } from '@/utils/reg.js'
import { updateRankType } from '@/services/organization/rank.js'
export default {
  name: 'Edit',
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      jsonData: {
        'list': [
          {
            'type': 'input',
            'label': '职类名称',
            'options': {
              'type': 'text',
              'width': '100%',
              'defaultValue': '',
              'placeholder': '请输入',
              'clearable': true,
              'maxLength': 10,
              'addonBefore': '',
              'addonAfter': '',
              'hidden': false,
              'disabled': false
            },
            'model': 'jobTypeName',
            'key': 'input_1646116416137',
            'help': '',
            'rules': [
              {
                'required': true,
                'message': '请输入职类名称'
              }
            ]
          },
          {
            'type': 'input',
            'label': '职类代码',
            'options': {
              'type': 'text',
              'width': '100%',
              'defaultValue': '',
              'placeholder': '请输入',
              'clearable': true,
              'maxLength': 1,
              'addonBefore': '',
              'addonAfter': '',
              'hidden': false,
              'disabled': false
            },
            'model': 'jobTypeCode',
            'key': 'input_1646116458728',
            'help': '',
            'rules': [
              {
                'required': true,
                'message': '请输入职类代码'
              },
              {
                'pattern': UpperCaseReg,
                'message': '请输入一位大写字母'
              }
            ]
          },
          {
            'type': 'textarea',
            'label': '职类说明',
            'options': {
              'width': '100%',
              'minRows': 4,
              'maxRows': 6,
              'maxLength': 300,
              'defaultValue': '',
              'clearable': false,
              'hidden': false,
              'disabled': false,
              'placeholder': '最多300个字符'
            },
            'model': 'detail',
            'key': 'textarea_1646116547266',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': '必填项'
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      jobTypeId: ''
    }
  },
  methods: {
    show(info) {
      console.log(info)
      this.jobTypeId = info.jobTypeId
      this.visible = true
      this.$nextTick(() => {
        this.$refs.KFB.setData(info)
      })
    },
    async handleOk() {
      const params = await this.$refs.KFB.getData()
      this.confirmLoading = true
      params['jobTypeId'] = this.jobTypeId
      const res = await updateRankType(params)
      this.confirmLoading = false
      if (!res) return false
      this.visible = false
      this.$emit('complate')
    },
    handleCancel() {
      this.visible = false
      this.$refs.KFB.reset()
    },
    afterClose() {
      this.$refs.KFB.reset()
    }
  }
}
</script>

<style lang="less" scoped>
</style>
