var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"incomeTax"},[_c('div',{staticClass:"top_container"},[_c('HeaderBack',{on:{"back":function($event){return _vm.$emit('goBack')}},scopedSlots:_vm._u([{key:"left",fn:function(){return [_vm._v(" "+_vm._s(_vm.titleName)+" "+_vm._s(_vm.infos.companyName)+" ")]},proxy:true}])})],1),_c('CbStatistics',{staticClass:"statistics",attrs:{"data-list":_vm.infos.tableNameList,"length":"4"}}),_c('div',{staticClass:"search"},[_c('appForm',{attrs:{"to-list":_vm.formList,"screen-roster-list":_vm.screenRosterList,"search-placeholder":"请搜索姓名/工号"},on:{"screenRosterParams":_vm.screenRosterParams,"getSearchCallback":_vm.getSearchCallback}},[_c('template',{slot:"customButton"},[_c('div',{staticClass:"custom-button"},[_c('a-button',{attrs:{"prefix":"piliangdaoru"},on:{"click":_vm.exportTaxpayStaffPaymentInfo}},[_vm._v(" 报税文件导出 ")]),(_vm.infos.checkStatus == 0)?_c('a-button',{attrs:{"prefix":"piliangdaoru"},on:{"click":_vm.handleImport}},[_vm._v(" 算税结果导入 ")]):_vm._e()],1)])],2)],1),_c('custom-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"row-key":"onJobId","pagination":{
      current: _vm.pager.pageNum,
      pageSize: _vm.pager.pageSize,
      total: _vm.total,
      showTotal: function (total) { return ("共 " + total + " 条数据"); },
      showQuickJumper: true,
      showSizeChanger: true
    }},on:{"change":_vm.loadDataList},scopedSlots:_vm._u([{key:"index",fn:function(ref){
    var scope = ref.scope;
return [_c('span',[_vm._v(_vm._s(scope.index + 1))])]}},{key:"staffName",fn:function(ref){
    var scope = ref.scope;
return [_c('CbTableAvater',{attrs:{"staff-id":scope.record.staffId,"on-job-id":scope.record.onJobId,"table-avater":scope.record.avatar,"table-name":scope.record.staffName}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }