<template>
  <div class="main-content">
    <a-tabs v-if="!isPreview" v-model="activeKey" @change="callback">
      <a-tab-pane key="1" tab="待开具">
        <CertificateTabel v-if="keyval == 1" :keyval="keyval" @nextStep="nextStep" @gettemId="gettemId" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="已开具">
        <CertificateTabel v-if="keyval == 2" ref="CertificateTabel" :keyval="keyval" @detail="getDetail" />
      </a-tab-pane>
    </a-tabs>
    <div v-if="isPreview">
      <CertificatePreview :select-info="selectInfo" :temp-id="tempId" :details-info="detailsInfo" @back="comeBack" />
    </div>
  </div>
</template>

<script>
import CertificateTabel from './table.vue'
import mixins from '@/components/CustomTable/index.js'
export default {
  name: 'Certificate',
  components: {
    CertificateTabel,
    CertificatePreview: () => import('./form/preview/certificatePreview.vue')
  },
  mixins: [mixins],
  data() {
    return {
      keyval: '1',
      isPreview: false,
      selectInfo: {},
      activeKey: '1',
      detailsInfo: [],
      tempId: ''
    }
  },
  methods: {
    callback(key) {
      this.keyval = key
    },
    // sourceType 1: 开具证明 2：批量开具
    nextStep(val, sourceType) {
      if (sourceType === 2) {
        this.selectInfo = val
        this.detailsInfo = []
      } else {
        this.selectInfo = {}
        this.detailsInfo = val
        this.detailsInfo['isEdit'] = 'edit'
      }
      this.isPreview = true
    },
    getDetail(val) {
      this.detailsInfo = {
        ...val,
        isEdit: 'details',
        onlyRead: true
      }
      this.selectInfo = []
      this.isPreview = true
    },
    comeBack(bool) {
      this.isPreview = false
      if (bool) {
        this.activeKey = this.keyval = '2'
      } else {
        this.activeKey = this.keyval
      }
    },
    gettemId(e) {
      this.tempId = e
    }
  }
}
</script>

<style lang="less" scoped>
.main-content {
  min-height: 100%;
  background: #fff;
  padding: 20px 20px 0;
  overflow: auto;
}
/deep/ .ant-tabs{
  height: 100%;
  overflow: auto;
}
/deep/ .ant-tabs-nav-scroll {
  margin-top: -4px;
}
/deep/ .ant-tabs-tab {
  color:  @sc-grey-100;
  font-size: 16px;
  padding-top: 0;
}
/deep/ .ant-tabs-tab-active{
  color: @sc-primary-100;
  font-weight: 500;
}
/deep/ .ant-tabs-tab:hover {
  color: @sc-primary-100;
}
</style>
