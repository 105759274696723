<template>
  <div>
    <Modal overflow-auto :visible="visible" closable title="办理调动" size="normal" ok-text="保存" @ok="handleOk" @cancel="handleCancel">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="调动员工" prop="staffId">
          <perTreeSelect v-model="form.staffId" @getPerTreeValue="getPerTreeValue" />
          <!-- <a-select v-model="form.staffId" style="width: 100%" placeholder="请选择" :options="optionsType"></a-select> -->
        </a-form-model-item>
        <a-form-model-item label="调动类型" prop="handleType">
          <dictionariesInput v-model="form.handleType" parameter="transfer_type" />
        </a-form-model-item>
        <a-form-model-item label="生效日期" prop="applyTransferDate">
          <a-date-picker v-model="form.applyTransferDate" value-format="YYYY-MM-DD" placeholder="请选择时间" />
        </a-form-model-item>
        <div class="div-line">
          <a-form-model-item label="部门" prop="email" class="line-left" :label-col="labelColC" :wrapper-col="wrapperColC">
            <!-- <a-input v-model="form.email" disabled /> -->
            <depTreeSelect v-model="form.beferOrgId" :multiple="false" :allow-clear="false" placeholder="请选择部门" disabled />
          </a-form-model-item>
          <a-form-model-item label="变更为" prop="afterOrgId" class="line-right" :label-col="labelColC" :wrapper-col="wrapperColC">
            <depTreeSelect v-model="form.afterOrgId" :multiple="false" :allow-clear="false" placeholder="请选择部门" :has-relation="true" @getValueSelect="getValueSelect" />
          </a-form-model-item>
        </div>
        <div class="div-line">
          <a-form-model-item label="岗位" prop="email" class="line-left" :label-col="labelColC" :wrapper-col="wrapperColC">
            <postInput v-model="form.beforePostId" disabled />
          </a-form-model-item>
          <a-form-model-item label="变更为" prop="afterPostId" class="line-right" :label-col="labelColC" :wrapper-col="wrapperColC">
            <postInput v-model="form.afterPostId" :disabled="postDisabled" :clearable="false" :org-id="orgIdObj" :has-relation="true" @getPostValue="getPostValue" />
          </a-form-model-item>
        </div>
        <div class="div-line">
          <a-form-model-item label="职务" prop="beforeDuty" class="line-left" :label-col="labelColC" :wrapper-col="wrapperColC">
            <a-select v-model="form.beforeDuty" :options="dutyList" placeholder="请选择" disabled allow-clear />
          </a-form-model-item>
          <a-form-model-item label="变更为" prop="afterDuty" class="line-right" :label-col="labelColC" :wrapper-col="wrapperColC">
            <a-select v-model="form.afterDuty" :options="dutyList" placeholder="请选择" :disabled="postDisabled" allow-clear />
          </a-form-model-item>
        </div>
        <div class="div-line">
          <a-form-model-item label="职级" prop="email" class="line-left" :label-col="labelColC" :wrapper-col="wrapperColC">
            <rankInput v-model="form.beforeJobGradeId" disabled />
          </a-form-model-item>
          <a-form-model-item label="变更为" prop="afterJobGradeId" class="line-right" :label-col="labelColC" :wrapper-col="wrapperColC">
            <rankInput v-model="form.afterJobGradeId" :clearable="false" :disabled="rankDisabled" :has-relation="true" :post-id="form.afterPostId" />
          </a-form-model-item>
        </div>
        <div class="div-line">
          <a-form-model-item label="工作地点" prop="email" class="line-left" :label-col="labelColC" :wrapper-col="wrapperColC">
            <a-select v-model="form.beforeWorkplaceId" placeholder="请选择" :options="workAddressList" disabled />
          </a-form-model-item>
          <a-form-model-item label="变更为" prop="afterWorkplaceId" class="line-right" :label-col="labelColC" :wrapper-col="wrapperColC">
            <a-select v-model="form.afterWorkplaceId" placeholder="请选择" :options="workAddressList" />
          </a-form-model-item>
        </div>
        <div class="div-line">
          <a-form-model-item label="合同公司" prop="email" class="line-left" :label-col="labelColC" :wrapper-col="wrapperColC">
            <a-select v-model="form.beforeContractCompanyId" placeholder="请选择" :options="contractCompanyList" disabled />
          </a-form-model-item>
          <a-form-model-item label="变更为" prop="afterContractCompanyId" class="line-right" :label-col="labelColC" :wrapper-col="wrapperColC">
            <a-select v-model="form.afterContractCompanyId" placeholder="请选择" :options="contractCompanyList" />
          </a-form-model-item>
        </div>
        <a-form-model-item label="调动原因" prop="reasons">
          <a-input v-model="form.reasons" type="textarea" :auto-size="true" placeholder="请输入" />
        </a-form-model-item>
      </a-form-model>
    </Modal>
  </div>
</template>

<script>
import perTreeSelect from '@/components/CbPerDepSelection/perTreeSelect/index'
// 字典下拉框
import dictionariesInput from '@/components/CbDropDownInput/dictionariesInput/index'
// 岗位下拉框
import postInput from '@/components/CbDropDownInput/post/index'
// 职级
import rankInput from '@/components/CbDropDownInput/rank/index'
import mixins from '@/components/CbPerDepSelection/index.js'
import { addTransfer, workAddress, contractCompany, getDetailsBefore } from '@/services/user/transfer.js'
import { getSelectDutyList } from '@/services/organization/duty.js'
// import { getPostDropDownList } from '@/services/organization/post.js'
export default {
  components: {
    dictionariesInput,
    postInput,
    rankInput,
    perTreeSelect,
    Modal: () => import('@/components/CbModal/index')
  },
  mixins: [mixins],

  data() {
    return {
      rankDisabled: true,
      postDisabled: true,
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      labelColC: { span: 7 },
      wrapperColC: { span: 17 },
      workAddressList: [],
      contractCompanyList: [],
      form: {
        staffId: undefined,
        handleType: undefined,
        applyTransferDate: undefined,
        afterOrgId: [],
        afterPostId: undefined,
        afterDuty: undefined,
        afterJobGradeId: undefined,
        afterWorkplaceId: undefined,
        afterContractCompanyId: undefined,
        reasons: ''
      },
      rules: {
        'staffId': [
          {
            required: true,
            validator: this.rulesArray
          }
        ],
        handleType: [
          {
            required: true,
            message: '请选择调动类型',
            trigger: 'change'
          }
        ],
        applyTransferDate: [
          {
            required: true,
            message: '请选择生效日期',
            trigger: 'change'
          }
        ],
        afterOrgId: [
          {
            required: true,
            trigger: 'change',
            validator: this.rulesOrgId

          }
        ],
        afterPostId: [
          {
            required: true,
            message: '请选择岗位',
            trigger: 'change'
          }
        ]
        // afterJobGradeId: [
        //   {
        //     required: true,
        //     message: '请选择职级',
        //     trigger: 'change'
        //   }
        // ]
      },
      optionsType: [
        {
          value: '1',
          label: '社招'
        },
        {
          value: '2',
          label: '校招'
        },
        {
          value: '3',
          label: '内推'
        }
      ],
      dutyList: []
    }
  },
  computed: {
    mode() {
      // 根据id自动计算新增、编辑
      return this.form.id ? 'update' : 'add'
    },
    orgIdObj() {
      return typeof this.form.afterOrgId !== 'undefined' && this.form.afterOrgId.length > 0 ? this.form.afterOrgId[0].value : ''
    }
  },
  watch: {
  },
  created() {
    this.rules.afterOrgId = [
      {
        required: true,
        trigger: 'change',
        validator: this.rulesOrgId
      }
    ]
  },
  methods: {
    // 打开弹窗
    open() {
      this.visible = true
      this.initData()
    },
    async getPerInfo(val) {
      const res = await getDetailsBefore({
        onJobId: String(val)
      })// 获取不到调动前的数据
      if (res.code === 100) {
        this.form.beferOrgId = res.data.beforeOrgId ? [{ value: String(res.data.beforeOrgId) }] : undefined
        this.form.beforePostId = res.data.beforePostId ? res.data.beforePostId : undefined
        this.form.beforeDuty = res.data.beforeDuty ? res.data.beforeDuty : undefined
        this.form.beforeJobGradeId = res.data.beforeJobGradeId ? res.data.beforeJobGradeId : undefined
        this.form.beforeWorkplaceId = res.data.beforeWorkplaceId ? res.data.beforeWorkplaceId : undefined
        this.form.beforeContractCompanyId = res.data.beforeContractCompanyId ? res.data.beforeContractCompanyId : undefined
        this.form.afterOrgId = res.data.beforeOrgId ? [{ value: String(res.data.beforeOrgId) }] : undefined
        this.form.afterPostId = res.data.beforePostId ? res.data.beforePostId : undefined
        this.form.afterDuty = res.data.beforeDuty ? res.data.beforeDuty : undefined
        this.form.afterJobGradeId = res.data.beforeJobGradeId ? res.data.beforeJobGradeId : undefined
        this.form.afterWorkplaceId = res.data.beforeWorkplaceId ? res.data.beforeWorkplaceId : undefined
        this.form.afterContractCompanyId = res.data.beforeContractCompanyId ? res.data.beforeContractCompanyId : undefined
        this.$forceUpdate()
        this.postDisabled = !this.form.afterOrgId
        this.rankDisabled = !this.form.afterPostId
        // this.preCheck(this.form)
      }
    },
    getPerTreeValue(val) {
      this.getPerInfo(val)
      this.$refs.ruleForm.validateField('staffId')
    },
    // 保存事件
    handleOk() {
      // eslint-disable-next-line space-before-function-paren
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.mode === 'add') {
            const res = await addTransfer({
              onJobId: this.form.staffId,
              transferType: this.form.handleType,
              applyTransferDate: this.form.applyTransferDate,
              reasons: this.form.reasons,
              afterOrgId: this.form.afterOrgId[0].value,
              afterPostId: this.form.afterPostId,
              afterDuty: this.form.afterDuty,
              afterJobGradeId: this.form.afterJobGradeId,
              afterWorkplaceId: this.form.afterWorkplaceId,
              afterContractCompanyId: this.form.afterContractCompanyId
            })
            if (res.code === 100) {
              this.$message.success('添加成功')
              this.$emit('getFetchDataApiFn')
              this.visible = false
              this.resetForm()
            }
          } else {
            console.log('删除')
          }
        }
      })
    },

    async initData() {
      // 获取工作地址
      const res = await workAddress()
      if (res.code === 100) {
        this.workAddressList = res.data.map((item) => {
          return {
            label: item.addressName,
            value: item.addressId
          }
        })
      }
      // 获取合同公司
      const ress = await contractCompany()
      if (ress.code === 100) {
        this.contractCompanyList = ress.data.map((item) => {
          return {
            label: item.companyName,
            value: item.companyId
          }
        })
      }
      const dutyList = await getSelectDutyList()
      this.dutyList = dutyList.data.map((item) => {
        return {
          label: item.dutyName,
          value: item.dutyId
        }
      })
    },
    // 取消事件
    handleCancel() {
      this.visible = false
      this.postDisabled = true
      this.resetForm()
    },
    // 重置表单内容和验证
    resetForm() {
      this.form = {
        staffId: undefined,
        handleType: undefined,
        applyTransferDate: undefined,
        afterOrgId: [],
        afterPostId: undefined,
        afterDuty: undefined,
        afterJobGradeId: undefined,
        afterWorkplaceId: undefined,
        afterContractCompanyId: undefined,
        reasons: ''
      }
      this.rankDisabled = true
      this.postDisabled = true
      this.$refs.ruleForm.resetFields()
    },
    // 获取岗位
    async getValueSelect(e) {
      this.form.afterJobGradeId = undefined
      this.form.afterPostId = undefined
      if (e && e.length > 0) {
        this.postDisabled = false
      } else {
        this.rankDisabled = true
        this.postDisabled = true
      }
      if (typeof this.form.afterPostId === 'undefined') {
        this.rankDisabled = true
      }
    },
    // 判断岗位input中是否有值，使职级input的disabled的值变化
    getPostValue(data) {
      this.form.afterJobGradeId = undefined
      if (data && data.length > 0) {
        this.rankDisabled = false
      }
      if (typeof this.form.afterPostId === 'undefined') {
        this.rankDisabled = true
      }
      this.form.afterPostId = data
    },
    // 自定义表单数组项回调
    rulesArray(rule, value, callback) {
      if (!value) {
        callback(`请输入员工`)
      } else {
        callback()
      }
    },
    rulesOrgId(rule, value, callback) {
      if (this.form.afterOrgId.length === 0) {
        callback(`请选择部门`)
      } else {
        callback()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ant-calendar-picker{
  width: 100%;
}
.div-line {
  display: flex;
  justify-content: space-between;
  .line-left{
    width: 50%;
  }
  .line-right{
    width: 48%;
  }
}
</style>
