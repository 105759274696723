var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bill-details"},[_c('div',{staticClass:"header-back"},[_c('a-button',{attrs:{"prefix":"left"},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v("返回")])],1),_c('appForm',{staticClass:"appform",attrs:{"to-list":_vm.formList,"search-placeholder":"请搜索姓名/工号"},on:{"getSearchCallback":_vm.getSearchCallback,"exportFileList":_vm.exportFile}}),_c('custom-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"row-key":"onJobId","pagination":{
      current: _vm.pager.pageNum,
      pageSize: _vm.pager.pageSize,
      total: _vm.total,
      showTotal: function (total) { return ("共 " + total + " 条数据"); },
      showQuickJumper: true,
      showSizeChanger: true
    }},on:{"change":_vm.loadDataList},scopedSlots:_vm._u([{key:"staffName",fn:function(ref){
    var scope = ref.scope;
return [_c('CbTableAvater',{attrs:{"staff-id":scope.record.staffId,"on-job-id":scope.record.onJobId,"table-name":scope.record.staffName,"title":scope.record.staffName,"is-archives":true}})]}},{key:"operation",fn:function(ref){
    var scope = ref.scope;
return [_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.edit(scope.record)}}},[_vm._v(" 明细 ")])]}}])}),_c('DetailDrawer',{ref:"detailDrawer",attrs:{"detail-type":"person"},on:{"reloadData":_vm.serchGetData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }