<!-- 消息提醒 -->
<template>
  <div class="socialSecurityScheme">
    <div class="socialSecurityScheme-head">
      <a-button prefix="add" @click="addRemind">
        新增消息提醒
      </a-button>
    </div>
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      :pagination="false"
      row-key="remindId"
      @change="loadDataList"
    >
      <template slot="earlyDays" slot-scope="{scope}">
        <div>增减员截至日期前{{ scope.record.earlyDays }}天</div>
      </template>
      <template slot="iodsEndDay" slot-scope="{scope}">
        <div>每月{{ scope.record.iodsEndDay }}日</div>
      </template>
      <!-- 是否启用 -->
      <template slot="state" slot-scope="{scope}">
        <a-switch v-model="scope.record.status" @change="changeRemindStatusApi(scope.record)" />
      </template>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{scope}">
        <span class="operation-btn-box">
          <a-button type="link" @click="edit(scope.record)">
            编辑
          </a-button>
          <a-button type="link" class="delete" danger @click="delRemind(scope.record)">
            删除
          </a-button>
        </span>
        <!-- <a-popover
          placement="bottom"
          trigger="hover"
        >
          <template slot="content">
            <div class="more-handle-btn">
              <a-button type="link" @click="edit(scope.record)">
                编辑
              </a-button>
              <a-button type="link" class="delete" danger @click="delRemind(scope.record)">
                删除
              </a-button>
            </div>
          </template>
          <div class="more-handle">
            ···
          </div>
        </a-popover> -->
      </template>
    </custom-table>
    <Drawer
      :title="title==='add'?'新增消息提醒':'编辑消息提醒'"
      :closable="false"
      size="small"
      :visible="visible"
      @close="onClose"
      @onSubmit="submit"
    >
      <div class="deawer-content">
        <a-form-model
          ref="messageForm"
          :model="messageForm"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="消息类别" prop="messageType">
            <dictionariesInput v-model="messageForm.messageType" disabled parameter="remind_type" />
          </a-form-model-item>
          <a-form-model-item label="提醒事件" prop="reminderEvents">
            <dictionariesInput v-model="messageForm.reminderEvents" placeholder="请选择提醒事件" parameter="reminder" />
          </a-form-model-item>
          <a-form-model-item label="所属城市" prop="provinceCity">
            <CbSelectArea ref="selectArea" :area-show="false" :province-width="150" :government-auto="true" :city-width="150" @change="onChange" />
          </a-form-model-item>
          <a-form-model-item label="增减员截止日期" prop="messageDate">
            <div class="number">
              每月 <CbNumber v-model="messageForm.messageDate" :max="28" />日
            </div>
          </a-form-model-item>
          <a-form-model-item label="提醒时间" prop="messageTime">
            <span>增减员截止日期前</span>
            <a-select v-model="messageForm.messageTime" style="width:100px;margin:0 16px">
              <a-select-option v-for="index in 10" :key="index" :value="index">{{ index }}</a-select-option>
            </a-select>
            <span>天</span>
          </a-form-model-item>
        </a-form-model>
      </div>
    </Drawer>
  </div>
</template>
<script>
import mixins from '@/components/CustomTable/index.js'
import dictionariesInput from '@/components/CbDropDownInput/dictionariesInput/index'
import {
  getRemindListApi,
  createRemindApi,
  updateRemindApi,
  deleteRemindApi,
  changeRemindStatusApi
} from '@/services/insurance/setting.js'
export default {
  name: 'MessageReminders',
  components: {
    CbNumber: () => import('@/components/CbNumber'),
    CbSelectArea: () => import('@/components/CbSelectArea'),
    Drawer: () => import('@/components/CbDrawer/index'),
    dictionariesInput
  },
  mixins: [mixins],
  data() {
    return {
      title: '',
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
      visible: false,
      messageForm: {
        messageType: undefined,
        reminderEvents: undefined,
        province: undefined,
        city: undefined,
        messageDate: 1,
        messageTime: 1
      },
      remindId: undefined,
      status: false,
      dataSource: [],
      columns: [
        {
          title: '消息类型',
          dataIndex: 'remindTypeName',
          key: 'remindTypeName',
          width: 120
        }, {
          title: '提醒时间',
          scopedSlots: { customRender: 'earlyDays' },
          width: 200
        }, {
          title: '提醒事件',
          dataIndex: 'reminderName',
          key: 'reminderName',
          width: 250
        }, {
          title: '城市',
          dataIndex: 'cityName',
          key: 'cityName',
          width: 160,
          ellipsis: true
        }, {
          title: '增减员截止时间',
          dataIndex: 'iodsEndDay',
          key: 'iodsEndDay',
          scopedSlots: { customRender: 'iodsEndDay' },
          width: 140
        }, {
          title: '是否启用',
          scopedSlots: { customRender: 'state' },
          width: 80
        }, {
          title: '操作',
          scopedSlots: { customRender: 'operation' },
          width: 100
        }
      ],
      rules: {
        reminderEvents: [
          { required: true, message: '请选择提醒事件', trigger: 'blur' }
        ],
        provinceCity: [{ required: true, validator: this.getProvinceCity, trigger: 'change' }],
        messageDate: [
          { required: true, message: '请填写截至日期', trigger: 'change' }
        ],
        messageTime: [
          { required: true, message: '请选择提醒时间', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
  },
  methods: {
    // 所属城市校验
    getProvinceCity(rule, value, callback) {
      this.$nextTick(() => {
        if (['110000', '120000', '310000', '500000'].includes(this.messageForm.province)) {
          this.messageForm.city = this.messageForm.province
        }
        if (!this.messageForm.province) {
          callback(new Error('请选择所属省份'))
        } else if (Number(this.messageForm.city) === -1 || !this.messageForm.city) {
          callback(new Error('请选择所属城市'))
        } else {
          callback()
        }
      })
    },
    // 初始获取列表数据
    async fetchDataApiFn() {
      const res = await getRemindListApi()
      this.dataSource = res.data ? res.data.map(item => {
        return {
          ...item,
          status: item.status === 1
        }
      }) : []
    },
    // 新增接口
    async createRemindApi() {
      const res = await createRemindApi({
        remindType: this.messageForm.messageType,
        cityCode: this.messageForm.city,
        earlyDays: this.messageForm.messageTime,
        reminder: this.messageForm.reminderEvents,
        iodsEndDay: this.messageForm.messageDate
      })
      if (res.code === 100) {
        this.$message.success('新增成功')
        this.serchGetData()
        this.onClose()
      }
    },
    // 修改接口
    async updateRemindApi() {
      const res = await updateRemindApi({
        remindId: this.remindId,
        remindType: this.messageForm.messageType,
        cityCode: this.messageForm.city,
        earlyDays: this.messageForm.messageTime,
        reminder: this.messageForm.reminderEvents,
        iodsEndDay: this.messageForm.messageDate,
        status: this.status ? 1 : 0
      })
      if (res.code === 100) {
        this.$message.success('修改成功')
        this.serchGetData()
        this.onClose()
      }
    },
    // 更新消息状态
    async changeRemindStatusApi(scope) {
      const res = await changeRemindStatusApi({
        remindId: scope.remindId,
        status: scope.status ? 1 : 0
      })
      if (res.code === 100) {
        this.$message.success('状态更改成功')
        this.serchGetData()
      }
    },
    // 新增
    addRemind() {
      this.title = 'add'
      this.messageForm.messageType = 'iods'
      this.visible = true
    },
    // 编辑
    edit(e) {
      this.title = 'edit'
      this.remindId = e.remindId
      this.messageForm.messageType = e.remindType
      this.messageForm.reminderEvents = e.reminder
      this.messageForm.messageTime = e.earlyDays
      this.messageForm.messageDate = e.iodsEndDay
      this.status = e.status
      this.visible = true
      this.$nextTick(() => {
        this.$refs.selectArea.dataEcho({ province: e.provinceCode, city: e.cityCode }) // 回显省市
      })
    },
    // 删除弹窗
    delRemind(scope) {
      this.$Reconfirm({
        title: '删除',
        content: '删除消息提醒'
      }).then(() => {
        this.deletedOk(scope.remindId)
      }).catch(() => {})
    },
    // 删除确认
    async deletedOk(remindId) {
      const res = await deleteRemindApi({ remindId })
      if (res.code === 100) {
        this.$message.success('删除成功')
        this.serchGetData()
      }
    },
    // 确认
    submit() {
      this.$refs.messageForm.validate(async(valid) => {
        if (valid) {
          if (Number(this.messageForm.messageDate) === 0) {
            this.$message.warning('增援截至日期不能为0')
          } else {
            if (this.title === 'add') {
              await this.createRemindApi()
            } else {
              await this.updateRemindApi()
            }
          }
        }
      })
    },
    // 所属城市选择
    onChange(val, e) {
      this.$set(this.messageForm, 'province', e[0])
      this.$set(this.messageForm, 'city', e[1])
    },
    // 关闭抽屉
    onClose() {
      this.visible = false
      this.reset()
      this.$refs.selectArea.closeClearValue() // 清除省市
      this.messageForm = {
        messageType: undefined,
        reminderEvents: undefined,
        province: undefined,
        city: undefined,
        messageDate: 1,
        messageTime: 1
      }
    },
    // 抽屉表单清空
    reset() {
      this.$refs.messageForm.clearValidate()
    }
  }
}
</script>
<style lang="less" scoped>
  .socialSecurityScheme{
    padding: 20px;
    .socialSecurityScheme-head{
      /deep/.ant-btn{
        margin-bottom: 16px;
      }
    }
  }
  .number{
    display: flex;
    align-items: center;
    /deep/.number{
      width: 80px;
      margin:  0 16px;
    }
  }
  .more-handle {
    font-weight: 700;
    color: @sc-primary-100;
    font-size: 20px;
    vertical-align: baseline;
    cursor: pointer;
  }
  .more-handle-btn {
    display: flex;
    flex-direction: column;
    .ant-btn {
      height:25px;
    }
  }
  .operation-btn-box{
    display:flex;
    button{
      padding-left:0;
      padding-right:16px;
    }
  }
  /deep/.ant-form-item-label label{
    color: @sc-grey-100;
  }
  /deep/.ant-form-item-control-wrapper{
    color: @sc-grey-100;
  }
  /deep/.ant-select-enabled{
  &:nth-child(3){
    display: none;
  }
}
</style>
