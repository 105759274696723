<template>
  <div class="record-table">
    <div v-if="!dragValue">
      <div class="time">
        <svg class="iconpark-icon"><use href="#rongqi" /></svg>
        <a-range-picker v-model="recordTime" :allow-clear="clear" value-format="YYYY-MM-DD" separator="~" :ranges="ranges" @change="onChange" />
      </div>
      <div class="record-table-heard">
        <div class="record-table-heard-left">
          <appForm>
            <template slot="customButton">
              <a-dropdown type="primary">
                <template #overlay>
                  <a-menu>
                    <a-menu-item v-for="(item, index) in functionList" :key="index" @click="add(item)">{{ item.name }}</a-menu-item>
                  </a-menu>
                </template>
                <a-button prefix="add-one" suffix="left">
                  添加补卡记录
                </a-button>
              </a-dropdown>
            </template>
          </appForm>
          <a-button class="deleteBtn" prefix="reduce-one" :disabled="selectedRowKeys.length===0?true:false" @click="openDeleteBox">
            批量删除
          </a-button>
        </div>
        <div class="left">
          <appForm
            ref="appForm"
            :to-list="formList"
            search-placeholder="请搜索姓名/工号"
            :screen-roster-list="screenRecordList"
            @getSearchCallback="getSearchCallback"
            @screenRosterParams="screenRosterParams"
            @exportFileList="exportFileList"
          />
        </div>
      </div>
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        row-key="appendPunchApplyId"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          columnWidth: 30,
          onChange: onSelectChange,
          getCheckboxProps: (record) => ({
            props: {
              disabled: record.operateType === '1',
            },
          }),
        }"
        :selection-mode="selectionMode"
        :pagination="{
          current: pager.pageNum,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条数据`,
          showQuickJumper: true,
          showSizeChanger: true
        }"
        @change="haedChang"
      >
        <!-- 序号 -->
        <template slot="index" slot-scope="{ scope }">
          <span>{{ scope.index + 1 }}</span>
        </template>
        <template slot="staffName" slot-scope="{ scope }">
          <CbTableAvater :table-name="scope.record.staffName" :on-job-id="scope.record.onJobId" :staff-id="scope.record.staffId" :title="scope.record.staffName" />
        </template>
        <!-- 占比头部自定义插槽 -->
        <!-- 数据来源 -->
        <template slot="operateType" slot-scope="{scope}">
          <div :title="getCodeContent(scope.record.operateType,operate_type)">{{ getCodeContent(scope.record.operateType,operate_type) }}</div>
        </template>
        <!-- 操作列 -->
        <template slot="operation" slot-scope="{scope}">
          <div class="flex-align-center">
            <div class="more-handle">
              <a-button slot="reference" :disabled="scope.record.operateType ==='1'" type="link" @click="edit(scope.record)">编辑</a-button>
            </div>
          </div>
        </template>
      </custom-table>
      <AddModal ref="addModal" @updata="updata" />
    </div>
    <ExcelImport v-else :title="importTitle" import-template="append_punch" @toggleValue="toggleValue" />
  </div>
</template>
<script>
import mixins from '@/components/CustomTable/index.js'
import moment from 'moment'
import { exportStaffApplyAppendPunch, getStaffApplyAppendPunch, delSstaffApplyAppendPunch } from '@/services/attendance/attendance.js'
export default {
  name: 'Replacement',
  components: {
    ExcelImport: () => import('../../components/excelImport.vue'),
    CbTableAvater: () => import('@/components/CbTableAvater/index'),
    AddModal: () => import('./component/addModal.vue')
  },
  mixins: [mixins],
  data() {
    return {
      operate_type: [],
      importTitle: '批量导入补卡记录',
      dragValue: false,
      clear: false,
      ranges: {
        '上月': [
          moment().month(moment().month() - 1).startOf('month'),
          moment().month(moment().month() - 1).endOf('month')
        ],
        '近三月': [
          moment()
            .startOf('day')
            .subtract(3, 'months'),
          moment()
        ],
        '近六月': [
          moment()
            .startOf('day')
            .subtract(6, 'months'),
          moment()
        ],

        '近一年': [
          moment()
            .startOf('day')
            .subtract(1, 'years'),
          moment()
        ]
      },
      addName: '',
      addRecord: false,
      functionList: [
        { name: '单个添加', label: 'addSingle' },
        { name: '批量导入', label: 'addAll' }
      ],
      formList: ['search', 'export', 'screen'], // search搜索框 screen筛选 export导出
      // 时间段
      recordTime: [moment().startOf('month'), moment().endOf('month')],
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'index' },
          width: '50px',
          fixed: 'left',
          align: 'center',
          key: 'index'
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          key: 'staffName',
          width: '105px',
          fixed: 'left',
          scopedSlots: { customRender: 'staffName' }
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          width: '100px',
          fixed: 'left',
          key: 'staffNum',
          ellipsis: true
        },
        {
          title: '部门',
          width: '100px',
          fixed: 'left',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          width: '100px',
          fixed: 'left',
          key: 'postName',
          ellipsis: true
        },
        {
          title: '上班日期',
          dataIndex: 'punchDate',
          key: 'punchDate',
          width: '100px',
          sorter: true,
          ellipsis: true
        },
        {
          title: '数据来源',
          dataIndex: 'operateType',
          width: '80px',
          scopedSlots: { customRender: 'operateType' },
          key: 'operateType',
          ellipsis: true
        },
        {
          title: '上下班时间',
          dataIndex: 'planDateTime',
          width: '100px',
          key: 'planDateTime',
          ellipsis: true,
          customCell: () => {
            return {
              style: {
                'max-width': '100px'
              }
            }
          }
        },
        {
          title: '补卡时间',
          dataIndex: 'punchDateTime',
          width: '140px',
          key: 'punchDateTime',
          ellipsis: true,
          customCell: () => {
            return {
              style: {
                'max-width': '140px'
              }
            }
          }
        },
        {
          title: '补卡原因',
          dataIndex: 'applyReason',
          ellipsis: true,
          key: 'applyReason',
          width: '150px'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '50px',
          scopedSlots: { customRender: 'operation' },
          fixed: 'right',
          key: 'operation'
        }
      ],
      screenRecordList: {
        'list': [
          {
            'type': 'selectDictionaries',
            'label': '员工状态',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'staff_status',
              'clearable': true,
              'placeholder': '请选择员工状态',
              'showSearch': false
            },
            'model': 'staffStatuses',
            'key': 'staffStatuses',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'engage_mode',
              'clearable': true,
              'placeholder': '请选择聘用形式',
              'showSearch': false
            },
            'model': 'engageModes',
            'key': 'engageModes',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'width': '100%',
              'multiple': true,
              'disabled': false,
              'clearable': true,
              'hidden': false,
              'placeholder': '请选择部门',
              'dynamicKey': 'department',
              'defaultValue': undefined,
              'dynamic': false,
              'showSearch': false
            },
            'model': 'orgIds',
            'key': 'orgIds',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'selectPost',
            'label': '岗位',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'post_type',
              'clearable': true,
              'placeholder': '请选择岗位',
              'showSearch': false
            },
            'model': 'postIds',
            'key': 'postIds',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '合同公司',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择合同公司',
              'showSearch': false,
              'parameter': 'contract_company'
            },
            'model': 'contractCo',
            'key': 'contractCo',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '工作地点',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'work_address',
              'clearable': true,
              'placeholder': '请选择工作地点',
              'showSearch': false
            },
            'model': 'workplaceIds',
            'key': 'workplaceIds',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '数据来源',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择数据来源',
              'showSearch': false,
              'parameter': 'operate_type'
            },
            'model': 'operateType',
            'key': 'operateType',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 80,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      // 数据
      dataSource: [],
      // 选中列表集合
      selectedRowKeys: [],
      selectedRows: [],
      selectionMode: true,
      orgids: [],
      newOrg: [],
      appendPunchApplyId: '',
      staffShiftPunchId: '',
      // 删除列表
      deleteIds: [],
      // 日期范围
      startDate: '',
      endDate: '',
      staffId: '',
      // 弹窗选中日期
      punchDate: '',
      onJobIdVal: '',
      // 弹窗选中时间
      punchTime: '',
      // 禁用时分
      disabledHours: [],
      disabledMinutes: []
    }
  },
  async created() {
    this.operate_type = await this.getTenantCode('operate_type')
  },
  methods: {
    moment,
    range(start, end) {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    },
    // 周期变化  考勤数据变化
    onChange(date) {
      this.startDate = date[0]
      this.endDate = date[1]
      this.serchGetData()
    },
    toggleValue(val) {
      this.dragValue = val
      this.serchGetData()
    },
    // 获取列表信息
    async fetchDataApiFn(param) {
      const startDate = moment(this.recordTime[0]).format('YYYY-MM-DD')
      const endDate = moment(this.recordTime[1]).format('YYYY-MM-DD')
      const res = await getStaffApplyAppendPunch({ ...param, startDate, endDate })
      this.dataSource = res.data.records ? res.data.records : []
      this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
      this.total = res.data.total
    },
    onClose() {
      this.addRecord = false
    },
    // 新增编辑之后刷新
    updata() {
      this.serchGetData()
    },
    // 查询回调
    getSearchCallback(data) {
      this._queryParam.searchWord = data.searchValue
      this.serchGetData()
    },
    // 筛选回调
    screenRosterParams(data) {
      this._queryParam.searchWord = data.searchValue
      if (data.screenCondition.orgIds) {
        this.orgids = data.screenCondition.orgIds
        this.newOrg = this.orgids.map(item => {
          return item.value
        })
        this._queryParam.orgIds = this.newOrg.join(',')
      }
      this._queryParam.staffStatuses = data.screenCondition.staffStatuses ? data.screenCondition.staffStatuses.join(',') : ''
      this._queryParam.postIds = data.screenCondition.postIds ? data.screenCondition.postIds.join(',') : ''
      this._queryParam.engageModes = data.screenCondition.engageModes ? data.screenCondition.engageModes.join(',') : ''
      this._queryParam.workplaceIds = data.screenCondition.workplaceIds ? data.screenCondition.workplaceIds.join(',') : ''
      this._queryParam.contractCo = data.screenCondition.contractCo ? data.screenCondition.contractCo : ''
      if (data.screenCondition.operateType) {
        if (data.screenCondition.operateType.length === 2) {
          delete this._queryParam.operateType
        } else {
          this._queryParam.operateType = data.screenCondition.operateType ? data.screenCondition.operateType.join(',') : ''
        }
      }
      for (var key in this._queryParam) {
        if (!this._queryParam[key]) {
          delete this._queryParam[key]
        }
      }
      this.serchGetData()
    },
    // 导出功能
    exportFileList() {
      const startDate = moment(this.recordTime[0]).format('YYYY-MM-DD') || this.startDate
      const endDate = moment(this.recordTime[1]).format('YYYY-MM-DD') || this.endDate
      const { staffIds, operateType, searchWord, engageModes, orgIds, postIds, contractCo, workplaceIds, staffStatuses } = this._queryParam
      exportStaffApplyAppendPunch({
        staffIds: staffIds,
        operateType: operateType,
        searchWord: searchWord,
        engageModes: engageModes,
        orgIds: orgIds,
        postIds: postIds,
        contractCo: contractCo,
        workplaceIds: workplaceIds,
        staffStatuses: staffStatuses,
        startDate,
        endDate
      })
    },
    // 删除按钮
    openDeleteBox() {
      this.deleteIds = []
      this.selectedRows.forEach(item => {
        this.deleteIds.push(item.appendPunchApplyId)
      })
      this.$Reconfirm({
        title: '删除考勤信息',
        content: '确定要删除该补卡信息吗？'
      }).then(() => {
        delSstaffApplyAppendPunch({ ids: this.deleteIds }).then(res => {
          if (res.code === 100) {
            this.$message.success('删除成功')
            this.selectedRowKeys = []
            this.selectedRows = []
            this.serchGetData()
          }
        })
      }).catch(() => {
        this.$message.info('已取消删除')
        this.selectedRowKeys = []
      })
    },
    // 新增
    add(val) {
      if (val.label === 'addAll') {
        this.dragValue = true
      } else {
        this.addName = 'add'
        this.$refs.addModal.show()
      }
    },
    // 修改
    edit(scope) {
      this.addName = 'edit'
      this.$refs.addModal.show(scope, true)
    },
    // 选择更改回调
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    disabledDate(current) { // 不可选择未来日期
      return current > moment().endOf('day')
    },
    haedChang(pagination, filters, sorter) {
      // console.log(sorter)
      this._queryParam = {
        ...this._queryParam,
        ...this.pager,
        ...filters,
        punchDateSort: sorter.order === 'descend' ? '2' : '1'
      }
      if (sorter.order) {
        console.log(sorter.field, this._queryParam.sort)
        this._queryParam.punchDateSort = sorter.order === 'descend' ? '2' : '1'
      } else if (this._queryParam.punchDateSort) {
        delete this._queryParam.punchDateSort
      }
      this._queryParam.pageNum = pagination.current
      this._queryParam.pageSize = pagination.pageSize
      this.pager.pageNum = pagination.current
      this.pager.pageSize = pagination.pageSize
      this.total = pagination.total
      console.log(this._queryParam)
      this.$$fetchListWithLoading(this._queryParam)
    }
  }
}
</script>

<style lang="less" scoped>

.record-table{
  width: 100%;
  height: calc(100% - 50px);
  overflow: hidden;
  /deep/.ant-pagination{
    .ant-pagination-options-size-changer.ant-select {
      margin-right: 10px;
    }
  }
}
.record-table-heard{
  display: flex;
  justify-content: space-between;
}
.record-table-heard-left{
  display: flex;
  .deleteBtn{
    margin-left: 12px;
  }
}
.time{
    width: 240px;
    font-size: 16px;
    margin-bottom: 16px;
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 14px;
    background: @sc-greyBg-10;
    border-radius: 4px;
    /deep/.ant-calendar-picker{
      width: 100%;
      .ant-calendar-picker-input.ant-input {
        background: @sc-greyBg-10;
        border: none;
      }
      .ant-calendar-range-picker-input {
        width: 46%;
        color: @sc-grey-100;
      }
    }
  .iconpark-icon{
    width: 20px;
    height: 20px;
    color: @sc-primary-100;
  }
}
/deep/ .time .ant-input {
  border: 0;
  background:  @sc-greyBg-10;
  &:hover .ant-calendar-range-picker-input,
  &:hover .ant-calendar-range-picker-separator {
    color:@sc-primary-100 !important;
    cursor: pointer;
  }
}
</style>
