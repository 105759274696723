<template>
  <div>
    <Modal
      :visible="approvalVisible"
      :closable="true"
      :size="'normal'"
      :title="'审批'"
      :ok-text="'确认'"
      @ok="handleSubmit"
      @cancel="handleCancel"
    >
      <div class="text">
        确定{{ form.approvalStatus==='accept'?'同意':'拒绝' }} “{{ form.flowName }}” 吗？
      </div>
      <div class="operation">
        <!-- <p class="operation-title">审批意见</p> -->
        <a-form-model ref="ruleForm" :model="form" :rules="rules">
          <a-form-model-item prop="opinion" label="审批意见">
            <a-textarea
              v-model="form.opinion"
              placeholder="请填写审批意见"
              :auto-size="{ minRows: 5, maxRows: 5 }"
              :max-length="100"
            />
          </a-form-model-item>
          <a-form-model-item v-if="signRequired" prop="signFileId" label="签名" :label-col="{ span: signLabelWidth }">
            <template v-if="editSign">
              <a-space>
                <a-button type="link" @click="saveAsImg">保存签名</a-button>
                <a-button type="link" @click="clearSign">清空签名</a-button>
              </a-space>
              <SignCanvas
                v-if="editSign"
                ref="SignCanvas"
                v-model="sign"
                class="sign-canvas"
                :options="options"
              />
            </template>
            <template v-else>
              <div class="writeSign" @click="editSignFunc">
                <template v-if="!signFileId">
                  手写签名
                </template>
                <img v-else :src="signImgPath" style="width: 60px;height: 30px" alt="" srcset="">
              </div>
            </template>
          </a-form-model-item>
        </a-form-model>
      </div>
    </Modal>
  </div>
</template>

<script>
import { approval, currentSign, replaceCurrentSign } from '@/services/home/process.js'
import { debounce } from '@/utils/index.js'
import SignCanvas from 'sign-canvas'
import { upload, getSignature } from '@/services/upload/index.js'
export default {
  components: {
    Modal: () => import('@/components/CbModal/index'),
    SignCanvas
  },
  data() {
    return {
      signRequired: null,
      basePath: process.env.VUE_APP_API_BASE_URL,
      editSign: false,
      sign: '',
      signImgPath: '',
      signLabelWidth: 6,
      options: {
        lastWriteSpeed: 6, // 书写速度 [Number] 可选
        lastWriteWidth: 2, // 下笔的宽度 [Number] 可选
        lineCap: 'round', // 线条的边缘类型 [butt]平直的边缘 [round]圆形线帽 [square]	正方形线帽
        lineJoin: 'round', // 线条交汇时边角的类型  [bevel]创建斜角 [round]创建圆角 [miter]创建尖角。
        canvasWidth: 500, // canvas宽高 [Number] 可选
        canvasHeight: 300, // 高度  [Number] 可选
        isShowBorder: false, // 是否显示边框 [可选]   当签名模式处于false的时候此选项才生效
        bgColor: '#fff', // 背景色 [String] 可选
        borderWidth: 2, // 网格线宽度  [Number] 可选
        borderColor: '#000', // 网格颜色  [String] 可选
        writeWidth: 4, // 基础轨迹宽度  [Number] 可选
        maxWriteWidth: 4, // 写字模式最大线宽  [Number] 可选
        minWriteWidth: 4, // 写字模式最小线宽  [Number] 可选
        writeColor: '#101010', // 轨迹颜色  [String] 可选
        isSign: true, // 签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
        imgType: 'png' // 下载的图片格式  [String] 可选为 jpeg  canvas本是透明背景的
      },
      approvalVisible: false,
      signFileId: '',
      form: {
        opinion: ''
      },
      rules: {}
    }
  },
  watch: {
    editSign() {
      this.signLabelWidth = this.editSign ? 24 : 6
    }
  },
  methods: {
    // 确认操作
    async handleSubmit() {
      if (this.editSign) {
        return this.$message.warning('签名编辑状态下无法保存')
      }
      if (this.signRequired) {
        this.form['signFileId'] = this.signFileId
        if (!this.form['signFileId']) {
          return this.$message.warning('请添加审批签名')
        }
      }
      const { onJobId } = JSON.parse(localStorage.getItem(process.env.VUE_APP_ACCOUNT_KEY))
      this.form['onJobId'] = onJobId
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.onSubmit()
        }
      })
    },
    onSubmit: debounce(async function() {
      const { code } = await approval(this.form)
      if (code !== 100) return
      this.$message.success('审批成功！')
      this.$emit('refresh')
      this.approvalVisible = false
    }, 500),
    resetData() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
        this.$refs.SignCanvas && this.$refs.SignCanvas.canvasClear()
        Object.assign(this.$data.form, this.$options.data().form)
      })
    },
    clearSign() {
      this.$refs.SignCanvas.canvasClear()
    },
    convertBase64UrlToBlob(base64) {
      let bytes = null
      if (base64.split(',').length > 1) { // 是否带前缀
        bytes = window.atob(base64.split(',')[1]) // 去掉url的头，并转换为byte
      } else {
        bytes = window.atob(base64)
      }
      // 处理异常,将ascii码小于0的转换为大于0
      const ab = new ArrayBuffer(bytes.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i)
      }
      return new Blob([ab], { type: 'png' })
    },
    editSignFunc() {
      this.editSign = true
    },
    async saveAsImg() {
      const img = this.$refs.SignCanvas.saveAsImg()
      const file = this.convertBase64UrlToBlob(img)
      const form = new FormData()
      form.append('file', file)
      form.append('fileType', 'png')
      form.append('sensitiveFile', '1')
      form.append('checkColor', '1')
      // 请求上传文件
      upload(form).then(async res => {
        this.editSign = false
        const { onJobId } = JSON.parse(localStorage.getItem(process.env.VUE_APP_ACCOUNT_KEY))
        await replaceCurrentSign({ fileId: res.data.fileId, onJobId })
        this.currentSign()
      }).catch((error) => {
        console.log(error)
        return this.$message.warning('请填写签名后再进行保存')
      })
    },
    // 取消事件
    handleCancel() {
      this.$refs.SignCanvas && this.$refs.SignCanvas.canvasClear()
      this.approvalVisible = false
      this.editSign = false
    },
    // 查询当前签名
    async currentSign() {
      const { onJobId } = JSON.parse(localStorage.getItem(process.env.VUE_APP_ACCOUNT_KEY))
      const res = await currentSign({ onJobId })
      this.signFileId = res.data
      if (res.data) {
        const signatureRes = await getSignature({
          id: res.data
        })
        this.signImgPath = `${this.basePath}/hrsaas-storage/image/thumbnail?id=${res.data}&signature=${signatureRes.data.signature}&expireTime=${signatureRes.data.time}`
      }
    },
    // 打开弹窗
    openModal(data) {
      this.resetData()
      this.currentSign()
      const { opinionRequired, approvalStatus, signRequired } = data
      this.rules = {}
      if (opinionRequired === 'required' || (opinionRequired === 'notpassrequired' && approvalStatus === 'reject')) {
        this.$set(this.rules, 'opinion', [
          { required: true, message: '请填写审批意见！', trigger: 'blur' }
        ])
      }
      this.form.flowRecordParticipantId = data.flowRecordParticipantId
      this.form.flowRecordId = data.flowRecordId
      this.form.approvalStatus = data.approvalStatus
      this.form.flowName = data.flowName
      this.form.opinionRequired = data.opinionRequired
      this.signRequired = signRequired
      this.approvalVisible = true
    }
  }
}
</script>

<style lang="less" scoped>
.text{
  .font-size(14px);
  color: @sc-grey-100;
}
.writeSign{
  text-align: right;
  &:hover{
    cursor: pointer;
  }
}
.sign-canvas{
  border: 1px solid #c7c9d0;
}
.operation {
  color: @sc-grey-80;
  .font-size(14px);
  &-title{
    margin: 20px 0 10px 0;
  }
}
</style>
