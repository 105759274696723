var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.infoVisible)?_c('div',[_c('appForm',{ref:"appForm",attrs:{"to-list":_vm.toList,"screen-roster-list":_vm.screenRosterList,"width":"400"},on:{"getSearchCallback":_vm.getSearchCallback,"screenRosterParams":_vm.screenRosterParams,"exportFileList":_vm.exportFileList}},[_c('template',{slot:"customButton"},[_c('a-space',[_c('a-button',{attrs:{"prefix":"add-one"},on:{"click":_vm.handleAdd}},[_vm._v(" 批量邀请完善资料 ")]),_c('a-button',{attrs:{"prefix":"genjin"},on:{"click":_vm.handleRecord}},[_vm._v(" 完善记录 ")])],1)],1)],2),_c('custom-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"row-key":"onJobId","table-ref":".user-list","pagination":{
        current: _vm.pager.pageNum,
        pageSize: _vm.pager.pageSize,
        total: _vm.total,
        showTotal: function (total) { return ("共 " + total + " 条数据"); },
        showQuickJumper: true,
        showSizeChanger: true,
        change: _vm.changPages(_vm.pager.pageNum, _vm.pager.pageSize)
      }},on:{"change":_vm.loadDataList},scopedSlots:_vm._u([{key:"index",fn:function(ref){
      var scope = ref.scope;
return [_c('span',[_vm._v(_vm._s(scope.index + 1))])]}},{key:"staffName",fn:function(ref){
      var scope = ref.scope;
return [_c('CbTableAvater',{attrs:{"table-avater":scope.record.avatar,"on-job-id":scope.record.onJobId,"table-name":scope.record.staffName,"staff-id":scope.record.staffId}})]}},{key:"operation",fn:function(ref){
      var scope = ref.scope;
return [(scope.record.ifShow)?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.getInvitationPerfect(scope.record.staffId)}}},[_c('span',{staticClass:"operation-items"},[_vm._v("邀请完善")])]):_vm._e(),(scope.record.entryFormExamShow === 1)?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.entryAudit(scope)}}},[_vm._v(" 入职登记表审核 ")]):_vm._e()]}}],null,false,1212355803)})],1):_c('Information',{on:{"goBack":_vm.goBack}}),_c('PerDepModel',{attrs:{"placeholder":'请输入部门、人员、工号',"get-per-data-list":_vm.getPerDataList,"search-value-list":_vm.searchValueList},on:{"perModelConfirm":_vm.perModelConfirm},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }