<!--
 * @Date: 2022-07-04 19:37:50
 * @descriotion:
-->
<template>
  <div>
    <Modal :visible="visible" :z-index="2" title="创建账单" @ok="handleOk" @cancel="cancel">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="periodId" label="账单月份选择" prop="insureMonth">
          <a-month-picker v-model="form.insureMonth" placeholder="请选择账单月份" :disabled-date="disabledDate" value-format="YYYY-MM" />
        </a-form-model-item>
      </a-form-model>
    </Modal>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'AddBill',
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  props: {
    month: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      visible: false,
      form: {
        insureMonth: ''
      },
      rules: {
        insureMonth: [{ required: true, message: '请选择账单月份', trigger: 'change' }]
      }
    }
  },
  watch: {
    'month': function(val) {
      this.form.insureMonth = val
    }
  },
  mounted() {
    this.form.insureMonth = this.month
  },
  methods: {
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$emit('change', this.form.insureMonth)
          this.cancel()
        }
      })
    },
    cancel() {
      // this.$refs.ruleForm.resetFields()
      this.visible = false
    },
    disabledDate(current) {
      const yearEnd = moment().endOf('month')
      return current > yearEnd
    }
  }
}
</script>
<style lang="less" scoped>
    /deep/.ant-calendar-picker{
        width:100%;
    }
</style>
