<template>
  <div class="incomeTax">
    <div class="top_container">
      <HeaderBack @back="$emit('goBack')">
        <template #left>
          {{ titleName }}
          {{ infos.companyName }}
        </template>
      </HeaderBack>
    </div>
    <!-- <div class="the-line">
      <div v-for="(item, index) in infos.tableNameList" :key="index" class="the-line-item">
        <p> {{ item.infoNum || '--' }} </p>
        <span>{{ item.title }}    </span>
      </div>
    </div> -->
    <CbStatistics :data-list="infos.tableNameList" length="4" class="statistics" />
    <div class="search">
      <appForm
        :to-list="formList"
        :screen-roster-list="screenRosterList"
        search-placeholder="请搜索姓名/工号"
        @screenRosterParams="screenRosterParams"
        @getSearchCallback="getSearchCallback"
      >
        <template slot="customButton">
          <div class="custom-button">
            <a-button prefix="piliangdaoru" @click="exportTaxpayStaffPaymentInfo">
              报税文件导出
            </a-button>
            <a-button v-if="infos.checkStatus == 0" prefix="piliangdaoru" @click="handleImport">
              算税结果导入
            </a-button>
          </div>
        </template>
      </appForm>
    </div>
    <!-- 表格数据 -->
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      row-key="onJobId"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="loadDataList"
    >
      <!-- 序号 -->
      <template slot="index" slot-scope="{ scope }">
        <span>{{ scope.index + 1 }}</span>
      </template>
      <!-- 姓名头像 -->
      <template slot="staffName" slot-scope="{ scope }">
        <CbTableAvater
          :staff-id="scope.record.staffId"
          :on-job-id="scope.record.onJobId"
          :table-avater="scope.record.avatar"
          :table-name="scope.record.staffName"
        />
      </template>
    </custom-table>
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import CbTableAvater from '@/components/CbTableAvater/index'
import { getTaxPaymentListByPeriod, exportTaxpayStaffPaymentInfo } from '@/services/wage/tax.js'
import moment from 'moment'

export default {
  name: 'IncomeTax',
  components: {
    HeaderBack: () => import('@/components/headerBack/index'),
    CbTableAvater,
    CbStatistics: () => import('@/components/CbStatistics/index')
  },
  mixins: [mixins],
  props: {
    infos: {
      type: Object,
      default: function() {}
    }
  },
  data() {
    return {
      total: 0,
      formList: ['search', 'screen'], // search搜索框 export导出
      // 表格项
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'index' },
          width: '50px'
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          scopedSlots: { customRender: 'staffName' },
          width: '105px'
        },
        {
          title: '手机号',
          dataIndex: 'staffPhoneNum',
          key: 'staffPhoneNum',
          width: '100px',
          ellipsis: true
        },
        {
          title: '员工工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          width: '100px',
          ellipsis: true
        },
        {
          title: '所得项目类型',
          dataIndex: 'incomeType',
          key: 'incomeType',
          width: '100px'
        },
        {
          title: '本期收入',
          dataIndex: 'currentPeriodIncome',
          key: 'currentPeriodIncome',
          width: '100px',
          dataType: 'money'
        },
        {
          title: '本期个税',
          dataIndex: 'taxPayment',
          key: 'taxPayment',
          width: '100px',
          ellipsis: true,
          dataType: 'money'
        },
        {
          title: '应补/退税额',
          dataIndex: 'refundableAmount',
          key: 'refundableAmount',
          width: '100px',
          ellipsis: true,
          dataType: 'money'
        },
        {
          title: '免税收入',
          dataIndex: 'exemptionIncome',
          key: 'exemptionIncome',
          width: '100px',
          ellipsis: true,
          dataType: 'money'
        },
        {
          title: '专项扣除',
          dataIndex: 'specialDeduction',
          key: 'specialDeduction',
          width: '100px',
          ellipsis: true,
          dataType: 'money'
        },
        {
          title: '累计专项附加扣除',
          dataIndex: 'accumulatedSpecialDeduction',
          key: 'accumulatedSpecialDeduction',
          width: '140px',
          ellipsis: true,
          dataType: 'money'
        },
        {
          title: '其他扣除',
          dataIndex: 'otherDeduction',
          key: 'otherDeduction',
          width: '140px',
          ellipsis: true,
          dataType: 'money'
        }

      ],
      // 表格数据
      dataSource: [],
      screenRosterList: {
        'list': [
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'width': '100%',
              'parameter': 'engage_mode',
              defaultValue: '',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'engageMode'
          },
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'width': '100%',
              defaultValue: '',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'orgId'
          },
          {
            'type': 'selectPost',
            'label': '岗位',
            'options': {
              'width': '100%',
              defaultValue: null,
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'postId'
          },
          {
            'type': 'selectDictionaries',
            'label': '合同公司',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'contract_company',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'contractCo',
            'key': 'selectDictionaries_1650962628706'
          },
          {
            'type': 'selectDictionaries',
            'label': '工作地点',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'work_address',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'workPlaceId',
            'key': 'workplaceIds',
            'rules': [
              {
                'required': false,
                'message': '请选择'
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '员工状态',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'staff_status',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'staffStatus',
            'key': 'selectDictionaries_1651110897449'
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      }
    }
  },
  computed: {
    titleName() {
      return moment(this.infos.taxpayPeriod).format('YYYY年MM月')
    }
  },
  methods: {
    goBack() {
      this.$emit('goBack')
    },
    getSearchCallback(data) {
      this._queryParam.inputValue = data.searchValue
      this.serchGetData()
    },
    async fetchDataApiFn(param) {
      const { taxpayPeriod, taxpayPeriodInfoId, taxpaySubjectId } = this.infos
      this._queryParam = {
        ...param,
        taxpayPeriod,
        taxpayPeriodInfoId,
        taxpaySubjectId
      }
      const res = await getTaxPaymentListByPeriod(this._queryParam)
      this.dataSource = res.data?.records || []
      this.total = res.data?.total || 0
    },
    async exportTaxpayStaffPaymentInfo() {
      const params = JSON.parse(JSON.stringify(this._queryParam))
      params.pageNum = 1
      params.pageSize = -1
      await exportTaxpayStaffPaymentInfo(params)
    },
    screenRosterParams(data) {
      const { orgId } = data.screenCondition
      this._queryParam = {
        ...data.screenCondition,
        inputValue: data.searchValue,
        orgId: orgId && orgId[0].value
      }
      this.serchGetData()
    },
    // 批量导出
    handleImport() {
      this.$emit('handleImport', this.infos.taxpaySubjectId)
    }
  }
}
</script>
<style lang="less" scoped>
.custom-button {
  display: flex;
  .ant-btn{
    margin-right:12px;
  }
}
/deep/.statistics-center-item{
  position: relative;
  &:first-child::after {
    content: '';
    position: absolute;
    right: 0;
    height: 30px;
    width: 1px;
    background-color: @sc-greyBg-20;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
