<template>
  <div class="subScribe">
    <template v-if="!isImport">
      <div v-if="!isShow">
        <MonthPicker v-model="month" title-text="个税申报" @change="initData" />
        <Alert show-icon closable>
          <template #content>
            <span>提示:  本期计薪-核算薪资环节的 应发工资 全部锁定后，方可进行报送人员统计</span>
            <a @click="skip">前往本期计薪</a>
          </template>
        </Alert>
        <div v-for="(item,index) in taxList" :key="index" class="content">
          <CbStatistics :data-list="item.tableNameList" length="4" :is-title="true" :is-logo="true" :min-width="200" :title-width="240" :title="item.companyName" :is-function="true" class="statistics">
            <template slot="right">
              <div class="main-right">
                <a-button v-if="item.checkStatus !== -1" prefix="chakan-chakanmingxi" @click="look(item)">
                  查看
                </a-button>
              </div>
            </template>
            <template slot="logo">
              <div v-if="item.checkStatus == 1" class="logo" :style="{ 'color': '#00A870' , 'backgroundColor':'rgba(0,168,112,0.1)' }">
                申报成功
              </div>
              <div v-if="item.checkStatus == 0" class="logo" :style="{ 'color': '#ED7B2F' , 'backgroundColor':'rgba(237,123,47,0.1)' }">
                申报处理中
              </div>
              <div v-if="item.checkStatus == -1" class="logo" :style="{ 'color': '#5179FB' , 'backgroundColor':'rgba(81,121,251,0.1)' }">
                待申报
              </div>
            </template>
          </CbStatistics>
        </div>
      </div>
      <div v-if="isShow">
        <IncomeTax :infos="list" @handleImport="handleImport" @goBack="goBack" />
      </div>
    </template>
    <!-- 导入表格 -->
    <!-- 导入表格 -->
    <BatchImport
      v-if="isImport"
      title="个税申报批量导入"
      :extra-params="{...extraParams,taxpayPeriodInfoId:list.taxpayPeriodInfoId}"
      import-template="importTaxpayStaffPaymentInfo"
      @goBack="isImport = false"
    />
  </div>
</template>

<script>
import { getTaxpaySubjectListByPeriod } from '@/services/wage/tax.js'
export default {
  name: 'Subscribe',
  components: {
    IncomeTax: () => import('./incomeTax.vue'),
    MonthPicker: () => import('../components/monthPicker.vue'),
    Alert: () => import('@/components/CbAlert/index'),
    BatchImport: () => import('@/components/CbBatchImport/newImport.vue'),
    CbStatistics: () => import('@/components/CbStatistics/index')
  },
  data() {
    return {
      isShow: false,
      // 是否下一个月
      isNext: false,
      month: '',
      show: false,
      isYearPicker: false,
      taxList: [],
      list: {},
      isImport: false,
      extraParams: {}
    }
  },
  mounted() {
    if (this.$route.params.period) {
      this.month = this.$route.params.period
    }
  },
  methods: {
    async initData() {
      const res = await getTaxpaySubjectListByPeriod({ taxpayPeriod: this.month })
      this.taxList = res.data
      this.taxList.forEach(item => {
        item.tableNameList.forEach((col, index) => {
          console.log(col)
          if (index === 0) {
            col.label = col.title
            col.num = col.infoNum
            col.isMoney = false
          } else {
            col.label = col.title
            col.num = col.infoNum
            col.isMoney = true
          }
        })
      })
    },
    showPicker() {
      this.show = true
    },
    look(val) {
      this.isShow = true
      this.list = val
    },
    goBack() {
      this.isShow = false
    },
    skip() {
      this.$router.push({
        name: 'wagePayroll',
        params: {
          payPeriod: this.month
        }
      })
      // this.$router.push('../wagesIndex/wagePayroll')
    },
    // 点击导出触发
    handleImport(taxpaySubjectId) {
      const { month: taxpayPeriodInfoId } = this
      this.extraParams = {
        taxpayPeriod: taxpayPeriodInfoId,
        taxpaySubjectId
      }
      this.isImport = true
    }
  }
}
</script>
<style lang="less" scoped>
.subScribe{
  width: 100%;
  height: calc(100% - 50px);
  margin-top: 4px;
  padding: 0 20px;
  overflow: auto;
  /deep/.ant-alert{
    margin-bottom: 16px;
  }
  .ant-alert-description a{
    margin-left: 8px;
  }
  .content{
    margin-bottom: 20px;
  }
}
.logo{
  width: 100%;
  border-radius:6px 0 ;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
</style>
