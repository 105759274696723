<template>
  <div class="changeWage">
    <Modal overflow-auto :visible="visible" closable title="人员定薪" size="normal" @ok="handleOk" @cancel="visible=false">
      <div class="infoBox">
        <div class="infoItem">
          <div class="label">员工姓名</div>
          <div class="value">{{ info.staffName }}</div>
        </div>
        <div class="infoItem">
          <div class="label">员工状态</div>
          <div class="value">{{ info.staffStatus }}</div>
        </div>
        <div class="infoItem">
          <div class="label">转正日期</div>
          <div class="value">{{ info.formalDate }}</div>
        </div>
        <div class="infoItem">
          <div class="label">定薪生效日期</div>
          <div class="value">{{ info.setEffectiveDate }}</div>
        </div>
      </div>
      <div class="changeBox">
        <template v-for="(item, index) in salaryList">
          <div :key="`changeItem${index}`" class="changeItem fixed">
            <!-- 正式 -->
            <div class="formal">
              <div class="name" :title=" item.formal.name">{{ item.formal.name }}</div>
              <!-- <NumberInt
                v-model="item.formal.amount"
                class="value"
                suffix="元"
                :is-thousands="true"
                decimal-separator
                is-fill-zero
                :precision="2"
                @change="formalAmountChange(item.formal.amount, item.probation.proportion, item)"
              /> -->
              <NumThousand
                :v-model="item.formal.amount"
                class="value"
                suffix="元"
                :is-thousands="true"
                decimal-separator
                is-fill-zero
                :precision="2"
                @blur="echoValFun(arguments[0], item.probation.proportion, item, index)"
              />
            </div>
            <!-- 试用期 -->
            <div v-if="isShowProbation" class="probation">
              <div class="name" :title=" item.probation.name">{{ item.probation.name }}</div>
              <div class="content">
                <a-radio-group v-model="item.probation.way" class="way" @change="handleChange(...arguments, item)">
                  <a-radio value="proportion">
                    薪资比例
                  </a-radio>
                  <a-radio value="num">
                    薪资数值
                  </a-radio>
                </a-radio-group>
                <NumberInt
                  v-if="item.probation.way === 'proportion'"
                  v-model="item.probation.proportion"
                  :max="100"
                  :min="1"
                  class="proportion"
                  suffix="%"
                  @blur="proportionChange(item.formal.amount, item.probation.proportion, item)"
                />
                <NumberInt
                  v-model="item.probation.amount"
                  class="value"
                  suffix="元"
                  decimal-separator
                  is-fill-zero
                  :precision="2"
                  :disabled="item.probation.way === 'proportion'"
                  @blur="probationAmountChange(item.probation.amount, item.formal.amount, item)"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
    </Modal>
  </div>
</template>

<script>
import floatTool from '@/utils/floatTool'
import {
  setStaffFixedSalary
} from '@/services/wage/wageArchives.js'
export default {
  name: 'ChangeWage',
  components: {
    Modal: () => import('@/components/CbModal/index'),
    NumberInt: () => import('@/components/CbNumber'),
    NumThousand: () => import('@/components/CbNumber/thousand')
  },
  props: {
    info: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      visible: false,
      salaryList: []
    }
  },
  computed: {
    isShowProbation() {
      if (this.info.staffStatus === '试用期') return true
      if (this.info.formalDate) {
        return this.info.formalDate.replaceAll('-', '') > this.info.setEffectiveDate.replaceAll('-', '')
      } else {
        return false
      }
    }
  },
  methods: {
    initData() {
      const copyInfoList = JSON.parse(JSON.stringify(this.info.list))
      for (let i = 0; i < copyInfoList.length; i++) {
        const item = copyInfoList[i]
        item.probation['itemNo'] = item.probation.key
        item.probation['amount'] = undefined
        item.probation['proportion'] = 80
        item.probation['way'] = 'proportion'
        item.formal['itemNo'] = item.formal.key
        item.formal['amount'] = undefined
      }
      this.salaryList = copyInfoList
    },
    show() {
      this.$nextTick(() => {
        this.initData()
      })
      this.visible = true
    },
    handleChange(e, info) {
      if (info.probation['way'] === 'proportion') {
        info.probation.proportion = 80
      } else {
        info.probation.proportion = ''
      }
      if (info.formal.amount && info.probation.proportion) {
        info.probation.amount = floatTool.divide(info.formal.amount * info.probation.proportion, 100)
      } else {
        info.probation.amount = ''
      }
    },
    formalAmountChange(amount, proportion, info) {
      console.log(amount, 'amount')
      console.log(proportion, 'proportion')
      console.log(info, 'info')
      // 不展示试用期的情况下不进行计算赋值
      console.log(this.isShowProbation, '=isShowProbation')
      if (!this.isShowProbation) return
      if ((!amount && amount !== 0) || !proportion) {
        info.probation.amount = ''
        return
      }
      // info.probation.amount = floatTool.divide(amount * proportion, 100)
      info.probation.amount = amount * proportion / 100
    },
    echoValFun(amount, proportion, info, index) {
      // input项赋值
      this.salaryList.forEach(() => {
        if (amount === '') {
          this.salaryList[index].formal.amount = ''
        } else {
          this.salaryList[index].formal.amount = amount
        }
      })
      // 不展示试用期的情况下不进行计算赋值
      if (!this.isShowProbation) return
      if ((!amount && amount !== 0) || !proportion) {
        info.probation.amount = ''
        return
      }
      info.probation.amount = amount * proportion / 100
    },
    probationAmountChange(probationAmount, formalAmount, info) {
      if (!probationAmount || !formalAmount) return
      if (Number(probationAmount) > Number(formalAmount)) {
        info.probation.amount = ''
        return this.$message.warning(`${info.probation.name}不得大于${info.formal.name}`)
      }
    },
    proportionChange(amount, proportion, info) {
      if (!proportion) {
        info.probation.amount = ''
        return
      }
      info.probation.amount = floatTool.divide(amount * proportion, 100)
    },
    async handleOk() {
      const params = {
        onJobId: this.info.onJobId,
        salaryList: [],
        isCalc: '0'
      }
      for (let i = 0; i < this.salaryList.length; i++) {
        const item = this.salaryList[i]
        console.log(item, 'item')
        console.log(this.isShowProbation, 'isShowProbation')
        if (item.probation.amount && !item.formal.amount) {
          return this.$message.warning(`请填写${item.formal.name}`)
        }
        if (this.isShowProbation) params.salaryList.push(item.probation)
        params.salaryList.push(item.formal)
      }
      console.log(params, 'params')
      const res = await setStaffFixedSalary(params)
      this.visible = false
      if (res.code === 100) {
        this.$message.success('定薪成功')
      }
      this.$emit('complate')
    }
  }
}
</script>

<style lang="less" scoped>
.infoBox{
  display: flex;
  flex-wrap: wrap;
  .infoItem{
    width: 50%;
    display: flex;
    margin-bottom: 20px;
    .value {
      width: (100% - 20px - 20%);
      color: @sc-grey-100;
    }
    .label {
      width: 120px;
      margin-right: 20px;
      color: @sc-grey-80;
      .font-size(14px);
      .multi-ellipsis(1);
    }
  }
}
.changeItem{
  width: 100%;
  flex-wrap: wrap;
  .formal{
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    .value{
      flex: 1;
    }
  }
  .probation{
    display: flex;
    margin-bottom: 20px;
    .content{
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      .way{
        width: 100%;
        margin-bottom: 20px;
      }
      .proportion{
        width: calc((100% - 10px) / 2);
        margin-right: 10px;
      }
      .value{
        flex: 1;
        width: calc((100% - 10px) / 2);
      }
    }
  }
  .name{
    width: 20%;
    margin-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: @sc-grey-80;
    .font-size(14px);
  }
  &:last-child {
    .probation {
      margin-bottom: 0;
    }
  }
}
.fixed{
  width: 100%;
}
/deep/ .ant-input-suffix {
  color: @sc-grey-80;
}
/deep/ span.ant-radio + * {
  color: @sc-grey-80;
  font-size: 14px;
}
</style>
