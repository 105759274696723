<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
    >
      <BaseMessage :form="form" :shift-type="shiftType" @change="typeChange" />
      <Timeline :form="form" />
      <FlexSetting v-if="form.shiftType!=='B2'" :shift-type="shiftType" :form="form" />
      <!-- <AbnormalSetting :form="form" /> -->
    </a-form-model>
  </div>
</template>

<script>
import { sepcialCode } from '@/utils/reg.js'
export default {
  components: {
    BaseMessage: () => import('../components/baseMessage.vue'),
    Timeline: () => import('../components/timeline.vue'),
    FlexSetting: () => import('../components/flexSetting.vue')
    // AbnormalSetting: () => import('../components/abnormalSetting.vue')
  },
  props: {
    shiftType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      timeSetUp: {
        width: '110px',
        format: 'HH:mm',
        valueFormat: 'HH:mm',
        placeholder: '时间',
        clearable: true
      },
      shiftVisible: false,
      form: {
        type: 1,
        shiftName: '',
        shiftShortName: '',
        colorThemeCode: '',
        startTime: '', // 上班时点
        endTime: '', // 上班时点
        dayEndTime: '', // 日结束时间
        shiftFlexType: '0', // 弹性方式
        allowLateInMinutes: '10', // 迟到豁免时间
        allowEarlyOutMinutes: '20', // 早退豁免时间
        flexInMaxMinutes: '', // 上班弹性时间
        flexOutMaxMinutes: '', // 下班弹性时间
        flexTimes: '', // 弹性时间个数
        flexTimesStep: '', // 弹性时间间隔分钟数
        shiftType: 'B1',
        punchInStartTime: '', // 上班打卡起始
        punchInEndTime: '', // 上班打卡终止
        punchOutStartTime: '', // 下班打卡起始
        punchOutEndTime: '', // 下班打卡终止
        breakStartTime: '', // 休息起始
        breakEndTime: '', // 休息终止
        punchTimesRuleType: '', // 打卡次数
        checkLateInFlag: '1', // 需要记迟到标记
        checkEarlyOutFlag: '1', // 需要记早退标记
        workMinutes: '',
        shiftPunchRuleList: [{ breakFlag: false }]
        // openLateAbnormalRule: false,
        // openEarlyAbnormalRule: false,
        // abnormalLateRule: [],
        // abnormalEarlyRule: []
      },
      rules: {
        shiftName: [
          { required: true, message: '请输入班次名称', trigger: 'blur' },
          { min: 1, max: 30, message: '请输入1~30个文字', trigger: ['change', 'blur'] },
          { pattern: sepcialCode, message: '班次名称不支持特殊字符', trigger: ['change', 'blur'] }
        ],
        shiftShortName: [
          { required: true, message: '请输入班次简称', trigger: 'blur' },
          { min: 1, max: 4, message: '班次简称仅支持1~4个文字', trigger: ['change', 'blur'] },
          { pattern: sepcialCode, message: '班次简称不支持特殊字符', trigger: ['change', 'blur'] }
        ],
        colorThemeCode: [
          { required: true, message: '请选择班次颜色', trigger: 'change' }
        ],
        shiftType: [
          { required: true, message: '请选择班次类型', trigger: 'change' }
        ]
      }
    }
  },
  mounted() {
  },
  methods: {
    change() {
      // const form = this.form
      // console.log(form.startTime)
      // if (form.startTime && form.endTime && form.restStart && form.restEnd) {
      //   const startTime = this.minutes(form.startTime)
      //   const endTime = this.minutes(form.endTime)
      //   const restStart = this.minutes(form.restStart)
      //   const restEnd = this.minutes(form.restEnd)
      //   this.workHours = ((endTime - startTime - (restEnd - restStart)) / 60).toFixed(1)
      //   return true
      // }
      this.workHours = ''
    },
    minutes(time) {
      if (time) {
        const hour = time.split(':')[0]
        const minute = parseInt(time.split(':')[1])
        return hour * 60 + minute
      }
    },
    typeChange(val) {
      if (['B2'].includes(val)) this.form.shiftPunchRuleList = []
      if (['B1'].includes(val)) this.form.shiftPunchRuleList = [{}]
      this.form.shiftHours = this.form.shiftOvertimeHours = ''
    }
  }
}
</script>

<style lang="less" scoped>
</style>

