<!--
 * @Date: 2022-07-05 16:14:36
 * @descriotion:
-->
<template>
  <div class="content">
    <ul class="header">
      <li title="名称">名称</li>
      <li title="基数">基数</li>
      <li title="险种">险种</li>
      <li title="比例">比例</li>
      <li title="额外固定金额">额外固定金额</li>
      <li title="本月缴纳金额">本月缴纳金额</li>
      <li v-show="showFlag" title="补缴">补缴</li>
    </ul>
    <ul class="table-body">
      <li>{{ detailForm.insureClassifyName }}</li>
      <li>{{ detailForm.paymentBase | stateFormatNum }}</li>
      <li>
        <div
          v-for="(item, index) in detailForm.insureTypeList"
          :key="index"
          class="table-item item"
          :style="`height:${100 / detailForm.insureTypeList.length}%`"
        >
          {{ item.insureName }}
        </div>
      </li>
      <li>
        <div v-for="(item, index) in detailForm.insureTypeList" :key="index">
          <div class="table-item">公司 {{ item.companyScale || '-' }}</div>
          <div class="table-item">个人 {{ item.personScale || '-' }}</div>
        </div>
      </li>
      <li>
        <div v-for="(item, index) in detailForm.insureTypeList" :key="index">
          <div class="table-item">{{ item.companyExtra || '-' | stateFormatNum }}</div>
          <div class="table-item">{{ item.personExtra || '-' | stateFormatNum }}</div>
        </div>
      </li>
      <li>
        <div v-for="(item, index) in detailForm.insureTypeList" :key="index">
          <div class="table-item">{{ item.companyPay || '-' | stateFormatNum }}</div>
          <div class="table-item">{{ item.personPay || '-' | stateFormatNum }}</div>
        </div>
      </li>
      <li v-show="showFlag">
        <div v-for="(item, index) in detailForm.insureTypeList" :key="index">
          <div v-show="!editFlag" class="table-item">{{ item.companySupply || '-' | stateFormatNum }}</div>
          <div v-show="editFlag" class="table-item">
            <CbNumber
              v-model="item.companySupply"
              :min="-9999"
              :decimal-separator="true"
              :precision="2"
              :negative-number="true"
              placeholder=""
              @change="updateCompanySupply"
            />
          </div>
          <div v-show="!editFlag" class="table-item">{{ item.personSupply || '-' | stateFormatNum }}</div>
          <div v-show="editFlag" class="table-item">
            <CbNumber
              v-model="item.personSupply"
              :min="-9999"
              :decimal-separator="true"
              :precision="2"
              :negative-number="true"
              placeholder=""
              @change="updatePersonSupply"
            />
          </div>
        </div>
      </li>
    </ul>
    <div class="table-footer">
      <div class="tableFooter">
        <p>社保本月公司合计：￥{{ Number(detailForm.companyTotal).toFixed(2) || '-' | stateFormatNum }}元</p>
        <p>社保本月个人合计：￥{{ Number(detailForm.personTotal).toFixed(2) || '-' | stateFormatNum }}元</p>
      </div>
    </div>
  </div>
</template>
<script>
import { stateFormatNum } from '@/utils/num-handle.js'
export default {
  name: 'Table',
  filters: {
    stateFormatNum
  },
  components: {
    CbNumber: () => import('@/components/CbNumber/index')
  },
  props: {
    dataForm: {
      type: Object,
      default: () => {}
    },
    editFlag: {
      type: Boolean,
      default: false
    },
    showFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      detailForm: {}
    }
  },
  watch: {
    'dataForm': {
      handler: function(val) {
        this.detailForm = val
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {},
  methods: {
    updateCompanySupply() {
      this.detailForm.companySupply = 0
      let companyTotal = 0 // 本月缴纳金额的和，接口返回的合计是加上调差后的 不能使用，只能自己加
      for (let i = 0; i < this.detailForm.insureTypeList.length; i++) {
        if (!isNaN(this.detailForm.insureTypeList[i].companySupply)) {
          this.detailForm.companySupply += Number(this.detailForm.insureTypeList[i].companySupply)
        }
        if (!isNaN(this.detailForm.insureTypeList[i].companyPay)) {
          companyTotal += Number(this.detailForm.insureTypeList[i].companyPay)
        }
      }
      this.detailForm.companyTotal = companyTotal + Number(this.detailForm.companySupply)
      this.detailForm.companySupply = this.detailForm.companySupply.toFixed(2)
      this.$emit('update:dataForm', this.detailForm)
    },
    updatePersonSupply() {
      this.detailForm.personSupply = 0
      let personTotal = 0
      for (let i = 0; i < this.detailForm.insureTypeList.length; i++) {
        if (!isNaN(this.detailForm.insureTypeList[i].personSupply)) {
          this.detailForm.personSupply += Number(this.detailForm.insureTypeList[i].personSupply)
        }
        if (!isNaN(this.detailForm.insureTypeList[i].personPay)) {
          personTotal += Number(this.detailForm.insureTypeList[i].personPay)
        }
      }
      this.detailForm.personTotal = personTotal + Number(this.detailForm.personSupply)
      this.detailForm.personSupply = this.detailForm.personSupply.toFixed(2)
      this.$emit('update:dataForm', this.detailForm)
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.ant-input {
  width: 80%;
}
ul {
  margin: 0;
}
.content {
  width: 100%;
  height: auto;
  margin-bottom: 16px;
  .header {
    display: flex;
    background: @sc-greyBg-20;
    height: 50px;
    align-items: center;
    li {
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: @sc-grey-100;
      font-size: 16px;
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .table-body {
    display: flex;
    text-align: center;
    li {
      display: flex;
      justify-content: center;
      color: @sc-grey-100;
    }
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    li:nth-child(1) {
      border-bottom: 1px solid @sc-greyBg-50;
    }
    li:nth-child(2) {
      border-bottom: 1px solid @sc-greyBg-50;
    }
    .table-item {
      width: 100%;
      height: 50px;
    }
  }
  li {
    width: calc(100% / 6);
    flex-direction: column;
    line-height: 50px;
    border-right: 1px solid @sc-greyBg-50;
    border-top: 1px solid @sc-greyBg-50;
    border-collapse: collapse;
    .table-item {
      border-bottom: 1px solid @sc-greyBg-50;
    }
  }
  li:nth-child(1) {
    border-left: 1px solid @sc-greyBg-50;
  }
  .table-footer {
    border: 1px solid @sc-greyBg-50;
    border-top: none;
    .tableFooter {
      width: 100%;
      min-height: 40px;
      border-top: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .font-size(14px);
      p {
        width: 50%;
        text-align: left;
        margin: 0;
        line-height: 28px;
        color: @sc-grey-100;
      }
    }
  }
}
</style>
