<!--
 * @Date: 2022-04-26 10:56:20
 * @descriotion:
-->
<template>
  <div class="user-setting-panel">
    <layout
      ref="layoutMenu"
      :menu-data-list="menuDataList"
      class="left-menu-panel"
      @menu-select-change="menuSelectChange"
    >
      <template #leftTop>
        <div class="left-top-p" />
      </template>
      <component :is="curComponentName" />
    </layout>
  </div>
</template>

<script>
import layout from '@/components/CbLayoutMenu/index'
import common from './common'
export default {
  name: 'Setting',
  components: {
    layout,
    ...common
  },
  data() {
    return {
      curComponentName: '',
      menuDataList: [
        { id: '1',
          name: '考勤',
          frontIcon: '',
          fileName: '',
          disabled: true,
          actions: [],
          children: [
            {
              id: '1-1',
              name: '考勤组',
              frontIcon: '#three-hexagons',
              fileName: 'attendanceGroup',
              funcCode: 'attendanceGroup',
              actions: []
            },
            {
              id: '1-2',
              name: '排班日历',
              frontIcon: '#calendar-thirty-two',
              fileName: 'schedulingCalendar',
              funcCode: 'shiftScheduling',
              actions: []
            },
            {
              id: '1-3',
              name: '班次',
              frontIcon: '#preschool',
              fileName: 'shiftManagement',
              funcCode: 'attendanceShift',
              actions: []
            },
            {
              id: '1-4',
              name: '规则',
              frontIcon: '#agreement-5m8imnn8',
              fileName: 'rules',
              funcCode: 'attendanceRule',
              actions: []
            },
            {
              id: '1-5',
              name: '考勤项',
              frontIcon: '#guize',
              fileName: 'attendanceitems',
              funcCode: 'attendanceItem',
              actions: []
            },
            {
              id: '1-6',
              name: '打卡方式',
              frontIcon: '#plan',
              fileName: 'methodPunch',
              funcCode: 'clockType',
              actions: []
            },
            {
              id: '1-7',
              name: '统计周期',
              frontIcon: '#radar-two-5m8jml68',
              fileName: 'statisticalPeriod',
              funcCode: 'statisticalPeriod',
              actions: []
            },
            {
              id: '1-8',
              name: '考勤提醒',
              frontIcon: '#remind-5m8k28bp',
              fileName: 'attendanceReminders',
              funcCode: 'attendanceReminders',
              actions: []
            }
          ]
        },
        {
          id: '2',
          name: '假期',
          frontIcon: '',
          fileName: '',
          disabled: true,
          actions: [],
          children: [
            {
              id: '2-1',
              name: '假期方案',
              frontIcon: '#fangan',
              fileName: 'vacationPlan',
              funcCode: 'vacationRule',
              actions: []
            },
            {
              id: '2-2',
              name: '假期类型',
              frontIcon: '#application-effect',
              fileName: 'holidayType',
              funcCode: 'vacationType',
              actions: []
            },
            {
              id: '2-3',
              name: '法定节假',
              frontIcon: '#landscape',
              fileName: 'legalHoliday',
              funcCode: 'legalHoliday',
              actions: []
            },
            {
              id: '2-4',
              name: '假期提醒',
              frontIcon: '#message-unread',
              fileName: 'holidayReminder',
              funcCode: 'vacationRemind',
              actions: []
            }
          ]
        }
      ]
    }
  },
  mounted() {
    this.$refs.layoutMenu.showInit()
  },
  methods: {
    menuSelectChange(item) {
      this.curComponentName = item.fileName
    }
  }
}
</script>

<style lang="less" scoped>
.user-setting-panel {
  height: 100%;
  .left-top-p {
    margin-top: 20px;
  }
}
.left-menu-panel{
  // background-color: @base-bg-color
}
</style>
