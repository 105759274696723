<template>
  <div class="container">
    <Modal :visible="visible" type="info" closable title="立即升级" size="small" @cancel="visible=false">
      <p>收到您的升级申请后，专属客服会第一时间致电于您，请放心接听。您也可拨打24小时客服电话：400-000-000。</p>
      <template slot="footer">
        <a-button type="default" @click="visible=false">取消</a-button>
        <a-button type="primary" @click="hideModal">发送请求</a-button>
      </template>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'UpgradeAlert',
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      active: false,
      visible: false,
      value: '',
      modalImg: require('@/assets/img/global/info-circle-filled.png')
    }
  },
  methods: {
    open(val) {
      this.value = val.val
      this.visible = true
    },
    hideModal() {
      this.visible = false
      this.active = true
      this.$emit('changeActive', this.active, this.value)
    }
  }
}
</script>
<style scoped lang="less">
p {
  color: @sc-grey-80;
  margin-bottom: 0;
}
</style>
