<template>
  <div>
    <!--  添加 搜索 筛选  -->
    <appForm
      ref="appForm"
      :to-list="toList"
      :screen-roster-list="screenRosterList"
      width="400"
      @getSearchCallback="getSearchCallback"
      @screenRosterParams="screenRosterParams"
      @exportFileList="exportFileList"
    />
    <Alert v-if="keyval === '2'" :show-icon="true" :banner="true">
      <template #content>
        <div>提示：最近转正列表仅显示近90天内已确认转正员工</div>
      </template>
    </Alert>
    <custom-table
      :columns="columns"
      table-ref=".main-content"
      :tabs-height="36"
      :data-source="dataSource"
      row-key="onJobId"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="loadDataList"
    >
      <!-- 序号 -->
      <template slot="index" slot-scope="{ scope }">
        <span>{{ scope.index + 1 }}</span>
      </template>
      <!-- 姓名头像 -->
      <template slot="name" slot-scope="{ scope }">
        <CbTableAvater :table-avater="scope.record.avatar" :on-job-id="scope.record.onJobId" :staff-id="scope.record.staffId" :table-name="scope.record.name" />
      </template>
      <!-- 招聘形式 -->
      <template slot="engageMode" slot-scope="{ scope }">
        <span>{{ scope.engageMode === 0 ? '正式' : '非正式' }}</span>
      </template>
      <!-- 审批状态 -->
      <template slot="applyStatus" slot-scope="{ scope }">
        <span>
          <StatusTag
            v-if="scope.record.applyStatus"
            :type="scope.record.applyStatus"
            :type-list="applyStatusList"
            @click="getCbApproveSteps(scope.record.flowId, scope.record.applyStatus)"
          />
        </span>
      </template>
      <!-- 操作列 -->
      <template v-if="keyval === '1'" slot="operation" slot-scope="{ scope }">
        <div v-if="scope.record.applyStatus === 2">不予转正</div>
        <div v-else class="different">
          <a-button type="link" @click="handleConfirm(scope)"> 办理转正 </a-button>
          <a-popover placement="bottom" trigger="hover">
            <template slot="content">
              <div class="more-handle-btn">
                <a-button type="link" @click="trimTime(scope)"> 调整转正日期 </a-button>
                <a-button type="link" @click="handleGiveUp(scope)"> 不予转正 </a-button>
              </div>
            </template>
            <div class="more-handle">···</div>
          </a-popover>
        </div>
      </template>
    </custom-table>
    <!-- 办理转正 -->
    <confirmForm ref="confirmForm" @getFetchDataApiFn="getFetchDataApiFn" />
    <!-- 调整入职日期 -->
    <trimTimeForm ref="trimTimeForm" @getFetchDataApiFn="getFetchDataApiFn" />
    <!-- 不予转正 -->
    <giveUpForm ref="giveUpForm" @getFetchDataApiFn="getFetchDataApiFn" />
    <!-- 审批进度条 -->
    <CbApproveSteps v-model="stepsVisible" :flow-id="flowId" :apply-status="applyStatus" :apply-status-list="applyStatusList" />
  </div>
</template>

<script>
import moment from 'moment'
import mixins from '@/components/CustomTable/index.js'
import { probationList, recentlyOfficialList, probationListExport, recentlyOfficialLIstExport } from '@/services/user/worker.js'
import CbTableAvater from '@/components/CbTableAvater/index'

export default {
  name: 'InductionTabel',
  components: {
    Alert: () => import('@/components/CbAlert/index'),
    confirmForm: () => import('./form/confirmForm.vue'),
    // eslint-disable-next-line vue/no-unused-components
    trimTimeForm: () => import('./form/trimTimeForm.vue'),
    // eslint-disable-next-line vue/no-unused-components
    giveUpForm: () => import('./form/giveUpForm.vue'),
    CbApproveSteps: () => import('@/components/CbApproveSteps/index'),
    CbTableAvater,
    StatusTag: () => import('@/components/CbStatusTag')
  },
  mixins: [mixins],
  props: {
    keyval: {
      type: String,
      default: ''
    },
    screenRosterListData: {
      type: Object,
      default: () => {}
    },
    isFirst: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      lazyInitData: true,
      applyStatusList: {
        '1': { name: '审批中' },
        '2': { name: '已通过' },
        '3': { name: '驳回' }
      },
      total: 0,
      toList: ['search', 'screen', 'export'], // search搜索框 screen筛选 choice位置选择弹窗 export导出
      columns: [],
      probationList: [
        {
          title: '序号',
          dataIndex: 'index',
          width: '50px',
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '姓名',
          dataIndex: 'name',
          width: 105,
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          key: 'phoneNum',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          ellipsis: true
        },
        {
          title: '职务',
          dataIndex: 'dutyName',
          key: 'dutyName',
          ellipsis: true
        },
        {
          title: '聘用形式',
          dataIndex: 'engageModeName',
          key: 'engageModeName'
        },
        {
          title: '入职时间',
          dataIndex: 'entryDate',
          key: 'entryDate'
        },
        {
          title: '试用期',
          dataIndex: 'probation',
          key: 'probation',
          sorter: true
        },
        {
          title: '计划转正日期',
          dataIndex: 'planFormalDate',
          key: 'planFormalDate',
          width: 140,
          sorter: true
        },
        {
          title: '审批状态',
          dataIndex: 'applyStatus',
          scopedSlots: { customRender: 'applyStatus' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      recentlyList: [
        {
          title: '序号',
          dataIndex: 'index',
          width: '50px',
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '姓名',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          width: 105,
          sorter: true
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          key: 'phoneNum',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true,
          sorter: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          ellipsis: true
        },
        {
          title: '职务',
          dataIndex: 'dutyName',
          key: 'dutyName',
          ellipsis: true
        },
        {
          title: '聘用形式',
          dataIndex: 'engageModeName',
          key: 'engageModeName'
        },
        {
          title: '入职时间',
          dataIndex: 'entryDate',
          key: 'entryDate'
        },
        {
          title: '转正日期',
          dataIndex: 'formalDate',
          key: 'formalDate',
          sorter: true
        },
        {
          title: '司龄',
          dataIndex: 'companyAge',
          key: 'companyAge'
        }
      ],
      dataSource: [], // 数据
      selectionMode: true,

      stepsVisible: false,
      flowId: '',
      applyStatus: ''
    }
  },
  computed: {
    screenRosterList() {
      const query = this.$route.params.isHome
      const date = [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).add(10, 'days').format('YYYY-MM-DD')]
      return {
        'list': [
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'engage_mode',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'employment',
            'key': 'selectDictionaries_1651022813841',
            'hasErrorValid': false
          },
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'depMent',
            'key': 'selectDepartment_1651022903831'
          },
          {
            'type': 'selectPost',
            'label': '岗位',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'selectPost',
            'key': 'selectPost_1651022948111'
          },
          {
            'type': 'selectDataRange',
            'label': '入职日期',
            'options': {
              'width': '100%',
              'defaultValue': [undefined, undefined],
              'rangePlaceholder': ['开始时间', '结束时间'],
              'range': false,
              'showTime': false,
              'disabled': false,
              'hidden': false,
              'clearable': true,
              'placeholder': '请选择结束时间',
              'format': 'YYYY-MM-DD'
            },
            'model': 'endEntryDate',
            'key': 'date_1651214034719',
            'help': ''
          },
          {
            'type': 'selectDataRange',
            'label': '转正日期',
            'options': {
              'width': '100%',
              'defaultValue': query === 'regular' && this.keyval === '1' && this.isFirst ? date : [undefined, undefined],
              'rangePlaceholder': ['开始时间', '结束时间'],
              'range': false,
              'showTime': false,
              'disabled': false,
              'hidden': false,
              'clearable': true,
              'placeholder': '请选择结束时间',
              'format': 'YYYY-MM-DD'
            },
            'model': 'endFormalDate',
            'key': 'date_1651214034712',
            'help': ''
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      }
    }

  },
  watch: {
    keyval: {
      handler(val) {
        this.getFetchDataApiFn()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.params.isHome === 'regular' && this.keyval === '1' && this.isFirst) {
        this._queryParam.startFormalDate = moment(new Date()).format('YYYY-MM-DD')
        this._queryParam.endFormalDate = moment(new Date()).add(10, 'days').format('YYYY-MM-DD')
        this.fetchDataApiFn(this._queryParam)
        this._queryParam.startFormalDate = undefined
        this._queryParam.endFormalDate = undefined
      } else {
        this.fetchDataApiFn(this._queryParam)
      }
    })
  },
  methods: {
    // 获取表格数据
    async fetchDataApiFn(param) {
      if (this.keyval === '1') {
        // 试用期
        this.columns = this.probationList
        const res = await probationList(param)
        this.dataSource = res.data.records ? res.data.records : []
        this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
        this.total = res.data.total
      } else if (this.keyval === '2') {
        // 最近入职
        this.columns = this.recentlyList
        const res = await recentlyOfficialList(param)
        this.dataSource = res.data.records ? res.data.records : []
        this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
        this.total = res.data.total
      }
    },
    // 办理转正
    handleConfirm(val) {
      this.$refs.confirmForm.open(val)
    },
    // 调整转正日期
    trimTime(val) {
      this.$refs.trimTimeForm.open(val)
    },
    // 不予转正
    handleGiveUp(val) {
      this.$refs.giveUpForm.open(val)
    },
    // 搜索
    getSearchCallback(val) {
      this._queryParam.name = val.searchValue
      this.serchGetData()
    },
    // 操作回调 更新列表保存当前页
    getFetchDataApiFn(val) {
      this.fetchDataApiFn(this._queryParam)
      // this.serchGetData()
    },
    // 筛选
    screenRosterParams(data) {
      const { employment, depMent, selectPost, endEntryDate, endFormalDate } = data.screenCondition
      this._queryParam = {
        name: data.searchValue,
        engageMode: employment,
        orgId: depMent ? depMent[0].value : '',
        postId: selectPost,
        startEntryDate: endEntryDate ? endEntryDate[0] : '',
        endEntryDate: endEntryDate ? endEntryDate[1] : '',
        startFormalDate: endFormalDate ? endFormalDate[0] : '',
        endFormalDate: endFormalDate ? endFormalDate[1] : ''
      }
      this.serchGetData()
    },
    // 审批步骤条
    getCbApproveSteps(data, val) {
      this.flowId = data
      this.applyStatus = val
      this.stepsVisible = true
    },
    // 导出
    exportFileList() {
      const { name, engageMode, orgId, postId, startEntryDate, endEntryDate, startFormaldate, endFormaldate } = this._queryParam
      const params = {
        name: name,
        engageMode: engageMode,
        orgId: orgId,
        postId: postId,
        startEntryDate: startEntryDate,
        endEntryDate: endEntryDate,
        startFormaldate: startFormaldate,
        endFormaldate: endFormaldate
      }
      if (this.keyval === '1') {
        probationListExport(params)
      } else {
        recentlyOfficialLIstExport(params)
      }
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .ant-alert{
  margin-bottom: 14px;
  border-radius: 4px;
}
// /deep/ .anticon-exclamation-circle {
//   top: 11px !important;
// }
.more-handle-btn {
  display: flex;
  flex-direction: column;
}
.different{
  display: flex;
}
.more-handle {
  cursor: pointer;
  font-weight: 700;
  color: @sc-primary-100;
  margin-left: 16px;
  font-size: 20px;
}
</style>
