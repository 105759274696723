<template>
  <div>
    <CbNumStatistics :statistics-info="statisticsInfo" :is-tabs="true" class="statistics" @changeTab="changeTab" />
    <!--  添加 搜索 筛选  -->
    <appForm
      :to-list="toList"
      :screen-roster-list="screenRosterList"
      width="400"
      search-placeholder="请输入员工姓名/工号"
      @getSearchCallback="getSearchCallback"
      @screenRosterParams="screenRosterParams"
      @exportFileList="exportFileList"
    >
      <template slot="customButton">
        <a-space>
          <a-button v-if="['0', '2'].includes(selectType)" prefix="piliangdaoru" class="a-button" @click="batchSalaryBtn">
            批量定薪
          </a-button>
          <a-button v-if="['1', '2'].includes(selectType)" prefix="piliangdaoru" class="a-button" @click="SalaryadjustmentBtn">
            批量调薪
          </a-button>
          <a-button v-if="['2'].includes(selectType)" prefix="piliangdaoru" class="a-button" @click="addFixedItem">
            添加薪资固定项
          </a-button>
        </a-space>
      </template>
    </appForm>
    <custom-table
      class="table-height-box"
      :columns="columns"
      :data-source="dataSource"
      :tabs-height="33"
      :row-key="'onJobId'"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true,
      }"
      @change="loadDataList"
    >
      <template slot="staffName" slot-scope="{ scope }">
        <CbTableAvater
          :on-job-id="scope.record.onJobId"
          :staff-id="scope.record.staffId"
          :table-name="scope.record.staffName"
        />
      </template>
      <!-- 操作列 isAdjustment 1调薪 0定薪-->
      <template slot="operation" slot-scope="{ scope }">
        <a-button v-if="scope.record.isAdjustment === 1" type="link" @click="changeWage(scope.record)">调薪</a-button>
        <a-button v-if="scope.record.lastSetSalaryDate" type="link" @click="changeWageHistory(scope.record)">调薪历史</a-button>
        <a-button v-if="scope.record.isAdjustment === 0" type="link" @click="fixedWage(scope.record)">定薪</a-button>
      </template>
    </custom-table>
    <!-- 薪资项字段弹框 -->
    <AddWageFIxed ref="AddWageFIxed" @complate="serchGetData" />
    <!-- 调薪 -->
    <ChangeWage
      ref="ChangeWage"
      :info="selectTableItemInfo"
      @complate="updateList"
    />
    <SalaryHistory
      ref="SalaryHistory"
      :on-job-id="selectTableItemInfo.onJobId"
      :formal-date="selectTableItemInfo.formalDate"
    />
    <!-- 定薪 -->
    <FixedWage ref="FixedWage" :info="selectTableItemInfo" @complate="updateList" />
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import {
  getStaffFixedSalaryStatistics,
  staffFixedSalaryList,
  exportStaffFixedList
} from '@/services/wage/wageArchives.js'
import CbNumStatistics from '@/components/CbNumStatistics/index'
export default {
  name: 'ArchivesTabel',
  components: {
    CbNumStatistics,
    CbTableAvater: () => import('@/components/CbTableAvater/index'),
    AddWageFIxed: () => import('./components/addWageFIxed'),
    ChangeWage: () => import('./components/changeWage'),
    FixedWage: () => import('./components/fixedWage'),
    SalaryHistory: () => import('./components/salaryHistory')
  },
  mixins: [mixins],
  props: {
    keyval: {
      type: String,
      default: ''
    },
    screenRosterListData: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      statisticsInfo: {}, // 统计
      time: '',
      total: 0,
      selectType: '2',
      // 定薪或者调薪的时候选择的表格项的数据
      selectTableItemInfo: {},
      toList: ['search', 'screen', 'export'], // search搜索框 screen筛选 choice位置选择弹窗 export导出
      indexList: {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        width: 50,
        fixed: true,
        customRender: (text, record, index) => `${index + 1}`
      },
      selectDataList: [
        {
          type: '2',
          label: '全部',
          value: 0,
          selected: true
        },
        {
          type: '0',
          label: '待定薪',
          value: 0
        }
        // {
        //   type: '1',
        //   title: '待调薪',
        //   num: 0
        // }
      ],
      columns: [],
      dataSource: [], // 数据
      screenRosterList: {
        list: [
          {
            type: 'selectDictionaries',
            label: '聘用形式',
            options: {
              width: '100%',
              parameter: 'engage_mode',
              clearable: true,
              placeholder: '请选择职级'
            },
            model: 'engageMode',
            key: 'selectDictionaries_1651022813841'
          },
          {
            type: 'selectDepartment',
            label: '部门',
            options: {
              width: '100%',
              clearable: true,
              placeholder: '请选择'
            },
            model: 'orgId',
            key: 'selectDepartment_1651022903831'
          },
          {
            type: 'selectPost',
            label: '岗位',
            options: {
              width: '100%',
              clearable: true,
              placeholder: '请选择'
            },
            model: 'postId',
            key: 'selectPost_1651022948111'
          },
          {
            'type': 'selectDictionaries',
            'label': '合同公司',
            'options': {
              'width': '100%',
              'parameter': 'contract_company',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'contractCompany',
            'key': 'selectDictionaries_1650962628706'
          },
          {
            'type': 'selectDictionaries',
            'label': '工作地点',
            'options': {
              'width': '100%',
              'parameter': 'work_address',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'workAddress',
            'key': 'selectDictionaries_1650962628701'
          },
          {
            'type': 'selectDictionaries',
            'label': '员工状态',
            'options': {
              'width': '100%',
              'parameter': 'staff_status',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'staffStatus',
            'key': 'selectDictionaries_1650962628702'
          }
        ],
        config: {
          layout: 'horizontal',
          labelCol: {
            xs: 4,
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
            xxl: 4
          },
          labelWidth: 100,
          labelLayout: 'flex',
          wrapperCol: {
            xs: 18,
            sm: 18,
            md: 18,
            lg: 18,
            xl: 18,
            xxl: 18
          },
          hideRequiredMark: false,
          customStyle: ''
        }
      }
    }
  },
  computed: {
    // 表头字段名和对应展示名称的对应关系，定薪调薪要用
    columnsNameList() {
      const obj = {}
      this.columns.forEach(item => {
        obj[item.dataIndex] = item.title
      })
      return obj
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    changeTab(val) {
      this.initData()
      this.adjustments(val.type)
    },
    // 获取表格数据
    async fetchDataApiFn(param) {
      // 薪资档案
      param['type'] = this.selectType
      const res = await staffFixedSalaryList(param)
      const columnObj = {
        'staffName': '105px',
        'staffNum': '120px',
        'orgName': '120px'
      }
      this.columns = res.data.columns.map((item, index) => {
        return {
          title: item.fieldName,
          dataIndex: item.fieldCode,
          width: columnObj[item.fieldCode]
            ? columnObj[item.fieldCode] : `${item.fieldName.length * 30 + 20}px`,
          // width: `${item.fieldName.length * 30 + 20}px`,
          fixed: index < 3 ? 'left' : false,
          ellipsis: true,
          scopedSlots: item.fieldCode === 'staffName' ? { customRender: item.fieldCode } : '',
          dataType: item.dataType
        }
      })
      this.columns.unshift(this.indexList)
      this.columns.push({
        title: '操作',
        dataIndex: 'operation',
        width: param.type === '2' ? 120 : 120,
        fixed: 'right',
        scopedSlots: { customRender: 'operation' }
      })
      this.columns.push({
        title: '',
        dataIndex: new Date().getTime()
      })
      this.dataSource = res.data.content ? res.data.content.records : []
      this.pager.currentPage = res.data.content.pageNum || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.content.pageSize || 10 // 更新当前的分页信息
      this.total = res.data.content.total
    },
    // 批量调薪
    SalaryadjustmentBtn() {
      this.$emit('change', 'batchUpdateFixedSalary')
    },
    // 批量定薪
    batchSalaryBtn() {
      this.$emit('change', 'batchSetFixedSalary')
    },
    // 搜索
    getSearchCallback(val) {
      this._queryParam.keyWord = val.searchValue
      this.serchGetData()
    },
    // 筛选
    screenRosterParams(data) {
      const { engageMode, orgId, postId, contractCompany, workAddress, staffStatus } = data.screenCondition
      this._queryParam = {
        keyWord: data.searchValue,
        engageMode,
        orgId: orgId && orgId[0] && orgId[0].value,
        postId,
        contractCompany,
        workAddress,
        staffStatus
      }
      this.serchGetData()
    },
    updateList() {
      this.initData()
      this.$$fetchListWithLoading(this._queryParam)
    },
    async initData() {
      const res = await getStaffFixedSalaryStatistics()
      const keyList = [
        'allCount',
        'toBeFixedSalaryCount',
        'toBeAdjustSalaryCount'
      ]
      this.selectDataList.forEach((item, index) => {
        item.value = res.data[keyList[index]] || 0
      })
      this.statisticsInfo = {
        tabList: this.selectDataList
      }
    },
    adjustments(type) {
      this.selectType = type
      this.pager.pageNum = 1
      this.serchGetData()
    },
    addFixedItem() {
      this.$refs.AddWageFIxed.show()
    },
    // 调薪
    changeWage(info) {
      this.formateData(info)
      this.$refs.ChangeWage.show()
    },
    // 调薪历史
    changeWageHistory(info) {
      this.selectTableItemInfo.onJobId = info.onJobId
      this.selectTableItemInfo.formalDate = info.formalDate
      this.$refs.SalaryHistory.show(info.onJobId)
    },
    // 定薪
    fixedWage(info) {
      this.formateData(info)
      this.$refs.FixedWage.show()
    },
    // 格式化表格数据成可使用的数据
    formateData(info) {
      this.$set(this, 'selectTableItemInfo', info)
      this.$set(this.selectTableItemInfo, 'list', [])
      Object.entries(this.columnsNameList).forEach(item => {
        const key = item[0]
        let copyKey = item[0]
        const val = info[key]
        // 排除非固定字段和试用期的字段
        if (this.generateRule(key) && key.indexOf('syq') === -1) {
          // 试用期 gd zdy syq【兼容后台】别删
          if (key.indexOf('zdy') !== -1) {
            copyKey = key.replaceAll('zdy', 'ZDY')
          }
          if (key.indexOf('gd') !== -1) {
            copyKey = key.replaceAll('gd', 'GD')
          }
          this.selectTableItemInfo.list.push({
            probation: {
              name: this.columnsNameList[`syq${key}`],
              key: `SYQ${copyKey}`,
              val: info[`syq${key}`]
            },
            // 正式
            formal: {
              name: this.columnsNameList[key],
              key: copyKey,
              val
            }
          })
        }
      })
    },
    // 默认内置的基本工资项和自定义添加的固定工资项的生成规则
    generateRule(key) {
      // 默认内置的固定项工资
      if (key.indexOf('gd') > -1 || key.indexOf('syqgd') > -1) return true
      if (key.indexOf('zdy') > -1 || key.indexOf('syqzdy') > -1) return true
      return false
    },
    async exportFileList() {
      const params = JSON.parse(JSON.stringify(this._queryParam))
      params.pageNum = 1
      params.pageSize = -1
      await exportStaffFixedList(params)
    }
  }
}
</script>

<style lang="less" scoped>
.a-button {
  margin-right: 12px;
}
/deep/.ant-input-search {
  width: 239px !important;
  background: rgba(143, 147, 161, 0.1);
}
/deep/.ant-space-item{
  margin: 0 !important;
}
/deep/.ant-btn.link:first-child{
  margin-right: 16px;
}
/deep/.content-list li{
  position: relative;
  &:first-child::after {
    content: '';
    position: absolute;
    right: 0;
    height: 30px;
    width: 1px;
    background-color: @sc-greyBg-20;
    top: 50%;
    transform: translateY(-50%);
  }
}
.statistics{
  margin-bottom: 20px;
}
/deep/.table-height-box .ant-table-tbody  tr  td{
  padding: 0 8px;
  line-height: 55px;
}
</style>
