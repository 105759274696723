var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"salary-particulars"},[_c('HeaderBack',{attrs:{"title":((_vm.moment(_vm.currentTime).format('YYYY年MM月')) + "工资条")},on:{"back":_vm.goBack}}),_c('CbNumStatistics',{staticClass:"Statistics",attrs:{"is-tabs":true,"statistics-info":_vm.statisticsInfo},on:{"changeTab":_vm.changeTab}}),_c('div',{staticClass:"v-line-button"},[_c('a-space',[_c('a-button',{attrs:{"prefix":"send-one"},on:{"click":_vm.sendImmediatelyStaffPayroll}},[_vm._v(" 立即发送 ")]),_c('a-button',{attrs:{"prefix":"dingshifasong"},on:{"click":function($event){_vm.timedSendShow = true}}},[_vm._v("定时发送 ")]),_c('a-button',{attrs:{"prefix":"quanyuanchehui"},on:{"click":_vm.withdrawAllStaffPayroll}},[_vm._v("全部撤回 ")])],1)],1),_c('custom-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"row-key":'staffyPayrollId',"pagination":{
      current: _vm.pager.pageNum,
      pageSize: _vm.pager.pageSize,
      total: _vm.total,
      showTotal: function (total) { return ("共 " + total + " 条数据"); },
      showQuickJumper: true,
      showSizeChanger: true
    }},on:{"change":_vm.loadDataList},scopedSlots:_vm._u([{key:"index",fn:function(ref){
    var scope = ref.scope;
return [_c('span',[_vm._v(_vm._s(scope.index + 1))])]}},{key:"staffName",fn:function(ref){
    var scope = ref.scope;
return [_c('CbTableAvater',{attrs:{"staff-id":scope.record.staffId,"on-job-id":scope.record.onJobId,"table-avater":scope.record.avatar,"table-name":scope.record.staffName}})]}},{key:"sendState",fn:function(ref){
    var scope = ref.scope;
return [_vm._v(" "+_vm._s(scope.record.sendState? '已发送':'未发送')+" ")]}},{key:"confirmState",fn:function(ref){
    var scope = ref.scope;
return [_vm._v(" "+_vm._s(scope.record.confirmState? '已确认':'未确认')+" ")]}},{key:"viewState",fn:function(ref){
    var scope = ref.scope;
return [_vm._v(" "+_vm._s(scope.record.viewState? '已查看':'未查看')+" ")]}},{key:"operation",fn:function(ref){
    var scope = ref.scope;
return [_c('div',{staticClass:"more-handle-btn",staticStyle:{"display":"flex"}},[(scope.record.sendState === 0)?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.sendStaffPayroll(scope.record)}}},[_vm._v(" 发送")]):_vm._e(),(scope.record.sendState === 1)?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.withdrawStaffPayroll(scope.record)}}},[_vm._v(" 撤回 ")]):_vm._e()],1)]}}])}),_c('Modal',{staticClass:"modal-comp",attrs:{"visible":_vm.timedSendShow,"size":'normal',"title":'定时发送',"closable":true,"ok-text":'确认'},on:{"ok":_vm.timedSend,"cancel":function($event){_vm.timedSendShow=false}}},[_c('div',{staticClass:"send-block"},[_c('div',{staticClass:"send-label"},[_vm._v(" 发送时间 ")]),_c('a-date-picker',{attrs:{"format":"YYYY-MM-DD HH:mm:ss","show-time":{ defaultValue: _vm.moment('00:00:00', 'HH:mm:ss') }},on:{"change":_vm.sendTimeChange}})],1),_c('div',{staticClass:"tip-text"},[_vm._v(" 注：定时发送工资条仅支持全员发送 ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }