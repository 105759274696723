<template>
  <div class="HolidayReminder">
    <AlertTips>
      <template slot="content">
        <p>1.员工关注公众号并绑定小程序，当余额变动可接收微信提醒通知。</p>
      </template>
    </AlertTips>
    <BlockTitle title="假期提醒" :is-bold="true" class="msg-title" />
    <a-table :columns="columns" :data-source="dataSource" row-key="tenantConfigId" :pagination="false">
      <span slot="reminders">
        <span>余额变动</span>
      </span>
      <span slot="reminderDescription">
        <span>当假期余额更新，即发送提醒。包括发放、修改、扣减余额</span>
      </span>
      <span slot="configContent" slot-scope="text,record">
        <a-switch :default-checked="Boolean(JSON.parse(record.configContent))" size="small" @change="onChange(record.configContent,record.configItemName)" />
      </span>
    </a-table>
  </div>
</template>

<script>
import { getTenantConfigList, updateTenantConfig } from '@/services/attendance/holidays.js'
import mixins from '@/components/CustomTable/index.js'
export default {
  name: 'HolidayReminder',
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index'),
    AlertTips: () => import('../../components/alert.vue')
  },
  mixins: [mixins],
  data() {
    return {
      columns: [{
        title: '提醒事项',
        dataIndex: 'reminders',
        key: 'reminders',
        scopedSlots: { customRender: 'reminders' },
        width: '200px'
      },
      {
        title: '提醒说明',
        dataIndex: 'reminderDescription',
        key: 'reminderDescription',
        scopedSlots: { customRender: 'reminderDescription' },
        width: '400px'
      },
      {
        title: '是否提醒',
        dataIndex: 'configContent',
        scopedSlots: { customRender: 'configContent' },
        key: 'configContent',
        width: '150px',
        fixed: 'right'
      }
      ],
      dataSource: [],
      loading: false
    }
  },
  methods: {
    // 请求数据
    async fetchDataApiFn(_queryParam) {
      const res = await getTenantConfigList({ ..._queryParam, itemNameList: ['holiday_balance'] })
      if (res.code === 100) {
        this.dataSource = res.data
      }
    },
    async onChange(configContent, configItemName) {
      const res = await updateTenantConfig({ configContent: !JSON.parse(configContent), configItemName: configItemName })
      if (res.code === 100) {
        this.$message.success('状态改变成功！')
        this.fetchDataApiFn()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.HolidayReminder{
  height: 100%;
  background-color: #fff;
  padding:20px 20px;
  .msg-title {
      margin-bottom: 16px;
    }
}
/deep/ .ant-switch-small {
  margin-left: 15px;
}
</style>
