<template>
  <div v-if="dragValue" class="ExcelImport">
    <HeaderBack :title="title" @back="toggleValue" />
    <div class="ExcelImport-content">
      <div class="importType">
        <p>匹配方式：</p>
        <a-radio-group v-model="matchField" name="radioGroup">
          <a-radio v-for="item in import_matchField_type" :key="item.value" :value="item.value">{{ item.label }}</a-radio>
        </a-radio-group>
      </div>
      <!-- <div v-if="importTemplate === 'append_punch'" class="importType">
        <p>导入月份：</p>
        <a-month-picker placeholder="选择导入月份" :disabled-date="disabledDate" @change="onChange" />
      </div> -->
      <BatchImport
        ref="batchimport"
        :show-update-list="true"
        :multiple="false"
        :file-list="fileList"
        :file-size="20"
        :data-list="dataList"
        :match-field="matchField"
        :import-template="importTemplate"
      />
    </div>
    <a-row class="ExcelImport-bottom" type="flex" justify="center">
      <!-- <a-button type="primary">确定上传</a-button> -->
    </a-row>
  </div>
</template>

<script>
import { import_matchField_type } from '../dict'
import moment from 'moment'
export default {
  name: 'ExcelImport',
  components: {
    HeaderBack: () => import('@/components/headerBack'),
    BatchImport: () => import('@/components/CbBatchImport/index')
  },
  props: {
    title: {
      type: String,
      default: '',
      required: true
    },
    importTemplate: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dragValue: true,
      dataType: {
        sta: null,
        message: '请先导入符合规则的文件'
      },
      fileList: [],
      dataList: [],
      matchField: 'staffName',
      import_matchField_type: import_matchField_type
    }
  },
  methods: {
    toggleValue() {
      this.dragValue = false
      this.$emit('toggleValue', this.dragValue)
    },
    disabledDate(current) {
      return current && current > moment().endOf('day')
    },
    onChange() {

    }
  }
}
</script>

<style lang="less" scoped>
.importType{
  background-color: @gray-4;
  border-radius: 4px;
  padding: 10px 20px;
  margin-bottom: 20px;
  p{
    margin: 0;
    padding: 0;
    padding-bottom: 5px;
    color: @gray-10;
    span{
      color: @gray-8;
      margin-left: 10px;
    }
  }
}
  .ExcelImport{
    height: 100%;
    background-color: @base-bg-color;
    &-content{
      font-size: 14px;
      .rule-tip{
        height: 40px;
        padding:10px;
        background: @sc-primary-10;
      }
    }
    &-bottom{
      position: fixed;
      left: 53%;
      bottom:20px;
    }
}
/deep/ .ant-btn {
  margin-left: 0;
}
/deep/.ant-btn-link{
    &:nth-child(2){
      padding: 0 15px;
    }
}
// /deep/ .header {
//   margin-top: -3px;
// }
/deep/.confirm_btn{
  bottom: 0;
  background: @base-bg-color;
  height: 50px;
}
</style>
