<!--
 * @Date: 2022-05-18 14:23:18
 * @descriotion:
-->
<template>
  <div v-show="tipsShow" class="model">
    <div class="title">
      <div class="open" :class="show?'show':'hide'" @click="look">
        <svg class="iconpark-icon"><use href="#send-one" /></svg>
        <span>使用指南</span>
      </div>
      <span v-show="show" class="away" @click="look">收起</span>
      <!-- <span v-show="show" class="noremind" @click="noremind">不再提醒</span> -->
    </div>
    <transition>
      <div v-show="show" :class="show?'contentShow':'contentHide'" class="content">
        <div class="bg-img" />
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      tipsShow: true
    }
  },
  methods: {
    noremind() {
      this.tipsShow = false
      this.$emit('noremind')
    },
    look() {
      this.show = !this.show
      this.$emit('look')
    }
  }
}
</script>
<style lang="less" scoped>
.model{
  position: relative;
  margin-bottom:16px;
}
.title{
    display: flex;
    align-items: center;
    .open{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom:-4px;
        width:120px;
        height:34px;
        font-size:14px;
        border-radius: 4px;
        cursor: pointer;
        transition: all 1s;
        .iconpark-icon {
          width: 14px;
          height: 14px;
        }
        span{
          margin-left:6px;
        }
        //  &:hover {
        //     background:  @sc-primary-10;
        //     color: @sc-primary-100;
        //   }
    }
    .hide{
        background: @sc-grey-10;
        color: @sc-grey-80;
        span {
          margin-top: -2px;
        }
         &:hover {
            background:  @sc-primary-10;
            color: @sc-primary-100;
          }
    }
    .show{
        border-bottom-left-radius: 0;
        background: @sc-primary-100;
        color:#fff;
         span {
          margin-top: -5px;
        }
        .iconpark-icon {
          margin-top: -5px;
        }
    }
    .away{
        color: @sc-primary-80;
        margin:0 16px;
        cursor: pointer;
        font-size:14px;
    }
    .noremind{
        color: @sc-primary-80;
        cursor: pointer;
        font-size:14px;
    }
}
.content{
  position:relative;
  // z-index: 99;
  // top:31px;
  width:100%;
  height:auto;
  color: @sc-grey-100;
  padding:16px 16px 0 16px;
  background: @sc-primary-10;
  border-radius: 4px;
  font-size:14px;
  border:1px solid @sc-primary-40;
  .bg-img{
    position:absolute;
    width:100%;
    height:calc(100% - 20px);
    right:20px;
    bottom:0;
    display: inline-block;
    background-image: url("../../../assets/img/attendance/tips-bg.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 100%;
  }
}
    .v-enter,
    .v-leave-to{
        opacity: 0;
        // transform: translateX(0);
    }
    .v-enter-active,
    .v-leave-active{
        transition: all 0.3s ease;
    }
</style>
