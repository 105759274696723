<!--
 * @Date: 2022-05-23 10:48:53
 * @descriotion:
-->
<template>
  <div>
    <a-checkbox v-for="(item,index) in workselectOptions" :key="index" :checked="item.checked" @change="checkWork($event,index)">
      {{ item.checked?`休`: `班` }}
    </a-checkbox>
  </div>
</template>
<script>
export default {
  name: 'CheckCycle',
  props: {
    options: {
      type: Array,
      default: () => {}
    }
  },
  data() {
    return {
      workselectOptions: []
    }
  },
  watch: {
    options: {
      handler(val) {
        this.workselectOptions = val
      }
    }
  },
  mounted() {
    this.workselectOptions = this.options
  },
  methods: {
    checkWork(e, index) {
      this.workselectOptions[index].checked = e.target.checked
      this.workselectOptions[index].value = undefined
      this.$set(this.workselectOptions, index, this.workselectOptions[index])
      this.$emit('checked', this.workselectOptions)
    }
  }
}
</script>
<style lang="less" scoped>
.ant-checkbox-wrapper{
    width:calc( 100% / 7);
}
/deep/.ant-checkbox-wrapper + .ant-checkbox-wrapper {
 margin-left:0 !important;
}

</style>
