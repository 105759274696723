<template>
  <div>
    <Modal
      :closable="true"
      :visible="visible"
      title="选择证明类型"
      cancel-text="上一步"
      size="large"
      ok-text="确认"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="view">
        <div
          v-for="item in typeData"
          :key="item.proveTempId"
          class="view-type"
          :class="item.proveTempId == selectVal ? 'view-type select-div' : ''"
          style="cursor: pointer"
          @click="getValue(item)"
        >
          <a-icon class="v-icon" type="file-word" />
          {{ item.tempName }}
        </div>
      </div>

      <!-- <a-radio-group v-model="value">
        <a-radio-button v-for="item in typeData" :key="item.id" :value="item.id">{{ item.name }}</a-radio-button>
      </a-radio-group> -->
    </Modal>
  </div>
</template>

<script>
import { getTemType, openerProveBatch } from '@/services/user/certificate.js'
export default {
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  props: {
    tempTypeId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      typeData: [],
      value: '',
      selectVal: '',
      selectTemp: '',
      visible: false,
      selectPerson: []
    }
  },

  methods: {
    // 打开弹窗
    open(val) {
      this.selectPerson = val
      this.visible = true
      this.initData()
    },
    // 初始化数据
    async initData() {
      const res = await getTemType({ 'tempStatus': 1 })
      if (res.code === 100) {
        this.typeData = res.data
        this.selectVal = this.tempTypeId
      }
    },
    getValue(val) {
      this.selectVal = val.proveTempId
      this.tempFileId = val.tempFileId
      this.$emit('temId', this.selectVal)
    },
    // 保存事件
    async handleOk() {
      const staffIdList = []
      const orgIdList = []
      this.selectPerson.map((item) => {
        if (item.identification === 'peo') {
          staffIdList.push(item.id)
        } else {
          orgIdList.push(item.id)
        }
      })
      const per = {
        proveTempId: this.selectVal,
        tempFileId: this.tempFileId,
        onJobIdList: staffIdList,
        orgIdList: orgIdList
      }
      const res = await openerProveBatch(per)
      if (res.code === 100) {
        if (res.data.length === 0) {
          this.$message.warning('当前部门没有员工！')
          return
        } else {
          this.visible = false
          this.$forceUpdate()
          this.$emit('nextStep', res.data)
        }
      }
    },
    // 上一步
    handleCancel() {
      this.visible = false
      this.$forceUpdate()
      this.$emit('previousStep', this.selectVal)
    }
  }
}
</script>

<style lang="less" scoped>
.ant-modal-root{
  min-height: 500px;
}
.view {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.view-type {
  display: inline-block;
  width: 202px;
  height: 76px;
  line-height: 76px;
  font-size: 16px;
  border: 1px solid  @sc-greyBg-50;
  margin: 4px;
  padding-left: 30px;
  border-radius: 4px;
  color: @sc-grey-100;
  box-shadow: 0px 2px 9px 1px rgba(46, 54, 88, 0.05);
}
.select-div {
  border: 1px solid @sc-primary-100;
  border-radius: 4px;
}
.v-icon {
  color: @sc-primary-80;
}
</style>
