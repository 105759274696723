<template>
  <div>
    <!--  添加 搜索 筛选  -->
    <appForm
      ref="appForm"
      :to-list="toList"
      :screen-roster-list="tabKey === '2' ? normalSelectionList : tabKey === '3' ? toStopped : abnormalSelectionList"
      width="400"
      @getSearchCallback="getSearchCallback"
      @screenRosterParams="screenRosterParams"
      @exportFileList="exportFileList"
    >
      <!-- <template slot="rightButton">
        <a-tooltip placement="top">
          <template slot="title">
            <span>导入</span>
          </template>
          <a-button v-if="tabKey === '1'" type="iconBtn" icon="daoru" class="right-btn" @click="getImport" />
        </a-tooltip>
      </template> -->
      <template slot="customButton">
        <div class="custom_content">
          <a-button v-if="tabKey === '1' || tabKey === '3'" prefix="piliangdaoru" class="custom-button" @click="getBatchShutdown"> 批量停保</a-button>
          <a-button v-if="tabKey === '1'" prefix="piliangdaoru" class="custom-button" @click="getBatchAdjustment"> 批量调整 </a-button>
          <a-button v-if="tabKey === '1'" prefix="piliangdaoru" class="custom-button" @click="getImport">导入在缴人员</a-button>
          <a-button v-if="tabKey === '1'" prefix="daochu" class="custom-button" @click="getCardinalityTable"> 导出基数表 </a-button>
          <a-button v-if="tabKey === '2'" prefix="piliangdaoru" class="custom-button" @click="getBatchInsure"> 批量投保 </a-button>
          <Alert v-if="tabKey === '1'" type="warning" :banner="true" :show-icon="true" class="alert_message">
            <template slot="content">
              <span>提示：人员的社保、医保、公积金只要有一项在缴，即为正常在缴人员</span>
            </template>
          </Alert>
          <Alert v-if="tabKey === '2' && Number(ignoreCount) > 0" type="warning" :banner="true" :show-icon="true" class="alert_message">
            <template slot="content">
              <span>
                提示：有{{ ignoreCount }}人无需在本单位投保，前往<span class="to_be_insured" @click="openIgnoreList">查看 ></span>
              </span>
            </template>
          </Alert>
          <Alert v-if="tabKey === '4'" type="warning" :banner="true" :show-icon="true" class="alert_message">
            <template slot="content">
              <span>提示：社保、医保、公积金均停止缴纳，即为停保人员</span>
            </template>
          </Alert>
        </div>
      </template>
    </appForm>
    <!--  表格  -->
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      row-key="onJobId"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="loadDataList"
    >
      <!-- 序号 -->
      <template slot="index" slot-scope="{ scope }">
        <span>{{ scope.index + 1 }}</span>
      </template>
      <!-- 姓名头像 -->
      <template slot="staffName" slot-scope="{ scope }">
        <CbTableAvatar
          :table-avater="scope.record.avatar"
          :on-job-id="scope.record.onJobId"
          :staff-id="scope.record.staffId"
          :table-name="scope.record.staffName"
          :is-archives="true"
          jump-type="social"
        />
      </template>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{ scope }">
        <!--  正常在缴      -->
        <div v-if="tabKey === '1'" class="operation-btn-box">
          <a-button type="link" @click="getAdjustment(scope.record)"> 调整 </a-button>
          <a-popover placement="bottom" trigger="hover">
            <template slot="content">
              <div class="more-handle-btn">
                <a-button type="link" :disabled="scope.record.housingCompanyId && scope.record.medicalCompanyId&&scope.record.socialCompanyId" @click="getIncreaseInsurance(scope.record)"> 增投保 </a-button>
                <a-button type="link" @click="getStopMaintenance(scope.record)"> 停保 </a-button>
              </div>
            </template>
            <div class="more-handle">···</div>
          </a-popover>
        </div>
        <!--  待在缴      -->
        <div v-if="tabKey === '2'" class="operation-btn-box">
          <div class="operation-btn-box">
            <a-button type="link" @click="insure(scope,false)"> 投保 </a-button>
            <a-button type="link" @click="getIgnore(scope.record)"> 无需参保 </a-button>
          </div>
          <!-- <a-popover placement="bottom" trigger="hover">
            <template slot="content">
              <div class="more-handle-btn">
                <a-button type="link" @click="insure(scope,false)"> 投保 </a-button>
                <a-button type="link" @click="getIgnore(scope.record)"> 无需参保 </a-button>
              </div>
            </template>
            <div class="more-handle">···</div>
          </a-popover> -->
        </div>
        <!--  待停保     -->
        <a-button v-if="tabKey === '3'" type="link" @click="getStopMaintenance(scope.record,true)">停保</a-button>
        <!--  停保      -->
        <a-button v-if="tabKey === '4'" type="link" @click="insure(scope,true)">重新投保</a-button>
      </template>
    </custom-table>
    <!-- 忽略人员列表 -->
    <Modal :visible="visibleIgnoreList" size="normal" title="无需参保" @cancel="offIgnoreListClose" @ok="offIgnoreList">
      <div class="visible-content">
        <div v-for="(item, index) in ignoreList" :key="index" class="user">
          <span class="photo">
            <CbAvatar :on-job-id="item.onJobId" :avater-name="item.staffName" />
          </span>
          <span class="thename">({{ item.staffNum }})</span>
          <a-button type="link" :disabled=" insuranceList.includes(item.onJobId)" @click="getTenantBillDetail(item)">{{ insuranceList.includes(item.onJobId) ? '等待投保' : '加入待投保' }}</a-button>
        </div>
      </div>
    </Modal>
    <!--  批量人员选择弹窗  -->
    <BatchModal ref="BatchModal" :title="title" :query-type="queryType" @batchCallBack="batchCallBack" />
    <!--  批量停保  -->
    <BatchShutdown ref="BatchShutdown" :query-type="queryType" @callBackBatch="callBackBatch" />
    <!--  批量调整  -->
    <BatchAdjustment ref="BatchAdjustment" :query-type="queryType" @callBackBatch="callBackBatch" />
    <!--  导出基数表  -->
    <CardinalityTable ref="CardinalityTable" />
    <!--  调整  -->
    <Adjustment ref="Adjustment" @callBackBatch="callBackBatch" />
    <!--  增投保  -->
    <IncreaseInsurance ref="IncreaseInsurance" @callBackBatch="callBackBatch" />
    <!--  停保  -->
    <StopMaintenance ref="StopMaintenance" @callBackBatch="callBackBatch" />
    <!-- 投保 -->
    <Insure ref="insure" :is-again="isAgain" @callBackBatch="callBackBatch" />
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import CbTableAvatar from '@/components/CbTableAvater/index'
import {
  getIgnoreCountApi,
  getIgnoreInsureStaffListApi,
  getTenantBillDetailApi,
  getArchivesListApi,
  getArchivesListExportApi,
  ignoreStaffInsureApi
} from '@/services/insurance/archives'
import { getTenantBillApi } from '@/services/insurance/supplementary.js'
import moment from 'moment'
export default {
  name: 'Table',
  components: {
    CbTableAvatar,
    BatchShutdown: () => import('./components/batchShutdown'),
    BatchAdjustment: () => import('./components/batchAdjustment'),
    CardinalityTable: () => import('./components/exportCardinalityTable'),
    Adjustment: () => import('./components/adjustment'),
    IncreaseInsurance: () => import('./components/increaseInsurance'),
    StopMaintenance: () => import('./components/stopMaintenance'),
    Insure: () => import('./components/insure'),
    BatchModal: () => import('./components/batchModal'),
    CbAvatar: () => import('@/components/CbAvater/theAvater.vue'),
    Modal: () => import('@/components/CbModal/index'),
    Alert: () => import('@/components/CbAlert')
  },
  mixins: [mixins],
  props: {
    tabKey: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
      total: 0,
      toList: ['search', 'screen', 'export'],
      columns: [],
      ignoreCount: '',
      ignoreList: [],
      visibleIgnoreList: false,
      columnsMany: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'index' },
          width: 50,
          fixed: 'left'
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          width: 105,
          scopedSlots: { customRender: 'staffName' },
          fixed: 'left'
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          width: 100,
          ellipsis: true,
          fixed: 'left'
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          width: 120,
          ellipsis: true,
          fixed: 'left'
        },
        {
          title: '聘用形式',
          dataIndex: 'engageMode',
          key: 'engageMode',
          width: 100
        },
        {
          title: '员工状态',
          dataIndex: 'staffStatus',
          key: 'staffStatus',
          width: 100
        },
        {
          title: '社保参保主体',
          dataIndex: 'socialCompanyName',
          key: 'socialCompanyName',
          ellipsis: true,
          width: 200
        },
        {
          title: '社保基数',
          dataIndex: 'socialPaymentBase',
          key: 'socialPaymentBase',
          dataType: 'money',
          width: 150
        },
        {
          title: '医保参保主体',
          dataIndex: 'medicalCompanyName',
          key: 'medicalCompanyName',
          ellipsis: true,
          width: 200
        },
        {
          title: '医保基数',
          dataIndex: 'medicalPaymentBase',
          key: 'medicalPaymentBase',
          dataType: 'money',
          width: 150
        },
        {
          title: '公积金参保主体',
          dataIndex: 'housingCompanyName',
          key: 'housingCompanyName',
          ellipsis: true,
          width: 200
        },
        {
          title: '公积金基数',
          dataIndex: 'housingPaymentBase',
          key: 'housingPaymentBase',
          dataType: 'money',
          width: 150
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          fixed: 'right',
          width: 120
        }
      ],
      columnsLess: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'index' },
          width: 50
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          scopedSlots: { customRender: 'staffName' },
          width: 105
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '聘用形式',
          dataIndex: 'engageMode',
          key: 'engageMode'
        },
        {
          title: '员工状态',
          dataIndex: 'staffStatus',
          key: 'staffStatus'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: 120
        }
      ],
      dataSource: [], // 数据
      screenList: [],
      normalSelectionList: {
        'list': [
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择部门',
              'showSearch': false
            },
            'model': 'orgIds',
            'key': 'orgIds',
            'rules': [
              {
                'required': false,
                'message': '请选择部门'
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'engage_mode',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'engageModes',
            'key': 'engageModes'
          },
          {
            'type': 'selectDictionaries',
            'label': '员工状态',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false,
              'parameter': 'staff_status',
              'defaultValue': ''
            },
            'model': 'staffStatuses',
            'key': 'staffStatuses',
            'rules': [
              {
                'required': false,
                'message': '请选择'
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 110,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      abnormalSelectionList: {
        'list': [
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择部门',
              'showSearch': false
            },
            'model': 'orgIds',
            'key': 'orgIds',
            'rules': [
              {
                'required': false,
                'message': '请选择部门'
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'engage_mode',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'engageModes',
            'key': 'engageModes'
          },
          {
            'type': 'selectDictionaries',
            'label': '员工状态',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false,
              'parameter': 'staff_status',
              'defaultValue': ''
            },
            'model': 'staffStatuses',
            'key': 'staffStatuses',
            'rules': [
              {
                'required': false,
                'message': '请选择'
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '社保参保主体',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false,
              'parameter': 'getInsureCompanyListApi'
            },
            'model': 'socialCompanyIds',
            'key': 'selectDictionaries_1658371533901'
          },
          {
            'type': 'selectDictionaries',
            'label': '医保参保主体',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false,
              'parameter': 'getInsureCompanyListApi'
            },
            'model': 'medicalCompanyIds',
            'key': 'selectDictionaries_1658371533901'
          },
          {
            'type': 'selectDictionaries',
            'label': '公积金参保主体',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false,
              'parameter': 'getInsureCompanyListApi'
            },
            'model': 'housingCompanyIds',
            'key': 'selectDictionaries_1658371533901'
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 7,
            'sm': 7,
            'md': 7,
            'lg': 7,
            'xl': 7,
            'xxl': 7
          },
          'labelWidth': 110,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 17,
            'sm': 17,
            'md': 17,
            'lg': 17,
            'xl': 17,
            'xxl': 17
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      toStopped: {
        'list': [
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择部门',
              'showSearch': false
            },
            'model': 'orgIds',
            'key': 'orgIds',
            'rules': [
              {
                'required': false,
                'message': '请选择部门'
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'engage_mode',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'engageModes',
            'key': 'engageModes'
          },
          {
            'type': 'selectDictionaries',
            'label': '社保参保主体',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false,
              'parameter': 'getInsureCompanyListApi'
            },
            'model': 'socialCompanyIds',
            'key': 'selectDictionaries_1658371533901'
          },
          {
            'type': 'selectDictionaries',
            'label': '医保参保主体',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false,
              'parameter': 'getInsureCompanyListApi'
            },
            'model': 'medicalCompanyIds',
            'key': 'selectDictionaries_1658371533901'
          },
          {
            'type': 'selectDictionaries',
            'label': '公积金参保主体',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false,
              'parameter': 'getInsureCompanyListApi'
            },
            'model': 'housingCompanyIds',
            'key': 'selectDictionaries_1658371533901'
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 7,
            'sm': 7,
            'md': 7,
            'lg': 7,
            'xl': 7,
            'xxl': 7
          },
          'labelWidth': 110,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 17,
            'sm': 17,
            'md': 17,
            'lg': 17,
            'xl': 17,
            'xxl': 17
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      mainType: '',
      title: '',
      queryType: '2',
      isAgain: false,
      insuranceList: [],
      tenantBillStatus: null
    }
  },
  watch: {
    tabKey: {
      handler(val) {
        console.log(val)
        if (val === '2') {
          this.getIgnoreCountApi()
        }
        this._queryParam = {
          pageNum: 1,
          pageSize: 10
        }
        this.$refs.appForm.refresh()
        this.getFetchDataApiFn()
      }
    }
  },
  mounted() {
    this.getTenantBillStatus()
  },
  methods: {
    async getTenantBillStatus() {
      const res = await getTenantBillApi({ insureMonth: moment(new Date()).format('YYYY-MM') })
      if (res.code === 100) {
        if (res.data) {
          this.tenantBillStatus = res.data.status
        } else {
          this.tenantBillStatus = null
        }
      }
    },
    // 操作回调
    getFetchDataApiFn() {
      this.serchGetData()
    },
    // 表格获取数据
    async fetchDataApiFn(param) {
      if (this.tabKey === '1') {
        // 正常在缴
        this.columns = this.columnsMany
        const res = await getArchivesListApi({ queryType: 2, ...param })
        this.dataSource = res.data.records ? res.data.records : []
        this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
        this.total = res.data.total
      } else if (this.tabKey === '2') {
        // 待投保
        this.columns = this.columnsLess
        const res = await getArchivesListApi({ queryType: 1, ...param })
        this.dataSource = res.data.records ? res.data.records : []
        this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
        this.total = res.data.total
      } else if (this.tabKey === '3') {
        // 待停保
        this.columns = this.columnsMany
        const res = await getArchivesListApi({ queryType: 3, ...param })
        this.dataSource = res.data.records ? res.data.records : []
        this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
        this.total = res.data.total
      } else {
        // 停保
        this.columns = this.columnsMany
        const res = await getArchivesListApi({ queryType: 4, ...param })
        this.dataSource = res.data.records ? res.data.records : []
        this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
        this.total = res.data.total
      }
    },
    // 无需投保人员统计
    async getIgnoreCountApi() {
      const res = await getIgnoreCountApi()
      this.ignoreCount = res.data
    },
    // 搜索
    getSearchCallback(val) {
      this._queryParam.keyWord = val.searchValue
      this.serchGetData()
    },
    // 筛选
    screenRosterParams(data) {
      const { orgIds, engageModes, staffStatuses, socialCompanyIds, medicalCompanyIds, housingCompanyIds } = data.screenCondition
      const { searchValue } = data
      this._queryParam = {
        keyWord: searchValue,
        orgIds: orgIds ? this.stringSplicingId(orgIds, 'value') : null,
        engageModes: engageModes ? this.stringSplicingId(engageModes) : null,
        staffStatuses: staffStatuses ? this.stringSplicingId(staffStatuses) : null,
        socialCompanyIds: socialCompanyIds ? this.stringSplicingId(socialCompanyIds) : null,
        medicalCompanyIds: medicalCompanyIds ? this.stringSplicingId(medicalCompanyIds) : null,
        housingCompanyIds: housingCompanyIds ? this.stringSplicingId(housingCompanyIds) : null
      }
      this.serchGetData()
    },
    // 拼接筛选所需id组
    stringSplicingId(data, idName) {
      if (!data) return
      let arr = ''
      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
          arr = idName ? data[i][idName] : data[i]
        } else {
          arr += idName ? ',' + data[i][idName] : ',' + data[i]
        }
      }
      return arr
    },
    // 导出
    exportFileList() {
      getArchivesListExportApi({
        ...this._queryParam,
        queryType: this.tabKey === '1' ? 2 : this.tabKey === '2' ? 1 : this.tabKey === '3' ? 3 : 4
      })
    },
    // 导入
    getImport() {
      if (this.tenantBillStatus !== null) {
        this.$Reconfirm({
          title: '导入',
          content: this.tenantBillStatus === 0
            ? '由于本月账单已生成，导入的在缴名单后，须前往账单页重新生成账单生效。历史月份账单已锁定，本月以前的历史月份投保数据不再生效，且不会产生历史增员数据。'
            : '由于本月账单已锁定，导入的在缴名单后，须撤销锁定，重新生成账单生效。历史月份账单已锁定，本月以前的历史月份投保数据不再生效，且不会产生历史增员数据。'
        }).then(() => {
          this.$emit('getImportCallback')
        }).catch(() => {})
      } else {
        this.$emit('getImportCallback')
      }
    },
    // 批量人员选择弹窗
    batchCallBack(data) {
      if (this.mainType === 'stopMain') {
        this.$refs.BatchShutdown.show(data)
      } else if (this.mainType === 'adjustment') {
        this.$refs.BatchAdjustment.show(data)
      } else if (this.mainType === 'insure') {
        this.$refs.insure.onShow(data, 'batch')
      }
    },
    // 批量停保
    getBatchShutdown() {
      if (this.tabKey === '1') {
        this.mainType = 'stopMain'
        this.title = '选择批量停保人员'
        this.queryType = '2'
        this.$refs.BatchModal.show()
      } else {
        this.mainType = 'stopMain'
        this.title = '选择批量停保人员'
        this.queryType = '3'
        this.$refs.BatchModal.show()
      }
    },
    // 批量投保
    getBatchInsure() {
      this.queryType = '1'
      this.isAgain = false
      this.mainType = 'insure'
      this.title = '选择批量投保人员'
      this.$refs.BatchModal.show()
    },
    // 批量调整
    getBatchAdjustment() {
      this.mainType = 'adjustment'
      this.title = '选择批量调整人员'
      this.queryType = '2'
      this.$refs.BatchModal.show()
    },
    // 批量回调
    callBackBatch() {
      this.serchGetData()
      this.$emit('callBackBatch')
    },
    // 导出基数表
    getCardinalityTable() {
      this.$refs.CardinalityTable.show()
    },
    // 调整
    getAdjustment(item) {
      const { staffName, onJobId, staffNum, orgName } = item
      const userInfo = {
        staffName: staffName,
        onJobId: onJobId,
        staffNum: staffNum,
        orgName: orgName
      }
      this.$refs.Adjustment.show(userInfo)
    },
    // 增投保
    getIncreaseInsurance(item) {
      const { staffName, onJobId, staffNum, orgName } = item
      const userInfo = {
        staffName: staffName,
        onJobId: onJobId,
        staffNum: staffNum,
        orgName: orgName
      }
      this.$refs.IncreaseInsurance.show(userInfo)
    },
    // 停保
    getStopMaintenance(item, flag = false) {
      const { staffName, onJobId, staffNum, orgName } = item
      const userInfo = {
        staffName: staffName,
        onJobId: onJobId,
        staffNum: staffNum,
        orgName: orgName
      }
      this.$refs.StopMaintenance.show(userInfo, flag)
    },
    // 投保
    insure(scope, isAgain) {
      this.isAgain = isAgain
      this.$refs.insure.onShow(scope.record)
    },
    // 忽略
    getIgnore(e) {
      this.$Reconfirm({
        title: '无需参保',
        content: '该人员将被移到无需在本单位参保人员名单'
      }).then(async() => {
        const res = await ignoreStaffInsureApi({
          onJobId: e.onJobId
        })
        if (res.code === 100) {
          this.$message.success('无需参保人员移入成功')
          this.callBackBatch()
          await this.getIgnoreCountApi()
        }
      }).catch(() => {
        console.log('no')
      })
    },
    // 无需投保人员列表
    async openIgnoreList() {
      const res = await getIgnoreInsureStaffListApi()
      this.ignoreList = res.data
      this.visibleIgnoreList = true
    },
    // 加入待投保
    async getTenantBillDetail(data) {
      this.insuranceList.push(data.onJobId)
    },
    // 忽略人员弹窗确认
    async offIgnoreList() {
      const res = await getTenantBillDetailApi({ onJobIds: this.insuranceList })
      if (res.code === 100) {
        this.$message.success('加入待投保成功')
        this.offIgnoreListClose()
        this.callBackBatch()
        await this.getIgnoreCountApi()
      }
    },
    // 忽略人员弹窗关闭
    offIgnoreListClose() {
      this.insuranceList = []
      this.visibleIgnoreList = false
    }
  }
}
</script>

<style lang="less" scoped>
.right-btn{
  margin-left: 12px;
}
.custom_content {
  display: flex;
  align-items: center;
  justify-content: center;
  .custom-button {
    margin-right: 12px;
  }
  .alert_message {
    width: auto;
    height: 34px;
    /deep/.ant-alert{
      padding: 5px 20px 5px 38px !important;
      .ant-alert-icon{
        top: -6px;
        .anticon{
          width: auto;
          height: auto;
          font-size: 16px;
        }
      }
    }
    .to_be_insured {
      color: @sc-primary-100;
      cursor: pointer;
      margin-left: 5px;
    }
  }
}
.more-handle-btn {
  display: flex;
  flex-direction: column;
}
.more-handle {
  cursor: pointer;
  font-weight: 700;
  color: @sc-primary-100;
  font-size: 20px;
}
.visible-content {
  width: 100%;
  height: auto;
  .user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    &:last-child{
      margin-bottom: 0;
    }
    .thename {
      color: @sc-grey-100;
    }
  }
}
.operation-btn-box{
  display:flex;
  button{
    padding-left:0;
    padding-right:16px;
  }
}
</style>
