<template>
  <div>
    <Modal
      :closable="true"
      :visible="visible"
      title="转让创建人"
      size="small"
      :ok-text="isTab ? '下一步' : '确定'"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div v-if="isTab" class="first">
        <p>为确保数据安全，请进行身份验证</p>
        <a-space>
          <span>手机号</span>
          <span>{{ getInfo.phoneNum }}</span>
        </a-space>
        <a-space>
          <span>验证码</span>
          <GetCheckCode
            ref="codeRef"
            v-model.trim="verifyCode"
            :can-use="String(getInfo.phoneNum).length !== 0"
            @getCode="getVerifyCode"
          />
        </a-space>
      </div>
      <div v-if="!isTab" class="second">
        <a-space>
          <span>对接人</span>
          <a-select v-model="transferUserId" placeholder="请选择对接人" @change="handleChange">
            <a-select-option v-for="item in getData" :key="item.userId">
              {{ item.userName }} {{ item.phoneNum }}
            </a-select-option>
          </a-select>
        </a-space>
        <!-- <a-space class="checkSpace">
          <a-tooltip placement="right">
            <template slot="title">
              <span>离职交接，转让后会退出该企业</span>
            </template>
            <a-checkbox :checked="isChecked" @change="onCheck">
              离职交接<a-icon type="exclamation-circle" theme="filled" class="circle_icon" />
            </a-checkbox>
          </a-tooltip>
        </a-space> -->
      </div>
    </Modal>
  </div>
</template>

<script>
import GetCheckCode from '@/components/GetCheckCode'
import { transferAccount, getTransferAccountList } from '@/services/globalSetting/settingAuthor.js'
import { passwordGetActivateCode, doValidateCode, doLogout } from '@/services/login/index.js'
import { removeAuthorization } from '@/utils/request'
export default {
  name: 'TransferCreator',
  components: {
    GetCheckCode,
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      transferUserId: '',
      visible: false,
      verifyCode: '',
      uuidCode: '',
      getInfo: {},
      isTab: false,
      getData: [],
      isChecked: false,
      isLeave: '0'
    }
  },
  methods: {
    // 打开弹窗
    open(val) {
      this.isTab = true
      this.getInfo = val.getInfo
      this.visible = true
      this.getTransferList()
    },
    async getTransferList() {
      const res = await getTransferAccountList()
      this.getData = res.data
    },
    async getVerifyCode(cutdown) {
      this.verifyCode = ''
      const res = await passwordGetActivateCode({
        phoneNum: this.getInfo.phoneNum
      })
      if (res.code === 100) {
        this.uuidCode = res.data
        cutdown()
      }
    },
    handleChange(val) {
      this.transferUserId = val
    },
    onCheck(e) {
      if (e.target.checked) {
        this.isChecked = true
        this.isLeave = '1'
      } else {
        this.isChecked = false
        this.isLeave = '0'
      }
    },
    // 保存事件
    async handleOk() {
      if (this.isTab) {
        if (this.uuidCode === '' && this.verifyCode) {
          this.$message.error('请填写正确验证码')
        } else if (!this.verifyCode && !this.uuidCode) {
          this.$message.error('请获取验证码')
        } else if (this.verifyCode && this.uuidCode) {
          const param = {
            phoneNum: this.getInfo.phoneNum,
            code: this.verifyCode,
            uuid: this.uuidCode
          }
          await doValidateCode(param).then(res => {
            if (res.code === 100) {
              this.uuidCode = res.data
              this.isTab = false
            }
          }).catch(err => err)
        }
      } else {
        if (!this.transferUserId) {
          this.$message.info('请先选择对接人')
          return
        }
        const param = {
          userId: this.getInfo.userId,
          transferUserId: this.transferUserId,
          isLeave: this.isLeave
        }
        const res = await transferAccount(param)
        if (res.code === 100) {
          // this.$emit('transfer')
          // this.handleCancel()
          if (this.isLeave === '1') {
            doLogout().then(() => {
              this.$message.success('您已退出该企业')
              localStorage.clear()
              removeAuthorization()
              this.$router.push('/login')
            })
          } else {
            this.$message.success('转让创建人成功')
            localStorage.clear()
            removeAuthorization()
            this.$router.push('/login')
          }
        }
      }
    },
    // 取消
    handleCancel() {
      this.verifyCode = ''
      this.visible = false
      this.transferUserId = ''
      this.isChecked = false
      if (this.isTab) {
        this.$refs.codeRef.val = ''
        this.$refs.codeRef.getCheckCoding = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.first{
	color:  @sc-grey-100;
	p{
		margin-bottom: 16px;
    opacity: 0.6;
	}
	.ant-space{
		margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    /deep/.ant-space-item:last-child {
      margin-left: 12px;
    }
	}
  .get-check-code{
    width: 360px;
  }
}
.second{
	display: flex;
	flex-direction: column;
  /deep/.ant-space-item:last-child {
    margin-left: 12px;
  }
	.ant-select{
		width: 200px;
	}
	.checkSpace{
		margin-left: 50px;
    /deep/.anticon{
      margin-left: 5px;
    }
	}
}
</style>
