<template>
  <div class="add-template">
    <HeaderBack class="add-template-header" :title="`${tempData.templateType==='edit'? '编辑':'新增'}证明模板`" @back="$emit('back')" />
    <div class="add-template-content">
      <LeftMessage ref="leftMessage" :label-list="labelList" @insert="insert" />
      <div class="right-message">
        <!-- <span>循环内容使用%&amp; &amp;%符号 包裹</span> -->
        <Tinymce-vue :value="tempData.tempContent" :setting="setting" @setups="setups" @input="(res)=> tempData.tempContent = res" />
      </div>
    </div>
    <div class="add-template-bottom">
      <a-button type="primary" @click="addOrEditTemplate">确认</a-button>
    </div>
  </div>
</template>

<script>
import LeftMessage from './leftMessage'
import HeaderBack from '@/components/headerBack'
import TinymceVue from '@/components/Tinymce'
import { createProveTemp, updateProveTemp } from '@/services/user/setting.js'
import { getFieldGroupOfTemp } from '@/services/user/fieldGroup.js'
import { upload } from '@/services/upload/index.js'
import { debounce } from '@/utils/index.js'
const tinymce = window.tinymce
var specialChars = []
export default {
  name: 'AddTemplate',
  components: {
    TinymceVue,
    HeaderBack,
    LeftMessage
  },
  data() {
    return {
      templateType: '',
      setting: {
        cleanup: false,
        branding: false, // 隐藏商标
        elementpath: false, // 隐藏底栏的元素路径
        height: 500,
        min_height: 400,
        max_height: 650,
        font_formats: "微软雅黑='微软雅黑';宋体='宋体'",
        menubar: ' edit insert view format',
        preview: true,
        toolbar: ' undo redo | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |',
        toolbar_drawer: 'sliding',
        quickbars_selection_toolbar: 'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
        plugins: 'link preview image  lists fullscreen ',
        language: 'zh_CN', // 本地化设置
        forced_root_block: 'p', // 设置默认标签项
        images_upload_handler: this.uploadImages // 自定义上传图片
      },
      tempData: {
        tempContent: ''
      },
      labelList: [],
      basePath: process.env.VUE_APP_API_BASE_URL
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getLabelList()
    })
  },
  methods: {
    async getLabelList() {
      const res = await getFieldGroupOfTemp()
      if (res.code === 100) {
        this.labelList = res.data
        specialChars = []
        res.data.forEach(it => {
          specialChars = [...specialChars, ...it.fields.map((v) => { return { text: v.fieldName, value: v.fieldId } })]
        })
        tinymce.init({})
        this.tempData = JSON.parse(sessionStorage.getItem('proveTemplate'))
        console.log(specialChars)
      }
    },
    async uploadImages(blobInfo, succFun, failFun) {
      const formData = new FormData()
      formData.append('file', blobInfo.blob())
      formData.append('fileType', 'other')
      formData.append('fileName', blobInfo.blob().name)
      formData.append('sensitiveFile', 0)
      const res = await upload(formData)
      if (res.code === 100) {
        const url = `${this.basePath}/hrsaas-storage/image/thumbnail/name?id=${res.data.fileId}`
        succFun(url)
      }
    },
    setups(editor) {
      var onAction = function(autocompleteApi, rng, value) {
        editor.selection.setRng(rng)
        editor.insertContent(value)
        autocompleteApi.hide()
      }
      var getMatchedChars = function(pattern) {
        return specialChars.filter(function(char) {
          return char.text.indexOf(pattern) !== -1 || pattern.charAt(pattern.length - 1) === '#'
        })
      }
      let bookmark = []
      editor.on('FullscreenStateChanged', (e) => {
        console.log(e)
        this.fullscreen = e.state
      })
      editor.on('mousedown', (e) => {
        if (editor.selection.getNode().nodeName !== 'LABEL') {
          bookmark = editor.selection.getBookmark()
          return
        }
      })
      editor.on('mouseup', (e) => {
        if (editor.selection.getNode().nodeName === 'LABEL') {
          editor.selection.moveToBookmark(bookmark, 2)
        }
      })
      editor.on('keyup', (e) => {
        if (editor.selection.getNode().nodeName === 'LABEL') {
          editor.selection.moveToBookmark(bookmark, 2)
        }
      })
      editor.on('keydown', (e) => {
        if (editor.selection.getNode().nodeName !== 'LABEL') {
          bookmark = editor.selection.getBookmark(2, true)
        }
        if (e.key === 'Backspace') {
          setTimeout(() => {
            if (editor.selection.getNode().nodeName === 'LABEL') {
              const rngInfo = editor.selection.getRng()
              rngInfo.commonAncestorContainer.data = '#'
              editor.execCommand('mceRemoveNode', false, '')
              editor.selection.setRng('#', true)
              editor.selection.select(editor.getBody(), true)
              editor.selection.collapse(false)
            }
          })
        }
      })
      editor.ui.registry.addAutocompleter('specialchars', {
        ch: '#',
        minChars: -1,
        columns: 'auto',
        onAction: onAction,
        fetch: function(pattern) {
          return new Promise(function(resolve) {
            var results = getMatchedChars(pattern).map(function(char) {
              return {
                type: 'autocompleteitem',
                value: `<label  id="${char.value}" style="color:#507FF7;border-bottom:1px solid black;padding:0 8px;">#${char.text}#</label>&nbsp;`,
                text: char.text,
                icon: `#${char.text}#`
              }
            })
            resolve(results)
          })
        }
      })
    },
    insert({ fieldName, fieldId, style }) {
      const insertText = `<label  id="${fieldId}" style="color:#507FF7;border-bottom:1px solid black;padding:0 8px;${style}">#${fieldName}#</label>&nbsp;`
      tinymce.execCommand('mceReplaceContent', false, insertText)
      // editor.selection.select(editor.getBody(), true)
      // editor.selection.collapse(false)
    },
    addOrEditTemplate() {
      this.$refs.leftMessage.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.onSubmit()
          return
        }
        this.$message.error('新增信息不完整！')
      })
    },
    onSubmit: debounce(async function() {
      const data = {
        tempName: this.$refs.leftMessage.staffAddInfo.staffName,
        tempContent: this.tempData.tempContent
      }
      if (this.tempData.templateType === 'edit') {
        const ids = {
          proveTempId: this.tempData.proveTempId,
          tempFileId: this.tempData.tempFileId
        }
        const res = await updateProveTemp({ ...data, ...ids })
        if (res.code === 100) {
          this.$message.success('编辑成功！')
          this.$emit('back', 'Refresh')
        }
        return
      }
      const res = await createProveTemp(data)
      if (res.code === 100) {
        this.$message.success('新增成功！')
        this.$emit('back', 'Refresh')
      }
    }, 500)
  }
}
</script>

<style lang="less" scoped>
.add-template {
  &-header{
    padding-left: 20px;
    height: 56px;
    line-height: 68px;
    margin-bottom: 0;
  }
  &-content {
    display: flex;
    border-top: 1px solid @sc-greyBg-20;
    background: #fff;
    .right-message {
      padding: 68px 53px 0 47px;
    }
  }
  &-bottom {
    height: 56px;
    width:80%;
    background: #fff;
    box-shadow: 6px 3px 24px 1px rgba(64, 81, 120, 0.15);
    position: fixed;
    bottom: 0;
    display:flex;
    align-items: center;
    justify-content: center;
    .ant-btn {
      width: 88px;
      height: 32px;
    }
  }
}
/deep/ .tox-tinymce {
  border: 1px solid @sc-greyBg-20;
}
</style>
