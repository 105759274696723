<template>
  <div class="all-content">
    <div class="v-title">工号规则</div>
    <div class="v-line">
      <a-space>
        <span class="v-line-title">工号自动生成</span>
        <a-switch v-model="checked" size="small" @change="onChangeSwitch" />
        <span class="v-line-explain">开启状态时，批量添加在职员工也会自动生成工号</span>
      </a-space>
    </div>
    <!--  默认隐藏  -->
    <div v-if="checked">
      <a-divider />
      <div>
        <!-- <a-checkbox :checked="staffNumEditData" class="checkbox_group" @change="staffNumEditChange">开启工号不可编辑</a-checkbox> -->
        <!-- <a-checkbox :checked="staffNumChangeEngageStayData" class="checkbox_group" @change="staffNumChange">更改聘用形式保留工号</a-checkbox> -->
        <a-checkbox :checked="staffNumSecondEntryStayData" @change="staffNumSecond">离职再入职保留原工号</a-checkbox>
      </div>
      <a-divider />
      <div class="rule-list">
        <div>
          <span class="serial_num">序号</span>
          <a-tooltip class="tool-title" placement="right">
            <template slot="title">
              <span>前缀与流水号组合需唯一</span>
            </template>
            <span class="prefix">工号规则前缀<a-icon type="exclamation-circle" theme="filled" class="circle_icon" /></span>
          </a-tooltip>
          <a-tooltip class="tool-title" placement="right">
            <template slot="title">
              <span>输入流水号位数</span>
            </template>
            <span class="number_digit">数字位数<a-icon type="exclamation-circle" theme="filled" class="circle_icon" /></span>
          </a-tooltip>
          <a-tooltip class="tool-title" placement="right">
            <template slot="title">
              <span>工号前缀+数字位数组合，如QZ+2位数字，生成工号范围QZ01-QZ99</span>
            </template>
            <span class="hover_tips_span" :class="[isLong?'tool-title':'tool-title table_span']">工号预览<a-icon type="exclamation-circle" theme="filled" class="circle_icon" /></span>
          </a-tooltip>
          <a-tooltip class="tool-title-long" placement="right">
            <template slot="title">
              <span>规则之间,聘用形式不能有重叠</span>
            </template>
            <span class="hover_tips">适用聘用形式<a-icon type="exclamation-circle" theme="filled" class="circle_icon" /></span>
          </a-tooltip>
        </div>
        <span class="tool-title">操作</span>
      </div>
      <a-divider />
      <div>
        <div v-for="(item, index) in tableData" :key="index" class="rule_list">
          <div>
            <span class="serial_num">{{ index + 1 }}</span>
            <a-input v-model="item.prefix" :max-length="10" class="table_input operation" placeholder="1-10位字符" allow-clear @blur="prefixBlur(index,item.prefix)" />
            <div class="number_box">
              <Number v-model="item.numLength" :allow-clear="true" :min="1" :max="10" class="table_input" placeholder="1-10之间整数" @blur="numLengthBlur(index,item.numLength)" />
            </div>
            <!-- <a-input  allow-clear /> -->
            <span :class="[isLong?'long_span':'table_span']">{{ item.initialValue }}</span>
            <a-checkbox-group v-model="item.engageModes" class="table_input-long" :options="tableOptions" />
          </div>
          <div>
            <span class="cursor del" @click="delTable(index)">删除</span>
          </div>
        </div>
      </div>
      <div>
        <span :class="[tableOptions.length === tableData.length ? 'full' : 'cursor add-rule']" @click="addTableList">
          <svg class="iconpark-icon"><use href="#add-one" /></svg>
          添加生成规则
        </span>
      </div>
      <a-button type="primary" class="submitBtn" :disabled="disabledButton" @click="onSave" @enterPress="onSave"> 保存 </a-button>
    </div>
  </div>
</template>
<script>
import { enNumReg, intReg } from '@/utils/reg.js'
import { getTenantCode } from '@/services/dropDownInput/index.js'
import { getStaffNumConfig, staffNumConfig, changeAutoGenerate } from '@/services/user/setting.js'
import Number from '@/components/CbNumber/index'
export default {
  components: {
    Number
  },
  data() {
    return {
      jobNumRuleIndex: null,
      checked: false,
      staffNumEditData: false,
      staffNumChangeEngageStayData: false,
      staffNumSecondEntryStayData: false,
      tableOptions: [],
      value: [],
      tableData: [
        {
          prefix: '',
          numLength: null,
          initialValue: '',
          engageModes: []
        }
      ],
      isLong: false,
      len: []
    }
  },
  computed: {
    // 当无列表禁用按钮
    disabledButton() {
      if (this.tableData && this.tableData.length > 0) {
        return false
      } else {
        return true
      }
    }
  },
  mounted() {
    this.getDictionariesData() // 获取字典查询聘用类型
    this.getStaffNumConfig() // 获取工号规则
  },
  methods: {
    // 获取字典查询聘用类型
    async getDictionariesData() {
      const res = await getTenantCode({ codeName: 'engage_mode' })
      this.tableOptions = res.data.map(item => {
        return {
          ...item,
          label: item.content,
          value: item.codeKey
        }
      })
    },
    // 获取工号规则
    async getStaffNumConfig(bool) {
      const res = await getStaffNumConfig()
      this.checked = res.data.staffNumAuto !== 0
      this.staffNumEditData = res.data.staffNumEdit !== 0
      this.staffNumChangeEngageStayData = res.data.staffNumChangeEngageStay !== 0
      this.staffNumSecondEntryStayData = res.data.staffNumSecondEntryStay !== 0
      this.tableData = res.data.rules
      this.setNumWidth()
    },
    // 设置工号自动配置开关
    async changeAutoGenerate() {
      const res = await changeAutoGenerate({ autoGenerate: this.checked === false ? 0 : 1 })
      if (res.code === 100) {
        if (this.checked) {
          this.$message.success('已开启工号自动生成')
          this.getStaffNumConfig()
        } else {
          this.$message.success('已关闭工号自动生成')
        }
      }
    },
    // 是否开启自动生成工号
    onChangeSwitch(checked) {
      this.changeAutoGenerate(checked)
    },
    // 开启工号不可编辑
    staffNumEditChange(e) {
      this.staffNumEditData = e.target.checked
    },
    // 更改聘用形式保留工号
    staffNumChange(e) {
      this.staffNumChangeEngageStayData = e.target.checked
    },
    // 离职再入职保留原工号
    staffNumSecond(e) {
      this.staffNumSecondEntryStayData = e.target.checked
    },
    // 前缀失去焦点
    prefixBlur(i, val) {
      // const reg = /^[A-Za-z]{1,10}$/
      if (!enNumReg.test(this.tableData[i].prefix)) {
        this.$message.warning('请输入1-10位的字符')
        this.tableData[i].prefix = null
        return
      }
      if (this.tableData[i].prefix && this.tableData[i].numLength) {
        const preval = this.tableData.map(item => item.prefix === val)
        const counts = this.getArrItemNum(preval)
        if (counts >= 2) {
          this.$message.warning('第' + (i + 1) + '条工号规则重复,请重新输入')
          this.tableData[i].prefix = ''
          this.tableData[i].numLength = ''
        } else {
          const previewNum = this.getPreview(this.tableData[i].prefix, this.tableData[i].numLength)
          this.tableData[i].initialValue = previewNum
          this.setNumWidth()
        }
      } else {
        this.tableData[i].initialValue = ''
      }
    },
    // 数字长度失去焦点
    numLengthBlur(i) {
      if (!intReg.test(this.tableData[i].numLength)) {
        this.$message.warning('请输入1-10之间整数')
        this.tableData[i].numLength = null
        return
      }
      if (this.tableData[i].prefix && this.tableData[i].numLength) {
        const preval = this.tableData.map(item => item.prefix === this.tableData[i].prefix)
        const counts = this.getArrItemNum(preval)
        if (this.tableData[i].prefix && counts >= 2) {
          this.$message.warning('第' + (i + 1) + '条工号规则重复,请重新输入')
          this.tableData[i].prefix = ''
          this.tableData[i].numLength = ''
        } else {
          const previewNum = this.getPreview(this.tableData[i].prefix, this.tableData[i].numLength)
          this.tableData[i].initialValue = previewNum
          this.setNumWidth()
        }
      } else if (this.tableData[i].prefix) {
        const previewNum = this.getPreview(this.tableData[i].prefix, this.tableData[i].numLength)
        this.tableData[i].initialValue = previewNum
        this.setNumWidth()
      } else {
        this.tableData[i].initialValue = ''
      }
    },
    // 工号预览
    getPreview(d, n) {
      let previewNum = ''
      let num = ''
      for (let i = 0; i < n - 1; i++) {
        num += 0
      }
      previewNum = d + num + 1
      return previewNum
    },
    getArrItemNum(arr) { // 获取数组中true值出现的次数
      let num = 0
      arr.forEach(element => {
        if (element === true) {
          return num++
        }
      })
      return num
    },
    // 设置工号预览的宽度变化
    setNumWidth() {
      this.tableData.map(item => {
        const cc = item.initialValue.length
        if (cc > 10) {
          this.len.push(true)
        } else {
          this.len.push(false)
        }
      })
      const lenTrue = this.getArrItemNum(this.len)
      if (lenTrue >= 1) {
        this.isLong = true
        this.len = []
      } else {
        this.isLong = false
        this.len = []
      }
    },
    // 删除
    delTable(index) {
      this.jobNumRuleIndex = index
      this.$Reconfirm({
        title: '删除',
        content: '是否确认删除？'
      }).then(() => {
        this.tableData.splice(this.jobNumRuleIndex, 1)
      }).catch(() => {
        console.log('no')
      })
    },
    // 添加
    addTableList() {
      this.tableData.push({
        prefix: '',
        numLength: 1,
        initialValue: '',
        engageModes: []
      })
    },
    // 保存
    onSave() {
      const isEmpty = this.tableData.some(obj => {
        return Object.keys(obj).some(key => {
          return Array.isArray(obj[key]) ? !obj[key].join(',') : !obj[key]
        })
      })
      if (!isEmpty) {
        this.staffNumConfigData()
      } else {
        this.$message.warning('有未填项，请全部填写在保存')
      }
    },
    // 保存接口
    async staffNumConfigData() {
      // let n
      // this.tableData.map((res) => {
      //   n = res.initialValue.split(res.prefix)
      //   res.initialValue = res.prefix + n[1].slice(0, n[1].length - 1) + '0'
      // })
      const res = await staffNumConfig({
        staffNumEdit: !this.staffNumEditData ? 0 : 1,
        staffNumChangeEngageStay: !this.staffNumChangeEngageStayData ? 0 : 1,
        staffNumSecondEntryStay: !this.staffNumSecondEntryStayData ? 0 : 1,
        rules: this.tableData
      })
      if (res.code === 100) {
        this.$message.success('保存成功')
        this.getDictionariesData() // 获取字典查询聘用类型
        this.getStaffNumConfig(true) // 获取工号规则
        // this.getPreview()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.all-content {
  height:100%;
  overflow: auto;
  padding: 20px;
  box-sizing: border-box;
}
.v-title {
  font-weight: 600;
  font-size: 16px;
  color: @sc-grey-100;
}
.v-line {
  height: 40px;
  background: @sc-greyBg-10;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  line-height: 40px;
  font-size: 16px;
  margin-top: 10px;
}
.v-line-title {
  color: @sc-grey-100;
  margin-left: 10px;
  font-size: 14px;
}
.v-line-explain {
  color: @sc-grey-60;
  font-size: 14px;
}
.rule-list{
  display: flex;
  justify-content: space-between;
}
.tool-title {
  display: inline-block;
  font-size: 14px;
  color: @sc-grey-60;
  margin-right: 20px;
}
.tool-title-long {
  display: inline-block;
  font-size: 14px;
  color: @sc-grey-60;
}
.table_input {
  width: 130px;
  /deep/&:hover{
    .ant-input{
      border-color: @sc-primary-100;
    }
    .ant-input[disabled]{
      border-color: unset;
      cursor: not-allowed;
    }
  }
}
.table_input-long {
  width: 430px;
  padding: 0 20px;
}
.hover_tips{
  padding: 0 20px;
}
.hover_tips_span{
  display: inline-block;
  // width: 185px;
  margin: 0 20px;
  text-align: left;
  font-size: 14px;
  margin-right: 135px;
}
.table_span {
  display: inline-block;
  width: 130px;
  margin: 0 20px;
  text-align: center;
}
.long_span{
  display: inline-block;
  width: 185px;
  margin: 0 20px;
  text-align: left;
  font-size: 14px;
}
.full{
  display: none;
}
.add-rule {
  font-size: 14px;
  font-weight: normal;
  color: @sc-primary-100;
  line-height: 22px;
  display: flex;
  align-items: center;
  margin: 10px 0px 30px 0px;
  visibility: visible;
  .iconpark-icon{
    width: 16px;
    height: 16px;
    color: @sc-primary-100;
    margin-right: 4px;
  }
}
.cursor {
  cursor: pointer;
}
  .serial_num {
    margin-right: 20px;
    font-size: 14px;
    color: @sc-grey-60;
  }
 .circle_icon {
   margin-left: 5px;
 }
  .prefix {
    width: 130px;
    text-align: center;
  }
  .number_digit {
    width: 130px;
    text-align: center;
    margin-right: 0;
  }
  .operation {
    margin: 0 25px 0 20px;
  }
  .del {
    color: @error-color;
    font-size: 14px;
    text-align: right;
    margin-right: 20px;
  }
  .checkbox_group {
    margin-right: 55px;
  }
  .rule_list {
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
  }
.submitBtn {
  margin-top: 10px;
}
.number_box{
  display: inline-block;
}
</style>
