<!--
 * @Date: 2022-07-06 10:04:11
 * @descriotion:
-->
<template>
  <div class="header">
    <div class="tips">
      导出的列表包含如下字段 <span>可拖拽改变显示顺序</span>
    </div>
    <draggable v-model="list" filter=".forbid" :move="onMove">
      <transition-group>
        <a-button v-for="(element,index) in list" :key="element" :class="index===1?'item forbid':'item'" type="primary">
          {{ element }}
        </a-button>
      </transition-group>
    </draggable>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  name: 'ImportHeader',
  components: {
    draggable
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {

    }
  },
  methods: {
    onMove(e) {
      if (e.relatedContext.element.id === 1) return false
      return true
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.ant-btn{
    margin: 0 16px 16px 0;
}
.header{
    width:100%;
    padding:16px 20px;
    .tips{
        margin-bottom:16px;
        .font-size(16px);
        color: @sc-grey-100;
        font-weight: 600;
        span{
            .font-size(14px);
            margin-left:16px;
            color: @sc-grey-40;
        }
    }
}
</style>
