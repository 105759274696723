<template>
  <div class="Role">
    <template v-if="formVisible">
      <!-- <div class="Role-header">
        <div>
          <svg-icon class="icon" icon-class="warning" />
          <span>{{ msg }}</span>
        </div>
        <svg-icon class="icon" icon-class="close" />
      </div> -->
      <appForm :form-list="formList" @getSearchCallback="getSearchCallback">
        <template slot="customButton">
          <a-button type="primary" prefix="add-one" @click="addRole">
            添加角色
          </a-button>
        </template>
      </appForm>
      <custom-table :columns="columns" :data-source="dataSource" table-ref=".Role" row-key="flowRoleName" :pagination="false" @change="loadDataList">
        <!-- 操作列 -->
        <template slot="operation" slot-scope="{ scope }">
          <span class="operation-btn-box">
            <a-button type="link" @click="editRole(scope.record)"> 编辑 </a-button>
            <a-button type="link" class="delete" @click="delet(scope.record)"> 删除 </a-button>
          </span>
          <!-- <div class="flex-align-center">
            <a-popover placement="bottom" trigger="hover">
              <template slot="content">
                <div class="more-handle-btn">
                  <a-button type="link" @click="editRole(scope.record)"> 编辑 </a-button>
                  <a-button type="link" class="delete" @click="delet(scope.record)"> 删除 </a-button>
                </div>
              </template>
              <div class="more-handle">···</div>
            </a-popover>
          </div> -->
        </template>
      </custom-table>
    </template>
    <div v-else>
      <AddRole :form-type="formType" :flow-role-id="flowRoleId" @back="formVisible = true" @getInit="getInit" />
    </div>
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import AddRole from './AddRole/index'
import { getFlowRoleList, deleteFlowRole } from '@/services/logicFlow'
export default {
  name: 'Role',
  components: {
    AddRole
  },
  mixins: [mixins],
  data() {
    return {
      msg: 'xxx已离职，请尽快调整相关角色人员',
      formList: [], // search搜索框 screen筛选 choice位置选择弹窗 export导出
      columns: [
        {
          title: '角色名称',
          dataIndex: 'flowRoleName',
          key: 'flowRoleName',
          scopedSlots: { customRender: 'flowRoleName' },
          width: '300px',
          ellipsis: true
        },
        {
          title: '包含人员',
          dataIndex: 'userNameList',
          key: 'userNameList',
          width: '300px',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '120px'
        }
      ],
      dataSource: [],
      loading: false,
      formVisible: true,
      flowRoleId: undefined,
      formType: 1
    }
  },
  computed: {},
  mounted() {
    this.getRoleList()
  },
  methods: {
    async getRoleList() {
      const res = await getFlowRoleList({})
      if (res.code === 100) {
        this.dataSource = res.data
      }
    },
    addRole() {
      this.formVisible = false
      this.flowRoleId = undefined
      this.formType = 1
    },
    editRole(data) {
      this.formVisible = false
      this.formType = 0
      this.flowRoleId = data.flowRoleId
    },
    delet(data) {
      this.flowRoleId = data.flowRoleId
      this.$Reconfirm({
        title: '删除角色',
        content: '此角色关联请假、出差审批，确定删除？'
      }).then(async() => {
        const { flowRoleId } = this
        const { code } = await deleteFlowRole({ flowRoleId })
        if (code !== 100) return
        this.$message.success('删除成功')
        this.getRoleList()
      }).catch(() => {
        console.log('no')
      })
    },
    getSearchCallback() {},
    getInit() {
      this.formVisible = true
      this.getRoleList()
    }
  }
}
</script>

<style lang="less" scoped>
.Role {
  height:100%;
  background-color: #fff;
  padding: 20px 20px 0;
  &-header {
    height: 50px;
    padding: 0 10px;
    margin: 0 0 20px 0;
    font-size: 18px;
    background: @sc-primary-10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon {
      color: @sc-primary-100;
      cursor: pointer;
    }
    span {
      font-size: 14px;
      font-weight: 700;
      vertical-align: text-bottom;
    }
  }
}
.more-handle-btn {
  display: flex;
  flex-direction: column;
  .ant-btn {
    height: 25px;
  }
}
.more-handle {
  font-weight: 700;
  line-height: 44px;
  font-size: 20px;
  vertical-align: baseline;
  cursor: pointer;
  margin-left: 5px;
  color: @sc-primary-100;
}
.operation-btn-box{
  button{
    padding-left:0;
    padding-right:16px;
  }
}
</style>
