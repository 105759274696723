<template>
  <div>
    <Modal :visible="visible" :closable="true" size="normal" :title="modalTitle" ok-text="保存" @ok="handleOk" @cancel="handleCancel">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="离职员工" prop="onJobId">
          <perTreeSelect ref="perTree" v-model="form.onJobId" :disabled="!isAdd" :per-tree-data="getCanLeaveStaff" />
        </a-form-model-item>
        <a-form-model-item label="计划离职日期" prop="planLeaveDate">
          <a-date-picker v-model="form.planLeaveDate" :disabled="!isAdd" value-format="YYYY-MM-DD" placeholder="请选择时间" />
        </a-form-model-item>
        <a-form-model-item label="离职方式" prop="handleType">
          <dictionariesInput v-model="form.handleType" :disabled="!isAdd" parameter="handle_type" />
        </a-form-model-item>
        <a-form-model-item label="离职原因" prop="leaveReasons">
          <dictionariesInput v-model="form.leaveReasons" :disabled="!isAdd" parameter="leave_reasons" />
        </a-form-model-item>
        <a-form-model-item v-if="isAdd" label="离职备注" prop="remark">
          <a-input v-model="form.remark" type="textarea" :auto-size="true" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item v-else label="离职日期" prop="newplanLeaveDate">
          <a-date-picker v-model="form.newplanLeaveDate" value-format="YYYY-MM-DD" placeholder="请选择时间" />
        </a-form-model-item>
      </a-form-model>
    </Modal>
  </div>
</template>

<script>
import dictionariesInput from '@/components/CbDropDownInput/dictionariesInput/index'
import perTreeSelect from '@/components/CbPerDepSelection/perTreeSelect/index'
import { addQuitEntry, getCanLeaveStaff, updatePlanLeaveDateWaitLeave } from '@/services/user/quit.js'
export default {
  components: {
    dictionariesInput,
    perTreeSelect,
    Modal: () => import('@/components/CbModal/index')
  },
  props: {
    modalTitle: {
      type: String,
      default: ''
    },
    isAdd: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      visible: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      labelColC: { span: 7 },
      wrapperColC: { span: 17 },
      form: {},
      rules: {
        onJobId: [
          {
            required: true,
            message: '请选择离职员工',
            trigger: 'change'
          }
        ],
        planLeaveDate: [
          {
            required: true,
            message: '计划离职日期',
            trigger: 'change'
          }
        ],
        handleType: [
          {
            required: true,
            message: '请选择离职方式',
            trigger: 'change'
          }
        ],
        leaveReasons: [
          {
            required: true,
            message: '请选择离职原因',
            trigger: 'change'
          }
        ],
        newplanLeaveDate: [
          {
            required: true,
            message: '请选择最新的离职日期',
            trigger: 'change'
          }
        ]
      },
      leaveApplyId: '' // 离职申请id
    }
  },

  computed: {
    mode() {
      // 根据id自动计算新增、编辑
      return this.form.id ? 'update' : 'add'
    }

  },
  methods: {
    // 打开弹窗
    open(scope) {
      if (scope) {
        const { name, planLeaveDate, handleTypeName, leaveReasonsName, leaveApplyId } = scope.record
        this.form.onJobId = name
        this.form.planLeaveDate = planLeaveDate
        this.form.handleType = handleTypeName
        this.form.leaveReasons = leaveReasonsName
        this.leaveApplyId = leaveApplyId
        this.rules.onJobId[0].required = false
        this.rules.planLeaveDate[0].required = false
        this.rules.handleType[0].required = false
        this.rules.leaveReasons[0].required = false
      }
      this.visible = true
    },
    async getCanLeaveStaff() {
      const res = await getCanLeaveStaff()
      return res.data
    },
    // 保存事件
    handleOk() {
      this.$refs.ruleForm.validate(async(valid) => {
        const nowTime = new Date().getTime()
        const toDay = new Date(this.form.planLeaveDate).getTime()
        if (valid) {
          if (this.isAdd) {
            const res = await addQuitEntry(this.form)
            if (res.code === 100) {
              this.$message.success('添加成功')
              this.$emit('getFetchDataApiFn')
              this.visible = false
              this.resetForm()
              if (nowTime >= toDay) {
                this.getCanLeaveStaff()
                if (this.$refs.perTree) {
                  this.$refs.perTree.getDataList()
                }
              }
            }
          } else {
            const obj = {
              planLeaveDate: this.form.newplanLeaveDate,
              leaveApplyId: this.leaveApplyId
            }
            const res = await updatePlanLeaveDateWaitLeave(obj)
            if (res.code === 100) {
              this.$message.success('修改成功')
              this.$emit('getFetchDataApiFn')
              this.visible = false
              this.resetForm()
            }
          }
        }
      })
    },

    // 取消事件
    handleCancel() {
      this.visible = false
      this.resetForm()
    },
    // 重置表单内容和验证
    resetForm() {
      this.form = {}
      this.$refs.ruleForm.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
.ant-calendar-picker{
  width: 100%;
}
</style>
