<template>
  <div class="MovementModal">
    <Modal
      :closable="true"
      :title="'移动'"
      :visible="visible"
      :size="'normal'"
      :mask-closable="false"
      @ok="handleOk"
      @cancel="() => { visible = false }"
    >
      <a-tree-select
        v-model="value"
        style="width: 100%"
        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
        :tree-data="treeData"
        placeholder="请选择上级组织"
        tree-default-expand-all
        :replace-fields="{children:'children', title:'orgName', key:'orgId', value: 'orgId' }"
        @select="treeChange"
      >
        <!-- <span v-if="key === '0-0-1'" slot="title" slot-scope="{ key, value }" style="color: #08c">
          Child Node1 {{ value }}
        </span> -->
      </a-tree-select>
    </Modal>
  </div>
</template>

<script>
import { moveOrg } from '@/services/organization/framework.js'
export default {
  name: 'MovementModal',
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  props: {},
  data() {
    return {
      visible: false,
      value: '',
      treeData: [],
      orgId: '', // 需要移动的id
      parentId: '' // 移动到新的id
    }
  },
  methods: {
    open(arr, orgId) {
      this.value = null
      this.orgId = orgId
      const treeData = JSON.parse(JSON.stringify(arr))
      this.filtrationData(treeData, orgId)
      this.treeData = treeData
      this.visible = true
    },
    filtrationData(arr, orgId) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].orgId === orgId) {
          arr.splice(i, 1)
          break
        }
        if (arr[i].children && arr[i].children.length > 0) {
          this.filtrationData(arr[i].children, orgId)
        }
      }
    },
    select(selectedKeys, e) {
      this.parentId = e.$node.data.props.orgId
    },
    async moveOrg(params) {
      const { code } = await moveOrg(params)
      if (code !== 100) return
      this.visible = false
      this.$emit('moveSuccess')
    },
    treeChange(parentId) {
      this.parentId = parentId
    },
    handleOk() {
      const { orgId, parentId } = this
      const params = {
        orgId,
        parentId
      }
      this.moveOrg(params)
    }
  }
}
</script>

<style lang="less" scoped>

</style>
