<template>
  <div class="record-table">
    <div v-if="!dragValue">
      <div class="time">
        <svg class="iconpark-icon"><use href="#rongqi" /></svg>
        <a-range-picker v-model="recordTime" :allow-clear="clear" value-format="YYYY-MM-DD" separator="~" :ranges="ranges" @change="onChange" />
      </div>
      <div class="record-table-heard">
        <div class="heard record-table-heard-left">
          <appForm>
            <template slot="customButton">
              <a-dropdown type="primary">
                <template #overlay>
                  <a-menu>
                    <a-menu-item v-for="(item, index) in functionList" :key="index" @click="add(item)">{{ item.name }}</a-menu-item>
                  </a-menu>
                </template>
                <a-button prefix="add-one" suffix="left">
                  添加打卡记录
                </a-button>
              </a-dropdown>
            </template>
          </appForm>
          <a-button class="deleteBtn" prefix="reduce-one" :disabled="selectedRowKeys.length===0?true:false" @click="openDeleteBox">
            批量删除
          </a-button>
        </div>
        <div class="left">
          <appForm
            ref="appForm"
            :to-list="formList"
            search-placeholder="请搜索姓名/工号"
            :screen-roster-list="screenRecordList"
            @getSearchCallback="getSearchCallback"
            @screenRosterParams="screenRosterParams"
            @exportFileList="exportFileList"
          />
        </div>
      </div>
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        row-key="staffClockId"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          columnWidth: 30,
          onChange: onSelectChange,
          getCheckboxProps: (record) => ({
            props: {
              disabled: !['1','2','5'].includes(record.punchSourceType),
            },
          }),
        }"
        :pagination="{
          current: pager.pageNum,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条数据`,
          showQuickJumper: true,
          showSizeChanger: true
        }"
        :selection-mode="selectionMode"
        @change="haedChang"
      >
        <!-- 序号 -->
        <template slot="index" slot-scope="{ scope }">
          <span>{{ scope.index + 1 }}</span>
        </template>
        <!-- 打卡来源插槽 -->
        <template slot="punchSourceType" slot-scope="{ scope }">
          <div>{{ getCodeContent(scope.record.punchSourceType,punch_source) }}</div>
        </template>
        <!-- 打卡结果 -->
        <template slot="punchResultType" slot-scope="{ scope }">
          <div>{{ getCodeContent(scope.record.punchResultType,punch_result) }}</div>
        </template>
        <template slot="staffName" slot-scope="{ scope }">
          <CbTableAvater :table-name="scope.record.staffName" :on-job-id="scope.record.onJobId" :staff-id="scope.record.staffId" :title="scope.record.staffName" />
        </template>
        <template slot="photo" slot-scope="{ scope }">
          <span>
            <img v-if="scope.record.clockPicId" class="imgBox" :src="`${basePath}/hrsaas-storage/image/thumbnail?id=${scope.record.clockPicId}&width=30&height=30`" alt="" @click="preview(scope)">
          </span>
        </template>
        <!-- 操作列 -->
        <template slot="operation" slot-scope="{scope}">
          <a-button :disabled="!['1','2','5'].includes(scope.record.punchSourceType)" type="link" @click="edit(scope.record)">编辑</a-button>
        </template>
      </custom-table>
      <Modal
        :z-index="8"
        :closable="true"
        :visible="addRecord"
        :title="addName === 'add'?'添加打卡记录':'编辑打卡时间'"
        size="normal"
        @after-close="afterVisibleChange"
        @cancel="onClose"
        @ok="onSubmin"
        @enterPress="onSubmin"
      >
        <k-form-build
          ref="KFB"
          :value="addIndividuallyList"
          :text-display="true"
          @change="changeForm"
        />
      </Modal>
    </div>
    <ExcelImport v-else :title="importTitle" import-template="clock" @toggleValue="toggleValue" />
    <!-- 打卡图片放大 -->
    <Modal :visible="previewVisible" title="打卡图片查看" closable keyboard :footer="null" @cancel="previewVisible = false">
      <!-- <img alt="example" class="" :src="`${basePath}/hrsaas-storage/image/thumbnail?id=1538802112679452673&width=200&height=200`"> -->
      <div class="model_img" :style="`background-image:url(${basePath}/hrsaas-storage/image/thumbnail?id=${previewImageId}&width=200&height=200)`" />
    </Modal>
  </div>
</template>
<script>
import mixins from '@/components/CustomTable/index.js'
import moment from 'moment'
import { getStaffInfo } from '@/services/user/roster.js'
import { exportStaffClock, getstaffClockPageList, editStaffClockTime, addStaffClock, delStaffClock } from '@/services/attendance/attendance.js'

export default {
  name: 'Clock',
  components: {
    Modal: () => import('@/components/CbModal/index'),
    ExcelImport: () => import('../../components/excelImport.vue'),
    CbTableAvater: () => import('@/components/CbTableAvater/index')
  },
  filters: {
  },
  mixins: [mixins],
  data() {
    return {
      punch_result: [],
      punch_source: [],
      basePath: process.env.VUE_APP_API_BASE_URL,
      previewVisible: false,
      previewImageId: '',
      importTitle: '批量导入打卡记录',
      dragValue: false,
      clear: false,
      ranges: {
        '上月': [
          moment().month(moment().month() - 1).startOf('month'),
          moment().month(moment().month() - 1).endOf('month')
        ],
        '近三月': [
          moment()
            .startOf('day')
            .subtract(3, 'months'),
          moment()
        ],
        '近六月': [
          moment()
            .startOf('day')
            .subtract(6, 'months'),
          moment()
        ],

        '近一年': [
          moment()
            .startOf('day')
            .subtract(1, 'years'),
          moment()
        ]
      },
      addName: '',
      addRecord: false,
      visible: true,
      functionList: [
        { name: '单个添加', label: 'addSingle' },
        { name: '批量导入', label: 'addAll' }
      ],
      formList: ['search', 'export', 'screen'], // search搜索框 screen筛选 export导出
      // 时间段
      recordTime: [moment().startOf('month'), moment().endOf('month')],
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'index' },
          width: 50,
          key: 'index',
          align: 'center',
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          key: 'staffName',
          width: 105,
          fixed: 'left',
          scopedSlots: { customRender: 'staffName' }
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          fixed: 'left',
          width: 100,
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          fixed: 'left',
          width: 100,
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          fixed: 'left',
          width: 100,
          ellipsis: true
        },

        {
          title: '上班日期',
          dataIndex: 'clockDate',
          key: 'clockDate',
          width: 100,
          sorter: true,
          ellipsis: true
        },
        {
          title: '班次',
          dataIndex: 'shiftName',
          key: 'shiftName',
          width: 200,
          ellipsis: true
        },
        {
          title: '打卡时间',
          dataIndex: 'clockTime',
          key: 'clockTime',
          width: 100,
          sorter: true,
          ellipsis: true
        },
        {
          title: '打卡来源',
          dataIndex: 'punchSourceType',
          width: 100,
          scopedSlots: { customRender: 'punchSourceType' },
          key: 'punchSourceType',
          ellipsis: true
        },
        {
          title: '打卡结果',
          dataIndex: 'punchResultType',
          key: 'punchResultType',
          width: 160,
          scopedSlots: { customRender: 'punchResultType' },
          ellipsis: true
        },
        {
          title: '打卡设备',
          dataIndex: 'clockDevice',
          key: 'clockDevice',
          width: 200,
          ellipsis: true
        },
        {
          title: '打卡地点',
          dataIndex: 'locationName',
          key: 'locationName',
          width: 200,
          ellipsis: true
        },
        {
          title: '打卡拍照',
          dataIndex: 'photo',
          key: 'photo',
          width: 200,
          scopedSlots: { customRender: 'photo' },
          ellipsis: true
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark',
          width: 170,
          ellipsis: true
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operation' },
          fixed: 'right',
          width: 50
        }
      ],
      screenRecordList: {
        'list': [
          {
            'type': 'selectDictionaries',
            'label': '员工状态',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'staff_status',
              'clearable': true,
              'placeholder': '请选择员工状态',
              'showSearch': false
            },
            'model': 'staffStatuses',
            'key': 'staffStatuses',
            'rules': [
              {
                'required': false,
                'message': '请选择'
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '打卡结果',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择打卡结果',
              'showSearch': false,
              'parameter': 'punch_result_type'
            },
            'model': 'punchResultType',
            'key': 'punchResultType',
            'rules': [
              {
                'required': false,
                'message': '请选择'
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'engage_mode',
              'clearable': true,
              'placeholder': '请选择聘用形式',
              'showSearch': false
            },
            'model': 'engageModes',
            'key': 'engageModes',
            'rules': [
              {
                'required': false,
                'message': '请选择'
              }
            ]
          },
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'width': '100%',
              'multiple': true,
              'disabled': false,
              'clearable': true,
              'hidden': false,
              'placeholder': '请选择部门',
              'dynamicKey': 'department',
              'defaultValue': undefined,
              'dynamic': false,
              'showSearch': false
            },
            'model': 'orgIds',
            'key': 'orgIds',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'selectPost',
            'label': '岗位',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'post_type',
              'clearable': true,
              'placeholder': '请选择岗位',
              'showSearch': false
            },
            'model': 'postIds',
            'key': 'postIds',
            'rules': [
              {
                'required': false,
                'message': '请选择'
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '合同公司',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择合同公司',
              'showSearch': false,
              'parameter': 'contract_company'
            },
            'model': 'contractCo',
            'key': 'contractCo',
            'rules': [
              {
                'required': false,
                'message': '请选择'
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '工作地点',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'work_address',
              'clearable': true,
              'placeholder': '请选择工作地点',
              'showSearch': false
            },
            'model': 'workplaceIds',
            'key': 'workplaceIds',
            'rules': [
              {
                'required': false,
                'message': '请选择'
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '打卡来源',
            'options': {
              'width': '100%',
              'multiple': true,
              'disabled': false,
              'clearable': false,
              'hidden': false,
              'parameter': 'punch_source_type',
              'placeholder': '请选择打卡来源',
              'dynamicKey': '',
              'dynamic': false,
              'showSearch': false
            },
            'model': 'punchSourceType',
            'key': 'punchSourceType',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 80,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      // 单个添加表单
      addIndividuallyList: {
        'list': [
          {
            'type': 'selectStaff',
            'label': '员工姓名',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择员工姓名',
              'showSearch': false
            },
            'model': 'onJobId',
            'key': 'onJobId',
            'rules': [
              {
                'required': true,
                'message': '请选择员工姓名'
              }
            ]
          },
          {
            'type': 'date',
            'label': '打卡日期',
            'options': {
              'width': '100%',
              'defaultValue': '',
              'rangeDefaultValue': [],
              'range': false,
              'showTime': false,
              'disabled': false,
              'hidden': false,
              'clearable': false,
              'disabledDate': (val) => { return val.valueOf() >= new Date().valueOf() },
              'placeholder': '请选择打卡日期',
              'rangePlaceholder': [
                '开始时间',
                '结束时间'
              ],
              'format': 'YYYY-MM-DD'
            },
            'model': 'clockDate',
            'key': 'clockDate',
            'help': '',
            'rules': [
              {
                'required': true,
                'message': '请选择打卡日期'
              }
            ]
          },
          {
            'type': 'time',
            'label': '打卡时间',
            'options': {
              'width': '100%',
              'defaultValue': '',
              'disabled': false,
              'hidden': false,
              'clearable': false,
              'placeholder': '请选择打卡时间',
              'format': 'HH:mm',
              disabledHours: [],
              disabledMinutes: []
            },
            'model': 'clockTime',
            'key': 'clockTime',
            'help': '',
            'rules': [
              {
                'required': true,
                'message': '请选择打卡时间'
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      dataSource: [], // 数据
      // 选中列表集合
      selectedRowKeys: [],
      selectedRows: [],
      selectionMode: true,
      orgids: [],
      newOrg: [],
      staffClockId: '',
      startDate: '',
      endDate: '',
      deleteIds: [],
      params: {
        staffId: '',
        onJobId: '',
        clockDate: '',
        clockTime: '',
        remark: ''
      }
    }
  },
  async created() {
    this.punch_result = await this.getTenantCode('punch_result_type')
    this.punch_source = await this.getTenantCode('punch_source_type')
  },
  methods: {
    preview(scope) {
      this.previewImageId = scope.record.clockPicId
      this.previewVisible = true
    },
    range(start, end) {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    },
    changeForm(val, key) {
      if (key === 'onJobId') {
        if (val) {
          getStaffInfo({ onJobId: val }).then(res => {
            if (res.code === 100) {
              this.params.staffId = res.data.staffId
              this.params.onJobId = val
            }
          })
        }
      } else if (key === 'clockDate') {
        this.disabled(val)
        this.params.clockDate = val
      } else if (key === 'clockTime') {
        this.params.clockTime = val
      }
    },
    // 禁用时分
    disabled(val) {
      if (val === moment(new Date()).format('YYYY-MM-DD')) {
        const hours = new Date().getHours()
        const minutes = new Date().getMinutes()
        const arr = () => this.range(0, 24).splice(hours + 1, 24 - hours)
        const arr1 = () => this.range(0, 60).splice(minutes + 1, 60)
        this.addIndividuallyList.list[2].options.disabledHours = arr()
        this.addIndividuallyList.list[2].options.disabledMinutes = arr1(hours)
        // this.$nextTick(() => {
        //   this.$refs.KFB.setData({ clockTime: undefined })
        // })
      } else {
        this.addIndividuallyList.list[2].options.disabledHours = []
        this.addIndividuallyList.list[2].options.disabledMinutes = []
      }
    },
    // 周期时间变化 考勤数据相应变化
    onChange(date) {
      const startDate = date[0]
      const endDate = date[1]
      this.startDate = date[0]
      this.endDate = date[1]
      this.serchGetData(startDate, endDate)
    },
    toggleValue(val) {
      this.dragValue = val
      this.fetchDataApiFn(this.pager)
    },
    // 获取列表信息
    async fetchDataApiFn(param) {
      const startDate = moment(this.recordTime[0]).format('yyyy-MM-DD')
      const endDate = moment(this.recordTime[1]).format('yyyy-MM-DD')
      const res = await getstaffClockPageList({ ...param, startDate, endDate })
      this.dataSource = res.data.records ? res.data.records : []
      this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
      this.total = res.data.total
    },
    onClose() {
      this.addRecord = false
      this.$refs.KFB.reset()
    },
    // 查询回调
    getSearchCallback(data) {
      this._queryParam.searchWord = data.searchValue
      this.serchGetData()
    },
    // 筛选回调
    screenRosterParams(data) {
      this._queryParam.searchWord = data.searchValue
      if (data.screenCondition.orgIds) {
        this.orgids = data.screenCondition.orgIds
        this.newOrg = this.orgids.map(item => {
          return item.value
        })
        this._queryParam.orgIds = this.newOrg.join(',')
      }
      this._queryParam.staffStatuses = data.screenCondition.staffStatuses ? data.screenCondition.staffStatuses.join(',') : ''
      this._queryParam.punchResultType = data.screenCondition.punchResultType ? data.screenCondition.punchResultType.join(',') : ''
      this._queryParam.postIds = data.screenCondition.postIds ? data.screenCondition.postIds.join(',') : ''
      this._queryParam.engageModes = data.screenCondition.engageModes ? data.screenCondition.engageModes.join(',') : ''
      this._queryParam.punchSourceType = data.screenCondition.punchSourceType ? data.screenCondition.punchSourceType.join(',') : ''
      this._queryParam.workplaceIds = data.screenCondition.workplaceIds ? data.screenCondition.workplaceIds.join(',') : ''
      this._queryParam.contractCo = data.screenCondition.contractCo ? data.screenCondition.contractCo : ''
      for (var key in this._queryParam) {
        if (!this._queryParam[key]) {
          delete this._queryParam[key]
        }
      }
      this.serchGetData()
    },
    // 导出功能
    exportFileList() {
      const startDate = moment(this.recordTime[0]).format('yyyy-MM-DD') || this.startDate
      const endDate = moment(this.recordTime[1]).format('yyyy-MM-DD') || this.endDate
      const { searchWord, staffStatus, punchResultType, engageModes, orgIds, postIds, contractCo, clockLocationId, punchSourceType } = this._queryParam
      exportStaffClock({
        searchWord: searchWord,
        staffStatus: staffStatus,
        punchResultType: punchResultType,
        engageModes: engageModes,
        orgIds: orgIds,
        postIds: postIds,
        contractCo: contractCo,
        clockLocationId: clockLocationId,
        punchSourceType: punchSourceType,
        startDate,
        endDate
      })
    },
    // 删除按钮
    openDeleteBox() {
      this.deleteIds = []
      this.selectedRows.forEach(item => {
        this.deleteIds.push(item.staffClockId)
      })
      this.$Reconfirm({
        title: '删除考勤信息',
        content: '确定要删除该打卡信息吗？'
      }).then(() => {
        delStaffClock({ staffClockId: this.deleteIds }).then(res => {
          if (res.code === 100) {
            this.$message.success('删除成功')
            this.selectedRowKeys = []
            this.selectedRows = []
            this.serchGetData()
          }
        })
      }).catch(() => {
        this.$message.info('已取消删除')
      })
    },
    // 导入表格
    changeRecord() {
      this.dragRecord = false
      this.addRecord = true
    },
    // 新增
    add(val) {
      this.addName = 'add'
      if (val.label === 'addSingle') {
        this.addRecord = true
        this.addIndividuallyList.list[0].options.disabled = false
        this.addIndividuallyList.list[1].options.disabled = false
      } else {
        this.dragValue = true
        this.addRecord = false
      }
    },
    // 编辑
    edit(scope) {
      this.disabled(scope.clockDate)
      this.addRecord = true
      this.addName = 'edit'
      this.staffClockId = scope.staffClockId
      this.addIndividuallyList.list[0].options.disabled = true
      this.addIndividuallyList.list[1].options.disabled = true
      this.$nextTick(() => {
        const { onJobId, clockDate, clockTime, remark } = scope
        this.$refs.KFB.setData({ onJobId, clockDate, clockTime })
        this.params.clockDate = clockDate
        this.params.clockTime = clockTime
        this.params.remark = remark
      })
    },
    // 添加、编辑
    async onSubmin() {
      const params = await this.$refs.KFB.getData()
      console.log(params)
      params.staffId = this.params.staffId
      if (this.addName === 'add') {
        const res = await addStaffClock(params)
        if (res.code === 100) {
          this.$message.success('添加成功')
        }
      } else {
        const res = await editStaffClockTime({ staffClockId: this.staffClockId, ...params	})
        if (res.code === 100) {
          this.$message.success('修改成功')
        }
      }
      this.onClose()
      this.serchGetData()
    },
    afterVisibleChange() {
      this.addRecord = false
    },
    disabledDate(current) { // 不可选择未来日期
      return current > moment().endOf('day')
    },
    // 表格选择项变更
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    haedChang(pagination, filters, sorter) {
      // console.log(sorter)
      this._queryParam = {
        ...this._queryParam,
        ...this.pager,
        ...filters,
        sort: sorter.order && sorter.order === 'descend' ? sorter.field + ' ' + 'desc' : sorter.field + ' ' + ' asc'
      }
      if (sorter.order) {
        if (sorter.field === 'clockDate') {
          this._queryParam['sortDate'] = true
          this._queryParam['sortDateAsc'] = this._queryParam.sort === 'clockDate  asc'
        } else if (sorter.field === 'clockTime') {
          this._queryParam['sortTime'] = true
          this._queryParam['sortTimeAsc'] = this._queryParam.sort === 'clockTime  asc'
        }
        delete this._queryParam.sort
      } else if (this._queryParam.sort) {
        delete this._queryParam.sort
        delete this._queryParam.sortDate
        delete this._queryParam.sortDateAsc
        delete this._queryParam.sortTime
        delete this._queryParam.sortTimeAsc
      }
      this._queryParam.pageNum = pagination.current
      this._queryParam.pageSize = pagination.pageSize
      this.pager.pageNum = pagination.current
      this.pager.pageSize = pagination.pageSize
      this.total = pagination.total
      this.$$fetchListWithLoading(this._queryParam)
    }
  }
}
</script>

<style lang="less" scoped>
.model_img{
  width:100%;
  height:400px;
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.imgBox{
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.ant-table td { white-space: nowrap; }
.record-table{
  width: 100%;
  height: calc(100% - 50px);
  overflow: hidden;
  /deep/.ant-pagination{

    .ant-pagination-options-size-changer.ant-select {
      margin-right: 10px;
    }
  }
}
.record-table-heard-left{
  display: flex;
}
.record-table-heard{
  display: flex;
  justify-content: space-between;
}

.time{
    width: 240px;
    font-size: 16px;
    margin-bottom: 16px;
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 14px;
    background: @sc-greyBg-10;
    border-radius: 4px;
    /deep/.ant-calendar-picker{
      width: 100%;
      .ant-calendar-picker-input .ant-input {
        background: @sc-greyBg-10;
        border: none;
      }
      .ant-calendar-range-picker-input {
        width: 46%;
        color: @sc-grey-100;
      }
    }
  .iconpark-icon{
    width: 20px;
    height: 20px;
    color: @sc-primary-100;
  }
}
/deep/ .time .ant-input {
  border: 0;
  background:  @sc-greyBg-10;
  &:hover .ant-calendar-range-picker-input,
  &:hover .ant-calendar-range-picker-separator {
    color:@sc-primary-100 !important;
    cursor: pointer;
  }
}
.deleteBtn{
  margin-left: 12px;
}
</style>
