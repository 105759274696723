<template>
  <div class="selectTenant">
    <template v-for="(key, index) in tenantLst">
      <div :key="`tenant${index}`" class="tenantItem" :class="{'active': index === selectIndex}" @click="selectTenant(key, index)">
        {{ key.tenantName }}
        <a-icon v-if="index === selectIndex" type="arrow-right" class="arrow" />
      </div>
    </template>
  </div>
</template>

<script>
import { getUserTenantList, loginTenant } from '@/services/login'
import { afterLogin } from './publicMixins'
import publicMixins from './publicMixins'
export default {
  name: 'SelectTenant',
  mixins: [afterLogin, publicMixins],
  data() {
    return {
      selectIndex: '',
      tenantLst: []
    }
  },
  async mounted() {
    const res = await getUserTenantList()
    this.tenantLst = res.data
  },
  methods: {
    async selectTenant(item, index) {
      this.selectIndex = index
      // this.tenantLst[index]
      const res = await loginTenant({ tenantId: item.tenantId })
      this.changeLoginType(1)
      this.afterLogin(res.data)
    }
  }
}
</script>

<style lang="less" scoped>
.selectTenant{
  height: 300px;
  overflow: auto;
}
.tenantItem{
  margin-bottom: 24px;
  background-color: rgba(143, 147, 161, .1);
  color: #202A40;
  padding: 15px 16px;
  .font-size(18px);
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .arrow{
    font-size: 16px;
  }
}
.active{
  border: 1px solid #507FF7;
  background-color: rgba(80, 127, 247, 0.1);
}
</style>
