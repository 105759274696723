<template>
  <div class="AnnualForm">
    <Drawer
      closable
      :is-mask="false"
      :z-index="100"
      :visible="visible"
      :title="`${mode === 'add' ? '新增' : '编辑'}年终奖`"
      size="normal"
      @close="visible = false"
      @onSubmit="handleOk"
    >
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="所属年份" prop="year">
          <CbYearPicker v-model="form.year" :disabled="mode!=='add'" :disabled-date="disabledYear" :allow-clear="false" />
        </a-form-model-item>
        <a-form-model-item label="发放人员">
          <!-- <a-button @click="model = true">
            <svg class="iconpark-icon"><use href="#piliangdaoru" /></svg>
            选择部门和员工
          </a-button> -->
          <SelectPerson
            v-model="model"
            :query-leave-person="true"
            :data-echo="form.staffList"
            :placeholder="'请输入部门、人员、工号'"
            @perModelConfirm="perModelConfirm"
          />
        </a-form-model-item>
        <a-form-model-item label="预计发放金额">
          <CbNumber v-model="form.estimateMount" :decimal-separator="true" precision="2" :is-fill-zero="true" :maxlength="10" placeholder="请输入数字" />
        </a-form-model-item>
      </a-form-model>
    </Drawer>
    <!-- <PerModelSelect
      v-model="model"
      :data-echo="form.staffList"
      :placeholder="'请输入部门、人员、工号'"
      @perModelConfirm="perModelConfirm"
    /> -->
  </div>
</template>

<script>
import { createYearendBouns, updateYearendBouns, getYearendBouns } from '@/services/wage/yearendBouns.js'
import { debounce } from '@/utils/index.js'
import moment from 'moment'
export default {
  name: 'AnnualForm',
  components: {
    Drawer: () => import('@/components/CbDrawer'),
    CbNumber: () => import('@/components/CbNumber'),
    CbYearPicker: () => import('@/components/CbYearPicker'),
    SelectPerson: () => import('@/components/CbSelectPersons/index.vue')
  },
  data() {
    return {
      visible: false,
      model: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      yearendBounsId: '',
      form: {
        year: '',
        staffList: []
      },
      // 限制当年及3年以前年份数组
      disabledYears: [
        moment().format('YYYY'),
        moment().subtract(1, 'year').format('YYYY'),
        moment().subtract(2, 'year').format('YYYY'),
        moment().subtract(3, 'year').format('YYYY')
      ],
      rules: {
        year: [
          {
            required: true,
            message: '请选择所属年份',
            trigger: 'change'
          }
        ]
      }
    }
  },
  computed: {
    mode() {
      // 根据id自动计算新增、编辑
      return this.yearendBounsId ? 'edit' : 'add'
    }
  },
  methods: {
    async getDetail() {
      const res = await getYearendBouns({ yearendBounsId: this.yearendBounsId })
      this.form = res.data
    },
    perModelConfirm(data) {
      this.form.staffList = data.map((v) => {
        return {
          onJobId: v.onJobId,
          staffName: v.staffName,
          staffId: v.staffId
        }
      })
      this.model = false
    },
    // 打开弹窗
    open(yearendBounsId) {
      this.yearendBounsId = yearendBounsId
      this.visible = true
      this.resetForm()
      if (yearendBounsId) {
        this.getDetail()
      }
    },
    // 保存事件
    handleOk() {
      if (!this.form.staffList.length) {
        return this.$message.error('请选择员工！')
      }
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          if (this.mode === 'add') {
            return this.submitAdd()
          }
          return this.submitEdit()
        }
        this.$message.error(`${this.mode === 'add' ? '新增' : '编辑'}信息不完整！`)
      })
    },
    // 确认添加
    submitAdd: debounce(async function() {
      const res = await createYearendBouns({ ...this.form, staffList: this.form.staffList.map((v) => { return v.onJobId }) })
      if (res.code === 100) {
        this.$message.success('创建成功！')
        this.visible = false
        this.$parent.initData()
      }
    }, 500),
    // 确认编辑
    submitEdit: debounce(async function() {
      const res = await updateYearendBouns({ ...this.form, staffList: this.form.staffList.map((v) => { return v.onJobId }) })
      if (res.code === 100) {
        this.$message.success('编辑成功！')
        this.visible = false
        this.$parent.initData()
      }
    }, 500),
    // 重置表单内容和验证
    resetForm() {
      this.form = { staffList: [] }
      this.$refs.ruleForm?.resetFields()
    },
    // 得到年份选择器的值
    disabledYear(year) {
      return { flag: !this.disabledYears.includes(year), msg: '可选当前年份及之前3年的年份' }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-form-item-label > label {
  color: @sc-grey-80;
}
/deep/.ant-calendar-picker-container-placement-bottomRight{
  top: 0;
  left: 0;
}
.iconpark-icon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  margin-bottom: -4px;
  color: @sc-primary-100;
}
.data {
   width: 100%;
}
</style>
