<template>
  <div class="emils-seting">
    <div class="v-title">发送邮箱设置</div>
    <div class="card-list">
      <a-card v-for="item in emailList" :key="item.emailId" class="antd-card">
        <a-tag v-if="item.defaultStatus === 1" class="antd-tag" color="#5179FB"> 默认 </a-tag>
        <a-popover class="antd-icon">
          <template slot="content">
            <p class="cursor" @click="openVisible('edit', item.emailId, item)">编辑</p>
            <a-popconfirm placement="left" title="是否确认删除？" ok-text="确认" cancel-text="取消" @confirm="getDeleteTenantEmail(item.emailId)">
              <p class="del_p">删除</p>
            </a-popconfirm>
            <a-popconfirm placement="left" title="是否确认设为默认？" ok-text="确认" cancel-text="取消" @confirm="getSetDefaultEmail(item.emailId)">
              <p class="cursor">设为默认</p>
            </a-popconfirm>
          </template>
          <a-icon class="antd-icon" type="ellipsis" />
        </a-popover>
        <a-card-meta>
          <template slot="description">
            <div class="v-content-title">{{ item.email }}<a-tag v-if="item.bindStatus === 1">已绑定</a-tag></div>
            <div class="v-send-person">发送人：{{ item.sender }}</div>
          </template>
        </a-card-meta>
      </a-card>

      <a-card class="antd-card-add" @click="openVisible('add')">
        <div><a-icon type="plus" /><span>添加</span></div>
      </a-card>
    </div>
    <Drawer
      :title="title"
      :closable="false"
      :visible="visible"
      size="small"
      @close="onClose"
      @onSubmit="onSubmin"
    >
      <k-form-build
        ref="KFB"
        :value="jsonData"
        :text-display="textDisplay"
      />
    </Drawer>
  </div>
</template>

<script>
import {
  getList,
  deleteTenantEmail,
  setDefaultEmail,
  checkEmail,
  createTenantEmail,
  updateTenantEmail
} from '@/services/user/setting.js'
import Drawer from '@/components/CbDrawer/index'
export default {
  components: {
    Drawer
  },
  data() {
    return {
      emailList: [],
      visible: false,
      textDisplay: true,
      title: '添加邮箱',
      status: 'add',
      emailId: '',
      jsonData: {
        'list': [
          {
            'type': 'input',
            'label': '邮箱',
            'options': {
              'type': 'text',
              'width': '100%',
              'defaultValue': '',
              'placeholder': '请输入',
              'clearable': true,
              'maxLength': null,
              'addonBefore': '',
              'addonAfter': '',
              'hidden': false,
              'disabled': false
            },
            'model': 'email',
            'key': 'input_1651128619572',
            'help': '',
            'rules': [
              {
                'required': true,
                'message': '请输入邮箱'
              }
            ]
          },
          {
            'type': 'input',
            'label': '密码',
            'icon': 'icon-write',
            'options': {
              'type': 'password',
              'width': '100%',
              'defaultValue': '',
              'placeholder': '请输入',
              'clearable': true,
              'maxLength': null,
              'addonBefore': '',
              'addonAfter': '',
              'hidden': false,
              'disabled': false
            },
            'model': 'password',
            'key': 'input_1651128689700',
            'help': '',
            'rules': [
              {
                'required': true,
                'message': '请输入密码'
              }
            ]
          },
          {
            'type': 'input',
            'label': '发件人',
            'icon': 'icon-write',
            'options': {
              'type': 'text',
              'width': '100%',
              'defaultValue': '',
              'placeholder': '请输入',
              'clearable': true,
              'maxLength': null,
              'addonBefore': '',
              'addonAfter': '',
              'hidden': false,
              'disabled': false
            },
            'model': 'sender',
            'key': 'input_1651128749867',
            'help': ''
          },
          {
            'type': 'input',
            'label': '服务器地址',
            'icon': 'icon-write',
            'options': {
              'type': 'text',
              'width': '100%',
              'defaultValue': '',
              'placeholder': 'SMTP服务器地址',
              'clearable': true,
              'maxLength': null,
              'addonBefore': '',
              'addonAfter': '',
              'hidden': false,
              'disabled': false
            },
            'model': 'host',
            'key': 'input_1651128755835',
            'help': '',
            'rules': [
              {
                'required': true,
                'message': '请输入SMTP服务器地址'
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 获取邮箱列表
    async getList() {
      const res = await getList()
      this.emailList = res.data
    },
    // 删除
    async getDeleteTenantEmail(emailId) {
      const res = await deleteTenantEmail({ emailId: emailId })
      if (res.code === 100) {
        this.$message.success('删除成功')
        this.getList()
      }
    },
    // 默认
    async getSetDefaultEmail(emailId) {
      const res = await setDefaultEmail({ emailId: emailId })
      if (res.code === 100) {
        this.$message.success('设置默认成功')
        this.getList()
      }
    },
    // 开启弹窗
    openVisible(data, emailId, formData) {
      this.visible = true
      this.title = data === 'add' ? '添加邮箱' : '编辑邮箱'
      this.status = data
      this.emailId = emailId
      this.$nextTick(() => {
        if (formData) this.$refs.KFB.setData(formData)
      })
    },
    // 关闭弹窗
    onClose() {
      this.visible = false
      this.$refs.KFB.reset()
    },
    // 确认
    async onSubmin() {
      const params = await this.$refs.KFB.getData().catch(() => {})
      if (!params) return // 当无返回值的时候 return
      const param = {
        email: params.email,
        password: params.password,
        sender: params.sender,
        host: params.host,
        emailId: this.emailId
      }
      const res = await checkEmail(param).catch(err => { console.log('err', err) })
      if (res.code === 100) {
        if (this.status === 'add') {
          const rt = await createTenantEmail(param).catch(err => { console.log('err', err) })
          if (rt.code === 100) {
            this.$message.success('邮箱添加成功')
            this.visible = false
            this.$refs.KFB.reset()
            this.getList()
          }
        } else {
          const rts = await updateTenantEmail(param).catch(err => { console.log('err', err) })
          if (rts.code === 100) {
            this.$message.success('邮箱更新成功')
            this.visible = false
            this.$refs.KFB.reset()
            this.getList()
          }
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.v-title {
  // margin: 10px;
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
  color: @sc-grey-100;
}
.v-content-title {
  margin-top: 10px;
  font-size: 14px;
  color: @sc-grey-100;
  font-weight: normal;
}
.v-send-person {
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal;
  color: @sc-grey-60;
}
.emailCount {
  font-size: 20px;
  color: @sc-grey-100;
}
.card-list {
  display: flex;
}
.antd-card {
  width: 255px;
  height: 100px;
  margin: 10px;
  margin-left: 0;
  background: @sc-greyBg-10;
  text-align: center;
  border-radius: 4px;
}
.emils-seting{
  padding-left: 20px;
  padding-right: 20px;
  height:100%;
  overflow: auto;
}
.antd-card-add {
  width: 300px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed @sc-greyBg-100;
  border-radius: 4px;
  margin-left: 20px;
  margin-top: 10px;
  &:hover{
    cursor: pointer;
    border-color: @sc-primary-100;
    color: @sc-primary-100;
  }
}
.antd-tag {
  position: absolute;
  top: 0px;
  left: 0px;
}
.antd-icon {
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 20px;
  cursor: pointer;
}
/deep/ .ant-card-meta-description {
  text-align: left;
}
  .cursor {
    cursor: pointer;
    &:hover{
      color: @sc-primary-100;
    }
  }
.del_p{
  cursor: pointer;
  &:hover{
    color: @error-color;
  }
}
</style>
