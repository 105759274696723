import { render, staticRenderFns } from "./adjustment.vue?vue&type=template&id=4740e882&scoped=true&"
import script from "./adjustment.vue?vue&type=script&lang=js&"
export * from "./adjustment.vue?vue&type=script&lang=js&"
import style0 from "./adjustment.vue?vue&type=style&index=0&id=4740e882&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4740e882",
  null
  
)

export default component.exports