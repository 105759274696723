<!--
 * @Date: 2022-07-06 09:43:00
 * @descriotion:
-->
<template>
  <div>
    <Drawer
      :closable="false"
      :visible="visible"
      size="normal"
      :title="`导出${data.label?data.label:''}`"
      @close="onClose"
      @onSubmit="submit"
    >
      <div class="content">
        <ImportHeader :list="list" />
        <ImportList @change="setHeader" />
      </div>
    </Drawer>
  </div>
</template>
<script>
export default {
  name: 'ImportDrawer',
  components: {
    ImportHeader: () => import('../component/importHeader.vue'),
    ImportList: () => import('../component/importList.vue'),
    Drawer: () => import('@/components/CbDrawer/index')
  },
  data() {
    return {
      visible: false,
      data: {},
      list: []
    }
  },
  methods: {
    onClose() {
      this.visible = false
    },
    onShow(data) {
      this.data = data
      this.visible = true
    },
    submit() {

    },
    setHeader(data) {
      this.list = data
    }
  }
}
</script>
<style lang="less" scoped>
.content{
    width:100%;
    height: 100%;
    overflow: auto;
    padding-bottom:50px;
    position:relative;
    .font-size(14px);
}
</style>
