<template>
  <div class="Approvalcenter">
    <approvalcenterHead ref="approvalcenterHead" @getType="getType" />
    <appForm
      ref="appForm"
      width="400"
      :to-list="formList"
      :search-style="searchStyle"
      search-placeholder="请输入审批编号、姓名、部门"
      :screen-roster-list="screenRosterList"
      @getSearchCallback="getSearchCallback"
      @screenRosterParams="screenRosterParams"
      @exportFileList="exportFileList"
    />
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      table-ref=".Approvalcenter"
      :row-key="'approveNum'"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="loadDataList"
    >
      <template slot="initiatorName" slot-scope="{scope}">
        <CbTableAvater
          :table-name="scope.record.initiatorName"
          :on-job-id="scope.record.initiatorId"
        />
      </template>
      <!-- 状态 -->
      <template slot="processApproveStatus" slot-scope="{ scope }">
        <StatusTag
          :type="scope.record.processApproveStatus"
          :type-list="statusNameList"
          @click="getCbApproveSteps(scope.record.flowRecordId, scope.record.processApproveStatus)"
        />
      </template>
      <!-- 操作 -->
      <template slot="operation" slot-scope="{ scope }">
        <span v-if="['inapprove', 'exception'].includes(scope.record.processApproveStatus)" class="operation-btn-box">
          <a-button type="link" @click="transferApproval(scope.record)"> 转交  </a-button>
          <a-button type="link" @click="downloadDoc(scope.record)"> 下载审批单 </a-button>
        </span>
        <a-button v-else type="link" @click="downloadDoc(scope.record)"> 下载审批单 </a-button>
        <!-- <div v-if="['inapprove', 'exception'].includes(scope.record.processApproveStatus)" class="flex-align-center">
          <a-popover placement="bottom" trigger="hover">
            <template slot="content">
              <div class="more-handle-btn">
                放在迭代需求里面
                <a-button v-if="scope.record.processApproveStatus !== 'inapprove'" type="link" @click="deletApproval(scope.record)"> 删除 </a-button>
                <a-button v-else type="link"> 撤回 </a-button>
                <a-button
                  type="link"
                  @click="transferApproval(scope.record)"
                >
                  转交
                </a-button>
                <a-button type="link" @click="stopApproval(scope.record)"> 终止 </a-button>
                <a-button type="link" @click="downloadDoc(scope.record)"> 下载审批单 </a-button>
              </div>
            </template>
            <div class="more-handle">···</div>
          </a-popover>
        </div>
        <a-button v-else type="link" @click="downloadDoc(scope.record)"> 下载审批单 </a-button> -->
      </template>
    </custom-table>
    <TransferModal ref="transferModal" @change="changTransfer" />
    <!-- 审批状态的时间轴 -->
    <CbApproveSteps
      v-model="stepsVisible"
      :flow-id="flowId"
      :apply-status="applyStatus"
      :apply-status-list="statusNameList"
    />
  </div>
</template>

<script>
import {
  getflowRecordList,
  deleteflowRecord,
  stopRecord,
  downloadFlowDoc,
  groupStatistics,
  exportFlowRecord } from '@/services/logicFlow'
import { getFlowRecordDetail } from '@/services/approveSteps/approveSteps.js'
import mixins from '@/components/CustomTable/index.js'
import { formateData } from '@/components/CbApproveSteps/formateFormData.js'
const apprpverStatus = [
  { label: '审批中', value: 'inapprove' },
  { label: '等待HR干预', value: 'exception' },
  { label: '审批终止', value: 'exceptionend' },
  { label: '撤销审批', value: 'revokeapprove' },
  { label: '未通过', value: 'rejectapprove' },
  { label: '已通过', value: 'completeapprove' }
]

export default {
  name: 'Approvalcenter',
  components: {
    CbTableAvater: () => import('@/components/CbTableAvater/index.vue'),
    approvalcenterHead: () => import('./approvalcenterHeadl'),
    TransferModal: () => import('./transferModall'),
    StatusTag: () => import('@/components/CbStatusTag'),
    CbApproveSteps: () => import('@/components/CbApproveSteps')
  },
  mixins: [mixins],
  data() {
    return {
      stepsVisible: false,
      flowId: '',
      applyStatus: '',
      total: 1,
      searchStyle: { width: '250px' },
      formList: ['search', 'export', 'screen'], // search搜索框 screen筛选 choice位置选择弹窗 export导出
      columns: [],
      columnsALL: [
        {
          title: '审批编号',
          dataIndex: 'approveNum',
          key: 'approveNum',
          width: 120,
          ellipsis: true
        },
        {
          title: '事项名称',
          dataIndex: 'flowName',
          key: 'flowName',
          width: 120,
          ellipsis: true
        },
        {
          title: '审批类型',
          dataIndex: 'flowGroupName',
          key: 'flowGroupName',
          width: 120
        },
        {
          title: '发起人',
          dataIndex: 'initiatorName',
          key: 'initiatorName',
          scopedSlots: { customRender: 'initiatorName' },
          width: 105
        },
        {
          title: '发起人部门',
          dataIndex: 'initiatorOrgName',
          key: 'initiatorOrgName',
          width: 120,
          ellipsis: true
        },
        {
          title: '审批发起时间',
          dataIndex: 'startTime',
          width: 120,
          key: 'startTime'
        },
        {
          title: '当前审批人',
          dataIndex: 'approverName',
          key: 'approverName',
          width: 120,
          ellipsis: true
        },
        {
          title: '最后操作时间',
          dataIndex: 'endTime',
          width: 120,
          key: 'endTime'
        },
        {
          title: '审批状态',
          dataIndex: 'processApproveStatus',
          key: 'processApproveStatus',
          width: 120,
          scopedSlots: { customRender: 'processApproveStatus' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '160px'
        }
      ],
      columnsSelectStatue: [
        {
          title: '审批编号',
          dataIndex: 'approveNum',
          key: 'approveNum',
          scopedSlots: { customRender: 'approveNum' },
          ellipsis: true
        },
        {
          title: '事项名称',
          dataIndex: 'flowName',
          key: 'flowName',
          ellipsis: true
        },
        {
          title: '发起人',
          dataIndex: 'initiatorName',
          key: 'initiatorName',
          scopedSlots: { customRender: 'initiatorName' },
          width: 105
        },
        {
          title: '发起人部门',
          dataIndex: 'initiatorOrgName',
          key: 'initiatorOrgName',
          ellipsis: true
        },
        {
          title: '审批发起时间',
          dataIndex: 'startTime',
          key: 'startTime'
        },
        {
          title: '当前审批人',
          dataIndex: 'approverName',
          key: 'approverName',
          ellipsis: true
        },
        {
          title: '最后操作时间',
          dataIndex: 'endTime',
          key: 'endTime'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '160px'
        }
      ],
      columnsSelectType: [
        {
          title: '审批编号',
          dataIndex: 'approveNum',
          key: 'approveNum',
          ellipsis: true
        },
        {
          title: '事项名称',
          dataIndex: 'flowName',
          key: 'flowName',
          ellipsis: true
        },
        {
          title: '发起人',
          dataIndex: 'initiatorName',
          key: 'initiatorName',
          scopedSlots: { customRender: 'initiatorName' },
          width: 105
        },
        {
          title: '发起人部门',
          dataIndex: 'initiatorOrgName',
          key: 'initiatorOrgName',
          ellipsis: true
        },
        {
          title: '审批发起时间',
          dataIndex: 'startTime',
          key: 'startTime'
        },
        {
          title: '当前审批人',
          dataIndex: 'approverName',
          key: 'approverName',
          ellipsis: true
        },
        {
          title: '最后操作时间',
          dataIndex: 'endTime',
          key: 'endTime'
        },
        {
          title: '审批状态',
          dataIndex: 'processApproveStatus',
          key: 'processApproveStatus',
          scopedSlots: { customRender: 'processApproveStatus' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '160px'
        }
      ],
      dataSource: [],
      loading: false,
      screenRosterList: {
        'list': [
          {
            'type': 'selectStaff',
            'label': '发起人',
            'options': {
              'width': '100%',
              'multiple': false,
              'disabled': false,
              'clearable': true,
              'hidden': false,
              'placeholder': '请选择发起人',
              'dynamicKey': 'initiatorName',
              'defaultValue': undefined,
              'dynamic': false,
              'options': [],
              'showSearch': false
            },
            'model': 'initiatorName',
            'key': 'initiatorName',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': '必填项'
              }
            ]
          },
          {
            'type': 'selectDepartment',
            'label': '发起人部门',
            'options': {
              'width': '100%',
              'multiple': false,
              'disabled': false,
              'clearable': true,
              'hidden': false,
              'placeholder': '请选择发起人部门',
              'dynamicKey': 'initiatorOrgId',
              'defaultValue': undefined,
              'dynamic': false,
              'options': [{ label: '1', value: 2 }],
              'showSearch': true
            },
            'model': 'initiatorOrgId',
            'key': 'initiatorOrgId',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': '必填项'
              }
            ]
          },
          {
            'type': 'selectStaff',
            'label': '当前审批人',
            'options': {
              'width': '100%',
              'multiple': false,
              'disabled': false,
              'clearable': true,
              'hidden': false,
              'placeholder': '请选择当前审批人',
              'dynamicKey': 'queryApproverId',
              'defaultValue': undefined,
              'dynamic': false,
              'options': [],
              'showSearch': false
            },
            'model': 'queryApproverId',
            'key': 'queryApproverId',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': '必填项'
              }
            ]
          },
          {
            'type': 'selectDataRange',
            'label': '审批发起时间',
            'options': {
              'width': '100%',
              'defaultValue': [undefined, undefined],
              'rangePlaceholder': ['开始时间', '结束时间']
            },
            'model': 'startTime',
            'key': 'date_1651214034712',
            'help': ''
          },
          {
            'type': 'selectDataRange',
            'label': '最后操作时间',
            'options': {
              'width': '100%',
              'defaultValue': [],
              'range': true,
              'showTime': false,
              'disabled': false,
              'hidden': false,
              'clearable': true,
              'placeholder': '请选择',
              'rangePlaceholder': ['开始时间', '结束时间'],
              'format': 'YYYY-MM-DD'
            },
            'model': 'endTime',
            'key': 'date_1651214034712',
            'help': ''
          },
          {
            'type': 'select',
            'label': '审批状态',
            'options': {
              'width': '100%',
              'multiple': false,
              'disabled': false,
              'clearable': true,
              'hidden': false,
              'placeholder': '请选择审批状态',
              'dynamicKey': 'processApproveStatus',
              'defaultValue': undefined,
              'dynamic': false,
              'options': apprpverStatus,
              'showSearch': false
            },
            'model': 'processApproveStatus',
            'key': 'processApproveStatus',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': '必填项'
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      statusNameList: {
        inapprove: { name: '审批中' },
        exception: { name: '等待HR干预' },
        exceptionend: { name: '审批终止' },
        revokeapprove: { name: '撤销审批' },
        rejectapprove: { name: '未通过' },
        completeapprove: { name: '已通过' }
      },
      flowRecordId: '' // 审批记录id
    }
  },
  mounted() {
    this.initStatisticsOpption()
  },
  methods: {
    async initStatisticsOpption() {
      const res = await groupStatistics()
      if (res.code === 100) {
        this.screenRosterList.list[0].options.options = res.data.map((item) => {
          return {
            label: item.groupName,
            value: item.flowGroupId
          }
        })
      }
    },
    async fetchDataApiFn(data) {
      const res = await getflowRecordList(data)
      if (res.code === 100) {
        this.dataSource = res.data.records
        if (data.flowStatus && !data.flowGroupId) {
          this.columns = this.columnsSelectStatue
        } else if (!data.flowStatus && data.flowGroupId) {
          this.columns = this.columnsSelectType
        } else {
          this.columns = this.columnsALL
        }
        this.total = res.data.total
        this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
      }
    },
    deletApproval(data) {
      this.flowRecordId = data.flowRecordId
      this.$Reconfirm({
        title: '删除',
        content: '该审批单将被删除且不可恢复，确认删除？'
      }).then(async() => {
        const { flowRecordId } = this
        const { code } = await deleteflowRecord({ flowRecordId })
        if (code !== 100) return
        this.$message.success('删除成功')
        this.serchGetData()
      }).catch(() => {
        console.log('no')
      })
    },
    stopApproval(data) {
      this.flowRecordId = data.flowRecordId
      this.$Reconfirm({
        title: '终止',
        content: '确定终止当前审批吗？'
      }).then(async() => {
        const { flowRecordId } = this
        const { code } = await stopRecord({ flowRecordId })
        if (code !== 100) return
        this.$message.success('终止成功')
        this.serchGetData()
      }).catch(() => {
        console.log('no')
      })
    },
    // 审批步骤条
    getCbApproveSteps(data, val) {
      this.flowId = data
      this.applyStatus = val
      this.stepsVisible = true
    },
    changTransfer() {
      this.$refs.approvalcenterHead.statistics()
      this.serchGetData()
    },
    transferApproval(data) {
      this.$refs.transferModal.openModal(data)
    },
    // 查询回调
    getSearchCallback(data) {
      this._queryParam.searchWord = data.searchValue
      this.serchGetData()
    },
    // 组合参数给筛选和导出使用
    combinationParams(data) {
      const { endTime, initiatorName, initiatorOrgId, processApproveStatus, queryApproverId, startTime } = data.screenCondition
      const { flowStatus, flowGroupId } = this._queryParam
      this._queryParam = {
        searchWord: data.searchValue,
        flowGroupId,
        flowStatus,
        initiatorId: initiatorName,
        initiatorOrgId: initiatorOrgId && initiatorOrgId[0] ? initiatorOrgId[0].value : '',
        processApproveStatus,
        approverId: queryApproverId,
        startTimeStart: startTime ? startTime[0] : '',
        startTimeEnd: startTime ? startTime[1] : '',
        endTimeStart: endTime ? endTime[0] : '',
        endTimeEnd: endTime ? endTime[1] : ''
      }
    },
    // 筛选回调
    screenRosterParams(data) {
      this.combinationParams(data)
      this.serchGetData()
    },
    exportFileList() {
      exportFlowRecord(this._queryParam)
    },
    // 点击头部的类型回调
    getType(status, groupId) {
      this.$refs.appForm.refresh()
      this._queryParam = {
        pageNum: 1,
        pageSize: 10
      }
      // status存在就代表是选择了状态之一的分类
      this.screenRosterList.list[5].options.hidden = Boolean(status)
      this._queryParam.flowStatus = status
      this._queryParam.flowGroupId = groupId
      this.fetchDataApiFn(this._queryParam)
    },
    async downloadDoc(data) {
      const { flowRecordId } = data
      const res = await getFlowRecordDetail({ flowRecordId })
      const { formData, formDesign } = res.data
      const { list } = formDesign
      const formDataRes = []
      formateData(formData, list, true, formDataRes)
      // 因为明细数据格式和打印的数据格式不同需要给数据平铺出来才行
      const newformDataRes = []
      formDataRes.forEach(item => {
        if (item.list) {
          item.list.forEach((key, index) => {
            newformDataRes.push({ name: item.title + index })
            key.forEach(k => {
              newformDataRes.push(k)
            })
          })
          item.summaryList.forEach(k => {
            newformDataRes.push({
              name: k.label,
              value: k.value
            })
          })
        } else {
          newformDataRes.push(item)
        }
      })
      downloadFlowDoc({ formData: newformDataRes, flowRecordId })
    }
  }
}
</script>

<style lang="less" scoped>
.Approvalcenter {
  height: 100%;
  background-color: #fff;
  padding: 20px 20px 0;
}
.more-handle-btn {
  display: flex;
  flex-direction: column;
  .ant-btn {
    padding: 0;
  }
}
.more-handle {
  font-weight: 700;
  line-height: 22px;
  font-size: 20px;
  cursor: pointer;
  vertical-align: baseline;
  color: @sc-primary-100;
}
.operation-btn-box{
  display:flex;
  button{
    padding: 0;
    margin-right: 16px;
    &:last-child{
      margin: 0;
    }
  }
}
</style>
