<template>
  <div class="forget-password login-form">
    <div class="form-item">
      <Number v-model.trim="globForm.phoneNum" type="text" prefix="+86" />
    </div>
    <div class="form-item">
      <GetCheckCode
        v-model.trim="globForm.code"
        :can-use="String(globForm.phoneNum).length !== 0"
        @getCode="getCode"
      />
    </div>
    <div>
      <a-button
        class="submit"
        type="primary"
        :disabled="canUse"
        @click="nextStep"
        @enterPress="nextStep"
      >下一步</a-button>
    </div>
    <!-- 滑块验证 -->
    <div v-if="visible" class="dialog">
      <div class="dialogContent">
        <div class="title">
          <p>拖动下方滑块完成拼图</p>
          <div class="icon">
            <a-icon type="redo" @click="onRefresh" />
            <a-icon type="close" @click="visible = false" />
          </div>
        </div>
        <slideverify
          ref="dialogopen"
          :l="puzzePass.smallHeight"
          :w="puzzePass.bigWidth"
          :h="puzzePass.bigHeight"
          :blocky="puzzePass.posY"
          :imgurl="puzzePass.bigImageBase64"
          :miniimgurl="puzzePass.smallImageBase64"
          :slider-text="puzzePass.text"
          @success="onSuccess"
          @fail="onFail"
          @refresh="onRefresh"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GetCheckCode from '@/components/GetCheckCode'
import Number from '@/components/CbNumber'
import { passwordGetActivateCode, doValidateCode, getImagesApi, checkImagesApi } from '@/services/login'
import publicMixins from './publicMixins'
import { phoneReg } from '@/utils/reg.js'
import slideverify from '@/components/CbSlideverify/index.vue'
export default {
  name: 'ForgetPassword',
  inject: ['globForm'],
  components: {
    GetCheckCode,
    Number,
    slideverify
  },
  mixins: [publicMixins],
  data() {
    return {
      visible: false,
      puzzePass: {},
      cutdown: null
    }
  },
  computed: {
    canUse() {
      const { phoneNum, code } = this.globForm
      return !(phoneNum && code)
    }
  },
  methods: {
    async nextStep() {
      if (this.canUse) return
      const { phoneNum, code, uuid } = this.globForm
      if (!phoneNum) return this.$message.warning('请输入手机号')
      if (!phoneReg.test(phoneNum)) return this.$message.warning('手机号码格式错误，请检查')
      if (!code) return this.$message.warning('请输入验证码')
      const res = await doValidateCode({ phoneNum, code, uuid })
      console.log(res)
      this.setLoginType(4)
    },
    async getCode(cutdown) {
      const { phoneNum } = this.globForm
      if (!phoneNum) return this.$message.warning('请输入手机号')
      if (phoneReg.test(phoneNum)) {
        this.getImage()
        this.cutdown = cutdown
      } else {
        this.$message.warning('手机号码格式错误，请检查')
      }
    },
    async onSuccess(left) {
      const res = await checkImagesApi({ uuid: this.puzzePass.uuid, moveX: left })
      if (res.code === 100) {
        if (res.data) {
          this.visible = false
          const { phoneNum } = this.globForm
          const res = await passwordGetActivateCode({ phoneNum })
          this.globForm.uuid = res.data
          this.cutdown()
        } else {
          this.$message.error('验证失败，重新验证')
          this.onRefresh()
        }
      }
    },
    onFail() {
      this.msg = ''
    },
    onRefresh() {
      this.getImage()
    },
    getImage() {
      getImagesApi({}).then(res => {
        if (res.code === 100) {
          res.data.bigImageBase64 = 'data:image/png;base64,' + res.data.bigImageBase64
          res.data.smallImageBase64 = 'data:image/png;base64,' + res.data.smallImageBase64
        }
        this.puzzePass = res.data
        this.visible = true
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./index.less');
.dialog{
  position: fixed;
  width:100%;
  height:100vh;
  top:0;
  left:0;
  z-index: 999;
  background: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: center;
  .dialogContent{
    display: inline-block;
    padding: 10px;
    background: #fff;
    border-radius: 6px;
    .title{
      display: flex;
      justify-content: space-between;
      margin-bottom:16px;
      align-items: center;
      p{
        margin:0;
        font-weight: bold;
        font-size:16px;
      }
    }
    .icon{
      /deep/.anticon{
        width:16px;
        height:16px;
        margin-left:16px;
        cursor: pointer;
      }
    }
  }
}
</style>
