<template>
  <div class="organ-report-p">
    <div v-for="item in reportList" :key="item.title" class="report-p-multi">
      <panelTable :title="item.title" :table-list="item.column" />
    </div>
  </div>
</template>

<script>
import panelTable from '@/components/CbReportForm/panelTable.vue'
import { getReportColumn } from '@/services/organization/reportForm.js'
export default {
  components: {
    panelTable
  },
  data() {
    return {
      reportList: []
    }
  },
  mounted() {
    this.initReport()
  },
  methods: {
    // 初始化报表页面
    initReport() {
      console.log('9306000')
      getReportColumn({
        moduleCode: 'org'
      }).then((res) => {
        if (res.code === 100) {
          this.reportList = res.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.organ-report-p {
  overflow: auto;
  background-color: #fff;
  min-height: 100%;
  padding: 20px 20px 0;
  .report-p-multi + .report-p-multi {
    margin-top: 30px;
  }
}
/deep/ .ant-table-thead > tr > th {
  color: @sc-grey-100;
  font-size: 14px;
}
/deep/ .ant-table-tbody > tr > td {
  color:  @sc-grey-80;
  font-size: 14px;
}
/deep/ .report-table-panel .more-handle {
  font-weight: 700;
  font-size: 20px;
  color:  @sc-primary-100;
  margin-left: 5px;
}
/deep/ .report-table-panel .report-panel-title {
  margin-top: -3px;
}
/deep/ .report-table-panel .report-panel-title:before {
  height: 16px;
}
/deep/ .report-table-panel .report-panel-tb {
  padding-left: 0;
}
/deep/ .report-table-panel .report-panel-tb .ant-table-header-column {
  margin-left: 0;
}
</style>
