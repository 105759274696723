<template>
  <div class="contaier">
    <div class="care-center">
      <div class="year">
        <a-button prefix="oval-love-two-66g9caap" @click="onClick">
          <!-- <svg class="iconpark-icon Circle-Button"><use href="#oval-love-two-66g9caap" /></svg> -->
          设置祝福
        </a-button>
        {{ val ? '生日月份' : '入职月份' }}：
        <a-month-picker v-model="yearMonth" format="MM" :allow-clear="false" placeholder="请选择" @change="onChange" />
      </div>
      <!-- <appForm
        :to-list="toList"
        width="400"
        search-placeholder="请输入员工姓名/手机号"
        @getSearchCallback="getSearchCallback"
      /> -->
    </div>
    <custom-table
      :columns="val ? columnsBirth : columnsYear"
      :data-source="dataSource"
      :row-key="'onJobId'"
      :tabs-height="36"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true,
      }"
      @change="loadDataList"
    >
      <!-- 姓名 -->
      <template slot="staffName" slot-scope="{ scope }">
        <CbTableAvater
          :on-job-id="scope.record.onJobId"
          :staff-id="scope.record.staffId"
          :table-name="scope.record.staffName"
        />
      </template>
      <!-- 聘用形式 -->
      <template slot="engageMode" slot-scope="{ scope }">
        <div>{{ getCodeContent(scope.record.engageMode,engage_mode) }}</div>
      </template>
      <template slot="companyAge" slot-scope="{ scope }">
        <div>{{ scope.record.companyAge }}年</div>
      </template>
      <!-- 发送状态 -->
      <template slot="receiveStatus" slot-scope="{ scope }">
        <div>{{ scope.record.receiveStatus === 1 ? '已接收' : '未接收' }}</div>
      </template>
    </custom-table>
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import { getBirthList, getYearList } from '@/services/culture/care.js'
import moment from 'moment'
export default {
  name: 'Table',
  components: {
    // appForm: () => import('@/components/CbAppForm/index.vue'),
    CbTableAvater: () => import('@/components/CbTableAvater/index.vue')
  },
  mixins: [mixins],
  props: {
    keyVal: {
      type: String,
      default: 'birthday'
    }
  },
  data() {
    return {
      yearMonth: moment(),
      result: [],
      yearObj: {},
      dataSource: [], // 数据
      toList: ['search'], // search搜索框 screen筛选 choice位置选择弹窗 export导出
      columnsBirth: [
        {
          title: '姓名',
          dataIndex: 'staffName',
          scopedSlots: { customRender: 'staffName' },
          width: 105
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          ellipsis: true
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          ellipsis: true
        },
        {
          title: '聘用形式',
          dataIndex: 'engageMode',
          key: 'engageMode',
          scopedSlots: { customRender: 'engageMode' }
        },
        {
          title: '出生日期',
          dataIndex: 'birthDate',
          key: 'birthDate',
          ellipsis: true
        },
        {
          title: '接收状态',
          dataIndex: 'receiveStatus',
          key: 'receiveStatus',
          scopedSlots: { customRender: 'receiveStatus' }
        }
      ],
      columnsYear: [
        {
          title: '姓名',
          dataIndex: 'staffName',
          scopedSlots: { customRender: 'staffName' },
          width: 105
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          ellipsis: true
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          ellipsis: true
        },
        {
          title: '聘用形式',
          dataIndex: 'engageMode',
          key: 'engageMode',
          scopedSlots: { customRender: 'engageMode' }
        },
        {
          title: '入职日期',
          dataIndex: 'entryDate',
          key: 'entryDate',
          ellipsis: true
        },
        {
          title: '司龄',
          dataIndex: 'companyAge',
          key: 'companyAge',
          scopedSlots: { customRender: 'companyAge' },
          ellipsis: true
        },
        {
          title: '接收状态',
          dataIndex: 'receiveStatus',
          key: 'receiveStatus',
          scopedSlots: { customRender: 'receiveStatus' },
          ellipsis: true
        }
      ],
      engage_mode: []
    }
  },
  computed: {
    val() {
      return this.keyVal === 'birthday'
    },
    month() {
      return moment(this.yearMonth).format('YYYY-MM')
    }
  },
  watch: {
    yearMonth(val) {
      this.fetchDataApiFn()
    }
  },
  async created() {
    this.engage_mode = await this.getTenantCode('engage_mode')
  },
  methods: {
    // 获取列表信息
    async fetchDataApiFn(param) {
      if (this.val) {
        const res = await getBirthList({ ...param, yearMonth: this.month })
        this.dataSource = res.data ? res.data : []
      } else if (!this.val) {
        const res = await getYearList({ ...param, yearMonth: this.month })
        this.dataSource = res.data ? res.data : []
      }
    },
    // 搜索 关键字查询姓名和手机号
    getSearchCallback(val) {
      this._queryParam.keyWord = val.searchValue
      this.serchGetData()
    },
    onClick() {
      this.$emit('onShow')
    },
    // 选择月份
    onChange(date, month) {
      this.yearMonth = date
    }
  }
}
</script>
<style scoped lang="less">
.care-center {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .year {
      color: @sc-grey-100;
      .font-size(14px);
      /deep/.ant-btn{
        border: none;
        background-color: @sc-primary-100;
        margin-right: 12px;
        color: #FFF;
        &:hover{
          background: @sc-primary-80;
        }
      }
    }
  }
/deep/.prefix{
  color: #FFF !important;
}
</style>
