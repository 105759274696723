<template>
  <div id="methodPunch" class="methodpunch">
    <a-tabs default-active-key="1" @change="callback">
      <a-tab-pane key="1" tab="地址">
        <Address />
      </a-tab-pane>
      <a-tab-pane key="2" tab="WIFI">
        <Wifi />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import Address from './address.vue'
import Wifi from './wifi.vue'
export default {
  name: 'MethodPunch',
  components: {
    Address,
    Wifi
  },
  data() {
    return {
      keyval: '1'
    }
  },
  methods: {
    callback(key) {
      this.keyval = key
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.ant-tabs-bar{
  margin:0;
}
.methodpunch{
  width:100%;
  height: 100%;
  background-color: #fff;
  overflow: auto;
  padding: 20px 20px 0;
}
/deep/.ant-tabs{
  height: 100%;
  .ant-tabs-top-content{
    height: 100%;
  }
}
/deep/.ant-tabs-tab{
  color: @sc-grey-100;
  font-size: 16px;
  padding-top: 0;
}
/deep/.ant-tabs-tab-active{
  color: @sc-primary-100 !important;
}
</style>
