<template>
  <div class="ScannInvitation">
    <HeaderBack title="邀请员工开通" @back="$emit('back')" />
    <div class="ScannInvitation-content">
      <!-- <div class="rule-tip flex-align-center">
        自动生成工号，可在设置中进行规则设置
        <svg-icon class="icon" icon-class="exclamation" />
        员工已有工号会被替换为自动生成的工号
      </div> -->
      <div class="steps">
        <div v-for="(step,index) in stepsList" :key="index" class="step">
          <div class="step-items">
            <span class="num">{{ index+1 }}</span>
            <span class="text">{{ step }}</span>
          </div>
        </div>
      </div>
      <div class="invitation-container">
        <div class="invitation-box">
          <div class="invitation_box_left">
            <img :src="url.img" alt="" class="invitation-box_img">
            <div>分享二维码，邀请员工快速加入企业</div>
          </div>
          <a-button type="primary" @click="download">保存二维码</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBack from '@/components/headerBack'
import { getQrCode } from '@/services/user/roster.js'
export default {
  name: 'ScannInvitation',
  components: {
    HeaderBack
  },
  data() {
    return {
      // stepsList: ['HR分享邀请二维码', '员工微信扫码登录', '验证员工提交的信息', '验证通过，邀请成功'],
      stepsList: ['HR分享邀请二维码', '员工微信扫码登录'],
      url: {}
    }
  },
  mounted() {
    this.getQrCode()
  },
  methods: {
    async getQrCode() {
      const res = await getQrCode({
        imgWidth: 120,
        imgHeight: 120
      })
      this.url = res.data
      this.url.img = 'data:image/png;base64,' + res.data.img
    },
    // 下载
    download() {
      const link = document.createElement('a')
      link.href = this.url.img
      link.download = '员工入职二维码.png'
      link.click()
    }
  }
}
</script>

<style lang="less" scoped>
  .ScannInvitation{
    padding:20px 20px;
    .header {
      margin-bottom: 20px;
    }
    &-content{
      font-size: 14px;
      .icon{
        font-size: 17px;
        margin:0 6px 0 14px;
      }
      .msg-title{
        margin: 20px 56px 16px 0;
        position: relative;
        padding-left: 10px;
        .font-size(16px);
        font-weight: bold;
        color: @sc-grey-100;
          margin-bottom: 20px;
        &:before{
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 4px;
          height: 18px;
          background-color: @sc-primary-100;
        }
      }
      .steps{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0;
        padding-bottom: 60px;
        .step {
          display: flex;
          align-items: center;
          .step-items {
            padding-right: 16px;
            background: @sc-greyBg-10;
            border-radius: 42px;
            padding: 7px 16px 7px 7px;
          }
        }
        .step::after{
          content: '';
          display:inline-block;
          width: 100px;
          height: 1px;
          margin: 0 10px;
          border: 1px solid @sc-greyBg-20;
        }
        .step:last-child:after{
          display:none;
        }
        .num{
          display: inline-block;
          width: 26px;
          height: 26px;
          line-height: 26px;
          text-align: center;
          margin-right: 10px;
          border-radius: 50%;
          background:  @sc-primary-100;
          font-size: 14px;
          color: #fff;
        }
        .text{
          color: @sc-grey-100;
          font-size: 16px;
        }
      }
      .invitation-container {
        width: 100%;
        display: flex;
        justify-content: center;
        .invitation-box{
          width: 1148px;
          padding: 15px;
          background: rgba(143, 147, 161, 0.05);
          border-radius: 8px 8px 8px 8px;
          display:flex;
          align-items: center;
          justify-content:space-between;
          .invitation_box_left {
            display: flex;
            align-items: center;
            .invitation-box_img{
              width: 120px;
              height: 120px;
              background: #fff;
              /*border-radius: 50%;*/
              margin-right: 31px;
            }
          }
        }
      }
    }
  }
  /deep/ .ant-alert-message {
    font-weight: 700;
    color: @sc-grey-100;
  }
</style>
