<!--
 * @Date: 2022-07-04 17:22:17
 * @descriotion: 账单列表
-->
<template>
  <ul class="bill-list">
    <li v-for="(item, index) in list" :key="index">
      <BillDetail ref="detail" :item="item" :month="month" @lookBill="lookBill" @importBill="importBill" />
    </li>
    <div v-if="list.length == 0" class="empty-data">
      <img :style="`width:126px`" src="@/assets/img/home/noData.png" alt="">
      <div>暂无数据，请创建账单</div>
    </div>
  </ul>
</template>
<script>
export default {
  name: 'BillList',
  components: {
    BillDetail: () => import('./component/billDetail.vue')
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    month: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  methods: {
    refresh() {
      for (let i = 0; i < this.list.length; i++) {
        this.$refs.detail[i].getData()
      }
    },
    lookBill(data) {
      console.log(data)
      this.$emit('lookBill', data)
    },
    voucher(data) {
      this.$emit('voucher', data)
    },
    importBill(data) {
      console.log(data)
      this.$emit('importBill', data)
    }
  }
}
</script>
<style lang="less" scoped>

.bill-list {
  width: 100%;
  height: auto;
  // border: 1px solid @sc-greyBg-20;
  // border-radius: 12px;
}
.empty-data {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 220px;
  div {
    font-size: 16px;
  }
}
</style>
