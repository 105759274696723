<template>
  <div class="forget-password login-form">
    <div class="form-item">
      <a-input v-model.trim="globForm.phoneNum" type="text">
        <a-icon slot="prefix" type="user" />
      </a-input>
    </div>
    <div class="form-item">
      <GetCheckCode v-model="globForm.code" :can-use="String(globForm.phoneNum).length !== 0" @getCode="getCode" />
    </div>
    <div>
      <a-button class="submit" type="primary" :disabled="canUse" @click="nextStep" @enterPress="nextStep">下一步</a-button>
    </div>
  </div>
</template>

<script>
import GetCheckCode from '@/components/GetCheckCode'
import { getActivateCode, activateTenant } from '@/services/login'
import { setAuthorization } from '@/utils/request'
import { loadRoutes } from '@/utils/routerUtil'
import { mapMutations } from 'vuex'
import { phoneReg } from '@/utils/reg.js'
export default {
  name: 'Regiter',
  inject: ['globForm'],
  components: {
    GetCheckCode
  },
  computed: {
    canUse() {
      const { phoneNum, code } = this.globForm
      return !(phoneNum && code)
    }
  },
  methods: {
    ...mapMutations('setting', ['setLoginType']),
    ...mapMutations('account', ['setUser', 'setPermissions', 'setRoles', 'setTenant']),
    async getCode(cutDown) {
      const { phoneNum } = this.globForm
      if (phoneReg.test(phoneNum)) {
        const res = await getActivateCode({ phoneNum })
        this.globForm.uuid = res.data
        cutDown()
      } else {
        this.$message.warning('手机号码格式错误，请检查')
      }
    },
    async nextStep() {
      if (this.canUse) return
      const { phoneNum, code, uuid } = this.globForm
      const params = {
        phoneNum,
        code,
        uuid
      }
      const res = await activateTenant(params)
      // 走登陆成功后的一套逻辑
      this.afterLogin(res)
    },
    afterLogin(res) {
      const { user, permissions, roles, token, tenant } = res.data
      this.setUser(user)
      this.setTenant(tenant)
      this.setPermissions(permissions)
      this.setRoles(roles)
      setAuthorization({ token, expireAt: 7 })
      // 获取路由配置
      const routesConfig = [
        {
          router: 'root',
          children: [
            {
              router: 'home'
            },
            {
              router: 'organization',
              children: ['framework', 'post', 'rank', 'reportForm']
            },
            {
              router: 'logicFlow'
            }
          ]
        }
      ]
      loadRoutes(routesConfig)
      this.setLoginType(6)
      // this.$message.success(loginRes.message, 3)
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./index.less');
</style>
