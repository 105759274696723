<template>
  <div>
    <div class="title"><div class="title-left" />{{ dataSource.insureClassifyName }}</div>
    <div class="tabs">
      <p>参保城市：<span>{{ dataSource.cityName }}</span></p>
      <p>参保主体：<span>{{ dataSource.insureCompanyName }}</span></p>
      <p>参保起始月：<span>{{ dataSource.startMonth }}</span></p>
      <!-- <p>增员原因：<span>{{ dataSource.increaseReason }}</span></p> -->
    </div>
    <custom-table
      :columns=" dataSource.insureClassifyName==='公积金'?column:columns"
      :data-source="dataSource.insureTypeList"
      :pagination="false"
      class="custom-table"
      row-key="insureCode"
      @change="loadDataList"
    >
      <!-- 公司比例 -->
      <template slot="companyScale" slot-scope="{scope}">
        <span>{{ scope.record.companyScale }}%</span>
      </template>
      <!-- 个人比例 -->
      <template slot="personScale" slot-scope="{scope}">
        <span>{{ scope.record.personScale }}%</span>
      </template>
    </custom-table>
  </div>
</template>
<script>
import mixins from '@/components/CustomTable/index.js'
export default {
  mixins: [mixins],
  props: {
    dataSource: {
      type: [Array, Object],
      default: () => []
    }
  },
  data() {
    return {
      columns: [
        {
          title: '险种',
          dataIndex: 'insureCodeName',
          key: 'insureCodeName',
          width: 100,
          customCell: () => {
            return {
              style: {
                'paddingLeft': '24px'
              }
            }
          }
        },
        {
          title: '基数',
          dataIndex: 'paymentBase',
          key: 'paymentBase',
          width: 100,
          dataType: 'money'
        },
        // 分隔间距 等距排列使用
        {
          width: 110
        },
        {
          title: '公司比例',
          dataIndex: 'companyScale',
          scopedSlots: { customRender: 'companyScale' },
          width: 100,
          key: 'companyScale'
        },
        {
          title: '公司额外固定金额',
          dataIndex: 'companyExtra',
          width: 120,
          key: 'companyExtra',
          dataType: 'money'
        },
        // 分隔间距 等距排列使用
        {
          width: 110
        },
        {
          title: '个人比例',
          dataIndex: 'personScale',
          scopedSlots: { customRender: 'personScale' },
          width: 100,
          key: 'personScale'
        },
        {
          title: '个人额外固定金额',
          dataIndex: 'personExtra',
          width: 130,
          key: 'personExtra',
          dataType: 'money',
          customCell: () => {
            return {
              style: {
                'paddingRight': '24px'
              }
            }
          }
        }
      ],
      column: [
        {
          title: '险种',
          dataIndex: 'insureCodeName',
          key: 'insureCodeName',
          width: 150,
          customCell: () => {
            return {
              style: {
                'paddingLeft': '24px'
              }
            }
          }
        },
        {
          title: '基数',
          dataIndex: 'paymentBase',
          key: 'paymentBase',
          width: 150,
          dataType: 'money'
        },
        // 分隔间距 等距排列使用
        {
          width: 200
        },
        {
          title: '公司比例',
          dataIndex: 'companyScale',
          width: 200,
          scopedSlots: { customRender: 'companyScale' },
          key: 'companyScale'
        },
        {
          title: '个人比例',
          dataIndex: 'personScale',
          scopedSlots: { customRender: 'personScale' },
          width: 200,
          key: 'personScale'
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.title{
    font-size: 16px;
    color: @sc-grey-100;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin: 20px 0 20px;
    .title-left{
        width: 3px;
        height: 14px;
        background: @sc-primary-100;
        margin-right: 6px;
    }
}
.tabs{
    display: flex;
    p{
        width: 25%;
        font-size: 16px;
        color: @sc-grey-60;
        span{
            color: @sc-grey-100;
        }
    }
}
/deep/.ant-table-thead > tr:first-child > th:first-child{
    border-top-left-radius: 10px;
    padding-left: 24px;
}
/deep/.ant-table-thead > tr:first-child > th:last-child{
    border-top-right-radius: 10px;
    padding-right: 24px;
}
/deep/.ant-table-tbody > tr:last-child > td:first-child{
    border-bottom-left-radius: 10px;
}
/deep/.ant-table-tbody > tr:last-child > td:last-child{
    border-bottom-right-radius: 10px;
}
.custom-table{
    margin-bottom: 30px;
}

</style>
