<template>
  <div>
    <Modal :visible="visible" :closable="true" size="normal" title="放弃入职" ok-text="保存" @ok="handleOk" @cancel="handleCancel">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="原因" prop="reason">
          <dictionariesInput v-model="form.reason" parameter="leave_reasons" />
        </a-form-model-item>
      </a-form-model>
    </Modal>
  </div>
</template>

<script>
// 字典下拉框
import dictionariesInput from '@/components/CbDropDownInput/dictionariesInput/index'
import { noEntry } from '@/services/user/Induction.js'
export default {
  components: {
    dictionariesInput,
    Modal: () => import('@/components/CbModal/index')

  },
  data() {
    return {
      entryApplyIds: '',
      visible: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      form: {},
      optionsReason: [
        {
          value: '1',
          label: '个人原因'
        },
        {
          value: '2',
          label: '企业原因'
        },
        {
          value: '3',
          label: '其他'
        }
      ],
      rules: {
        reason: [
          {
            required: true,
            message: '请选择原因',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    // 打开弹窗
    open(data) {
      this.visible = true
      this.entryApplyIds = data.record.entryApplyId
    },
    // 保存事件
    handleOk() {
      // eslint-disable-next-line space-before-function-paren
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.visible = false
          const res = await noEntry({
            entryApplyId: this.entryApplyIds,
            reasons: this.form.reason
          })
          if (res.code === 100) {
            this.$message.success('放弃入职成功')
            this.$emit('getFetchDataApiFn')
          }
        }
      })
    },

    // 取消事件
    handleCancel() {
      this.visible = false
      this.resetForm()
    },
    // 重置表单内容和验证
    resetForm() {
      this.form = {}
      this.$refs.ruleForm.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
</style>
