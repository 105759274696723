<template>
  <div class="AddWageFIxed">
    <Drawer
      title="新增薪资项字段"
      size="small"
      placement="right"
      :closable="false"
      :is-mask="false"
      :visible="visible"
      @close="onClose"
      @onSubmit="handleOk"
    >
      <div class="content">
        <template v-for="(item, index) in showList">
          <a-button :key="`item${index}`" class="item" :disabled="item.disabled">
            {{ item.salaryItemName }}
            <svg v-if="!item.disabled" class="iconpark-icon" @click="delAddItem(index)"><use href="#close-small" /></svg>
          </a-button>
        </template>
        <a-popover v-model="addVisible" trigger="click" placement="bottom" :get-popup-container="trigger => { return trigger.parentNode}">
          <template slot="content">
            <span class="salary-name">薪资项名称:</span>
            <a-input v-model="addName" :max-length="10" placeholder="请填写薪资项名称" class="input-salary" />
            <br>
            <div class="btn">
              <a-button type="primary" @click="add">确认</a-button>
            </div>
          </template>
          <a-button type="iconBtn" class="item" icon="add-one" />
        </a-popover>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { getSalaryItemList, createFixedSalaryItem } from '@/services/wage/wageArchives.js'
export default {
  name: 'AddWageFIxed',
  components: {
    Drawer: () => import('@/components/CbDrawer')
  },
  data() {
    return {
      visible: false,
      addVisible: false,
      fixedList: [],
      addList: [],
      addName: ''
    }
  },
  computed: {
    showList() {
      return [...this.fixedList, ...this.addList]
    }
  },
  watch: {
    addVisible: {
      handler(val) {
        if (!val) {
          this.addName = ''
        }
      }
    }
  },
  methods: {
    async show() {
      const res = await getSalaryItemList()
      this.fixedList = res.data.map(item => {
        return {
          ...item,
          disabled: true
        }
      })
      this.visible = true
    },
    onClose() {
      this.visible = false
      this.addList = []
    },
    async add() {
      if (!this.addName) {
        this.$message.warning('薪资项名称不能为空')
        this.addVisible = true
      } else {
        for (let i = 0; i < this.showList.length; i++) {
          const item = this.showList[i]
          if (item.salaryItemName === this.addName) {
            this.$message.warning('薪资固定项，名称不可重复！')
            return
          }
        }
        this.addList.push({ salaryItemName: this.addName })
        this.addVisible = false
        this.addName = ''
      }
    },
    delAddItem(index) {
      this.addList.splice(index - this.fixedList.length, 1)
    },
    async handleOk() {
      await createFixedSalaryItem(this.addList)
      this.$message.success('薪资项新增成功！')
      this.addVisible = false
      this.visible = false
      this.addList = []
      this.$emit('complate')
    }
  }
}
</script>

<style lang="less" scoped>
.content{
  margin-bottom: 40px;
  .salary-name {
    color: @sc-grey-100;
    .font-size(16px);
  }
  .input-salary {
    margin: 16px 0;
    color: @sc-grey-100;
    .font-size(16px);
    &::-webkit-input-placeholder {
      color: @sc-grey-40;
    }
  }
  .btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
.bottom{
  position: absolute;
  bottom: 20px;
}
.item{
  margin-bottom: 15px;
  margin-right: 10px;
}
/deep/ .ant-popover-inner-content {
  padding: 12px 14px 14px 14px;
}
.iconpark-icon{
  margin-left: 4px;
  z-index: 2;
}
/deep/.default:hover .iconpark-icon{
  color: @sc-error-100 !important;
}
</style>
