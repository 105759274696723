<template>
  <div class="group-steps-container">
    <HeaderBack :title="`${handlerType==='edit' ? '编辑' : '新增'}薪资组`" class="header-back" @back="goBack">
      <template #center>
        <!--  步骤条 -->
        <div class="steps-box">
          <a-steps :current="current" size="small" type="navigation" @change="stepChange">
            <a-step v-for="item in steps" :key="item.id" :title="item.title" />
          </a-steps>
        </div>
      </template>
    </HeaderBack>
    <!--  步骤条内容 -->
    <div class="steps-content">
      <div class="content">
        <BasicInfo v-if="current === 0" ref="BasicInfo" :current="current" :handler-type="handlerType" @changeGroupId="changeGroupId" />
        <SalaryPlans v-if="current === 1" :current="current" :group-id="groupId" />
        <SalaryRules v-if="current === 2" ref="SalaryRules" :current="current" @savedSuccess="goBack" />
      </div>
    </div>
    <!--  步骤条内容操作 -->
    <div class="steps-action">
      <a-space>
        <!-- <a-button v-if="current >= 0" @click="returnBack"> {{ current === 2?'完成':'保存' }} </a-button> -->
        <a-button type="primary" @click="preserveNext"> {{ current === 2?'保存':'保存且下一步' }} </a-button>
      </a-space>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupSteps',
  components: {
    HeaderBack: () => import('@/components/headerBack/index'),
    SalaryPlans: () => import('./salaryPlans/index.vue'),
    SalaryRules: () => import('./salaryRules/index.vue'),
    BasicInfo: () => import('./basicInfo/index.vue')
  },
  provide() {
    return {
      infoData: {
        groupId: undefined,
        handlerType: undefined
      }
    }
  },
  props: {
    groupId: {
      type: String,
      default: undefined
    },
    handlerType: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      // 记录点击跳转步骤条数据
      stepCurrent: 0,
      // wthch 判断改变步骤条值
      current: 0,
      steps: [
        {
          id: 1,
          title: '基本信息',
          content: 'First-content'
        },
        {
          id: 2,
          title: '薪资方案',
          content: 'Second-content'
        },
        {
          id: 3,
          title: '薪资规则',
          content: 'Last-content'
        }
      ]
    }
  },
  watch: {
    stepCurrent(newCurrent, oldCurrent) {
      console.log(newCurrent, oldCurrent)
      if (newCurrent === this.current) {
        return
      }
      // 信息填写校验 && 信息保存校验
      // 当 oldCurrent：0则当前页为基础信息 2:薪资规则
      if (oldCurrent === 0) {
        // this.$refs.BasicInfo.$refs.basicForm.validate(valid => {
        //   if (valid) {
        if (!this._provided.infoData.groupId) {
          console.log('222222')
          this.$message.warning('请先保存当前信息')
          this.stepCurrent = oldCurrent
          return
        }
        this.current = newCurrent
        // return
        //   }
        //   this.stepCurrent = oldCurrent
        //   this.$message.warning('请先完成基本信息！')
        // })
      } else if (oldCurrent === 2) {
        this.$refs.SalaryRules.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.current = newCurrent
            return
          }
          this.$message.warning('请先完成基本信息！')
        })
      } else {
        console.log('2xzzzzzzzz')
        this.current = newCurrent
      }
    }
  },
  mounted() {
    // 编辑薪资组 provide => groupId
    if (this.handlerType === 'edit') {
      this._provided.infoData.groupId = this.groupId
      this._provided.infoData.handlerType = this.handlerType
    }
  },
  methods: {
    // 步骤条改变 判断
    stepChange(current) {
      this.stepCurrent = current
    },
    // 下一步
    preserveNext() {
      if (this.current === 0) {
        this.$refs.BasicInfo.onPreserve()
      } else if (this.current === 1) {
        this.current++
        this.stepCurrent++
      } else if (this.current === 2) {
        this.$refs.SalaryRules.updateRule()
      }
    },
    // 返回
    goBack() {
      this.$emit('goBack')
    },
    // 薪资组id change改变刷新
    changeGroupId(id) {
      this._provided.infoData.groupId = id
      this.current++
      this.stepCurrent++
    }
  }
}
</script>
<style scoped lang="less">
.group-steps-container {
  height:100%;
  padding-top: 20px;
  .header-back{
    padding: 0 20px;
  }
  .steps-box {
    flex: 1;
    display: flex;
    justify-content: center;
    .ant-steps {
      width: 50vw;
    }
  }
  .steps-action {
    position: fixed;
    z-index: 1;
    bottom: 20px;
    left: 50%;
  }
  .steps-content{
    border-top: 2px solid @border-color;
    padding: 0 208px 0 200px;
    margin: 0 auto;
    box-sizing: border-box;
    .content{
      padding: 18px 20px 20px 20px;
      min-height:calc(100vh - 120px);
      border-left: 2px solid @border-color;
      border-right: 2px solid @border-color;
    }
  }
}
/deep/.ant-form-item label{
    color: @sc-grey-100;
}
/deep/.ant-input-suffix{
  color: @sc-grey-40;
}
</style>
