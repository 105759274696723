<!--
 * @Date: 2022-07-07 13:55:58
 * @descriotion: 12312
-->
<template>
  <div>
    <Drawer
      :title="title"
      :closable="false"
      :visible="visible"
      :z-index="5"
      size="small"
      @close="onClose"
      @onSubmit="onsubmit"
    >
      <div class="content">
        <UserInfo :user-info="userInfo" />
        <div class="setForm">
          <AddFormVue v-if="socialStatus === 1" ref="social" title="社保" insure-classify="social" :is-flag="false" :disabled-list="disabledList" :form-data="socialData" />
          <AddFormVue v-if="medicalStatus === 1" ref="medical" title="医保" insure-classify="medical" :is-flag="false" :disabled-list="disabledList" :form-data="medicalData" />
          <AddFormVue v-if="housingStatus === 1" ref="housing" title="公积金" insure-classify="housing" :is-flag="false" :disabled-list="disabledList" :form-data="housingData" />
        </div>
      </div>
    </Drawer>
  </div>
</template>
<script>
import {
  getStaffInsureInfoApi,
  updateInsureApi,
  checkChangStaffSchemeApi
} from '@/services/insurance/archives'
export default {
  name: 'Adjustment',
  components: {
    UserInfo: () => import('./userInfo'),
    AddFormVue: () => import('./addForm'),
    Drawer: () => import('@/components/CbDrawer/index')
  },
  props: {
    title: {
      type: String,
      default: '调整'
    }
  },
  data() {
    return {
      visible: false,
      disabledList: ['city', 'company', 'scheme', 'month', 'reason'],
      userInfo: {},
      socialStatus: undefined,
      medicalStatus: undefined,
      housingStatus: undefined,
      socialData: {},
      medicalData: {},
      housingData: {},
      social: {},
      medical: {},
      housing: {}
    }
  },
  mounted() {},
  methods: {
    onClose() {
      this.userInfo = {}
      this.socialStatus = undefined
      this.medicalStatus = undefined
      this.housingStatus = undefined
      this.socialData = {}
      this.medicalData = {}
      this.housingData = {}
      this.visible = false
    },
    show(data) {
      this.visible = true
      this.getStaffInsureInfoApi(data.onJobId)
      this.$nextTick(() => {
        this.userInfo = data
      })
    },
    async onsubmit() {
      this.social = { flag: false, formData: { paymentBaseType: null, fixedPaymentBase: null, customScaleType: null, customScale: null }}
      this.medical = { flag: false, formData: { paymentBaseType: null, fixedPaymentBase: null, customScaleType: null, customScale: null }}
      this.housing = { flag: false, formData: { paymentBaseType: null, fixedPaymentBase: null, customScaleType: null, customScale: null, housingCode: null }}
      if (this.socialStatus === 1) {
        this.social = await this.$refs.social.getData()
        if (!this.social.flag) return
      }
      if (this.medicalStatus === 1) {
        this.medical = await this.$refs.medical.getData()
        if (!this.medical.flag) return
      }
      if (this.housingStatus === 1) {
        this.housing = await this.$refs.housing.getData()
        if (!this.housing.flag) return
      }
      const res = await checkChangStaffSchemeApi({ onJobIds: this.userInfo.onJobId })
      if (res.code === 100) {
        if (res.data.tenantBillStatus !== 0) {
          const tips = res.data.tenantBillStatus === 1
            ? '本月账单已生成，调整内容下月生效，如需本月生效，请重新生成本月账单。确认是否要进行调整？'
            : '本月账单已锁定，调整内容下月生效，如需本月生效，请重新生成本月账单。确认是否要进行调整？'
          this.$Reconfirm({
            title: '调整',
            content: tips
          }).then(() => {
            this.update()
          }).catch(() => {})
        } else {
          this.update()
        }
      }
    },
    async update() {
      const res = await updateInsureApi({
        onJobIds: [this.userInfo.onJobId],
        social: this.socialStatus === 1 ? { paymentBaseType: this.social.formData.paymentBaseType, fixedPaymentBase: this.social.formData.fixedPaymentBase, customScaleType: this.social.formData.customScaleType, customScale: this.social.formData.customScale } : null,
        medical: this.medicalStatus === 1 ? { paymentBaseType: this.medical.formData.paymentBaseType, fixedPaymentBase: this.medical.formData.fixedPaymentBase, customScaleType: this.medical.formData.customScaleType, customScale: this.medical.formData.customScale } : null,
        housing: this.housingStatus === 1 ? { paymentBaseType: this.housing.formData.paymentBaseType, fixedPaymentBase: this.housing.formData.fixedPaymentBase, customScaleType: this.housing.formData.customScaleType, customScale: this.housing.formData.customScale, housingCode: this.housing.formData.housingCode } : null
      })
      if (res.code === 100) {
        this.$message.success('调整成功！')
        this.$emit('callBackBatch')
        this.onClose()
      }
    },
    async getStaffInsureInfoApi(onJobId) {
      const res = await getStaffInsureInfoApi({ onJobId })
      this.socialStatus = res.data.socialStatus
      this.medicalStatus = res.data.medicalStatus
      this.housingStatus = res.data.housingStatus
      this.socialData = res.data.social
      this.medicalData = res.data.medical
      this.housingData = res.data.housing
    }
  }
}
</script>
<style lang="less" scoped>
  .content{
    width:100%;
    height:100%;
    position:relative;
    .font-size(14px);
    .setForm{
      width: 100%;
      height: calc(100% - 200px);
      overflow: auto;
    }
  }
</style>
