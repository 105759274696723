var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"attendanceitems beauty-scroll"},[_c('BlockTitle',{staticClass:"attendanceitems-one-heared",attrs:{"title":"考勤合计项"}}),_c('custom-table',{attrs:{"columns":_vm.columns,"data-source":_vm.total,"pagination":false,"row-key":"attendanceItemId"},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var scope = ref.scope;
return [_c('a-switch',{attrs:{"size":"small","disabled":""},on:{"click":function($event){return _vm.onClick(scope)}},model:{value:(scope.record.status),callback:function ($$v) {_vm.$set(scope.record, "status", $$v)},expression:"scope.record.status"}})]}},{key:"unit",fn:function(ref){
var scope = ref.scope;
return [_c('a-checkbox',{on:{"change":function($event){return _vm.preferredChange(scope)}},model:{value:(scope.record.enableFirstUnitFlag),callback:function ($$v) {_vm.$set(scope.record, "enableFirstUnitFlag", $$v)},expression:"scope.record.enableFirstUnitFlag"}},[_vm._v(" "+_vm._s(scope.record.firstUnitText)+" ")]),_c('a-checkbox',{attrs:{"disabled":""},model:{value:(scope.record.enableSecondUnitFlag),callback:function ($$v) {_vm.$set(scope.record, "enableSecondUnitFlag", $$v)},expression:"scope.record.enableSecondUnitFlag"}},[_vm._v(" "+_vm._s(scope.record.secondUnitText)+" ")])]}}])}),_c('BlockTitle',{staticClass:"attendanceitems-one-heared",attrs:{"title":"迟到、早退、旷工"}}),_c('custom-table',{attrs:{"columns":_vm.columns,"data-source":_vm.error,"pagination":false,"row-key":"attendanceItemId"},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var scope = ref.scope;
return [_c('a-switch',{attrs:{"size":"small"},on:{"click":function($event){return _vm.onClick(scope)}},model:{value:(scope.record.status),callback:function ($$v) {_vm.$set(scope.record, "status", $$v)},expression:"scope.record.status"}})]}},{key:"unit",fn:function(ref){
var scope = ref.scope;
return [(scope.record.multipleUnitFlag)?_c('div',[_c('a-checkbox',{attrs:{"disabled":scope.record.status===false},on:{"change":function($event){return _vm.preferredChange(scope)}},model:{value:(scope.record.enableFirstUnitFlag),callback:function ($$v) {_vm.$set(scope.record, "enableFirstUnitFlag", $$v)},expression:"scope.record.enableFirstUnitFlag"}},[_vm._v(" "+_vm._s(scope.record.firstUnitText)+" ")]),_c('a-checkbox',{attrs:{"disabled":scope.record.status===false},on:{"change":function($event){return _vm.secondaryChange(scope)}},model:{value:(scope.record.enableSecondUnitFlag),callback:function ($$v) {_vm.$set(scope.record, "enableSecondUnitFlag", $$v)},expression:"scope.record.enableSecondUnitFlag"}},[_vm._v(" "+_vm._s(scope.record.secondUnitText)+" ")])],1):_c('span',[_vm._v(_vm._s(scope.record.firstUnitText))])]}}])}),_c('BlockTitle',{staticClass:"attendanceitems-one-heared",attrs:{"title":"加班"}}),_c('custom-table',{attrs:{"columns":_vm.columns,"data-source":_vm.overtime,"pagination":false,"row-key":"attendanceItemId"},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var scope = ref.scope;
return [_c('a-switch',{attrs:{"size":"small"},on:{"click":function($event){return _vm.onClick(scope)}},model:{value:(scope.record.status),callback:function ($$v) {_vm.$set(scope.record, "status", $$v)},expression:"scope.record.status"}})]}},{key:"unit",fn:function(ref){
var scope = ref.scope;
return [_vm._v(" "+_vm._s(scope.record.firstUnitText)+" ")]}}])}),_c('BlockTitle',{staticClass:"attendanceitems-one-heared",attrs:{"title":"外出"}}),_c('custom-table',{attrs:{"columns":_vm.columns,"data-source":_vm.out,"pagination":false,"row-key":"attendanceItemId"},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var scope = ref.scope;
return [_c('a-switch',{attrs:{"size":"small"},on:{"click":function($event){return _vm.onClick(scope)}},model:{value:(scope.record.status),callback:function ($$v) {_vm.$set(scope.record, "status", $$v)},expression:"scope.record.status"}})]}},{key:"unit",fn:function(ref){
var scope = ref.scope;
return [(scope.record.multipleUnitFlag)?_c('div',[_c('a-checkbox',{attrs:{"disabled":scope.record.status===false},on:{"change":function($event){return _vm.preferredChange(scope)}},model:{value:(scope.record.enableFirstUnitFlag),callback:function ($$v) {_vm.$set(scope.record, "enableFirstUnitFlag", $$v)},expression:"scope.record.enableFirstUnitFlag"}},[_vm._v(" "+_vm._s(scope.record.firstUnitText)+" ")]),_c('a-checkbox',{attrs:{"disabled":scope.record.status===false},on:{"change":function($event){return _vm.secondaryChange(scope)}},model:{value:(scope.record.enableSecondUnitFlag),callback:function ($$v) {_vm.$set(scope.record, "enableSecondUnitFlag", $$v)},expression:"scope.record.enableSecondUnitFlag"}},[_vm._v(" "+_vm._s(scope.record.secondUnitText)+" ")])],1):_c('span',[_vm._v(_vm._s(scope.record.firstUnitText))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }