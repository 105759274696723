<template>
  <div class="MatterStatus">
    <div v-for="(item,key) in matterObj" :key="key">
      <div v-if="item.length" class="matter">
        <div class="matter-title flex-align-center">
          <svg v-if="key==='toBeList'" class="iconpark-icon"><use href="#daiban" /></svg>
          <svg v-else class="iconpark-icon"><use href="#genjin" /></svg>
          <span>{{ key==='toBeList' ? '待办':'跟进' }}</span>
        </div>
        <div v-for="(status,i) in item" :key="i" class="matter-content flex-align-center" @click="jumpDetail(status)">
          <span class="status-title">{{ status.statusTitle }}<span class="user-num">{{ status.userList.length }}</span></span>
          <div v-for="(user,userIndex) in status.userList" :key="`${userIndex}`">
            <span v-if="userIndex<limitNum" class="user-box flex-align-center">
              <CbAvater :on-job-id="user.onJobId" :avater-name="user.staffName" size="26" :default-name-length="1" :show-user-name="false" />
              <span v-if="user.staffName&&user.staffName.length <4" class="user-name">{{ user.staffName }}</span>
              <a-tooltip v-else class="user-name">
                <template slot="title">
                  {{ user.staffName }}
                </template>
                {{ user.staffName | ellipsis }}...
              </a-tooltip>
            </span>
          </div>
          <div v-if="status.userList.length >limitNum" class="hide-num flex-center">+{{ status.userList.length-limitNum > 99 ? 99 : status.userList.length-limitNum }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getIndexWaitInsure, getIndexWaitStop, getIndexInsuring, getIndexIncrease, getIndexDecrease } from '@/services/home/insurance.js'
import { listArchivesPerfect, entryFormExamList, upcomingEntry, upcomingOfficial, upcomingLeave } from '@/services/home/user.js'
import { staffNotInGroupNotIgnore } from '@/services/home/attendance.js'
export default {
  name: 'MatterStatus',
  components: {
    CbAvater: () => import('@/components/CbAvater/theAvater.vue')
  },
  filters: {
    ellipsis(val) {
      return val?.slice(0, 3)
    }
  },
  props: {
    type: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      matterObj: {
        toBeList: [],
        followUpList: []
      }
    }
  },
  computed: {
    limitNum() {
      if (window.screen.width >= 1920) {
        return 4
      } else {
        return 2
      }
    }
  },
  watch: {
    type(newVal) {
      Object.assign(this.$data.matterObj, this.$options.data().matterObj)
      switch (newVal) {
        case '1':
          // 获取员工事项数据
          this.initUserData()
          break
        case '2':
          // 获取考勤事项数据
          this.initAttendance()
          break
        case '4':
          // 获取社保事项数据
          this.initInsurance()
          break
        default:
          break
      }
    }
  },

  mounted() {
    this.initUserData()
  },
  methods: {
    async initInsurance() {
      // 获取待投保人员列表
      const waitInsure = await getIndexWaitInsure()
      this.matterObj.toBeList.push({ statusTitle: '待投保', userList: waitInsure.data })
      // 获取待停保人员列表
      const waitStop = await getIndexWaitStop()
      this.matterObj.toBeList.push({ statusTitle: '待停保', userList: waitStop.data })
      // 获取正常在缴人员列表
      const insuring = await getIndexInsuring()
      this.matterObj.followUpList.push({ statusTitle: '正常在缴', userList: insuring.data })
      // 获取增员人员列表
      const increase = await getIndexIncrease()
      this.matterObj.followUpList.push({ statusTitle: '本月增员', userList: increase.data })
      // 获取减员人员列表
      const decrease = await getIndexDecrease()
      this.matterObj.followUpList.push({ statusTitle: '本月减员', userList: decrease.data })
    },
    async initAttendance() {
      // 获取所有没有考勤组的员工
      const res = await staffNotInGroupNotIgnore()
      if (res.code === 100) {
        this.matterObj.toBeList.push({ statusTitle: '设考勤组', userList: res.data })
      }
    },
    async initUserData() {
      // 获取档案待审核列表
      const archives = await listArchivesPerfect()
      this.matterObj.toBeList.push({ statusTitle: '档案审核', userList: archives.data.staffList })
      // 获取入职登记表待审核列表
      const induction = await entryFormExamList()
      this.matterObj.toBeList.push({ statusTitle: '入职审核', userList: induction.data.staffList })
      // 获取即将入职列表
      const entry = await upcomingEntry()
      this.matterObj.followUpList.push({ statusTitle: '即将入职', userList: entry.data.staffList })
      // 获取即将转正列表
      const official = await upcomingOfficial()
      this.matterObj.followUpList.push({ statusTitle: '即将转正', userList: official.data.staffList })
      // 获取待离职列表
      const leave = await upcomingLeave()
      this.matterObj.followUpList.push({ statusTitle: '待离职', userList: leave.data.staffList })
      // // 获取即将生日列表
      // const birthdate = await upcomingBirthdate()
      // this.matterObj.followUpList.push({ statusTitle: '即将生日', userList: birthdate.data.staffList })
      // // 获取即将周年列表
      // const anniversary = await upcomingAnniversary()
      // this.matterObj.followUpList.push({ statusTitle: '即将周年', userList: anniversary.data.staffList })
    },
    // 跳转详情页
    jumpDetail(status) {
      let routeName
      let params
      switch (status.statusTitle) {
        case '设考勤组':
          routeName = 'attendanceSetting'
          break
        case '档案审核':
          routeName = 'userArchive'
          params = true
          break
        case '入职审核':
          routeName = 'userInduction'
          params = 'recentEmployment'
          break
        case '即将入职':
          routeName = 'userInduction'
          params = 'toBeEmployed'
          break
        case '即将转正':
          routeName = 'userWorker'
          params = 'regular'
          break
        case '待离职':
          routeName = 'userQuit'
          break
        case '正常在缴':
        case '本月增员':
        case '本月减员':
          routeName = 'socialSecurityBill'
          break
        case '待投保':
          routeName = 'socialSecurityArchives'
          params = 'toBeInsured'
          break
        case '待停保':
          routeName = 'socialSecurityArchives'
          params = 'toBeStopped'
          break
      }
      this.$router.push({
        name: routeName,
        params: {
          isHome: params
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.MatterStatus{
    padding: 0 0 0  14px;
    .matter:first-child{
      margin: 20px 0 5px 0;
    }
    .matter-title{
        .font-size(14px);
        color: @sc-grey-100;
        font-weight: bold;
        .iconpark-icon{
            width:15px;
            height:15px;
            margin-right: 7px;
            color: @sc-grey-100;
        }
    }
    .matter-content{
        height:60px;
        border-bottom: 1px solid @border-color;
        .font-size(14px);
        .status-title{
          display:inline-block;
          min-width: 80px;
          color: @sc-grey-60;
          .user-num {
            color: @sc-grey-100;
            margin-left: 5px;
          }
        }
        .user-box{
            margin-left: 20px;
            .user-name{
                margin-left: 5px;
                color: @sc-grey-100;
            }
        }
        .hide-num{
            margin-left: 20px;
            width: 28px;
            height: 28px;
            background: #8F93A1;
            color: #fff;
            border-radius: 50%;

        }
    }
    .matter-content:hover {
      border-radius: 4px;
      background: @sc-greyBg-10;
    }
}
</style>
