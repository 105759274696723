<template>
  <div>
    <Modal :visible="visible" :closable="true" size="normal" title="办理转正" ok-text="保存" @ok="handleOk" @cancel="handleCancel">
      <div class="v-title">未进行转正审批，确认办理转正？</div>
      <!-- <a-checkbox-group v-model="checkedList" :options="options" /> -->
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="计划转正日期" prop="plantTime"> {{ form.plantTime }} </a-form-model-item>
        <a-form-model-item label="转正日期" prop="time">
          <a-date-picker v-model="form.time" value-format="YYYY-MM-DD" placeholder="请选择时间" />
        </a-form-model-item>
      </a-form-model>
    </Modal>
  </div>
</template>

<script>
import { official } from '@/services/user/worker.js'
export default {
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      onJobId: '',
      visible: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      form: {},
      rules: {
        time: [
          {
            required: true,
            message: '请选择日期',
            trigger: 'change'
          }
        ]
      }
    }
  },

  methods: {
    // 打开弹窗
    open(val) {
      this.form.plantTime = val.record.planFormalDate
      this.onJobId = val.record.onJobId
      this.visible = true
    },
    // 保存事件
    async handleOk() {
      const res = await official({
        onJobId: this.onJobId,
        planFormalDate: this.form.time
      })
      if (res.code === 100) {
        this.$message.success('转正办理成功')
        this.$emit('getFetchDataApiFn')
        this.visible = false
        this.resetForm()
      }
    },

    // 取消事件
    handleCancel() {
      this.visible = false
      this.resetForm()
    },
    // 重置表单内容和验证
    resetForm() {
      this.form = {}
      this.$refs.ruleForm.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
.ant-calendar-picker{
  width: 100%;
}
.v-title {
  margin-bottom: 16px;
  color: @error-color;
}
/deep/ .ant-form-item-children {
  color: @sc-grey-100;
}
</style>
