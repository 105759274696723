<template>
  <div class="regional-class-container">
    <Drawer
      closable
      :is-mask="false"
      :visible="visible"
      size="small"
      title="添加城市"
      @close="visible=false"
      @onSubmit="onSave"
    >
      <div>
        <a-form-model
          ref="form"
          :model="form"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="已选择城市">
            <div class="area-box">
              <span v-for="(area,i) in form.areaList" :key="area.areaCode" class="area">{{ area.areaName }}
                <svg class="iconpark-icon delete" @click="form.areaList.splice(i,1)"><use href="#close-small" /></svg>
              </span>
            </div>
          </a-form-model-item>
          <a-form-model-item label="新增城市">
            <SelectArea
              v-if="visible"
              ref="selectArea"
              v-model="form.areaId"
              :province-width="100"
              :city-width="100"
              :area-width="100"
              :area-show="false"
              :government-auto="true"
              @getStrandValue="getStrandValue"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { setByLevel } from '@/services/wage/areaLevel.js'
export default {
  name: 'RegionalClass',
  components: {
    Drawer: () => import('@/components/CbDrawer'),
    SelectArea: () => import('@/components/CbSelectArea/index')
  },
  data() {
    return {
      visible: false,
      labelCol: { lg: 5, xl: 5, xxl: 5 },
      wrapperCol: { lg: 19, xl: 18, xxl: 19 },
      form: {
        areaId: ''
      },
      areaCodes: []
    }
  },
  methods: {
    getStrandValue(resCode, areaCodeArr) {
      if (['110000', '120000', '310000', '500000'].includes(resCode[0]) || resCode[1]) {
        this.$set(this.form, 'areaId', resCode[1] || resCode[0])
      }
    },
    async onSave() {
      this.areaCodes = this.form.areaList?.map(v => { return v.areaCode }) || []
      this.areaCodes?.push(this.form.areaId)
      this.areaCodes = this.areaCodes?.join(',')
      const res = await setByLevel({ ...this.form, areaCodes: this.areaCodes })
      if (res.code === 100) {
        this.$message.success('操作成功！')
        this.visible = false
        this.$emit('refresh')
      }
    },
    open(area) {
      this.visible = true
      this.form = JSON.parse(JSON.stringify(area))
    }
  }
}
</script>
<style scoped lang="less">
.area-box{
  display:flex;
  flex-wrap: wrap;
.area{
    // position: relative;
    padding: 5px 15px;
    border: 1px solid @sc-primary-60;
    color: @sc-primary-100;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin:0 15px 15px 0;
    .delete{
      // position: absolute;
      width: 16px;
      height: 16px;
      margin-left: 5px;
      cursor: pointer;
      color: @error-color;
      // right: -2px;
      // top: -2px;
    }
  }
}
  /deep/.ant-form-item-control {
    line-height: 21px;
  }
  /deep/ .ant-form-item-label > label {
    color: @sc-grey-80;
  }
</style>
