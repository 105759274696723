var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contract-body"},[_c('div',{staticClass:"add-contract"},[_c('a-button',{attrs:{"type":"primary","prefix":"add-one"},on:{"click":_vm.handleAdd}},[_vm._v(" 新增 ")])],1),_c('custom-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"table-ref":".contract-body","row-key":"companyId","pagination":{
      current: _vm.pager.pageNum,
      pageSize: _vm.pager.pageSize,
      total: _vm.total,
      showTotal: function (total) { return ("共 " + total + " 条数据"); },
      showQuickJumper: true,
      showSizeChanger: true
    }},on:{"change":_vm.loadDataList},scopedSlots:_vm._u([{key:"status",fn:function(ref){
    var scope = ref.scope;
return _c('span',{},[_c('div',[_c('a-switch',{attrs:{"size":"small","default-checked":Boolean(scope.record.status)},on:{"change":function($event){
    var i = arguments.length, argsArray = Array(i);
    while ( i-- ) argsArray[i] = arguments[i];
return _vm.onChange.apply(void 0, argsArray.concat( [scope] ))}}})],1)])}},{key:"operation",fn:function(ref){
    var scope = ref.scope;
return [_c('a-popover',{attrs:{"placement":"bottomLeft","trigger":"hover"}},[_c('template',{slot:"content"},[_c('div',{staticClass:"more-handle-btn"},[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.editContract(scope.record)}}},[_vm._v(" 编辑 ")]),_c('a-button',{staticClass:"delete",attrs:{"type":"link"},on:{"click":function($event){return _vm.deleContract(scope.record)}}},[_vm._v(" 删除 ")])],1)]),_c('div',{staticClass:"more-handle"},[_vm._v("···")])],2)]}}])}),_c('ContractForm',{ref:"contractFormRef",on:{"getfetchData":_vm.getfetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }