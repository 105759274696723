<template>
  <div>
    <a-form-model
      ref="addInfo"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <div class="switch_box">
        <span class="switch_title">{{ switchTitle }}</span>
        <a-switch v-model="isOpen" size="small" @change="onChange" />
      </div>
      <div v-show="isOpen">
        <a-form-model-item label="参保主体" prop="">
          <a-select v-model="entryInsurance.insureCompanyId" city-code="item.cityCode" allow-clear placeholder="请选择参保主体" @change="changeInsuredEntity">
            <a-select-option v-for="it of insureCompanyList" :key="it.companyId" :city-code="it.cityCode" :value="it.companyId">{{ it.companyName }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="参保方案" prop="">
          <a-select v-model="entryInsurance.schemeId" allow-clear placeholder="请选择参保方案" @change="changeSchemeList">
            <a-select-option v-for="it of schemeList" :key="it.schemeId" :value="it.schemeId">{{ it.schemeName }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="type === 'housing'" label="公积金账号" prop="">
          <a-input v-model="entryInsurance.accountNum" placeholder="以前在本地的话需要录入" />
        </a-form-model-item>
        <a-form-model-item label="缴纳基数" prop="">
          <a-radio-group v-model="entryInsurance.paymentBaseType" @change="changeBaseNum">
            <a-radio v-for="item of paymentBaseList" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-radio>
            <CbNumber v-if="entryInsurance.paymentBaseType === 1" v-model="entryInsurance.fixedPaymentBase" :decimal-separator="true" placeholder="请输入基数" suffix="元" />
            <div v-else class="unit_input">
              <a-row :gutter="24">
                <a-col :span="16">
                  <DictionariesInput v-model="entryInsurance.customScaleType" placeholder="请选择自定义金额" parameter="custom_scale_type" class="dictionariesInput" />
                </a-col>
                <a-col :span="8">
                  <CbNumber v-model="entryInsurance.customScale" :decimal-separator="true" :max="100" suffix="%" placeholder="请输入比例" />
                </a-col>
              </a-row>
            </div>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="缴纳起始月" prop="">
          <a-month-picker v-model="entryInsurance.startMonth" value-format="YYYY-MM" placeholder="选择日期" />
        </a-form-model-item>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import { getSchemeListByCityApi } from '@/services/insurance/archives.js'
import { getInsureCompanyListApi } from '@/services/insurance/setting.js'
export default {
  name: 'EntryInsurance',
  components: {
    CbNumber: () => import('@/components/CbNumber/index'),
    DictionariesInput: () => import('@/components/CbDropDownInput/dictionariesInput/index')
  },
  props: {
    switchTitle: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      labelCol: { lg: 5, xl: 4, xxl: 4 },
      wrapperCol: { lg: 12, xl: 13, xxl: 14 },
      entryInsurance: {
        insureCompanyId: undefined, // 参保主体
        schemeId: undefined, // 参保方案
        paymentBaseType: 1, // 缴纳基数
        startMonth: undefined
      },
      paymentBaseList: [
        {
          value: 1,
          label: '固定金额'
        },
        {
          value: 2,
          label: '自定义金额'
        }
      ],
      selectValue: '',
      schemeList: [], // 参保方案列表
      insureCompanyList: [],
      isOpen: true
    }
  },
  watch: {
    'entryInsurance.paymentBaseType': {
      immediate: true,
      handler(val) {
        if (val === 1) {
          delete this.entryInsurance.customScaleType
          delete this.entryInsurance.customScale
          this.$set(this.entryInsurance, 'fixedPaymentBase', undefined)
        } else if (val === 2) {
          delete this.entryInsurance.fixedPaymentBase
          this.$set(this.entryInsurance, 'customScaleType', undefined)
          this.$set(this.entryInsurance, 'customScale', undefined)
        }
      }
    }
  },
  mounted() {
    this.getInsureCompanyList()
  },
  methods: {
    onChange() {
    },
    changeBaseNum(val) {
    },
    changeSchemeList() {
    },
    transmitData() {
      const { isOpen, type } = this
      const formData = this.entryInsurance
      return {
        type,
        isOpen,
        formData
      }
    },
    changeInsuredEntity(val, option) {
      this.schemeList = []
      this.$set(this.entryInsurance, 'schemeId', undefined)
      if (option) {
        this.getSchemeListByCity(option.data.attrs['city-code'], true)
      }
    },
    // 获取社保主体列表
    async getInsureCompanyList() {
      const res = await getInsureCompanyListApi()
      this.insureCompanyList = res.data.map((item) => {
        if (item.defaultValue === '1' && !this.entryInsurance.insureCompanyId) {
          this.$set(this.entryInsurance, 'insureCompanyId', item.companyId)
          this.getSchemeListByCity(item.cityCode, true)
        }
        return item
      })
    },
    // 根据城市code获取方案列表 defaultValue： 如果有默认值是否选中默认值
    async getSchemeListByCity(cityCode, defaultValue) {
    //   this.entryInsurance.cityCode = cityCode
      const obj = {
        cityCode: cityCode,
        insureClassify: this.type
      }
      const res = await getSchemeListByCityApi(obj)
      if (res.data.length > 0) {
        this.schemeList = res.data.map((item) => {
          if (item.defaultValue === '1' && defaultValue) {
            this.$set(this.entryInsurance, 'schemeId', item.schemeId)
          }
          return item
        })
      }
    }
  }
}
</script>

    <style lang="less" scoped>
    .basenum_input{
        margin-top: 8px;
        /deep/ .ant-input{
        width: 308px !important;
    }
    }
    .switch_box{
      .switch_title{
      position: relative;
      width: 60px;
      display: inline-block;
      margin: 0 12px 20px 0;
      font-size: 14px;
      font-weight: bold;
    }
    .switch_title::before{
      position: absolute;
      top: 50%;
      transform: translatey(-40%);
      left: -12px;
      content: '';
      width: 4px;
      height: 14px;
      background-color: @sc-info-100;
    }
    }
    /deep/ .ant-form-item-label{
        display: inline-block;
    }
    /deep/ .ant-input{
        width: 340px;
    }
    /deep/ .ant-select-selection{
        width: 340px;
    }
    /deep/ .ant-radio-group{
        line-height: inherit;
    }
    .small_select{
      /deep/.ant-select-selection{
        width: 160px !important;
    }
    }
    .unit_input{
        /deep/.ant-select-selection{
        width: 200px !important;
        margin-right: 40px;
    }
    /deep/ .ant-input{
        margin-left: 20px;
        width: 115px !important;
    }
    /deep/.ant-input-affix-wrapper .ant-input-suffix {
        left: 115px;
    }
    }
    </style>

