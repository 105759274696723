<template>
  <div class="user-setting-panel">
    <layout
      ref="layoutMenu"
      class="left-menu-panel"
      :menu-data-list="menuDataList"
      :selected-key.sync="selectedKey"
      @menu-select-change="menuSelectChange"
    >
      <template #leftTop>
        <div class="left-top-p" />
      </template>
      <component :is="curComponentName" />
    </layout>
  </div>
</template>

<script>
import layout from '@/components/CbLayoutMenu/index'
import common from './common'
export default {
  components: {
    layout,
    ...common
  },
  data() {
    return {
      selectedKey: '',
      curComponentName: '',
      menuDataList: [
        {
          id: '1',
          name: '计薪周期',
          frontIcon: '#hourglass',
          fileName: 'payCycle',
          funcCode: 'salaryPeriod',
          actions: []
        },
        {
          id: '2',
          name: '薪资组',
          frontIcon: '#financing-two-5m1l59ij',
          fileName: 'salaryGroup',
          funcCode: 'salaryGroup',
          actions: []
        },
        {
          id: '3',
          name: '工资条模板',
          frontIcon: '#transaction-order',
          fileName: 'payslipTemplate',
          funcCode: 'salarySheetTemp',
          actions: []
        },
        {
          id: '4',
          name: '报税主体',
          frontIcon: '#switch-themes',
          fileName: 'taxDeclaration',
          funcCode: 'taxSubject',
          actions: []
        },
        // {
        //   id: '4',
        //   name: '企业账号',
        //   frontIcon: '',
        //   disabled: true,
        //   actions: [],
        //   children: [
        //     {
        //       id: '4-1',
        //       frontIcon: '#bank-transfer',
        //       fileName: 'payAccount',
        //       name: '发薪账户',
        //       actions: []
        //     },
        //     {
        //       id: '4-2',
        //       frontIcon: '#switch-themes',
        //       fileName: 'taxDeclaration',
        //       name: '报税主体',
        //       actions: []
        //     }
        //   ]
        // },
        {
          id: '5',
          name: '消息设置',
          frontIcon: '#message',
          fileName: 'infoSetting',
          funcCode: 'salaryRemind',
          actions: []
        },
        {
          id: '6',
          name: '地区分级',
          frontIcon: '#message',
          fileName: 'regionalClass',
          funcCode: 'regionalClass',
          actions: []
        }
      ]
    }
  },
  mounted() {
    if (this.$route.params.fileName === 'salaryGroup') {
      this.$refs.layoutMenu.showInit(1)
    } else {
      this.$refs.layoutMenu.showInit()
    }
  },
  methods: {
    menuSelectChange(item) {
      this.curComponentName = item.fileName
    }
  }
}
</script>

<style lang="less" scoped>
.user-setting-panel {
  height: 100%;
  .left-top-p {
    margin-top: 20px;
  }
}
</style>
