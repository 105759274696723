<template>
  <div class="ToolInlet">
    <a-card
      hoverable
      :tab-list="tabListNoTitle"
      :active-tab-key="noTitleKey"
      size="small"
      class="tool-card"
      @tabChange="key => onTabChange(key, 'noTitleKey')"
    >
      <!-- 快捷入口 -->
      <div v-if="noTitleKey === 'quick'" class="tool-content">
        <div v-for="(item, index) in quickToolList" :key="index" class="tool-item" @click="quickJump(item)">
          <div class="tool-icon flex-center">
            <img :src="item.icon" alt="">
          </div>
          <div class="tool-title">{{ item.title }}</div>
        </div>
      </div>
      <!-- 实用工具 -->
      <div v-else-if="noTitleKey === 'practical'" class="tool-content">
        <div v-for="(item, index) in practicalToolList" :key="index" class="tool-item" @click="$message.info('此功能正在测试阶段即将上线，尽请期待~')">
          <div class="tool-icon flex-center">
            <svg class="iconpark-icon"><use :href="`#${item.icon}`" /></svg>
          </div>
          <div class="tool-title">{{ item.title }}</div>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
export default {
  name: 'ToolInlet',
  props: {},
  data() {
    return {
      noTitleKey: 'quick',
      tabListNoTitle: [
        {
          key: 'quick',
          tab: '快捷入口'
        },
        {
          key: 'practical',
          tab: '实用工具'
        }
      ],
      // 快捷入口栏
      quickToolList: [
        {
          title: '添加员工',
          icon: require('@/assets/img/home/addUser.png'),
          routerPath: 'userRoster',
          thirdName: 'AddEmployeeInfo'
        },
        {
          title: '花名册',
          icon: require('@/assets/img/home/roster.png'),
          routerPath: '/staffIndex/roster'
        },
        {
          title: '员工报表',
          icon: require('@/assets/img/home/userList.png'),
          routerPath: '/staffIndex/userReportForm'
        },
        {
          title: '组织架构',
          icon: require('@/assets/img/home/org.png'),
          routerPath: '/organization/framework'
        }
      ],
      // 实用工具栏
      practicalToolList: [
        {
          title: '行政文件模版',
          icon: 'file-text'
        },
        {
          title: '个税计算',
          icon: 'arithmetic-one'
        },
        {
          title: '常用法规',
          icon: 'agreement'
        },
        // 社保公积金计算
        {
          title: '险金公积金计算',
          icon: 'add-subtract'
        }
      ]
    }
  },
  methods: {
    // 跳转地址：routerPath
    // 三级路由名称：thirdName
    quickJump({ routerPath, thirdName }) {
      if (thirdName) {
        this.$router.push({
          name: routerPath,
          params: {
            thirdName: thirdName
          }
        })
        return
      }
      this.$router.push({
        path: routerPath,
        query: {
          thirdName: thirdName
        }
      })
    },
    onTabChange(key, type) {
      console.log(key, type)
      this[type] = key
    }
  }
}
</script>

<style lang="less" scoped>
.ToolInlet {
  .tool-card {
    width: 100%;
    height: 180px;
    border-radius: 8px;
    .tool-content {
      height: 135px;
      display: flex;
      .tool-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .tool-icon {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        background: #F6F7FB;
        margin-bottom: 6px;
        .iconpark-icon,img{
          width:30px;
          height:30px;
          color: @sc-primary-100;
        }
        img{
          width:37px;
          height:37px;
        }
      }
      .tool-icon:hover{
        box-shadow: 0px 6px 16px 1px rgba(36, 49, 90, 0.1);
      }
      .tool-title {
        .font-size(14px);
        color: @sc-grey-100;
      }
    }
  }

  /deep/.ant-card-head {
    padding: 0 13px !important;
  }
  .ant-card {
   border-radius: 8px;
   border-color:#fff ;
  }
  .ant-card:hover {
    box-shadow: none;
  }
  /deep/.ant-tabs-tab {
    margin-right: 40px;
    padding: 10px 6px;
    .font-size(16px);
    color: @sc-grey-100;
  }
  /deep/.ant-tabs-tab:hover,/deep/.ant-tabs-tab-active {
    color: @sc-primary-100;
    font-weight: 400;
  }
  /deep/.ant-card-body {
    padding: 0;
  }
}
</style>
