<template>
  <div>
    <img class="noDataImg" src="@/assets/img/home/noData.png" alt="">
    <p>暂无数据</p>
  </div>
</template>

<script>
export default {
  name: 'NoData'
}
</script>

<style lang="less" scoped>

.noDataImg{
  width: 100px;
  height: 100px;
}
</style>
