<template>
  <div id="mytb">
    <div v-for="(item,index) in salaryList" :key="item.id" class="salary-plan-table">
      <div class="table-head flex-just-between">
        <span class="flex-align-center">
          <svg class="iconpark-icon dragIcon"><use href="#drag" /></svg>
          <div class="table-text"> {{ item.typeName }} </div>
          <a-switch v-model="item.enabled" size="small" @change="onChange(item)" />
        </span>
        <span v-if="item.typeNo&&item.typeNo.slice(0,1)==='B'" class="operation-icon">
          <!-- <a-icon class="icon" type="edit" @click="editClass({...item,type:'edit'})" />
          <a-icon class="icon" type="delete" @click="deleteHandle(item.typeNo,'class')" /> -->
          <svg class="iconpark-icon icon" @click="editClass({...item,type:'edit'})"><use href="#bianji" /></svg>
          <svg class="iconpark-icon icon" @click="deleteHandle(item.typeNo,'class')"><use href="#delete-one" /></svg>
        </span>
      </div>
      <div v-if="item.enabled">
        <custom-table
          :columns="columns"
          :data-source="salaryList[index].itemList||[]"
          :pagination="false"
          @change="loadDataList"
        >
          <!-- 加减项 -->
          <span slot="calcType" slot-scope="{ scope }" class="calcType">
            {{ scope.record.calcType === '1' ? '+':'-' }}
          </span>
          <!-- 操作列 -->
          <template slot="operation" slot-scope="{ scope }">
            <span v-if="!['A06','A07'].includes(item.typeNo)" class="operation-btn-box">
              <a-button type="link" @click="editSalary(scope.record)">
                编辑
              </a-button>
              <!-- 固定项不能删除 -->
              <a-button class="delete" type="link" danger @click="deleteHandle(scope.record.itemNo,item.typeNo==='A01'?'fixed':'item')">
                删除
              </a-button>
            </span>
          </template>
        </custom-table>
        <!-- 固定工资 个税 不可新增薪资项 -->
        <div v-if="!['A01','A07'].includes(item.typeNo)" class="add-salary">
          <a-button prefix="piliangdaoru" @click="addSalary(item)">
            添加薪资项
          </a-button>
        </div>
      </div>
    </div>
    <AddOrEditCategoryChild ref="handleChild" :salary-id="salaryId" @refreshData="initData" />
    <!-- 新增编辑分类抽屉 -->
    <NewCategory ref="newCategory" @refresh="initData" />
  </div>
</template>

<script>
import { listItem, deleteType, deleteItem, enableType, updateTypeOrder, deleteSalaryItem } from '@/services/wage/salaryGroup.js'
import Sortable from 'sortablejs'
import CustomTable from '@/components/CustomTable/index.js'
import { debounce } from '@/utils/index.js'
export default {
  name: 'SalaryTable',
  components: {
    AddOrEditCategoryChild: () => import('./addOrEditCategoryChild/index.vue'),
    NewCategory: () => import('./newCategory/index.vue')
  },
  inject: ['infoData'],
  mixins: [CustomTable],
  data() {
    return {
      delType: '',
      delTypeObj: {
        class: {
          api: deleteType
        },
        item: {
          api: deleteItem
        },
        fixed: {
          api: deleteSalaryItem
        }
      },
      salaryId: '',
      // 薪资项分类类型code
      delTypeNo: '',
      columns: [{
        title: '薪资项',
        dataIndex: 'itemName',
        key: 'itemName',
        width: '200px'
      },
      {
        title: '薪资方案',
        dataIndex: 'calcRuleType',
        key: 'calcRuleType',
        width: '200px'
      },
      {
        title: '加/减项',
        dataIndex: 'calcType',
        scopedSlots: { customRender: 'calcType' },
        key: 'calcType',
        width: '100px'
      },
      {
        title: '是否计税',
        dataIndex: 'taxable',
        scopedSlots: { customRender: 'taxable' },
        key: 'taxable',
        width: '200px'
      },
      {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
        width: '100px'
      }],
      salaryList: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initSortable()
      this.initData()
    })
  },
  methods: {
    // 初始化拖拽表格获取元素dom
    initSortable() {
      const mytb = document.querySelector('#mytb')
      const self = this
      new Sortable(mytb, {
        handle: '.salary-plan-table',
        animation: 150,
        sort: true,
        onEnd({ oldIndex, newIndex }) {
          if (newIndex === oldIndex) return
          self.salaryList?.splice(newIndex, 0, self.salaryList?.splice(oldIndex, 1)[0])
          var newArray = self.salaryList?.slice(0)
          self.salaryList = []
          let list
          self.$nextTick(async() => {
            self.salaryList = newArray
            list = self.salaryList.map(v => {
              return {
                typeNo: v.typeNo,
                order: v.displayOrder
              }
            })
            const res = await updateTypeOrder({ itemTypeList: list, groupId: self.infoData.groupId })
            if (res.code === 100) {
              self.$message.success('排序成功！')
            }
          })
        }
      })
    },
    // 初始化薪资方案表格数据
    async initData() {
      const res = await listItem({ groupId: this.infoData.groupId })
      if (res.code === 100) {
        res?.data.forEach((v) => {
          v.enabled = !!v.enabled
        })
        this.salaryList = []
        this.salaryList = res.data
        this.$forceUpdate()
      }
    },
    // 打开/关闭薪资分类
    async onChange(record) {
      try {
        const { enabled, typeNo } = record
        const res = await enableType({ enabled: enabled ? 1 : 0, groupId: this.infoData.groupId, code: typeNo })
        if (res.code === 100) {
          this.$message.success('状态改变成功！')
          this.initData()
        }
      } catch (error) {
        this.initData()
      }
    },
    // 添加薪资项
    addSalary(item) {
      const { typeNo } = { ...item }
      this.$refs.handleChild.open(typeNo, undefined)
    },
    // 编辑薪资项
    editSalary(item) {
      // 薪资项编号 salaryItemNo
      // 操作类型 type
      const { salaryType, itemNo } = { ...item }
      this.$refs.handleChild.open(salaryType, itemNo)
    },
    // 编辑薪资分类
    editClass(item) {
      this.$refs.newCategory.open(item)
    },
    // 删除 薪资分类&&薪资项
    deleteHandle(delTypeNo, delType) {
      this.delTypeNo = delTypeNo
      this.delType = delType
      this.$Reconfirm({
        title: `删除薪资${delType === 'class' ? '类型' : '项'}`,
        content: `确定要删除该薪资${delType === 'class' ? '类型' : '项'}吗？`
      }).then(() => {
        this.submitDel()
      }).catch(() => {
      })
    },
    // 确定删除
    submitDel: debounce(async function() {
      let params
      if (this.delType === 'class') {
        params = {
          code: this.delTypeNo
        }
      } else if (this.delType === 'item') {
        params = {
          itemNo: this.delTypeNo,
          groupId: this.infoData.groupId
        }
      } else if (this.delType === 'fixed') {
        params = {
          salaryItemNo: this.delTypeNo
        }
      }
      const res = await this.delTypeObj[this.delType].api(params)
      if (res.code === 100) {
        this.$message.success('删除成功！')
        this.initData()
      }
    }, 500)
  }
}
</script>
<style scoped lang="less">
.salary-plan-table {
  padding: 10px 20px;
  margin-top: 20px;
  box-sizing: border-box;
  border: 1px solid @sc-greyBg-20;
  .table-head {
    .table-text {
      margin:0 14px 0 12px;
      color: @sc-grey-100;
      .font-size(16px);
      font-weight: 700;
    }
    .operation-icon{
      display: flex;
      align-items: center;
      .icon{
        cursor: pointer;
        width:18px;
        height:18px;
        margin-left:10px
      }
    }
    .dragIcon{
      width: 16px;
      height: 16px;
      color: @sc-greyBg-100;
    }
  }
  .add-salary {
    margin: 20px 0 10px;
  }
  .calcType{
    .font-size(20px)
  }
}
/deep/ .ant-table-thead > tr > th {
  background-color: @gray-1;
}
.operation-btn-box{
  button{
    padding-left:0;
    padding-right:16px;
  }
}
</style>
