<!--
 * @Date: 2022-04-26 14:48:24
 * @descriotion:
-->
<template>
  <div id="address" class="address">
    <appForm
      :to-list="formList"
      search-placeholder="请搜索"
      @getSearchCallback="getSearchCallback"
    >
      <template slot="customButton">
        <a-button type="primary" prefix="add-one" @click="addAddress">
          新增打卡地址
        </a-button>
      </template>
    </appForm>
    <custom-table
      id="table"
      :columns="columns"
      :data-source="dataSource"
      :pagination="false"
      :tabs-height="36"
      row-key="clockLocationId"
      @change="loadDataList"
    >
      <!-- 范围 -->
      <template slot="radius" slot-scope="{scope}">
        <span>{{ scope.record.radius }} 米</span>
      </template>
      <template slot="addressType" slot-scope="{scope}">
        <span v-html="scope.record.outsideFlag===0?'上下班打卡':'外勤打卡'" />
      </template>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{scope}">
        <template>
          <div class="more-handle-btn-box">
            <a-button type="link" @click="edit(scope.record)">
              编辑
            </a-button>
            <a-button type="link" class="delete" danger @click="delet(scope.record)">
              删除
            </a-button>
          </div>
        </template>
        <!-- <a-popover
          placement="bottom"
          trigger="hover"
        >
          <template slot="content">
            <div class="more-handle-btn">
              <a-button type="link" @click="edit(scope.record)">
                编辑99
              </a-button>
              <a-button type="link" class="delete" danger @click="delet(scope.record)">
                删除9
              </a-button>
            </div>
          </template>
          <div class="more-handle">
            ···
          </div>
        </a-popover> -->
      </template>
    </custom-table>
    <Modal :title="title" :size="'normal'" overflow-auto :closable="true" :visible.sync="dialogFormVisible" :mask-closable="false" @cancel="cancel" @ok="ok">
      <a-form-model ref="addressForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
        <div style="width:100%;height:250px;margin:0 auto 20px">
          <Map ref="Map" :lngint="lngint" :radius="parseInt(radius)" @map="onMap" @search="onSearch" />
        </div>
        <a-form-model-item label="打卡地址名称" prop="locationName">
          <a-input v-model="form.locationName" placeholder="请输入打卡地址名称" />
          <span>打卡地址名称默认是您搜索的地点名称，您可以修改</span>
        </a-form-model-item>
        <a-form-model-item label="实际打卡地址">
          <a-input v-model="form.gpsName" disabled />
        </a-form-model-item>
        <a-form-model-item label="打卡范围" prop="radius" class="modelItem">
          <a-select v-model="form.radius" :options="rangePunch" :default-value="100" @change="selectChange" />
          <CbNumber v-show="flag" v-model="radius" :maxlength="5" class="select-input">
            <template slot="addonAfter">米</template>
          </CbNumber>
        </a-form-model-item>
        <a-form-model-item label="打卡地址类别" prop="outsideFlag">
          <a-radio-group v-model="form.outsideFlag " :options="outsideFlag" />
        </a-form-model-item>
      </a-form-model>
    </Modal>
  </div>
</template>
<script>
import { addressCode } from '@/utils/reg.js'
import mixins from '@/components/CustomTable/index.js'
import Map from '@/components/CbMap'
import { clockLocationGetPageApi, addLocationApi, delLocationApi, editLocationApi } from '@/services/attendance/settingset.js'
export default {
  name: 'Address',
  components: { Map,
    Modal: () => import('@/components/CbModal/index'),
    CbNumber: () => import('@/components/CbNumber')
  },
  mixins: [mixins],
  data() {
    const checkPuch = (rule, value, callback) => {
      if (value < 0) {
        if (this.radius === '') {
          callback(new Error('请输入自定义范围'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      lngint: [],
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      form: {
        locationName: '',
        gpsName: '',
        longitude: null,
        latitude: null,
        radius: 100,
        outsideFlag: '0'// 打卡标志
      },
      radius: 100,
      title: '',
      dialogFormVisible: false,
      formLabelWidth: '170px',
      formVisible: true,
      formList: ['search'],
      // 表单校验
      rules: {
        locationName: [
          { required: true, message: '请输入地址名称', trigger: 'change' },
          { min: 1, max: 20, message: '地址名称仅支持1~20个文字', trigger: 'change' },
          { pattern: addressCode, message: '地址名称不支持特殊字符', trigger: 'blur' }
        ],
        radius: [
          { required: true, message: '请选择打卡范围', trigger: 'change' },
          { validator: checkPuch, trigger: 'change' }
        ],
        outsideFlag: [
          { required: true, message: '请选择打卡类型', trigger: 'blur' }
        ]
      },
      outsideFlag: [
        {
          value: '0',
          label: '上下班打卡'
        }, {
          value: '1',
          label: '外勤打卡'
        }
      ],
      // 范围选择器
      rangePunch: [
        {
          value: 100,
          label: '100米'
        }, {
          value: 150,
          label: '150米'
        }, {
          value: 200,
          label: '200米'
        }, {
          value: 250,
          label: '250米'
        }, {
          value: 300,
          label: '300米'
        }, {
          value: 350,
          label: '350米'
        }, {
          value: -1,
          label: '自定义'
        }],
      columns: [{
        title: '打卡地址名称',
        dataIndex: 'locationName',
        key: 'locationName',
        ellipsis: true
      },
      {
        title: '实际打卡地址',
        dataIndex: 'gpsName',
        key: 'gpsName',
        ellipsis: true,
        customCell: () => {
          return {
            style: {
              'min-width': '240px'
            }
          }
        }
      },
      {
        title: '可打卡范围',
        dataIndex: 'radius',
        scopedSlots: { customRender: 'radius' },
        key: 'radius',
        customCell: () => {
          return {
            style: {
              'min-width': '100px'
            }
          }
        }
      },
      {
        title: '打卡地址类别',
        scopedSlots: { customRender: 'addressType' },
        ellipsis: true,
        customCell: () => {
          return {
            style: {
              'min-width': '120px'
            }
          }
        }
      },
      {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
        width: '100px',
        fixed: 'right'
      }],
      dataSource: [],
      clockLocationId: '',
      flag: false
    }
  },
  created() {
    this.clockLocationGetPage()
  },
  methods: {
    selectChange(val) {
      if (val > 0) {
        this.form.radius = val
        this.radius = val
        this.flag = false
      } else {
        this.flag = true
      }
    },
    clockLocationGetPage(data) {
      clockLocationGetPageApi({ locationName: data }).then(res => {
        this.dataSource = res.data
      })
    },
    getSearchCallback(val) {
      this.clockLocationGetPage(val.searchValue)
    },
    addAddress() {
      this.title = '新增打卡地址'
      console.log(this.form)
      this.dialogFormVisible = true
    },
    delet(scope) {
      this.clockLocationId = scope.clockLocationId
      this.$Reconfirm({
        title: '删除打卡地址',
        content: '确定要删除该打卡地址吗？'
      }).then(async() => {
        const res = await delLocationApi({ clockLocationId: this.clockLocationId })
        if (res.code === 100) {
          this.$message.success('删除成功')
          this.clockLocationGetPage()
        }
      }).catch(() => {
        console.log('no')
      })
    },
    edit(scope) {
      this.title = '编辑打卡地址'
      this.dialogFormVisible = true
      this.form = JSON.parse(JSON.stringify(scope))
      if (scope.radius === 100 || scope.radius === 150 || scope.radius === 200 || scope.radius === 250 || scope.radius === 300 || scope.radius === 350) {
        this.flag = false
      } else {
        this.form.radius = -1
        this.flag = true
      }
      this.radius = scope.radius
      this.clockLocationId = scope.clockLocationId
      this.form.outsideFlag = scope.outsideFlag.toString()
      this.lngint = [scope.longitude, scope.latitude]
    },
    cancel() {
      this.$refs.Map.clearMap()
      this.lngint = []
      this.flag = false
      this.radius = 100
      this.form = this.$options.data().form
      this.$refs.addressForm.resetFields()
      this.dialogFormVisible = false
      this.clockLocationGetPage()
    },
    async ok() {
      this.$refs.addressForm.validate(valid => {
        if (valid) {
          if (this.title === '新增打卡地址') {
            addLocationApi({ ...this.form, radius: parseInt(this.radius) }).then(res => {
              if (res.code !== 100) return
              this.$message.success('新增成功')
              this.cancel()
            })
          } else {
            editLocationApi({ ...this.form, clockLocationId: this.clockLocationId, radius: parseInt(this.radius) }).then(res => {
              if (res.code !== 100) return
              this.$message.success('编辑成功')
              this.cancel()
            })
          }
        }
      })
    },
    onMap(value, val, result) {
      console.log(value, val, result)
      this.form.gpsName = result ? result.regeocode ? result.regeocode.formattedAddress : result.name : this.form.gpsName
      this.form.latitude = val.toString()
      this.form.longitude = value.toString()
    },
    onSearch(val) {
      this.form.locationName = val
    }
  }
}
</script>
<style lang="less" scoped>
.ant-alert.ant-alert-closable{
  margin-bottom:12px;
}
.address{
  height:100%;
  background-color: @base-bg-color;
  /deep/.arr_form{
    margin: 16px 0;
  }
}
.more-handle-btn {
    display: flex;
    flex-direction: column;
    .ant-btn {
      height:25px;
    }
  }
.more-handle {
  font-weight: 700;
  color: @sc-primary-100;
  font-size:20px;
  vertical-align: baseline;
  cursor: pointer;
  margin-left: 5px;
}
/deep/.ant-form-item-children{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  .ant-select{
    width: 49%;
  }
  .select-input{
    width: 49%;
  }
}
.map{
  z-index: 0;
}
/deep/.ant-form-item{
  margin-bottom: 16px;
}
/deep/.ant-radio-group{
  margin: 8px 0 0 0;
}
/deep/.ant-form-item-control{
  color: @sc-grey-60;
}
.more-handle-btn-box{
  display: flex;
  button{
    padding-left: 0;
    padding-right: 16px;
  }
}
</style>
