<template>
  <div>
    <Modal :visible="visible" title="导出基数表" @cancel="handleCancel" @ok="handleOk">
      <a-form-model ref="ruleForm" :rules="rules" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="参保主体" prop="insureCompanyId">
          <a-select v-model="form.insureCompanyId" placeholder="请选择">
            <a-select-option v-for="item in insureList" :key="item.companyId" :value="item.companyId">{{ item.companyName }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="基数">
          <a-radio-group v-model="form.exportBaseType">
            <a-radio value="social"> 社保基数 </a-radio>
            <a-radio value="medical">医保基数</a-radio>
            <a-radio value="housing">公积金基数</a-radio>
            <a-radio value="all">全部</a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </Modal>
  </div>
</template>

<script>
import {
  getInsureCompanyListApi,
  exportInsureBaseApi
} from '@/services/insurance/archives'
export default {
  name: 'ExportCardinalityTable',
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      visible: false,
      insureList: [],
      form: {
        insureCompanyId: undefined,
        exportBaseType: 'social'
      },
      rules: {
        insureCompanyId: [{ required: true, message: '请选择参保主体', trigger: 'change' }]
      }
    }
  },
  methods: {
    show() {
      this.visible = true
      this.getInsureCompanyListApi()
    },
    async getInsureCompanyListApi() {
      const res = await getInsureCompanyListApi()
      this.insureList = res.data
    },
    handleCancel() {
      this.visible = false
      this.form = {
        insureCompanyId: undefined,
        exportBaseType: 'social'
      }
      this.$refs.ruleForm.resetFields()
    },
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          exportInsureBaseApi(this.form)
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  /deep/ .ant-radio-wrapper {
    margin-top: 10px !important;
  }
  /deep/.ant-form-item-label label{
    color: @sc-grey-100;
  }
  /deep/.ant-radio-wrapper span{
    color: @sc-grey-100;
  }
</style>
