<!--
 * @Date: 2022-05-19 15:19:43
 * @descriotion:
-->
<template>
  <Modal
    size="normal"
    title="选择打卡地址"
    :closable="true"
    overflow-auto
    :visible="show"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div>
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        row-key="id"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          columnWidth: 50,
          onChange: onSelectChange
        }"
        :selection-mode="true"
        :pagination="false"
        :scroll="{y:600}"
        @change="loadDataList"
      />
    </div>
  </Modal>
</template>
<script>
import mixins from '@/components/CustomTable/index.js'
import { clockLocationGetPageApi, clockWifiGetPageApi } from '@/services/attendance/settingset.js'
export default {
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  mixins: [mixins],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    // true 查外勤 false 查内勤
    outSideFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedRowKeys: [],
      columns: [
        {
          title: '地址名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '实际打卡地址',
          dataIndex: 'detail',
          key: 'detail'
        }
      ],
      dataSource: []
    }
  },
  watch: {
    type(newvalue) {
      console.log(newvalue)
      if (newvalue === 'address') {
        this.getAddressList()
      } else {
        this.getWifiList()
      }
    },
    options: {
      immediate: true,
      handler(value) {
        this.selectedRowKeys = []
        value.map(item => {
          this.selectedRowKeys.push(item.clockLocationId || item.clockWifiId)
        })
      }
    },
    outSideFlag(newvalue) {
      if (newvalue) {
        this.getAddressList(true)
      } else {
        this.getAddressList()
      }
    }

  },
  mounted() {
    if (this.type === 'address') {
      if (this.outSideFlag) {
        this.getAddressList(true)
      } else {
        this.getAddressList()
      }
    } else {
      this.getWifiList()
    }
  },
  methods: {
    async getAddressList(data) {
      const params = data ? { outSideFlag: 1 } : { outSideFlag: 0 }
      const res = await clockLocationGetPageApi(params)
      const arr = res.data || []
      arr.map(item => {
        item.name = item.locationName
        item.detail = item.gpsName
        item.outSideFlag = data ? 1 : 0
        item.id = item.clockLocationId
      })
      this.dataSource = arr
    },
    async getWifiList() {
      const res = await clockWifiGetPageApi()
      const arr = res.data || []
      arr.map(item => {
        item.name = item.wifiName
        item.detail = item.wifiBssId
        item.id = item.clockWifiId
      })
      this.dataSource = arr
    },
    handleOk() {
      const arr = []
      this.selectedRowKeys.forEach(item => {
        const obj = this.dataSource.filter(address => item === address.id)
        arr.push(obj[0])
      })
      this.$emit('address', arr)
    },
    handleCancel() {
      this.$emit('cancel')
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>
