<template>
  <div class="div-content">
    <div v-if="isShowIndex">
      <div class="calendar-top">
        <div class="top-box">
          <a-icon
            type="left"
            :style="`color: ${canSelectPreMounth ? '#d9d9d9' : ''}`"
            @click="pervious"
          />
          <div class="timePicker dont-copy">
            <svg class="iconpark-icon calenderIcon" @click="showPicker"><use href="#rongqi" /></svg>
            <a-month-picker
              v-model="time"
              :disabled-date="disabledDate"
              value-format="YYYYMM"
              format="YYYY年MM月薪资表"
              :open="isMonthPicker"
              :get-calendar-container="trigger => {return trigger.parentNode}"
              @openChange="onChanges"
            />
          </div>
          <a-icon type="right" :style="`color: ${canSelectNextMounth ? '#d9d9d9' : ''}`" @click="next" />
        </div>
        <div class="bottom-box dont-copy">
          {{ salaryPeriod.startDate }} ~ {{ salaryPeriod.endDate }}
        </div>
      </div>
      <CbStatistics :data-list="allDataList" length="4" class="statistics" :is-logo="true" :is-function="true" :min-width="340">
        <template slot="logo">
          <div v-if="status == 2" class="logo">
            已锁定
          </div>
        </template>
        <template slot="right">
          <div class="right-btns">
            <template v-if="status === '2' && ( !applyStatus || ['3', '4','100'].includes(applyStatus) )">
              <a-button prefix="audit-5o5mjp9j" @click="paySalaryProcess">
                发薪审批
              </a-button>
            </template>
            <template v-if="status === '1'&&isAllComplete">
              <a-button prefix="lock" @click="lockSalaryPeriod">
                锁定
              </a-button>
            </template>
            <template v-if="status === '2'&&isAllComplete">
              <a-button prefix="unlock" @click="unLockSalaryPeriod">
                解锁
              </a-button>
            </template>
            <a-button v-if="status === '2'&&applyStatus === '2'" class="ant-button" @click="sendImmediatelyStaffPayroll">前往工资条</a-button>
            <CbStatusTag
              v-if="status === '2'&&applyStatus"
              :is-click="false"
              :type-list="{
                '1': {
                  name: '发薪审批中'
                },
                '2': {
                  name: '发薪审批已通过'
                },
                '3': {
                  name: '发薪审批未通过'
                },
                '4': {
                  name: '发薪审批被驳回'
                },
              }"
              :type="applyStatus"
            />
          </div>
        </template>
      </CbStatistics>
      <Alert v-if="!['2','6'].includes(status)" type="info" closable show-icon>
        <template #content>
          <div class="flex-align-center">
            1.如需新增薪资组，可前往【设置-薪资组】进行
            <a-button
              type="link"
              class="nav"
              @click="noCompensation(1)"
            >去设置</a-button>
          </div>
          <div class="flex-align-center">
            2.有{{ tipInfo.notIncludedNum || 0 }}名人员暂无薪资组、{{ tipInfo.ignoreNum || 0 }}名人员不参与计薪
            <a-button
              type="link"
              class="nav"
              @click="noCompensation(2)"
            >去设置</a-button>
          </div>
          <div v-if="tipInfo.otherCalcGroupNum">
            3.有{{ tipInfo.otherCalcGroupNum }}名人员未启用薪资组或即将启用薪资组
          </div>
        </template>
      </Alert>
      <div v-for="(item, index) in wageList" :key="index" class="wage">
        <div class="wage-left">
          <div class="wage-left-title">
            {{ item.groupName }}
            <a-tag v-if="item.calcStatus != 5" color="orange"> 未生成 </a-tag>
            <a-tag v-else-if="item.calcStatus == 5" color="green">
              已完成
            </a-tag>
            <div class="name">{{ item.perNum }}人</div>
          </div>
          <div class="wage-left-content">
            <div v-if="item.calcStatus == 5" class="wage-left-content">
              人力成本：{{ item.laborCostSum || 0 | stateFormatNum }}元
              <span style="margin-right: 24px" /> 应付工资：{{ item.planSum || 0 | stateFormatNum }}元
              <span style="margin-right: 24px" /> 实发工资：{{ item.realSum || 0 | stateFormatNum }}元
            </div>
            <div v-else class="button-content">
              <template v-for="(key, statusIndex) in salaryGroupStatusList">
                <div
                  :key="`salaryGroupStatusList${statusIndex}`"
                  class="content"
                >
                  <svg class="iconpark-icon">
                    <use :href="key.status(item.calcStatus)" />
                  </svg>
                  {{ key.name }}
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="wage-right">
          <template v-if="item.calcStatus == 0">
            <a-button prefix="bendijisuan" @click="startCalc(item)">
              开始计薪
            </a-button>
          </template>
          <template v-else>
            <!-- <a-button
              v-if="item.calcStatus != 6"
              prefix="jixusuanxin18"
              @click="calculateSalary(item)"
            >
              重新计算
            </a-button> -->
            <a-button prefix="chakan-chakanmingxi" @click="toDetail(item)">
              查看明细
            </a-button>
            <template v-if="status!=='2'">
              <a-button
                v-if="item.calcStatus != 5"
                prefix="jixusuanxin18"
                @click="toContinue(item)"
              >
                继续计算</a-button>
              <a-button
                prefix="quxiaojixin"
                @click="cancelSalary(item)"
              >
                {{ item.calcStatus > 3?'解锁重算':'取消计薪' }}
              </a-button>
            </template>
          </template>
        </div>
      </div>
    </div>
    <div v-else>
      <Process
        :salary-item-info="salaryItemInfo"
        :opration-type="oprationType"
        :salary-period="salaryPeriod"
        :period="time"
        :salary-group="wageList"
        @goBack="goBack"
      />
    </div>
    <!--弹出框  -->
    <div class="model">
      <IgnoreModal
        :visible="visible"
        title="无薪资组人员"
        :data-prompt="userList"
        placeholder="请选择薪资组"
        replace-group-key="payrollGroupId"
        replace-ignore-type-key="needSalaryGroup"
        :select-list="wageList"
        :replace-fields="{
          label: 'groupName',
          value: 'payrollGroupId',
        }"
        @setPerson="setPerson"
        @cancel="closeModel"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { stateFormatNum } from '@/utils/num-handle.js'
import {
  getSalaryPeriodAll,
  getSalaryGroupCalc,
  startCalc,
  getNotCalcStaff,
  restartCalc,
  saveNotCalcStaff,
  cancelCalc,
  salaryApprovalGetSalaryGroupCalc,
  lockSalaryPeriod,
  unLockSalaryPeriod
} from '@/services/wage/wagePayroll'
export default {
  name: 'WagePayroll',
  filters: {
    stateFormatNum
  },
  components: {
    IgnoreModal: () => import('@/components/CbIgnoreModal'),
    Process: () => import('./process.vue'),
    Alert: () => import('@/components/CbAlert'),
    CbStatusTag: () => import('@/components/CbStatusTag'),
    CbStatistics: () => import('@/components/CbStatistics/index')
  },
  data() {
    return {
      canUseMounthList: [],
      form: {
        region: undefined
      },
      visible: false,
      isShowIndex: true,
      time: '',
      // 选择的薪资组的信息集合
      salaryItemInfo: null,
      // 点击操作按钮的类型数据
      oprationType: '',
      // 计薪周期的起止年月
      salaryPeriod: {
        startDate: '',
        endDate: ''
      },
      clicked: false,
      status: undefined,
      applyStatus: undefined, // 审批状态
      // 顶部展示的具体数据信息的集合
      detailInfo: {
        startDate: '',
        endDate: ''
      },
      isMonthPicker: false,
      allDataList: [
        {
          label: '计薪人员',
          code: 'perNum',
          num: '',
          unit: ''
        },
        {
          label: '人力成本',
          code: 'laborCostSum',
          num: '',
          unit: '万元'
        },
        {
          label: '应发工资',
          code: 'planSum',
          num: '',
          unit: '万元'
        },
        {
          label: '实发工资',
          code: 'realSum',
          num: '',
          unit: '万元'
        }
      ],
      wageList: [],
      userList: [],
      tipInfo: {},
      salaryGroupStatusList: [
        {
          name: '应发工资',
          status: function(status) {
            status = Number(status)
            if (status === 0) {
              return '#weikaishi'
            }
            if (status > 0 && status <= 3) {
              return '#jinhangzhong'
            }
            if (status > 3) {
              return '#yiwancheng'
            }
          }
        },
        {
          name: '应缴个税',
          status: function(status) {
            status = Number(status)
            if (status <= 3) {
              return '#weikaishi'
            }
            if (status === 4) {
              return '#jinhangzhong'
            }
            if (status > 4) {
              return '#yiwancheng'
            }
          }
        },
        {
          name: '实发工资',
          status: function(status) {
            status = Number(status)
            if (status < 5) {
              return '#weikaishi'
            }
            if (status === 5) {
              return '#jinhangzhong'
            }
            if (status === 6) {
              return '#yiwancheng'
            }
          }
        }
      ]
    }
  },
  computed: {
    showMounthList() {
      return this.canUseMounthList.map((item) => {
        return item.payPeriod
      })
    },
    canSelectPreMounth() {
      if (this.showMounthList.length === 0) return true
      return this.showMounthList[0] === this.time
    },
    canSelectNextMounth() {
      if (this.showMounthList.length === 0) return true
      return this.showMounthList[this.showMounthList.length - 1] === this.time
    },
    isAllComplete() {
      const isComplete = this.wageList?.every((wage) => wage.calcStatus === '5')
      return isComplete
    },
    isHasComplete() {
      const isComplete = this.wageList?.some((wage) => wage.calcStatus === '5')
      return isComplete
    },
    isAllIncomplete() {
      const isIncomplete = this.wageList?.every((wage) => wage.calcStatus === '0')
      return isIncomplete
    }
  },
  watch: {
    // 选择的计薪周期改变了则重新刷新数据
    time() {
      this.initData()
    }
  },
  async mounted() {
    const payPeriod = this.$route.params.payPeriod || this.$route.query.time
    await this.getSalaryPeriodAll(payPeriod)
    this.initData()
  },
  methods: {
    async initData() {
      const res = await getSalaryGroupCalc({ payPeriod: this.time })
      this.salaryPeriod.startDate = res.data.startDate
      this.salaryPeriod.endDate = res.data.endDate
      this.allDataList.forEach((item) => {
        item.num = stateFormatNum(res.data[item.code])
      })
      this.wageList = res.data.salaryGroupCaclList
      this.status = res.data.calcStatus
      this.applyStatus = res.data.applyStatus
      this.tipInfo = {
        notIncludedNum: res.data.notIncludedNum,
        otherCalcGroupNum: res.data.otherCalcGroupNum,
        ignoreNum: res.data.ignoreNum
      }
    },
    async getSalaryPeriodAll(period) {
      const res = await getSalaryPeriodAll()
      this.canUseMounthList = res.data
      this.time = period || res.data[0].currentPayPeriod
    },
    disabledDate(data) {
      return !this.showMounthList.includes(moment(data).format('YYYYMM'))
    },
    // 发薪审批
    async paySalaryProcess() {
      const res = await salaryApprovalGetSalaryGroupCalc({
        payPeriod: this.time,
        pageNum: 1,
        pageSize: -1
      })
      if (res.code === 100) {
        this.$message.success('操作成功！')
        this.initData()
      }
    },
    // 锁定计薪周期
    async lockSalaryPeriod() {
      const res = await lockSalaryPeriod({
        period: this.time
      })
      if (res.code === 100) {
        this.$message.success('已锁定！')
        this.initData()
      }
    },
    // 锁定计薪周期
    async unLockSalaryPeriod() {
      const res = await unLockSalaryPeriod({
        period: this.time
      })
      if (res.code === 100) {
        this.$message.success('已解锁！')
        this.initData()
      }
    },
    visibleChange(val) {
      const link = document.createElement('a')
      this.clicked = val
      link.click()
    },
    // 前往工资条
    async sendImmediatelyStaffPayroll() {
      this.$router.push({
        name: 'wageSlip',
        params: {
          period: this.time
        }
      })
    },
    startCalc(info) {
      if (this.isHasComplete || this.isAllIncomplete) {
        this.$Reconfirm({
          title: '提示',
          content: `${this.isAllIncomplete ? '当前计薪周期开始计薪后，将不可再新增薪资组或编辑薪资组规则，确认开始计薪吗？' : '计薪开始，薪资组全部取消锁定，确认开始计薪吗？'}`
        }).then(() => {
          this.handleStartCalc(info)
        }).catch(() => {
        })
        return
      }
      this.handleStartCalc(info)
    },
    // 开始计薪
    async handleStartCalc(info) {
      this.oprationType = 'startCalc'
      const res = await startCalc({
        payPeriod: this.time,
        payrollGroupId: info.payrollGroupId
      })
      this.salaryItemInfo = info
      this.salaryItemInfo.groupCalcId = res.data.groupCalcId
      this.isShowIndex = false
    },
    // 重新计算
    async calculateSalary(info) {
      this.oprationType = 'restartCalc'
      const res = await restartCalc({ groupCalcId: info.groupCalcId })
      this.salaryItemInfo = info
      this.salaryItemInfo.groupCalcId = res.data.groupCalcId
      this.isShowIndex = false
    },
    // 查看明细
    toDetail(info) {
      this.oprationType = 'detail'
      this.salaryItemInfo = info
      this.isShowIndex = false
    },
    // 继续计算
    toContinue(info) {
      this.oprationType = 'toContinue'
      this.salaryItemInfo = info
      this.isShowIndex = false
    },
    cancelSalary(info) {
      cancelCalc({ groupCalcId: info.groupCalcId }).then((res) => {
        this.$message.success(`${info.calcStatus > 3 ? '解锁' : '取消'}成功！`)
        this.initData()
      })
    },
    // 返回
    goBack(period) {
      this.getSalaryPeriodAll(period)
      this.initData()
      this.isShowIndex = true
    },
    pervious() {
      if (!this.canSelectPreMounth) {
        this.time = moment(this.time).subtract(1, 'months').format('YYYYMM')
      }
    },
    next() {
      if (!this.canSelectNextMounth) {
        this.time = moment(this.time).month(moment(this.time).month() + 1).format('YYYYMM')
      }
    },
    showPicker() {
      this.isMonthPicker = true
    },
    onChanges() {
      this.isMonthPicker = false
    },
    async noCompensation(id) {
      if (id === 2) {
        const res = await getNotCalcStaff()
        this.userList = res.data.map((item) => {
          return {
            ...item,
            needSalaryGroup: !item.needSalaryGroup
          }
        })
        this.visible = true
      } else {
        this.$router.push({
          name: 'wageSetting',
          params: {
            fileName: 'salaryGroup'
          }
        })
      }
    },
    // 给人员设置薪资组
    setPerson(info) {
      saveNotCalcStaff({
        staffList: info.map((item) => {
          return {
            ...item,
            needSalaryGroup: Number(!item.needSalaryGroup)
          }
        })
      }).then(() => {
        this.visible = false
        this.$message.success('修改成功！')
        this.initData()
      })
    },
    closeModel() {
      this.visible = false
    }
  }
}
</script>
<style lang="less" scoped>
.iconpark-icon {
  width: 16px;
  height: 16px;
  color: @sc-primary-100;
}
.div-content {
  background: @gray-1;
  min-height: 100%;
  height: auto !important;
  padding: 20px;
  position: relative;
}
 /deep/ .ant-alert-with-description .ant-alert-message {
    margin-bottom: 0 !important;
  }
  .right-btns{
    /deep/ .ant-btn {
      margin-right: 10px;
    }
  }
.wage {
  display: flex;
  justify-content: space-between;
  border: 1px solid @sc-greyBg-10;
  padding: 19px 24px 20px 14px;
  margin-top: 16px;
  border-radius: 8px;
  /deep/ .ant-btn {
    > span {
      color: @sc-grey-100;
    }
    &:hover {
      span {
        color: @sc-primary-100;
      }
    }
  }
  &-left {
    &-title {
      margin-bottom: 20px;
      display: flex;
      font-size: 16px;
      color: @sc-grey-100;
      .ant-tag {
        margin-left: 10px;
      }
      .name {
        margin-left: 10px;
      }
    }
    &-content {
      color: @sc-grey-80;
      .font-size(14px);
    }
  }
  .wage-right {
    display: flex;
    align-items: center;
    .iconpark-icon {
      vertical-align: bottom;
      margin-bottom: 3px;
      margin-right: 3px;
    }
    .ant-btn {
      margin-right: 10px;
    }
  }
}
// 顶部
.calendar-top {
  width: auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .top-box {
    display: flex;
    justify-content: center;
    align-items: center;
    color: @sc-grey-100;
    .font-size(16px);
    /deep/i.anticon {
      font-size: 14px;
    }
    .timePicker {
      .calenderIcon {
        vertical-align: bottom;
        margin-bottom: 8px;
        margin-left: 60px;
        cursor: pointer;
      }
      .ant-calendar-picker {
        /deep/ .ant-input {
          padding-right: 0;
        }
        /deep/.ant-input:focus{
          box-shadow: unset;
        }
        /deep/.ant-calendar-picker-input {
          border: 0;
          font-size: 16px;
          padding-left: 5px;
          color: @sc-grey-100;
          font-weight: 600;
        }
        /deep/.ant-calendar-picker-clear,
        /deep/.ant-calendar-picker-icon {
          display: none;
        }
      }
    }
  }
  .bottom-box {
    margin: 5px 0 0 0;
    color: @sc-grey-60;
    .font-size(14px);
  }
}
.wage-left-content {
  .button-content {
    .content {
      margin: 0 24px 0 0;
      color: @sc-grey-80;
      display: flex;
      align-items: center;
      .iconpark-icon {
        width: 18px;
        height: 18px;
        margin-right: 6px;
        color: @sc-primary-100;
      }
    }
     display: flex;
  }
}
.ant-tag-orange {
  color: @sc-warning-100;
  background: @sc-warning-10;
  border: 1px solid @sc-warning-100;
}
.ant-tag-green {
  padding: 0 7px;
  background: @sc-success-10;
  color: @sc-success-100;
  border: 1px solid @sc-success-100;
}
.invitation-box{
  width: 160px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .invitation-box_img{
    width: 120px;
    height: 120px;
    background: #fff;
    /*border-radius: 50%;*/
  }
  .invitation-box_title{
    margin: 16px 0;
  }
}
.ant-button{
  margin-left: 12px;
}
.logo{
  width: 100%;
  border-radius:6px 0 ;
  height: 28px;
  background-color: @sc-primary-100;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/.statistics-center{
  padding-left:60px ;
}
/deep/.statistics-center-item{
  position: relative;
  &:first-child::after {
    content: '';
    position: absolute;
    right: 0;
    height: 30px;
    width: 1px;
    background-color: @sc-greyBg-20;
    top: 50%;
    transform: translateY(-50%);
  }
}
/deep/ .ant-calendar-picker-container {
  top: 32px !important;
  left: -64px !important;
}
/deep/ button.nav.ant-btn {
  padding-left: 6px;
}
.button{
  margin-right: 12px;
}
</style>
