<template>
  <div class="ScheduleList">
    <a-card
      hoverable
      :tab-list="tabListNoTitle"
      :active-tab-key="noTitleKey"
      size="small"
      class="affair-card"
      @tabChange="key => onTabChange(key, 'noTitleKey')"
    >
      <div>
        <StatisticsEchart :type="noTitleKey" />
        <NumStatistics v-if="noTitleKey === '2'" />
        <MoneyStatistics v-if="noTitleKey === '3'" />
        <MatterStatus :type="noTitleKey" />
      </div>
    </a-card>
  </div>
</template>

<script>
export default {
  name: 'ScheduleList',
  components: {
    StatisticsEchart: () => import('./components/statisticsEchart.vue'),
    MatterStatus: () => import('./components/matterStatus.vue'),
    NumStatistics: () => import('./components/numStatistics.vue'),
    MoneyStatistics: () => import('./components/moneyStatistics.vue')
  },
  props: {},
  data() {
    return {
      noTitleKey: '1',
      // 筛选后的日程
      filterScheduleList: [],
      tabListNoTitle: [
        {
          tab: '员工',
          key: '1'
        },
        {
          tab: '考勤',
          key: '2'
        },
        {
          tab: '薪酬',
          key: '3'
        },
        {
          tab: '险金',
          key: '4'
        }
      ]
    }
  },
  methods: {
    onTabChange(key, type) {
      this[type] = key
    }
  }
}
</script>

<style lang="less" scoped>
.ScheduleList {
  width: calc(50% - 10px);
  height: 100%;
  overflow: hi;
  .affair-card {
    height: 641px;
  }
  .ant-card {
   border-radius: 8px;
   border-color:#fff ;
  }
  .ant-card:hover {
    box-shadow: none;
  }
  /deep/.ant-card-head {
    padding: 0 13px !important;
  }
  /deep/.ant-tabs-tab {
    margin-right: 40px;
    padding: 13px 6px;
    .font-size(16px);
    color: @sc-grey-100;
  }
  /deep/.ant-tabs-tab:hover,/deep/.ant-tabs-tab-active {
    color: @sc-primary-100;
    font-weight: 400;
  }
  /deep/.ant-card-body {
    padding: 15px 14px 15px 0px;
    height: 590px;
    overflow: auto;
  }
  // 滚动条
  ::-webkit-scrollbar {
      width: 4px;
    }
  ::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background: rgba(143, 147, 161, 0.1);
    }
  ::-webkit-scrollbar-track {
      display:none;
  }
}
</style>
