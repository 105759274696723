<template>
  <Modal
    overflow-auto
    :closable="true"
    :visible="visible"
    :title="modalTitle"
    size="normal"
    :z-index="2"
    @cancel="handleCancel"
  >
    <a-tabs v-model="activeKey" class="tabs_model" @change="callback">
      <a-tab-pane v-for="(item,index) of entryMatterList" :key="index" :tab="item.itemName">
        <div class="tabs_box">
          <a-form-model
            ref="addInfo"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-row>
              <a-col :span="24">
                <a-form-model-item v-if="item.itemKey === 'entry_attence'" label="考勤组名称" prop="entryAttence">
                  <a-select v-model="entryAttence.entryAttenceGroup" :disabled="funName.addStaffInAttendanceGroup === 'addStaffInAttendanceGroup'" allow-clear placeholder="请选择考勤组" @change="changeAttenceName">
                    <a-select-option v-for="it of entryAttenceGroupList" :key="it.attendanceGroupId" :value="it.attendanceGroupId">{{ it.attendanceGroupName }}</a-select-option>
                  </a-select>
                </a-form-model-item>
                <div v-if="item.itemKey === 'entry_salary'">
                  <a-form-model-item v-for="i of entrySalaryList" :key="i.fieldCode" :label="i.fieldName" :prop="i.fieldCode">
                    <a-input v-model="i.amount" :placeholder="`请填写` + i.fieldName" />
                  </a-form-model-item>
                </div>
                <a-form-model-item v-if="item.itemKey === 'salary_group_config'" label="薪资组名称" prop="">
                  <a-select v-model="salaryGroupConfig.salaryGroupName" :disabled="funName.setStaffGroup === 'setStaffGroup'" allow-clear placeholder="请选择薪资组" @change="changeSalary">
                    <a-select-option v-for="it of salaryGroupList" :key="it.groupId" :value="it.groupId">{{ it.groupName }}</a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item v-if="item.itemKey === 'entry_taxpay_subject'" allow-clear label="报税主体" prop="">
                  <a-select v-model="entryTaxpaySubject.taxEntity" :disabled="funName.addTaxpaySubjectStaff === 'addTaxpaySubjectStaff'" placeholder="请选择报税主体" @change="changeTaxEntity">
                    <a-select-option v-for="it of endyearBounsList" :key="it.taxpaySubjectId" :value="it.taxpaySubjectId">{{ it.tenantName }}</a-select-option>
                  </a-select>
                </a-form-model-item>
                <EntryInsurance v-if="item.itemKey === 'entry_insurance'" ref="entryInsurance" :insure-company-list="insureCompanyList" />
              </a-col>
            </a-row>
          </a-form-model>
        </div>
      </a-tab-pane>
    </a-tabs>
    <template slot="footer">
      <div class="footer_box">
        <a-button type="primary" @click="handleOk">提交</a-button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { listGroup, setStaffGroup } from '@/services/wage/salaryGroup.js'
import { getStaffNotSetFixedSalary, getEndyearBounsList, addTaxpaySubjectStaff } from '@/services/wage/account.js'
import { attendanceGroupNameListApi } from '@/services/attendance/scheduling.js'
import { getEntryMatterList } from '@/services/user/setting.js'
import { addStaffInAttendanceGroup } from '@/services/attendance/attendance.js'
import { setStaffFixedSalary } from '@/services/wage/wageArchives.js'
import { insureApi } from '@/services/insurance/archives.js'
export default {
  name: 'EntryMatters',
  components: {
    Modal: () => import('@/components/CbModal/index'),
    EntryInsurance: () => import('./entryInsurance.vue')
  },
  data() {
    return {
      labelCol: { lg: 5, xl: 4, xxl: 4 },
      wrapperCol: { lg: 12, xl: 13, xxl: 14 },
      visible: false,
      modalTitle: '入职事项',
      entryMatterList: [],
      activeKey: 0, // 当前激活tabs
      entryAttence: { // 考勤组值
        entryAttenceGroup: undefined
      },
      entrySalaryList: [],
      salaryGroupConfig: {
        salaryGroupName: undefined // 薪资组名称
      },
      entryTaxpaySubject: {
        taxEntity: undefined // 报税主体
      },
      entryAttenceGroupList: [], // 考勤组名称列表
      userInfo: {},
      salaryGroupList: [], // 薪资组列表
      endyearBounsList: [], // 报税主体列表
      insureCompanyList: [], // 社保主体列表
      funName: {}, // 函数名
      isFirst: false,
      taxEntityDisabled: false // 报税主体是否禁用
    }
  },
  methods: {
    open(userInfo, dataList, key) {
      this.isFirst = typeof dataList !== 'undefined'
      this.userInfo = userInfo
      this.visible = true
      this.getEntryMatterList(dataList)
      if (key) {
        this.activeTab(dataList, key)
      }
    },
    activeTab(arr, key) {
      arr.forEach((item, index) => {
        if (item.entryMatterKey === key) {
          this.activeKey = index
        }
      })
    },
    handleOk() {
      try {
        switch (this.entryMatterList[this.activeKey].itemKey) {
          case 'entry_attence': // 单个人员添加到考勤组
            if (this.funName.addStaffInAttendanceGroup === 'addStaffInAttendanceGroup') {
              this.$message.warning('已设置考勤组')
              break
            }
            this.addStaffInAttendanceGroup()
            break
          case 'entry_salary': // 入职定薪
            this.setStaffFixedSalary()
            break
          case 'salary_group_config': // 薪资组设置
            this.setStaffGroup()
            break
          case 'entry_taxpay_subject':
            if (this.funName.addTaxpaySubjectStaff === 'addTaxpaySubjectStaff') {
              this.$message.warning('已关联报税主体')
              break
            }
            this.addTaxpaySubjectStaff()
            break
          default:
            this.getDataList()
            break
        }
      } catch (error) {
        console.log(error, 'error')
      }
    },
    handleCancel() {
      this.visible = false
      this.activeKey = 0
      this.funName = {}
      this.entryTaxpaySubject.taxEntity = undefined
      this.entryAttence.entryAttenceGroup = undefined
      this.salaryGroupConfig.salaryGroupName = undefined
      this.$emit('closeSon', this.userInfo.onJobId)
    },
    // 请求入职事项列表
    async getEntryMatterList(dataList) {
      if (dataList) {
        this.entryMatterList = dataList
      } else {
        const res = await getEntryMatterList()
        this.entryMatterList = res.data.filter((item) => {
          return item.itemStatus === '1'
        })
      }
      if (this.entryMatterList.length > 0) {
        const arr = []
        this.entryMatterList.forEach((item) => {
          arr.push(item.itemKey)
        })
        if (arr.includes('entry_attence')) {
          this.attendanceGroupNameList()
        }
        if (arr.includes('entry_salary')) {
          this.getStaffNotSetFixedSalary()
        }
        if (arr.includes('salary_group_config')) {
          this.listGroup()
        }
        if (arr.includes('entry_taxpay_subject')) {
          this.getEndyearBounsList()
        }
      }
    },
    // 点击tabs回调
    callback() {
    },
    // 获取考勤组名称列表
    async attendanceGroupNameList() {
      const res = await attendanceGroupNameListApi()
      this.entryAttenceGroupList = res.data.map((item) => {
        item.amount = undefined
        return item
      })
    },
    // 获取定薪列表
    async getStaffNotSetFixedSalary() {
      const res = await getStaffNotSetFixedSalary({ onJobId: this.userInfo.onJobId })
      this.entrySalaryList = res.data.filter((item) => {
        return item.fieldCode === 'GD01' || item.fieldCode === 'SYQGD01'
      })
    },
    // 获取薪资组列表
    async listGroup() {
      const res = await listGroup()
      this.salaryGroupList = res.data.filter((item) => {
        return item.enabled === 1
      })
    },
    // 获取报税主体列表
    async getEndyearBounsList() {
      const res = await getEndyearBounsList()
      this.endyearBounsList = res.data
    },
    changeAttenceName(val) {
    },
    changeSalary(val) {
    },
    changeTaxEntity() {

    },
    // 单个人员添加到考勤组
    async addStaffInAttendanceGroup() {
      if (this.entryAttence.entryAttenceGroup) {
        const obj = {
          staffId: this.userInfo.staffId,
          onJobId: this.userInfo.onJobId,
          attendanceGroupId: this.entryAttence.entryAttenceGroup
        }
        const res = await addStaffInAttendanceGroup(obj)
        if (res.code === 100) {
          this.$set(this.funName, 'addStaffInAttendanceGroup', 'addStaffInAttendanceGroup')
          this.$message.success('考勤组设置成功')
          if (this.isFirst) {
            this.$emit('refreshTable')
          }
        }
      } else {
        this.$message.warning('当前表单暂无内容提交')
      }
    },
    // 入职定薪
    async setStaffFixedSalary() {
      const num = this.entrySalaryList.filter((item) => {
        return typeof item.amount !== 'undefined' && item.amount !== ''
      })
      if (num.length > 0) {
        const arr = []
        num.forEach((item) => {
          const obj = {
            itemNo: item.fieldCode,
            amount: item.amount
          }
          arr.push(obj)
        })
        const obj = {
          onJobId: this.userInfo.onJobId,
          isCalc: 1,
          salaryList: arr
        }
        const res = await setStaffFixedSalary(obj)
        if (res.code === 100) {
          this.$set(this.funName, 'setStaffFixedSalary', 'setStaffFixedSalary')
          this.$message.success('定薪成功')
          if (this.isFirst) {
            this.$emit('refreshTable')
          }
        }
      } else {
        this.$message.warning('当前表单暂无内容提交')
      }
    },
    // 报税主体添加人员
    async addTaxpaySubjectStaff() {
      if (this.entryTaxpaySubject.taxEntity) {
        const obj = {
          onJobId: this.userInfo.onJobId,
          staffId: this.userInfo.staffId,
          taxpaySubjectId: this.entryTaxpaySubject.taxEntity
        }
        const res = await addTaxpaySubjectStaff(obj)
        if (res.code === 100) {
          this.$set(this.funName, 'addTaxpaySubjectStaff', 'addTaxpaySubjectStaff')
          this.$message.success('关联成功')
          if (this.isFirst) {
            this.$emit('refreshTable')
          }
        }
      } else {
        this.$message.warning('当前表单暂无内容提交')
      }
    },
    // 薪资组设置
    async setStaffGroup() {
      if (this.salaryGroupConfig.salaryGroupName) {
        const obj = {
          onJobId: this.userInfo.onJobId,
          staffId: this.userInfo.staffId,
          groupId: this.salaryGroupConfig.salaryGroupName
        }
        const res = await setStaffGroup(obj)
        if (res.code === 100) {
          this.$set(this.funName, 'setStaffGroup', 'setStaffGroup')
          this.$message.success('薪资组设置成功')
          if (this.isFirst) {
            this.$emit('refreshTable')
          }
        }
      } else {
        this.$message.warning('当前表单暂无内容提交')
      }
    },
    // 员工投保
    async getDataList() {
      const obj = {}
      let num = 0
      const dataList = this.$refs.entryInsurance[0].$children.map((item) => {
        return item.transmitData()
      })
      dataList.forEach((item) => {
        num = item.isOpen ? ++num : num
        obj[`${item.type}Status`] = item.isOpen ? '1' : '0'
        obj[item.type] = item.formData
      })
      if (num === 0) {
        this.$message.warning('当前表单暂无内容提交')
        return
      }
      if (this.$refs.entryInsurance && this.$refs.entryInsurance.length > 0) {
        const { social, medical, housing } = obj
        const socialForm = this.judgeForm(social, obj.socialStatus)
        const medicalForm = this.judgeForm(medical, obj.medicalStatus)
        const housingForm = this.judgeForm(housing, obj.housingStatus)
        if (socialForm && medicalForm && housingForm) {
          const res = await insureApi({
            onJobIds: [this.userInfo.onJobId],
            socialStatus: obj.socialStatus,
            medicalStatus: obj.medicalStatus,
            housingStatus: obj.housingStatus,
            social: obj.socialStatus === '1' ? social : null,
            medical: obj.medicalStatus === '1' ? medical : null,
            housing: obj.housingStatus === '1' ? housing : null
          })
          if (res.code === 100) {
            this.$set(this.funName, 'getDataList', 'getDataList')
            this.$message.success('员工投保设置成功')
            if (this.isFirst) {
              this.$emit('refreshTable')
            }
          }
        } else {
          this.$message.warning('请开启的选表单填写完整')
          return
        }
      } else {
        this.entryMatterList.forEach((item, index) => {
          if (item.itemKey === 'entry_insurance') {
            this.activeKey = index
            this.$message.warning('参保基数未设置,若员工未投保请关闭开关')
          }
        })
      }
    },
    // 判断表单是否填写完整
    judgeForm(obj, isOpen) {
      if (isOpen === '1') {
        let empty = 0
        Object.values(obj).forEach((item) => {
          if (typeof item === 'undefined') {
            empty++
          }
        })
        return empty === 0
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="less" scoped>
.tabs_box{
    height: 220px;
    // margin: 0 auto;
    padding: 10px;
}
/deep/ .ant-form-item-label{
    font-size: 14px;
    width: 120px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
/deep/ .ant-input{
    width: 340px;
}
/deep/ .ant-select-selection{
    width: 340px;
}
/deep/.ant-form-item{
    margin-bottom: 16px !important;
}
/deep/form .ant-select, form .ant-cascader-picker{
    width: auto;
}
</style>
