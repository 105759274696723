<template>
  <div>
    <BlockTitle title="弹性设置" :is-bold="true" class="msg-title" />
    <a-row>
      <a-radio-group v-model="form.shiftFlexType" @change="flexTypeChange">
        <a-radio v-for="item in flexTypeList" :key="item.value" :value="item.value">{{ item.label }}</a-radio>
      </a-radio-group>
      <div v-show="+form.shiftFlexType" class="flex-box">
        <!-- 豁免时间 -->
        <div v-if="+form.shiftFlexType === 1" class="flex-setting">
          晚到<CbNumber v-model="form.allowLateInMinutes" :min="1" :max="480" class="number-input" />分钟内不计迟到，早走<CbNumber v-model="form.allowEarlyOutMinutes" :min="1" :max="480" class="number-input" />分钟内不计早退
        </div>
        <!-- 弹性考勤（早来早走，晚来晚走） -->
        <div v-show="+form.shiftFlexType === 2" class="flex-setting">
          最多早到早走
          <CbNumber v-model="form.flexInMaxMinutes" :min="5" :max="240" class="number-input" @change="change" />
          分钟，最多晚到晚走
          <CbNumber v-model="form.flexOutMaxMinutes" :min="5" :max="240" class="number-input" @change="change" />分钟
        </div>
        <!-- 弹性考勤（分时段晚来晚走） -->
        <div v-show="+form.shiftFlexType === 3" class="flex-setting">
          有<a-select v-model="form.flexTimes" allow-clear @change="change">
            <a-select-option v-for="item in flexNumList" :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
          </a-select>个弹性上班时段
          <CbNumber v-model="form.flexTimesStep" :min="5" :max="240" class="number-input" @change="change" />分钟
        </div>
        <!-- 弹性文案 -->
        <div v-if="describeList.length" class="time-display">
          <ul v-for="(content,i) in describeList" :key="i">
            <li>{{ content }}</li>
          </ul>
        </div>
      </div>
    </a-row>
  </div>
</template>

<script>
import { getShiftFlexTypeDescribe } from '@/services/attendance/shift.js'
import { shiftFlexType, flexNum } from '../../../../dict.js'
import { debounce } from '@/utils/index.js'
export default {
  name: 'FlexSetting',
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index'),
    CbNumber: () => import('@/components/CbNumber/index')
  },
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    shiftType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      flexTypeList: shiftFlexType,
      flexNumList: flexNum,
      describeList: []
    }
  },
  watch: {
    shiftType(val) {
      console.log(val)
    }
  },
  created() {
    this.getFlexTypeList()
  },
  mounted() {
    this.flexTypeList = this.shiftType === 'ordinary' ? shiftFlexType : shiftFlexType.slice(0, 2)
  },
  methods: {
    // 获取弹性设置字典表
    getFlexTypeList() {

    },
    change: debounce(function() {
      /*
       判断时间设置列表中最后一组的结束时间是否填写 && 不同type时判断的不同条件
       type = 2 判断上、下班弹性最大分钟 是否填写
       type = 3 判断弹性时段个数，弹性时段间隔分钟 是否填写
       不成立则清空
       */
      const {
        shiftPunchRuleList: timeGroup,
        shiftFlexType: type,
        flexInMaxMinutes,
        flexOutMaxMinutes,
        flexTimes,
        flexTimesStep } = this.form
      if (timeGroup[timeGroup.length - 1]?.endTime && +type === 2 && flexInMaxMinutes && flexOutMaxMinutes) {
        this.getDescribeList()
        return
      } else if (timeGroup[timeGroup.length - 1]?.endTime && +type === 3 && flexTimes && flexTimesStep) {
        this.getDescribeList()
        return
      }
      this.describeList = []
    }, 500),
    // 获取弹性设置文案
    async getDescribeList() {
      const res = await getShiftFlexTypeDescribe({ ...this.form })
      this.describeList = res.data
    },
    // 更新弹性设置文案
    flexTypeChange({ target: { value }}) {
      const form = this.form
      form.allowLateInMinutes = form.allowEarlyOutMinutes = form.flexInMaxMinutes = form.flexOutMaxMinutes = form.flexTimes = form.flexTimesStep = undefined
      if (['2', '3'].includes(value)) return this.change()
      this.describeList = []
    }
  }
}
</script>

<style lang="less" scoped>
.msg-title {
  margin-bottom: 14px;
}
.flex-box{
  margin-top: 16px;
  padding: 20px 12px 0 12px;
  background: @sc-greyBg-10;
  border-radius: 4px;
  border: 1px solid @sc-grey-20;
  /deep/.ant-select{
    width:115px;
    margin: 0 10px;
  }
  .flex-setting{
    margin-bottom:20px;
  }
  .time-display{
    border-top: 1px solid @sc-grey-20;
    padding: 17px 0;
    ul{
      li {
        list-style-type: disc !important;
        margin-left: 18px;
        .font-size(14px);
        color: @sc-grey-40;
      }
    }
  }
}
/deep/ span.ant-radio + * {
  color: @sc-grey-80;
}
.number-input{
  width:115px;
  margin: 0 10px;
  display:inline-block;
}
</style>
<style>
.ant-time-picker-panel-select::-webkit-scrollbar {
    display:none ;
}
</style>
