<template>
  <CbDrawer
    is-mask
    :title="mode === 'add' ? '' : '预览'"
    size="normal"
    :visible="visible"
    :closable="mode !== 'add'"
    :z-index="12"
    :display-btn="true"
    @close="onClose"
  >
    <template v-if="mode === 'add'" #title>
      <HeaderBack title="预览" @back="onClose" />
    </template>
    <PhonePanel height="100%">
      <template #content>
        <p class="title">{{ newsTitle }}</p>
        <p class="date">{{ date }}</p>
        <p class="text" v-html="text" />
      </template>
    </PhonePanel>
  </CbDrawer>
</template>
<script>
import moment from 'moment'
export default {
  name: 'Preview',
  components: {
    CbDrawer: () => import('@/components/CbDrawer'),
    HeaderBack: () => import('@/components/headerBack/index.vue'),
    PhonePanel: () => import('@/components/CbPhonePanel/index.vue')
  },
  props: {
    mode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      newsTitle: '',
      date: '',
      text: ''
    }
  },
  methods: {
    onShow(info) {
      this.newsTitle = info.newsTitle
      this.date = info.createTime ? moment(info.createTime).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm')
      this.text = info.newsText
      this.visible = true
    },
    onClose() {
      this.visible = false
    }
  }
}
</script>
<style lang="less" scoped>
.title{
  margin-bottom: 16px;
  font-size: 18px;
  color:@sc-grey-100;
  font-weight: 600;
}
.date{
  margin-bottom: 16px;
  color:@sc-grey-60;
}
.text{
  color:@sc-grey-100 !important;
  /deep/a{
    color: #29CEA0;
  }
}
</style>
