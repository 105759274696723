<template>
  <div class="salary-particulars">
    <!--  页头导航  -->
    <HeaderBack :title="`${ moment(currentTime).format('YYYY年MM月')}工资条`" @back="goBack" />
    <CbNumStatistics :is-tabs="true" :statistics-info="statisticsInfo" class="Statistics" @changeTab="changeTab" />
    <!--  添加 搜索 筛选  -->
    <div class="v-line-button">
      <a-space>
        <a-button prefix="send-one" @click="sendImmediatelyStaffPayroll"> 立即发送 </a-button>
        <a-button prefix="dingshifasong" @click="timedSendShow = true">定时发送 </a-button>
        <a-button prefix="quanyuanchehui" @click="withdrawAllStaffPayroll">全部撤回 </a-button>
      </a-space>
    </div>
    <!-- <appForm
      ref="appForm"
      :to-list="toList"
      :screen-roster-list="screenRosterList"
      width="300"
      @getSearchCallback="getSearchCallback"
      @screenRosterParams="screenRosterParams"
    >
      <template slot="customButton">
        <div class="v-line-button">
          <a-space>
            <a-button class="send-btn" @click="sendImmediatelyStaffPayroll"><svg class="iconpark-icon Circle-Button"><use href="#send-one" /></svg> 立即发送 </a-button>
            <a-button class="send-btn" @click="timedSendShow = true"><svg class="iconpark-icon Circle-Button"><use href="#dingshifasong" /></svg> 定时发送 </a-button>
            <a-button class="send-btn" @click="withdrawAllStaffPayroll"> <svg class="iconpark-icon Circle-Button"><use href="#quanyuanchehui" /></svg> 全部撤回 </a-button>
          </a-space>
        </div>
      </template>
    </appForm> -->
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      :row-key="'staffyPayrollId'"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="loadDataList"
    >
      <!-- 序号 -->
      <template slot="index" slot-scope="{ scope }">
        <span>{{ scope.index + 1 }}</span>
      </template>
      <!-- 姓名头像 -->
      <template slot="staffName" slot-scope="{ scope }">
        <CbTableAvater
          :staff-id="scope.record.staffId"
          :on-job-id="scope.record.onJobId"
          :table-avater="scope.record.avatar"
          :table-name="scope.record.staffName"
        />
      </template>
      <!-- 发送状态,0未发送 1已发送 -->
      <template slot="sendState" slot-scope="{ scope }">
        {{ scope.record.sendState? '已发送':'未发送' }}
      </template>
      <!-- 确认状态,0未确认 1已确认 -->
      <template slot="confirmState" slot-scope="{ scope }">
        {{ scope.record.confirmState? '已确认':'未确认' }}
      </template>
      <!-- 查看状态,0未查看 1已查看 -->
      <template slot="viewState" slot-scope="{ scope }">
        {{ scope.record.viewState? '已查看':'未查看' }}
      </template>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{ scope }">
        <div class="more-handle-btn" style="display:flex;">
          <!-- 未发送的展示发送按钮 -->
          <a-button v-if="scope.record.sendState === 0" type="link" @click="sendStaffPayroll(scope.record)"> 发送</a-button>
          <!-- 已发送 未确认的展示撤销按钮 -->
          <a-button v-if="scope.record.sendState === 1" type="link" @click="withdrawStaffPayroll(scope.record)"> 撤回 </a-button>
        </div>
        <!-- <a-popover placement="bottomRight" trigger="hover">
            <template slot="content">
              <div class="more-handle-btn" style="display:flex; flex-direction: column;">
                <a-button v-if="scope.record.sendState === 0" type="link" @click="sendStaffPayroll(scope.record)"> 发送</a-button>
                <a-button v-if="scope.record.sendState === 1" type="link" @click="withdrawStaffPayroll(scope.record)"> 撤回 </a-button>
              </div>
            </template>
            <div class="more-handle">···</div>
          </a-popover> -->
      </template>
    </custom-table>
    <!-- 定时发送 -->
    <Modal class="modal-comp" :visible="timedSendShow" :size="'normal'" :title="'定时发送'" :closable="true" :ok-text="'确认'" @ok="timedSend" @cancel="timedSendShow=false">
      <div class="send-block">
        <div class="send-label">
          发送时间
        </div>
        <a-date-picker
          format="YYYY-MM-DD HH:mm:ss"
          :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
          @change="sendTimeChange"
        />
      </div>
      <div class="tip-text">
        注：定时发送工资条仅支持全员发送
      </div>
    </Modal>
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import { getStatusInfo,
  getStaffPayrollList,
  sendImmediatelyStaffPayroll,
  withdrawAllStaffPayroll,
  sendTimerStaffPayroll,
  sendStaffPayroll,
  withdrawStaffPayroll
} from '@/services/wage/staffPayroll.js'
import CbNumStatistics from '@/components/CbNumStatistics/index'
import CbTableAvater from '@/components/CbTableAvater/index'
import moment from 'moment'

export default {
  name: 'ArchivesTabel',
  components: {
    CbTableAvater,
    CbNumStatistics,
    HeaderBack: () => import('@/components/headerBack'),
    Modal: () => import('@/components/CbModal/index')
  },
  filters: {
    formatTime(time) {
      return moment(time).format('YYYY年MM月')
    }
  },
  mixins: [mixins],
  props: {
    currentTime: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      statisticsInfo: {}, // 页头统计数据
      moment,
      payrollStatus: {},
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'index' },
          width: '50px'

        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          scopedSlots: { customRender: 'staffName' },
          width: '105px'
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          ellipsis: true,
          width: '120px'
        },
        {
          title: '聘用形式',
          dataIndex: 'engageModeName',
          key: 'engageModeName'
        },
        {
          title: '员工状态',
          dataIndex: 'staffStatusName',
          key: 'staffStatusName'
        },
        {
          title: '实发金额',
          dataIndex: 'amount',
          key: 'amount',
          dataType: 'money'
        },
        {
          title: '发送状态',
          dataIndex: 'sendState',
          scopedSlots: { customRender: 'sendState' }
        },
        {
          title: '查看状态',
          dataIndex: 'viewState',
          scopedSlots: { customRender: 'viewState' }
        },
        {
          title: '确认状态',
          dataIndex: 'confirmState',
          scopedSlots: { customRender: 'confirmState' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '50px'
        }
      ],
      dataSource: [
      ],
      currentFilter: 0,
      timedSendShow: false,
      timer: '', // 定时发送时间
      lazyInitData: false,
      toList: ['search', 'screen'],
      screenRosterList: {
        'list': [
          {
            'type': 'input',
            'label': '员工姓名',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请输入员工姓名',
              'showSearch': false
            },
            'model': 'staffName',
            'key': 'staffName',
            'help': ''
          },
          {
            'type': 'input',
            'label': '员工姓名',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请输入员工工号',
              'showSearch': false
            },
            'model': 'staffNum',
            'key': 'staffNum',
            'help': ''
          },
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'engage_mode',
              'clearable': true,
              'placeholder': '请选择聘用形式',
              'showSearch': false
            },
            'model': 'employment',
            'key': 'employment'
          },
          {
            'type': 'select',
            'label': '发送状态',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'engage_mode',
              'clearable': true,
              'placeholder': '请选择发送状态',
              'showSearch': false,
              'options': [
                {
                  'value': '3',
                  'label': '全部'
                }, {
                  'value': '1',
                  'label': '已发送'
                }, {
                  'value': '0',
                  'label': '未发送'
                }
              ]
            },
            'model': 'sendState',
            'key': 'sendState'
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    // 返回
    goBack() {
      this.$emit('goBack')
    },
    init() {
      this.getStatusInfo()
      // this.filterStaffPayrollList({ sendState: 1 }, 0)
      this.fetchDataApiFn({
        ...this._queryParam
        // sendState: 1
      })
    },
    // 全部发送
    sendImmediatelyStaffPayroll() {
      const { currentTime: period, payrollStatus } = this
      if (payrollStatus.sendCount === payrollStatus.allCount) {
        return this.$message.error('暂时没有可发送的工资条')
      }
      this.$Reconfirm({
        title: '全部发送',
        content: '确定全部发送吗？'
      }).then(async() => {
        const { data } = await sendImmediatelyStaffPayroll({ period })
        if (data) {
          this.$message.success('发送成功')
          this.init()
        }
      })
    },
    // 定时发送
    async timedSend() {
      const { timer, currentTime: period } = this
      if (!timer) {
        return this.$message.warning('请先选择定时发送的时间')
      }
      const data = await sendTimerStaffPayroll({
        period,
        timer
      })
      if (data) {
        this.$message.success('发送成功')
        this.timedSendShow = false
      }
    },
    // 时间变化
    sendTimeChange(date, dateString) {
      this.timer = dateString
    },
    // 全部撤销
    async withdrawAllStaffPayroll() {
      const { currentTime: period, payrollStatus } = this
      if (payrollStatus.sendCount <= 0) {
        return this.$message.error('暂时没有可撤销的工资条')
      }
      this.$Reconfirm({
        title: '全部撤销',
        content: '确定全部撤销吗?'
      }).then(async() => {
        const { data } = await withdrawAllStaffPayroll({ period })
        if (data) {
          this.$message.success('撤销成功')
          this.init()
        }
      })
    },
    // 获取头部数据
    async getStatusInfo() {
      const { currentTime: period } = this
      const { data } = await getStatusInfo({
        period
      })
      this.payrollStatus = data
      this.statisticsInfo = {
        tabList: [
          {
            id: 1,
            value: data.sendCount + '/' + data.allCount,
            label: '发送',
            selected: true // 默认数据高亮
          },
          {
            id: 2,
            value: data.viewCount,
            label: '查看'
          },
          {
            id: 3,
            value: data.confirmCount,
            label: '确认'
          },
          {
            id: 4,
            value: data.noConfirmCount,
            label: '未确认'
          }
        ] }
    },
    // 获取当前月的表格数据
    async fetchDataApiFn(param) {
      const { currentTime: period } = this
      this._queryParam = {
        ...param,
        period
      }
      // 薪资档案
      const res = await getStaffPayrollList(this._queryParam)
      this.dataSource = res.data.records ? res.data.records : []
      this.pager.currentPage = res.data.current || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
      this.total = res.data.total
    },
    // 筛选
    filterStaffPayrollList(param) {
      // 筛选的list 合并为null
      const filterList = {
        sendState: null,
        confirmState: null,
        viewState: null,
        ...param
      }
      this._queryParam = {
        ...this._queryParam,
        ...filterList
      }
      this.serchGetData()
    },
    // 单个发送
    async sendStaffPayroll({ staffyPayrollId }) {
      const { data } = await sendStaffPayroll({ staffyPayrollId })
      if (!data) return
      this.$message.success('发送成功')
      this.init()
    },
    // 单个撤销
    async withdrawStaffPayroll({ staffyPayrollId }) {
      const { data } = await withdrawStaffPayroll({ staffyPayrollId })
      if (!data) return
      this.$message.success('撤回成功')
      this.init()
    },
    changeTab(tab) {
      const obj = tab.id === 1 ? {} : tab.id === 2 ? { viewState: 1 } : tab.id === 3 ? { confirmState: 1 } : tab.id === 4 ? { confirmState: 0 } : undefined
      this.filterStaffPayrollList(obj)
    },
    // 筛选
    screenRosterParams(data) {
      const { employment, sendState, staffName, staffNum } = data.screenCondition
      this._queryParam = {
        value: data.searchValue,
        engageMode: employment || '',
        sendState: sendState || '',
        staffName: staffName || '',
        staffNum: staffNum || ''
      }
      this.serchGetData()
    },
    // 搜索
    getSearchCallback(val) {
      this._queryParam.value = val.searchValue
      this.serchGetData()
    }
  }

}
</script>

<style lang="less" scoped>
.salary-particulars{
.more-handle{
  font-weight: 700;
  color: @sc-primary-100;
  font-size: 20px;
  vertical-align: baseline;
  cursor: pointer;
  margin-left: 4px;
}
}
.send-block {
  display: flex;
  align-items: center;
  .send-label {
    margin-right: 10px;
    font-size: 14px;
    color: @sc-grey-100;
  }
}
.tip-text {
  font-size: 14px;
  color: @sc-grey-40;
  padding: 20px 0 0;
}
/deep/ .modal-comp .ant-modal-body{
  // max-height: 700px;
  overflow: inherit;
}
.Statistics{
  margin-bottom: 20px;
}
/deep/.header{
  margin-bottom: 20px !important;
}
/deep/.ant-space-item{
  margin-right: 12px !important;
}
/deep/.content-list li{
  position: relative;
  &:nth-child(-n+3)::after {
    content: '';
    position: absolute;
    right: 0;
    height: 30px;
    width: 1px;
    background-color: @sc-greyBg-20;
    top: 50%;
    transform: translateY(-50%);
  }
}
.v-line-button{
  margin-bottom: 16px;
}
</style>
