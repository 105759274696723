<template>
  <div class="ApprovalcenterHead">
    <div class="head-left">
      <div class="head-all">
        <ul>
          <li @click="highlight('all', '', '')">
            <div class="head-container">
              <span :class="active == 'all' ? 'activeNum' : 'approvalNum'">{{ allNum }}</span>
              <br>
              <span :class="active == 'all' ? 'activeText' : 'approvelText'">全部</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="head-status">
        <ul>
          <li
            v-for="(item, index) in statusList"
            :key="index"
            @click="highlight(item.name, typeList[item.type], '')"
          >
            <div class="head-container">
              <span :class="active == item.name ? 'activeNum' : 'approvalNum'">{{ item.num }}</span>
              <br>
              <span :class="active == item.name ? 'activeText' : 'approvelText'">{{ item.name }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 有数据再初始化轮播，否则初始化第一个高亮的点不亮 -->
    <div v-if="childList.length > 0" class="head-function">
      <a-carousel>
        <ul v-for="(it, index1) in childList" :key="`carousel-ul${index1}`" class="carouser-ul">
          <li
            v-for="(item, index) in it"
            :key="index"
            @click="highlight(item.groupCode, '', item.flowGroupId)"
          >
            <div class="head-container">
              <span :class="active == item.groupCode ? 'activeNum' :'approvalNum'">{{ item.num }}</span>
              <br>
              <span :class="active == item.groupCode ? 'activeText' : 'approvelText'">{{ item.groupName }}</span>
            </div>
          </li>
        </ul>
      </a-carousel>
    </div>
  </div>
</template>

<script>
import { statistics, groupStatistics } from '@/services/logicFlow'
const status = {
  exception: '异常',
  complete: '通过',
  notPass: '未通过',
  inApprove: '审批中'
}

export default {
  name: 'ApprovalcenterHead',
  data() {
    return {
      childList: [],
      funcList: [],
      statusList: [],
      active: 'all',
      allNum: 0,
      // 字段转义对称用的，用来返回回去列表查询时候当入参使用
      typeList: {
        complete: '1',
        exception: '-1',
        inApprove: '0',
        notPass: '2',
        allNum: '1'
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    highlight(active, status, groupId) {
      this.active = active
      this.init()
      this.$emit('getType', status, groupId)
    },
    async init() {
      this.statistics()
      this.groupStatistics()
    },
    async statistics() {
      const statusList = await statistics()
      const arr = []
      this.allNum = statusList.data.allNum
      Object.keys(statusList.data).forEach((item) => {
        if (status[item]) {
          const obj = {
            name: status[item],
            type: item,
            num: statusList.data[item]
          }
          arr.push(obj)
        }
      })
      this.statusList = arr
    },
    async groupStatistics() {
      const funcList = await groupStatistics()
      this.funcList = funcList.data
      this.childList = []
      for (var i = 0; i < this.funcList.length; i += 4) {
        this.childList.push(this.funcList.slice(i, i + 4))
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ApprovalcenterHead {
  height: 88px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .head-left {
    width: 60%;
    height: 88px;
    margin-right: 16px;
    border: 1px solid @sc-greyBg-20;
    border-radius: 6px;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: 1px solid @sc-greyBg-20;
    ul {
      display: flex !important;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      font-size: 14px;
      cursor: pointer;
      margin-bottom: 0;
      li {
        .head-container{
          display: inline-block;
          text-align: left;
          border: none;
           .approvelText {
            .font-size(16px);
            color: @sc-grey-60;
          }
          .approvalNum {
            .font-size(20px);
            font-weight: 700;
            color: @sc-grey-100;
          }
        }
        .activeNum {
          .font-size(20px);
          font-weight: 700;
          color: @sc-primary-100;
        }
        .activeText {
          .font-size(16px);
          color: @sc-primary-100;
        }
        &:hover .head-container span  {
          color: @sc-primary-100;
        }
      }
    }
  }
  .head-all {
    flex: 1;
  }
  .head-status {
    flex: 4;
    border: none;
  }
  .head-function {
    width: 40%;
    border: none;
    height: 88px;
    border: 1px solid @sc-greyBg-20;
    border-radius: 6px;
    /deep/ .ant-carousel {
      width: 100%;
      height: 100%;
      .slick-slider {
        width: 100%;
      }
      .carouser-ul {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        li {
          width: 25%;
        }
      }
      .slick-dots {
        height: 7px;
          li button {
          background: @sc-primary-60;
          border-radius: 30px;
          height: 6px;
        }
      }
      .slick-dots-bottom {
        bottom: -12px;
        .slick-active button {
          border-radius: 30px;
          background: @sc-primary-100;
        }
      }
    }
  }
}
</style>
