<template>
  <div>
    <!--  顶部返回  -->
    <div class="ArchiveHistory_top">
      <div class="ArchiveHistory_top_left">
        <div class="historyRecord" @click="archiveHistoryCallback">
          <a-button type="iconBtn" icon="left" />
          <span>历史归档</span>
        </div>
        <div class="ArchiveHistory_top_left_data">
          <span>日期选择</span>
          <a-cascader v-model="dataTime" :options="options" placeholder="请选择日期与时间" size="small" :allow-clear="false" @change="change" />
        </div>
      </div>
      <div>
        <a-tooltip placement="bottom">
          <template slot="title">
            <span>导出</span>
          </template>
          <a-button type="iconBtn" icon="daochu" @click="getFileExportOrgTree" />
        </a-tooltip>
      </div>
    </div>
    <!--  内容  -->
    <div class="ArchiveHistory_count">
      <div class="ArchiveHistory_count_left beauty-scroll">
        <!--   导航菜单     -->
        <a-menu
          v-model="defaultSelectedKeys"
          mode="inline"
        >
          <a-menu-item key="framework" @click="titleClick('framework')">
            <svg class="iconpark-icon"><use href="#chart-graph" /></svg>
            <span>架构</span>
          </a-menu-item>
          <a-sub-menu key="post">
            <span slot="title">
              <svg class="iconpark-icon"><use href="#people-5m13bljp" /></svg>
              <span>岗位</span>
            </span>
            <a-menu-item v-for="it in postList" :key="it.postTypeArchiveId" @click="titleClick((it.postTypeArchiveId+'+post'), it.postTypeArchiveId)">
              {{ it.postTypeName }}
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="rank">
            <span slot="title">
              <svg class="iconpark-icon"><use href="#level-5m13e8p6" /></svg>
              <span>职级</span>
            </span>
            <a-menu-item v-for="it in rankList" :key="it.jobTypeArchiveId" @click="titleClick((it.jobTypeArchiveId+'+rank'), it.jobTypeArchiveId)">
              {{ it.jobTypeName }}
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item key="architecture" @click="titleClick('architecture')">
            <svg class="iconpark-icon"><use href="#chart-graph" /></svg>
            <span>架构图</span>
          </a-menu-item>
        </a-menu>
      </div>
      <div class="ArchiveHistory_count_right">
        <div class="total-organization">
          <svg class="iconpark-icon cityName"><use href="#building-2-fill" /></svg>
          <span class="company">
            {{ orgName }}
          </span>
        </div>
        <TreeList v-if="menuType === 'framework'" :tree-list="treeList" />
        <PostList v-if="menuType === 'post'" :post-type-statistics="PostTypeStatistics" :post-id="postId" />
        <RankList v-if="menuType === 'rank'" :rank-statistics="RankStatistics" :rank-id="rankId" />
        <Diagram v-if="menuType === 'architecture'" ref="Diagram" :data-tree="treeList" />
      </div>
    </div>
  </div>
</template>

<script>
import TreeList from './treeList'
import PostList from './postList'
import RankList from './rankList'
import Diagram from './diagram'
import {
  getTimeSelection,
  getPostTypeList,
  getRankTypeList,
  getOrgTreeArchive,
  getPostTypeStatistics,
  getRankStatistics,
  fileExportOrgTree,
  fileExportPostList,
  fileExportRankList
} from '@/services/organization/framework.js'
export default {
  name: 'ArchiveHistory',
  components: {
    TreeList,
    PostList,
    RankList,
    Diagram
  },
  data() {
    return {
      orgName: '',
      defaultSelectedKeys: ['framework'],
      menuType: 'framework',
      historyId: '',
      options: [],
      dataTime: [],
      dataList: [],
      treeList: [],
      postList: [],
      rankList: [],
      postId: '',
      PostTypeStatistics: {},
      rankId: '',
      RankStatistics: {}
    }
  },
  mounted() {
    this.getTimeSelection()
    this.$nextTick(() => {
      const contentH = document.body.scrollHeight - 48
      const topH = document.querySelector('.archive_history .ArchiveHistory_top').offsetHeight
      const leftH = document.querySelector('.archive_history .ArchiveHistory_count .ArchiveHistory_count_left')
      leftH.style = `min-height: ${contentH - topH}px; max-height: ${contentH - topH}px`
    })
  },
  methods: {
    changeName(val) {
      this.orgName = val
      console.log(this.orgName)
    },
    // 获取时间
    async getTimeSelection() {
      const res = await getTimeSelection()
      const arr = res.data
      for (let i = 0; i < arr.length; i++) {
        this.options.push({ value: arr[i].id, label: arr[i].year, children: [] })
        const arr1 = arr[i].days
        for (let j = 0; j < arr1.length; j++) {
          this.options[i].children.push({ value: arr1[j].id, label: arr1[j].day, children: [] })
          const arr2 = arr1[j].times
          for (let x = 0; x < arr2.length; x++) {
            this.options[i].children[j].children.push({ value: arr2[x].id, label: arr2[x].time })
          }
        }
      }
      this.dataTime = [this.options[0].value, this.options[0].children[0].value, this.options[0].children[0].children[0].value]
      this.dataList = [this.options[0].label, this.options[0].children[0].label, this.options[0].children[0].children[0].label]
      this.getArchiveHistoryData()
    },
    // 获取数据
    async getArchiveHistoryData() {
      const params = {
        year: this.dataList[0],
        day: this.dataList[1],
        time: this.dataList[2]
      }
      this.getOrgTreeArchive(params)
      this.getPostTypeList(params)
      this.getRankTypeList(params)
    },
    // 获取树
    async getOrgTreeArchive(data) {
      const res = await getOrgTreeArchive({ nested: true, staffNum: true, ...data })
      this.setSlotItem(res.data, 0)
      const depData = JSON.parse(JSON.stringify(res.data))
      this.treeList = depData
      this.orgName = this.treeList[0].orgName
    },
    // 获取岗位
    async getPostTypeList(data) {
      const re = await getPostTypeList(data)
      this.postList = re.data
    },
    // 获取职级
    async getRankTypeList(data) {
      const rt = await getRankTypeList(data)
      this.rankList = rt.data
    },
    setSlotItem(arr, index) {
      arr.forEach((item) => {
        item.scopedSlots = { 'title': 'name' }
        item.id = item.orgId
        if (index > this.deep) {
          this.deep = index
        }
        item.deep = index
        if (item.children && item.children.length > 0) {
          this.setSlotItem(item.children, this.deep + 1)
        }
      })
    },
    // 时间改变
    change(openKeys, e) {
      this.dataList = [e[0].label, e[1].label, e[2].label]
      this.defaultSelectedKeys = ['framework']
      this.menuType = 'framework'
      this.getArchiveHistoryData()
    },
    // 点击导航菜单
    titleClick(key, id) {
      this.menuType = key
      this.historyId = id
      const arr = key.split('+')
      if (arr[1]) {
        this.menuType = arr[1]
        if (arr[1] === 'post') {
          this.getPostTypeStatistics(id)
          this.postId = arr[0]
        } else if (arr[1] === 'rank') {
          this.getRankStatistics(id)
          this.rankId = arr[0]
        }
      } else {
        const params = {
          year: this.dataList[0],
          day: this.dataList[1],
          time: this.dataList[2]
        }
        this.getOrgTreeArchive(params)
      }
    },
    // 获取岗位统计
    async getPostTypeStatistics(data) {
      const res = await getPostTypeStatistics({
        postTypeArchiveId: data
      })
      this.PostTypeStatistics = res.data
    },
    async getRankStatistics(data) {
      const res = await getRankStatistics({
        jobTypeArchiveId: data
      })
      this.RankStatistics = res.data
    },
    // 返回
    archiveHistoryCallback() {
      this.$emit('archiveHistoryCallback')
    },
    // 导出
    getFileExportOrgTree() {
      if (this.menuType === 'framework') {
        // 架构导出
        fileExportOrgTree({
          year: this.dataList[0],
          day: this.dataList[1],
          time: this.dataList[2]
        })
      } else if (this.menuType === 'post') {
        // 岗位导出
        fileExportPostList({
          postTypeArchiveId: this.historyId
        })
      } else if (this.menuType === 'rank') {
        // 职级导出
        fileExportRankList({
          jobTypeArchiveId: this.historyId
        })
      } else {
        // 架构图导出
        this.$refs.Diagram.downloadImage()
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .ArchiveHistory_top {
    width: 100%;
    height:56px;
    border-bottom: 1px @sc-greyBg-10 solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color:  @sc-grey-100;
    padding: 0 16px;
    box-sizing: border-box;
    .ArchiveHistory_top_left {
      display: flex;
      align-items: center;
      cursor: pointer;
      .historyRecord {
        width: 150px;
       .flex-align-center();
        .iconBtn {
          width: 20px;
          height: 20px;
          padding: 1px;
        }
        span {
          margin-left: 6px;
        }
      }
      .ArchiveHistory_top_left_data {
        width: 100%;
        color:  @sc-grey-80;
        font-size: 14px;
        margin-left: 10px;
        span {
          margin-right: 10px;
        }
      }
    }
  }
  .ArchiveHistory_count{
    display: flex;
    .ArchiveHistory_count_left {
      width: 134px;
      border-right: 2px @sc-greyBg-10 solid;
      overflow-x: hidden;
      .ant-menu-inline{
        border: 0;
      }
      .iconpark-icon {
        width: 14px;
        height: 14px;
        color: @sc-grey-60;
        margin-right: 8px;
      }
    }
    .ArchiveHistory_count_right {
      width: calc(100% - 134px);
      padding: 20px 20px 0;
      background-color: #fff;
      height: 756px;
    }
  }
 /deep/ .ant-cascader-input.ant-input {
    width: 184px;
    height: 32px;
}
/deep/ .ant-menu-item {
        padding-left: 16px !important;
      }
/deep/  .ant-menu-submenu-title {
          padding-left: 16px !important;
        }
/deep/ .ant-menu-item:hover .iconpark-icon {
  color: @sc-primary-100;
}
/deep/ .ant-menu-submenu:hover .iconpark-icon {
  color: @sc-primary-100;
}
/deep/ .ant-menu-item-selected,
      .ant-menu-submenu-selected {
        background: @sc-primary-10;
  .iconpark-icon {
    color:  @sc-primary-100 !important;
  }
}
.total-organization {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 44px;
    background: @sc-greyBg-10;
     .cityName {
          width: 19px;
          height: 17px;
          margin-left: 15px;
          margin-right: 5px;
        }
    .company {
      color: @sc-grey-100;
      font-weight: 500;
      .font-size(16px);
    }
  }
</style>
