<template>
  <div class="user-setting-panel">
    <layout
      ref="layoutMenu"
      :menu-data-list="menuDataList"
      class="left-menu-panel"
      :selected-key="selectedKey"
      @menu-select-change="menuSelectChange"
    >
      <template #leftTop>
        <div class="left-top-p" />
      </template>
      <component :is="curComponentName" />
      <!-- <GuidedTour @fileName="onChange" /> -->
    </layout>
  </div>
</template>

<script>
import layout from '@/components/CbLayoutMenu/index'
import common from './common'
export default {
  name: 'Setting',
  components: {
    layout,
    GuidedTour: () => import ('./components/guidedTour.vue'),
    ...common
  },
  data() {
    return {
      selectedKey: '',
      menuData: {},
      curComponentName: '',
      menuDataList: [
        { id: '1',
          name: '社保方案',
          frontIcon: '#shebao',
          fileName: 'socialSecurityScheme',
          funcCode: 'secRule',
          actions: []
        }, { id: '2',
          name: '医保方案',
          frontIcon: '#yibao-5m7573ea',
          fileName: 'healthCareScheme',
          funcCode: 'healthSecRule',
          actions: []
        }, { id: '3',
          name: '公积金方案',
          frontIcon: '#gongjijin',
          fileName: 'providentFundScheme',
          funcCode: 'cpfRule',
          actions: []
        }, { id: '4',
          name: '参保主体',
          frontIcon: '#canbaozhuti-5m7573e2',
          fileName: 'insuredSubjects',
          funcCode: 'secSubject',
          actions: []
        }, { id: '5',
          name: '消息提醒',
          frontIcon: '#tips',
          fileName: 'messageReminders',
          funcCode: 'msgRemind',
          actions: []
        }
      ]
    }
  },
  mounted() {
    this.$refs.layoutMenu.showInit()
  },
  methods: {
    menuSelectChange(item) {
      this.curComponentName = item.fileName
    },
    onChange(val) {
      this.curComponentName = val
      this.selectedKey = this.menuDataList.filter(item => item.fileName === val)[0].id
    }
  }
}
</script>

<style lang="less" scoped>
.user-setting-panel {
  height: 100%;
  .left-top-p {
    margin-top: 20px;
  }
}
.left-menu-panel{
  // background-color: @base-bg-color
}
</style>

