var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('appForm',{ref:"appForm",attrs:{"to-list":_vm.toList,"screen-roster-list":_vm.screenRosterList,"width":"400"},on:{"getSearchCallback":_vm.getSearchCallback,"screenRosterParams":_vm.screenRosterParams,"exportFileList":_vm.exportFileList}},[(_vm.keyval === '1')?_c('template',{slot:"customButton"},[_c('a-button',{attrs:{"type":"primary","prefix":"add-one"},on:{"click":function($event){return _vm.handleAdd()}}},[_vm._v(" 添加待离职 ")])],1):_vm._e()],2),_c('custom-table',{attrs:{"table-ref":".main-content","tabs-height":36,"columns":_vm.columns,"data-source":_vm.dataSource,"row-key":"index","pagination":{
      current: _vm.pager.pageNum,
      pageSize: _vm.pager.pageSize,
      total: _vm.total,
      showTotal: function (total) { return ("共 " + total + " 条数据"); },
      showQuickJumper: true,
      showSizeChanger: true
    }},on:{"change":_vm.loadDataList},scopedSlots:_vm._u([{key:"index",fn:function(ref){
    var scope = ref.scope;
return [_c('span',[_vm._v(_vm._s(scope.index + 1))])]}},{key:"name",fn:function(ref){
    var scope = ref.scope;
return [_c('CbTableAvater',{attrs:{"status-type":_vm.keyval === '2' ? 'resigned' : '',"table-avater":scope.record.avatar,"on-job-id":scope.record.onJobId,"table-name":scope.record.name}})]}},{key:"applyStatus",fn:function(ref){
    var scope = ref.scope;
return [_c('span',[(scope.record.flowId)?_c('StatusTag',{attrs:{"type":scope.record.applyStatus,"type-list":_vm.applyStatusList},on:{"click":function($event){return _vm.getCbApproveSteps(scope.record.flowId, scope.record.applyStatus)}}}):_vm._e()],1)]}},{key:"operation",fn:function(ref){
    var scope = ref.scope;
return [(_vm.keyval == '1' && (scope.record.applyStatus === '2' || _vm.dataType(scope.record.applyStatus) === 'null'))?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.handleAdd(scope)}}},[_vm._v(" 调整离职日期 ")]):_vm._e(),(_vm.keyval == '2')?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.getQuitDetails(scope.record.onJobId)}}},[_vm._v(" 详情 ")]):_vm._e()]}}])}),_c('QuitForm',{ref:"transferForm",attrs:{"modal-title":_vm.modalTitle,"is-add":_vm.isAdd},on:{"getFetchDataApiFn":_vm.getFetchDataApiFn}}),_c('CbApproveSteps',{attrs:{"flow-id":_vm.flowId,"apply-status":_vm.applyStatus,"apply-status-list":_vm.applyStatusList},model:{value:(_vm.stepsVisible),callback:function ($$v) {_vm.stepsVisible=$$v},expression:"stepsVisible"}}),_c('QuitDetails',{ref:"quitDetails"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }