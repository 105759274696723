<template>
  <div class="box">
    <div class="top">
      <span>岗位总数：{{ postTypeStatisticsValue.postCount }}人</span>
      <span class="num">在职人数：{{ postTypeStatisticsValue.incumbencyCount }}人</span>
    </div>
    <div>
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        table-ref=".box"
        :row-key="'postName'"
        :pagination="{
          current: pager.pageNum,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条数据`,
          showQuickJumper: true,
          showSizeChanger: true
        }"
        @change="loadDataList"
      />
    </div>
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import { getPostList } from '@/services/organization/framework.js'
export default {
  name: 'PostList',
  mixins: [mixins],
  props: {
    postId: {
      type: String,
      default: ''
    },
    postTypeStatistics: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      total: 0,
      postTypeStatisticsValue: {},
      columns: [
        {
          title: '岗位名称',
          dataIndex: 'postName',
          key: 'postName'
        },
        {
          title: '在职人数',
          dataIndex: 'userCount',
          key: 'userCount'
        },
        {
          title: '适用组织',
          dataIndex: 'orgNames',
          key: 'orgNames'
        },
        {
          title: '岗位说明',
          dataIndex: 'detail',
          key: 'detail'
        }
      ],
      dataSource: []
    }
  },
  watch: {
    postId(val) {
      this._queryParam.postTypeArchiveId = val
      this.serchGetData()
    },
    postTypeStatistics(val) {
      this.postTypeStatisticsValue = val
    }
  },
  methods: {
    async fetchDataApiFn(param) {
      this._queryParam.postTypeArchiveId = this.postId
      const res = await getPostList(param)
      this.dataSource = res.data.postList ? res.data.postList : []
      this.pager.pageNum = res.data.pageNum || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.pageSize || 10 // 更新当前的分页信息
      this.total = res.data.total
    }
  }
}
</script>

<style lang="less" scoped>
.box {
    box-sizing: border-box;
    font-size: 14px;
    margin-top: 30px;
    .top {
      margin-bottom: 20px;
      color: @sc-grey-100;
      .num {
        margin-left: 10px;
      }
    }
}
/deep/ .ant-table-thead > tr > th {
  color: @sc-grey-100;
  font-size: 14px;
}
/deep/ .ant-table-tbody > tr > td {
  color:  @sc-grey-80;
  font-size: 14px;
  padding-left: 7px;
}
</style>
