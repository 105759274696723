<template>
  <div class="user-info-panel beauty-scroll">
    <userInfo />
  </div>
</template>

<script>
import userInfo from './user-info.vue'
export default {
  name: 'UserInfoIndex',
  components: {
    userInfo
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.user-info-panel {
  height: 100vh;
  overflow-y: auto;
}
</style>
