<!--
 * @Date: 2022-04-27 12:02:25
 * @descriotion: 报表
-->
<template>
  <div class="organ-report-p">
    <div v-for="item in reportList" :key="item.title" class="report-p-multi">
      <panelTable :title="item.title" type="attendance" :table-list="item.column" />
    </div>
  </div>
</template>

<script>
import panelTable from '@/components/CbReportForm/panelTable.vue'
import { getReportColumn } from '@/services/organization/reportForm.js'
import { } from '@/services/attendance/attendance.js'// downloadReportForm下载, previewReportForm预览
export default {
  name: 'ReportForm',
  components: {
    panelTable
  },
  data() {
    return {
      reportList: []
    }
  },
  created() {
    this.initReport()
  },
  methods: {
    // 初始化报表页面
    initReport() {
      getReportColumn({
        moduleCode: 'attendance'
      }).then((res) => {
        if (res.code === 100) {
          this.reportList = res.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.organ-report-p {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff;
  padding: 20px;
  .report-p-multi + .report-p-multi {
    margin-top: 30px;
  }
}
/deep/ .report-table-panel .report-panel-tb {
  padding-left: 0;
  .ant-table-header-column {
    margin-left: 0 !important;
  }
  .more-handle {
    margin-left: 62px;
    cursor: pointer;
    font-weight: 700;
    font-size: 20px;
    color: @sc-primary-100;
  }
}
/deep/ .report-panel-title {
    margin-top: -3px;
    &::before {
      height: 16px !important;
    }
  }
  /deep/ .ant-table-thead > tr:first-child > th:last-child {
    padding-left: 10px;
  }
</style>

