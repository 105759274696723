<template>
  <div class="record-table">
    <div v-if="!dragValue">
      <div class="time">
        <svg class="iconpark-icon"><use href="#rongqi" /></svg>
        <a-range-picker v-model="recordTime" :allow-clear="clear" value-format="YYYY-MM-DD" separator="~" :ranges="ranges" @change="onChange" />
      </div>
      <div class="record-table-heard">
        <div class="record-table-heard-left">
          <appForm>
            <template slot="customButton">
              <a-dropdown type="primary">
                <template #overlay>
                  <a-menu>
                    <a-menu-item v-for="(item, index) in functionList" :key="index" @click="add(item)">{{ item.name }}</a-menu-item>
                  </a-menu>
                </template>
                <a-button prefix="add-one" suffix="left">
                  添加出差记录
                </a-button>
              </a-dropdown>
            </template>
          </appForm>
          <a-button class="deleteBtn" prefix="reduce-one" :disabled="selectedRowKeys.length===0?true:false" @click="openDeleteBox">
            批量删除
          </a-button>
        </div>
        <appForm
          ref="appForm"
          :to-list="formList"
          search-placeholder="请搜索姓名/工号"
          :screen-roster-list="screenRecordList"
          @getSearchCallback="getSearchCallback"
          @screenRosterParams="screenRosterParams"
          @exportFileList="exportFileList"
        />
      </div>
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        row-key="travelApplyId"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          columnWidth: 30,
          onChange: onSelectChange,
          getCheckboxProps: (record) => ({
            props: {
              disabled: record.operateType === '1',
            },
          }),
        }"
        :selection-mode="selectionMode"
        :pagination="{
          current: pager.pageNum,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条数据`,
          showQuickJumper: true,
          showSizeChanger: true
        }"
        @change="loadDataList"
      >
        <!-- 序号 -->
        <template slot="index" slot-scope="{ scope }">
          <span>{{ scope.index + 1 }}</span>
        </template>
        <template slot="staffName" slot-scope="{ scope }">
          <CbTableAvater :table-name="scope.record.staffName" :on-job-id="scope.record.onJobId" :staff-id="scope.record.staffId" :title="scope.record.staffName" />
        </template>
        <!-- 数据来源 -->
        <template slot="operateType" slot-scope="{ scope }">
          <div :title="getCodeContent(scope.record.operateType,operate_type)">{{ getCodeContent(scope.record.operateType,operate_type) }}</div>
        </template>
        <!-- 操作列 -->
        <template slot="operation" slot-scope="{scope}">
          <div class="flex-align-center">
            <div class="more-handle">
              <a-button :disabled="scope.record.operateType=='1'" type="link" @click="edit(scope.record)">编辑</a-button>
            </div>
          </div>
        </template>
      </custom-table>
      <Modal
        :closable="true"
        :visible="addRecord"
        :title="addName === 'add'?'添加出差记录':'编辑出差记录'"
        size="normal"
        overflow-auto
        :z-index="8"
        @after-close="afterVisibleChange"
        @cancel="onClose"
        @ok="onSubmin"
      >
        <k-form-build
          ref="KFB"
          :value="addTravelList"
          :text-display="true"
          @change="changeForm"
        />
      </Modal>
    </div>
    <ExcelImport v-else :title="importTitle" import-template="travel" @toggleValue="toggleValue" />
  </div>
</template>
<script>
import mixins from '@/components/CustomTable/index.js'
import moment from 'moment'
import { sepcialCode } from '@/utils/reg.js'
import { getStaffInfo } from '@/services/user/roster.js'
import { getStaffApplyTravelClass, exportStaffApplyTravel, getStaffApplyTravel, editStaffApplyTravel, delStaffApplyTravel, getStaffApplyTravelTime, addStaffApplyTravel } from '@/services/attendance/attendance.js'
export default {
  name: 'Travel',
  components: {
    Modal: () => import('@/components/CbModal/index'),
    ExcelImport: () => import('../../components/excelImport.vue'),
    CbTableAvater: () => import('@/components/CbTableAvater/index')
  },
  mixins: [mixins],
  data() {
    return {
      importTitle: '批量导入出差记录',
      dragValue: false,
      clear: false,
      ranges: {
        '上月': [
          moment().month(moment().month() - 1).startOf('month'),
          moment().month(moment().month() - 1).endOf('month')
        ],
        '近三月': [
          moment()
            .startOf('day')
            .subtract(3, 'months'),
          moment()
        ],
        '近六月': [
          moment()
            .startOf('day')
            .subtract(6, 'months'),
          moment()
        ],

        '近一年': [
          moment()
            .startOf('day')
            .subtract(1, 'years'),
          moment()
        ]
      },
      addName: '',
      addRecord: false,
      visible: true,
      functionList: [
        { name: '单个添加', label: 'addSingle' },
        { name: '批量导入', label: 'addAll' }
      ],
      formList: ['search', 'export', 'screen'], // search搜索框 screen筛选 export导出
      // 时间段
      recordTime: [moment().startOf('month'), moment().endOf('month')],
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'index' },
          width: '50px',
          key: 'index',
          align: 'center',
          fixed: 'left'
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          key: 'staffName',
          width: '105px',
          scopedSlots: { customRender: 'staffName' },
          fixed: 'left'
        },
        {
          title: '工号',
          width: '100px',
          dataIndex: 'staffNum',
          key: 'staffNum',
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          width: '100px',
          key: 'orgName',
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          width: '100px',
          key: 'postName',
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '数据来源',
          dataIndex: 'operateType',
          width: '80px',
          scopedSlots: { customRender: 'operateType' },
          key: 'operateType'
        },
        {
          title: '出差开始时间',
          width: '150px',
          dataIndex: 'startDateTime',
          key: 'startDateTime',
          ellipsis: true
        },
        {
          title: '出差结束时间',
          width: '150px',
          dataIndex: 'endDateTime',
          key: 'endDateTime',
          ellipsis: true
        },
        {
          title: '申请时长',
          dataIndex: 'applyTime',
          width: '100px',
          key: 'applyTime',
          ellipsis: true
        },
        {
          title: '出差城市',
          dataIndex: 'targetCityName',
          width: '180px',
          key: 'targetCityName',
          ellipsis: true
        },
        {
          title: '出差原因',
          dataIndex: 'applyReason',
          key: 'applyReason',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          key: 'operation',
          fixed: 'right',
          width: '50px'
        }
      ],
      screenRecordList: {
        'list': [
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'engage_mode',
              'clearable': true,
              'placeholder': '请选择聘用形式',
              'showSearch': false
            },
            'model': 'engageModes',
            'key': 'engageModes',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'width': '100%',
              'multiple': true,
              'disabled': false,
              'clearable': true,
              'hidden': false,
              'placeholder': '请选择部门',
              'dynamicKey': 'department',
              'defaultValue': undefined,
              'dynamic': false,
              'showSearch': false
            },
            'model': 'orgIds',
            'key': 'orgIds',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'selectPost',
            'label': '岗位',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'post_type',
              'clearable': true,
              'placeholder': '请选择岗位',
              'showSearch': false
            },
            'model': 'postIds',
            'key': 'postIds',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '合同公司',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择合同公司',
              'showSearch': false,
              'parameter': 'contract_company'
            },
            'model': 'contractCo',
            'key': 'contractCo',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '工作地点',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'work_address',
              'clearable': true,
              'placeholder': '请选择工作地点',
              'showSearch': false
            },
            'model': 'workplaceIds',
            'key': 'workplaceIds',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '数据来源',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择数据来源',
              'showSearch': false,
              'parameter': 'operate_type'
            },
            'model': 'operateType',
            'key': 'operateType',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 80,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      // 单个添加
      addTravelList: {
        'list': [
          {
            'type': 'selectStaff',
            'label': '员工姓名',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择员工姓名',
              'showSearch': false
            },
            'model': 'onJobId',
            'key': 'onJobId',
            'rules': [
              {
                'required': true,
                'message': '请选择员工姓名'
              }
            ]
          },
          {
            'type': 'selectDataRange',
            'label': '出差起止时间',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'showTime': true,
              'clearable': true,
              'placeholder': '请选择',
              'defaultValue': [undefined, undefined],
              'rangePlaceholder': ['开始时间', '结束时间'],
              'showSearch': false,
              'format': 'YYYY-MM-DD HH:mm'
            },
            'model': 'startTime',
            'key': 'startTime',
            'help': '',
            'rules': [
              {
                'required': true,
                'message': '请选择出差起止时间'
              }
            ]
          },
          {
            'type': 'input',
            'label': '当前班次',
            'options': {
              'type': 'text',
              'width': '100%',
              'defaultValue': '',
              'placeholder': '--',
              'clearable': false,
              'maxLength': null,
              'addonBefore': '',
              'addonAfter': '',
              'hidden': false,
              'disabled': true
            },
            'model': 'shiftName',
            'key': 'shiftName',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'input',
            'label': '申请时长',
            'options': {
              'type': 'text',
              'width': '100%',
              'defaultValue': '',
              'placeholder': '--',
              'clearable': false,
              'maxLength': null,
              'addonBefore': '',
              'addonAfter': '',
              'hidden': false,
              'disabled': true
            },
            'model': 'applyTime',
            'key': 'applyTime',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'threeLevel',
            'label': '出差城市',
            'options': {
              'defaultValue': { province: '', city: '' },
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择出差城市',
              'showLimit': [
                'city'
              ],
              'showSearch': false,
              'areaShow': false,
              'governmentAuto': true
            },
            'model': 'targetCity',
            'key': 'targetCity',
            'rules': [
              {
                'required': true,
                'message': '请选择出差城市'
              }
            ]
          },
          {
            'type': 'textarea',
            'label': '出差原因',
            'options': {
              'width': '100%',
              'minRows': 4,
              'maxRows': 6,
              'maxLength': 300,
              'defaultValue': '',
              'clearable': false,
              'hidden': false,
              'disabled': false,
              'placeholder': '请输入出差原因'
            },
            'model': 'applyReason',
            'key': 'applyReason',
            'help': '',
            'rules': [
              { 'required': false, 'message': '' },
              { 'pattern': sepcialCode, 'message': '出差原因不支持特殊字符', 'trigger': 'change' }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      dataSource: [], // 数据
      // 选中列表集合
      selectedRowKeys: [],
      selectedRows: [],
      selectionMode: true,
      orgids: [],
      newOrg: [],
      travelApplyId: '',
      // 申请的时间单位
      timeCode: '',
      // 时间周期选择
      startDate: '',
      endDate: '',
      deleteIds: [],
      params: {
        staffId: '',
        onJobId: '',
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        targetCity: '',
        applyReason: ''
      },
      // 字典项
      operate_type: []
    }
  },
  async created() {
    this.operate_type = await this.getTenantCode('operate_type')
  },
  methods: {
    // 申请时间单位
    changeForm(val, key) {
      console.log(val, key)
      if (key === 'onJobId') {
        this.params.onJobId = val
        if (this.params.onJobId) {
          getStaffInfo({ onJobId: this.params.onJobId }).then(res => {
            if (res.code === 100) {
              this.params.staffId = res.data.staffId
            }
          })
          this.getStaffApplyTravelTime(val)
        }
      } else if (key === 'startTime') {
        if (val[0] && val[1]) {
          const start = val[0].split(' ')
          const end = val[1].split(' ')
          this.params.startDate = start[0]
          this.params.endDate = end[0]
          this.params.startTime = start[1] || ''
          this.params.endTime = end[1] || ''
        }
      }
      if (key === 'targetCity') {
        this.params.targetCity = val
      }
      if (key === 'applyReason') {
        this.params.applyReason = val
        return
      }
      console.log(this.params)
      // 获取班次、时长
      if (!this.params.startDate || !this.params.endDate || !this.params.onJobId) {
        return false
      } else {
        getStaffApplyTravelClass({ onJobId: this.params.onJobId, startDate: this.params.startDate, endDate: this.params.endDate, startTime: this.params.startTime, endTime: this.params.endTime }).then(res => {
          if (res.code === 100) {
            this.$nextTick(() => {
              delete res.data.workHours
              this.$refs.KFB.setData(res.data)
            })
          }
        })
      }
    },
    getStaffApplyTravelTime(id) {
      getStaffApplyTravelTime({ onJobId: id }).then(res => {
        if (res.code === 100) {
          this.timeCode = res.data
          if (this.timeCode === 'D') {
            this.addTravelList.list[1].options.format = 'YYYY-MM-DD'
            this.addTravelList.list[1].options.showTime = false
          } else {
            this.addTravelList.list[1].options.format = 'YYYY-MM-DD HH:mm'
            this.addTravelList.list[1].options.showTime = true
          }
        }
      })
    },
    // 周期变化  考勤数据变化
    onChange(date) {
      this.startDate = date[0]
      this.endDate = date[1]
      this.serchGetData()
    },
    toggleValue(val) {
      this.dragValue = val
      this.serchGetData()
    },
    // 获取列表信息
    async fetchDataApiFn(param) {
      const startDate = moment(this.recordTime[0]).format('YYYY-MM-DD')
      const endDate = moment(this.recordTime[1]).format('YYYY-MM-DD')
      const res = await getStaffApplyTravel({ ...param, startDate, endDate })
      this.dataSource = res.data.records ? res.data.records : []
      this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
      this.total = res.data.total
    },
    onClose() {
      this.params = this.$options.data().params
      console.log(this.params)
      this.$refs.KFB.reset()
      this.addRecord = false
    },
    // 导出
    exportFileList() {
      const startDate = moment(this.recordTime[0]).format('yyyy-MM-DD') || this.startDate
      const endDate = moment(this.recordTime[1]).format('yyyy-MM-DD') || this.endDate
      const { staffIds, operateType, targetCity, searchWord, engageModes, orgIds, postIds, contractCo, workplaceIds, staffStatuses } = this._queryParam
      exportStaffApplyTravel({
        staffIds: staffIds,
        operateType: operateType,
        targetCity: targetCity,
        searchWord: searchWord,
        engageModes: engageModes,
        orgIds: orgIds,
        postIds: postIds,
        contractCo: contractCo,
        workplaceIds: workplaceIds,
        staffStatuses: staffStatuses,
        startDate,
        endDate
      })
    },
    // 查询回调
    getSearchCallback(data) {
      this._queryParam.searchWord = data.searchValue
      this.serchGetData()
    },
    // 筛选回调
    screenRosterParams(data) {
      this._queryParam.searchWord = data.searchValue
      if (data.screenCondition.orgIds) {
        this.orgids = data.screenCondition.orgIds
        this.newOrg = this.orgids.map(item => {
          return item.value
        })
        this._queryParam.orgIds = this.newOrg.join(',')
      }
      this._queryParam.engageModes = data.screenCondition.engageModes ? data.screenCondition.engageModes.join(',') : ''
      this._queryParam.postIds = data.screenCondition.postIds ? data.screenCondition.postIds.join(',') : ''
      this._queryParam.workplaceIds = data.screenCondition.workplaceIds ? data.screenCondition.workplaceIds.join(',') : ''
      this._queryParam.contractCo = data.screenCondition.contractCo ? data.screenCondition.contractCo : ''
      this._queryParam.operateType = data.screenCondition.operateType ? data.screenCondition.operateType : ''
      for (var key in this._queryParam) {
        if (!this._queryParam[key]) {
          delete this._queryParam[key]
        }
      }
      this.serchGetData()
    },
    // 删除前判断
    openDeleteBox() {
      this.deleteIds = []
      this.selectedRows.forEach(item => {
        this.deleteIds.push(item.travelApplyId)
      })
      this.$Reconfirm({
        title: '删除考勤信息',
        content: '确定要删除该出差信息吗？'
      }).then(() => {
        delStaffApplyTravel({ ids: this.deleteIds }).then(res => {
          if (res.code === 100) {
            this.$message.success('删除成功')
            this.selectedRowKeys = []
            this.selectedRows = []
            this.serchGetData()
          }
        })
      }).catch(() => {
        this.$message.info('已取消删除')
      })
    },
    // 新增
    add(val) {
      this.addName = 'add'
      if (val.label === 'addSingle') {
        this.$nextTick(() => {
          this.addRecord = true
        })
        this.addRecord = true
        this.addTravelList.list[0].options.disabled = false
        this.addTravelList.list[1].options.defaultValue = ''
        this.addTravelList.list[4].options.defaultValue = ''
        this.params.startDate = ''
        this.params.onJobId = ''
        this.params.endDate = ''
      } else {
        this.dragValue = true
        this.addRecord = false
      }
    },
    // 编辑
    edit(scope) {
      this.addName = 'edit'
      this.addRecord = true
      this.travelApplyId = scope.travelApplyId
      this.addTravelList.list[0].options.disabled = true
      if (scope.targetCityCodes) {
        const cityArr = scope.targetCityCodes.split(',')
        this.addTravelList.list[4].options.defaultValue = { province: cityArr[0], city: cityArr[1] }
      } else {
        this.addTravelList.list[4].options.defaultValue = { province: scope.targetCityCode, city: scope.targetCityCode }
      }
      this.$nextTick(async() => {
        const { onJobId, startDateTime, endDateTime, targetCityCode, applyReason } = scope
        const startTime = [startDateTime, endDateTime]
        this.$refs.KFB.setData({ onJobId, startTime, applyReason })
        const startDate = startDateTime.split(' ')
        this.params.startDate = startDate[0]
        this.params.startTime = startDate[1]
        const endDate = endDateTime.split(' ')
        this.params.endDate = endDate[0]
        this.params.endTime = endDate[1]
        this.params.targetCity = targetCityCode
        this.params.applyReason = applyReason
        this.params.onJobId = onJobId
        this.getStaffApplyTravelTime(onJobId)
        const res = await getStaffApplyTravelClass({ onJobId, startDate: this.params.startDate, startTime: this.params.startTime, endDate: this.params.endDate, endTime: this.params.endTime })
        delete res.data.workHours
        this.$refs.KFB.setData(res.data)
      })
    },
    // 确定事件
    async onSubmin() {
      await this.$refs.KFB.getData()
      this.params.startTime = this.timeCode === 'H' ? this.params.startTime : ''
      this.params.endTime = this.timeCode === 'H' ? this.params.endTime : ''
      if (this.addName === 'add') {
        const res = await addStaffApplyTravel(this.params)
        if (res.code === 100) {
          this.$message.success('添加成功')
        }
      } else {
        const res = await editStaffApplyTravel({ travelApplyId: this.travelApplyId, ...this.params	})
        if (res.code === 100) {
          this.$message.success('修改成功')
        }
      }
      this.onClose()
      this.serchGetData()
    },
    afterVisibleChange() {
      this.addRecord = false
    },
    // 表格选择项变更
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    disabledDate(current) { // 不可选择未来日期
      return current > moment().endOf('day')
    }
  }
}
</script>

<style lang="less" scoped>
.record-table{
  width: 100%;
  height: calc(100% - 50px);
  overflow: hidden;
  /deep/.ant-pagination{
    .ant-pagination-options-size-changer.ant-select {
      margin-right: 10px;
    }
  }
}
.record-table-heard{
  display: flex;
  justify-content: space-between;
}
.record-table-heard-left{
  display: flex;
  .deleteBtn{
    margin-left: 12px;
  }
}
.time{
    width: 240px;
    font-size: 16px;
    margin-bottom: 16px;
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 14px;
    background: @sc-greyBg-10;
    border-radius: 4px;
    /deep/.ant-calendar-picker{
      width: 100%;
      .ant-calendar-picker-input.ant-input {
        background: @sc-greyBg-10;
        border: none;
      }
      .ant-calendar-range-picker-input {
        width: 46%;
        color: @sc-grey-100;
      }
    }
  .iconpark-icon{
    width: 20px;
    height: 20px;
    color: @sc-primary-100;
  }
}
/deep/ .time .ant-input {
  border: 0;
  background:  @sc-greyBg-10;
  &:hover .ant-calendar-range-picker-input,
  &:hover .ant-calendar-range-picker-separator {
    color:@sc-primary-100 !important;
    cursor: pointer;
  }
}
</style>
