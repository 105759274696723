<template>
  <div class="basic-info-container">
    <Form ref="form" :form="form" :wrapper-col="{span:21}" :label-col="{span:3}" />
  </div>
</template>

<script>
import { addGroup, getBaseInfo, updateBaseInfo } from '@/services/wage/salaryGroup.js'
import mixins from '@/components/CbPerDepSelection/index.js'
export default {
  name: 'BasicInfo',
  components: {
    Form: () => import('./form.vue')
  },
  mixins: [mixins],
  inject: ['infoData'],
  props: {
    current: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      form: {
        groupName: '',
        userList: [],
        enabled: true,
        startPeriod: ''
      }
    }
  },
  watch: {
    current(newCurrent) {
      if (newCurrent === 0 && this.infoData.groupId) {
        this.echoData()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.infoData.groupId) {
        this.echoData()
      }
    })
  },
  methods: {
    // 保存薪资组基本信息
    async onPreserve() {
      this.$refs.form.$refs.basicForm.validate(valid => {
        if (valid) {
          // 如果存在薪资组id 说明薪资组已经是被创建状态
          if (this.infoData.groupId) {
            return this.editGroup()
          }
          this.addGroup()
        }
      })
    },
    // 新增薪资组
    async addGroup() {
      const res = await addGroup({ ...this.form, enabled: this.form.enabled ? 1 : 0 })
      if (res.code === 100) {
        this.$emit('changeGroupId', res.data)
        this.$message.success('添加成功！')
      }
    },
    // 编辑薪资组
    async editGroup() {
      const res = await updateBaseInfo({ ...this.form, enabled: this.form.enabled ? 1 : 0, groupId: this.infoData.groupId })
      if (res.code === 100) {
        this.$emit('changeGroupId', res.data)
        this.$message.success('保存成功！')
      }
    },
    // 编辑回显基本信息
    async echoData() {
      const res = await getBaseInfo({ groupId: this.infoData.groupId })
      if (res.code === 100) {
        this.form = { ...res.data, enabled: !!res.data.enabled }
      }
    },
    perModelConfirm(data) {
      this.form.userList = data.map((v) => {
        return {
          onJobId: v.onJobId,
          staffName: v.staffName,
          staffId: v.staffId
        }
      })
      this.$refs.basicForm.validateField(['userList'])
      this.model = false
    },
    selectEmployees() {
      this.model = true
    }
  }
}
</script>
<style scoped lang="less">
.basic-info-container{
  padding: 0 10px;
  width: 100%;
  /deep/.ant-input{
    width: 414px;
  }
}
</style>
