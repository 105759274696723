<!--员工档案的员工信息组件封装-->
<template>
  <div class="component-userinfo-contain">
    <div class="userinfo-contain">
      <div class="basic-userinfo-p">
        <basicInfo v-bind="$props" :update="update" :status-type="statusType" :on-job-id="onJobId" />
      </div>
      <div class="tab-userinfo-p">
        <div
          class="com-tab"
          :class="{ 'active': activeTab == 'personal' }"
          @click="changeTab('personal')"
        >
          个人信息
        </div>
        <div
          class="com-tab"
          :class="{ 'active': activeTab == 'attachment' }"
          @click="changeTab('attachment')"
        >
          附件材料
        </div>
        <div
          v-if="statusType !== 'toExamine'"
          class="com-tab"
          :class="{ 'active': activeTab == 'record' }"
          @click="changeTab('record')"
        >
          信息记录
        </div>
      </div>
    </div>
    <div class="tab-info-p">
      <personInfo v-if="activeTab == 'personal'" v-bind="$props" @updateInfo="updateInfo" />
      <attachmentInfo v-if="activeTab == 'attachment'" v-bind="$props" />
      <infoMationRecord v-if="activeTab == 'record'" v-bind="$props" :status-type="statusType" />
    </div>
    <examine v-if="statusType === 'toExamine'" :on-job-id="onJobId" :page-type="pageType" :archives-perfect-id="archivesPerfectId" :user-id="userId" class="examine_class" />
  </div>
</template>

<script>
import basicInfo from './basic-info.vue'
import personInfo from './person-info.vue'
import attachmentInfo from './attachment-info.vue'
import infoMationRecord from './infomation-record.vue'
import examine from './examine.vue'
export default {
  components: { basicInfo, personInfo, attachmentInfo, infoMationRecord, examine },
  props: {
    anchorHeight: {
      type: String,
      default: ''
    },
    userId: {
      type: String,
      default: ''
    },
    statusType: {
      type: String,
      default: ''
    },
    pageType: {
      type: String,
      default: ''
    },
    archivesPerfectId: {
      type: String,
      default: ''
    },
    onJobId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      update: false,
      activeTab: ''
    }
  },
  provide() {
    return {
      anchorHeight: this.getAnchorHeight,
      statusType: this.statusType
    }
  },
  mounted() {
    this.activeTab = 'personal'
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab
      this.$emit('changeTab', tab)
    },
    getAnchorHeight() {
      return this.anchorHeight
    },
    updateInfo() {
      this.update = !this.update
    }
  }
}
</script>

<style lang="less" scoped>
.component-userinfo-contain {
  padding: 15px 0 0;
  .userinfo-contain {
    padding-left: 20px;
    padding-right: 20px;
  }
  .basic-userinfo-p {
    margin-bottom: 20px;
  }
  .tab-userinfo-p {
    height: 40px;
    border-radius: 8px;
    border:1px solid @sc-greyBg-20;
    margin-bottom:20px;
    .flex-center();
    .com-tab {
      width: 140px;
      height: 30px;
      color: @sc-grey-60;
      .font-size(14px);
      .flex-center();
      &:hover{
        color: @sc-grey-100;
        cursor: pointer;
      }
      &.active {
        background-color: @sc-greyBg-20;
        color: @sc-grey-100;
        border-radius: 4px;
        cursor: pointer;
        transition: all .5s;
      }
    }
    .com-tab + .com-tab {
      margin-left: 125px;
    }
  }
}
  .examine_class {
    position: fixed;
    right: 0;
    bottom: 0;
  }
</style>
