<template>
  <div v-if="from">
    <appForm
      :to-list="toList"
      :screen-roster-list="screenRosterList"
      width="400"
      @getSearchCallback="getSearchCallback"
      @screenRosterParams="screenRosterParams"
      @exportFileList="exportFileList"
    />
    <div>
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        :row-key="'onJobId'"
        :scroll="{ x: 1300 }"
        :offset-bottom="64"
        :pagination="{
          current: pager.pageNum,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条数据`,
          showQuickJumper: true,
          showSizeChanger: true
        }"
        @change="loadDataList"
      >
        <!-- 序号 -->
        <template slot="index" slot-scope="{ scope }">
          <span>{{ scope.index + 1 }}</span>
        </template>
        <!-- 操作列 -->
        <template slot="operation" slot-scope="{ scope }">
          <div class="flex-align-center">
            <div class="more-handle">
              <a-button type="link" @click="handleDetail(scope)">明细</a-button>
            </div>
          </div>
        </template>
        <!-- 头像 -->
        <template slot="staffName" slot-scope="{ scope }">
          <CbTableAvater
            :table-avater="scope.record.avatar"
            :table-name="scope.record.staffName"
            :on-job-id="scope.record.onJobId"
            :staff-id="scope.record.staffId"
          />
        </template>
      </custom-table>
    </div>
    <Drawer
      size="small"
      title="计薪明细"
      placement="right"
      is-mask
      display-btn
      closable
      :visible="visible"
      @close="detailOnClose"
    >
      <div class="detailContent">
        <div class="header">
          <div class="avater">
            <CopyAvater size="28" :on-job-id="detailInfo.onJobId" :avater-name="detailInfo.staffName" />
          </div>
          <div class="sum">{{ detailInfo.sum }}元</div>
        </div>
        <div class="detailItems">
          <template v-for="(item, index) in detailInfo.types">
            <div :key="`detailContentItem${index}`" class="detailContentItem">
              <div class="groupTitle">
                <div class="name">{{ item.salaryTypeName }}</div>
                <div class="amount">{{ item.amount }}元</div>
              </div>
              <div class="groupContent">
                <template v-for="(key, groupItemIndex) in item.items">
                  <div :key="`groupItem${groupItemIndex}`" class="groupItem">
                    <div class="label">{{ key.salaryItemName }}</div>
                    <div class="value">{{ key.amount }}元</div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import {
  finishGetCalcSalaryTypePage,
  getSalaryItemDetail,
  exportSalaryCalFinishList } from '@/services/wage/wagePayroll'
export default {
  components: {
    CopyAvater: () => import('@/components/CbAvater/theAvater.vue'),
    Drawer: () => import('@/components/CbDrawer'),
    CbTableAvater: () => import('@/components/CbTableAvater/index')
  },
  inject: ['salaryItemInfo'],
  mixins: [mixins],
  data() {
    return {
      from: true,
      visible: false,
      screenRosterList: {
        list: [
          {
            type: 'selectDictionaries',
            label: '聘用形式',
            options: {
              width: '100%',
              parameter: 'engage_mode',
              clearable: true,
              placeholder: '请选择职级'
            },
            model: 'engageMode',
            key: 'selectDictionaries_1651022813841'
          },
          {
            type: 'selectDepartment',
            label: '部门',
            options: {
              width: '100%',
              clearable: true,
              placeholder: '请选择'
            },
            model: 'orgId',
            key: 'selectDepartment_1651022903831'
          },
          {
            type: 'selectPost',
            label: '岗位',
            options: {
              width: '100%',
              clearable: true,
              placeholder: '请选择'
            },
            model: 'postId',
            key: 'selectPost_1651022948111'
          },
          {
            'type': 'selectDictionaries',
            'label': '合同公司',
            'options': {
              'width': '100%',
              'parameter': 'contract_company',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'contractCoId',
            'key': 'selectDictionaries_1650962628706'
          },
          {
            'type': 'selectDictionaries',
            'label': '工作地点',
            'options': {
              'width': '100%',
              'parameter': 'work_address',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'addressId',
            'key': 'selectDictionaries_1650962628701'
          },
          {
            'type': 'selectDictionaries',
            'label': '员工状态',
            'options': {
              'width': '100%',
              'parameter': 'staff_status',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'staffStatus',
            'key': 'selectDictionaries_1650962628702'
          }
        ],
        config: {
          layout: 'horizontal',
          labelCol: {
            xs: 4,
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
            xxl: 4
          },
          labelWidth: 100,
          labelLayout: 'flex',
          wrapperCol: {
            xs: 18,
            sm: 18,
            md: 18,
            lg: 18,
            xl: 18,
            xxl: 18
          },
          hideRequiredMark: false,
          customStyle: ''
        }
      },
      columns: [],
      indexList: {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        width: 50,
        fixed: true,
        customRender: (text, record, index) => `${index + 1}`
      },
      dataSource: [],
      detailInfo: {}, // 明细列表
      toList: ['search', 'screen', 'export']
    }
  },
  methods: {
    fetchDataApiFn(param) {
      param['salaryType'] = this.selectType
      param['groupCalcId'] = this.salaryItemInfo.groupCalcId
      param['taxpayPeriod'] = this.period
      param['inputValue'] = param['inputValue'] ? param['inputValue'] : ''
      finishGetCalcSalaryTypePage(param).then(res => {
        const columnObj = {
          'staffName': '105px',
          'staffNum': '140px'
        }
        this.columns = res.data.columns.map((item, index) => {
          console.log(item.dataType, '==dataType')
          return {
            title: item.fieldName,
            dataIndex: item.fieldCode,
            width: columnObj[item.fieldCode]
              ? columnObj[item.fieldCode] : `${item.fieldName.length * 30 + 20}px`,
            fixed: index < 4 ? 'left' : false,
            scopedSlots: item.fieldCode === 'staffName' ? { customRender: item.fieldCode } : '',
            ellipsis: true,
            dataType: item.dataType
          }
        })
        this.columns.unshift(this.indexList)
        this.columns.push({
          title: '操作',
          dataIndex: 'operation',
          fixed: 'right',
          scopedSlots: { customRender: 'operation' }
        })
        this.columns.push({
          title: '',
          dataIndex: new Date().getTime()
        })
        this.dataSource = res.data.content ? res.data.content.records : []
        this.pager.pageNum = res.data.content.pageNum || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.content.pageSize || 10 // 更新当前的分页信息
        this.total = res.data.content.total || 0
      })
    },
    // 搜索
    getSearchCallback(val) {
      this._queryParam.inputValue = val.searchValue
      this.serchGetData()
    },
    screenRosterParams(data) {
      const { engageMode, orgId, postId, contractCoId, addressId, staffStatus } = data.screenCondition
      this._queryParam = {
        inputValue: data.searchValue,
        engageMode,
        orgId: orgId && orgId[0] && orgId[0].value,
        postId,
        contractCoId,
        addressId,
        staffStatus
      }
      this.serchGetData()
    },
    // 获取薪资项明细
    async handleDetail(info) {
      const res = await getSalaryItemDetail({ onJobId: info.record.onJobId, groupCalcId: this.salaryItemInfo.groupCalcId })
      this.detailInfo = res.data
      this.visible = true
    },
    // 明细关闭
    detailOnClose() {
      this.visible = false
    },
    async exportFileList() {
      const params = JSON.parse(JSON.stringify(this._queryParam))
      params.pageNum = 1
      params.pageSize = -1
      await exportSalaryCalFinishList(params)
    }
  }
}
</script>
<style lang="less" scoped>
.flex-align-center{
  .more-handle .ant-btn-link{
    padding: 0;
  }
}
.detailContent{
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 12px;
    .font-size(16px);
     color: @sc-grey-100;
     margin-bottom: 18px;
    .avater{
      display: flex;
      align-items: center;
      /deep/.box .user-name{
        font-size: 16px;
      }
    }
  }
  .detailContentItem{
    margin-bottom: 20px;
    .groupTitle{
      background: @sc-greyBg-10;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 12px;
      border-radius: 6px 6px 0 0;
      .font-size(14px);
      color: @sc-grey-100;
    }
    .groupContent{
      .groupItem{
        .font-size(14px);
        padding: 10px 12px;
        border-bottom: 1px solid @sc-greyBg-20;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .label{
          color: @sc-grey-80;
        }
        .value{
          color: @sc-grey-100;
        }
      }
    }
  }
}
</style>
