import InductionControl from './InductionControl'
import jobNumRule from './jobNumRule'
import messageSet from './messageSet'
import probation from './probation'
import proofTemplate from './proofTemplate'
import sendEmail from './sendEmail'
import userInfo from './userInfo'
import EntryMatters from './EntryMatters'

export default {
  InductionControl,
  jobNumRule,
  messageSet,
  probation,
  proofTemplate,
  sendEmail,
  userInfo,
  EntryMatters
}
