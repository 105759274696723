<template>
  <div class="payTax">
    <div class="navigation">
      <div class="navigation_button" @click="goBack"><a-icon type="left" /></div>
      <span>{{ payInfo.proof }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PayTax',
  props: {
    payInfo: {
      type: Object,
      default: function() {}
    },
    curtime: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  methods: {
    goBack() {
      this.$emit('goBack')
    }
  }
}
</script>
<style lang="less" scoped>
.navigation{
	display: flex;
	align-items: center;
	padding-bottom: 30px;
	.navigation_button{
		width: 30px;
		height: 30px;
		font-size: 20px;
		margin-right: 10px;
		text-align: center;
		cursor: pointer;
	}
	span{
		font-size: 18px;
	}
}
</style>
