<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
    >
      <BaseMessage :form="form" :shift-type="shiftType" @change="typeChange" />
      <TimeSetting :form="form" />
      <FlexSetting :form="form" :shift-type="shiftType" />
      <!-- <AbnormalSetting :form="form" /> -->
    </a-form-model>
  </div>
</template>

<script>
import { sepcialCode } from '@/utils/reg.js'
export default {
  components: {
    BaseMessage: () => import('../components/baseMessage.vue'),
    TimeSetting: () => import('../components/timeSetting.vue'),
    FlexSetting: () => import('../components/flexSetting.vue')
    // AbnormalSetting: () => import('../components/abnormalSetting.vue')
  },
  props: {
    shiftType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      shiftVisible: false,
      form: {
        shiftName: '',
        shiftShortName: '',
        colorThemeCode: '',
        dayStartTime: '00:00', // 上班时点
        dayEndTime: '23:59', // 上班时点
        shiftFlexType: '0', // 弹性方式
        // allowLateInMinutes: '10', // 迟到豁免时间
        // allowEarlyOutMinutes: '20', // 早退豁免时间
        flexInMaxMinutes: '', // 上班弹性时间
        flexOutMaxMinutes: '', // 下班弹性时间
        flexTimes: '', // 弹性时间个数
        flexTimesStep: '', // 弹性时间间隔分钟数
        shiftType: 'A1',
        shiftPunchRuleList: [{ invalidPunchHandleType: '1' }]
        // openLateAbnormalRule: false,
        // openEarlyAbnormalRule: false,
        // abnormalLateRule: [],
        // abnormalEarlyRule: []
      },
      rules: {
        shiftName: [
          { required: true, message: '请输入班次名称', trigger: 'blur' },
          { min: 1, max: 30, message: '请输入1~30个文字', trigger: ['change', 'blur'] },
          { pattern: sepcialCode, message: '班次名称不支持特殊字符', trigger: ['change', 'blur'] }
        ],
        shiftShortName: [
          { required: true, message: '请输入班次简称', trigger: 'blur' },
          { min: 1, max: 4, message: '班次简称仅支持1~4个文字', trigger: ['change', 'blur'] },
          { pattern: sepcialCode, message: '班次简称不支持特殊字符', trigger: ['change', 'blur'] }
        ],
        colorThemeCode: [
          { required: true, message: '请选择班次颜色', trigger: ['change', 'blur'] }
        ],
        shiftType: [
          { required: true, message: '请选择班次类型', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    typeChange(val) {
      if (['A1', 'A2'].includes(val)) this.form.shiftPunchRuleList = [{ invalidPunchHandleType: '1', breakFlag: true }]
      this.form.shiftHours = ''
    }
  }
}
</script>

<style lang="less" scoped>

</style>
<style>
.ant-time-picker-panel-select::-webkit-scrollbar {
    display:none ;
}
</style>
