<template>
  <div>
    <Modal :visible="transferModal" :closable="true" :size="'normal'" :title="'转交审批'" :mask-closable="false" :ok-text="'确认'" @ok="handleSubmit" @cancel="handleCancel">
      <div v-if="approverList.length > 0" class="approver-box">
        <p class="transfer-p">需要转交的审批人</p>
        <div class="approver">
          <div v-for="(item, index) in approverList" :key="item.approverId" @click="changeUser(index)">
            <!-- <img src="" alt="" /> -->
            <div class="v-name-circl">
              <Avater on-job-id="" :avater-name="item.approverName" size="60" :show-user-name="false" />
              <!-- <span class="v-name-circl-text">{{
                item.approverName.length > 2 ? item.approverName.slice(item.approverName.length - 2, item.approverName.length) : item.approverName
              }}</span> -->
            </div>
            <div :class="active === index ? 'active' : ''">{{ item.approverName }}</div>
          </div>
        </div>
      </div>
      <div class="operation">
        <p class="transfer-p">转交对象</p>
        <PerTreeSelect
          :per-tree-value="form.targetUserId"
          :placeholder="'请选择人员'"
          @getPerTreeValue="getPerTreeValue"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import { transferRecord } from '@/services/logicFlow'
import mixins from '@/components/CbPerDepSelection/index.js'
export default {
  components: {
    Modal: () => import('@/components/CbModal/index'),
    Avater: () => import('@/components/CbAvater/theAvater')
  },
  mixins: [mixins],
  data() {
    return {
      transferModal: false,
      modal: false,
      approverList: [],
      transferApporver: [],
      active: 0,
      form: {
        flowRecordId: '',
        sourceUserId: '',
        targetUserId: ''
      }
    }
  },
  mounted() {},
  methods: {
    // 确认操作
    async handleSubmit() {
      // 转交对象不能和需要转交的审批人出现重复
      if (this.vaild()) {
        const { code } = await transferRecord(this.form)
        if (code !== 100) return
        this.$message.success('转交成功')
        this.$emit('change')
        this.transferModal = false
        this.resetData()
      }
    },
    vaild() {
      const { targetUserId } = this.form
      const { approverList } = this
      if (!targetUserId) {
        this.$message.warning('请选择转交对象')
        return false
      }
      for (let i = 0; i < approverList.length; i++) {
        const item = approverList[i]
        if (item.approverId === targetUserId) {
          this.$message.warning('转交对象不能和需要转交的审批人出现重复')
          return false
        }
      }
      return true
    },
    changeUser(index) {
      this.active = index
      this.form.sourceUserId = this.approverList[index].approverId
    },
    resetData() {
      Object.assign(this.$data, this.$options.data())
    },
    // 取消事件
    handleCancel() {
      this.resetData()
      this.transferModal = false
    },
    // 打开弹窗
    openModal(data) {
      this.form.flowRecordId = data.flowRecordId
      const approverList = []
      if (data.approverId) {
        const ids = data.approverId.split(',')
        const names = data.approverName.split(',')
        names.forEach((item, index) => {
          const obj = {
            approverName: item,
            approverId: ids[index]
          }
          approverList.push(obj)
        })
        this.approverList = approverList
        this.form.sourceUserId = this.approverList[this.active].approverId
      }
      this.transferModal = true
    },
    getPerTreeValue(val) {
      this.form.targetUserId = val
    }
  }
}
</script>

<style lang="less" scoped>
.approver-box {
  font-weight: 600;
  .approver {
    display: flex;
    flex-wrap: wrap;
    div {
      width: 70px;
      margin: 0 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      cursor: pointer;
      img {
        width: 40px;
        height: 40px;
        margin-bottom: 5px;
        border-radius: 50%;
        border: 1px solid @sc-primary-100;
      }
    }
    .active {
      color: @sc-primary-80;
    }
  }
}
.operation {
  font-weight: 600;
  margin-top: 20px;
  &:first-child {
    margin-top: 0;
  }
  .icon {
    font-size: 45px;
    cursor: pointer;
  }
  .approver {
    display: flex;
    flex-wrap: wrap;
    div {
      width: 70px;
      margin: 0 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      img {
        width: 40px;
        height: 40px;
        margin-bottom: 5px;
        border-radius: 50%;
        border: 1px solid @sc-primary-100;
      }
    }
  }
}
.v-name-circl {
  border-radius: 100px;
  height: 60px;
  width: 60px !important;
  background: @sc-primary-80;
  &-text {
    line-height: 60px;
    color: #fff;
  }
  /deep/.box .avater-name{
    font-size: 14px;
  }
}
.transfer-p {
  color: @sc-grey-80;
  font-weight: normal;
  margin-bottom: 16px;
}
/deep/ .ant-select-selection-selected-value {
  font-weight: normal;
}
/deep/ .ant-select-selection__placeholder {
  font-weight: normal;
}
</style>
