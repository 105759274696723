<template>
  <div class="Jcommon-layout">
    <div class="page Jcommon-layout-center">
      <header class="page__header">
        <div class="page-actions">
          <a-button type="iconBtn" icon="left" @click="exit" />
          <div>{{ title }}</div>
        </div>
        <div class="step-tab">
          <div
            v-for="(item, index) in steps"
            :key="index"
            class="step"
            :class="[
              activeStep == item.key
                ? 'active'
                : activeIndex > index
                  ? 'normal'
                  : 'default',
            ]"
            @click="changeSteps(item, index)"
          >
            <span v-if="index !== 0" class="step-line" />
            <span class="step-index">{{ index + 1 }}</span>
            {{ item.label }}
          </div>
          <div class="ghost-step step" :style="{ transform: translateX }" />
        </div>
        <div class="designer-actions">
          <a-popover
            v-if="errList.length > 0"
            placement="bottom"
            trigger="click"
          >
            <div slot="content" class="err-content">
              <div class="title">内容不完善</div>
              <div class="description">以下内容不完善，请修改后发布</div>
              <div class="err-list">
                <template>
                  <div
                    v-for="(key, index) in errList"
                    :key="`errItem${index}`"
                    class="err-item"
                  >
                    <span class="module-name">{{ key.title }}</span>
                    <span class="msg">{{ key.msg }}</span>
                    <span
                      class="put"
                      @click="toFixErr(key.stepName)"
                    >去修改</span>
                  </div>
                </template>
              </div>
            </div>
            <span class="err-tip">未完善</span>
          </a-popover>
          <a-button v-if="env === 'development'" class="a-but" @click="changeDesignType">切换表单</a-button>
          <a-button class="a-but">预览</a-button>
          <a-button type="primary" class="a-btn" @click="sendToServer()">发布</a-button>
        </div>
      </header>
      <section class="Jcommon-layout-main Jflex-main">
        <BasicSetting
          v-show="activeStep === 'basicSetting'"
          ref="basicSetting"
          :conf="echoData ? echoData.basicSetting : {}"
          tab-name="basicSetting"
          :group-list="groupList"
          :active-flow-group-id="activeFlowGroupId"
        />
        <KFormDesign
          v-show="activeStep === 'formDesign'"
          ref="formDesign"
          :process-data="params.flowDesign"
          :flow-setting="params.flowSetting"
          tab-name="formDesign"
          :type="formType"
          :toolbars="KFormToolbar"
          :custom-filter-type="['selectIcon', 'threeLevel']"
        />
        <Process
          v-show="activeStep === 'processDesign'"
          ref="processDesign"
          :conf="echoData ? echoData.flowDesign : {}"
          :form-data="params.formDesign.list"
          tab-name="processDesign"
        />
        <AdSetting
          v-show="activeStep === 'AdSetting'"
          ref="AdSetting"
          :conf="echoData ? echoData.adSetting : {}"
        />
      </section>
    </div>
  </div>
</template>

<script>
import Process from '@/components/workflow/Process'
import BasicSetting from '@/components/workflow/BasicSetting'
import KFormDesign from '@/components/kFormDesign/packages/KFormDesign'
import { createFlow, updateFlow } from '@/services/logicFlow'
import AdSetting from '@/components/workflow/AdvancedSetting'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'Home',
  components: {
    Process,
    BasicSetting,
    KFormDesign,
    AdSetting
  },
  props: {
    title: {
      type: String,
      default: '流程设计器'
    },
    groupList: {
      type: Array,
      default: () => []
    },
    activeFlowGroupId: {
      type: String,
      default: ''
    },
    flowId: {
      type: [String, Number],
      default: ''
    },
    echoData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      env: process.env.VUE_APP_ENV,
      formType: 'kFormDesign',
      id: null,
      errList: [],
      orgTreeData: [],
      activeIndex: 0,
      activeStep: 'basicSetting', // 激活的步骤面板
      steps: [
        { label: '基础设置', key: 'basicSetting' },
        { label: '表单设计', key: 'formDesign' },
        { label: '流程设计', key: 'processDesign' },
        { label: '高级设置', key: 'AdSetting' }
      ],
      params: {
        flowSetting: {},
        flowDesign: {
          list: []
        },
        formDesign: {}
      },
      isValid: false // 业务层面是否进行了验证
    }
  },
  computed: {
    ...mapGetters('designer', ['getPredefine', 'getFormConditionKeyList', 'getAutoComputeRelation']),
    translateX() {
      return `translateX(${
        this.steps.findIndex((t) => t.key === this.activeStep) * 100
      }%)`
    },
    KFormToolbar() {
      return process.env.VUE_APP_ENV === 'development' ? ['reset', 'exportJson', 'undo', 'redo'] : ['reset', 'undo', 'redo']
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.echoData) {
        this.$refs.formDesign && this.$refs.formDesign.handleSetData(this.echoData.formDesign)
        this.setFormConditionKeyList(this.echoData.formDesign.formConditionKeyList || {})
        this.setAutoComputeRelation(this.echoData.formDesign.autoComputeRelation || {})
      }
    })
  },
  methods: {
    ...mapMutations('designer', ['setFormConditionKeyList', 'setAutoComputeRelation']),
    toFixErr(stepName) {
      this.activeStep = stepName
    },
    changeDesignType() {
      if (this.formType === 'kFormDesign') {
        this.formType = 'kForm'
      } else {
        this.formType = 'kFormDesign'
      }
    },
    changeSteps(item, index) {
      this.activeStep = item.key
      this.activeIndex = index
      this.findAllErr()
    },
    // 找出每一步里面的报错项
    async findAllErr() {
      const titleObj = {
        0: {
          title: '基础设置',
          stepName: 'basicSetting'
        },
        1: {
          title: '表单设计',
          stepName: 'formDesign'
        },
        2: {
          title: '流程设计',
          stepName: 'processDesign'
        },
        3: {
          title: '高级设置',
          stepName: 'AdSetting'
        }
      }
      const getCmpData = (name) => this.$refs[name].getData()
      const getCmpErrList = (name) => this.$refs[name].getErrList()
      const dataHttpList = []
      const errMsgHttpList = []
      Object.values(titleObj).forEach((item) => {
        dataHttpList.push(getCmpData(item.stepName))
        errMsgHttpList.push(getCmpErrList(item.stepName))
      })
      const dataRes = await Promise.all(dataHttpList)
      this.params.formDesign = dataRes[1]
      this.params.flowSetting = Object.assign(dataRes[0], dataRes[3])
      this.params.flowDesign = dataRes[2].formData
      const errRes = await Promise.all(errMsgHttpList)
      this.errList = []
      // 错误收集的逻辑
      errRes.forEach((item, index) => {
        item.forEach((key) => {
          this.errList.push({
            title: titleObj[index].title,
            stepName: titleObj[index].stepName,
            msg: key.message
          })
        })
      })
    },
    async sendToServer() {
      await this.findAllErr()
      if (this.errList.length > 0) {
        return this.$message.warning(
          `请再次检查配置项，配置项中有${this.errList.length}处错误`
        )
      }
      if (this.getPredefine) this.filterSummary()
      // 把使用的组件作为条件的数据塞入kformdesign中，回显时候再次赋值使用
      this.params.formDesign['formConditionKeyList'] = this.getFormConditionKeyList
      this.params.formDesign['autoComputeRelation'] = this.getAutoComputeRelation
      if (this.flowId) {
        const params = JSON.parse(JSON.stringify(this.params))
        params.flowSetting['flowId'] = this.flowId
        updateFlow(params).then((res) => {
          this.$message.success('流程更新成功！')
          this.$emit('refresh', true)
        })
      } else {
        createFlow(this.params).then((res) => {
          this.$message.success('流程新建成功！')
          this.$emit('refresh', true)
        })
      }
    },
    filterSummary() {
      const summaryList = []
      const unitList = ['WKNumber', 'WKAmount']
      this.params.formDesign.list.forEach((item) => {
        const baseInfoList = item.options.baseInfoList
        // 常规组件是否是摘要字段的逻辑
        if (item.isSummary) {
          // 是否为时间区间这种多个label的
          if (baseInfoList.length > 1) {
            baseInfoList.forEach((key) => {
              summaryList.push({
                label: key.label,
                key: `${item.model}.${key.model}`,
                dataType: item.dataType || ''
              })
            })
          } else {
            const obj = {
              label: baseInfoList[0].label,
              key: item.model,
              dataType: item.dataType || ''
            }
            if (unitList.includes(item.type)) {
              obj['unit'] = item.options.companyVal
            }
            summaryList.push(obj)
          }
        }
        // 套件是否是摘要字段的逻辑
        if (item.subType === 'suite') {
          baseInfoList.forEach((key) => {
            if (key.isSummary) {
              if (this.vaild(key.type, key.model)) {
                summaryList.push({
                  label: key.label,
                  key: `${item.model}.${key.type}`,
                  dataType: key.dataType || ''
                })
              } else {
                summaryList.push({
                  label: key.label,
                  key: `${item.model}.${key.model}`,
                  dataType: key.dataType || ''
                })
              }
            }
          })
        }
      })
      this.params.formDesign['summary'] = summaryList
    },
    vaild(type = '', model) {
      // 这几个套件内的时间都需要合并
      // 补卡的数据比较特殊没有startDate、endDate，所以这里单独判断
      if (type.indexOf('WKAppendPunchSuite') > -1 && model === 'punchDate') { return true }
      const ContainsTimeSuite = [
        'WKOvertimeSuite', // 加班
        'WKOutsideSuite', // 外出
        'WKBusinessTripSuite', // 出差
        'WKLeaveSuite' // 请假
      ]
      let res = false
      ContainsTimeSuite.forEach((item) => {
        if (
          type.indexOf(item) > -1 &&
          (model === 'startDate' || model === 'endDate')
        ) { res = true }
      })
      return res
    },
    close() {
      this.$emit('refresh', true)
    },
    exit() {
      // this.$confirm({
      //   title: '提示',
      //   content: '离开此页面您得修改将会丢失, 是否继续?',
      //   onOk: () => {
      //     this.$parent.formVisible = true
      //   }
      // })
      this.$parent.formVisible = true
      this.$emit('backForm')
    }
  }
}
</script>
<style lang="less" scoped>
.Jcommon-layout{
    height: 100%;
}
.page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .page__header {
    z-index: 99;
    background-color: #fff;
    width: 100%;
    height: 56px;
    display: flex;
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    font-size: 14px;
    border-bottom: 1px solid @sc-greyBg-20;
    .page-actions {
      height: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      line-height: 56px;
      .iconBtn{
      display: flex;
      justify-content: center;
      align-items: center;
      height:20px !important;
      width:20px !important;
      margin-right:6px;
      padding: 0 !important;
      .iconBtn{
        padding: 0 !important;
      }
      }

      > div {
        height: 100%;
        font-size: 16px;
        padding-left: 6px;
        padding-right: 20px;
        color:  @sc-grey-100;
        display: inline-block;
      }
    }

    .step-tab {
      margin-left: 90px;
      display: flex;
      justify-content: center;
      position: relative;

      >.step {
        padding-left: 0px;
        padding-right: 0px;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;

        &.ghost-step{
          position: absolute;
          height: 56px;
          left: 0;
          z-index: -1;
          background: @sc-primary-80;
          transition: transform .5s;

          &::after {
            content: '';
            border-width: 6px 6px 6px;
            border-style: solid;
            border-color: transparent transparent #fff;
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -6px;
          }
        }

        &.active {
          color: @sc-primary-100;
          >.step-index  {
            display: inline-block;
            width: 24px;
            height: 24px;
            line-height: 22px;
            text-align: center;
            background-color: @sc-primary-100;
            color: #fff;
            border-radius: 50%;
          }
          > .step-line {
            background-color: @sc-primary-100;
          }
        }
        &.normal {
          > .step-index{
            display: inline-block;
            width: 24px;
            height: 24px;
            line-height: 22px;
            text-align: center;
            color: @sc-primary-100;
            border-radius: 50%;
            border: 1px solid @sc-primary-100;
            font-size: 14px;
          }
          > .step-line {
            background-color: @sc-primary-100;
          }
        }
        >.step-index {
          display: inline-block;
          width: 24px;
          height: 24px;
          border: 1px solid @sc-greyBg-20;
          border-radius: 50%;
          line-height: 22px;
          text-align: center;
          box-sizing: border-box;
          margin-right: 10px
        }
      }
    }
    .designer-actions{
      width: 222px;
      text-align: right;
      .a-but{
        color: @sc-primary-100 !important;
        margin-left: 12px;
      }
      .a-btn {
        margin-left: 12px;
      }
    }
  }
  .Jcommon-layout-main.Jflex-main{
    width: 100%;
    height: calc(100% - 56px);
  }
}

/deep/.form-designer-container-9136076486841527{
  height:100%;
}
.err-tip {
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: @error-color;
  padding: 2px 7px;
  background-color: rgba(227, 77, 89, 0.06);
  cursor: pointer;
}
.err-content {
  .title {
    color: @sc-grey-100;
    font-size: 14px;
    line-height: 22px;
  }
  .description {
    color: @sc-grey-60;
    font-size: 14px;
    line-height: 20px;
  }
  .err-list {
    // width: calc(100% - 20px);
    height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.err-content {
  width: 400px;
  height: 270px;
  background-color: #fff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}
.err-item {
  height: 36px;
  margin-left: 16px;
  margin-top: 12px;
  border: 1px solid #fff;
  background-color: @sc-greyBg-10;
  border-radius: 4px 4px 4px 4px;
  padding-top: 6px;
  position: relative;
  align-items: center;
}
.module-name {
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
  color: @sc-grey-60;
  margin-right: 8px;
  margin-left: 8px;
}
.err-item .msg {
  position: absolute;
  display: inline-block;
  font-size: 14px;
  color: @sc-grey-100;
}
.put {
  display: inline-block;
  position: absolute;
  right: 8px;
  color: @sc-primary-100;
  cursor: pointer;
}
.step-line {
  display: inline-block;
  width: 98px;
  height: 2px;
  background-color: @sc-greyBg-20;
  text-align: center;
  margin-left: 12px;
  margin-right: 14px;
}

.title {
  margin-left: 16px;
  margin-top: 11px;
}
.description {
  margin-left: 16px;
  margin-top: 3px;
}
</style>
