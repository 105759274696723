<template>
  <div class="ApprovalCategory">
    <div class="category-header">
      <HeaderBack title="审批分类管理" @back="$emit('back')" />
    </div>
    <appForm
      @getSearchCallback="getSearchCallback"
    >
      <template slot="customButton">
        <a-button type="primary" prefix="add-one" @click="addCategory">
          新建分类
        </a-button>
      </template>
    </appForm>
    <custom-table
      id="mytb"
      :columns="columns"
      table-ref="#mytb"
      :data-source="groupList"
      row-key="flowGroupId"
      :pagination="false"
      @change="loadDataList"
    >
      <template slot="operation" slot-scope="{ scope }">
        <!-- <div class="flex-align-center"> -->
        <!-- <a-popover placement="bottom" trigger="hover">
            <template slot="content">
              <div class="more-handle-btn">
                <a-button type="link" @click="editCategory(scope.record)">
                  编辑
                </a-button>
                <a-button type="link" class="delete" @click="deletCategory(scope.record)">
                  删除
                </a-button>
                <a-button type="link" @click="move(scope.record)">
                  移动到新分组
                </a-button>
              </div>
            </template>
            <div class="more-handle">···</div>
          </a-popover>
        </div> -->
        <div class="operation-btn-box">
          <a-button type="link" @click="editCategory(scope.record)">
            编辑
          </a-button>
          <div class="flex-align-center">
            <a-popover placement="bottom" trigger="hover">
              <template slot="content">
                <div class="more-handle-btn">

                  <a-button type="link" class="delete" @click="deletCategory(scope.record)">
                    删除
                  </a-button>
                  <a-button type="link" @click="move(scope.record)">
                    移动到新分组
                  </a-button>
                </div>
              </template>
              <div class="more-handle">···</div>
            </a-popover>
          </div>
        </div>
      </template>
    </custom-table>
    <HandleModal ref="handleModal" :sort-num="groupList.length" v-on="$listeners" />
    <MoveModal ref="moveModal" :group-list="groupList" @submit="$emit('upFlowGroup')" />
  </div>
</template>

<script>
import Sortable from 'sortablejs'
import HandleModal from '../handleModall'
import MoveModal from '../moveModall'
import mixins from '@/components/CustomTable/index.js'
import { deleteFlowGroup, changeFlowGroupSort, getFlowGroupList } from '@/services/logicFlow'
export default {
  name: 'ApprovalCategory',
  components: {
    HandleModal,
    MoveModal,
    HeaderBack: () => import ('@/components/headerBack')
  },
  mixins: [mixins],
  props: {
    groupList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      columns: [{
        title: '分类名称',
        dataIndex: 'groupName',
        key: 'groupName',
        scopedSlots: { customRender: 'groupName' },
        ellipsis: true
      },
      {
        title: '分类中的表单数',
        dataIndex: 'num',
        key: 'num'
      },
      {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
        width: '300px'
      }],
      pagination: {
        pageSizeOptions: ['40', '50', '60', '70']
      },
      flowGroupId: '',
      dataSource: this.groupList
    }
  },
  mounted() {
    // 解决火狐浏览器默认拖拽打开新页面
    document.body.ondrop = function(event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.initSortable()
  },
  methods: {
    async getGroupList() {
      const res = await getFlowGroupList()
      this.groupList = res.data
    },
    getOrderList(oldIndex, newIndex, oldList) {
      const newList = []
      if (oldIndex < newIndex) {
        for (let i = 0; i < oldList.length; i++) {
          if (i < oldIndex || i > newIndex) {
            newList[i] = oldList[i]
          } else {
            if (i < newIndex) {
              newList[i] = oldList[i + 1]
            } else {
              newList[i] = oldList[oldIndex]
            }
          }
        }
      } else if (oldIndex > newIndex) {
        for (let i = 0; i < oldList.length; i++) {
          if (i > oldIndex || i < newIndex) {
            newList[i] = oldList[i]
          } else {
            if (i === newIndex) {
              newList[i] = oldList[oldIndex]
            } else if (i > newIndex) {
              newList[i] = oldList[i - 1]
            }
          }
        }
      } else {
        for (let i = 0; i < oldList.length; i++) {
          newList[i] = oldList[i]
        }
      }
      return newList
    },
    initSortable() {
      const mytb = document.querySelector('#mytb tbody')
      const self = this
      new Sortable(mytb, {
        handle: '.ant-table-row',
        animation: 150,
        sort: true,
        async onEnd({ oldIndex, newIndex }) {
          if (oldIndex === newIndex) return
          self.dataSource = self.getOrderList(oldIndex, newIndex, self.dataSource)
          const items = []
          self.dataSource.forEach((item, index) => {
            const obj = {
              flowGroupId: item.flowGroupId,
              sortNum: index + ''
            }
            items.push(obj)
          })
          const { code } = await changeFlowGroupSort({ items })
          if (code !== 100) return
          self.$message.success('更改排序成功')
        }
      })
    },
    addCategory() {
      this.$refs.handleModal.openModal({ handleType: 1 })
    },
    editCategory(data) {
      this.$refs.handleModal.openModal({ handleType: 0 }, data)
    },
    deletCategory(data) {
      this.flowGroupId = data.flowGroupId
      this.$Reconfirm({
        title: '删除分类',
        content: '是否确认删除此分类？'
      }).then(async() => {
        const { flowGroupId } = this
        const { code } = await deleteFlowGroup({ flowGroupId })
        if (code !== 100) return
        this.$message.success('删除成功')
        this.$emit('upFlowGroup')
      }).catch(() => {
        console.log('no')
      })
    },
    move(data) {
      this.$refs.moveModal.openModal(data)
    },
    getSearchCallback() {}
  }
}
</script>

<style lang="less" scoped>
.ApprovalCategory{
  height: 100%;
  background-color: #fff;
  padding:20px;
  .category-header{
    background: #ffffff;
    font-size: 16px;
    color: @sc-grey-100;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .header{
      margin-bottom: 0;
    }
  }
}
.more-handle-btn {
    display: flex;
    flex-direction: column;
    .ant-btn {
      height:25px;
    }
}
.more-handle {
  font-weight: 700;
  line-height: auto;
  font-size: 20px;
  vertical-align: baseline;
  color: @sc-primary-100;
}
.operation-btn-box{
  display:flex;
  button{
    padding-left:0;
    padding-right:16px;
  }
}
</style>
