<!--
 * @Date: 2022-04-27 15:13:31
 * @descriotion: 调整
-->
<template>
  <div class="adjustment beauty-scroll">
    <div v-if="!dragValue">
      <div class="adjustment-heard">
        <div class="adjustment-heard-left">
          <appForm>
            <template slot="customButton">
              <a-dropdown type="primary">
                <template #overlay>
                  <a-menu>
                    <a-menu-item v-for="(item, index) in singleList" :key="index" @click="openDrawer">{{ item.name }}</a-menu-item>
                  </a-menu>
                </template>
                <a-button prefix="add-one" suffix="left">
                  单个调整
                </a-button>
              </a-dropdown>
            </template>
            <template slot="customButton">
              <a-dropdown type="primary">
                <template #overlay>
                  <a-menu>
                    <a-menu-item v-for="(item, index) in functionList" :key="index" @click="openAll">{{ item.name }}</a-menu-item>
                  </a-menu>
                </template>
                <a-button prefix="add-one" suffix="left">
                  批量调整
                </a-button>
              </a-dropdown>
            </template>
          </appForm>
          <a-button prefix="reduce-one" :disabled="selectedRowKeys.length<1" @click="openDeleteBox">
            批量删除
          </a-button>
        </div>
        <div class="adjustment-heard-right">
          <appForm
            :to-list="formList"
            search-placeholder="请搜索姓名/工号"
            :screen-roster-list="screenBalanceList"
            @getSearchCallback="getSearchCallback"
            @screenRosterParams="screenRosterParams"
            @exportFileList="exportFileList"
          />
        </div>
      </div>
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        row-key="balanceDetailId"
        :pagination="{
          current: pager.pageNum,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条数据`,
          showQuickJumper: true,
          showSizeChanger: true
        }"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          columnWidth: 30,
          onChange: onSelectChange,
          getCheckboxProps: (record) => ({
            props: {
              disabled: record.effectStatus === 1,
            },
          }),
        }"
        :selection-mode="selectionMode"
        @change="loadDataList"
      >
        <!-- 序号 -->
        <template slot="index" slot-scope="{ scope }">
          <span>{{ scope.index + 1 }}</span>
        </template>
        <template slot="staffName" slot-scope="{ scope }">
          <CbTableAvater :staff-id="scope.record.staffId" :on-job-id="scope.record.onJobId" :table-name="scope.record.staffName" :title="scope.record.staffName" />
        </template>
        <!-- 调整数量 -->
        <template slot="amount" slot-scope="{ scope }">
          <div>{{ scope.record.amount + '小时' }}</div>
        </template>
        <!-- 生效状态 -->
        <template slot="effectStatus" slot-scope="{scope}">
          <div class="flex-align-center">
            <span v-if="scope.record.effectStatus == true" slot="reference" class="ellipsis">已生效</span>
            <span v-else slot="reference" class="ellipsis">未生效</span>
          </div>
        </template>
        <template slot="operation" slot-scope="{scope}">
          <a-button type="link" :disabled="scope.record.effectStatus===1" @click="edit(scope.record)">
            修改
          </a-button>
        </template>
      </custom-table>
      <Modal
        :closable="true"
        :mask-closable="false"
        :visible="adjustDrawer"
        :title="title"
        :size="'normal'"
        overflow-auto
        @cancel="onClose"
        @ok="onSubmit"
      >
        <k-form-build
          ref="KFB"
          :value="formType==='edit'?reviseList:singleIssue"
          :text-display="true"
          @change="change"
        />
      </Modal>
    </div>
    <ExcelImport v-else :title="`${text}假期`" :import-template="text === '批量发放'? 'vacation_adjustment_grant' :'vacation_adjustment_deduct'" @toggleValue="toggleValue" />
  </div>
</template>
<script>
import mixins from '@/components/CustomTable/index.js'
import moment from 'moment'
import { thousand } from '@/utils/reg.js'
import { getStaffVague } from '@/services/dropDownInput/index.js'
import { selectAdjustment, addVacationBalanceAdjustment, updateVacationBalanceAdjustment, getStaffVacationBalance, deleteVacationAdjustment, exportVacationAdjustment, getVacationType } from '@/services/attendance/holidays.js'
export default {
  components: {
    Modal: () => import('@/components/CbModal/index'),
    ExcelImport: () => import('../components/excelImport.vue'),
    CbTableAvater: () => import('@/components/CbTableAvater/index')
  },
  mixins: [mixins],
  data() {
    return {
      text: '',
      dragValue: false,
      form: {
        vacationTypeId: '',
        onJobId: ''
      },
      formType: '',
      singleList: [
        { name: '单个发放' },
        { name: '单个扣减' }
      ],
      functionList: [
        { name: '批量发放' },
        { name: '批量扣减' }
      ],
      theTime: moment().endOf('day').format('YYYY-MM-DD'),
      adjustDrawer: false,
      title: '',
      formList: ['search', 'export', 'screen'],
      dataSource: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'index' },
          key: 'index',
          fixed: 'left',
          align: 'center',
          width: '50px'
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          scopedSlots: { customRender: 'staffName' },
          key: 'staffName',
          fixed: 'left',
          width: '105px'
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          fixed: 'left',
          width: '100px',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          fixed: 'left',
          width: '100px',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          fixed: 'left',
          width: '100px',
          ellipsis: true
        },
        {
          title: '假期类型',
          dataIndex: 'vacationTypeName',
          scopedSlots: { customRender: 'vacationTypeName' },
          key: 'vacationTypeName',
          width: '140px',
          ellipsis: true,
          customCell: () => {
            return {
              style: {
                'min-width': '140px'
              }
            }
          }
        },
        {
          title: '调整数量',
          dataIndex: 'amount',
          scopedSlots: { customRender: 'amount' },
          key: 'amount',
          width: '150px',
          ellipsis: true,
          customCell: () => {
            return {
              style: {
                'min-width': '150px'
              }
            }
          }
        },
        {
          title: '生效时间',
          dataIndex: 'effectDate',
          key: 'effectDate',
          width: '150px',
          ellipsis: true,
          customCell: () => {
            return {
              style: {
                'min-width': '150px'
              }
            }
          }
        },
        {
          title: '生效状态',
          dataIndex: 'effectStatus',
          scopedSlots: { customRender: 'effectStatus' },
          key: 'effectStatus',
          width: '140px',
          ellipsis: true,
          customCell: () => {
            return {
              style: {
                'min-width': '140px'
              }
            }
          }
        },
        {
          title: '调整事由',
          dataIndex: 'changeNote',
          key: 'changeNote',
          width: '150px',
          ellipsis: true,
          customCell: () => {
            return {
              style: {
                'min-width': '150px'
              }
            }
          }
        },
        {
          title: '操作',
          dataIndex: 'operate',
          scopedSlots: { customRender: 'operation' },
          key: 'operate',
          width: '50px'
        }
      ],
      balanceDetailId: '',
      selectionMode: true,
      // 单个发放
      singleIssue: {
        'list': [
          {
            'type': 'selectStaff',
            'label': '员工姓名',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择员工姓名',
              'showSearch': false
            },
            'model': 'onJobId',
            'key': 'onJobId',
            'help': '',
            'rules': [
              {
                'required': true,
                'message': '请选择员工姓名'
              }
            ]
          },
          {
            'type': 'select',
            'label': '假期类型',
            'options': {
              'width': '100%',
              'multiple': false,
              'disabled': false,
              'clearable': false,
              'hidden': false,
              'placeholder': '请选择假期类型',
              'options': [],
              'showSearch': false
            },
            'model': 'vacationTypeId',
            'key': 'vacationTypeId',
            'help': '',
            'rules': [
              {
                'required': true,
                'message': '请选择假期类型'
              }
            ]
          },
          {
            'type': 'input',
            'label': '假期余额',
            'options': {
              'type': 'text',
              'width': '100%',
              'defaultValue': '',
              'placeholder': '--',
              'clearable': false,
              'maxLength': null,
              'addonBefore': '',
              'addonAfter': '',
              'hidden': false,
              'disabled': true,
              'suffix': '小时'
            },
            'model': 'balance',
            'key': 'balance',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'input',
            'label': this.text,
            'options': {
              'type': 'text',
              'width': '100%',
              'defaultValue': '',
              'placeholder': `请输入调整数量`,
              'clearable': false,
              'precision': '2',
              'hidden': false,
              'suffix': '小时',
              'disabled': false
            },
            'model': 'changeHours',
            'key': 'changeHours',
            'help': '',
            'rules': [
              {
                'required': true,
                'message': '请输入调整数量'
              },
              {
                'pattern': thousand,
                'message': '发放数量限制1000'
              }
            ]
          },
          {
            'type': 'date',
            'label': '生效日期',
            'options': {
              'width': '100%',
              'defaultValue': '',
              'rangeDefaultValue': [],
              'range': false,
              'showTime': false,
              'disabled': false,
              'hidden': false,
              'clearable': false,
              'placeholder': '请选择生效日期',
              'rangePlaceholder': [
                '开始时间',
                '结束时间'
              ],
              'format': 'YYYY-MM-DD'
            },
            'model': 'effectDate',
            'key': 'effectDate',
            'help': '',
            'rules': [
              {
                'required': true,
                'message': '请选择生效日期'
              }
            ]
          },
          {
            'type': 'textarea',
            'label': '调整事由',
            'options': {
              'width': '100%',
              'minRows': 4,
              'maxRows': 6,
              'maxLength': 300,
              'defaultValue': '',
              'clearable': false,
              'hidden': false,
              'disabled': false,
              'placeholder': '请输入调整事由'
            },
            'model': 'changeNote',
            'key': 'changeNote',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': '请输入调整事由'
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      // 修改假期
      reviseList: {
        'list': [
          {
            'type': 'input',
            'label': '员工姓名',
            'options': {
              'multiple': false,
              'disabled': true,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择员工',
              'showSearch': false
            },
            'model': 'staffName',
            'key': 'staffName',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'input',
            'label': '假期类型',
            'options': {
              'multiple': false,
              'disabled': true,
              'width': '100%',
              'defaultValue': '',
              'clearable': true,
              'showSearch': false
            },
            'model': 'vacationTypeName',
            'key': 'vacationTypeName',
            'rules': [
              {
                'required': true,
                'message': '请选择假期类型'
              }
            ]
          },
          {
            'type': 'input',
            'label': this.text,
            'options': {
              'type': 'text',
              'width': '100%',
              'defaultValue': '',
              'placeholder': '请输入调整数量',
              'clearable': false,
              'maxLength': 3,
              'precision': '2',
              'hidden': false,
              'disabled': false,
              'suffix': '小时'
            },
            'model': 'amount',
            'key': 'amount',
            'help': '',
            'rules': [
              {
                'required': true,
                'message': '请输入调整数量'
              }
            ]
          },
          {
            'type': 'date',
            'label': '生效日期',
            'options': {
              'width': '100%',
              'defaultValue': '',
              'rangeDefaultValue': [],
              'range': false,
              'showTime': false,
              'disabled': false,
              'hidden': false,
              'clearable': false,
              'placeholder': '请选择生效日期',
              'rangePlaceholder': [
                '开始时间',
                '结束时间'
              ],
              'format': 'YYYY-MM-DD'
            },
            'model': 'effectDate',
            'key': 'effectDate',
            'help': '',
            'rules': [
              {
                'required': true,
                'message': '请选择生效日期'
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      selectedRowKeys: [],
      // 筛选
      screenBalanceList: {
        'list': [
          {
            'type': 'selectDepartment',
            'label': '部门选择',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择部门',
              'showSearch': false
            },
            'model': 'orgName',
            'key': 'selectDepartment_1651888662945',
            'rules': [
              {
                'required': false,
                'message': '请选择部门'
              }
            ]
          },
          {
            'type': 'selectPost',
            'label': '岗位',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择岗位',
              'showSearch': false
            },
            'model': 'post',
            'key': 'selectPost_1651803307161',
            'rules': [
              {
                'required': false,
                'message': '请选择岗位'
              }
            ]
          },
          {
            'type': 'selectVacation',
            'label': '假期类型',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择假期类型',
              'showSearch': false
            },
            'model': 'vacationTypeId',
            'key': 'vacationTypeId',
            'rules': [
              {
                'required': false,
                'message': '请选择假期类型'
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      }
    }
  },
  created() {
    this.getVacationType()
  },
  methods: {
    // 获取假期类型Id
    getVacationType() {
      getVacationType().then(res => {
        const TXJ = res.data.filter(item => item.vacationTypeName === '调休假')
        const NJ = res.data.filter(item => item.vacationTypeName === '年假')
        const dictList = []
        if (TXJ.length > 0) {
          dictList.push({
            value: TXJ[0].vacationTypeId,
            label: '调休假'
          })
        }
        if (NJ.length > 0) {
          dictList.push({
            value: NJ[0].vacationTypeId,
            label: '年假'
          })
        }
        this.singleIssue.list[1].options.options = dictList
      })
    },
    // 获取假期余额
    change(value, key) {
      this.form[key] = value
      if (key === 'onJobId' || key === 'vacationTypeId') {
        if (this.form.vacationTypeId && this.form.onJobId) {
          getStaffVacationBalance({ onJobId: this.form.onJobId, vacationTypeId: this.form.vacationTypeId }).then(res => {
          // console.log(res)
            this.singleIssue.list.forEach(item => {
              if (item.model === 'balance') {
                item.options.defaultValue = res.data
              }
            })
          })
        }
        if (this.form.onJobId) {
          getStaffVague({ onJobId: this.form.onJobId }).then(res => {
            console.log(res)
            res.data.forEach(item => {
              if (item.onJobId === value) {
                this.form.staffId = item.staffId
                this.form.onJobId = item.onJobId
              }
            })
          })
        }
      }
    },
    // 批量弹窗
    toggleValue(val) {
      this.dragValue = false
      this.fetchDataApiFn(this.pager)
    },
    // 获取数据
    async fetchDataApiFn(param) {
      const res = await selectAdjustment({ ...param })
      this.dataSource = res.data.records ? res.data.records : []
      this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
      this.total = res.data.total
    },
    // 导出功能
    exportFileList() {
      const { searchWord, orgIds, postIds, vacationtypeId } = this._queryParam
      exportVacationAdjustment({
        searchWord: searchWord,
        orgIds: orgIds,
        postIds: postIds,
        vacationtypeId: vacationtypeId
      })
    },
    // 搜索
    getSearchCallback(data) {
      this._queryParam.searchWord = data.searchValue
      this.serchGetData()
    },
    // 筛选回调
    screenRosterParams(data) {
      this._queryParam.searchWord = data.searchValue
      const orgIds = []
      if (data.screenCondition.orgName !== undefined) {
        data.screenCondition.orgName.forEach(item => {
          orgIds.push(item.value)
        })
        this._queryParam.orgIds = orgIds.join(',')
      }
      if (data.screenCondition.post !== undefined) {
        this._queryParam.postIds = data.screenCondition.post.join(',')
      }
      this._queryParam.vacationTypeId = data.screenCondition.vacationTypeId ? data.screenCondition.vacationTypeId : ''
      for (var key in this._queryParam) {
        if (!this._queryParam[key]) {
          delete this._queryParam[key]
        }
      }
      this.serchGetData()
    },
    // 点击叉号和遮罩层的回调
    onClose() {
      this.adjustDrawer = false
      this.$refs.KFB.reset()
      this.form = {}
    },
    // 单个调整
    openDrawer() {
      this.adjustDrawer = true
      this.formType = 'add'
      this.title = event.currentTarget.innerText
      this.singleIssue.list[3].label = this.title.slice(-2) + '数量'
      this.singleIssue.list[1].options.defaultValue = undefined
      this.singleIssue.list[2].options.defaultValue = ''
      this.getVacationType()
    },
    // 批量调整
    openAll() {
      this.dragValue = true
      if (event.currentTarget.innerText === '批量发放') {
        this.text = '批量发放'
      } else {
        this.text = '批量扣减'
      }
    },
    // 修改假期
    edit(value) {
      this.title = '编辑假期'
      this.formType = 'edit'
      this.adjustDrawer = true
      const data = JSON.parse(JSON.stringify(value))
      if (data.balanceChangeType === 'G') {
        this.reviseList.list[2].label = '发放数量'
      } else if (data.balanceChangeType === 'D') {
        this.reviseList.list[2].label = '扣减数量'
        data.amount = data.amount.toString().slice(1)
      }
      this.balanceDetailId = data.balanceDetailId
      this.reviseList.list.forEach(item => {
        const val = item.model
        if (item.model === val) {
          item.options.defaultValue = data[val]
        }
      })
    },
    // 确定按钮
    async onSubmit() {
      const params = await this.$refs.KFB.getData() // 获取表单参数
      // console.log(params)
      if (this.title === '单个发放') {
        params.changeMinutes = params.changeHours * 60
        addVacationBalanceAdjustment({ ...params, adjustType: 'grant', balanceChangeType: 'G', ...this.form }).then(res => {
          if (res.code === 100) {
            this.$message.success('调整成功')
            this.serchGetData()
          }
        })
      } else if (this.title === '单个扣减') {
        params.changeMinutes = params.changeHours * 60
        addVacationBalanceAdjustment({ ...params, adjustType: 'deduct', balanceChangeType: 'D', ...this.form }).then(res => {
          if (res.code === 100) {
            this.$message.success('调整成功')
            this.serchGetData()
          }
        })
      } else {
        // 提交修改表单
        updateVacationBalanceAdjustment({ effectDate: params.effectDate, changeHours: params.amount, balanceDetailId: this.balanceDetailId	}).then(res => {
          if (res.code === 100) {
            this.$message.success('修改成功')
            this.serchGetData()
          }
        })
      }
      this.onClose()
    },
    // 表格选择项变更
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    openDeleteBox() {
      this.$Reconfirm({
        title: '删除调整记录',
        content: '确定要删除该假期调整记录吗?'
      }).then(() => {
        deleteVacationAdjustment({ balanceDetailId: this.selectedRowKeys }).then(res => {
          if (res.code === 100) {
            this.$message.success('删除成功')
            this.serchGetData()
          }
        })
      }).catch(() => {
        console.log('no')
      })
    }
  }
}
</script>
<style lang="less" scoped>
.adjustment{
  width: 100%;
  height:100%;
  overflow: auto;
  padding: 20px 20px 0 ;
  background-color: @base-bg-color;
}
.adjustment-heard {
  display: flex;
  justify-content: space-between;
  &-left,
  &-right {
    display: flex;
  }
}
.adjustment-heard-left .ant-btn{
  margin-right: 12px;
}
.ellipsis{
  width:100%;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis
}
</style>
