<template>
  <div class="container beauty-scroll">
    <div class="unit">
      <a-radio-group v-model="title">
        <a-radio-button value="1">
          <!-- <a-icon type="calendar" /> -->
          <svg class="iconpark-icon"><use href="#rongqi" /></svg>
        </a-radio-button>
        <a-radio-button value="2">
          <!-- <a-icon type="unordered-list" /> -->
          <svg class="iconpark-icon"><use href="#list-middle" /></svg>
        </a-radio-button>
      </a-radio-group>
    </div>
    <Calendar v-if="title==='1'" ref="calendar" class="calender-box" :start-date="calendarStartDate" :end-date="calendarEndDate" @onChange="changeTime">
      <!-- <template slot="leftContent">左边插槽</template> -->
      <template #rightContent />
      <template slot="bottomContent">
        <div class="content-btn" @click="getChirldNode($event,'')">
          <div :class="active===''?'active':''" @click="tabs()">全部 {{ vacationTypeList.allNum || 0 }} 人</div>
          <div v-for="(item,index) in vacationTypeList.vacationData" :key="index" :class="active ===item.vacationTypeId ? 'active':'' " @click="tabs(item.vacationTypeId)">
            <span :style="'backgroundColor:rgba('+item.fontRgba+')'" class="text-color" />
            <span>{{ item.vacationTypeName }} · {{ item.num || 0 }} 人</span>
          </div>
        </div>
      </template>
      <template v-for="item in calendarList" :slot="item.calendarDate" style="width: 100%;position: relative;">
        <div :key="item.calendarDate" class="events" :class="item.vacation.length > 3 ? 'events-active':''">
          <div v-for="col in item.vacation" :key="col.vacationTypeId">
            <a-popover placement="bottom" trigger="click">
              <template slot="title">
                <div class="header-box">
                  <div class="color_box" :style="`background:rgba(${col.fontRgba})`" />
                  <div class="title">
                    {{ col.vacationTypeName }}
                  </div>
                </div>
              </template>
              <template slot="content">
                <div class="content-box beauty-scroll">
                  <div v-for="events in col.staffApplyVacation" :key="events.staffId" class="contentDetail">
                    <div class="personnel-information">
                      <div class="img"><CbTableAvater :show-user-name="false" :avater-name="events.staffName" :on-job-id="events.onJobId" :staff-id="events.staffId" size="40" /></div>
                      <div class="Information">
                        <span>{{ events.staffName }}</span>
                        <span>{{ events.orgName }}/{{ events.postName }}</span>
                      </div>
                    </div>
                    <div class="date">
                      <span class="title">开始时间</span>{{ events.startDate | invert }} {{ events.vacationTypeUnit === 'H'? events.startTime:'' }}
                    </div>
                    <div class="date">
                      <span class="title">结束时间</span>{{ events.endDate | invert }} {{ events.vacationTypeUnit === 'H' ? events.endTime:'' }}
                    </div>
                    <div class="date">
                      <span class="duration">时长</span> {{ events.vacationTypeUnit === 'D' ?events.workDays+'天' : events.workHours+'小时' }}
                    </div>
                  </div>
                </div>
              </template>
              <div class="badge" :style="`background:rgba(${col.backgroundRgba})`">
                <div class="color_box" :style="`background:rgba(${col.fontRgba})`" />
                <p class="literals" :title="`${col.vacationTypeName }${col.vacationNum }人`">{{ col.vacationTypeName }} {{ col.vacationNum }}人</p>
              </div>
            </a-popover>
          </div>
        </div>
      </template>
    </Calendar>

    <Leave v-if="title==='2'" />
  </div>
</template>
<script>
import { vacationcalendar, vacationCalendarCollect } from '@/services/attendance/holidays.js'
import moment from 'moment'
import { replaceTime } from '@/utils/reg.js'
export default {
  name: 'OverView',
  components: {
    Calendar: () => import('@/components/CbCalendar'),
    CbTableAvater: () => import ('@/components/CbAvater/theAvater.vue'),
    Leave: () => import('./leave.vue')
  },
  filters: {
    invert(val) {
      return val.replace(replaceTime, '$1-$2-$3')
    }
  },
  data() {
    return {
      title: '1',
      active: '',
      yearMonth: new Date(),
      vacationTypeList: {},
      calendarList: [],
      calendarStartDate: '',
      calendarEndDate: ''
    }
  },
  created() {

  },
  mounted() {
    this.getVacation()
    this.getVacationList()
  },

  methods: {
    changeTime(value) {
      this.yearMonth = value
      this.active = ''
      this.getVacationList()
      this.getVacation()
    },
    async getVacation() {
      const res = await vacationCalendarCollect({ yearMonth: moment(this.yearMonth).format('YYYYMM') })
      if (res.code !== 100) return
      this.vacationTypeList = res.data
    },
    //
    async getVacationList() {
      const year = this.yearMonth.getFullYear()
      const month = this.yearMonth.getMonth()
      console.log('asdas', year, month)
      const startDate = new Date(year, month, 1)
      const endDate = new Date(year, month + 1, 0)
      vacationcalendar({ yearMonth: moment(this.yearMonth).format('YYYYMM'), vacationTypeId: this.active }).then(res => {
        this.calendarStartDate = res.data.calendarStartDate ? moment(res.data.calendarStartDate).format('YYYY-MM-DD') : moment(startDate).format('YYYY-MM-DD')
        this.calendarEndDate = res.data.calendarEndDate ? moment(res.data.calendarEndDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD')
        this.$nextTick(() => {
          this.$set(this, 'calendarList', res.data.calendar)
        })
      }).catch(err => {
        console.log(err)
        this.calendarStartDate = moment(startDate).format('YYYY-MM-DD')
        this.calendarEndDate = moment(endDate).format('YYYY-MM-DD')
      })
    },
    getChirldNode() {

    },
    tabs(id) {
      console.log(id)
      this.active = id || ''
      this.getVacation()
      this.getVacationList()
    }
  }
}
</script>
<style lang="less" scoped>
.content-box{
  height:auto;
  max-height:400px;
  margin-right:-16px;
  padding-right:16px;
  overflow: auto;
}
.header-box{
  height: 100%;
  padding:20px 0 15px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
}
  .container{
    position: relative;
    width: 100%;
    min-height: calc(100vh - 48px);
    overflow: auto;
    position: relative;
    background-color: #fff;
    .calender-box {
      width: 100%;
      padding: 20px 20px;
      box-sizing: border-box;
    }
    .content-btn {
      width: 100%;
      display: flex;
      overflow: auto;
      align-items: center;
      margin: 16px 0;
      div{
        cursor: pointer;
        margin-right:70px;
        font-size: 14px;
      }
      .active {
        font-size: 16px;
        color: @sc-primary-100;
      }
      .text-color {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 10px;
      }
    }
  }
.events {
  position: absolute;
  padding:0 20px;
  width:100%;
  height:120px;
  overflow: hidden;
}

.events-active{
  &:hover{
    height:auto;
    z-index:999;
    box-shadow: 0px 10px 13px 1px rgba(54, 67, 99, 0.09);
    border-radius: 2px;
  }
}
.color_box{
    width:14px;
    height:14px;
    border-radius: 2px;
    margin-right:10px;
  }
.badge{
  position: relative;
  box-sizing: border-box;
  width:100%;
  height:30px;
  display: flex;
  align-items: center;
  padding:0 20px;
  margin-bottom: 10px;
  border-radius: 4px;
  &:hover{
    &::after{
    content: '';
    position:absolute;
    top:0;
    left:0;
    display: block;
    width:100%;
    height:100%;
    background: rgba(255,255,255,0.3);
  }
  }

  .literals{
    flex:1;
    margin:0;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    font-size:14px;
    border-radius: 2px;
    text-align: left
  }
}
.title{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.contentDetail{
  width: 271px;
  height: auto;
  padding-bottom:16px;
  .personnel-information{
    width: 100%;
    height: 58px;
    padding: 12px 10px;
    background-color: @sc-greyBg-10;
    border-radius: 4px;
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    .img{
      width: 42px;
      height: 42px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
    }
    .Information{
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      align-content:space-evenly;
      span{
        width: 100%;
        font-size: 14px;
        &:nth-child(1){
          color: @sc-grey-80
        }
        &:nth-child(2){
          color: @sc-grey-40
        }
      }
    }
  }
  .date{
    width: 100%;
    height: 22px;
    color: @sc-grey-80;
    margin-bottom: 20px;
    .title{
      height: 22px;
      margin-right: 14px;
      color: @sc-grey-40;
    }
    .duration{
      height: 22px;
      margin-right: 44px;
      color: @sc-grey-40;
    }
  }
}
// 取消点击按钮出现光晕效果
button[ant-click-animating-without-extra-node]:after {
  border: 0 none;
  opacity: 0;
  animation:none 0 ease 0 1 normal;
}
.unit{
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 8;
  .iconpark-icon{
    width: 14px;
    height: 14px;
    color: @sc-grey-80;
    margin-top: 8px;
  }
  /deep/.ant-radio-button-wrapper-checked .iconpark-icon{
    color: @sc-primary-100;
  }
}
/deep/.calendar{
  position: relative;
}
/deep/.ant-btn-icon-only{
  margin-right: 115px;
}
.ant-popover-inner-content{
  max-height: 400px;
  overflow: auto;
}
</style>
