<template>
  <div class="index-container">
    <div class="index-conent">
      <div class="conetnt-left">
        <!-- 数字统计 -->
        <EmployeeSituation ref="situation" />
        <div class="company-affair">
          <!-- 审批 -->
          <CompanyAffair />
          <!-- 功能事项 -->
          <ScheduleList />
        </div>
      </div>
      <div class="conetnt-right">
        <!-- 工具入口 -->
        <ToolInlet />
        <!-- 待办日历 -->
        <ToDoCalendar />
        <!-- 广告栏 -->
        <!-- <AdvertisingColumn /> -->
      </div>
    </div>
    <TenantModal ref="TenantModal" />
  </div>
</template>

<script>
import { getLastMonthSalary } from '@/services/wage/home'
export default {
  name: 'Home',
  provide() {
    return {
      costObj: {}
    }
  },
  components: {
    CompanyAffair: () => import('./CompanyAffair'),
    EmployeeSituation: () => import('./EmployeeSituation'),
    ScheduleList: () => import('./ScheduleList'),
    ToolInlet: () => import('./ToolInlet/index.vue'),
    ToDoCalendar: () => import('./ToDoCalendar/index.vue'),
    TenantModal: () => import('./TenantModal/index.vue')
    // AdvertisingColumn: () => import('./AdvertisingColumn/index.vue')
  },
  created() {
    this.getCostObj()
  },
  methods: {
    async getCostObj() {
      const res = await getLastMonthSalary()
      const that = this
      this._provided.costObj = res.data
      that.$refs.situation.averageCostOfLastMonth = res.data ? res.data.laborCostSumCountByPerson : ''
    }
  }
}
</script>

<style lang="less">
@divisionSpace: 20px;
.index-container {
  width: calc(100vw - 66PX);
  .index-conent {
    width:100%;
    background: #F8F9FB;
    display: flex;
    padding: @divisionSpace;
    .conetnt-left {
      width:67.7%;
      margin-right: @divisionSpace;
      .company-affair {
        padding-top: @divisionSpace;
        display: flex;
      }
    }
    .conetnt-right {
      width: 31.1%;

    }
  }
}
</style>
