<template>
  <div>
    <!-- 配置详情数据循环 -->
    <div v-for="detail in form.detailList" :key="detail.salDetailValue">
      <div v-if="form.detailList.length>1" class="formula-header flex-align-center">
        <div class="title">{{ detail.salDetailName }}</div>
        <a-switch v-model="detail.enabled" size="small" />
      </div>
      <div v-if="detail.enabled==1" class="formula-content">
        <!-- 计算公式 -->
        <!-- 选择计算公式options 获取当前formulaIndex 存入数组对象中  当数据显示标识使用 -->
        <a-form-model-item label="计算公式">
          <a-select v-model="detail.formulaValue" class="line-input" @change="detailChange(detail)">
            <a-select-option v-for="(item,i) in detail.formulaList" :key="item.formulaValue" @click="detail.formulaIndex = i">{{ item.formulaContent }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <div v-for="formula in detail.formulaList" :key="formula.formulaValue">
          <!-- 配置项 -->
          <template v-if="formula.formulaValue === detail.formulaValue">
            <div v-for="config in formula.configItemList" :key="config.configItemNo">
              <!--configType：1 固定值--输入框 -->
              <div v-if="config.configType==='1'">
                <a-form-model-item :label="`${config.configItemName}:`">
                  <Percentage v-if="config.unitType==='%'" v-model="config.configValue" :decimal-separator="true" :max="config.unitType==='%'&&form.salaryItemNo!=='KQ02'? 100: 999999999" class="line-input" />
                  <CbNumber v-else v-model="config.configValue" :decimal-separator="true" class="line-input" :suffix="config.unitType" />
                </a-form-model-item>
              </div>
              <!--configType：2 分段配置--起始值 分段 configType：3 阶梯配置-- 分段 -->
              <div v-else-if="['2','3'].includes( config.configType)">
                <a-form-model-item :label="`${config.configItemName}:`">
                  <div class="set-rules">
                    <div v-for="(it,index) in config.paraList" :key="index">
                      <CbTimePicker v-if="config.configItemNo === 'wxbbtbz'" v-model="it.startValue" class="x-margin-12 margin-left-none" :options="timeSetUp" />
                      <CbNumber v-else v-model="it.startValue" class="small-input margin-left-none" />
                      &lt;<span class="range-text">{{ config.indexItem.indexItemName }}</span>≤
                      <CbTimePicker v-if="config.configItemNo === 'wxbbtbz'" v-model="it.endValue" class="x-margin-12" :options="timeSetUp" />
                      <CbNumber v-else v-model="it.endValue" class="small-input" />{{ config.configItemName }}
                      <Percentage v-if="config.unitType==='%'" v-model="it.configValue" :decimal-separator="true" :max="100" class="number-input" />
                      <CbNumber v-else v-model="it.configValue" :decimal-separator="true" class="number-input" :suffix="config.unitType" />
                      <a-button v-if="config.paraList.length > 1" type="iconBtn" icon="close-small" class="close-circle" @click="deleteParaList(config,index)" />
                    </div>
                    <div class="config-list-btn flex-align-center">
                      <a-button type="iconBtn" icon="add-one" class="plus-circle" @click="config.paraList.push({})" />
                    </div>
                  </div>
                </a-form-model-item>
              </div>
              <!--configType：4 分类配置--分类+固定值 分段 -->
              <div v-else-if="config.configType==='4'">
                <a-form-model-item :label="`${config.configItemName}:`">
                  <div class="set-rules">
                    <div v-for="(it,index) in config.paraList" :key="index">
                      {{ config.indexItem.indexItemName }}
                      <!-- indexDataType:L 选择类型 -->
                      <template v-if="config.indexItem.indexDataType==='L'">
                        <DictionariesInput v-model="it.fixedValue" class="number-input" :parameter="config.indexItem.indexCode" :placeholder="`请选择${config.indexItem.indexItemName}`" />
                      </template>
                      <!-- indexDataType:N 数字输入类型 -->
                      <template v-else-if="config.indexItem.indexDataType==='N'">
                        <CbNumber v-model="it.fixedValue" :decimal-separator="true" class="number-input" :suffix="config.unitType" />
                      </template>
                      <!-- indexDataType:C 字符输入类型 -->
                      <template v-else-if="config.indexItem.indexDataType==='C'">
                        <a-input v-model="it.fixedValue" class="number-input" />
                      </template>
                      {{ config.configItemName }}
                      <CbNumber v-model="it.configValue" class="number-input" :decimal-separator="true" :suffix="config.unitType" />
                      <a-button v-if="config.paraList.length > 1" type="iconBtn" icon="close-small" class="close-circle" @click="deleteParaList(config,index)" />
                    </div>
                    <div class="config-list-btn flex-align-center">
                      <a-button type="iconBtn" icon="add-one" @click="config.paraList.push({})" />
                    </div>
                  </div>
                </a-form-model-item>
              </div>
              <!--configType：5 下拉选择-->
              <div v-else-if="config.configType==='5'">
                <a-form-model-item :label="`${config.configItemName}:`">
                  <!-- <a-select v-model="config.configListValue" class="line-input">
                    <a-select-option v-for="it in config.configOption" :key="it.codeKey">{{ it.content }}</a-select-option>
                  </a-select> -->
                  <DictionariesInput v-model="config.configListValue" class="line-input" :parameter="config.configListCode" />
                  <!-- config.configItemNo:jxjs 选择类型为计薪基数 -->
                  <template v-if="config.configItemNo==='jxjs'&&config.configListValue==='3'">
                    <CbNumber v-model="config.configValue" :decimal-separator="true" class="number-input" suffix="元" />
                  </template>
                  <!-- config.configItemNo:zsbz 选择类型为折算标准 -->
                  <template v-if="config.configItemNo==='zsbz'">
                    <CbNumber v-if="config.configListValue==='5'" v-model="config.configListValueAdd[1]" max="31" :decimal-separator="true" :is-zero="false" class="number-input margin-right-none" suffix="天" />
                  </template>
                </a-form-model-item>
              </div>
            </div>
          </template>
          <!-- 公式说明 -->
          <template v-if="formula.formulaValue === detail.formulaValue">
            <a-form-model-item label="公式说明">
              {{ formula.formulaDesc }}
              <a-button prefix="bendijisuan" class="plus-circle x-margin-12" @click="formulaBudget(detail,formula)">公式测算</a-button>
            </a-form-model-item>
          </template>
        </div>
        <!-- 个性化 needPersonalDeduct:1 -->
        <div v-if="detail.needPersonalDeduct&&detail.formulaValue">
          <a-form-model-item v-if="form.salaryItemNo ==='KQ01'||form.salaryItemNo ==='KQ04'&&!['C','D'].includes(detail.formulaValue)" label="个性化">
            <!-- 请假计薪 -->
            <template v-if="form.salaryItemNo ==='KQ01'">
              每月累计{{ detail.salDetailName }}
              <CbNumber v-model="detail.personalDeduct[0]" :decimal-separator="true" class="number-input" suffix="小时" />
              不扣款
            </template>
            <!-- 缺勤计薪 迟到&&早退 -->
            <template v-if="form.salaryItemNo ==='KQ04'&&['1','2'].includes(detail.salDetailValue)">
              <!-- 按月累计 分钟&&次数 扣款 -->
              <div v-if="['E','H'].includes(detail.formulaValue)">
                每月累计{{ detail.salDetailName }}
                <CbNumber v-model="detail.personalDeduct[0]" :decimal-separator="true" class="number-input" :suffix="`${detail.formulaValue==='E' ? '分钟' : '次'}`" />
                不扣款
                <span v-if="detail.formulaValue==='E'">，如时长超出则按超出时长扣款</span>
              </div>
              <!-- 按单次迟到分钟组合扣款 && 按月累计迟到 分钟&&次数 组合扣款 -->
              <div v-else-if="['A','B','F','G','I','J'].includes(detail.formulaValue)">
                每月累计{{ detail.salDetailName }}
                <CbNumber v-model="detail.personalDeduct[0]" :decimal-separator="true" class="number-input" suffix="次" />
                ，且每次迟到分钟数在
                <CbNumber v-model="detail.personalDeduct[1]" :decimal-separator="true" class="number-input" suffix="分钟" />
                内不扣款（按迟到先后统计）
              </div>
            </template>
          </a-form-model-item>
        </div>
      </div>
    </div>
    <FormulaBudget ref="formulaBudget" />
  </div>
</template>

<script>
export default {
  components: {
    DictionariesInput: () => import('@/components/CbDropDownInput/dictionariesInput/index.vue'),
    CbNumber: () => import('@/components/CbNumber/index.vue'),
    Percentage: () => import('@/components/CbNumber/percentage.vue'),
    CbTimePicker: () => import('@/components/CbTimePicker/index.vue'),
    FormulaBudget: () => import('./components/formulaBudget.vue')
  },
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      timeSetUp: {
        format: 'HH:mm',
        valueFormat: 'HHmm',
        placeholder: '时间',
        clearable: false,
        width: '115px'
      }
    }
  },
  computed: {
    isBudget() {
      return false
    }
  },
  methods: {
    deleteParaList(config, index) {
      config.paraList.splice(index, 1)
    },
    // 公式测算
    formulaBudget(detail, formula) {
      this.$refs.formulaBudget.open({ formulaNo: detail.formulaNo, formulaValue: detail.formulaValue, configItemList: formula.configItemList, personalDeduct: detail.personalDeduct })
    },
    detailChange(detail) {
      console.log(detail)
      detail.personalDeduct = []
    }
  }
}
</script>
<style lang="less" scoped>
.formula-header{
  margin-bottom:24px;
    .title {
        position: relative;
        padding-left: 10px;
        .font-size(16px);
        font-weight: bold;
        color: @sc-grey-100;
        margin:0 20px 0 0;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 4px;
          height: 17px;
          background-color: @sc-primary-100;
        }
    }
}
.small-input {
  width:90px;
  margin: 0 12px;
  display: inline-block;
}
.number-input {
  width: 115px;
  margin: 0 12px;
  display: inline-block;
}
.line-input{
  width: 320px;
  display: inline-block;
}
.x-margin-12{
  margin:0 12px;
}
.margin-left-none {
  margin-left: 0px;
}
.margin-right-none {
  margin-right: 0px;
}
.set-rules {
  width: 100%;
  background: @layout-body-background;
  padding: 4px 16px 14px 16px;
  line-height: 52px;
  .range-text{
    margin: 0 12px;
  }
  .config-list-btn{
    margin-top: 10px;
    /deep/.ant-btn {
      margin-right: 12px;
    }
  }
}
.close-circle{
  &:hover{
    /deep/.iconpark-icon{
    color: @error-color;
    }
    border-color: @error-color;
  }
}
</style>
