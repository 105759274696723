<!--
 * @Date: 2022-07-04 14:18:16
 * @descriotion:账单
-->
<template>
  <div class="bill">
    <div v-show="!showDetail&&!showBill&&!declarationShow&&!excelImport" class="content">
      <!-- 月份选择 -->
      <div class="flex-between-center head">
        <div class="btn-box">
          <a-button :disabled="tenantBillStatus ===1" type="primary" prefix="add" @click="addBillModel">
            创建账单
          </a-button>
          <a-button class="ant-button" prefix="lock" :disabled="tenantBillStatus===null" @click="lock">
            {{ tenantBillStatus === 1 ? '解除锁定' : '锁定' }}
          </a-button>
          <a-button v-show="tenantBillStatus !== 1" class="ant-button" prefix="shanchu" @click="delBill">
            删除账单
          </a-button>
        </div>
        <div />
        <MonthPicker v-model="month" />
        <div />
      </div>
      <div class="beauty-scroll center-content">
        <!-- list -->
        <div class="bill-head">
          <ul class="tenantBill">
            <li v-for="(item, index) in monthTenantBill" :key="index" class="detail" :class="{'first':index===0}">
              <div class="bill-item">
                <p>{{ item.value | stateFormatNum }}</p>
                <p>{{ item.label }}{{ item.label !== '缴纳人数' ? `(${item.unit})` : '' }}</p>
              </div>
            </li>
            <li class="detail lock-btn">

              <a-dropdown placement="bottomLeft">
                <a-button prefix="daochu" suffix="left">导出</a-button>
                <a-menu slot="overlay">
                  <a-menu-item @click="exportSummary">
                    导出汇总
                  </a-menu-item>
                  <a-menu-item @click="exportBill">
                    导出明细
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
              <a-button class="ant-button" prefix="daoru" @click="importParams.insureCompanyId = '';excelImport = true">
                导入
              </a-button>
              <a-button class="ant-button" prefix="chakan-chakanmingxi" @click="showBill=true">
                查看明细
              </a-button>
            </li>
          </ul>
          <div v-show="insureClassifyList.length>0" class="tenantDetail">
            <ul class="detail-head head-border">
              <li v-for="(item,index) in monthBillDetail" :key="index">{{ item.label }}</li>
            </ul>
            <template v-for="(item,index) in insureClassifyList">
              <ul :key="index" class="detail-head">
                <li :span="3">{{ item.insureClassifyName }}</li>
                <li :span="3">{{ item.increaseCount }}</li>
                <li :span="3">{{ item.decreasesCount }}</li>
                <li :span="3">{{ item.personCount }}</li>
                <li :span="3">{{ item.supplyCount }}</li>
                <li :span="3">{{ item.companyTotal | stateFormatNum }}</li>
                <li :span="3">{{ item.personTotal | stateFormatNum }}</li>
                <li :span="3">{{ item.total | stateFormatNum }}</li>
              </ul>
            </template>
          </div>
        </div>
        <div class="bill_box">
          <!-- 账单列表 -->
          <BillList ref="billList" :list="billList" :month="month" @lookBill="lookBill" @voucher="voucher" @importBill="importBill" />
        </div>
      </div>
      <!-- 创建账单 -->
      <AddBill ref="addbill" :month="month" @change="createBill" />
      <!-- 底部 -->
      <div class="footer">
        <a-button type="link" @click="exportNotification">
          一键导出官方申报文件
        </a-button>
        <a-button type="link" @click="quickLogin">
          官方系统快捷登录
        </a-button>
        <!--        <a-button type="link" @click="getDeclaration">-->
        <!--          机器人智能申报-->
        <!--        </a-button>-->
      </div>
    </div>
    <!-- 汇总查看明细 -->
    <div v-if="showBill">
      <Table ref="billTable" :month="month" @back="showBill=false" />
    </div>
    <!-- 查看主体明细 -->
    <div v-if="showDetail">
      <ViewBill v-if="detailFlag" ref="viewBill" :bill-param="billParam" @back="back()" />
      <!-- 申报凭证 -->
      <Voucher v-else @back="back()" />
    </div>
    <!-- 机器人智能申报 -->
    <div v-if="declarationShow">
      <SmartDeclaration @back="declarationBack" />
    </div>
    <a-modal v-model="delVisible" title="删除账单" ok-text="确认" @ok="delConfirm">
      <p>确定要删除账单？</p>
    </a-modal>

    <CbDrawer
      :closable="true"
      :is-mask="true"
      :visible="urlVisible"
      title="官方系统快捷登录"
      size="normal"
      :overflow-auto="true"
      :display-btn="true"
      :show-cancel="false"
      :show-confirm="false"
      @close="urlVisible = false"
    >
      <div class="contentLogin">
        <div v-for="(item,index) in quickList" :key="index" class="commonpy">
          <a-row class="title">{{ item.companyName }}</a-row>
          <a-row>
            <a-col :span="6">社保登录地址</a-col>
            <a-col :span="18" class="loginUrl" @click="goLogin(item.socialUrl)">{{ item.socialUrl || '-' }}</a-col>
          </a-row>
          <a-row>
            <a-col :span="6">医保登录地址</a-col>
            <a-col :span="18" class="loginUrl" @click="goLogin(item.medicalUrl)">{{ item.medicalUrl || '-' }}</a-col>
          </a-row>
          <a-row>
            <a-col :span="6">公积金登录地址</a-col>
            <a-col :span="18" class="loginUrl" @click="goLogin(item.housingUrl)">{{ item.housingUrl || '-' }}</a-col>
          </a-row>
        </div>
      </div>
    </CbDrawer>
    <ExcelImport v-if="excelImport" title="导入账单" :bill-param="importParams" import-template="socialSecurityBill" @goBack="excelImport=false" />
  </div>
</template>
<script>
import moment from 'moment'
import { stateFormatNum } from '@/utils/num-handle.js'
import { getCompanyBillListApi,
  getMonthTenantBillApi,
  updateStatusApi,
  createBillApi,
  deleteBillApi,
  exportInsureBillApi,
  exportNotificationDocumentApi,
  exportInsureBillCollectApi,
  getSystemUrlApi
} from '@/services/insurance/bill'
import { getTenantBillApi } from '@/services/insurance/supplementary.js'
import { monthBillDetail } from './dict'
export default {
  name: 'Bill',
  filters: {
    stateFormatNum
  },
  components: {
    MonthPicker: () => import('./component/monthPicker.vue'),
    BillList: () => import('./billList.vue'),
    Table: () => import('./table.vue'),
    AddBill: () => import('./component/addBill.vue'),
    ViewBill: () => import('./viewBill/index.vue'),
    CbDrawer: () => import('@/components/CbDrawer/index'),
    SmartDeclaration: () => import('./smartDeclaration/index.vue'),
    Voucher: () => import('./voucher/index'),
    ExcelImport: () => import('@/components/CbBatchImport/newImport.vue')
  },
  data() {
    return {
      urlVisible: false,
      quickList: [],
      monthBillDetail: monthBillDetail,
      month: moment().format('YYYY-MM'),
      tabs: '1',
      showDetail: false,
      detailFlag: false,
      declarationShow: false,
      billList: [],
      monthTenantBill: [
        {
          label: '缴费总金额',
          value: '-',
          unit: '元'
        },
        {
          label: '单位缴费合计',
          value: '-',
          unit: '元'
        },
        {
          label: '个人缴费合计 ',
          value: '-',
          unit: '元'
        }
      ], // 账单顶部统计
      isLock: false, // 是否锁定状态
      tenantBillId: '', // 参保账单ID
      billParam: {}, // 查看账单的参数
      createMonth: '', // 创建账单选择的年月
      showBill: false,
      delVisible: false,
      excelImport: false,
      insureClassifyList: [],
      tenantBillStatus: null, // 账单状态 null未生成  0 未锁定 1 已锁定
      importParams: {
        insureCompanyId: undefined,
        insureMonth: moment().format('YYYY-MM')
      }
    }
  },
  provide() {
    return {
      // isLock: () => this.isLock,
      initBillData: () => {
        this.initDatas()
        this.$refs.viewBill ? this.$refs.viewBill.getDetail() : null // 补缴调差有可能通过明细进入，因此只能从主页判断是否展示账单列表并进行刷新
      }
    }
  },
  watch: {
    'month': function(val) {
      this.importParams.insureMonth = val
      this.initDatas()
    }
  },
  mounted() {
    this.initDatas()
  },
  methods: {
    importBill(data) {
      this.importParams.insureCompanyId = data
      this.excelImport = true
    },
    goLogin(url) {
      if (url) {
        window.open(url)
      }
    },
    quickLogin() {
      this.urlVisible = true
      getSystemUrlApi({ insureMonth: this.month }).then(res => {
        if (res.code === 100) {
          this.quickList = res.data
        }
      })
    },
    // 初始化内容
    initDatas() {
      this.getBillList()
      this.getMonthTenantBill()
      this.getTenantBillStatus()
    },
    // 获取当月参保主体账单列表
    async getBillList() {
      const res = await getCompanyBillListApi({ insureMonth: this.month })
      if (res.code === 100) {
        this.billList = res.data
      }
    },
    // 导出明细
    exportBill() {
      exportInsureBillApi({ insureMonth: this.month })
    },
    // 导出汇总
    exportSummary() {
      exportInsureBillCollectApi({ insureMonth: this.month })
    },
    // 一键导出
    exportNotification() {
      exportNotificationDocumentApi({ insureMonth: this.month })
    },
    // 统计租户当月各项数值
    getMonthTenantBill() {
      getMonthTenantBillApi({ insureMonth: this.month }).then((res) => {
        if (res.code === 100) {
          if (res.data == null) {
            this.monthTenantBill = [
              {
                label: '缴费总金额',
                value: '-',
                unit: '元'
              },
              {
                label: '单位缴费合计',
                value: '-',
                unit: '元'
              },
              {
                label: '个人缴费合计 ',
                value: '-',
                unit: '元'
              }
            ]
            this.insureClassifyList = []
            this.tenantBillId = ''
          } else {
            this.monthTenantBill = [
              {
                label: '缴费总金额',
                value: res.data.total || '-',
                unit: '元'
              },
              {
                label: '单位缴费合计',
                value: res.data.companyTotal || '-',
                unit: '元'
              },
              {
                label: '个人缴费合计',
                value: res.data.personTotal || '-',
                unit: '元'
              }
            ]
            this.insureClassifyList = res.data.insureClassifyList
            this.tenantBillId = res.data.tenantBillId
          }
        }
      })
    },
    // 获取当前月份账单状态
    async getTenantBillStatus() {
      const res = await getTenantBillApi({ insureMonth: this.month })
      if (res.code === 100) {
        if (res.data) {
          this.tenantBillStatus = res.data.status
        } else {
          this.tenantBillStatus = null
        }
      }
    },
    // 创建账单
    async createBill(insureMonth) {
      if (this.tenantBillStatus === null) {
        const res1 = await createBillApi({ insureMonth })
        if (res1.code === 100) {
          this.initDatas()
          this.$refs.billTable ? this.$refs.billTable.serchGetData() : null
        }
      } else if (this.tenantBillStatus === 0) {
        this.createMonth = insureMonth
        this.$Reconfirm({
          title: '提示',
          content: '本月账单已经生成，再次生成将会覆盖账单'
        }).then(() => {
          this.createConfirm()
        }).catch(() => {})
      }
    },
    // 创建账单弹窗
    addBillModel() {
      this.$refs.addbill.visible = true
    },
    // 锁定二次确认
    lock() {
      this.$Reconfirm({
        title: this.tenantBillStatus === 0 ? '锁定' : '撤销锁定',
        content: this.tenantBillStatus === 0 ? '账单锁定后将计入薪酬，是否锁定账单？' : '本月已经完成计薪，并且按照当前账单在薪酬里面对员工的险金费用进行了扣减，是否确定要撤销锁定？'
      }).then(() => {
        this.lockConfirm()
      }).catch(() => {})
    },
    // 锁定
    lockConfirm() {
      updateStatusApi({
        tenantBillId: this.tenantBillId,
        status: this.tenantBillStatus === 0 ? '1' : '0'
      }).then((res) => {
        if (res.code === 100) {
          this.getTenantBillStatus()
        }
      })
    },
    // 创建账单
    createConfirm() {
      createBillApi({ insureMonth: this.createMonth }).then((res) => {
        if (res.code === 100) {
          this.initDatas()
          this.$refs.billTable ? this.$refs.billTable.serchGetData() : null
          this.$refs.billList.refresh()
        }
        this.createMonth = ''
      })
    },
    delBill() {
      if (this.tenantBillStatus === 0) {
        this.$Reconfirm({
          title: '删除账单',
          content: '删除账单后将会清空本月账单，删除后可重新创建账单，或直接重新创建账单可将原账单覆盖。'
        }).then(() => {
          this.delConfirm()
        }).catch(() => {})
      } else {
        this.$message.warning('暂无账单可删除')
      }
    },
    //  删除账单
    delConfirm() {
      deleteBillApi({ insureMonth: this.month }).then(res => {
        if (res.code === 100) {
          this.initDatas()
        }
      })
    },
    // 查看账单
    lookBill(data) {
      this.billParam = {
        month: this.month,
        insureCompanyId: data.insureCompanyId, // 参保主体id
        companyBillId: data.companyBillId, // 参保主体月度参保记录ID
        companyName: data.companyName // 参保主体名称
      }
      this.showDetail = true
      this.detailFlag = true
    },
    // 申报凭证
    voucher(data) {
      this.showDetail = true
      this.detailFlag = false
    },
    back() {
      this.showDetail = false
    },
    // 智能申报
    getDeclaration() {
      this.declarationShow = true
    },
    declarationBack() {
      this.declarationShow = false
    }
  }
}
</script>
<style lang="less" scoped>
.content{
  height:100%;
  position: relative;
  .footer{
    position:absolute;
    left:0;
    width:100%;
    height:46px;
    border-top:1px solid @sc-greyBg-20;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .ant-btn.link{
      margin-left: 12px;
    }
  }
  .center-content{
    margin-right: -20px;
    padding-right: 19px;
    height:calc(100% - 86px);
  }
}
.contentLogin .title{
  font-size:16px;
  line-height: 24px;
  margin-bottom:16px;
}
/deep/.ant-col{
  font-size:14px;
  line-height: 16px;
  margin-bottom:16px;
}
.loginUrl{
  color: @sc-primary-100;
  cursor: pointer;
}
.commonpy{
  margin-bottom:20px;
}
.flex-between-center {
  height: 40px;
}
p {
  margin: 0;
}
ul{
  margin:0;
}
.bill {
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #fff;
  overflow: auto;
}
.head{
  position:relative;
  width:100%;
  margin-bottom:20px;
  .btn-box{
    position: absolute;
    top:4px;
    left:0;
  }
}
.disabledIcon{
  color: @sc-grey-40
}
.bill-head {
  margin-bottom: 20px;
  width: 100%;
  padding:4px;
  border-radius: 6px;
  font-size: 14px;
  color: @sc-grey-80;
  background-color: @sc-greyBg-10;
  .tenantBill{
    height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .first{
      width: 418px;
      position: relative;
      display: flex;
      justify-content: center;
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 32px;
        background-color: @sc-greyBg-50;
      }
    }
    .lock-btn{
      display: flex;
      justify-content: center;
      width: 418px;
      border-left:1px solid @sc-greyBg-50
    }
  }
  .tenantDetail{
    background: #fff;
    border-radius: 4px;
    padding: 0 18px;
    .head-border{
      border-bottom:1px solid @sc-greyBg-20;
    }
    .detail-head{
      width:100%;
      height: 50px;
      line-height: 50px;
      display: flex;
      justify-content: space-between;
      li:last-child{
        text-align: right;
      }
      li{
        width:131px;
      }
    }
  }
}
.ant-button {
  margin-left:12px;
}
.detail {
  p {
    .font-size(16px);
    &:nth-child(1) {
      color: @sc-grey-100;
      font-weight: bold;
      .font-size(20px);
      margin-bottom: 2px;
    }
  }
}
/deep/.dateMonth {
  color: @sc-grey-100;
}
/deep/.ant-tabs-tab {
  font-size: 16px;
  color: @sc-grey-100;
  &:hover {
    color: @sc-primary-100;
  }
}
/deep/.ant-tabs-tab-active {
  color: @sc-primary-100 !important;
}
/deep/.anticon-export{
  color: @sc-primary-100
}
/deep/.ant-calendar-picker-input {
  font-size: 16px !important;
  display: flex;
  margin: 0 !important;
}
/deep/.ant-calendar-picker {
  width: 120px !important;
}
/deep/.statistics-center {
  padding-left: 60px;
}
.statistics {
  margin-top: 16px;
}
</style>
