<!--
 * @Date: 2022-04-25 15:31:59
 * @descriotion:
-->
<template>
  <div class="user-manage-panel">
    <a-tabs :default-active-key="defaultActiveKey" @change="callback">
      <a-tab-pane v-for="(key, index) in menuDataList" :key="index" :tab="key.name" />
    </a-tabs>
    <component :is="curComponentName" />
    <!-- <layout
      ref="layoutMenu"
      :menu-data-list="menuDataList"
      :selected-key.sync="selectedKey"
      class="left-menu-panel"
      @menu-select-change="menuSelectChange"
    >
      <template #leftTop>
        <div class="left-top-p" />
      </template>
      <component :is="curComponentName" />
    </layout> -->
  </div>
</template>
<script>
import layout from '@/components/CbLayoutMenu/index'
import common from './common'
export default {
  name: 'Record',
  components: {
    ...common,
    layout
  },
  data() {
    return {
      defaultActiveKey: 0,
      curComponentName: 'clock',
      menuDataList: [
        {
          id: '1',
          name: '打卡',
          frontIcon: '#plan-5me0lp9d',
          fileName: 'clock',
          actions: []
        },
        {
          id: '2',
          name: '补卡',
          frontIcon: '#card-two',
          fileName: 'replacement',
          actions: []
        },
        {
          id: '3',
          name: '请假',
          frontIcon: '#file-date',
          fileName: 'leave',
          actions: []
        },
        {
          id: '4',
          name: '外出',
          frontIcon: '#local',
          fileName: 'out',
          actions: []
        },
        {
          id: '5',
          name: '出差',
          frontIcon: '#lark-one',
          fileName: 'travel',
          actions: []
        },
        {
          id: '6',
          name: '加班',
          frontIcon: '#time-5me2d8dj',
          fileName: 'overtime',
          actions: []
        }
      ]
    }
  },
  mounted() {
  },
  methods: {
    callback(activeKey) {
      this.curComponentName = this.menuDataList[activeKey].fileName
      this.defaultActiveKey = activeKey
    }
  }
}
</script>

<style lang="less" scoped>
.user-manage-panel {
  min-height: 100%;
  background: #fff;
  padding: 20px 20px 0;
  overflow: auto;
}
/deep/ .ant-tabs-extra-content {
  line-height: 30px;
}
/deep/ .ant-tabs-nav-scroll {
  margin-top: -3px;
}
/deep/ .ant-tabs-tab {
  color: @sc-grey-100;
  padding-top: 0 !important;
  font-size: 16px;
  &:hover {
    color: @sc-primary-100;
  }
}
/deep/ .ant-tabs-tab-active {
  color: @sc-primary-100;
}
</style>
