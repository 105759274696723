<template>
  <div class="container">
    <!--  添加 搜索 筛选  -->
    <appForm
      ref="appForm"
      :to-list="toList"
      :not-shown-list="notShownList"
      :display-list="displayList"
      :screen-roster-list="screenRosterList"
      @getSearchCallback="getSearchCallback"
      @searchHeaderCallback="searchHeaderCallback"
      @screenRosterParams="screenRosterParams"
      @exportFileList="exportFileList"
    >
      <template slot="customButton">
        <a-dropdown type="primary">
          <template #overlay>
            <a-menu @click="handleMenuClick">
              <a-menu-item v-for="(item, index) in functionList" :key="index">{{ item.name }}</a-menu-item>
            </a-menu>
          </template>
          <a-button type="primary" suffix="left">
            添加员工
          </a-button>
        </a-dropdown>
        <a-popover
          trigger="click"
          :visible="clicked"
          placement="rightTop"
          :arrow-point-at-center="true"
          @visibleChange="visibleChange"
        >
          <template slot="content">
            <div class="invitation-box">
              <img :src="url.img" alt="" class="invitation-box_img">
              <div class="invitation-box_title">员工扫一扫，打开小程序</div>
              <a-button type="primary" @click="download">保存二维码</a-button>
            </div>
          </template>
          <a-button class="share" prefix="two-dimensional-code">分享</a-button>
        </a-popover>
      </template>
    </appForm>
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      row-key="staffId"
      :scroll="tableScroll"
      dragable
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="loadDataList"
      @watchWidth="watchWidth"
    >
      <span slot="staffname" slot-scope="{scope}">
        <CbTableAvater
          :table-name="scope.record[staffNameCode]"
          :on-job-id="scope.record.onJobId"
          :staff-id="scope.record.staffId"
        />
      </span>
      <template slot="operation" slot-scope="{ scope }">
        <a-button type="link" @click="resetPassword(scope)">重置密码</a-button>
      </template>
    </custom-table>
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import CbTableAvater from '@/components/CbTableAvater/index'
import { getRosterList, getRosterHeadersConfig, configRosterHeaders, exportStaffList, getQrCode, resetPasswordApi } from '@/services/user/roster.js'
export default {
  name: 'RankTabel',
  components: {
    CbTableAvater
  },
  mixins: [mixins],
  props: {},
  data() {
    return {
      url: {},
      clicked: false,
      total: 0,
      functionList: [
        { name: '单个添加' },
        { name: '批量导入' }
        // { name: '邀请员工开通' }
      ],
      tableScroll: { x: 0 },
      columns: [],
      indexList: {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        width: 50,
        fixed: true,
        customRender: (text, record, index) => `${index + 1}`
      },
      selectedRowKeys: [], // 当前选择列集合
      dataSource: [], // 数据
      notShownList: [],
      displayList: [],
      toList: ['search', 'screen', 'choice', 'export'], // search搜索框 screen筛选 choice位置选择弹窗 export导出
      screenRosterList: {
        'list': [
          {
            'type': 'selectDictionaries',
            'label': '性别',
            'options': {
              'width': '100%',
              'parameter': 'sex',
              'clearable': true,
              defaultValue: '',
              'placeholder': '请选择'
            },
            'model': 'sex'
          },
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'width': '100%',
              'parameter': 'engage_mode',
              defaultValue: '',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'employment'
          },
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'width': '100%',
              defaultValue: '',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'depMent'
          },
          {
            'type': 'selectPost',
            'label': '岗位',
            'options': {
              'width': '100%',
              defaultValue: '',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'selectPost'
          },
          {
            'type': 'selectRank',
            'label': '职级',
            'options': {
              'width': '100%',
              defaultValue: '',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'selectRank'
          },
          {
            'type': 'selectDictionaries',
            'label': '合同公司',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择合同公司',
              'showSearch': false,
              'parameter': 'contract_company'
            },
            'model': 'contractCo',
            'key': 'contractCo',
            'rules': [
              {
                'required': false,
                'message': '请选择合同公司'
              }
            ]
          },
          {
            'type': 'selectDataRange',
            'label': '入职日期',
            'options': {
              'width': '100%',
              'defaultValue': [undefined, undefined],
              'rangePlaceholder': ['开始时间', '结束时间'],
              'range': false,
              'clearable': true,
              'placeholder': '请选择开始时间',
              'format': 'YYYY-MM-DD'
            },
            'model': 'startEntryDate'
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      staffNameCode: '',
      temporaryStorage: []
    }
  },
  mounted() {
    this.getRosterHeadersConfig()
    this.getQrCode()
  },
  methods: {
    // 获取数据
    async fetchDataApiFn(param) {
      this.tableScroll.x = 0
      const res = await getRosterList(param)
      const columnObj = {
        'staffname': 105,
        'phonenum': 120,
        'staffnum': 125
      }
      // const unEllipsisList = ['姓名', '工号', '司龄']
      this.columns = res.data.columns.map((item, index) => {
        this.tableScroll.x = this.tableScroll.x + (item.width || 100)
        if (item.fieldCode === 'staffname') {
          this.staffNameCode = item.fieldId
        }
        // 判断列宽是否被修改过  如果改过则替换宽度
        if (this.temporaryStorage.length > 0) {
          this.temporaryStorage.forEach((i) => {
            if (i.dataIndex === item.fieldId) {
              item.width = i.width
            }
          })
        }
        return {
          ...item,
          title: item.fieldName,
          dataIndex: item.fieldId,
          width: columnObj[item.fieldCode]
            ? columnObj[item.fieldCode] : item.width ? item.width : 120,
          fixed: item.disable,
          ellipsis: item.fieldCode !== 'staffname',
          sorter: item.sort,
          scopedSlots: item.fieldCode === 'staffname' ? { customRender: item.fieldCode } : ''
        }
      })
      this.columns.unshift(this.indexList)
      this.columns.push({
        title: '',
        dataIndex: new Date().getTime()
      })
      this.columns.push(
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: 100,
          align: 'center',
          fixed: 'right'
        }
      )
      this.dataSource = res.data.content.records.map(item => {
        return {
          ...item.staffInfo
        }
      })
      this.pager.pageNum = res.data.content.current || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.content.size || 10 // 更新当前的分页信息
      this.total = res.data.content.total
    },
    // 搜索
    getSearchCallback(data) {
      this._queryParam.staffName = data.searchValue
      this.serchGetData()
    },
    // 获取表头设置数据
    async getRosterHeadersConfig() {
      const res = await getRosterHeadersConfig()
      this.notShownList = res.data.noShow.map(item => {
        return {
          ...item,
          id: item.fieldId,
          name: item.fieldName,
          d: item.disable
        }
      })
      const showList = res.data.show.map(item => {
        return {
          ...item,
          id: item.fieldId,
          name: item.fieldName,
          d: item.disable
        }
      })
      const a = showList.filter(item => item.disable === true)
      const b = showList.filter(item => item.disable === false)
      this.displayList = [...a, ...b]
    },
    // 选择表头
    async searchHeaderCallback(data, val) {
      const show = data.map(item => {
        return {
          fieldId: item.fieldId,
          fieldName: item.fieldName,
          sortNum: item.sortNum,
          disable: item.disable,
          sort: item.sort,
          fieldCode: item.fieldCode,
          width: item.width
        }
      })
      const noShow = val.map(item => {
        return {
          fieldId: item.fieldId,
          fieldName: item.fieldName,
          sortNum: item.sortNum,
          disable: item.disable,
          sort: item.sort,
          fieldCode: item.fieldCode,
          width: item.width
        }
      })
      const res = await configRosterHeaders({
        show,
        noShow
      })
      if (res.code === 100) {
        this.$message.success('表头设置成功')
        this.getRosterHeadersConfig()
        this.serchGetData()
      }
    },
    // 筛选
    screenRosterParams(data) {
      const { sex, employment, depMent, selectPost, selectRank, startEntryDate, contractCo } = data.screenCondition
      this._queryParam = {
        staffName: data.searchValue,
        sex: sex,
        engageMode: employment,
        orgId: depMent ? depMent[0].value : '',
        postId: selectPost,
        jobGradeId: selectRank,
        startEntryDate: startEntryDate ? startEntryDate[0] : '',
        endEntryDate: startEntryDate ? startEntryDate[1] : '',
        contractCo: contractCo || ''
      }
      this.serchGetData()
    },
    exportFileList() {
      const { staffName, sex, engageMode, orgId, postId, jobGradeId, startEntryDate, endEntryDate, contractCo } = this._queryParam
      exportStaffList({
        staffName: staffName,
        sex: sex,
        engageMode: engageMode,
        orgId: orgId,
        postId: postId,
        jobGradeId: jobGradeId,
        startEntryDate: startEntryDate,
        endEntryDate: endEntryDate,
        contractCo: contractCo
      })
    },
    // 跳转不同的添加方式
    handleMenuClick(column) {
      // this.functionType = column.key
      this.$emit('change', column)
    },
    download() {
      const link = document.createElement('a')
      link.href = this.url.img
      link.download = '员工入职二维码.png'
      link.click()
      this.clicked = false
    },
    async getQrCode() {
      const res = await getQrCode({})
      this.url = res.data
      this.url.img = `data:image/png;base64,${res.data.img}`
    },
    visibleChange(val) {
      const link = document.createElement('a')
      this.clicked = val
      link.click()
    },
    // 重置密码
    resetPassword(scope) {
      this.$Reconfirm({
        title: '重置密码',
        content: '是否确认重置密码？'
      }).then(async() => {
        const res = await resetPasswordApi({ onJobId: scope.record.onJobId })
        if (res.data) {
          this.$message.success('重置密码成功')
        } else {
          this.$message.success('重置密码失败')
        }
      }).catch(() => {
        console.log('no')
      })
    },
    // 监听列宽的变化记录改变后的列宽和key的值
    watchWidth(width, key) {
      const obj = {
        dataIndex: key,
        width: width
      }
      this.temporaryStorage.push(obj)
    }
  }
}
</script>

<style lang="less" scoped>
.share{
  color: @sc-grey-60;
  margin-left: 12px;
  /deep/.iconpark-icon{
    color: @sc-grey-60;
  }
}
.invitation-box{
  width: 160px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .invitation-box_img{
    width: 120px;
    height: 120px;
    background: #fff;
    /*border-radius: 50%;*/
  }
  .invitation-box_title{
    margin: 16px 0;
  }
}
</style>
