<template>
  <div class="PayrollRules">
    <a-form-model-item label="计薪规则">
      <span v-if="typeNo === 'A01'">薪资档案固定值</span>
      <a-radio-group
        v-else
        v-model="form.calcRuleType"
        :options="plainOptions"
        @change="calcRuleTypeChange"
      />
    </a-form-model-item>
    <!-- 自定义规则计算公式 -->
    <template v-if="form.calcRuleType === '2'">
      <a-form-model-item label="计算公式类型">
        <a-radio-group v-model="form.formula.formulaType" :options="formulaOptions" @change="formulaTypeChange" />
      </a-form-model-item>
      <a-form-model-item label="计算公式">
        <div class="custom-formula" @click="setUpCustomFormula">
          <span v-if="badFormula">点击输入自定义公式</span>
          <span v-else>{{ form.formula | formatFormula }}</span>
        </div>
        <div class="formula-budget">
          <a-button :disabled="badFormula" prefix="bendijisuan" @click="handleBudget">公式测算</a-button>
        </div>
      </a-form-model-item>
    </template>
    <!-- 系统内置计算公式 -->
    <FormulaList v-if="typeNo!=='A01'&&form.calcRuleType === '1'&&form.detailList" :form="form" />
    <!-- 公式测算 -->
    <formulaBudget ref="formulaBudget" />
  </div>
</template>

<script>
export default {
  name: 'PayrollRules',
  components: {
    FormulaList: () => import('./formula/index.vue'),
    formulaBudget: () => import('../../formulaSetting/formulaBudget.vue')
  },
  filters: {
    formatFormula({ formulaType, description, branchList }) {
      let formulaText = ''
      if (formulaType === '1') {
        formulaText = description
      }
      if (formulaType === '2' && branchList && branchList.length) {
        branchList.forEach(({ conditionList, description }) => {
          formulaText += `如果`
          conditionList.forEach(({ compareSymbol, leftExpression, rightExpression }, index) => {
            if (index > 0) {
              formulaText += '且'
            }
            formulaText += `${leftExpression}${compareSymbol}${rightExpression}`
          })
          formulaText += `则 ${description}`
        })
        formulaText += `否则${description}`
      }
      return formulaText
    }
  },
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    typeNo: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      isSetting: false,
      formulaOptions: [
        { label: '普通计算公式', value: '1' },
        { label: '条件计算公式', value: '2' }
      ]
    }
  },
  computed: {
    plainOptions() {
      const list = [{ label: '系统规则', value: '1' }, { label: '自定义规则', value: '2' }, { label: '计薪时导入', value: '3' }]
      if (this.form.defineMode === '2' || this.form.salaryItemNo?.slice(0, 3) === 'ZDY') {
        return list.splice(-2)
      } else if (this.typeNo === 'A08') {
        return list.splice(2)
      }
      return list
    },
    badFormula() {
      return !this.form.formula || !this.form.formula.description
    }
  },
  methods: {
    handleBudget() {
      // const { description, formulaType, maxLimit, minLimit } = this.form.formula
      console.log('this.form.formula', this.form.formula)
      this.$refs.formulaBudget.open(this.form.formula)
    },
    setUpCustomFormula() {
      this.$emit('setUpCustomFormula')
    },
    calcRuleTypeChange() {
      const { calcRuleType, salaryItemNo } = this.form
      if (calcRuleType === '1' && salaryItemNo) {
        this.$emit('getSalaryData', salaryItemNo)
      } else if (calcRuleType === '2') {
        this.$set(this.form.formula, 'formulaType', '1')
      } else {
        this.$set(this.form, 'formula', {})
      }
    },
    formulaTypeChange() {
      const { formulaType } = this.form.formula
      this.$set(this.form, 'formula', { formulaType })
    }
  }
}
</script>
<style lang="less" scoped>
.PayrollRules{
.custom-formula{
  min-height:80px;
  padding:10px;
  border: 1px solid @border-color;
  line-height: 1.5 !important;
}
}
</style>
