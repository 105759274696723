<template>
  <div>
    <Modal
      overflow-auto
      :closable="true"
      :visible="visible"
      :title="modalTitle"
      ok-text="保存"
      size="normal"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <!-- <div class="v-title">基本信息</div> -->
        <BlockTitle title="基本信息" class="v-title" />
        <a-form-model-item label="姓名" prop="name">
          <a-input v-model="form.name" placeholder="请输入姓名" />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="phoneNum">
          <a-input v-model="form.phoneNum" placeholder="请输入手机号" />
        </a-form-model-item>
        <a-form-model-item label="身份证" prop="idNum">
          <a-input v-model="form.idNum" placeholder="请输入身份证" />
        </a-form-model-item>
        <a-form-model-item v-if="isOldStaff" label=" " prop="idCard">
          <span style="color: red">该员工为离职状态，请选择入职方式</span>
          <a-radio-group v-model="form.entryType" :options="typeOptions" />
        </a-form-model-item>
        <a-form-model-item label="邮箱" prop="email">
          <a-input v-model="form.email" placeholder="请输入邮箱" />
        </a-form-model-item>
        <a-form-model-item label="招聘方式" prop="type">
          <dictionariesInput v-model="form.recruitType" parameter="recruit_type" />
        </a-form-model-item>
        <!-- <div class="v-title">入职信息</div> -->
        <BlockTitle title="入职信息" class="v-title" />
        <a-form-model-item label="计划入职日期" prop="planEntryDate">
          <a-date-picker v-model="form.planEntryDate" :disabled="!isAdd" value-format="YYYY-MM-DD" placeholder="请选择时间" />
        </a-form-model-item>
        <a-form-model-item v-if="!isAdd" label="实际入职日期" prop="actualEntryDate">
          <a-date-picker v-model="form.actualEntryDate" :disabled-date="disabledDateTime" value-format="YYYY-MM-DD" placeholder="请选择时间" />
        </a-form-model-item>
        <a-form-model-item v-if="!isAdd" label="试用期" prop="realEntryDate">
          <a-select v-model="form.probationNum" placeholder="请选择" :options="optionsType" />
        </a-form-model-item>
        <a-form-model-item label="聘用形式" prop="engageMode">
          <dictionariesInput v-model="form.engageMode" parameter="engage_mode" />
        </a-form-model-item>
        <a-form-model-item label="部门" prop="orgId">
          <depTreeSelect v-model="form.orgId" :multiple="false" placeholder="请选择部门" @echoPost="echoPost" @getValueSelect="deepTreeChange" />
        </a-form-model-item>
        <a-form-model-item label="岗位" prop="post">
          <a-select v-model="form.postId" placeholder="请选择" :disabled="postDisabled" allow-clear @change="postListChange">
            <a-select-opt-group v-for="item in postList" :key="item.postTypeId" :label="item.postTypeName">
              <a-select-option v-for="it in item.postList" :key="it.postId" :value="it.postId">
                {{ it.postName }}
              </a-select-option>
            </a-select-opt-group>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="职务">
          <a-select v-model="form.duty" placeholder="请选择" :disabled="postDisabled" allow-clear>
            <a-select-option v-for="it in dutyList" :key="it.dutyId" :value="it.dutyId">
              {{ it.dutyName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="职级" prop="level">
          <a-select v-model="form.jobGradeId" placeholder="请选择" :disabled="!form.postId" allow-clear>
            <a-select-opt-group v-for="item in rankList" :key="item.jobTypeId" :label="item.jobTypeName">
              <a-select-option v-for="it in item.jobGradeList" :key="it.jobGradeId" :value="it.jobGradeId">
                {{ it.jobTypeCode }}{{ it.jobGrade }}
              </a-select-option>
            </a-select-opt-group>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <a-checkbox v-if="!isAdd" v-model="form.entryRegistration" class="checkbox">发送入职登记表</a-checkbox>
    </Modal>
  </div>
</template>

<script>
import { addWaitEntry, getStaffByIdNum, confirmEntry } from '@/services/user/Induction.js'
import { getPostDropDownList } from '@/services/organization/post.js'
import { getRankGroupDropDownList } from '@/services/organization/rank.js'
import mixins from '@/components/CbPerDepSelection/index.js'
import { phoneReg, idCardReg } from '@/utils/reg.js'
import { getProbationConfig } from '@/services/user/setting.js'
import { getSelectDutyList } from '@/services/organization/duty.js'
// import moment from 'moment'

export default {
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index'),
    Modal: () => import('@/components/CbModal/index'),
    dictionariesInput: () => import('@/components/CbDropDownInput/dictionariesInput/index') // 字典下拉框
  },
  mixins: [mixins],
  props: {
    modalTitle: {
      type: String,
      default: ''
    },
    isAdd: {
      type: Boolean,
      default: false
    },
    entryApplyId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      form: {
        entryType: '1',
        orgId: [],
        postId: undefined,
        jobGradeId: undefined,
        entryRegistration: true // 是否发送入职登记表
      },
      isOldStaff: false,
      typeOptions: [
        { label: '新员工', value: '1' },
        { label: '复职', value: '2' }
      ],
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'change' },
          { min: 2, message: '姓名至少两个文字组成', trigger: 'blur' }
        ],
        phoneNum: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'change'
          },
          {
            pattern: phoneReg,
            message: '请输入正确的手机号'
          }
        ],
        idNum: [
          {
            required: false,
            message: '请输入身份证号',
            trigger: 'change'
          },
          {
            pattern: idCardReg,
            message: '身份证格式不正确'
          }
        ],
        planEntryDate: [
          {
            required: true,
            message: '请选择入职日期',
            trigger: 'change'
          }
        ],
        engageMode: [
          {
            required: true,
            message: '请选择招聘形式',
            trigger: 'change'
          }
        ]
      },
      postList: [],
      rankList: [],
      echoData: {},
      optionsType: [
        {
          value: '0',
          label: '无试用期'
        },
        {
          value: '1',
          label: '1个月'
        },
        {
          value: '2',
          label: '2个月'
        },
        {
          value: '3',
          label: '3个月'
        },
        {
          value: '4',
          label: '4个月'
        },
        {
          value: '5',
          label: '5个月'
        },
        {
          value: '6',
          label: '6个月'
        }
      ],
      dutyList: []
    }
  },
  computed: {
    postDisabled() {
      let boolean = true
      if (this.form.orgId && this.form.orgId.length > 0) {
        boolean = false
      } else {
        boolean = true
      }
      return boolean
    }
  },
  watch: {
    'form.entryType'(val) {
      if (val === '2') {
        this.form.orgId = this.echoData.orgId ? [{ value: this.echoData.orgId }] : undefined
        this.form.postId = this.echoData.postId ? this.echoData.postId : undefined
        this.form.jobGradeId = this.echoData.jobGradeId ? this.echoData.jobGradeId : undefined
        if (this.dataType(this.echoData.orgId) !== 'Null') {
          this.deepTreeChange([{ value: this.echoData.orgId }], true).then(() => {
            if (this.dataType(this.echoData.postId) !== 'Null') {
              this.postListChange(this.echoData.postId, true)
            }
          })
        }
      }
    },
    'form.idNum'(val) {
      if (val && val.length === 18) {
        this.getStaffByIdNums(val)
      }
    }
  },
  mounted() {
    this.getSelectDutyList()
  },
  methods: {
    disabledDateTime(current) {
      // 不能选择今天之前（不包括今天）
      const atime = current._d
      const ctime = new Date(this.form.actualEntryDate)
      return atime >= ctime
    },
    async getProbationData() {
      const res = await getProbationConfig()
      this.$set(this.form, 'probationNum', res.data)
    },
    // 打开弹窗
    open(data) {
      if (data) {
        const { name, phoneNum, orgId, postId, duty, planEntryDate, idNum, email, engageMode, jobGradeId, recruitType, actualEntryDate } = data
        this.$set(this.form, 'name', name) // 姓名
        this.$set(this.form, 'phoneNum', phoneNum) // 手机号
        this.$set(this.form, 'idNum', idNum) // 身份证
        this.$set(this.form, 'email', email) // 邮箱
        this.$set(this.form, 'recruitType', recruitType) // 招聘方式
        this.$set(this.form, 'engageMode', engageMode) // 聘用形式
        this.$set(this.form, 'planEntryDate', planEntryDate) // 计划入职日期
        this.$set(this.form, 'actualEntryDate', actualEntryDate) // 实际入职日期
        // this.form.planEntryDate = planEntryDate // 入职日期
        // this.form.actualEntryDate = actualEntryDate
        this.$set(this.form, 'orgId', orgId) // 部门
        this.$set(this.form, 'postId', postId) // 岗位
        this.$set(this.form, 'jobGradeId', jobGradeId) // 职级
        this.$set(this.form, 'duty', duty)// 职务
        this.form.entryRegistration = true // 入职登记表默认为true
        this.getProbationData()
        this.rules.idNum[0].required = true
        this.rules.planEntryDate[0].required = false
      } else {
        this.rules.planEntryDate[0].required = true
      }
      this.visible = true
    },
    async echoPost() {
      if (this.form.orgId) {
        const res = await getPostDropDownList({ orgId: this.form.orgId })
        this.postList = res.data || []
      }
      if (this.form.postId) {
        const rank = await getRankGroupDropDownList({ postId: this.form.postId })
        this.rankList = rank.data || []
      }
    },
    // 获取岗位
    async deepTreeChange(e, boolean) {
      if (!boolean) {
        this.form.postId = undefined
        this.form.jobGradeId = undefined
      }
      if (e && e.length > 0) {
        const res = await getPostDropDownList({ orgId: e[0].value })
        this.postList = res.data || []
      }
    },
    // 获取职级
    async postListChange(e, boolean) {
      if (!boolean) {
        this.form.jobGradeId = undefined
      }
      if (!e) return
      const res = await getRankGroupDropDownList({ postId: e })
      this.rankList = res.data || []
    },
    // 请求职务下拉列表
    async getSelectDutyList() {
      const res = await getSelectDutyList()
      this.dutyList = res.data
    },
    // 保存事件
    handleOk() {
      // eslint-disable-next-line space-before-function-paren
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.isAdd) {
            const res = await addWaitEntry({
              name: this.form.name,
              phoneNum: this.form.phoneNum,
              idNum: this.form.idNum,
              entryType: this.form.entryType,
              email: this.form.email,
              recruitType: this.form.recruitType,
              planEntryDate: this.form.planEntryDate,
              engageMode: this.form.engageMode,
              orgId: this.form.orgId && this.form.orgId.length > 0 ? this.form.orgId[0].value : '',
              postId: this.form.postId,
              jobGradeId: this.form.jobGradeId,
              duty: this.form.duty
            })
            if (res.code === 100) {
              this.$message.success('添加成功')
              this.visible = false
              this.$emit('getFetchDataApiFn')
              this.resetForm()
            }
          } else {
            const res = await confirmEntry({
              entryApplyId: this.entryApplyId,
              name: this.form.name,
              idNum: this.form.idNum,
              entryType: this.form.entryType,
              phoneNum: this.form.phoneNum,
              email: this.form.email,
              recruitType: this.form.recruitType,
              planEntryDate: this.form.planEntryDate,
              actualEntryDate: this.form.actualEntryDate,
              engageMode: this.form.engageMode,
              orgId: this.form.orgId && this.form.orgId.length > 0 ? this.form.orgId[0].value : '',
              postId: this.form.postId,
              jobGradeId: this.form.jobGradeId,
              duty: this.form.duty,
              entryRegistration: this.form.entryRegistration ? 2 : 1,
              probationNum: this.form.probationNum
            })
            if (res.code === 100) {
              this.$message.success('入职成功！')
              this.visible = false
              this.$emit('getFetchDataApiFn')
              this.$emit('entryMattersEvent', res.data)
              this.rules.idNum[0].required = false
              this.resetForm()
            }
          }
        }
      })
    },
    async getStaffByIdNums() {
      const res = await getStaffByIdNum({ idNum: this.form.idNum })
      if (res.code === 100) {
        this.isOldStaff = res.data
        this.echoData = res.data
        if (this.dataType(res.data) !== 'Null') {
          this.form.name = res.data.staffName
          this.form.phoneNum = res.data.phoneNum
        }
      }
    },
    // 取消事件
    handleCancel() {
      this.visible = false
      this.rules.idNum[0].required = false
      this.resetForm()
    },
    // 重置表单内容和验证
    resetForm() {
      this.form = {
        entryType: '1',
        orgId: [],
        postId: undefined,
        jobGradeId: undefined
      }
      this.isOldStaff = false
      this.$refs.ruleForm.resetFields()
    },
    dataType(data) {
      return Object.prototype.toString.call(data).split(' ')[1].slice(0, -1)
    }
  }
}
</script>

<style lang="less" scoped>
.v-title {
 margin-bottom: 16px;
}

.ant-calendar-picker{
  width: 100%;
}
.checkbox{
  margin-top: 20px;
}
</style>
