<template>
  <div class="statisticalperiod">
    <div class="tips">
      <p>系统将按薪酬计算周期统计考勤结果，如需更改，前往【薪酬】-【设置】-【计薪周期】设置，且历史月份不受影响。</p>
      <p>考勤统计周期：{{ tips }}  <span class="set" @click="goTo">前往设置</span> </p>
    </div>
  </div>
</template>
<script>
import { getCurrentPeriodApi } from '@/services/attendance/config.js'
export default {
  name: 'Statisticalperiod',
  data() {
    return {
      tips: ''
    }
  },
  mounted() {
    this.getStr()
  },
  methods: {
    goTo() {
      this.$router.push('/wagesIndex/wageSetting')
    },
    getStr() {
      getCurrentPeriodApi({}).then(res => {
        if (res.code === 100) {
          this.tips = res.data
        } else {
          this.tips = ''
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.statisticalperiod{
  width: 100%;
  padding:20px;
  .tips{
    font-size: 14px;
    color: @sc-grey-80;
  }
  .set{
    color: @sc-primary-100;
    cursor: pointer;
  }
}
</style>
