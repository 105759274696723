<template>
  <div class="account-container">
    <div>
      <div class="add-salary">
        <a-button prefix="add" @click="addGroup">
          新增发薪账户
        </a-button>
      </div>
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        @change="loadDataList"
      >
        <!-- 操作列 -->
        <template slot="operation" slot-scope="{ scope }">
          <a-popover
            placement="rightTop"
            trigger="hover"
          >
            <template slot="content">
              <div class="more-handle-btn">
                <a-button type="link" @click="edit(scope.record)">
                  编辑
                </a-button>
                <a-button type="link" class="delete" danger @click="delet(scope.record)">
                  删除
                </a-button>
              </div>
            </template>
            <div class="more-handle">
              ···
            </div>
          </a-popover>
        </template>
      </custom-table>
    </div>
    <!-- 弹出层 -->
    <Drawer
      closable
      :visible="accountVisible"
      size="normal"
      title="新增发薪账户"
      :is-mask="false"
      @close="onClose"
      @onSubmit="confirmAccount"
    >
      <div class="card-content">
        <a-form-model
          ref="accountForm"
          :model="accountForm"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="开户行" prop="accountName">
            <a-select v-model="accountForm.accountName" :default-value="accountForm.accountName">
              <a-select-option v-for="(item) in accountNameList" :key="item.id" :value="item.name">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="银行卡账号" prop="accountId">
            <a-input v-model="accountForm.accountId" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="适用合同公司" prop="isCompany">
            <a-select v-model="accountForm.isCompany" placeholder="请选择">
              <a-select-option v-for="(item) in isCompanyList" :key="item.id" :value="item.name">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
    </Drawer>
  </div>
</template>

<script>
import CustomTable from '@/components/CustomTable/index.js'
export default {
  name: 'PayAccount',
  components: {
    Drawer: () => import('@/components/CbDrawer')
  },
  mixins: [CustomTable],
  data() {
    return {
      accountVisible: false,
      labelCol: { lg: 5, xl: 4, xxl: 6 },
      wrapperCol: { lg: 19, xl: 20, xxl: 16 },
      accountForm: {
        accountName: '中国工商银行',
        accountId: '',
        isCompany: []
      },
      rules: {
        accountName: [
          { required: true, message: '请选择开户行', trigger: 'blur' }
        ],
        accountId: [
          { required: true, message: '请输入银行卡账号', trigger: 'blur' }
        ],
        isCompany: [
          { required: true, message: '请选择使用公司', trigger: 'blur' }
        ]
      },
      accountNameList: [
        { id: 1, name: '中国工商银行' },
        { id: 2, name: '邮政银行' },
        { id: 3, name: '农业银行' },
        { id: 4, name: '中国招商银行' },
        { id: 5, name: '中国交通银行' },
        { id: 6, name: '中国建设银行' },
        { id: 7, name: '中国齐鲁银行' },
        { id: 8, name: '民生银行' }
      ],
      isCompanyList: [
        { id: 1, name: '山东数维信息科技有限公司' },
        { id: 2, name: '山东小数点信息科技有限公司' }
      ],
      dataSource: [
        {
          accountName: 'Joe Black',
          accountId: 32,
          accountCompany: 'Sidney No. 1 Lake Park'
        },
        {
          accountName: 'Joe Black',
          accountId: 32,
          accountCompany: 'Sidney No. 1 Lake Park'
        },
        {
          accountName: 'Joe Black',
          accountId: 32,
          accountCompany: 'Sidney No. 1 Lake Park'
        }
      ],
      columns: [{
        title: '开户行',
        dataIndex: 'accountName',
        key: 'accountName',
        scopedSlots: { customRender: 'name' },
        width: '200px'
      },
      {
        title: '银行卡账号',
        dataIndex: 'accountId',
        key: 'accountId',
        width: '400px'
      },
      {
        title: '适用合同公司',
        dataIndex: 'accountCompany',
        key: 'accountCompany',
        width: '600px'
      },
      {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
        width: '250px'
      }]
    }
  },
  methods: {
    // 新增账号
    addGroup() {
      this.accountVisible = true
    },
    // 取消弹窗
    onClose() {
      this.accountVisible = false
      this.accountForm = {}
    },
    confirmAccount() {
      this.accountVisible = false
    }
  }
}
</script>
<style lang="less" scoped>
.account-container {
  width: 100%;
  padding: 40px;
  box-sizing: border-box;
  .add-salary {
    margin-bottom: 25px;
  }
}
.more-handle-btn {
    display: flex;
    flex-direction: column;
    .ant-btn {
      height:25px;
    }
  }
  .screen_button {
    position: fixed;
    right: 20px;
    bottom: 30px;
    .close_button {
      margin-right: 10px;
    }
  }
  /deep/ .ant-col-xxl-3 {
    width: 18%;
  }
</style>

