<template>
  <div class="proof-template">
    <div v-if="!isAddTemplate" class="proof-template-list">
      <div class="proof-template-search">
        <span>证明模版设置</span>
        <a-button type="primary" prefix="add-one" @click="addOrEditTemplate(templateType='add',{})">
          添加模板
        </a-button>
      </div>
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        table-ref=".proof-template-list"
        row-key="jobGradeId"
        :pagination="{
          current: pager.pageNum,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条数据`,
          showQuickJumper: true,
          showSizeChanger: true
        }"
        @change="loadDataList"
      >
        <span slot="switchStatus" slot-scope="{scope}">
          <div>
            <a-switch v-model="scope.record.tempStatustype" size="small" @change="changeStatus(scope.record)" />
          </div>
        </span>
        <!-- 操作列 -->
        <template slot="operation" slot-scope="{scope}">
          <template>
            <div class="operation-btn-box">
              <a-button type="link" @click="addOrEditTemplate(templateType='edit', scope.record)">
                编辑
              </a-button>
              <a-button class="delete" type="link" @click="deletTemplate(scope.record)">
                删除
              </a-button>
            </div>
          </template>
          <!-- <a-popover
            placement="bottom"
            trigger="hover"
          >
            <template slot="content">
              <div class="more-handle-btn">
                <a-button type="link" @click="addOrEditTemplate(templateType='edit', scope.record)">
                  编辑
                </a-button>
                <a-button class="delete" type="link" @click="deletTemplate(scope.record)">
                  删除
                </a-button>
              </div>
            </template>
            <div class="more-handle">
              ···
            </div>
          </a-popover> -->
        </template>
      </custom-table>
    </div>
    <AddTemplate v-else @back="backRefresh" />
  </div>
</template>

<script>
import AddTemplate from './addTemplate'
import mixins from '@/components/CustomTable/index.js'
import { getProveTempList, changeTempStatus, deleteProveTemp } from '@/services/user/setting.js'
export default {
  name: 'ProofTemplate',
  components: {
    AddTemplate
  },
  mixins: [mixins],
  data() {
    return {
      deletTemplatPproveTempId: '',
      deletTemplatTempFileId: '',
      total: 0,
      isAddTemplate: false,
      columns: [{
        title: '模版名称',
        dataIndex: 'tempName',
        key: 'tempName'
      },
      {
        title: '状态',
        dataIndex: 'tempStatus',
        key: 'tempStatus',
        scopedSlots: { customRender: 'switchStatus' }
      },
      {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
        width: '130px'
      }],
      dataSource: [],
      loading: false
    }
  },
  created() {},
  methods: {
    // 获取模板列表
    async fetchDataApiFn(param) {
      const res = await getProveTempList(param)
      if (res.code === 100) {
        this.dataSource = res.data.records.map(item => {
          return {
            ...item,
            tempStatustype: item.tempStatus === 1
          }
        })
        this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
        this.total = res.data.total
      }
    },
    // 改变模板状态
    async changeStatus(row) {
      const data = {
        proveTempId: row.proveTempId,
        tempStatus: row.tempStatustype ? 1 : 0
      }
      const res = await changeTempStatus(data)
      if (res.code === 100) {
        this.$message.success('状态改变成功！')
        this.serchGetData()
      }
    },
    // 删除模板
    async deletTemplate(row) {
      this.deletTemplatPproveTempId = row.proveTempId
      this.deletTemplatTempFileId = row.tempFileId
      this.$Reconfirm({
        title: '删除',
        content: '是否确认删除？'
      }).then(async() => {
        const data = {
          proveTempId: this.deletTemplatPproveTempId,
          tempFileId: this.deletTemplatTempFileId
        }
        const res = await deleteProveTemp(data)
        if (res.code === 100) {
          this.$message.success('模板删除成功！')
          this.serchGetData()
        }
      }).catch(() => {
        console.log('no')
      })
    },
    // 返回刷新
    backRefresh(isRefresh) {
      if (isRefresh === 'Refresh') {
        this.serchGetData()
      }
      this.isAddTemplate = false
    },
    addOrEditTemplate(templateType, { ...row }) {
      sessionStorage.setItem('proveTemplate', JSON.stringify({ templateType, ...row }))
      this.isAddTemplate = true
    }
  }
}
</script>

<style lang="less" scoped>
.proof-template {
  height: 100%;
  overflow: auto;
  &-list {
    padding:20px 20px 0;
  }
  &-search {
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-bottom: 16px;
    margin-top: -4px;
    span{
      font-weight: 700;
      font-size: 16px;
      color:@sc-grey-100;
    }
  }
}
.more-handle-btn {
    display: flex;
    flex-direction: column;
    .ant-btn{
      height: auto;
    }
}
.more-handle {
  width:20px;
  font-weight: 700;
  line-height: 44px;
  font-size: 20px;
  color: @sc-primary-100;
  vertical-align: baseline;
  cursor: pointer;
}
.operation-btn-box button{
  padding-left: 0;
  padding-right: 16px;
}
</style>
