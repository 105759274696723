<!--
 * @Date: 2022-07-05 13:52:44
 * @descriotion:
-->
<template>
  <div class="content">
    <appForm
      ref="appForm"
      class="appform"
      :to-list="formList"
      search-placeholder="请搜索姓名/工号"
      :screen-roster-list="screenRosterList"
      @getSearchCallback="getSearchCallback"
      @screenRosterParams="screenRosterParams"
    >
      <template slot="customButton">
        <a-dropdown placement="bottomLeft">
          <a-button>{{ importList[active].title }}</a-button>
          <a-menu slot="overlay">
            <a-menu-item v-for="(item, index) in importList[active].menuList" :key="index" @click="importExecl(item)">
              {{ item.label }}
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <!-- <a-button v-show="active === 0 && !isLock()" class="ant-button" @click="exportBatchSupply">
          导入补缴调差
        </a-button> -->
      </template>
    </appForm>
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      :scroll="{ x: active?'auto':2700, y: 480 }"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      row-key="onJobId"
      @change="loadDataList"
    >
      <!-- 序号 -->
      <template slot="index" slot-scope="{ scope }">
        <span>{{ scope.index + 1 }}</span>
      </template>
      <!-- 姓名 -->
      <template slot="staffName" slot-scope="{ scope }">
        <CbTableAvater
          :staff-id="scope.record.staffId"
          :on-job-id="scope.record.onJobId"
          :table-name="scope.record.staffName"
          :title="scope.record.staffName"
          :is-archives="true"
        />
      </template>
      <!-- 操作 -->
      <template slot="operation" slot-scope="{ scope }">
        <a-button type="link" @click="detail(scope.record)">
          明细
        </a-button>
      </template>
      <!-- 补缴详情 -->
      <template slot="operationDetail" slot-scope="{ scope }">
        <a-button type="link" @click="detailSupply(scope.record)">
          详情
        </a-button>
      </template>
    </custom-table>
    <DetailDrawer ref="detailDrawer" detail-type="company" @reloadData="serchGetData" />
    <ImportDrawer ref="importDrawer" />
    <SupplyModal ref="supplyModal" />

  </div>
</template>
<script>
import { importList } from '../dict'
import mixins from '@/components/CustomTable/index.js'
import { getInsureBillListApi, getZyList, getJyList, exportSocialZyApi, exportInsureBillApi, getSupplyListApi, exportSupplyPayApi } from '@/services/insurance/bill'
export default {
  name: 'Table',
  components: {
    DetailDrawer: () => import('./detailDrawer.vue'),
    ImportDrawer: () => import('./importDrawer.vue'),
    CbTableAvater: () => import('@/components/CbTableAvater/index'),
    SupplyModal: () => import('../component/supplyModal.vue')
  },
  mixins: [mixins],
  props: {
    active: {
      type: [Number, String],
      default: 0
    },
    billParam: {
      type: Object,
      default: () => {}
    }
  },
  // inject: ['isLock'],
  data() {
    return {
      importList: importList,
      screenRosterList: {
        list: [
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'engage_mode',
              'clearable': true,
              'placeholder': '请选择聘用形式',
              'showSearch': false
            },
            'model': 'engageModes',
            'key': 'engageModes'
          },
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择部门',
              'showSearch': false
            },
            'model': 'orgIds',
            'key': 'orgIds',
            'rules': [
              {
                'required': false,
                'message': '请选择部门'
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 80,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      formList: ['search', 'screen'],
      columns: [
        {
          title: '序号',
          width: 50,
          dataIndex: 'index',
          fixed: 'left',
          customRender: (text, record, index) => `${index + 1}`
        },
        {
          title: '证件号码',
          dataIndex: 'idNum',
          key: 'idNum',
          fixed: 'left',
          width: 200
        },
        {
          title: '姓名',
          dataIndex: '',
          key: '',
          scopedSlots: { customRender: 'staffName' },
          fixed: 'left',
          width: 105
        },
        {
          title: '操作',
          dataIndex: 'operation',
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
          width: 50
        }
      ],
      zyColumns: [
        {
          title: '序号',
          width: 50,
          dataIndex: 'index',
          customRender: (text, record, index) => `${index + 1}`
        },
        {
          title: '证件号码',
          dataIndex: 'idNum',
          key: 'idNum',
          width: 120
        },
        {
          title: '姓名',
          dataIndex: '',
          key: '',
          scopedSlots: { customRender: 'staffName' },
          width: 105
        },
        {
          title: '社保',
          children: [
            {
              title: '缴费开始月',
              dataIndex: 'socialStartMonth',
              key: 'socialStartMonth',
              width: 100
            },
            {
              title: '缴费基数',
              dataIndex: 'socialFixedPaymentBase',
              key: 'socialFixedPaymentBase',
              width: 100
            }
          ]
        },
        {
          title: '医保',
          children: [
            {
              title: '缴费开始月',
              dataIndex: 'medicalStartMonth',
              key: 'medicalStartMonth',
              width: 100
            },
            {
              title: '缴费基数',
              dataIndex: 'medicalFixedPaymentBase',
              key: 'medicalFixedPaymentBase',
              width: 100
            }
          ]
        },
        {
          title: '公积金',
          children: [
            {
              title: '缴费开始月',
              dataIndex: 'housingStartMonth',
              key: 'housingStartMonth',
              width: 100
            },
            {
              title: '缴费基数',
              dataIndex: 'housingFixedPaymentBase',
              key: 'housingFixedPaymentBase',
              width: 100
            }
          ]
        }
      ],
      jyColumns: [
        {
          title: '序号',
          width: 50,
          dataIndex: 'index',
          customRender: (text, record, index) => `${index + 1}`
        },
        {
          title: '证件号码',
          dataIndex: 'idNum',
          key: 'idNum',
          width: 120
        },
        {
          title: '姓名',
          dataIndex: '',
          key: '',
          scopedSlots: { customRender: 'staffName' },
          width: 105
        },
        {
          title: '社保',
          children: [
            {
              title: '缴费停止月',
              dataIndex: 'socialEndMonth',
              key: 'socialEndMonth',
              width: 100
            },
            {
              title: '停保原因',
              width: 100,
              dataIndex: 'socialDecreasesReason',
              key: 'socialDecreasesReason',
              ellipsis: true,
              customCell: () => {
                return {
                  style: {
                    'max-width': '100px'
                  }
                }
              }
            }
          ]
        },
        {
          title: '医保',
          children: [
            {
              title: '缴费停止月',
              dataIndex: 'medicalEndMonth',
              key: 'medicalEndMonth',
              width: 100
            },
            {
              title: '停保原因',
              width: 100,
              dataIndex: 'medicalDecreasesReason',
              key: 'medicalDecreasesReason',
              ellipsis: true,
              customCell: () => {
                return {
                  style: {
                    'max-width': '100px'
                  }
                }
              }
            }
          ]
        },
        {
          title: '公积金',
          children: [
            {
              title: '缴费停止月',
              dataIndex: 'housingEndMonth',
              key: 'housingEndMonth',
              width: 100
            },
            {
              title: '停保原因',
              width: 100,
              dataIndex: 'housingDecreasesReason',
              key: 'housingDecreasesReason',
              ellipsis: true,
              customCell: () => {
                return {
                  style: {
                    'max-width': '100px'
                  }
                }
              }
            }
          ]
        }
      ],
      bjColumns: [
        {
          title: '序号',
          width: 50,
          dataIndex: 'index',
          customRender: (text, record, index) => `${index + 1}`
        },
        {
          title: '证件号码',
          dataIndex: 'idNum',
          key: 'idNum',
          width: 120
        },
        {
          title: '姓名',
          dataIndex: '',
          key: '',
          scopedSlots: { customRender: 'staffName' },
          width: 105
        },
        {
          title: '社保',
          children: [
            {
              title: '公司补缴金额',
              dataIndex: 'socialCompanyTotal',
              key: 'socialCompanyTotal',
              width: 100
            },
            {
              title: '个人补缴金额',
              dataIndex: 'socialPersonTotal',
              key: 'socialPersonTotal',
              width: 100
            }
          ]
        },
        {
          title: '医保',
          children: [
            {
              title: '公司补缴金额',
              dataIndex: 'medicalCompanyTotal',
              key: 'medicalCompanyTotal',
              width: 100
            },
            {
              title: '个人补缴金额',
              dataIndex: 'medicalPersonTotal',
              key: 'medicalPersonTotal',
              width: 100
            }
          ]
        },
        {
          title: '公积金',
          children: [
            {
              title: '公司补缴金额',
              dataIndex: 'housingCompanyTotal',
              key: 'housingCompanyTotal',
              width: 100
            },
            {
              title: '个人补缴金额',
              dataIndex: 'housingPersonTotal',
              key: 'housingPersonTotal',
              width: 100
            }
          ]
        },
        {
          title: '操作',
          dataIndex: 'operationDetail',
          fixed: 'right',
          scopedSlots: { customRender: 'operationDetail' },
          width: 50
        }
      ],
      total: 0,
      dataSource: []
    }
  },
  watch: {
    active(newVal, oldVal) {
      // this.setTableHeader(newVal)
      this._queryParam.keyWord = ''
      this._queryParam.engageMode = ''
      this._queryParam.orgId = ''
      this.serchGetData()
      this.refresh()
    },
    billParam: {
      handler: function(val) {
        this.serchGetData()
      },
      deep: true
    }
  },
  mounted() {
    // 补缴调差之后，新增表头
    // this.setTableHeader(0)
  },
  methods: {
    exportBatchSupply() {
      this.$emit('exportBatchSupply', 'exportBatchSupply')
    },
    getSearchCallback(data) {
      this._queryParam.keyWord = data.searchValue
      this.serchGetData()
    },
    screenRosterParams(param) {
      this._queryParam.engageMode = param.screenCondition.engageModes ? param.screenCondition.engageModes.join(',') : ''
      if (param.screenCondition.orgIds) {
        const orgIds = param.screenCondition.orgIds.map((item) => {
          return item.value
        })
        this._queryParam.orgId = orgIds.join(',')
      }
      for (var key in this._queryParam) {
        if (!this._queryParam[key]) {
          delete this._queryParam[key]
        }
      }
      this.serchGetData()
    },
    // 获取账单明细
    async fetchDataApiFn(param) {
      if (this.active === 0) {
        // 正常缴纳
        const res = await getInsureBillListApi({
          insureMonth: this.billParam.month,
          insureCompanyId: this.billParam.insureCompanyId,
          type: this.active === 0 ? null : this.active,
          ...param
        })
        if (res.code === 100) {
          this.columns = this.$options.data().columns
          // 加载列
          const tempColumns = res.data.header
          for (let i = 0; i < tempColumns.length; i++) {
            const children = tempColumns[i].children.map((v) => {
              return {
                title: v.label,
                dataIndex: v.value,
                key: v.value,
                width: 190,
                dataType: v.dataType
              }
            })
            tempColumns[i].children = children
            tempColumns[i].title = tempColumns[i].insureClassify
          }
          console.log(tempColumns, 'tempColumns')
          this.columns.splice(
            3,
            0,
            {
              title: '单位合计金额',
              dataIndex: 'companyTotal',
              key: 'companyTotal',
              width: 120,
              dataType: 'money'
            },
            {
              title: '个人合计金额',
              dataIndex: 'personTotal',
              key: 'personTotal',
              width: 120,
              dataType: 'money'
            },
            ...tempColumns
          )
          // 加载数据
          this.dataSource = res.data.dataList.records ? res.data.dataList.records : []
          this.total = res.data.dataList.total
          this.pager.pageNum = res.data.dataList.current || 1 // 更新当前的分页信息
          this.pager.pageSize = res.data.dataList.size || 10 // 更新当前的分页信息
        }
      } else if (this.active === 1) {
        const res = await getZyList({
          insureMonth: this.billParam.month,
          insureCompanyId: this.billParam.insureCompanyId,
          type: this.active === 0 ? null : this.active,
          ...param
        })
        if (res.code === 100) {
          // 加载列
          this.columns = this.zyColumns
          // 加载数据
          this.dataSource = res.data.records ? res.data.records : []
          this.total = res.data.total
          this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
          this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
        }
      } else if (this.active === 2) {
        const res = await getJyList({
          insureMonth: this.billParam.month,
          insureCompanyId: this.billParam.insureCompanyId,
          type: this.active === 0 ? null : this.active,
          ...param
        })
        if (res.code === 100) {
          // 加载列
          this.columns = this.jyColumns
          // 加载数据
          this.dataSource = res.data.records ? res.data.records : []
          this.total = res.data.total
          this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
          this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
        }
      } else if (this.active === 3) {
        const res = await getSupplyListApi({
          insureMonth: this.billParam.month,
          insureCompanyId: this.billParam.insureCompanyId,
          type: this.active === 0 ? null : this.active,
          ...param
        })
        if (res.code === 100) {
          this.columns = this.bjColumns
          this.dataSource = res.data.records ? res.data.records : []
          this.total = res.data.total
          this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
          this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
        }
      }
    },
    detail(data) {
      this.$refs.detailDrawer.onShow({ ...data, insureMonth: this.billParam.month, insureCompanyId: this.billParam.insureCompanyId })
    },
    setTableHeader(val) {
      //   const len = this.columns.length
      this.columns = this.$options.data().columns
      switch (val) {
        case 0:
          this.columns = this.$options.data().columns
          this.columns.splice(7, 0, { title: '公司补缴调差', dataIndex: 'companySupply', key: 'companySupply' })
          this.columns.splice(9, 0, { title: '个人补缴调差', dataIndex: 'personSupply', key: 'personSupply' })
          break
        case 1:
          this.columns.splice(5, 0, { title: '增员原因', dataIndex: 'bb', key: 'bb' })
          break
        case 2:
          this.columns.splice(5, 3, { title: '减员原因', dataIndex: 'bb', key: 'bb' })
          break
      }
    },
    importExecl(data) {
      // this.$refs.importDrawer.onShow(data)
      if (this.active === 0) {
        exportInsureBillApi({
          insureMonth: this.billParam.month,
          insureCompanyId: this.billParam.insureCompanyId,
          insureClassify: data.type === '' ? undefined : data.type,
          ...this._queryParam
        })
      } else if (this.active === 1 || this.active === 2) {
        // 增员、减员的三种账单导出
        exportSocialZyApi({
          insureMonth: this.billParam.month,
          insureCompanyId: this.billParam.insureCompanyId,
          type: this.active,
          insureClassify: data.type,
          ...this._queryParam
        })
      } else if (this.active === 3) {
        exportSupplyPayApi({
          insureMonth: this.billParam.month,
          insureCompanyId: this.billParam.insureCompanyId,
          insureClassify: data.type,
          ...this._queryParam
        })
      }
    },
    // 补缴详情
    detailSupply(data) {
      const params = {
        insureMonth: this.billParam.month,
        insureCompanyId: this.billParam.insureCompanyId,
        onJobId: data.onJobId
      }
      this.$refs.supplyModal.show(params)
    },
    // 刷新
    refresh() {
      this._queryParam = {
        pageNum: 1,
        pageSize: 10
      }
      this.$refs.appForm.refresh()
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  height: calc(100% - 160px);
}
/deep/.ant-table-thead tr th {
  padding: 6px 8px !important;
  border-right: 0.5px solid @sc-greyBg-20 !important;
  border-bottom: 1px solid @sc-greyBg-20 !important;
}
</style>
