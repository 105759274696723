<template>
  <div>
    <Drawer
      title="详情"
      is-mask
      display-btn
      :visible="visible"
      size="small"
      :show-cancel="false"
      :show-confirm="false"
      :z-index="3"
      @close="onClose"
    >
      <div>
        <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item label="姓名" />
          <div class="transfer_content">
            {{ transferDetailData.staffName }}
          </div>
          <a-form-model-item label="调动类型" />
          <div class="transfer_content">
            {{ transferDetailData.transferTypeName }}
          </div>
          <a-form-model-item label="生效日期" />
          <div class="transfer_content">
            {{ transferDetailData.applyTransferDate }}
          </div>
          <div v-if="transferDetailData.afterOrgName !== transferDetailData.beforeOrgName">
            <a-form-model-item label="部门" class="line-left" :label-col="labelColC" :wrapper-col="wrapperColC" />
            <div class="transfer_content transfer_border"><div class="content_div" :title="transferDetailData.beforeOrgName">{{ transferDetailData.beforeOrgName }}</div> <span class="middle_span">变更为</span> <div class="content_div after_content_div" :title="transferDetailData.afterOrgName">{{ transferDetailData.afterOrgName }}</div> </div>
          </div>
          <!-- <div v-if="transferDetailData.beforeDutyName !== transferDetailData.afterDutyName">
            <a-form-model-item label="职务" class="line-left" :label-col="labelColC" :wrapper-col="wrapperColC" />
            <div class="transfer_content transfer_border"><div class="content_div" :title="transferDetailData.beforeDutyName">{{ transferDetailData.beforeDutyName }}</div> <span class="middle_span">变更为</span> <div class="content_div after_content_div" :title="transferDetailData.afterDutyName">{{ transferDetailData.afterDutyName }}</div> </div>
          </div> -->
          <div v-if="transferDetailData.beforePostName !== transferDetailData.afterPostName">
            <a-form-model-item label="岗位" class="line-left" :label-col="labelColC" :wrapper-col="wrapperColC" />
            <div class="transfer_content transfer_border"><div class="content_div" :title="transferDetailData.beforePostName">{{ transferDetailData.beforePostName }}</div> <span class="middle_span">变更为</span> <div class="content_div after_content_div" :title="transferDetailData.afterPostName">{{ transferDetailData.afterPostName }}</div> </div>
          </div>
          <div v-if="transferDetailData.beforeJobGradeName !== transferDetailData.afterJobGradeName">
            <a-form-model-item label="职级" class="line-left" :label-col="labelColC" :wrapper-col="wrapperColC" />
            <div class="transfer_content transfer_border"><div class="content_div" :title="transferDetailData.beforeJobGradeName">{{ transferDetailData.beforeJobGradeName }}</div> <span class="middle_span">变更为</span> <div class="content_div after_content_div" :title="transferDetailData.afterJobGradeName">{{ transferDetailData.afterJobGradeName }}</div> </div>
          </div>
          <div v-if="transferDetailData.beforeWorkplaceName !== transferDetailData.afterWorkplaceName">
            <a-form-model-item label="工作地点" class="line-left" :label-col="labelColC" :wrapper-col="wrapperColC" />
            <div class="transfer_content transfer_border"><div class="content_div" :title="transferDetailData.beforeWorkplaceName">{{ transferDetailData.beforeWorkplaceName }}</div> <span class="middle_span">变更为</span> <div class="content_div after_content_div" :title="transferDetailData.afterWorkplaceName">{{ transferDetailData.afterWorkplaceName }}</div> </div>
          </div>
          <div v-if="transferDetailData.beforeContractCompanyName !== transferDetailData.afterContractCompanyName">
            <a-form-model-item label="合同公司" class="line-left" :label-col="labelColC" :wrapper-col="wrapperColC" />
            <div class="transfer_content transfer_border"><div class="content_div" :title="transferDetailData.beforeContractCompanyName">{{ transferDetailData.beforeContractCompanyName }}</div> <span class="middle_span">变更为</span> <div class="content_div after_content_div" :title="transferDetailData.afterContractCompanyName">{{ transferDetailData.afterContractCompanyName }}</div> </div>
          </div>
          <a-form-model-item label="调动原因" />
          <div class="transfer_content reasons">{{ transferDetailData.reasons }}</div>
        </a-form-model>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { getTransferDetail } from '@/services/user/transfer.js'
import Drawer from '@/components/CbDrawer/index'
export default {
  name: 'TransferDetails',
  components: {
    Drawer
  },
  data() {
    return {
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      labelColC: { span: 7 },
      wrapperColC: { span: 17 },
      transferDetailData: {}
    }
  },
  methods: {
    show(id) {
      this.visible = true
      this.getTransferDetail(id)
    },
    async getTransferDetail(id) {
      const res = await getTransferDetail({
        transferApplyId: id
      })
      this.transferDetailData = res.data
    },
    // 取消
    onClose() {
      this.transferDetailData = {}
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
 .ant-form-item {
    margin-bottom: 0 !important;
  }
  .div-line {
    display: flex;
    justify-content: space-between;
    .line-left{
      width: 50%;
    }
    .line-right{
      width: 48%;
    }
  }
  /deep/ .ant-drawer-body{
    .ant-form-item-label{
      height: 35px;
    }
     .ant-form-item-label > label{
    color: rgba(0, 0, 0, .6);
    // color: red;
  }
  }
  .transfer_content{
    padding: 0;
    color: @sc-grey-80;
    margin-bottom: 24px;
    font-size: 15px;
  }
  .transfer_border{
    border-bottom: 1px solid @sc-greyBg-20;
    margin-top: 8px;
    padding-bottom: 10px;
    width: 100%;
    overflow: hidden;
  }
  .middle_span{
    color: @gray-7;
    margin-right: 30px;
  }
  .content_div{
    padding: 0;
    margin: 0;
    display: inline-block;
    vertical-align: bottom;
    width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .content_div:hover{
    color: @sc-primary-100;
  }
  .reasons{
    line-height: 25px;
    letter-spacing: 2px;
  }
  .after_content_div{
    font-weight: 500;
  }

</style>
