<!--
 * @Date: 2022-05-21 15:37:09
 * @descriotion:
-->
<template>
  <div id="schedulingCalendar" class="ShiftManagement">
    <appForm
      :to-list="formList"
      search-placeholder="请搜索"
      @getSearchCallback="getSearchCallback"
    >
      <template slot="customButton">
        <a-button type="primary" prefix="add-one" @click="addScheduling">
          新增排班日历
        </a-button>
      </template>
    </appForm>
    <custom-table
      id="table"
      row-key="shiftSchedulingId"
      :columns="columns"
      :data-source="dataSource"
      :pagination="false"
      @change="loadDataList"
    >
      <template slot="shiftCycleType" slot-scope="{scope}">
        <p>{{ scope.record.shiftCycleType | shiftCycleType }}</p>
      </template>
      <template slot="syncHolidayFlag" slot-scope="{scope}">
        <p>{{ scope.record.syncHolidayFlag ?'是':'否' }}</p>
      </template>
      <template slot="operation" slot-scope="{ scope}">
        <div class="flex-align-center">
          <template>
            <div class="handle-btn-box">
              <a-button type="link" @click="editScheduling(scope.record)">编辑</a-button>
            </div>
          </template>
          <a-popover
            trigger="hover"
          >
            <template slot="content">
              <div class="more-handle-btn">
                <!-- <a-button type="link" @click="editScheduling(scope.record)">编辑</a-button> -->
                <a-button type="link" @click="copyScheduling(scope.record)">复制</a-button>
                <a-button type="link" class="delete" @click="delet(scope.record)">删除</a-button>
              </div>
            </template>
            <div class="more-handle">
              ···
            </div>
          </a-popover>
        </div>
      </template>
    </custom-table>
    <AddScheduling ref="addScheduling" @ok="serchGetData" />
  </div>
</template>

<script>
import { shiftSchedulingListApi, shiftSchedulingDeleteApi } from '@/services/attendance/settingset.js'
import mixins from '@/components/CustomTable/index.js'
export default {
  name: 'Scheduling',
  components: {
    AddScheduling: () => import('./model/addScheduling.vue')
  },
  filters: {
    shiftCycleType(val) {
      return val === '1' ? '按周' : val === '2' ? '按月' : '无固定休息日'
    }
  },
  mixins: [mixins],
  data() {
    return {
      formList: ['search'], // search搜索框 screen筛选 choice位置选择弹窗 export导出
      columns: [
        {
          title: '排班名称',
          dataIndex: 'shiftSchedulingName',
          scopedSlots: { customRender: 'shiftSchedulingName' },
          key: 'shiftSchedulingName',
          customCell: () => {
            return {
              style: {
                'min-width': '200px'
              }
            }
          },
          ellipsis: true
        },
        {
          title: '排班类型',
          dataIndex: 'shiftCycleType',
          key: 'shiftCycleType',
          scopedSlots: { customRender: 'shiftCycleType' },
          customCell: () => {
            return {
              style: {
                'min-width': '140px'
              }
            }
          },
          ellipsis: true
        },
        {
          title: '同步法定节假安排',
          dataIndex: 'syncHolidayFlag',
          key: 'syncHolidayFlag',
          scopedSlots: { customRender: 'syncHolidayFlag' },
          width: '300px',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '100px',
          fixed: 'right'
        }],
      dataSource: [],
      shiftSchedulingId: ''
    }
  },
  computed: {},
  methods: {
    // 请求数据
    async fetchDataApiFn(data) {
      const res = await shiftSchedulingListApi(this._queryParam)
      if (res.code !== 100) return
      this.dataSource = res.data
    },
    // 增加
    addScheduling() {
      this.$refs.addScheduling.open()
    },
    // 编辑
    editScheduling(data) {
      this.$refs.addScheduling.open(data, 'edit')
    },
    // 复制
    copyScheduling(data) {
      this.$refs.addScheduling.open(data, 'copy')
    },
    // 删除
    delet(record) {
      this.shiftSchedulingId = record.shiftSchedulingId
      this.$Reconfirm({
        title: '删除排班日历',
        content: '确定要删除该排班日历吗？'
      }).then(async() => {
        const { shiftSchedulingId } = this
        const res = await shiftSchedulingDeleteApi({ shiftSchedulingId: [shiftSchedulingId] })
        if (res.code !== 100) return
        this.$message.success('删除成功')
        this.serchGetData()
      }).catch(() => {
        console.log('no')
      })
    },
    getSearchCallback(value) {
      this._queryParam.searchWord = value.searchValue
      console.log(this._queryParam)
      this.serchGetData()
    }
  }
}
</script>

<style lang="less" scoped>
p{
  margin:0;
}
.ShiftManagement{
  height: 100%;
  overflow: auto;
  background-color: #fff;
  padding:20px 20px 0;
  .color-div{
    width:24px;
    height:24px;
  }
}
.more-handle-btn {
    display: flex;
    flex-direction: column;
    .ant-btn {
      height:25px;
    }
  }
.more-handle {
  font-weight: 700;
  line-height: 44px;
  font-size: 20px;
  color: @sc-primary-100;
  vertical-align: baseline;
  cursor: pointer;
  margin-left: 5px;
}
.handle-btn-box{
  button{
    padding-left: 0;
    padding-right: 16px;
  }
}
</style>
