<!--
 * @Date: 2022-04-26 10:44:40
 * @descriotion:
-->
<template>
  <div id="rules" class="main-content beauty-scroll">
    <a-tabs default-active-key="1" @change="callback">
      <a-tab-pane key="1" tab="补卡">
        <CardRule />
      </a-tab-pane>
      <a-tab-pane key="2" tab="加班">
        <ExtraRule />
      </a-tab-pane>
      <a-tab-pane key="3" tab="外勤">
        <FieldRule />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import CardRule from './cardModal/index.vue'
import ExtraRule from './extraworkModal/index.vue'
import FieldRule from './fieldModal/index.vue'

export default {
  name: 'Rules',
  components: {
    CardRule,
    ExtraRule,
    FieldRule
  },

  data() {
    return {
      keys: '1'
    }
  },
  methods: {
    callback(key) {
      this.keys = key
    }
  }
}
</script>

<style lang="less" scoped>
.main-content {
  width:100%;
  height: 100%;
  background-color: #fff;
  overflow: auto;
  padding: 20px 20px 0;
}
/deep/.ant-tabs-tab{
  color: @sc-grey-100;
  font-size: 16px;
  padding-top: 0;
}
/deep/.ant-tabs-tab-active{
  color: @sc-primary-100 !important;
}
/deep/ .ant-tabs-nav-scroll {
  margin-top: -5px;
}
</style>
