<template>
  <div class="ShiftModal">
    <div>
      <Drawer
        v-if="shiftVisible"
        size="large"
        :z-index="8"
        :is-mask="false"
        :mask-closable="false"
        :visible="shiftVisible"
        :title="`${shiftType==='ordinary'?'普通':'复杂'}班次`"
        @close="shiftVisible = false"
        @onSubmit="onSave"
      >
        <div class="ShiftModal-content">
          <OrdinaryShift v-if="shiftType === 'ordinary'" ref="ordinary" :shift-type="shiftType" />
          <ComplexShift v-else ref="complex" :shift-type="shiftType" />
        </div>
        <template slot="preFooter">
          <!-- <a-tooltip v-show="formType !== 'add'" placement="topRight" trigger="hover">
            <template slot="title">
              <div>修改点将于明日生效</div>
            </template>
            <a-icon type="exclamation-circle" class="exclamation-circle" theme="filled" />
          </a-tooltip> -->
          <span v-show="formType !== 'add'">
            修改生效日期：<a-date-picker v-model="validDate" :disabled-date="disabledDate" value-format="YYYY-MM-DD" />
          </span>
        </template>
      </Drawer>
    </div>
    <Modal
      :closable="true"
      :visible="typeVisible"
      size="normal"
      title="班次类型"
      ok-text="确认"
      @ok="typeVisible = false,shiftVisible=true"
      @cancel="typeVisible=false"
    >
      <div class="typeModal-content">
        <div v-for="(item,i) in typeList" :key="i" :style="item.selected ? 'border-color:rgb(80, 127, 247)':''" class="type-box flex-just-between flex-align-center" @click="selected(item)">
          <div class="type-msg flex-align-center">
            <img :src="item.img">
            <div>
              <span>{{ item.name }}</span><br>
              <span>{{ item.content }}</span>
            </div>
          </div>
          <svg :style="`${item.selected ?'color:rgb(80, 127, 247)':'color:#fff'};fontSize:24px'`" class="iconpark-icon"><use href="#check" /></svg>
          <!-- <a-icon :style="`${item.selected ?'color:rgb(80, 127, 247)':'color:#fff'};fontSize:24px'`" type="check" />s -->
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { insertShift, getShiftInfo, updateShift, getNextPeriodEndDateApi } from '@/services/attendance/shift.js'
import { debounce } from '@/utils/index.js'
import moment from 'moment'
export default {
  name: 'ShiftModal',
  components: {
    Drawer: () => import('@/components/CbDrawer'),
    Modal: () => import('@/components/CbModal/index'),
    OrdinaryShift: () => import('./ordinaryShift/index.vue'),
    ComplexShift: () => import('./complexShift/index.vue')
  },
  props: {
    formType: {
      type: String,
      default: null
    },
    shiftId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      typeList: [
        {
          img: require('@/assets/img/attendance/ordinary.png'),
          name: '普通班次',
          type: 'ordinary',
          content: '灵活定义当天班次，支持早晚打卡、分段打卡',
          selected: true
        },
        {
          img: require('@/assets/img/attendance/complex.png'),
          name: '复杂班次',
          type: 'complex',
          content: '适用于跨天班次，且支持一天多段上班时间定义',
          selected: false
        }
      ],
      shiftVisible: false,
      typeVisible: false,
      // 班次类型
      shiftType: 'ordinary',
      timeGroup: [{}],
      validDate: null,
      endDate: null
    }
  },
  methods: {
    moment,
    async getShiftDetails(shiftId) {
      const res = await getShiftInfo({ shiftId: shiftId })
      if (res.code === 100) {
        this.$nextTick(() => {
          if (['A1', 'A2'].includes(res.data.shiftType)) {
            this.shiftType = 'ordinary'
          } else {
            this.shiftType = 'complex'
          }
          setTimeout(() => {
            this.$refs[this.shiftType].form = res.data
          }, 200)
        })
      }
      this.form = res.data
      this.validDate = res.data.validDate || moment(new Date()).add(1, 'days')
      const endDate = await getNextPeriodEndDateApi()
      if (endDate.code === 100) {
        this.endDate = endDate.data
      }
    },
    disabledDate(current) {
      return current && current < moment().endOf('day') || current >= moment(this.endDate).endOf('day')
    },
    // 保存
    onSave() {
      const data = JSON.parse(JSON.stringify(this.$refs[this.shiftType].form))
      for (let i = 0; i < data.shiftPunchRuleList.length; i++) {
        if (!data.shiftPunchRuleList[i].punchInStartTime || !data.shiftPunchRuleList[i].punchInEndTime || !data.shiftPunchRuleList[i].punchOutStartTime || !data.shiftPunchRuleList[i].punchOutEndTime) {
          this.$message.error(`${this.formType === 'add' ? '新增' : '编辑'}信息不完整！`)
          return
        }
      }
      // if (data.openLateAbnormalRule) {
      //   for (const item of data.abnormalLateRule) {
      //     if (!item.abnormalStartMinute || !item.abnormalEndMinute || !item.absentHour) {
      //       this.$message.error('请完善迟到惩罚规则')
      //       return
      //     }
      //   }
      // }
      // if (data.openEarlyAbnormalRule) {
      //   for (const item of data.abnormalEarlyRule) {
      //     if (!item.abnormalStartMinute || !item.abnormalEndMinute || !item.absentHour) {
      //       this.$message.error('请完善早退惩罚规则')
      //       return
      //     }
      //   }
      // }
      this.$refs[this.shiftType].$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.formType === 'add') {
            return this.addShift()
          }
          return this.editShift()
        }
        this.$message.error(`${this.formType === 'add' ? '新增' : '编辑'}信息不完整！`)
      })
    },
    // 添加班次
    addShift: debounce(async function() {
      const data = JSON.parse(JSON.stringify(this.$refs[this.shiftType].form))
      if (JSON.stringify(data.shiftPunchRuleList[0]) === '{}') {
        data.shiftPunchRuleList = []
      }
      // 普通班次 设置休息开关
      if (data.shiftType === 'A1') {
        data.shiftPunchRuleList[0].breakFlag = true
      } else if (data.shiftType === 'A2') {
        data.shiftPunchRuleList.forEach(item => {
          item.breakFlag = false
        })
      }
      const res = await insertShift({ ...data })
      if (res.code === 100) {
        this.$message.success('添加成功！')
        this.$parent.serchGetData()
        this.shiftVisible = false
      }
    }, 500),
    // 编辑班次
    editShift: debounce(async function() {
      const data = JSON.parse(JSON.stringify(this.$refs[this.shiftType].form))
      // 普通班次 设置休息开关
      if (data.shiftType === 'A1') {
        data.shiftPunchRuleList[0].breakFlag = true
      } else if (data.shiftType === 'A2') {
        data.shiftPunchRuleList.forEach(item => {
          item.breakFlag = false
        })
      }
      const res = await updateShift({ ...data, validDate: moment(this.validDate).format('YYYY-MM-DD') })
      if (res.code === 100) {
        this.$message.success('编辑成功！')
        this.$parent.serchGetData()
        this.shiftVisible = false
      }
    }, 500),
    // 检查时间表是否填写完成
    checkTimeGroup() {
      // const { shiftPunchRuleList } = this.form.shiftPunchRuleList
      // shiftPunchRuleList.forEach((v) =>{

      // })
    },
    selected(item) {
      this.typeList.forEach((v) => {
        v.selected = false
      })
      item.selected = true
      this.shiftType = item.type
    }
  }
}
</script>

<style lang="less" scoped>
.exclamation-circle{
  margin-left: 12px;
  color: @sc-primary-100;
}
.typeModal-content {
  .type-box {
    border: 1px solid @border-color;
    border-radius: 6px;
    padding: 18px 20px;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .type-msg {
      img {
        width: 40px;
        height: 40px;
      }
      span {
        margin-left: 12px;
      }
      span:first-child {
        .font-size(14px);
        color: @sc-grey-100;
      }
      span:last-child {
        .font-size(12px);
        color: @sc-grey-80;
      }
    }
    .iconpark-icon{
      width: 16px;
      height: 16px;
      color: @sc-primary-100;
    }
  }
  .type-box:hover {
    border-color: @sc-primary-100;
    .iconpark-icon{
      width: 16px;
      height: 16px;
      color: @sc-primary-100;
    }
  }
}
.ShiftModal-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid @sc-greyBg-20;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
  .close-btn,
  .ok-btn {
    width: 74px;
  }
}
</style>
