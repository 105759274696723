<!--个人信息-->
<template>
  <div class="person-info-p">
    <div class="person-anchor">
      <Anchor
        v-if="showAnchor"
        ref="anchor"
        :title-list="anchorList"
        container=".person-info"
        :style="{ height: anchorHeight() }"
      />
    </div>
    <div ref="personInfo" class="person-info beauty-scroll" :style="{ height: anchorHeight() }">
      <div class="person-info-download-p">
        <template v-for="(item, index) in infoList">
          <div
            v-if="(!item.multiple && item.content.list[0].columns.length > 0)
              || item.multiple
            "
            :key="index"
            class="com-panel"
          >
            <!--标题-->
            <div :id="'grid_' + item.fieldGroupId" class="com-panel-head">
              <BlockTitle :title="item.fieldGroupName">
                <template slot="center">
                  <div class="com-head-divide" />
                </template>
                <template slot="right">
                  <div v-if="!item.isEdit && item.multiple == 0 && statusType !== 'toExamine' && statusType !== 'resigned'" class="com-head-edit">
                    <a-button type="link" @click="openEdit(item)">编辑</a-button>
                  </div>
                </template>
              </BlockTitle>
            </div>
            <!--一对一表单-->
            <div v-if="!item.multiple" class="com-panel-body">
              <!--非编辑状态-->
              <div v-if="!item.isEdit" class="com-body-noEdit">
                <template v-for="(obj, objIndex) in item.content.list[0].columns">
                  <div
                    v-if="obj.list[0].show"
                    :key="objIndex"
                    class="com-body-item"
                  >
                    <div class="com-body-item-label">{{ obj.list[0].fieldName }}
                      <a-tooltip placement="right">
                        <template slot="title">
                          此试用期为计划试用期
                        </template>
                        <a-icon v-if="obj.list[0].fieldCode === 'probation'" class="tips_icon" type="exclamation-circle" theme="filled" />
                      </a-tooltip>
                    </div>
                    <div v-if="obj.list[0].fieldCode === 'probation'" class="com-body-item-value">{{ obj.list[0].fieldText ? obj.list[0].fieldText + '个月' : '' }}</div>
                    <div v-else-if="obj.list[0].fieldCode === 'nativeplace' || obj.list[0].fieldCode === 'address'">
                      <a-tooltip placement="bottom">
                        <template slot="title">
                          {{ obj.list[0].fieldText }}
                        </template>
                        <div class="com-body-item-value">{{ obj.list[0].fieldText }}</div>
                      </a-tooltip>
                    </div>
                    <div v-else class="com-body-item-value">{{ obj.list[0].fieldText }}</div>
                    <div v-if="!!obj.list[0].modify" class="com-body-item-update">已更新</div>
                  </div>
                </template>
              </div>
              <!--编辑状态-->
              <div v-else class="com-body-edit">
                <k-form-build
                  :ref="'KFormBuild' + index"
                  :engage-index="index"
                  :value="item.content || {}"
                  :dynamic-data="dynamicData"
                  :relation-key-obj="relationKeyObj"
                  :org-id="orgId"
                  :post-id="postId"
                  :has-relation="item.fieldGroupName === '在职信息'"
                  @change="changehand"
                />
                <div class="com-body-edit-btns">
                  <a-button
                    type="primary"
                    @click="saveForm(item, item.multiple, index, item.fieldGroupId)"
                  >
                    保存
                  </a-button>
                  <a-button @click="cancelForm(item)">取消</a-button>
                </div>

              </div>
            </div>
            <!--一对多表单-->
            <div v-else class="com-panel-multi-body">
              <div
                v-for="(item1, itemIndex) in item.content"
                :key="itemIndex"
                class="com-panel-multi-body-p"
              >
                <div class="com-body-title">
                  <div class="com-body-title-name">
                    {{ item.fieldGroupName }}{{ itemIndex + 1 }}
                    <span v-if="item1.modify === 1" class="com-body-item-update">已新增</span>
                    <span v-if="item1.modify === 2" class="com-body-item-update">已删除</span>
                  </div>
                  <div class="com-body-title-btns">
                    <a-button v-if="!item1.isEdit && statusType !== 'toExamine' && statusType !== 'resigned'" type="link" @click="openEdit(item1, index, itemIndex)">
                      编辑
                    </a-button>
                    <!-- item.content.filter((v) => !v.isAdd).length > 1 &&
                        item.content.length > 1 && -->
                    <a-button
                      v-if="
                        dataType(item1.dataId) !== 'Null' &&
                          !item1.isEdit &&
                          statusType !== 'toExamine' &&
                          statusType !== 'resigned'
                      "
                      type="link"
                      class="delete"
                      @click="
                        mulitDelHandle(item1, item.fieldGroupName + (itemIndex + 1), index, itemIndex)
                      "
                    >删除</a-button>
                  </div>
                </div>
                <div v-if="!item1.isEdit" class="com-body-noEdit">
                  <div
                    v-for="(obj, objIndex) in item1.content.list[0].columns"
                    :key="objIndex"
                    class="com-body-item"
                  >
                    <div class="com-body-item-label">{{ obj.list[0].fieldName }}</div>
                    <div class="com-body-item-value">{{ obj.list[0].fieldText }}</div>
                    <div v-if="!!obj.list[0].modify" class="com-body-item-update">已更新</div>
                  </div>
                </div>
                <div v-else class="com-body-edit">
                  <k-form-build
                    :ref="'KFormBuild' + index + 'Multi' + itemIndex"
                    :value="item1.content || {}"
                    :dynamic-data="dynamicData"
                    @change="changehand"
                  />
                  <div class="com-body-edit-btns1">
                    <a-button
                      type="primary"
                      @click="
                        saveForm(
                          item1,
                          item.multiple,
                          `${index}Multi${itemIndex}`,
                          item.fieldGroupId,
                          item1.dataId
                        )
                      "
                    >
                      保存
                    </a-button>
                    <a-button @click="cancelForm(item1, index, itemIndex)">取消</a-button>
                  </div>
                </div>
              </div>
              <div v-if="statusType !== 'toExamine' && statusType !== 'resigned'" class="com-body-add" @click="addMultiForm(index, item.content)">新增</div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getArchivesAllInfo,
  getStaffAllInfoByOnJobId,
  createStaffInfo,
  updateStaffInfo,
  deleteStaffContacts,
  deleteStaffEdu,
  deleteStaffWorkBefore,
  deleteStaffTrainBefore,
  deleteStaffContract,
  deleteStaffAward,
  createStaffNum,
  getEntryFormExamInfo,
  getLeaveArchivesByOnJobId
} from '@/services/user/roster.js'
import { getStaffNumConfig } from '@/services/user/setting.js'
import Anchor from '@/components/CbAnchor/index.js'
import { idCardReg, phoneReg } from '@/utils/reg.js'
// import { computedDate } from './date.js'
export default {
  name: 'PersonInfo',
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index'),
    Anchor
  },
  props: {
    userId: {
      type: String,
      default: ''
    },
    onJobId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      addstaffNumBtn: false,
      // staffNumDisabled: false,
      showAnchor: false, // 是否展示锚点导航
      infoList: [],
      delMultiItemObj: {}, // 删除数据
      dynamicData: {},
      // 组织、岗位、职级三级关联
      relationKeyObj: {
        org: '',
        post: '',
        rank: ''
      },
      orgId: '',
      postId: '',
      engagemodeId: '',
      certificatesType: '1',
      employmentType: false,
      employmentValue: '',
      listData: [],
      fieldIdCollection: {},
      enddate: '',
      startdate: '',
      oTmIndex: {},
      nativeplaceRequired: false,
      groupCodeArr: [],
      watchIdNum: '', // 监听身份证信息
      domIndex: '', // 保存当前操作dom的index（一对一）
      screenWidth: document.body.clientWidth
    }
  },
  inject: ['anchorHeight', 'statusType'],
  computed: {
    anchorList: function() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.groupCodeArr.splice(0)
      return this.infoList.filter(item => {
        this.groupCodeArr.push(item.groupCode)
        return (item.content.list && item.content.list.length > 0) || (item.content instanceof Array)
      }).map((v) => {
        return { id: `grid_${v.fieldGroupId}`, label: v.fieldGroupName }
      })
    },
    getEditItem: function() {
      // 获取当前编辑状态的表单ref值，没编辑表单则返回''
      for (let i = 0; i < this.infoList.length; i++) {
        if (this.infoList[i].isEdit && this.infoList[i].multiple === 0) {
          return `KFormBuild${i}`
        } else if (this.infoList[i].multiple === 1) {
          // 一对多关系
          const content = this.infoList[i].content
          for (let j = 0; j < content.length; j++) {
            if (content[j].isEdit) {
              return `KFormBuild${i}Multi${j}`
            }
          }
        }
      }
      return ''
    }
  },
  watch: {
    startdate(val) {
      this.compare(val, this.enddate, this.fieldIdCollection.startdate)
    },
    enddate(val) {
      this.compare(this.startdate, val, this.fieldIdCollection.enddate)
    },
    //  当编辑表单时 监听身份证号码回显籍贯 性别 出生日期
    watchIdNum(value) {
      if (this.certificatesType === '1' && value.length === 18) {
        const topSix = value.slice(0, 6)
        const sex = value.slice(16, 17)
        this.crossLevel(this.domIndex, this.fieldIdCollection.birthdate, value.slice(6, 10) + '-' + value.slice(10, 12) + '-' + value.slice(12, 14))
        this.crossLevel(this.domIndex, this.fieldIdCollection.sex, sex % 2 === 0 ? '1' : '0')
        const areaCode = {
          province: topSix.slice(0, 2) + '0000',
          city: topSix.slice(0, 4) + '00',
          county: topSix
        }
        this.crossLevel(this.domIndex, this.fieldIdCollection.nativeplace, areaCode)
      }
    }
  },
  mounted() {
    this.initData()
  },
  updated() {
    this.$refs.anchor ? this.$refs.anchor.getAllTitleOffsetTop() : null
  },
  methods: {
    // 比较开始时间和结束时间的大小
    /**
     * @param {Date} val 开始时间
     * @param {Date} time 结束时间
     * @param {String} clear 要清空的时间
     */
    compare(val, time, clear) {
      const start = new Date(val).getTime()
      const end = new Date(time).getTime()
      if (start > end) {
        this.$message.warning('结束时间不能早于开始时间')
        this.$refs[`KFormBuild${this.oTmIndex.index}Multi${this.oTmIndex.itemIndex}`][0].form.setFieldsValue({ [clear]: '' })
      }
      //  else if (start && end && this.fieldIdCollection.contractterm) {
      //   if (this.$refs[`KFormBuild${this.oTmIndex.index}Multi${this.oTmIndex.itemIndex}`]) {
      //     const key = this.fieldIdCollection.contractterm
      //     this.$refs[`KFormBuild${this.oTmIndex.index}Multi${this.oTmIndex.itemIndex}`][0].form.setFieldsValue({ [key]: computedDate(start, end) })
      //   }
      // }
    },
    // 展示锚点
    anchorShow() {
      this.$nextTick(() => {
        this.showAnchor = true
      })
    },
    // 开启编辑状态
    openEdit(item, index, itemIndex) {
      if (this.getEditItem === '') {
        // 没有正在编辑的表单，则切换为编辑状态
        item.isEdit = true
        this.oTmIndex = {
          index,
          itemIndex
        }
        item.content.list[0].columns.forEach((i) => {
          // 合同起止时间
          if (i.list[0].fieldCode === 'startdate') {
            this.fieldIdCollection.startdate = i.list[0].fieldId
            this.startdate = i.list[0].fieldValue ? i.list[0].fieldValue : undefined
          }
          if (i.list[0].fieldCode === 'enddate') {
            this.fieldIdCollection.enddate = i.list[0].fieldId
            this.enddate = i.list[0].fieldValue ? i.list[0].fieldValue : undefined
          }
          if (i.list[0].fieldCode === 'contractterm') {
            this.fieldIdCollection.contractterm = i.list[0].fieldId
          }
        })
      } else {
        this.showEditMessage()
      }
    },
    // 展示正在编辑表单的提示
    showEditMessage() {
      this.$message.warning((h) => {
        return h(
          'span',
          {
            class: 'message-p',
            style: {
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }
          },
          [
            h(
              'span',
              {
                class: 'message-p-text'
              },
              '您有正在编辑的表单'
            ),
            h(
              'a-button',
              {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginLeft: '5px'
                },
                on: {
                  click: () => this.locateToEditPanel()
                }
              },
              '继续'
            )
          ]
        )
      }, 3)
    },
    // 一对多删除确认框
    mulitDelHandle(item, name, index, itemIndex) {
      this.delMultiItemObj = { ...item }
      this.delMultiItemObj.index = index
      this.delMultiItemObj.itemIndex = itemIndex
      this.$Reconfirm({
        title: '删除数据',
        content: `确定要删除“${this.delMultiItemObj.title}${this.delMultiItemObj.itemIndex + 1}”数据？`
      }).then(async() => {
        // 一对多删除
        const { dataId, title } = this.delMultiItemObj
        if (dataId) {
          if (title === '紧急联系人' || title === '家庭成员') {
            await deleteStaffContacts({ contactId: dataId })
          }
          if (title === '教育经历') {
            await deleteStaffEdu({ eduId: dataId })
          }
          if (title === '工作经历') {
            await deleteStaffWorkBefore({ workId: dataId })
          }
          if (title === '培训记录') {
            await deleteStaffTrainBefore({ trainId: dataId })
          }
          if (title === '合同记录') {
            await deleteStaffContract({ contractRecordId: dataId })
          }
          if (title === '奖惩记录') {
            await deleteStaffAward({ awardId: dataId })
          }
          this.$message.success('删除成功')
          this.initData()
        } else {
          this.infoList[this.delMultiItemObj.index].content.splice(this.delMultiItemObj.itemIndex, 1)
        }
      }).catch(() => {
        console.log('no')
      })
    },
    // 新增动态表单
    async addMultiForm(index, content) {
      if (this.getEditItem === '') {
        const field = JSON.parse(JSON.stringify(content[0]))
        field.dataId = null // 清空dataId
        field.isEdit = true
        field.isAdd = true
        const columns = field.content.list[0].columns
        for (let i = 0; i < columns.length; i++) {
          columns[i].list[0].fieldText = undefined
          columns[i].list[0].fieldValue = undefined
          columns[i].list[0].options.defaultValue = undefined
        }
        this.infoList[index].content.push(field)
        this.oTmIndex.index = index
        this.oTmIndex.itemIndex = content.length - 1
        content[content.length - 1].content.list[0].columns.forEach((i) => {
          // 合同起止时间
          if (i.list[0].fieldCode === 'startdate') {
            this.fieldIdCollection.startdate = i.list[0].fieldId
            this.startdate = i.list[0].fieldValue ? i.list[0].fieldValue : undefined
          }
          if (i.list[0].fieldCode === 'enddate') {
            this.fieldIdCollection.enddate = i.list[0].fieldId
            this.enddate = i.list[0].fieldValue ? i.list[0].fieldValue : undefined
          }
          if (i.list[0].fieldCode === 'contractterm') {
            this.fieldIdCollection.contractterm = i.list[0].fieldId
          }
        })
      } else {
        this.showEditMessage()
      }
    },
    // 保存编辑
    saveForm(item, multiple, index, fieldGroupId = null, dataId = null) {
      this.$refs[`KFormBuild${index}`][0].getData().then((res) => {
        const form = {
          // staffId: this.userId,
          onJobId: this.onJobId,
          fieldGroupId: fieldGroupId,
          multiple,
          dataId,
          fields: []
        }
        for (const key in res) {
          if (typeof res[key] !== 'string' && res[key]) {
            form.fields.push({
              fieldId: key,
              fieldValue: res[key][0].value
            })
          } else {
            form.fields.push({
              fieldId: key,
              fieldValue: res[key] || ''
            })
          }
        }
        if (dataId) {
          updateStaffInfo(form).then((updateRes) => {
            if (updateRes.code === 100) {
              this.$message.success('编辑成功')
              this.initData()
              item.isEdit = false
              this.clearTime()
            } else {
              this.$message.error('编辑失败')
            }
          })
        } else {
          createStaffInfo(form).then((updateRes) => {
            form.fields.map((item) => {
            })
            if (updateRes.code === 100) {
              this.$message.success('保存成功')
              this.initData()
              item.isEdit = false
              this.$emit('updateInfo')
              this.clearTime()
            } else {
              this.$message.error('保存失败')
            }
          })
        }
      })
    },
    // 取消编辑
    cancelForm(item, index, itemIndex) {
      if (item.isAdd) {
        // 新增数据的取消编辑
        this.infoList[index].content.splice(itemIndex, 1)
      } else {
        // 现有数据的取消编辑
        item.isEdit = false
      }
      this.clearTime()
    },
    clearTime() {
      this.$refs[`KFormBuild${this.oTmIndex.index}Multi${this.oTmIndex.itemIndex}`] = undefined
      this.oTmIndex = {
        startdate: undefined,
        enddate: undefined
      }
      this.startdate = undefined
      this.enddate = undefined
      // 清除合同期限id
      this.fieldIdCollection.contractterm = undefined
    },
    // 删除某数组中的某项
    handleDelItem(arr, index) {
      arr = arr.filter((val, i) => i !== index)
    },
    // 重定位到编辑位置
    locateToEditPanel() {
      this.$refs.personInfo.scrollTo({
        left: 0,
        top: this.$refs[this.getEditItem][0].$el.offsetParent.offsetTop,
        behavior: 'smooth'
      })
    },
    async initData() {
      let res
      const config = await getStaffNumConfig()
      this.addstaffNumBtn = config.data.staffNumAuto === 0
      const { archivesPerfectId, pageType, statusType, onJobId, staffId } = this.$route.query
      // 修改接口 （从档案审核到员工信息页面）
      if (pageType === 'archive') {
        res = await getArchivesAllInfo({ archivesId: archivesPerfectId })
      } else if (statusType === 'toExamine') {
        res = await getEntryFormExamInfo({ staffId, onJobId })
      } else if (statusType === 'resigned') {
        res = await getLeaveArchivesByOnJobId({ staffId, onJobId })
      } else {
        res = await getStaffAllInfoByOnJobId({
          onJobId
        })
      }
      if (res.code === 100) {
        this.infoList = []
        const newformData = [...res.data.groups]
        for (let i = 0; i < newformData.length; i++) {
          const group = newformData[i]
          // 一对一的字段组
          if (group.multiple === 0) {
            const { content } = this.resolveSingleChild(group.fields, group.multiple)
            group.isEdit = false // 初始化不是编辑状态
            group.content = content
            delete group.fields
            // 整理对应的标题数据
            this.infoList.push(group)
            content.list[0].columns.forEach(i => {
              if (i.list[0].fieldCode === 'idtype') {
                this.certificatesType = i.list[0].fieldValue
              }
              if (i.list[0].fieldCode === 'engagemode') {
                if (i.list[0].fieldValue !== '') {
                  this.employmentValue = i.list[0].fieldValue
                }
              }
            })
          } else {
            // 一对多的字段组，例如紧急联系人
            const fields = group.fields
            group.content = []
            for (let j = 0; j < fields.length; j++) {
              const field = fields[j]
              const { content } = this.resolveSingleChild(field.fieldList, group.multiple)
              field.isEdit = false // 初始化不是编辑状态
              field.isAdd = false // 初始化不是新增数据
              field.content = content
              delete field.fieldList
              group.content.push(field)
            }
            group.isEdit = false // 初始化不是编辑状态
            delete group.fields
            // 整理对应的标题数据
            this.infoList.push(group)
          }
        }
        this.anchorShow()
      }
    },
    // 前端生成出对应的form设计数据 ?是否要增加判断 各个平台的显示
    resolveSingleChild(children, multiple, disabledType = 'edit', showType = 'show') {
      const content = {
        list: [
          {
            'type': 'grid',
            'label': '栅格布局',
            'columns': [],
            'options': {
              'gutter': 16
            },
            'key': 'grid_1646811746197'
          }
        ],
        config: {
          'layout': 'horizontal',
          'labelCol': { span: 5 },
          'labelWidth': 100,
          'labelLayout': 'block',
          'wrapperCol': { span: 12, offset: 1 },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      }
      const newList = []
      if (children && children.length !== 0) {
        const obj = {
          org: 'selectDepartment',
          post: 'selectPost'
        }
        // 工作地点  合同公司 汇报上级
        // field_type（1文本，2日期，3选项）
        const findType = (data) => {
          if (data.fieldType === 1) return 'input'
          if (data.fieldType === 2) return 'date'
          if (data.fieldType === 3 && ['org', 'post', 'jobgrade'].includes(data.fieldCode)) {
            if (data.fieldCode === 'jobgrade') {
              this.relationKeyObj['rank'] = data.fieldId
              return 'selectRank'
            } else {
              this.relationKeyObj[data.fieldCode] = data.fieldId
              if (obj[data.fieldCode] === 'jobgrade') return 'rank'
              return obj[data.fieldCode]
            }
          }
          if (data.fieldType === 3 && ['nativeplace'].includes(data.fieldCode)) return 'threeLevel'
          if (data.fieldType === 3 && ['workaddr', 'contractco', 'companyid', 'duty'].includes(data.fieldCode)) return 'selectDictionaries'
          if (data.fieldType === 3 && ['report'].includes(data.fieldCode)) return 'selectStaff'
          return 'select'
        }
        const echoVal = (data) => {
          const { fieldValue, fieldCode, fieldText } = data
          if (fieldValue || fieldValue === 0) {
            if (fieldCode === 'org') {
              this.orgId = fieldValue
              return [{
                label: fieldText,
                value: fieldValue
              }]
            }
            if (fieldCode === 'post') {
              this.postId = fieldValue
            }
            // 籍贯单独处理
            if (fieldCode === 'nativeplace') {
              const areaList = fieldValue.split(',')
              return {
                province: areaList[0] || '',
                city: areaList[1] || '',
                county: areaList[2] || ''
              }
            }
            return fieldValue
          }
          return undefined
        }
        const formateRule = (data) => {
          const { require, fieldName, fieldCode } = data
          const ruleList = []
          // 添加必填校验，required
          if (require === 1) {
            ruleList.push({
              required: true,
              message: `请输入${fieldName}`
            })
          }
          // 手机号校验
          if (fieldCode === 'phonenum') {
            ruleList.push({
              required: require === 1,
              validateTrigger: ['change'],
              validator: (rule, value, callback) => {
                if (!value) {
                  callback()
                }
                if (!phoneReg.test(value)) {
                  callback('请输入正确的手机号')
                  return
                }
                callback()
              }
            })
          }
          if (fieldCode === 'idnum') {
            ruleList.push({
              validateTrigger: ['change'],
              validator: (rule, value, callback) => {
                if (!value) {
                  callback()
                  return
                }
                if (this.certificatesType === '1' && !idCardReg.test(value)) {
                  callback('证件号格式不正确')
                  return
                }
                callback()
              }
            })
          }
          // 现居地址不能超过50字符
          if (fieldCode === 'address') {
            ruleList.push({
              validateTrigger: ['blur'],
              required: false,
              validator: (rule, value, callback) => {
                if (typeof value === 'undefined') {
                  callback()
                } else if (value.length > 50) {
                  callback('居住地址最多50字')
                  return
                }
                callback()
              }
            })
          }
          // 籍贯单独处理
          if (fieldCode === 'nativeplace') {
            ruleList.push({
              validateTrigger: ['change'],
              validator: (rule, value, callback) => {
                if (this.nativeplaceRequired) {
                  callback('请填充完毕省市区三级')
                  return
                } else {
                  callback()
                }
              }
            })
          }
          return ruleList
        }
        const dictionaryObj = {
          workaddr: 'work_address',
          contractco: 'contract_company',
          companyid: 'contract_company',
          duty: 'duty'
        }
        for (let i = 0; i < children.length; i++) {
          const orginData = children[i]
          const template = {
            ...orginData,
            type: findType(orginData),
            label: orginData.fieldName,
            model: orginData.fieldId,
            key: orginData.fieldId,
            options: {
              type: findType(orginData),
              width: '100%',
              placeholder: `请输入${orginData.fieldName}`,
              defaultValue: echoVal(orginData),
              range: false,
              showTime: false,
              clearable: true,
              maxLength: orginData.fieldLength,
              showLimit: ['province', 'city'],
              options: orginData.option,
              format: 'YYYY-MM-DD',
              hidden: orginData[showType] === 0,
              disabled: orginData[disabledType] === 0,
              areaShow: true,
              governmentAuto: true
            },
            rules: formateRule(orginData)
          }
          // 如果是工号的则添加自动生成工号的按钮
          if (orginData.fieldCode === 'staffnum') {
            this.fieldIdCollection.staffnum = orginData.fieldId
            template.options['disabled'] = false
          }
          // 聘用形式
          if (orginData.fieldCode === 'engagemode' && orginData[disabledType] !== 0) {
            this.fieldIdCollection.engagemode = orginData.fieldId
            this.engagemodeId = orginData.fieldId
          }
          // 生日
          if (orginData.fieldCode === 'birthdate' && orginData[disabledType] !== 0) {
            this.fieldIdCollection.birthdate = orginData.fieldId
          }
          // 性别
          if (orginData.fieldCode === 'sex' && orginData[disabledType] !== 0) {
            this.fieldIdCollection.sex = orginData.fieldId
          }
          if (orginData.fieldCode === 'staffname' && orginData[disabledType] !== 0) {
            template.options['min'] = 2
          }
          // 证件类型
          if (orginData.fieldCode === 'idtype' && orginData[disabledType] !== 0) {
            this.fieldIdCollection.idtype = orginData.fieldId
          }
          // 证件号码
          if (orginData.fieldCode === 'idnum' && orginData[disabledType] !== 0) {
            this.fieldIdCollection.idnum = orginData.fieldId
          }
          if (orginData.fieldCode === 'nativeplace' && orginData[disabledType] !== 0) {
            this.fieldIdCollection.nativeplace = orginData.fieldId
          }
          // 如果是字典表项的话，添加字典表专属入参数据parameter
          if (dictionaryObj[orginData.fieldCode]) {
            template.options['parameter'] = dictionaryObj[orginData.fieldCode]
          }
          newList.push({
            span: 12,
            list: [template]
          })
        }
      }
      content.list[0].columns = newList
      return { content }
    },
    async createStaffNumFunc(data, index) {
      // 入职类型（新员工1， 再入职2）
      // 身份证号
      // 聘用形式
      const res = await createStaffNum({ entryType: '1', idNum: '', engage: this.employmentValue })
      const json = {}
      json[data.fieldId] = res.data
      this.$refs[`KFormBuild${index + 1}`][0].setData(json)
    },
    /**
     * @param value -----select value
     * @param key -------字典id
     * @param index -----编辑虚拟DOM标识
     * @function setFieldsValue() 双向绑定清空value值 @param {object} key:value
     */
    changehand(value, key, index) {
      if (typeof this.oTmIndex.index !== 'undefined') {
        this.listData = Object.keys(this.$refs[`KFormBuild${this.oTmIndex.index}Multi${this.oTmIndex.itemIndex}`][0].form.getFieldsValue())
        switch (key) {
          // 合同开始时间
          case this.fieldIdCollection.startdate:
            this.startdate = value
            break
          // 合同结束时间
          case this.fieldIdCollection.enddate:
            this.enddate = value
            break
        }
      } else {
        this.listData = Object.keys(this.$refs[`KFormBuild${index}`][0].form.getFieldsValue())
        switch (key) {
          // 证件类型
          case this.fieldIdCollection.idtype:
            this.crossLevel(index, this.fieldIdCollection.idnum, '')
            this.certificatesType = value
            break
          // 身份证号码
          case this.fieldIdCollection.idnum:
            this.watchIdNum = value.trim()
            this.domIndex = index
            break
          // 聘用类型
          case this.fieldIdCollection.engagemode:
            this.crossLevel(index, this.fieldIdCollection.staffnum, '')
            this.employmentValue = value
            this.employmentType = typeof value === 'undefined'
            break
          // 开始时间
          case this.fieldIdCollection.startdate:
            this.startdate = value
            break
          // 结束时间
          case this.fieldIdCollection.enddate:
            this.enddate = value
            break
          case this.fieldIdCollection.nativeplace:
            this.nativeplaceRequired = typeof value === 'undefined'
            break
        }
      }
      // 更新按钮状态
      this.addButton()
      this.addUnit()
      // this.addTips()
      // this.addtrainUnit()
    },
    /**
     *
     * @param {String} code
     */
    groupCodeFun(code) {
      let index
      this.groupCodeArr.map((item, i) => {
        if (item === code) {
          index = i
        }
      })
      return index
    },
    addButton() {
      this.infoList[this.groupCodeFun('onjob')].content.list[0].columns.forEach(item => {
        if (item.list[0].fieldCode === 'staffnum') {
          this.$set(item.list[0].options, 'addonAfter', () => {
            return (<a-button ref='addbtn' disabled={this.addstaffNumBtn ? true : !!this.employmentType} onClick={this.createStaffNumFunc.bind(this, { fieldId: this.fieldIdCollection.staffnum }, 0)}>自动生成</a-button>)
          })
        }
      })
      this.$forceUpdate()
    },
    addUnit() {
      this.infoList[this.groupCodeFun('onjob')].content.list[0].columns.forEach(item => {
        if (item.list[0].fieldCode === 'probation') {
          this.$set(item.list[0].options, 'addonAfter', () => {
            return (<span class='unit_span'>(月)</span>)
          })
        }
      })
      this.$forceUpdate()
    },
    addTips() {
      this.infoList[this.groupCodeFun('onjob')].content.list[0].columns.forEach(item => {
        if (item.list[0].fieldCode === 'probation') {
          this.$set(item.list[0].options, 'addonBefore', () => {
            return (
              <div class='tips'>
                <a-tooltip placement='right'>
                  <template slot='title'>
                          此试用期为计划试用期
                  </template>
                  <a-icon type='exclamation-circle' theme='filled' class='tips_icon' />
                </a-tooltip>
              </div>
            )
          })
        }
      })
      this.$forceUpdate()
    },
    crossLevel(index, key, value) {
      this.$refs[`KFormBuild${index}`][0].form.setFieldsValue({ [key]: value })
    },
    dataType(data) {
      return Object.prototype.toString.call(data).split(' ')[1].slice(0, -1)
    }
  }
}
</script>

<style lang="less" scoped>
.tips_icon{
  color: @gray-8;
}
.tips{
  /deep/ .anticon{
    background-color: #fff;
    position: relative;
    // left: -70px;
    // top: 8px;
    left: 0;
    top: 0;
  }
}
/deep/ .ant-input-group-addon{
  background-color: #fff;
}
// 工号自动生成使用插槽插入，需要把自带的padding清除了
/deep/.ant-input-group-addon{
  padding: 0;
  border: none;
}
.person-info-p {
  display: flex;
  .person-anchor {
    position: relative;
    width: 180px;
    padding-left: 20px;
    margin-top: 20px;
  }
  .person-info {
    overflow-y: auto;
    flex: 1;
    padding-bottom: 20px;
    height: 100vh;
    .person-info-download-p {
      padding-left: 20px;
      padding-right: 20px;
    }
    .com-panel {
      .com-panel-head {
        padding: 20px 0;
        .flex-between-center();
        .com-head-title {
          position: relative;
          padding-left: 7px;
          color: @sc-grey-100;
          margin-right: 10px;
          .font-size(16px);
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 3px;
            height: 14px;
            background: @sc-primary-100;
          }
        }
        .com-head-divide {
          flex: 1;
          height: 1px;
          background-color: @sc-greyBg-20;
        }
        .com-head-edit {
          margin-left: 10px;
        }
      }
      .com-panel-body {
        padding-left: 100px;
        .com-body-noEdit {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: wrap;
          .com-body-item {
            position: relative;
            width: 45%;
            padding: 25px 0 0 0px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .com-body-item-label {
              width: 85px;
              white-space: pre-wrap;
              .font-size(14px);
              color: @sc-grey-100;
            }
            .com-body-item-value {
              margin-left: 20px;
              max-width: 300px;
              .multi-ellipsis(1);
              .font-size(14px);
              color: @sc-grey-100;
            }
            .com-body-item-update {
              transform: translate(10px, -2px);
              margin-left: 10px;
              width: 58px;
              height: 26px;
              border-radius: 4px;
              text-align: center;
              opacity: 1;
              border: 1px solid @sc-primary-100;
              .font-size(14px);
              line-height: 1.6;
              color: @sc-primary-100;
            }
          }
        }
        .com-body-edit {
          .com-body-edit-btns {
            padding: 0 20% 0 10%;
            display: flex;
            justify-content: center;
            .ant-btn {
              margin-left: 40px;
              width: 88px;
              height: 32px;
            }
          }
        }
      }
      .com-panel-multi-body {
          padding-left: 100px;
        .com-panel-multi-body-p {
          padding-bottom: 25px;
          position: relative;
          .com-body-title {
            width: 100%;
            height: 34px;
            line-height: 34px;
            background-color: @sc-greyBg-10;
            border-radius: 4px;
            .flex-between-center();
            .com-body-title-name {
              .font-size(14px);
              padding-left: 13px;
              color: @sc-grey-100;
              .com-body-item-update {
                display: inline-block;
                transform: translate(10px, -2px);
                width: 58px;
                height: 26px;
                border-radius: 4px 4px 4px 4px;
                text-align: center;
                opacity: 1;
                border: 1px solid @sc-primary-100;
                .font-size(14px);
                line-height: 1.6;
                color: @sc-primary-100;
              }
            }
            .com-body-title-btns{
              /deep/.ant-btn{
                margin-right: 12px;
              }
            }
          }
        }
        .com-body-noEdit {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: wrap;
          .com-body-item {
            position: relative;
            width: 45%;
            padding: 25px 0 0 0;
            margin-right: 0px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .com-body-item-label {
              width: 85px;
              white-space: pre-wrap;
              .font-size(14px);
              color: @sc-grey-100;
            }
            .com-body-item-value {
              margin-left: 20px;
              max-width: 300px;
              .multi-ellipsis(1);
              .font-size(14px);
              color: @sc-grey-100;
            }
            .com-body-item-update {
              transform: translate(10px, -2px);
              width: 58px;
              height: 26px;
              border-radius: 4px 4px 4px 4px;
              text-align: center;
              opacity: 1;
              border: 1px solid @sc-primary-100;
              .font-size(14px);
              line-height: 1.6;
              color: @sc-primary-100;
            }
          }
        }
        .com-body-edit {
          padding-left: 86px;
          padding-top: 25px;
          .com-body-del {
            text-align: right;
            font-size: 12px;
            color: @error-color;
            .body-del-btn {
              cursor: pointer;
            }
          }
        }
        .com-body-edit-btns1 {
          padding: 0 20% 0 10%;
          display: flex;
          justify-content: center;
          .ant-btn {
            margin-left: 40px;
            width: 88px;
            height: 32px;
          }
        }
        .com-body-add {
          height: 40px;
          .flex-center();
          .font-size(14px);
          color: @sc-grey-60;
          background: @sc-greyBg-10;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 1px dashed @sc-greyBg-50;
          cursor: pointer;
        }
      }
    }
  }
  /deep/.ant-form-item-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 39px;
    white-space: pre-line;
    line-height: 20px;
  }
}
.message-p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
 /deep/ .anchor-item-wrapper:before {
     background: @sc-greyBg-20;
 }
 /deep/ .anchor-item-wrapper:after {
      background: @sc-greyBg-20;
}
/deep/ .anchor-circle {
      background: @sc-greyBg-20;
}
/deep/ .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input{
  border-radius: 4px;
}
</style>
<style>
.ant-message-custom-content {
  display: flex;
}
.unit_span{
  padding: 7px 5px;
  background-color: #fff;
}
@media screen and (max-width: 1000px) {
  .com-body-noEdit{
    flex-direction: column;
    padding-left: 150px;
  }
  .com-body-item{
    width: 80% !important;
  }
}
</style>
