<template>
  <div>
    <!--  导航  -->
    <div class="top_navigation">
      <a-button class="top_navigation_button" type="iconBtn" icon="left" @click="goBack" />
      <!-- <div class="top_navigation_button" @click="goBack"><a-icon type="left" /></div> -->
      <span class="archiveRecord">档案记录</span>
    </div>
    <!--  tab切换  -->
    <div style="margin-top: 4px">
      <a-tabs v-model="tabType" @change="callback">
        <a-tab-pane key="1" tab="待完善" />
        <a-tab-pane key="2" tab="待审核" />
        <a-tab-pane key="3" tab="已通过" />
        <a-tab-pane key="4" tab="未通过" />
      </a-tabs>
    </div>
    <!--  表格  -->
    <div>
      <appForm>
        <template slot="customButton">
          <a-space>
            <a-button v-if="tabType === '1'" prefix="add-one" @click="handleAdd">
              批量邀请完善
            </a-button>
          </a-space>
        </template>
      </appForm>
      <!-- row-key="staffId" -->
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        row-key="archivesPerfectId"
        table-ref=".user-list"
        :pagination="{
          current: pager.pageNum,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条数据`,
          showQuickJumper: true,
          showSizeChanger: true
        }"
        @change="loadDataList"
      >
        <!-- 序号 -->
        <template slot="index" slot-scope="{ scope }">
          <span>{{ scope.index + 1 }}</span>
        </template>
        <!-- 姓名头像 -->
        <template slot="staffName" slot-scope="{ scope }">
          <CbTableAvater :table-avater="scope.record.avatar" :on-job-id="scope.record.onJobId" :table-name="scope.record.staffName" :staff-id="scope.record.staffId" />
        </template>
        <!-- 操作列 -->
        <template slot="operation" slot-scope="{ scope }">
          <a-button v-if="tabType === '1'" type="link" @click="getInvitationPerfect(scope.record.staffId, '是否确认邀请完善')">邀请完善</a-button>
          <a-button v-if="tabType === '2'" type="link" @click="entryAudit(scope)">审核</a-button>
          <a-button v-if="tabType === '4'" type="link" @click="getInvitationPerfect(scope.record.staffId, '是否重新发送邀请')">重新发送邀请</a-button>
        </template>
      </custom-table>
    </div>
    <PerDepModel
      v-model="model"
      :placeholder="'请输入部门、人员、工号'"
      :get-per-data-list="getPerDataList"
      :search-value-list="searchValueList"
      @perModelConfirm="perModelConfirm"
    />
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import mixinss from '@/components/CbPerDepSelection/index.js'
import CbTableAvater from '@/components/CbTableAvater/index'
import { listArchivesPerfect, invitationPerfect, getStaffList, invitationBatchPerfect } from '@/services/user/archive.js'
export default {
  name: 'Information',
  components: { CbTableAvater },
  mixins: [mixins, mixinss],
  props: {},
  data() {
    return {
      archiveStaffId: '',
      archiveTitle: '',
      model: false,
      tabType: '1',
      columns: [],
      toBeImproved: [
        {
          title: '序号',
          dataIndex: 'index',
          width: '50px',
          fixed: 'left',
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          width: 105,
          fixed: 'left',
          scopedSlots: { customRender: 'staffName' }
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          key: 'phoneNum',
          width: 120,
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          width: 125,
          fixed: 'left',
          ellipsis: true,
          key: 'staffNum'
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '整体信息(%)',
          dataIndex: 'beforeAllRate',
          key: 'beforeAllRate',
          width: 125
        },
        {
          title: '个人信息(%)',
          dataIndex: 'beforeInfoRate',
          key: 'beforeInfoRate',
          width: 125
        },
        {
          title: '附件材料(%)',
          dataIndex: 'beforeFileRate',
          key: 'beforeFileRate',
          width: 125
        },
        {
          title: '操作',
          dataIndex: 'operation',
          // fixed: 'right',
          // width: 100,
          scopedSlots: { customRender: 'operation' }
        }
      ], // 待完善
      toBeReviewed: [
        {
          title: '序号',
          dataIndex: 'index',
          width: '50px',
          fixed: 'left',
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          width: 105,
          fixed: 'left',
          scopedSlots: { customRender: 'staffName' }
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          key: 'phoneNum',
          width: 120,
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          width: 125,
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '修改前完整度（%）',
          dataIndex: 'beforeAllRate',
          key: 'beforeAllRate'
        },
        {
          title: '修改后完整度(%)',
          dataIndex: 'afterAllRate',
          key: 'afterAllRate'
        },
        {
          title: '提交时间',
          dataIndex: 'submitTime',
          key: 'submitTime'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          fixed: 'right',
          width: 100,
          scopedSlots: { customRender: 'operation' }
        }
      ], // 待审核
      passedList: [
        {
          title: '序号',
          dataIndex: 'index',
          width: '50px',
          fixed: 'left',
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          width: 105,
          fixed: 'left',
          scopedSlots: { customRender: 'staffName' }
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          key: 'phoneNum',
          width: 120,
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          width: 125,
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '修改前完整度（%）',
          dataIndex: 'beforeAllRate',
          key: 'beforeAllRate'
        },
        {
          title: '修改后完整度(%)',
          dataIndex: 'afterAllRate',
          key: 'afterAllRate'
        },
        {
          title: '提交时间',
          dataIndex: 'submitTime',
          key: 'submitTime'
        }
      ], // 已通过
      noPassedList: [
        {
          title: '序号',
          dataIndex: 'index',
          width: '50px',
          align: 'center',
          fixed: 'left',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          width: 105,
          fixed: 'left',
          scopedSlots: { customRender: 'staffName' }
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          key: 'phoneNum',
          width: 120,
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          width: 125,
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '修改前完整度（%）',
          dataIndex: 'beforeAllRate',
          key: 'beforeAllRate'
        },
        {
          title: '修改后完整度(%)',
          dataIndex: 'afterAllRate',
          key: 'afterAllRate'
        },
        {
          title: '提交时间',
          dataIndex: 'submitTime',
          key: 'submitTime'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          fixed: 'right',
          width: 120,
          scopedSlots: { customRender: 'operation' }
        }
      ], // 未通过
      dataSource: [],
      total: 0
    }
  },
  // !从详情返回档案时刷新列表数据
  // watch: {
  //   $route(val) {
  //     if (val.meta.subName === '档案') {
  //       this.fetchDataApiFn(this.pager)
  //     }
  //   }
  // },
  created() {
    // 首页判断跳转参数
    if (this.$route.params.isHome) {
      this.tabType = '2'
    }
  },
  methods: {
    // 获取数据
    async fetchDataApiFn(param) {
      this.columns = this.tabType === '1'
        ? this.toBeImproved : this.tabType === '2'
          ? this.toBeReviewed : this.tabType === '3'
            ? this.passedList : this.noPassedList
      const res = await listArchivesPerfect({
        ...param,
        status: this.tabType === '1' ? '1' : this.tabType === '2' ? '2' : this.tabType === '3' ? '3' : '4'
      })
      this.dataSource = res.data.archivesPerfectList ? res.data.archivesPerfectList : []
      this.pager.pageNum = res.data.pageNum || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.pageSize || 10 // 更新当前的分页信息
      this.total = res.data.total
    },
    // tab切换
    callback() {
      this.dataSource = []
      this._queryParam.status = this.tabType === '1' ? '1' : this.tabType === '2' ? '2' : this.tabType === '3' ? '3' : '4'
      this.serchGetData()
    },
    // 邀请完善
    getInvitationPerfect(staffId, title) {
      this.archiveStaffId = staffId
      this.archiveTitle = title
      this.$Reconfirm({
        title: '邀请完善',
        content: `${this.archiveTitle}?`
      }).then(async() => {
        const res = await invitationPerfect({ staffId: this.archiveStaffId })
        if (res.code === 100) {
          this.$message.success('发送成功')
          this._queryParam.status = this.tabType === '1' ? '1' : this.tabType === '2' ? '2' : this.tabType === '3' ? '3' : '4'
        }
      }).catch(() => {
        console.log('no')
      })
    },
    // 审核
    entryAudit(data) {
      this.$router.push({
        path: '/staffIndex/userDetail',
        query: {
          staffId: data.record.staffId,
          onJobId: data.record.onJobId,
          archivesPerfectId: data.record.archivesPerfectId,
          statusType: 'toExamine', // 审核
          pageType: 'archive',
          path: encodeURIComponent(this.$route.fullPath)
        }
      })
    },
    async getPerDataList(id, name) {
      const res = await getStaffList({
        orgId: id,
        staffName: name
      })
      return res.data
    },
    // 人员部门选择搜索
    async searchValueList(value) {
      return this.getPerDataList(null, value)
    },
    // 批量按钮
    handleAdd() {
      this.model = true
    },
    // 弹窗确认
    async perModelConfirm(data) {
      const staffIdList = []
      const orgIdList = []
      if (data.length === 0) {
        this.$message.warning('请选择员工！')
        return
      }
      for (let i = 0; i < data.length; i++) {
        if (data[i].identification === 'peo') {
          staffIdList.push(data[i].id)
        } else if (data[i].identification === 'dep') {
          orgIdList.push(data[i].id)
        }
      }
      const res = await invitationBatchPerfect({
        staffIdList: staffIdList,
        orgIdList: orgIdList
      })
      if (res.code === 100) {
        this.$message.success('发送成功')
        this.model = false
        this.serchGetData()
      }
    },
    // 返回
    goBack() {
      this.tabType = '1'
      this.$emit('goBack')
    }
  }
}
</script>

<style lang="less" scoped>
  .top_navigation{
    display: flex;
    align-items: center;
    height: 30px;
    font-size: 16px;
    color: @sc-grey-100;
    margin-top: -5px;
    .top_navigation_button {
      width: 20px;
      height: 20px;
      font-size: 12px;
      margin-right: 6px;
      padding: 1px;
    }
    .archiveRecord {
      color: @sc-grey-100;
      font-weight: bold;
      line-height: 22px;
    }
  }
  /deep/ .ant-tabs-tab {
    color:  @sc-grey-100;
  }
  /deep/ .ant-tabs-tab-active{
    color: @sc-primary-100;
    font-weight: 500;
  }
  /deep/ .ant-tabs-ink-bar {
    height: 1px !important;
  }
  /deep/ .ant-tabs-tab:hover {
    color: @sc-primary-100;
  }
</style>
