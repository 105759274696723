<template>
  <div class="content">
    <Calendar v-if="test" :default-month="new Date(time)" :start-date="calendarStartDate" :end-date="calendarEndDate" :future="false" @onChange="onChange">
      <template slot="leftContent">
        <div class="header">
          <a-button class="topbtn" type="iconBtn" icon="left" @click="goBack" />
          <span>返回</span>
        </div>
      </template>
      <template slot="rightContent">
        <div class="rightnav">
          <CbTableAvater :avater-name="staffName" :show-user-name="false" :on-job-id="staffId" size="34" />
          <span class="thename">{{ staffName }}</span>
          <div class="right-rule">
            <a-popover trigger="click" :visible="popover" placement="bottomRight" overlay-class-name="check-box" :overlay-style="{width:'380px'}">
              <template slot="title">
                <div style="padding: 9px 0 10px;">考勤规则</div>
              </template>
              <template slot="content">
                <div v-for="item in attendanceRules" :key="item.id" class="rule-box">
                  <div class="left-box">
                    <div class="left-content">{{ item.ruleName }}</div>
                    <div class="right-content">{{ item.rulePlan }}</div>
                  </div>
                  <a-button class="right-box" type="link" :disabled="!item.ruleId" @click="view(item.ruleId,item.ruleName)">查看</a-button>
                </div>
              </template>
              <a-button type="default" @click="button">
                考勤规则
              </a-button>
            </a-popover>

          </div>
          <div class="personTab">
            <a-button prefix="turn-around" @click="userTab">人员切换</a-button>
          </div>
        </div>
      </template>
      <template slot="bottomContent">
        <div class="content-top">
          <div v-if="actualDays>0" type="default" :class="active ===''? 'active':'' " class="statistics" @click="buttonTab('',null)">
            出勤  {{ actualDays }} 天
          </div>
          <div v-for="item in attendanceList" :key="item.id" type="default" @click="buttonTab(item.value,item.id)">
            <div v-if="item.count>0" class="statistics" :class="active ===item.value ? 'active':'' ">
              <span :style="`backgroundColor:${item.fontRgba}`" class="text-color" />
              {{ item.value }}  <span :style="`color:${item.fontRgba};padding: 0 5px`">{{ item.count }}</span> {{ item.unit }}
            </div>
          </div>
        </div>
        <div class="content-bottom">
          <p v-for="item in attendanceTotal" :key="item.id">
            {{ item.value }}:
            <span>{{ item.count }}</span>
          </p>
        </div>
      </template>
      <template v-for="item in calendarList.monthDetailVO" :slot="item.date" style="width: 100% ; position: relative;">
        <div v-if="item.holidayFlag==='1' || item.offdayFlag==='1'" :key="item.data" class="button"> {{ item.holidayFlag==='1' ?'节假':'休息' }} </div>
        <div :key="item.data">
          <div v-if="item.workdayFlag==='1'" class="times"><span>{{ item.timeList[0].actualInTime || '--' }}</span><span>—</span> <span>{{ item.timeList[0].actualOutTime || '--' }}</span></div>
          <div class="events" :class="{'active-events': hasThree(item)}">
            <div v-for="(col, index) in abnormals" :key="index">
              <div v-if="item[col.statistics]" class="badge" :style="{background:col.backgroundColor,color:col.color }">
                <p class="literals">{{ item[col.statistics] }}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Calendar>
    <Drawer
      title="人员选择"
      placement="right"
      closable
      display-btn
      is-mask
      :visible="visible"
      size="small"
      @close="onClose"
    >
      <a-input-search placeholder="请输入姓名/工号" style="width: 100%" @search="onSearch" />
      <div class="staff-box">
        <div
          v-for="item in userList"
          :key="item.onJobId"
          class="left"
        >
          <div class="content" @click="onClick(item)">
            <CbTableAvater :avater-name="item.staffName" :on-job-id="item.onJobId" :show-user-name="false" />
            <p :title="item.staffName">{{ item.staffName }}</p>
            <span class="num" :title="item.staffNum">{{ item.staffNum }}</span>
          </div>
        </div>
      </div>
    </Drawer>
    <Drawer
      :title="`${title}详情`"
      placement="right"
      :closable="false"
      is-mask
      display-btn
      :visible="detailsVisible"
      size="small"
      @close="onClose"
    >
      <div v-if="title==='考勤组'">
        <div class="title">
          考勤组名称:<span>{{ attendanceGroupDetails.attendanceGroupName }}</span>
        </div>
        <div class="title">
          考勤组类型:<span>{{ attendanceGroupDetails.attendanceGroupType==='1'?'无需打卡':'需打卡' }}</span>
        </div>
        <div class="title">
          排班日历:<span>{{ attendanceGroupDetails.shiftScheduling.shiftSchedulingName }}</span>
        </div>
        <div class="title">
          出勤日:<div class="text-box">{{ attendanceGroupDetails.shiftScheduling.workCycleSequenceStr }}</div>
        </div>
        <div class="title">
          出勤时间:<div class="text-box">{{ attendanceGroupDetails.shiftScheduling.shiftPunchRuleStr }}</div>
        </div>
        <div class="title">
          核算规则:<div class="text-box">考勤规则--{{ attendanceGroupDetails.overTimeRuleName }}<br>外勤规则--{{ attendanceGroupDetails.outSideRuleName }}<br>补卡规则--{{ attendanceGroupDetails.appendPunchRuleName }}</div>
        </div>
        <div class="title">
          适用人员:<div class="text-box">{{ attendanceGroupDetails.attendanceGroupStaffStr }}</div>
        </div>
      </div>
      <div v-if="title==='考勤日历'">
        <div class="title">
          排班日历名称:<span>{{ shiftScheduling.shiftSchedulingName }}</span>
        </div>
        <div class="title">
          排班类型:<span>{{ shiftScheduling.shiftCycleType|shiftCycleType }}</span>
        </div>
        <div class="title">
          是否同步法定节假安排:<span>{{ shiftScheduling.syncHolidayFlag ?'是':'否' }}</span>
        </div>
      </div>
      <div v-if="title==='加班规则'">
        <div class="title">
          规则名称:<span>{{ overtimeRule.ruleName }}</span>
        </div>
        <div class="title">
          补偿方式:
          <div class="overtime">
            <div class="item">工作日 -- {{ getCodeContent(overtimeRule.workdayOvertimeConvertType,overtime_convert_type) }}</div>
            <div class="item">休息日 -- {{ getCodeContent(overtimeRule.offdayOvertimeConvertType,overtime_convert_type) }}</div>
            <div class="item">法定节假日 -- {{ getCodeContent(overtimeRule.holidayOvertimeConvertType,overtime_convert_type) }}</div>
          </div>
        </div>
        <div class="title">
          加班时长核算方式:
          <div class="overtime">
            <div class="item">工作日 -- {{ getCodeContent(overtimeRule.workdayOvertimeCalcType,overtime_calc_type) }}</div>
            <div class="item">休息日 -- {{ getCodeContent(overtimeRule.offdayOvertimeCalcType,overtime_calc_type) }}</div>
            <div class="item">法定节假日 -- {{ getCodeContent(overtimeRule.holidayOvertimeCalcType,overtime_calc_type) }}</div>
          </div>
        </div>
      </div>
      <div v-if="title==='补卡规则'">
        <div class="title">
          规则名称:<span>{{ punchRule.ruleName }}</span>
        </div>
        <div class="title">
          补卡次数限制:<span>{{ punchRule.maxTimes }}次/月</span>
        </div>
        <div class="title">
          补卡时间限制:<span>{{ getCodeContent(punchRule.appendPunchLimitType,appendpunch_limit_type) }}<cite v-show="punchRule.appendPunchLimitType === '2'">，第{{ punchRule.nextMonthMaxDay }}天内可补卡</cite>  </span>
        </div>
      </div>
      <div v-if="title==='外勤规则'">
        <div class="title">
          规则名称:<span>{{ outsideRule.ruleName }}</span>
        </div>
        <div class="title">
          外出申请时间单位:<span>{{ outsideRule.outsideUnit | applicantUnit }}</span>
        </div>
        <!-- <div class="title">
          外出半天折算方式:<span>{{ getCodeContent(outsideRule.outsideHalfDayCalcType,halfday_calc_type)}}</span>
        </div> -->
        <div class="title">
          出差申请时间单位:<span>{{ outsideRule.travelUnit | applicantUnit }}</span>
        </div>
        <!-- <div class="title">
          出差半天折算方式:<span>{{ getCodeContent(outsideRule.travelHalfDayCalcType,halfday_calc_type)}}</span>
        </div> -->
      </div>
    </Drawer>
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import { getAttendanceRule, getDetailTab, getDetail } from '@/services/attendance/attendance.js'
import { getattendanceGroupDetailApi, shiftSchedulingDeatailApi } from '@/services/attendance/settingset.js'
import { detailOvertimeRuleApi, detailOutsideRuleApi, detailPunchRuleApi } from '@/services/attendance/rule.js'
import { getStaffVague } from '@/services/dropDownInput/index.js'
import moment from 'moment'
import { replaceTime } from '@/utils/reg.js'
import { getTenantCode } from '@/services/dropDownInput/index.js'
export default {
  name: 'SummaryDetail',
  components: {
    Drawer: () => import('@/components/CbDrawer'),
    Calendar: () => import('@/components/CbCalendar/index'),
    // CbTableAvater: () => import('@/components/CbTableAvater/index')
    CbTableAvater: () => import('@/components/CbAvater/theAvater.vue')
  },
  filters: {
    shiftCycleType(val) {
      return val === '1' ? '按周' : val === '2' ? '按月' : '无固定休息日'
    },
    applicantUnit(val) {
      return val === 'D' ? '天' : val === 'H' ? '小时' : '半天'
    }
  },
  mixins: [mixins],
  props: {
    onJobId: {
      type: String,
      default: ''
    },
    userName: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      time: moment(this.date).format('YYYY-MM'),
      staffName: '',
      staffId: '',
      test: true,
      calendarList: {
        monthDetailVO: {}
      },
      actualDays: '',
      calendarStartDate: '',
      calendarEndDate: '',
      active: '',
      title: '',
      abnormals: [{
        title: '迟到',
        backgroundColor: '#FFEAD6',
        color: '#FF9733',
        statistics: 'lateTime'
      }, {
        title: '旷工',
        backgroundColor: '#FFE6E6',
        color: '#F76565 ',
        statistics: 'absentTime'
      }, {
        title: '早退',
        backgroundColor: '#FEE8DF',
        color: '#F98E5D ',
        statistics: 'earlyTime'
      }, {
        title: '上班缺卡',
        backgroundColor: '#FBE5F1',
        color: '#F759AB',
        statistics: 'missInTime'
      }, {
        title: '下班缺卡',
        backgroundColor: '#FBE5F1',
        color: '#F759AB',
        statistics: 'missOutTime'
      }, {
        title: '请假',
        backgroundColor: '#DFEDFE',
        color: '#5C8BF6',
        statistics: 'vactionTime'
      }, {
        title: '出差',
        backgroundColor: '#D8EFE7',
        color: '#3DB189',
        statistics: 'travelTime'
      }, {
        title: '加班',
        backgroundColor: '#E9F6CB',
        color: '#92CE10',
        statistics: 'overTime'
      }, {
        title: '外勤打卡',
        backgroundColor: '#EDE3FB',
        color: '#A675EB',
        statistics: 'lateinTimes'
      }, {
        title: '外出',
        backgroundColor: '#DFFAF5',
        color: '#77E9D6',
        statistics: 'outsideTime'
      }],
      popover: false,
      attendanceRules: [
        {
          id: 1,
          ruleName: '考勤组',
          rulePlan: '-'
        },
        {
          id: 2,
          ruleName: '考勤日历',
          rulePlan: '-'
        },
        {
          id: 3,
          ruleName: '加班规则',
          rulePlan: '-'
        },
        {
          id: 4,
          ruleName: '补卡规则',
          rulePlan: '-'
        },
        {
          id: 5,
          ruleName: '外勤规则',
          rulePlan: '-'
        }
      ],
      formList: ['search'], // search搜索框
      userList: [],
      rulevisible: false,
      detailsVisible: false,
      visible: false,
      result: null,
      shiftScheduling: {},
      overtimeRule: {
        workdayOvertimeConvertType: '0',
        workdayOvertimeCalcType: '0'
      },
      punchRule: {
        appendPunchLimitType: '0'
      },
      outsideRule: {},
      attendanceGroupDetails: {
        shiftScheduling: {
          shiftSchedulingName: ''
        }
      },
      attendanceList: [],
      attendanceTotal: [],
      // 字典项
      appendpunch_limit_type: [],
      overtime_convert_type: [],
      overtime_calc_type: [],
      halfday_calc_type: []
    }
  },
  watch: {
    staffId() {
      this.getAttendanceRule()
      this.getDetailTab()
      this.getDetail()
      this.getJobSelectList()
    },
    result() {
      this.getDetail()
    }
  },
  async created() {
    this.staffId = this.onJobId
    this.staffName = this.userName
    this.appendpunch_limit_type = await this.getTenantCode('appendpunch_limit_type')
    this.overtime_convert_type = await this.getTenantCode('overtime_convert_type')
    this.overtime_calc_type = await this.getTenantCode('overtime_calc_type')
    // this.halfday_calc_type = await this.getTenantCode('halfday_calc_type')
  },
  methods: {
    moment,
    // 字典项匹配
    async getTenantCode(codeName) {
      const res = await getTenantCode({ codeName })
      if (res.code === 100) {
        return res.data
      } else {
        return []
      }
    },
    getCodeContent(val, items) {
      const obj = items.filter(item => item.codeKey === val)
      return obj.length > 0 ? obj[0].content : '-'
    },
    hasThree(info) {
      let num = 0
      for (let i = 0; i < this.abnormals.length; i++) {
        const element = this.abnormals[i]
        info[element.statistics] && num++
        if (num > 2) {
          return true
        }
      }
      return false
    },
    onClick(val) {
      // console.log(val)
      this.staffName = val.staffName
      this.staffId = val.onJobId
      this.result = null
      this.active = ''
    },
    // 获取员工列表
    async getJobSelectList() {
      const res = await getStaffVague()
      // console.log(res)
      this.userList = res.data
    },
    // 查询人员月度考勤详情
    getDetail() {
      getDetail({ month: this.time.split('-').join(''), onJobId: this.staffId, result: this.result }).then(res => {
        if (res) {
          this.calendarList = res.data || []
          this.calendarStartDate = res.data.startDate ? res.data.startDate.replace(replaceTime, '$1-$2-$3') : moment(this.time).startOf('month').format('YYYY-MM-DD')
          this.calendarEndDate = res.data.startDate ? res.data.endDate.replace(replaceTime, '$1-$2-$3') : moment(this.time).endOf('month').format('YYYY-MM-DD')
        } else {
          this.calendarList = []
        }
      })
    },
    // 考勤项table表头
    getDetailTab() {
      getDetailTab({ month: this.time.split('-').join(''), onJobId: this.staffId }).then(res => {
        if (res) {
          this.actualDays = res.data.actualDays || 0
          console.log(res.data)
          this.attendanceList = [
            {
              id: 1,
              value: '迟到',
              count: parseInt(res.data.lateinTimes) || 0,
              unit: '次',
              fontRgba: '#FFA940'
            },
            {
              id: 2,
              value: '早退',
              count: parseInt(res.data.earlyOutTimes) || 0,
              unit: '次',
              fontRgba: '#FA9556'
            },
            {
              id: 4,
              value: '缺卡',
              count: parseInt(res.data.missTimes) || 0,
              unit: '次',
              fontRgba: '#F759AB'
            },
            {
              id: 3,
              value: '旷工',
              count: parseInt(res.data.absentTimes) || 0,
              unit: '次',
              fontRgba: '#FF4D4F'
            },
            {
              id: 5,
              value: '请假',
              count: parseInt(res.data.vacationTimes) || 0,
              unit: '次',
              fontRgba: '#5C7CFD'
            },
            {
              id: 8,
              value: '加班',
              count: parseInt(res.data.overtimeTimes) || 0,
              unit: '次',
              fontRgba: '#AFDE20'
            },
            {
              id: 7,
              value: '外出',
              count: parseInt(res.data.outsideTimes) || 0,
              unit: '次',
              fontRgba: '#77E9D6'
            },
            {
              id: 6,
              value: '出差',
              count: parseInt(res.data.travelTimes) || 0,
              unit: '次',
              fontRgba: '#36CFC9'
            }
          ]
          this.attendanceTotal = [
            {
              id: 1,
              value: '应出勤(天)',
              count: res.data.planDays || 0
            },
            {
              id: 2,
              value: '应出勤工时(小时)',
              count: res.data.planHours || 0
            },
            {
              id: 3,
              value: '实际出勤(天)',
              count: res.data.factDays || 0
            },
            {
              id: 4,
              value: '实际出勤工时(小时)',
              count: res.data.actualHours || 0
            },
            {
              id: 5,
              value: '打卡天数',
              count: res.data.clockDays || 0
            }
          ]
        } else {
          this.actualDays = 0
          this.attendanceList = []
          this.attendanceTotal = []
        }
      })
    },
    getAttendanceRule() {
      this.test = false
      getAttendanceRule({ onJobId: this.staffId }).then(res => {
        // console.log(res)
        const item = res.data
        if (item) {
          this.attendanceRules = [{
            ruleName: '考勤组',
            rulePlan: item.groupName,
            ruleId: item.attendanceGroupId
          }, {
            ruleName: '考勤日历',
            rulePlan: item.schedulingName,
            ruleId: item.shiftSchedulingId
          }, {
            ruleName: '加班规则',
            rulePlan: item.overtimeRuleName,
            ruleId: item.overtimeRuleId
          }, {
            ruleName: '补卡规则',
            rulePlan: item.punchRuleName,
            ruleId: item.appendPunchRuleId
          }, {
            ruleName: '外勤规则',
            rulePlan: item.outsideRuleName,
            ruleId: item.outsideRuleId
          }]
        }
      })
      this.$forceUpdate()
      this.test = true
      // console.log(this.attendanceRules)
    },
    // 返回
    goBack() {
      this.$emit('goBack')
    },
    // 搜索框
    onSearch(value) {
      getStaffVague({ inputValue: value }).then(res => {
        this.userList = res.data
        this.value = ''
      })
    },
    userTab() { // 人员切换
      this.popover = false
      this.visible = true
      // console.log(this.visible, 'visible')
    },
    onClose() {
      this.visible = false
      this.detailsVisible = false
    },
    buttonTab(e, key) { // 点击button切换颜色
      this.active = e
      this.result = key
    },
    onChange(value) {
      this.time = moment(value).format('YYYY-MM')
      this.getDetailTab()
      this.getDetail()
    },
    button() {
      this.popover = !this.popover
    },
    view(value, val) {
      this.title = val
      if (val === '考勤组') {
        getattendanceGroupDetailApi({ attendanceGroupId: value }).then(res => {
          // console.log(res.data)
          this.attendanceGroupDetails = res.data
          this.attendanceGroupDetails.shiftScheduling.shiftPunchRuleStr = res.data.shiftScheduling.shiftPunchRuleStr.replace(/<[a-zA-Z]+.*?>/g, '\n')
          this.attendanceGroupDetails.shiftScheduling.workCycleSequenceStr = res.data.shiftScheduling.workCycleSequenceStr.replace(/<[a-zA-Z]+.*?>/g, '\n')
        })
      }
      if (val === '考勤日历') {
        shiftSchedulingDeatailApi({ shiftSchedulingId: value }).then(res => {
          this.shiftScheduling = res.data
        })
      }
      if (val === '加班规则') {
        detailOvertimeRuleApi({ id: value }).then(res => {
          this.overtimeRule = res.data
        })
      }
      if (val === '补卡规则') {
        detailPunchRuleApi({ id: value }).then(res => {
          this.punchRule = res.data
        })
      }
      if (val === '外勤规则') {
        detailOutsideRuleApi({ id: value }).then(res => {
          this.outsideRule = res.data
        })
      }
      this.detailsVisible = true
      this.popover = false
    }
  }
}
</script>

<style lang="less" scoped>
.content{
  height:100%;
}
.header{
  text-align: left;
  cursor: pointer;
  font-size: 16px;
  color: @sc-grey-100;
  display: flex;
  align-items: center;
}
.topbtn{
  width: 20px;
  height: 20px;
  padding: 1px !important;
  margin-right: 8px;
}

.rightnav{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /deep/.box{
    margin-right: 8px;
  }
  .thename{
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    white-space:nowrap;
    text-overflow:ellipsis
  }
.right-rule{
  margin-left: 16px;
}
  .personTab{
    margin-left: 16px;
    position: relative;
  }

}
.content-top{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 16px 0;
  height: auto;
  .statistics{
    margin-right: 40px;
    display: flex;
    align-items: center;
    color: @sc-grey-100;
    font-size: 14px;
    &:hover{
      cursor: pointer;
    }
  }
}
.content-bottom{
  display: flex;
  p{
    display: inline-block;
    margin-right: 50px;
    font-size: 14px;
    color: @sc-grey-80;
    span{
      font-weight: bold;
    }
  }
}
.staff-box{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .left{
      width: 50%;
      margin-top: 16px;
      .content{
        width: 100%;
        display: flex;
        align-items: center;
        &:hover{
          cursor: pointer;
        }
      }
      p{
          width: 45px;
          overflow: hidden;
          white-space:nowrap;
          text-overflow:ellipsis;
          margin: 0 18px 0 8px;
          color: @sc-grey-80;
      }
      .num{
        flex:1;
        overflow: hidden;
        white-space:nowrap;
        text-overflow:ellipsis;
        color: @sc-grey-80;
      }
    }
  }
.rule-box{
  display: flex;
  margin-top: 14px;
  justify-content: space-between;
  align-items: center;
  .left-box{
    display: flex;
    .left-content{
      color: @sc-grey-60;
    }
  }
  .right-content{
    width: 170px;
    margin-left: 53px;
    color: @sc-grey-100;
    .multi-ellipsis(1);
    white-space: break-spaces;
    word-break: break-all;
  }
  .right-box{
    color: @sc-primary-100;
  }
}
.rule-box:nth-child(1){
  margin: 0;
  .left-box{
  .right-content{
    margin-left: 69px;
  }
  }
}
.title{
  margin-bottom: 20px;
  color: @sc-grey-80;
  span{
    padding-left: 20px;
    white-space:pre-wrap
  }
  .text-box{
    padding-left: 60px;
    white-space:pre-wrap
  }
  .overtime {
    padding-top: 10px;
    .item {
      .font-size(16px);
      margin-bottom:3px;
    }
  }
}
/deep/.header li{
  position: relative;
}
 .events {
  position: absolute;
  width:100%;
  padding:0 12px;
  background: #fff;
  height:90px;
  overflow: hidden;

}
.active-events{
 &:hover{
    height:auto;
    z-index:999;
    box-shadow: 0px 10px 13px 1px rgba(54, 67, 99, 0.09);
    border-radius: 2px;
  }
}
.text-color {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 10px;
}
.button{
  width: 40px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:6px;
  margin-left:70%;
  background-color: @sc-greyBg-10;
}
.times{
  font-size: 14px;
  padding: 10px 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  }
.badge{
  box-sizing: border-box;
  width:100%;
  height:30px;
  display: flex;
  align-items: center;
  padding:0 20px;
  font-size: 14px;
  margin-bottom: 10px;
  border-radius: 2px;
  }
.active {
  font-size: 16px !important;
  color: @sc-primary-100 !important;
}
// .right-box{
//   width: 30%;
//   height: 500px;
//   background: #1890ff;
//   position: fixed;
//   bottom: 0;
//   right: 0;
// }
</style>
