<template>
  <div class="SalaryItem">
    <!-- 编辑薪资项不可编辑 添加方式&&薪资项 -->
    <!-- 自定义项无内置薪资项 -->
    <!-- 添加方式固定项 社保 个税无  -->
    <a-form-model-item v-if="!['A01','A07','A06','A08'].includes(typeNo)" label="添加方式">
      <a-radio-group v-model="form.defineMode" @change="typeChange">
        <a-radio v-if="typeNo&&typeNo.slice(0,1)!=='B'" :disabled="mode==='edit'" value="1">内置薪资项</a-radio>
        <a-radio :disabled="mode==='edit'" value="2">自定义薪资项</a-radio>
      </a-radio-group>
    </a-form-model-item>
    <!--  社保为内置项 个税无 -->
    <a-form-model-item :label="salaryName">
      <!-- 薪资项固定项展示不可修改 -->
      <a-input v-if="typeNo === 'A01'||form.defineMode==='2'" v-model="form.salaryItemName" class="line-input" :disabled="typeNo==='A01'" />
      <a-radio-group v-else v-model="form.salaryItemNo" @change="salaryChange">
        <a-radio-button
          v-for="item in unconfigItemsList"
          :key="item.salaryItemNo"
          :disabled="!!item.isConfig||mode==='edit'"
          :value="item.salaryItemNo"
        >
          {{ item.salaryItemName }}
        </a-radio-button>
      </a-radio-group>
    </a-form-model-item>
  </div>
</template>

<script>
import { getUnconfigItems } from '@/services/wage/salaryGroup.js'
export default {
  name: 'SalaryItem',
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    typeNo: {
      type: String,
      default: undefined
    },
    mode: {
      type: String,
      default: undefined
    }
  },
  inject: ['infoData'],
  data() {
    return {
      unconfigItemsList: []
    }
  },
  computed: {
    salaryName() {
      if (this.typeNo === 'A01') {
        return '薪资项'
      } else if (this.form.defineMode === '2') {
        return '薪资项名称'
      }
      return '选择薪资项'
    }
  },
  methods: {
    typeChange({ target: { value }}) {
      this.form.calcRuleType = value
      this.form.salaryItemNo = ''
      this.form.detailList = []
    },
    // 获取未配置的薪资项
    async getUnconfigItems() {
      // 自定义公式无系统内置公式
      if (!['A01', 'A07']?.includes(this.typeNo) && this.typeNo?.slice(0, 1) !== 'B') {
        const res = await getUnconfigItems({ typeNo: this.typeNo, groupId: this.infoData.groupId })
        if (res.code === 100) {
          this.unconfigItemsList = res.data
        }
        return
      }
      if (this.typeNo?.slice(0, 1) === 'B') {
        // 自定义分类 默认为自定义薪资项
        this.form.defineMode = '2'
        this.form.calcRuleType = '2'
        this.$forceUpdate()
      }
    },
    // 薪资项改变 查询内置薪资项
    salaryChange({ target: { value }}) {
      if (value === 'BC01') {
        this.form.calcType = '1'
        this.form.taxable = 1
      } else if (value === 'BC02') {
        this.form.calcType = '2'
        this.form.taxable = 1
      } else if (value === 'BC03') {
        this.form.calcType = '1'
        this.form.taxable = 0
      } else if (value === 'BC04') {
        this.form.calcType = '2'
        this.form.taxable = 0
      } else if (['KQ01', 'KQ04'].includes(value)) {
        this.form.calcType = '2'
        this.form.taxable = 1
      } else {
        this.form.calcType = '1'
        this.form.taxable = 1
      }
      this.form.calcRuleType = (this.mode === 'add' && ['BT10', 'BT06', 'BT14', 'BT13'].includes(this.form.salaryItemNo)) || this.typeNo === 'A08' ? '3' : '1'
      // 查询节流判断 计薪规则为系统规则
      // if (this.form.calcRuleType === '1') {
      this.$emit('getSalaryData', value)
      // }
    }
  }
}
</script>
<style lang="less" scoped>
.line-input{
  width: 320px;
  display: inline-block;
}
/deep/.ant-radio-button-wrapper{
  padding: 4px 12px;
  margin:0 17px 16px 0;
  .font-size(14px);
  color: @sc-grey-80;
  background: @sc-greyBg-10;
  border-color:@sc-greyBg-10;
  border-radius: 4px;
}
/deep/.ant-radio-button-wrapper-disabled{
  color: @sc-grey-40;
  background:#fff;
  border: 1px solid @sc-greyBg-20;
}
/deep/.ant-radio-button-wrapper-checked{
  color: @sc-primary-100 !important;
  background: #fff;
  border-color: @sc-primary-100;
}
/deep/.ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
</style>
