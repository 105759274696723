<template>
  <div class="company-information">
    <div class="info-main">
      <div class="main-title">{{ baseInfoList.tenantName }}
      </div>
    </div>
    <a-descriptions :column="1" :colon="false">
      <a-descriptions-item label="所属行业">
        {{ baseInfoList.businessName }}
      </a-descriptions-item>
      <a-descriptions-item label="企业规模">
        {{ scaleName }}
      </a-descriptions-item>
      <a-descriptions-item label="注册资本">
        {{ baseInfoList.registerCapital }} 万元
      </a-descriptions-item>
      <a-descriptions-item label="注册地">
        {{ baseInfoList.areaName }}
      </a-descriptions-item>
      <a-descriptions-item label="统一社会信用代码">
        {{ baseInfoList.uniFormCode }}
      </a-descriptions-item>
      <a-descriptions-item label="法人代表">
        {{ baseInfoList.corporate }}
      </a-descriptions-item>
      <a-descriptions-item label="联系方式">
        {{ baseInfoList.corporateContact }}
      </a-descriptions-item>
      <a-descriptions-item label="邮政编码">
        {{ baseInfoList.postalCode }}
      </a-descriptions-item>
      <a-descriptions-item label="营业执照">
        <img v-if="baseInfoList.licenseFileId" :src="imgSrc">
        <img v-else src="">
      </a-descriptions-item>
      <a-descriptions-item label="">
        <a-button prefix="bianji" @click="handleEdit">编辑</a-button>
      </a-descriptions-item>
    </a-descriptions>
    <InductionClassForms
      ref="baseInfoListForm"
      :scale-list="scaleList"
      @getFetchDataApiFn="getFetchDataApiFn"
    />
  </div>
</template>

<script>
import { getTenantInfo } from '@/services/globalSetting/companyInfo.js'
import { getSignature } from '@/services/upload/index.js'
import { getScale } from '@/services/dictionary/tenant.js'
export default {
  name: 'GlobalSettingCompanyInfo',
  components: {
    InductionClassForms: () => import('./InductionForms.vue')
  },
  data() {
    return {
      baseInfoList: {},
      visible: false,
      basePath: process.env.VUE_APP_API_BASE_URL,
      time: '',
      signatures: '',
      imgSrc: '',
      orginName: '',
      scaleList: []
    }
  },
  computed: {
    scaleName() {
      let name = ''
      for (let i = 0; i < this.scaleList.length; i++) {
        const item = this.scaleList[i]
        if (item.codeKey === this.baseInfoList.scale) {
          name = item.content
        }
      }
      return name
    }
  },
  created() {
    this.getTenantInfo()
    this.getScaleList()
  },
  methods: {
    // 获取企业信息
    async getTenantInfo() {
      const res = await getTenantInfo()
      this.baseInfoList = res.data
      if (this.baseInfoList.licenseFileId) {
        this.getSignature()
      } else {
        this.baseInfoList.licenseFileId = ''
      }
    },
    // 获取规模
    async getScaleList() {
      const res = await getScale()
      this.scaleList = res.data.map(item => {
        return {
          ...item,
          content: `${item.content}${item.content === '1000' ? '人以上' : '人'}`
        }
      })
    },
    getFetchDataApiFn() {
      this.getTenantInfo()
    },
    async getSignature() {
      const res = await getSignature({ id: this.baseInfoList.licenseFileId })
      this.orginName = res.data.originFileName
      this.signatures = res.data.signature
      this.time = res.data.time
      this.imgSrc = `${this.basePath}/hrsaas-storage/image/thumbnail?id=${this.baseInfoList.licenseFileId}&width=${291}&height=${125}&expireTime=${this.time}&signature=${this.signatures}`
    },
    handleEdit() {
      this.$refs.baseInfoListForm.open({
        baseInfoList: this.baseInfoList,
        imgFile: {
          fileName: this.orginName,
          fileUrl: this.imgSrc,
          signature: this.signatures,
          time: this.time,
          fileId: this.baseInfoList.licenseFileId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.company-information{
  padding: 20px 20px 0;
  background-color: #fff;
  min-height: 100%;
}
.info-main{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}
.main-title{
  font-size: 20px;
  font-weight: bold;
  color: @sc-grey-100;
}
/deep/ .ant-descriptions-item{
  display: flex;
  padding-bottom: 32px;
}
/deep/ .ant-descriptions-row:last-child .ant-descriptions-item{
  padding-bottom: 0;
}
/deep/ .ant-descriptions-item {
  .ant-descriptions-item-label{
    width: 10%;
    text-align: left;
    color: @sc-grey-100;
  }
  .ant-descriptions-item-content{
    color: @sc-grey-60;
  }
}
.description-p{
  color: @sc-grey-40;
  margin-bottom: 30px;
}
/deep/.ant-upload.ant-upload-select-picture-card{
  width: 200px;
  height: 180px;
  .upload-file-contain, .upload-file-contain img{
    width: 100%;
    height: 100%;
  }
  .flex-center{
    flex-direction: column;
  }
}
/deep/ .ant-btn:hover {
  color: @sc-primary-100;
}
</style>
