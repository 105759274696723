<template>
  <div>
    <Drawer :visible="visible" title="权限" size="small" @close="onClose" @onSubmit="onSubmit">
      <div>
        <a-checkbox :checked="checkAll" :style="{display: isNone}" @change="onCheckAllChange">
          全选
        </a-checkbox>
        <a-tree
          v-model="checkedKeys"
          checkable
          :auto-expand-parent="true"
          :tree-data="treeData"
          :replace-fields="replaceFields"
          @check="onCheck"
        />
      </div>
    </Drawer>
  </div>
</template>
<script>
import { getFuncTree, updateUserFunction } from '@/services/globalSetting/settingAuthor.js'
export default {
  name: 'PermissionsTree',
  components: {
    Drawer: () => import('@/components/CbDrawer/index')
  },
  data() {
    return {
      visible: false,
      userId: '',
      inList: [],
      isNone: 'block',
      treeData: [],
      theCheckedKey: '',
      checkedKeys: [],
      replaceFields: {
        children: 'children',
        title: 'funcName',
        key: 'funcId'
      },
      checkAll: false,
      treeList: []
    }
  },
  mounted() {
    this.getFunTree()
  },
  methods: {
    getTreeList(res) {
      for (const item of res) {
        if (item.children !== null && item.children.length) {
          this.getTreeList(item.children)
        } else {
          this.treeList.push(item.funcId)
        }
      }
    },
    open(val) {
      this.visible = true
      this.userId = val.userId
      setTimeout(() => {
        this.checkedKeys = [...Array.from(new Set(val.funcIdList))]
        this.getTreeList(this.treeData)
        if (JSON.stringify(this.checkedKeys.sort()) === JSON.stringify(this.treeList.sort())) {
          this.checkAll = true
          this.checkedKeys = this.getList(this.treeData)
        } else {
          this.checkAll = false
          this.checkedKeys = [...Array.from(new Set(val.funcIdList)), this.theCheckedKey]
        }
      }, 100)
    },
    // 获取功能树
    async getFunTree() {
      const res = await getFuncTree()
      if (res.data.length !== 0 && res.data[0].funcCode === 'home') {
        this.theCheckedKey = res.data[0].funcId
        this.checkedKeys = [this.theCheckedKey]
        res.data[0]['disabled'] = true
        res.data.forEach(item => {
          if (item.funcName === '员工' || item.funcName === '险金') {
            item.children.map((it, index) => {
              if (it.funcName === '用户详情' || it.funcName === '档案详情') {
                item.children.splice(index, 1)
              }
            })
          }
        })
        this.treeData = res.data
      } else {
        this.isNone = 'none'
      }
    },
    // 点击复选框触发
    onCheck(checkedKeys, e) {
      if (e.checked) {
        this.checkedKeys = checkedKeys
        const listinfo = this.getList(this.treeData)
        if (JSON.stringify(checkedKeys.sort()) === JSON.stringify(listinfo.sort())) {
          this.checkAll = true
          this.checkedKeys = listinfo
        } else {
          this.checkAll = false
          this.checkedKeys = checkedKeys
        }
      } else {
        this.checkAll = false
        this.checkedKeys = checkedKeys
      }
    },
    getList(res) {
      for (const item of res) {
        this.inList.push(item.funcId)
        if (item.children != null && item.children.length) {
          this.getList(item.children)
        }
      }
      return (Array.from(new Set(this.inList)))
    },
    // 全选
    onCheckAllChange(e) {
      if (e.target.checked) {
        this.getList(this.treeData)
        this.checkedKeys = this.inList
        this.checkAll = true
        console.log('all', this.checkedKeys, this.inList)
      } else {
        this.inList = [this.theCheckedKey]
        this.checkedKeys = [this.theCheckedKey]
        this.checkAll = false
      }
    },
    onClose() {
      this.visible = false
      this.checkAll = !!this.checkAll
      this.treeList = []
    },
    async onSubmit() {
      const param = {
        userId: this.userId,
        funcIdList: this.checkedKeys
      }
      const res = await updateUserFunction(param)
      if (res.code === 100) {
        this.$message.success('修改权限成功')
        this.$emit('getPermission')
        this.onClose()
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>
