<template>
  <div>
    <Modal :visible="visible" :closable="true" size="normal" title="调整转正日期" ok-text="保存" @ok="handleOk" @cancel="handleCancel">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="调整方式">
          <a-radio-group v-model="workerType" name="radioGroup">
            <a-radio value="1" class="date">调整日期</a-radio>
            <a-radio value="2" class="date">调整试用期</a-radio>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item v-if="workerType == '1'" label="计划转正日期"> {{ form.plantTime }} </a-form-model-item>
        <a-form-model-item v-if="workerType == '1'" label="转正日期" prop="time">
          <a-date-picker v-model="form.planFormalDate" value-format="YYYY-MM-DD" placeholder="请选择时间" :disabled-date="disabledDate" />
        </a-form-model-item>

        <a-form-model-item v-if="workerType == '2'" label="原试用期限"> {{ form.trialData }} </a-form-model-item>
        <a-form-item v-if="workerType == '2'" label="调整试用期限">
          <a-form-item name="select" no-style>
            <dictionariesInput v-model="form.selectTrialData" parameter="probation" class="dict-input" />
            <!-- <a-select v-model="form.trialData" :options="options" /> -->
          </a-form-item>
          <!-- <span class="ant-form-text">个月</span> -->
        </a-form-item>
      </a-form-model>
    </Modal>
  </div>
</template>

<script>
import dictionariesInput from '@/components/CbDropDownInput/dictionariesInput/index'
import { editOfficialDate, editProbation } from '@/services/user/worker.js'
import moment from 'moment'
export default {
  components: {
    dictionariesInput,
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      onJobId: '',
      visible: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      workerType: '1',
      form: {},
      rules: {}
    }
  },

  methods: {
    // 打开弹窗
    open(val) {
      this.form.plantTime = val.record.planFormalDate
      this.form.trialData = val.record.probation
      this.onJobId = val.record.onJobId
      this.visible = true
    },
    // 保存事件
    handleOk() {
      // eslint-disable-next-line space-before-function-paren
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.workerType === '1') {
            const res = await editOfficialDate({
              onJobId: this.onJobId,
              // officialDate: this.form.trialData
              planFormalDate: this.form.planFormalDate
            })
            if (res.code === 100) {
              this.$message.success('转正日期调整成功')
              this.$emit('getFetchDataApiFn')
              this.visible = false
            }
          } else {
            const res = await editProbation({
              onJobId: this.onJobId,
              probation: this.form.selectTrialData
            })
            if (res.code === 100) {
              this.$message.success('转正日期调整成功')
              this.$emit('getFetchDataApiFn')
              this.visible = false
            }
          }
        }
      })
    },

    // 取消事件
    handleCancel() {
      this.visible = false
      this.resetForm()
    },
    // 重置表单内容和验证
    resetForm() {
      this.form = {}
      this.$refs.ruleForm.resetFields()
    },
    disabledDate(current) {
      // 不能选择今天之前（不包括今天）
      const atime = current._d
      const btime = moment().endOf('day')._d
      const a = String(atime.getFullYear()) + atime.getMonth() + atime.getDate()
      const b = String(btime.getFullYear()) + btime.getMonth() + btime.getDate()
      if (a === b) {
        return false
      }
      return current && current < moment().endOf('day')
    }
  }
}
</script>

<style lang="less" scoped>
.v-title {
  margin-bottom: 16px;
}
.ant-calendar-picker{
  width: 100%;
}
.ant-form-text {
  margin-left: 10px;
}
/deep/ .ant-form-item-children {
  display: flex;
  color: @sc-grey-100;
  .dict-input{
    width: 200px;
  }
}
/deep/ .ant-form-item:first-child {
  .ant-col-19 {
  margin-top: 10px;
  }
}
/deep/ .date > span:nth-child(2) {
    color: @sc-grey-100;
}
// /deep/ .ant-calendar-today .ant-calendar-date{
//   color: rgba(0, 0, 0, 0.65);
//   font-weight: normal;
//   border: none;
// }
</style>
