<template>
  <div class="changeWage">
    <Modal
      :visible="visible"
      closable
      destroy-on-close
      overflow-auto
      size="normal"
      title="人员调薪"
      @ok="handleOk"
      @cancel="close"
    >
      <div class="infoBox">
        <div class="infoItem">
          <div class="label">员工姓名</div>
          <div class="value">{{ info.staffName }}</div>
        </div>
        <div class="infoItem">
          <div class="label">员工状态</div>
          <div class="value">{{ info.staffStatusName }}</div>
        </div>
        <div class="infoItem">
          <div class="label">转正日期</div>
          <div class="value">{{ info.formalDate }}</div>
        </div>
        <div class="infoItem">
          <div class="label">最近调薪日期</div>
          <div class="value">{{ info.lastFixedDate }}</div>
        </div>
        <div class="infoItem">
          <div class="label">生效日期</div>
          <a-date-picker
            v-model="effectiveTime"
            value-format="YYYY-MM-DD"
            :disabled-date="formalDisabledDate"
          />
        </div>
      </div>
      <div v-if="effectiveTime" class="changeBox">
        <template v-for="(item, index) in salaryList">
          <div :key="`changeItem${index}`" class="changeItem">
            <!-- 正式 -->
            <div class="formal item">
              <div class="itemLine">
                <div class="name" :title="item.formal.name">{{ item.formal.name }}</div>
                <a-input v-model="item.formal.beforeAmount" disabled class="value" suffix="元" />
              </div>
              <div class="itemLine">
                <div class="adjust"> 调整后</div>
                <!-- <NumberInt
                  v-model="item.formal.amount"
                  :precision="2"
                  decimal-separator
                  is-fill-zero
                  class="value"
                  suffix="元"
                /> -->
                <NumThousand
                  :v-model="item.formal.amount"
                  :int-val="item.formal.amount"
                  :decimal-separator="true"
                  :is-thousands="true"
                  class="value"
                  suffix="元"
                  is-fill-zero
                  precision="2"
                  @blur="inputChangeFun(arguments[0],index,'amount')"
                />
              </div>
            </div>
            <!-- 试用期 -->
            <div v-if="showProbation" class="probation item">
              <div class="itemLine">
                <div class="name" :title="item.probation.name">{{ item.probation.name }}</div>
                <a-input v-model="item.probation.beforeAmount" disabled class="value" suffix="元" />
              </div>
              <div class="itemLine">
                <div class="adjust"> 调整后</div>
                <NumberInt
                  v-model="item.probation.amount"
                  :precision="2"
                  decimal-separator
                  is-fill-zero
                  class="value"
                  suffix="元"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  changeSalary
} from '@/services/wage/wagePayroll.js'
export default {
  name: 'ChangeWage',
  inject: ['salaryItemInfo'],
  components: {
    Modal: () => import('@/components/CbModal/index'),
    NumberInt: () => import('@/components/CbNumber'),
    NumThousand: () => import('@/components/CbNumber/thousand')
  },
  props: {
    info: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      visible: false,
      salaryList: [],
      effectiveTime: ''
    }
  },
  computed: {
    // 是否展示试用期工资项
    showProbation() {
      if (this.info.staffStatusName === '试用期') return true
      if (this.effectiveTime) {
        return this.info.formalDate.replaceAll('-', '') > this.effectiveTime.replaceAll('-', '')
      } else {
        return true
      }
    }
  },
  methods: {
    inputChangeFun(returnVal, index, fields) {
      this.salaryList[index].formal[fields] = returnVal
    },
    show() {
      this.$nextTick(() => {
        this.initData()
      })
      this.visible = true
    },
    initData() {
      const copyInfoList = JSON.parse(JSON.stringify(this.info.list))
      for (let i = 0; i < copyInfoList.length; i++) {
        const item = copyInfoList[i]
        item.formal['beforeAmount'] = item.formal.val
        item.formal['amount'] = item.formal.val
        item.formal['itemNo'] = item.formal.key
        item.probation['beforeAmount'] = item.probation.val
        item.probation['amount'] = item.probation.val
        item.probation['itemNo'] = item.probation.key
      }
      this.salaryList = copyInfoList
    },
    // 试用期生效时间的限制
    probationDisabledDate(time) {
      const { entryDate, formalDate } = this.info
      const start = new Date(entryDate).getTime()
      const end = new Date(formalDate).getTime()
      return !(start < time.valueOf() && time.valueOf() < end)
    },
    // 正式工生效时间的限制
    formalDisabledDate(time) {
      const { formalDate } = this.info
      const end = new Date(formalDate).getTime()
      return time.valueOf() <= end
    },
    async handleOk() {
      if (!this.effectiveTime) return this.$message.warning('请选择生效日期')
      const params = {
        onJobId: this.info.onJobId,
        effectiveTime: this.effectiveTime,
        salaryList: [],
        groupCalcId: this.salaryItemInfo.groupCalcId,
        isCalc: '0'
      }
      for (let i = 0; i < this.salaryList.length; i++) {
        const item = this.salaryList[i]
        if (this.showProbation && (item.probation.amount || item.probation.amount === 0)) {
          params.salaryList.push(item.probation)
        }
        if (item.formal.amount || item.formal.amount === 0) {
          params.salaryList.push(item.formal)
        }
      }
      const res = await changeSalary(params)
      if (res.code === 100) {
        this.close()
        this.$message.success('调薪成功')
      }
      this.$emit('complate')
    },
    // 关闭弹窗
    close() {
      this.effectiveTime = ''
      this.visible = false
      this.salaryList = []
    }
  }
}
</script>

<style lang="less" scoped>
.infoBox{
  display: flex;
  flex-wrap: wrap;
  .infoItem{
    width: calc(100% / 2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .font-size(14px);
    .value {
      width: 62%;
      text-align: left;
      color: @sc-grey-100;
    }
    .label {
      color: @sc-grey-80;
      width: 100px;
      margin-right: 20px;
    }
  }
}
.changeItem{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    .itemLine{
      width: calc(100% / 2);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .value{
        flex: 1;
      }
    }
  }
  &:last-child {
    .item {
      margin-bottom: 20px;
    }
  }
}
.adjust {
  margin: 0 20px;
  color: @sc-grey-80;
  .font-size(14px);
}
// .effectDate{
//   margin-left: 24px;
//    color: @sc-grey-80;
//   .font-size(14px);
// }
.name{
  width: 83px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 20px;
  color: @sc-grey-80;
}
/deep/ .ant-input-affix-wrapper .ant-input-suffix {
  color: @sc-grey-80;
  font-size: 14px;
}
</style>
