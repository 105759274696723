<template>
  <div>
    <!--  添加 搜索 筛选  -->
    <appForm
      :to-list="toList"
      :screen-roster-list="screenRosterList"
      @getSearchCallback="getSearchCallback"
      @screenRosterParams="screenRosterParams"
      @exportFileList="exportFileList"
    >
      <template v-if="keyval == '1'" slot="customButton">
        <a-button type="primary" prefix="add-one" @click="handleAdd">
          批量开具证明
        </a-button>
      </template>
    </appForm>
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      :tabs-height="36"
      row-key="proveApplyId"
      table-ref=".main-content"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="loadDataList"
    >
      <!-- 序号 -->
      <template slot="index" slot-scope="{ scope }">
        <span>{{ scope.index + 1 }}</span>
      </template>
      <!-- 状态 -->
      <template slot="applyStatus" slot-scope="{ scope }">
        <span>
          <StatusTag
            v-if="scope.record.applyStatus"
            :type="scope.record.applyStatus"
            :type-list="applyStatusList"
            @click="getCbApproveSteps(scope.record.flowId, scope.record.applyStatus)"
          />
        </span>
      </template>
      <!-- 姓名头像 -->
      <template slot="staffName" slot-scope="{ scope }">
        <CbTableAvater :table-avater="scope.record.avatar" :on-job-id="scope.record.onJobId" :staff-id="scope.record.staffId" :table-name="scope.record.staffName" />
      </template>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{ scope }">
        <a-button
          v-if="keyval == '1'"
          :disabled="scope.record.applyStatus !== 2"
          type="link"
          @click="getOpenerProve(scope.record)"
        >
          开具证明
        </a-button>
        <a-button v-if="keyval === '2'" type="link" @click="handleDetail(scope, 'details')"> 下载 </a-button>
        <a-button v-if="keyval === '2'" class="delete" type="link" @click="editItemConfirm(scope)"> 删除 </a-button>
      </template>
    </custom-table>
    <CertificateType ref="certificateType" :temp-type-id="tempTypeId" @previousStep="previousStep" @nextStep="nextStep" @temId="temId" />
    <PerDepModel v-model="selectPerson" :placeholder="'请输入部门、人员、工号'" :check-echo="selectPersonList" :query-leave-person="true" @perModelConfirm="perModelConfirm" />
    <CbApproveSteps v-model="stepsVisible" :flow-id="flowId" :apply-status="applyStatus" :apply-status-list="applyStatusList" />
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import mixinss from '@/components/CbPerDepSelection/index.js'
import CbTableAvater from '@/components/CbTableAvater/index'
import {
  waitIssueList,
  alreadyIssueList,
  deleteCertificateEntry,
  getProveDetail,
  exportWaitIssue,
  exportAlreadyIssue,
  openerProve
} from '@/services/user/certificate.js'
export default {
  name: 'InductionTabel',
  components: {
    CbTableAvater,
    CbApproveSteps: () => import('@/components/CbApproveSteps/index'),
    CertificateType: () => import('./form/certificateType.vue'),
    StatusTag: () => import('@/components/CbStatusTag')
  },

  mixins: [mixins, mixinss],
  props: {
    keyval: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      applyStatusList: {
        1: { name: '审批中' },
        2: { name: '已通过' },
        3: { name: '驳回' }
      },
      certificateProveApplyId: '',
      certificateProveFileId: '',
      total: 0,
      selectPerson: false,
      selectPersonList: [],
      tempTypeId: '',
      columns: [],
      toList: ['search', 'screen', 'export'], // search搜索框 screen筛选 choice位置选择弹窗 export导出
      waitIssueArray: [
        {
          title: '序号',
          dataIndex: 'index',
          width: '50px',
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          scopedSlots: { customRender: 'staffName' },
          width: 105
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          key: 'phoneNum',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          ellipsis: true
        },
        {
          title: '职务',
          dataIndex: 'dutyName',
          key: 'dutyName',
          ellipsis: true
        },
        {
          title: '聘用形式',
          dataIndex: 'engageModeName',
          key: 'engageModeName'
        },
        {
          title: '证明类型',
          dataIndex: 'tempName',
          key: 'tempName'
        },
        {
          title: '审批状态',
          dataIndex: 'applyStatus',
          scopedSlots: { customRender: 'applyStatus' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      alreadyIssueArray: [
        {
          title: '序号',
          dataIndex: 'index',
          width: '50px',
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          scopedSlots: { customRender: 'staffName' },
          width: 105
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          key: 'phoneNum',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          ellipsis: true
        },
        {
          title: '职务',
          dataIndex: 'dutyName',
          key: 'dutyName',
          ellipsis: true
        },
        {
          title: '聘用形式',
          dataIndex: 'engageModeName',
          key: 'engageModeName'
        },
        {
          title: '证明类型',
          dataIndex: 'tempName',
          key: 'tempName'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      dataSource: [], // 数据
      screenRosterList: {
        'list': [
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'engage_mode',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'employment',
            'key': 'selectDictionaries_1651110883682'
          },
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'selectDepartment',
            'key': 'selectDepartment_1651110886169'
          },
          {
            'type': 'selectPost',
            'label': '岗位',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'selectPost',
            'key': 'selectPost_1651110888169'
          },
          {
            'type': 'selectDictionaries',
            'label': '证明类型',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'prove_status',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'proveState',
            'key': 'selectDictionaries_1651110897449'
          },
          {
            'type': 'selectDictionaries',
            'label': '审批状态',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'apply_status',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'approveState',
            'key': 'selectDictionaries_1651110897449'
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      stepsVisible: false,
      flowId: '',
      applyStatus: '',
      tempId: ''
    }
  },
  watch: {
    keyval: {
      handler(val) {
        this.getFetchDataApiFn(this.pageNum)
      }
    },
    tempId: {
      handler(val) {
        this.$emit('gettemId', val)
      }
    }
  },
  methods: {
    // 获取表格数据
    async fetchDataApiFn(param) {
      if (this.keyval === '1') {
        // 试用期
        this.columns = this.waitIssueArray
        const res = await waitIssueList(param)
        this.dataSource = res.data.records ? res.data.records : []
        this.pager.pageNum = res.data.pageNum || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.pageSize || 10 // 更新当前的分页信息
        this.total = res.data.total
      } else if (this.keyval === '2') {
        // 最近入职
        this.columns = this.alreadyIssueArray
        const res = await alreadyIssueList(param)
        this.dataSource = res.data.records ? res.data.records : []
        this.pager.pageNum = res.data.pageNum || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.pageSize || 10 // 更新当前的分页信息
        this.total = res.data.total
      }
    },
    // 删除确认
    async editItemConfirm(val) {
      this.certificateProveApplyId = val.record.proveApplyId
      this.certificateProveFileId = val.record.proveFileId
      this.$Reconfirm({
        title: '删除',
        content: '是否确认删除？'
      }).then(async() => {
        const res = await deleteCertificateEntry({
          proveApplyId: this.certificateProveApplyId,
          proveFileId: this.certificateProveFileId
        })
        if (res.code === 100) {
          this.$message.success('删除成功')
          this.serchGetData()
        }
      }).catch(() => {
        console.log('no')
      })
    },
    // 开具证明
    async getOpenerProve(data) {
      const res = await openerProve({
        proveApplyId: data.proveApplyId,
        onJobId: data.onJobId,
        proveTempId: data.proveTempId
      })
      this.$emit('nextStep', res.data, 1)
    },
    // 详情
    async handleDetail(val, isEdit) {
      // 获取模板信息
      const res = await getProveDetail({
        proveFileId: val.record.proveFileId
      })
      if (res.code === 100) {
        this.content = res.data.proveContent
        this.$emit('detail', res.data)
      }
    },
    // 操作回调
    getFetchDataApiFn(val) {
      this.serchGetData()
    },

    // 搜索
    getSearchCallback(val) {
      this._queryParam.staffName = val.searchValue
      this.serchGetData()
    },
    // 筛选
    screenRosterParams(data) {
      const { employment, selectDepartment, selectPost, proveState, approveState } = data.screenCondition
      this._queryParam = {
        staffName: data.searchValue,
        engageMode: employment,
        orgId: selectDepartment ? selectDepartment[0].value : '',
        postId: selectPost,
        proveTempId: proveState,
        applyStatus: approveState
      }
      this.serchGetData()
    },
    handleAdd() {
      this.selectPersonList = []
      this.selectPerson = true
    },
    // 组织选人确定
    perModelConfirm(val) {
      this.selectPerson = false
      this.selectPersonList = val
      this.$refs.certificateType.open(val)
    },
    // 选择类型确定
    nextStep(val) {
      this.$emit('nextStep', val, 2)
    },
    // 上一步
    previousStep(val) {
      this.tempTypeId = val
      this.selectPerson = true
    },
    // 审批步骤条
    getCbApproveSteps(data, val) {
      this.flowId = data
      this.applyStatus = val
      this.stepsVisible = true
    },
    // 导出
    exportFileList() {
      const { name, engageMode, orgId, postId, proveTempId, applyStatus } = this._queryParam
      const params = {
        name: name,
        engageMode: engageMode,
        orgId: orgId,
        postId: postId,
        proveTempId: proveTempId,
        applyStatus: applyStatus
      }
      if (this.keyval === '1') {
        exportWaitIssue(params)
      } else {
        exportAlreadyIssue(params)
      }
    },
    temId(e) {
      this.tempId = e
      this.$emit('gettemId', this.tempId)
    }
  }
}
</script>

<style lang="less" scoped>
.more-handle-btn {
  display: flex;
  flex-direction: column;
}
/deep/.ant-btn.link{
  margin-right: 16px;
  &:last-child{
    margin: 0;
  }
}
</style>
