<!--
 * @Date: 2022-07-07 13:48:58
 * @descriotion:
-->
<template>
  <div class="addForm">
    <BlockTitle id="title" :title="title">
      <template slot="left">
        <a-switch v-show="isFlag" v-model="switchFlag" size="small" />
      </template>
    </BlockTitle>
    <!-- <div class="title">
      <div class="title_division" />
      <div class="title_name">{{ title }}</div>
      <a-switch v-show="isFlag" v-model="switchFlag" />
    </div> -->
    <a-form-model
      v-show="switchFlag"
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <!-- <a-form-model-item label="参保城市" :prop="!disabledList.includes('city')?'cityCode':''">
        <a-select v-if="!disabledList.includes('city')" v-model="form.cityCode" placeholder="请选择参保城市">
          <a-select-option v-for="city in cityList" :key="city.cityCode" :value="city.cityCode">
            {{ city.cityName }}
          </a-select-option>
        </a-select>
        <p v-else>{{ form.cityName }}</p>
      </a-form-model-item> -->
      <a-form-model-item label="参保主体" :prop="!disabledList.includes('company')?'insureCompanyId':''">
        <a-select v-if="!disabledList.includes('company')" v-model="form.insureCompanyId" placeholder="请选择参保主体" @change="changeCompany">
          <a-select-option v-for="company in companyList" :key="company.companyId" :value="company.companyId">
            {{ company.companyName }}
          </a-select-option>
        </a-select>
        <p v-else>{{ form.companyName }}</p>
      </a-form-model-item>
      <a-form-model-item label="参保方案" :prop="!disabledList.includes('scheme')?'schemeId':''">
        <a-select v-if="!disabledList.includes('scheme')" v-model="form.schemeId" :disabled="disabledList.includes('programme')" placeholder="请选择参保方案">
          <a-select-option v-for="scheme in schemeList" :key="scheme.schemeId" :value="scheme.schemeId">
            {{ scheme.schemeName }}
          </a-select-option>
        </a-select>
        <p v-else>{{ form.schemeName }}</p>
        <!-- <div class="tips">
          参保险种： <span>公积金、补充公积金</span>
        </div> -->
      </a-form-model-item>
      <a-form-model-item label="缴纳基数" :prop="!disabledList.includes('paymentBase')?'paymentBaseType':''">
        <template v-if="!disabledList.includes('paymentBase')">
          <a-radio-group v-model="form.paymentBaseType" @change="changepayment">
            <a-radio value="1">
              固定金额
            </a-radio>
            <a-radio value="2">
              自定义金额
            </a-radio>
          </a-radio-group>
          <!-- <CbNumber v-show="form.paymentBaseType === '1'" v-model="form.fixedPaymentBase" :decimal-separator="true" placeholder="请输入基数" suffix="元" @blur="setPayment" @change="valid" /> -->
          <NumThousand
            v-show="form.paymentBaseType === '1'"
            :v-model="form.fixedPaymentBase"
            placeholder="请输入基数"
            :decimal-separator="true"
            :int-val="form.fixedPaymentBase"
            :is-thousands="true"
            suffix="元"
            precision="2"
            @blur="inputChangeFun"
          />
          <a-row v-show="form.paymentBaseType === '2'" :gutter="20">
            <a-col :span="14">
              <DictionariesInput v-model="form.customScaleType" placeholder="请选择自定义金额" parameter="custom_scale_type" class="dictionariesInput" />
            </a-col>
            <a-col :span="10">
              <CbNumber v-model="form.customScale" :decimal-separator="false" precision="2" :max="100" suffix="%" placeholder="请输入比例" @change="valid" />
            </a-col>
          </a-row>
        </template>
        <p v-else>{{ form.fixedPaymentBase }}</p>
      </a-form-model-item>
      <a-form-model-item v-if="!stopInsure" label="缴纳起始月" prop="startMonth">
        <a-month-picker v-if="!disabledList.includes('month')" v-model="form.startMonth" :disabled-date="disabledDate" placeholder="请选择月份" value-format="YYYY-MM" />
        <p v-else>{{ form.startMonth }}</p>
      </a-form-model-item>
      <!-- <a-form-model-item v-if="!stopInsure" :label="insureClassify === 'housing'?'变更原因':'增员原因'" prop="increaseReason">
        <a-input v-if="!disabledList.includes('reason')" v-model="form.increaseReason" :max-length="30" placeholder="请输入原因" type="textarea" />
        <p v-else>{{ form.increaseReason }}</p>
      </a-form-model-item> -->
      <a-form-model-item v-if="stopInsure" label="缴纳停止月" prop="endMonth">
        <a-month-picker v-if="!disabledList.includes('month')" v-model="form.endMonth" :disabled-date="disabledDate" placeholder="请选择月份" value-format="YYYY-MM" />
        <p v-else>{{ form.endMonth }}</p>
      </a-form-model-item>
      <a-form-model-item v-if="stopInsure" :label="insureClassify === 'housing'?'变更原因':'减员原因'" prop="decreasesReason">
        <!-- <a-input v-model="form.decreasesReason" :max-length="30" placeholder="请输入原因" type="textarea" /> -->
        <a-select v-if="!disabledList.includes('reason')" v-model="form.decreasesReason" :get-popup-container="(triggerNode)=>triggerNode.parentNode">
          <a-select-option v-for="item in decreases_reason" :key="item.codeKey" :value="item.codeKey">
            {{ item.content }}
          </a-select-option>
        </a-select>
        <p v-else>{{ form.decreasesReason }}</p>
      </a-form-model-item>
      <a-form-model-item v-if="insureClassify === 'housing' && !batch" label="公积金账号">
        <a-input v-model="form.housingCode" :disabled="stopInsure" placeholder="以前在本地的话需要录入" />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { getSchemeListByCityApi, getInsureCompanyListApi } from '@/services/insurance/archives.js'
import { getTanantCodeBatch } from '@/services/dropDownInput/index.js'
import moment from 'moment'
import { sepcialCode } from '@/utils/reg.js'
export default {
  name: 'AddForm',
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index'),
    CbNumber: () => import('@/components/CbNumber/index'),
    DictionariesInput: () => import('@/components/CbDropDownInput/dictionariesInput/index'),
    NumThousand: () => import('@/components/CbNumber/thousand')
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    disabledList: {
      type: Array,
      default: () => [] // city 城市 company参保主体 scheme参保方案 paymentBase缴纳基数 month缴纳月 reason原因
    },
    // switch是否存在
    isFlag: {
      type: Boolean,
      default: true
    },
    formData: {
      type: Object,
      default: null
    },
    // 是否是批量操作隐藏公积金账号
    batch: {
      type: Boolean,
      default: false
    },
    insureClassify: {
      type: String,
      default: '',
      required: true
    },
    // 投保停保文案  false 投保 true 停保
    stopInsure: {
      type: Boolean,
      default: false
    },
    isAgain: {
      type: Boolean,
      default: false
    },
    payment: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    const validateBase = (rule, value, callback) => {
      if (value === '1') {
        if (!this.form.fixedPaymentBase) {
          callback(new Error('请填写完整缴纳基数'))
        } else {
          callback()
        }
      } else {
        if (!this.form.customScaleType || !this.form.customScale) {
          callback(new Error('请填写完整缴纳基数'))
        } else {
          callback()
        }
      }
    }
    return {
      decreases_reason: {},
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      switchFlag: true,
      form: {
        cityCode: undefined, // 参保城市
        insureCompanyId: undefined, // 参保主体
        schemeId: undefined, // 参保方案
        paymentBaseType: '1', // 缴纳基数类型
        fixedPaymentBase: undefined, //
        customScaleType: undefined,
        customScale: undefined,
        startMonth: moment(new Date()).format('YYYY-MM'),
        endMonth: moment(new Date()).format('YYYY-MM'),
        increaseReason: undefined,
        housingCode: undefined
      },
      rules: {
        cityCode: [{ required: true, message: '请选择参保城市', trigger: 'change' }],
        insureCompanyId: [{ required: true, message: '请选择参保主体', trigger: 'change' }],
        schemeId: [{ required: true, message: '请选择参保方案', trigger: 'change' }],
        paymentBaseType: [{ required: true, validator: validateBase, trigger: 'change' }],
        startMonth: [{ required: true, message: '请选择月份', trigger: 'change' }],
        endMonth: [{ required: true, message: '请选择月份', trigger: 'change' }],
        increaseReason: [{ pattern: sepcialCode, message: '不支持特殊字符', trigger: 'change' }],
        decreasesReason: [{ pattern: sepcialCode, message: '不支持特殊字符', trigger: 'change' }]
      },
      companyList: [],
      schemeList: [],
      flag: false // 控制回显 cityCode更改 清空
    }
  },
  watch: {
    formData: {
      immediate: true,
      handler(val) {
        this.form = { ...this.form, ...val } || this.form
        console.log(this.form)
        if (this.isAgain) {
          this.switchFlag = !!val
        }
      }
    },
    'form.insureCompanyId': {
      handler(val) {
        if (val) {
          if (this.flag) {
            this.form.schemeId = undefined
            // this.form.insureCompanyId = undefined
          }
          this.flag = true
        }
      },
      immediate: true
    },
    'form.cityCode': {
      handler(val) {
        if (val) {
          this.getSchemeListByCity(val)
        }
      },
      immediate: true
    },
    payment(newval) {
      if (newval && !this.form.fixedPaymentBase) {
        this.form.fixedPaymentBase = newval
      }
    }
  },
  mounted() {
    this.getCompanyList()
    this.getTanantCode(this.insureClassify)
  },
  methods: {
    changepayment(e) {
      if (e.target.value === '1') {
        this.form.customScaleType = undefined
        this.form.customScale = undefined
        this.$set(this.form, 'fixedPaymentBase', this.form.fixedPaymentBase)
      }
    },
    getTanantCode(type) {
      const data = [`${type}_decreases_reason`]
      getTanantCodeBatch(data).then((res) => {
        if (res.code === 100) {
          this.decreases_reason = res.data[0].baseCodes
          const code = type === 'social' ? 'S003' : type === 'medical' ? 'Y001' : 'G001'
          this.$set(this.form, 'decreasesReason', code)
        }
      })
    },
    setPayment() {
      this.$emit('setPayment', this.form.fixedPaymentBase)
    },
    inputChangeFun(returnVal) {
      this.form.fixedPaymentBase = returnVal
      this.$emit('setPayment', returnVal)
      this.$refs.ruleForm.validateField('paymentBaseType', (err) => { console.log(err) })
    },
    disabledDate(current) { // 可选择未来日期
      // return current < moment().startOf('month')
    },
    getData() {
      let flag = false
      if (!this.switchFlag) return { flag: this.switchFlag, formData: this.form }
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          flag = true
        }
      })
      const obj = JSON.parse(JSON.stringify(this.form))
      obj.fixedPaymentBase = Number(obj.fixedPaymentBase) || null
      obj.customScale = Number(obj.customScale) || null
      return flag ? { flag: this.switchFlag, formData: obj } : false
    },
    valid() {
      this.$refs.ruleForm.validateField('paymentBaseType', (err) => { console.log(err) })
    },
    resetFile() {
      this.flag = false
      this.form = this.$options.data().form
      this.switchFlag = true
      this.$refs.ruleForm.resetFields()
    },
    getCompanyList() {
      getInsureCompanyListApi().then(res => {
        if (res.code === 100) {
          this.companyList = res.data
          res.data.forEach(item => {
            if (item.defaultValue === '1' && !this.form.insureCompanyId) {
              this.form.insureCompanyId = item.companyId
              this.form.cityCode = item.cityCode
            }
          })
        }
      })
    },
    getSchemeListByCity(cityCode) {
      getSchemeListByCityApi({ cityCode, insureClassify: this.insureClassify }).then(res => {
        if (res.code === 100) {
          this.schemeList = res.data
          this.form.schemeId = undefined
          res.data.forEach(item => {
            if (item.defaultValue === '1' && !this.form.schemeId) {
              this.form.schemeId = item.schemeId
            }
          })
        }
      })
    },
    changeCompany(e) {
      const arr = this.companyList.filter(item => item.companyId === e)
      this.getSchemeListByCity(arr[0].cityCode)
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.ant-calendar-picker{
  width:100%;
}
p{
  margin:0;
}
#title {
    margin-bottom:20px ;
    .title_division {
      width: 3px;
      height: 14px;
      background: @sc-primary-100;
      margin-right: 5px;
    }
    .title_name{
      width:80px;
    }
  }
  /deep/.ant-form-item-label label{
    color: @sc-grey-100;
  }
  /deep/.ant-radio-wrapper span{
    color: @sc-grey-100;
  }
  /deep/.ant-switch{
    margin-left: 30px;
    margin-bottom: 5px;
  }
</style>
