<template>
  <div class="StaffDetailsDrawer">
    <Drawer
      size="small"
      is-mask
      display-btn
      placement="right"
      closable
      :z-index="2"
      :visible="staffVisible"
      class="staff-drawer"
      @close="staffVisibleClose"
    >
      <template slot="title">
        {{ details.orgName }}
      </template>
      <div v-if="!leaderName" class="principal">
        <div class="empty_div">暂无负责人</div>
      </div>
      <div v-else class="principal">
        <div class="leader-header">
          <span v-if="leaderName">
            <Avater :on-job-id="details.onJobId" :avater-name="leaderName" size="34" :show-user-name="false" is-person-info />
          </span>
        </div>
        <div>负责人：</div>
        <div> {{ leaderName }}</div>
      </div>
      <div class="description">
        <span>组织说明：</span>
        <span v-if="detail.length === 0">暂无</span>
        <span v-if="!openContentDetail">{{ detail.length > 20 ? detail.slice(0, 20) + '...' : detail }}</span>
        <span v-else>{{ detail }}</span>
        <a v-if=" detail.length > 20" class="open_content" @click="openContent">{{ openContentDetail ? '收起' : '展开' }}</a>
      </div>
      <div class="addPeoButton">
        <a-button v-if="details.invented" @click="addPeople">添加人员</a-button>
      </div>
      <div class="items">
        <a-tabs v-model="tabsKey">
          <a-tab-pane v-if="!details.invented" key="1" tab="直属">
            <div v-if="directStaff.length > 0">
              <ul v-for="(item, index) in directStaff" :key="index" class="staff-list">
                <li>
                  <div class="users-info">
                    <span v-if="item.staffName" class="leader_name_img">
                      <Avater :on-job-id="item.onJobId" :size="34" :avater-name="item.staffName" is-person-info />
                    </span>
                    <span>{{ item.phoneNum }}</span>
                  </div>
                  <div v-if="item.staffNum" class="staff_number">
                    <a-tooltip placement="left">
                      <template slot="title">
                        {{ item.staffNum }}
                      </template>
                      <span>工号：</span>
                      <span>{{ item.staffNum }}</span>
                    </a-tooltip>
                  </div>
                </li>
              </ul>
            </div>
            <div v-else class="empty_box">
              <img class="emptyImg" :src="emptyImg" alt="">
              <p>暂无数据</p>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="全员">
            <div v-if="allStaff.length > 0">
              <ul v-for="(item, index) in allStaff" :key="index" class="staff-list">
                <li>
                  <div class="users-info">
                    <span v-if="item.staffName" class="leader_name_img">
                      <Avater :on-job-id="item.onJobId" :size="34" :avater-name="item.staffName" is-person-info />
                    </span>
                    <span>{{ item.phoneNum }}</span>
                  </div>
                  <div v-if="item.staffNum" class="staff_number">
                    <a-tooltip placement="left">
                      <template slot="title">
                        {{ item.staffNum }}
                      </template>
                      <span>工号：</span>
                      <span>{{ item.staffNum }}</span>
                    </a-tooltip>
                  </div>
                  <div v-if="details.invented">
                    <a-popconfirm title="是否确认删除？" placement="left" ok-text="确认" cancel-text="取消" @confirm="delFictitious(item.onJobId)">
                      <svg class="iconpark-icon delicon"><use href="#close-small" /></svg>
                    </a-popconfirm>
                  </div>
                </li>
              </ul>
            </div>
            <div v-else class="empty_box">
              <img class="emptyImg" :src="emptyImg" alt="">
              <p>暂无数据</p>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
      <!--      <a-modal-->
      <!--        v-model="visiblePeople"-->
      <!--        title="添加员工"-->
      <!--        :get-container="()=>window.document.querySelector('.staff-drawer')"-->
      <!--        @cancel="addPeopleCancel"-->
      <!--        @ok="addPeopleSelect"-->
      <!--      >-->
      <!--        <span class="select_people">选择员工：</span>-->
      <!--        <PerTreeSelect v-model="model" mode-type="multiple" style="width: 380px" />-->
      <!--      </a-modal>-->
      <PerModelSelect ref="PerModelSelect" v-model="visiblePeople" @perModelConfirm="perModelConfirm" />
    </Drawer>
  </div>
</template>

<script>
import mixins from '@/components/CbPerDepSelection/index.js'
import { getOrgDetails, addPerson, deleteInvertStaff } from '@/services/organization/framework.js'
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 }
}
export default {
  name: 'StaffDetailsDrawer',
  components: {
    Drawer: () => import('@/components/CbDrawer'),
    Avater: () => import('@/components/CbAvater/theAvater.vue')
  },
  mixins: [mixins],
  props: {},
  data() {
    return {
      window: window,
      formItemLayout,
      staffVisible: false,
      visiblePeople: false,
      details: {},
      model: undefined,
      tabsKey: '1',
      detail: '',
      leaderName: '',
      avatar: '',
      allStaff: [],
      directStaff: [],
      basePath: process.env.VUE_APP_API_BASE_URL,
      leaderImgShow: true,
      emptyImg: require('@/assets/img/home/noData.png'),
      openContentDetail: false
    }
  },
  created() {},
  methods: {
    open(details) {
      this.details = details
      this.leaderName = details.leaderName ? details.leaderName : ''
      this.staffVisible = true
      this.getStaffVague(details.orgId)
      if (details.invented) {
        this.tabsKey = '2'
      }
      this.addShow(this.directStaff)
      this.addShow(this.allStaff)
    },
    // 获取全员直属
    async getStaffVague(data) {
      const res = await getOrgDetails({ orgId: data })
      this.detail = res.data.detail ? res.data.detail : ''
      this.leaderName = res.data.leaderName ? res.data.leaderName : this.leaderName
      this.avatar = res.data.avatar
      this.allStaff = res.data.allStaff ? res.data.allStaff : []
      this.directStaff = res.data.directStaff ? res.data.directStaff : []
    },
    addShow(arr) {
      arr.forEach((item) => {
        item.show = true
      })
    },
    // 添加人员打开弹窗
    addPeople() {
      this.visiblePeople = true
    },
    async perModelConfirm(data) {
      const params = []
      data.forEach(item => { params.push(item.onJobId) })
      const res = await addPerson({
        orgId: this.details.orgId,
        onJobIdList: params
      })
      if (res.code === 100) {
        this.visiblePeople = false
        this.$message.success('添加成功')
        this.$refs.PerModelSelect.clear()
        this.getStaffVague(this.details.orgId)
      }
    },
    // 虚拟组织删除成员
    async delFictitious(data) {
      const res = await deleteInvertStaff({
        onJobId: data
      })
      if (res.code === 100) {
        this.$message.success('删除成功')
        this.getStaffVague(this.details.orgId)
        this.tabsKey = '2'
      }
    },
    // 关闭弹窗
    staffVisibleClose() {
      this.staffVisible = false
      this.details = {}
      this.tabsKey = '1'
      this.allStaff = []
      this.directStaff = []
    },
    leaderImgError() {
      this.leaderImgShow = false
    },
    directImgError(i) {
      this.directStaff[i].show = false
    },
    allImgError(i) {
      this.allStaff[i].show = false
    },
    openContent() {
      this.openContentDetail = !this.openContentDetail
    }
  }
}
</script>

<style lang="less" scoped>
.leader_name_img{
  width: 140px;
}
.principal {
  color: @sc-grey-100;
  margin: 0 0 16px;
  height: 50px;
  font-size: 14px;
  background: @sc-greyBg-10;
  border-radius: 4px;
   .empty_div{
  margin-left:12px
 }
 .flex-align-center()

}
.leader-header {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin: 5px 16px 0 12px;
}
.description {
  color: @sc-grey-80;
  .font-size(14px);
}
.staff-list {
  border: 0;
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid @sc-greyBg-20;
    padding: 15px 0;
    color: @sc-grey-80;
  }
  .users-info {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: @sc-grey-100;
    /deep/.box .user-name{
      margin-left: 10px;
    }
    > span {
      padding-right: 8px;
    }
  }
  .delicon{
    width: 18px;
    height: 18px;
    vertical-align: middle;
  }
}
.addPeoButton {
  text-align: right;
  margin: 10px 0;
}
.select_people{
  margin-right: 10px;
}
/deep/  .ant-tabs-tab {
  color: @sc-grey-80;
  padding-top: 0;
}
/deep/ .ant-tabs-tab-active {
  color: @sc-primary-100;
}
.empty_box {
  text-align: center;
  margin-top: 250px;
.emptyImg {
  width: 85px;
  height: 72px;
}
}
.open_content{
  color: @sc-primary-100;
  margin-left: 5px;
}
.staff_number{
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
