<template>
  <div>
    <Drawer :visible="visible" title="添加账号" size="small" @close="onClose" @onSubmit="onSubmitForm">
      <a-row class="radioRow">
        <a-radio-group v-model="radios" @change="onChange">
          <a-radio :value="1">
            选择员工
          </a-radio>
          <a-radio :value="2">
            邀请加入
          </a-radio>
        </a-radio-group>
      </a-row>
      <a-form :form="inForm" :label-col="labelCol" :wrapper-col="wrapperCol">
        <div>
          <a-form-item v-if="radios === 1" label="姓名" prop="userInfo">
            <PerTreeSelect
              ref="userRef"
              v-decorator="[
                'userInfo',
                { rules: [
                  {
                    required: true,
                    message: '请选择人员',
                    trigger: 'change',
                  },{
                    validator: userInfoValid,
                  },
                ]}]"
              placeholder="请选择人员"
              per-tree-type="phone"
              @getPerTreeValue="getPerTreeValue"
            />
          </a-form-item>
          <a-form-item v-if="radios === 2" label="手机号" prop="phoneNum">
            <a-input
              v-decorator="[
                'phoneNum',
                { rules: [
                  {
                    required: true,
                    message: '请输入手机号',
                    trigger: 'blur'
                  }, {
                    pattern: phoneReg,
                    message: '请输入正确的手机号',
                    trigger: 'blur'
                  }
                ]}]"
              allow-clear
              placeholder="请输入手机号"
              @change="change"
            />
          </a-form-item>
          <a-form-item v-if="radios === 2" label="姓名" prop="realName">
            <a-input
              v-decorator="[
                'realName',
                { rules: [
                  {
                    required: true,
                    message: '请输入姓名',
                    trigger: 'blur'
                  }, {
                    min: 1,
                    max: 20,
                    message: '姓名长度应在1~20位之间',
                    trigger: 'blur'
                  }
                ]}]"
              allow-clear
              :disabled="isDisabled"
              placeholder="请输入姓名"
            />
          </a-form-item>
          <a-form-item label="权限" prop="checkedKeys" class="permision">
            <a-checkbox :style="{display: isNone}" :checked="checkAll" @change="onCheckAllChange">
              全选
            </a-checkbox>
            <a-tree
              v-model="checkedKeys"
              checkable
              :auto-expand-parent="true"
              :tree-data="treeData"
              :replace-fields="replaceFields"
              @check="onCheck"
            />
          </a-form-item>
        </div>
      </a-form>
    </Drawer>
  </div>
</template>

<script>
import { phoneReg } from '@/utils/reg.js'
import PerTreeSelect from '@/components/CbPerDepSelection/perTreeSelect/index'
import { getFuncTree, createAccount } from '@/services/globalSetting/settingAuthor.js'
import { getStaffInfoByPhoneNum } from '@/services/dropDownInput/index.js'
export default {
  name: 'AddAuthor',
  components: {
    PerTreeSelect,
    Drawer: () => import('@/components/CbDrawer/index')
  },

  data() {
    return {
      phoneReg: phoneReg,
      visible: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      inForm: this.$form.createForm(this),
      radios: 1,
      checkAll: false,
      inList: [],
      isNone: 'block',
      isDisabled: false,
      treeData: [],
      theCheckedKey: '',
      checkedKeys: [],
      replaceFields: {
        children: 'children',
        title: 'funcName',
        key: 'funcId'
      }
    }
  },
  methods: {
    // 打开弹窗
    open() {
      this.visible = true
      this.radios = 1
      this.getFunTree()
      setTimeout(() => { // 解决选择人员默认值未被清空
        this.$refs.userRef.perTreeValueTemp = undefined
      }, 100)
    },
    // 获取功能树
    async getFunTree() {
      const res = await getFuncTree()
      if (res.data.length !== 0 && res.data[0].funcCode === 'home') {
        this.theCheckedKey = res.data[0].funcId
        this.checkedKeys = [this.theCheckedKey]
        res.data[0]['disabled'] = true
        res.data.forEach(item => {
          if (item.funcName === '员工' || item.funcName === '险金') {
            item.children.map((it, index) => {
              if (it.funcName === '用户详情' || it.funcName === '档案详情') {
                item.children.splice(index, 1)
              }
            })
          }
        })
        this.treeData = res.data
      } else {
        this.isNone = 'none'
      }
    },
    onChange(e) { // 单选事件
      if (e.target.value === 1) {
        this.checkAll = false
        this.inForm.resetFields()
        this.checkedKeys = [this.theCheckedKey]
      } else {
        this.checkAll = false
        this.inForm.resetFields()
        this.checkedKeys = [this.theCheckedKey]
      }
    },
    // 点击复选框触发
    onCheck(checkedKeys, e) {
      if (e.checked) {
        this.checkedKeys = checkedKeys
        const listinfo = this.getList(this.treeData)
        if (JSON.stringify(checkedKeys.sort()) === JSON.stringify(listinfo.sort())) {
          this.checkAll = true
          this.checkedKeys = listinfo
        } else {
          this.checkAll = false
          this.checkedKeys = checkedKeys
        }
      } else {
        this.checkAll = false
        this.checkedKeys = checkedKeys
      }
    },
    getList(res) {
      for (const item of res) {
        this.inList.push(item.funcId)
        if (item.children != null && item.children.length) {
          this.getList(item.children)
        }
      }
      return this.inList
    },
    // 全选
    onCheckAllChange() {
      if (!this.checkAll) {
        this.getList(this.treeData)
        this.checkedKeys = this.inList
        this.checkAll = true
      } else {
        this.inList = [this.theCheckedKey]
        this.checkedKeys = [this.theCheckedKey]
        this.checkAll = false
      }
    },
    getPerTreeValue(val) {
      this.userInfo = val
    },
    userInfoValid(rule, value, callback) {
      if (!this.userInfo && !value) {
        const arr = [{
          message: '请选择人员',
          field: 'userInfo'
        }]
        this.inForm.setFields({ userInfo: { value: value, errors: arr }})
      } else {
        this.inForm.setFields({ userInfo: { value: this.userInfo }})
        callback()
      }
    },
    change(e) {
      const val = e.target.value
      if (phoneReg.test(val)) {
        getStaffInfoByPhoneNum({ phoneNum: val }).then(res => {
          if (res.data && res.data.staffId) {
            this.inForm.setFieldsValue({ realName: res.data.staffName })
            this.isDisabled = true
          } else {
            this.isDisabled = false
          }
        })
      }
      this.inForm.setFieldsValue({ realName: '' })
      this.isDisabled = false
    },
    // 保存事件
    onSubmitForm() {
      if (this.radios === 1) {
        this.inForm.setFields({ userInfo: { value: this.userInfo }})
        this.inForm.validateFields(async(errors, values) => {
          if (!errors) {
            const param = {
              onJobId: this.userInfo,
              phoneNum: '',
              funcIdList: this.checkedKeys
            }
            const res = await createAccount(param)
            if (res.code === 100) {
              this.$message.success('添加账号成功')
              this.onClose()
              this.$emit('getFetchAddIn')
            }
          }
        })
      } else {
        this.inForm.validateFields(['realName', 'phoneNum', 'checkedKeys'], async(errors, values) => {
          if (!errors) {
            const param = {
              onJobId: '',
              realName: values.realName,
              phoneNum: values.phoneNum,
              funcIdList: this.checkedKeys
            }
            const res = await createAccount(param)
            if (res.code === 100) {
              this.$message.success('添加账号成功')
              this.$emit('getFetchAddIn')
              this.onClose()
            }
          }
        })
      }
    },
    // 取消事件
    onClose() {
      this.inForm.resetFields()
      this.visible = false
      this.checkAll = false
      this.isDisabled = false
      this.checkedKeys = [this.theCheckedKey]
      this.userInfo = ''
    }
  }
}
</script>

<style lang="less" scoped>
.radioRow{
  margin-bottom: 24px;
}
.ant-form{
  /deep/ .permision .ant-form-item-label label{
    padding-left: 5px;
    position: relative;
    &::before {
      position: absolute;
      content: '';
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 14px;
      background: @sc-primary-100;
    }
  }
}
</style>
