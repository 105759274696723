<template>
  <div class="daily-table">
    <div class="toobar-left">
      <svg class="iconpark-icon"><use href="#rongqi" /></svg>
      <a-month-picker v-model="time" value-format="YYYY-MM" :disabled-date="disabledDate" @change="dateChange">
        <span>{{ time }}</span>
      </a-month-picker>
      <a-button class="month" @click="onMonth">当月</a-button>
    </div>
    <div class="daily-toolbar">
      <div class="daily-statistic-left">
        <div v-show="statistcs.lackNumber > 0" class="statistic-p">旷工：<span>{{ statistcs.lackNumber }}</span></div>
        <div v-show="statistcs.lateNumber > 0" class="statistic-p">迟到：<span>{{ statistcs.lateNumber }}</span></div>
        <div v-show="statistcs.earlyNumber > 0" class="statistic-p">早退：<span>{{ statistcs.earlyNumber }}</span></div>
        <div v-show="statistcs.missNumber > 0" class="statistic-p">缺卡：<span>{{ statistcs.missNumber }}</span></div>
        <div v-show="statistcs.outNumber > 0" class="statistic-p">外勤打卡：<span>{{ statistcs.outNumber }}</span></div>
        <div v-show="statistcs.overtimeNumber > 0" class="statistic-p">加班：<span>{{ statistcs.overtimeNumber }}</span></div>
        <div v-show="statistcs.leaveNumber > 0" class="statistic-p">请假：<span>{{ statistcs.leaveNumber }}</span></div>
        <div v-show="statistcs.travelNumber > 0" class="statistic-p">出差：<span>{{ statistcs.travelNumber }}</span></div>
      </div>
      <appForm
        ref="appForm"
        :to-list="formList"
        search-placeholder="请搜索姓名/工号"
        :screen-roster-list="screenRosterList"
        @getSearchCallback="getSearchCallback"
        @screenRosterParams="screenRosterParams"
        @exportFileList="exportFileList"
      />
    </div>
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      row-key="attendanceResultId"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="loadDataList"
    >
      <!-- 序号 -->
      <template slot="index" slot-scope="{ scope }">
        <span>{{ scope.index + 1 }}</span>
      </template>
      <template slot="userName" slot-scope="{ scope }">
        <CbTableAvater :table-name="scope.record.staffName" :on-job-id="scope.record.onJobId" :staff-id="scope.record.staffId" :title="scope.record.staffName" />
      </template>
      <template slot="thedetail" slot-scope="{scope}">
        <div v-for="col in scope.record.abnormals" :key="col.title">
          <span v-show="col.number>0" class="btn" :style="{backgroundColor:col.backgroundColor,color:col.color}">{{ col.title }} {{ col.number }}</span>
        </div>
      </template>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{scope}">
        <div class="flex-align-center">
          <a-button :disabled="scope.record.ifFill==='false'" type="link" @click="submit(scope.record)">
            补卡
          </a-button>
        </div>
      </template>
    </custom-table>
    <PunchModal ref="punchModal" @updata="updata" />
  </div>
</template>

<script>
import appForm from '@/components/CbAppForm/index'
import mixins from '@/components/CustomTable/index.js'
import moment from 'moment'
import { getEmphasPageList, exportEmphas } from '@/services/attendance/attendance.js'//
export default {
  name: 'MonthlyAttention',
  components: {
    appForm,
    CbTableAvater: () => import('@/components/CbTableAvater/index'),
    PunchModal: () => import('../../record/replacement/component/addModal.vue')
  },
  mixins: [mixins],
  data() {
    return {
      formList: ['search', 'export', 'screen'], // search搜索框 screen筛选 export导出
      time: moment(new Date()).format('YYYY-MM'),
      columns: [
        {
          title: '序号',
          dataIndex: 'id',
          scopedSlots: { customRender: 'index' },
          width: '50px',
          fixed: 'left'
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          key: 'staffName',
          width: '105px',
          scopedSlots: { customRender: 'userName' },
          fixed: 'left'
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          width: '120px',
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          width: '100px',
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          width: '100px',
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '明细',
          dataIndex: 'thedetail',
          scopedSlots: { customRender: 'thedetail' },
          key: 'thedetail',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '50px',
          fixed: 'right'
        }
      ],
      // 筛选内容
      screenRosterList: {
        'list': [
          {
            'type': 'selectDictionaries',
            'label': '员工状态',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择员工状态',
              'showSearch': false,
              'parameter': 'staff_status'
            },
            'model': 'staffStatuses',
            'key': 'staffStatuses',
            'rules': [
              {
                'required': false,
                'message': '请选择'
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '出勤结果',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'attendance_result_item',
              'clearable': true,
              'placeholder': '请选择出勤结果',
              'showSearch': false
            },
            'model': 'resultList',
            'key': 'resultList',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'engage_mode',
              'clearable': true,
              'placeholder': '请选择聘用形式',
              'showSearch': false
            },
            'model': 'engageModes',
            'key': 'engageModes'
          },
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择部门',
              'showSearch': false
            },
            'model': 'orgIds',
            'key': 'orgIds',
            'rules': [
              {
                'required': false,
                'message': '请选择部门'
              }
            ]
          },
          {
            'type': 'selectPost',
            'label': '岗位',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择岗位',
              'showSearch': false
            },
            'model': 'postIds',
            'key': 'postIds',
            'rules': [
              {
                'required': false,
                'message': '请选择岗位'
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 80,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      dataSource: [], // 数据
      statistcs: {
        lackNumber: '0', // 旷工次数
        lateNumber: '0', // 迟到次数
        earlyNumber: '0', // 早退次数
        missNumber: '0', // 缺卡次数
        outNumber: '0', // 外勤打卡
        overtimeNumber: '0', // 加班次数
        leaveNumber: '0', // 请假
        travelNumber: '0' // 出差
      },
      disabledHours: [],
      disabledMinutes: [],
      form: {}
    }
  },
  created() {
  },
  mounted() {},
  methods: {
    // 禁用时分
    disabled(val) {
      if (val === moment(new Date()).format('YYYY-MM-DD')) {
        const hours = new Date().getHours()
        const minutes = new Date().getMinutes()
        const arr = () => this.range(0, 24).splice(hours + 1, 24 - hours)
        const arr1 = () => this.range(0, 60).splice(minutes + 1, 60)
        this.disabledHours = arr()
        this.disabledMinutes = arr1()
        this.$nextTick(() => {
          this.$refs.KFB.setData({ startPunchTime: undefined })
        })
      } else {
        this.disabledHours = []
        this.disabledMinutes = []
      }
    },
    range(start, end) {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    },
    // 时间变化回调
    dateChange() {
      this.serchGetData()
    },
    // 获取出勤列表
    async fetchDataApiFn(param) {
      const res = await getEmphasPageList({ ...param, month: this.time.split('-').join('') })
      this.dataSource = res.data.list ? res.data.list.records : []
      this.pager.pageNum = res.data.list.current || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.list.size || 10 // 更新当前的分页信息
      this.total = res.data.list.total
      this.statistcs = res.data.statistcs
      this.dataSource.forEach(item => {
        item.abnormals = [{
          title: '迟到',
          number: item.lateinTimes,
          backgroundColor: '#FFEAD6',
          color: '#FF9733'
        }, {
          title: '旷工',
          number: item.absentTimes,
          backgroundColor: '#FFE6E6',
          color: '#F76565 '
        }, {
          title: '早退',
          number: item.earlyOutTimes,
          backgroundColor: '#FEE8DF',
          color: '#F98E5D '
        }, {
          title: '上班缺卡',
          number: item.missInTimes,
          backgroundColor: '#FBE5F1',
          color: '#ED7CBA'
        }, {
          title: '下班缺卡',
          number: item.missOutTimes,
          backgroundColor: '#FBE1FB',
          color: '#EA6AEA'
        }, {
          title: '请假',
          number: item.vacationTimes,
          backgroundColor: '#DFEDFE',
          color: '#5C8BF6'
        }, {
          title: '出差',
          number: item.travelTimes,
          backgroundColor: '#D8EFE7',
          color: '#3DB189'
        }, {
          title: '加班',
          number: item.overtimeTimes,
          backgroundColor: '#E9F6CB',
          color: '#92CE10'
        }, {
          title: '外勤打卡',
          number: item.outsidePunchTimes,
          backgroundColor: '#EDE3FB',
          color: '#A675EB'
        }, {
          title: '外出',
          number: item.outsideTimes,
          backgroundColor: '#DFFAF5',
          color: '#77E9D6'
        }]
      })
    },
    // 搜索
    getSearchCallback(value) {
      this._queryParam.searchWord = value.searchValue
      this.serchGetData()
    },
    nowMonth() {
      this.time = moment(new Date()).format('YYYY-MM')
      this.fetchDataApiFn(this._queryParam)
    },
    // 筛选
    screenRosterParams(param) {
      this._queryParam.searchWord = param.searchValue
      this._queryParam.engageModes = param.screenCondition.engageModes ? param.screenCondition.engageModes.join(',') : ''
      this._queryParam.resultList = param.screenCondition.resultList ? param.screenCondition.resultList.join(',') : ''
      this._queryParam.staffStatuses = param.screenCondition.staffStatuses ? param.screenCondition.staffStatuses.join(',') : ''
      this._queryParam.postIds = param.screenCondition.postIds ? param.screenCondition.postIds.join(',') : ''
      if (param.screenCondition.orgIds) {
        const orgIds = param.screenCondition.orgIds.map(item => {
          return item.value
        })
        this._queryParam.orgIds = orgIds.join(',')
      }
      for (var key in this._queryParam) {
        if (!this._queryParam[key]) {
          delete this._queryParam[key]
        }
      }
      this.serchGetData()
    },
    // 导出
    exportFileList() {
      const { staffStatuses, engageModes, orgIds, postIds, resultList, searchWord } = this._queryParam
      exportEmphas({
        staffStatuses: staffStatuses,
        engageModes: engageModes,
        orgIds: orgIds,
        postIds: postIds,
        month: this.time.split('-').join(''),
        resultList: resultList,
        searchWord: searchWord
      })
    },
    disabledDate(current) { // 不可选择未来日期
      return current > moment().endOf('day')
    },
    onMonth() {
      this.time = moment(new Date()).format('YYYY-MM')
      this.serchGetData()
    },
    // 补卡
    submit(info) {
      console.log(info)
      this.$refs.punchModal.show(info)
    },
    updata() {
      this.serchGetData()
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-table td { white-space: nowrap; }
.daily-table {
  width: 100%;
  height: calc(100% - 50px);
  overflow: hidden;
  .toobar-left{
    width: auto;
    height: 34px;
    padding: 0 0 0 16px;
    background-color: @sc-greyBg-10;
    display: inline-flex;
    // justify-content: space-evenly;
    align-items: center;
    border-radius: 4px;
    color: @sc-primary-100;
    margin-bottom: 16px;
    .ant-calendar-picker{
      color: @sc-grey-100 !important;
      line-height: 34px;
      height: 100%;
      text-align: center;
      padding: 0 16px;
      /deep/.ant-calendar-picker-input{
        border: 0;
      }
      &:hover {
        cursor: pointer;
        color: @sc-primary-100 !important;
      }
    }
    .month {
      width: 63px;
      height: 30px;
      padding:  0 10px;
      border: 0;
      margin-right: 2px;
      border-radius: 0;
    }
    .iconpark-icon{
      width: 20px;
      height: 20px;
      color: @sc-primary-100;
    }
  }
  .daily-toolbar {
    .flex-just-between();
  }
    .daily-statistic-left{
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
    }
    .statistic-p {
      margin-right: 40px;
      margin-bottom: 16px;
      font-size: 14px;
      color: @sc-grey-100;
      span{
        color: #FFAC1C;
      }
    }
}
.btn{
  float: left;
  margin-bottom: 5px;
  margin-right: 10px;
  padding: 3px 10px;
  border-radius: 4px;
}
</style>
