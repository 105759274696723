<template>
  <Modal
    :visible="visible"
    :title="add ? '添加计算公式': '普通计算公式'"
    :z-index="2000"
    :closable="true"
    :size="'large'"
    :overflow-auto="true"
    :custom-style="{ height: '580px', maxHeight: 'none' }"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="formula-box">
      <div class="tinymce-view">
        <div class="formula-label">
          计算公式
        </div>
        <Tinymce-vue
          v-if="editorShow"
          class="tinymce"
          :value="content"
          :setting="setting"
          @input="(res)=> content = res"
        />
      </div>
      <div class="operation-view dont-copy">
        <div class="other-operation">
          <div class="comparison-operator">
            <div class="comparison-label">快速运算符</div>
            <a-button
              v-for="(item) in operatorList"
              :key="item.id"
              class="comparison-btn"
              type="default"
              @click="handleComparison(item)"
            >
              {{ item }}
            </a-button>
          </div>
          <div class="add-container">
            <div v-if="showChar" class="add-text add-view">
              <div class="add-label">请添加文本</div>
              <a-input v-model="text" placeholder="请输入文本" />
              <div class="add-btn">
                <!-- <a-button icon="plus" @click="addText" /> -->
                <a-button class="addText" type="iconBtn" icon="add" @click="addText" />
              </div>
            </div>
            <div class="add-number add-view">
              <div class="add-label">请添加数值</div>
              <CbNumber v-model="numberText" decimal-separator placeholder="请输入数值" />
              <div class="add-btn">
                <a-button class="addText" type="iconBtn" icon="add" @click="addNumber" />
              </div>
            </div>
          </div>
        </div>
        <div class="indicator-bin">
          <div class="indicator-title content-title">点击选择指标字段</div>
          <div class="indicator-list">
            <div v-for="(item, index) in indicatorList" :key="index" class="indicator-outer">
              <div class="indicator-groupName">
                {{ item.resourceName }}:
              </div>
              <div class="indicator-item">
                <div v-for="indexitem in item.indexItemList" :key="indexitem.id" class="indicator-text" @click="handleIndicator(indexitem)">
                  [{{ indexitem }}]
                </div>
              </div>
            </div>
          </div>
        </div>
        <a-button type="link" prefix="piliangdaoru" class="addIndicator" @click="addIndicator">
          添加自定义指标
        </a-button>
      </div>
      <div v-if="!add" class="bottom-value">
        <div>
          <div class="form-label">封顶值</div>
          <CbNumber v-model="maxLimit" placeholder="请输入封顶值" />
        </div>
        <div>
          <div class="form-label minimum">保底值</div>
          <CbNumber v-model="minLimit" placeholder="请输入保底值" />
        </div>
      </div>
      <customIndicator ref="customIndicator" @addSucceed="getAll()" />
    </div>
  </Modal>
</template>

<script>
import TinymceVue from '@/components/Tinymce'
import CbNumber from '@/components/CbNumber'
import customIndicator from './customIndicator'
const tinymce = window.tinymce
import { checkFormula, getAll } from '@/services/wage/formulaSetting'
export default {
  name: 'FormulaView',
  components: {
    TinymceVue,
    CbNumber,
    customIndicator,
    Modal: () => import('@/components/CbModal/index.vue')
  },
  props: {
    add: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 是否显示字符型指标项，
      // 为1时显示字符型指标项, 否则不展示
      showChar: null,
      // 为了解决 tinymce-vue #163 issuse中的该问题
      // https://github.com/tinymce/tinymce-vue/issues/163
      editorShow: false,
      setting: {
        cleanup: false,
        branding: false, // 隐藏商标
        elementpath: false, // 隐藏底栏的元素路径
        min_height: 100,
        max_height: 100,
        menubar: '',
        preview: true,
        toolbar: '',
        toolbar_drawer: 'sliding',
        quickbars_selection_toolbar: '',
        plugins: '',
        language: 'zh_CN', // 本地化设置
        forced_root_block: '' // 设置默认标签项
      },
      content: '',
      indicatorList: [],
      operatorList: ['+', '-', '*', '/', '(', ')'],
      text: '',
      numberText: '',
      visible: false,
      maxLimit: '',
      minLimit: ''
    }
  },
  watch: {
    content: {
      handler(newVal) {
        this.$emit('change', newVal)
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      tinymce.init({})
    })
  },
  methods: {
    show(showChar = null, formula) {
      this.visible = true
      this.$nextTick(() => {
        this.editorShow = true
      })
      if (showChar) {
        this.minLimit = formula.minLimit
        this.maxLimit = formula.maxLimit
      }
      this.content = formula.description
      this.showChar = showChar
      this.getAll()
    },
    // 点击指标项插入
    handleIndicator(name) {
      this.insert(`[${name}]`)
    },
    // 添加自定义指标
    addIndicator() {
      console.log('this.$refs', this.$refs)
      this.$refs.customIndicator.open()
    },
    // 运算符插入
    handleComparison(comparison) {
      this.insert(comparison)
    },
    // 文本插入
    addText() {
      const { text } = this
      if (text === '') {
        return this.$message.warning('请输入文本')
      }
      this.insert(`"${text}"`)
    },
    // 数值插入
    addNumber() {
      const { numberText } = this
      if (numberText === '') {
        return this.$message.warning('请输入数值')
      }
      this.insert(numberText)
    },
    insert(insertText) {
      tinymce.execCommand('mceReplaceContent', false, insertText)
    },
    async handleOk() {
      const { content: description, maxLimit, minLimit } = this
      if (!description) {
        return this.$message.warning('请填写公式')
      }
      if (maxLimit && minLimit && (Number(minLimit) > Number(maxLimit))) {
        return this.$message.warning('保底值不能大于封顶值')
      }
      const { data } = await checkFormula({
        description
      })
      if (!data) return
      this.visible = false
      const params = {
        description: this.content,
        maxLimit,
        minLimit
      }
      this.$emit('formulaComplete', params, data)
    },
    // 获取所有指标
    async getAll() {
      const { showChar } = this
      const { data } = await getAll({ showChar })
      this.indicatorList = data.filter(({ indexItemList }) => {
        return indexItemList && indexItemList.length > 0
      })
    },
    handleCancel() {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.formula-box {
  .tinymce-view {
    .formula-label {
      font-size: 16px;
      color: @sc-grey-100;
      padding-left: 4px;
      border-left: 3px solid @sc-primary-100;
      margin-bottom: 14px;
    }
    .form-label {
      font-weight: 700;
      padding-bottom: 10px;
      color: @sc-grey-100;
    }
    .tinymce {
      height: 84px;
      margin-bottom: 14px;
    }
  }
  .content-title {
    font-size: 14px;
    padding-bottom: 12px;
    // font-weight: 700;
    color: @sc-grey-100;
  }
  .operation-view {
    border-bottom: 1px solid @sc-greyBg-20;
    .indicator-bin {
      .indicator-title {
        padding-bottom: 10px;
      }
      .indicator-list {
        height: 230px;
        overflow: auto;
        .indicator-outer {
          display: flex;
          .indicator-groupName {
            font-size: 14px;
            color: @sc-grey-80;
            margin-right: 14px;
            flex-shrink: 0;
          }
          .indicator-item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .indicator-text {
              padding: 5px 10px;
              border: 1px solid @sc-greyBg-50;
              cursor: pointer;
              border-radius: 4px 4px 4px 4px;
              margin-right: 12px;
              margin-bottom: 12px;
              &:hover {
                color: @sc-primary-80;
                border-color: @sc-primary-80;
              }
            }
          }
        }
      }
    }
    .addIndicator {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      height: auto;
      padding-bottom: 14px;
    }
    .other-operation {
      display: flex;
      align-items: center;
      padding-bottom: 18px;
      justify-content: space-between;
      .comparison-operator {
        display: flex;
        align-items: center;
        .comparison-label {
          padding-right: 14px;
          font-size: 14px;
          color: @sc-grey-80;
        }
        .comparison-btn {
          margin-right: 12px;
          height: 30px;
          width: 30px;
          text-align: center;
          padding: 0;
        }
      }
      .add-view {
        display: flex;
        align-items: center;
        .add-btn {
          padding-left: 10px;
        }
      }
      .content-title  {
        flex-shrink: 0;
        padding-right: 10px;
      }
    }
    .add-container {
      display: flex;
      .addText {
        height: 32px;
        width: 32px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .add-text {
        padding-right: 20px;
      }
      .add-label {
        color: @sc-grey-100;
        font-size: 14px;
        padding-right: 14px;
      }
      /deep/.ant-input {
        width: 120px;
      }
    }
  }
}
.bottom-value {
  display: flex;
  padding-top: 10px;
  .form-label {
    font-weight: 700;
    padding-right: 10px;
  }
  > div {
    display: flex;
    align-items: center;
  }
  .minimum {
    padding-left: 20px;
  }
}
/deep/ .tox-statusbar {
  display: none !important;
}
/deep/ .tox-tinymce {
  border-radius: 10px;
  height: 84px !important;
}
</style>
