<template>
  <div class="check-code-login">
    <div class="login-form">
      <div class="form-item">
        <Number v-model.trim="globForm.phoneNum" prefix="+86" />
      </div>
      <div class="form-item">
        <GetCheckCode
          v-model.trim="globForm.code"
          :can-use="String(globForm.phoneNum).length !== 0"
          @getCode="getCode"
        />
      </div>
      <div>
        <a-button
          class="submit"
          type="primary"
          @click="onSubmit"
          @enterPress="onSubmit"
        >登录</a-button>
        <span class="login cursor" @click="changeLoginType(1)">
          密码登录
        </span>
      </div>
    </div>
    <!-- 滑块验证 -->
    <div v-if="visible" class="dialog">
      <div class="dialogContent">
        <div class="title">
          <p>拖动下方滑块完成拼图</p>
          <div class="icon">
            <a-icon type="redo" @click="onRefresh" />
            <a-icon type="close" @click="visible = false" />
          </div>
        </div>
        <slideverify
          ref="dialogopen"
          :l="puzzePass.smallHeight"
          :w="puzzePass.bigWidth"
          :h="puzzePass.bigHeight"
          :blocky="puzzePass.posY"
          :imgurl="puzzePass.bigImageBase64"
          :miniimgurl="puzzePass.smallImageBase64"
          :slider-text="puzzePass.text"
          @success="onSuccess"
          @fail="onFail"
          @refresh="onRefresh"
        />

      </div>
    </div>
  </div>
</template>

<script>
import GetCheckCode from '@/components/GetCheckCode'
import Number from '@/components/CbNumber'
import { getLoginCode, doCodeLogin, getImagesApi, checkImagesApi } from '@/services/login'
import publicMixins from './publicMixins'
import { mapMutations } from 'vuex'
import { setAuthorization } from '@/utils/request'
import { phoneReg } from '@/utils/reg.js'
import slideverify from '@/components/CbSlideverify/index.vue'
export default {
  name: 'CheckCodeLogin',
  components: {
    GetCheckCode,
    Number,
    slideverify
  },
  mixins: [publicMixins],
  data() {
    return {
      visible: false,
      puzzePass: {},
      cutdown: null
    }
  },
  computed: {
    canUse() {
      const { phoneNum, code } = this.globForm
      return !(phoneNum && code)
    }
  },
  methods: {
    ...mapMutations('setting', ['setLoginType']),
    async onSubmit() {
      if (this.canUse) return
      const { phoneNum, code, uuid } = this.globForm
      if (!phoneNum) return this.$message.warning('请输入手机号')
      if (!code) return this.$message.warning('请输入验证码')
      if (!phoneReg.test(phoneNum)) return this.$message.warning('手机号码格式错误，请检查')
      const res = await doCodeLogin({ phoneNum, code, uuid })
      setAuthorization({ token: res.data.token.accessToken, expireAt: 7 })
      if (res.data.user.firstLogin) {
        this.setLoginType(6)
      } else {
        this.changeLoginType(8)
      }
    },
    async getCode(cutdown) {
      if (this.buttonCanUse) return
      const { phoneNum } = this.globForm
      if (phoneReg.test(phoneNum)) {
        this.getImage()
        this.cutdown = cutdown
      } else {
        this.$message.warning('手机号码格式错误，请检查')
      }
    },
    async onSuccess(left) {
      const res = await checkImagesApi({ uuid: this.puzzePass.uuid, moveX: left })
      if (res.code === 100) {
        if (res.data) {
          const { phoneNum } = this.globForm
          this.visible = false
          const res = await getLoginCode({ phoneNum })
          this.globForm.uuid = res.data
          this.cutdown()
        } else {
          this.$message.error('验证失败，重新验证')
          this.onRefresh()
        }
      }
    },
    onFail() {
      this.msg = ''
    },
    onRefresh() {
      this.getImage()
    },
    getImage() {
      getImagesApi({}).then(res => {
        if (res.code === 100) {
          res.data.bigImageBase64 = 'data:image/png;base64,' + res.data.bigImageBase64
          res.data.smallImageBase64 = 'data:image/png;base64,' + res.data.smallImageBase64
        }
        this.puzzePass = res.data
        this.visible = true
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./index.less');
.dialog{
  position: fixed;
  width:100%;
  height:100vh;
  top:0;
  left:0;
  z-index: 999;
  background: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: center;
  .dialogContent{
    display: inline-block;
    padding: 10px;
    background: #fff;
    border-radius: 6px;
    .title{
      display: flex;
      justify-content: space-between;
      margin-bottom:16px;
      align-items: center;
      p{
        margin:0;
        font-weight: bold;
        font-size:16px;
      }
    }
    .icon{
      /deep/.anticon{
        width:16px;
        height:16px;
        margin-left:16px;
        cursor: pointer;
      }
    }
  }
}
</style>
