var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('appForm',{attrs:{"to-list":_vm.toList,"screen-roster-list":_vm.screenRosterList},on:{"getSearchCallback":_vm.getSearchCallback,"screenRosterParams":_vm.screenRosterParams,"exportFileList":_vm.exportFileList}},[(_vm.keyval == '1')?_c('template',{slot:"customButton"},[_c('a-button',{attrs:{"type":"primary","prefix":"add-one"},on:{"click":_vm.handleAdd}},[_vm._v(" 批量开具证明 ")])],1):_vm._e()],2),_c('custom-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"tabs-height":36,"row-key":"proveApplyId","table-ref":".main-content","pagination":{
      current: _vm.pager.pageNum,
      pageSize: _vm.pager.pageSize,
      total: _vm.total,
      showTotal: function (total) { return ("共 " + total + " 条数据"); },
      showQuickJumper: true,
      showSizeChanger: true
    }},on:{"change":_vm.loadDataList},scopedSlots:_vm._u([{key:"index",fn:function(ref){
    var scope = ref.scope;
return [_c('span',[_vm._v(_vm._s(scope.index + 1))])]}},{key:"applyStatus",fn:function(ref){
    var scope = ref.scope;
return [_c('span',[(scope.record.applyStatus)?_c('StatusTag',{attrs:{"type":scope.record.applyStatus,"type-list":_vm.applyStatusList},on:{"click":function($event){return _vm.getCbApproveSteps(scope.record.flowId, scope.record.applyStatus)}}}):_vm._e()],1)]}},{key:"staffName",fn:function(ref){
    var scope = ref.scope;
return [_c('CbTableAvater',{attrs:{"table-avater":scope.record.avatar,"on-job-id":scope.record.onJobId,"staff-id":scope.record.staffId,"table-name":scope.record.staffName}})]}},{key:"operation",fn:function(ref){
    var scope = ref.scope;
return [(_vm.keyval == '1')?_c('a-button',{attrs:{"disabled":scope.record.applyStatus !== 2,"type":"link"},on:{"click":function($event){return _vm.getOpenerProve(scope.record)}}},[_vm._v(" 开具证明 ")]):_vm._e(),(_vm.keyval === '2')?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.handleDetail(scope, 'details')}}},[_vm._v(" 下载 ")]):_vm._e(),(_vm.keyval === '2')?_c('a-button',{staticClass:"delete",attrs:{"type":"link"},on:{"click":function($event){return _vm.editItemConfirm(scope)}}},[_vm._v(" 删除 ")]):_vm._e()]}}])}),_c('CertificateType',{ref:"certificateType",attrs:{"temp-type-id":_vm.tempTypeId},on:{"previousStep":_vm.previousStep,"nextStep":_vm.nextStep,"temId":_vm.temId}}),_c('PerDepModel',{attrs:{"placeholder":'请输入部门、人员、工号',"check-echo":_vm.selectPersonList,"query-leave-person":true},on:{"perModelConfirm":_vm.perModelConfirm},model:{value:(_vm.selectPerson),callback:function ($$v) {_vm.selectPerson=$$v},expression:"selectPerson"}}),_c('CbApproveSteps',{attrs:{"flow-id":_vm.flowId,"apply-status":_vm.applyStatus,"apply-status-list":_vm.applyStatusList},model:{value:(_vm.stepsVisible),callback:function ($$v) {_vm.stepsVisible=$$v},expression:"stepsVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }