<template>
  <div id="vacationPlan" class="VacationPlan beauty-scroll">
    <AlertTips>
      <template slot="content">
        <p>1.您可以选择已启用的假期类型，设置不同假期方案规则，并关联适用人员。</p>
        <p>2.设置后，可以在【假期】→【余额】管理年假、调休假、带薪假期等假期余额。</p>
        <p>3.有关假期扣款规则，您可以在【薪酬】→【设置】→【计薪规则】中进行设置。</p>
      </template>
    </AlertTips>
    <appForm
      :to-list="formList"
      search-placeholder="请搜索"
      @getSearchCallback="getSearchCallback"
    >
      <template slot="customButton">
        <div ref="customButton" class="custom-button">
          <a-dropdown :get-popup-container="()=>$refs.customButton">
            <!-- <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              {{ vacationName }} <a-icon type="down" />
            </a> -->
            <a-button suffix="left">
              {{ vacationName }}
            </a-button>
            <a-menu slot="overlay">
              <a-menu-item v-for="vacation in vacationTypeList" :key="vacation.vacationTypeId" @click="initData(vacation)">
                <a>{{ vacation.vacationTypeName }}</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <a-button type="primary" prefix="add-one" @click="addVacation">
            新增假期方案
          </a-button>
        </div>
      </template>
    </appForm>
    <custom-table
      id="table"
      :columns="columns"
      :data-source="dataSource"
      row-key="vacationRuleId"
      :pagination="false"
      @change="loadDataList"
    >
      <template slot="ruleName" slot-scope="{scope}">
        <span :title="scope.record.ruleName" class="ellipsis" v-html="scope.record.ruleName" />
      </template>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{ scope}">
        <div class="flex-align-center">
          <template>
            <div class="more-handle-btn-box">
              <a-button type="link" @click="editVacation(scope.record)">
                编辑
              </a-button>
              <a-button type="link" class="delete" @click="delet(scope.record)">
                删除
              </a-button>
            </div>
          </template>
          <!-- <a-popover
            placement="bottom"
            trigger="hover"
          >
            <template slot="content">
              <div class="more-handle-btn">
                <a-button type="link" @click="editVacation(scope.record)">
                  编辑
                </a-button>
                <a-button type="link" class="delete" @click="delet(scope.record)">
                  删除
                </a-button>
              </div>
            </template>
            <div class="more-handle">
              ···
            </div>
          </a-popover> -->
        </div>
      </template>
    </custom-table>
    <PlanModal ref="planModal" :form-type="formType" :vacation-rule-id="vacationRuleId" />
  </div>
</template>

<script>
import { getVacationType } from '@/services/attendance/holidays.js'
import { getVacationRuleList, deleteVacationRule } from '@/services/attendance/holidays.js'
import { debounce } from '@/utils/index.js'
import mixins from '@/components/CustomTable/index.js'
export default {
  name: 'VacationPlan',
  components: {
    PlanModal: () => import('./planModal/index'),
    AlertTips: () => import('../../components/alert.vue')
  },
  mixins: [mixins],
  data() {
    return {
      vacationTypeList: [],
      vacationName: '全部假期',
      formList: ['search'], // search搜索框
      columns: [{
        title: '方案名称',
        dataIndex: 'ruleName',
        key: 'ruleName',
        scopedSlots: { customRender: 'ruleName' },
        width: '120px',
        ellipsis: true
      },
      {
        title: '假期类型',
        dataIndex: 'vacationTypeName',
        key: 'vacationTypeName',
        width: '120px'
      },
      {
        title: '请假规则',
        dataIndex: 'applyRule',
        key: 'applyRule',
        width: '180px'
      },
      {
        title: '发放规则 ',
        dataIndex: 'grantRule',
        key: 'grantRule',
        width: '180px'
      },
      {
        title: '扣减规则',
        dataIndex: 'deductRule',
        key: 'deductRule',
        width: '180px'
      },
      {
        title: '适用范围',
        dataIndex: 'scope',
        key: 'scope',
        customCell: () => {
          return {
            style: {
              'min-width': '180px'
            }
          }
        },
        ellipsis: true
      },
      {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
        width: '100px',
        fixed: 'right'
      }],
      dataSource: [],
      loading: false,
      formVisible: true,
      vacationRuleId: undefined,
      formType: 'add'
    }
  },
  computed: {},
  created() {
    this.getTypeList()
  },
  methods: {
    // 获取假期类型
    async getTypeList() {
      const type = await getVacationType()
      this.vacationTypeList = [{ vacationTypeName: '全部假期', vacationTypeId: '' }, ...type.data]
    },
    // 请求数据
    async fetchDataApiFn(_queryParam) {
      const res = await getVacationRuleList({ ..._queryParam })
      if (res.code === 100) {
        this.dataSource = res.data
      }
    },
    // 条件查询
    initData({ vacationTypeName, vacationTypeId }) {
      this.vacationName = vacationTypeName
      this._queryParam.vacationTypeId = vacationTypeId
      this.serchGetData()
    },
    addVacation() {
      this.formType = 'add'
      this.$refs.planModal.open()
    },
    editVacation(record) {
      this.formType = 'edit'
      this.vacationRuleId = record.vacationRuleId
      this.$refs.planModal.open()
    },
    delet(record) {
      this.vacationRuleId = record.vacationRuleId
      this.$Reconfirm({
        title: '删除方案',
        content: '删除方案后，相关人员将无法享受该假期，确定要删除吗？'
      }).then(() => {
        this.handleDelPlan()
      }).catch(() => {
        console.log('no')
      })
    },
    handleDelPlan: debounce(async function() {
      const res = await deleteVacationRule({ vacationRuleId: this.vacationRuleId })
      if (res.code === 100) {
        this.$message.success('删除成功！')
        this.serchGetData()
      }
    }, 500),
    getSearchCallback({ searchValue }) {
      this._queryParam.searchWord = searchValue
      this.serchGetData()
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-dropdown-menu{
  max-height:300px;
  overflow: auto;
}
.VacationPlan{
  height: 100%;
  overflow: auto;
  background-color: #fff;
  padding:20px 20px 0;
  .custom-button{
    .ant-btn{
      margin-right:12px;
    }
  }
}
/deep/.ant-table-tbody > tr > td{
  white-space: pre-line;
}
/deep/.ant-table-row-cell-ellipsis{
  white-space: nowrap !important
}
.ellipsis{
  width:200px;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
}
.more-handle-btn-box{
  display: flex;
  .ant-btn{
    margin-right: 16px;
    &:last-child{
      margin: 0;
    }
  }
}
</style>
