<template>
  <div class="personnel-report">
    <div v-if="!isShow">
      <MonthPicker v-model="month" @change="monthChange" />
      <!-- 顶部标题提示 -->
      <Alert v-if="showTip" show-icon closable>
        <template #content>
          <span>提示:  本期计薪-核算计薪资环节的 应发工资 全部锁定后，方可进行报送人员统计</span>
          <a @click="toSalaryCalculation">前往本期计薪</a>
        </template>
      </Alert>
      <Alert v-if="unassignedNum" show-icon closable class="alert-text">
        <template #content>
          <span>有{{ unassignedNum }}名人员暂无报税主体, 有{{ ignoreStaffNum }}名不需要报税</span>
          <a @click="setUp">去设置</a>
        </template>
      </Alert>
      <div v-for="(item, index) in taxList" :key="index">
        <CbStatistics :data-list="[]" length="0" :is-title="true" :title-width="320" :is-function="true" class="statistics">
          <template slot="title">
            <div class="tax-left">
              <div class="tax-left-title">{{ item.companyName }}</div>
              <div v-if="item.checkStatus !== -1" class="tax-left-content">报送人员：{{ item.submissionNum }}</div>
            </div>
          </template>
          <template slot="right">
            <div class="tax-right">
              <a-button v-if="item.checkStatus === 0" prefix="heduirenyuan-18" @click="verifyStaff(item, true)">
                核对人员
              </a-button>
              <a-button v-if="item.checkStatus === 1" prefix="chakan-chakanmingxi" @click="verifyStaff(item, false)">
                查看
              </a-button>
            </div>
          </template>
        </CbStatistics>
      </div>
    </div>
    <div v-if="isShow">
      <VerifyStaff :info="taxpaysubjectInfo" :only-read="onlyRead" @goBack="goBack" />
    </div>
    <CbIgnore
      :visible="visible"
      title="无报税主体人员"
      :select-list="optionList"
      :data-prompt="staffList"
      replace-group-key="taxpaySubjectId"
      replace-ignore-type-key="needTaxpaySubject"
      :replace-fields="optionKeysList"
      @isIgnore="isIgnore"
      @setPerson="setPerson"
      @cancel="cancel"
    />
  </div>
</template>

<script>
import {
  getTaxpaySubjectListByPeriod,
  unassignedStaffNum,
  ignoreBatch,
  noticeResult
} from '@/services/wage/tax.js'
import { getEndyearBounsList } from '@/services/wage/account.js'
export default {
  name: 'PersonnelReport',
  components: {
    VerifyStaff: () => import('./verifyPersonnel/index.vue'),
    MonthPicker: () => import('../components/monthPicker.vue'),
    CbIgnore: () => import('@/components/CbIgnoreModal/index'),
    // TopAlert: () => import('../components/topAlert.vue'),
    Alert: () => import('@/components/CbAlert/index'),
    CbStatistics: () => import('@/components/CbStatistics/index')
  },
  data() {
    return {
      isShow: false,
      // 是否下一个月
      isNext: true,
      month: '',
      isYearPicker: false,
      // 无报税主体人员人数
      unassignedNum: '-',
      ignoreStaffNum: '-',
      // 无报税主体人员
      staffList: [],
      taxList: [
      ],
      taxpaysubjectInfo: {},
      visible: false,
      optionList: [],
      optionKeysList: {
        label: 'companyName',
        value: 'taxpaySubjectId'
      },
      onlyRead: false,
      showTip: false
    }
  },
  // mounted() {
  //   this.initData()
  // },
  methods: {
    // 获取提示信息是否提示
    async noticeResult() {
      const { data } = await noticeResult({
        taxpayPeriod: this.month
      })
      this.showTip = data
    },
    // 获取当前年份和月份 初始化报税主体
    async initData() {
      this.getUnassign()
      this.noticeResult()
      try {
        const res = await getTaxpaySubjectListByPeriod({ taxpayPeriod: this.month })
        this.taxList = res.data
      } catch (error) {
        this.taxList = []
        return
      }
      const list = await getEndyearBounsList()
      this.optionList = list.data
    },
    monthChange() {
      this.initData()
    },
    // 核对人员 查看人员
    verifyStaff(info, onlyRead) {
      this.taxpaysubjectInfo = info
      this.isShow = true
      this.onlyRead = onlyRead
    },
    goBack() {
      this.isShow = false
    },
    setUp() {
      this.visible = true
    },
    toSalaryCalculation() {
      this.$router.push({
        name: 'wagePayroll',
        params: {
          payPeriod: this.month
        }
      })
    },
    async isIgnore(item) {
      item.needTaxpaySubject = item.ignoreType ? 0 : 1
      const obj = {
        onJobId: item.onJobId,
        staffId: item.staffId,
        needTaxpaySubject: item.needTaxpaySubject,
        taxpaySubjectId: item.taxpaySubjectId
      }
      await ignoreBatch([obj]).then(res => {
        if (res.code === 100) {
          this.getUnassign().then(res => {
            this.$message.success('操作成功')
          })
        }
      })
    },
    async setPerson(arr) {
      const list = arr.map(({ onJobId, staffId, taxpaySubjectId, needTaxpaySubject }) => {
        return {
          onJobId,
          staffId,
          taxpaySubjectId,
          needTaxpaySubject: Number(needTaxpaySubject)
        }
      })
      console.log('arrarrarr', arr)
      await ignoreBatch(list).then(res => {
        if (res.code === 100) {
          this.$message.success('操作成功')
          this.visible = false
          this.getUnassign()
        }
      })
    },
    async getUnassign() {
      const { data } = await unassignedStaffNum({ taxpayPeriod: this.month })
      if (data) {
        this.staffList = data.staffInfoList.map((item) => {
          return {
            ...item,
            needTaxpaySubject: Boolean(item.needTaxpaySubject)
          }
        })
      }
      this.unassignedNum = data ? data.unassignedStaffNum : 0
      this.ignoreStaffNum = data ? data.ignoreStaffNum : 0
    },
    cancel(e) {
      this.visible = e
    }
  }
}
</script>
<style lang="less" scoped>
.personnel-report{
  width: 100%;
  padding:0 20px;
  height: calc(100% - 50px);
  margin-top: 4px;
  overflow: auto;
  /deep/.ant-alert{
    margin-bottom: 16px;
  }
  .ant-alert-description a{
    margin-left: 8px;
  }
}
.tax-right{
  display: flex;
 /deep/.ant-btn:last-child{
   margin-left: 12px;
  }
}
.tax-left{
  flex: 1;
  .font-size(16px);
  .tax-left-title{
    color: @sc-grey-100;
  }
  .tax-left-content{
    color: @sc-grey-60;
    margin-top: 12px;
  }
}

</style>
