<!--
 * @Date: 2022-07-07 13:55:58
 * @descriotion: 12312
-->
<template>
  <Drawer
    :title="title"
    :visible="visible"
    size="small"
    :z-index="2"
    @close="onClose"
    @onSubmit="onsubmit"
  >
    <div class="content">
      <UserInfo :user-info="userInfo" />
      <div class="insurance">
        <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item label="停保" prop="type">
            <a-checkbox-group v-model="form.type">
              <a-checkbox v-if="socialStatus === 1" value="social" name="社保">社保</a-checkbox>
              <a-checkbox v-if="medicalStatus === 1" value="medical" name="医保">医保</a-checkbox>
              <a-checkbox v-if="housingStatus === 1" value="housing" name="公积金">公积金</a-checkbox>
            </a-checkbox-group>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="setForm">
        <AddFormVue v-if="form.type.includes('social')" ref="social" title="社保" insure-classify="social" :is-flag="false" :disabled-list="disabledList" :form-data="socialData" stop-insure />
        <AddFormVue v-if="form.type.includes('medical')" ref="medical" title="医保" insure-classify="medical" :is-flag="false" :disabled-list="disabledList" :form-data="medicalData" stop-insure />
        <AddFormVue v-if="form.type.includes('housing')" ref="housing" title="公积金" insure-classify="housing" :is-flag="false" :disabled-list="disabledList" :form-data="housingData" stop-insure />
      </div>
    </div>
  </Drawer>
</template>
<script>
import {
  getStaffInsureInfoApi,
  getStopInsureApi,
  checkStopMonthExistSupplyApi,
  getInsureBillRemindStatusApi
} from '@/services/insurance/archives'
import { stopTips } from '../../bill/dict.js'
export default {
  name: 'StopMaintenance',
  components: {
    UserInfo: () => import('./userInfo'),
    AddFormVue: () => import('./addForm'),
    Drawer: () => import('@/components/CbDrawer/index')
  },
  props: {
    title: {
      type: String,
      default: '停保'
    }
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      form: {
        type: []
      },
      visible: false,
      disabledList: ['city', 'company', 'scheme', 'paymentBase'],
      userInfo: {},
      socialStatus: 1,
      medicalStatus: undefined,
      housingStatus: undefined,
      socialData: {},
      medicalData: {},
      housingData: {},
      rules: {
        type: [{ type: 'array', required: true, message: '请选择停保类型', trigger: 'change' }]
      },
      social: {},
      medical: {},
      housing: {}
    }
  },
  mounted() {},
  methods: {
    onClose() {
      this.form = {
        type: []
      }
      this.staffInsure = {
        yetInsureClassify: [],
        notInsureClassify: []
      }
      this.$refs.ruleForm.resetFields()
      this.userInfo = {}
      this.visible = false
    },
    async show(data, flag) {
      await this.getStaffInsureInfoApi(data.onJobId)
      if (flag) {
        if (this.socialStatus === 1) {
          this.form.type.push('social')
        }
        if (this.medicalStatus === 1) {
          this.form.type.push('medical')
        }
        if (this.housingStatus === 1) {
          this.form.type.push('housing')
        }
      }
      this.userInfo = data
      this.visible = true
    },
    async onsubmit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.social = { formData: { endMonth: null, decreasesReason: null }}
          this.medical = { formData: { endMonth: null, decreasesReason: null }}
          this.housing = { formData: { endMonth: null, decreasesReason: null }}
          if (this.form.type.includes('social')) {
            this.social = await this.$refs.social.getData()
            if (!this.social) return
          }
          if (this.form.type.includes('medical')) {
            this.medical = await this.$refs.medical.getData()
            if (!this.medical) return
          }
          if (this.form.type.includes('housing')) {
            this.housing = await this.$refs.housing.getData()
            if (!this.housing) return
          }
          let tips = ''
          const isSure = await checkStopMonthExistSupplyApi({
            onJobIds: this.userInfo.onJobId,
            socialStopMonth: this.form.type.includes('social') ? this.social.formData.endMonth : null,
            medicalStopMonth: this.form.type.includes('medical') ? this.medical.formData.endMonth : null,
            housingStopMonth: this.form.type.includes('housing') ? this.housing.formData.endMonth : null
          })
          const res = await getInsureBillRemindStatusApi({
            socialMonth: this.form.type.includes('social') ? this.social.formData.endMonth : null,
            medicalMonth: this.form.type.includes('medical') ? this.medical.formData.endMonth : null,
            housingMonth: this.form.type.includes('housing') ? this.housing.formData.endMonth : null
          })
          if (res.code === 100) {
            if (isSure.data) {
              tips += '<p>存在补缴，请到补缴中自行删除</p>'
            }
            if (res.data.socialBillRemindStatus === '1' && res.data.medicalBillRemindStatus === '1' && res.data.housingBillRemindStatus === '1') {
              if (isSure.data) {
                this.$Reconfirm({
                  title: '停保',
                  content: tips
                }).then(() => {
                  this.upData()
                }).catch(() => {})
              } else {
                this.upData()
              }
            } else {
              const arr = [res.data.socialBillRemindStatus, res.data.medicalBillRemindStatus, res.data.housingBillRemindStatus]
              const newArr = new Set(arr)
              newArr.forEach(item => {
                if (item !== '1') {
                  tips += stopTips[item]
                }
              })
              this.$Reconfirm({
                title: '停保',
                content: tips
              }).then(() => {
                this.upData()
              }).catch(() => {})
            }
          }
        } else {
          return false
        }
      })
    },
    async upData() {
      const res = await getStopInsureApi({
        onJobIds: [this.userInfo.onJobId], // 人员onJobId集合
        social: this.form.type.includes('social') ? { endMonth: this.social.formData.endMonth, decreasesReason: this.social.formData.decreasesReason } : null, // 社保
        medical: this.form.type.includes('medical') ? { endMonth: this.medical.formData.endMonth, decreasesReason: this.medical.formData.decreasesReason } : null, // 医保
        housing: this.form.type.includes('housing') ? { endMonth: this.housing.formData.endMonth, decreasesReason: this.housing.formData.decreasesReason } : null // 公积金
      })
      if (res.code === 100) {
        this.$message.success('停保成功！')
        this.$emit('callBackBatch')
        this.onClose()
      }
    },
    // 获取员工参保信息(调整时查询参保信息使用)
    async getStaffInsureInfoApi(onJobId) {
      const res = await getStaffInsureInfoApi({ onJobId })
      this.socialStatus = res.data.socialStatus
      this.medicalStatus = res.data.medicalStatus
      this.housingStatus = res.data.housingStatus
      this.socialData = res.data.social
      this.medicalData = res.data.medical
      this.housingData = res.data.housing
    }
  }
}
</script>
<style lang="less" scoped>
  .insurance {
    box-sizing: border-box;
  }
  .content{
    // padding:20px;
    width:100%;
    height:100%;
    position:relative;
    .font-size(14px);
    .setForm{
      width: 100%;
      height: calc(100% - 240px);
      overflow: auto;
      box-sizing: border-box;
    }
    /deep/.ant-form{
      padding-bottom: 0 !important;
    }
  }
</style>
