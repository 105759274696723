<template>
  <div>
    <Drawer
      title="批量调整"
      :closable="false"
      size="small"
      :z-index="2"
      :visible="visible"
      @close="onClose"
      @onSubmit="onSubmin"
    >
      <div class="content">
        <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item label="适用员工" :wrapper-col="wrapperCols">
            <div class="per_list" :style="!flag ? avatarClassDefault : ''">
              <div v-for="(item, index) in form.staff" :key="index" class="per_list_avatar">
                <CbAvatar
                  :on-job-id="item.onJobId"
                  :avater-name="item.staffName"
                  size="24"
                  :default-name-length="1"
                />
              </div>
              <div v-if="form.staff.length > 8" class="downUp" @click="flag = !flag">
                <svg class="iconpark-icon" :style="!flag ? 'transform: rotate(270deg)' : 'transform: rotate(90deg)'"><use href="#left" /></svg>
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item label="基数调整" prop="adjustmentType">
            <a-checkbox-group v-model="form.adjustmentType">
              <a-checkbox value="1" name="type">社保</a-checkbox>
              <a-checkbox value="2" name="type">医保</a-checkbox>
              <a-checkbox value="3" name="type">公积金</a-checkbox>
            </a-checkbox-group>
          </a-form-model-item>
          <div v-if="form.adjustmentType.includes('1')">
            <BlockTitle id="title" title="社保" />
            <a-form-model-item label="缴纳基数">
              <a-radio-group v-model="form.socialResource" @change="radioChange('social')">
                <a-radio value="1">固定金额</a-radio>
                <a-radio value="2">自定义金额</a-radio>
              </a-radio-group>
              <CbNumber v-if="form.socialResource === '1'" v-model="form.socialValue" :decimal-separator="true" placeholder="请输入" suffix="元" />
              <div v-if="form.socialResource === '2'" class="custom_layout">
                <dictionariesInput v-model="form.socialRegion" parameter="custom_scale_type" class="dictionariesInput" />
                <CbNumber v-model="form.socialPer" :decimal-separator="true" suffix="%" placeholder="请输入" style="width: 30%" />
              </div>
            </a-form-model-item>
          </div>
          <div v-if="form.adjustmentType.includes('2')">
            <BlockTitle id="title" title="医保" />
            <a-form-model-item label="缴纳基数">
              <a-radio-group v-model="form.medicalResource" @change="radioChange('medical')">
                <a-radio value="1">固定金额</a-radio>
                <a-radio value="2">自定义金额</a-radio>
              </a-radio-group>
              <CbNumber v-if="form.medicalResource === '1'" v-model="form.medicalValue" :decimal-separator="true" placeholder="请输入" suffix="元" />
              <div v-if="form.medicalResource === '2'" class="custom_layout">
                <dictionariesInput v-model="form.medicalRegion" parameter="custom_scale_type" class="dictionariesInput" />
                <CbNumber v-model="form.medicalPer" :decimal-separator="true" suffix="%" placeholder="请输入" style="width: 30%" />
              </div>
            </a-form-model-item>
          </div>
          <div v-if="form.adjustmentType.includes('3')">
            <BlockTitle id="title" title="公积金" />
            <a-form-model-item label="缴纳基数">
              <a-radio-group v-model="form.accumulationResource" @change="radioChange('accumulation')">
                <a-radio value="1">固定金额</a-radio>
                <a-radio value="2">自定义金额</a-radio>
              </a-radio-group>
              <CbNumber v-if="form.accumulationResource === '1'" v-model="form.accumulationValue" :decimal-separator="true" placeholder="请输入" suffix="元" />
              <div v-if="form.accumulationResource === '2'" class="custom_layout">
                <dictionariesInput v-model="form.accumulationRegion" parameter="custom_scale_type" class="dictionariesInput" />
                <CbNumber v-model="form.accumulationPer" :decimal-separator="true" suffix="%" placeholder="请输入" style="width: 30%" />
              </div>
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
    </Drawer>
  </div>
</template>

<script>
import {
  updateInsureApi,
  checkChangStaffSchemeApi
} from '@/services/insurance/archives'
import dictionariesInput from '@/components/CbDropDownInput/dictionariesInput/index'
export default {
  name: 'BatchAdjustment',
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index'),
    CbAvatar: () => import('@/components/CbAvater/theAvater.vue'),
    CbNumber: () => import('@/components/CbNumber/index'),
    Drawer: () => import('@/components/CbDrawer/index'),
    dictionariesInput
  },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      wrapperCols: { span: 20 },
      visible: false,
      flag: false,
      avatarClassDefault: {
        height: '110px',
        overflow: 'hidden'
      },
      form: {
        staff: [],
        adjustmentType: [],
        socialResource: '1',
        socialValue: '',
        socialRegion: undefined,
        socialPer: '',
        medicalResource: '1',
        medicalValue: '',
        medicalRegion: undefined,
        medicalPer: '',
        accumulationResource: '1',
        accumulationValue: '',
        accumulationRegion: undefined,
        accumulationPer: ''
      },
      socialMonthList: [],
      medicalMonth: [],
      accumulationMonth: [],
      rules: {
        adjustmentType: [{ type: 'array', required: true, message: '请选择调整类型', trigger: 'change' }]
      }
    }
  },
  methods: {
    // 打开抽屉
    show(data) {
      this.form.staff = data
      this.visible = true
    },
    // 保存
    async onSubmin() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          if (this.form.adjustmentType.includes('1') && (!this.form.socialValue && (!this.form.socialRegion || !this.form.socialPer))) {
            this.$message.warning('社保至少有一项基数必填')
            return
          }
          if (this.form.adjustmentType.includes('2') && (!this.form.socialValue && (!this.form.socialRegion || !this.form.socialPer))) {
            this.$message.warning('医保至少有一项基数必填')
            return
          }
          if (this.form.adjustmentType.includes('3') && (!this.form.socialValue && (!this.form.socialRegion || !this.form.socialPer))) {
            this.$message.warning('公积金至少有一项基数必填')
            return
          }
          const getStatus = await checkChangStaffSchemeApi({ onJobIds: this.arrHandle(this.form.staff).join(',') })
          if (getStatus.data.tenantBillStatus !== 0) {
            const tips = getStatus.data.tenantBillStatus === 1
              ? '本月账单已生成，调整内容下月生效，如需本月生效，请重新生成本月账单。确认是否要进行调整？'
              : '本月账单已锁定，调整内容下月生效，如需本月生效，请重新生成本月账单。确认是否要进行调整？'
            this.$Reconfirm({
              title: '调整',
              content: tips
            }).then(() => {
              this.batchUpdata()
            }).catch(() => {})
          } else {
            this.batchUpdata()
          }
        } else {
          return false
        }
      })
    },
    async batchUpdata() {
      const res = await updateInsureApi({
        onJobIds: this.arrHandle(this.form.staff),
        social: this.form.adjustmentType.includes('1')
          ? { paymentBaseType: this.form.socialResource, fixedPaymentBase: this.form.socialValue, customScaleType: this.form.socialRegion, customScale: this.form.socialPer }
          : null,
        medical: this.form.adjustmentType.includes('2')
          ? { paymentBaseType: this.form.medicalResource, fixedPaymentBase: this.form.medicalValue, customScaleType: this.form.medicalRegion, customScale: this.form.medicalPer }
          : null,
        housing: this.form.adjustmentType.includes('3')
          ? { paymentBaseType: this.form.accumulationResource, fixedPaymentBase: this.form.accumulationValue, customScaleType: this.form.accumulationRegion, customScale: this.form.accumulationPer }
          : null
      })
      if (res.code === 100) {
        this.$message.success('批量调整成功！')
        this.$emit('callBackBatch')
        this.onClose()
      }
    },
    arrHandle(list) {
      const arr = []
      for (let i = 0; i < list.length; i++) {
        arr.push(list[i].onJobId)
      }
      return arr
    },
    // 单选切换清空绑定值
    radioChange(e) {
      if (e === 'social') {
        this.form.socialValue = ''
        this.form.socialRegion = undefined
        this.form.socialPer = ''
      } else if (e === 'medical') {
        this.form.medicalValue = ''
        this.form.medicalRegion = undefined
        this.form.medicalPer = ''
      } else {
        this.form.accumulationValue = ''
        this.form.accumulationRegion = undefined
        this.form.accumulationPer = ''
      }
    },
    // 关闭抽屉
    onClose() {
      this.visible = false
      this.form.staff = []
      this.$refs.ruleForm.resetFields()
      this.form = {
        staff: [],
        adjustmentType: [],
        socialResource: '1',
        socialValue: '',
        socialRegion: undefined,
        socialPer: '',
        medicalResource: '1',
        medicalValue: '',
        medicalRegion: undefined,
        medicalPer: '',
        accumulationResource: '1',
        accumulationValue: '',
        accumulationRegion: undefined,
        accumulationPer: ''
      }
      this.$refs.ruleForm.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
  .per_list {
    position: relative;
    width: 465px;
    background: rgba(143, 147, 161, 0.05);
    border-radius: 4px 4px 4px 4px;
    border: 1px solid @sc-greyBg-10;
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    box-sizing: border-box;
    .per_list_avatar {
      width: 88px;
      margin: 0 20px 10px 0;
    }
    .downUp {
      cursor: pointer;
      position: absolute;
      top: 18px;
      right: 12px;
      color: @sc-greyBg-50;
      .iconpark-icon{
        width: 25px;
        height: 25px;
      }
    }
  }
  #title {
    margin-bottom: 20px;
    .title_division {
      width: 3px;
      height: 14px;
      background: @sc-primary-100;
      margin-right: 5px;
    }
  }
  .custom_layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dictionariesInput {
      width: 67%;
    }
  }
  /deep/.ant-form-item-label label ,/deep/.ant-radio-wrapper span ,/deep/.ant-drawer-title{
    color: @sc-grey-100;
  }
</style>
