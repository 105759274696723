<!-- 参保主体 -->
<template>
  <div class="socialSecurityScheme">
    <div class="socialSecurityScheme-head">
      <a-button prefix="add" @click="addSubject">
        新增参保主体
      </a-button>
    </div>
    <custom-table
      :scroll="{ y:650 }"
      :columns="columns"
      :data-source="dataSource"
      :pagination="false"
      row-key="companyId"
      @change="loadDataList"
    >
      <!-- 是否启用 -->
      <template slot="defaultValue" slot-scope="{scope}">
        <a-switch :checked="scope.record.defaultValue==='1' ? true : false" @click="setDefault(scope.record)" />
      </template>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{scope}">
        <span class="operation-btn-box">
          <a-button type="link" @click="edit(scope.record)">
            编辑
          </a-button>
          <a-button type="link" class="delete" danger @click="delCompany(scope.record)">
            删除
          </a-button>
        </span>
        <!-- <a-popover
          placement="bottom"
          trigger="hover"
        >
          <template slot="content">
            <div class="more-handle-btn">
              <a-button type="link" @click="edit(scope.record)">
                编辑
              </a-button>
              <a-button type="link" class="delete" danger @click="delCompany(scope.record)">
                删除
              </a-button>
            </div>
          </template>
          <div class="more-handle">
            ···
          </div>
        </a-popover> -->
      </template>
    </custom-table>
    <Drawer
      :title="title==='add'?'新增参保主体':'编辑参保主体'"
      :closable="false"
      size="small"
      :visible="visible"
      @close="onClose"
      @onSubmit="submit"
    >
      <div class="deawer-content">
        <a-form-model
          ref="messageForm"
          :model="messageForm"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="公司名称" prop="companyName">
            <a-input v-model="messageForm.companyName" placeholder="请填写公司名称" allow-clear style="width:100%" />
          </a-form-model-item>
          <a-form-model-item label="统一社会信用代码" prop="uniformCode">
            <a-input v-model="messageForm.uniformCode" placeholder="请填写18位统一社会信用代码" allow-clear style="width:100%" />
          </a-form-model-item>
          <a-form-model-item label="所属城市" prop="provinceCity">
            <CbSelectArea ref="selectArea" :government-auto="true" :area-show="false" :province-width="150" :city-width="150" @change="onChange" />
          </a-form-model-item>
          <a-form-model-item label="社保官方平台登录地址" prop="socialUrl">
            <a-input v-model="messageForm.socialUrl" placeholder="链接地址包括前缀https或http" allow-clear style="width:100%" />
          </a-form-model-item>
          <!-- <a-form-model-item label="社保登录用户名">
            <a-input v-model="messageForm.socialNum" placeholder="请填写社保登录用户名" allow-clear style="width:100%" />
          </a-form-model-item>
          <a-form-model-item label="社保登录口令">
            <a-input v-model="messageForm.socialKey" placeholder="请填写社保登录口令" allow-clear style="width:100%" />
          </a-form-model-item> -->
          <a-form-model-item label=" ">
            <a-button @click="goUrl(messageForm.socialUrl)">测试登录情况</a-button>
          </a-form-model-item>
          <a-form-model-item label="医保官方平台登录地址" prop="medicalUrl">
            <a-input v-model="messageForm.medicalUrl" placeholder="链接地址包括前缀https或http" allow-clear style="width:100%" />
          </a-form-model-item>
          <!-- <a-form-model-item label="医保登录用户名">
            <a-input v-model="messageForm.medicalNum" placeholder="请填写医保登录用户名" allow-clear style="width:100%" />
          </a-form-model-item>
          <a-form-model-item label="医保登录口令">
            <a-input v-model="messageForm.medicalKey" placeholder="请填写医保登录口令" allow-clear style="width:100%" />
          </a-form-model-item> -->
          <a-form-model-item label=" ">
            <a-button @click="goUrl(messageForm.medicalUrl)">测试登录情况</a-button>
          </a-form-model-item>
          <a-form-model-item label="公积金官方平台登录地址" prop="housingUrl">
            <a-input v-model="messageForm.housingUrl" placeholder="链接地址包括前缀https或http" allow-clear style="width:100%" />
          </a-form-model-item>
          <!-- <a-form-model-item label="公积金登录用户名">
            <a-input v-model="messageForm.housingNum" placeholder="请填写公积金登录用户名" allow-clear style="width:100%" />
          </a-form-model-item>
          <a-form-model-item label="公积金登录口令">
            <a-input v-model="messageForm.housingKey" placeholder="请填写公积金登录口令" allow-clear style="width:100%" />
          </a-form-model-item> -->
          <a-form-model-item label=" ">
            <a-button @click="goUrl(messageForm.housingUrl)">测试登录情况</a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </Drawer>
  </div>
</template>
<script>
import mixins from '@/components/CustomTable/index.js'
import { textReg } from '@/utils/reg.js'
import {
  getInsureCompanyListApi,
  createInsureCompanyApi,
  updateInsureCompany,
  delInsureCompanyApi,
  setupDefaultValueApi
} from '@/services/insurance/setting.js'
export default {
  name: 'MessageReminders',
  components: {
    CbSelectArea: () => import('@/components/CbSelectArea'),
    Drawer: () => import('@/components/CbDrawer/index')
  },
  mixins: [mixins],
  data() {
    const companyName = (rule, val, callback) => {
      if (!this.messageForm.companyName) {
        return callback(new Error('请输入公司名称'))
      } else {
        if (textReg.test(this.messageForm.companyName)) {
          return (new Error('公司名称不支持特殊字符'))
        } else if (this.messageForm.companyName.length > 50) {
          return (new Error('公司名称最多输入50个字'))
        } else {
          callback()
        }
      }
    }
    return {
      guidedTour: false,
      title: 'add',
      total: 0,
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      visible: false,
      messageForm: {
        companyName: '',
        uniformCode: '',
        province: '',
        cityCode: '',
        socialUrl: '',
        socialNum: '',
        socialKey: '',
        medicalUrl: '',
        medicalNum: '',
        medicalKey: '',
        housingUrl: '',
        housingNum: '',
        housingKey: ''
      },
      dataSource: [],
      columns: [
        {
          title: '公司名称',
          dataIndex: 'companyName',
          key: 'companyName',
          ellipsis: true,
          customCell: () => {
            return {
              style: {
                'min-width': '120px'
              }
            }
          }
        }, {
          title: '统一社会信用代码',
          dataIndex: 'uniformCode',
          key: 'uniformCode',
          ellipsis: true,
          customCell: () => {
            return {
              style: {
                'min-width': '120px'
              }
            }
          }
        }, {
          title: '所属城市',
          dataIndex: 'cityName',
          key: 'cityName',
          ellipsis: true,
          customCell: () => {
            return {
              style: {
                'min-width': '120px'
              }
            }
          }
        },
        // #region
        // {
        //   title: '社保登录用户名',
        //   dataIndex: 'socialNum',
        //   key: 'socialNum',
        //   ellipsis: true,
        //   customCell: () => {
        //     return {
        //       style: {
        //         'min-width': '80px'
        //       }
        //     }
        //   }
        // },
        // {
        //   title: '医保登录用户名',
        //   dataIndex: 'medicalNum',
        //   key: 'medicalNum',
        //   ellipsis: true,
        //   customCell: () => {
        //     return {
        //       style: {
        //         'min-width': '160px'
        //       }
        //     }
        //   }
        // }, {
        //   title: '公积金登录用户名',
        //   dataIndex: 'housingNum',
        //   key: 'housingNum',
        //   ellipsis: true,
        //   customCell: () => {
        //     return {
        //       style: {
        //         'min-width': '160px'
        //       }
        //     }
        //   }
        // },
        // #endregion
        {
          title: '默认主体',
          dataIndex: 'defaultValue',
          key: 'defaultValue',
          ellipsis: true,
          scopedSlots: { customRender: 'defaultValue' },
          customCell: () => {
            return {
              style: {
                'min-width': '160px'
              }
            }
          }
        }, {
          title: '操作',
          scopedSlots: { customRender: 'operation' },
          width: 100
        }
      ],
      rules: {
        companyName: [{ required: true, validator: companyName, trigger: 'blur' }],
        uniformCode: [
          {
            pattern: /^([159Y]{1})([1239]{1})([0-9ABCDEFGHJKLMNPQRTUWXY]{6})([0-9ABCDEFGHJKLMNPQRTUWXY]{9})([0-90-9ABCDEFGHJKLMNPQRTUWXY])$/,
            message: '统一社会信用编码格式错误',
            trigger: 'change'
          }
        ],
        provinceCity: [],
        medicalUrl: [{ pattern: /^((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+/,
          message: '平台登录地址格式错误',
          trigger: 'change'
        }],
        housingUrl: [{ pattern: /^((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+/,
          message: '平台登录地址格式错误',
          trigger: 'change'
        }],
        socialUrl: [{ pattern: /^((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+/,
          message: '平台登录地址格式错误',
          trigger: 'change'
        }]
        // unitSocial: [
        //   { max: 20, message: '单位社保编号最大支持20个字符', trigger: 'change' },
        //   { pattern: '^[A-Za-z0-9]+$', message: '单位社保编号格式错误', trigger: 'change' }
        // ],
        // unitMedicare: [
        //   { max: 20, message: '单位医保编号最大支持20个字符', trigger: 'change' },
        //   { pattern: '^[A-Za-z0-9]+$', message: '单位医保编号格式错误', trigger: 'change' }
        // ],
        // unitAccumulationFund: [
        //   { max: 20, message: '单位公积金编号最大支持20个字符', trigger: 'change' },
        //   { pattern: '^[A-Za-z0-9]+$', message: '单位公积金编号格式错误', trigger: 'change' }
        // ]
      },
      isFirst: false
    }
  },
  watch: {
    company() {
      if (this.messageForm.companyName) { this.$refs.messageForm.validateField('company', () => {}) }
    },
    'messageForm.uniformCode': {
      handler(val) {
        const address = {
          province: val.slice(2, 4) + '0000',
          city: val.slice(2, 6) + '00',
          county: val.slice(2, 8)
        }
        // 判断信用代码长度
        if (this.messageForm.province === '' && val.length === 18) {
          if (this.isFirst || this.title === 'add') {
            this.$refs.selectArea.dataEcho({ province: address.province, city: address.city }) // 回显省市
          } else if (address.county !== this.messageForm.cityCode) { // 如果回显数据与填写的信用代码不同
            this.isFirst = true
            this.$nextTick(() => {
              this.$refs.selectArea.dataEcho({ province: this.messageForm.provinceCode, city: this.messageForm.cityCode }) // 回显省市
            })
          }
        }
      }
    }
  },
  mounted() {
    this.rules.provinceCity = [
      { required: true, validator: this.getProvinceCity, trigger: 'change' }
    ]
  },
  methods: {
    // 测试跳转
    goUrl(url) {
      const reg = /^((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+/
      if (reg.test(url)) {
        window.open(url)
      } else {
        this.$message.error('请填写正确的官方平台登陆地址')
      }
    },
    // 设置默认主体
    async setDefault(data) {
      const res = await setupDefaultValueApi({ companyId: data.companyId, defaultValue: data.defaultValue === '1' ? 0 : 1 })
      if (res.code === 100) {
        this.serchGetData()
      }
    },
    // 所属城市校验
    getProvinceCity(rule, value, callback) {
      this.$nextTick(() => {
        if (!this.messageForm.province) {
          callback(new Error('请选择所属省份'))
        } else if (!this.messageForm.cityCode) {
          callback(new Error('请选择所属城市'))
        } else {
          callback()
        }
      })
    },
    // 初始获取列表数据
    async fetchDataApiFn() {
      const res = await getInsureCompanyListApi()
      this.dataSource = res.data ? res.data : []
    },
    // 新增接口
    async createInsureCompanyApi() {
      const res = await createInsureCompanyApi(this.messageForm)
      if (res.code === 100) {
        this.$message.success('新增成功')
        this.serchGetData()
        this.onClose()
      }
    },
    // 修改接口
    async updateInsureCompany() {
      const res = await updateInsureCompany({ ...this.messageForm })
      if (res.code === 100) {
        this.$message.success('修改成功')
        this.serchGetData()
        this.onClose()
      }
    },
    // 新增
    addSubject() {
      this.title = 'add'
      this.visible = true
    },
    // 编辑
    edit(e) {
      this.messageForm = { ...e }
      this.title = 'edit'
      this.visible = true
      this.$nextTick(() => {
        this.$refs.selectArea.dataEcho({ province: e.provinceCode, city: e.cityCode }) // 回显省市
      })
    },
    // 删除
    delCompany(scope) {
      this.$Reconfirm({
        title: '删除',
        content: '删除参保主体'
      }).then(() => {
        this.delInsureCompanyApi(scope.companyId)
      }).catch(() => {})
    },
    async delInsureCompanyApi(companyId) {
      const res = await delInsureCompanyApi({ companyId: companyId })
      if (res.code === 100) {
        this.$message.success('删除成功')
        this.serchGetData()
      }
    },
    // 所属城市选择
    onChange(val, e) {
      this.$set(this.messageForm, 'province', e[0])
      this.$set(this.messageForm, 'cityCode', e[1])
    },
    // 关闭抽屉
    onClose() {
      this.visible = false
      this.isFirst = false
      this.guidedTour = false
      this.$refs.selectArea.closeClearValue() // 清除省市
      this.$refs.messageForm.clearValidate()
      this.messageForm = this.$options.data().messageForm
    },
    // 确认
    submit() {
      this.$refs.messageForm.validate(async(valid) => {
        if (valid) {
          if (this.title === 'add') {
            await this.createInsureCompanyApi()
          } else {
            await this.updateInsureCompany()
          }
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.socialSecurityScheme{
  padding: 20px;
  .socialSecurityScheme-head{
    /deep/.ant-btn{
      margin-bottom: 16px;
    }
  }

}
.more-handle {
  font-weight: 700;
  color: @sc-primary-100;
  font-size: 20px;
  vertical-align: baseline;
  cursor: pointer;
}
 .more-handle-btn {
  display: flex;
  flex-direction: column;
  .ant-btn {
    height:25px;
  }
}
/deep/.ant-form-item-label label{
  color: @sc-grey-100;
}
/deep/.ant-select-enabled{
  &:nth-child(3){
    display: none;
  }
}
.operation-btn-box{
  display:flex;
  button{
    padding-left:0;
    padding-right:16px;
  }
}
</style>
