<template>
  <div class="verify-staff">
    <template v-if="!isImport">
      <HeaderBack :title="navName" @back="$emit('goBack')">
        <template #left><span class="company-name">{{ info.companyName||'申报主体' }}</span></template>
      </HeaderBack>
      <CbNumStatistics :is-tabs="true" :statistics-info="statisticsInfo" class="Statistics" @changeTab="changeTab" />
    </template>
    <!-- 表格组件 -->
    <component
      :is="tableType"
      v-if="!isImport"
      :only-read="onlyRead"
      :screen-roster-list="screenRosterList"
      :subject-ids="subjectIds"
      @importTable="importTable"
      @getPersonnelStatistics="getPersonnelStatistics"
    />
    <!-- 导入表格 -->
    <BatchImport
      v-if="isImport"
      :extra-params="{...subjectIds,type}"
      title="人员报送批量导入"
      import-template="importTaxFilingInfoList"
      @goBack="isImport = false"
    />
  </div>
</template>

<script>
import {
  getPersonnelStatistics
} from '@/services/wage/tax.js'
import CbNumStatistics from '@/components/CbNumStatistics/index'
import moment from 'moment'
export default {
  name: 'VerifyStaff11',
  components: {
    CbNumStatistics,
    HeaderBack: () => import('@/components/headerBack/index'),
    SubmissionTable: () => import('./components/submissionTable.vue'), // 报送人员列表
    ReducingTable: () => import('./components/reducingTable.vue'), // 减员人员列表
    TaxFilingTable: () => import('./components/taxFilingTable.vue'), // 报税人员列表
    ChangedTable: () => import('./components/changedTable.vue'), // 主体变动列表
    BatchImport: () => import('@/components/CbBatchImport/newImport.vue') // 导入表格组件
  },
  props: {
    info: {
      type: Object,
      default: function() {}
    },
    onlyRead: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 保存报送主体ids
      subjectIds: {},
      statisticsInfo: {},
      isImport: false,
      // 汇总信息
      statisticsObj: {
        submissionNum: '3',
        reducingNum: '4',
        taxFilingNum: '10',
        unassignedStaffNum: '2'
      },
      // 表格类型 默认报送人员列表
      tableType: 'SubmissionTable',
      type: 0,
      // 筛选
      screenRosterList: {
        'list': [
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'width': '100%',
              'parameter': 'engage_mode',
              defaultValue: '',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'engageMode'
          },
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'width': '100%',
              defaultValue: '',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'orgId'
          },
          {
            'type': 'selectPost',
            'label': '岗位',
            'options': {
              'width': '100%',
              defaultValue: '',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'postId'
          },
          {
            'type': 'selectDictionaries',
            'label': '合同公司',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'contract_company',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'contractCo',
            'key': 'selectDictionaries_1650962628706'
          },
          {
            'type': 'selectDictionaries',
            'label': '工作地点',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'work_address',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'workPlaceId',
            'key': 'workplaceIds',
            'rules': [
              {
                'required': false,
                'message': '请选择'
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '员工状态',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'staff_status',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'staffStatus',
            'key': 'selectDictionaries_1651110897449'
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      dataList: [
        {
          label: '报送人员',
          key: 'submissionNum',
          componentName: 'SubmissionTable',
          value: '--',
          selected: true
        },
        {
          label: '减员人员',
          key: 'reducingNum',
          componentName: 'ReducingTable',
          value: '--'
        },
        {
          label: '报税人员',
          key: 'taxFilingNum',
          componentName: 'TaxFilingTable',
          value: '--'
        },
        {
          label: '报税主体变动',
          key: 'changeStaffNum',
          componentName: 'ChangedTable',
          value: '--'
        }
      ]
    }
  },
  computed: {
    navName() {
      return moment(this.info.taxpayPeriod).format('YYYY年MM月')
    }
  },
  created() {
    // 格式化参数subjectIds
    const { taxpayPeriod, taxpayPeriodInfoId, taxpaySubjectId } = this.info
    this.subjectIds = {
      taxpayPeriod,
      taxpayPeriodInfoId,
      taxpaySubjectId
    }
    this.getPersonnelStatistics()
  },
  methods: {
    // 获取报送汇总信息
    async getPersonnelStatistics() {
      const statistics = await getPersonnelStatistics(this.subjectIds)
      this.statisticsObj = { ...statistics.data }
      this.dataList.forEach((item, index) => {
        Object.keys(this.statisticsObj).forEach((i) => {
          if (item.key === i) {
            this.dataList[index].value = this.statisticsObj[i]
          }
        })
      })
      this.statisticsInfo = {
        tabList: this.dataList
      }
      console.log(this.statisticsInfo)
    },
    // 获取报送人员列表
    changeTab(val, index) {
      console.log(val, index)
      this.getPersonnelStatistics()
      this.tableType = val.componentName
      this.type = index
    },
    // 批量导入
    importTable() {
      this.isImport = true
    }
  }
}
</script>

<style lang="less" scoped>
.header{
  margin-bottom: 20px;
}
.company-name{
  margin-left:15px
}
.Statistics{
  margin-bottom: 20px;
}
/deep/.content-list li{
  position: relative;
  &:nth-child(-n+3)::after {
    content: '';
    position: absolute;
    right: 0;
    height: 30px;
    width: 1px;
    background-color: @sc-greyBg-20;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
