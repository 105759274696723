<template>
  <div class="box">
    <a-button class="box_margin" @click="cancel('no')">审核驳回,通知TA修改</a-button>
    <a-button type="primary" @click="confirm('yes')">确认无误,更新员工信息</a-button>
  </div>
</template>

<script>
import { examEntryForm } from '@/services/user/roster.js'
import { approve } from '@/services/user/archive.js'
export default {
  name: 'Examine',
  props: {
    userId: {
      type: String,
      default: ''
    },
    pageType: {
      type: String,
      default: ''
    },
    archivesPerfectId: {
      type: String,
      default: ''
    },
    onJobId: {
      type: String,
      default: ''
    }
  },
  methods: {
    confirm(e) {
      this.$Reconfirm({
        title: '提示',
        content: `确定要通过审核吗?`
      }).then(() => {
        this.submin('yes')
      }).catch(() => {
      })
    },
    cancel(e) {
      this.$Reconfirm({
        title: '提示',
        content: `确定要驳回审核吗?`
      }).then(() => {
        this.submin('no')
      }).catch(() => {
      })
    },
    async submin(val) {
      if (this.pageType === 'archive') {
        // 档案审核
        const res = await approve({
          id: this.archivesPerfectId,
          status: val === 'no' ? 4 : 3
        })
        if (res.code === 100) {
          this.$message.success(val === 'no' ? '驳回成功' : '已通过审核')
          this.$router.replace(decodeURIComponent(this.$route.query.path))
        }
      } else {
        const res = await examEntryForm({
          onJobId: this.onJobId,
          status: val === 'no' ? '3' : '2'
        })
        if (res.code === 100) {
          this.$message.success(val === 'no' ? '驳回成功' : '已通过审核')
          this.$router.replace(decodeURIComponent(this.$route.query.path))
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .box {
    width: 1708px;
    height: 100px;
    background: @sc-greyBg-50;
    opacity: 0.8;
    text-align: center;
    line-height: 100px;
    .box_margin {
      margin-right: 20px;
    }
  }
</style>
