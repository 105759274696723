<template>
  <div class="daily-table">
    <div v-if="!dragValue">
      <div v-if="!detailVisible">
        <div class="daily-toolbar">
          <div class="left-box">
            <svg class="iconpark-icon"><use href="#rongqi" /></svg>
            <a-month-picker v-model="time" value-format="YYYY-MM" :disabled-date="disabledDate" :bordered="false" @change="dateChange">
              <span>{{ time }}</span>
            </a-month-picker>
            <a-button class="month" @click="onday">当月</a-button>
          </div>
        </div>
        <div class="toolbar-flex">
          <div class="toobar-left">
            <a-button prefix="faqishenpi" :disabled="ifSend !== '0' || !ifSend" @click="allSend">{{ ifSend === '2'?'已归档':ifSend === '1'?'考勤确认中':'发送考勤确认' }}</a-button>
          </div>
          <div class="toobar-right">
            <appForm
              ref="appForm"
              :to-list="formList"
              :not-shown-list="notShownList"
              :display-list="displayList"
              :do-not-drag-list="doNotDragList"
              search-placeholder="请搜索姓名/工号"
              :screen-roster-list="screenRosterList"
              @exportFileList="exportFileList"
              @getSearchCallback="getSearchCallback"
              @screenRosterParams="screenRosterParams"
              @searchHeaderCallback="searchHeaderCallback"
            />
          </div>
        </div>
        <div class="daily-statistic-left">
          <div v-if="statistcs.requiredNumber>0" class="statistic-p">出勤：{{ statistcs.requiredNumber }}</div>
          <div v-if="statistcs.allNumber>0" class="statistic-p">满勤：{{ statistcs.allNumber }}</div>
          <div v-if="statistcs.lackNumber>0" class="statistic-p">旷工：<span>{{ statistcs.lackNumber }}</span></div>
          <div v-if="statistcs.overtimeNumber>0" class="statistic-p">加班：<span>{{ statistcs.overtimeNumber }}</span></div>
          <div v-if="statistcs.missNumber>0" class="statistic-p">缺卡：<span>{{ statistcs.missNumber }}</span></div>
          <div v-if="statistcs.lateNumber>0" class="statistic-p">迟到：<span>{{ statistcs.lateNumber }}</span></div>
          <div v-if="statistcs.leaveNumber>0" class="statistic-p">请假：<span>{{ statistcs.leaveNumber }}</span></div>
          <div v-if="statistcs.earlyNumber>0" class="statistic-p">早退：<span>{{ statistcs.earlyNumber }}</span></div>
          <div v-if="statistcs.outNumber>0" class="statistic-p">外出：<span>{{ statistcs.outNumber }}</span></div>
          <div v-if="statistcs.travelNumber>0" class="statistic-p">出差：<span>{{ statistcs.travelNumber }}</span></div>
        </div>
        <custom-table
          :columns="columns"
          :data-source="dataSource"
          row-key="attendanceResultId"
          :pagination="{
            current: pager.pageNum,
            pageSize: pager.pageSize,
            total: total,
            showTotal: (total) => `共 ${total} 条数据`,
            showQuickJumper: true,
            showSizeChanger: true
          }"
          @change="haedChang"
        >
          <!-- 序号 -->
          <template slot="index" slot-scope="{ scope }">
            <span>{{ scope.index + 1 }}</span>
          </template>
          <!-- 考勤组 -->
          <template slot="attendanceGroupId" slot-scope="{scope}">
            <span class="attendanceGroupId" :title="scope.record.attendanceGroupName">{{ scope.record.attendanceGroupName }}</span>
          </template>
          <template slot="staffName" slot-scope="{ scope }">
            <CbTableAvater :table-name="scope.record.staffName" :on-job-id="scope.record.onJobId" :staff-id="scope.record.staffId" class="staffName" :title="scope.record.staffName" />
          </template>
          <template slot="confirmStatus" slot-scope="{ scope }">
            <span>{{ getCodeContent(scope.record.confirmStatus,attendance_confirm_status) }}</span>
          </template>
          <!-- 操作列 -->
          <template slot="operation" slot-scope="{ scope}">
            <div class="flex-align-center">
              <!-- <a-popover
                placement="bottom"
                trigger="hover"
              >
                <template slot="content">
                  <div class="more-handle-btn">
                    <a-button type="link" class="detail" @click="detail(scope.record)">
                      详情
                    </a-button>
                    <a-button class="delete" type="link" @click="del(scope.record)"> 删除 </a-button>
                  </div>
                </template>
                <div class="more-handle">
                  ···
                </div>
              </a-popover> -->
              <a-button type="link" class="detail" @click="detail(scope.record)">
                详情
              </a-button>
            </div>
          </template>
        </custom-table>
      </div>
      <SummaryDetail v-else :on-job-id="onJobId" :user-name="userName" :date="time" @goBack="goBack" />
    </div>
    <ExcelImport v-else :title="importTitle" @toggleValue="dragValue = false" />
    <Modal
      :closable="true"
      size="normal"
      title="发送本月考勤确认"
      :visible="visible"
      ok-text="发送"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="modal-main">
        <p style="color: #4c5466;">规则说明</p>
        <ul style=" margin-bottom: 30px; color: #202A40;">
          <li style="line-height: 30px;">1、每月仅可给每个员工发送一次确认考勤结果通知。</li>
          <li style="line-height: 30px;">2、员工收到通知后，若未确认，系统会在第二天11:00，自动再次发送提醒。</li>
          <li style="line-height: 30px;">3、若员工在收到两次提醒后仍未确认，系统将依据您设置的自动确认时间为员工自动确认。</li>
        </ul>
        <div class="aredio" style="color:#4c5466;">
          自动确认时间
          <a-select v-model="confirmTime" style="width: 150px;margin-left: 20px;">
            <a-select-option :value="3">
              收到提醒3天后
            </a-select-option>
            <a-select-option :value="2">
              收到提醒2天后
            </a-select-option>
            <a-select-option :value="1">
              收到提醒1天后
            </a-select-option>
          </a-select>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import moment from 'moment'
import select from '@/components/CbPerDepSelection/index.js'
import { getMonthAttendancePageList, getResultItem, saveResultItem, deleteMonthAttendance, confirm, exportMonthAttendance, ifSendConfirm } from '@/services/attendance/attendance.js'
export default {
  name: 'MonthlySummary',
  components: {
    Modal: () => import('@/components/CbModal/index'),
    appForm: () => import('@/components/CbAppForm/index'),
    SummaryDetail: () => import('./summaryDetail.vue'),
    CbTableAvater: () => import('@/components/CbTableAvater/index'),
    ExcelImport: () => import('../../components/excelImport.vue')
  },
  mixins: [mixins, select],
  data() {
    return {
      importTitle: '批量导入月度汇总',
      dragValue: false,
      confirmTime: 1,
      confirmButton: '',
      doNotDragList: ['1534795954914508802', '1534795954910314498', '1534795954885148673', '1534795954901925889'],
      large: '',
      time: moment(new Date()).format('YYYY-MM'),
      formList: ['search', 'export', 'screen', 'choice'], // search搜索框 screen筛选 export导出 choice配置表头
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'index' },
          fixed: 'left',
          width: '50px',
          ellipsis: true
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          key: 'staffName',
          width: '105px',
          fixed: 'left',
          scopedSlots: { customRender: 'staffName' }
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          width: '120px',
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          width: '100px',
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          width: '100px',
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '50px',
          fixed: 'right'
        }
      ],
      // 筛选内容
      screenRosterList: {
        'list': [
          {
            'type': 'selectDictionaries',
            'label': '考勤确认',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择考勤确认状态',
              'showSearch': false,
              'parameter': 'attendance_confirm_status'
            },
            'model': 'confirmStatus',
            'key': 'confirmStatus'
          },
          {
            'type': 'selectDictionaries',
            'label': '员工状态',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择员工状态',
              'showSearch': false,
              'parameter': 'staff_status'
            },
            'model': 'staffStatuses',
            'key': 'staffStatuses',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '出勤结果',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'attendance_result_item',
              'clearable': true,
              'placeholder': '请选择出勤结果',
              'showSearch': false
            },
            'model': 'resultList',
            'key': 'resultList',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': ''
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'engage_mode',
              'clearable': true,
              'placeholder': '请选择聘用形式',
              'showSearch': false
            },
            'model': 'engageModes',
            'key': 'engageModes'
          },
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择部门',
              'showSearch': false
            },
            'model': 'orgIds',
            'key': 'orgIds',
            'rules': [
              {
                'required': false,
                'message': '请选择部门'
              }
            ]
          },
          {
            'type': 'selectPost',
            'label': '岗位',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择岗位',
              'showSearch': false
            },
            'model': 'postIds',
            'key': 'postIds',
            'rules': [
              {
                'required': false,
                'message': '请选择岗位'
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 80,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      selectedRows: [],
      // 删除使用框
      selectedRowKey: [],
      unSelect: true, // 是否禁用
      selectedRowKeys: [], // 当前选择列集合
      selectionMode: true,
      dataSource: [], // 数据
      dataUserName: '', // 将点击详情时传递的userName值
      statistcs: {},
      ifSend: '',
      detailVisible: false, // 详情
      visible: false, // 全员发送考勤确认
      editdrawer: false,
      editList: [],
      editRules: {
        usersName: [{ required: false, message: '请输入姓名', trigger: 'blur' }]
      },
      labelCol: { lg: 5, xl: 4, xxl: 3 },
      wrapperCol: { lg: 12, xl: 13, xxl: 14 },
      notShownList: [],
      displayList: [],

      // 字典项
      attendance_confirm_status: []
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.getResultItem()
  },
  async created() {
    this.attendance_confirm_status = await this.getTenantCode('attendance_confirm_status')
  },
  methods: {
    // 是否可以发送考勤确认
    ifSendConfirm() {
      ifSendConfirm({ yearMonth: this.time.split('-').join('') }).then(res => {
        this.ifSend = res.data
      })
    },
    // 时间框发生变化事件
    dateChange() {
      this.serchGetData()
      this.selectedRowKeys = []
    },
    // 表头查询
    async getResultItem() {
      const res = await getResultItem()
      const noShow = []
      res.data.noneHeader.map(item => {
        noShow.push(...item.list)
      })
      this.notShownList = noShow.map(item => {
        const data = {
          ...item,
          id: item.attendanceResultItemId,
          name: item.attendanceResultItemName,
          d: false
        }
        if (item.attendanceResultItemType === 'A') {
          data.d = true
        } else {
          data.d = false
        }
        return data
      })
      this.displayList = res.data.displayHeader.map(item => {
        const data = {
          ...item,
          id: item.attendanceResultItemId,
          name: item.attendanceResultItemName,
          d: false
        }
        if (item.attendanceResultItemType === 'A') {
          data.d = true
        } else {
          data.d = false
        }
        return data
      })
    },
    // 选择表头
    async searchHeaderCallback(data, val) {
      const show = data.map((item, index) => {
        return {
          attendanceResultItemId: item.attendanceResultItemId,
          selectedFlag: 1,
          sortNo: index
        }
      })
      const noShow = val.map(item => {
        return {
          attendanceResultItemId: item.attendanceResultItemId,
          selectedFlag: 0,
          sortNo: item.sortNo
        }
      })
      const headers = [...show, ...noShow]
      const res = await saveResultItem(headers)
      if (res.code === 100) {
        this.$message.success('表头设置成功')
        this.getResultItem()
        this.serchGetData()
      }
    },
    // 获取每日出勤列表
    async fetchDataApiFn(param) {
      const res = await getMonthAttendancePageList({ ...param, month: this.time.split('-').join('') })
      this.dataSource = res.data.list.records ? res.data.list.records : []
      this.pager.pageNum = res.data.list.current || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.list.size || 10 // 更新当前的分页信息
      this.total = res.data.list.total
      this.statistcs = res.data.statistcs
      this.columns = [{
        title: '序号',
        scopedSlots: { customRender: 'index' },
        fixed: 'left',
        align: 'center',
        width: '50px',
        ellipsis: true
      },
      {
        title: '姓名',
        dataIndex: 'staffName',
        key: 'staffName',
        width: '105px',
        fixed: 'left',
        scopedSlots: { customRender: 'staffName' },
        ellipsis: true
      },
      {
        title: '工号',
        dataIndex: 'staffNum',
        key: 'staffNum',
        width: '120px',
        fixed: 'left',
        ellipsis: true
      },
      {
        title: '部门',
        dataIndex: 'orgName',
        key: 'orgName',
        width: '100px',
        fixed: 'left',
        ellipsis: true
      },
      {
        title: '岗位',
        dataIndex: 'postName',
        key: 'postName',
        width: '100px',
        fixed: 'left',
        ellipsis: true
      }]
      res.data.itemCode.map((item, index) => {
        // console.log(item)
        if (item.selectedFlag === '1' && item.attendanceResultItemType !== 'A') {
          const data = {
            title: item.attendanceResultItemName,
            dataIndex: item.attendanceResultItemCode,
            scopedSlots: { customRender: item.attendanceResultItemCode },
            fixed: false,
            width: `${item.attendanceResultItemName.length * 30}px`,
            sorter: item.sortSign,
            ellipsis: true
          }
          console.log(data)
          this.columns.push(data)
        }
      })
      this.columns.push(
        {
          title: '考勤确认',
          dataIndex: 'confirmStatus',
          key: 'confirmStatus',
          width: '120px',
          scopedSlots: { customRender: 'confirmStatus' },
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '50px',
          fixed: 'right'
        }
      )
      this.ifSendConfirm()
    },
    // 搜索
    getSearchCallback(value) {
      this._queryParam.searchWord = value.searchValue
      this.serchGetData()
    },
    // 筛选
    screenRosterParams(param) {
      this._queryParam.searchWord = param.searchValue
      this._queryParam.engageModes = param.screenCondition.engageModes ? param.screenCondition.engageModes.join(',') : ''
      this._queryParam.resultList = param.screenCondition.resultList ? param.screenCondition.resultList.join(',') : ''
      this._queryParam.staffStatuses = param.screenCondition.staffStatuses ? param.screenCondition.staffStatuses.join(',') : ''
      this._queryParam.postIds = param.screenCondition.postIds ? param.screenCondition.postIds.join(',') : ''
      this._queryParam.confirmStatus = param.screenCondition.confirmStatus
      if (param.screenCondition.orgIds) {
        const orgIds = param.screenCondition.orgIds.map(item => {
          return item.value
        })
        this._queryParam.orgIds = orgIds.join(',')
      }
      for (var key in this._queryParam) {
        if (!this._queryParam[key]) {
          delete this._queryParam[key]
        }
      }
      this.serchGetData()
    },
    // 导出
    exportFileList() {
      const { staffStatuses, engageModes, orgIds, postIds, resultList, searchWord, sort } = this._queryParam
      exportMonthAttendance({
        staffStatuses: staffStatuses,
        engageModes: engageModes,
        orgIds: orgIds,
        postIds: postIds,
        month: this.time.split('-').join(''),
        resultList: resultList,
        searchWord: searchWord,
        sort: sort
      })
    },
    disabledDate(current) { // 不可选择未来日期
      return current > moment().endOf('day')
    },
    // 详情
    detail(val) {
      // console.log(val)
      this.detailVisible = true
      this.userName = val.staffName
      this.onJobId = val.onJobId
      this.$emit('getDetailVisible', this.detailVisible)
    },
    // 返回回调
    goBack() {
      this.detailVisible = false
      this.$emit('getDetailVisible', this.detailVisible)
    },
    // 表格选择项变更
    onSelectChange(selectedRowKeys, selectedRows) {
      const selectedRowKey = []
      selectedRows.forEach(item => {
        selectedRowKey.push(item.attendanceResultId)
      })
      this.selectedRowKeys = selectedRowKeys
      this.selectedRowKey = selectedRowKey
      if (selectedRows.length > 0) {
        this.unSelect = false
      } else {
        this.unSelect = true
      }
      this.selectedRows = selectedRows
    },
    allSend() { // 发送考勤确认弹出框
      this.visible = true
    },
    handleOk(e) { // 确认发送
      const archiveTime = moment().add(this.confirmTime, 'days').format('YYYY-MM-DD HH:mm:ss')
      confirm({ month: this.time.split('-').join(''), archiveTime }).then(res => {
        if (res.code === 100) {
          this.$message.success('发送成功')
          this.serchGetData()
          this.ifSendConfirm()
          this.cancel()
        }
      })
    },
    handleCancel(e) { // 取消发送
      this.$message.error('取消发送')
      this.visible = false
      this.confirmTime = 1
    },
    cancel() {
      this.visible = false
      this.confirmTime = 1
    },
    del(val) {
      this.selectedRowKey = [val.attendanceResultId]
      this.$Reconfirm({
        title: '删除考勤信息',
        content: '删除后的人员月考勤信息将无法参与当月薪酬计算，确定要删除吗？'
      }).then(() => {
        deleteMonthAttendance(this.selectedRowKey).then(res => {
          if (res.code === 100) {
            this.$message.success('删除成功')
            this.selectedRowKey = []
            this.serchGetData()
          }
        })
      }).catch(() => {
        console.log('no')
      })
    },
    onClose() {
      this.editdrawer = false
    },
    onday() {
      this.time = moment(new Date()).format('YYYY-MM')
      this.fetchDataApiFn(this.pager)
    },
    haedChang(pagination, filters, sorter) {
      // console.log(sorter)
      this._queryParam = {
        ...this._queryParam,
        ...this.pager,
        ...filters,
        sort: sorter.order && sorter.order === 'descend' ? sorter.field + ' ' + 'desc ' : sorter.field + ' ' + ' asc'
      }
      if (sorter.order) {
        this._queryParam['sort'] = sorter.order === 'descend' ? sorter.field + ' ' + 'desc ' : sorter.field + ' ' + 'asc'
      } else if (this._queryParam.sort) {
        delete this._queryParam.sort
      }
      this._queryParam.pageNum = pagination.current
      this._queryParam.pageSize = pagination.pageSize
      this.pager.pageNum = pagination.current
      this.pager.pageSize = pagination.pageSize
      this.total = pagination.total
      this.$$fetchListWithLoading(this._queryParam)
    }
  }
}
</script>

<style lang="less" scoped>
.daily-table {
  width: 100%;
  height: calc(100% - 50px);
  overflow: hidden;
  .daily-toolbar {
    margin-bottom: 16px;
    .left-box{
      background-color: @sc-greyBg-10;
      margin-right: 12px;
      width: auto;
      height: 34px;
      display: inline-flex;
      padding: 0 0 0 16px;
      justify-content: space-evenly;
      align-items: center;
      border-radius: 4px;
      .ant-calendar-picker{
        color: @sc-grey-100;
        text-align: center;
        height: 100%;
        padding: 0 16px;
        line-height: 34px;
        &:hover {
          color: @sc-primary-100 !important;
          cursor: pointer;
        }
      }
      .month {
        font-size: 14px;
        cursor:pointer;
        width: 63px;
        padding:  0 10px;
        border: 0;
        height: 30px;
        margin-right: 2px;
        border-radius: 0;
      }
      .iconpark-icon{
        width: 20px;
        height: 20px;
        color: @sc-primary-100;
      }
    }
  }
  .toolbar-flex{
    .flex-just-between();
    // .toobar-left{
    //   display: flex;
    //   justify-content: flex-start;
    // }
  }
    .daily-statistic-left{
      display: flex;
      justify-content: flex-start;
      margin-bottom: 16px;
    }
    .statistic-p {
      margin-right: 40px;
      font-size: 14px;
      color: @sc-grey-100;
      span{
        color: #FFAC1C ;
      }
    }
  .more-handle {
    font-weight: 700;
    line-height: 44px;
    font-size: 20px;
    color: @sc-primary-100;
    vertical-align: baseline;
    cursor: pointer;
    margin-left: 8px;
  }
}
.more-handle-btn {
    display: flex;
    flex-direction: column;
  }
//   /deep/.ant-table-row>.ant-table-row-cell-break-word:nth-child(3){
//   color: @sc-primary-100;
// }
  .attendanceGroupId{
    width:80px;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis
  }
</style>
