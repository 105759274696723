<template>
  <div class="cultureDynamic">
    <appForm
      ref="appForm"
      :to-list="['search']"
      width="400"
      search-placeholder="请搜索动态标题"
      @getSearchCallback="getSearchCallback"
    >
      <template slot="customButton">
        <a-space>
          <a-button class="a-button" prefix="add-one" @click="handleAdd">
            添加动态
          </a-button>
        </a-space>
      </template>
    </appForm>
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      row-key="newsId"
      table-ref=".user-list"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="loadDataList"
    >
      <!-- 序号 -->
      <template slot="index" slot-scope="{ scope }">
        <span>{{ scope.index + 1 }}</span>
      </template>
      <!-- 接收人员 -->
      <template slot="staffList" slot-scope="{ scope }">
        <TablePerson v-if="scope.record.scope === 'choose'" :data-echo="scope.record.staffList" />
        <span v-if="scope.record.scope === 'all'">全体员工</span>
      </template>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{scope}">
        <span class="operation-btn-box">
          <a-button type="link" @click="preview(scope.record)">
            预览
          </a-button>
          <a-button type="link" danger class="delete" @click="del(scope.record)">
            删除
          </a-button>
        </span>
        <!-- <a-popover
          placement="bottom"
          trigger="hover"
        >
          <template slot="content">
            <div class="more-handle-btn">
              <a-button type="link" @click="preview(scope.record)">
                预览
              </a-button>
              <a-button type="link" danger class="delete" @click="del(scope.record)">
                删除
              </a-button>
            </div>
          </template>
          <div class="more-handle">
            ···
          </div>
        </a-popover> -->
      </template>
    </custom-table>
    <addCultureDynamic ref="addCultureDynamic" @onSubmit="onSubmit" @onPreview="onPreview" />
    <Preview ref="Preview" mode="" />
  </div>
</template>
<script>
import mixins from '@/components/CustomTable/index.js'
import { getNewsListApi, getNewsDetailApi, delNewsApi } from '@/services/culture/dynamic.js'
export default {
  name: 'CultureDynamic',
  components: {
    TablePerson: () => import ('@/components/CbTablePerson'),
    addCultureDynamic: () => import('./components/addCultureDynamic.vue'),
    Preview: () => import('./components/Preview.vue')
  },
  mixins: [mixins],
  data() {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: '50px',
          align: 'center',
          fixed: 'left',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '动态标题',
          dataIndex: 'newsTitle',
          key: 'newsTitle',
          ellipsis: true,
          width: 150,
          fixed: 'left'
        },
        {
          title: '接收人员 ',
          dataIndex: 'staffList',
          scopedSlots: { customRender: 'staffList' },
          key: 'staffList',
          width: 750
        },
        {
          title: '浏览量',
          dataIndex: 'viewCount',
          key: 'viewCount',
          width: 100,
          ellipsis: true
        },
        {
          title: '发送时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width: 210
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: 100
        }
      ],
      dataSource: []
    }
  },
  methods: {
    async fetchDataApiFn(param) {
      const res = await getNewsListApi(param)
      this.dataSource = res.data.records ? res.data.records : []
      this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
      this.total = res.data.total
    },
    getSearchCallback(val) {
      this._queryParam.keyWord = val.searchValue
      this.serchGetData()
    },
    // 添加动态
    handleAdd() {
      this.$refs.addCultureDynamic.onShow()
    },
    // 预览
    preview(info) {
      getNewsDetailApi({ newsId: info.newsId }).then(res => {
        if (res.code === 100) {
          this.$refs.Preview.onShow(res.data)
        }
      })
    },
    onPreview(info) {
      this.$refs.Preview.onShow(info, 1)
    },
    // 删除
    del(info) {
      this.$Reconfirm({
        title: '删除动态',
        content: `确定要删除《${info.newsTitle}》这条动态吗?`
      }).then(async() => {
        console.log(info)
        const res = await delNewsApi({ newsId: info.newsId })
        if (res.code !== 100) return
        this.$message.success('删除成功')
        this.serchGetData()
      }).catch(() => {
        console.log('no')
      })
    },
    onSubmit(data) {
      this.visible = false
      this.serchGetData()
    }
  }
}
</script>
<style lang="less" scoped>
.cultureDynamic{
  padding: 20px;
  background-color: #fff;
  height: 100%;
  .a-button{
  color:@sc-grey-100;
  &:hover{
    color: @sc-primary-100;
  }
}
}
.delete{
  color: red;
}
.more-handle-btn {
    display: flex;
    flex-direction: column;
    .ant-btn {
      height:25px;
    }
  }
.more-handle {
  font-weight: 700;
  color: @sc-primary-100;
  font-size: 20px;
  vertical-align: baseline;
  cursor: pointer;
  margin-left: 4px;
}
.operation-btn-box{
  display:flex;
  button{
    padding-left:0;
    padding-right:16px;
  }
}
</style>
