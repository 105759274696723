<template>
  <Modal
    size="normal"
    :visible="visible"
    :mask-closable="false"
    closable
    :z-index="9"
    title="修改计薪周期"
    @ok="submitEdit"
    @cancel="visible=false"
  >
    <Alert show-icon type="warning">
      <template #content>
        <p class="alert-p">1.修改后可能出现薪酬信息丢失、系统异常、历史薪酬月报表内计薪周期显示错误等多种问题,请谨慎操作,请您先<span class="article-common">线下备份好数据</span>,确定后再行修改。</p>
        <p class="alert-p">2.修改后将无法倒退回历史计薪月,且<span class="article-common">系统将自动调整当前计薪月的开始、结束时间</span>,这会影响到您的考勤和计薪统计，修改当月甚至可能需要贵公司线下计薪。</p>
        <p class="alert-p">3.更改周期后<span class="article-common">生效年月计薪规则</span>：生效月已计薪日期将从当月计薪天数中扣除、或当月未参与计薪天数自动合并至生效月计薪。</p>
      </template>
    </Alert>
    <a-form-model ref="ruleForm" :rules="rules" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="计薪周期" prop="startDay">
        <div class="item-content">
          <a-select v-model="form.startMonthType" class="date-sum">
            <a-select-option v-for="item in timeList" :key="`timeList${item.codeKey}`" :value="item.codeKey">
              {{ item.content }}
            </a-select-option>
          </a-select>
          <a-select v-model="form.startDay" class="date-sum">
            <a-select-option v-for="(item, index) in 28" :key="`date-sum${index}`" :value="item">
              {{ item }}日
            </a-select-option>
          </a-select>
          {{ dateText }}
        </div>
      </a-form-model-item>
      <a-form-model-item label="生效月份" prop="effectiveYearMonth">
        <a-month-picker v-model="form.effectiveYearMonth" :disabled-date="disabledMonth" value-format="YYYYMM" format="YYYY-MM" />
      </a-form-model-item>
    </a-form-model>
    <div class="agreeText">
      <a-checkbox v-model="isDetermine" class="agreement">我已清楚并愿意承担修改计薪周期带来的风险</a-checkbox>
    </div>
  </Modal>

</template>

<script>
import { setConfig } from '@/services/wage/payPeroid.js'
import moment from 'moment'
export default {
  name: 'RevisePayTime',
  components: {
    Modal: () => import('@/components/CbModal'),
    Alert: () => import('@/components/CbAlert')
  },
  props: {
    timeList: {
      type: Array,
      default: () => []
    },
    nextTimeList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      form: {
        startMonthType: '',
        startDay: '',
        effectiveYearMonth: ''
      },
      visible: false,
      // 是否愿意
      isDetermine: false,
      rules: {
        startDay: [
          { required: true, message: '请选择计薪周期', trigger: 'change' }
        ],
        effectiveYearMonth: [
          { required: true, message: '请选择生效月份', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    dateText() {
      if (this.form.startMonthType && this.form.startDay) {
        if (this.form.startDay === 1) {
          return `至${this.timeList[+this.form.startMonthType - 1]?.content}最后一天`
        }
        return `至${this.nextTimeList[+this.form.startMonthType - 1]}${this.form.startDay - 1}日`
      }
      return ''
    }
  },
  methods: {
    // 修改计薪周期
    submitEdit() {
      if (!this.isDetermine) {
        return this.$message.error('请先确认勾选我已清楚选项')
      }
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const res = await setConfig(this.form)
          if (res.code === 100) {
            this.$message.success('修改成功！')
            this.visible = false
            this.$emit('refresh')
          }
        }
      })
    },
    // 限制月份
    disabledMonth(time) {
      if (!this.form?.allowedMinMonth || !this.form.isCal) return
      return moment(time).isBefore(moment(this.form?.allowedMinMonth))
    },
    // 打开弹窗
    open(data) {
      this.form = data
      this.isDetermine = false
      this.visible = true
    }
  }
}
</script>
<style lang='less' scoped>
.alert-p {
  color: @sc-grey-100;
  margin-bottom: 0;
}
.article-common {
  color: @sc-primary-100;
}
.item-content{
  color: @sc-grey-80;
  /deep/.ant-select{
    width: 100px;
    margin-right: 10px;
  }
}
/deep/.ant-form-item{
  margin:20px 0 0 0;
}
.agreeText {
  margin-top: 20px;
}
/deep/.ant-form-explain{
  position: absolute;
}
/deep/ .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
  margin-top: 5px;
}
/deep/ .ant-calendar-picker {
  width: 211px;
}
</style>
