<!--
 * @Date: 2022-07-07 14:07:48
 * @descriotion:
-->
<template>
  <div class="userbox">
    <div class="userInfo">
      <div class="info">
        <p>员工姓名</p>
        <p>{{ userInfo.staffName || '-' }}</p>
      </div>
      <div class="info">
        <p>工号</p>
        <p>{{ userInfo.staffNum || '-' }}</p>
      </div>
      <div class="info">
        <p>部门</p>
        <p>{{ userInfo.orgName || '-' }}</p>
      </div>
      <div>
        <slot name="content" />
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: 'AddForm',
  props: {
    userInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
    }
  },
  mounted() {

  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.userbox{
  margin-bottom:20px;
}
p{
    margin:0;
}
.userInfo{
    width: 100%;
    min-height:150px;
    padding:20px;
    background: @sc-greyBg-10;
    border:1px solid @sc-greyBg-50;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.info{
  color: @sc-grey-100;
    display: flex;
    p:first-child{
        width:100px;
    }
}
</style>
