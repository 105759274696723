<template>
  <div class="contract-body">
    <div class="add-contract">
      <a-button type="primary" prefix="add-one" @click="handleAdd">
        新增
      </a-button>
    </div>
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      table-ref=".contract-body"
      row-key="companyId"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="loadDataList"
    >
      <!-- 操作列 -->
      <span slot="status" slot-scope="{ scope }">
        <div>
          <a-switch
            size="small"
            :default-checked="Boolean(scope.record.status)"
            @change="onChange(...arguments, scope)"
          />
        </div>
      </span>
      <template slot="operation" slot-scope="{ scope }">
        <a-popover placement="bottomLeft" trigger="hover">
          <template slot="content">
            <div class="more-handle-btn">
              <a-button type="link" @click="editContract(scope.record)">
                编辑
              </a-button>
              <a-button type="link" class="delete" @click="deleContract(scope.record)"> 删除 </a-button>
            </div>
          </template>
          <div class="more-handle">···</div>
        </a-popover>
      </template>
    </custom-table>
    <ContractForm ref="contractFormRef" @getfetchData="getfetchData" />
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import { getContractSubjectList, deleteContractSubject, changeContractStatus } from '@/services/globalSetting/contract.js'
export default {
  name: 'GlobalSettingContract',
  components: {
    ContractForm: () => import('./contractForm')
  },
  mixins: [mixins],
  data() {
    return {
      deleteId: '',
      deleteBox: false,
      dataSource: [],
      columns: [
        {
          title: '公司名称',
          dataIndex: 'companyName',
          key: 'companyName',
          width: '300px',
          ellipsis: true
        },
        {
          title: '统一社会信用代码',
          dataIndex: 'companyCode',
          key: 'companyCode',
          width: '300px',
          ellipsis: true
        },
        {
          title: '法人代表',
          dataIndex: 'corporate',
          key: 'corporate',
          width: '200px',
          ellipsis: true
        },
        {
          title: '注册地',
          dataIndex: 'areaName',
          key: 'areaName',
          ellipsis: true
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime'
        },
        {
          title: '启用/禁用',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '80px'
        }
      ]
    }
  },
  mounted() {
    this.getContractInfo()
  },
  methods: {
    // 获取列表
    async getContractInfo() {
      const res = await getContractSubjectList()
      this.dataSource = res.data
    },
    // 改变状态
    onChange(value, e, info) {
      changeContractStatus({ companyId: info.record.companyId, status: value ? 1 : 0 }).then(res => {
        if (res.code === 100) {
          this.$message.success('更改状态成功')
        }
      })
    },
    // 新增
    handleAdd() {
      this.$refs.contractFormRef.open()
    },
    // 编辑
    editContract(val) {
      this.$refs.contractFormRef.edit(val)
    },
    // 删除
    deleContract(val) {
      this.$Reconfirm({
        title: '删除',
        content: '是否确认删除？'
      }).then(async() => {
        const param = { companyId: val.companyId }
        const res = await deleteContractSubject(param)
        if (res.code === 100) {
          this.$message.success('删除成功')
          this.getContractInfo()
        } else {
          this.$message.error('删除失败')
        }
      }).catch(() => {
        console.log('no')
      })
    },
    getfetchData() {
      this.getContractInfo()
    }
  }
}
</script>

<style lang="less" scoped>
.contract-body{
  background-color: #fff;
  padding: 20px 20px 0;
  min-height: 100%;
  .add-contract{
    margin-bottom: 20px;
  }
}
.more-handle-btn {
  display: flex;
  flex-direction: column;
}
.more-handle {
  color: @sc-primary-100;
  font-weight: 700;
  margin-left: 5px;
  font-size: 20px;
}
/deep/ .ant-switch-small {
  margin-left: 18px;
}
</style>
