<template>
  <div class="forget-password login-form">
    <k-form-build ref="KFB" :value="jsonData" />
    <div class="btnBox">
      <a-button
        class="submit"
        type="primary"
        @click="nextStep"
        @enterPress="nextStep"
      >确认</a-button>
      <a-button
        class="submit"
        type="link"
        @click="toHome"
        @enterPress="toHome"
      >直接体验</a-button>
    </div>
  </div>
</template>

<script>
import publicMixins from './publicMixins'
import { perfectTenant } from '@/services/login'
import { mapGetters } from 'vuex'
export default {
  name: 'Regiter',
  mixins: [publicMixins],
  data() {
    return {
      jsonData: {
        'list': [
          {
            'type': 'input',
            'label': '企业名称',
            'options': {
              'type': 'text',
              'width': '100%',
              'defaultValue': '',
              'placeholder': '请输入',
              'clearable': true,
              'maxLength': null,
              'addonBefore': '',
              'addonAfter': '',
              'hidden': false,
              'disabled': true
            },
            'model': 'name',
            'key': 'name',
            'help': '',
            'rules': [
              {
                'required': true,
                'message': '必填项'
              }
            ]
          },
          {
            'type': 'select',
            'label': '企业规模',
            'options': {
              'width': '100%',
              'multiple': false,
              'disabled': false,
              'clearable': true,
              'hidden': false,
              'placeholder': '请选择',
              'dynamicKey': '',
              'dynamic': false,
              'options': [
                {
                  'value': '1',
                  'label': '下拉框1'
                },
                {
                  'value': '2',
                  'label': '下拉框2'
                }
              ],
              'showSearch': false
            },
            'model': 'scale',
            'key': 'scale',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': '必填项'
              }
            ]
          },
          {
            'type': 'select',
            'label': '所属行业',
            'options': {
              'width': '100%',
              'multiple': false,
              'disabled': false,
              'clearable': true,
              'hidden': false,
              'placeholder': '请选择',
              'dynamicKey': '',
              'dynamic': false,
              'options': [
                {
                  'value': '1',
                  'label': '下拉框1'
                },
                {
                  'value': '2',
                  'label': '下拉框2'
                }
              ],
              'showSearch': false
            },
            'model': 'business',
            'key': 'business',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': '必填项'
              }
            ]
          },
          {
            'type': 'cascader',
            'label': '所在地区',
            'options': {
              'disabled': false,
              'hidden': false,
              'showSearch': false,
              'placeholder': '请选择',
              'clearable': true,
              'dynamicKey': '',
              'dynamic': false,
              'options': [
                {
                  'value': '1',
                  'label': '选项1',
                  'children': [
                    {
                      'value': '11',
                      'label': '选项11'
                    }
                  ]
                },
                {
                  'value': '2',
                  'label': '选项2',
                  'children': [
                    {
                      'value': '22',
                      'label': '选项22'
                    }
                  ]
                }
              ]
            },
            'model': 'area',
            'key': 'area',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': '必填项'
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 80,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      }
    }
  },
  computed: {
    ...mapGetters('account', ['tenant'])
  },
  mounted() {
    this.jsonData.list[0].options.defaultValue = this.tenant ? this.tenant.tenantName : ''
  },
  methods: {
    nextStep() {
      // this.setLoginType(1)
      // 通过函数获取数据
      this.$refs.KFB.getData()
        .then(async params => {
          // 获取数据成功
          params['phoneNum'] = this.globForm.phoneNum
          params['area'] = '32132'
          const data = await perfectTenant(params)
          console.log(data)
          this.toHome()
        })
        .catch(err => {
          console.log(err, '校验失败')
        })
    },
    toHome() {
      this.$router.push('/home')
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./index.less');
.btnBox{
  display: flex;
}
</style>
