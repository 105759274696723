<template>
  <div class="basic-info-p">
    <div class="basic-info-avator" @click="$refs.cbavater.hasAvator ? imgDetail = true : imgDetail = false">
      <CbAvater ref="cbavater" :on-job-id="userInfo.onJobId" :avater-name="userInfo.staffName" :show-user-name="false" size="52" />
    </div>
    <div class="basic-info-contain">
      <div class="info-p">
        <div class="name">
          <span>{{ userInfo.staffName }}</span><span>{{ userInfo.staffNum }}</span>
        </div>
        <div class="tel">
          {{ userInfo.phoneNum }}
        </div>
      </div>
      <div class="dept-p">
        <div class="com-info">
          <div class="com-info-title-post">部门：</div>
          <div class="com-info-value-post">{{ userInfo.orgName }}</div>
        </div>
      </div>
      <div class="post-p">
        <div class="com-info">
          <div class="com-info-text">岗位：</div>
          <div class="com-info-val">{{ userInfo.postName }}</div>
        </div>
        <div class="com-info">
          <div class="com-info-title">司龄：</div>
          <div class="com-info-value">{{ userInfo.companyAge }}</div>
        </div>
      </div>
      <div class="addr-p">
        <div class="com-info">
          <div class="com-info-text">汇报上级：</div>
          <div class="com-info-val">{{ userInfo.reportText }}</div>
        </div>
        <div class="com-info">
          <div class="com-info-title">工作地点：</div>
          <div class="com-info-value">
            {{ userInfo.workAddr }}
          </div>
        </div>
      </div>
    </div>
    <a-modal
      class="imgcontainer"
      title="放大查看"
      :visible="imgDetail"
      :mask-closable="true"
      footer=""
      @cancel="cancel"
    >
      <img :src="`${basePath}/hrsaas-base/staff/getAvatar?onJobId=${userInfo.onJobId}&width=${400}&height=${400}`" alt="">
    </a-modal>
  </div>
</template>

<script>
import { getStaffInfoByOnJobId, getStaffInfoFromLeaveInfo } from '@/services/user/roster.js'
export default {
  components: {
    CbAvater: () => import('@/components/CbAvater/theAvater')
  },
  props: {
    userId: {
      type: String,
      default: ''
    },
    isShow: {
      type: Boolean,
      default: false
    },
    update: {
      type: Boolean,
      default: false
    },
    statusType: {
      type: String,
      default: ''
    },
    onJobId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      basePath: process.env.VUE_APP_API_BASE_URL,
      userInfo: {
        avatar: '',
        name: '',
        phoneNum: '',
        staffNum: '',
        duty: '',
        orgName: '',
        postName: '',
        companyAge: '',
        workAddr: ''
      },
      imgDetail: false,
      imgShow: true
    }
  },
  watch: {
    'userId': function(val) {
      if (val) {
        this.initUserBasicInfo()
      }
    },
    'update': function() {
      this.initUserBasicInfo()
    }
  },
  created() {
    this.initUserBasicInfo()
  },
  methods: {
    // 初始化用户基础信息
    initUserBasicInfo() {
      if (this.statusType === 'resigned') {
        getStaffInfoFromLeaveInfo({ onJobId: this.onJobId }).then((res) => {
          if (res.code === 100) {
            this.userInfo = res.data
            if (this.userInfo.onJobId !== '') {
              this.imgShow = true
            }
          }
        })
      } else {
        getStaffInfoByOnJobId({ onJobId: this.onJobId ? this.onJobId : this.$route.query.onJobId }).then((res) => {
          if (res.code === 100) {
            this.userInfo = res.data
            if (this.userInfo.onJobId !== '') {
              this.imgShow = true
            }
          }
        })
      }
    },
    cancel() {
      this.imgDetail = false
    }
  }
}
</script>

<style lang="less" scoped>
.basic-info-p {
  width: 100%;
  min-height: 80px;
  background-color: @sc-greyBg-10;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px;
  .basic-info-avator {
    margin-right: 14px;
    /deep/.img_box{
      cursor: pointer;
    }
  }
  .basic-info-contain {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-grow: 1;
    .info-p {
      flex-basis: 15.5%;
      .name {
        display: flex;
        justify-content: flex-start;
        color: @sc-grey-100;
        .font-size(14px);
        span:nth-child(1){
          width: 140px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        span:nth-child(2) {
          margin-left: 22px;
        }
      }
      .tel {
        margin-top: 9px;
        color: @sc-grey-100;
        .font-size(14px);
        .anticon-phone {
          margin-right: 10px;
        }
      }
    }
    .dept-p {
      .font-size(14px);
      flex-basis: 15.5%;
    }
    .post-p {
      .font-size(14px);
      flex-basis: 15.5%;
    }
    .addr-p {
      .font-size(14px);
      flex-basis: 53.5%;
    }
    .com-info {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .com-info-text {
        margin-top: 0;
        color: @sc-grey-60;
      }
      .com-info-title {
        margin-top: 12px;
        flex-shrink: 0;
        color: @sc-grey-60;
      }
      .com-info-title-post {
        flex-shrink: 0;
        color: @sc-grey-60;
      }
      .com-info-value {
        margin-top: 12px;
        color: @sc-grey-100;
      }
      .com-info-value-post {
        color: @sc-grey-100;
      }
      .com-info-val {
        color: @sc-grey-100;
      }
    }

  }
}
.imgcontainer{
  text-align: center;
}
</style>
