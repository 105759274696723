<!--
 * @Date: 2022-07-06 14:22:59
 * @descriotion:
-->
<template>
  <div class="voucher">
    <HeaderBack title="返回" @back="loading=true;$emit('back')">
      <template slot="center">
        <span>申报凭证</span>
      </template>
    </HeaderBack>
    <div class="content">
      <div class="left-content">
        <a-menu v-model="current" mode="inline" @click="menuCheck">
          <a-menu-item-group v-for="item in menuList" :key="item.title" :title="item.title">
            <a-menu-item v-for="child in item.childer" :key="child.type">
              {{ child.name }}
            </a-menu-item>
          </a-menu-item-group>
        </a-menu>
      </div>
      <div class="right-content">
        <!-- <img src="//y.qq.com/music/photo_new/T002R300x300M000000iTjKD0hg5ja_2.jpg?max_age=2592000" alt=""> -->
        <!-- <Upload /> -->
        <!-- <div>
          <a-spin v-show="loading" />
        </div>
        <div v-show="!loading" class="pdf">
          <pdf
            v-for="i in numPages"
            :key="i"
            :src="src"
            :page="i"
            style="display: inline-block; width: 100%"
          />
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
// import pdf from 'vue-pdf'
// var loadingTask = pdf.createLoadingTask('https://www.gjtool.cn/pdfh5/git.pdf')
export default {
  name: 'ViewBill',
  components: {
    HeaderBack: () => import('@/components/headerBack')
    // Upload: () => import('../component/upload.vue'),
    // pdf
  },

  data() {
    return {
      menuList: [
        {
          title: '社保',
          childer: [
            {
              name: '缴纳凭证',
              type: '1'
            },
            {
              name: '缴费明细',
              type: '2'
            },
            {
              name: '增员申报表',
              type: '3'
            },
            {
              name: '减员申报表',
              type: '4'
            }
          ]
        },
        {
          title: '医保',
          childer: [
            {
              name: '缴纳凭证',
              type: '5'
            },
            {
              name: '缴费明细',
              type: '6'
            },
            {
              name: '增员申报表',
              type: '7'
            },
            {
              name: '减员申报表',
              type: '8'
            }
          ]
        },
        {
          title: '公积金',
          childer: [
            {
              name: '缴纳凭证',
              type: '9'
            },
            {
              name: '缴费明细',
              type: '10'
            },
            {
              name: '增员申报表',
              type: '11'
            },
            {
              name: '减员申报表',
              type: '12'
            }
          ]
        }
      ],
      current: ['1'],
      // src: loadingTask,
      numPages: undefined,
      loading: true,
      excelData: null
    }
  },
  mounted() {
    // this.src.promise.then(pdf => {
    //   this.numPages = pdf.numPages
    //   this.loading = false
    // })
  },
  methods: {
    menuCheck(data) {
      const key = data.key
      console.log(key)
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.ant-menu-item-group-title{
    padding:0;
    font-size:14px;
}
/deep/.ant-menu-inline .ant-menu-item:not(:last-child){
    margin:0;
    border:none
}
/deep/.ant-menu-item{
    padding:0;
    line-height: 35px;
    font-size:14px;
}
.voucher{
    width:100%;
    height:100%;
    overflow: hidden;
}
.content{
    width:100%;
    height:calc(100% - 50px);
    display: flex;
    .left-content{
        width:150px;
        height:100%;
        overflow-y: auto;
        border-right:1px solid @sc-greyBg-20;
    }
    .right-content{
        flex:1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: 100%;
        .pdf{
          width: 800px;
          height:100%;
          border:1px solid @sc-greyBg-20;
          overflow: auto;
        }
    }
}
</style>
