<template>
  <div class="box">
    <div class="top">
      <span>在职总数：{{ rankStatisticsValue.incumbencyCount }}人</span>
      <span class="num">定级人数：{{ rankStatisticsValue.gradedCount }}</span>
      <!-- <span class="num">定级人数：{{ rankStatisticsValue.gradedCount }}({{ rankStatisticsValue.gradedProportion }}%)</span> -->
    </div>
    <div>
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        :row-key="'jobGradeArchiveId'"
        :pagination="false"
      >
        <!-- 占比头部自定义插槽 -->
        <span slot="proportionTitle">
          占比
          <a-tooltip placement="top">
            <template slot="title">
              <span>当前职级人数占当前职类总人数的比例</span>
            </template>
            <span class="details">
              <svg class="iconpark-icon"><use href="#help" /></svg>
              (%)
            </span>
          </a-tooltip>
        </span>
      </custom-table>
    </div>
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import { getRankList } from '@/services/organization/framework.js'
export default {
  name: 'RankList',
  mixins: [mixins],
  props: {
    rankId: {
      type: String,
      default: ''
    },
    rankStatistics: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      rankStatisticsValue: {},
      columns: [
        {
          title: '级别',
          dataIndex: 'jobGrade',
          key: 'jobGrade',
          width: 100
        },
        {
          title: '人数',
          dataIndex: 'personCount',
          key: 'personCount',
          width: 100
        },
        {
          dataIndex: 'proportion',
          slots: { title: 'proportionTitle' },
          key: 'proportion',
          width: 100
        },
        {
          title: '通用能力',
          dataIndex: 'commonSkill',
          key: 'commonSkill',
          width: 100
        },
        {
          title: '岗位能力',
          dataIndex: 'professinalSkill',
          key: 'professinalSkill',
          width: 100
        },
        {
          title: '核心价值',
          dataIndex: 'coreValue',
          key: 'coreValue',
          width: 100
        }
      ],
      dataSource: []
    }
  },
  watch: {
    rankId(val) {
      this._queryParam.jobTypeArchiveId = val
      this.serchGetData()
    },
    rankStatistics(val) {
      this.rankStatisticsValue = val
    }
  },
  methods: {
    async fetchDataApiFn(param) {
      this._queryParam.jobTypeArchiveId = this.rankId
      const res = await getRankList(param)
      this.dataSource = res.data ? res.data : []
      // this.pager.currentPage = res.data.current || 1 // 更新当前的分页信息
      // this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
      // this.total = res.data.total
    }
  }
}
</script>

<style lang="less" scoped>
  .box {
    box-sizing: border-box;
    font-size: 14px;
    margin-top: 30px;
    .top {
      margin-bottom:20px;
      color: @sc-grey-100;
      .num {
        margin-left: 10px;
      }
    }
    .details{
      cursor: pointer;
    }
    .iconpark-icon{
      width: 16px;
      height: 16px;
      vertical-align: text-bottom;
      margin-bottom: 1px;
    }
  }
/deep/ .ant-table-thead > tr > th {
  color: @sc-grey-100;
  font-size: 14px;
}
/deep/ .ant-table-tbody > tr > td {
  color:  @sc-grey-80;
  font-size: 14px;
  padding-left: 7px;
}
</style>
