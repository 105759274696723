import { render, staticRenderFns } from "./exportCardinalityTable.vue?vue&type=template&id=e115ed10&scoped=true&"
import script from "./exportCardinalityTable.vue?vue&type=script&lang=js&"
export * from "./exportCardinalityTable.vue?vue&type=script&lang=js&"
import style0 from "./exportCardinalityTable.vue?vue&type=style&index=0&id=e115ed10&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e115ed10",
  null
  
)

export default component.exports