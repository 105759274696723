<template>
  <div>
    <BlockTitle title="基本信息" :is-bold="true" class="msg-title" />
    <!-- <div class="msg-title">基本信息</div> -->
    <a-row>
      <a-col>
        <a-form-model-item label="班次名称" prop="shiftName">
          <a-input v-model="form.shiftName" placeholder="请输入班次名称" />
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row>
      <a-col>
        <a-form-model-item label="班次颜色" prop="colorThemeCode">
          <CbColorPicker v-model="form.colorThemeCode" :color="form.fontRgba" />
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row>
      <a-col>
        <a-form-model-item label="班次简称" prop="shiftShortName">
          <a-input v-model="form.shiftShortName" placeholder="请输入班次简称" />
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row>
      <div class="type-card">
        <a-form-model-item label="班次类型" prop="shiftType">
          <a-radio-group v-model="form.shiftType" @change="change">
            <a-radio-button v-for="item in shiftTypeList" :key="item.value" :value="item.value" class="radioBtn">
              <div class="type-content">
                <p>{{ item.label }}</p>
                <span>{{ item.text }}</span>
              </div>
              <div v-if="form.shiftType === item.value" class="after">
                <svg class="iconpark-icon"><use href="#check" /></svg>
              </div>
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
      </div>
    </a-row>
  </div>
</template>

<script>
import { shiftType } from '../../../../dict'
export default {
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index'),
    CbColorPicker: () => import('@/components/CbColorPicker/index')
  },
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    shiftType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      buttonStyle: {
        minHeight: '92px'
      }
    }
  },
  computed: {
    shiftTypeList() {
      if (this.shiftType === 'ordinary') {
        return shiftType.slice(0, 2)
      } else {
        return shiftType.slice(2, 4)
      }
    }
  },
  methods: {
    change({ target: { value }}) {
      this.$emit('change', value)
    }
  }
}
</script>

<style lang="less" scoped>
.msg-title {
 margin-bottom: 6px;
 }
.ant-form-item {
  min-height: 56px !important;
  margin-bottom: 0;
  display: flex !important;
  align-items: center;
}
.ant-form-label {
  padding: 0;
  margin: 0;
}
/deep/.ant-form-item-label > label::after {
  margin-right: 36px;
}
/deep/.ant-form-item-control {
  min-width: 320px;
}
/deep/.has-error .ant-form-explain {
  position: absolute;
  bottom: -15px;
}
/deep/.ant-radio-group {
  display: flex !important;
}
/deep/.ant-radio-button-wrapper{
  width: 174px;
  height:92px;
  padding: 7px 10px 13px 10px;
  margin:12px 12px 0 0;
  background: @base-bg-color;
  border-radius: 4px;
}
.type-card{
  .type-content{
    .font-size(14px);
    p {
      font-weight: 600;
      margin-bottom: 6px;
    }
  }
  /deep/.ant-form-item {
    min-height: 123px !important;
  }
  .ant-form-item {
    align-items: start;
  }
  .after {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 26px solid @sc-primary-100;
    border-left: 26px solid transparent;
    .iconpark-icon {
      position: absolute;
      bottom: 13px;
      right: 2px;
      width: 12px;
      height: 12px;
      color: #fff;
    }
  }
}
</style>
<style>
.ant-time-picker-panel-select::-webkit-scrollbar {
    display:none ;
}
</style>
