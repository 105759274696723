<!--
 * @Date: 2022-04-25 15:48:05
 * @descriotion:余额
-->
<template>
  <div class="balance beauty-scroll">
    <div v-if="!showImport">
      <appForm
        class="appform"
        :to-list="formList"
        search-placeholder="请搜索姓名/工号"
        :screen-roster-list="screenRosterList"
        @getSearchCallback="getSearchCallback"
        @screenRosterParams="screenRosterParams"
        @exportFileList="exportFileList"
      >
        <!-- <template slot="rightButton">
          <a-button class="menuSearch_icon" icon="import" @click="showImport = true" />
        </template> -->
      </appForm>
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        row-key="key"
        :pagination="{
          current: pager.pageNum,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条数据`,
          showQuickJumper: true,
          showSizeChanger: true
        }"
        @change="loadDataList"
      >
        <!-- 序号 -->
        <template slot="index" slot-scope="{ scope }">
          <span>{{ scope.index + 1 }}</span>
        </template>
        <!-- 姓名头像 -->
        <template slot="staffName" slot-scope="{ scope }">
          <CbTableAvater :table-name="scope.record.staffName" :on-job-id="scope.record.onJobId" :staff-id="scope.record.staffId" :title="scope.record.staffName" />
        </template>
        <template slot="operation" slot-scope="{scope}">
          <div class="flex-align-center">
            <div class="more-handle">
              <a-button type="link" @click="openDrawer(scope.record)">
                查看记录
              </a-button>
            </div>
          </div>
        </template>
      </custom-table>
      <Drawer
        is-mask
        display-btn
        :visible="detailVisible"
        :title="title"
        placement="right"
        size="small"
        @close="closeDetailDrawer"
      >
        <div class="head">
          <div class="content">
            <p><span>{{ holidayList.totalGrantLeave }}</span>小时</p>
            <p>累计发放年假</p>
          </div>
          <div class="content">
            <p><span>{{ holidayList.totalDeductLeave }}</span>小时</p>
            <p>累计扣减年假</p>
          </div>
          <div class="content">
            <p><span>{{ holidayList.leaveBalanceHours }}</span>小时</p>
            <p>剩余年假</p>
          </div>
          <br>
          <div class="content">
            <p><span>{{ holidayList.totalGrantInLieu }}</span>小时</p>
            <p>累计发放调休假</p>
          </div>
          <div class="content">
            <p><span>{{ holidayList.totalDeductInLieu }}</span>小时</p>
            <p>累计扣减调休假</p>
          </div>
          <div class="content">
            <p><span>{{ holidayList.inLieuBalanceHours }}</span>小时</p>
            <p>剩余调休假</p>
          </div>
        </div>
        <div class="holidayRecords">
          <div class="holidayRecords-head">
            <BlockTitle title="假期记录（小时）">
              <template slot="right">
                <a-select v-model="value" :options="holiday" default-value="3" style="width: 120px" @change="handleChange" />
              </template>
            </BlockTitle>
            <!-- <span class="holidayRecords-head-title">假期记录（小时）</span>
            <a-select v-model="value" :options="holiday" default-value="3" style="width: 120px" @change="handleChange" /> -->
          </div>

          <a-timeline class="holidayRecords-timeline">
            <a-timeline-item v-for="item in holidayList.records" :key="item.vacationTypeIdvacationTypeId">
              <a-row>
                <p>{{ item.createTime }}</p>
                <p><span class="orange">{{ item.adjustBalanceAmount }}</span>小时</p>
              </a-row>
              <a-row>
                <p>{{ item.vacationTypeName }} - {{ getCodeContent(item.balanceChangeReason,vacation_balance_change_reason) }}</p>
              </a-row>
            </a-timeline-item>
          </a-timeline>
        </div>
      </Drawer>
    </div>
  </div>
</template>
<script>
import mixins from '@/components/CustomTable/index.js'
import { balanceRecord, selectBalance, exportVacationBalance } from '@/services/attendance/holidays.js'

export default {
  name: 'Balance',
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index'),
    Drawer: () => import('@/components/CbDrawer'),
    CbTableAvater: () => import('@/components/CbTableAvater/index')
  },
  filters: {

  },
  mixins: [mixins],
  data() {
    return {
      showImport: false,
      formList: ['search', 'screen', 'export'],
      screenRosterList: {
        'list': [
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择部门',
              'showSearch': false
            },
            'model': 'orgIds',
            'key': 'orgIds',
            'rules': [
              {
                'required': false,
                'message': '请选择部门'
              }
            ]
          },
          {
            'type': 'selectPost',
            'label': '岗位',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择岗位',
              'showSearch': false
            },
            'model': 'postIds',
            'key': 'postIds',
            'rules': [
              {
                'required': false,
                'message': '请选择岗位'
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'index' },
          key: 'index',
          fixed: 'left',
          align: 'center',
          width: '50px'
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          scopedSlots: { customRender: 'staffName' },
          key: 'staffName',
          fixed: 'left',
          width: '105px'
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          fixed: 'left',
          width: '100px',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          fixed: 'left',
          width: '100px',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          fixed: 'left',
          width: '100px',
          ellipsis: true
        },
        {
          title: '累计发放（小时）',
          dataIndex: 'totalGrant',
          key: 'totalGrant',
          width: '140px',
          customCell: () => {
            return {
              style: {
                'min-width': '140px',
                'width': '140px'
              }
            }
          },
          ellipsis: true
        },
        {
          title: '累计扣减（小时）',
          dataIndex: 'totalDeduct',
          key: 'totalDeduct',
          width: '140px',
          customCell: () => {
            return {
              style: {
                'min-width': '140px'
              }
            }
          },
          ellipsis: true
        },
        {
          title: '假期剩余总计（小时）',
          dataIndex: 'balanceHours',
          width: '160px',
          key: 'balanceHours',
          customCell: () => {
            return {
              style: {
                'min-width': '160px'
              }
            }
          },
          ellipsis: true
        },
        {
          title: '年假（小时）',
          dataIndex: 'annualLeaveBalance',
          width: '140px',
          key: 'annualLeaveBalance',
          customCell: () => {
            return {
              style: {
                'min-width': '100px'
              }
            }
          },
          ellipsis: true
        },
        {
          title: '调休假（小时）',
          dataIndex: 'lieuLeaveBalance',
          key: 'lieuLeaveBalance',
          width: '120px',
          customCell: () => {
            return {
              style: {
                'min-width': '120px'
              }
            }
          },
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'operate',
          scopedSlots: { customRender: 'operation' },
          key: 'operate',
          fixed: 'right',
          width: '80px'
        }
      ],
      onJob: { onJobId: '', staffName: '' },
      dataSource: [],
      detailVisible: false,
      holiday: [
        { value: '年假', label: '年假' },
        { value: '调休假', label: '调休假' },
        { value: '全部假期', label: '全部假期' }
      ],
      title: '',
      holidayList: {
        totalGrantLeave: '0',
        totalDeductLeave: '0',
        leaveBalanceHours: '0',
        totalGrantInLieu: '0',
        totalDeductInLieu: '0',
        inLieuBalanceHours: '0',
        records: []
      },
      value: '全部假期',
      detailList: [],
      vacation_balance_change_reason: []
    }
  },
  async created() {
    this.vacation_balance_change_reason = await this.getTenantCode('vacation_balance_change_reason')
  },
  methods: {
    closeDetailDrawer() {
      this.value = '全部假期'
      this.detailVisible = false
    },
    // 获取数据
    async fetchDataApiFn(param) {
      const res = await selectBalance(param)
      this.dataSource = res.data.records ? res.data.records : []
      this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
      this.total = res.data.total
      this.dataSource.forEach((item, key) => {
        item.key = key
      })
    },
    // 查询功能
    getSearchCallback(data) {
      this._queryParam.searchWord = data.searchValue
      this.serchGetData()
    },
    // 筛选回调
    screenRosterParams(data) {
      this._queryParam.searchWord = data.searchValue
      const orgIds = []
      if (data.screenCondition.orgIds !== undefined) {
        data.screenCondition.orgIds.forEach(item => {
          orgIds.push(item.value)
        })
        this._queryParam.orgIds = orgIds.join(',')
      }
      if (data.screenCondition.postIds !== undefined) {
        this._queryParam.postIds = data.screenCondition.postIds.join(',')
      }
      for (var key in this._queryParam) {
        if (!this._queryParam[key]) {
          delete this._queryParam[key]
        }
      }
      this.serchGetData()
    },
    // 导出
    exportFileList() {
      const { searchWord, orgIds, postIds } = this._queryParam
      exportVacationBalance({
        searchWord: searchWord,
        orgIds: orgIds,
        postIds: postIds
      })
    },
    // 查看记录
    async openDrawer(val) {
      this.onJob.onJobId = val.onJobId
      this.onJob.staffName = val.staffName
      this.detailVisible = true
      const res = await balanceRecord({
        onJobId: val.onJobId
      })
      if (res.code === 100) {
        this.holidayList.records = res.data.records
        this.detailList = res.data.records
        this.holidayList.totalGrantLeave = res.data.totalGrantLeave || 0
        this.holidayList.totalDeductLeave = res.data.totalDeductLeave || 0
        this.holidayList.leaveBalanceHours = res.data.leaveBalanceHours || 0
        this.holidayList.totalGrantInLieu = res.data.totalGrantInLieu || 0
        this.holidayList.totalDeductInLieu = res.data.totalDeductInLieu || 0
        this.holidayList.inLieuBalanceHours = res.data.inLieuBalanceHours || 0
        this.title = val.staffName ? `${val.staffName}的假期余额记录` : `假期余额记录`
      }
    },
    handleChange(value) {
      const holidayList = []
      if (value === '全部假期') {
        this.holidayList.records = this.detailList
      } else {
        this.detailList.forEach(item => {
          if (item.vacationTypeName === value) {
            holidayList.push(item)
          }
        })
        this.holidayList.records = holidayList
      }
    }
  }
}
</script>

<style lang="less" scoped>
.balance{
  width: 100%;
  height: 100%;
  background: @base-bg-color;
  padding: 20px 20px 0;
}
.head{
  // width: 96%;
  margin: 0 auto;
  border-bottom:  3px solid #f9f9f9;
  height: 160px;
  display: flex;
  flex-wrap: wrap;
  .content{
    width: 33%;
    height: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-around;
    p{
      width: 100%;
      text-align: center;
      color: @sc-grey-80;
      span{
        color: #FFAC1C;
      }
    }
  }
}
.holidayRecords{
  width: 100%;
  margin: 30px auto;
  &-timeline{
    .ant-row{
      display: flex;
      justify-content: space-between;
      &::before, &::after{
        display: none;
      }
      p{
        display: block;
        span{
          color: @sc-grey-80;
        }
        .orange{
          color: #FFAC1C;
        }
      }
    }
  }
}
.more-handle {
  color: @sc-primary-100;
}
.holidayRecords-head{
  width: 100%;
  height: 20px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.holidayRecords-head-title{
  padding:0 0 0 10px;
  color: @sc-grey-80;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 15px;
    background:  @sc-primary-100;
  }
  /deep/.ant-select-enabled{
    margin-left: 75px;
  }
}
.ExcelImport{
  padding: 20px;
}
/deep/.main-content{
  padding: 20px;
}
/deep/.ant-timeline-item-content{
  color: @sc-grey-80;
}
</style>
