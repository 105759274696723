<!--
 * @Date: 2022-08-10 14:42:34
 * @descriotion:
-->
<template>
  <div class="detail-box" :style="style">
    <div class="list-item">
      <div class="content-left">
        <div class="detail-l">
          <div class="name">{{ item.companyName }}</div>
          <div class="address">
            <svg class="iconpark-icon iconColor"><use href="#local-two-5mga1aal" /></svg>
            <span class="cityName">{{ item.cityName }}</span>
          </div>
        </div>
        <div class="detail-list">
          <div v-for="(option, billIndex) in billOptions" v-show="item[option.key]" :key="billIndex" class="detail">
            <div class="label">{{ option.label }}({{ option.company }})：</div>
            <div class="value">
              {{ item[option.key] | stateFormatNum }}
            </div>
          </div>
        </div>
      </div>
      <div class="detail-r">
        <a-dropdown placement="bottomLeft">
          <a-button prefix="daochu" suffix="left">导出</a-button>
          <a-menu slot="overlay">
            <a-menu-item @click="exportBill">
              导出明细
            </a-menu-item>
            <a-menu-item @click="exportFile">
              导出申报文件
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <a-button class="ant-button" prefix="daoru" @click="importBill">
          导入
        </a-button>
        <a-button class="ant-button" prefix="chakan-chakanmingxi" @click="lookBill(item)">
          查看账单
        </a-button>
        <a-button type="link" class="ant-button" :prefix="flag?'shouqi':'zhankai'" @click="show">
          {{ flag?'收起':'展开' }}
        </a-button>
      <!-- <a-button class="ant-button" @click="voucher(item)">
          申报凭证
        </a-button> -->
      </div>
    </div>
    <div class="tenantDetail">
      <ul class="detail-head head-border">
        <li v-for="(head,index) in monthBillDetail" :key="index">{{ head.label }}</li>
      </ul>
      <template v-for="(detail,index) in insureClassifyList">
        <ul :key="index" class="detail-head">
          <li :span="3">{{ detail.insureClassifyName }}</li>
          <li :span="3">{{ detail.increaseCount }}</li>
          <li :span="3">{{ detail.decreasesCount }}</li>
          <li :span="3">{{ detail.personCount }}</li>
          <li :span="3">{{ detail.supplyCount }}</li>
          <li :span="3">{{ detail.companyTotal | stateFormatNum }}</li>
          <li :span="3">{{ detail.personTotal | stateFormatNum }}</li>
          <li :span="3">{{ detail.total | stateFormatNum }}</li>
        </ul>
      </template>
    </div>
  </div>

</template>
<script>
import { monthBillDetail } from '../dict'
import { stateFormatNum } from '@/utils/num-handle.js'
import { getInsureClassifyDetailApi, exportInsureBillApi, exportNotificationDocumentApi } from '@/services/insurance/bill'
export default {
  name: 'BillDetail',
  filters: {
    stateFormatNum
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    month: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      monthBillDetail: monthBillDetail,
      insureClassifyList: [],
      billOptions: [
        { label: '缴费总金额', key: 'total', company: '元' },
        { label: '单位缴费合计', key: 'companyTotal', company: '元' },
        { label: '个人缴费合计', key: 'personTotal', company: '元' }
      ],
      flag: false,
      style: {
        height: '88px'
      }
    }
  },
  mounted() {

  },
  methods: {
    lookBill(data) {
      this.$emit('lookBill', data)
    },
    importBill() {
      this.$emit('importBill', this.item.insureCompanyId)
    },
    exportBill() {
      exportInsureBillApi({ insureMonth: this.month, insureCompanyId: this.item.insureCompanyId })
    },
    // 导出申报文件
    exportFile() {
      exportNotificationDocumentApi({ insureMonth: this.month, insureCompanyId: this.item.insureCompanyId })
    },
    async show() {
      if (!this.flag) {
        const res = await getInsureClassifyDetailApi({ insureMonth: this.month, insureCompanyId: this.item.insureCompanyId })
        if (res.code === 100) {
          this.insureClassifyList = res.data
        }
      }
      this.flag = !this.flag
      if (this.flag) {
        this.style.height = 'auto'
      } else {
        this.style.height = '88px'
      }
    },
    // 创建账单时，当某个账单展开时更新数据 不需要再次点击收起在获取数据
    async getData() {
      const res = await getInsureClassifyDetailApi({ insureMonth: this.month, insureCompanyId: this.item.insureCompanyId })
      if (res.code === 100) {
        this.insureClassifyList = res.data
      }
    }
  }
}
</script>
<style lang="less" scoped>
.detail-box{
  width:100%;
  background-color: #fff;
  border:1px solid @sc-greyBg-20;
  margin-bottom: 16px;
  border-radius: 6px;
  overflow: hidden;
}
.iconColor{
  width: 16px;
  height: 16px;
  color: @sc-primary-100;
  margin-right: 4px;
  margin-bottom: -2px;
  color:@sc-grey-40
}
.list-item {
  width: 100%;
  height: 88px;
  padding: 12px 14px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-left{
    flex:1;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .detail-l {
    position: relative;
    display: flex;
    align-items: flex-start;
    .name {
      .multi-ellipsis(1);
      color: @sc-grey-100;
      .font-size(16px);
      font-weight: bold;
      margin-bottom: 5px;
      margin-right:38px;
    }
    .address {
      display: flex;
      align-items: center;
      color: @sc-grey-60;
      .font-size(16px);
    }
  }
  .detail-r {
    // width:300px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /deep/.ant-button {
      margin-left: 12px;
    }
  }
  .detail-list {
    display: flex;
    align-items: center;
    .detail {
      display: flex;
      align-items: flex-start;
      margin-right:80px;
      .value {
        color: @sc-grey-100;
        .font-size(14px);
      }
      .label {
        color: @sc-grey-60;
        .font-size(14px);
      }
    }
  }
.tenantDetail{
  background: #fff;
  border-radius: 4px;
  padding: 0 18px;
  font-size:14px;
  .head-border{
    border-bottom:1px solid @sc-greyBg-20;
    border-top:1px solid @sc-greyBg-20;
  }
  .detail-head{
    width:100%;
    height: 50px;
    line-height: 50px;
    display: flex;
    justify-content: space-between;
    li:last-child{
      text-align: right;
    }
    li{
      width:131px;
    }
  }
}
</style>
