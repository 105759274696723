<!-- 组织架构图预研究demo，relation graph官网地址： http://www.relation-graph.com/#/docs/start-->
<template>
  <div
    style="
        width: 100%;
        height: 100%;
        margin-top: 20px;
      "
  >
    <div
      style="
        width: 100%;
        height: 100%;
      "
    >
      <SeeksRelationGraph
        ref="seeksRelationGraph"
        :options="graphOptions"
        :on-node-click="nodeClick"
        class="RelationGraph-main"
      >
        <!-- 插槽自定义每个node节点样式 -->
        <div
          slot="node"
          slot-scope="{ node }"
          style="width: 100%; height: 100%"
        >
          <div :class="`node-p border-${node.deep < 3 ? node.deep : 3}`" @click="getnode">
            <div class="node-l" :style="getColor(node.orgTypeName)">
              {{ node.orgTypeName }}
            </div>
            <div class="node-r">
              <div class="org-name">{{ node.orgName }}</div>
              <div v-if="node.deep == 1" class="personnel-num">
                <div>
                  <span>全员</span>
                  <span>{{ node.staffCount }}人</span>
                </div>
                <div>
                  <span>直属</span>
                  <span>{{ node.directlyStaffCount }}人</span>
                </div>
              </div>
              <div v-else class="personnel-num">
                <CbAvater :avater-name="node.leaderName" :on-job-id="node.onJobId" :default-name-length="1" width="60" size="20" />
                <!-- <img
                  v-if="node.show"
                  :src="`${basePath}/hrsaas-base/staff/getAvatar?onJobId=${node.onJobId}&width=${28}&height=${28}`"
                  class="img_box"
                  @error="imgError(node)"
                >
                <span v-else class="img_box else_img">{{ node.leaderName ? node.leaderName.substr(-2,2) : '' }}</span>
                <span>
                  {{ node.leaderName }}</span> -->
                <span>{{ node.staffCount }}人</span>
              </div>
              <!-- <div v-if="node.leader">
                负责人：{{ node.leader }}
              </div>
              <div>直属：{{ node.zhishu }}人</div>
              <div>在职：{{ node.zaizhi }}人</div> -->
            </div>
          </div>
        </div>
      </SeeksRelationGraph>
    </div>
  </div>
</template>

<script>
import SeeksRelationGraph from '@/components/SeeksRelationGraph'
const color = [
  {
    orgTypeName: '总部',
    color: '#447DFA'
  },
  {
    orgTypeName: '分公司',
    color: '#4EABEE'
  },
  {
    orgTypeName: '事业部',
    color: '#50CCCE'
  },
  {
    orgTypeName: '部门',
    color: '#6CDE7B'
  },
  {
    orgTypeName: '组',
    color: '#EDC21A'
  }
]
export default {
  name: 'Diagram',
  components: {
    SeeksRelationGraph,
    CbAvater: () => import('@/components/CbAvater/theAvater.vue')
  },
  props: {
    dataTree: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      basePath: process.env.VUE_APP_API_BASE_URL,
      g_loading: true,
      graphOptions: {
        // 配置项
        backgrounImage: 'http://ai-mark.cn/images/ai-mark-desc.png', // 水印
        backgrounImageNoRepeat: true,
        layouts: [
          // 布局
          {
            label: '中心',
            layoutName: 'tree',
            // 'useLayoutStyleOptions': true,
            layoutClassName: 'seeks-layout-tree',
            // 'centerOffset_x': -300,
            centerOffset_y: 0,
            from: 'top',
            min_per_width: '360',
            max_per_width: '800',
            min_per_height: '220',
            max_per_height: '270'
          }
        ],
        disableDragNode: true, // 是否禁用图谱中节点的拖动
        allowShowMiniNameFilter: false, // 是否显示搜索框
        defaultFocusRootNode: false, // 默认为根节点添加一个被选中的样式
        defaultExpandHolderPosition: 'hide', // 默认的节点展开/关闭按钮位置
        defaultLineShape: 2, // 连接线样式
        isHideArrow: 'hide',
        defaultLineWidth: 2,
        defaultLineColor: 'rgba(28, 57, 183, 0.2)',
        defaultJunctionPoint: 'tb', // 默认的连线与节点接触的方式（border:边缘/ltrb:上下左右/tb:上下/lr:左右）当布局为树状布局时应使用tb或者lr，这样才会好看
        defaultNodeShape: 1, // 默认的节点形状，0:圆形；1:矩形
        defaultNodeWidth: 320, // 默认的节点宽度
        defaultNodeHeight: 135, // 默认的节点高度
        defaultNodeColor: 'transparent', // 默认的节点背景颜色
        defaultNodeFontColor: 'transparent', // 默认的节点文字颜色
        defaultNodeBorderColor: 'transparent', // 默认的节点边框颜色
        // 'hideNodeContentByZoom': true,
        defaultNodeBorderWidth: 0, // 默认的节点边框粗细（像素）
        defaultLineMarker: {
          markerWidth: '0',
          markerHeight: '0',
          refX: '101',
          refY: '100',
          data: 'M2,2 L10,6 L2,10 L6,6 L2,2'
        }
      }
    }
  },
  mounted() {
    this.setGraphData()
  },
  methods: {
    getColor(targetName) {
      let setColor = ''
      color.forEach(({ orgTypeName, color }) => {
        if (orgTypeName === targetName) {
          // return color
          setColor = color
        }
      })
      return { 'color': setColor }
    },
    back() {
      this.$emit('back')
    },
    getnode(node) {
      console.log(node)
    },
    setGraphData() {
      setTimeout(() => {
        console.log('__graph_json_data', this.dataTree)
      }, 1000)
      // this.$nextTick(() => {
      // })

      var __graph_json_data = {
        rootId: this.dataTree[0].id,
        nodes: JSON.parse(JSON.stringify(this.dataTree)),
        links: []
      }
      setTimeout(
        function() {
          this.g_loading = false
          this.$refs.seeksRelationGraph.setJsonData(
            { ...__graph_json_data },
            (seeksRGGraph) => {
              // 这些写上当图谱初始化完成后需要执行的代码
              console.log(
                'seeksRGGraph.graphSetting',
                seeksRGGraph.graphSetting
              )
              this.calc() // 自适应居中缩放方法
              // this.$refs.seeksRelationGraph.cycleTask()
            }
          )
        }.bind(this),
        1000
      )
    },
    nodeClick(nodeObject) {
      console.log(nodeObject)
      nodeObject.expanded = !nodeObject.expanded
      nodeObject.lot.childs.forEach((thisNode) => {
        thisNode.isShow = nodeObject.expanded
      })
      // this.$refs.seeksRelationGraph.refresh()
    },
    calc() {
      var _min_x = 999999
      var _min_y = 999999
      var _max_x = 0
      var _max_y = 0
      // 获取外包络矩形相对坐标
      this.$refs.seeksRelationGraph.nodeViewList.forEach((thisNode) => {
        if (thisNode.x < _min_x) {
          _min_x = thisNode.x
        }
        if (thisNode.x > _max_x) {
          _max_x = thisNode.x + thisNode.el.offsetWidth
        }
        if (thisNode.y < _min_y) {
          _min_y = thisNode.y
        }
        if (thisNode.y > _max_y) {
          _max_y = thisNode.y + thisNode.el.offsetHeight
        }
      })

      var _n_width = _max_x - _min_x
      var _n_height = _max_y - _min_y
      // 获取内容区域整个中心点位置
      var _final_x =
          _min_x * -1 +
          this.$refs.seeksRelationGraph.graphSetting.viewSize.width / 2 -
          _n_width / 2
      var _final_y =
          _min_y * -1 +
          this.$refs.seeksRelationGraph.graphSetting.viewSize.height / 2 -
          _n_height / 2
      // 获取内容区域和视口区域的宽/高的比例，用于缩放
      // const xRotes =
      //     this.$refs.seeksRelationGraph.graphSetting.viewSize.width / _n_width
      // const yRotes =
      //     this.$refs.seeksRelationGraph.graphSetting.viewSize.height / _n_height

      // let rorate = Math.min(xRotes, yRotes).toFixed(2) // 取宽高最小缩放比例
      // rorate = (rorate < 1 ? 1 : rorate) * 100 // 0.6为缩放上限，也可指定下限，可配置为传入
      // 区域定位到中心点
      this.$refs.seeksRelationGraph.animateGoto(_final_x, _final_y, 500, () => {
        // 区域进行缩放
        this.$refs.seeksRelationGraph.animateToZoom(100, 300, () => {})
      })
    },
    downloadImage() {
      this.$refs.seeksRelationGraph.downloadAsImage()
    }
    // imgError(node) {
    //   node.show = false
    // }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
  /deep/.rel-node-shape-1 {
    padding: 0;
  }
  /deep/.rel-node {
    width: 276PX !important;
    height: 100PX !important;
    box-shadow: none !important;
  }
  .node-p {
    width: 276PX;
    height: 100PX;
    border: 1px solid @sc-greyBg-50;
    border-radius: 5px;
    display: flex;
    align-items: center;
    color: #000;
    background: #fff;
    border: 2px solid @sc-primary-100;
    padding: 16px 18px;
    .node-l {
      position: relative;
      width: 66PX;
      height: 66PX;
      background: @sc-grey-10 !important;
      border-radius: 9PX;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10PX;
    }
    .node-r {
      text-align: left;
      flex: 1;
      .org-name {
        font-size: 16PX;
        font-weight: 500;
        color: @sc-grey-100;
      }
      .personnel-num-sum {
        display: flex;
        padding-top: 10PX;
        color: @sc-grey-60;
        align-items: center;
        justify-content: space-between;
      }
       .personnel-num{
      display: flex;
      padding-top: 10px;
      color: @sc-grey-100;
      justify-content: space-between;
      padding-right: 38PX;
      align-items: center;
    }
    }
  }
  div[rootid] {
    height: 100%;
    width: 100%;
  }
  .RelationGraph-header {
    height: 66px;
    background: #fff;
    padding:0 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: @sc-grey-100;
  }
  .RelationGraph-main {
    height: calc(100% - 66px) !important;
    background: red;
  }
  /deep/.rel-map {
    background: @sc-grey-10 !important;
  }
  .border-0 {
    border-color: @sc-primary-100;
  }
  .border-1 {
    border-color: @sc-primary-80;
  }
  .border-2 {
    border-color: @sc-primary-60;
  }
  .border-3 {
    border-color: @sc-primary-40;
  }
  .back {
    cursor: pointer;
  }
</style>
