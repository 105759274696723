<template>
  <div class="container">
    <Modal
      :closable="true"
      :title="(mode == 'add' ? '添加' : '编辑') + '合同主体'"
      size="normal"
      :visible="visible"
      @cancel="onClose"
      @ok="onSubmit"
    >
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
        <a-form-model-item label="企业名称" prop="companyName">
          <a-input v-model="form.companyName" :max-length="20" placeholder="最多20个字符" />
        </a-form-model-item>
        <a-form-model-item label="法人代表" prop="corporate">
          <a-input v-model="form.corporate" :max-length="20" placeholder="最多20个字符" />
        </a-form-model-item>
        <a-form-model-item label="统一社会信用代码" prop="companyCode">
          <a-input v-model="form.companyCode" placeholder="请输入正确代码" />
        </a-form-model-item>
        <a-form-model-item label="注册地" prop="address">
          <CbSelectArea
            ref="selectArea"
            v-model="form.address"
            :area-show="true"
            @change="onChange"
          />
        </a-form-model-item>
      </a-form-model>
    </Modal>
  </div>
</template>

<script>
import { SocialCreditCode } from '@/utils/reg.js'
import { createContractSubject, updateContractSubject } from '@/services/globalSetting/contract.js'
import CbSelectArea from '@/components/CbSelectArea/index'
export default {
  name: 'ContractForm',
  components: {
    CbSelectArea,
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      limit: ['city', 'area'],
      visible: false,
      areaCode: [],
      form: {
        companyId: '',
        companyName: '',
        companyCode: '',
        address: '',
        corporate: ''
      },
      defaultForm: {},
      areaAdress: [],
      rules: {
        companyName: [
          {
            required: true,
            message: '请输入企业名称',
            trigger: 'blur'
          },
          { min: 2, max: 50, message: '请输入的字符在2-50之间', trigger: 'blur' }
        ],
        companyCode: [
          {
            required: true,
            message: '请输入统一社会信用代码',
            trigger: 'blur'
          }, { validator: this.uniCodeValid, trigger: 'blur' }
        ],
        address: [
          { required: true, validator: this.areaValid, trigger: 'blur' }
        ],
        corporate: [
          {
            required: true,
            message: '请输入法定代表',
            trigger: 'blur'
          },
          { min: 2, max: 10, message: '请输入的字符在2-10之间', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    mode() {
      return this.form.companyId ? 'edit' : 'add'
    }
  },
  created() {
    this.defaultForm = JSON.parse(JSON.stringify(this.form))
  },
  methods: {
    open() {
      this.visible = true
      this.areaAdress = []
      this.$nextTick(() => {
        this.form = JSON.parse(JSON.stringify(this.defaultForm))
        this.$refs.ruleForm.resetFields()
      })
    },
    // 编辑回显数据
    edit(val) {
      this.visible = true
      if (val.area) {
        const areaList = val.areaCode.split(',')
        this.areaAdress = areaList
      } else {
        this.areaAdress = []
      }
      this.form = JSON.parse(JSON.stringify(val))
      this.$set(this.form, 'address', this.form.areaCode)
      // this.$nextTick(() => {
      //   // this.$refs['selectArea'].dataEcho(this.areaAdress)
      // })
    },
    // 验证信用代码
    uniCodeValid(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入统一社会信用代码'))
      } else if (value && SocialCreditCode.test(value)) {
        callback()
      } else if (value && !SocialCreditCode.test(value)) {
        callback(new Error('统一社会信用代码格式有误，请重新输入'))
      }
    },
    // 提交
    onSubmit() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          if (this.mode === 'add') {
            const { companyName, address, companyCode, corporate } = this.form
            const params = {
              companyName,
              address,
              companyCode,
              corporate
            }
            createContractSubject({ ...params }).then(res => {
              if (res.code === 100) {
                this.$message.success('添加合同主体成功')
                this.$emit('getfetchData')
                this.onClose()
              }
            })
          } else {
            const { companyName, address, companyCode, companyId, corporate } = this.form
            const params = {
              companyName,
              address,
              companyCode,
              companyId,
              corporate
            }
            updateContractSubject({ ...params }).then(res => {
              if (res.code === 100) {
                this.$message.success('编辑合同主体成功')
                this.$emit('getfetchData')
                this.onClose()
              }
            })
          }
        } else {
          console.log('err')
        }
      })
    },
    // 关闭
    onClose() {
      this.visible = false
      this.areaAdress = []
      this.form = JSON.parse(JSON.stringify(this.defaultForm))
      this.$refs.ruleForm.resetFields()
      this.$refs.selectArea.closeClearValue()
    },
    areaValid(rule, value, callback) {
      if (!value) {
        callback(new Error('请选择注册地'))
      } else {
        callback()
      }
    },
    onChange(val, text) {
      this.areaAdress = text
    }
  }
}
</script>

<style lang="less" scoped>
</style>
