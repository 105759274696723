<template>
  <div>
    <HeaderBack :title="title" @back="toggleValue" />
    <!--  姓名 险种  -->
    <div class="enter_content">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <div class="name_button">
          <a-form-model-item label="姓名" prop="staffId" class="per_class">
            <perTreeSelect v-model="form.staffId" :per-tree-data="perTreeData" />
          </a-form-model-item>
          <a-form-model-item>
            <a-button class="button_top" @click="getVisibleDrawer">添加补缴</a-button>
          </a-form-model-item>
        </div>
      </a-form-model>
    </div>
    <!--  表格数据  -->
    <div class="insurance-list">
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        :scroll="{ x: true }"
        row-key="insureCode"
        @change="loadDataList"
      >
        <!-- 补缴起始年月 -->
        <template slot="enterStartDate" slot-scope="{ scope }">
          <a-month-picker v-model="scope.record.enterStartDate" placeholder="" :disabled-date="disabledDate" value-format="YYYY-MM" @change="startDateChang(scope)" />
        </template>
        <!-- 补缴终止年月 -->
        <template slot="enterEndDate" slot-scope="{ scope }">
          <a-month-picker v-model="scope.record.enterEndDate" placeholder="" :disabled-date="disabledDate" value-format="YYYY-MM" @change="endDateChang(scope)" />
        </template>
        <!-- 基数 -->
        <template slot="enterBase" slot-scope="{ scope }">
          <!-- <CbNumber v-model="scope.record.enterBase" :decimal-separator="true" precision="2" /> -->
          <NumThousand
            :v-model="scope.record.enterBase"
            :int-val="scope.record.enterBase"
            :decimal-separator="true"
            :is-thousands="true"
            precision="2"
            @blur="inputChangeFun(arguments[0], scope, 'enterBase')"
          />
        </template>
        <!-- 单位比例 -->
        <template slot="unitProportion" slot-scope="{ scope }">
          <CbNumber v-model="scope.record.unitProportion" :disabled="!!scope.record.unitFixedAmount || !!scope.record.individualFixedAmount" :decimal-separator="true" precision="2" suffix="%" />
        </template>
        <!-- 个人比例 -->
        <template slot="individualProportion" slot-scope="{ scope }">
          <CbNumber v-model="scope.record.individualProportion" :disabled="!!scope.record.unitFixedAmount || !!scope.record.individualFixedAmount" :decimal-separator="true" precision="2" suffix="%" />
        </template>
        <!-- 单位月固定额 -->
        <template slot="unitFixedAmount" slot-scope="{ scope }">
          <CbNumber v-model="scope.record.unitFixedAmount" :disabled="!!scope.record.enterBase" :decimal-separator="true" precision="2" />
        </template>
        <!-- 个人月固定额 -->
        <template slot="individualFixedAmount" slot-scope="{ scope }">
          <CbNumber v-model="scope.record.individualFixedAmount" :disabled="!!scope.record.enterBase" :decimal-separator="true" precision="2" />
        </template>
        <!-- 补缴原因 -->
        <template slot="enterReason" slot-scope="{ scope }">
          <DictionariesInput v-model="scope.record.enterReason" parameter="supply_reason" placeholder="" style="width: 120px" :get-popup-container="getPopupContainer" />
        </template>
        <!-- 操作列 -->
        <template slot="operation" slot-scope="{ scope }">
          <a-button type="link" class="delete" @click="deleted(scope)">删除</a-button>
        </template>
      </custom-table>
    </div>
    <!--  确认 取消按钮  -->
    <div class="onSubmit_button">
      <a-button type="primary" @click="onSubmit">保存</a-button>
    </div>
    <!--  添加险种抽屉  -->
    <CbDrawer v-model="visibleDrawer" :z-index="9" title="添加险种" confirm-text="保存" size="small" @close="closeDrawer" @onSubmit="checkboxChange">
      <div>
        <div class="drawer-content">
          <a-checkbox-group v-model="checkList" :options="options" />
        </div>
        <a-form-model
          ref="ruleFormList"
          :model="formList"
          :label-col="labelCols"
          :wrapper-col="wrapperCols"
        >
          <a-form-model-item label="补缴起始年月">
            <a-month-picker v-model="formList.startTime" placeholder="" :disabled-date="disabledDate" value-format="YYYY-MM" />
          </a-form-model-item>
          <a-form-model-item label="补缴终止年月">
            <a-month-picker v-model="formList.endTime" placeholder="" :disabled-date="disabledDate" value-format="YYYY-MM" />
          </a-form-model-item>
          <a-form-model-item label="补缴基数">
            <!-- <CbNumber v-model="formList.base" :decimal-separator="true" precision="2" /> -->
            <NumThousand
              :v-model="formList.base"
              :int-val="formList.base"
              :decimal-separator="true"
              :is-thousands="true"
              precision="2"
              @blur="inputChangeBaseFun"
            />
          </a-form-model-item>
          <a-form-model-item label="补缴原因">
            <DictionariesInput v-model="formList.reason" parameter="supply_reason" placeholder="" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </CbDrawer>
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import {
  getScaleApi,
  addSupplyPayApi,
  canSupplyPayPersonApi,
  getInsureTypeApi,
  getTenantBillApi
} from '@/services/insurance/supplementary'
export default {
  name: 'Enter',
  components: {
    HeaderBack: () => import('@/components/headerBack'),
    perTreeSelect: () => import('@/components/CbPerDepSelection/perTreeSelect/index'),
    CbNumber: () => import('@/components/CbNumber'),
    CbDrawer: () => import('@/components/CbDrawer'),
    DictionariesInput: () => import('@/components/CbDropDownInput/dictionariesInput/index'),
    NumThousand: () => import('@/components/CbNumber/thousand')
  },
  mixins: [mixins],
  props: {
    month: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      labelCol: { span: 3 },
      wrapperCol: { span: 20 },
      labelCols: { span: 6 },
      wrapperCols: { span: 15 },
      visibleDrawer: false, // 添加险种抽屉
      title: '补缴录入',
      form: {
        staffId: undefined
      },
      checkList: [], // 险种类型选中
      options: [],
      scaleList: [],
      formList: {
        startTime: undefined,
        endTime: undefined,
        base: undefined,
        reason: undefined
      },
      columns: [
        {
          title: '险种',
          dataIndex: 'insureCodeName',
          key: 'insureCodeName',
          width: '130px',
          customCell: () => {
            return {
              style: {
                'min-width': '100px',
                'padding-left': '32px'
              }
            }
          }
        },
        {
          title: '补缴起始年月',
          dataIndex: 'enterStartDate',
          key: 'enterStartDate',
          scopedSlots: { customRender: 'enterStartDate' },
          width: '150px'
        },
        {
          title: '补缴终止年月',
          dataIndex: 'enterEndDate',
          key: 'enterEndDate',
          scopedSlots: { customRender: 'enterEndDate' },
          width: '150px'
        },
        {
          title: '基数',
          dataIndex: 'enterBase',
          key: 'enterBase',
          scopedSlots: { customRender: 'enterBase' },
          width: '150px'
        },
        {
          title: '单位比例',
          dataIndex: 'unitProportion',
          key: 'unitProportion',
          scopedSlots: { customRender: 'unitProportion' },
          width: '150px'
        },
        {
          title: '个人比例',
          dataIndex: 'individualProportion',
          key: 'individualProportion',
          scopedSlots: { customRender: 'individualProportion' },
          width: '150px'
        },
        {
          title: '单位月固定额',
          dataIndex: 'unitFixedAmount',
          key: 'unitFixedAmount',
          scopedSlots: { customRender: 'unitFixedAmount' },
          width: '150px'
        },
        {
          title: '个人月固定额',
          dataIndex: 'individualFixedAmount',
          key: 'individualFixedAmount',
          scopedSlots: { customRender: 'individualFixedAmount' },
          width: '150px'
        },
        {
          title: '补缴原因',
          dataIndex: 'enterReason',
          key: 'enterReason',
          scopedSlots: { customRender: 'enterReason' },
          width: '150px'
        },
        {
          title: ' ',
          scopedSlots: { customRender: 'operation' },
          width: 80
        }
      ],
      dataSource: [],
      tenantBill: {},
      rules: {
        staffId: [{ required: true, validator: this.rulesArray, trigger: 'change' }],
        type: [
          { type: 'array', required: true, message: '请至少选择一种险种', trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    'form.staffId'(val) {
      // 监听员工变化清除表格数据
      this.dataSource = []
      if (val) {
        this.getDictionariesData()
      }
    }
  },
  mounted() {
    this.getTenantBillApi()
  },
  methods: {
    getPopupContainer() {
      return this.$el.parentNode
    },
    // 判断账单是否存在
    async getTenantBillApi() {
      const res = await getTenantBillApi({ insureMonth: this.month })
      this.tenantBill = res.data
    },
    // 统一获取字典表
    async getDictionariesData() {
      const res = await getInsureTypeApi({ onJobId: this.form.staffId })
      this.options = res.data.map(item => {
        return {
          label: item.insureName,
          value: item.insureCode
        }
      })
    },
    // 可补缴人员列表
    async perTreeData() {
      const res = await canSupplyPayPersonApi()
      return res.data
    },
    // 限制可选月份
    disabledDate(current) { // 可选择过去日期
      return current && Number(new Date(current.format('YYYY-MM'))) >= Number(new Date(this.month))
    },
    // 开始时间选择
    startDateChang(e) {
      this.dataSource[e.index].enterStartDate = e.record.enterStartDate
    },
    // 结束时间选择
    endDateChang(e) {
      this.dataSource[e.index].enterEndDate = e.record.enterEndDate
    },
    // 删除
    deleted(scope) {
      this.dataSource.splice(scope.index, 1)
    },
    // 保存
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          // 判断是否有账单
          if (this.tenantBill) {
            // 判断账单是否生成
            this.$Reconfirm({
              title: '添加',
              content: this.tenantBill.status === 0
                ? '本月账单已生成，新增补缴后须前往账单页重新生成账单，否则无法自动生成险金扣除信息。是否确认生成补缴？'
                : '本月账单已锁定，新增补缴后须前往账单页重新生成账单，否则无法自动生成险金扣除信息。是否确认生成补缴？'
            }).then(() => {
              this.addSupplyPayApi()
            }).catch(() => {})
          } else {
            this.addSupplyPayApi()
          }
        } else {
          return false
        }
      })
    },
    // 保存数据
    async addSupplyPayApi() {
      const childrenList = this.dataSource.map(item => {
        const { insureCode, enterStartDate, enterEndDate, enterBase, unitProportion, individualProportion, unitFixedAmount, individualFixedAmount, enterReason } = item
        return {
          insureCode: insureCode,
          startMonth: enterStartDate,
          endMonth: enterEndDate,
          paymentBase: enterBase,
          companyScale: unitProportion,
          personScale: individualProportion,
          companyExtra: unitFixedAmount,
          personExtra: individualFixedAmount,
          supplyReason: enterReason
        }
      })
      const res = await addSupplyPayApi({ onJboId: this.form.staffId, insureMonth: this.month, childrenList })
      if (res.code === 100) {
        this.$message.success('添加成功')
        await this.toggleValue()
      }
    },
    // 取消
    resetForm() {
      this.$refs.ruleForm.resetFields()
    },
    // 返回
    toggleValue() {
      this.$emit('toggleValue')
    },
    // 员工姓名校验
    rulesArray(rule, value, callback) {
      this.$nextTick(() => {
        if (!this.form.staffId) {
          callback(new Error('请输入员工'))
        } else {
          callback()
        }
      })
    },
    // 添加险种
    getVisibleDrawer() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.visibleDrawer = true
        } else {
          return false
        }
      })
    },
    // 险种input赋值
    inputChangeFun(returnVal, scope, fields) {
      console.log(returnVal)
      console.log(scope)
      this.dataSource[scope.index][fields] = returnVal
    },
    inputChangeBaseFun(val) {
      this.formList.base = val
    },
    // 抽屉保存
    async checkboxChange() {
      if (this.checkList && this.checkList.length > 0) {
        const newAddCodeList = []
        const { startTime, endTime, base, reason } = this.formList
        const startTimeDate = new Date(startTime).getTime()
        const endTimeDate = new Date(endTime).getTime()
        if (startTimeDate > endTimeDate) {
          this.$message.warning('起始时间不得大于终止时间')
          return
        }
        const res = await getScaleApi({ onJobId: this.form.staffId, insureCodeList: this.checkList })
        this.scaleList = res.data
        this.checkList.forEach((item) => {
          newAddCodeList.push({
            insureCodeName: this.getTenantVal(item),
            insureCode: item,
            enterStartDate: startTime || undefined,
            enterEndDate: endTime || undefined,
            enterBase: base || undefined,
            unitProportion: this.getScaleList(item),
            individualProportion: this.getScaleList(item, 'per'),
            unitFixedAmount: undefined,
            individualFixedAmount: undefined,
            enterReason: reason || undefined
          })
        })
        this.dataSource = this.dataSource.concat(newAddCodeList)
        this.closeDrawer()
      } else {
        this.$message.warning('请至少选择一种险种类型')
      }
    },
    // 根据code获取险种名称
    getTenantVal(data) {
      return this.options.find(item => data === item.value).label
    },
    // 根据code获取公司个人比例
    getScaleList(data, val) {
      let arr = ''
      if (val === 'per') {
        arr = this.scaleList.find(item => data === item.insureCode).personScale
      } else {
        arr = this.scaleList.find(item => data === item.insureCode).companyScale
      }
      return arr
    },
    // 关闭抽屉
    closeDrawer() {
      this.checkList = []
      this.scaleList = []
      this.formList = {
        startTime: undefined,
        endTime: undefined,
        base: undefined
      }
      this.visibleDrawer = false
    }
  }
}
</script>

<style lang="less" scoped>
  .enter_content {
    width: 850px;
    .name_button {
      display: flex;
      align-items: center;
      .per_class {
        width: 400px;
      }
    }
  }
  .insurance-list {
    /deep/.number {
      width: 85%;
      .ant-input {
        color: @sc-grey-100;
      }
    }
    /deep/.ant-select {
      width: 85%;
      .ant-select-selection-selected-value {
        color: @sc-grey-100;
      }
    }
  }
  /deep/.ant-table-thead > tr:first-child > th:first-child {
    padding-left: 32px;
    border-top-left-radius: 6px;
  }
  /deep/.ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 6px;
  }
  .onSubmit_button {
    text-align: right;
    margin-top: 20px;
  }
  .drawer-content {
    margin-bottom: 24px;
    /deep/ .ant-checkbox-wrapper{
      width: 40%;
      margin-bottom: 16px;
      margin-left: 0;
    }
  }
  /deep/ .ant-checkbox-group {
    width: 100%;
  }
  /deep/ .ant-calendar-picker {
    width: 100% !important;
  }
</style>
