<template>
  <div class="post-table">
    <div class="post-toolbar">
      <div class="post-tb-left">
        <a-button prefix="add-one" @click="handleAdd">
          <span>新建岗位</span>
        </a-button>
        <a-button prefix="reduce-one" :disabled="!selectedRowKeys.length" @click="handleDel">
          <span>批量删除岗位</span>
        </a-button>
      </div>
      <div class="post-tb-right">
        <a-input-search
          v-model="currentPostName"
          placeholder="搜索"
          class="post-tb-search"
          @search="onSearch"
        />
      </div>
    </div>
    <div class="post-statistic">
      <div class="statistic-p">岗位总数：{{ postCount }}</div>
      <div class="statistic-p">在职总人数：{{ incumbencyCount }}</div>
    </div>
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      row-key="postId"
      table-ref=".post-table"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      :loading="loading"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        columnWidth: 25,
        onChange: onSelectChange
      }"
      :selection-mode="selectionMode"
      @change="loadDataList"
    >
      <!-- 占比头部自定义插槽 -->
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{ scope }">
        <span class="operation-btn-box">
          <a-button type="link" @click="edit(scope.record)">
            编辑
          </a-button>
          <a-button type="link" class="delete" @click.once="delSingle(scope.record)">
            删除
          </a-button>
        </span>
        <!-- <div class="flex-align-center">
          <a-popover placement="bottom" trigger="hover" destroy-tooltip-on-hide>
            <template slot="content">
              <div class="more-handle-btn">
                <a-button type="link" @click="edit(scope.record)">
                  编辑
                </a-button>
                <a-button type="link" class="delete" @click.once="delSingle(scope.record)">
                  删除
                </a-button>
              </div>
            </template>
            <div class="more-handle">
              ···
            </div>
          </a-popover>
        </div> -->
      </template>
    </custom-table>
    <postForm ref="postForm" @reload="postFormReload" />
    <!-- <a-modal v-model="delPostVisible" title="删除岗位" ok-text="确认" @ok="handleDelPost">
      <p>{{ delPostDesc }}</p>
    </a-modal> -->
    <!-- <a-modal v-model="delSinglePostVisible" title="删除岗位" ok-text="确认" @ok="del">
      <p>确定要删除“{{ delSingleObj.postName }}”该岗位？</p>
    </a-modal> -->
  </div>
</template>

<script>
import {
  postGetPostList,
  postGetPostStatistics,
  postDeletePost,
  postDeletePostList
} from '@/services/organization/post.js'
import mixins from '@/components/CustomTable/index.js'
export default {
  name: 'PostTabel',
  components: {
    postForm: () => import('./form/postForm.vue')
  },
  mixins: [mixins],
  data() {
    return {
      total: 0,
      lazyInitData: true, // 懒加载
      columns: [
        {
          title: '岗位名称',
          dataIndex: 'postName',
          key: 'postName',
          width: '200px'
        },
        {
          title: '在职人数',
          dataIndex: 'userCount',
          key: 'userCount',
          width: '100px'
        },
        {
          title: '适用组织',
          dataIndex: 'orgNames',
          key: 'orgNames',
          width: '200px'
        },
        {
          title: '岗位说明',
          dataIndex: 'detail',
          key: 'detail',
          width: '500px'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '100px',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      currentPostTypeId: '', // 当前左侧岗位分类选择ID
      selectedRowKeys: [], // 当前选择列集合
      currentPostName: '', // 当前查询的岗位名称
      dataSource: [], // 数据
      postCount: 0, // 岗位总数
      incumbencyCount: 0, // 在职总人数
      queryForm: this.$form.createForm(this, { name: 'horizontal_login' }), // 查询的form表单
      selectionMode: true,
      delPostVisible: false, // 删除岗位提示框
      delPostDesc: '', // 删除岗位提示
      delSinglePostVisible: false, // 单独删除岗位提示框
      delSingleObj: {}, // 单独删除岗位的对象
      pager: {
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  computed: {
    postRate() {
      return this.incumbencyCount === 0
        ? 0
        : ((this.incumbencyCount / this.postCount) * 100).toFixed(0)
    }
  },
  mounted() {},
  methods: {
    onSearch(value) {
      if (value) {
        this.currentPostName = value // 记录当前输入项
        this.serchGetData()
      } else {
        this.currentPostName = ''
        this.serchGetData()
      }
    },
    reloadTable(param) {
      this.currentPostTypeId = param.postTypeId
      this.fetchStatistics({ postTypeId: param.postTypeId })
      this.resetDatalist()
    },
    // 查询岗位分类数量统计
    fetchStatistics(param) {
      postGetPostStatistics(param).then((res) => {
        if (res.code === 100) {
          this.postCount = res.data.postCount || 0
          this.incumbencyCount = res.data.incumbencyCount || 0
        }
      })
    },
    // 查询表格数据
    async fetchDataApiFn(query) {
      const res = await postGetPostList({
        ...this.pager,
        postName: this.currentPostName,
        postTypeId: this.currentPostTypeId
      })
      if (res.code === 100 || res.code === 0) {
        this.dataSource = res.data.postList.map((item) => {
          return {
            ...item,
            orgNames: item.orgList.map((v) => v.orgName).join('、')
          }
        })
        this.pager.pageNum = res.data.pageNum || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.pageSize || 10 // 更新当前的分页信息
        this.total = res.data.total
        // this.total = res.data.total
      } else {
        this.dataSource = []
        this.pager.pageNum = 1 // 更新当前的分页信息
        this.pager.pageSize = 10 // 更新当前的分页信息
        this.total = 0
      }
    },
    // 表格选择项变更
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    // 新增岗位按钮
    handleAdd() {
      this.$refs.postForm.open({
        postTypeId: this.currentPostTypeId
      })
    },
    // 批量删除岗位
    handleDel() {
      if (this.selectedRowKeys.length > 0) {
        this.$Reconfirm({
          title: '删除岗位',
          content: `确定删除选中的${this.selectedRowKeys.length}个岗位吗？`
        }).then(() => {
          this.handleDelPost()
        }).catch(() => {
          console.log('no')
        })
      }
    },
    // 编辑按钮
    edit(info) {
      this.$refs.postForm.open({
        ...info,
        postTypeId: this.currentPostTypeId
      })
    },
    // 单独删除岗位确认
    delSingle(info) {
      this.delSingleObj = { ...info }
      this.$Reconfirm({
        title: '删除岗位',
        content: `确定要删除${this.delSingleObj.postName}该岗位？`
      }).then(() => {
        this.del()
      }).catch(() => {
        console.log('no')
      })
    },
    // 删除岗位
    del() {
      postDeletePost({
        postId: this.delSingleObj.postId
      }).then((res) => {
        if (res.code === 100) {
          this.$message.success('删除岗位成功')
          this.onSearch()
        }
      })
    },
    // 删除岗位确定框回调
    handleDelPost() {
      postDeletePostList({ postIds: this.selectedRowKeys }).then((res) => {
        if (res.code === 100) {
          this.$message.success('批量删除岗位成功')
          this.selectedRowKeys = []
          this.onSearch(this.currentPostName)
        }
      })
    },
    // 岗位表单新增、编辑回调
    postFormReload() {
      this.onSearch(this.currentPostName)
    }
  }
}
</script>

<style lang="less" scoped>
@fontSize: 14px;
.post-table {
  .post-toolbar {
    .flex-between-center();
    .post-tb-left {
      .ant-btn {
        margin-right: 12px;
        background: #fff;
      }
    }
    .post-tb-right {
      .post-tb-search {
        width: 300px;
      }
    }
  }
  .post-statistic {
    .font-size(14px);
    .flex-align-center();
    justify-content: flex-start;
    padding: 26px 0 17px;
    color:  @sc-grey-100;
    font-size: @fontSize;
    .statistic-p {
      margin-right: 12px;
    }
  }
}
.operation-btn-box{
  display:flex;
  button{
    padding-left:0;
    padding-right:16px;
  }
}
/deep/ .ant-table-thead > tr > th {
  color: @sc-grey-100;
  font-size: @fontSize;
}
/deep/ .ant-table-tbody > tr > td {
  color: @sc-grey-80;
  font-size: @fontSize;
  padding: 10px 8px;
}
/deep/ .ant-table-tbody > tr:hover {
  background: @sc-primary-10;
}
</style>
