<!-- 报税人员列表 -->
<template>
  <div>
    <div>
      <appForm
        :to-list="formList"
        search-placeholder="请搜索姓名/工号"
        :screen-roster-list="screenRosterList"
        @screenRosterParams="screenRosterParams"
        @getSearchCallback="getSearchCallback"
        @exportFileList="exportFileList"
      >
        <template slot="customButton">
          <div class="custom-button">
            <a-button prefix="piliangdaoru" @click="$emit('importTable')">
              批量导入
            </a-button>
          </div>
        </template>
      </appForm>
      <!-- 表格数据 -->
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        :tabs-height="15"
        :row-key="'onJobId'"
        :pagination="{
          current: pager.pageNum,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条数据`,
          showQuickJumper: true,
          showSizeChanger: true
        }"
        @change="loadDataList"
      >
        <!-- 序号 -->
        <template slot="index" slot-scope="{ scope }">
          <span>{{ scope.index + 1 }}</span>
        </template>
        <!-- 姓名头像 -->
        <template slot="staffName" slot-scope="{ scope }">
          <CbTableAvater
            :staff-id="scope.record.staffId"
            :on-job-id="scope.record.onJobId"
            :table-avater="scope.record.avatar"
            :table-name="scope.record.staffName"
          />
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/utils/index.js'
import { getPersonnelTaxFilingList, exportPersonnelTaxFilingList, removeStaff } from '@/services/wage/tax.js'
import mixins from '@/components/CustomTable/index.js'
import tableMixin from './tableMixin'
export default {
  name: 'VerifyStaff',
  components: {
    CbTableAvater: () => import('@/components/CbTableAvater/index')
  },
  mixins: [mixins, tableMixin],
  props: {
    subjectIds: {
      type: Object,
      default: () => {}
    },
    screenRosterList: {
      type: [Array, Object],
      default: () => {}
    }
  },
  data() {
    return {
      delVisible: false,
      formList: ['search', 'export', 'screen'], // search搜索框 export导出
      // 表格项
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'index' },
          fixed: 'left',
          width: '50px'
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          scopedSlots: { customRender: 'staffName' },
          key: 'staffName',
          fixed: 'left',
          width: 105
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          key: 'phoneNum',
          width: '180px',
          ellipsis: true
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          width: 120,
          key: 'staffNum',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          width: 120,
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '聘用形式',
          dataIndex: 'engageModeName',
          key: 'engageModeName',
          width: 120,
          ellipsis: true
        },
        {
          title: '报税主体',
          dataIndex: 'contractCoName',
          key: 'contractCoName',
          width: '200px',
          ellipsis: true
        },
        {
          title: '证件类型',
          dataIndex: 'idTypeName',
          key: 'idTypeName',
          width: 120,
          ellipsis: true
        },
        {
          title: '证件号码',
          dataIndex: 'idNum',
          key: 'idNum',
          width: '200px',
          ellipsis: true
        },
        {
          title: '性别',
          dataIndex: 'sexName',
          width: 60,
          key: 'sexName',
          ellipsis: true
        },
        {
          title: '出生日期',
          dataIndex: 'birthDate',
          width: 120,
          key: 'birthDate',
          ellipsis: true
        },
        {
          title: '任职受雇关系',
          dataIndex: 'employmentTypeName',
          key: 'employmentTypeName',
          width: '150px',
          ellipsis: true

        },
        {
          title: '员工状态',
          dataIndex: 'staffStatusName',
          key: 'staffStatusName',
          width: 120,
          ellipsis: true
        },
        {
          title: '人员状态',
          dataIndex: 'employeeStateName',
          key: 'employeeStateName',
          width: 120,
          ellipsis: true
        },
        {
          title: '报税入职日期',
          dataIndex: 'taxFilingEntryTime',
          key: 'taxFilingEntryTime',
          width: '150px',
          ellipsis: true
        },
        {
          title: '报税离职日期',
          dataIndex: 'taxFilingLeaveTime',
          key: 'taxFilingLeaveTime',
          width: '150px',
          ellipsis: true
        },
        {
          title: '入职年度就业情形',
          dataIndex: 'employmentSituationOfEntryYearName',
          key: 'employmentSituationOfEntryYearName',
          width: '160px',
          ellipsis: true
        },
        {
          title: '本年度6万扣除',
          dataIndex: 'deductionOf60kThisYearName',
          key: 'deductionOf60kThisYearName',
          width: '160px',
          ellipsis: true
        }
      ],
      // 表格数据
      dataSource: [],
      pager: {
        pageNum: 1,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  methods: {
    // 获取年终奖发放人员列表数据
    async fetchDataApiFn(param) {
      this._queryParam = {
        ...param,
        ...this.subjectIds
      }
      const res = await getPersonnelTaxFilingList(this._queryParam)
      this.dataSource = res.data?.records || []
      this.pager = param
      this.total = res.data?.total || 0
    },
    // 导出报表
    exportFileList() {
      exportPersonnelTaxFilingList({
        ...this._queryParam,
        pageSize: -1
      })
    },
    submitDel: debounce(async function() {
      const res = await removeStaff({ staffId: this.staffId })
      if (res.code === 100) {
        this.$message.success('删除成功！')
        this.delVisible = false
        this.initData()
      }
    }, 500)
  }
}
</script>

<style lang="less" scoped>
</style>
