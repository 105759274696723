<template>
  <div class="AddFrameworkModal">
    <!-- 新增编辑弹窗 -->
    <Modal
      :closable="true"
      :title="mode == 'edit'? '编辑组织':'添加组织'"
      :visible="visible"
      :size="'normal'"
      :mask-closable="false"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        ref="form"
        :rules="rules"
        :layout="form.layout"
        :model="form"
        v-bind="{
          labelCol: { span: 5 },
          wrapperCol: { span: 19 },
        }"
      >
        <a-form-model-item label="组织名称" prop="orgName">
          <a-input
            v-model="form.orgName"
            placeholder="请输入组织名称"
          />
        </a-form-model-item>
        <a-form-model-item label="组织类型" class="orgin-type" prop="orgTypeName">
          <a-select v-model="form.orgTypeName" placeholder="请选择组织类型" :default-value="form.orgTypeName" @change="handleChange">
            <a-select-option v-for="(item) in orginOption" :key="item.orgTypeId" :value="item.orgTypeId">
              {{ item.orgTypeName }}
            </a-select-option>
          </a-select>
          <a-checkbox v-model="form.invented" :disabled="!!editItemId" :default-checked="form.invented" value="1" name="type" @change="inventedChange">
            虚拟组织
          </a-checkbox>
        </a-form-model-item>
        <a-form-model-item label="组织说明" prop="detail">
          <a-textarea v-model="form.detail" :auto-size="{ minRows: 4, maxRows: 5 }" placeholder="请输入组织说明" />
        </a-form-model-item>
      </a-form-model>
    </Modal>
  </div>
</template>

<script>
import mixins from '@/components/CbPerDepSelection/index.js'
import { getOrgTypeList, createOrg, updateOrg } from '@/services/organization/framework.js'
export default {
  name: 'AddFrameworkModal',
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  mixins: [mixins],
  data() {
    return {
      addInvented: false,
      form: {
        orgName: '',
        orgTypeId: null,
        invented: false,
        leaderName: [],
        leaderId: undefined,
        leaderType: 1,
        detail: ''
      },
      visible: false,
      editItemId: null,
      supId: null, // 父级id
      mode: null,
      rules: {
        orgName: [
          { required: true, message: '请输入组织名称', trigger: 'blur' },
          { min: 1, max: 20, message: '请输入1-20个字符', trigger: 'change' }
        ],
        orgTypeName: [
          { required: true, message: '请选择组织类型', trigger: 'change' }
        ],
        detail: [
          { mix: 0, max: 300, message: '请输入0~300个字符', trigger: 'change' }
        ]
      },
      orginOption: []
    }
  },
  methods: {
    aaa() {
      this.form.leaderId = undefined
    },
    open(mode, id, item, invented) {
      // mode 标识删除还是新增
      this.mode = mode
      this.resetForm()
      this.editItemId = null
      this.supId = null
      if (mode === 'edit') {
        this.form = { ...item }
        this.form.leaderType = item.leaderType ? Number(item.leaderType) : 1
        this.editItemId = id
      } else {
        this.supId = id
        if (invented) {
          this.editItemId = '0'
          this.form.invented = true
        }
      }
      this.visible = true
      this.getOrgTypeList()
    },
    async getOrgTypeList() {
      const { code, data } = await getOrgTypeList()
      if (code !== 100) return
      this.orginOption = data
    },
    inventedChange(e) {
      this.form.invented = e.target.checked
    },
    handleChange(value) {
      this.form.orgTypeId = value
    },
    handleOk() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const params = {
            orgName: this.form.orgName,
            orgTypeId: this.form.orgTypeId,
            detail: this.form.detail,
            invented: this.form.invented,
            leaderId: this.form.leaderId,
            leaderType: String(this.form.leaderType)
          }
          if (this.mode === 'edit') {
            params['orgId'] = this.editItemId
            const { code } = await updateOrg(params)
            if (code !== 100) return
            this.$message.success('修改成功')
            this.$emit('AddFrameworkModalOk', this.form, this.editItemId, this.supId)
            this.visible = false
          } else {
            params['parentId'] = this.supId
            const { code } = await createOrg(params)
            if (code !== 100) return
            this.$message.success('新增成功')
            this.$emit('AddFrameworkModalOk', this.form, this.editItemId, this.supId)
            this.visible = false
          }
        }
      })
    },
    handleCancel() {
      this.resetForm()
      this.visible = false
    },
    resetForm() {
      // 初始化表单
      const form = {
        orgName: '',
        orgTypeId: undefined,
        invented: false,
        leaderName: undefined,
        leaderId: undefined,
        leaderType: 1,
        detail: ''
      }
      this.$set(this, 'form', form)
      this.$refs.form?.resetFields()
      this.addInvented = false
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-row {
  margin-bottom: 20px;
}
.orgin-type {
  /deep/.ant-form-item-children {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .ant-checkbox-wrapper{
    flex-shrink: 0;
    margin-left: 54px;
  }
}
</style>
