<template>
  <div class="main-content">
    <div v-show="!showUserInfo" class="user-list">
      <!--  顶部导航  -->
      <!-- <div v-if="!infoVisible" class="all-top-top">
        <div v-for="item in tabsList" :key="item.id" class="all-top-item">
          <div class="top-num-title">
            <div class="top-num">{{ item.num }}%</div>
            <div class="top-title">{{ item.name }} (%)</div>
          </div>
          <a-divider class="v-divider" type="vertical" />
        </div>
      </div> -->
      <CbStatistics :data-list="tabsList" length="6" class="statistics" />
      <ArchiveTabel ref="archiveTabel" @getInfoVisible="getInfoVisible" />
    </div>
  </div>
</template>

<script>
import ArchiveTabel from './table.vue'
import { getArchivesStatistics } from '@/services/user/archive.js'
export default {
  name: 'Certificate',
  components: {
    ArchiveTabel,
    CbStatistics: () => import('@/components/CbStatistics/index')
  },
  data() {
    return {
      key: '1',
      showUserInfo: false, // 是否展示个人信息
      tabsList: [
        {
          id: '1',
          label: '在职员工信息完善率',
          num: '',
          unit: '%',
          routeJump: 'home'
        },
        {
          id: '2',
          label: '个人信息完善率',
          num: '',
          unit: '%',
          routeJump: 'home'
        },
        {
          id: '3',
          label: '附件材料完善率',
          num: '',
          unit: '%',
          routeJump: 'home'
        }
      ],
      infoVisible: false
    }
  },
  mounted() {
    // 首页判断跳转参数
    if (this.$route.params.isHome) {
      this.infoVisible = true
      this.$refs.archiveTabel.infoVisible = true
    }
    this.getArchivesStatistics()
  },
  methods: {
    // 获取统计数据
    async getArchivesStatistics() {
      const res = await getArchivesStatistics()
      this.tabsList[0].num = res.data.allRate
      this.tabsList[1].num = res.data.infoRate
      this.tabsList[2].num = res.data.fileRate
    },
    getInfoVisible(data) {
      this.infoVisible = data
    }
  }
}
</script>

<style lang="less" scoped>
.main-content {
  background: #fff;
  padding: 20px;
  min-height: 100%;
  overflow: auto;
  .user-list {
    height: 100%;
    background: #fff;
  }
}
/deep/ .ant-tabs-ink-bar {
  height: 0;
}
/deep/ .ant-btn:hover {
  color: @sc-primary-100;
}
</style>
