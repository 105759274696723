<template>
  <div>
    <!--  添加 搜索 筛选  -->
    <appForm
      ref="appForm"
      :to-list="toList"
      :screen-roster-list="screenRosterList"
      width="400"
      @getSearchCallback="getSearchCallback"
      @screenRosterParams="screenRosterParams"
      @exportFileList="exportFileList"
    >
      <template slot="customButton">
        <a-button v-if="keyval === '1'" prefix="add-one" type="primary" @click="handleAdd">
          办理调动
        </a-button>
      </template>
    </appForm>
    <custom-table
      :columns="columns"
      :tabs-height="36"
      :data-source="dataSource"
      table-ref=".main-content"
      row-key="transferApplyId"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="loadDataList"
    >
      <!-- 序号 -->
      <template slot="index" slot-scope="{ scope }">
        <span>{{ scope.index + 1 }}</span>
      </template>
      <!-- 招聘形式 -->
      <template slot="engageMode" slot-scope="{ scope }">
        <span>{{ scope.engageMode === 0 ? '正式' : '非正式' }}</span>
      </template>
      <!-- 调动类型 -->
      <template slot="transferType" slot-scope="{ scope }">
        <span>{{ scope.transferType === '1' ? '调岗' : scope.transferType === '2' ? '晋升' : '降级' }}</span>
      </template>
      <!-- 状态 -->
      <template slot="applyStatus" slot-scope="{ scope }">
        <span>
          <StatusTag
            v-if="scope.record.applyStatus"
            :type="scope.record.applyStatus"
            :type-list="applyStatusList"
            @click="getCbApproveSteps(scope.record.flowId, scope.record.applyStatus)"
          />
        </span>
      </template>
      <!-- 姓名头像 -->
      <template slot="name" slot-scope="{ scope }">
        <CbTableAvater :table-avater="scope.record.avatar" :on-job-id="scope.record.onJobId" :table-name="scope.record.name" :staff-id="scope.record.staffId" />
      </template>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{ scope }">
        <a-button v-if="keyval == '2'" type="link" @click="getransferDetails(scope.record.transferApplyId)"> 详情 </a-button>
      </template>
    </custom-table>
    <!--  办理调动  -->
    <TransferForm ref="transferForm" @getFetchDataApiFn="getFetchDataApiFn" />
    <CbApproveSteps v-model="stepsVisible" :flow-id="flowId" :apply-status="applyStatus" :apply-status-list="applyStatusList" />
    <TransferDetails ref="TransferDetails" />
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import { transferList, alreadyTransferList, transferListExport, alreadyTransferListExport } from '@/services/user/transfer.js'
import CbTableAvater from '@/components/CbTableAvater/index'
export default {
  name: 'Transfer',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    TransferForm: () => import('./form/transferForm.vue'),
    CbApproveSteps: () => import('@/components/CbApproveSteps/index'),
    TransferDetails: () => import('./form/transferDetails.vue'),
    CbTableAvater,
    StatusTag: () => import('@/components/CbStatusTag')
  },
  mixins: [mixins],

  props: {
    keyval: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      applyStatusList: {
        1: { name: '审批中' },
        2: { name: '已通过' },
        3: { name: '驳回' }
      },
      postList: '',
      total: 0,
      toList: ['search', 'screen', 'export'], // search搜索框 screen筛选 choice位置选择弹窗 export导出
      columns: [],
      transferredList: [
        {
          title: '序号',
          dataIndex: 'index',
          width: '50px',
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '姓名',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          width: 105,
          ellipsis: true
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          key: 'phoneNum',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          ellipsis: true
        },
        {
          title: '职务',
          dataIndex: 'dutyName',
          key: 'dutyName',
          ellipsis: true
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          ellipsis: true,
          key: 'staffNum'
        },
        {
          title: '聘用形式',
          dataIndex: 'engageModeName',
          key: 'engageModeName'
        },
        {
          title: '调动类型',
          dataIndex: 'transferTypeName',
          key: 'transferTypeName'
        },
        {
          title: '计划生效日期',
          dataIndex: 'applyTransferDate',
          key: 'applyTransferDate'
        },
        {
          title: '审批状态',
          dataIndex: 'applyStatus',
          scopedSlots: { customRender: 'applyStatus' }
        }
      ],
      invalidList: [
        {
          title: '序号',
          dataIndex: 'index',
          width: '50px',
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '姓名',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          width: 105
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          key: 'phoneNum',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          ellipsis: true
        },
        {
          title: '职务',
          dataIndex: 'dutyName',
          key: 'dutyName',
          ellipsis: true
        },
        {
          title: '聘用形式',
          dataIndex: 'engageModeName',
          key: 'engageModeName'
        },
        {
          title: '调动类型',
          dataIndex: 'transferTypeName',
          key: 'transferTypeName'
        },
        {
          title: '实际生效日期',
          dataIndex: 'applyTransferDate',
          key: 'applyTransferDate'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      dataSource: [], // 数据
      screenRosterList: {
        'list': [
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'engage_mode',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'engage_mode',
            'key': 'selectDictionaries_1651109389328'
          },
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'selectDepartment',
            'key': 'selectDepartment_1651109390736'
          },
          {
            'type': 'selectPost',
            'label': '岗位',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'selectPost',
            'key': 'selectPost_1651109394312'
          },
          {
            'type': 'selectDictionaries',
            'label': '调动类型',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'transfer_type',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'transfer_type',
            'key': 'selectDictionaries_1651109400448'
          },
          {
            'type': 'selectDataRange',
            'label': '生效日期',
            'options': {
              'width': '100%',
              'defaultValue': [undefined, undefined],
              'rangePlaceholder': ['开始时间', '结束时间'],
              'range': false,
              'showTime': false,
              'disabled': false,
              'hidden': false,
              'clearable': true,
              'placeholder': '请选择开始时间',
              'format': 'YYYY-MM-DD'
            },
            'model': 'startApplyTransferDate',
            'key': 'date_1651214034712',
            'help': ''
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      selectionMode: true,
      stepsVisible: false,
      flowId: '',
      applyStatus: ''
    }
  },
  methods: {
    async fetchDataApiFn(param) {
      if (this.keyval === '1') {
        // 调动中
        this.columns = this.transferredList
        const res = await transferList(param)
        this.dataSource = res.data.records ? res.data.records : []
        this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
        this.total = res.data.total
      } else if (this.keyval === '2') {
        // 已失效
        this.columns = this.invalidList
        const res = await alreadyTransferList(param)
        this.dataSource = res.data.records ? res.data.records : []
        this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
        this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
        this.total = res.data.total
      }
    },
    // 搜索
    getSearchCallback(data) {
      this._queryParam.name = data.searchValue
      this.serchGetData()
    },
    // 操作回调
    getFetchDataApiFn() {
      this.serchGetData()
    },
    // 筛选
    screenRosterParams(data) {
      const { engage_mode, selectDepartment, selectPost, transfer_type, startApplyTransferDate } = data.screenCondition
      this._queryParam = {
        name: data.searchValue,
        engageMode: engage_mode,
        orgId: selectDepartment ? selectDepartment[0].value : '',
        postId: selectPost,
        transferType: transfer_type,
        startApplyTransferDate: startApplyTransferDate ? startApplyTransferDate[0] : '',
        endApplyTransferDate: startApplyTransferDate ? startApplyTransferDate[1] : ''
      }
      this.serchGetData()
    },
    handleAdd() {
      this.$refs.transferForm.open()
    },
    // 审批步骤条
    getCbApproveSteps(data, val) {
      this.flowId = data
      this.applyStatus = val
      this.stepsVisible = true
    },
    getransferDetails(id) {
      this.$refs.TransferDetails.show(id)
    },
    // 导出
    exportFileList() {
      const { name, engageMode, orgId, postId, transferType, startApplyTransferDate, endApplyTransferDate } = this._queryParam
      const params = {
        name: name,
        engageMode: engageMode,
        orgId: orgId,
        postId: postId,
        transferType: transferType,
        startApplyTransferDate: startApplyTransferDate,
        endApplyTransferDate: endApplyTransferDate
      }
      if (this.keyval === '1') {
        transferListExport(params)
      } else {
        alreadyTransferListExport(params)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.more-handle-btn {
  display: flex;
  flex-direction: column;
}
</style>
