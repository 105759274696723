import clock from './clock'
import replacement from './replacement'
import overtime from './overtime'
import out from './out'
import travel from './travel'
import leave from './leave'
export default {
  clock,
  replacement,
  overtime,
  out,
  travel,
  leave
}
