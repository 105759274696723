<template>
  <div class="taxPay">
    <div v-if="!isShow">
      <div class="calendar-top">
        <a-icon type="left" @click="pervious" />
        <div class="timePicker">
          <a-month-picker v-model="time" value-format="YYYY-MM" format="YYYY年MM月税款缴纳" :open="show" @change="onChange" />
          <a-icon type="calendar" theme="twoTone" two-tone-color="#507ff7" @click="showPicker" />
        </div>
        <a-icon type="right" @click="next" />
      </div>
      <div class="content">
        <a-card v-for="(item, index) in payList" :key="index" :title="title +`${item.proof}`" class="main-card">
          <div slot="extra" class="main-top">
            <span>发生日期：{{ item.happenTime }}</span>
            <span>限缴日期：{{ item.limitedTime }}</span>
          </div>
          <div class="main-content">
            <div class="main-left">
              <span class="main-left-num">{{ item.shouldPay }}</span>
              <span>应缴金额</span>
            </div>
            <div class="main-right">
              <div class="v-line">
                <a-col>
                  <div class="v-line-item">
                    <span>税款属性：</span>
                    <span class="bold-span">{{ item.taxAttributes }}</span>
                  </div>
                  <div class="v-line-item">
                    <span>税款种类：</span>
                    <span class="bold-span">{{ item.taxKinds }}</span>
                  </div>
                </a-col>
                <a-col>
                  <div class="v-line-item">
                    <span>滞纳金：</span>
                    <span class="bold-span">{{ item.delayingPayment }}元</span>
                  </div>
                  <div class="v-line-item">
                    <span>加罚金额：</span>
                    <span class="bold-span">{{ item.penaltyAmount }}元</span>
                  </div>
                </a-col>
              </div>
            </div>
          </div>
          <div class="main-bottom">
            <a-button type="default" @click="handlePay(item)">缴纳</a-button>
          </div>
        </a-card>
      </div>
    </div>
    <div v-else>
      <PayTax :pay-info="info" :curtime="time" @goBack="goBack" />
    </div>
  </div>
</template>

<script>
import PayTax from './payTax.vue'
import moment from 'moment'
export default {
  name: 'TaxPayment',
  components: {
    PayTax
  },
  data() {
    return {
      moment,
      isShow: false,
      time: '',
      show: false,
      title: '应征缴凭证号：',
      payList: [{
        proof: '1238293822',
        happenTime: '2021-01-01',
        limitedTime: '2021-01-30',
        shouldPay: '102,900.09',
        taxAttributes: '一级申报',
        taxKinds: '正税',
        delayingPayment: '102.00',
        penaltyAmount: '50.01'
      }, {
        proof: '1234782312',
        happenTime: '2020-01-01',
        limitedTime: '2020-06-30',
        shouldPay: '112,900.09',
        taxAttributes: '一级申报',
        taxKinds: '正税',
        delayingPayment: '112.00',
        penaltyAmount: '50.51'
      }, {
        proof: '1456731822',
        happenTime: '2019-04-20',
        limitedTime: '2020-01-01',
        shouldPay: '102,930.09',
        taxAttributes: '一级申报',
        taxKinds: '正税',
        delayingPayment: '102.34',
        penaltyAmount: '51.21'
      }],
      info: {}
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      const currDate = new Date()
      const year = currDate.getFullYear()
      const month = currDate.getMonth() + 1
      this.time = `${year}年${month}`
    },
    showPicker() {
      this.show = true
    },
    onChange() {
      this.show = false
      this.time = this.time.split('-').join('年')
    },
    pervious() {
      let year = this.time.split('年')[0]
      let month = this.time.split('年')[1] - 1
      console.log('pre', year, month)
      if (month === 0) {
        year -= 1
        month = 12
      }
      this.time = `${year}年${month}`
    },
    next() {
      let year = Number(this.time.split('年')[0])
      let month = Number(this.time.split('年')[1]) + 1
      console.log('pre', year, month)
      if (month === 12) {
        year += 1
        month = 1
      }
      this.time = `${year}年${month}`
    },
    handlePay(item) {
      this.info = item
      console.log('info', item)
    },
    goBack() {
      this.isShow = false
    }
  }
}
</script>
<style lang="less" scoped>
.taxPay{
  width: 100%;
  height: 100vh;
  padding: 40px 30px 0;
  .calendar-top{
    width: auto;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /deep/i.anticon{
      font-size: 25px;
      margin-right: 20px;
    }
    .timePicker{
      .ant-calendar-picker {
        width: 260px;
        font-size: 25px;
        /deep/.ant-calendar-picker-input{
          border: 0;
          font-size: 25px;
        }
        /deep/.ant-calendar-picker-clear, /deep/.ant-calendar-picker-icon{
          display: none;
        }
      }
    }
  }
  .content{
    .main-card{
      margin: 30px 0;
      .main-top{
        span{
          margin-left: 30px;
          font-size: 14px;
        }
      }
      /deep/.ant-card-body{
        padding: 0;
        display: flex;
        flex-direction: column;
        &::before, &::after{
          display: none;
        }
        .main-content{
          padding: 10px 30px;
          border-bottom: 1px solid #eee;
          display: flex;
          align-items: center;
          .main-left{
            width: 20%;
            padding: 20px 30px;
            border-right: 2px solid #eee;
            display: flex;
            flex-direction: column;
            .main-left-num{
              font-size: 24px;
              color: #000;
            }
          }
          .main-right{
            width: 44%;
            padding: 0 20px;
            .v-line{
              margin-left: 50px;
              display: flex;
              justify-content: space-between;
              .v-line-item{
                margin: 10px 0;
                .bold-span{
                  color: #000;
                }
              }
            }
          }
        }
        .main-bottom{
          padding: 10px;
          text-align: right;
        }
      }
    }
  }
}
</style>
