<template>
  <div class="equity">
    <div class="equity-top">
      <div class="equity-top-left">
        <img :src="version ? FreeSrc : productInfo.productVersion === 'profession' ? ProSrc : ProTrySrc" class="img">
        <div class="equity-surplus-items">
          <div>
            <img :src="surplusImg" class="surplus-img">
          </div>
          <div>
            <div class="surplus-top">
              <span class="surplus-title">服务期：</span><span v-show="endDateShow" class="surplus-text">{{ version ? '超长使用期' : productInfo.startDate }}{{ version ? `` : ` 至 ${productInfo.endDate}` }}</span>
            </div>
            <div class="surplus-bottom">
              <span class="surplus-title">剩余：</span><span class="surplus-text">{{ version ? '超长使用期' : productInfo.surplus }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="equity-top-right">
        <div class="equity-top-employees">
          <div>
            <img :src="employeesImg" class="surplus-img">
          </div>
          <div>
            <div class="surplus-top">
              <span class="surplus-title">在职员工人数：</span><span class="surplus-text">{{ productInfo.actualLicenses }}{{ productInfo.equityLicenses ? `/${productInfo.equityLicenses}` : `` }}人</span>
            </div>
            <div class="surplus-bottom">
              <!-- @click="personModal" -->
              <!-- <div class="surplus-text personModal">升级 <a-icon type="right" class="personalIcon" /></div> -->
              <div class="surplus-text personModal exp_div" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="equity-items">
      <div v-for="(item, index) in equityList" :key="`items${index}`" class="items" :style="{ backgroundImage: `url(${item.img})` }">
        <div class="items-header">
          <div class="title-imgs">
            <div class="headerImg">
              <img :src="item.headerImg">
            </div>
            <div :style="{ color: `${item.color}`, lineHeight: `24PX` }">{{ item.name }}</div>
          </div>
          <!-- <div class="more" :style="{ color: `${item.color}` }">更多权益 <a-icon type="right" class="moreIcon" /></div> -->
        </div>
        <div class="items-article">
          <div v-for="(list, listIndex) in item.children" :key="`lists${listIndex}`" class="lists">
            <div class="lists-img">
              <img :src="list.itemSrc">
            </div>
            <span>{{ list.text }}</span>
          </div>
        </div>
        <div class="items-button">
          <!-- <a-button type="primary" :style="{ backgroundColor: `${item.buttonColor}`, opacity: item.active ?`0.4`:`1` }" @click="showModal(item)">
            <span>{{ item.active ?'已开通':'立即升级' }}</span>
          </a-button> -->
        </div>
      </div>
    </div>
    <h3>增值服务</h3>
    <div class="equity-services">
      <div v-for="(item, index) in ValueAddedServices" :key="index" class="equity-services-items">
        <div class="left">
          <div class="title">{{ item.title }}</div>
          <div class="separate" />
          <p class="services-p">{{ item.text }}</p>
          <div class="service-button" @click="open($event, item)">
            <!-- <a-button type="default">
              <span class="antNext">立即开通</span>
            </a-button> -->
          </div>
        </div>
        <div class="right">
          <img :src="item.bgSrc" alt="">
        </div>
      </div>
    </div>
    <UpgradeAlert ref="alert" @changeActive="change" />
    <UpgradeAdministrators ref="admin" />
  </div>
</template>

<script>
import { getEquityInfo } from '@/services/globalSetting/Equity.js'
export default {
  name: 'GlobalSettingEquity',
  components: {
    UpgradeAlert: () => import('./components/upgradeAlert'),
    UpgradeAdministrators: () => import('./components/upgradeAdministrators')
  },
  data() {
    return {
      endDateShow: false,
      show: false,
      visible: false,
      productInfo: {},
      surplusImg: require('@/assets/img/global/time.png'),
      employeesImg: require('@/assets/img/global/employees.png'),
      ProSrc: require('@/assets/img/global/equityProfession.png'),
      FreeSrc: require('@/assets/img/global/equityFree1.png'),
      ProTrySrc: require('@/assets/img/global/equityProTry.png'),
      equityList: [
        { val: 1,
          color: '#617194',
          img: require('@/assets/img/global/freeImg.png'),
          headerImg: require('@/assets/img/global/freeSmall.png'),
          name: '免费版',
          active: false,
          buttonColor: '#507FF7',
          children: [
            { itemSrc: require('@/assets/img/global/free-human.png'), text: '完整基础人事管理功能' },
            { itemSrc: require('@/assets/img/global/free-funnel.png'), text: '超长使用周期' },
            { itemSrc: require('@/assets/img/global/free-person.png'), text: '员工转移自助服务' },
            { itemSrc: require('@/assets/img/global/free-info.png'), text: '赠送业务短信包' },
            { itemSrc: require('@/assets/img/global/free-organ.png'), text: '50人以下企业人事管理' },
            { itemSrc: require('@/assets/img/global/free-service.png'), text: '5*8小时客服服务' }
          ]
        },
        { val: 2,
          color: '#B68350',
          img: require('@/assets/img/global/proImg.png'),
          headerImg: require('@/assets/img/global/professional.png'),
          name: '专业版',
          active: false,
          buttonColor: '#ffbe22',
          children: [
            { itemSrc: require('@/assets/img/global/pro-discount.png'), text: '尊享超值优惠' },
            { itemSrc: require('@/assets/img/global/add-pro.png'), text: '享受多项增值服务' },
            { itemSrc: require('@/assets/img/global/person-pro.png'), text: '人力资源一体化解决方案' },
            { itemSrc: require('@/assets/img/global/pro-info.png'), text: '赠送业务短信包' },
            { itemSrc: require('@/assets/img/global/role-pro.png'), text: '多角色移动管理' },
            { itemSrc: require('@/assets/img/global/service-pro.png'), text: '7*12小时客户成功服务' }
          ]
        }
      ],
      ValueAddedServices: [
        { bgSrc: require('@/assets/img/global/recruit-img.png'), title: '招聘管理', text: '全流程招聘管理，提升人岗匹配率，降低招聘平均时间，提高招聘效率。' },
        { bgSrc: require('@/assets/img/global/social-insurance.png'), title: '社保智能申报', text: '统一管理和留存企业社保信息，助力企业社保精细化管理。' },
        { bgSrc: require('@/assets/img/global/tax-declaration.png'), title: '个税智能申报', text: '对接税务平台，一键报税，助力企业采用合理得成本激发员工效能。' },
        { bgSrc: require('@/assets/img/global/branch-management.png'), title: '分公司管理', text: '轻松实现多企业统一管理，促使集团实力最大化。' },
        { bgSrc: require('@/assets/img/global/wechat.png'), title: '对接企业微信', text: '与企业微信进行对接，实现办公、管理、服务一体化。' },
        { bgSrc: require('@/assets/img/global/info-management.png'), title: '对接钉钉', text: '与钉钉进行对接，实现办公、管理、服务一体化。' }
      ]
    }
  },
  computed: {
    version() {
      return this.productInfo.productVersion === 'free'
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    // 在职员工升级
    personModal() {
      this.$refs.admin.open()
    },
    // 版本升级
    showModal(item) {
      this.$refs.alert.open(item)
    },
    open(e, item) {
      e.target.blur()
      this.$refs.alert.open(item)
    },
    change(val, index) {
      this.equityList[index - 1].active = val
    },
    // 获取信息
    async getInfo() {
      const res = await getEquityInfo()
      if (res.code === 100) {
        this.productInfo = res.data
        this.endDateShow = true
      }
    }
  }
}
</script>

<style scoped lang="less">
* {
  box-sizing: border-box;
}
.equity {
  width: 100%;
  padding: 20px 20px 0;
   &-top {
    width: 100%;
    height: 146px;
    background-image: url(../../../assets/img/global/topbanner.png);
    background-size: cover;
    display: flex;
    border-radius: 4px;
    &-left,
    &-right {
      width: 50%;
      display: flex;
      align-items: center;
      .img {
        width: 105px;
        height: 91px;
        margin: 0 63px 0 48px;
      }
      .equity-surplus-items,
      .equity-top-employees {
        display: flex;
        .surplus-top {
          margin-bottom: 12px;
        }
      }
      .surplus-img {
          width: 14px;
          height: 14px;
          margin-right: 10px;
          margin-top: 3px;
        }
      .surplus-text {
        color: #fff;
        font-weight: bold;
        .font-size(16px);
        }
        .personModal {
          opacity: 0;
          display: flex;
          align-items: center;
          .personalIcon {
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
          }
        }
      .surplus-title {
        .font-size(16px);
        color: #fff;
        opacity: 0.6;
        font-weight: 500;
      }
    }
  }
  &-items {
    width: 100%;
    margin: 21px 0 25px;
    display: flex;
    justify-content: space-between;
    .items {
      width: calc((100% - 14px) / 2);
      background-size: 100% 100%;
      border-radius: 8px;
      &-header {
        width: 100%;
        height: 50px;
        .font-size(16px);
        padding: 0 4%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-size: 100% 100%;
        .title-imgs {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
           .headerImg {
            width: 32px;
            height: 28px;
            margin-right: 7px;
            img {
              height: 100%;
            }
          }
        }
        .more {
          font-size: 14px;
          display: flex;
          align-items: center;
          .moreIcon {
            width: 12px;
            height: 12px;
            margin-left: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      &-article {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        padding-left: 8%;
        .lists {
          width: calc(100% / 3);
          color: @sc-grey-100;
          margin-top: 18px;
          display: flex;
          align-items: center;
          font-size: 14px;
          .lists-img{
            width: 42px;
            height: 42px;
            margin-right: 10px;
            img {
              width: 100%;
            }
          }
        }
      }
      &-button {
          width:112px;
          height: 32px;
          // margin: 10px auto 30px;
      }
    }
  }
  h3 {
    font-weight: bold;
    font-size: 16px;
    color: @sc-grey-100;
  }
   &-services {
     width: 100%;
     display: flex;
     justify-content: space-between;
     align-items: center;
     flex-wrap: wrap;
     &-items {
      height: 200px;
      width: calc((100% - 22px) / 3);
      padding-left: 28px;
      box-sizing: border-box;
      .flex-align-center();
       border: 1px solid @sc-greyBg-10;
       border-radius: 8px;
       margin-bottom: 24px;
       position: relative;
       background: #fff;
       .left{
          width: 70%;
          position: relative;
          z-index: 1;
         .title {
           color: @sc-grey-100;
           font-size: 16px;
         }
         .separate {
          width: 32px;
          height: 3px;
          margin: 14px 0;
          background-color: @sc-primary-80;
         }
         .services-p {
          color: @sc-grey-80;
          font-size: 14px;
         }
         .service-button {
          width: 112px;
          height: 32px;
          margin-top: 22px;
         }
       }
       .right {
         width: 200px;
         position: absolute;
         top: 38%;
         right: 0;
         img {
          width: 100%;
         }
       }
     }
   }
}
/deep/ .ant-btn-primary {
   border-color: transparent;
   text-shadow: none;
   box-shadow: none;
}
/deep/ .ant-btn:hover .antNext {
  color: @sc-primary-100;
}
.exp_div{
  height: 30px;
}
</style>
