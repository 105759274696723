<template>
  <div class="all-content">
    <div class="v-title">消息设置</div>
    <div v-for="item in dataInfo" :key="'info' + item.messageGroupId" class="info-all">
      <div class="info-swit-add">
        <div class="info-swit">
          <span class="info-swit-text">{{ item.groupName }}</span>
          <a-switch :checked="item.groupStatus == 1 ? true : false" size="small" @change="onChangeVal(item,...$event)" />
        </div>
        <a-button class="addButtom" type="link" size="small" @click="handleAdd(item)"><svg class="iconpark-icon"><use href="#add-one" /></svg>添加 </a-button>
      </div>
      <InfoTable :data-info="item.messageConfigList" @getFetchDataApiFn="getFetchDataApiFn" @handleEdit="handleEdit" />
    </div>
    <infoForm ref="infoForm" @getFetchDataApiFn="getFetchDataApiFn" />
  </div>
</template>

<script>
import { getMessageGroupList, changeGroupStatus } from '@/services/user/setting.js'
import InfoTable from './table'
import mixins from '@/components/CustomTable/index.js'
export default {
  name: 'MessageSet',
  components: {
    InfoTable,
    infoForm: () => import('./form/infoForm.vue')
  },
  mixins: [mixins],
  data() {
    return {
      dataInfo: [],
      param: {}
    }
  },
  created() {},
  methods: {
    // 获取表格数据
    async fetchDataApiFn(param) {
      this.param = param
      // 未入职
      this.columns = this.notEmployedList
      const res = await getMessageGroupList(param)
      this.dataInfo = res.data ? res.data : []
      this.pager.currentPage = res.data.current || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
      this.total = res.data.total
    },
    // 添加
    handleAdd(val) {
      this.$refs.infoForm.open({
        messageGroupId: val.messageGroupId,
        customFlag: val.customFlag
      })
    },
    // 操作回调
    getFetchDataApiFn() {
      this.serchGetData()
    },
    // 编辑回调
    handleEdit(val) {
      this.$refs.infoForm.open({
        messageConfigId: val
      })
    },
    async onChangeVal(val, b) {
      val.groupStatus = b
      const per = {
        messageGroupId: val.messageGroupId,
        groupStatus: val.groupStatus ? 1 : 0
      }
      changeGroupStatus(per).then((res) => {
        if (res.code === 100) {
          this.$forceUpdate()
        }
      })
      await getMessageGroupList(this.param)
    }
  }
}
</script>

<style lang="less" scoped>
.all-content {
  height: 100%;
  padding: 20px 20px 0;
  overflow: auto;
}
.v-title {
  font-weight: 600;
  font-size: 16px;
  color: @sc-grey-100;
}
.info-swit {
  margin: 10px 10px 10px 0px;
}
.info-swit-add {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 10px;
}
.info-swit-text {
  margin-right: 10px;
  font-size: 14px;
  color: @sc-grey-100;
}
.info-table {
  margin-bottom: 30px;
}
.addButtom .iconpark-icon{
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 6px;
}
</style>
