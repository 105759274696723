import { mapMutations } from 'vuex'
export default {
  inject: ['globForm'],
  methods: {
    ...mapMutations('setting', ['setLoginType']),
    changeLoginType(type) {
      this.globForm.phoneNum = ''
      this.globForm.password = ''
      this.globForm.repassword = ''
      this.globForm.code = ''
      this.globForm.uuid = ''
      this.setLoginType(type)
    }
  }
}

import { setAuthorization } from '@/utils/request'
import { loadRoutes } from '@/utils/routerUtil'
export const afterLogin = {
  methods: {
    ...mapMutations('account', ['setUser', 'setPermissions', 'setRoles', 'setTenant', 'setAccountInfo']),
    afterLogin(res) {
      this.logging = false
      const { user, permissions, roles, token, currTenant, accountInfo } = res
      const routesConfig = [
        {
          router: 'root',
          children: this.recursionPermissions(permissions)
        }
      ]
      this.setUser(user)
      this.setTenant(currTenant)
      this.setPermissions(routesConfig)
      this.setRoles(roles)
      this.setAccountInfo(accountInfo)
      setAuthorization({ token: token.accessToken, expireAt: 7 })
      loadRoutes(routesConfig)
      this.$message.success('登录成功')
      this.$router.push('/home')
      // this.$message.success(loginRes.message, 3)
    },
    recursionPermissions(permissions) {
      return permissions.map(item => {
        if (item.children && item.children.length > 0) {
          return {
            ...item,
            router: item.funcCode,
            children: this.recursionPermissions(item.children)
          }
        } else {
          return {
            ...item,
            router: item.funcCode
          }
        }
      })
    }
  }
}
