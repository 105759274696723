<template>
  <div class="user-setting-panel">
    <layout
      ref="layoutMenu"
      :menu-data-list="menuDataList"
      :selected-key.sync="selectedKey"
      @menu-select-change="menuSelectChange"
    >
      <component :is="curComponentName" />
    </layout>
  </div>
</template>

<script>
import layout from '@/components/CbLayoutMenu/index'
import common from './common'
export default {
  components: {
    layout,
    ...common
  },
  data() {
    return {
      selectedKey: '',
      curComponentName: '',
      menuDataList: [
        {
          id: '1',
          name: '员工信息',
          frontIcon: '#id-card-v-5m18npi6',
          fileName: 'userInfo',
          funcCode: 'userInfo',
          actions: []
        },
        {
          id: '2',
          name: '证明模板',
          frontIcon: '#passport',
          fileName: 'proofTemplate',
          funcCode: 'proofTemplate',
          actions: []
        },
        // {
        //   id: '3',
        //   name: '消息设置',
        //   frontIcon: '#remind',
        //   fileName: 'messageSet',
        //   funcCode: 'messageSet',
        //   actions: []
        // },
        {
          id: '4',
          name: '工号规则',
          frontIcon: '#id-card-h',
          fileName: 'jobNumRule',
          funcCode: 'staffNum',
          actions: []
        },
        {
          id: '5',
          name: '试用期设置',
          frontIcon: '#appointment',
          fileName: 'probation',
          funcCode: 'probation',
          actions: []
        },
        // {
        //   id: '6',
        //   name: '发件邮箱',
        //   frontIcon: '#email-push',
        //   fileName: 'sendEmail',
        //   funcCode: 'sendEmail',
        //   actions: []
        // },
        {
          id: '7',
          name: '小程序入职',
          frontIcon: '#doc-detail-5m1912a3',
          fileName: 'InductionControl',
          funcCode: 'InductionControl',
          actions: []
        },
        {
          id: '8',
          name: '入职事项',
          frontIcon: '#doc-detail-5m1912a3',
          fileName: 'EntryMatters',
          funcCode: 'EntryMatters',
          actions: []
        }
      ]
    }
  },
  mounted() {
    this.$refs.layoutMenu.showInit(this.$route.query.type)
  },
  methods: {
    menuSelectChange(item) {
      this.curComponentName = item.fileName
    }
  }
}
</script>

<style lang="less" scoped>
.user-setting-panel {
  min-height: 100%;
  /deep/.sc-menu{
    padding-top: 20px;
    background: #fff;
    position: fixed;
    height: 100% !important;
  }
}
</style>
