<template>
  <div class="pay-slip-container">
    <div class="pay-slip-main">
      <div class="left-setting">
        <!-- 温馨提示 -->
        <div class="pay-slip-common">
          <div class="pay-slip-common-text">温馨提示</div>
          <div>
            <div class="switch-icon">
              <a-switch v-model="tipsSwitch" size="small" />
            </div>
            <div v-show="tipsSwitch" class="textarea-text">
              <a-textarea v-model="tipsContent" placeholder="请输入温馨提示" :rows="5" />
            </div>
          </div>
        </div>
        <!-- 隐藏空数据项 -->
        <div class="pay-slip-common dataNull">
          <div class="pay-slip-common-text">隐藏空数据项</div>
          <div style="display: flex;">
            <div class="dataNull-first">
              <a-checkbox v-model="dateNull">
                <span class="dataNull-span">薪资项为空时，隐藏该项</span>
              </a-checkbox>
            </div>
            <div>
              <a-checkbox v-model="dateZero">
                <span class="dataNull-span">薪资项数额为0时，隐藏该项</span>
              </a-checkbox>
            </div>
          </div>
        </div>
        <!-- 工资条查看密码 -->
        <div class="pay-slip-common">
          <div class="pay-slip-common-text">工资条查看密码</div>
          <div>
            <div class="switch-icon">
              <a-switch v-model="passwordSwitch" size="small" />
            </div>
          </div>
        </div>
        <!-- 工资条确认时间 -->
        <div class="pay-slip-common">
          <div class="pay-slip-common-text">
            <span>工资条确认时间</span>
          </div>
          <div>
            <div class="switch-icon slip-icon">
              <a-switch v-model="enterSwitch" size="small" />
            </div>
            <div v-if="enterSwitch" class="textarea-text">
              <a-radio-group v-model="enterType" class="radio-group">
                <a-radio :value="1" class="group-text">
                  不自动确认
                </a-radio>
                <div>
                  <a-radio :value="2">
                    <CbNumber v-model="enterTime" :min="1" :max="100000" />
                    <span class="group-text">
                      天自动确认
                    </span>
                  </a-radio>
                </div>
              </a-radio-group>
            </div>
          </div>
        </div>
        <!-- 阅后即焚 -->
        <div class="pay-slip-common readAfter">
          <div class="pay-slip-common-text">阅后即焚</div>
          <div>
            <div class="switch-icon slip-icon">
              <a-switch v-model="snapchatSwitch" size="small" />
            </div>
            <div v-if="snapchatSwitch" class="textarea-text">
              <span class="slip-check">员工查看</span>
              <CbNumber v-model="snapchatNumber" :min="1" :max="100000" class="number-check" />
              <a-select v-model="snapchatUnit" default-value="小时">
                <a-select-option v-for="item in timeList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
              </a-select>
              <span class="slip-destroy">后自动销毁</span>
            </div>
          </div>
        </div>
        <div class="pay-slip-common proview">
          <div class="pay-slip-common-text">工资条预览</div>
          <div class="salary-groups">
            <div
              v-for="item in salaryGroups"
              :key="item.groupId"
              class="salary-item"
              :class="{ active : item.groupId === salaryItem.groupId }"
              @click="selectProview(item)"
            >
              {{ item.groupName }}
            </div>
          </div>
        </div>
      </div>
      <div class="rigth-phone">
        <proviewMin :tips-switch="tipsSwitch" :tips-content="tipsContent" :salary-list="salaryList" />
      </div>
    </div>
    <div class="screen_btn">
      <a-button type="primary" @click="setConfig">确认</a-button>
    </div>
  </div>
</template>

<script>
import CbNumber from '@/components/CbNumber'
import { setConfig, getConfig, getSalaryGroupAndItem } from '@/services/wage/staffPayrollSetting'
import { listGroup } from '@/services/wage/salaryGroup'
import proviewMin from './proviewMin'
export default {
  name: 'PayslipTemplate',
  components: {
    CbNumber,
    proviewMin
  },
  data() {
    return {
      tipsSwitch: true, // 温馨提示开关
      tipsContent: '工资条属于敏感信息，请注意保密', // 温馨提示
      dateNull: false, // 薪资项为空时
      dateZero: false, // 薪资项数额为0时
      checkList: [ // 薪资项目为空的配置
        {
          dateNull: false,
          dateZero: false
        },
        {
          dateNull: true,
          dateZero: false
        },
        {
          dateNull: false,
          dateZero: true
        },
        {
          dateNull: true,
          dateZero: true
        }
      ],
      passwordSwitch: true,
      enterSwitch: true, // 工资条自动确认时间开关
      enterType: 1, // 工资条确认方式
      enterTime: 3, // 工资条自动确认时间
      snapchatSwitch: true, // 阅后即焚开关 0关闭 1开启
      snapchatNumber: 30, // 阅后即焚的时间
      snapchatUnit: 2, // 阅后即焚的时间单位
      timeList: [
        { id: 1, name: '小时' },
        { id: 2, name: '分钟' }
      ],
      salaryGroups: [],
      salaryItem: {},
      salaryList: []
    }
  },
  created() {
    this.listGroup()
    this.getConfig()
  },
  methods: {
    // 设置
    async setConfig() {
      const param = {}
      const {
        tipsSwitch,
        tipsContent,
        dateNull,
        dateZero,
        passwordSwitch,
        enterSwitch,
        enterType,
        enterTime,
        snapchatSwitch,
        snapchatNumber,
        snapchatUnit
      } = this
      // 温馨提示 start
      if (tipsSwitch) {
        if (tipsContent === null || tipsContent === '') {
          return this.$message.warning('请填写温馨提示')
        }
      }
      param.tipsContent = tipsContent
      param.tipsSwitch = Number(tipsSwitch)
      // 温馨提示 end

      // 隐藏空数据 start
      // ❎薪资项为空时，隐藏该项   ❎薪资项数额为0时，隐藏该项 0
      // ✅薪资项为空时，隐藏该项   ❎薪资项数额为0时，隐藏该项1
      // ❎薪资项为空时，隐藏该项   ✅薪资项数额为0时，隐藏该项2
      // ✅薪资项为空时，隐藏该项   ✅薪资项数额为0时，隐藏该项3
      if (dateNull) {
        if (dateZero) {
          param.concealEmpty = 3
        } else {
          param.concealEmpty = 1
        }
      } else {
        if (dateZero) {
          param.concealEmpty = 2
        } else {
          param.concealEmpty = 0
        }
      }
      // 隐藏空数据 end

      // 工资条查看密码start
      param.passwordSwitch = Number(passwordSwitch)
      // 工资条查看密码end

      // 工资自动确认时间 start
      if (enterSwitch) {
        if (enterType === 2) {
          if (enterTime === '' || enterTime === null) {
            return this.$message.warning('请填写工资自动确认时间')
          }
        }
      }
      param.enterTime = enterTime
      param.enterType = enterType
      param.enterSwitch = Number(enterSwitch)
      // 工资自动确认时间 end

      // 阅后即焚 start
      if (snapchatSwitch) {
        if (snapchatNumber === null || snapchatNumber === '') {
          return this.$message.warning('请填写阅后即焚时间')
        }
      }
      param.snapchatNumber = snapchatNumber
      param.snapchatUnit = snapchatUnit
      param.snapchatSwitch = Number(snapchatSwitch)
      // 阅后即焚 end
      const data = await setConfig(param)
      this.$message.success('设置成功')
      console.log(data)
    },
    // 获取薪资组
    async listGroup() {
      const { data } = await listGroup()
      this.salaryGroups = data
      if (!data || data.length === 0) return

      this.salaryItem = data[0] || {}
      this.getSalaryGroupAndItem(this.salaryItem)
    },
    // 点击预览
    selectProview(item) {
      this.salaryItem = item
      this.getSalaryGroupAndItem(item)
    },
    async getSalaryGroupAndItem({ groupId: payrollgroupid }) {
      const { data } = await getSalaryGroupAndItem({ payrollgroupid })
      this.salaryList = data || []
      console.log(data)
    },
    async getConfig() {
      const { data } = await getConfig()
      const {
        tipsSwitch,
        tipsContent,
        concealEmpty,
        passwordSwitch,
        enterSwitch,
        enterType,
        enterTime,
        snapchatSwitch,
        snapchatNumber,
        snapchatUnit
      } = data
      this.tipsSwitch = !!tipsSwitch
      this.tipsContent = tipsContent
      this.tipsContent = tipsContent
      const { dateNull, dateZero } = this.checkList[concealEmpty]
      this.dateNull = dateNull
      this.dateZero = dateZero
      this.passwordSwitch = !!passwordSwitch
      this.enterSwitch = !!enterSwitch
      this.enterType = enterType
      this.enterTime = enterTime
      this.snapchatSwitch = !!snapchatSwitch
      this.snapchatNumber = snapchatNumber
      this.snapchatUnit = snapchatUnit
    }
  }
}
</script>

<style scoped lang="less">
.right-menu-panel {
  position: relative;
}
.pay-slip-container {
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  padding-top: 40px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
  .pay-slip-main {
    display: flex;
  }
  .left-setting {
    width: 60%;
    padding-left: 20px;
    .pay-slip-common {
      display: flex;
      width: 100%;
      margin-bottom: 40px;
      .pay-slip-common-text {
        width: 100px;
        margin-right: 60px;
        flex-shrink: 0;
        color: @sc-grey-100;
        .font-size(14px);

      }
      .switch-icon {
        margin-bottom: 11px;
      }
      .slip-icon {
        margin-bottom: 16px;
      }

      .textarea-text {
        width: 346px;
        color: @sc-grey-100;
        .font-size(14px);
        .slip-check {
          margin-right: 12px;
        }
         .slip-destroy {
          margin-left: 12px;
        }
        .number-check {
          width: 95px;
          margin-right: 6px;
        }
        /deep/ .ant-select {
          width: 95px;
        }
        .radio-group {
          display: flex;
          align-items: center;
          /deep/ .ant-radio-wrapper:first-child {
            margin-right: 50px;
          }
          .group-text {
            color: @sc-grey-100;
            .font-size(14px);
          }
        }
        &::-webkit-input-placeholder {
          color: @sc-grey-40;
          .font-size(14px);
        }
      }
      /deep/.number {
        width: 90px;
        height: 32px;
        display: inline-block;
      }
      .checkbox{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0;
      }
    }
    .dataNull {
      margin: 40px 0;
      .dataNull-first {
        margin-right: 63px;
      }
      .dataNull-span {
          color: @sc-grey-100;
          .font-size(14px);
      }
    }
    .readAfter {
      margin-top: 40px;
    }
  }
  .rigth-phone {
    width: 40%;
    display: flex;
    justify-content: center;
  }
  .screen_btn {
    width: 100%;
    height: 55px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    border-top: 1px solid @sc-greyBg-20;
    /deep/ .ant-btn {
      width: 92px;
      border-radius: 3px;
    }
  }
}
.proview {
  padding:  0 0 30px;
}
.salary-groups {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .salary-item {
    width: calc(100% / 4);
    height:32px;
    color: @sc-grey-80;
    text-align: center;
    border: 1px solid @sc-greyBg-10;
    border-radius: 4px;
    margin-right: 13px;
    color: @sc-grey-80;
    background: @sc-greyBg-10;
    flex-shrink: 0;
    margin-bottom: 10px;
    cursor: pointer;
    .font-size(14px);
    line-height: 32px;
    &:hover {
      color: @sc-primary-100;
    }
    &.active {
      background: #fff;
      color: @sc-primary-100;
      border: 1px solid @sc-primary-100;
    }
  }
}
</style>
