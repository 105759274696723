<template>
  <div>
    <CbNumStatistics
      :is-tabs="true"
      :statistics-info="statisticsInfo"
      class="Statistics"
      @changeTab="changeTab"
    />
    <!--  添加 搜索 筛选  -->
    <appForm
      :to-list="toList"
      :screen-roster-list="screenRosterList"
      search-placeholder="搜索员工/工号"
      width="400"
      @getSearchCallback="getSearchCallback"
      @screenRosterParams="screenRosterParams"
      @exportFileList="exportFileList"
    >
      <template v-if="isNotDetail" slot="customButton">
        <a-space>
          <a-button v-if="selectType === 0 || selectType === 1" prefix="piliangdaoru" @click="salary">
            批量定薪
          </a-button>
          <a-button v-if="selectType === 0" prefix="piliangdaoru" @click="adjustment">
            批量调薪
          </a-button>
        </a-space>
      </template>
    </appForm>
    <!--  table  -->
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      row-key="onJobId"
      :offset-bottom="60"
      :pagination="{
        current: pager.pageNum,
        pageSize: pager.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条数据`,
        showQuickJumper: true,
        showSizeChanger: true
      }"
      @change="loadDataList"
    >
      <!-- 序号 -->
      <template slot="index" slot-scope="{ scope }">
        <span>{{ scope.index + 1 }}</span>
      </template>
      <!-- 头像 -->
      <template slot="staffName" slot-scope="{ scope }">
        <CbTableAvater
          :table-avater="scope.record.avatar"
          :table-name="scope.record.staffName"
          :on-job-id="scope.record.onJobId"
          :staff-id="scope.record.staffId"
        />
      </template>

      <!-- 操作列 -->
      <template slot="operation" slot-scope="{ scope }">
        <span class="different">
          <a-button v-if="scope.record.isAdjustment === 0" type="link" @click="fixedWage(scope.record)">定薪</a-button>
          <a-button v-if="scope.record.isAdjustment === 1" type="link" @click="changeWage(scope.record)">调薪</a-button>
          <a-button v-if="scope.record.isAdjustment === 1" type="link" @click="changeWageHistory(scope.record)">调薪历史</a-button>
        </span>
      </template>
    </custom-table>
    <!-- 调薪 -->
    <ChangeWage
      ref="ChangeWage"
      :info="selectTableItemInfo"
      @complate="updateList"
    />
    <!-- 调薪历史 -->
    <SalaryHistory ref="SalaryHistory" />
    <!-- 定薪 -->
    <FixedWage ref="FixedWage" :info="selectTableItemInfo" @complate="updateList" />
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import {
  getStaffFixedInfo,
  getFixedStaff,
  exportFixedStaff } from '@/services/wage/wagePayroll'
export default {
  components: {
    CbNumStatistics: () => import('@/components/CbNumStatistics/index.vue'),
    CbTableAvater: () => import('@/components/CbTableAvater/index.vue'),
    ChangeWage: () => import('./components/changeWage'),
    FixedWage: () => import('./components/fixedWage'),
    SalaryHistory: () => import('./components/salaryHistory')
  },
  mixins: [mixins],
  inject: ['salaryItemInfo', 'batchShow', 'batchHide', 'isNotDetail'],
  data() {
    return {
      selectType: 0,
      toList: ['search', 'screen', 'export'], // search搜索框 screen筛选 choice位置选择弹窗 export导出
      screenRosterList: {
        list: [
          {
            type: 'selectDictionaries',
            label: '聘用形式',
            options: {
              width: '100%',
              parameter: 'engage_mode',
              clearable: true,
              placeholder: '请选择职级'
            },
            model: 'engageMode',
            key: 'selectDictionaries_1651022813841'
          },
          {
            type: 'selectDepartment',
            label: '部门',
            options: {
              width: '100%',
              clearable: true,
              placeholder: '请选择'
            },
            model: 'orgId',
            key: 'selectDepartment_1651022903831'
          },
          {
            type: 'selectPost',
            label: '岗位',
            options: {
              width: '100%',
              clearable: true,
              placeholder: '请选择'
            },
            model: 'postId',
            key: 'selectPost_1651022948111'
          },
          {
            'type': 'selectDictionaries',
            'label': '合同公司',
            'options': {
              'width': '100%',
              'parameter': 'contract_company',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'contractCoId',
            'key': 'selectDictionaries_1650962628706'
          },
          {
            'type': 'selectDictionaries',
            'label': '工作地点',
            'options': {
              'width': '100%',
              'parameter': 'work_address',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'addressId',
            'key': 'selectDictionaries_1650962628701'
          },
          {
            'type': 'selectDictionaries',
            'label': '员工状态',
            'options': {
              'width': '100%',
              'parameter': 'staff_status',
              'clearable': true,
              'placeholder': '请选择'
            },
            'model': 'staffStatus',
            'key': 'selectDictionaries_1650962628702'
          }
        ],
        config: {
          layout: 'horizontal',
          labelCol: {
            xs: 4,
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
            xxl: 4
          },
          labelWidth: 100,
          labelLayout: 'flex',
          wrapperCol: {
            xs: 18,
            sm: 18,
            md: 18,
            lg: 18,
            xl: 18,
            xxl: 18
          },
          hideRequiredMark: false,
          customStyle: ''
        }
      },
      selectTableItemInfo: {},
      taxRosterList: {
        'list': [
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'selectDepartment',
            'key': 'selectDepartment_1651110886169'
          },
          {
            'type': 'selectPost',
            'label': '职位',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'selectPost',
            'key': 'selectPost_1651110888169'
          },
          {
            'type': 'selectDictionaries',
            'label': '员工状态',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'staff_status',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'staff_status',
            'key': 'selectDictionaries_1651110897449'
          },
          {
            'type': 'selectDictionaries',
            'label': '报税主体',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'parameter': 'contract_company',
              'clearable': true,
              'placeholder': '请选择',
              'showSearch': false
            },
            'model': 'contract_company',
            'key': 'selectDictionaries_1650962628706'
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      selectDataList: [
        {
          type: 0,
          label: '本月计薪人员',
          code: 'total',
          value: '',
          selected: true
        },
        {
          type: 1,
          label: '未定薪人员',
          code: 'notFixed',
          value: ''
        },
        {
          type: 2,
          label: '本月入职',
          code: 'currMonthEntry',
          value: ''
        },
        {
          type: 3,
          label: '本月转正',
          code: 'currMonthFormal',
          value: ''
        },
        {
          type: 4,
          label: '本月定薪',
          code: 'currMonthFixed',
          value: ''
        },
        {
          type: 5,
          label: '本月调薪',
          code: 'currMonthChange',
          value: ''
        }
      ],
      indexList: {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        width: 50,
        fixed: true,
        customRender: (text, record, index) => `${index + 1}`
      },
      columns: [],
      dataSource: [],
      statisticsInfo: {}
    }
  },
  computed: {
    // 表头字段名和对应展示名称的对应关系，定薪调薪要用
    columnsNameList() {
      const obj = {}
      this.columns.forEach(item => {
        obj[item.dataIndex] = item.title
      })
      return obj
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    changeTab(val) {
      this.initData()
      this.handleType(val.type)
    },
    async initData() {
      const res = await getStaffFixedInfo({ groupCalcId: this.salaryItemInfo.groupCalcId })
      this.selectDataList.forEach(item => {
        item.value = res.data[item.code]
      })
      this.statisticsInfo = {
        tabList: this.selectDataList
      }
    },
    async fetchDataApiFn(params) {
      params['groupCalcId'] = this.salaryItemInfo.groupCalcId
      params['inputValue'] = params['inputValue'] ? params['inputValue'] : ''
      params['type'] = this.selectType
      const res = await getFixedStaff(params)
      const columnObj = {
        'staffName': '105px',
        'staffNum': '140px'
      }
      this.columns = res.data.columns.map((item, index) => {
        return {
          title: item.fieldName,
          dataIndex: item.fieldCode,
          // width: `${item.fieldName.length * 30 + 20}px`,
          width: columnObj[item.fieldCode]
            ? columnObj[item.fieldCode] : `${item.fieldName.length * 30 + 20}px`,
          fixed: index < 3 ? 'left' : false,
          scopedSlots: item.fieldCode === 'staffName' ? { customRender: item.fieldCode } : '',
          ellipsis: true,
          dataType: item.dataType === 'money' ? 'money' : ''
        }
      })
      this.columns.unshift(this.indexList)
      if (this.isNotDetail) {
        this.columns.push({
          title: '操作',
          dataIndex: 'operation',
          fixed: 'right',
          width: '150px',
          scopedSlots: { customRender: 'operation' }
        })
      }
      this.columns.push({
        title: '',
        dataIndex: new Date().getTime()
      })
      this.dataSource = res.data.content ? res.data.content.records : []
      this.pager.pageNum = res.data.content.pageNum || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.content.pageSize || 10 // 更新当前的分页信息
      this.total = res.data.content.total || 0
    },
    // 搜索
    getSearchCallback(val) {
      this._queryParam.inputValue = val.searchValue
      this.serchGetData()
    },
    // 筛选
    screenRosterParams(data) {
      const { engageMode, orgId, postId, contractCoId, addressId, staffStatus } = data.screenCondition
      this._queryParam = {
        inputValue: data.searchValue,
        engageMode,
        orgId: orgId && orgId[0] && orgId[0].value,
        postId,
        contractCoId,
        addressId,
        staffStatus
      }
      this.serchGetData()
    },
    updateList() {
      this.initData()
      this.$$fetchListWithLoading(this._queryParam)
    },
    handleType(val) {
      this.selectType = val
      this.serchGetData()
    },
    // 调薪
    changeWage(info) {
      this.formateData(info)
      this.$refs.ChangeWage.show()
    },
    // 调薪历史
    changeWageHistory(info) {
      this.$refs.SalaryHistory.show(info.onJobId)
    },
    // 定薪
    fixedWage(info) {
      this.formateData(info)
      this.$refs.FixedWage.show()
    },
    // 格式化表格数据成可使用的数据
    formateData(info) {
      this.$set(this, 'selectTableItemInfo', info)
      this.$set(this.selectTableItemInfo, 'list', [])
      this.$set(this.selectTableItemInfo, 'groupCalcId', this.salaryItemInfo.groupCalcId)
      Object.entries(this.columnsNameList).forEach(item => {
        const key = item[0]
        let copyKey = item[0]
        const val = info[key]
        // 排除非固定字段和试用期的字段
        if (this.generateRule(key) && key.indexOf('syq') === -1) {
          // 试用期 gd zdy syq【兼容后台】别删
          if (key.indexOf('zdy') !== -1) {
            copyKey = key.replaceAll('zdy', 'ZDY')
          }
          if (key.indexOf('gd') !== -1) {
            copyKey = key.replaceAll('gd', 'GD')
          }
          this.selectTableItemInfo.list.push({
            probation: {
              name: this.columnsNameList[`syq${key}`],
              key: `SYQ${copyKey}`,
              val: info[`syq${key}`]
            },
            // 正式
            formal: {
              name: this.columnsNameList[key],
              key: copyKey,
              val
            }
          })
        }
      })
    },
    // 默认内置的基本工资项和自定义添加的固定工资项的生成规则
    generateRule(key) {
      // 默认内置的固定项工资
      if (key.indexOf('gd') > -1 || key.indexOf('syqgd') > -1) return true
      if (key.indexOf('zdy') > -1 || key.indexOf('syqzdy') > -1) return true
      return false
    },
    // 批量调整薪资
    adjustment() {
      this.batchShow('batchChangeSalary')
    },
    // 批量定薪
    salary() {
      this.batchShow('batchFixedSalary')
    },
    async exportFileList() {
      const params = JSON.parse(JSON.stringify(this._queryParam))
      params.pageNum = 1
      params.pageSize = -1
      await exportFixedStaff(params)
    }
  }
}
</script>
<style lang="less" scoped>
.different{
  display: flex;
  .ant-btn{
    padding-left: 0;
    padding-right: 16px;
    &:last-child{
      padding: 0;
    }
  }
}
.Statistics{
  margin-bottom: 20px;
}
/deep/.content-list li{
  position: relative;
  &:nth-child(-n+5)::after {
    content: '';
    position: absolute;
    right: 0;
    height: 30px;
    width: 1px;
    background-color: @sc-greyBg-20;
    top: 50%;
    transform: translateY(-50%);
  }
}
/deep/.link :first-child{
  margin-right: 6px;
}
</style>
