<template>
  <div>
    <div class="PreviewEmployeeInfo">
      <div class="PreviewEmployeeInfo-header flex-align-center">
        <div>
          <HeaderBack title="预览并开具证明" @back="$emit('back')" />
        </div>

        <div class="header-right">
          <a-space>
            <!--            <a-tooltip placement="bottom" title="微信发送给员工">-->
            <!--              <a-icon type="select" />-->
            <!--            </a-tooltip>-->
            <!--            <a-tooltip placement="bottom" title="导出">-->
            <!-- <a-icon v-if="detailsInfo.onlyRead" type="download" @click="exportTemplate" /> -->
            <!--            </a-tooltip>-->
            <!-- <a-button v-if="detailsInfo.onlyRead" type="primary" class="print-btn" @click="printTemplate"> 打印 </a-button> -->
            <a-button v-if="detailsInfo.onlyRead" type="primary" class="print-btn" @click="exportTemplate"> 下载 </a-button>
          </a-space>
          <a-button v-if="isEditTemp !== 'havePerson' && !detailsInfo.onlyRead" type="primary" class="confirm-btn" @click="confirmBtn">确认开具</a-button>
        </div>
      </div>
      <a-divider />
      <div class="PreviewEmployeeInfo-content">

        <div class="PreviewEmployeeInfo-content-left">
          <div v-if="!isEdit" class="temContent">
            <div class="v-title">
              <a-button v-if="isEditTemp != 'details' && showEdit" type="link" @click="editInfo">编辑</a-button>
              <div v-else class="empty_box" />
              <a-divider />
            </div>
            <div ref="printTemplate" class="printTemplate" v-html="content" />
          </div>
          <div v-if="isEdit">
            <div class="v-title-can">
              <a-button type="link" @click="handleCance">取消</a-button>
              <a-button type="link" @click="handleOk">保存</a-button>
            </div>
            <!--     @setups="setups"       -->
            <Tinymce-vue ref="tinymceVue" class="tinymce-vue" :value="content" :setting="setting" @input="(res) => (content = res)" />
          </div>
        </div>
        <div v-if="isEditTemp === 'havePerson'" class="PreviewEmployeeInfo-content-right">
          <SelectPerson :select-info="selectInfo" :temp-id="tempId" @getStaffId="getStaffId" @edit="edit" @getInfo="getInfo" @unShow="unShow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TinymceVue from '@/components/Tinymce'
import SelectPerson from './selectPerson.vue'
import { updateProveContent, getProveDetail, exportPdf, updateIssueStatus } from '@/services/user/certificate.js'
const tinymce = window.tinymce
export default {
  name: 'Preview',
  components: {
    TinymceVue,
    SelectPerson,
    HeaderBack: () => import ('@/components/headerBack')
  },
  // selectInfo是批量 detailsInfo是单个
  props: {
    selectInfo: {
      type: [Array, Object],
      default: () => {}
    },
    detailsInfo: {
      type: [Array, Object],
      default: () => {}
    },
    tempId: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      typeData: [],
      value: '',
      selectVal: '',
      visible: false,
      isEdit: false,
      checkPersonInfo: {},
      isEditTemp: 'edit',
      setting: {
        selector: 'textarea',
        cleanup: false,
        menubar: true,
        preview: true,
        toolbar:
          'code undo redo | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |',
        toolbar_drawer: 'sliding',
        quickbars_selection_toolbar: 'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
        plugins: 'code link preview image media table lists fullscreen ',
        language: 'zh_CN', // 本地化设置
        height: 700,
        forced_root_block: 'p', // 设置默认标签项
        auto_focus: true // 自动获取焦点
      },
      content: '',
      staffId: '',
      showEdit: true
    }
  },
  mounted() {
    this.initData()
    if (this.content === '') {
      this.$message.warning('请选择具体开具员工')
    }
  },
  methods: {
    initData() {
      // 默认显示第一个数据 富文本框的内容
      // 批量开具
      if (this.selectInfo.length > 0) {
        const insertText = this.selectInfo[0].proveInfo.proveContent
        this.staffId = this.selectInfo[0].staffId
        this.content = insertText
        tinymce.execCommand('mceReplaceContent', false, insertText)
        this.checkPersonInfo = this.selectInfo
        this.isEditTemp = 'havePerson'
      }
      // 编辑和详情
      if (this.detailsInfo.isEdit) {
        const insertText = this.detailsInfo.proveContent
        this.content = insertText
        tinymce.execCommand('mceReplaceContent', false, insertText)
        this.isEditTemp = this.detailsInfo.isEdit
      }
    },
    edit(e) {
      this.showEdit = e
      this.isEdit = e
    },
    editInfo() {
      this.isEdit = true
    },
    handleCance() {
      this.isEdit = false
    },
    async handleOk() {
      let per = {}
      if (this.detailsInfo.isEdit) {
        per = {
          proveFileId: this.detailsInfo.proveFileId,
          proveContent: this.content
        }
      } else {
        per = {
          proveFileId: this.checkPersonInfo[0].proveInfo.proveFileId,
          proveContent: this.content
        }
      }
      await updateProveContent(per).then((res) => {
        if (res.code === 100) {
          if (this.selectInfo.length > 0) {
            this.getStaffId(this.staffId)
          }
          if (this.detailsInfo.isEdit) {
            getProveDetail({ proveFileId: this.detailsInfo.proveFileId }).then((res) => {
              this.content = res.data.proveContent
            })
          }
          this.isEdit = false
        }
      })
    },
    // 导出
    exportTemplate() {
      if (this.isEdit) {
        const template = this.$refs.tinymceVue.getPrintTemplate()
        exportPdf({ content: template.content })
      } else {
        exportPdf({ content: this.content })
      }
    },
    // 打印
    printTemplate() {
      if (this.isEdit) {
        const template = this.$refs.tinymceVue.getPrintTemplate()
        template._data.editor.execCommand('print')
      } else {
        this.$print(this.$refs.printTemplate)
      }
    },
    async getStaffId(val) {
      if (val) {
        // 获取选择的人的信息
        const per = this.selectInfo.filter((item) => {
          return item.staffId === val
        })
        this.checkPersonInfo = per
        // 获取模板信息
        const res = await getProveDetail({
          proveFileId: per[0].proveInfo.proveFileId
        })
        if (res.code === 100) {
          this.content = res.data.proveContent
          this.selectInfo.forEach((item) => {
            if (item.staffId === per[0].staffId) {
              item.proveInfo.proveContent = res.data.proveContent
            }
          })
        }
      } else {
        this.content = ''
      }
    },
    async confirmBtn() {
      const res = await updateIssueStatus({
        'proveApplyId': this.detailsInfo.proveApplyId
      })
      if (res.code === 100) {
        this.$message.success('开具成功')
        this.unShow()
      } else {
        this.$message.warning(`${res.message}`)
      }
    },
    getInfo(e) {
      if (typeof e !== 'undefined') {
        this.checkPersonInfo = [e]
        this.staffId = e.staffId
        this.content = e.proveInfo.proveContent
      }
    },
    unShow() {
      this.$emit('back', true)
    }
  }
}
</script>

<style lang="less" scoped>
.PreviewEmployeeInfo {
  padding: 0;
  &-header {
    font-weight: bold;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    margin-top: -5px;
    /deep/ .header{
    margin-bottom: 0;
    }
  }
  &-content {
    display: flex;
    justify-content:center;
    padding: 0px 50px;
    &-left {
      width:810px;
    }
    &-right {
      width: 200px;
      border: 1px solid @sc-greyBg-20;
      margin-left: 16px;
      padding: 10px;
      color: @sc-grey-100;
      border-radius: 4px;
    }
  }
}
.header-right {
  display: flex;
  align-items: center;
  .confirm-btn{
    margin-left: 12px;
  }
}
.temContent {
  border: 1px solid @sc-greyBg-20;
  height: calc( 100vh - 170px );
  width:810px;
  margin: 0 auto;
  padding: 0px;
  font-size: 16px;
  border-radius: 4px;
}
.empty_box{
  height: 32px;
}
.v-title {
  text-align: right;
  .ant-btn.link{
    padding: 7px 10px;
  }
}
.v-title-can {
  text-align: right;
  border: 1px solid @sc-greyBg-50;
  border-bottom: none;
  .ant-btn.link{
    padding: 7px 10px;
  }
}
.printTemplate {
  padding:0 62px;
  color: @sc-grey-100;
  /deep/ h1 {
    color:  @sc-grey-100;
  }
}
/deep/ .ant-divider-horizontal {
  margin: 18px 0 28px;
  background: @sc-greyBg-20;
}
</style>
