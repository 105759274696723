import payCycle from './payCycle'
import salaryGroup from './salaryGroup'
import payslipTemplate from './payslipTemplate'
import taxDeclaration from './taxDeclaration'
import payAccount from './payAccount'
import infoSetting from './infoSetting'
import regionalClass from './regionalClass'

export default {
  payCycle,
  salaryGroup,
  payslipTemplate,
  taxDeclaration,
  payAccount,
  infoSetting,
  regionalClass
}
