<template>
  <div class="main">
    <div v-if="show" class="main-care">
      <a-tabs :default-active-key="keyVal" @change="callback">
        <a-tab-pane key="birthday" tab="生日">
          <Table v-if="keyVal==='birthday'" ref="birthday" :key-val="keyVal" @onShow="onShow" />
        </a-tab-pane>
        <a-tab-pane key="anniversary" tab="周年">
          <Table v-if="keyVal==='anniversary'" ref="anniversary" :key-val="keyVal" @onShow="onShow" />
        </a-tab-pane>
      </a-tabs>
    </div>
    <SettingBless v-if="!show" :care-type="keyVal" @back="back" />
  </div>

</template>
<script>
export default {
  name: 'Care',
  components: {
    Table: () => import('./components/Table'),
    SettingBless: () => import('./components/SettingBless')
  },
  data() {
    return {
      tab: '',
      keyVal: 'birthday',
      show: true // 海报页面显示
    }
  },
  methods: {
    back() {
      this.show = true
    },
    // tab栏选择
    callback(key) {
      this.keyVal = key
    },
    onShow(val) {
      this.show = val
    }
  }
}
</script>
<style lang="less" scoped>
.main{
  position: relative;
}
.main-care {
  background: #fff;
  padding: 20px 20px 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  /deep/.ant-tabs{
  height: 100%;
}
  .setting {
    color: @sc-grey-100;
    cursor: pointer;
    .font-size(16px);
    &:hover{
      color: @sc-primary-100;
    }
  }
}
/deep/.ant-tabs-top-bar{
  margin-bottom: 20px;
}
/deep/ .ant-tabs-nav {
  .ant-tabs-tab {
    color: @sc-grey-100;
    .font-size(16px);
    padding-top: 0;
    &:hover{
      color: @sc-primary-100;
    }
  }
  .ant-tabs-tab-active {
    color: @sc-primary-100;
  }
}
/deep/.flex-just-between{
  margin-bottom: 20px !important;
}

</style>
