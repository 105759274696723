<template>
  <div class="backs">
    <!--  表格  -->
    <div>
      <div class="addAddress">
        <a-button type="primary" prefix="add-one" @click="handleAdd">
          添加工作地点
        </a-button>
      </div>
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        row-key="addressId"
        table-ref=".backs"
        :pagination="{
          current: pager.pageNum,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条数据`,
          showQuickJumper: true,
          showSizeChanger: true
        }"
        @change="loadDataList"
      >
        <!-- 操作列 -->
        <template slot="operation" slot-scope="{ scope }">
          <a-popover placement="bottomLeft" trigger="hover">
            <template slot="content">
              <div class="more-handle-btn">
                <a-button type="link" @click="handleEdit(scope.record)">
                  编辑
                </a-button>
                <a-button type="link" class="delete" @click="handleDel(scope.record)"> 删除 </a-button>
              </div>
            </template>
            <div class="more-handle">···</div>
          </a-popover>
        </template>
      </custom-table>
    </div>
    <LocationForm ref="inductionClassForms" @getFetchDataApiFn="getFetchDataApiFn" />
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import LocationForm from './InductionForms.vue'
import { getList, deleteWorkAddress } from '@/services/globalSetting/locationOfWork.js'
export default {
  name: 'Location',
  components: {
    LocationForm
  },
  mixins: [mixins],
  props: {
  },
  data() {
    return {
      columns: [
        {
          title: '工作地点名称',
          dataIndex: 'addressName',
          scopedSlots: { customRender: 'addressName' },
          ellipsis: true
        },
        {
          title: '所在地区',
          dataIndex: 'areaName',
          scopedSlots: { customRender: 'areaName' },
          ellipsis: true
        },
        {
          title: '详细地址',
          dataIndex: 'detail',
          key: 'detail',
          ellipsis: true
        },
        {
          title: '描述',
          dataIndex: 'remarks',
          key: 'remarks',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '80px'
        }
      ],
      dataSource: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    // 获取表格数据
    async getData() {
      const res = await getList()
      this.dataSource = res.data
    },
    // 操作回调
    getFetchDataApiFn() {
      this.getData()
    },
    // 编辑
    handleEdit(record) {
      this.$refs.inductionClassForms.edit(record)
    },
    // 删除
    handleDel(record) {
      this.$Reconfirm({
        title: '删除',
        content: '是否确认删除？'
      }).then(async() => {
        const param = { addressId: record.addressId }
        const res = await deleteWorkAddress(param)
        if (res.code === 100) {
          this.$message.success('删除成功')
          this.getData()
        } else {
          this.$message.error('删除失败')
        }
      }).catch(() => {
        console.log('no')
      })
    },
    // 新增
    handleAdd() {
      this.$refs.inductionClassForms.open()
    }
  }
}
</script>

<style lang="less" scoped>
.addAddress{
  margin-bottom: 20px;
}
.backs{
  background-color: #fff;
  padding: 20px 20px 0;
  min-height: 100%;
}
.more-handle-btn {
  display: flex;
  flex-direction: column;
}
.more-handle {
  margin-left: 5px;
  font-weight: 700;
  color: @sc-primary-100;
  font-size: 20px;
}
</style>
