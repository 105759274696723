<template>
  <div>
    <Modal
      :visible="visible"
      :closable="true"
      :size="'normal'"
      :title="'预览入职登记表'"
      :mask-closable="false"
      :footer="null"
      @cancel="visible=false"
    >
      <div class="box">
        <img v-if="!isShow" :src="'data:image/jpeg;base64,' + url" alt="">
        <div>扫码预览入职登记表</div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getEntryFormPreviewQrCode } from '@/services/user/setting.js'
export default {
  name: 'RegistrationCode',
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      isShow: true,
      visible: false,
      url: ''
    }
  },
  methods: {
    show() {
      this.visible = true
      setTimeout(() => {
        this.creatQrCode()
      }, 100)
    },
    handleOk() {
      this.visible = false
    },
    async creatQrCode() {
      const res = await getEntryFormPreviewQrCode({ imgWidth: 400, imgHeight: 400 })
      if (res.code === 100) {
        this.isShow = false
        this.url = res.data
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .box {
    text-align: center;
    box-sizing: border-box;
    img {
      width: 200px;
      height: 200px;
      margin: 0 auto;
    }
    div {
      font-size: 18px;
      margin-top: 10px;
      color: @sc-grey-100;
    }
  }
</style>
