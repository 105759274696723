<template>
  <div class="salaryHistory">
    <Drawer
      title="调薪历史"
      placement="right"
      closable
      is-mask
      display-btn
      size="small"
      :visible="visible"
      @close="onClose"
    >
      <div class="content">
        <template>
          <a-timeline v-for="(item, index) in salaryList" :key="`changeItem${index}`">
            <a-timeline-item>
              <div :key="`changeItem${index}`" class="changeItem">
                <div class="label" :title="item.salaryItemName">
                  {{ item.salaryItemName }}
                </div>
                <div class="adjust">调整为</div>
                <div class="value">
                  {{ item.amount | stateFormatNum }}元
                </div>
              </div>
              <div class="date">生效日期：{{ item.startDate }}</div>
            </a-timeline-item>
          </a-timeline>
        </template>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { getStaffFixedSalaryHistory } from '@/services/wage/wageArchives'
import { stateFormatNum } from '@/utils/num-handle.js'
export default {
  name: 'SalaryHistory',
  filters: {
    stateFormatNum
  },
  components: {
    Drawer: () => import('@/components/CbDrawer')
  },
  data() {
    return {
      visible: false,
      salaryList: []
    }
  },
  methods: {
    show(onJobId) {
      this.$nextTick(async() => {
        const res = await getStaffFixedSalaryHistory({ onJobId })
        for (const key in res.data) {
          if (res.data[key]) {
            this.salaryList = this.salaryList.concat(res.data[key])
          }
        }
        this.visible = true
      })
    },
    onClose() {
      this.visible = false
      this.salaryList = []
    }
  }
}
</script>

<style lang="less" scoped>
.changeItem{
  display: flex;
  .font-size(14px);
  .label{
    width: 22%;
    margin-right: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: @sc-grey-100;
  }
  .value{
    flex: 1;
    color: @sc-grey-100;
  }
}
.date {
    .font-size(12px);
    color: @sc-grey-40;
    margin-top: 4px;
}
.adjust {
  margin-right: 34px;
  color: @sc-grey-80;
}
/deep/ .ant-timeline:last-child {
  .ant-timeline-item-tail {
    display: none;
  }
}
/deep/ .ant-timeline-item {
  margin-bottom: 10px;
}
/deep/ .ant-timeline-item-head {
  width: 14px;
  height: 14px;
}
/deep/ .ant-timeline-item-last > .ant-timeline-item-tail {
  display: block;
  height: calc(100% - 14px);
  top: 20px;
  left: 6px;
}
</style>
