<template>
  <div>
    <Modal
      :closable="true"
      :visible="visible"
      :title="(mode == 'add' ? '添加' : '编辑') + '工作地点'"
      ok-text="保存"
      size="normal"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="地点名称" prop="addressName">
          <a-input v-model="form.addressName" :max-length="30" placeholder="最多30个字符" />
        </a-form-model-item>
        <a-form-model-item label="所在地区" prop="area">
          <CbSelectArea
            ref="selectArea"
            v-model="form.area"
            :province-width="100"
            :city-width="100"
            :area-width="100"
            :auto-fill-in-area-value="false"
            @getStrandValue="onChange"
          />
        </a-form-model-item>
        <a-form-model-item label="详细地址" prop="detail">
          <a-input v-model="form.detail" :max-length="100" placeholder="最多100个字符" />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remarks">
          <a-textarea
            v-model="form.remarks"
            placeholder="最多300个字符"
            :max-length="300"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-model-item>
      </a-form-model>
    </Modal>
  </div>
</template>

<script>
import { createWorkAddress, updateWorkAddress } from '@/services/globalSetting/locationOfWork.js'
import CbSelectArea from '@/components/CbSelectArea/index'
export default {
  name: 'LocationForm',
  components: {
    CbSelectArea,
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      visible: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      form: {
        addressName: '',
        area: '',
        detail: '',
        remarks: ''
      },
      defaultForm: {},
      areaAdress: {},
      rules: {
        addressName: [
          {
            required: true,
            message: '请输入地点名称',
            trigger: 'change'
          }
        ],
        area: [
          {
            required: true,
            validator: this.areaValid,
            trigger: ['change', 'blur']
          }
        ]
      }
    }
  },
  computed: {
    mode() {
      // 根据id自动计算新增、编辑
      return this.form.addressId ? 'edit' : 'add'
    }
  },
  created() {
    this.defaultForm = JSON.parse(JSON.stringify(this.form))
  },
  methods: {
    // 添加
    open() {
      this.visible = true
      this.form = JSON.parse(JSON.stringify(this.defaultForm))
    },
    onChange(val, text) {
      this.form.area = val[2]
      this.areaAdress = { province: val[0], city: val[1], county: val[2] }
      if (val[2]) {
        this.$refs.ruleForm.clearValidate('area')
      }
    },
    // 编辑
    edit(data) {
      this.visible = true
      this.form = JSON.parse(JSON.stringify(data))
      if (this.form.areaCode && this.form.area !== '-1') {
        const areaList = this.form.areaCode.split(',')
        this.areaAdress = { province: areaList[0], city: areaList[1], county: areaList[2] }
      } else {
        this.areaAdress = { province: undefined, city: undefined, county: undefined }
      }
      this.$set(this.form, 'area', this.areaAdress)
      // this.$nextTick(() => {
      //   this.$refs['selectArea'].dataEcho(this.area)
      // })
    },
    areaValid(rule, value, callback) {
      if (!this.areaAdress.province && !value) {
        callback(new Error('请选择所在地区'))
      } else if (this.areaAdress.province && !value) {
        callback(new Error('请选择正确的地址'))
      } else {
        callback()
      }
    },
    // 保存事件
    handleOk() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          if (this.mode === 'add') {
            // 新增
            const { addressName, area, detail, remarks } = this.form
            const param = {
              addressName,
              country: '',
              area,
              detail,
              addressType: 0,
              remarks
            }
            const res = await createWorkAddress(param)
            if (res.code === 100 && res.data) {
              this.$message.success('新增地点成功')
              this.$emit('getFetchDataApiFn')
              this.handleCancel()
            }
          } else {
            // 编辑
            const { addressId, addressName, area, detail, remarks } = this.form
            const param = {
              addressId,
              addressName,
              country: '',
              area,
              detail,
              addressType: 0,
              remarks
            }
            const res = await updateWorkAddress(param)
            if (res.code === 100 && res.data) {
              this.$message.success('编辑成功')
              this.$emit('getFetchDataApiFn')
              this.handleCancel()
            }
          }
        } else {
          this.$message.error('请填写完整信息')
        }
      })
    },
    // 取消事件
    handleCancel() {
      this.visible = false
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
        this.$refs.selectArea.closeClearValue()
      })
      this.areaAdress = { province: undefined, city: undefined, county: undefined }
    }
  }
}
</script>

<style lang="less" scoped>
</style>
