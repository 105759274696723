<template>
  <div>
    <BlockTitle title="上下班时间设置" :is-bold="true" class="msg-title" />
    <div v-for="(item,index) in form.shiftPunchRuleList" :key="index" class="time-card">
      <div v-if="form.shiftType==='A2'" class="time-title flex-just-between">
        <span>{{ `第 ${index+1}次上下班` }}</span>
        <svg v-if="index" class="iconpark-icon deleteBtn" @click="del(index)"><use href="#delete-one" /></svg>
        <!-- <a-icon v-if="index" type="delete" style="color:#8F93A1" @click="del(index)" /> -->
      </div>
      <div class="time-content">
        <a-row>
          <a-col :span="12">
            <span class="title">上下班时段</span>
            <span class="rule">企业规定的上班时间、下班时间</span>
          </a-col>
          <a-col :span="12">
            <span class="title">有效打卡时段</span>
            <span class="rule">用于判断员工的打卡是否有效，如凌晨2点打卡是否有效</span>
          </a-col>
        </a-row>
        <a-row class="time-box">
          <a-col :span="12">
            <a-form-model-item label="上班时间" class="timepicker">
              <CbTimePicker v-model="item.startTime" default-value="00:00" :end-disabled="item.breakStartTime" :options="timeSetUp" @change="change" />
            </a-form-model-item>
            <a-form-model-item v-if="form.shiftType==='A1'" label="休息时段">
              <CbTimePicker v-model="item.breakStartTime" :parent-disabled="!item.startTime" :default-value="item.startTime" :start-disabled="item.startTime" :end-disabled="item.endTime" :options="timeSetUp" @change="change" />
              <a-icon class="swap-right" type="swap-right" />
              <CbTimePicker v-model="item.breakEndTime" :parent-disabled="!item.breakStartTime" :start-disabled="item.breakStartTime" :end-disabled="item.endTime" :options="timeSetUp" @change="change" />
            </a-form-model-item>
            <a-form-model-item label="下班时间" class="timepicker">
              <CbTimePicker v-model="item.endTime" :parent-disabled="form.shiftType==='A1'?!item.breakEndTime:!item.startTime" :options="timeSetUp" :start-disabled="form.shiftType==='A1'?item.breakEndTime:item.startTime" @change="change" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="上班打卡时段">
              <CbTimePicker v-model="item.punchInStartTime" :parent-disabled="!item.endTime" :end-disabled="item.endTime" :options="timeSetUp" />
              <a-icon class="swap-right" type="swap-right" />
              <CbTimePicker v-model="item.punchInEndTime" :parent-disabled="!item.punchInStartTime" :start-disabled="item.punchInStartTime" :end-disabled="form.shiftType==='A1'?item.breakEndTime:item.endTime" :options="timeSetUp" />
            </a-form-model-item>
            <a-form-model-item label="下班打卡时段">
              <CbTimePicker v-model="item.punchOutStartTime" :parent-disabled="!item.punchInEndTime" :start-disabled="form.shiftType==='A1'?item.breakStartTime:item.punchInEndTime" :options="timeSetUp" />
              <a-icon class="swap-right" type="swap-right" />
              <CbTimePicker v-model="item.punchOutEndTime" :parent-disabled="!item.punchOutStartTime" :start-disabled="item.punchOutStartTime" :options="timeSetUp" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <div class="time-bottom">
          <a-form-model-item label="未打卡为">
            <a-radio-group v-model="item.invalidPunchHandleType">
              <a-radio value="1">缺卡</a-radio>
              <a-radio value="2">旷工</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </div>
      </div>
    </div>
    <div class="time-add">
      <a-button v-if="form.shiftType === 'A2'" type="link" prefix="add-one" @click="form.shiftPunchRuleList.push({invalidPunchHandleType:'1',breakFlag:false})">
        添加上下班时间
      </a-button>
      <p v-if="form.shiftHours">合计工作时长：<span>{{ form.shiftHours }}</span>小时</p>
    </div>
  </div>
</template>

<script>
import { getComputingWorkTime } from '@/services/attendance/shift.js'
export default {
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index'),
    CbTimePicker: () => import('@/components/CbTimePicker/index')
  },
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      timeSetUp: {
        format: 'HH:mm',
        valueFormat: 'HH:mm',
        placeholder: '时间',
        clearable: true
      }
    }
  },
  mounted() {

  },
  methods: {
    del(index) {
      this.form.shiftPunchRuleList.splice(index, 1)
      this.getTimes()
    },
    change() {
      this.$nextTick(() => {
        const { shiftType } = this.form
        const { startTime, breakStartTime, breakEndTime, endTime } = this.form.shiftPunchRuleList[this.form.shiftPunchRuleList.length - 1]
        if (shiftType === 'A1' && startTime && breakStartTime && breakEndTime && endTime) {
        // 早晚打卡
          this.getTimes()
        } else if (shiftType === 'A2' && startTime && endTime) {
        // 分段打卡
          this.getTimes()
        } else {
          this.form.shiftHours = ''
          this.$forceUpdate()
        }
      })
    },
    // 获取时间
    async getTimes() {
      if (this.form.shiftType === 'A2') {
        this.form.shiftPunchRuleList[0].breakFlag = false
      } else {
        this.form.shiftPunchRuleList[0].breakFlag = true
      }
      const res = await getComputingWorkTime({ shiftPunchRuleList: this.form.shiftPunchRuleList, dayStartTime: '00:00' })
      this.form.shiftHours = res.data.shiftHours
      this.$forceUpdate()
    },
    clearData() {
    },
    minutes(time) {
      if (time) {
        const hour = time.split(':')[0]
        const minute = parseInt(time.split(':')[1])
        return hour * 60 + minute
      }
    }
  }
}
</script>

<style lang="less" scoped>
.msg-title {
  margin-bottom: 14px;
  margin-top: 2px;
}
.time-card{
    background: @sc-greyBg-10;
    border-radius: 4px;
    width: 100%;
    border: 1px solid @sc-grey-10;
    margin-bottom: 16px;
  .time-title{
    height: 46px;
    .font-size(14px);
    font-weight: 600;
    padding: 12px 18px;
    color:@sc-grey-100;
    border-bottom: 1px solid @sc-grey-10;
    align-items: center;
    .deleteBtn {
      width: 16px;
      height: 16px;
      color: @sc-greyBg-100;
      cursor: pointer;
    }
  }
  .time-content{
    padding:17px 17px 0 17px;
    border-bottom: 1px solid @sc-grey-10;
    .time-box{
      border-bottom: 1px solid @sc-grey-10;
    }
    .title{
      .font-size(14px);
      font-weight: 600;
      color:@sc-grey-100;
      margin-right: 9px;
    }
    .rule {
      color: @sc-grey-80;
    }
    .ant-form-item {
      height: 52px !important;
      margin-bottom:0;
      display: flex !important;
      align-items: center;
    }
    .ant-form-label {
      padding:0;
      margin:0;
    }
    /deep/.ant-form-item-label > label::after{
      margin-right: 36px;
    }
    /deep/.ant-time-picker{
        width:80px !important;
      }
    .timepicker{
      /deep/.ant-time-picker{
        width:220px !important;
      }
    }
    .swap-right{
      margin: 0 18px;
      width:20px;
      color: @sc-grey-60;
    }
  }
}
/deep/ .ant-time-picker-input {
  padding: 4px 12px;
}
/deep/ span.ant-radio + * {
  color: @sc-grey-80;
}
</style>
<style>
.ant-time-picker-panel-select::-webkit-scrollbar {
    display:none ;
}
</style>
