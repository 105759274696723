<template>
  <div class="PayAccount">
    <div>
      <appForm>
        <template slot="customButton">
          <div class="custom-button">
            <a-button prefix="piliangdaoru" @click="addGroup">
              新增报税主体
            </a-button>
          </div>
        </template>
      </appForm>
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        row-key="index"
        :pagination="false"
        :scroll="{ x:1220 }"
        @change="loadDataList"
      >
        <!-- 操作列 -->
        <template slot="operation" slot-scope="{ scope }">
          <span class="operation-btn-box">
            <a-button type="link" @click="edit(scope.record)">
              编辑
            </a-button>
            <a-button type="link" class="delete" @click="delet(scope.record)">
              删除
            </a-button>
          </span>
          <!-- <a-popover
            placement="bottom"
            trigger="hover"
          >
            <template slot="content">
              <div class="more-handle-btn">
                <a-button type="link" @click="edit(scope.record)">
                  编辑
                </a-button>
                <a-button type="link" danger @click="delet(scope.record)">
                  删除
                </a-button>
              </div>
            </template>
            <div class="more-handle">
              ···
            </div>
          </a-popover> -->
        </template>
      </custom-table>
    </div>
    <TaxDrawer ref="taxDrawer" :drawer-type="drawerType" />
  </div>
</template>

<script>
import { getEndyearBounsList, deleteTaxpaySubject, getTaxpayPeriodList } from '@/services/wage/account.js'
import CustomTable from '@/components/CustomTable/index.js'
import { debounce } from '@/utils/index.js'
export default {
  name: 'PayAccount',
  components: {
    TaxDrawer: () => import('./taxDrawer/index.vue')
  },
  mixins: [CustomTable],
  data() {
    return {
      drawerType: 'add',
      taxpaySubjectId: '',
      dataSource: [
      ],
      columns: [{
        title: '公司名称',
        dataIndex: 'companyName',
        key: 'companyName',
        scopedSlots: { customRender: 'companyName' },
        width: '240px',
        ellipsis: true
      },
      {
        title: '税号',
        dataIndex: 'taxNum',
        key: 'taxNum',
        width: '260px',
        ellipsis: true
      },
      {
        title: '所属地区',
        dataIndex: 'areaName',
        key: 'areaName',
        width: '220px',
        ellipsis: true
      },
      {
        title: '报税周期',
        dataIndex: 'taxFilingPeriodText',
        key: 'taxFilingPeriodText',
        width: '100px',
        ellipsis: true
      },
      {
        title: '报税人员',
        dataIndex: 'showNameList',
        key: 'showNameList',
        width: '300px',
        ellipsis: true
      },
      {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
        width: '100px',
        fixed: 'right'
      }],
      taxCycle: []
    }
  },
  mounted() {
    this.initCycle()
  },
  methods: {
    // 请求数据
    async fetchDataApiFn(_queryParam) {
      const res = await getEndyearBounsList({ ..._queryParam })
      if (res.code === 100 && res.data) {
        this.dataSource = res.data.map((item, index) => {
          return {
            ...item,
            index: index,
            taxFilingPeriodText: item.taxFilingPeriodType === '1' ? '当月报税' : '次月报税',
            showNameList: item.staffNameList?.join('、')
          }
        })
      }
      if (!res.data) {
        this.dataSource = []
      }
    },
    // 新增账号
    addGroup() {
      this.drawerType = 'add'
      this.$refs.taxDrawer.open()
    },
    delet(record) {
      this.taxpaySubjectId = record.taxpaySubjectId
      this.$Reconfirm({
        title: '删除报税主体',
        content: '是否确定删除该报税主体？'
      }).then(() => {
        this.handleDel()
      }).catch(() => {
      })
    },
    handleDel: debounce(async function() {
      const res = await deleteTaxpaySubject({ taxpaySubjectId: this.taxpaySubjectId })
      if (res.code === 100) {
        this.$message.success('删除成功！')
        this.serchGetData()
      }
    }, 500),
    edit(scope) {
      this.drawerType = 'edit'
      this.$refs.taxDrawer.open()
      this.$refs.taxDrawer.editFill(scope)
    },
    initCycle() {
      getTaxpayPeriodList().then((res) => {
        this.taxCycle = res.data
      })
    }
  }
}
</script>
<style lang="less" scoped>
.PayAccount {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.more-handle-btn {
    display: flex;
    flex-direction: column;
    .ant-btn {
      height:25px;
    }
  }
.more-handle {
  width:20px;
  font-weight: 700;
  line-height: 44px;
  font-size: 20px;
  color: @sc-primary-100;
  vertical-align: baseline;
  cursor: pointer;
}
.operation-btn-box{
  display:flex;
  button{
    padding-left:0;
    padding-right:16px;
  }
}
</style>
