<!--
 * @Date: 2022-04-26 16:00:41
 * @descriotion:
-->
<template>
  <div class="add-content">
    <header class="page-header">
      <div class="cancle" @click="cancelPrev()">
        <a-button type="iconBtn" icon="left" />
        <span class="title">{{ type==='edit'?'编辑':type==='copy'?'复制':'新增' }}考勤组</span>
      </div>
      <div class="steps-box">
        <a-steps :current="current" size="small" type="navigation" @change="stepChange">
          <a-step title="基本信息" :status="current === 0?'process':infoflag?'finish':'wait'" />
          <a-step title="排班日历" :status="current === 1?'process':sehedflag?'finish':'wait'" />
          <a-step title="考勤规则" :status="current === 2?'process':ruleflag?'finish':'wait'" />
          <a-step title="打卡设置" :status="current === 3?'process':setflag?'finish':'wait'" />
        </a-steps>
      </div>
      <!-- <div class="btns">
        <a-button type="primary" @click="add">提交</a-button>
      </div> -->
    </header>
    <div class="solt-content">
      <div class="steps-content Jcommon-layout-main Jflex-main">
        <BasicInformation v-show="current === 0" ref="BasicInformation" :type="type" :data="form" @changeType="changeType" @getData="basicInformation" @resetData="resetData" />
        <ScheduleCalendar v-show="current === 1" ref="ScheduleCalendar" :data="form" @getData="scheduleCalendar" @resetData="resetData" />
        <Attendancerules v-show="current === 2" ref="Attendancerules" :data="form" @getData="attendancerules" @resetData="resetData" />
        <ClockSetting v-show="current === 3" ref="ClockSetting" :type="type" :formdata="form" @getData="clockSetting" />
      </div>
      <div class="steps-action">
        <a-button v-if="current > 0" style="margin-left: 12px" @click="prev">
          上一步
        </a-button>
        <a-button v-if="current!==3" type="primary" @click="next">
          下一步
        </a-button>
        <a-button
          v-if="current == 3"
          type="primary"
          @click="add"
        >
          保存
        </a-button>
        <a-tooltip v-if="current == 3&&type==='edit'" placement="topRight" trigger="hover">
          <template slot="title">
            <div>修改点将于明日生效</div>
          </template>
          <a-icon type="exclamation-circle" class="exclamation-circle" theme="filled" />
        </a-tooltip>
      </div>
    </div>

  </div>
</template>
<script>
import common from './index.js'
import { addAttendanceGroupApi, getAttendanceGroupApi, editAttendanceGroupApi } from '@/services/attendance/settingset.js'
export default {
  name: 'AddAttendance',
  components: { ...common },
  props: {
    type: {
      type: String,
      default: ''
    },
    attendanceGroupId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      steps: [
        { label: '基本信息', key: 'basicSetting' },
        { label: '排班日历', key: 'formDesign' },
        { label: '考勤规则', key: 'processDesign' },
        { label: '打卡设置', key: 'AdSetting' }
      ],
      current: 0,
      infoflag: false,
      sehedflag: false,
      ruleflag: false,
      setflag: false,
      cancelShow: false,
      stepShow: false,
      saveCurrent: undefined,
      form: {
        attendanceGroupName: '', // 考勤组名称
        attendanceGroupStaff: [], // 适用人员
        attendanceGroupType: '2', // 考勤组类型
        laborTimeType: '1', // 工时制类型
        laborTimeCycle: undefined, // 工时核算周期
        earlyLateDeductFlag: 2, // 日工时核算规则
        standardHours: '', // 考勤标准时长
        shiftSchedulingId: undefined, // 排班日历id
        overTimeRuleId: undefined,
        outSideRuleId: undefined,
        appendPunchRuleId: undefined,
        entryAutoPunchFlag: 0,
        leaveAutoPunchFlag: 0,
        punchRuleType: '1',
        allowOutSidePunchFlag: 0,
        outSideUploadPhotoFlag: 0,
        outSideTakePhotoFlag: 0,
        outSideLimitLocationFlag: 0,
        attendanceGroupClockLocation: [],
        attendanceGroupClockWifi: []
      }
    }
  },
  mounted() {
    if (this.type) {
      this.getAttendanceGroupDetail()
    }
  },
  methods: {
    next() {
      const num = this.current + 1
      this.stepChange(num)
    },
    prev() {
      const num = this.current - 1
      this.stepChange(num)
    },
    async getAttendanceGroupDetail() {
      const res = await getAttendanceGroupApi({ attendanceGroupId: this.attendanceGroupId })
      this.form = { ...res.data, standardHours: Number(res.data.standardHours) }
      if (this.type === 'copy') {
        this.form.attendanceGroupName = ''
        this.form.attendanceGroupStaff = []
      }
    },
    // handleOk() {
    //   this.$emit('cancle')
    // },
    cancelPrev() {
      // this.cancelShow = true
      this.$Reconfirm({
        title: '确定要离开吗',
        content: '退出页面则无法保存所输入的信息，建议您先去保存，您确认要立即退出吗？'
      }).then(() => {
        this.$emit('cancle')
      }).catch(() => {
        console.log('no')
      })
    },
    changeType(value) {
      this.form.attendanceGroupType = value
    },
    stepChange(current) {
      this.saveCurrent = current
      if (this.current === 0) {
        const flag = this.$refs.BasicInformation.next()
        if (flag) { this.current = current }
      } else if (this.current === 1) {
        const flag = this.$refs.ScheduleCalendar.next()
        console.log(flag)
        if (flag) { this.current = current }
      } else if (this.current === 2) {
        const flag = this.$refs.Attendancerules.next()
        if (flag) { this.current = current }
      } else if (this.current === 3) {
        const flag = this.$refs.ClockSetting.next()
        if (flag) { this.current = current }
      }
      if (current === 1) {
        this.$refs.ScheduleCalendar.handleChange()
      }
    },
    basicInformation(value) {
      this.form = { ...this.form, ...value }
      this.infoflag = true
    },
    scheduleCalendar(value) {
      this.form = { ...this.form, ...value }
      this.sehedflag = true
    },
    attendancerules(value) {
      this.form = { ...this.form, ...value }
      this.ruleflag = true
    },
    clockSetting(value) {
      this.form = { ...this.form, ...value }
      this.setflag = true
    },
    resetData(value) {
      this.form = { ...this.form, ...value }
    },
    async add() {
      if (this.current === 0) {
        const flag = await this.$refs.BasicInformation.next()
        if (!flag) { return }
      }
      if (this.current === 1) {
        const flag = await this.$refs.ScheduleCalendar.next()
        if (!flag) { return }
      }
      if (this.current === 2) {
        const flag = await this.$refs.Attendancerules.next()
        if (!flag) { return }
      }
      const flag = await this.$refs.ClockSetting.next()
      if (!flag) { return }
      if (this.type === 'edit') {
        const res = await editAttendanceGroupApi({ ...this.form, attendanceGroupId: this.attendanceGroupId })
        if (res.code === 100) {
          this.$message.success('编辑成功')
        }
      } else {
        if (!this.infoflag) {
          this.$message.info('请先完善基本信息')
          return
        }
        if (!this.sehedflag) {
          this.$message.info('请先完善排班日历')
          return
        }
        if (!this.ruleflag) {
          this.$message.info('请先完善考勤规则')
          return
        }
        const res = await addAttendanceGroupApi({ ...this.form, clockLocation: undefined, clockWifi: undefined, remark: undefined })
        if (res.code === 100) {
          this.$message.success('新增成功')
        }
      }
      this.$emit('cancle')
    }
  }
}
</script>
<style lang="less" scoped>
.exclamation-circle{
  margin-left: 12px;
  color: @sc-primary-100;
}
.add-content{
  width:100%;
  height:100%;
  overflow: hidden;
}
.cancle {
  display: flex;
  align-items: center;
  .iconBtn{
    padding: 1px;
    width: 20px;
    height: 20px;
  }
}
.cancle > .title{
  font-size:14px;
  margin-left:6px;
  color: @sc-grey-100;
}
.page-header{
    width: 100%;
    height: 56px;
    padding:16px 20px;
    border-bottom:2px solid @sc-greyBg-20;
    position:relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cancle{
      position: absolute;
    }
    .steps-box{
      flex:1;
      display: flex;
      justify-content: center;
      .ant-steps{
        width:60%;
      }
    }
}
.solt-content{
  height:calc(100% - 50px);
  width:993px;
  margin: 0 auto;
  border-left:2px solid @sc-greyBg-20;
  border-right:2px solid @sc-greyBg-20;
  .steps-content{
    width:993px;
    height:calc(100% - 70px);
    margin: 0 auto;
    box-sizing: border-box;
  }
  .steps-action{
    display: flex;
    height:70px;
    justify-content: center;
    align-items: center;
    .ant-btn {
      margin:0 16px;
    }
  }
}
.model-title{
  display: flex;
  align-items: center;
  font-size:16px;
  font-weight: 600;
  color:  @sc-grey-100;
  margin-bottom:14px;
  span{
    margin-left:7px;
  }
  i.anticon.anticon-exclamation-circle {
    color: @sc-primary-100;
  }
}
.model-text{
  font-size: 14px;
  color:  @sc-grey-100;
  .tips{
    color:#ED7B2F
  }
}
/deep/ .ant-steps-navigation .ant-steps-item::before {
  bottom: -4px;
}
</style>

