<template>
  <div class="record-table">
    <div v-if="!dragValue">
      <div class="time">
        <svg class="iconpark-icon"><use href="#rongqi" /></svg>
        <a-month-picker v-model="recordTime" :allow-clear="clear" value-format="YYYY-MM" class="picker" @change="onChange">
          <span class="weekBox">{{ startDate }} ~ {{ endDate }}</span>
        </a-month-picker>
      </div>
      <div class="record-table-heard">
        <div class="record-table-heard-left">
          <appForm>
            <template slot="customButton">
              <a-dropdown type="primary">
                <template #overlay>
                  <a-menu>
                    <a-menu-item v-for="(item, index) in functionList" :key="index" @click="add">{{ item.name }}</a-menu-item>
                  </a-menu>
                </template>
                <a-button prefix="add-one" suffix="left">
                  添加请假记录
                </a-button>
              </a-dropdown>
            </template>
          </appForm>
          <a-button class="deleteBtn" prefix="reduce-one" :disabled="selectedRowKeys.length===0?true:false" @click="openDeleteBox">
            批量删除
          </a-button>
        </div>
        <appForm
          ref="appForm"
          :to-list="formList"
          class="btn"
          search-placeholder="请搜索姓名/工号"
          :screen-roster-list="screenRecordList"
          @getSearchCallback="getSearchCallback"
          @screenRosterParams="screenRosterParams"
          @exportFileList="exportFileList"
        />
        <!-- @refreshTableList="refreshTableList" -->
      </div>
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        row-key="vacationApplyId"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          columnWidth: 30,
          onChange: onSelectChange,
          getCheckboxProps: (record) => ({
            props: {
              disabled: record.operateType === '1',
            },
          }),
        }"
        :selection-mode="selectionMode"
        :pagination="{
          current: pager.pageNum,
          pageSize: pager.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条数据`,
          showQuickJumper: true,
          showSizeChanger: true
        }"
        @change="loadDataList"
      >
        <!-- 序号 -->
        <template slot="index" slot-scope="{ scope }">
          <span>{{ scope.index + 1 }}</span>
        </template>
        <!-- 数据来源 -->
        <template slot="operateType" slot-scope="{scope}">
          <div :title="getCodeContent(scope.record.operateType,operate_type)">{{ getCodeContent(scope.record.operateType,operate_type) }}</div>
        </template>
        <template slot="staffName" slot-scope="{ scope }">
          <CbTableAvater :table-name="scope.record.staffName" :on-job-id="scope.record.onJobId" :staff-id="scope.record.staffId" :title="scope.record.staffName" />
        </template>
        <!-- 操作列 -->
        <template slot="operation" slot-scope="{scope}">
          <div class="flex-align-center">
            <a-button type="link" :disabled="scope.record.operateType=='1'" @click="edit(scope.record)">编辑</a-button>
          </div>
        </template>
      </custom-table>
      <Modal
        closable
        overflow-auto
        :z-index="10"
        :visible="addRecord"
        :title="drawerTitle"
        size="normal"
        @after-close="afterVisibleChange"
        @cancel="onClose"
        @ok="onSubmin"
      >
        <p class="tips">特别说明：如请假时间有跨月，请按月进行拆分</p>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item ref="onJobId" label="员工姓名" prop="onJobId">
            <PerTreeSelect v-model="form.onJobId" :disabled="drawerTitle !== '添加请假记录'" placeholder="请选择员工姓名" @getPerTreeValue="getPerTreeValue" />
          </a-form-model-item>
          <a-form-model-item ref="vacationTypeId" label="假期类型" prop="vacationTypeId">
            <a-select v-model="form.vacationTypeId" placeholder="请选择假期类型" @change="getVacationType">
              <a-select-option v-for="item in rankData" :key="item.vacationTypeId" :value="item.vacationTypeId">
                {{ item.vacationTypeName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="startDate" label="开始时间" prop="startDate">
            <div class="add-time">
              <a-date-picker v-model="form.startDate" :disabled-date="disabledStartDate" value-format="YYYY-MM-DD" :style="`width:${timeUnit === 'D'?'100%':'60%'}`" @change="timeChange" />
              <a-time-picker v-if="timeUnit === 'H'" v-model="form.startTime" style="width: 35%" format="HH:mm" value-format="HH:mm" @change="timeChange" />
              <a-select v-if="timeUnit === 'B'" v-model="form.startTime" placeholder="请选择时间" style="width: 35%" @change="timeChange">
                <a-select-option v-for="item in halfDay_type" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-form-model-item>
          <a-form-model-item ref="endDate" label="结束时间" prop="endDate">
            <div class="add-time">
              <a-date-picker v-model="form.endDate" :disabled-date="disabledEndDate" value-format="YYYY-MM-DD" :style="`width:${timeUnit === 'D'?'100%':'60%'}`" @change="timeChange" />
              <a-time-picker v-if="timeUnit === 'H'" v-model="form.endTime" style="width: 35%" format="HH:mm" value-format="HH:mm" @change="timeChange" />
              <a-select v-if="timeUnit === 'B'" v-model="form.endTime" placeholder="请选择时间" style="width: 35%" @change="timeChange">
                <a-select-option v-for="item in halfDay_type" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-form-model-item>
          <a-form-model-item ref="shift" label="当前班次">
            {{ formText.shiftList | shiftName }}
          </a-form-model-item>
          <a-form-model-item ref="time" label="时长">
            <p>{{ formText.interval }}</p>
          </a-form-model-item>
          <a-form-model-item ref="applyReason" label="请假原因" prop="applyReason">
            <a-textarea
              v-model="form.applyReason"
              placeholder="请输入请假原因"
              :max-length="300"
              :auto-size="{ minRows: 4, maxRows: 6 }"
            />
          </a-form-model-item>
        </a-form-model>
      </Modal>
    </div>
    <ExcelImport v-else :title="importTitle" import-template="vacation" @toggleValue="toggleValue" />
  </div>
</template>
<script>
import { sepcialCode } from '@/utils/reg.js'
import mixins from '@/components/CustomTable/index.js'
import moment from 'moment'
import PerTreeSelect from '@/components/CbPerDepSelection/perTreeSelect/index'
import { exportApplyVacation, getapplyVacationPageList, addApplyVacationCreate, delApplyVacation, getApplyVacationCalc, editApplyVacationUpdate, applyVacationDetailApi } from '@/services/attendance/attendance.js'
import { getVacationType, getVacationUnitApi } from '@/services/attendance/holidays.js'
import { getStaffInfoApi } from '@/services/user/roster.js'
import { halfDay_type } from '@/pages/attendance/dict.js'
export default {
  name: 'Leave',
  components: {
    Modal: () => import('@/components/CbModal/index'),
    CbTableAvater: () => import('@/components/CbTableAvater/index'),
    ExcelImport: () => import('@/pages/attendance/components/excelImport.vue'),
    PerTreeSelect
  },
  filters: {
    shiftName(val) {
      const arr = []
      try {
        val.map(item => {
          arr.push(item.shiftShortName)
        })
        return arr.join('，')
      } catch (error) {
        return '--'
      }
    }
  },
  mixins: [mixins],
  data() {
    return {
      halfDay_type: halfDay_type,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      timeUnit: 'H',
      importTitle: '批量导入请假记录',
      dragValue: false,
      clear: false,
      rankData: [],
      form: {
        staffId: '',
        onJobId: undefined,
        vacationTypeId: undefined,
        startDate: '',
        startTime: undefined,
        endDate: '',
        endTime: undefined,
        applyReason: ''
      },
      formText: {
        interval: '--',
        shiftList: '--'
      },
      rules: {
        onJobId: [{ required: true, message: '请选择员工姓名', trigger: 'change' }],
        vacationTypeId: [{ required: true, message: '请选择假期类型', trigger: 'change' }],
        startDate: [{ required: true, message: '请选择开始时间', trigger: 'change' }],
        endDate: [{ required: true, message: '请选择结束时间', trigger: 'change' }],
        applyReason: [{ min: 1, max: 300, message: '请假原因支持1~300个文字', trigger: 'change' }, { pattern: sepcialCode, message: '请假原因不支持特殊字符', trigger: 'change' }]
      },
      settlement: false,
      drawerTitle: '',
      addName: '',
      addRecord: false,
      functionList: [
        { name: '单个添加' },
        { name: '批量导入' }
      ],
      formList: ['search', 'export', 'screen'], // search搜索框 screen筛选 export导出 refresh刷新
      // 时间段
      recordTime: moment(new Date()).format('YYYY-MM'),
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'index' },
          key: 'index',
          fixed: 'left',
          width: '50px',
          align: 'center'
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          key: 'staffName',
          scopedSlots: { customRender: 'staffName' },
          fixed: 'left',
          width: '105px'
        },
        {
          title: '工号',
          dataIndex: 'staffNum',
          key: 'staffNum',
          fixed: 'left',
          width: '100px',
          ellipsis: true
        },
        {
          title: '部门',
          dataIndex: 'orgName',
          key: 'orgName',
          fixed: 'left',
          width: '100px',
          ellipsis: true
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          key: 'postName',
          fixed: 'left',
          width: '100px',
          ellipsis: true
        },
        {
          title: '请假类型',
          dataIndex: 'vacationTypeName',
          key: 'vacationTypeName',
          width: '80px',
          ellipsis: true
        },
        {
          title: '数据来源',
          dataIndex: 'operateType',
          scopedSlots: { customRender: 'operateType' },
          key: 'operateType',
          width: '80px'
        },
        {
          title: '请假开始时间',
          dataIndex: 'startDateTime',
          scopedSlots: { customRender: 'startDateTime' },
          key: 'startDateTime',
          width: '140px',
          ellipsis: true
        },
        {
          title: '请假结束时间',
          dataIndex: 'endDateTime',
          scopedSlots: { customRender: 'endDateTime' },
          key: 'endDateTime',
          width: '140px',
          ellipsis: true
        },
        {
          title: '申请时长',
          dataIndex: 'applyDuration',
          key: 'applyDuration',
          width: '80px',
          ellipsis: true
        },
        {
          title: '请假原因',
          dataIndex: 'applyReason',
          key: 'applyReason',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          key: 'operation',
          fixed: 'right',
          width: '50px'
        }
      ],
      screenRecordList: {
        'list': [
          {
            'type': 'selectVacation',
            'label': '请假类型',
            'options': {
              'width': '100%',
              'multiple': false,
              'disabled': false,
              'clearable': true,
              'defaultValue': undefined,
              'placeholder': '请选择请假类型',
              'showSearch': false
            },
            'model': 'vacationTypeId',
            'key': 'vacationTypeId',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': '请选择请假类型'
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '聘用形式',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'engage_mode',
              'clearable': true,
              'placeholder': '请选择聘用形式',
              'showSearch': false
            },
            'model': 'engageModes',
            'key': 'engageModes',
            'rules': [
              {
                'required': false,
                'message': '请选择聘用形式'
              }
            ]
          },
          {
            'type': 'selectDepartment',
            'label': '部门',
            'options': {
              'width': '100%',
              'multiple': true,
              'disabled': false,
              'clearable': true,
              'hidden': false,
              'placeholder': '请选择部门',
              'dynamicKey': 'department',
              'defaultValue': undefined,
              'dynamic': false,
              'showSearch': false
            },
            'model': 'orgIds',
            'key': 'orgIds',
            'help': '',
            'rules': [
              {
                'required': false,
                'message': '请选择部门'
              }
            ]
          },
          {
            'type': 'selectPost',
            'label': '岗位',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'post_type',
              'clearable': true,
              'placeholder': '请选择岗位',
              'showSearch': false
            },
            'model': 'postIds',
            'key': 'postIds',
            'rules': [
              {
                'required': false,
                'message': '请选择岗位'
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '合同公司',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择合同公司',
              'showSearch': false,
              'parameter': 'contract_company'
            },
            'model': 'contractCo',
            'key': 'contractCo',
            'rules': [
              {
                'required': false,
                'message': '请选择合同公司'
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '工作地点',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': 'work_address',
              'clearable': true,
              'placeholder': '请选择工作地点',
              'showSearch': false
            },
            'model': 'workplaceIds',
            'key': 'workplaceIds',
            'rules': [
              {
                'required': false,
                'message': '请选择工作地点'
              }
            ]
          },
          {
            'type': 'selectDictionaries',
            'label': '打卡来源',
            'options': {
              'multiple': false,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择打卡来源',
              'showSearch': false,
              'parameter': 'operate_type'
            },
            'model': 'operateType',
            'key': 'operateType',
            'rules': [
              {
                'required': false,
                'message': '请选择打卡来源'
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 80,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      dataSource: [], // 数据
      // 选中列表集合
      selectedRowKeys: [],
      selectedRows: [],
      selectionMode: true,
      orgids: [],
      newOrg: [],
      addLeaveForm: {},
      vacationUnit: 'B',
      onJobId: '',
      vacationApplyId: '',
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD'),
      // 字典项
      operate_type: []
    }
  },
  async created() {
    this.operate_type = await this.getTenantCode('operate_type')
  },
  mounted() {
    this.getVacationSelectList()
  },
  methods: {
    // 结束时间不能大于开始时间
    disabledStartDate(startValue) {
      const endValue = moment(this.form.endDate)
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    disabledEndDate(endValue) {
      const startValue = moment(this.form.startDate)
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },

    // 员工选择 获取时间类型  天 半天 小时
    async getPerTreeValue(val) {
      if (this.form.onJobId) {
        const res = await getStaffInfoApi({ onJobId: this.form.onJobId })
        this.form.staffId = res.data.staffId
        if (this.form.vacationTypeId) {
          this.getVacationUnit()
        }
      } else {
        this.timeUnit = 'H'
      }
    },
    // 假期选择 获取时间类型  天 半天 小时
    getVacationType(val) {
      if (this.form.onJobId) {
        this.getVacationUnit()
      }
    },
    // 获取时间类型 天 半天 小时
    async getVacationUnit() {
      const { onJobId, vacationTypeId } = this.form
      const res = await getVacationUnitApi({ onJobId, vacationTypeId })
      if (res.code !== 100) return
      this.timeUnit = res.data
    },
    // 获取假期类型列表
    async getVacationSelectList() {
      const res = await getVacationType()
      this.rankData = res.data
    },
    // 根据时间获取班次 时长
    async timeChange() {
      const { startDate, startTime, endDate, endTime } = this.form
      if (this.timeUnit === 'D') {
        if (startDate && endDate) {
          this.calc()
        }
      } else {
        if (startDate && startTime && endDate && endTime) {
          this.calc()
        }
      }
    },
    // 计算周期 和 排班
    async calc() {
      const res = await getApplyVacationCalc(this.form)
      if (res.code !== 100) return
      this.formText.interval = res.data.interval
      this.formText.shiftList = res.data.shiftList || '--'
    },
    // 周期变化  考勤数据变化
    onChange(date) {
      this.startDate = moment(date).startOf('month').format('YYYY-MM-DD')
      this.endDate = moment(date).endOf('month').format('YYYY-MM-DD')
      this.serchGetData()
    },
    toggleValue(val) {
      this.dragValue = val
      this.fetchDataApiFn()
    },
    // 获取列表信息
    async fetchDataApiFn(param) {
      const startDate = moment(this.recordTime).startOf('month').format('YYYY-MM-DD')
      const endDate = moment(this.recordTime).endOf('month').format('YYYY-MM-DD')
      const res = await getapplyVacationPageList({ ...param, startDate, endDate })
      this.dataSource = res.data.records ? res.data.records : []
      this.pager.pageNum = res.data.current || 1 // 更新当前的分页信息
      this.pager.pageSize = res.data.size || 10 // 更新当前的分页信息
      this.total = res.data.total
    },
    onClose() {
      this.$refs.ruleForm.resetFields()
      this.addRecord = false
    },
    // 导出功能
    exportFileList() {
      const { staffIds, vacationTypeId, operateType, searchWord, engageModes, orgIds, postIds, contractCo, workplaceIds } = this._queryParam
      const startDate = moment(this.recordTime).startOf('month').format('YYYY-MM-DD')
      const endDate = moment(this.recordTime).endOf('month').format('YYYY-MM-DD')
      exportApplyVacation({ startDate, endDate, staffIds, vacationTypeId, operateType, searchWord, engageModes, orgIds, postIds, contractCo, workplaceIds })
    },
    // 刷新功能
    // refreshTableList() {
    //   delete this._queryParam.searchWord
    //   delete this._queryParam.orgIds
    //   delete this._queryParam.vacationTypeId
    //   delete this._queryParam.engageModes
    //   delete this._queryParam.workplaceIds
    //   delete this._queryParam.operateType
    //   delete this._queryParam.contractCo
    //   delete this._queryParam.postIds
    //   this.recordTime = moment(new Date()).format('YYYY-MM')
    //   this.startDate = moment().startOf('month').format('YYYY-MM-DD')
    //   this.endDate = moment().endOf('month').format('YYYY-MM-DD')
    //   this.$refs.appForm.refresh()
    //   this.serchGetData()
    // },
    // 查询回调
    getSearchCallback(data) {
      this._queryParam.searchWord = data.searchValue
      this.serchGetData()
    },
    // 筛选回调
    screenRosterParams(data) {
      this._queryParam.searchWord = data.searchValue
      if (data.screenCondition.orgIds) {
        this.orgids = data.screenCondition.orgIds
        this.newOrg = this.orgids.map(item => {
          return item.value
        })
        this._queryParam.orgIds = this.newOrg.join(',')
      }
      this._queryParam.vacationTypeId = data.screenCondition.vacationTypeId ? data.screenCondition.vacationTypeId : ''
      this._queryParam.operateType = data.screenCondition.operateType ? data.screenCondition.operateType : ''
      this._queryParam.contractCo = data.screenCondition.contractCo ? data.screenCondition.contractCo : ''
      this._queryParam.engageModes = data.screenCondition.engageModes ? data.screenCondition.engageModes.join(',') : ''
      this._queryParam.postIds = data.screenCondition.postIds ? data.screenCondition.postIds.join(',') : ''
      this._queryParam.workplaceIds = data.screenCondition.workplaceIds ? data.screenCondition.workplaceIds.join(',') : ''
      for (var key in this._queryParam) {
        if (!this._queryParam[key]) {
          delete this._queryParam[key]
        }
      }
      this.serchGetData()
    },
    // 删除按钮
    openDeleteBox() {
      this.$Reconfirm({
        title: '删除请假信息',
        content: '确定要删除该假期信息吗？'
      }).then(() => {
        const arr = []
        this.selectedRows.map(item => {
          arr.push(item.vacationApplyId)
        })
        delApplyVacation({ vacationApplyIds: arr }).then(res => {
          if (res.code === 100) {
            this.$message.success('删除成功')
            this.selectedRowKeys = []
            this.selectedRows = []
            this.serchGetData()
          }
        })
      }).catch(() => {
        console.log('no')
      })
    },
    // 新增
    add() {
      this.addName = event.currentTarget.innerHTML
      this.drawerTitle = '添加请假记录'
      if (this.addName === '单个添加') {
        Object.assign(this.form, this.$options.data().form)
        Object.assign(this.formText, this.$options.data().formText)
        this.addRecord = true
      } else {
        this.dragValue = true
        this.addRecord = false
      }
    },
    // 编辑
    async edit(scope) {
      const res = await applyVacationDetailApi({ vacationApplyId: scope.vacationApplyId })
      const { onJobId, vacationApplyId, applyDuration, vacationTypeId, applyReason, startDate, startTime, endDate, endTime, vacationUnit } = res.data
      const unit = vacationUnit === 'D' ? '天' : '小时'
      this.formText.interval = applyDuration + unit
      this.form = { onJobId, vacationApplyId, vacationTypeId, applyReason, startDate, startTime, endDate, endTime }
      this.drawerTitle = '编辑请假记录'
      this.getPerTreeValue()
      this.addRecord = true
    },
    onSubmin() {
      this.$refs.ruleForm.validate(async valid => {
        console.log(valid)
        if (valid) {
          if (this.drawerTitle === '添加请假记录') {
            addApplyVacationCreate(this.form).then(res => {
              if (res.code !== 100) return
              this.$message.success('添加成功')
            })
          } else {
            editApplyVacationUpdate(this.form).then(res => {
              if (res.code !== 100) return
              this.$message.success('修改成功')
            })
          }
          this.serchGetData()
          this.onClose()
        } else {
          return false
        }
      }
      )
    },
    afterVisibleChange() {
      this.$refs.ruleForm.resetFields()
      this.addRecord = false
    },
    // 表格选择项变更
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
    // disabledDate(current) { // 不可选择未来日期
    //   return current > moment().endOf('day')
    // }
  }
}
</script>

<style lang="less" scoped>
.tips{
  color: #FF9733;
}
.add-time{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.record-table{
  width: 100%;
  padding: 20px 20px 0;
  position: relative;
  /deep/.ant-pagination{

    .ant-pagination-options-size-changer.ant-select {
      margin-right: 10px;
    }
  }
}
.record-table-heard-left{
  display: flex;
  align-items: top;
  .deleteBtn{
    margin-left: 12px;
  }
}
.record-table-heard{
  display: flex;
  justify-content: space-between;
}
.weekBox{
    padding: 7px 6px;
    height: auto;
    width: auto;
    border-radius: 4px;
    color: @sc-grey-100;
    &:hover {
      color: @sc-primary-100;
      cursor: pointer;
    }
}
.time{
  width:fit-content;
  font-size: 16px;
  margin-bottom: 16px;
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 14px;
  background: @sc-greyBg-10;
  border-radius: 4px;
  .picker{
    padding: 0 6px;
  }
  .iconpark-icon{
    width: 20px;
    height: 20px;
    color: @sc-primary-100;
  }
}
</style>
