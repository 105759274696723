<template>
  <CbDrawer
    is-mask
    :title="mode === 'add' ? '' : '预览'"
    size="normal"
    wrap-class-name="cbDrawer"
    :visible="visible"
    :closable="mode !== 'add'"
    :display-btn="true"
    @close="onClose"
  >
    <template v-if="mode === 'add'" #title>
      <HeaderBack title="预览" @back="onClose" />
    </template>
    <PhonePanel height="100%">
      <template #content>
        <p class="title">{{ info.institutionTitle }}</p>
        <p class="subhead">{{ info.createTime }}</p>
        <div class="cont" v-html="info.institutionText" />
        <div v-for="item in info.uploadFile" :key="item.fileId" class="link">
          <img :src="findIcon(item.fileName)" alt="">
          <span>{{ item.fileName.split('.')[0] }}</span>
        </div>
      </template>
    </PhonePanel>
  </CbDrawer>
</template>
<script>
import moment from 'moment'
import PhonePanel from '@/components/CbPhonePanel/index.vue'
export default {
  name: 'SystemPreview',
  components: {
    CbDrawer: () => import('@/components/CbDrawer'),
    HeaderBack: () => import('@/components/headerBack/index.vue'),
    PhonePanel
  },
  data() {
    return {
      visible: false,
      mode: '',
      info: {}
    }
  },
  methods: {
    show(info, mode) {
      Object.assign(this.info, info)
      this.info.createTime = moment(this.info.createTime).format('YYYY-MM-DD HH:mm')
      this.visible = true
      this.mode = mode
      if (this.info.institutionText || this.info.institutionText.includes('\n')) {
        const arr = []
        this.info.institutionText.split('\n').map(item => {
          arr.push(`<p>${item}</p>`)
        })
        this.info.institutionText = arr.join('')
      }
      this.info.institutionText = `<p>${this.info.institutionText}</p>`
    },
    findIcon(fileName) {
      if (fileName.includes('.')) {
        const name = fileName.split('.')
        if (['doc', 'docx'].includes(name[1])) {
          return require('@/assets/img/upload/word_file.png')
        } else if (['pdf'].includes(name[1])) {
          return require('@/assets/img/upload/pdf_file.png')
        } else if (['xls', 'xlsx'].includes(name[1])) {
          return require('@/assets/img/upload/excel_file.png')
        } else if (['ppt', 'pptx'].includes(name[1])) {
          return require('@/assets/img/upload/ppt_file.png')
        } else if (['txt'].includes(name[1])) {
          return require('@/assets/img/upload/txt_file.png')
        }
        return ''
      }
      return ''
    },
    onClose() {
      this.visible = false
    }
  }
}
</script>
<style lang="less" scoped>
.title{
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
  color: @sc-grey-100;
}
.subhead{
  color: @sc-grey-60;
  .font-size(14px);
  margin-bottom: 16px;
}
.cont{
  text-indent:2em;
  color: @sc-grey-100;
  margin-bottom: 14px;
  white-space: pre-wrap;
  margin-bottom: 14px;
  /deep/p{
    line-height: 26px;
    margin-bottom: 0;
    word-break: break-all;
  }
}
.link{
  font-weight: 600;
  margin-bottom: 14px;
  img{
    width: 26px;
    height: 30px;
    margin-right: 15px;
  }
  img[src=""]{
    display: none;
  }
  span{
    font-weight: 400;
    color: @sc-grey-100;
  }
}
</style>
<style>
.cbDrawer .drawer-content{
  height: calc(100% - 20px) !important;
}
</style>
