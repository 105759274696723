<template>
  <div>
    <div class="title-box flex-align-center">
      <BlockTitle title="打卡时段" :is-bold="true" class="msg-title">
        <template slot="left">
          <span class="msg-span">请按时间轴从上往下设置上班时间段、加班时段，注意时段间不能交叉</span>
        </template>
      </BlockTitle>
    </div>
    <a-timeline>
      <a-timeline-item>
        <div class="time-setting">
          <span>一天开始时间</span><CbTimePicker v-model="form.dayStartTime" :options="timeSetUp" @change="getDayEndTime" /><span>
            <!-- <a-checkbox v-if="form.shiftType!=='B2'" v-model="form.earlyOverTimeFlag">
              提前上班，需要计算加班时长
            </a-checkbox> -->
          </span>
        </div>
      </a-timeline-item>
      <div v-if="form.shiftType === 'B1'">
        <a-timeline-item v-for="(item,index) in form.shiftPunchRuleList" :key="index">
          <div class="time-card">
            <div v-if="form.shiftType==='B1'" class="time-title flex-just-between">
              <div>
                <span>{{ `第 ${index+1}段` }}</span>
                <!-- <a-checkbox v-if="index" v-model="item.overtimeFlag">是否为加班时段</a-checkbox> -->
              </div>
              <svg v-if="index" class="iconpark-icon" @click="del(index)"><use href="#delete-one" /></svg>
            </div>
            <div class="time-content">
              <a-row>
                <a-col :span="7">
                  <span class="title">出勤时段</span>
                </a-col>
                <a-col :span="10">
                  <span class="title">有效打卡时段</span>
                </a-col>
                <a-col :span="7">
                  <span class="title">规则设定</span>
                </a-col>
              </a-row>
              <a-row class="time-box" type="flex" justify="space-between">
                <a-col :span="7">
                  <a-form-model-item label="上班时间" class="timepicker">
                    <!-- :start-disabled="index?form.shiftPunchRuleList[index-1].endTime:''" :end-disabled="item.endTime" -->
                    <CbTimePicker v-model="item.startTime" default-value="00:00" :options="timeSetUp" @change="change" />
                  </a-form-model-item>
                  <a-form-model-item label="下班时间" class="timepicker">
                    <!-- :start-disabled="item.startTime" -->
                    <CbTimePicker v-model="item.endTime" :parent-disabled="!item.startTime" :options="timeSetUp" @change="change" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="10">
                  <a-form-model-item label="上班打卡时段" class="time-bottom">
                    <!--  :start-disabled="index?form.shiftPunchRuleList[index-1].endTime:''" :end-disabled="item.breakEndTime?item.breakEndTime:item.endTime" -->
                    <CbTimePicker v-model="item.punchInStartTime" :parent-disabled="!item.endTime" :options="timeSetUp" @change="change" />
                    <!-- :start-disabled="item.punchInStartTime" :end-disabled="item.endTime" -->
                    <CbTimePicker v-model="item.punchInEndTime" :parent-disabled="!item.punchInStartTime" :options="timeSetUp" @change="change" />
                  </a-form-model-item>
                  <a-form-model-item label="下班打卡时段" class="time-bottom">
                    <!-- :start-disabled="item.punchInEndTime"  -->
                    <CbTimePicker v-model="item.punchOutStartTime" :parent-disabled="!item.punchInEndTime" :options="timeSetUp" @change="change" />
                    <!-- :start-disabled="item.punchOutStartTime" :end-disabled="item.punchInStartTime"  -->
                    <CbTimePicker v-model="item.punchOutEndTime" :parent-disabled="!item.punchOutStartTime" :options="timeSetUp" @change="change" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="7">
                  <a-form-model-item label="打卡次数" class="timepicker">
                    <a-select v-model="item.punchTimesRuleType" allow-clear>
                      <a-select-option v-for="v in clockInTimesList" :key="v.value" :value="v.value" :title="v.label">{{ v.label }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="异常判断">
                    <a-checkbox v-model="item.checkLateInFlag">需要记迟到</a-checkbox>
                    <a-checkbox v-model="item.checkEarlyOutFlag">需要记早退</a-checkbox>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <div class="time-bottom">
                <a-checkbox v-model="item.breakFlag" @change="change">休息时间</a-checkbox>
                <CbTimePicker v-model="item.breakStartTime" :parent-disabled="!item.endTime || !item.breakFlag" :start-disabled="item.startTime" :end-disabled="item.endTime" :options="timeSetUp" @change="change" />
                <a-icon class="swap-right" type="swap-right" />
                <CbTimePicker v-model="item.breakEndTime" :parent-disabled="!item.breakStartTime || !item.breakFlag" :start-disabled="item.breakStartTime" :end-disabled="item.endTime" :options="timeSetUp" @change="change" />
              </div>
            </div>
          </div>
        </a-timeline-item>
      </div>
      <a-timeline-item v-if="form.shiftType === 'B1'">
        <div class="time-add">
          <a-button type="link" prefix="add-one" @click="form.shiftPunchRuleList.push({breakFlag:false})">
            添加上下班时间
          </a-button>
          <div>
            <span v-if="form.shiftHours">合计工作时长：<span class="time-hour">{{ form.shiftHours }}</span>小时/天；</span>
            <span v-if="form.shiftOvertimeHours" style="margin-right:16px">最长加班总时长（不含提前上班加班）：<span class="time-hour">{{ form.shiftOvertimeHours }}</span>小时/天</span>
          </div>
        </div>
      </a-timeline-item>
      <a-timeline-item>
        <div class="time-setting">
          <span> 一天结束时间</span><CbTimePicker v-model="form.dayEndTime" :parent-disabled="true" :options="timeSetUp" /><span>次日</span>
        </div>
      </a-timeline-item>
    </a-timeline>
  </div>
</template>

<script>
import moment from 'moment'
import { punchTimesRuleType } from '../../../../dict'
import { getComputingWorkTime } from '@/services/attendance/shift.js'
export default {
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index'),
    CbTimePicker: () => import('@/components/CbTimePicker/index')
  },
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      timeSetUp: {
        format: 'HH:mm',
        valueFormat: 'HH:mm',
        placeholder: '时间',
        clearable: true
      },
      clockInTimesList: punchTimesRuleType
    }
  },
  methods: {
    del(index) {
      this.form.shiftPunchRuleList.splice(index, 1)
      this.getTimes()
    },
    change(time) {
      this.$nextTick(() => {
        const { shiftType } = this.form
        const { startTime, breakStartTime, breakEndTime, endTime, breakFlag } = this.form.shiftPunchRuleList[this.form.shiftPunchRuleList.length - 1]
        if (shiftType === 'B1' && breakFlag && startTime && breakStartTime && breakEndTime && endTime) {
        // 工作日打卡 有休息日
          this.getTimes()
        } else if (shiftType === 'B1' && !breakFlag && startTime && endTime) {
        // 工作日打卡 无休息日
          this.getTimes()
        } else {
          this.form.shiftHours = ''
          this.$forceUpdate()
        }
      })
    },
    // 获取时间
    async getTimes() {
      const res = await getComputingWorkTime({ shiftPunchRuleList: this.form.shiftPunchRuleList, dayStartTime: this.form.dayStartTime })
      this.form.shiftHours = res.data.shiftHours
      this.form.shiftOvertimeHours = res.data.shiftOvertimeHours
      this.$forceUpdate()
    },
    clearData() {
    },
    minutes(time) {
      if (time) {
        const hour = time.split(':')[0]
        const minute = parseInt(time.split(':')[1])
        return hour * 60 + minute
      }
    },
    // 获取一天结束时间
    getDayEndTime(time) {
      const date = moment(moment(time, 'hh:mm').format()).valueOf()
      const end = moment(date - 60 * 1000).format('HH:mm')
      this.form.dayEndTime = end
    }
  }
}
</script>

<style lang="less" scoped>
.title-box{
  margin:2px 0 14px 0;
  span{
    margin-left: 8px;
    color:@sc-grey-60;
  }
}
.msg-span {
  .font-size(14px);
  color:@sc-grey-20;
}
.msg-title{
  margin-bottom: 20px
}
.time-setting{
  .font-size(14px);
  color:@sc-grey-100;
  span:first-child {
    font-weight: 600;
  }
  /deep/.ant-time-picker{
    width:208px !important;
    margin:0 24px 0 22px;
  }
}
.time-card{
    background: @sc-greyBg-10;
    border-radius: 4px;
    width: 100%;
    border: 1px solid @sc-grey-10;
  .time-title{
    height: 46px;
    .font-size(14px);
    font-weight: 600;
    padding: 12px 18px;
    color:@sc-grey-100;
    border-bottom: 1px solid @sc-grey-10;
    /deep/.ant-checkbox {
      margin-left: 47px !important;
    }
    .iconpark-icon{
      width: 16px;
      height: 16px;
      color: @sc-greyBg-100;
      cursor: pointer;
    }
  }
  .time-content{
    padding:17px 17px 0 17px;
    border-bottom: 1px solid @sc-grey-10;
    /deep/.ant-form-item-control{
      height: 40px
    }
    .title{
      .font-size(14px);
      font-weight: 600;
      color:@sc-grey-100;
      margin-right: 9px;
    }
    .time-box{
      height: 135px;
      border-bottom: 1px solid @sc-grey-10;
    }
    .time-bottom{
      padding:20px 0;
      /deep/.ant-checkbox-wrapper{
        margin-left: 7px;
        .font-size(14px);
        color:@sc-grey-100;
      }
      /deep/.ant-time-picker-input{
        padding: 4px 12px;
        &:nth-child(2){
        margin-left: 7px;
        }
      }
    }
    .ant-form-item {
      height: 52px !important;
      margin-bottom:0;
      display: flex !important;
      align-items: center;
    }
    .ant-form-label {
      padding:0;
      margin:0;
    }
    /deep/ .ant-form-item-label > label {
      color: @sc-grey-80;
    }
    /deep/.ant-time-picker{
        width:80px !important;
        &:nth-child(1){
          margin-right: 12px;
        }
      }
    .timepicker{
      /deep/.ant-time-picker{
        width:120px !important;
      }
      /deep/.ant-select{
        width: 100px !important;
      }
    }
    .swap-right{
      margin: 0 18px;
      width:20px;
      color: @sc-grey-60;
    }
  }
}
.time-add{
  /deep/.ant-btn{
    padding: 0;
  }
  .time-hour{
    color:@sc-primary-100;
  }
}
/deep/.ant-timeline-item-content{
    top: -11px;
    margin-left:22px !important;
}
/deep/.ant-form-item-children{
  .ant-checkbox-wrapper{
    margin: 0;
  }
}
</style>
<style>
.ant-time-picker-panel-select::-webkit-scrollbar {
    display:none ;
}
</style>
