<template>
  <div class="salary-group-container">
    <div v-if="groupVisible" class="salary-group-table">
      <div class="add-salary">
        <a-button prefix="add" class="ant-button" @click="addGroup">
          新增薪资组
        </a-button>
      </div>
      <custom-table
        :columns="columns"
        :data-source="dataSource"
        row-key="itemNo"
        :pagination="false"
        @change="loadDataList"
      >
        <template slot="userCount" slot-scope="{ scope }">
          {{ scope.record.userCount }}人
        </template>
        <!-- 是否启用 -->
        <span slot="enabled" slot-scope="{scope}" class="label" :class="scope.record.enabled==1?'enable':'close'">
          {{ scope.record.enabled==1?'启用':'关闭' }}
        </span>
        <!-- 操作列 -->
        <template slot="operation" slot-scope="{ scope }">
          <span class="pre-operation-box">
            <a-button type="link" @click="editGroup(scope.record)">
              编辑
            </a-button>
            <a-popover
              placement="bottom"
              trigger="hover"
            >
              <template slot="content">
                <div class="more-handle-btn">
                  <a-button type="link" @click="copyGroup(scope.record)">
                    复制
                  </a-button>
                  <a-button type="link" class="delete" @click="deletGroup(scope.record)">
                    删除
                  </a-button>
                  <a-button v-if="!scope.record.enabled" type="link" @click="emptyPersonnel(scope.record)">
                    清空人员
                  </a-button>
                </div>
              </template>
              <div class="more-handle">
                ···
              </div>
            </a-popover>
          </span>

        </template>
      </custom-table>
    </div>
    <div v-else>
      <GroupSteps :group-id="groupId" :handler-type="handlerType" @goBack="goBack" />
    </div>
    <CopyDrawer ref="copyDrawer" @refresh="serchGetData" />
  </div>
</template>

<script>
import { listGroup, deleteGroup, clearUser } from '@/services/wage/salaryGroup.js'
import CustomTable from '@/components/CustomTable/index.js'
import { debounce } from '@/utils/index.js'
export default {
  name: 'SalaryGroup',
  components: {
    GroupSteps: () => import('./addGroupSteps/index.vue'),
    CopyDrawer: () => import('./copyDrawer/index.vue')
  },
  mixins: [CustomTable],
  data() {
    return {
      handlerType: '',
      groupId: '',
      groupVisible: true,
      columns: [{
        title: '薪资组名称',
        dataIndex: 'groupName',
        key: 'groupName',
        width: '200px'
      },
      {
        title: '适用人员',
        dataIndex: 'userCount',
        scopedSlots: { customRender: 'userCount' },
        key: 'userCount',
        width: '200px'
      },
      {
        title: '启用状态',
        dataIndex: 'enabled',
        scopedSlots: { customRender: 'enabled' },
        key: 'enabled',
        width: '100px'
      },
      {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
        width: '50px'
      }],
      dataSource: []
    }
  },
  methods: {
    // 请求数据
    async fetchDataApiFn(_queryParam) {
      const res = await listGroup({ ..._queryParam })
      if (res.code === 100) {
        this.dataSource = res.data
      }
    },
    onChange(record) {
      console.log(record)
    },
    // 清空薪资组人员
    emptyPersonnel(record) {
      this.groupId = record.groupId
      this.$Reconfirm({
        title: '清空人员',
        content: '是否确定清空该薪资组人员？'
      }).then(() => {
        this.handleClearUser()
      }).catch(() => {
      })
    },
    handleClearUser: debounce(async function() {
      const res = await clearUser({ groupId: this.groupId })
      if (res.code === 100) {
        this.$message.success('已清空该薪资组人员！')
        this.serchGetData()
      }
    }, 500),
    // 复制薪资组
    async copyGroup(record) {
      // 复制薪资组 即复制当前行得薪资组内容，人员重新进行选择返回旧id赋给新得薪资组
      this.$refs.copyDrawer.open(record.groupId)
    },
    // 删除薪资组
    deletGroup(record) {
      this.groupId = record.groupId
      this.$Reconfirm({
        title: '删除薪资组',
        content: '是否确定删除该薪资组？'
      }).then(() => {
        console.log('ok')
        this.handleDelGroup()
      }).catch(() => {
        console.log('no')
      })
    },
    handleDelGroup: debounce(async function() {
      const res = await deleteGroup({ groupId: this.groupId })
      if (res.code === 100) {
        this.$message.success('删除成功！')
        this.serchGetData()
      }
    }, 500),
    // 新增薪资组
    addGroup() {
      this.handlerType = 'add'
      this.groupVisible = false
    },
    // 编辑薪资组
    editGroup(record) {
      this.handlerType = 'edit'
      this.groupId = record.groupId
      this.groupVisible = false
    },
    // 编辑/新增薪资组返回刷新数据
    goBack() {
      this.groupVisible = true
      this.serchGetData()
    }
  }

}
</script>
<style lang="less" scoped>
.salary-group-container {
  height: 100%;
  overflow:auto;
  box-sizing: border-box;
  .salary-group-table{
    padding: 20px;
  }
  .add-salary {
    margin-bottom: 16px;
  }
}
.more-handle-btn {
    display: flex;
    flex-direction: column;
    .ant-btn {
      height:25px;
    }
}
.more-handle {
  width:20px;
  font-weight: 700;
  font-size: 20px;
  color: @sc-primary-100;
  vertical-align: baseline;
  cursor: pointer;
}
.label{
  display: inline-block;
  padding: 3px 15px;
  background:@sc-error-10;
  color: @sc-error-100;
  border-radius: 3px;
}
.enable{
  background:@sc-success-10;
  color: @sc-success-100;
}
.close {
  background:@sc-error-10;
  color: @sc-error-100;
}
.pre-operation-box{
  display:flex;
  button{
    padding-left:0;
    padding-right:16px;
  }
}
</style>
