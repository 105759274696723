<template>
  <div class="CopyDrawer">
    <Drawer
      :is-mask="false"
      :z-index="100"
      title="复制薪资组"
      size="normal"
      :visible="visible"
      @close="visible = false"
      @onSubmit="onSubmit"
    >
      <Form ref="form" :form="form" :wrapper-col="{span:20}" :label-col="{span:4}" />
    </Drawer>
  </div>
</template>

<script>
import { copyGroup } from '@/services/wage/salaryGroup.js'
export default {
  name: 'CopyDrawer',
  components: {
    Drawer: () => import('@/components/CbDrawer'),
    Form: () => import('../addGroupSteps/basicInfo/form.vue')
  },
  data() {
    return {
      visible: false,
      groupId: '',
      form: {
        groupName: '',
        userList: [],
        enabled: true,
        startPeriod: ''
      }
    }
  },
  methods: {
    onSubmit() {
      this.$refs.form.$refs.basicForm.validate(valid => {
        if (valid) {
          this.copyGroup()
        }
      })
    },
    // 复制薪资组
    async copyGroup() {
      const res = await copyGroup({ ...this.form, enabled: this.form.enabled ? 1 : 0, oldGroupId: this.groupId })
      if (res.code === 100) {
        this.visible = false
        this.$message.success('复制成功！')
        this.$emit('refresh')
      }
    },
    // 打开窗口
    open(groupId) {
      this.visible = true
      this.groupId = groupId
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-form-item-label > label {
  color: @sc-grey-80;
}
/deep/.ant-input{
    width: 414px;
  }
</style>
