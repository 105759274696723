// 班次弹性方式
const shiftFlexType = [
  { value: '0', label: '无弹性' },
  { value: '1', label: '豁免时间' },
  { value: '2', label: '弹性考勤(早来早走晚来晚走)' },
  { value: '3', label: '弹性考勤(分时段晚来晚走)' }
]
// 弹性时段
const flexNum = [
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' }
]
// 考勤组类型
const attendance_group_type = [
  { value: '2', label: '需打卡' },
  { value: '1', label: '无需打卡' }

]
// 工时制类型
const labor_time_type = [
  { value: '1', label: '标准工时制' },
  { value: '2', label: '综合工时制' }
]
// 综合工时制核算周期
const labor_time_cycle = [
  { value: '1', label: '1个月' },
  { value: '2', label: '2个月' },
  { value: '3', label: '3个月' },
  { value: '6', label: '半年' },
  { value: 'A', label: '1年' }
]
// 打卡条件规则
const punch_rule_type = [
  { value: '1', label: '满足地址范围可打卡' },
  { value: '2', label: '满足wifi范围可打卡' },
  { value: '3', label: '满足wifi或地址中一个即可打卡' },
  { value: '4', label: '同时满足wifi和地址范围可打卡' }
]
// 排班循环周期类型
const shift_cycle_type = [
  { value: 1, label: '按周' },
  { value: 2, label: '按月' },
  { value: 3, label: '无固定休息日' }
]
// 排班方式
const shift_scheduling_type = [
  { value: 1, label: '自动排班' },
  { value: 2, label: '手动排班' }
]

// 班次类型
const shiftType = [
  { value: 'A1', label: '早晚打卡', text: '如朝九晚六，中间午休时间无需打卡上下班' },
  { value: 'A2', label: '分段打卡', text: '适用于每次上下班均需要打卡' },
  { value: 'B1', label: '工作日', text: '适用于多个时间段上下班' },
  { value: 'B2', label: '休息日', text: '无需上班' }
]

// 打卡次数规则
const punchTimesRuleType = [
  { value: '1', label: '上下班均需打卡' },
  { value: '2', label: '仅上班打卡' },
  { value: '3', label: '仅下班打卡' },
  { value: '4', label: '上班或下班打一次卡即可' }
]

// 预设假期类型
const vacationType = [
  { value: 'NXJ', label: '年假' },
  { value: 'TXJ', label: '调休假' },
  { value: 'DBJ', label: '短期病假' },
  { value: 'SHJ', label: '事假' },
  { value: 'CHJ', label: '产假' },
  { value: 'HUJ', label: '婚假' },
  { value: 'KHJ', label: '看护假' },
  { value: 'PCJ', label: '陪产假' },
  { value: 'SAJ', label: '丧假' },
  { value: 'LTJ', label: '路途假' },
  { value: 'CJJ', label: '产检假' },
  { value: 'BRJ', label: '哺乳假' },
  { value: 'OTH', label: '非出勤假' },
  { value: 'SRJ', label: '生日假' },
  { value: 'TQJ', label: '探亲假' }
]

//
const earlyLatedEductFlag = [
  { value: 1, label: '迟到、早退扣工时' },
  { value: 2, label: '迟到、早退不扣工时' }
]

// 半天选择
const halfDay_type = [
  { label: '上午', value: 'am' },
  { label: '下午', value: 'pm' }
]
// 加班类型
const overtime_type = [
  { label: '工作日加班', value: 'A' },
  { label: '休息日加班', value: 'B' },
  { label: '节假日加班', value: 'C' },
  { label: '超时加班', value: 'D' }
]
// 加班数据来源
const operate_type = [
  { label: '加班审批', value: '1' },
  { label: 'HR添加', value: '2' },
  { label: '打卡生成', value: '3' }
]
// 加班补偿方式
const overtime_convert_type = [
  { label: '不计补偿', value: '0' },
  { label: '加班工资', value: '1' },
  { label: '加班调休', value: '2' }
]
// 加班核算规则
const overtime_calc_type = [
  { label: '需审批，在审批时段内按打卡时长计算', value: '1' },
  { label: '需审批，按审批时长计算', value: '2' },
  { label: '无需审批，按打卡时长计算', value: '3' }
]
// 加班时长计算单位
const overtime_unit = [
  { label: '按分钟加班', value: '1' },
  { label: '按半小时加班', value: '2' },
  { label: '按小时加班', value: '3' },
  { label: '按半天加班', value: '4' },
  { label: '按天加班', value: '5' }
]
// 请假时间单位
const vacation_unit = [
  { label: '按小时', value: 'H' },
  { label: '按天', value: 'D' }
]
// 半天折算方式
const halfday_calc_type = [
  { label: '按班次自动折算', value: '1' },
  { label: '自定义', value: '2' }
]
// 考勤结果确认状态
const attendance_confirm_status = [
  { label: '未确认', value: '0' },
  { label: '正确认', value: '1' },
  { label: '已确认', value: '2' }

]
// 考勤确认方式
const attendance_confirm_type = [
  { label: '员工手动确认', value: '1' },
  { label: '超时自动确认', value: '2' }
]
// 归档状态
const attendance_archive_status = [
  { label: '未归档', value: '1' },
  { label: '已归档', value: '2' }
]

// missingpunch_alert_day_type
const missingpunch_alert_day_type = [
  {
    value: 'P1',
    label: '考勤周期最后一天'
  }, {
    value: 'P2',
    label: '考勤周期倒数第二天'
  }, {
    value: 'P3',
    label: '考勤周期倒数第三天'
  }, {
    value: 'P4',
    label: '考勤周期倒数第四天'
  }, {
    value: 'N1',
    label: '下个考勤周期第一天'
  }, {
    value: 'N2',
    label: '下个考勤周期第二天'
  }, {
    value: 'N3',
    label: '下个考勤周期第三天'
  }, {
    value: 'N4',
    label: '下个考勤周期第四天'
  }
]

const punch_result_type = [
  { label: '打卡成功-正常', value: '100' },
  { label: '打卡成功-迟到', value: '101' },
  { label: '打卡成功-早退', value: '102' },
  { label: '打卡成功-其他', value: '199' },
  { label: '打卡失败-无班次记录', value: '201' },
  { label: '打卡失败-重复打卡', value: '202' },
  { label: '打卡失败-无需打卡', value: '203' },
  { label: '打卡失败-未到有效打卡时段', value: '204' },
  { label: '打卡失败-其他', value: '205' }
]

// 下载模板匹配方式
const import_matchField_type = [
  { label: '按照姓名匹配', value: 'staffName' },
  { label: '按照证件号码匹配', value: 'idNum' },
  { label: '按照工号匹配', value: 'staffNum' }
]
const punch_source_type = [
  { label: 'HR添加', value: '1' },
  { label: '上下班打卡', value: '2' },
  { label: '数据导入', value: '3' },
  { label: '补卡申请', value: '4' },
  { label: '外勤打卡', value: '5' },
  { label: '添加补卡', value: '6' }
]
// week_day
const week_day = [
  {
    value: '1',
    label: '周一'
  }, {
    value: '2',
    label: '周二'
  }, {
    value: '3',
    label: '周三'
  }, {
    value: '4',
    label: '周四'
  }, {
    value: '5',
    label: '周五'
  }, {
    value: '6',
    label: '周六'
  }, {
    value: '7',
    label: '周日'
  }]
// 假期余额变动原因类型
const vacation_balance_change_reason = [
  { label: '手动发放', value: 'G01' },
  { label: '自动发放', value: 'G02' },
  { label: '加班补偿', value: 'G03' },
  { label: '请假扣减', value: 'D01' },
  { label: '手动扣减', value: 'D02' },
  { label: '自动到期扣减', value: 'D03' }
]
export {
  import_matchField_type,
  shiftFlexType,
  flexNum,
  attendance_group_type,
  labor_time_type,
  labor_time_cycle,
  punch_rule_type,
  shift_cycle_type,
  shift_scheduling_type,
  shiftType,
  punchTimesRuleType,
  vacationType,
  earlyLatedEductFlag,
  halfDay_type,
  overtime_type,
  operate_type,
  overtime_convert_type,
  overtime_calc_type,
  overtime_unit,
  vacation_unit,
  halfday_calc_type,
  attendance_confirm_status,
  attendance_confirm_type,
  attendance_archive_status,
  missingpunch_alert_day_type,
  week_day,
  punch_result_type,
  punch_source_type,
  vacation_balance_change_reason
}
