<template>
  <div class="info-setting-container">
    <custom-table
      :columns="columns"
      :data-source="dataSource"
      row-key="infoId"
      :pagination="false"
      @change="loadDataList"
    >
      <template slot="remindTime" slot-scope="{ scope: { record } }">
        <!-- <span v-if="record.remindTimeType === '2'">
          {{ record.remindTime }} {{ timeTypeList[record.remindTimeUnit - 1].time }}
        </span>
        <span v-else>
          立即提醒
        </span> -->
        <span v-if="record.remindTimeType === '2'">定时提醒，{{ record.messageCategory==='调薪提醒'?'调薪完成':'工资条发送' }}{{ record.remindTime }}{{ record.remindTimeUnit==='1'?'小时':'天' }}后提醒</span>
        <span v-else>{{ record.messageCategory==='调薪提醒'?'完成调薪操作':'工资条发送' }}立即提醒</span>
      </template>
      <template slot="remindNotifiler" slot-scope="{ scope: { record } }">
        <span v-if="record.remindNotifiler !== '3'">
          {{ notifierList[record.remindNotifiler - 1].person }}
        </span>
        <span v-else>
          {{ notifierListMapText }}
        </span>
      </template>
      <span slot="remindEnableSwitch" slot-scope="{ scope: { record } }">
        <a-switch
          v-model="record.remindEnableSwitch"
          size="small"
          checked-children="启用"
          un-checked-children="禁用"
          @change="switchChange(record)"
        />
      </span>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{ scope: { record } }">
        <div class="more-handle-btn">
          <a-button type="link" @click="edit(record)">
            编辑
          </a-button>
        </div>
      </template>
    </custom-table>
    <Drawer
      :is-mask="false"
      closable
      :visible="drawer"
      title="编辑消息提醒"
      placement="right"
      size="small"
      @close="drawer = false"
      @onSubmit="onSubmin"
    >
      <a-form-model :model="infoForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
        <a-form-model-item label="消息类别">
          <a-input v-model="infoForm.messageCategory" disabled />
        </a-form-model-item>
        <a-form-model-item label="提醒时间" prop="remindTimeType">
          <a-select v-model="infoForm.remindTimeType" :default-value="infoForm.remindTimeType">
            <a-select-option value="1">{{ infoForm.messageCategory==='调薪提醒'?'调薪完成':'完成工资发放' }},立即提醒</a-select-option>
            <a-select-option value="2">定时提醒</a-select-option>
          </a-select>
          <div v-if="infoForm.remindTimeType === '2'" class="timeNotify">
            <span class="payRoll">{{ infoForm.messageCategory==='调薪提醒'?'调薪完成':'工资条发送' }}</span>
            <number v-model="infoForm.remindTime" />
            <div class="timeNum">
              <a-select v-model="infoForm.remindTimeUnit" :default-value="infoForm.infoTimeType">
                <a-select-option v-for="(item) in timeTypeList" :key="item.id" :value="item.id">{{ item.time }}</a-select-option>
              </a-select>
            </div>
            <span class="payRoll">后提醒</span>
          </div>
        </a-form-model-item>
        <a-form-model-item label="通知人" prop="remindNotifiler">
          <a-select v-model="infoForm.remindNotifiler" mode="multiple" :token-separators="[',']">
            <a-select-option v-for="(item) in notifierList" :key="item.id" :value="item.id">{{ item.person }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </Drawer>
  </div>
</template>

<script>
import CustomTable from '@/components/CustomTable/index.js'
import {
  getSalaryAdjustmentConfig,
  getSalaryTemplateRemindConfig,
  setSalaryAdjustmentConfig,
  setSalaryTemplateRemindConfig
} from '@/services/wage/infoSetting.js'
import number from '@/components/CbNumber'
export default {
  name: 'InfoSetting',
  components: {
    number,
    Drawer: () => import('@/components/CbDrawer')
  },
  mixins: [CustomTable],
  data() {
    return {
      labelCol: { lg: 5, xl: 4, xxl: 5 },
      wrapperCol: { lg: 19, xl: 20, xxl: 16 },
      drawer: false,
      infoId: '',
      infoForm: {
        remindEnableSwitch: '', // 调薪提醒状态(0：禁用，1：启用)调薪提醒状态(0：禁用，1：启用)
        remindNotifiler: '', // 调薪通知人（1：员工，2：直属领导，3：全部）
        remindTimeType: '', // 调薪提醒方式(1：立即提醒，2：定时提醒)
        remindTime: '', // 调薪定时提醒时间
        remindTimeUnit: '', // 调薪定时提醒时间单位（1：小时，2：天）
        messageCategory: '', // 消息类别
        // 前端设置code值来区分是工资条提醒、调薪提醒｜ code：0--调薪、 code：1--工资条 提交时删除
        code: ''
      },
      dataSource: [],
      columns: [
        {
          title: '消息类别',
          dataIndex: 'messageCategory',
          key: 'messageCategory',
          width: '100px'
        },
        {
          title: '提醒时间',
          dataIndex: 'remindTime',
          key: 'remindTime',
          scopedSlots: { customRender: 'remindTime' },
          width: '100px'
        },
        {
          title: '通知人',
          dataIndex: 'remindNotifiler',
          key: 'remindNotifiler',
          scopedSlots: { customRender: 'remindNotifiler' },
          width: '100px'
        },
        {
          title: '是否启用',
          dataIndex: 'remindEnableSwitch',
          key: 'remindEnableSwitch',
          scopedSlots: { customRender: 'remindEnableSwitch' },
          width: '100px'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '50px'
        }
      ],
      timeTypeList: [ // 调薪定时提醒时间单位（1：小时，2：天）
        { id: '1', time: '小时' },
        { id: '2', time: '天' }
      ],
      notifierList: [ // 调薪通知人（1：员工，2：直属领导，3：全部）
        { id: '1', person: '员工本人' },
        { id: '2', person: '直属领导' }
      ],
      rules: {
        infoTime: [
          { required: true, message: '必填项', trigger: 'blur' }
        ],
        person: [
          { required: true, message: '必填项', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    // 获取全部的人员类型
    notifierListMapText() {
      const { notifierList } = this
      const notifierListMap = notifierList.map(({ person }) => person)
      return notifierListMap.join(',')
    }
  },
  async created() {
    await this.getSalaryAdjustmentConfig()
    await this.getSalaryTemplateRemindConfig()
  },
  methods: {
    // 获取调薪提醒配置
    async getSalaryAdjustmentConfig() {
      const { data } = await getSalaryAdjustmentConfig()
      // 前端设置code值来区分是工资条提醒、调薪提醒｜ code：0--调薪、 code：1--工资条
      data.code = 0
      data.remindEnableSwitch = Boolean(data.remindEnableSwitch - 0)
      this.dataSource.splice(0, 1, data)
    },
    // 工资条提醒配置
    async getSalaryTemplateRemindConfig() {
      const { data } = await getSalaryTemplateRemindConfig()
      // 前端设置code值来区分是工资条提醒、调薪提醒｜ code：0--调薪、 code：1--工资条
      data.code = 1
      data.remindEnableSwitch = Boolean(data.remindEnableSwitch - 0)
      this.dataSource.splice(1, 1, data)
    },
    // 编辑功能
    edit(record) {
      const { remindNotifiler } = record
      this.drawer = true
      // 防止浅拷贝
      this.infoForm = { ...record }
      // 3是全部 前端转换成1、2都勾选
      if (remindNotifiler === '3') {
        this.infoForm.remindNotifiler = ['1', '2']
      }
    },
    // 提交的参数格式化
    getParams() {
      const { infoForm: { remindEnableSwitch, remindNotifiler }} = this
      const params = {
        ...this.infoForm
      }
      // 删除前端自定义的值
      delete params.code
      params.remindEnableSwitch = Number(remindEnableSwitch)
      params.remindNotifiler = remindNotifiler.length > 1 ? '3' : remindNotifiler[0]
      return params
    },
    switchChange(record) {
      this.infoForm = { ...record }
      if (record.code === 0) {
        this.setSalaryAdjustmentConfig()
      }
      if (record.code === 1) {
        this.setSalaryTemplateRemindConfig()
      }
      console.log(this.infoForm)
    },
    // 设置调薪消息提醒配置
    async setSalaryAdjustmentConfig() {
      const { data } = await setSalaryAdjustmentConfig(this.getParams())
      if (data) {
        this.getSalaryAdjustmentConfig()
        this.drawer = false
        this.$message.success('设置成功')
      }
    },
    // 设置工资条消息提醒配置
    async setSalaryTemplateRemindConfig() {
      const { data } = await setSalaryTemplateRemindConfig(this.getParams())
      if (data) {
        this.getSalaryTemplateRemindConfig()
        this.drawer = false
        this.$message.success('设置成功')
      }
    },
    async onSubmin() {
      const { infoForm: { code }} = this
      if (code === 0) {
        this.setSalaryAdjustmentConfig()
      }
      if (code === 1) {
        this.setSalaryTemplateRemindConfig()
      }
    }
  }
}
</script>
<style scoped lang="less">
.info-setting-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.screen_btn{
  position: absolute;
  right: 30px;
  bottom: 30px;
  .close_button{
    margin-right: 20px;
  }
}
.timeNotify {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 14px;
  > span {
    flex-shrink: 0;
  }
}
.more-handle-btn {
  /deep/ .ant-btn {
    padding-left: 0;
  }
}
.number {
  width: 30%;
}
.timeNum {
  width: 30%;
}
.payRoll {
  color: @sc-grey-100;
}
/deep/ .ant-form-item-label > label {
  color: @sc-grey-80;
}
/deep/ .ant-switch-small {
  margin-left: 5px;
}
</style>
