<template>
  <div>
    <formulaView
      ref="formulaView"
      @formulaComplete="sevaFormula"
    />
    <branchFormula
      ref="branchFormula"
      @formulaComplete="sevaFormula"
    />
  </div>
</template>

<script>
import formulaView from './formulaView'
import branchFormula from './branchFormula'
export default {
  name: 'FormulaSetting',
  components: {
    formulaView,
    branchFormula
  },
  props: {
    form: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      settingFormula: false, // fasle 正常设置公式的流程  true只设置公式
      maxLimit: '', // 封顶值
      minLimit: '', // 保底值
      description: '',
      formulaType: '1' // 1：普通计算公式 2：带分支的公式
    }
  },
  methods: {
    open() {
      const { form: { formula }} = this
      console.log('form表单数据', formula)
      this.formulaType = formula.formulaType
      if (formula.formulaType === '1') {
        this.$refs.formulaView.show('', formula)
      }
      if (formula.formulaType === '2') {
        const { description, branchList, minLimit, maxLimit } = formula || {}
        const defaultFormula = {
          description,
          branchList,
          minLimit,
          maxLimit
        }
        this.$refs.branchFormula.show(defaultFormula)
      }
      this.formulaType = formula.formulaType || '1'
      this.maxLimit = formula.maxLimit || ''
      this.minLimit = formula.minLimit || ''
      this.visible = true
      this.$nextTick(() => {
        this.editorShow = true
      })
    },
    // 完成公式
    sevaFormula(formula, formulaList) {
      const { formulaType } = this
      this.form.formula = {
        formulaType,
        ...formula,
        formulaList
      }
      console.log('设置完成了啊 ', this.form)
    }
  }
}
</script>
<style scoped lang="less">
.form-item {
  display: flex;
  .form-label {
    font-weight: 700;
  }
}
</style>
