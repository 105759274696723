<!--
 * @Date: 2022-04-26 17:14:03
 * @descriotion:
-->
<template>
  <div class="setting-container">
    <a-form-model
      ref="ruleForm"
      width="600px"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item ref="attendanceGroupName" label="考勤组名称" prop="attendanceGroupName">
        <a-input v-model="form.attendanceGroupName" placeholder="请输入名称1-30个字符" :max-length="30" />
      </a-form-model-item>
      <a-form-model-item ref="attendanceGroupType" label="考勤组类型" @change="changeType">
        <a-radio-group v-model="form.attendanceGroupType" :options="attendance_group_type" />
      </a-form-model-item>
      <a-form-model-item ref="attendanceGroupStaff" label="选择适用人员" prop="attendanceGroupStaff">
        <CbSelectPersons :data-echo="perDataList" @perModelConfirm="perModelConfirm" />
      </a-form-model-item>
      <a-form-model-item ref="type" label="工时制类型">
        <a-radio-group v-model="form.laborTimeType" :disabled="type === 'edit'" @change="changeLaborTimeType">
          <a-radio v-for="item in labor_time_type" :key="item.value" :disabled="item.value === '2'" :value="item.value">
            {{ item.label }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="form.laborTimeType==='2'" ref="type" label="工时核算周期" prop="laborTimeCycle">
        <a-select v-model="form.laborTimeCycle" style="width: 120px">
          <a-select-option v-for="item in labor_time_cycle" :key="item.value" :slot-scope="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- <a-form-model-item ref="rules" label="日工时核算规则">
        <a-radio-group v-model="form.earlyLateDeductFlag" :options="earlyLatedEductFlag" />
      </a-form-model-item> -->
      <a-form-model-item ref="times" label="考勤标准时长" prop="standardHours">
        <NumberInput v-model="form.standardHours" :decimal-separator="true" placeholder="请输入考勤标准时长" type="number" max="24" suffix="小时/天" />
        <p>用于请假/加班/出差的天数，小时数之间的折算</p>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { attendance_group_type, labor_time_type, labor_time_cycle, earlyLatedEductFlag } from '@/pages/attendance/dict.js'
import NumberInput from '@/components/CbNumber/index'
import { textReg } from '@/utils/reg.js'
export default {
  name: 'BasicInformation',
  components: {
    NumberInput,
    CbSelectPersons: () => import('@/components/CbSelectPersons/index')
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    const validateText = (rule, val, callback) => {
      if (textReg.test(val)) {
        callback(new Error('不允许输入特殊字符'))
      } else {
        callback()
      }
    }
    const validGroupStaff = (rule, val, callback) => {
      if (this.perDataList.length < 1) {
        callback(new Error('请选择人员'))
      } else {
        callback()
      }
    }
    return {
      attendance_group_type: attendance_group_type,
      labor_time_type: labor_time_type,
      labor_time_cycle: labor_time_cycle,
      earlyLatedEductFlag: earlyLatedEductFlag,
      perDataList: [],
      modal: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 10 },
      form: {},
      rules: {
        attendanceGroupName: [
          { required: true, message: '请输入考勤组名称', trigger: 'blur' },
          { min: 1, max: 30, message: '请输入1-30个字符', trigger: 'blur' },
          { validator: validateText, trigger: 'change' }
        ],
        attendanceGroupStaff: [{ required: true, validator: validGroupStaff, trigger: 'change' }],
        standardHours: [
          { required: true, message: '请输入考核标准时长', trigger: 'blur' }
        ],
        laborTimeCycle: [
          { required: true, message: '请输入内容', trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        const { attendanceGroupName, attendanceGroupStaff, attendanceGroupType, laborTimeType, laborTimeCycle, earlyLateDeductFlag, standardHours } = value
        this.form = { attendanceGroupName, attendanceGroupStaff, attendanceGroupType, laborTimeType, laborTimeCycle, earlyLateDeductFlag, standardHours }
        if (attendanceGroupStaff.length > 0 && this.type && this.perDataList.length < 1) {
          this.perDataList = attendanceGroupStaff
        }
      }
    }
  },
  mounted() {

  },
  methods: {
    // 考勤组类型改变
    changeType(value) {
      this.$emit('changeType', value)
    },
    // 选择人员
    perModelConfirm(data) {
      const arr = []
      this.perDataList = data
      this.$refs.ruleForm.validateField('attendanceGroupStaff', (err) => { console.log(err) })
      data.map(item => {
        arr.push({ staffId: item.staffId, onJobId: item.onJobId })
      })
      this.form.attendanceGroupStaff = arr
    },
    // change工时制类型 重置工时核算周期
    changeLaborTimeType(e) {
      if (e.target.value === '1') {
        this.form.laborTimeCycle = undefined
      }
    },
    // 下一步
    next() {
      let flag = false
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          flag = true
          this.$emit('getData', this.form)
        } else {
          this.$message.info('请先完善基本信息')
        }
      })
      return flag
    },
    reset() {
      this.$refs.ruleForm.resetFields()
      this.$emit('resetData', this.form)
    }
  }

}
</script>
<style lang="less" scoped>
/dee/.ant-form-item{
  margin-bottom:24px;
}
.setting-container{
    width:100%;
    padding:20px 43px 34px;
    height:100%;
    overflow: auto;
}
.scopeList{
  display: flex;
  flex-wrap: wrap;
  .person{
    margin: 10px 10px 0 0;
    height:26px;
    border:1px solid @sc-grey-20;
    border-radius: 13px;
    padding: 5px 10px 5px 3px;
    display: flex;
    align-items: center;
    .avatar{
      width:20px;
      height:20px;
      background: @sc-primary-100;
      border-radius: 50%;
      margin-right:5px;
      text-align: center;
      line-height: 20px;
      color:#fff;
      font-size:12px;
    }
  }
}
.setting-container{
  position: relative;
  height:100%;
}
.steps-action{
  width:100%;
  // position:absolute;
  // bottom:60px;
  .btns{
    display: flex;
    justify-content: center;
    .seat{
      width:24px;
    }
  }
}
</style>
