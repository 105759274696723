<template>
  <div>
    <Drawer
      :visible="visible"
      size="small"
      :z-index="2"
      title="编辑"
      confirm-text="保存"
      @close="handleClose"
      @onSubmit="onSubmit"
    >
      <a-form-model ref="ruleForm" :model="editForm" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="所属行业" prop="business">
          <a-select v-model="editForm.business" :allow-clear="true">
            <a-select-opt-group v-for="item in treeData" :key="item.businessTypeId" :label="item.businessTypeName">
              <a-select-option v-for="it in item.businessList" :key="it.businessId" :value="it.businessId">
                {{ it.businessName }}
              </a-select-option>
            </a-select-opt-group>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="企业规模" prop="scale">
          <a-select v-model="editForm.scale" :allow-clear="true">
            <a-select-option v-for="item in scaleList" :key="item.codeKey" :value="item.codeKey">
              {{ item.content }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="注册资本" prop="registerCapital">
          <Number v-model="editForm.registerCapital" placeholder="请输入数字">
            <span slot="addonAfter">万元</span>
          </Number>
        </a-form-model-item>
        <a-form-model-item label="注册地" prop="areaCode">
          <CbSelectArea
            ref="selectArea"
            v-model="editForm.areaCode"
            :province-width="100"
            :city-width="100"
            :area-width="100"
            :auto-fill-in-area-value="false"
            @getStrandValue="onChange"
          />
        </a-form-model-item>
        <a-form-model-item label="统一社会信用代码" prop="uniFormCode">
          <a-input v-model="editForm.uniFormCode" placeholder="请输入正确代码" />
        </a-form-model-item>
        <a-form-model-item label="法人代表" prop="corporate">
          <a-input v-model="editForm.corporate" :max-length="20" placeholder="最多20个字符" />
        </a-form-model-item>
        <a-form-model-item label="联系方式" prop="corporateContact">
          <!-- <a-input v-model="editForm.corporateContact" placeholder="请输入" /> -->
          <Number v-model="editForm.corporateContact" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="邮政编码" prop="postalCode">
          <!-- <a-input v-model="editForm.postalCode" placeholder="请输入" /> -->
          <Number v-model="editForm.postalCode" :maxlength="6" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="营业执照" prop="licenseFileId">
          <UploadImage
            v-decorator="['licenseFileId']"
            :max-sum="1"
            :type-list="['jpeg', 'png', 'jpg']"
            :file-size="5"
            :compress="false"
            :prop-file-list="fileList"
            @change="fileChange"
            @fileDel="fileDel"
          />
        </a-form-model-item>
      </a-form-model>
    </Drawer>
  </div>
</template>

<script>
import { updateTenant } from '@/services/globalSetting/companyInfo.js'
import Number from '@/components/CbNumber/index'
import CbSelectArea from '@/components/CbSelectArea/index'
import { getBusinessList } from '@/services/dictionary/tenant.js'
import UploadImage from '@/components/UploadImage/index'
import { phoneReg, postalNoReg, SocialCreditCode } from '@/utils/reg.js'
export default {
  name: 'InductionForms',
  components: {
    Drawer: () => import('@/components/CbDrawer/index'),
    Number,
    CbSelectArea,
    UploadImage
  },
  props: {
    scaleList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visible: false,
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
      editForm: {},
      treeData: [],
      // scaleList: [],
      areaAdress: {},
      rules: {
        areaCode: [
          { validator: this.areaValid, trigger: ['change', 'blur'] }
        ],
        uniFormCode: [
          {
            pattern: SocialCreditCode,
            message: '统一社会信用代码格式有误，请重新输入',
            trigger: 'blur'
          }
        ],
        corporateContact: [
          {
            pattern: phoneReg,
            message: '请输入正确的手机号',
            trigger: 'blur'
          }
        ],
        postalCode: [
          {
            pattern: postalNoReg,
            message: '请输入正确的邮政编码',
            trigger: 'blur'
          }
        ]
      },
      fileList: []
    }
  },
  created() {
    this.getBusinessList()
  },
  methods: {
    // 打开弹窗
    async open(data) {
      this.visible = true
      this.fileList = []
      this.editForm = JSON.parse(JSON.stringify(data.baseInfoList))
      if (this.editForm.areaCode && this.editForm.areaCode !== '-1') {
        const areaList = this.editForm.areaCode.split(',')
        this.areaAdress = { province: areaList[0], city: areaList[1], county: areaList[2] }
      } else {
        this.areaAdress = { province: undefined, city: undefined, county: undefined }
      }
      this.editForm.areaCode = this.areaAdress
      // this.$set(this.editForm, 'areaCode', this.areaAdress)
      // this.$nextTick(() => {
      //   this.$refs['selectArea'].dataEcho(this.areaAdress)
      // })
      if (this.editForm.licenseFileId) {
        this.fileList.push(data.imgFile)
      } else {
        this.fileList = []
      }
    },
    // 获取行业
    async getBusinessList() {
      const res = await getBusinessList()
      this.treeData = res.data
    },
    areaValid(rule, value, callback) {
      if (!this.areaAdress.province && !value) {
        callback(new Error('请选择所在地区'))
      } else if (this.areaAdress.province && !value) {
        callback(new Error('请选择正确的地址'))
      } else {
        callback()
      }
    },
    onChange(val, text) {
      this.editForm.areaCode = val[2]
      this.areaAdress = { province: val[0], city: val[1], county: val[2] }
      if (val[2]) {
        this.$refs.ruleForm.clearValidate('areaCode')
      }
    },
    // 上传照片
    fileChange(file) {
      this.fileList = file instanceof Array ? file : [file]
    },
    fileDel() {
      this.fileList = []
    },
    // 保存事件
    onSubmit() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid && this.editForm.areaCode !== '-1') {
          const { business, scale, areaCode, registerCapital, uniFormCode, corporate, corporateContact, postalCode } = this.editForm
          const params = {
            business: business || '',
            scale: scale || '',
            registerCapital: registerCapital || '',
            areaCode: areaCode !== '-1' ? areaCode : '',
            uniFormCode: uniFormCode || '',
            corporate: corporate || '',
            corporateContact: corporateContact || '',
            postalCode: postalCode || ''
          }
          if (this.fileList[0] && this.fileList[0].fileId) {
            params['licenseFileId'] = this.fileList[0].fileId
          } else {
            params['licenseFileId'] = ''
            this.editForm.licenseFileId = ''
          }
          const res = await updateTenant(params)
          if (res.code === 100) {
            this.$message.success('编辑成功')
            this.$emit('getFetchDataApiFn', params)
            this.handleClose()
          }
        }
      })
    },
    // 取消事件
    handleClose() {
      this.visible = false
      this.$refs.ruleForm.resetFields()
      this.areaAdress = { province: undefined, city: undefined, county: undefined }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-form-item{
  margin-bottom: 20px;
  .number{
    .ant-input-group-wrapper{
      width: 100% !important;
    }
  }
}
/deep/.ant-input-group .ant-input{
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
/deep/.ant-input-group-addon{
  border: 0;
  background-color: unset;
}
/deep/.ant-upload.ant-upload-select-picture-card{
  width: 320px;
  height: 200px;
  .upload-file-contain img{
    width: 100%;
  }
}
/deep/.ant-select-dropdown-menu-item-group-list{
  padding-left: 15px;
}
/deep/.ant-select-selection-selected-value{
  margin-left: 0 !important;
}
</style>
