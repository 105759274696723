<template>
  <div>
    <Drawer
      is-mask
      display-btn
      title="详情"
      :visible="visible"
      size="small"
      :z-index="3"
      @close="onClose"
    >
      <div>
        <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item label="姓名">
            {{ quitDetailsData.staffName }}
          </a-form-model-item>
          <a-form-model-item label="离职日期">
            {{ quitDetailsData.leaveDate }}
          </a-form-model-item>
          <a-form-model-item label="离职方式">
            {{ quitDetailsData.handleTypeName }}
          </a-form-model-item>
          <a-form-model-item label="离职原因">
            <div>{{ quitDetailsData.leaveReasons }}</div>
          </a-form-model-item>
          <a-form-model-item label="离职备注">
            <div>{{ quitDetailsData.remark }}</div>
          </a-form-model-item>
        </a-form-model>
      </div>
    </Drawer>
  </div>
</template>

<script>
// 待离职详情  已离职详情
import { getLeaveDetail, getLeavedDetail } from '@/services/user/quit.js'
import Drawer from '@/components/CbDrawer/index'
export default {
  name: 'QuitDetails',
  components: {
    Drawer
  },
  data() {
    return {
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      quitDetailsData: {}
    }
  },
  methods: {
    show(id, key) {
      this.visible = true
      this.getLeaveDetail(id, key)
    },
    async getLeaveDetail(id, key) {
      let res
      if (key === '1') {
        res = await getLeaveDetail({
          onJobId: id
        })
      } else if (key === '2') {
        res = await getLeavedDetail({
          onJobId: id
        })
      }

      this.quitDetailsData = res.data
    },
    // 取消
    onClose() {
      this.quitDetailsData = {}
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
  .ant-form-item {
    margin-bottom: 0;
  }
</style>
