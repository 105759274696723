<template>
  <div class="container">
    <DutiesTable />
  </div>
</template>

<script>
export default {
  components: {
    DutiesTable: () => import ('./table.vue')
  }
}
</script>

<style lang="less" scoped>
.container{
    min-height: 100%;
    background-color: #fff;
}
</style>
