<template>
  <div class="DistributionRules">
    <!-- 只有年假 调休假 有减扣规则 -->
    <div v-if="['NXJ','TXJ'].includes(form.vacationTypeCode)">
      <BlockTitle title="扣减规则" :is-bold="true" class="msg-title" />
      <a-form-model-item label="自动扣减规则">
        <a-radio-group v-model="form.vacationDeductType">
          <a-radio :style="largeRadioStyle" value="0">
            一直有效，不扣减
          </a-radio>
          <a-radio :style="largeRadioStyle" value="1">
            发放的假期<CbNumber v-model="form.expiryMonths" class="number-input" :decimal-separator="false" suffix="个月" />
            后自动失效
          </a-radio>
          <a-radio :style="largeRadioStyle" value="2">
            每年固定日期<a-date-picker v-model="form.autoDeductDate" format="MM-DD" value-format="MM-DD" class="large-input" :show-today="false" mode="date" /><br><span style="margin-left:25px">清除</span>
            <a-select v-model="form.deductCurrentYearFlag" default-value="1" :options="clearRange" class="large-input" placeholder="请选择清除范围" @change="onChange">
              <a-select-option value="1">去年及之前</a-select-option>
              <a-select-option value="2">今年及之前</a-select-option>
            </a-select>发放的假期余额
          </a-radio>
        </a-radio-group>
        <a-checkbox v-model="form.dimissionDeductFlag" @change="onChange">人员离职当月直接扣减</a-checkbox>
      </a-form-model-item>
      <a-form-model-item label="折算规则">
        <a-radio-group v-model="form.balanceConvertType">
          <a-radio v-for="convert in vacationConvertList" v-show="convert.codeKey !=='2'" :key="convert.codeKey" :style="radioStyle" :value="convert.codeKey">
            {{ convert.content }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
    </div>
  </div>
</template>

<script>
import { getTenantCode } from '@/services/dropDownInput/index.js'
export default {
  name: 'DistributionRules',
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index'),
    CbNumber: () => import('@/components/CbNumber')
    // DictionariesInput
  },
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      labelCol: { lg: 5, xl: 4, xxl: 3 },
      wrapperCol: { lg: 19, xl: 20, xxl: 21 },
      largeRadioStyle: {
        display: 'block',
        lineHeight: '45px'
      },
      radioStyle: {
        display: 'block',
        lineHeight: '35px'
      },
      clearRange: [
        { label: '去年及之前', value: '1' },
        { label: '今年及之前', value: '2' }
      ],
      vacationConvertList: []
    }
  },
  created() {
    this.getVacationDelay()
  },
  methods: {
    // 获取折算规则
    async getVacationDelay() {
      const convert = await getTenantCode({ codeName: 'vacation_balance_convert_type' })
      this.vacationConvertList = convert.data
    },
    onChange() {
      console.log(this.form.deductCurrentYearFlag)
    }
  }
}
</script>

<style lang="less" scoped>
.DistributionRules {
  .msg-title {
    margin-bottom: 14px;
    margin-top: 2px;
  }
  .number-input {
    width: 91px;
    margin: 0 10px;
    display: inline-block;
  }
  .large-input {
    width: 161px;
    margin: 0 10px;
    display: inline-block;
  }
  /deep/.ant-form-item-label {
    width: 100%;
    font-weight: 600;
  }
  /deep/.ant-form-item label{
    color: @sc-grey-80;
  }
}
/deep/.ant-calendar-header .ant-calendar-year-select ,/deep/.ant-calendar-header .ant-calendar-prev-year-btn ,/deep/.ant-calendar-header .ant-calendar-next-year-btn{
  display: none;
}
</style>
