<template>
  <div class="box">
    <!--  在职信息  -->
    <div class="top_tips_box">
      <p>小程序入职</p>
      <span class="top_tip">用于配置新员工入职后首次登陆小程序查看欢迎信及入职指南</span>
    </div>
    <!--  开关  -->
    <!-- <div class="public_margin_bottom">
      <div v-for="item in entryMatterList" :key="item.itemKey" class="top_checked">
        <span>{{ item.itemName }}</span>
        <a-switch v-model="item.fixedSalary" size="small" class="public_margin_right" @change="entryMatterListChange(item)" />
      </div>
    </div> -->
    <!--  入职引导管理  -->
    <div class="public_margin_bottom">
      <div class="top_box title_box">
        <span class="top_title">
          <BlockTitle title="欢迎信">
            <template slot="left">
              <span class="public_box_title_tip">（最多500字符）</span>
            </template>
          </blocktitle>
        </span>
        <span
          v-if="welcomeDisabled"
          class="public_color_font_size public_margin_right cursor"
          @click="welcomeDisabledEdit"
        >编辑</span>
        <!-- <span class="top_title">入职引导管理</span> -->
      </div>
      <!-- <div class="top_checked">
        <div class="guide">
          <span>入职引导提示期限设置：员工确认入职后提示</span>
          <CbNumber v-model="inputNumber.configContent" :max="30" class="guide_input" :disabled="inductionDayTips" />
          <span>天</span>
          <div class="edit_btn_box">
            <a-button v-if="inductionDayTips" class="day_button" type="primary" size="default" ghost @click="editDayTips">编辑</a-button>
          </div>
          <a-button class="day_button" type="primary" size="default" @click="getDayOk">保存</a-button>
        </div>
        <a-switch v-model="guideValue.switchCheck" size="small" class="public_margin_right" @change="guideValueChange" />
      </div> -->
      <div class="public_box">
        <!-- <div class="public_box_title">

        </div> -->
        <div class="public_box_textarea">
          <a-textarea
            v-model="welcomeValue.configContent"
            :disabled="welcomeDisabled"
            placeholder="请输入"
            :auto-size="{ minRows: 5 }"
          />
        </div>
        <div v-if="!welcomeDisabled" class="public_box_button">
          <a-button class="public_box_button_close" @click="welcomeDisabledClose">取消</a-button>
          <a-button type="primary" @click="welcomeDisabledOk">保存</a-button>
        </div>
      </div>
    </div>
    <!--  入职指南  -->
    <div>
      <div class="top_box public_flex">
        <div>
          <span class="top_title">入职指南</span>
          <span class="top_tip">开启后入职指南帮助员工快速办理入职</span>
        </div>
        <div>
          <span v-if="jobGuide.switchCheck" class="public_color_font_size cursor" @click="jobGuideAdd">
            <svg class="iconpark-icon Circle-Button"><use href="#add-one" /></svg>
            添加
          </span>
          <a-switch v-model="jobGuide.switchCheck" size="small" class="public_margin_right public_margin_left" @change="jobGuideChange" />
        </div>
      </div>
      <!--   代办    -->
      <div v-if="jobGuide.switchCheck">
        <div v-for="(item, index) in needDealt" :key="index" class="public_box">
          <div class="public_box_title">
            <span>
              <BlockTitle :title="`事件${index+1}`">
                <template slot="left">
                  <span class="public_box_title_tip">（最多300字符）</span>
                </template>
              </BlockTitle>
            </span>
            <div>
              <span
                v-if="item.needDealtDisabled"
                class="public_color_font_size public_margin_right cursor"
                @click="getNeedDealtEdit(index)"
              >编辑</span>
              <span v-if="index !== 0" class="public_color_font_size_del public_margin_right cursor" @click="delTable(item,index)">删除</span>
            </div>
          </div>
          <div class="public_box_textarea">
            <a-textarea
              v-model="item.needDealtValue"
              :disabled="item.needDealtDisabled"
              placeholder="请输入"
              :auto-size="{ minRows: 4 }"
            />
          </div>
          <div v-if="!item.needDealtDisabled" class="public_box_button">
            <a-button class="public_box_button_close" @click="getNeedDealClose(index)">取消</a-button>
            <a-button type="primary" @click="getNeedDealOk(item)">保存</a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getEntryMatterList,
  getEntryGuideList,
  getEntryTodoList,
  createEntryTodo,
  updateEntryTodo,
  deleteEntryTodo,
  updateConfigContent
} from '@/services/user/setting.js'
// import CbNumber from '@/components/CbNumber/index'
export default {
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index')
    // CbNumber
  },
  data() {
    return {
      inductionData: {},
      inductionIndex: '',
      entryMatterList: [],
      inputNumber: {
        configContent: ''
      },
      guideValue: {}, // 入职引导
      welcomeValue: {
        configContent: ''
      },
      welcomeDisabled: true,
      jobGuide: {},
      needDealt: [
        {
          needDealtValue: '',
          needDealtDisabled: true
        }
      ],
      inductionDayTips: true,
      hasEditStatus: false
    }
  },
  mounted() {
    this.getEntryMatterList()
    this.getEntryGuideList()
    this.getEntryTodoList()
  },
  methods: {
    // 入职事项配置列表
    async getEntryMatterList() {
      const res = await getEntryMatterList()
      this.entryMatterList = res.data.map(item => {
        return {
          ...item,
          fixedSalary: item.itemStatus !== '0'
        }
      })
    },
    // 入职引导配置列表
    async getEntryGuideList() {
      const res = await getEntryGuideList()
      if (res.data && res.data.length > 0) {
        this.inputNumber = res.data.filter(item => item.configItemName === 'entry_guide_day')[0]
        this.guideValue = res.data.filter(item => item.configItemName === 'entry_guide').map(it => {
          return {
            ...it,
            switchCheck: it.configContent !== '0'
          }
        })[0]
        this.welcomeValue = res.data.filter(item => item.configItemName === 'welcome_letter')[0]
        this.jobGuide = res.data.filter(item => item.configItemName === 'entry_handbook').map(it => {
          return {
            ...it,
            switchCheck: it.configContent !== '0'
          }
        })[0]
      }
    },
    // 入职待办事项列表
    async getEntryTodoList() {
      const res = await getEntryTodoList()
      if (res.data && res.data.length > 0) {
        this.needDealt = res.data.map(item => {
          return {
            ...item,
            needDealtValue: item.todoContent,
            needDealtDisabled: true
          }
        })
      }
    },
    // 顶部配置开关
    async entryMatterListChange(item) {
      const res = await updateConfigContent({
        tenantConfigId: item.tenantConfigId,
        configContent: item.fixedSalary === false ? '0' : '1'
      })
      if (res.code === 100) {
        this.getEntryMatterList()
      }
    },
    // 开启天数可编辑
    editDayTips() {
      this.inductionDayTips = false
    },
    // 天数保存
    async getDayOk() {
      const res = await updateConfigContent({
        tenantConfigId: this.inputNumber.tenantConfigId,
        configContent: this.inputNumber.configContent
      })
      if (res.code === 100) {
        this.$message.success('保存成功')
        this.getEntryGuideList()
        this.inductionDayTips = true
      }
    },
    // 天数开关
    async guideValueChange() {
      const res = await updateConfigContent({
        tenantConfigId: this.guideValue.tenantConfigId,
        configContent: this.guideValue.switchCheck === false ? '0' : '1'
      })
      if (res.code === 100) {
        this.getEntryGuideList()
      }
    },
    // 入职引导编辑
    welcomeDisabledEdit() {
      this.welcomeDisabled = false
    },
    // 入职引导取消
    welcomeDisabledClose() {
      this.welcomeDisabled = true
      this.getEntryGuideList()
    },
    // 欢迎保存
    async welcomeDisabledOk() {
      const res = await updateConfigContent({
        tenantConfigId: this.welcomeValue.tenantConfigId,
        configContent: this.welcomeValue.configContent
      }).catch(err => err)
      if (res.code === 100) {
        this.$message.success('保存成功')
        this.welcomeDisabled = true
        this.getEntryGuideList()
      }
    },
    // 入职指南添加
    jobGuideAdd() {
      if (this.hasEditStatus) {
        this.$message.warning('当前有正在编辑的内容')
        return
      } else {
        this.needDealt.push(
          {
            needDealtValue: '',
            needDealtDisabled: false
          }
        )
      }

      this.hasEditStatus = true
    },
    // 入职指南开关
    async jobGuideChange(val) {
      const res = await updateConfigContent({
        tenantConfigId: this.jobGuide.tenantConfigId,
        configContent: this.jobGuide.switchCheck === false ? '0' : '1'
      })
      if (res.code === 100) {
        this.getEntryGuideList()
      }
    },
    // 待办编辑
    getNeedDealtEdit(index) {
      if (this.hasEditStatus) {
        this.$message.warning('当前有正在编辑的内容')
        return
      }
      this.needDealt[index].needDealtDisabled = false
      this.hasEditStatus = true
    },
    // 入职指南删除
    delTable(item, index) {
      this.inductionData = item
      this.inductionIndex = index
      this.$Reconfirm({
        title: '删除',
        content: '是否确认删除？'
      }).then(async() => {
        if (this.inductionData.tenantTodoId) {
          const res = await deleteEntryTodo({ tenantTodoId: this.inductionData.tenantTodoId })
          if (res.code === 100) {
            this.$message.success('已删除')
            this.needDealt.splice(this.inductionIndex, 1)
          }
        } else {
          this.$message.success('已删除')
          this.needDealt.splice(this.inductionIndex, 1)
        }
      }).catch(() => {
        console.log('no')
      })
    },
    // 入职指南取消
    getNeedDealClose(index) {
      this.getEntryTodoList()
      this.hasEditStatus = false
    },
    // 入职指南保存
    async getNeedDealOk(item) {
      if (item.needDealtValue === '') {
        this.$message.warning('事项内容不能为空')
        return
      }
      if (item.tenantTodoId) {
      //  更新
        const res = await updateEntryTodo({
          tenantTodoId: item.tenantTodoId,
          todoContent: item.needDealtValue
        })
        if (res.code === 100) {
          this.$message.success('更新成功')
          this.getEntryTodoList()
          this.hasEditStatus = false
        }
      } else {
      //  新增
        const res = await createEntryTodo({ todoContent: item.needDealtValue })
        if (res.code === 100) {
          this.$message.success('新增成功')
          this.getEntryTodoList()
          this.hasEditStatus = false
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
* {
  font-size: 14px;
}
.top_tips_box{
        margin-bottom: 20px;
}
  .box {
    height:100%;
    overflow: auto;
    padding: 20px;
    box-sizing: border-box;
    p{
      .font-size(14px);
      color: @sc-grey-100;
      margin: 0;
    }
    .top_box {
      display: flex;
      align-items: center;
      height: 14px;
     .font-size(14px);
      margin-bottom: 20px;
      // border-left: 3px solid @sc-primary-100;
      // margin-top: 4px;
      .top_title{
        color: @sc-grey-100;
        margin: 0 14px 0 5px;
      }
      .top_tip {
        color: @sc-grey-60;
      }
    }
    .top_checked {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      font-size: 14px;
      color: @sc-grey-100;
      border-bottom: 1px solid @sc-greyBg-20;
    }
    .guide {
      font-size: 14px;
      .guide_input {
        display: inline-block;
        width: 44px;
        height: 32px;
        margin: 0 10px;
        /deep/.ant-input{
          width: 100% !important;
        }
      }
    }
  }
  .public_box {
    .public_box_title {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 16px;
      margin-top: 20px;
      font-size: 14px;
      color: @sc-grey-100;
      .public_box_title_tip {
        opacity: 60%;
      }
    }
    .public_box_textarea {
      margin: 20px 0 36px 0;
    }
    .public_box_button {
      text-align: center;
      margin-bottom: 20px;
      .public_box_button_close {
        margin-right: 12px;
      }
    }
  }
  .day_button {
    margin-left: 10px;
  }
  .public_margin_right {
    margin-right: 24px;
  }
  .public_margin_left {
    margin-left: 17px;
  }
  .public_margin_bottom {
    margin-bottom: 30px;
    .title_box{
      display: flex;
      justify-content: space-between;
      .public_box_title_tip{
        opacity: 60%;
      }
    }
  }
  .public_color_font_size {
    font-size: 14px;
    color: @sc-primary-100;
  }
  .public_color_font_size_del {
    font-size: 14px;
    color: @error-color;
  }
  .public_flex {
    display: flex;
    justify-content: space-between;
  }
  .cursor {
    cursor: pointer;
  }
  .edit_btn_box{
    display: inline-block;
    width: 80px;
    margin-left: 20px;
    margin-right: 10px
  }
  .Circle-Button {
  width: 16px;
  height: 16px;
  margin-top:3px;
  color: @sc-primary-100;
  vertical-align: top;
}
</style>
