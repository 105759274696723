<template>
  <div class="container">
    <div class="user-info-header">
      <div class="user-info-header-left">
        <div class="user-info-header-left-t">入职事项管理</div>
        <div class="user-info-header-left-b">
          用于配置新员工入职后HR为员工办理的事项
        </div>
      </div>
    </div>
    <div class="check_group">
      <p v-for="item in entryMatterList" :key="item.tenantConfigId"><span>{{ item.itemName }}</span><a-switch v-model="item.status" size="small" @change="onChange(item)" /></p>
    </div>
  </div>
</template>

<script>
import { getEntryMatterList, updateConfigContent } from '@/services/user/setting.js'
export default {
  data() {
    return {
      entryMatterList: []
    }
  },
  mounted() {
    this.getEntryMatterList()
  },
  methods: {
    async onChange(item) {
      const res = await updateConfigContent({
        tenantConfigId: item.tenantConfigId,
        configContent: item.status === false ? '0' : '1'
      })
      if (res.code === 100) {
        this.getEntryMatterList()
      }
    },
    async getEntryMatterList() {
      const res = await getEntryMatterList()
      if (res.code === 100) {
        this.entryMatterList = res.data.map((item) => {
          item.status = item.itemStatus === '1'
          return {
            ...item
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container{
    padding: 20px 20px 0;
    .check_group{
        margin-top: 20px;
        p{
            .font-size(14px);
            font-weight: 600;
            span{
                display: inline-block;
                width: 160px;
            }
        }
    }
    }
.user-info-header {
    margin-bottom: 10px;
    .flex-between-center();
    .user-info-header-left {
      .user-info-header-left-t {
        font-weight: bold;
        .font-size(16px);
        color: @sc-grey-100;
      }
      .user-info-header-left-b {
        margin-top: 7px;
        .font-size(14px);
        color: @sc-grey-60;
      }
    }
    .user-info-header-right {
      /deep/.link{
        color: @sc-grey-80;
        margin-right: 12px;
        .iconpark-icon{
          color: @sc-grey-80;
        }
        &:hover{
          color: @sc-primary-100;
          .iconpark-icon{
            color: @sc-primary-100;
          }
        }
      }
    }
  }
</style>
