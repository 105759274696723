import { render, staticRenderFns } from "./changedTable.vue?vue&type=template&id=524bc14d&scoped=true&"
import script from "./changedTable.vue?vue&type=script&lang=js&"
export * from "./changedTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "524bc14d",
  null
  
)

export default component.exports