<template>
  <div ref="echarts" class="statistics" />
</template>
<script>
import { getYearBill } from '@/services/home/insurance.js'
import { changePersonnel } from '@/services/home/user.js'
import { attendanceDayPercent } from '@/services/home/attendance.js'
import * as echarts from 'echarts/core'
import { GridComponent, TooltipComponent } from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import moment from 'moment'
import { getSalaryCalcList } from '@/services/wage/home'
echarts.use([GridComponent, TooltipComponent, LineChart, CanvasRenderer, UniversalTransition])

export default {
  name: 'StatisticsEchart',
  props: {
    type: {
      type: [Number, String],
      default: undefined
    },
    dataSource: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      configs: {
        1: {
          xName: '月',
          yName: '在职人数',
          seriesName: '在职人数',
          interval: 0
        },
        2: {
          xName: '日',
          yName: '出勤率(%)',
          seriesName: '出勤率',
          interval: 4
        },
        3: {
          xName: '月',
          yName: '人力成本(万元)',
          seriesName: '金额',
          interval: 0
        },
        4: {
          xName: '月',
          yName: '险金成本(元)',
          seriesName: '险金成本',
          interval: 0
        }
      },
      echartsData: undefined
    }
  },
  watch: {
    type(newVal) {
      switch (newVal) {
        case '1':
          this.getChangePersonnel()
          break
        case '2':
          // 获取出勤率
          this.attendanceDayPercent()
          break
        case '3':
          // 获取薪酬数据
          this.getSalaryCalcList()
          break
        case '4':
          // 获取险金数据
          this.getYearBill()
          break
        default:
          this.individual()
      }
    }
  },
  mounted() {
    this.getChangePersonnel()
  },
  methods: {
    individual() {
      var that = this
      var chartDom = that.$refs.echarts
      var myChart = echarts.init(chartDom)
      const configObj = that.configs[+that.type]
      const option = {
        textStyle: {
          fontFamily: 'PingFang SC-常规体, PingFang SC'
        },
        grid: {
          left: 14,
          right: 25,
          top: 30,
          bottom: 0,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          name: configObj.xName,
          boundaryGap: false,
          axisLine: {
            show: false // 隐藏x轴
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: configObj.interval, // 跨格显示
            color: 'rgba(32, 42, 64, 0.4)'
          },
          splitLine: { // 网格线
            show: true,
            // x 轴网格线
            lineStyle: {
              color: 'rgba(229, 232, 239, 0.3)'
            }
          },
          axisPointer: {
            snap: true,
            lineStyle: {
              color: 'rgba(81, 121, 251, 1)',
              width: 2
            },
            label: {
              show: true,
              formatter: function(params) {
                if (that.type === '2') {
                  const labelNum = params.seriesData[0].data[1].toString()
                  params.seriesData[0].data[1] = labelNum.includes('%') ? `${labelNum}` : `${labelNum}` + '%'
                  return moment(params.seriesData[0].data[2]).format('YYYY-MM-DD')
                }
                return `${new Date().getFullYear()}-${params.value}`
              },
              backgroundColor: '#7581BD'
            }
            // handle: {
            //   show: true,
            //   color: '#7581BD'
            // }
          }
        },
        yAxis: {
          type: 'value',
          name: configObj.yName,
          boundaryGap: false,
          axisLabel: {
            color: 'rgba(32, 42, 64, 0.4)'
          },
          splitLine: { // 网格线
            show: true,
            // y 轴网格线
            lineStyle: {
              color: 'rgba(229, 232, 239, 0.3)'
            }
          }
        },
        tooltip: {
          trigger: 'axis',
          // 设置浮层的 css 样式
          extraCssText: 'position: absolute;min-width:180px;height:66px;backdrop-filter: blur(31px);background-color:rgba(255, 255, 255, 0.6);color:rgba(32, 42, 64, 1);border-radius:6px;box-shadow: 0px 1px 14px 2px rgba(54, 103, 197, 0.1);'
        },
        series: [
          {
            type: 'line',
            name: configObj.seriesName,
            smooth: false,
            symbol: 'none',
            lineStyle: {
              color: 'rgba(75, 186, 255, 1)',
              width: 2
            },
            markLine: {
              symbol: ['none', 'none'],
              label: { show: false }

            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0.5, color: 'rgba(42, 139, 255, 0.16)' },
                    { offset: 1, color: 'rgba(16, 128, 255, 0)' }
                  ]
                )
              },
              shadowColor: 'rgba(0, 0, 0, 0.5)',
              opacity: 0.2
            },
            data: this.echartsData
          }
        ]
      }
      option && myChart.setOption(option)
    },
    // 获取出勤率
    async attendanceDayPercent() {
      const res = await attendanceDayPercent()
      if (res.code === 100) {
        this.echartsData = res.data.dayAttendanceRatio.map(v => {
          return [
            v.shiftDate.slice(-2), v.percent, v.shiftDate
          ]
        })
        this.individual()
      }
    },
    // 获取人员变动数据
    async getChangePersonnel() {
      const res = await changePersonnel()
      if (res.code === 100) {
        this.echartsData = res.data.map(v => {
          return [
            v.month, v.staffCount
          ]
        })
        this.individual()
      }
    },
    async getSalaryCalcList() {
      const res = await getSalaryCalcList()
      this.echartsData = Object.entries(res.data).map(item => {
        const key = item[0]
        const val = item[1]
        return [String(key).substring(String(key).length - 2), val.laborCostSumCount]
      })
      this.individual()
    },
    async getYearBill() {
      const res = await getYearBill()
      this.echartsData = res.data.map(v => {
        return [
          v.insureMonth.slice(-2), v.total
        ]
      })
      this.individual()
    }
  }
}
</script>
<style lang="less" scoped>
.statistics{
    width: 100%;
    height: 223px;
}
</style>
