<template>
  <div class="TaxDrawer">
    <!-- 弹出层 -->
    <Drawer
      :visible="accountVisible"
      size="small"
      closable
      :z-index="100"
      :is-mask="false"
      :title="drawerTypeStr==='edit'?'编辑':'新增'+ '报税主体'"
      @close="cancelDrawer"
      @onSubmit="onSave"
    >
      <div v-if="destroyElement" class="card-content">
        <a-form-model
          ref="taxForm"
          :model="taxForm"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="企业名称" prop="companyName">
            <a-input v-model="taxForm.companyName" placeholder="请输入企业名称" />
          </a-form-model-item>
          <a-form-model-item label="税号" prop="taxNum">
            <a-input v-model="taxForm.taxNum" :disabled="drawerTypeStr==='edit'? true:false" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="所属地区" prop="areaId">
            <SelectArea
              ref="selectArea"
              @getStrandValue="getStrandValue"
            />
          </a-form-model-item>
          <a-form-model-item v-if="taxForm.pwdType ==='手机短信验证码'" label="手机验证码" prop="telephone">
            <a-input v-model="taxForm.telephone" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="报税周期" prop="taxFilingPeriodType">
            <a-select v-model="taxForm.taxFilingPeriodType" placeholder="请选择">
              <a-select-option v-for="(item) in taxCycleList" :key="item.codeKey" :value="item.codeKey">{{ item.content }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="报税人员" prop="person">
            <SelectPerson v-model="model" :query-leave-person="true" :data-echo="drawerTypeStr === 'add' ? taxForm.onJobIdList : spareArr" :placeholder="'请输入部门、人员、工号'" @perModelConfirm="perModelConfirm" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { createTaxpaySubject, updateTaxpaySubject, getTaxpayPeriodList } from '@/services/wage/account.js'
import SelectArea from '@/components/CbSelectArea/index.vue'
import SelectPerson from '@/components/CbSelectPersons/index.vue'
import { debounce } from '@/utils/index.js'
import mixins from '@/components/CbPerDepSelection/index.js'
import { SocialCreditCode, companyNameReg } from '@/utils/reg.js'
export default {
  name: 'TaxDrawer',
  components: {
    Drawer: () => import('@/components/CbDrawer'),
    SelectArea,
    SelectPerson
  },
  mixins: [mixins],
  props: {
    drawerType: {
      type: String,
      default: 'add'
    }
  },
  data() {
    return {
      destroyElement: false,
      accountVisible: false,
      model: false,
      labelCol: { lg: 5, xl: 4, xxl: 6 },
      wrapperCol: { lg: 19, xl: 20, xxl: 18 },
      taxForm: {
        companyName: undefined,
        taxNum: undefined,
        areaId: undefined,
        taxFilingPeriodType: undefined,
        onJobIdList: []
      },
      rules: {
        companyName: [
          { required: true, validator: (rule, value, callback) => {
            if (!value) {
              callback('请输入公司名称')
              return
            } else if (!companyNameReg.test(value)) {
              callback('公司名称不能包含数字、字母、特殊字符')
              return
            }
            callback()
          }, trigger: 'blur' }
        ],
        taxNum: [
          { required: true, validator: this.uniCodeValid, trigger: 'blur' }
        ],
        areaId: [
          { required: true, message: '请选择所属地区', trigger: 'change' }
        ],
        taxFilingPeriodType: [
          { required: true, message: '请选择周期', trigger: 'change' }
        ],
        person: [
          { required: true, validator: this.personValid, trigger: 'change' }
        ]
      },
      companyList: [],
      taxCycleList: [],
      spareArr: [],
      taxpaySubjectId: ''
    }
  },
  computed: {
    drawerTypeStr: {
      get() {
        return this.drawerType
      },
      set() {
      }
    }
  },
  mounted() {
    this.getCycleList()
  },
  methods: {
    onSave() {
      this.$refs.taxForm.validate(async valid => {
        if (valid) {
          this.onSubmit()
          return
        }
        return this.$message.error('输入信息不完整！')
      })
    },
    // 提交新增 或 编辑
    onSubmit: debounce(async function() {
      let res
      if (this.drawerTypeStr === 'edit') {
        res = await updateTaxpaySubject({ ...this.taxForm, taxpaySubjectId: this.taxpaySubjectId })
      } else {
        res = await createTaxpaySubject({ ...this.taxForm, onJobIdList: this.taxForm.onJobIdList.map((v) => { return v.onJobId }) })
      }
      if (res.code === 100) {
        this.$message.success(`${this.drawerTypeStr === 'edit' ? '编辑' : '新增'}成功！`)
        this.$parent.serchGetData()
        this.cancelDrawer()
      }
      return
    }, 500),

    open() {
      this.accountVisible = true
      this.destroyElement = true
      this.$forceUpdate()
    },

    confirmAccount() {
      this.cancelDrawer()
    },
    // 删除已选中的人员
    delUser(onJobId) {
      if (this.drawerTypeStr === 'edit') {
        this.spareArr = this.spareArr.filter((v) => v.onJobId !== onJobId)
        this.taxForm.onJobIdList = this.taxForm.onJobIdList.filter((v) => v !== onJobId)
      } else {
        this.taxForm.onJobIdList = this.taxForm.onJobIdList.filter((v) => v.onJobId !== onJobId)
      }
    },
    perModelConfirm(data) {
      this.drawerTypeStr === 'add' ? this.taxForm.onJobIdList : this.spareArr = data.map((v) => {
        return {
          onJobId: v.onJobId,
          staffName: v.staffName,
          show: true
        }
      })
      if (this.drawerTypeStr === 'edit') {
        this.taxForm.onJobIdList = data.map((v) => {
          return v.onJobId
        })
      } else {
        this.taxForm.onJobIdList = data.map((v) => {
          return {
            onJobId: v.onJobId,
            staffName: v.staffName,
            show: true
          }
        })
      }
      this.model = false
      // change校验报税人员
      if (data.length >= 0) {
        this.$refs.taxForm.validateField('person')
      }
    },
    getStrandValue(resCode, areaCodeArr) {
      if (resCode[2] !== '-1' && resCode[2]) {
        this.taxForm.areaId = resCode[2]
        this.$set(this.taxForm, 'areaId', resCode[2])
        this.$refs.taxForm.validateField('areaId')
        return
      }
      this.taxForm.areaId = ''
    },
    // 关闭抽屉时清空表单内容
    cancelDrawer() {
      this.accountVisible = false
      this.destroyElement = false
      this.taxForm = {
        companyName: undefined,
        taxNum: undefined,
        areaId: undefined,
        taxFilingPeriodType: undefined,
        onJobIdList: []
      }
      this.$refs.selectArea.closeClearValue()
    },
    clearChangeUser() {
      this.taxForm.onJobIdList = []
    },
    async getCycleList() {
      await getTaxpayPeriodList().then((res) => {
        this.taxCycleList = res.data
      })
    },
    // 点击编辑 填充数据
    editFill(scope) {
      let arr = []
      if (scope.onJobIdList && scope.onJobIdList.length > 0) {
        scope.onJobIdList.forEach((item, index) => {
          const obj = {
            onJobId: item,
            staffName: scope.staffNameList[index],
            show: true
          }
          arr.push(obj)
        })
      } else {
        arr = []
      }
      this.taxForm = {
        companyName: scope.companyName,
        taxNum: scope.taxNum,
        areaId: scope.areaId,
        taxFilingPeriodType: scope.taxFilingPeriodType,
        onJobIdList: scope.onJobIdList ? scope.onJobIdList : []
      }
      this.spareArr = arr
      this.taxpaySubjectId = scope.taxpaySubjectId
      const e = {
        province: scope.areaCode.split(',')[0],
        city: scope.areaCode.split(',')[1],
        county: scope.areaCode.split(',')[2]
      }
      this.$nextTick(() => {
        this.$refs.selectArea.dataEcho(e)
      })
    },
    uniCodeValid(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入税号'))
      } else if (value && SocialCreditCode.test(value)) {
        callback()
      } else if (value && !SocialCreditCode.test(value)) {
        callback(new Error('税号格式有误，请重新输入'))
      }
    },
    personValid(rule, value, callback) {
      if (this.taxForm.onJobIdList.length === 0) {
        callback('请选择报税部门或人员')
        return
      } else {
        callback()
      }
    }
    // handleSearch(value) {
    //   this.handleChange(value)
    // },
    // handleChange(value) {
    //   this.taxForm.companyName = value || undefined
    // },
    // handleBlur(value) {
    //   this.taxForm.companyName = value
    // }
  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-form-item-label > label {
  color: @sc-grey-80;
}
</style>
