<template>
  <div class="AffairList">
    <div v-for="(item, index) in affairList" :key="index" class="affair-list-item" @click="getCbApproveSteps(item)">
      <div class="list-left">
        <div class="list-title flex-align-center">{{ item.flowName }}<span class="status-label" :style="`background-color: ${item.bgColor};color: ${item.color};`">{{ item.name }}</span></div>
        <div class="list-box">
          <span v-for="(v,i) in item.summaries" :key="i" class="list-date">
            <span>{{ v.label }}</span>
            <span>{{ v.value }}</span>
          </span>
        </div>
      </div>
      <div class="list-right">
        <div class="list-info">
          <CbAvater :on-job-id="item.initiatorId" :avater-name="item.initiatorName" size="24" :default-name-length="1" :show-user-name="false" />
          <span v-if="item.initiatorName !== undefined &&item.initiatorName.length <4" class="user-name">{{ item.initiatorName }}</span>
          <a-tooltip v-else class="user-name">
            <template slot="title">
              {{ item.initiatorName }}
            </template>
            <span v-if="item.initiatorName !== undefined">{{ item.initiatorName.slice(0,3) }}...</span>
          </a-tooltip>
        </div>
        <div class="list-time">{{ item.endTime }}</div>
      </div>
    </div>
    <CbApproveSteps
      v-model="stepsVisible"
      :flow-id="flowId"
      :apply-status="applyStatus"
      :apply-status-list="statusNameList"
      is-home
    >
      <template #approveButton="{ participant }">
        <div v-if="isRole(participant.role)" class="approve-button">
          <a-button type="link" @click="addSend(participant.flowRecordParticipantId)">抄送</a-button>
          <a-button type="link" @click="transfer(participant.flowRecordParticipantId)">转交</a-button>
          <a-button @click="refuse(participant)">拒绝</a-button>
          <a-button @click="agree(participant)">同意</a-button>
        </div>
      </template>
    </CbApproveSteps>
    <TransferModal ref="transferModal" @refresh="$emit('refreshList')" />
    <ApprovalModal ref="approvalModal" @refresh="$emit('refreshList')" />
    <AddSendModal ref="addSendModal" />
  </div>
</template>

<script>
export default {
  name: 'AffairList',
  components: {
    CbAvater: () => import('@/components/CbAvater/theAvater.vue'),
    CbApproveSteps: () => import('@/components/CbApproveSteps/index'),
    TransferModal: () => import('./transferModal.vue'),
    ApprovalModal: () => import('./approvalModal.vue'),
    AddSendModal: () => import('./addSendModal.vue')
  },
  props: {
    affairList: {
      type: Array,
      default: () => []
    },
    statusNameList: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      stepsVisible: false,
      flowId: '',
      applyStatus: '',
      // 当前选中审批对象
      currentApprove: {}
    }
  },
  methods: {
    getCbApproveSteps(item) {
      this.flowId = item.flowRecordId
      this.applyStatus = item.processApproveStatus
      this.currentApprove = item
      this.stepsVisible = true
    },
    // 不予办理
    refuse({ flowRecordParticipantId, flowName, opinionRequired, signRequired }) {
      console.log(flowName, opinionRequired)
      this.stepsVisible = false
      this.$refs.approvalModal.openModal({ ...this.currentApprove, flowRecordParticipantId, flowName, opinionRequired, signRequired, approvalStatus: 'reject' })
    },
    // 同意办理
    agree({ flowRecordParticipantId, flowName, opinionRequired, signRequired }) {
      this.stepsVisible = false
      this.$refs.approvalModal.openModal({ ...this.currentApprove, flowRecordParticipantId, flowName, opinionRequired, signRequired, approvalStatus: 'accept' })
    },
    // 转交
    transfer(flowRecordParticipantId) {
      this.stepsVisible = false
      this.$refs.transferModal.openModal({ ...this.currentApprove, flowRecordParticipantId })
    },
    // 抄送
    addSend(flowRecordParticipantId) {
      this.stepsVisible = false
      this.$refs.addSendModal.openModal({ ...this.currentApprove, flowRecordParticipantId })
    },
    isRole(role) {
      return role?.includes('approver')
    }
  }
}
</script>

<style lang="less" scoped>
.CompanyAffair {
  .affair-list-item {
    display: flex;
    padding: 12px 14px 14px 14px;
    height: 84px;
    border: 1px solid @border-color;
    border-radius: 6px;
    justify-content: space-between;
    margin-bottom: 16px;
    .font-size(14px);
    color: @sc-grey-100;
    .list-left {
      width:79%;
      .list-title {
        font-weight: bold;
        .font-size(16px);
        .status-label{
          border-radius: 2px;
          margin-left:14px;
          .font-size(12px);
          font-weight: 500;
          padding:0px 9px;
        }
      }
      .list-box{
        .multi-ellipsis(1);
        margin-top: 10px;
        .list-date {
          display:inline-block;
          margin-left: 30px;
         span:first-child {
            color: @sc-grey-60;
           margin-right: 14px;
          }
        }
         .list-date:first-child{
            margin-left:0 ;
         }
      }
    }
    .list-right {
      width:21%;
      .list-info {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .user-name{
            margin-left: 6px;
            color: @sc-grey-100;
        }
      }
      .list-time {
        text-align:right;
        .multi-ellipsis(1);
        margin-top: 10px;
        color: @sc-grey-60;
      }
    }
  }
  .affair-list-item:hover {
    box-shadow: 0px 1px 17px 1px rgba(41, 55, 100, 0.06);
  }
}
.approve-button{
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    left: 0;
    background: #fff;
    border-radius: 0 0 4px 4px;
    /deep/.ant-btn{
      margin-left:16px
    }
  }
</style>
