<template>
  <div class="attendanceitems beauty-scroll">
    <BlockTitle title="考勤合计项" class="attendanceitems-one-heared" />
    <custom-table
      :columns="columns"
      :data-source="total"
      :pagination="false"
      row-key="attendanceItemId"
    >
      <!-- 是否启用 -->
      <template slot="status" slot-scope="{scope}">
        <a-switch v-model="scope.record.status" size="small" disabled @click="onClick(scope)" />
      </template>
      <!-- 单位 -->
      <template slot="unit" slot-scope="{scope}">
        <a-checkbox v-model="scope.record.enableFirstUnitFlag" @change="preferredChange(scope)">
          {{ scope.record.firstUnitText }}
        </a-checkbox>
        <a-checkbox v-model="scope.record.enableSecondUnitFlag" disabled>
          {{ scope.record.secondUnitText }}
        </a-checkbox>
      </template>
    </custom-table>
    <BlockTitle title="迟到、早退、旷工" class="attendanceitems-one-heared" />
    <custom-table
      :columns="columns"
      :data-source="error"
      :pagination="false"
      row-key="attendanceItemId"
    >
      <!-- 是否启用 -->
      <template slot="status" slot-scope="{scope}">
        <a-switch v-model="scope.record.status" size="small" @click="onClick(scope)" />
      </template>
      <!-- 单位 -->
      <template slot="unit" slot-scope="{scope}">
        <div v-if="scope.record.multipleUnitFlag">
          <a-checkbox v-model="scope.record.enableFirstUnitFlag" :disabled="scope.record.status===false" @change="preferredChange(scope)">
            {{ scope.record.firstUnitText }}
          </a-checkbox>
          <a-checkbox v-model="scope.record.enableSecondUnitFlag" :disabled="scope.record.status===false" @change="secondaryChange(scope)">
            {{ scope.record.secondUnitText }}
          </a-checkbox>
        </div>
        <span v-else>{{ scope.record.firstUnitText }}</span>
      </template>
    </custom-table>
    <BlockTitle title="加班" class="attendanceitems-one-heared" />
    <custom-table
      :columns="columns"
      :data-source="overtime"
      :pagination="false"
      row-key="attendanceItemId"
    >
      <!-- 是否启用 -->
      <template slot="status" slot-scope="{scope}">
        <a-switch v-model="scope.record.status" size="small" @click="onClick(scope)" />
      </template>
      <!-- 单位 -->
      <template slot="unit" slot-scope="{scope}">
        {{ scope.record.firstUnitText }}
      </template>
    </custom-table>
    <BlockTitle title="外出" class="attendanceitems-one-heared" />
    <custom-table
      :columns="columns"
      :data-source="out"
      :pagination="false"
      row-key="attendanceItemId"
    >
      <!-- 是否启用 -->
      <template slot="status" slot-scope="{scope}">
        <a-switch v-model="scope.record.status" size="small" @click="onClick(scope)" />
      </template>
      <!-- 单位 -->
      <template slot="unit" slot-scope="{scope}">
        <div v-if="scope.record.multipleUnitFlag">
          <a-checkbox v-model="scope.record.enableFirstUnitFlag" :disabled="scope.record.status===false" @change="preferredChange(scope)">
            {{ scope.record.firstUnitText }}
          </a-checkbox>
          <a-checkbox v-model="scope.record.enableSecondUnitFlag" :disabled="scope.record.status===false" @change="secondaryChange(scope)">
            {{ scope.record.secondUnitText }}
          </a-checkbox>
        </div>
        <span v-else>{{ scope.record.firstUnitText }}</span>
      </template>
    </custom-table>
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import { getAttendanceItem, updateAttendanceItem } from '@/services/attendance/settingset.js'
export default {
  name: 'Attendanceitems',
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index')
  },
  mixins: [mixins],
  data() {
    return {
      // 外出
      out: [],
      // 考勤合计项
      total: [],
      // 考勤异常
      error: [],
      // 加班
      overtime: [],
      columns: [
        {
          title: '考勤项',
          dataIndex: 'attendanceItemName',
          width: '300px',
          key: 'attendanceItemName',
          customCell: () => {
            return {
              style: {
                'min-width': '120px'
              }
            }
          }
        },
        {
          title: '是否启用',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          width: '300px',
          key: 'status',
          customCell: () => {
            return {
              style: {
                'min-width': '60px'
              }
            }
          }
        },
        {
          title: '单位',
          dataIndex: 'unit',
          scopedSlots: { customRender: 'unit' },
          key: 'unit',
          customCell: () => {
            return {
              style: {
                'min-width': '300px'
              }
            }
          }
        }
      ],
      plainOptions: ['小时', '天'],
      multipleOptions: ['分钟数', '次数']
    }
  },
  created() {
    this.getAttendanceItem()
  },
  methods: {
    getAttendanceItem() {
      getAttendanceItem({}).then(res => {
        if (res.code === 100) {
          this.total = res.data.total
          this.error = res.data.error
          this.overtime = res.data.overtime
          this.out = res.data.out
        }
      })
    },
    onClick(scope) {
      if (!scope.record.status) {
        scope.record.enableFirstUnitFlag = false
        scope.record.enableSecondUnitFlag = false
      } else {
        if (scope.record.multipleUnitFlag) {
          if (scope.record.secondUnitText === '天') {
            scope.record.enableSecondUnitFlag = true
          } else {
            scope.record.enableFirstUnitFlag = true
          }
        }
      }
      updateAttendanceItem(scope.record).then(res => {
        if (res.code === 100) {
          this.$message.success('设置成功')
        } else {
          this.getAttendanceItem()
        }
      })
    },
    preferredChange(scope) {
      if (!scope.record.enableFirstUnitFlag && !scope.record.enableSecondUnitFlag) {
        scope.record.status = false
      }
      updateAttendanceItem(scope.record).then(res => {
        if (res.code === 100) {
          this.$message.success('设置成功')
        } else {
          this.getAttendanceItem()
        }
      })
    },
    secondaryChange(scope) {
      if (!scope.record.enableFirstUnitFlag && !scope.record.enableSecondUnitFlag) {
        scope.record.status = false
      }
      updateAttendanceItem(scope.record).then(res => {
        if (res.code === 100) {
          this.$message.success('设置成功')
        } else {
          this.getAttendanceItem()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.attendanceitems{
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 20px 20px;
}
.attendanceitems-one{
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
}
.attendanceitems-one-heared{
margin: 38px 0 16px ;
position: relative;
&:nth-child(1){
  margin: 0 0 16px;
}
}
.attendanceitems-one-conte{
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 8px;
  .content{
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid @sc-greyBg-50;
    span{
      width: 33%;
      display: flex;
      align-items: center;
    }
  }
}
/deep/.ant-table-wrapper .ant-checkbox-wrapper{
  display: inline-block
}
/deep/ .ant-switch-small {
  margin-left: 15px;
}
</style>
