<template>
  <div id="week" class="week">
    <div>
      <div class="toobar-left">
        <svg class="iconpark-icon"><use href="#rongqi" /></svg>
        <DateWeek @change="onChange" />
      </div>
      <CbStatistics :data-list="statisticsList" length="6" class="statistics" text-align="center" />
      <div class="title">周排班统计</div>
      <custom-table
        id="table"
        :columns="columns"
        row-key="index"
        :data-source="dataSource"
        :pagination="false"
      />
    </div>
  </div>
</template>
<script>
import mixins from '@/components/CustomTable/index.js'
import moment from 'moment'
import { staffShiftCountApi, weekDetailApi } from '@/services/attendance/scheduling.js'
export default {
  name: 'Week',
  components: {
    DateWeek: () => import('@/components/CbDateWeek'),
    CbStatistics: () => import('@/components/CbStatistics/index')
  },
  mixins: [mixins],
  data() {
    return {
      // 时间区间
      time: [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')],
      columns: [{
        title: '班次',
        dataIndex: 'shiftName',
        key: 'shiftName'
      },
      {
        title: '周统计',
        dataIndex: 'shiftDateTotalNum',
        key: 'shiftDateTotalNum'
      }],
      dataSource: [],
      statisticsList: [{
        label: '本周排班总工时/小时',
        num: '0'
      },
      {
        label: '本周调班人数',
        num: '0'
      },
      {
        label: '本周应出勤人数',
        num: '0'
      },
      {
        label: '本周实际出勤人数',
        num: '0'
      }]
    }
  },
  created() {
    this.staffShiftCount()
  },
  methods: {
    // 获取统计信息
    staffShiftCount() {
      staffShiftCountApi({
        type: 'week',
        daydate: `${this.time[0].split('-').join('')},${this.time[1].split('-').join('')}`
      }).then(res => {
        this.statisticsList[0].num = res.data.sumManHour ? res.data.sumManHour : '0'
        this.statisticsList[1].num = res.data.sumChangeShiftManTime ? res.data.sumChangeShiftManTime : '0'
        this.statisticsList[2].num = res.data.expectAttendanceManTime ? res.data.expectAttendanceManTime : '0'
        this.statisticsList[3].num = res.data.factAttendanceManTime ? res.data.factAttendanceManTime : '0'
      })
    },
    // 周班统计数据
    weekDetail() {
      weekDetailApi({
        type: 'week',
        daydate: `${this.time[0].split('-').join('')},${this.time[1].split('-').join('')}`
      }).then(res => {
        // console.log(res)
        const arr = []
        res.data.header.forEach(item => {
          const column = {
            title: `${item.shiftDateStr} \n ${item.dayWeekName}`,
            dataIndex: `${item.shiftDate}.shiftDateNum`,
            align: 'center',
            customCell: () => {
              return {
                style: {
                  'min-width': '100px'
                }
              }
            }
          }
          arr.push(column)
        })
        this.columns = [{
          title: '班次',
          dataIndex: 'shiftName',
          key: 'shiftName',
          width: '180px',
          ellipsis: true
        },
        {
          title: '周统计',
          dataIndex: 'shiftDateTotalNum',
          key: 'shiftDateTotalNum',
          width: '150px',
          align: 'center'
        }]
        this.columns.splice(2, 0, ...arr)
        this.dataSource = res.data.record
        this.dataSource.forEach((item, index) => {
          item.index = index
        })
      })
    },
    onChange(date, dateString) {
      // console.log(date, dateString)
      this.time = date
      this.weekDetail()
      this.staffShiftCount()
    },
    disabledDate(current) { // 不可选择未来日期
      return current > moment().endOf('day')
    }
  }
}
</script>
<style lang="less" scoped>
.week{
    width: 100%;
    height: calc(100% - 48px);
    background-color: @base-bg-color;
}
.toobar-left{
  // width: 246px;
  height: 34px;
  background-color: @sc-greyBg-10;
  display: inline-flex;
  padding: 0 0 0 16px;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 16px;
  .iconpark-icon{
    width: 20px;
    height: 20px;
    color: @sc-primary-100;
  }
  .ant-calendar-picker {
    // width: 180px;
    padding:0 10px;
    line-height: 30px;
    text-align: center;
    /deep/.weekBox{
      width: auto;
      background-color: @sc-greyBg-10;
      border: 0;
      font-size: 14px;
      color: @sc-grey-100;
      &:hover {
        color: @sc-primary-100 !important;
      }
    }
  }

}
.title{
  font-size:16px;
  height: 20px;
  font-weight: 600;
  color: @sc-grey-100;
  margin: 16px 0;
}
/deep/.statistics-center-item{
  position: relative;
  &:nth-child(-n+3)::after {
    content: '';
    position: absolute;
    right: 0;
    height: 30px;
    width: 1px;
    background-color: @sc-greyBg-20;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
