<template>
  <div class="common-layout">
    <div class="lay-out">
      <div class="leftBox">
        <div class="logoBox">
          <div class="logoImgBox">
            <img :src="logo" class="logo" :alt="systemName">
          </div>
          {{ systemName }}
        </div>
        <div class="description">
          <div class="bottomBox">
          <!-- <img :src="lognBottom" class="setImg" alt=""> -->
          </div>
          <div class="title">重塑HR价值</div>
          <div class="subTitle">业务驱动 · 以人为本 · 业人融合 · 全面赋能</div>
        </div>

      </div>
      <div class="login-box">
        <div class="box">
          <contentHeader />
          <div class="content">
            <component :is="componentsName" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contents from './components/mixins'
import { mapGetters, mapMutations, mapState } from 'vuex'
import lognBottom from '@/assets/img/Login/lognBottom.png'
import logo from '@/assets/img/Login/logo.png'
// import { removeAuthorization } from '@/utils/request'
export default {
  name: 'Login',
  provide() {
    return {
      globForm: this.form
    }
  },
  mixins: [contents],
  data() {
    return {
      lognBottom,
      logo,
      form: {
        phoneNum: '',
        password: '',
        repassword: '',
        code: '',
        uuid: ''
      }
    }
  },
  computed: {
    componentsName() {
      const arr = {
        1: 'account-login',
        2: 'check-code-login',
        3: 'forget-password',
        4: 'set-password',
        5: 'register',
        6: 'set-password',
        7: 'set-info',
        8: 'select-tenant'
      }
      return arr[this.getLoginType]
    },
    ...mapState('setting', ['systemName']),
    ...mapGetters('setting', ['getLoginType'])
  },
  mounted() {
    // 重置store里面的菜单数据
    this.setMenuData([])
    this.setSubMenuData([])
    // 最近访问记录
    const recentVisits = localStorage.getItem(process.env.VUE_APP_RECENTVISITS)
    localStorage.clear()
    this.setRecentVisits(JSON.parse(recentVisits))
    localStorage.setItem(process.env.VUE_APP_RECENTVISITS, recentVisits)
    // removeAuthorization()
    // 清空store的缓存
    this.clearCacheDictionaryObj()
  },
  methods: {
    ...mapMutations('setting', ['setSubMenuData', 'setMenuData', 'setRecentVisits', 'clearCacheDictionaryObj'])
  }
}
</script>
<style lang="less" scoped>
/deep/.slick-slide{
  height: 100%;
}
.common-layout{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 70px 95px;
  background-color: rgba(247, 250, 255, 1);
  .lay-out{
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #fff;
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 0px 4px 30px 10px rgba(33, 46, 76, 0.03);
    .leftBox{
      width: 42%;
      height: 100%;
      min-width: 525px;
      // min-height: 650px;
      position: relative;
      background-color: rgba(99, 136, 255, 1);
      .logoBox{
        margin: 30px;
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 26px;
        color: #fff;
        .logoImgBox{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 32px;
          height: 32px;
          padding: 6px;
          border-radius: 6px;
          margin-right: 8px;
          background-color: #fff;
          .logo{
            width: 20px;
          }
        }
      }
      .description{
        z-index: 99;
        margin-top: 60px;
        margin-left: 30px;
        .title{
          font-size: 34px;
          line-height: 34px;
          letter-spacing: 3px;
          color: #fff;
          margin-bottom: 20px;
        }
        .subTitle{
          font-size: 16px;
          font-weight: normal;
          color: #fff;
          line-height: 16px;
          opacity: .6;
        }
      }
      .bottomBox{
        position: absolute;
        bottom: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 55%;
        display: inline-block;
        background-image: url("../../assets/img/Login/lognBottom.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50%;
        z-index: 0;
      }
    }
    .login-box{
      background-color: #fff;
      width: 100%;

      min-width: 500px;
      border-radius: 16px;
      flex: 1;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      .box{
        width: 360px;
      }
    }
  }
}
</style>
