import checkCodeLogin from './check-code-login.vue'
import accountLogin from './account-login.vue'
import contentHeader from './content-header.vue'
import forgetPassword from './forget-password.vue'
import setPassword from './set-password.vue'
import register from './register.vue'
import setInfo from './setting-info.vue'
import selectTenant from './select-tenant.vue'
export default {
  components: {
    checkCodeLogin,
    accountLogin,
    contentHeader,
    forgetPassword,
    setPassword,
    register,
    setInfo,
    selectTenant
  }
}

