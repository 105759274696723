var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"backs"},[_c('div',[_c('div',{staticClass:"addCount"},[_c('a-button',{attrs:{"type":"primary","prefix":"add-one"},on:{"click":_vm.handleAdd}},[_vm._v(" 添加账号 ")])],1),_c('custom-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"table-ref":".backs","row-key":"userId","pagination":{
        current: _vm.pager.pageNum,
        pageSize: _vm.pager.pageSize,
        total: _vm.total,
        showTotal: function (total) { return ("共 " + total + " 条数据"); },
        showQuickJumper: true,
        showSizeChanger: true
      }},on:{"change":_vm.loadDataList},scopedSlots:_vm._u([{key:"index",fn:function(ref){
      var scope = ref.scope;
return [_c('span',[_vm._v(_vm._s(scope.index + 1))])]}},{key:"userName",fn:function(ref){
      var scope = ref.scope;
return [_c('span',[_vm._v(_vm._s(scope.record.userName))]),_c('span',{staticClass:"createTag"},[(scope.record.isCreator === '1')?_c('a-tag',{attrs:{"color":"orange"}},[_vm._v("创建人")]):_vm._e()],1)]}},{key:"userStatus",fn:function(ref){
      var scope = ref.scope;
return [(scope.record.isCreator != '1')?_c('a-switch',{attrs:{"size":"small","checked":scope.record.userStatus === '0'},on:{"click":function($event){return _vm.switchChange(scope.record)}}}):_vm._e()]}},{key:"isAuthority",fn:function(ref){
      var scope = ref.scope;
return (_vm.isCreator === '1')?[(scope.record.isCreator != '1')?_c('a-switch',{attrs:{"size":"small","checked":scope.record.isAuthority === '1'},on:{"click":function($event){return _vm.isAuthorityChange(scope.record)}}}):_vm._e()]:undefined}},{key:"operation",fn:function(ref){
      var scope = ref.scope;
return [(scope.record.isCreator === '1' && _vm.isCreator === '1')?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.onTransfer(scope.record)}}},[_vm._v(" 转让创建人 ")]):_vm._e(),(scope.record.isCreator != '1')?_c('a-popover',{attrs:{"placement":"bottomLeft","trigger":"hover"}},[_c('template',{slot:"content"},[_c('div',{staticClass:"more-handle-btn"},[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.permissions(scope.record.userId)}}},[_vm._v(" 权限 ")]),_c('a-popconfirm',{attrs:{"title":"是否确认删除？","trigger":"click","ok-text":"确认","cancel-text":"取消"},on:{"confirm":function($event){return _vm.deleteConfirm(scope.record.userId)}}},[_c('a-button',{staticClass:"delete",attrs:{"type":"link"}},[_vm._v(" 删除 ")])],1)],1)]),_c('div',{staticClass:"more-handle"},[_vm._v("···")])],2):_vm._e()]}}],null,true)})],1),_c('AddAuthor',{ref:"transferForm",on:{"getFetchAddIn":_vm.getFetchAddIn}}),_c('TransferCreator',{ref:"transferType",on:{"transfer":_vm.changeTransfer}}),_c('Permissions',{ref:"permissionForm",on:{"getPermission":_vm.getPermission}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }