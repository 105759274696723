<template>
  <Drawer
    :title="isAgain?'重新投保':batch?'批量投保':'投保'"
    :visible="visible"
    size="small"
    :z-index="2"
    @close="onClose"
    @onSubmit="ok"
  >
    <div class="content">
      <div class="form_box">
        <UserInfo v-if="!batch" :user-info="userInfo" />
        <div v-else class="userList">
          <p>适用员工</p>
          <div class="per_list" :style="!flag ? avatarClassDefault : ''">
            <div v-for="(item, index) in staff" :key="index" class="per_list_avatar">
              <CbAvatar
                :on-job-id="item.onJobId"
                :avater-name="item.staffName"
                size="24"
                :default-name-length="1"
              />
            </div>
            <div v-if="staff.length > 8" class="downUp" @click="flag = !flag">
              <svg class="iconpark-icon" :style="!flag ? 'transform: rotate(270deg)' : 'transform: rotate(90deg)'"><use href="#left" /></svg>
            </div>
          </div>
        </div>
        <div v-if="visible" class="setForm">
          <AddFormVue ref="socail" title="社保" :payment="payment" :is-again="isAgain" insure-classify="social" :form-data="insureData.social" @setPayment="setPayment" />
          <AddFormVue ref="medical" title="医保" :payment="payment" :is-again="isAgain" insure-classify="medical" :form-data="insureData.medical" @setPayment="setPayment" />
          <AddFormVue ref="housing" title="公积金" :batch="batch" :payment="payment" :is-again="isAgain" insure-classify="housing" :form-data="insureData.housing" @setPayment="setPayment" />
        </div>
      </div>
    </div>
  </Drawer>
</template>
<script>
import { insureApi, getStaffInsureInfoApi, getInsureBillRemindStatusApi } from '@/services/insurance/archives.js'
import { insureTips } from '../../bill/dict.js'
export default {
  name: 'AddForm',
  components: {
    UserInfo: () => import('./userInfo'),
    AddFormVue: () => import('./addForm'),
    CbAvatar: () => import('@/components/CbAvater/theAvater.vue'),
    Drawer: () => import('@/components/CbDrawer/index')
  },
  props: {
    isAgain: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      payment: null, // 缴纳基数传参
      visible: false,
      socialSwitch: false,
      wrapperCols: { span: 20 },
      avatarClassDefault: {
        height: '110px',
        overflow: 'hidden'
      },
      form: {},
      userInfo: {},
      insureData: {},
      batch: false,
      flag: false,
      staff: []
    }
  },
  mounted() {

  },
  methods: {
    setPayment(val) {
      this.payment = val
    },
    onClose() {
      this.$refs.socail.resetFile()
      this.$refs.medical.resetFile()
      this.$refs.housing.resetFile()
      this.payment = null
      this.insureData = {}
      this.visible = false
    },
    async onShow(data, batch) {
      if (batch) {
        this.batch = batch
        this.staff = data
      } else {
        this.batch = false
        this.userInfo = data
        this.$nextTick(async() => {
          if (this.isAgain) {
            const res = await getStaffInsureInfoApi({ onJobId: data.onJobId })
            if (res.code === 100) {
              this.insureData = res.data
            }
          }
        })
      }

      this.visible = true
    },
    getUserIDs() {
      const arr = []
      this.staff.forEach(item => {
        arr.push(item.onJobId)
      })
      return arr
    },
    async ok() {
      const socail = await this.$refs.socail.getData()
      const medical = await this.$refs.medical.getData()
      const housing = await this.$refs.housing.getData()
      if (!socail || !medical || !housing) return
      const onJobIds = this.batch ? this.getUserIDs() : [this.userInfo.onJobId]
      socail.formData.endMonth = undefined
      socail.formData.endMonth = undefined
      socail.formData.endMonth = undefined
      socail.formData.decreasesReason = undefined
      medical.formData.decreasesReason = undefined
      housing.formData.decreasesReason = undefined
      const params = {
        onJobIds,
        socialStatus: socail.flag ? 1 : 0,
        social: socail.flag ? socail.formData : null,
        medicalStatus: medical.flag ? 1 : 0,
        medical: medical.flag ? medical.formData : null,
        housingStatus: housing.flag ? 1 : 0,
        housing: housing.flag ? housing.formData : null
      }
      if (!params.socialStatus && !params.medicalStatus && !params.housingStatus) {
        this.$message.error('请至少选择一种投保类型')
        return
      }
      const paramMonth = {
        socialMonth: socail.flag ? socail.formData.startMonth : null,
        medicalMonth: medical.flag ? medical.formData.startMonth : null,
        housingMonth: housing.flag ? housing.formData.startMonth : null
      }
      const res = await getInsureBillRemindStatusApi(paramMonth)
      if (res.code === 100) {
        let tips = ''
        if (res.data.socialBillRemindStatus === '1' && res.data.medicalBillRemindStatus === '1' && res.data.housingBillRemindStatus === '1') {
          this.insure(params)
        } else {
          const arr = [res.data.socialBillRemindStatus, res.data.medicalBillRemindStatus, res.data.housingBillRemindStatus]
          const newArr = new Set(arr)
          newArr.forEach(item => {
            if (item !== '1') {
              tips += insureTips[item]
            }
          })
          this.$Reconfirm({
            title: '提示',
            content: tips
          }).then(() => {
            this.insure(params)
          }).catch(() => {})
        }
      }
    },
    // 提交
    insure(params) {
      insureApi(params).then(res => {
        if (res.code === 100) {
          this.$message.success('投保成功')
          this.onClose()
          this.$emit('callBackBatch')
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.userList{
  margin-bottom: 32px;
}
.per_list {
    position: relative;
    width: 100%;
    background: rgba(143,147,161,0.05);
    border-radius: 4px 4px 4px 4px;
    border: 1px solid rgba(143,147,161,0.10);
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    box-sizing: border-box;
    .per_list_avatar {
      width: 88px;
      margin: 0 20px 10px 0;
      cursor: pointer;
    }
    .downUp {
      cursor: pointer;
      position: absolute;
      top: 18px;
      right: 12px;
      color: @sc-greyBg-50;
      .iconpark-icon{
        width: 25px;
        height: 25px;
      }
    }
  }
.content{
    width:100%;
    height:100%;
    position:relative;
    .font-size(14px);
    .setForm{
      width: 100%;
      height: auto;
      /deep/.ant-form{
        padding-bottom: 10px !important;
      }
    }
}
</style>
