<template>
  <div class="salary-rule-container">
    <a-form-model
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 21 }"
    >
      <!-- 计薪时长 -->
      <!-- 提示语句用字典change 获取content计算 -->
      <a-form-model-item label="计薪天数" prop="planPayMode" class="pay-mode">
        <template>
          <a-form-model-item label="全月应计薪天数">
            <DictionariesInput v-model="ruleForm.planPayMode" :clearable="false" class="times-input" parameter="planpaymode" placeholder="请选择计薪时长方式" />
            <CbNumber v-if="ruleForm.planPayMode==='3'" v-model="ruleForm.daysPerMonth" max="31" :decimal-separator="true" :is-zero="false" class="small-input" suffix="天" />
            <a-tooltip placement="bottom">
              <template slot="title">
                <div class="tooltip-content">
                  <span>日薪=固定工资÷{{ planPayModeData }}</span>
                </div>
              </template>
              <span class="tooltip-icon">?</span>
            </a-tooltip>
          </a-form-model-item>
          <a-form-model-item v-if="ruleForm.planPayMode==='3'" label="月实际计薪天数">
            <DictionariesInput v-model="ruleForm.actualPayMode" :clearable="false" class="times-input" parameter="actualpaymode" placeholder="请选择计薪时长方式" />
          </a-form-model-item>
        </template>
        <template>
          <div class="attendance-none-title flex-align-center"><a-icon type="info-circle" />当计薪人员无考勤及排班表时，将按下方选项处理</div>
          <a-form-model-item label="全月应计薪天数">
            <DictionariesInput v-model="ruleForm.planPayModeNoAttend" :clearable="false" class="times-input" parameter="planpaymodenoattend" placeholder="请选择计薪时长方式" />
            <CbNumber v-if="ruleForm.planPayModeNoAttend==='4'" v-model="ruleForm.daysPerMonthNoAttend" max="31" :decimal-separator="true" :is-zero="false" class="small-input" suffix="天" />
            <a-tooltip placement="bottom">
              <template slot="title">
                <div class="tooltip-content">
                  <span>日薪=固定工资÷{{ planNoAttend }}</span>
                </div>
              </template>
              <span class="tooltip-icon">?</span>
            </a-tooltip>
          </a-form-model-item>
          <a-form-model-item v-if="ruleForm.planPayModeNoAttend==='4'" label="月实际计薪天数">
            <DictionariesInput v-model="ruleForm.actualPayModeNoAttend" :clearable="false" class="times-input" parameter="actualpayModenoattend" placeholder="请选择计薪时长方式" />
          </a-form-model-item>
        </template>
      </a-form-model-item>
      <!-- 计算方式 -->
      <a-form-model-item label="计算方式" prop="salCalcMode">
        <DictionariesInput v-model="ruleForm.salCalcMode" class="line-input" :clearable="false" parameter="salcalctype" placeholder="请选择计薪时长方式" />
        <div class="tips">
          <span v-if="ruleForm.salCalcMode==='1'">新入职、离职员工正算,对全月在职员工反算</span>
          <span v-else-if="ruleForm.salCalcMode==='2'">全员采用正算</span>
          <span v-else-if="ruleForm.salCalcMode==='3'">全员采用反算</span><br>
          <span v-if="['1','2'].includes(ruleForm.salCalcMode)">正算法举例<br>应发工资 = 日薪  * 实际出勤天数  <br></span>
          <span v-if="['1','3'].includes(ruleForm.salCalcMode)">反算法举例<br>应发工资 = 固定工资  -  日薪 * 缺勤天数 </span>
        </div>
      </a-form-model-item>
      <!-- 转正月 -->
      <a-form-model-item label="转正月" prop="regSalCalcMode">
        <DictionariesInput v-model="ruleForm.regSalCalcMode" :clearable="false" class="line-input" parameter="regsalcalctype" placeholder="请选择转正月计算方式" />
        <a-tooltip v-if="ruleForm.regSalCalcMode==='1'" placement="bottom">
          <template slot="title">
            <div class="tooltip-content">
              <span>固定工资 =（转正前固定工资*调薪前计薪天数+转正后固定工资*调薪后计薪天数）/本月计薪天数</span>
            </div>
          </template>
          <span class="tooltip-icon">?</span>
        </a-tooltip>
      </a-form-model-item>
      <!-- 调薪月 -->
      <a-form-model-item label="调薪月" prop="chgSalCalcMode">
        <DictionariesInput v-model="ruleForm.chgSalCalcMode" :clearable="false" class="line-input" parameter="chgsalcalctype" placeholder="请选择计薪时长方式" />
        <a-tooltip v-if="ruleForm.chgSalCalcMode==='1'" placement="bottom">
          <template slot="title">
            <div class="tooltip-content">
              <span>固定工资 =（调薪前固定工资*调薪前计薪天数+调薪后固定工资*调薪后计薪天数）/本月计薪天数</span>
            </div>
          </template>
          <span class="tooltip-icon">?</span>
        </a-tooltip>
      </a-form-model-item>
      <!-- 入职计薪 -->
      <a-form-model-item label="入职计薪" prop="daysToNextPeriod">
        当月
        <CbNumber v-model="ruleForm.daysToNextPeriod" class="small-input" max="27" suffix="天" />
        至考勤周期最后一天入职，当月薪资合并至下月发放
        <div class="attendance-none-title flex-align-center"><a-icon type="info-circle" />入职计薪天数为0时，默认为入职当月发薪</div>
      </a-form-model-item>
      <!-- 计税规则 -->
      <a-form-model-item label="计税规则" prop="taxClacMode">
        <DictionariesInput v-model="ruleForm.taxClacMode" :clearable="false" class="line-input" parameter="taxclacmode" placeholder="请选择计税规则" />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { getRule, updateRule } from '@/services/wage/salaryGroup.js'
export default {
  name: 'SalaryRule',
  components: {
    DictionariesInput: () => import('@/components/CbDropDownInput/dictionariesInput/index'),
    CbNumber: () => import('@/components/CbNumber/index')
  },
  props: {},
  inject: ['infoData'],
  data() {
    return {
      radioStyle: {
        display: 'inline-block',
        lineHeight: '35px'
      },
      ruleForm: {},
      rules: {
        planPayMode: [
          { required: true, message: '请选择计薪天数', trigger: 'change' }
        ],
        salCalcMode: [
          { required: true, message: '请选择计算方式', trigger: 'change' }
        ],
        defaultAttendMode: [
          { required: true, message: '请选择月实际计薪天数', trigger: 'change' }
        ],
        regSalCalcMode: [
          { required: true, message: '请选择转正月计算方式', trigger: 'change' }
        ],
        chgSalCalcMode: [
          { required: true, message: '请选择调薪月计算方式', trigger: 'change' }
        ],
        daysToNextPeriod: [
          { required: true, message: '请填写入职计薪天数', trigger: 'blur' }
        ],
        taxClacMode: [
          { required: true, message: '请选择计税规则', trigger: 'change' }
        ]
      },
      planPayTipList: [
        '',
        '月应出勤天数',
        '(月应出勤天数+法定节假日)',
        '自定义天数'
      ],
      planNoAttendTipList: [
        '',
        '法定工作日天数',
        '(法定工作日天数+法定节假日)',
        '按月历天数',
        '自定义天数'
      ]

    }
  },
  computed: {
    planPayModeData() {
      return this.planPayTipList[+this.ruleForm.planPayMode]
    },
    planNoAttend() {
      return this.planNoAttendTipList[+this.ruleForm.planPayModeNoAttend]
    }
  },
  mounted() {
    this.getRule()
  },
  methods: {
    // 查询薪资规则
    async getRule() {
      const res = await getRule({ groupId: this.infoData.groupId })
      if (res.code === 100) {
        this.ruleForm = res.data
      }
    },
    // 修改薪资规则
    updateRule() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const res = await updateRule({ ...this.ruleForm, groupId: this.infoData.groupId })
          if (res.code === 100) {
            this.$message.success('保存成功！')
            this.$emit('savedSuccess')
          }
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.salary-rule-container {
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
  margin:0 auto;
}
.line-input{
  display:inline-block;
  width:414px;
}
.number-input{
  display:inline-block;
  width:272px;
}
.small-input{
  display:inline-block;
  width:130px;
  margin-left:12px;
}
.times-input{
  display:inline-block;
  width:292px;
}
.margin-right-none{
  margin-right:0;
}
.attendance-none-title{
  color:@warning-color;
  .anticon-info-circle{
    font-size: 16px;
    margin-right: 8px;

  }
}
.tooltip-icon{
  margin-left: 12px;
  display:inline-block;
  text-align:center;
  width: 17px;
  height: 17px;
  line-height: 17px;
  border-radius: 50%;
  color: #fff;
  background: @sc-greyBg-20;
}
.tooltip-icon:hover{
  background: @sc-greyBg-50;
}
.tooltip-content{
  display: flex;
  flex-direction: column;
}
.tips{
  margin:9px 0 5px 0;
  padding:8px 10px;
  width:414px;
  .font-size(14px);
  color: @sc-grey-40;
  background: @sc-greyBg-10;
  border-radius: 4px;
  border: 1px solid @sc-greyBg-20;
}
/deep/.ant-form-item{
  margin-bottom: 14px;
  color: @sc-grey-100;
}
.pay-mode{
  /deep/.ant-form-item{
    margin-bottom: 0px;
    color: @sc-grey-100;
  }
}
</style>
