<template>
  <Modal
    :closable="true"
    :visible="visible"
    :title="(mode == 'add' ? '添加' : '编辑') + name"
    ok-text="确认"
    :destroy-on-close="true"
    size="normal"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item :label="name + '名称'" prop="fileName">
        <a-input v-model="form.fileName" placeholder="请输入1-20个字符" />
      </a-form-model-item>
      <a-form-model-item v-if="mode == 'add'" :label="name + '文件'" prop="fileId">
        <UploadImage
          v-if="name == '基本材料'"
          :max-sum="1"
          :type-list="uploadImageType"
          :file-size="10"
          :model-upload="false"
          @change="imageUploadChange"
        />
        <UploadFile
          v-if="name == '档案材料'"
          :type-list="uploadFileType"
          :file-size="50"
          :model-upload="false"
          :max-sum="1"
          @change="imageUploadChange"
        />
      </a-form-model-item>
    </a-form-model>
    <Alert v-if="mode == 'add'" :type="'info'" show-icon>
      <template #content>
        <div>{{ `请上传${ name === '基本材料' ? imageTips : fileTips}类型的文件` }}</div>
      </template>
    </Alert>
  </Modal>
</template>

<script>
import UploadImage from '@/components/UploadImage/index.vue'
import UploadFile from './modelUploadFile.vue'
import { createStaffFile, updateStaffFile } from '@/services/user/roster.js'
export default {
  components: {
    UploadImage,
    UploadFile,
    Modal: () => import('@/components/CbModal/index.vue'),
    Alert: () => import('@/components/CbAlert/index.vue')
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    userId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      uploadFileType: ['doc', 'docx', 'pdf', 'jpeg', 'png', 'jpg'],
      uploadImageType: ['jpeg', 'png', 'jpg', 'pdf'],
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      form: {
        staffFileId: '',
        staffId: '',
        fileType: null,
        fileName: '',
        createType: 1,
        fileId: '',
        sort: 20
      },

      rules: {
        fileName: [
          {
            required: true,
            message: `请输入${this.name}名称`,
            trigger: 'blur'
          },
          { min: 1, max: 20, message: '请输入1-20个字符', trigger: 'change' }
        ],
        fileId: [
          {
            required: true,
            message: `请上传${this.name}文件`,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    mode() {
      // 根据id自动计算新增、编辑
      return this.form.staffFileId ? 'update' : 'add'
    },
    imageTips() {
      return this.typeTips(this.uploadImageType)
    },
    fileTips() {
      return this.typeTips(this.uploadFileType)
    }
  },
  watch: {
    name: function(val) {
      this.rules = {
        fileName: [
          {
            required: true,
            message: `请输入${val}名称`,
            trigger: 'blur'
          },
          { min: 1, max: 20, message: '请输入1-20个字符', trigger: 'change' }
        ],
        fileId: [
          {
            required: true,
            message: `请上传${val}文件`,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted() {},
  methods: {
    // 打开弹窗
    open(data) {
      this.form = Object.assign(this.form, data)
      this.visible = true
    },
    // 基本材料  档案材料
    // 保存事件
    handleOk() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          this.form.staffId = this.userId
          this.form.fileType = this.name === '基本材料' ? 1 : this.name === '档案材料' ? 2 : 3
          if (this.mode === 'add') {
            const res = await createStaffFile(this.form)
            if (res.code === 100) {
              this.$message.success('新增成功')
              this.handleCancel()
              this.$emit('reload')
            }
          } else {
            const res = await updateStaffFile(this.form)
            if (res.code === 100) {
              this.$message.success('编辑成功')
              this.handleCancel()
              this.$emit('reload')
            }
          }
        }
      })
    },
    // 取消事件
    handleCancel() {
      this.visible = false
      setTimeout(() => {
        this.resetForm()
      }, 500)
    },
    // 重置表单内容和验证
    resetForm() {
      this.form = {
        staffFileId: '',
        staffId: '',
        fileType: null,
        fileName: '',
        createType: 1,
        fileId: '',
        sort: 20
      }
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.resetFields()
      }
    },
    // 上传文件change事件
    imageUploadChange(list) {
      if (list.length > 0) {
        this.form.fileId = list[0].fileId
      } else {
        this.form.fileId = ''
      }
    },
    // 提示上传文件类型
    typeTips(type) {
      return type.filter((item) => {
        return item
      })
    }
  }
}
</script>

<style lang="less" scoped>
// /deep/.upload-file {
//   width: 150px;
//   height: 150px;
// }
/deep/ .ant-input{
  width: 400px;
}
/deep/ .ant-col{
  display: block;
  width: 20%;
}
/deep/ .upload-image .ant-upload-select-picture-card i {
  color: @sc-primary-100;
}
/deep/ .upload-image .ant-upload-select-picture-card .ant-upload-text {
  color: @sc-grey-100;
}
</style>
