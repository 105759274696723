<template>
  <div>
    <div class="calendar-header">
      <svg class="iconpark-icon" @click="perviousMonth"><use href="#left" /></svg>
      <!-- <a-icon type="left" @click="perviousMonth" /> -->
      <div class="timePicker">
        <a-month-picker
          v-model="month"
          :disabled-date="disabledMonth"
          value-format="YYYY-MM"
          format="YYYY年MM月"
          :open="isMonthPicker"
          @openChange="isMonthPicker = false"
        />
        <svg class="iconpark-icon" @click="isMonthPicker = true"><use href="#rongqi" /></svg>
      </div>
      <svg v-if="isNext" key="next" class="iconpark-icon right" @click="nextMonth"><use href="#left" /></svg>
      <svg v-else class="iconpark-icon right" style="color:#d9d9d9; cursor: not-allowed"><use href="#left" /></svg>
      <!-- <a-icon v-if="isNext" key="next" type="right" @click="nextMonth" /> -->
      <!-- <a-icon v-else type="right" style="color:#d9d9d9" /> -->
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  model: {
    value: 'monthValue',
    event: 'change'
  },
  props: {
    monthValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // 是否下一个月
      month: '',
      isNext: true,
      isMonthPicker: false
    }
  },
  watch: {
    month(val) {
    //   const flag = val === `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, 0)}`
    //   this.isNext = !flag
      this.$emit('change', val)
    }
  },
  mounted() {
    this.month = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, 0)}`
  },
  methods: {
    // 上一月
    perviousMonth() {
      this.month = moment(this.month)
        .subtract(1, 'month')
        .format('YYYY-MM')
    },
    // 下一月
    nextMonth() {
      this.month = moment(this.month)
        .add(1, 'month')
        .format('YYYY-MM')
    },
    disabledMonth(current) {
      // return current && current > moment().startOf('day')
    }
  }
}
</script>
<style lang="less" scoped>
.calendar-header {
  width: auto;
  margin: 0 auto;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  /deep/i.anticon {
    font-size: 25px;
    margin-right: 20px;
  }
  /deep/.iconpark-icon {
    width: 25px;
    height: 25px;
    margin-right: 20px;
    cursor: pointer;
  }
  .right{
    transform: rotate(180deg);
  }
  .timePicker {
    display: flex;
    align-items: center;
    .iconpark-icon{
      width: 16px;
      height: 16px;
      color: @sc-primary-100;
      margin-right: 20px;
    }
    .ant-calendar-picker {
      width: 160px;
      font-size: 25px;
      /deep/.ant-calendar-picker-input:focus{
        box-shadow: unset;
      }
      /deep/.ant-calendar-picker-input {
        border: 0;
        font-size: 25px;
      }
      /deep/.ant-calendar-picker-clear,
      /deep/.ant-calendar-picker-icon {
        display: none;
      }
    }
  }
}
</style>
