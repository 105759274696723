var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Details"},[_c('HeaderBack',{attrs:{"title":((_vm.form.year||'') + "年年终奖")},on:{"back":function($event){return _vm.$emit('goBack')}}}),_c('CbStatistics',{staticClass:"statistics",attrs:{"data-list":_vm.form.list,"length":"4","is-function":true}},[_c('template',{slot:"right"},[_c('a-button',{attrs:{"prefix":_vm.form.locked ? 'unlock' : 'lock'},on:{"click":_vm.changeBounsStatus}},[_vm._v(_vm._s(_vm.form.locked?'数据解锁':'数据锁定'))])],1)],2),_c('appForm',{staticClass:"v-line-button",attrs:{"to-list":_vm.formList,"screen-roster-list":_vm.screenRosterList},on:{"screenRosterParams":_vm.screenRosterParams,"getSearchCallback":_vm.getSearchCallback,"exportFileList":_vm.exportFileList}},[_c('template',{slot:"customButton"},[_c('a-space',[(!_vm.showBatchCom)?_c('a-button',{attrs:{"disabled":!!_vm.form.locked,"prefix":"piliangdaoru"},on:{"click":_vm.showBatch}},[_vm._v(" 批量导入 ")]):_c('a-button',{attrs:{"disabled":!!_vm.form.locked,"prefix":"left"},on:{"click":_vm.showBatch}},[_vm._v(" 返回表格 ")]),_c('a-button',{attrs:{"disabled":!!_vm.form.locked,"prefix":"bendijisuan"},on:{"click":_vm.calculate}},[_vm._v(" 本地计算 ")])],1)],1)],2),(!_vm.showBatchCom)?_c('custom-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"row-key":'id',"pagination":{
      current: _vm.pager.pageNum,
      pageSize: _vm.pager.pageSize,
      total: _vm.total,
      showTotal: function (total) { return ("共 " + total + " 条数据"); },
      showQuickJumper: true,
      showSizeChanger: true
    }},on:{"change":_vm.loadDataList},scopedSlots:_vm._u([{key:"index",fn:function(ref){
    var scope = ref.scope;
return [_c('span',[_vm._v(_vm._s(scope.index + 1))])]}},{key:"staffName",fn:function(ref){
    var scope = ref.scope;
return [_c('CbTableAvater',{attrs:{"on-job-id":scope.record.onJobId,"staff-id":scope.record.staffId,"table-name":scope.record.staffName}})]}},{key:"operation",fn:function(ref){
    var scope = ref.scope;
return [_c('div',{staticClass:"flex-align-center"},[_c('div',{staticClass:"content-btn-box"},[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.edit(scope.record)}}},[_vm._v("编辑")]),_c('a-button',{staticClass:"delete",attrs:{"type":"link"},on:{"click":function($event){return _vm.delet(scope.record)}}},[_vm._v("删除")])],1)])]}}],null,false,793946907)}):_c('ImportBatch',{attrs:{"import-template":"yearBouns","title-show":false,"bouns-id":_vm.yearendBounsId},on:{"getStaffStatistics":_vm.getStaffStatistics}}),_c('UpdateYearendBouns',{ref:"UpdateYearendBouns",attrs:{"row":_vm.row}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }