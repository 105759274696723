<template>
  <div class="AddOrEditCategoryChild">
    <Drawer
      :z-index="100"
      :is-mask="false"
      :title="`${mode==='add'?'新增':'编辑'}薪资项`"
      placement="right"
      size="large"
      :visible="childVisible"
      @close="childVisible = false"
      @onSubmit="submitPreserve"
    >
      <a-form-model ref="ruleForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
        <!-- 薪资项 -->
        <SalaryItem ref="salaryItem" :form="form" :mode="mode" :type-no="typeNo" @getSalaryData="getSalaryData" />
        <!-- 基础项 -->
        <BaseMessage v-if="typeNo!=='A06'" :form="form" :type-no="typeNo" />
        <!-- 系统规则 -->
        <PayrollRules v-if="typeNo!=='A06'" :form="form" :type-no="typeNo" @getSalaryData="getSalaryData" @setUpCustomFormula="setUpCustomFormula" />
        <!-- 发放月份 折算方式 扣发规则 -->
        <!-- 固定项&&社保 无此规则 -->
        <OtherRules v-show="!['A01','A06'].includes(typeNo)&&form.calcRuleType !== '3'" ref="otherRules" :type-no="typeNo" :form="form" />
      </a-form-model>
    </Drawer>
    <FormulaSetting ref="FormulaSetting" :form="form" />
  </div>
</template>

<script>
import { getItemDetail, saveItem, getItemConfig } from '@/services/wage/salaryGroup.js'
import { debounce } from '@/utils/index.js'
export default {
  name: 'AddOrEditCategoryChild',
  components: {
    Drawer: () => import('@/components/CbDrawer'),
    FormulaSetting: () => import('../formulaSetting/index.vue'),
    SalaryItem: () => import('./salaryItem/index.vue'),
    BaseMessage: () => import('./baseMessage/index.vue'),
    PayrollRules: () => import('./payrollRules/index.vue'),
    OtherRules: () => import('./otherRules/index.vue')
  },
  inject: ['infoData'],
  data() {
    return {
      childVisible: false,
      labelCol: { lg: 5, xl: 4, xxl: 3 },
      wrapperCol: { lg: 19, xl: 20, xxl: 21 },
      // 薪资项分类编号
      typeNo: '',
      salaryItemNo: '',
      isSetting: false,
      // 编辑薪资项
      form: {
        // 薪资项类型
        salaryType: '',
        // 定义方式 1系统内置，2自定义
        defineMode: '1',
        // 计薪规则类型 1系统规则 2自定义规则 3计薪时导入
        calcRuleType: '1',
        // 自定义的薪资项需要输入名称
        salaryItemName: '',
        // 参算方式
        calcType: '1',
        // 是否计税
        taxable: 1,
        // 折算方式
        isAttendScale: 0,
        attendScaleMode: '',
        // 是否填写发放月份
        specialMonth: 0,
        // 发放月份列表
        isSpecialMonth: 0,
        specialMonthList: [],
        // 扣发规则
        deductRule: 'staff_status,inoutmonth,engage_mode,absencetype',
        deductRuleList: [],
        deductRulePara: {
          staff_status: [],
          inoutmonth: [],
          engage_mode: [],
          absencetype: []
        },
        // 自定义计算公式
        formula: {}
      },
      rules: {
        typeName: [
          { required: true, message: '请输入类型名称', trigger: 'blur' },
          { min: 2, max: 10, message: '限制2-10个文字', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    mode() {
      // 根据是否有id判断新增、编辑
      return this.salaryItemNo ? 'edit' : 'add'
    }
  },
  watch: {
    'form.defineMode'(newVal) {
      this.form.deductRule = newVal === '2' ? 'staff_status,inoutmonth,engage_mode,absencetype' : ''
      this.form.specialMonth = newVal === '2' ? 1 : 0
      this.form.isAttendScale = newVal === '2' ? 1 : 0
      if (newVal === '2') {
        this.$refs.otherRules.initWithHoldData()
      }
    }
  },
  methods: {
    // 确认保存薪资项
    submitPreserve: debounce(async function() {
      console.log(this.form)
      const res = await saveItem({
        ...this.form,
        payrollGroupId: this.infoData.groupId, // 薪资组id
        salaryType: this.typeNo, // 薪资分类
        specialMonthList: this.form.specialMonthList && this.form.specialMonthList?.join(',')
      })
      if (res.code === 100) {
        this.$message.success(`${this.mode === 'edit' ? '编辑' : '新增'}成功!`)
        this.childVisible = false
        this.$emit('refreshData')
      }
    }, 500),
    // 修改弹出规则框
    setUpCustomFormula() {
      this.$refs.FormulaSetting.open()
    },
    // 打开抽屉 type：edit/add
    open(typeNo, salaryItemNo) {
      this.childVisible = true
      this.typeNo = typeNo
      if (typeNo === 'A08') {
        setTimeout(() => {
          this.$set(this.form, 'calcRuleType', '3')
        })
      }
      // 清除缓存数据 和 验证规则
      this.resetData()
      // 编辑时 salaryItemNo有值 mode:edit 则获取薪资项详情
      this.salaryItemNo = salaryItemNo
      if (this.mode === 'edit') {
        this.getItemDetail(salaryItemNo)
      }
      // 获取未启用的薪资组
      setTimeout(() => {
        this.$refs.salaryItem.getUnconfigItems()
      })
    },
    // 获取薪资项详情
    async getItemDetail(salaryItemNo) {
      const res = await getItemDetail({ groupId: this.infoData.groupId, salaryItemNo: salaryItemNo })
      if (res.code === 100) {
        const data = { ...res.data }
        // 处理发放月份的值
        if (data.specialMonthList) {
          data.isSpecialMonth = 1
          data.specialMonthList = data.specialMonthList.split(',')
        } else {
          data.isSpecialMonth = 0
          data.specialMonthList = []
        }
        this.form = data
        if (this.form.deductRule) {
          this.$refs.otherRules.initWithHoldData()
        }
      }
    },
    // 获取薪资项配置项
    async getSalaryData(value) {
      // 自定义薪资项
      if (value?.slice(0, 3) === 'ZDY') {
        this.form.calcRuleType = '2'
      } else if (value?.slice(0, 2) === 'BC') {
        // 补发扣除
        return
      }
      const res = await getItemConfig({ salaryItemNo: value })
      if (res.code === 100) {
        this.form = { ...this.form, ...res.data }
        if (this.form.deductRule) {
          this.$refs.otherRules.initWithHoldData()
        }
      }
    },
    resetData() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
        this.form = { ...this.$options.data().form }
        // Object.assign(this.$data.form, this.$options.data().form)
      })
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-form-item-label > label {
  color: @sc-grey-80;
}
/deep/ span.ant-radio + * {
  color: @sc-grey-80;
}
/deep/.ant-form-item{
  margin-bottom: 14px !important;
}
/deep/.ant-form-item {
  display:flex;
  align-items: center;
}
/deep/.ant-form-item-label {
        display: flex;
        label {
          white-space: normal;
          width:95px !important;
          .font-size(14px);
          &:after {
            content: none !important;
          }
        }
      }
</style>
