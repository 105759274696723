<template>
  <div id="HolidayType" class="HolidayType beauty-scroll">
    <AlertTips>
      <template slot="content">
        <p>1.您可以新增/启用更多假期类型。设置后，可以到【假期】→【假期方案】 为员工设置不同假期规则。</p>
        <p>2.员工请假先扣除带薪假期余额，再计算请假扣款。</p>
      </template>
    </AlertTips>
    <appForm
      :to-list="formList"
      search-placeholder="请搜索"
      @getSearchCallback="getSearchCallback"
    >
      <template slot="customButton">
        <a-button type="primary" prefix="add-one" @click="addType">
          新增假期类型
        </a-button>
      </template>
    </appForm>
    <custom-table
      id="table"
      :columns="columns"
      :data-source="dataSource"
      row-key="vacationTypeId"
      :pagination="false"
      @change="loadDataList"
    >
      <!-- 假期 颜色 -->
      <template slot="textColor" slot-scope="{scope}">
        <div class="color-div" :style="getcolor(scope.record.textColor)" />
      </template>
      <span slot="switchStatus" slot-scope="{scope}">
        <div>
          <a-switch :checked="scope.record.status?true:false" size="small" @click="onChange(scope.record)" />
        </div>
      </span>
      <template slot="vacationTypeUnit" slot-scope="{scope}">
        <span>{{ scope.record.vacationTypeUnit==='D'?'天':'小时' }}</span>
      </template>
      <!-- 操作列 -->
      <template slot="operation" slot-scope="{ scope}">
        <div class="flex-align-center">
          <template>
            <div class="more-handle-btn-box">
              <a-button type="link" @click="editType(scope.record)">
                设置
              </a-button>
              <a-button type="link" class="delete" @click="delet(scope.record)">
                删除
              </a-button>
            </div>
          </template>
          <!-- <a-popover
            placement="bottom"
            trigger="hover"
          >
            <template slot="content">
              <div class="more-handle-btn">
                <a-button type="link" @click="editType(scope.record)">
                  设置
                </a-button>
                <a-button type="link" class="delete" @click="delet(scope.record)">
                  删除
                </a-button>
              </div>
            </template>
            <div class="more-handle">
              ···
            </div>
          </a-popover> -->
        </div>
      </template>
    </custom-table>
    <TypeModal ref="typeModal" :form-type="formType" />
  </div>
</template>

<script>
import { getVacationTypeList, updateVacationTypeStatus, deleteVacationType } from '@/services/attendance/holidays.js'
import { getcolorTheme } from '@/services/attendance/color.js'
import mixins from '@/components/CustomTable/index.js'
import { debounce } from '@/utils/index.js'
export default {
  name: 'HolidayType',
  components: {
    TypeModal: () => import('./typeModal/index'),
    AlertTips: () => import('../../components/alert.vue')
  },
  mixins: [mixins],
  data() {
    return {
      colorList: [],
      formList: ['search'], // search搜索框
      columns: [{
        title: '假期颜色',
        dataIndex: 'textColor',
        scopedSlots: { customRender: 'textColor' },
        key: 'textColor',
        width: '100px'
      },
      {
        title: '假期类型',
        dataIndex: 'vacationTypeName',
        key: 'vacationTypeName',
        width: '100px'
      },
      {
        title: '默认单位',
        scopedSlots: { customRender: 'vacationTypeUnit' },
        width: '100px'
      },
      {
        title: '假期描述',
        dataIndex: 'vacationDesc',
        key: 'vacationDesc',
        ellipsis: true,
        width: '500px'
      },
      {
        title: '状态',
        dataIndex: 'status',
        scopedSlots: { customRender: 'switchStatus' },
        key: 'status',
        width: '200px'
      },
      {
        title: '设置',
        dataIndex: 'setting',
        scopedSlots: { customRender: 'operation' },
        key: 'setting',
        width: '100px',
        fixed: 'right'
      }
      ],
      dataSource: [],
      loading: false,
      formType: ''
    }
  },
  computed: {},
  created() {
    this.getcolorThemeList()
  },
  methods: {
    // 查询系统颜色
    async getcolorThemeList() {
      const res = await getcolorTheme()
      this.colorList = res.data
    },
    getcolor(colorVal) {
      let color
      this.colorList.forEach((v) => {
        if (v.colorThemeCode === colorVal) {
          color = v.fontRgba
        }
      })
      return `background:rgba(${color})`
    },
    // 请求数据
    async fetchDataApiFn(_queryParam) {
      const res = await getVacationTypeList({ ..._queryParam })
      if (res.code === 100) {
        this.dataSource = res.data
      }
    },
    addType() {
      this.formType = 'add'
      this.$refs.typeModal.open()
    },
    editType(record) {
      this.formType = 'edit'
      this.$refs.typeModal.open(record)
    },
    onChange(record) {
      // console.log(col)
      const { status, vacationTypeId } = record
      updateVacationTypeStatus({ status: status ? 0 : 1, vacationTypeId: vacationTypeId }).then(res => {
        this.$message.success('状态改变成功！')
        this.serchGetData()
      }).catch(err => {
        if (err.code === 407076) {
          this.serchGetData()
        }
      })
    },
    delet(record) {
      this.vacationTypeId = record.vacationTypeId
      this.$Reconfirm({
        title: '删除假期类型',
        content: '是否确定删除该假期类型？'
      }).then(() => {
        this.handleDelType()
      }).catch(() => {
        console.log('no')
      })
    },
    handleDelType: debounce(async function() {
      const res = await deleteVacationType({ vacationTypeId: this.vacationTypeId })
      if (res.code === 100) {
        this.$message.success('删除成功！')
        this.serchGetData()
      }
    }, 500),
    getSearchCallback(val) {
      this._queryParam.searchWord = val.searchValue
      this.serchGetData()
    }
  }
}
</script>

<style lang="less" scoped>
.HolidayType{
  height: 100%;
  overflow: auto;
  background-color:@base-bg-color;
  padding:20px 20px;
  overflow:auto;
  .color-div{
    width:24px;
    height:24px;
    border-radius: 2px;
  }
}
.more-handle-btn {
    display: flex;
    flex-direction: column;
    .ant-btn {
      height:25px;
    }
}
.more-handle {
  font-weight: 700;
  line-height: 44px;
  font-size: 20px;
  color: @sc-primary-100;
  vertical-align: baseline;
  cursor: pointer;
  margin-left: 5px;
}
.more-handle-btn-box{
  display: flex;
  button{
    padding-left: 0;
    padding-right: 16px;
  }
}
</style>
